import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import React from 'react';
import { FontAwesome5 } from '@expo/vector-icons';
import {
  default as Icons,
  default as MaterialCommunityIcons,
} from 'react-native-vector-icons/MaterialCommunityIcons';
import { defaultTheme } from '../../themes/loot8';

const MessagesReplyComponent = ({
  quoteMsg,
  isLoading,
  setQuoteMsg,
  setSelectedMsg,
  friendsPublicKey,
  address,
}) => {
  return (
    <View style={styles.replyContainer}>
      <View
        style={[
          styles.row,
          {
            justifyContent: 'space-between',
          },
        ]}>
        <View style={styles.row}>
          <FontAwesome5
            name="reply"
            size={10}
            color={defaultTheme.SECONDARY_TEXT_COLOR}
          />
          <Text style={styles.replyText} numberOfLines={1} ellipsizeMode="tail">
            {'Replying to '}
            {quoteMsg?.user?.wallet?.toLowerCase() === address?.toLowerCase()
              ? 'yourself'
              : `${quoteMsg?.user?.name} #${quoteMsg?.user?.id}`}
          </Text>
        </View>

        <TouchableOpacity
          disabled={isLoading}
          onPress={() => {
            setQuoteMsg(null);
            setSelectedMsg(null);
          }}
          style={styles.replyCloseBtn}>
          <MaterialCommunityIcons
            name="close"
            size={11}
            color={defaultTheme.MAIN_BACKGROUND_COLOR}
            style={{ marginLeft: 1 }}
          />
        </TouchableOpacity>
      </View>
      {quoteMsg &&
        quoteMsg?.data &&
        quoteMsg?.data?.data.content?.text &&
        friendsPublicKey && (
          <Text numberOfLines={1} ellipsizeMode="tail" style={styles.replyText}>
            {quoteMsg?.data?.data.content?.decryptedText}{' '}
          </Text>
        )}
      {quoteMsg?.data?.data?.attachments &&
        quoteMsg?.data?.data?.attachments?.uri !== '' && (
          <Icons
            name="image"
            color={'grey'}
            size={30}
            style={styles.imageIcon}
          />
        )}
    </View>
  );
};

export default MessagesReplyComponent;

const styles = StyleSheet.create({
  imageIcon: {
    position: 'absolute',
    right: 5,
    zIndex: -1,
    height: 30,
    width: 30,
  },
  replyContainer: {
    paddingHorizontal: 5,
    paddingVertical: 10,
    marginVertical: 10,
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  replyCloseBtn: {
    height: 15,
    width: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7.5,
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  replyText: {
    marginLeft: 5,
    fontSize: defaultTheme.FONT_SIZE_11,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
