import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 45,
  },
  subtitle: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    marginTop: -7.5,
    width: '75%',
  },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
    lineHeight: defaultTheme.FONT_SIZE_XSMALL + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
});
