import React from 'react';
import { Image, StyleSheet, Text } from 'react-native';
import HOCContainer from '../../../../HOCContainer';
import { defaultTheme } from '../../../../../themes/loot8';
import { View } from 'react-native';
import GradientButton from '../../../../GradientButton';
import styles from '../../../../../styles';

let heading;
const PrivateMessageSubUnsub: React.FC<any> = ({ navigation, route }) => {
  const type = route.params.type;

  heading =
    type === 'sub'
      ? 'Subscribe to Private Messaging'
      : 'Unsubscribe From Private Messaging';
  return (
    <>
      <View
        style={{ height: '100%', width: '100%', overflow: 'hidden', flex: 1 }}>
        <View style={[{ paddingTop: 0, justifyContent: 'space-between' }]}>
          {/* Header Container */}

          <View style={{ justifyContent: 'center', padding: 20 }}>
            <Text style={localStyles.subHeadingDiscription}>{heading}</Text>
          </View>

          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              flex: 1,
            }}>
            <View
              style={{
                flexDirection: 'column',
                backgroundColor: 'rgba(255,255,255,0.13)',
                width: '100%',
                padding: 15,
                borderRadius: 6,
              }}>
              {/* ChatRoom Name */}
              <View style={styles.tokenBuyItemContainer}>
                <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
                  Chatroom Name
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 0.6,
                    justifyContent: 'flex-end',
                  }}>
                  <Text
                    style={{
                      ...styles.tokenBuyItemCaption,
                      maxWidth: '75%',
                      textAlign: 'right',
                    }}>
                    {'NAME'}
                  </Text>
                </View>
              </View>
              <View style={styles.tokenBuyHairline} />

              {/* Associated EXPass */}
              <View style={styles.tokenBuyItemContainer}>
                <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
                  Associated ExPass
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 0.6,
                    justifyContent: 'flex-end',
                  }}>
                  <Text
                    style={{
                      ...styles.tokenBuyItemCaption,
                      maxWidth: '75%',
                      textAlign: 'right',
                    }}>
                    Example
                  </Text>
                </View>
              </View>
              <View style={styles.tokenBuyHairline} />
              <View style={styles.tokenBuyItemContainer}>
                <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.6 }}>
                  {type === 'sub'
                    ? 'Amount (Loot8 Token)'
                    : 'Tokens Expected After Unsubscribing'}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 0.4,
                    justifyContent: 'flex-end',
                  }}>
                  <Image
                    source={require('../../../../../assets/eightIcon.png')}
                    style={{ ...localStyles.contentUserImage, borderWidth: 0 }}
                  />
                  <Text
                    style={{
                      ...styles.tokenBuyItemCaption,
                      maxWidth: '75%',
                      textAlign: 'right',
                    }}></Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ bottom: 30, position: 'absolute', width: '100%' }}>
          <GradientButton
            buttonLabel={type === 'sub' ? 'Subscribe' : 'Unsubscribe'}
            onPress={() => {}}
          />
        </View>
      </View>
    </>
  );
};

export default HOCContainer({
  OriginalComponent: PrivateMessageSubUnsub,
  title: heading,
});

const localStyles = StyleSheet.create({
  contentDetailContainer: {
    width: '95%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    minHeight: 40,
  },
  contentUserImage: {
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
    backgroundColor: 'lightblue',
    borderColor: '#fff',
    borderWidth: 1,
    marginRight: 5,
  },
  contentDetailText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    color: '#fff',
  },
  ownerNameText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    color: '#fff',
  },
  subHeadingDiscription: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    textAlign: 'center',
    color: '#fff',
  },
  splashExploreButton: {
    width: '100%',
    height: 52,
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    marginTop: 10,
    justifyContent: 'center',
  },
  splashExploreButtonText: {
    color: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
});
