import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import OffersScreen from '../screens/OffersScreen';
import FeaturedOfferDetail from '../components/FeaturedOfferDetail';

const Stack = createStackNavigator();

function OfferNavigator() {
  return (
    <Stack.Navigator initialRouteName="Offers">
      <Stack.Screen name="Offers" options={{ headerShown: false }} component={OffersScreen} />
      <Stack.Screen name="FeaturedOfferDetail" options={{ headerShown: false }} component={FeaturedOfferDetail} />
      {/* <Stack.Screen name="OfferDetail" options={{ headerShown:false }} component={OfferDetail} /> */}
      {/* <Stack.Screen name="EventsDetail" options={{ headerShown:false }} component={EventsDetail} /> */}
    </Stack.Navigator>
  );
}

export default OfferNavigator;
