import React from 'react';
import {
  View,
  Text,
  Platform,
  Dimensions,
  TouchableOpacity,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from 'react-native';
import { BlurView } from 'expo-blur';
import Modal from 'react-native-modal';
import { Portal } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import styles from '../../styles';
import AppLoaderComponent from '../Loader';
import { useAppSelector } from '../../hooks';
import { defaultTheme } from '../../themes/loot8';
import { ModalWebBlurView } from '../WebBlurView';
import { hideKeyboard } from '../../slices/helpers';

type ModalComponentProps = {
  showModal: boolean;
  headerText?: string;
  children?: React.ReactNode;
  dismissable?: boolean;
  onDismiss?: () => void;
  needCloseButton?: boolean;
  modalStyle?: any;
  modalBodyStyle?: any;
  modalHeaderStyle?: any;
  enableHeaderhairline?: boolean;
  enableHeader?: boolean;
  theme?: any;
  showLoading?: boolean;
  avoidKeyboard?: boolean;
  modalCenterdStyle?: any;
  dissmissKeyBoardOnTap?: boolean;
  loader?: boolean;
};

const ModalComponent = ({
  showModal,
  dismissable = true,
  onDismiss,
  headerText,
  children,
  modalStyle,
  modalHeaderStyle,
  modalBodyStyle,
  needCloseButton = false,
  enableHeaderhairline = true,
  enableHeader = true,
  theme = 'rgba(0, 0, 0, 0.5)',
  showLoading = true,
  avoidKeyboard = false,
  modalCenterdStyle,
  dissmissKeyBoardOnTap = false,
  loader = false,
}: ModalComponentProps) => {
  // const loading = useAppSelector(state => state.App.msgLoading);
  const windowDimensions = useWindowDimensions();

  const dismissKeyboard = () => {
    if (dissmissKeyBoardOnTap) {
      hideKeyboard();
    }
  };

  return (
    <Portal>
      <Modal
        isVisible={showModal}
        onBackdropPress={() => dismissable && onDismiss()}
        onDismiss={onDismiss}
        style={modalStyle}
        animationIn={'slideInUp'}
        animationOut={'slideOutDown'}
        hasBackdrop={true}
        backdropColor={theme}
        hideModalContentWhileAnimating={true}
        avoidKeyboard={avoidKeyboard}
        deviceHeight={Platform.OS === 'web' ? windowDimensions.height : null}
        deviceWidth={
          Platform.OS === 'web' ? Dimensions.get('window').width : null
        }>
        <TouchableWithoutFeedback onPress={dismissKeyboard}>
          {Platform.OS === 'web' ? (
            loader ? (
              <View style={[styles.modalMainBody, modalBodyStyle]}>
                {children}
              </View>
            ) : (
              <ModalWebBlurView>
                <LinearGradient
                  colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
                  start={{ x: 0.2, y: 0 }}
                  end={{ x: 1, y: 1 }}
                  style={{ ...styles.maxWidthAdjust, ...styles.modalMainView }}>
                  {enableHeader && (
                    <>
                      <View style={styles.modalHeaderView}>
                        <Text
                          style={[styles.modalHeaderText, modalHeaderStyle]}>
                          {headerText}
                        </Text>
                      </View>
                      {enableHeaderhairline && (
                        <View style={styles.modalHairline}></View>
                      )}
                    </>
                  )}
                  <View style={[styles.modalMainBody, modalBodyStyle]}>
                    {children}
                  </View>
                  {needCloseButton && (
                    <View
                      style={{
                        justifyContent: 'flex-end',
                        zIndex: 3,
                        position: 'absolute',
                        top: 5,
                        right: 5,
                      }}>
                      {/* <TouchableOpacity
                        onPress={onDismiss}
                        style={styles.closeBtnOuter}>
                        <Icon name="close" size={16} color="#FFFFFF" />
                      </TouchableOpacity> */}
                    </View>
                  )}
                </LinearGradient>
              </ModalWebBlurView>
            )
          ) : loader ? (
            <View style={[styles.modalMainBody, modalBodyStyle]}>
              {children}
            </View>
          ) : (
            <BlurView
              tint="dark"
              style={{
                borderRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
                overflow: 'hidden',
                backgroundColor: 'rgba(0,0,0,0.2)',
              }}
              intensity={Platform.OS === 'android' ? 15 : 25}>
              <LinearGradient
                colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
                start={{ x: 0.2, y: 0 }}
                end={{ x: 1, y: 1 }}
                style={[
                  styles.maxWidthAdjust,
                  {
                    borderRadius: defaultTheme.BUTTON_RADIUS,
                    overflow: 'hidden',
                  },
                ]}>
                <View style={styles.modalMainView}>
                  {enableHeader && (
                    <>
                      <View style={styles.modalHeaderView}>
                        <Text
                          style={[styles.modalHeaderText, modalHeaderStyle]}>
                          {headerText}
                        </Text>
                      </View>
                      {enableHeaderhairline && (
                        <View style={styles.modalHairline}></View>
                      )}
                    </>
                  )}
                  <View style={[styles.modalMainBody, modalBodyStyle]}>
                    {children}
                  </View>
                </View>
                {needCloseButton && (
                  <View
                    style={{
                      top: 10,
                      right: 10,
                      zIndex: 99,
                      position: 'absolute',
                      justifyContent: 'flex-end',
                    }}>
                    <TouchableOpacity
                      onPress={onDismiss}
                      style={styles.closeBtnOuter}>
                      <Icon name="close" size={16} color="#FFFFFF" />
                    </TouchableOpacity>
                  </View>
                )}
              </LinearGradient>
            </BlurView>
          )}
        </TouchableWithoutFeedback>
      </Modal>
      {/* {showLoading && loading ? (
        <AppLoaderComponent
          extraStyle={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
        />
      ) : null} */}
    </Portal>
  );
};

export default ModalComponent;
