import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';
import OfferNavigator from './OfferNavigator';
import WalletNavigator from './WalletNavigator';
import HomeNavigator from './HomeNavigator';
import { Platform } from 'react-native';
import QRScreen from '../screens/QRScreen';
// import CustomBottomTabBar from './CustomBottomTabBar';
import TabBarButton from '../components/TabBarButton';
import CustomBottomTab from './AnimatedBottomTab/CustomBottomTab';
import MoreNavigator from './MoreNavigator';
import FriendsNavigator from './FriendsNavigator';
import MapNavigator from './MapNavigator';

// const resetTabStacksOnBlur = ({ navigation }) => ({
//   blur: () => {
//     const state = navigation.getState();
//     state.routes.forEach((route, tabIndex) => {
//       if (state?.index !== tabIndex && route.state?.index > 0) {
//         navigation.setParams({ screen: null, params: {} });
//       }
//     });
//   },
// });

const Tab = createBottomTabNavigator();

const BottomTabNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        unmountOnBlur: true,
        tabBarHideOnKeyboard: true,
        headerShown: false,
      }}
      initialRouteName="HomeTab"
      tabBar={props => <CustomBottomTab {...props} />}>
      <Tab.Screen
        name="FriendsTab"
        options={{
          tabBarHideOnKeyboard: Platform.OS === 'android' && true,
          title: 'Messages',
        }}
        component={FriendsNavigator}
      />
      <Tab.Screen
        name="MapTab"
        options={{ title: 'Map' }}
        component={MapNavigator}
      />
      <Tab.Screen
        name="HomeTab"
        options={{
          title: 'Home',
        }}
        component={HomeNavigator}
      />
      {/* <Tab.Screen
        name="CatalogTab"
        options={{ title: 'Portfolio', headerShown: false }}
        listeners={resetTabStacksOnBlur}
        component={CatalogNavigator}
      /> */}
      <Tab.Screen
        name="WalletTab"
        options={{ title: 'Wallet' }}
        component={WalletNavigator}
      />
      <Tab.Screen
        name="MoreTab"
        options={{ title: 'More' }}
        component={MoreNavigator}
      />

      <Tab.Screen
        name="OffersTab"
        options={{ title: 'Offers' }}
        component={OfferNavigator}
      />
    </Tab.Navigator>
  );
};

export default BottomTabNavigator;
