import React, { useEffect, useRef } from 'react';
import { View, useWindowDimensions, Platform, FlatList } from 'react-native';
import ScreenTitle from '../components/ScreenTitle';
import HOCContainer from '../components/HOCContainer';
import { CatalogTypes } from '../enums/catalog.enum';
import useActiveDimensions from '../hooks/useActiveDimensions';
import CollIcon from '../assets/catalog/Collectible3x.png';
import EventIcon from '../assets/catalog/Event3x.png';
import PassIcon from '../assets/catalog/Passport3x.png';
import { defaultTheme } from '../themes/loot8';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { loadAllCatList } from '../slices/CatalogSlice';
import { clearCatalog } from '../helpers/Gadgets';
import { CatalogList } from '../components/CatalogList';
import { ScreenName } from '../enums/screen.enum';
import AnimatedUnderlineTabs from '../components/AnimatedUnderlineTabs';
import useIsResponsive from '../hooks/useIsResponsive';
import { useIsFocused } from '@react-navigation/native';

export const AllCatalogsTypes = [
  {
    key: CatalogTypes.PASSPORT,
    name: CatalogTypes.PASSPORT,
    title: 'ExPasses',
    subTitle: 'A look back at your journey, so far!',
    type: CatalogTypes.PASSPORT,
    icon: PassIcon,
  },
  {
    key: CatalogTypes.TICKET,
    name: CatalogTypes.TICKET,
    title: 'Tickets',
    subTitle: 'Our experience together!',
    type: CatalogTypes.TICKET,
    icon: EventIcon,
  },
  {
    key: CatalogTypes.COLLECTIBLES,
    name: CatalogTypes.COLLECTIBLES,
    subTitle: 'Go for it! More to come!!',
    title: 'Collectibles',
    type: CatalogTypes.COLLECTIBLES,
    icon: CollIcon,
  },
];

const topTabsButtonData = [
  { id: 1, title: 'ExPasses', type: CatalogTypes.PASSPORT },
  { id: 2, title: 'Tickets', type: CatalogTypes.TICKET },
  { id: 3, title: 'Collectibles', type: CatalogTypes.COLLECTIBLES },
];

function CatalogScreen({ navigation, route }) {
  const dispatch = useDispatch<AppDispatch>();
  const tabsRef = useRef(null);
  const activeWidth = useActiveDimensions().width;
  const { networkId, staticProvider, address, wallet, userInfo } =
    useWeb3AuthContext();
  const [refreshing, setRefreshing] = React.useState(false);
  const isResponsive = useIsResponsive();

  const onRefresh = React.useCallback(async () => {
    // setRefreshing(true);
    await clearCatalog('Passports');
    await clearCatalog('Events');
    await clearCatalog('Collectibles');
    await dispatch(
      loadAllCatList({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        userInfo,
      }),
    ).then(() => setRefreshing(false));
  }, []);
  useEffect(() => {
    if (
      Platform.OS === 'web' &&
      new URLSearchParams(window.location.search).get('page') ===
        'portfolio-expasses'
    ) {
      navigation.navigate(ScreenName.CATALOG_DETAILS);
    }
  }, []);

  const goToNextSlide = index => {
    const offset = isResponsive
      ? index * defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
        defaultTheme.HORIZONTAL_PADDING * 2
      : index * activeWidth - defaultTheme.HORIZONTAL_PADDING * 2;
    tabsRef?.current.scrollToOffset({ offset });

    // setPreviousTab(activeTab);
  };

  return (
    <>
      <ScreenTitle title={'Portfolio'} />
      <AnimatedUnderlineTabs
        data={topTabsButtonData}
        changeSlidesOnPress={goToNextSlide}
      />
      <FlatList
        ref={tabsRef}
        data={topTabsButtonData}
        horizontal
        snapToAlignment="start"
        decelerationRate={'fast'}
        snapToInterval={activeWidth - defaultTheme.HORIZONTAL_PADDING * 2}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ flex: 1 }}
        renderItem={({ item, index }) => (
          <Slide
            item={item}
            activeIndex={index}
            route={route}
            navigation={navigation}
          />
        )}
        scrollEnabled={false}
      />
      {/* {connected && <Passport />} */}
      {/* <MigartionComponent
        address={address}
        userInfo={userInfo}
        wallet={wallet}
        staticProvider={staticProvider}
        networkId={networkId}
      />
      {networkDisconnected ? (
        <ModalDisconnected
          isDisconnected={networkDisconnected}
          navigation={navigation}
        />
      ) : (
        <></>
      )} */}
    </>
    // <>
    //   <LinearGradient
    //     colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
    //     style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
    //     <ScrollView
    //       refreshControl={
    //         <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
    //       }
    //       showsVerticalScrollIndicator={false}>
    //       <View style={[styles.mainContainer]}>
    //         <View
    //           style={[
    //             styles.contentContainer,
    //             styles.maxWidthAdjust,
    //             { maxWidth: isResponsive ? '100%' : 600 },
    //           ]}>
    //           <View
    //             style={[
    //               styles.contentHeader,
    //               { flexDirection: 'row', justifyContent: 'center' },
    //             ]}>
    //             <Text style={styles.contentHeaderText}>Portfolio</Text>
    //             <Pressable
    //               style={{
    //                 width: 45,
    //                 height: 44,
    //                 borderRadius: 6,
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //               }}
    //               onPress={() => onRefresh()}>
    //               <FontAwesome name="refresh" size={18} color={'#F4B540'} />
    //             </Pressable>
    //           </View>
    //           <View
    //             style={[
    //               styles.tileContainer,
    //               {
    //                 flexDirection: isResponsive ? 'row' : 'column',
    //                 gap: 5,
    //                 justifyContent: 'center',
    //               },
    //             ]}>
    //             {AllCatalogsTypes.map((item, index) => {
    //               return (
    //                 <Pressable
    //                   key={index}
    //                   onPress={() => {
    //                     handleTileClicked(item);
    //                   }}>
    //                   {/* <View key = {index} style={styles.catalogTileContainer}> */}
    //                   <LinearGradient
    //                     colors={[
    //                       defaultTheme.GRADIENT_COLOR1,
    //                       defaultTheme.GRADIENT_COLOR2,
    //                     ]}
    //                     start={[0, 0]}
    //                     end={[1, 1]}
    //                     style={
    //                       isResponsive
    //                         ? [
    //                             styles.catalogTitleContainerLg,
    //                             activeTab === item.key
    //                               ? styles.catalogActiveTab
    //                               : '',
    //                           ]
    //                         : styles.catalogTileContainer
    //                     }
    //                     key={index}>
    //                     <View
    //                       style={{
    //                         flexDirection: 'row',
    //                         justifyContent: 'flex-start',
    //                         borderRadius: defaultTheme.CONTENT_RADIUS,
    //                       }}>
    //                       <ImageBackground
    //                         style={{ width: 60, height: 60, margin: 15 }}
    //                         source={item.icon}
    //                         resizeMode="cover"></ImageBackground>
    //                       <View
    //                         style={{
    //                           flexDirection: 'column',
    //                           marginTop: 12,
    //                           marginLeft: 10,
    //                           flex: 1,
    //                         }}>
    //                         <Text
    //                           numberOfLines={1}
    //                           adjustsFontSizeToFit
    //                           style={{
    //                             fontFamily: defaultTheme.FONT_FAMILY_MAIN,
    //                             fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    //                             fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    //                             color: defaultTheme.TEXT_COLOR_SECONDARY,
    //                           }}>
    //                           {item.title}
    //                         </Text>
    //                         <Text
    //                           style={{
    //                             fontFamily: defaultTheme.FONT_FAMILY_MAIN,
    //                             fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    //                             color: defaultTheme.TEXT_COLOR_SECONDARY,
    //                           }}>
    //                           {item.subTitle}
    //                         </Text>
    //                       </View>
    //                     </View>
    //                   </LinearGradient>
    //                   {/* </View>  */}
    //                 </Pressable>
    //               );
    //             })}
    //           </View>
    //           <View>
    //             {isResponsive && (
    //               <CatalogList
    //                 previousTab={previousTab}
    //                 activeTab={activeTab}
    //                 route={route}
    //                 navigation={navigation}
    //               />
    //             )}
    //           </View>
    //         </View>
    //       </View>
    //     </ScrollView>
    //     {networkDisconnected ? (
    //       <ModalDisconnected
    //         isDisconnected={networkDisconnected}
    //         navigation={navigation}
    //       />
    //     ) : (
    //       <></>
    //     )}
    //   </LinearGradient>
    // </>
  );
}

const Slide = ({ item, activeIndex, route, navigation }) => {
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();
  const isFocused = useIsFocused();
  const renderContent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <CatalogList
            previousTab={topTabsButtonData[2].type}
            activeTab={topTabsButtonData[0].type}
            route={route}
            navigation={navigation}
            isFocused={isFocused}
          />
        );
      case 1:
        return (
          <CatalogList
            previousTab={topTabsButtonData[0].type}
            activeTab={topTabsButtonData[1].type}
            route={route}
            navigation={navigation}
            isFocused={isFocused}
          />
        );
      case 2:
        return (
          <CatalogList
            previousTab={topTabsButtonData[1].type}
            activeTab={topTabsButtonData[2].type}
            route={route}
            navigation={navigation}
            isFocused={isFocused}
          />
        );
      default:
        return (
          <CatalogList
            previousTab={topTabsButtonData[2].type}
            activeTab={topTabsButtonData[0].type}
            route={route}
            navigation={navigation}
            isFocused={isFocused}
          />
        );
    }
  };
  return (
    <View
      style={
        isResponsive
          ? {
              width:
                defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
                defaultTheme.HORIZONTAL_PADDING * 2,
            }
          : {
              width: activeWidth - defaultTheme.HORIZONTAL_PADDING * 2,
            }
      }>
      {renderContent()}
    </View>
  );
};

const WrappedScreen = HOCContainer({
  OriginalComponent: CatalogScreen,
  isScrollEnabled: false,
});

export default WrappedScreen;
