import { useState, useEffect } from 'react';
import { RESPONSIVE, RESPONSIVE_WIDTH } from '../appconstants';
import useActiveDimensions from './useActiveDimensions';

const useIsResponsive = () => {
  const [isResponsive, setIsResponsive] = useState(false);
  const activeWidth = useActiveDimensions().width;

  useEffect(() => {
    // Convert RESPONSIVE_WIDTH to an integer
    const responsiveWidthInt = parseInt(RESPONSIVE_WIDTH);

    // Check if RESPONSIVE flag is 'true' and activeWidth is greater than RESPONSIVE_WIDTH
    if (RESPONSIVE === 'true' && activeWidth > responsiveWidthInt) {
      setIsResponsive(true);
    } else {
      setIsResponsive(false);
    }
  }, [activeWidth, RESPONSIVE, RESPONSIVE_WIDTH]);

  return isResponsive;
};

export default useIsResponsive;
