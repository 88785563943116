import React from 'react';
import Svg, {
  G,
  Path,
  Line,
  Defs,
  Stop,
  Circle,
  LinearGradient,
  Rect,
  ClipPath,
  Mask,
} from 'react-native-svg';

export function MessageSvg({ expand }) {
  return (
    <Svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M7.2 8C9.52125 8 11.4 6.21071 11.4 4C11.4 1.78929 9.52125 0 7.2 0C4.87875 0 3 1.78929 3 4C3 6.21071 4.87875 8 7.2 8ZM10.08 9.14286H9.76875C8.98875 9.5 8.1225 9.71429 7.2 9.71429C6.2775 9.71429 5.415 9.5 4.63125 9.14286H4.32C1.935 9.14286 0 10.9857 0 13.2571V14.2857C0 15.2321 0.80625 16 1.8 16H12.6C13.5938 16 14.4 15.2321 14.4 14.2857V13.2571C14.4 10.9857 12.465 9.14286 10.08 9.14286ZM18 8C19.9875 8 21.6 6.46429 21.6 4.57143C21.6 2.67857 19.9875 1.14286 18 1.14286C16.0125 1.14286 14.4 2.67857 14.4 4.57143C14.4 6.46429 16.0125 8 18 8ZM19.8 9.14286H19.6575C19.1363 9.31429 18.585 9.42857 18 9.42857C17.415 9.42857 16.8638 9.31429 16.3425 9.14286H16.2C15.435 9.14286 14.73 9.35357 14.1112 9.69286C15.0262 10.6321 15.6 11.8786 15.6 13.2571V14.6286C15.6 14.7071 15.5813 14.7821 15.5775 14.8571H22.2C23.1938 14.8571 24 14.0893 24 13.1429C24 10.9321 22.1213 9.14286 19.8 9.14286Z"
        fill={expand ? '#fff' : 'url(#paint0_linear_323_5838)'}
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_323_5838"
          x1="12"
          y1="0"
          x2="12"
          y2="41"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor={expand ? '#fff' : '#9A4DFF'} />
          <Stop
            offset="1"
            stopColor={expand ? '#fff' : '#9747FF'}
            stopOpacity="0"
          />
          <Stop offset="1" stopColor={expand ? '#fff' : '#F600DD'} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
export function HomeSvg({ expand }) {
  return (
    <Svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M15.5045 20H13.6362H5.66802H3.79974C1.9373 20 0.42749 18.4607 0.42749 16.5618V7.84736C0.434663 7.09967 0.779249 6.39702 1.36163 5.94256L7.72313 0.685301C8.8425 -0.228434 10.4337 -0.228434 11.5531 0.685301L17.9426 5.93303C18.5228 6.38935 18.8668 7.09083 18.8768 7.83784V16.5618C18.8768 18.4607 17.367 20 15.5045 20Z"
        fill={expand ? '#fff' : 'url(#paint0_linear_323_5861)'}
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_323_5861"
          x1="13"
          y1="2"
          x2="13"
          y2="22"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor={expand ? '#fff' : '#9A4DFF'} />
          <Stop offset="1" stopColor={expand ? '#fff' : '#F600DD'} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
export function MapSvg({ expand }) {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M13 2C8.58905 2 5.00008 5.589 5.00008 9.995C4.97108 16.44 12.696 21.784 13 22C13 22 21.0289 16.44 20.9999 10C20.9999 5.589 17.411 2 13 2ZM13 14C10.79 14 9.00004 12.21 9.00004 10C9.00004 7.79 10.79 6 13 6C15.21 6 17 7.79 17 10C17 12.21 15.21 14 13 14Z"
        fill={expand ? '#fff' : 'url(#paint0_linear_323_5861)'}
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_323_5861"
          x1="13"
          y1="2"
          x2="13"
          y2="22"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor={expand ? '#fff' : '#9A4DFF'} />
          <Stop offset="1" stopColor={expand ? '#fff' : '#F600DD'} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
export function MoreSvg({ expand }) {
  return (
    <Svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M1.48055 0.0694523C0.790309 0.253245 0.251183 0.804623 0.0673907 1.50304C-0.0224636 1.8502 -0.0224636 5.99983 0.0673907 6.34699C0.251183 7.05357 0.794393 7.60087 1.49689 7.78466C1.85631 7.87451 5.99777 7.87451 6.35719 7.78466C7.05968 7.60087 7.60289 7.05357 7.78668 6.34699C7.87654 5.99983 7.87654 1.8502 7.78668 1.50304C7.60289 0.796454 7.05968 0.249161 6.35719 0.0653677C6.01002 -0.0244865 1.81546 -0.020402 1.48055 0.0694523Z"
        fill={expand ? '#fff' : 'url(#paint0_linear_323_5927)'}
      />
      <Path
        d="M10.6295 0.0694523C9.93923 0.253245 9.40011 0.804623 9.21632 1.50304C9.12646 1.8502 9.12646 5.99983 9.21632 6.34699C9.40011 7.05357 9.94332 7.60087 10.6458 7.78466C11.0052 7.87451 15.1467 7.87451 15.5061 7.78466C16.2086 7.60087 16.7518 7.05357 16.9356 6.34699C17.0255 5.99983 17.0255 1.8502 16.9356 1.50304C16.7518 0.796454 16.2086 0.249161 15.5061 0.0653677C15.1589 -0.0244865 10.9644 -0.020402 10.6295 0.0694523Z"
        fill={expand ? '#fff' : 'url(#paint1_linear_323_5927)'}
      />
      <Path
        d="M1.48055 9.21838C0.790309 9.40217 0.251183 9.95355 0.0673907 10.652C-0.0224636 10.9991 -0.0224636 15.1488 0.0673907 15.4959C0.251183 16.2025 0.794393 16.7498 1.49689 16.9336C1.85631 17.0234 5.99777 17.0234 6.35719 16.9336C7.05968 16.7498 7.60289 16.2025 7.78668 15.4959C7.87654 15.1488 7.87654 10.9991 7.78668 10.652C7.60289 9.94538 7.05968 9.39809 6.35719 9.21429C6.01002 9.12444 1.81546 9.12852 1.48055 9.21838Z"
        fill={expand ? '#fff' : 'url(#paint2_linear_323_5927)'}
      />
      <Path
        d="M12.8108 9.2099C12.6597 9.27933 12.5249 9.42228 12.4677 9.5734C12.4391 9.64692 12.4228 10.186 12.4228 11.0519V12.4161L10.9974 12.4283C9.62914 12.4406 9.56379 12.4447 9.4576 12.5263C9.24113 12.6856 9.17578 12.8163 9.17578 13.0736C9.17578 13.3309 9.24113 13.4616 9.4576 13.6209C9.56379 13.7026 9.62914 13.7067 10.9933 13.7189L12.4187 13.7312L12.431 15.1566C12.4432 16.5208 12.4473 16.5861 12.529 16.6923C12.6883 16.9088 12.819 16.9741 13.0763 16.9741C13.3336 16.9741 13.4643 16.9088 13.6236 16.6923C13.7052 16.5861 13.7093 16.5208 13.7216 15.1566L13.7338 13.7312L15.1593 13.7189C16.5234 13.7067 16.5888 13.7026 16.6949 13.6209C16.9114 13.4616 16.9768 13.3309 16.9768 13.0736C16.9768 12.8163 16.9114 12.6856 16.6949 12.5263C16.5888 12.4447 16.5234 12.4406 15.1593 12.4283L13.7338 12.4161L13.7216 10.9906C13.7093 9.6265 13.7052 9.56115 13.6236 9.45496C13.5786 9.39369 13.4929 9.30384 13.4357 9.263C13.2968 9.16089 12.9742 9.1323 12.8108 9.2099Z"
        fill={expand ? '#fff' : 'url(#paint3_linear_323_5927)'}
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_323_5927"
          x1="3.92704"
          y1="7.85205"
          x2="3.92704"
          y2="-1.57356e-05"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor={expand ? '#fff' : '#9A4DFF'} />
          <Stop offset="1" stopColor={expand ? '#fff' : '#F600DD'} />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_323_5927"
          x1="13.076"
          y1="7.85205"
          x2="13.076"
          y2="-1.57356e-05"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor={expand ? '#fff' : '#9A4DFF'} />
          <Stop offset="1" stopColor={expand ? '#fff' : '#F600DD'} />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_323_5927"
          x1="3.92704"
          y1="17.001"
          x2="3.92704"
          y2="9.14891"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor={expand ? '#fff' : '#9A4DFF'} />
          <Stop offset="1" stopColor={expand ? '#fff' : '#F600DD'} />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_323_5927"
          x1="13.0763"
          y1="16.9741"
          x2="13.0763"
          y2="9.16562"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor={expand ? '#fff' : '#9A4DFF'} />
          <Stop offset="1" stopColor={expand ? '#fff' : '#F600DD'} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
export function WalletSvg({ expand }) {
  return (
    <Svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1 5.004C19.045 5 18.984 5 18.92 5H16.395C14.327 5 12.558 6.628 12.558 8.75C12.558 10.872 14.328 12.5 16.395 12.5H18.92C18.984 12.5 19.045 12.5 19.102 12.496C19.527 12.4704 19.9282 12.2911 20.2309 11.9916C20.5335 11.6921 20.7169 11.2927 20.747 10.868C20.751 10.808 20.751 10.743 20.751 10.683V6.817C20.751 6.757 20.751 6.692 20.747 6.632C20.7169 6.20726 20.5335 5.80793 20.2309 5.50842C19.9282 5.2089 19.527 5.02963 19.102 5.004M16.174 9.75C16.706 9.75 17.137 9.302 17.137 8.75C17.137 8.198 16.706 7.75 16.174 7.75C15.641 7.75 15.21 8.198 15.21 8.75C15.21 9.302 15.641 9.75 16.174 9.75Z"
        fill={expand ? '#fff' : 'url(#paint0_linear_323_5895)'}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.918 14C18.9526 13.9986 18.987 14.0054 19.0184 14.0198C19.0499 14.0342 19.0775 14.0558 19.099 14.0829C19.1206 14.11 19.1354 14.1418 19.1424 14.1757C19.1493 14.2096 19.1481 14.2446 19.139 14.278C18.939 14.99 18.62 15.598 18.109 16.108C17.36 16.858 16.411 17.189 15.239 17.347C14.099 17.5 12.644 17.5 10.806 17.5H8.694C6.856 17.5 5.4 17.5 4.261 17.347C3.089 17.189 2.14 16.857 1.391 16.109C0.643 15.36 0.311 14.411 0.153 13.239C1.19209e-07 12.099 0 10.644 0 8.806V8.694C0 6.856 1.19209e-07 5.4 0.153 4.26C0.311 3.088 0.643 2.139 1.391 1.39C2.14 0.642 3.089 0.31 4.261 0.152C5.401 -4.47035e-08 6.856 0 8.694 0H10.806C12.644 0 14.1 1.19209e-07 15.239 0.153C16.411 0.311 17.36 0.643 18.109 1.391C18.62 1.903 18.939 2.51 19.139 3.222C19.1481 3.25537 19.1493 3.29042 19.1424 3.32432C19.1354 3.35822 19.1206 3.39 19.099 3.41708C19.0775 3.44417 19.0499 3.46579 19.0184 3.4802C18.987 3.4946 18.9526 3.50139 18.918 3.5H16.394C13.557 3.5 11.057 5.74 11.057 8.75C11.057 11.76 13.557 14 16.394 14H18.918ZM3.75 4C3.55109 4 3.36032 4.07902 3.21967 4.21967C3.07902 4.36032 3 4.55109 3 4.75C3 4.94891 3.07902 5.13968 3.21967 5.28033C3.36032 5.42098 3.55109 5.5 3.75 5.5H7.75C7.94891 5.5 8.13968 5.42098 8.28033 5.28033C8.42098 5.13968 8.5 4.94891 8.5 4.75C8.5 4.55109 8.42098 4.36032 8.28033 4.21967C8.13968 4.07902 7.94891 4 7.75 4H3.75Z"
        fill={expand ? '#fff' : 'url(#paint1_linear_323_5895)'}
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_323_5895"
          x1="16.6545"
          y1="5"
          x2="16.6545"
          y2="12.5"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor={expand ? '#fff' : '#9A4DFF'} />
          <Stop offset="1" stopColor={expand ? '#fff' : '#F600DD'} />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_323_5895"
          x1="9.57342"
          y1="0"
          x2="9.57342"
          y2="17.5"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor={expand ? '#fff' : '#9A4DFF'} />
          <Stop offset="1" stopColor={expand ? '#fff' : '#F600DD'} />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
