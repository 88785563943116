import React, { useEffect, useState } from 'react';
import { View, Text, Platform } from 'react-native';
import MapComponent from '../components/Map';
import * as Location from 'expo-location';

import FontAwsome from 'react-native-vector-icons/FontAwesome5';
import styles from '../styles';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { LinearGradient } from 'expo-linear-gradient';
import { defaultTheme } from '../themes/loot8';
import { BlurView } from 'expo-blur';
import { WebBlurView } from '../components/WebBlurView';
export const DISTANCE_IN_MILES = 5;
const HOTEL_ZIGGY = {
  id: '0',
  title: 'Hotel Ziggy',
  coordinate: { latitude: 34.0943304, longitude: -118.3770287 },
  description: '',
};
let LIVE_LOCATION = {
  id: '0',
  title: 'Home',
  coordinate: { latitude: 0, longitude: 0 },
  description: '',
};

function MapScreen() {
  const [currenLocation, setCurrenLocation] = useState(null);
  const [locationPermission, setLocationPermission] = useState(true);

  const setLocation = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      setCurrenLocation(HOTEL_ZIGGY);
      setLocationPermission(false);
      return;
    } else {
      try {
        let location = await Location.getLastKnownPositionAsync();
        if (location == null) {
          location = await Location.getCurrentPositionAsync();
        }
        if (location) {
          LIVE_LOCATION.coordinate = {
            ...LIVE_LOCATION?.coordinate,
            latitude: location?.coords?.latitude,
            longitude: location?.coords?.longitude,
          };
          setCurrenLocation(LIVE_LOCATION);
          setLocationPermission(true);
        } else {
          setLocationPermission(false);
        }
      } catch (e) {
        setLocationPermission(false);
        LogToLoot8Console('Error while trying to get location: ', e);
      }
    }
  };

  useEffect(() => {
    setLocation();
  }, []);

  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
      style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <View style={styles.mapContainer}>
        <MapComponent initialLocation={currenLocation} />
        {!locationPermission ? (
          <>
            {Platform.OS === 'ios' ? (
              <View
                style={[
                  styles.messageContainer,
                  styles.mapBackgroudBlackLayerContainer,
                ]}>
                <BlurView
                  tint="light"
                  intensity={60}
                  style={styles.mobileNoLocationContainer}>
                  <View
                    style={[
                      styles.mobileNoLocationSubContainer,
                      styles.maxWidthAdjust,
                    ]}>
                    {
                      <FontAwsome
                        name="map-marked-alt"
                        size={40}
                        color={defaultTheme.BORDER_COLOR_PRIMARY}
                      />
                    }
                    <View
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Text style={styles.messageCaption}>
                        Hey!! This is a default location.
                      </Text>
                      <Text style={styles.messageCaption}>
                        Please check if location access is enabled.
                      </Text>
                      <Text style={styles.messageCaption}>
                        There is a chance that a passport with
                      </Text>
                      <Text style={styles.messageCaption}>
                        awesome offers is just near you.
                      </Text>
                    </View>
                  </View>
                </BlurView>
              </View>
            ) : Platform.OS === 'android' ? (
              <View style={styles.mapBackgroudBlackLayerContainer}>
                <View
                  style={{
                    ...styles.mobileNoLocationContainer,
                    backgroundColor: 'rgba(169, 175, 179,.97)',
                  }}>
                  <View
                    style={[
                      styles.mobileNoLocationSubContainer,
                      styles.maxWidthAdjust,
                    ]}>
                    {
                      <FontAwsome
                        name="map-marked-alt"
                        size={40}
                        color={defaultTheme.BORDER_COLOR_PRIMARY}
                      />
                    }
                    <View style={styles.mapLocationOffTextContainer}>
                      <Text style={styles.messageCaption}>
                        Hey!! This is a default location. Please check if
                        location access is enabled. There is a chance that a
                        passport with awesome offers is just near you.
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            ) : (
              <View style={styles.mapBackgroudBlackLayerContainer}>
                <View style={styles.mapBlurPopupContainer}>
                  <WebBlurView>
                    <View style={styles.MapLocationOffContainer}>
                      {
                        <FontAwsome
                          name="map-marked-alt"
                          size={40}
                          color={defaultTheme.BORDER_COLOR_PRIMARY}
                        />
                      }
                      <View style={styles.mapLocationOffTextContainer}>
                        <Text style={styles.messageCaption}>
                          Hey!! This is a default location. Please check if
                          location access is enabled. There is a chance that a
                          passport with awesome offers is just near you.
                        </Text>
                      </View>
                    </View>
                  </WebBlurView>
                </View>
              </View>
            )}
          </>
        ) : (
          <></>
        )}
      </View>
    </LinearGradient>
  );
}

export default MapScreen;
