import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import ChatNavigator from './ChatNavigator';
import BottomTabNavigator from './BottomTabNavigator';
import SuccessScreenNavigator from './SuccessScreenNavigator';
import QRNavigator from './QRNavigator';
import DeactivateUserNavigator from './DeactivateUserNavigator';

const Stack = createStackNavigator();

function MainAppNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="BottomTab"
      screenOptions={{ headerShown: false }}>
      <Stack.Screen name="BottomTab" component={BottomTabNavigator} />
      <Stack.Screen name="Chat" component={ChatNavigator} />
      <Stack.Screen name="Success" component={SuccessScreenNavigator} />
      <Stack.Screen name="deactivate" component={DeactivateUserNavigator} />
      <Stack.Screen name="QR" component={QRNavigator} />
    </Stack.Navigator>
  );
}

export default MainAppNavigator;
