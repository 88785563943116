import React, { useEffect, useState } from 'react';
import {
  Text,
  View,
  Dimensions,
  Pressable,
  Image,
  Platform,
  ScrollView,
  ActivityIndicator,
  useWindowDimensions,
} from 'react-native';
import styles from '../../../styles';
import { LinearGradient } from 'expo-linear-gradient';
import { defaultTheme } from '../../../themes/loot8';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { CatalogTypes } from '../../../enums/catalog.enum';
import WebView from 'react-native-webview';
import CachedImage from '../../CachedImage';
import { ResizeMode, Video } from 'expo-av';
import { Avatar, IconButton, List } from 'react-native-paper';
import { getTruncatedName, webViewHelper } from '../../../helpers/Gadgets';
import EightIcon from '../../../assets/eightIcon.png';
import usdcIcon from '../../../assets/usdcLogo.png';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { ScreenName } from '../../../enums/screen.enum';
import { CollectionType } from '../../../enums/collection.enum';
import { useAppSelector } from '../../../hooks';
import { isNativeChain } from '../../../appconstants';
import { RESPONSIVE, RESPONSIVE_WIDTH } from '../../../appconstants';

const ASPECT_RATIO = 1.3 / 1;

const MPCollectibleOwnerItems = ({ item }) => {
  const { width } = useWindowDimensions();
  const isResponsive =
    RESPONSIVE == 'true' && width > parseInt(RESPONSIVE_WIDTH);

  return (
    <View style={{ width: '100%', marginTop: 10 }}>
      <View style={styles.contentDetailContainer}>
        <Text style={[styles.ownerNameText, isResponsive && { fontSize: 16 }]}>
          Owner
        </Text>
        <View style={{ flexDirection: 'row' }}>
          {item?.sellerData?.name ? (
            <>
              <Avatar.Image
                style={styles.collectibleOwnerProfileAvatar}
                size={20}
                source={
                  item?.sellerData?.avatarURI == '' ||
                  item?.sellerData?.avatarURI == 'no-avatar' ||
                  (item?.sellerData?.avatarURI &&
                    (item?.sellerData?.avatarURI.includes('ipfs://') ||
                      item?.sellerData?.avatarURI.includes(
                        'assets_avatars_p_',
                      ) ||
                      item?.sellerData?.avatarURI.includes('file:///')))
                    ? require('../../../assets/loot8-default-avatar.png')
                    : { uri: item?.sellerData?.avatarURI }
                }
              />
              <Text
                style={[
                  styles.contentDetailText,
                  isResponsive && { fontSize: 14 },
                ]}>
                {getTruncatedName(item?.sellerData?.name)}
              </Text>
            </>
          ) : (
            <>
              <ActivityIndicator
                size="small"
                color={defaultTheme.BUTTON_TERTIARY_BACKGROUND_COLOR}
              />
            </>
          )}
        </View>
      </View>
      <View style={styles.contentDetailContainer}>
        <Text style={[styles.ownerNameText, isResponsive && { fontSize: 16 }]}>
          Collection address
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={[
              styles.contentDetailText,
              isResponsive && { fontSize: 14 },
            ]}>
            {Dimensions.get('window').width > 480
              ? item?.collectionData?.address
              : item?.collectionData?.address.substring(0, 5) +
                '...' +
                item?.collectionData?.address.substring(
                  item?.collectionData?.address.length - 5,
                  item?.collectionData?.address.length,
                )}
          </Text>
        </View>
      </View>
      <View style={styles.contentDetailContainer}>
        <Text style={[styles.ownerNameText, isResponsive && { fontSize: 16 }]}>
          Price
        </Text>
        <View style={{ flexDirection: 'row' }}>
          {isNativeChain(item.chainId) ? (
            <Image
              source={EightIcon}
              resizeMode="contain"
              style={{ ...styles.contentUserImage, borderWidth: 0 }}
            />
          ) : (
            <Image
              source={usdcIcon}
              resizeMode="contain"
              style={{ ...styles.contentUserImage, borderWidth: 0 }}
            />
          )}
          <Text
            style={[
              styles.contentDetailText,
              isResponsive && { fontSize: 14 },
            ]}>
            {item?.price}
          </Text>
        </View>
      </View>
      <View style={styles.contentDetailContainer}>
        <Text style={[styles.ownerNameText, isResponsive && { fontSize: 16 }]}>
          Token ID
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={[
              styles.contentDetailText,
              isResponsive && { fontSize: 14 },
            ]}>
            {item?.tokenId}
          </Text>
        </View>
      </View>
    </View>
  );
};

// const MPCollectibleTraits = () => {
//     return(
//         <LinearGradient
//             colors={[defaultTheme.GRADIENT_COLOR1,defaultTheme.GRADIENT_COLOR2]}
//             start={[ 0, 0 ]}
//             end={[ 1, 1 ]}
//             style={styles.collectibleDetailTraitsCardContainer}
//             >
//                 <Text style={{fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD, fontSize: defaultTheme.FONT_SIZE_XXXSMALL, color:'#FFFFFF'}}>{"Beared"}</Text>
//                 <Text style={{fontFamily: defaultTheme.FONT_FAMILY_MAIN, fontSize: defaultTheme.FONT_SIZE_XXXSMALL, color:'#FFFFFF'}}>{"Muttonchops 3%"}</Text>
//                 <Text style={{fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD, fontSize: 8, color:'#FFFFFF'}}>{"Floor: 0.0122 ETH"}</Text>
//         </LinearGradient>
//     )
// }

const accordionTheme = {
  colors: { background: defaultTheme.ACCORDION_BACKGROUND_COLOR },
  roundness: 9,
};

const MPCollectibleDetails = ({ route, navigation }) => {
  const isWeb = Platform.OS === 'web';

  let pathConfig = null;

  if (isWeb) {
    const queryParams = new URLSearchParams(window.location.search);

    pathConfig = {
      path: window.location.pathname,
      queryParams: queryParams,
      getParam: query => {
        return queryParams.has(query) ? queryParams.get(query) : null;
      },
    };
  }

  let type =
    pathConfig && pathConfig.getParam('type')
      ? pathConfig.getParam('type')
      : route?.params?.type;

  const collectionItem =
    pathConfig &&
    type === 'Passports' &&
    pathConfig.getParam('id') &&
    pathConfig.getParam('chainId') &&
    pathConfig.getParam('tokenId') &&
    pathConfig.getParam('type') &&
    pathConfig.getParam('price') &&
    pathConfig.getParam('seller') &&
    pathConfig.getParam('collectionData') &&
    pathConfig.getParam('uniqueId') &&
    pathConfig.getParam('listingType') &&
    pathConfig.getParam('passport') &&
    pathConfig.getParam('collection')
      ? {
          id: +pathConfig.getParam('id'),
          chainId: +pathConfig.getParam('chainId'),
          tokenId: +pathConfig.getParam('tokenId'),
          type: pathConfig.getParam('type'),
          price: +pathConfig.getParam('price'),
          seller: pathConfig.getParam('seller'),
          collectionData: JSON.parse(pathConfig.getParam('collectionData')),
          unique_id: +pathConfig.getParam('uniqueId'),
          listingType: +pathConfig.getParam('listingType'),
          passport: pathConfig.getParam('passport'),
          collection: pathConfig.getParam('collection'),
        }
      : route?.params?.collection;

  const allUsers = useAppSelector(state => state.AppUser.AllUsersData);
  const [selectedCollection, setSelectedCollection] = useState(collectionItem?.collectionItem);
  const [descriptionExpanded, setDescriptionExpanded] = React.useState(false);
  const onDescriptionAccordianClick = () =>
    setDescriptionExpanded(!descriptionExpanded);

  const playbackInstance = React.useRef(null);
  const [playbackInstanceInfo, setPlaybackInstanceInfo] = React.useState({
    position: 0,
    duration: 0,
    state: 'Buffering',
    positionMillis: 0,
  });

  const togglePlay = async () => {
    const shouldPlay = playbackInstanceInfo.state !== 'Playing';
    if (playbackInstance.current !== null) {
      await playbackInstance.current.setStatusAsync({
        shouldPlay,
        ...(playbackInstanceInfo.state === 'Ended' && { positionMillis: 0 }),
      });
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        positionMillis:
          playbackInstanceInfo.state === 'Ended'
            ? 0
            : playbackInstanceInfo.positionMillis,
        state: playbackInstanceInfo.state === 'Playing' ? 'Paused' : 'Playing',
      });
    }
  };

  const stopVideo = async () => {
    if (playbackInstance.current) {
      playbackInstance.current.setStatusAsync({
        shouldPlay: false,
        isPlaying: false,
        state: 'Ended',
        didJustFinish: true,
        positionMillis: -1,
        durationMillis: 0,
      });
    }
  };

  const updatePlaybackCallback = status => {
    if (status.isLoaded) {
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        position: status.positionMillis,
        duration: status.durationMillis || 0,
        state: status.didJustFinish
          ? 'Ended'
          : status.isBuffering
            ? 'Buffering'
            : status.isPlaying
              ? 'Playing'
              : 'Paused',
      });
    } else {
      if (status.isLoaded === false && status.error) {
        const errorMsg = `Encountered a fatal error during playback: ${status.error}`;
      }
    }
  };

  const moveBack = () => {
    if (Platform.OS === 'web') {
      window.history.replaceState(null, '', '/');
    }
    navigation.goBack();
  };

  const onPressExplore = () => {
    navigation.navigate(ScreenName.MARKET_PLACE_BUY_COLLECTIBLE, {
      collection: selectedCollection,
      type:
        type == CatalogTypes.COLLECTIBLES
          ? CollectionType.COLLECTION
          : CollectionType.PASSPORT,
    });
  };

  const getSellerData = async () => {
    if (selectedCollection) {
      const ownerData = allUsers?.find(
        (user: any) =>
          user?.wallet?.toLowerCase() ==
          selectedCollection?.seller?.toLowerCase(),
      );
      if (ownerData) {
        const addSellerData = { ...selectedCollection, sellerData: ownerData };
        setSelectedCollection(addSellerData);
      } else {
        const sellerData = { ...selectedCollection, sellerData: null };
        setSelectedCollection(sellerData);
      }
    }
  };

  useEffect(() => {
    getSellerData();
  }, [allUsers]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      navigation.addListener('blur', () => {
        window.history.replaceState(null, '', '/');
      });
    }
  }, [navigation]);

  const { width } = useWindowDimensions();
  const isResponsive =
    RESPONSIVE == 'true' && width > parseInt(RESPONSIVE_WIDTH);

  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
      start={[0, 0]}
      end={[1, 1]}
      style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <View
        style={[
          styles.mainContainer,
          styles.maxWidthAdjust,
          isResponsive && { maxWidth: '80%' },
        ]}>
        <View
          style={[
            styles.contentContainer,
            styles.maxWidthAdjust,
            isResponsive && { maxWidth: '100%' },
          ]}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: 10,
            }}>
            <Pressable
              onPress={moveBack}
              style={{ flex: isResponsive ? 0.055 : 0.1 }}>
              <Ionicons
                name="arrow-back"
                size={30}
                color={defaultTheme.CONTENT_NAME_BACKGROUND_COLOR}
              />
            </Pressable>
            <View style={{ flex: 0.9, right: 10, paddingHorizontal: 5 }}>
              <Text style={[styles.contentHeaderText]}>
                {selectedCollection?.collectionData?.name}
              </Text>
            </View>
          </View>
        </View>
        <ScrollView
          style={[{ flex: 1 }, isResponsive && { marginTop: '3%' }]}
          showsVerticalScrollIndicator={false}>
          <View
            style={[
              { flexDirection: isResponsive ? 'row' : 'column' },
              isResponsive && { justifyContent: 'space-around' },
            ]}>
            <View
              style={[
                { backgroundColor: '#000', borderRadius: 6 },
                isResponsive && {
                  borderRadius: 40,
                  width: '55%',
                  maxHeight: '80%',
                  overflow: 'hidden',
                },
              ]}>
              {!selectedCollection?.collectionData?.isMp4Collectible &&
                (selectedCollection?.collectionData?.animationUrl ? (
                  <View style={{ flex: 1, aspectRatio: ASPECT_RATIO }}>
                    <WebView
                      nestedScrollEnabled={true}
                      containerStyle={{ marginTop: -25, zIndex: 998 }}
                      style={Platform.OS == 'web' && { marginTop: -25 }}
                      source={{
                        uri: selectedCollection?.collectionData?.animationUrl,
                      }}
                      javaScriptEnabledAndroid={true}
                      onMessage={event => {
                        const data = event?.nativeEvent?.data;
                        if (
                          data &&
                          data.indexOf &&
                          data.indexOf('loot8') > -1
                        ) {
                          webViewHelper(event.nativeEvent.data);
                        }
                      }}
                      androidLayerType={'software'}
                    />
                  </View>
                ) : (
                  <CachedImage
                    isBackground
                    imageSize={selectedCollection?.collectionData?.imageSize}
                    source={{ uri: selectedCollection?.collectionData?.image }}
                    noImageFile={'no-image-text.png'}
                    style={{ width: '100%', aspectRatio: ASPECT_RATIO }}
                    contentFit="contain"></CachedImage>
                ))}
              {selectedCollection?.collectionData?.isMp4Collectible && (
                <View style={{ flex: 1 }}>
                  <View style={{ width: '100%', aspectRatio: 4 / 3 }}>
                    <Video
                      ref={playbackInstance}
                      style={{
                        alignSelf: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                      source={{
                        uri: selectedCollection?.collectionData?.video,
                      }}
                      //useNativeControls
                      resizeMode={ResizeMode.CONTAIN}
                      onPlaybackStatusUpdate={updatePlaybackCallback}
                      videoStyle={{ position: 'relative', flex: 1, padding: 5 }}
                    />
                  </View>

                  <View style={{ height: 50 }}>
                    <View
                      style={{
                        alignContent: 'center',
                        zIndex: 6,
                        flex: 1,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        backgroundColor: '#000000',
                        opacity: 0.8,
                      }}>
                      <IconButton
                        icon={
                          playbackInstanceInfo.state === 'Playing'
                            ? 'pause'
                            : 'play'
                        }
                        size={30}
                        style={{ margin: -7 }}
                        iconColor={'#FFFFFF'}
                        onPress={() => {
                          togglePlay();
                        }}
                      />
                      <IconButton
                        icon="stop"
                        size={30}
                        iconColor={'#FFFFFF'}
                        style={{ margin: -7 }}
                        onPress={() => {
                          stopVideo();
                        }}
                      />
                    </View>
                  </View>
                </View>
              )}
            </View>

            <View
              style={[
                isResponsive && {
                  width: '40%',
                  justifyContent: 'space-between',
                  maxHeight: '80%',
                },
              ]}>
              <View>
                {/* owner details   */}
                <MPCollectibleOwnerItems item={selectedCollection} />

                {/* description */}
                <View style={{ marginBottom: 10 }}>
                  <List.Accordion
                    title={''}
                    expanded={isResponsive ? true : descriptionExpanded}
                    theme={accordionTheme}
                    style={styles.collectibleDetailAccordion}
                    left={props => (
                      <Text style={styles.collectibleDetailAccordionCaption}>
                        {type == CatalogTypes.COLLECTIBLES
                          ? 'Description'
                          : 'ExPass Description'}
                      </Text>
                    )}
                    right={props =>
                      descriptionExpanded ? (
                        <MaterialCommunityIcons
                          name="chevron-up"
                          size={20}
                          color="#FFFFFF"
                          style={{ justifyContent: 'center' }}
                        />
                      ) : (
                        <MaterialCommunityIcons
                          name="chevron-down"
                          size={20}
                          color="#FFFFFF"
                          style={{ justifyContent: 'center' }}
                        />
                      )
                    }
                    onPress={onDescriptionAccordianClick}>
                    <View
                      style={styles.collectibleDetailAccordionContentContainer}>
                      <Text style={styles.collectibleDetailAccordionContent}>
                        {selectedCollection?.collectionData?.details}
                      </Text>
                    </View>
                  </List.Accordion>
                </View>
              </View>

              {/* traits */}
              {/* <View style={{marginBottom:10}}>
                                <List.Accordion
                                title={''}
                                expanded={traitsExpanded}                  
                                theme={accordionTheme}
                                style={styles.collectibleDetailAccordion}
                                left={props => <Text style={styles.collectibleDetailAccordionCaption}>{'Traits'}</Text>}
                                right={props => traitsExpanded ? <MaterialCommunityIcons name="chevron-up" size={20} color="#FFFFFF" style={{justifyContent:'center'}}/> 
                                        : <MaterialCommunityIcons name="chevron-down" size={20} color="#FFFFFF" style={{justifyContent:'center'}} />}
                                onPress={onTraitsAccordianClick}>
                                    <View style={styles.collectibleDetailAccordionContentContainer}>
                                        <MPCollectibleTraits />
                                        <MPCollectibleTraits />                                 
                                    </View>
                                </List.Accordion>
                            </View> */}

              {/* purchase button */}
              <Pressable
                onPress={() => onPressExplore()}
                style={{
                  ...styles.splashExploreButton,
                  marginTop: 10,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text
                  style={{
                    ...styles.splashExploreButtonText,
                    color: '#000',
                    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
                  }}>
                  {type == CatalogTypes.COLLECTIBLES
                    ? 'Buy Collectible'
                    : 'Buy ExPass'}
                </Text>
              </Pressable>
            </View>
          </View>
        </ScrollView>
      </View>
    </LinearGradient>
  );
};

export default MPCollectibleDetails;
