import { BlurView } from 'expo-blur';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, View } from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetManager,
  SheetProps,
} from 'react-native-actions-sheet';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import { ReShareWebBlurView } from '../WebBlurView';
import { LinearGradient } from 'expo-linear-gradient';
import ExpassThreeDotSettingsModalContent from '../ExpassThreeDotSettingsModalContent';

const ExpassThreeDotSettingsSheet = (props: SheetProps) => {
  const [isListed, setIsListed] = useState(false);
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const { navigation } = props.payload;
  const selectedEXPass = props.payload.selectedEXPass;
  const setSearchPassportText = props.payload.setSearchPassportText;
  const onListForSalePressed = props.payload.onListForSalePressed;
  const type = props.payload.type;
  const transferSuccessNavigation = props.payload.transferSuccessNavigation;
  const updateStateDataFunc = props.payload.updateStateDataFunc;

  useEffect(() => {
    if (selectedEXPass) {
      if (type === 'Expass') {
        const checkIfListed =
          selectedEXPass &&
          selectedEXPass?.collectibleIds.length > 0 &&
          selectedEXPass?.marketPlaceConfig?.listingIndex &&
          selectedEXPass?.marketPlaceConfig?.listingIndex?.findIndex(
            obj => obj.tokenId === Number(selectedEXPass?.collectibleIds[0]),
          ) !== -1
            ? true
            : false;
        setIsListed(checkIfListed);
      } else {
        const checkIfListed =
          selectedEXPass &&
          selectedEXPass?.marketPlaceConfig?.listingIndex &&
          selectedEXPass?.marketPlaceConfig?.listingIndex?.findIndex(
            obj => obj.tokenId === Number(selectedEXPass.collectibleId),
          ) !== -1;
        setIsListed(checkIfListed);
      }
    }
  }, [selectedEXPass]);

  const onSellPassportClick = async itemFromParams => {
    actionSheetRef?.current?.hide();
    setTimeout(async () => {
      await onListForSalePressed(itemFromParams);
    }, 700);
  };

  const onTransferPress = async itemFromParams => {
    actionSheetRef?.current?.hide();
    setTimeout(async () => {
      await SheetManager.show('TransferModalSheet', {
        payload: {
          itemObject: itemFromParams,
          navigation,
          transferSuccessNavigation: transferSuccessNavigation,
          updateStateDataFunc: updateStateDataFunc,
        },
      });
    }, 500);
  };
  return (
    <ActionSheet
      id={props.sheetId}
      ref={actionSheetRef}
      drawUnderStatusBar
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      enableGesturesInScrollView={false}>
      {Platform.OS === 'web' ? (
        <ReShareWebBlurView>
          <View
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <ExpassThreeDotSettingsModalContent
                isPassportListed={isListed}
                selectedEXPass={selectedEXPass}
                hideThreeDotSettingsModal={() => {
                  actionSheetRef?.current?.hide();
                }}
                setSearchPassportText={setSearchPassportText}
                navigation={navigation}
                onSellPassportClick={() => onSellPassportClick(selectedEXPass)}
                type={type}
                onTransferPress={() => {
                  onTransferPress(selectedEXPass);
                }}
              />
            </LinearGradient>
          </View>
        </ReShareWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={{
            borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <ExpassThreeDotSettingsModalContent
              isPassportListed={isListed}
              selectedEXPass={selectedEXPass}
              hideThreeDotSettingsModal={() => {
                actionSheetRef?.current?.hide();
              }}
              setSearchPassportText={setSearchPassportText}
              navigation={navigation}
              onSellPassportClick={() => onSellPassportClick(selectedEXPass)}
              type={type}
              onTransferPress={() => {
                onTransferPress(selectedEXPass);
              }}
            />
          </LinearGradient>
        </BlurView>
      )}
    </ActionSheet>
  );
};

export default ExpassThreeDotSettingsSheet;
