/*
    Location Helper
*/

import { getAppConfiguration } from '../appconstants';
import { LogToLoot8Console } from './Loot8ConsoleLogger';

let currentLocation: { latitude: number; longitude: number } = null;

const meterToMileConversionRate = 1609.34;
const meterToFeetConversionRate = 3.28084;

// get degree to radian
const getDegreeToRadian = (degree: number) => {
  return (degree * Math.PI) / 180;
};

// calculate distance(Miles/Kilometers) between two points
export const getLocationDistance = (
  fromLat: number,
  fromLon: number,
  toLat: number,
  toLon: number,
  inMiles: boolean = true,
) => {
  let fromLat1 = getDegreeToRadian(fromLat);
  let fromLong1 = getDegreeToRadian(fromLon);
  let toLat1 = getDegreeToRadian(toLat);
  let toLong1 = getDegreeToRadian(toLon);

  let distanceLan = toLat1 - fromLat1;
  let distanceLon = toLong1 - fromLong1;

  let temp =
    Math.pow(Math.sin(distanceLan / 2), 2) +
    Math.cos(fromLat1) * Math.cos(toLat1) * Math.pow(Math.sin(distanceLon / 2), 2);

  let calculate = 2 * Math.asin(Math.sqrt(temp));

  /*
        Radius for earth
        For Miles - 3956
        For Kilometers - 6371
    */
  let radius = 3956;
  if (!inMiles) {
    radius = 6371;
  }

  // get the distance
  return calculate * radius;
};

// convert meter value to miles.
export const getMeterToMile = (meter: number) => {
  if (meter) {
    return `${(meter / meterToMileConversionRate).toFixed(3)} miles`;
  }
};

// convert meter value to feet.
export const getMeterToFeet = (meter: number) => {
  if (meter) {
    return `${(meter * meterToFeetConversionRate).toFixed(2)} ft`;
  }
};

export const needLocationChangeTrigger = async (latitude: number, longitude: number): Promise<boolean> => {
  let trigger = true;
  if (currentLocation) {
    let appConfig;
    try {
      appConfig = await getAppConfiguration();
    } catch (err) {
      LogToLoot8Console('needLocationChangeTrigger: Error while reading app config');
    }
    if (appConfig && appConfig.radiusForLocationChange) {
      let distanceKM = getLocationDistance(
        currentLocation.latitude,
        currentLocation.longitude,
        latitude,
        longitude,
        false,
      );
      trigger = distanceKM * 1000 > appConfig.radiusForLocationChange ? true : false;
    } else {
      trigger = true;
    }
  }

  currentLocation = { latitude, longitude };
  return trigger;
};

export const forceNeedLocationChangeTrigger = () => {
  currentLocation = null;
};
