import {
  LayoutChangeEvent,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { useState } from 'react';
import { defaultTheme } from '../../themes/loot8';
import { formatTime, getTimeTextFromTimestamp } from '../../helpers/DateHelper';
import styles from '../../styles';
import { Image } from 'react-native';
import ImageInMessage from '../ImageInMessage';
import CreateURLs from '../CreateURLs';
import URLPreview from '../URLPreview';
import { FontAwesome5 } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import {
  default as Icons,
  default as MaterialCommunityIcons,
} from 'react-native-vector-icons/MaterialCommunityIcons';
import more_dots from '../../assets/ellipsis-2x.png';

const MessageBubble = ({
  message,
  address,
  friendsPublicKey,
  index,
  allMessages,
  selectedMsg,
  setSelectedMsg,
  hovered,
  setHovered,
  setShowOptions,
  layoutData,
  highLight,
  scrollToMessage,
  outerIndex,
  user,
  isUserMutualFriend,
  getRepliedToText,
}) => {
  const [urlPreview, seturlPreview] = useState(true);

  const onUrlPreview = val => {
    seturlPreview(val);
  };

  const handleLayout = (event: LayoutChangeEvent, id) => {
    const { y } = event.nativeEvent.layout;
    let item: any = { messageId: id, itemYCoordinate: y };

    // check if message is present
    const msgIndex = layoutData.messageItem?.findIndex(
      obj => obj.messageId === id,
    );

    if (msgIndex !== null && msgIndex !== -1) {
      layoutData.messageItem[msgIndex].itemYCoordinate = y;
    } else {
      layoutData.messageItem.push(item);
    }
  };

  const handleLongPressItem = () => {
    if (isUserMutualFriend) {
      setShowOptions(true);
      setSelectedMsg(message);
    }
  };

  const bubbleStyle =
    message?.data?.sender === user?.wallet
      ? { alignSelf: 'flex-end' }
      : { alignSelf: 'flex-start' };

  const highLightedMsg = ((selectedMsg &&
    selectedMsg.messageId === message.messageId) ||
    (highLight && highLight === message.messageId)) && {
    backgroundColor: '#7E2279',
  };
  return (
    <>
      <Pressable
        onLayout={event => handleLayout(event, message.messageId)}
        onHoverIn={() => setHovered(message.messageId)}
        onHoverOut={() => {
          setHovered(null);
        }}
        style={[bubbleStyle, highLightedMsg, componentStyles.container]}
        key={index}
        onLongPress={() => handleLongPressItem()}
        delayLongPress={500}>
        <>
          <LinearGradient
            colors={
              message?.data?.sender === user?.wallet
                ? [defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]
                : [defaultTheme.SECONDARY_COLOR, defaultTheme.SECONDARY_COLOR]
            }
            end={{ x: 0.0001, y: 0.2 }}
            start={{ x: 0.00001, y: 0.8 }}
            style={{
              borderRadius: 30,
              alignItems: 'flex-start',
              flexDirection:
                message?.data?.sender === user?.wallet ? 'row' : 'row-reverse',
            }}>
            {message &&
              message.data &&
              message.data?.data.content?.text &&
              friendsPublicKey && (
                <View
                  style={{
                    paddingVertical: 10,
                    paddingHorizontal: 12,
                    alignItems:
                      message?.data?.sender === user?.wallet
                        ? 'flex-end'
                        : 'flex-start',
                  }}>
                  {message &&
                  message?.data &&
                  message.data?.parent &&
                  message?.parentData &&
                  message.data?.data?._type === 'quote' ? (
                    <>
                      {/* Quoted message Text */}
                      <View style={componentStyles.reshareCard}>
                        <Pressable
                          onPress={() => scrollToMessage(message.data.parent)}
                          onLongPress={() => handleLongPressItem()}
                          delayLongPress={500}>
                          <View style={componentStyles.row}>
                            <View style={componentStyles.row}>
                              <FontAwesome5
                                name="reply"
                                size={10}
                                color={defaultTheme.SECONDARY_TEXT_COLOR}
                              />
                              <Text
                                style={componentStyles.repliedToText}
                                numberOfLines={1}
                                ellipsizeMode="tail">
                                {getRepliedToText(
                                  message?.parentData?.data,
                                  message.data,
                                  address,
                                  user,
                                )}
                              </Text>
                            </View>
                            {message?.parentData?.data?.data?.attachments &&
                              message?.parentData?.data?.data?.attachments
                                ?.uri !== '' && (
                                <Icons
                                  name="image"
                                  color={'grey'}
                                  size={30}
                                  style={{
                                    flex: 0.1,
                                    height: 30,
                                    width: 30,
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                />
                              )}
                          </View>
                          <Text
                            numberOfLines={1}
                            ellipsizeMode="tail"
                            style={componentStyles.msgText}>
                            {
                              message?.parentData?.data?.data?.content
                                .decryptedText
                            }
                          </Text>
                        </Pressable>
                      </View>
                      <Text style={componentStyles.msgText}>
                        {message.data?.data.content?.decryptedText}
                      </Text>
                      {message.data?.data?.attachments &&
                        message.data?.data?.attachments?.uri !== '' && (
                          <View style={componentStyles.msgImg}>
                            <ImageInMessage
                              uri={message.data?.data?.attachments?.uri}
                              decryptImage={true}
                              friendsPublicKey={friendsPublicKey}
                              handleLongPressItem={handleLongPressItem}
                            />
                          </View>
                        )}
                    </>
                  ) : message?.data?.data?._type === 'reshare' &&
                    message?.data?.parent &&
                    message?.parentData ? (
                    <View>
                      {/* Reshare passport Message */}
                      {message.data?.data.content?.decryptedText !== '' && (
                        <>
                          {message?.data?.data?.content?.messageURLs &&
                          message?.data?.data?.content?.messageURLs?.length >
                            0 ? (
                            <Text style={componentStyles.msgText}>
                              <CreateURLs
                                message={
                                  message?.data?.data?.content?.decryptedText
                                }
                                messageId={message.messageId}
                              />
                            </Text>
                          ) : (
                            <Text style={componentStyles.msgText}>
                              {message.data?.data?.content?.decryptedText}
                            </Text>
                          )}
                        </>
                      )}
                      <Pressable
                        onLongPress={() => handleLongPressItem()}
                        delayLongPress={500}>
                        <View style={componentStyles.reshareCard}>
                          {/* other content */}
                          <View style={componentStyles.row}>
                            <Text style={componentStyles.reshareCardTitle}>
                              {message?.parentData?.data?.sender?.toLowerCase() ==
                              address?.toLowerCase()
                                ? 'You'
                                : message?.parentData?.data?.name.trim()}
                              <Text style={componentStyles.userIdText}>
                                {` #${message?.parentData?.data?.id}`}
                              </Text>
                            </Text>
                            <Text
                              style={[
                                componentStyles.msgText,
                                { marginLeft: 'auto' },
                              ]}>
                              {getTimeTextFromTimestamp(
                                message?.parentData?.timestamp,
                              )}
                            </Text>
                          </View>
                          {message?.parentData?.data?.data?.content
                            ?.messageURLs &&
                          message?.parentData?.data?.data?.content?.messageURLs
                            ?.length > 0 ? (
                            <Text
                              style={{
                                ...componentStyles.msgText,
                                marginBottom: 10,
                              }}>
                              <CreateURLs
                                message={
                                  message?.parentData?.data?.data?.content.text
                                }
                                messageId={message.messageId}
                              />
                            </Text>
                          ) : (
                            <Text
                              style={{
                                ...componentStyles.msgText,
                                marginBottom: 10,
                              }}>
                              {message?.parentData?.data?.data?.content?.text}
                            </Text>
                          )}
                          {urlPreview &&
                            message?.parentData?.data.data.content?.messageURLs
                              .filter((x, i) => i == 0)
                              .map((url, index) => {
                                return (
                                  <View
                                    key={index}
                                    style={{
                                      width: '100%',
                                      minHeight: 100,
                                      marginTop: 5,
                                    }}>
                                    <URLPreview
                                      url={url}
                                      message={
                                        message?.parentData?.data?.data?.content
                                          .text
                                      }
                                      smallPreviewCard={true}
                                      hasURLPreview={onUrlPreview}>
                                      {' '}
                                    </URLPreview>
                                  </View>
                                );
                              })}
                          {message?.parentData?.data?.data?.attachments &&
                            message?.parentData?.data?.data?.attachments
                              ?.uri !== '' && (
                              <View style={styles.imageInMessageContainer}>
                                <ImageInMessage
                                  uri={
                                    message?.parentData?.data?.data?.attachments
                                      ?.uri
                                  }
                                  handleLongPressItem={handleLongPressItem}
                                />
                              </View>
                            )}
                        </View>
                      </Pressable>
                      <Text style={componentStyles.reshareCardText}>
                        {`Shared from ${message?.parentData?.data?.type} `}
                        <Text
                          style={{
                            fontFamily: defaultTheme.FONT_FAMILY_BOLD,
                          }}>
                          {message?.parentData?.data?.passportName}
                        </Text>
                      </Text>
                    </View>
                  ) : //  Deleted quoted message Text
                  message?.data?.data?._type === 'quote' &&
                    message?.data?.parent &&
                    message?.parentData ? (
                    <View
                      style={{
                        backgroundColor: '#D053BF',
                        padding: 5,
                        borderRadius: 6,
                        borderLeftColor: '#D7E2EF',
                        borderLeftWidth: 3,
                        maxWidth: '90%',
                        marginVertical: 3,
                      }}>
                      <Pressable
                        onPress={() => scrollToMessage(message.data.parent)}
                        onLongPress={() => handleLongPressItem()}
                        delayLongPress={500}>
                        <View
                          style={{
                            backgroundColor: '#e9e9e9',
                            paddingLeft: 4,
                            borderRadius: 4,
                            marginBottom: 3,
                            minHeight: 20,
                            justifyContent: 'center',
                          }}>
                          <Text
                            numberOfLines={2}
                            ellipsizeMode="tail"
                            style={{ ...styles.directMessageItemMessage }}>
                            {'This message has been deleted'}
                          </Text>
                        </View>
                      </Pressable>
                      <Text style={styles.directMessageItemMessage}>
                        {message?.data?.data.content?.decryptedText}
                      </Text>
                    </View>
                  ) : (
                    <>
                      {/* Simple message Text */}
                      {message.data?.data.content?.messageURLs &&
                      message.data?.data.content?.messageURLs?.length > 0 ? (
                        <Text style={componentStyles.msgText}>
                          <CreateURLs
                            message={message.data?.data.content?.decryptedText}
                            messageId={message.messageId}
                          />
                        </Text>
                      ) : (
                        <Text style={componentStyles.msgText}>
                          {message.data?.data.content?.decryptedText}
                        </Text>
                      )}
                      {urlPreview &&
                        message.data?.data.content?.messageURLs &&
                        message.data?.data.content?.messageURLs?.length > 0 && (
                          <View style={{ minHeight: 100 }}>
                            {message.data?.data.content?.messageURLs
                              ?.filter((x, i) => i == 0)
                              .map((url, index) => {
                                return (
                                  <URLPreview
                                    key={index}
                                    url={url}
                                    message={
                                      message.data?.data.content?.decryptedText
                                    }
                                    smallPreviewCard={true}
                                    hasURLPreview={onUrlPreview}
                                  />
                                );
                              })}
                          </View>
                        )}
                      {/* Image Attachment Component */}
                      {message.data?.data?.attachments &&
                        message.data?.data?.attachments?.uri !== '' && (
                          <View style={componentStyles.msgImg}>
                            <ImageInMessage
                              uri={message.data?.data?.attachments?.uri}
                              decryptImage={true}
                              friendsPublicKey={friendsPublicKey}
                              handleLongPressItem={handleLongPressItem}
                            />
                          </View>
                        )}
                    </>
                  )}
                </View>
              )}
            {Platform.OS === 'web' &&
              hovered &&
              hovered === message.messageId &&
              isUserMutualFriend && (
                <TouchableOpacity
                  hitSlop={{ bottom: 20, left: 20, right: 20, top: 20 }}
                  style={{
                    width: 18,
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginHorizontal: 5,
                  }}
                  onPress={() => handleLongPressItem()}>
                  <Image source={more_dots} style={{ width: 15, height: 5 }} />
                </TouchableOpacity>
              )}
          </LinearGradient>
        </>
      </Pressable>
      <Text
        style={[
          componentStyles.timestamp,
          {
            textAlign:
              message?.data?.sender === user?.wallet ? 'right' : 'left',
          },
        ]}>
        {formatTime(message?.timestamp)}
      </Text>
    </>
  );
};

export default MessageBubble;

const componentStyles = StyleSheet.create({
  container: {
    borderRadius: 30,
    marginVertical: 5,
    maxWidth: '75%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  timestamp: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    marginHorizontal: 7.5,
  },
  msgText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  msgImg: {
    minHeight: 150,
    marginTop: 12,
  },
  reshareCard: {
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    padding: 10,
    borderRadius: 10,
    marginBottom: 5,
  },
  reshareCardText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  reshareCardTitle: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  userIdText: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
  repliedToText: {
    marginLeft: 3,
    fontSize: defaultTheme.FONT_SIZE_11,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
  },
});
