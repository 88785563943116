import React from 'react';
import { ImageBackground, StatusBar, Text, View, Image } from 'react-native';
import background from '../../assets/backgroundImage.png';
import logoImg from '../../assets/images/loot8Logo.png';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';

const Maintenance = ({ maintenanceText }) => {
  return (
    <>
      <StatusBar
        barStyle="light-content"
        backgroundColor="transparent"
        translucent
      />

      <ImageBackground
        source={background}
        style={{ height: '100%', width: '100%', overflow: 'hidden' }}
        resizeMode="cover">
        <View style={styles.loadingContainer}>
          <View style={{ margin: 0 }}>
            <Image
              source={logoImg}
              style={{ width: 200, height: 150 }}
              resizeMode="contain"
            />
          </View>

          <View style={{ padding: 20 }}>
            <Text
              style={{
                color: 'white',
                textAlign: 'center',
                fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
                fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
              }}>
              {maintenanceText}
            </Text>
          </View>
        </View>
      </ImageBackground>
    </>
  );
};

export default Maintenance;
