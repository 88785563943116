import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { defaultTheme } from '../../themes/loot8';
import { TouchableRipple } from 'react-native-paper';
import GradientButton from '../GradientButton';

interface ExpassListPopupConfimationProps {
  title: string;
  customConfirmText: string;
  confirmButtonOnPress: () => void;
  cancelButtonOnPress: () => void;
  listed: boolean;
  alternateConfirmButton?: boolean;
}
const ExpassListPopupConfimation: React.FC<ExpassListPopupConfimationProps> = ({
  title,
  confirmButtonOnPress,
  cancelButtonOnPress,
  listed,
  alternateConfirmButton = false,
  customConfirmText,
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.separator} />
      <View style={styles.row}>
        <ConfirmButton onPress={cancelButtonOnPress} title={'No'} alternate />
        <ConfirmButton
          alternateConfirmButton={alternateConfirmButton}
          onPress={confirmButtonOnPress}
          title={
            customConfirmText
              ? customConfirmText
              : listed
                ? 'Yes, unlist'
                : 'Yes, List'
          }
        />
      </View>
    </View>
  );
};

export default ExpassListPopupConfimation;

const styles = StyleSheet.create({
  container: { paddingHorizontal: 15, paddingVertical: 25 },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  btnText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_TEXT_COLOR,
    opacity: 0.6,
    marginTop: 15,
    marginBottom: 25,
  },
  buttonContainer: {
    flex: 0.48,
    height: 48,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightTextInputImg: {
    height: 30,
    width: 30,
    borderRadius: 30,
    overflow: 'hidden',
  },
});

const ConfirmButton = ({
  onPress,
  title,
  alternate,
  alternateConfirmButton,
}) => {
  return (
    <>
      {alternateConfirmButton ? (
        <View style={styles.buttonContainer}>
          <GradientButton onPress={onPress} buttonLabel={title} />
        </View>
      ) : (
        <View
          style={[
            styles.buttonContainer,
            {
              backgroundColor: alternate
                ? 'rgba(255,255,255,0.2)'
                : 'rgba(33, 29, 40,0.8)',
            },
          ]}>
          <TouchableRipple
            style={styles.ripple}
            rippleColor={'rgba(0,0,0,0.25)'}
            onPress={onPress}>
            <Text style={styles.btnText}>{title}</Text>
          </TouchableRipple>
        </View>
      )}
    </>
  );
};
