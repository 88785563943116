export enum PassportCategory {
  ORGANIZATION = 'Organization',
  PEOPLE = 'people',
}

export enum PassportType {
  REGULAR,
  SUBSCRIPTION,
  SUBSCRIBED,
}

export enum PassportSubscriptionActions {
  LISTORSELLEXPASS = 'ListOrSellEXPass',
  UNSUBSCRIBE = 'Unsubscribe',
  SUBSCRIBE = 'Subscribe',
}

export enum SocialLinkType {
  TELEGRAM = 'telegram',
  DISCORD = 'discord',
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  TIKTOK = 'tiktok',
}

export enum PassportListType {
  MyExPass = 1,
  AvailableExPass = 2,
}
