import {
  Text,
  View,
  Pressable,
  TouchableWithoutFeedback,
  useWindowDimensions,
} from 'react-native';
import React, { useState } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { defaultTheme } from '../themes/loot8';
import styles from '../styles';
import { Ionicons } from '@expo/vector-icons';
import { PublicMarketPlaceTabs } from '../enums/marketPlace.enum';
import MarketPlaceCollectibles from '../components/MarketPlace/MPCollectibles/Index';
import MarketPlaceExpasses from '../components/MarketPlace/MPExpasses';
import { ScreenName } from '../enums/screen.enum';
import { hideKeyboard } from '../slices/helpers';
import { RESPONSIVE, RESPONSIVE_WIDTH } from '../appconstants';

const MarketPlaceScreen = ({ route, navigation }) => {
  const [selectedPassportCategory, setSelectedPassportCategory] = route?.params
    ?.selectedPassportCategory
    ? useState(route.params.selectedPassportCategory)
    : useState(PublicMarketPlaceTabs.EXPASSES);
  const navigationFrom = route?.params?.navigationFrom;
  const selectedCatalog = route?.params?.selectedCatalog;
  const catalogType = route?.params?.catalogType;
  const availableCatalogs = route?.params?.availableCatalogs;
  const { width } = useWindowDimensions();
  // const windowWidth = width > 600 ? 600 : width;
  // const numOfColumns = 2;
  // const tileWidth = width > 600 ? (windowWidth/numOfColumns) - 30 : (windowWidth/numOfColumns) - 30;

  // const dispatch = useAppDispatch();

  const onSelectedTab = type => {
    setSelectedPassportCategory(type);
  };

  const moveBack = () => {
    if (navigationFrom === ScreenName.HOME_SCREEN) {
      navigation.navigate(ScreenName.HOME_TAB, {
        screen: ScreenName.HOME_SCREEN,
      });
    } else if (navigationFrom === ScreenName.CATALOG_TAB) {
      navigation.navigate(ScreenName.CATALOG_TAB, {
        screen: 'CatalogList',
        params: {
          availableCatalogs: availableCatalogs,
          type: catalogType,
        },
      });
    } else if (navigationFrom === ScreenName.CATALOG_DETAILS) {
      navigation.navigate(ScreenName.CATALOG_TAB, {
        screen: ScreenName.CATALOG_DETAILS,
        params: {
          catalog: selectedCatalog,
          availableCatalogs: availableCatalogs,
          type: catalogType,
          from: 'Catalog',
          updateParent: null,
        },
      });
    } else {
      navigation.goBack();
    }
  };

  const isResponsive =
    RESPONSIVE == 'true' && width > parseInt(RESPONSIVE_WIDTH);

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        hideKeyboard();
      }}>
      <LinearGradient
        colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
        style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
        <View
          style={[
            styles.mainContainer,
            styles.maxWidthAdjust,
            // ((RESPONSIVE != "true") || ((RESPONSIVE == "true") && (width < parseInt(RESPONSIVE_WIDTH)))) ? { overflow: 'scroll' } : {},
            { paddingTop: 0, maxWidth: isResponsive ? '80%' : 600 },
            isResponsive && { overflow: 'overlay', scrollbarWidth: 'none' },
          ]}>
          {/* Header Container */}
          <View
            style={{
              ...styles.contentHeader,
              justifyContent: 'center',
              height: isResponsive ? 60 : 70,
              width: '100%',
            }}>
            <Pressable
              onPress={() => moveBack()}
              style={[
                styles.headerBackButton,
                isResponsive && { marginLeft: 5 },
              ]}>
              <Ionicons
                name="arrow-back"
                size={30}
                color={defaultTheme.CONTENT_NAME_BACKGROUND_COLOR}
              />
            </Pressable>
            <View
              style={{
                width: isResponsive ? '96%' : '90%',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Text style={{ ...styles.profileTitleText, marginLeft: '-5%' }}>
                {'Public Marketplace'}
              </Text>
            </View>
          </View>

          {(RESPONSIVE != 'true' ||
            (RESPONSIVE == 'true' && width < parseInt(RESPONSIVE_WIDTH))) && (
            <View style={[styles.friendsTabsContainer]}>
              <Pressable
                style={[
                  styles.friendsTabsItems,
                  styles.offerTabsRightBorder,
                  selectedPassportCategory === PublicMarketPlaceTabs.EXPASSES
                    ? styles.friendsTabsActive
                    : styles.friendsTabsInActive,
                ]}
                onPress={() => onSelectedTab(PublicMarketPlaceTabs.EXPASSES)}>
                <View style={{ flexDirection: 'column' }}>
                  <Text
                    style={[
                      styles.passportTabsText,
                      selectedPassportCategory ===
                      PublicMarketPlaceTabs.EXPASSES
                        ? styles.passportTabsActiveText
                        : styles.passportTabsInActiveText,
                    ]}>
                    ExPasses
                  </Text>
                </View>
              </Pressable>
              <Pressable
                style={[
                  styles.friendsTabsItems,
                  styles.offerTabsRightBorder,
                  selectedPassportCategory ===
                  PublicMarketPlaceTabs.COLLECTIBLES
                    ? styles.friendsTabsActive
                    : styles.friendsTabsInActive,
                ]}
                onPress={() =>
                  onSelectedTab(PublicMarketPlaceTabs.COLLECTIBLES)
                }>
                <View style={{ flexDirection: 'column' }}>
                  <Text
                    style={[
                      styles.passportTabsText,
                      selectedPassportCategory ===
                      PublicMarketPlaceTabs.COLLECTIBLES
                        ? styles.passportTabsActiveText
                        : styles.passportTabsInActiveText,
                    ]}>
                    {PublicMarketPlaceTabs.COLLECTIBLES}
                  </Text>
                </View>
              </Pressable>
            </View>
          )}
          <View
            style={[
              { marginTop: 0 },
              (RESPONSIVE != 'true' ||
                (RESPONSIVE == 'true' &&
                  width < parseInt(RESPONSIVE_WIDTH))) && { flex: 1 },
            ]}>
            {RESPONSIVE != 'true' ||
            (RESPONSIVE == 'true' && width < parseInt(RESPONSIVE_WIDTH)) ? (
              selectedPassportCategory != PublicMarketPlaceTabs.COLLECTIBLES ? (
                <View
                  style={{
                    paddingTop:
                      (RESPONSIVE != 'true' &&
                        selectedPassportCategory !=
                          PublicMarketPlaceTabs.COLLECTIBLES) ||
                      (isResponsive &&
                        selectedPassportCategory !=
                          PublicMarketPlaceTabs.COLLECTIBLES)
                        ? 10
                        : 0,
                    flex: 1,
                  }}>
                  {RESPONSIVE != 'true' ||
                  (RESPONSIVE == 'true' &&
                    width < parseInt(RESPONSIVE_WIDTH)) ? (
                    selectedPassportCategory ==
                      PublicMarketPlaceTabs.EXPASSES && (
                      <MarketPlaceExpasses navigation={navigation} />
                    )
                  ) : (
                    <MarketPlaceExpasses navigation={navigation} />
                  )}
                </View>
              ) : (
                <View
                  style={{
                    paddingTop:
                      (RESPONSIVE != 'true' &&
                        selectedPassportCategory ==
                          PublicMarketPlaceTabs.COLLECTIBLES) ||
                      (isResponsive &&
                        selectedPassportCategory ==
                          PublicMarketPlaceTabs.COLLECTIBLES)
                        ? 10
                        : 0,
                    flex: 1,
                  }}>
                  {RESPONSIVE != 'true' ||
                  (RESPONSIVE == 'true' &&
                    width < parseInt(RESPONSIVE_WIDTH)) ? (
                    selectedPassportCategory ==
                      PublicMarketPlaceTabs.COLLECTIBLES && (
                      <MarketPlaceCollectibles navigation={navigation} />
                    )
                  ) : (
                    <MarketPlaceCollectibles navigation={navigation} />
                  )}
                </View>
              )
            ) : (
              <>
                <View
                  style={[
                    {
                      padding:
                        (RESPONSIVE != 'true' &&
                          selectedPassportCategory !=
                            PublicMarketPlaceTabs.COLLECTIBLES) ||
                        (isResponsive &&
                          selectedPassportCategory !=
                            PublicMarketPlaceTabs.COLLECTIBLES)
                          ? 10
                          : 0,
                    },
                    !isResponsive && { flex: 1 },
                  ]}>
                  {RESPONSIVE != 'true' ||
                  (RESPONSIVE == 'true' &&
                    width < parseInt(RESPONSIVE_WIDTH)) ? (
                    selectedPassportCategory ==
                      PublicMarketPlaceTabs.EXPASSES && (
                      <MarketPlaceExpasses navigation={navigation} />
                    )
                  ) : (
                    <MarketPlaceExpasses navigation={navigation} />
                  )}
                </View>
                <View
                  style={[
                    {
                      padding:
                        (RESPONSIVE != 'true' &&
                          selectedPassportCategory ==
                            PublicMarketPlaceTabs.COLLECTIBLES) ||
                        (isResponsive &&
                          selectedPassportCategory ==
                            PublicMarketPlaceTabs.COLLECTIBLES)
                          ? 10
                          : 0,
                    },
                    !isResponsive && { flex: 1 },
                    isResponsive && { padding: 5 },
                  ]}>
                  {RESPONSIVE != 'true' ||
                  (RESPONSIVE == 'true' &&
                    width < parseInt(RESPONSIVE_WIDTH)) ? (
                    selectedPassportCategory ==
                      PublicMarketPlaceTabs.COLLECTIBLES && (
                      <MarketPlaceCollectibles navigation={navigation} />
                    )
                  ) : (
                    <MarketPlaceCollectibles navigation={navigation} />
                  )}
                </View>
              </>
            )}
            {/* {
              selectedPassportCategory != PublicMarketPlaceTabs.COLLECTIBLES &&            
              <View style={{ padding: ((RESPONSIVE != "true" && selectedPassportCategory != PublicMarketPlaceTabs.COLLECTIBLES) || (isResponsive && selectedPassportCategory != PublicMarketPlaceTabs.COLLECTIBLES)) ? 10 : 0, flex:1 }}>
                {
                  ((RESPONSIVE != "true") || ((RESPONSIVE == "true") && (width < parseInt(RESPONSIVE_WIDTH)))) ? selectedPassportCategory == PublicMarketPlaceTabs.EXPASSES &&
                  <MarketPlaceExpasses navigation={navigation} /> : <MarketPlaceExpasses navigation={navigation} />
                }
              </View>
              }
            {
              selectedPassportCategory == PublicMarketPlaceTabs.COLLECTIBLES &&
              <View style={{ padding: ((RESPONSIVE != "true" && selectedPassportCategory == PublicMarketPlaceTabs.COLLECTIBLES) || (isResponsive && selectedPassportCategory == PublicMarketPlaceTabs.COLLECTIBLES)) ? 10 : 0, flex:1 }}>
                {
                  ((RESPONSIVE != "true") || ((RESPONSIVE == "true") && (width < parseInt(RESPONSIVE_WIDTH))))  ? selectedPassportCategory == PublicMarketPlaceTabs.COLLECTIBLES &&
                  <MarketPlaceCollectibles navigation={navigation} /> : <MarketPlaceCollectibles navigation={navigation} />
                }
              </View>
            } */}
          </View>
        </View>
      </LinearGradient>
    </TouchableWithoutFeedback>
  );
};

export default MarketPlaceScreen;
