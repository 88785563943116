import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  rightContainer: {
    flex: 1,
    marginLeft: 15,
  },
  image: {
    aspectRatio: 41 / 25,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    borderWidth: 1,
    borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
    overflow: 'hidden',
  },
  title: {
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XMEDIUM + 2,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  description: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XXMEDIUM + 2,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  subtitle: {
    marginTop: 2.5,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXXSMALL + 2,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  creatorType: {
    marginTop: 2.5,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  btnContainer: {
    // flex: 1,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    height: 36,
    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textTransform: 'capitalize',
  },
  joinComponentContainer: {
    height: 36,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255,0.8)',
    shadowColor: defaultTheme.GRADIENT_COLOR1,
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 1,
    shadowRadius: 9.51,
    elevation: 15,
  },
  joinPriceWrapper: {
    height: 36,
    width: 100,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    shadowColor: defaultTheme.GRADIENT_COLOR1,
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 1,
    shadowRadius: 9.51,
    elevation: 15,
  },
  joinPriceContainer: {
    flex: 1,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeftWidth: 1.5,
    borderColor: 'rgba(255, 255, 255,0.8)',
  },
  joinText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textTransform: 'capitalize',
    marginLeft: 10,
  },
  joinOverlay: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tokenImg: {
    height: 20,
    width: 20,
    borderRadius: 20,
    marginRight: -5,
    marginBottom: 1.5,
  },
  modalTextStyle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    letterSpacing: 1,
    textAlign: 'center',
  },
});
