import React, { useEffect, useState } from 'react';
import { Image, Pressable } from 'react-native';
import Constants from 'expo-constants';

import ImagePreviewModal from '../Modal/ImagePreviewModal';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { getIPNSLink, getUserDetailsFromIPFS } from '../../helpers/ipfs';

const ImageInMessage = ({
  uri,
  decryptImage = false,
  friendsPublicKey = '',
  style = null,
  handleLongPressItem = null,
}) => {
  const { decryptMessage } = useWeb3AuthContext();
  const [isVisibleImagePreviewModal, setIsVisibleImagePreviewModal] =
    useState(false);
  const [imagePreviewSource, setImagePreviewSource] = useState(null);

  const [imgLayout, setImgLayout] = useState({
    height: 150,
    width: 150,
  });

  const [imageURI, setImageURI] = useState('');

  const errorOnLoadingImage = error => {
    const brokenImageURI =
      getIPNSLink(Constants.expoConfig.extra.APP_CONFIGURATION_IPNS_KEY) +
      '/' +
      'no-image.png';
    if (brokenImageURI) {
      setImageURI(brokenImageURI);
    }
  };

  useEffect(() => {
    getUserDetailsFromIPFS(uri).then((imageData: any) => {
      if (decryptImage) {
        imageData = decryptMessage(imageData, friendsPublicKey);
      }
      Image.getSize(imageData, (_width, _height) => {
        if (_height > 150) {
          _width = (150 / _height) * _width;
          _height = 150;
        }
        setImgLayout({ width: _width, height: _height });
      });
      setImageURI(imageData);
    });
  }, []);

  const onImagePreview = item => {
    setImagePreviewSource(item);
    setIsVisibleImagePreviewModal(true);
  };

  return (
    <>
      {imageURI && (
        <Pressable
          onPress={() => onImagePreview(imageURI)}
          onLongPress={() =>
            handleLongPressItem
              ? handleLongPressItem()
              : onImagePreview(imageURI)
          }
          delayLongPress={500}>
          <Image
            source={{ uri: imageURI }}
            style={
              style || {
                maxWidth: '90%',
                height: imgLayout.height,
                width: imgLayout.width,
                borderRadius: 10,
                alignSelf: 'center',
              }
            }
            borderRadius={6}
            resizeMethod="scale"
            resizeMode="cover"
            onError={({ nativeEvent: { error } }) => errorOnLoadingImage(error)}
          />
        </Pressable>
      )}

      {
        <ImagePreviewModal
          onDismiss={() => {
            setIsVisibleImagePreviewModal(false);
          }}
          isVisible={isVisibleImagePreviewModal}
          imagePreviewSource={imagePreviewSource}
        />
      }
    </>
  );
};

const areEqual = (prevProps: { uri: string }, nextProps: { uri: string }) => {
  let isEqual = true;
  if (prevProps.uri != nextProps.uri) {
    isEqual = false;
  }
  return true;
};
export default React.memo(ImageInMessage, areEqual);
