import React from 'react';
import { View, StyleSheet, ActivityIndicator, Text } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

const AppLoaderComponent = ({
  deemBg = true,
  extraStyle = null,
  loaderText = null,
  size = 'large',
}: {
  deemBg?: boolean;
  size?: number | 'small' | 'large' | undefined;
  extraStyle?: any;
  loaderText?: { text: string; textStyle?: any };
}) => {
  return (
    <View
      style={[
        StyleSheet.absoluteFillObject,
        styles.loaderContainer,
        deemBg && styles.deemBackground,
        extraStyle,
      ]}>
      <ActivityIndicator size={size} color={defaultTheme.PRIMARY_TEXT_COLOR} />
      {loaderText?.text && (
        <Text style={[styles.textMessage, loaderText.textStyle]}>
          {loaderText.text}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  loaderContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    minHeight: 200,
  },
  deemBackground: {
    backgroundColor: 'rgba(10,40,80,0.2)',
  },
  textMessage: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    marginTop: 15,
    textAlign: 'center',
    lineHeight: 26,
    padding: 5,
  },
});

export default AppLoaderComponent;
