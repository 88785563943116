import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import HOCContainer from '../components/HOCContainer';
import { defaultTheme } from '../themes/loot8';

function SwapScreen({ route, navigation }) {
  return (
    <>
      <View style={styles.root}>
        <Text style={[styles.subtitle, { marginTop: 16 }]}>
          The cash-out feature for Loot8 Tokens is no longer accessible within
          the Loot8 app due to compliance with platform guidelines.
        </Text>

        <Text style={[styles.title, { marginTop: 12, marginBottom: 8 }]}>
          More Information
        </Text>
        <Text style={styles.subtitle}>
          For further details on this change, please visit our web app.
        </Text>
      </View>
    </>
  );
}

export default HOCContainer({
  OriginalComponent: SwapScreen,
  title: 'Loot8 Token Cash-Out Important\nUpdate',
});

const styles = StyleSheet.create({
  root: {
    flex: 1,
    gap: 10,
    marginHorizontal: 4,
  },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    color: '#fff',
  },
  subtitle: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: '#fff',
    opacity: 0.9,
  },
});
