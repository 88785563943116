import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    borderRadius: 100,
    overflow: 'hidden',
    padding: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rippleWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  contentContainer: {
    // paddingVertical: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    borderRadius: 100,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
});
