import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { defaultTheme } from '../../themes/loot8';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { TouchableOpacity } from 'react-native-gesture-handler';

interface Props {
  text: string;
  onPress?: any;
}

const AddressChip: React.FC<Props> = ({ text, onPress }) => {
  const formatAddress = (address: string): string => {
    if (address.length > 12) {
      const firstPart = address.slice(0, 12);
      const lastPart = address.slice(-4);
      return `${firstPart}.....${lastPart}`;
    } else {
      return address;
    }
  };
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={styles.container}>
      <View style={styles.contentContainer}>
        <Text style={styles.textStyle}>{formatAddress(text)}</Text>
        <TouchableOpacity onPress={onPress}>
          <Icon
            name="clipboard-multiple"
            size={14}
            color="#FFF"
          />
        </TouchableOpacity>
      </View>
    </LinearGradient>
  );
};

export default AddressChip;

const styles = StyleSheet.create({
  container: {
    height: 26,
    borderRadius: defaultTheme.BUTTON_RADIUS,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 26,
    paddingHorizontal: 10,
    columnGap: 8,
  },
  textStyle: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    color: '#FFF',
  },
});
