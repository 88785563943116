import React from 'react';
import { DimensionValue, StyleProp, Text, View, ViewStyle } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import {
  ActivityIndicator,
  DefaultTheme,
  TouchableRipple,
} from 'react-native-paper';

import { styles } from './styles';
import { defaultTheme } from '../../themes/loot8';

interface GradientButtonProps {
  type?: 'primary' | 'secondary' | 'alternate';
  width?: DimensionValue;
  height?: DimensionValue;
  fontSize?: number;
  fontFamily?: string;
  buttonLabel: string;
  buttonLabelColor?: string;
  onPress: () => void;
  disabled?: boolean;
  loading?: boolean;
  bordered?: boolean;
  customStyle?: StyleProp<ViewStyle>;
}

const GradientButton: React.FC<GradientButtonProps> = ({
  type = 'primary',
  width = '100%',
  height = 48,
  fontSize = 14,
  fontFamily = defaultTheme.FONT_FAMILY_SEMI_BOLD,
  buttonLabel,
  buttonLabelColor = defaultTheme.PRIMARY_TEXT_COLOR,
  onPress,
  disabled = false,
  loading = false,
  customStyle,
  bordered = false,
}) => {
  return (
    <LinearGradient
      colors={
        type === 'primary'
          ? [defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]
          : type === 'secondary'
            ? [defaultTheme.SECONDARY_COLOR, defaultTheme.SECONDARY_COLOR]
            : ['rgba(33, 29, 40,0.8)', 'rgba(33, 29, 40,0.8)']
      }
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={[styles.container, { width, height }, customStyle]}>
      <TouchableRipple
        disabled={disabled}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}
        style={[
          {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          },
          bordered && styles.borderedStyles,
        ]}>
        {loading ? (
          <ActivityIndicator
            size={14}
            color={defaultTheme.PRIMARY_TEXT_COLOR}
          />
        ) : (
          <Text
            style={[
              styles.buttonText,
              {
                fontFamily,
                fontSize,
                color: buttonLabelColor,
              },
            ]}>
            {buttonLabel}
          </Text>
        )}
      </TouchableRipple>
      {disabled && <View style={styles.disabled} />}
    </LinearGradient>
  );
};

export default GradientButton;
