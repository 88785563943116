import React, { useCallback, useEffect } from 'react';
import {
  Image,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
  Keyboard,
} from 'react-native';
import { ActivityIndicator, TextInput } from 'react-native-paper';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import useState from 'react-usestateref';
import { ToastCustomMessageType, ZERO_ADDRESS } from '../../appconstants';
import reshare from '../../assets/arrows-retweet-2x.png';
import chat_close from '../../assets/chat-close.png';
import comment from '../../assets/comment-2x.png';
import like from '../../assets/heart-2x.png';
import likeFill from '../../assets/heart-fill-2x.png';
import { getTimeTextFromTimestamp } from '../../helpers/DateHelper';
import { showToastMessage } from '../../helpers/Gadgets';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import {
  postMessageLike,
  postMessageReply,
  postMessageUnlike,
} from '../../slices/PassportMessageSlice';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import ModalComponent from '../Modal';
import { PassportReplyItem } from './passportReplyItem';
import URLPreview from '../URLPreview';
import ImagePickerComponent from '../ImagePickerComponent';
import { uploadUserData } from '../../helpers/ipfs';
import CreateURLs from '../CreateURLs';
import ImageInMessage from '../ImageInMessage';
import { SheetManager } from 'react-native-actions-sheet';
import ProfileAvatar from '../ProfileAvatar';
import { getSenderID, getSenderName } from '.';
import { ThreeDotsSvg } from '../../assets/svg/HomeSvgs';
import useIsResponsive from '../../hooks/useIsResponsive';

const MESSAGE_MAX_LENGTH = 360;
const MESSAGE_READ_MORE_LENGTH = 100;
const MESSAGE_READ_MORE_LINES = 3;

export const PassportMessageItem = ({
  messagesCount,
  selectedPassport,
  item,
  index,
  closeReply,
  onMessageMenuClick,
  addReply,
  showAddReply,
  replyMessageID,
  messageDetails,
  scrollToText,
  onSetShowUserInfoModal,
}) => {
  const { networkId, staticProvider, wallet, address } = useWeb3AuthContext();

  const dispatch = useAppDispatch();
  const UserData = useAppSelector(state => state.AppUser.UserData);
  const isReplyPosted = useAppSelector(
    state => state.PassportMessage.replyPosted,
  );
  const isResponsive = useIsResponsive();

  const [messageText, setMessageText] = useState('');
  const [countInputTextLength, setCountInputTextLength] = useState(
    messageText ? messageText.length : 0,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  // const [showMoreLessClicked, setShowMoreLessClicked] = useState(false);
  // const [postedReply, setPostedReply] = useState(false);
  const [activePostMessage, setActivePostMessage] = useState(false);
  const [localLike, setLocalLike, localLikeRef] = useState(
    item.likes?.findIndex(p => p.hash?.toLowerCase() == address.toLowerCase()) >
      -1,
  );
  const [likeClicked, setLikeClicked] = React.useState(false);
  const [localLikeLength, setLocalLikeLength] = React.useState(
    item.likes?.length || 0,
  );
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [isDisableMore, setisDisableMore] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);

  const multilineLength = item?.data?.data?.content?.text?.trim()?.split('\n')
    ?.length;
  const [joinLines, setJoinLines] = useState(null);
  // const setMsgAsRead = (msgId) => {
  // dispatch(markMessagesAsRead([msgId]));
  // dispatch(setLastReadTimestamp({ lastReadTimestamp: getSyncedTime(), userAddress: address }));
  // }

  const handleResponse = (
    response: any,
    messageId: string,
    likeMessage: boolean,
  ) => {
    setActivePostMessage(false);

    if (
      response &&
      response.payload &&
      response.payload.status &&
      response.payload.status == 200
    ) {
      if (likeMessage && !localLikeRef.current) {
        postLikeUnlikeMessage(messageId, false, response.payload.data.itemId);
      } else if (!likeMessage && localLikeRef.current) {
        postLikeUnlikeMessage(messageId, true, null);
      }
    }
  };

  const postLikeUnlikeMessage = async (
    messageId: string,
    likeMessage: boolean,
    likeMessageId: string | null,
  ) => {
    setActivePostMessage(true);

    if (likeMessage) {
      dispatch(
        postMessageLike({
          networkID: networkId,
          provider: staticProvider,
          address: selectedPassport.address,
          wallet,
          messageId,
          chainId: selectedPassport.chainId,
        }),
      ).then((response: any) => {
        handleResponse(response, messageId, likeMessage);
      });
    } else {
      dispatch(
        postMessageUnlike({
          networkID: networkId,
          provider: staticProvider,
          address: selectedPassport.address,
          wallet,
          messageId: likeMessageId,
          parentId: messageId,
          chainId: selectedPassport.chainId,
        }),
      ).then((response: any) => {
        handleResponse(response, messageId, likeMessage);
      });
    }
  };

  useEffect(() => {
    if (likeClicked) {
      if (localLike && !activePostMessage && item) {
        postLikeUnlikeMessage(item.messageId, true, null);
      } else if (!localLike && !activePostMessage && item && item.likes) {
        const userLike = item.likes.find(
          p => p.hash?.toLowerCase() == address.toLowerCase(),
        );
        if (userLike) {
          postLikeUnlikeMessage(item.messageId, false, userLike.messageId);
        }
      }
    }
  }, [likeClicked]);

  const resetClickedFlag = () => {
    setTimeout(() => {
      setLikeClicked(false);
    }, 100);
  };

  const likeMessage = async () => {
    setLocalLike(true);
    setLocalLikeLength(localLikeLength + 1);
    resetClickedFlag();
  };

  const unlikeMessage = async () => {
    setLocalLike(false);
    setLocalLikeLength(localLikeLength - 1);
    resetClickedFlag();
  };

  const hasValue = useCallback(
    () =>
      (messageText && messageText.trim().length > 0) ||
      (selectedImage && selectedImage !== ''),
    [messageText, selectedImage],
  );

  const onCreateReplyPost = async (messageText: string, messageId: any) => {
    if (
      (messageText && messageText.trim().length > 0) ||
      (selectedImage && selectedImage !== '')
    ) {
      if (Keyboard && Keyboard.dismiss) {
        Keyboard.dismiss();
      }
      setIsLoading(true);
      let attachments: any = {};
      if (selectedImage && selectedImage !== '') {
        attachments.name = 'Passport Message Image' + Date.now().toString();
        attachments.uri = await uploadUserData(
          selectedImage,
          wallet,
          `PassportMessage_${Date.now().toString()}.txt`,
        );
      }
      const response = await dispatch(
        postMessageReply({
          networkID: networkId,
          provider: staticProvider,
          address: selectedPassport.address,
          wallet: wallet,
          text: messageText,
          messageId: messageId,
          chainId: selectedPassport.chainId,
          attachments: attachments,
        }),
      );
      if (
        response &&
        response.payload &&
        response.payload.status &&
        response.payload.status == 200
      ) {
        closeReply();
        setMessageText('');
        setCountInputTextLength(0);
        //   setPostedReply(true);
        setShowReplies(true);
        //   setShowMoreLessClicked(true);
      } else if (
        response &&
        response.payload &&
        response.payload.error &&
        response.payload.error.code &&
        response.payload.error.message &&
        response.payload.error.code === 400 &&
        response.payload.error.message.includes('account status: muted')
      ) {
        closeReply();
        setMessageText('');
        setCountInputTextLength(0);
        showToastMessage(
          ToastCustomMessageType.INFO,
          'Unable to post message. Your account is muted. Please try again later!',
        );
      } else {
        setShowErrorModal(true);
      }
      setSelectedImage('');
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //     if(postedReply || showMoreLessClicked) {
  //         setPostedReply(false);
  //         setShowMoreLessClicked(false);
  //         // setAddReplyClick(false);
  //     }
  // }, [itemHeight]);

  const onReshareClicked = async () => {
    // setMsgAsRead(item.messageId);
    await SheetManager.show('ReshareModalSheet', {
      payload: {
        selectedPassport: selectedPassport,
        message: item,
        getSenderName: getSenderName,
        getSenderID: getSenderID,
        getTimeTextFromTimestamp: getTimeTextFromTimestamp,
      },
    });
  };

  const onPressShowMore = (item, type) => {
    setisDisableMore(true);
    setTimeout(() => {
      // setMsgAsRead(item.messageId)
      // setShowMoreLessClicked(true);
      if (type == 'more') setShowReplies(true);
      else setShowReplies(false);
      setisDisableMore(false);
    }, 500);
  };

  useEffect(() => {
    // pick first three lines
    if (multilineLength > MESSAGE_READ_MORE_LINES) {
      const splitLines = item?.data?.data?.content?.text?.trim()?.split('\n');
      let lines = [];
      if (splitLines?.length > 0) {
        for (let l = 0; l < MESSAGE_READ_MORE_LINES; l++) {
          lines.push(splitLines[l]);
        }
        setJoinLines(lines?.join('\n'));
      }
    }
  }, []);

  return (
    <View
      style={[
        {
          flexDirection: 'column',
          marginBottom: messagesCount === 0 ? 50 : 0,
        },
        Platform.OS === 'web' && { paddingRight: 10 },
      ]}>
      <View
        // onPress={() => {setMsgAsRead(item.messageId)}}
        style={[
          { flexDirection: 'row', padding: 10, paddingLeft: 1, columnGap: 6 },
          // (item.isRead || item.data?.sender?.toLowerCase() == address?.toLowerCase())
          // ? null : styles.socialMessagingUnreadBackground,
          index === messageDetails.length - 1
            ? styles.socialMessagingItemRadius
            : null,
        ]}>
        <Pressable
          onPress={() => {
            onSetShowUserInfoModal(item.user);
          }}>
          <ProfileAvatar
            size={36}
            source={
              item.user &&
              item.user.avatarURI &&
              item.user.avatarURI != '' &&
              item.user.avatarURI != 'no-avatar' &&
              !item.user.avatarURI.includes('ipfs://') &&
              !item.user.avatarURI.includes('assets_avatars_p_') &&
              !item.user.avatarURI.includes('file:///')
                ? { uri: item.user.avatarURI }
                : null
            }
            userAddress={item?.user?.wallet}
          />
        </Pressable>

        <View style={{ flexDirection: 'column', paddingLeft: 10, flex: 1 }}>
          <Pressable
            onLongPress={() => onMessageMenuClick(item)}
            delayLongPress={750}>
            {state => (
              <View
                style={
                  state.pressed && {
                    backgroundColor: '#FFFFFF08',
                    borderRadius: defaultTheme.CONTENT_RADIUS,
                  }
                }>
                <View
                  style={{ flexDirection: 'row', flex: 1, marginBottom: 2 }}>
                  <View
                    style={{
                      flex: 0.82,
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginRight: 3,
                    }}>
                    <Text
                      style={[
                        styles.drawerUserNameLongText,
                        Platform.OS == 'web' ? { wordBreak: 'break-word' } : {},
                      ]}>
                      <Text style={styles.socialMessagingUserNameText}>
                        {getSenderName(item)}
                      </Text>
                      <Text style={styles.socialMessagingUserIDText}>
                        {getSenderID(item)}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 0.18,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    <Text style={styles.socialMessagingTimestampText}>
                      {getTimeTextFromTimestamp(item.data?.senderTimestamp)}
                    </Text>
                    {!item.user ||
                    (item.user && item.user.wallet === ZERO_ADDRESS) ? (
                      <></>
                    ) : (
                      <Pressable
                        hitSlop={{ bottom: 20, left: 20, right: 20, top: 20 }}
                        style={{
                          width: 18,
                          height: 5,
                          justifyContent: 'flex-start',
                          paddingTop: 1.5,
                          paddingLeft: 5,
                          marginLeft: 5,
                        }}
                        onPress={() => onMessageMenuClick(item)}>
                        <ThreeDotsSvg />
                      </Pressable>
                    )}
                  </View>
                </View>
                <View>
                  {item?.messageURLs && item?.messageURLs?.length > 0 ? (
                    <>
                      {!showReadMore && item.data?.data.content?.text.trim() ? (
                        <>
                          {multilineLength > MESSAGE_READ_MORE_LINES ? (
                            <>
                              <Text style={styles.socialMessageText}>
                                <CreateURLs
                                  key={item.messageId}
                                  message={joinLines}
                                  messageId={item.messageId}
                                />
                              </Text>
                              <Pressable
                                onPress={() => {
                                  setShowReadMore(!showReadMore);
                                }}>
                                <Text style={styles.socialReadMore}>
                                  {'Read more'}
                                </Text>
                              </Pressable>
                            </>
                          ) : item.data?.data.content?.text.trim().length <
                            MESSAGE_READ_MORE_LENGTH ? (
                            <Text style={styles.socialMessageText}>
                              <CreateURLs
                                key={item.messageId}
                                message={item.data?.data.content?.text}
                                messageId={item.messageId}
                              />
                            </Text>
                          ) : (
                            <>
                              <Text style={styles.socialMessageText}>
                                <CreateURLs
                                  key={item.messageId}
                                  message={item.data?.data.content?.text.substring(
                                    0,
                                    MESSAGE_READ_MORE_LENGTH,
                                  )}
                                  messageId={item.messageId}
                                />
                              </Text>
                              <Pressable
                                onPress={() => {
                                  setShowReadMore(!showReadMore);
                                }}>
                                <Text style={styles.socialReadMore}>
                                  {'Read more'}
                                </Text>
                              </Pressable>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {showReadMore &&
                        item?.messageURLs &&
                        item?.messageURLs?.length > 0 && (
                          <>
                            <Text style={styles.socialMessageText}>
                              <CreateURLs
                                key={item.messageId}
                                message={item.data?.data.content?.text}
                                messageId={item.messageId}
                              />
                            </Text>
                            <Pressable
                              onPress={() => {
                                setShowReadMore(!showReadMore);
                              }}>
                              <Text style={styles.socialReadMore}>
                                {'Show less'}
                              </Text>
                            </Pressable>
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {!showReadMore && item.data?.data.content?.text.trim() ? (
                        <>
                          {multilineLength > MESSAGE_READ_MORE_LINES ? (
                            <>
                              <Text style={styles.socialMessageText}>
                                {joinLines}
                              </Text>
                              <Pressable
                                onPress={() => {
                                  setShowReadMore(!showReadMore);
                                }}>
                                <Text style={styles.socialReadMore}>
                                  {'Read more'}
                                </Text>
                              </Pressable>
                            </>
                          ) : item.data?.data.content?.text?.trim().length <
                            MESSAGE_READ_MORE_LENGTH ? (
                            <Text style={styles.socialMessageText}>
                              {item.data?.data.content?.text.trim()}
                            </Text>
                          ) : (
                            <>
                              <Text style={styles.socialMessageText}>
                                {`${item.data?.data.content?.text?.substring(
                                  0,
                                  MESSAGE_READ_MORE_LENGTH,
                                )}... `}
                                <Text
                                  onPress={() => {
                                    setShowReadMore(!showReadMore);
                                  }}
                                  style={styles.socialReadMore}>
                                  {'Read more'}
                                </Text>
                              </Text>
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {showReadMore &&
                        item?.messageURLs &&
                        item?.messageURLs?.length == 0 && (
                          <>
                            <Text style={styles.socialMessageText}>
                              {item.data?.data.content?.text.trim()}
                            </Text>
                            <Pressable
                              onPress={() => {
                                setShowReadMore(!showReadMore);
                              }}>
                              <Text style={styles.socialReadMore}>
                                {'Show less'}
                              </Text>
                            </Pressable>
                          </>
                        )}
                    </>
                  )}
                  {item?.messageURLs &&
                    item?.messageURLs?.length > 0 &&
                    item?.messageURLs
                      ?.filter((x, i) => i == 0)
                      .map((url, index) => {
                        return (
                          <URLPreview
                            key={index}
                            url={url}
                            message={item.data?.data.content?.text}
                            children={''}></URLPreview>
                        );
                      })}
                </View>

                {item.data?.data?.attachments &&
                item.data?.data?.attachments?.uri !== '' ? (
                  <View style={{ marginTop: 2.5, alignItems: 'flex-start' }}>
                    <ImageInMessage uri={item.data?.data?.attachments?.uri} />
                  </View>
                ) : (
                  <></>
                )}
              </View>
            )}
          </Pressable>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginVertical: 10,
            }}>
            <Pressable
              onPress={() => addReply(item.messageId)}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}>
              <Image
                source={comment}
                style={{
                  width: 14,
                  height: 12,
                  marginRight: 5,
                  tintColor: defaultTheme.PRIMARY_TEXT_COLOR,
                }}
              />
            </Pressable>
            <Pressable
              style={{
                justifyContent: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}
              onPress={() => {
                setLikeClicked(true);
                if (localLike) {
                  unlikeMessage();
                } else {
                  likeMessage();
                }
              }}>
              <Image
                source={localLike ? likeFill : like}
                style={{
                  width: 14,
                  height: 12,
                  marginRight: 5,
                  tintColor: defaultTheme.PRIMARY_TEXT_COLOR,
                }}
              />
            </Pressable>
            <Pressable
              style={{
                justifyContent: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}
              onPress={onReshareClicked}>
              <Image
                source={reshare}
                style={{
                  width: 18,
                  height: 16,
                  marginRight: 5,
                  tintColor: defaultTheme.PRIMARY_TEXT_COLOR,
                }}
                resizeMode="contain"
              />
            </Pressable>
          </View>
          <View style={{ alignItems: 'flex-start' }}>
            <Text style={[styles.socialMessagingMainActionText]}>
              {item.replies && item.replies.length ? (
                <>
                  {item.replies.length}{' '}
                  {item.replies.length === 1 ? 'reply' : 'replies'}
                </>
              ) : null}
              {item.replies && item.replies.length && localLikeLength
                ? ', '
                : ''}
              {localLikeLength ? (
                <>
                  {localLikeLength} {localLikeLength === 1 ? 'like' : 'likes'}
                </>
              ) : null}
              {((item.replies && item.replies.length) || localLikeLength) &&
              item.shares?.length
                ? ', '
                : ''}
              {item.shares?.length
                ? `${item.shares.length} share${
                    item.shares.length === 1 ? '' : 's'
                  }`
                : null}
            </Text>
          </View>
          {showAddReply &&
            replyMessageID &&
            replyMessageID === item.messageId &&
            !isReplyPosted && (
              <View onLayout={Platform.OS === 'web' && scrollToText}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginTop: 5,
                  }}>
                  <Text
                    style={{
                      color: defaultTheme.PRIMARY_TEXT_COLOR,
                      fontSize: 11,
                    }}>
                    {'Replying to @' + item.user.name}
                  </Text>
                  <TouchableOpacity onPress={() => closeReply()}>
                    <Image
                      source={chat_close}
                      style={{
                        height: 12.5,
                        width: 12.5,
                        marginRight: 2.5,
                        tintColor: defaultTheme.PRIMARY_TEXT_COLOR,
                      }}
                    />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 5,
                    backgroundColor: defaultTheme.SECONDARY_COLOR,
                    borderRadius: defaultTheme.CONTENT_RADIUS,
                    paddingVertical: 2.5,
                    paddingRight: 5,
                  }}>
                  <View
                    style={{ marginLeft: 5, marginTop: 10, marginRight: 2 }}>
                    <ProfileAvatar
                      size={36}
                      source={
                        UserData.avatarURI &&
                        UserData.avatarURI != '' &&
                        UserData.avatarURI != 'no-avatar' &&
                        !UserData.avatarURI.includes('ipfs://') &&
                        !item.user.avatarURI.includes('assets_avatars_p_') &&
                        !item.user.avatarURI.includes('file:///')
                          ? { uri: UserData.avatarURI }
                          : null
                      }
                      userAddress={item?.user?.wallet}
                    />
                  </View>
                  <View
                    style={{
                      flexDirection: 'column',
                      flex: 1,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        marginTop: 5,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <TextInput
                        onFocus={event => {
                          Platform.OS !== 'web' && scrollToText(event);
                        }}
                        style={{
                          fontFamily: defaultTheme.FONT_FAMILY_MAIN,
                          fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                          backgroundColor: 'transparent',
                          textAlignVertical: 'center',
                          ...(Platform.OS == 'android' || Platform.OS == 'ios'
                            ? {
                                maxHeight: 70,
                                marginHorizontal: 5,
                                flex: 1,
                              }
                            : { flex: 1, flexGrow: 1, marginHorizontal: 10 }),
                          padding: 0,
                        }}
                        value={messageText}
                        onChangeText={text => {
                          if (text && text.length <= MESSAGE_MAX_LENGTH) {
                            setMessageText(text);
                            setCountInputTextLength(text.trim().length);
                          } else if (text.length === 0) {
                            setMessageText(text);
                            setCountInputTextLength(text.length);
                          }
                        }}
                        maxLength={MESSAGE_MAX_LENGTH}
                        placeholder="Enter your reply"
                        placeholderTextColor="#959595a0"
                        scrollEnabled={
                          Platform.OS == 'ios' || Platform.OS == 'android'
                            ? true
                            : false
                        }
                        multiline={true}
                        numberOfLines={1}
                        contentStyle={{
                          paddingBottom: 5,
                          paddingTop: 5,
                          minHeight: 55,
                        }}
                        cursorColor={defaultTheme.PRIMARY_TEXT_COLOR}
                        textColor={defaultTheme.PRIMARY_TEXT_COLOR}
                        autoFocus={true}
                        // blurOnSubmit={true}
                        disabled={isLoading}
                        mode="outlined"
                        selectionColor="rgba(255,255,255,.39)"
                        activeOutlineColor="rgba(255,255,255,.5)"
                        outlineColor="rgba(255,255,255,.5)"
                        returnKeyType="default"
                      />
                      <View
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: 0,
                        }}>
                        <View style={{ marginLeft: 0, marginBottom: 5 }}>
                          <Pressable
                            onPress={() => !isLoading && setShowAddImage(true)}>
                            <MaterialCommunityIcons
                              name="camera-plus-outline"
                              color={defaultTheme.PRIMARY_TEXT_COLOR}
                              size={25}
                              style={{
                                height: 25,
                                width: 25,
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          </Pressable>
                        </View>
                        {isLoading ? (
                          <View style={{ marginLeft: 0, marginBottom: 0 }}>
                            <ActivityIndicator
                              style={{ paddingRight: 0 }}
                              size={20}
                              color={defaultTheme.CONTENT_NAME_BACKGROUND_COLOR}
                            />
                          </View>
                        ) : (
                          <TouchableOpacity
                            onPress={() =>
                              onCreateReplyPost(messageText, item.messageId)
                            }
                            disabled={!hasValue()}>
                            <MaterialCommunityIcons
                              name="send-circle"
                              color={
                                hasValue()
                                  ? defaultTheme.PRIMARY_TEXT_COLOR
                                  : '#bdc6d5'
                              }
                              size={35}
                            />
                          </TouchableOpacity>
                        )}
                      </View>
                    </View>
                    <View>
                      <Text
                        style={{
                          marginLeft: Platform.OS === 'web' ? 5 : 2.5,
                          marginTop: 2.5,
                          fontSize: 12,
                          color: defaultTheme.PRIMARY_TEXT_COLOR,
                        }}>
                        {' '}
                        {countInputTextLength.toString()} / {MESSAGE_MAX_LENGTH}
                      </Text>
                    </View>
                  </View>
                </View>
                {selectedImage && selectedImage !== '' ? (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 70,
                      height: 70,
                      marginVertical: 8,
                    }}>
                    <Image
                      source={{ uri: selectedImage }}
                      style={{
                        height: 70,
                        width: 70,
                        borderColor: '#dfdfdf',
                        borderWidth: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      resizeMethod="scale"
                      resizeMode="contain"
                    />
                    {!isLoading && (
                      <Pressable
                        onPress={() => setSelectedImage(null)}
                        style={{
                          position: 'absolute',
                          backgroundColor: '#F0F0F0B0',
                          height: 25,
                          width: 25,
                          borderRadius: 30,
                          top: 5,
                          right: 5,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <MaterialCommunityIcons
                          name="window-close"
                          size={20}
                          style={{ color: '#404040' }}
                        />
                      </Pressable>
                    )}
                  </View>
                ) : (
                  <></>
                )}
              </View>
            )}
        </View>
      </View>
      <View style={[{ paddingLeft: 50, paddingRight: 10 }]}>
        {item.replies && item.replies?.length > 0 ? (
          <View>
            {showReplies ? (
              <>
                {item.replies.map((replyItem, index) => {
                  return (
                    <PassportReplyItem
                      key={replyItem.messageId}
                      selectedPassport={selectedPassport}
                      item={replyItem}
                      // parentItem={item}
                      onMessageMenuClick={onMessageMenuClick}
                      // getSenderName={getSenderName}
                      // setShowErrorModal={setShowErrorModal}
                      // getSenderID={getSenderID}
                      onSetShowUserInfoModal={onSetShowUserInfoModal}
                    />
                  );
                })}

                {item.replies.length > 1 && (
                  <TouchableOpacity
                    disabled={isDisableMore}
                    onPress={() => onPressShowMore(item, 'less')}>
                    <Text
                      style={{
                        fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                        fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
                        color: defaultTheme.PRIMARY_TEXT_COLOR,
                        marginLeft: 2.5,
                        marginTop: 2.5,
                      }}>
                      {'Show less'}
                    </Text>
                  </TouchableOpacity>
                )}
              </>
            ) : (
              <>
                {item.replies?.length > 0 && (
                  <PassportReplyItem
                    selectedPassport={selectedPassport}
                    key={item.replies[0].messageId}
                    item={item.replies[0]}
                    // parentItem={item}
                    onMessageMenuClick={onMessageMenuClick}
                    // setMsgAsRead={setMsgAsRead}
                    // getSenderName={getSenderName}
                    // setShowErrorModal={setShowErrorModal} getSenderID={getSenderID}
                    onSetShowUserInfoModal={onSetShowUserInfoModal}
                  />
                )}
                {item.replies?.length > 1 ? (
                  <TouchableOpacity
                    disabled={isDisableMore}
                    onPress={() => onPressShowMore(item, 'more')}>
                    <Text
                      style={{
                        fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                        fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
                        color: defaultTheme.PRIMARY_TEXT_COLOR,
                        marginLeft: 2.5,
                        marginTop: 2.5,
                      }}>
                      {'Show more from ' +
                        (item.replies?.length - 1) +
                        ' others'}
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <></>
                )}
              </>
            )}
          </View>
        ) : (
          <></>
        )}
      </View>
      {index != messageDetails.length - 1 && (
        <View
          style={[
            styles.socialMessagingHairLine,
            item.isRead ||
            item.data?.sender?.toLowerCase() == address?.toLowerCase()
              ? styles.socialMessagingReadHairLine
              : styles.socialMessagingUnreadHairLine,
          ]}
        />
      )}
      {showErrorModal ? (
        <ModalComponent
          showModal={showErrorModal}
          needCloseButton={true}
          enableHeader={false}
          onDismiss={() => {
            setShowErrorModal(false);
          }}>
          <View style={{ padding: 30, alignContent: 'center' }}>
            <Text style={styles.modalTextStyle}>Something went wrong.</Text>
            <Text style={styles.modalTextStyle}>Please try again!!.</Text>
          </View>
        </ModalComponent>
      ) : (
        <></>
      )}
      {showAddImage && (
        <ModalComponent
          showModal={showAddImage}
          enableHeader={false}
          needCloseButton={true}
          modalStyle={{ bottom: 0, justifyContent: 'flex-end' }}
          modalBodyStyle={{ alignSelf: 'flex-start' }}
          onDismiss={() => {
            setShowAddImage(false);
          }}>
          <View style={{ padding: 10 }}>
            <Text style={styles.imagePickerHeaderText}>Attach Photo</Text>
            <ImagePickerComponent
              onAvatarButtonClick={() => {}}
              setSelectedImage={setSelectedImage}
              setSelectImageAction={setShowAddImage}
              showAvatar={false}
              isSquare={false}
            />
          </View>
        </ModalComponent>
      )}
    </View>
  );
};
