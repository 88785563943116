import { Pressable, StyleSheet, Text, View } from 'react-native';
import React from 'react';
import ProfileAvatar from '../ProfileAvatar';
import { Title } from 'react-native-paper';
import { defaultTheme } from '../../themes/loot8';

const MessagesInitialComponent = ({ openModalPopup, user, userData }) => {
  return (
    <View style={styles.container}>
      <Pressable onPress={() => openModalPopup(user, true)}>
        {user && (
          <ProfileAvatar
            style={{
              backgroundColor: user.bgColor,
            }}
            size={70}
            source={
              user?.avatarURI &&
              user?.avatarURI != '' &&
              user?.avatarURI != 'no-avatar' &&
              !user?.avatarURI.includes('ipfs://') &&
              !user?.avatarURI.includes('assets_avatars_p_') &&
              !user?.avatarURI.includes('file:///')
                ? { uri: user?.avatarURI }
                : null
            }
            userAddress={user?.wallet}
          />
        )}
      </Pressable>
      <View style={styles.secondCircle}>
        <Pressable onPress={() => openModalPopup(userData, true)}>
          {userData && (
            <ProfileAvatar
              style={{
                backgroundColor: '#fff',
              }}
              size={70}
              source={
                userData?.avatarURI &&
                userData?.avatarURI != '' &&
                userData?.avatarURI != 'no-avatar' &&
                !userData?.avatarURI.includes('ipfs://') &&
                !userData?.avatarURI.includes('assets_avatars_p_') &&
                !userData?.avatarURI.includes('file:///')
                  ? { uri: userData?.avatarURI }
                  : null
              }
              userAddress={userData?.wallet}
              tickStyle={{ height: 12, width: 12 }}
            />
          )}
        </Pressable>
      </View>
      <Title style={styles.userTitle}>
        {`${user?.name.trim()}`}
        <Text style={styles.friendUserIdText}>{` #${user?.id}`}</Text>
      </Title>
    </View>
  );
};

export default MessagesInitialComponent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  friendUserIdText: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
  userTitle: {
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    marginTop: -36,
  },
  secondCircle: {
    left: 40,
    bottom: 44,
    borderRadius: 40,
    position: 'relative',
  },
});
