import React, { useEffect } from 'react';
import HOCContainer from '../components/HOCContainer';
import UserOwnedExpasses from '../components/UserOwnedExpasses';
import TrendingExpasses from '../components/TrendingExpasses';

const SeeAll = ({ route, setDynamicTitle }) => {
  const { screenTitle, type, closeSortingOptionsMenu } = route?.params;
  useEffect(() => {
    setDynamicTitle(screenTitle);
  }, [screenTitle]);

  const renderContent = () => {
    switch (type) {
      case 'userOwned':
        return (
          <UserOwnedExpasses
            showSeeAll={false}
            closeSortingOptionsMenu={closeSortingOptionsMenu}
            showLimitedExpasses={false}
          />
        );
      case 'trending':
        return (
          <TrendingExpasses closeSortingOptionsMenu={closeSortingOptionsMenu} />
        );

      default:
        return <></>;
    }
  };

  return <>{renderContent()}</>;
};

export default HOCContainer({
  OriginalComponent: SeeAll,
});
