import React from 'react';
import { Text, View } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';

import { styles } from './styles';
import { defaultTheme } from '../../../themes/loot8';

interface MoreGradientTabProps {
  item: any;
  onPress: () => void;
  gradient?: boolean;
  gradientFill?: boolean;
  bgColor: string;
  height?: number;
  fullWidth?: boolean;
  disabled?: boolean;
}

const MoreGradientTab: React.FC<MoreGradientTabProps> = ({
  item,
  onPress,
  gradient = true,
  gradientFill = false,
  bgColor,
  height,
  fullWidth = false,
  disabled = false,
}) => {
  if (gradient) {
    return (
      <LinearGradient
        colors={[defaultTheme.GRADIENT_COLOR2, defaultTheme.GRADIENT_COLOR1]}
        end={{ x: 0.0001, y: 0.2 }}
        start={{ x: 0.00001, y: 0.8 }}
        style={[
          styles.container,
          { flex: fullWidth ? 1 : 0.225, marginHorizontal: fullWidth ? 6 : 0 },
        ]}>
        <View style={styles.rippleWrapper}>
          <TouchableRipple
            style={[
              styles.contentContainer,
              {
                height: height ? height : 82,
                backgroundColor: gradientFill
                  ? 'rgba(0,0,0,0.1)'
                  : defaultTheme.CARD_BG_COLOR,
              },
            ]}
            rippleColor={'rgba(0,0,0,0.25)'}
            onPress={onPress}
            disabled={disabled}>
            <>
              <View style={[styles.tabItem, { opacity: disabled ? 0.5 : 1 }]}>
                {item.icon}
              </View>
              <View style={styles.tabItem}>
                <Text style={[styles.tabText, { opacity: disabled ? 0.5 : 1 }]}>
                  {item.name}
                </Text>
              </View>
            </>
          </TouchableRipple>
        </View>
      </LinearGradient>
    );
  } else {
    return (
      <View
        style={[
          styles.container,
          { flex: fullWidth ? 1 : 0.225, marginHorizontal: fullWidth ? 6 : 0 },
        ]}>
        <View style={styles.rippleWrapper}>
          <TouchableRipple
            style={[
              styles.contentContainer,
              bgColor ? { backgroundColor: bgColor } : null,
              { height: height ? height : 82 },
            ]}
            rippleColor={'rgba(0,0,0,0.25)'}
            onPress={onPress}>
            <>
              <View style={styles.tabItem}>{item.icon}</View>
              <View style={styles.tabItem}>
                <Text style={styles.tabText}>{item.name}</Text>
              </View>
            </>
          </TouchableRipple>
        </View>
      </View>
    );
  }
};

export default MoreGradientTab;
