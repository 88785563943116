import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import MarkerPinImage from './MarkerPinImage';
import MarkerImageItem from './MarkerImageItem';
import styles from '../../styles';

const MarkerItem = ({ marker }) => {
  const [countMarginTop, setMarginTop] = useState(0);

  useEffect(() => {
    let sizeArray = [];

    if (Platform.OS == 'web') {
      // create size object
      if (marker.totalPassportCount > 0) {
        sizeArray.push({
          item: 'passport',
          size: marker.totalPin > 1 ? 104 : 100,
        });
      }
      if (marker.totalCollectibleCount > 0) {
        sizeArray.push({
          item: 'collectible',
          size: marker.totalPin > 1 ? 104 : 100,
        });
      }
      if (marker.totalCouponCount > 0) {
        sizeArray.push({
          item: 'coupon',
          size: marker.totalPin > 1 ? 104 : 100,
        });
      }
      if (marker.totalEventCount > 0) {
        sizeArray.push({
          item: 'event',
          size: marker.totalPin > 1 ? 104 : 100,
        });
      }
      if (marker.totalFeaturedCount > 0) {
        sizeArray.push({
          item: 'featuredOffer',
          size: marker.totalPin > 1 ? 122 : 100,
        });
      }
      if (marker.totalRegularCount > 0) {
        sizeArray.push({
          item: 'regularOffer',
          size: marker.totalPin > 1 ? 104 : 100,
        });
      }

      let marginTop = 0;

      // calculate height based on category.
      sizeArray?.map((x, i) => {
        // check even rows
        if (i % 2 == 0) {
          // get next row.
          if (sizeArray[i + 1]) {
            const xArray = sizeArray[i + 1];
            // compare next rows size with current rows.
            // pick larger size.
            if (xArray?.size > x?.size) {
              marginTop += xArray?.size;
            } else {
              marginTop += x?.size;
            }
          }
        }
      });

      // caculate total rows and increase margin.
      const arrayLength = sizeArray?.length;
      const oddRows = arrayLength ? arrayLength % 2 : 0;
      if (oddRows) {
        marginTop += sizeArray[arrayLength - 1]?.size;
      }

      // calculate margin.
      if (marginTop > 0) {
        let mt = marginTop + (marker.totalPin > 1 ? 44 : 40);
        setMarginTop(mt);
      }
    }
  }, [marker]);

  return (
    <>
      <View
        style={[
          marker.totalPin > 1
            ? styles.mapImageMultipleCardContainer
            : styles.mapImageCardContainer,
          Platform.OS == 'web' ? { marginTop: -countMarginTop } : {},
        ]}>
        {/* passport  */}
        {marker?.totalPassportCount > 0 && (
          <MarkerImageItem
            caption={'Passport'}
            totalPin={marker.totalPin}
            totalItemCount={marker?.totalPassportCount}
            itemList={marker?.passportList}
            multipinWebHeight={104}
          />
        )}
        {/* collectible  */}
        {marker?.totalCollectibleCount > 0 && (
          <MarkerImageItem
            caption={'Collectible'}
            totalPin={marker.totalPin}
            totalItemCount={marker?.totalCollectibleCount}
            itemList={marker?.collectibleList}
            multipinWebHeight={104}
          />
        )}
        {/* featured offers  */}
        {marker?.totalFeaturedCount > 0 && (
          <MarkerImageItem
            caption={'Featured Offer'}
            totalPin={marker.totalPin}
            totalItemCount={marker?.totalFeaturedCount}
            itemList={marker?.featuredOfferList}
            multipinWebHeight={122}
          />
        )}
        {/* regular offers  */}
        {marker?.totalRegularCount > 0 && (
          <MarkerImageItem
            caption={'Offer'}
            totalPin={marker.totalPin}
            totalItemCount={marker?.totalRegularCount}
            itemList={marker?.regularOfferList}
            multipinWebHeight={104}
          />
        )}
        {/* events  */}
        {marker?.totalEventCount > 0 && (
          <MarkerImageItem
            caption={'Event'}
            totalPin={marker.totalPin}
            totalItemCount={marker?.totalEventCount}
            itemList={marker?.eventsList}
            multipinWebHeight={104}
          />
        )}
        {/* coupon  */}
        {marker?.totalCouponCount > 0 && (
          <MarkerImageItem
            caption={'Coupon'}
            totalPin={marker.totalPin}
            totalItemCount={marker?.totalCouponCount}
            itemList={marker?.couponList}
            multipinWebHeight={104}
          />
        )}
      </View>
      {<MarkerPinImage marker={marker} />}
    </>
  );
};

export default MarkerItem;
