import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import MoreScreen from '../screens/MoreScreen';
import ProfileScreen from '../screens/ProfileScreen';
import { ScreenName } from '../enums/screen.enum';
import LinkScreen from '../screens/LinkScreen';
import QRScreen from '../screens/QRScreen';
import TransactionScreen from '../screens/TransactionScreen';
import CatalogScreen from '../screens/CatalogScreen';
import CatalogList from '../components/CatalogList';
import CatalogDetail from '../components/CatalogDetails';
import { Platform } from 'react-native';
import CustomHeader from './AnimatedHeader/CustomHeader';
import LogoutScren from '../screens/LogoutScreen';
import PassportDetail from '../components/Passport/PassportDetail';
import PassportBuySellConfirmation from '../components/Passport/PassportBuySellConfirmation';
import CreatePost from '../components/CreatePost';
import FeaturedOfferDetail from '../components/FeaturedOfferDetail';
import PrivateMessageSubUnsub from '../components/Passport/Tabs/DirectMessaging/PrivateMessage/PrivateMessageSubUnsub';
import chatroomSubscribe from '../components/PremiumChatroom/chatroomSubscribe';
import WhitelistedEvents from '../screens/WhitelistedEvents';
import TicketScanScreen from '../screens/TicketScanScreen';

const Stack = createStackNavigator();

function MoreNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={'MoreTab'}
      screenOptions={{
        headerShown: true,
        headerStyle: {
          height: Platform.OS === 'ios' ? 120 : 70,
          backgroundColor: 'transparent',
          borderBottomWidth: 0,
        },
        headerMode: 'float',
        header: props => (
          <CustomHeader
            {...props}
            // setShowNotificationModal={setShowNotificationModal}
          />
        ),
      }}>
      <Stack.Screen name={'MoreTab'} component={MoreScreen} />
      <Stack.Screen
        name={ScreenName.PROFILE_SCREEN}
        component={ProfileScreen}
      />
      <Stack.Screen
        name={ScreenName.SUPPORT_SCREEN}
        initialParams={{ url: 'https://loot8.io/support' }}
        component={LinkScreen}
      />
      <Stack.Screen
        name={ScreenName.TERMS_OF_SERVICES}
        initialParams={{ url: 'https://loot8.io/terms-of-service' }}
        component={LinkScreen}
      />
      <Stack.Screen
        name={ScreenName.PRIVACY_NOTICE}
        initialParams={{ url: 'https://loot8.io/privacy-policy' }}
        component={LinkScreen}
      />
      <Stack.Screen name={ScreenName.SCAN_QR} component={QRScreen} />
      <Stack.Screen
        name={ScreenName.ORDERS_SCREEN}
        component={TransactionScreen}
      />
      <Stack.Screen name={'CatalogTab'} component={CatalogScreen} />
      <Stack.Screen name={'CatalogList'} component={CatalogList} />
      <Stack.Screen name={'CatalogDetails'} component={CatalogDetail} />
      <Stack.Screen name={ScreenName.LOGOUT_SCREEN} component={LogoutScren} />
      <Stack.Screen name="PassportDetail" component={PassportDetail} />
      <Stack.Screen
        name="PassportBuySellConfirmation"
        component={PassportBuySellConfirmation}
      />
      <Stack.Screen name="CreatePostScreen" component={CreatePost} />
      <Stack.Screen
        name="FeaturedOfferDetail"
        component={FeaturedOfferDetail}
      />
      <Stack.Screen
        name="PrivateMessageSubUnsub"
        component={PrivateMessageSubUnsub}
      />
      <Stack.Screen
        name={ScreenName.PREMIUMCHAT_SUB_UNSUB}
        component={chatroomSubscribe}
      />
      <Stack.Screen
        name={ScreenName.WHITELISTED_EVENTS}
        component={WhitelistedEvents}
      />
      <Stack.Screen
        name={ScreenName.TICKET_SCAN}
        component={TicketScanScreen}
      />
    </Stack.Navigator>
  );
}

export default MoreNavigator;
