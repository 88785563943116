import React, { useEffect, useState } from 'react';
import { FlatList, Image, Platform, Pressable, Text, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { TouchableRipple } from 'react-native-paper';
import { styles } from './styles';
import CachedImage from '../CachedImage';
import { defaultTheme } from '../../themes/loot8';
import { VerifiedSvg } from '../../assets/svg/HomeSvgs';
import GradientSocialButton from '../GradientSocialButton';
import useIsResponsive from '../../hooks/useIsResponsive';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import { ICollectibleDetail } from '../../interfaces/ICollectibleDetail.interface';
import cameraIcon from '../../assets/camera.png';
import { ScreenName } from '../../enums/screen.enum';
import { useNavigation } from '@react-navigation/native';
import { FontAwesome5 } from '@expo/vector-icons';
import { SheetManager } from 'react-native-actions-sheet';
import * as Device from 'expo-device';
import { PassportSubscriptionActions } from '../../enums/passportCategory.enum';

type ExpassDetailsCardProps = {
  selectPassport: ICollectibleDetail;
  type?: string;
  socialLinksList: Array<any>;
};

const ExpassDetailsCard: React.FC<ExpassDetailsCardProps> = ({
  selectPassport,
  type = 'passport',
  socialLinksList,
}) => {
  const navigation = useNavigation();
  const isResponsive = useIsResponsive();
  const activeWidth = useActiveDimensions().width;
  const imageSize = isResponsive ? 250 : activeWidth / 2.6;
  const [selectedEXPass, setSelectedEXPass] = useState(null);
  const [isListed, setIsListed] = useState(false);

  const navigateToBarcodeTab = () => {
    // @ts-ignore
    navigation.navigate('QR', {
      screen: ScreenName.BARCODE_TAB,
      params: {
        from: ScreenName.PASSPORT_DETAIL,
      },
    });
  };

  const onSharePassportPress = async () => {
    await SheetManager.show('SharePassportSheet', {
      payload: {
        selectedPassport: selectPassport,
      },
    });
  };

  const onListForSalePressed = async () => {
    if (selectedEXPass) {
      await SheetManager.show('ListingModalSheet', {
        payload: {
          itemObject: selectedEXPass,
          navigation,
          type: 'Expass',
          updateStateDataFunc: updatedObject => {
            setSelectedEXPass(prevState => ({
              ...prevState,
              marketPlaceConfig: updatedObject.marketPlaceConfig,
            }));
          },
        },
      });
    }
  };

  const OnListOnMarketplace = () => {
    setTimeout(
      async () => {
        await onListForSalePressed();
      },
      Platform.OS === 'ios' ? 500 : 10,
    );
  };

  const onUnsubscribePressed = async () => {
    // @ts-ignore
    navigation.navigate(ScreenName.PASSPORT_BUY_SELL_CONFIRMATION, {
      selectedEXPass: selectPassport,
      passportAction: PassportSubscriptionActions.UNSUBSCRIBE,
      setSearchPassportText: '',
    });
  };

  const checkListedStatus = () => {
    if (selectedEXPass) {
      const checkIfListed =
        selectedEXPass &&
        selectedEXPass.collectibleIds.length > 0 &&
        selectedEXPass.marketPlaceConfig?.listingIndex &&
        selectedEXPass.marketPlaceConfig.listingIndex.findIndex(
          obj => obj.tokenId === Number(selectedEXPass.collectibleIds[0]),
        ) !== -1;
      setIsListed(checkIfListed);
    }
  };

  useEffect(() => {
    if (selectPassport) {
      setSelectedEXPass(selectPassport);
    }
  }, [selectPassport]);

  useEffect(() => {
    checkListedStatus();
    return () => {
      setIsListed(false);
    };
  }, [selectPassport, selectedEXPass]);

  return (
    <View
      style={[
        styles.row,
        {
          alignItems: isResponsive ? 'flex-start' : 'center',
        },
      ]}>
      <CachedImage
        isBackground
        noImageOnError={true}
        source={{ uri: selectPassport?.image }}
        imageStyle={{}}
        style={{
          ...styles.image,
          maxWidth: imageSize,
          minHeight: imageSize,
          height: imageSize,
        }}
        contentFit={'cover'}>
        {/* BarCode Button */}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 5,
            marginHorizontal: 5,
          }}>
          <Pressable
            onPress={() => {
              navigateToBarcodeTab();
            }}>
            <Image
              source={cameraIcon}
              resizeMode="contain"
              style={{
                width: 18,
                height: 18,
                tintColor: 'rgba(255,255,255,1)',
              }}
            />
          </Pressable>
          {Platform.OS !== 'web' && !Device.isDevice && (
            <Pressable onPress={onSharePassportPress}>
              <FontAwesome5
                name="share-alt"
                size={13}
                color={defaultTheme.PRIMARY_TEXT_COLOR}
                style={{
                  transform: [{ rotate: '180deg' }],
                  opacity: 0.85,
                }}
              />
            </Pressable>
          )}
        </View>
      </CachedImage>
      <View style={styles.rightContainer}>
        <View style={[{ flexDirection: isResponsive ? 'row' : 'column' }]}>
          <View
            style={[
              { flex: 1 },
              isResponsive ? {} : { justifyContent: 'center' },
            ]}>
            <Text style={styles.title} ellipsizeMode="tail">
              {type == 'collectibles'
                ? `${selectPassport?.name} ${selectPassport?.subTitle} ${
                    selectPassport?.fromExternalWallet ? 'External' : ''
                  }`
                : `${selectPassport?.name?.trim()} #${
                    selectPassport?.subTitle?.trim()
                      ? selectPassport.subTitle.split('#')[1]
                      : ''
                  }`}{' '}
              {selectPassport?.thirdPartyVerifiedURL?.length > 0 && (
                <>
                  {' '}
                  <VerifiedSvg size={defaultTheme.FONT_SIZE_XMEDIUM} />
                </>
              )}
            </Text>
            <Text style={styles.creatorType}>{selectPassport?.category}</Text>
            {/* <Text style={styles.subtitle}>@hunterdickinson1</Text> */}
          </View>
          <View
            style={[
              { flex: 1 },
              isResponsive
                ? { flexDirection: 'column-reverse' }
                : { justifyContent: 'center' },
            ]}>
            <FlatList
              style={{ marginTop: isResponsive ? 25 : 10, flexGrow: 0 }}
              contentContainerStyle={{
                flexGrow: 1,
                justifyContent: isResponsive ? 'flex-end' : 'space-between',
              }}
              horizontal
              scrollEnabled={false}
              data={socialLinksList}
              renderItem={({ item }) => (
                <GradientSocialButton size={30} item={item} />
              )}
              ItemSeparatorComponent={() =>
                isResponsive && <View style={{ marginHorizontal: 10 }} />
              }
              keyExtractor={item => item.id}
            />
            <View style={[styles.row, isResponsive && { marginTop: 0 }]}>
              <CustomButton
                onPress={onUnsubscribePressed}
                label={isResponsive ? 'Unsubscribe' : 'Unsub'}
                disabled={isListed}
              />
              <CustomButton
                onPress={OnListOnMarketplace}
                label={
                  isListed && isResponsive
                    ? 'Unlist from Marketplace'
                    : isListed && !isResponsive
                      ? 'Unlist'
                      : !isListed && isResponsive
                        ? 'List on marketplace'
                        : 'List'
                }
                gradient
              />
            </View>
          </View>
        </View>
        {isResponsive && (
          <View>
            <Text style={styles.title}>Description</Text>
            <Text style={styles.description}>{selectPassport?.details}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default ExpassDetailsCard;

interface FollowUnFollowButtonProps {
  onPress: () => void;
  gradient?: boolean;
  disabled?: boolean;
  label: string;
}

const CustomButton: React.FC<FollowUnFollowButtonProps> = ({
  onPress,
  gradient = false,
  label,
  disabled,
}) => {
  return (
    <LinearGradient
      colors={
        gradient
          ? [defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]
          : [defaultTheme.SECONDARY_COLOR, defaultTheme.SECONDARY_COLOR]
      }
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={styles.btnContainer}>
      <TouchableRipple
        disabled={disabled}
        style={styles.ripple}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}>
        <Text style={styles.btnText}>{label}</Text>
      </TouchableRipple>
      {disabled && <View style={styles.disabled} />}
    </LinearGradient>
  );
};
