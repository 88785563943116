import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { TouchableRipple } from 'react-native-paper';
import { FlatList } from 'react-native-gesture-handler';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import { defaultTheme } from '../../themes/loot8';
import { styles } from './styles';
import useIsResponsive from '../../hooks/useIsResponsive';

const AnimatedUnderlineTabs = ({ data, height = 48, changeSlidesOnPress }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const isResponsive = useIsResponsive();
  const activeWidth = useActiveDimensions().width;
  const tabWidth = isResponsive
    ? defaultTheme.CONTENT_CONTAINER_MAX_WIDTH / data.length -
      (defaultTheme.HORIZONTAL_PADDING * 2) / data.length
    : activeWidth / data.length -
      (defaultTheme.HORIZONTAL_PADDING * 2) / data.length;
  const leftProgress = useSharedValue(0);

  const leftAnimatedStyles = useAnimatedStyle(() => {
    return {
      left: leftProgress.value,
    };
  });
  const GradientBg = () => {
    return (
      <Animated.View
        style={[styles.gradientBg, leftAnimatedStyles, { width: tabWidth }]}>
        <LinearGradient
          colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
          start={{ x: 0.2, y: 0.0001 }}
          end={{ x: 1, y: 0.0001 }}
          style={{ flex: 1 }}
        />
      </Animated.View>
    );
  };
  const onTabPress = index => {
    leftProgress.value = withSpring(tabWidth * index);
    changeSlidesOnPress(index);
    setActiveIndex(index);
  };
  useEffect(() => {
    onTabPress(activeIndex);
  }, [activeWidth, isResponsive]);
  return (
    <View style={[styles.contentContainer, { height: height }]}>
      <FlatList
        style={{ flex: 1 }}
        contentContainerStyle={{ flexGrow: 1 }}
        horizontal
        scrollEnabled={false}
        data={data}
        initialNumToRender={data.length}
        renderItem={({ item, index }) => (
          <TabItem
            title={item.title}
            onPress={() => onTabPress(index)}
            tabWidth={tabWidth}
            height={height}
          />
        )}
        keyExtractor={item => item.id}
      />

      <GradientBg />
    </View>
  );
};

export default AnimatedUnderlineTabs;

const TabItem = ({ title, onPress, tabWidth, height }) => {
  return (
    <View style={[styles.activeBg, { width: tabWidth }]}>
      <TouchableRipple
        style={styles.itemContainer}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}>
        <Text
          style={[
            styles.itemText,
            height < 40 && { fontSize: defaultTheme.FONT_SIZE_XXXSMALL },
          ]}>
          {title}
        </Text>
      </TouchableRipple>
    </View>
  );
};
