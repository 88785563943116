import { createSelector } from 'reselect';

import {
  GetEncodedHash,
  GetEntityOnboardedLogData,
  GetCreateEntityDataMessage,
  GetOnboardEntityDataMessage,
} from '../helpers/MsgHelper';
import { setAll } from './helpers';
import { SendMetaTX } from './AppSlice';
import { RootState } from '../../store';
import { loadAllPassportDetails } from './PassportSlice';
import { getIPFSData, getIPFSLink } from '../../helpers/ipfs';
import { IWalletBaseAsyncThunk } from '../../slices/interfaces';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAllEntityDetails } from '../../helpers/GraphQLHelper';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { ICollectionBaseAsyncThunk, IMessageMetaData } from './interfaces';
import { IEntity, IEntitySliceData } from '../interfaces/IEntity.interface';
import {
  addresses,
  ZERO_ADDRESS,
  getAppConfiguration,
} from '../../appconstants';
import {
  Entity__factory,
  EntityRegistry__factory,
  Loot8Onboarder__factory,
} from '../../typechain';

export const checkSuperAdmin = async ({
  networkID,
  provider,
  walletAddress,
  entityAddress,
}: {
  networkID;
  provider;
  walletAddress;
  entityAddress;
}): Promise<any> => {
  const entity = Entity__factory.connect(entityAddress, provider);
  const isSuperAdmin = await entity.managers(walletAddress);

  return isSuperAdmin;
};

export const checkInviteCodeValid = async ({
  networkID,
  provider,
  inviteCode,
  isOnboarding,
}): Promise<boolean> => {
  const loot8Onboarder = Loot8Onboarder__factory.connect(
    addresses[networkID].Onboarder,
    provider,
  );

  const encryptedHashCode = GetEncodedHash(inviteCode);
  const isValidHashCode = await loot8Onboarder.inviteHashes(encryptedHashCode);
  const isRedeemed = await loot8Onboarder.redeemed(encryptedHashCode);

  if (isOnboarding) {
    return isValidHashCode && !isRedeemed;
  }
  return isValidHashCode;
};

export const onboardEntity = createAsyncThunk(
  'Entity/onboardEntity',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      inviteCode,
      entityName,
      dataURI,
    }: any,
    { dispatch },
  ): Promise<any> => {
    const creationData = GetCreateEntityDataMessage(
      [['', ''], 0],
      entityName,
      dataURI,
      address,
      addresses[networkID].DAOAuthority,
      addresses[networkID].User,
      addresses[networkID].Onboarder,
    );

    const data = GetOnboardEntityDataMessage(inviteCode, creationData);

    if (data) {
      let msg: IMessageMetaData = {
        to: addresses[networkID].Onboarder,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      LogToLoot8Console(msg);
      let res = await dispatch(SendMetaTX(msg));
      if (res && res.payload?.eventLogs) {
        const createdEntity = GetEntityOnboardedLogData(res.payload?.eventLogs);
        if (createdEntity === ZERO_ADDRESS) {
          LogToLoot8Console('Entity Onboard: transaction failed...');
        }
        return true;
      }
    }
    return false;
  },
);

export const getAllEntityData = async (
  networkID,
  provider,
): Promise<{
  allEntityAddresses: string[];
  allEntityData: EntityDetails[];
}> => {
  // Fetch all entities list from blockchain
  const EntityRegistryContract = EntityRegistry__factory.connect(
    addresses[networkID].EntityRegistry,
    provider,
  );
  const allEntityAddresses = await EntityRegistryContract.getAllEntities(true);

  let entityData: EntityDetails[] = [];
  try {
    let appConfig;
    try {
      appConfig = await getAppConfiguration();
    } catch (err) {
      LogToLoot8Console('getAllEntityData: Error while reading app config');
    }

    if (
      appConfig &&
      appConfig.indexerService &&
      appConfig.indexerService.entity
    ) {
      entityData = await fetchAllEntityDetails(true);
    }
  } catch (err) {
    LogToLoot8Console('getAllEntityData', err.name, err.message, err.stack);
  }

  // Return all entities from chain & Data from indexer
  return { allEntityAddresses: allEntityAddresses, allEntityData: entityData };
};

export const getEntityDetails = async ({
  networkID,
  provider,
  address,
  dispatch,
}: {
  networkID;
  provider;
  address;
  dispatch;
}): Promise<any> => {
  let allentityDetails: IEntity[] = [];

  const { allEntityAddresses, allEntityData } = await getAllEntityData(
    networkID,
    provider,
  );
  dispatch(setAllEntityAddresses(allEntityAddresses));

  if (allEntityAddresses && allEntityAddresses.length > 0) {
    await Promise.all(
      allEntityAddresses
        .filter(entityAddress => entityAddress !== ZERO_ADDRESS)
        .map(async (entityAddress, index) => {
          const entity = Entity__factory.connect(entityAddress, provider);
          const entityAdmins = await entity.getAllEntityAdmins(true);
          if (
            entityAdmins &&
            entityAdmins.findIndex(
              obj => obj?.toLowerCase() === address?.toLowerCase(),
            ) > -1
          ) {
            let entityData: IEntity | any = {};

            if (
              allEntityData &&
              allEntityData?.length > 0 &&
              allEntityData.findIndex(
                item =>
                  item.address?.toLowerCase() === entityAddress?.toLowerCase(),
              ) > -1
            ) {
              const data = allEntityData.find(
                e => e.address?.toLowerCase() === entityAddress?.toLowerCase(),
              );
              entityData = { ...data };
            } else {
              const entity = Entity__factory.connect(entityAddress, provider);
              const blockData = await entity.getEntityData();
              entityData = {
                name: blockData?.name,
                dataURI: blockData?.dataURI,
                address: entityAddress,
                isActive: blockData?.isActive,
                walletAddress: blockData?.walletAddress,
              };
            }
            if (entityData) {
              const entityDataURI = entityData?.dataURI;
              if (
                entityDataURI &&
                entityDataURI !== '' &&
                entityDataURI !== 'ipfs://'
              ) {
                let response = await getIPFSData(entityDataURI);
                if (response) {
                  let responseJson = await response.json();
                  if (responseJson) {
                    entityData.description = responseJson?.description ?? '';
                    entityData.image = responseJson?.image
                      ? getIPFSLink(responseJson.image)
                      : '';
                    entityData.backgroundimage = responseJson?.meta
                      ?.backgroundimage
                      ? getIPFSLink(responseJson.meta.backgroundimage)
                      : '';
                    entityData.logo = responseJson?.meta?.logo
                      ? getIPFSLink(responseJson.meta.logo)
                      : '';
                  }
                  if (
                    entityData?.entityOnboarded === undefined ||
                    entityData?.entityOnboarded === null
                  ) {
                    const allentity = EntityRegistry__factory.connect(
                      addresses[networkID].EntityRegistry,
                      provider,
                    );
                    const entityOnboarded = await allentity.isOnboardedEntity(
                      entityAddress,
                    );
                    entityData.entityOnboarded = entityOnboarded;
                  }
                }
              }
              entityData.index = index;
              allentityDetails.push(entityData);
              dispatch(pushEntityData(entityData));
            }
          }
        }),
    );
  }
  return allentityDetails;
};

export const loadEntityDetails = createAsyncThunk(
  'app/loadEntity',
  async (
    { networkID, provider, address, wallet }: IWalletBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const allentityDetails: IEntity[] = await getEntityDetails({
      networkID,
      provider,
      address,
      dispatch,
    });
    if (allentityDetails && allentityDetails.length > 0) {
      allentityDetails.sort((a, b) => a.index - b.index);
      if (allentityDetails.length === 1) {
        const selectedEntity = {
          EntityData: allentityDetails[0],
          EntityAddress: allentityDetails[0]?.address,
          AuthorizedEntityAdmin: true,
        };
        dispatch(setSelectedEntity(selectedEntity));
      }
    }

    LogToLoot8Console('Entity slice', allentityDetails);

    return { AllEntityData: allentityDetails };
  },
);

// load est portal passports / loadAllDetails
export const loadAllDetails = createAsyncThunk(
  'app/loadAllDetails',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      isCache,
      isSuperAdmin,
    }: ICollectionBaseAsyncThunk,
    { getState, dispatch },
  ): Promise<any> => {
    const state = getState() as RootState;
    const entityAddress = state.EstPortalEntity.EntityAddress;
    await dispatch(
      loadAllPassportDetails({
        networkID,
        provider,
        address,
        wallet,
        entityAddress,
        isCache,
        isSuperAdmin,
      }),
    );
  },
);

const initialState: IEntitySliceData = {
  AllEntityData: null,
  EntityData: null,
  EntityAddress: '',
  isSuperAdmin: false,
  AuthorizedEntityAdmin: false,
  loading: false,
  TokenPrice: 0,
  allEntityAddresses: [],
};

const EstPortalEntitySlice = createSlice({
  name: 'Entity',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    setSuperAdmin(state, action) {
      state.isSuperAdmin = action?.payload;
    },
    setAllEntityAddresses(state, action) {
      state.allEntityAddresses = action?.payload;
    },
    pushEntityData(state, action) {
      let allEntityData = state.AllEntityData ?? [];
      allEntityData.push(action.payload);
      state.AllEntityData = allEntityData.sort(
        (a, b) => Number(a.index) - Number(b.index),
      );
    },
    setSelectedEntity(state, action) {
      state.EntityData = action.payload.EntityData;
      state.EntityAddress = action.payload.EntityAddress;
      state.AuthorizedEntityAdmin = action.payload.AuthorizedEntityAdmin;
    },
  },
  extraReducers: builder => {
    //   builder
    //     .addCase(loadEntityDetails.pending, (state: { loading: boolean; }) => {
    //       state.loading = true;
    //     })
    //     .addCase(loadEntityDetails.fulfilled, (state, action) => {
    //       state.loading = false;
    //     })
    //     .addCase(loadEntityDetails.rejected, (state: { loading: boolean; }, { error }: any) => {
    //       state.loading = false;
    //       LogToConsoleError("loadEntityDetails", error.name, error.message, error.stack);
    //     })
    //     .addCase(updateEntityDataUri.pending, (state: { loading: boolean; }) => {
    //       state.loading = true;
    //     })
    //     .addCase(updateEntityDataUri.fulfilled, (state, action) => {
    //       state.loading = false;
    //     })
    //     .addCase(getLoot8TokenPrice.fulfilled, (state, action) => {
    //       state.TokenPrice = action.payload.TokenPrice;
    //     })
  },
});

export const EstPortalEntitySliceReducer = EstPortalEntitySlice.reducer;

const baseInfo = (state: RootState) => state.Entity;

export const {
  fetchAppSuccess,
  setSelectedEntity,
  setSuperAdmin,
  pushEntityData,
  setAllEntityAddresses,
} = EstPortalEntitySlice.actions;

export const getEntityState = createSelector(baseInfo, Entity => Entity);
