import React, { useEffect, useState } from 'react';
import { Text, View, ScrollView, Pressable } from 'react-native';

import styles from '../styles';
import AppLoaderComponent from '../components/Loader';
import FeaturedOffer from '../components/FeaturedOffer';
import HOCContainer from '../components/HOCContainer';
import { UICustomOfferType } from '../enums/offers.enum';
import { useAppDispatch, useAppSelector } from '../hooks';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { getUnsubsribedNotifications } from '../slices/NotificationSlice';
import { ModalDisconnected } from '../components/Modal/DisconnectedModal';

function OffersScreen({ navigation, route }) {
  const {
    connected,
    SelectedPassport,
    networkId,
    staticProvider,
    address,
    wallet,
  } = useWeb3AuthContext();
  const loadingOffer = useAppSelector(state => state.Offers.loading);

  const [selectedTab, setSelectedTab] = useState(
    route?.params?.landingTab
      ? route.params.landingTab
      : UICustomOfferType.FEATURED_OFFER,
  );

  const onSelectedTab = (offerType: UICustomOfferType) => {
    setSelectedTab(offerType);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (connected) {
      dispatch(
        getUnsubsribedNotifications({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
        }),
      );
    }
  });

  useEffect(() => {
    if (route?.params?.landingTab) {
      setSelectedTab(route?.params?.landingTab);
    }
  }, [route?.params?.landingTab]);

  const onClose = () => {
    if (route.params?.updateParent) {
      route.params?.updateParent({ fromCollectibleDetails: true });
    }
    navigation.goBack();
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.offersMainContainer}>
        {!loadingOffer &&
          (SelectedPassport ? (
            <>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 10,
                  paddingHorizontal: 15,
                }}>
                <View style={{ flex: 1 }}>
                  {SelectedPassport ? (
                    <Text style={[styles.contentHeaderText]}>
                      {SelectedPassport.name} - All Offers
                    </Text>
                  ) : (
                    <Text style={[styles.contentHeaderText]}>All Offers</Text>
                  )}
                </View>
              </View>

              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ paddingHorizontal: 15 }}>
                {/* featured offer */}
                {selectedTab === UICustomOfferType.FEATURED_OFFER &&
                  SelectedPassport && (
                    <FeaturedOffer
                      navigation={navigation}
                      SelectedPassport={SelectedPassport}
                      updateParent={route.params?.updateParent}
                    />
                  )}
              </ScrollView>
            </>
          ) : (
            <>
              <View style={[styles.mainContainer, { marginTop: 10 }]}>
                <View
                  style={{
                    padding: 12,
                    width: '100%',
                    borderRadius: 5,
                    marginVertical: 3,
                    backgroundColor: '#FFFFFF15',
                  }}
                />
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={{
                      padding: 12,
                      width: '44%',
                      borderRadius: 5,
                      marginRight: '1%',
                      marginVertical: 3,
                      backgroundColor: '#FFFFFF15',
                    }}
                  />
                  <View
                    style={{
                      padding: 12,
                      width: '55%',
                      borderRadius: 5,
                      marginVertical: 3,
                      backgroundColor: '#FFFFFF15',
                    }}
                  />
                </View>

                <View
                  style={{
                    backgroundColor: '#FFFFFF15',
                    borderRadius: 5,
                    width: '100%',
                    marginVertical: 3,
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}>
                  <Text
                    style={[styles.contentHeaderText, { color: '#FFFFFF' }]}>
                    Please select a{' '}
                  </Text>
                  <Pressable
                    onPress={() => {
                      navigation.navigate('HomeTab');
                    }}>
                    <Text
                      style={[
                        styles.contentHeaderText,
                        { color: '#FFFFFF', textDecorationLine: 'underline' },
                      ]}>
                      Passport
                    </Text>
                  </Pressable>
                  <Text
                    style={[styles.contentHeaderText, { color: '#FFFFFF' }]}>
                    {' '}
                    to
                  </Text>
                </View>

                <View
                  style={{
                    backgroundColor: '#FFFFFF15',
                    borderRadius: 5,
                    width: '100%',
                    marginVertical: 3,
                  }}>
                  <Text
                    style={[styles.contentHeaderText, { color: '#FFFFFF' }]}>
                    view related offers
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={{
                      backgroundColor: '#FFFFFF15',
                      borderRadius: 5,
                      width: '34%',
                      padding: 12,
                      marginVertical: 3,
                      marginRight: '1%',
                    }}
                  />
                  <View
                    style={{
                      backgroundColor: '#FFFFFF15',
                      borderRadius: 5,
                      width: '65%',
                      padding: 12,
                      marginVertical: 3,
                    }}
                  />
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={{
                      backgroundColor: '#FFFFFF15',
                      borderRadius: 5,
                      width: '69%',
                      padding: 12,
                      marginVertical: 3,
                      marginRight: '1%',
                    }}
                  />
                  <View
                    style={{
                      backgroundColor: '#FFFFFF15',
                      borderRadius: 5,
                      width: '30%',
                      padding: 12,
                      marginVertical: 3,
                    }}
                  />
                </View>
              </View>
            </>
          ))}
        {loadingOffer && SelectedPassport ? <AppLoaderComponent /> : null}
      </View>
    </View>
  );
}

export default HOCContainer({
  OriginalComponent: OffersScreen,
});
