import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import CustomHeader from './AnimatedHeader/CustomHeader';
import QRScreen from '../screens/QRScreen';

const Stack = createStackNavigator();

function QRNavigator() {
  const navigation = useNavigation();

  useEffect(() => {
    navigation.addListener('blur', () => {
      if (Platform.OS === 'web') {
        history.replaceState(null, document.title, window.location.origin);
      }
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      initialRouteName="BarcodeTab"
      screenOptions={{
        headerShown: true,
        headerStyle: {
          borderBottomWidth: 0,
          backgroundColor: 'transparent',
          height: Platform.OS === 'ios' ? 120 : 70,
        },
        headerMode: 'float',
        header: props => <CustomHeader {...props} />,
      }}>
      <Stack.Screen name="BarcodeTab" component={QRScreen} />
    </Stack.Navigator>
  );
}

export default QRNavigator;
