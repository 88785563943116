import { FlatList, Image, ScrollView, Text, View } from 'react-native';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Constants from 'expo-constants';
import { LOGIN_PROVIDER } from '@web3auth/react-native-sdk';

import { WIDTH } from '../../themes/loot8';
import logoImg from '../../assets/images/loot8Logo.png';
import { styles } from './styles';
import { RESPONSIVE, RESPONSIVE_WIDTH } from '../../appconstants';
import GradientButton from '../GradientButton';
import TextInputComponent from '../TextInputComponent';
import GradientBorderBox from '../GradientBorderBox';
import { signInProviderData } from './data';
import warningImage from '../../assets/warning.png';

interface AuthComponentProps {
  onLoginProviderSelected: (
    provider?: any,
    loginEmail?: string,
    loginConfig?: any,
  ) => void;
  loginEmail: string;
  setLoginEmail: Dispatch<SetStateAction<string>>;
  onOpenWebLink: (link: string) => void;
}

const AuthComponent: React.FC<AuthComponentProps> = ({
  onLoginProviderSelected,
  loginEmail,
  setLoginEmail,
  onOpenWebLink,
}) => {
  const [isEmailValid, setIsEmailValid] = useState(false);

  //Checking if email is valid or not ==> START
  const checkEmailValidity = useCallback(() => {
    if (validEmail(loginEmail)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  }, [loginEmail]);

  useEffect(() => {
    checkEmailValidity();
  }, [loginEmail]);
  //Checking if email is valid or not ==> END

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={styles.scrollContainer}
      bounces={false}
      contentContainerStyle={styles.scrollContentContainer}>
      <Image source={logoImg} style={styles.logoImg} resizeMode="contain" />
      <View
        style={[
          RESPONSIVE == 'true' && WIDTH > parseInt(RESPONSIVE_WIDTH)
            ? styles.desktopContainer
            : styles.contentContainer,
          { flex: 1 },
        ]}>
        <Text style={styles.legacyText}>
          Sign in with your legacy email address
        </Text>
        <View style={styles.spacingVertical} />
        <TextInputComponent
          placeholder="Email"
          value={loginEmail}
          setOnChange={setLoginEmail}
          type="secondary"
        />
        <View style={styles.spacingVertical} />
        <GradientButton
          disabled={!isEmailValid}
          buttonLabel={'Continue with email'}
          onPress={() => {
            onLoginProviderSelected(
              LOGIN_PROVIDER.EMAIL_PASSWORDLESS,
              loginEmail,
            );
          }}
        />
        <View style={styles.row}>
          <View style={styles.line} />
          <Text style={styles.lineText}>OR</Text>
          <View style={styles.line} />
        </View>
        <Text style={styles.socialText}>
          Sign-In to <Text style={styles.socialTextBold}>LOOT8</Text> with your
          {'\n'}social accounts
        </Text>
        <FlatList
          numColumns={4}
          data={signInProviderData}
          renderItem={({ item }) => (
            <GradientBorderBox
              item={item}
              width={'23.5%'}
              onPress={() => onLoginProviderSelected(item.loginProvider)}
            />
          )}
          keyExtractor={item => item.id.toString()}
          columnWrapperStyle={{ justifyContent: 'space-between' }}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          style={styles.flatlistStyle}
          ItemSeparatorComponent={() => <View style={{ marginVertical: 5 }} />}
        />
        <View style={[styles.row, { alignItems: 'flex-start' }]}>
          <Image
            source={warningImage}
            resizeMode="contain"
            style={styles.warningImage}
          />
          <Text style={styles.warningText}>
            We do not collect data. LOOT8 values your privacy. We do not collect
            any personal data during the login process. Logging in is solely
            used to create your unique LOOT8 wallet address
          </Text>
        </View>
      </View>
      <View style={styles.bottomTextContainer}>
        <Text style={styles.bottomText}>
          By continuing you agree to LOOT8’s
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={[styles.bottomText, styles.bottomBoldText]}
            onPress={() =>
              onOpenWebLink(Constants.expoConfig.extra.TERMS_OF_SERVICE)
            }>
            Terms of Use
          </Text>
          <Text style={styles.bottomText}> and</Text>
          <Text
            style={[styles.bottomText, styles.bottomBoldText]}
            onPress={() =>
              onOpenWebLink(Constants.expoConfig.extra.PRIVACY_POLICY_URL)
            }>
            {' '}
            Privacy Policy
          </Text>
        </View>
        <Text style={styles.bottomText}>
          Need Help?{' '}
          <Text
            style={styles.bottomBoldText}
            onPress={() => onOpenWebLink(Constants.expoConfig.extra.SUPPORT)}>
            Contact Us!
          </Text>
        </Text>
      </View>
    </ScrollView>
  );
};

export default AuthComponent;

//REGEX
const validEmail = text => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  if (reg.test(text) === false) {
    return false;
  }
  return true;
};
