import React, { useEffect, useRef, useState } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View, Platform, Text, StyleSheet } from 'react-native';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import { CatalogTypes } from '../../enums/catalog.enum';
import { ScreenName } from '../../enums/screen.enum';
import { PublicMarketPlaceTabs } from '../../enums/marketPlace.enum';
import { BlurView } from 'expo-blur';
import { ReShareWebBlurView } from '../WebBlurView';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
} from 'react-native-actions-sheet';
import {
  ToastCustomMessageType,
  getAnynetStaticProvider,
} from '../../appconstants';
import { ConfirmButton } from '../ExpassListPopupContent';
import ExpassListPopupConfimation from '../ExpassListPopupConfimation';
import { wait } from '../../helpers/ipfs';
import {
  getCollectibleDetails,
  getThirdPartyCollectiableDetails,
  loadPassportOfferList,
  loadThirpartyCollectibleList,
} from '../../slices/OfferSlice';
import {
  clearSpecificCatalogCollectible,
  clearSpecificThirdPartyCollectible,
} from '../../slices/CatalogSlice';
import { showToastMessage } from '../../helpers/Gadgets';
import { LogCustomError } from '../../helpers/AppLogger';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { hideTransferModal, showTransferModal } from '../../slices/ModalsSlice';
import { ReshareModalSheetContent } from '../Friends/modal';
import { transferNFTToFriend } from '../../slices/friendsSlice';

export const ConfirmationModal = ({
  showConfirmation,
  setShowConfirmation,
  listed,
  catalogType,
  selectedCatalog,
  failedOp,
  navigation,
  navigationFrom,
  availableCatalogs,
}) => {
  return (
    <View style={{ justifyContent: 'center', alignItems: 'center' }}>
      {failedOp ? (
        <View
          style={{
            margin: 10,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={[
              componentStyles.confirmModalHeading,
              { paddingVertical: 3 },
            ]}>{`Failed!!`}</Text>
          <Text style={[styles.confirmModalText, { paddingVertical: 3 }]}>
            {catalogType == CatalogTypes.COLLECTIBLES
              ? 'Your collectible'
              : 'Your ExPass'}
            {listed ? ' unlisting failed' : ' listing failed'}
          </Text>
        </View>
      ) : (
        <View
          style={{
            margin: 10,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {!listed && (
            <Text
              style={[
                componentStyles.confirmModalHeading,
                { paddingVertical: 3 },
              ]}>{`Congratulations!!`}</Text>
          )}
          <Text
            style={[
              componentStyles.confirmModalHeading,
              { paddingVertical: 3 },
            ]}>
            {catalogType == CatalogTypes.COLLECTIBLES
              ? 'Your collectible'
              : 'Your ExPass'}
            {' is successfully '}
            {listed ? 'unlisted' : 'listed'}
          </Text>
          {/* TODO: Need to change this later */}
          <Text
            style={[
              componentStyles.confirmModalText,
              { paddingVertical: 3, color: defaultTheme.PRIMARY_TEXT_COLOR },
            ]}>{`Please wait a few minutes while your ${
            catalogType == CatalogTypes.COLLECTIBLES ? 'collectible' : 'ExPass'
          } is ${listed ? 'deleted from' : 'uploaded to'} LOOT8!`}</Text>
          <View style={{ width: 250, paddingTop: 20 }}>
            <ConfirmButton
              onPress={() => {
                navigation.navigate('WalletTab', {
                  screen: ScreenName.MARKET_PLACE,
                  params: {
                    selectedPassportCategory:
                      catalogType == CatalogTypes.COLLECTIBLES
                        ? PublicMarketPlaceTabs.COLLECTIBLES
                        : PublicMarketPlaceTabs.EXPASSES,
                    navigationFrom: navigationFrom,
                    selectedCatalog: selectedCatalog,
                    availableCatalogs: availableCatalogs,
                    catalogType: catalogType,
                  },
                });
              }}
              title={'Go to Marketplace'}
              disabled={false}
            />
          </View>
        </View>
      )}
    </View>
  );
};

const TransferModalContent = ({ props, actionSheetRef }) => {
  const [transferConfirmation, setTransferConfirmation] = useState(false);
  const [friendToSend, setFriendToSend] = useState(null);

  // const [isListed, setIsListed] = useState(false);
  const dispatch = useAppDispatch();
  const entityData = useAppSelector(state => state.Entity.EntityData);

  const { networkId, staticProvider, address, wallet, SelectedPassport } =
    useWeb3AuthContext();
  const {
    itemObject,
    navigation,
    transferSuccessNavigation,
    updateStateDataFunc,
  } = props.payload;

  // useEffect(() => {
  //   if (itemObject) {
  //     if (type === 'Expass') {
  //       const checkIfListed =
  //         itemObject &&
  //         itemObject?.collectibleIds.length > 0 &&
  //         itemObject?.marketPlaceConfig?.listingIndex &&
  //         itemObject?.marketPlaceConfig?.listingIndex?.findIndex(
  //           obj => obj.tokenId === Number(itemObject?.collectibleIds[0]),
  //         ) !== -1
  //           ? true
  //           : false;
  //       setIsListed(checkIfListed);
  //     } else {
  //       const checkIfListed =
  //         itemObject &&
  //         itemObject?.marketPlaceConfig?.listingIndex &&
  //         itemObject?.marketPlaceConfig?.listingIndex?.findIndex(
  //           obj => obj.tokenId === Number(itemObject.collectibleId),
  //         ) !== -1;
  //       setIsListed(checkIfListed);
  //     }
  //   }
  // }, [itemObject]);

  const sendNFTToFriend = async () => {
    if (friendToSend && itemObject) {
      try {
        if (Platform.OS === 'ios') {
          await wait(500);
        }
        dispatch(showTransferModal());
        const sent = await dispatch(
          transferNFTToFriend({
            networkID: itemObject.chainId,
            friendWalletAddress: friendToSend.wallet,
            tokenAddress: itemObject.address,
            tokenId: itemObject.collectibleId ?? itemObject.tokenId,
            wallet,
          }),
        );

        if (sent && sent.payload) {
          await dispatch(
            clearSpecificCatalogCollectible({
              collectionAddress: itemObject.address,
              tokenId: itemObject?.collectibleId,
            }),
          );
          await dispatch(
            clearSpecificThirdPartyCollectible({
              collectionAddress: itemObject.address,
              tokenId: itemObject?.collectibleId,
            }),
          );

          //refresh collectible
          if (itemObject.isThirdParty) {
            await getThirdPartyCollectiableDetails(
              {
                chainId: itemObject.chainId,
                provider: getAnynetStaticProvider(itemObject.chainId),
                collectiableAddress: itemObject.address,
                address,
              },
              false,
            );
            if (SelectedPassport) {
              await dispatch(
                loadThirpartyCollectibleList({
                  networkID: networkId,
                  provider: staticProvider,
                  passport: SelectedPassport,
                  wallet,
                  address,
                }),
              );
            }
          } else {
            await getCollectibleDetails(
              {
                networkID: itemObject.chainId,
                provider: getAnynetStaticProvider(itemObject.chainId),
                collectibleAddress: itemObject.address,
                address,
                wallet,
              },
              { entityData },
              { isCache: false },
            );
          }
          if (SelectedPassport) {
            await dispatch(
              loadPassportOfferList({
                networkID: networkId,
                provider: staticProvider,
                passport: SelectedPassport,
                wallet,
                address,
              }),
            );
          }
          updateStateDataFunc(true);
          dispatch(hideTransferModal());
          setTimeout(() => {
            if (!transferSuccessNavigation) {
              navigation.navigate('Success', {
                screen: ScreenName.SUCCESS_SCREEN,
                params: {
                  message: `Your Collectible is successfully transferred!`,
                },
              });
            } else {
              navigation.navigate('Success', {
                screen: ScreenName.SUCCESS_SCREEN,
                params: {
                  message: `Your Collectible is successfully transferred!`,
                  navigateTo: transferSuccessNavigation.screen,
                  navigationParams: transferSuccessNavigation.params,
                },
              });
            }
          }, 500);
        } else {
          showToastMessage(
            ToastCustomMessageType.INFO,
            `Transaction failed. Please contact support@loot8.io for any further queries`,
          );
        }
      } catch (e) {
        dispatch(hideTransferModal());
        LogCustomError('Error: sendNFTToFriend', e.name, e.message, e.stack);
      }
    }
  };
  return (
    <>
      <View>
        {!transferConfirmation ? (
          <>
            <ReshareModalSheetContent
              scrollHandlers={() => {}}
              noFriendsText={
                'You do not have any friends, please add a friend to send your collectibles to them.'
              }
              onSelectedFriend={item => {
                setFriendToSend(item);
                setTransferConfirmation(true);
              }}
            />
          </>
        ) : (
          <ExpassListPopupConfimation
            confirmButtonOnPress={() => {
              actionSheetRef.current.hide();
              sendNFTToFriend();
            }}
            cancelButtonOnPress={() => {
              actionSheetRef.current.hide();
            }}
            customConfirmText={'Yes, Send'}
            title={`Are you sure you want to send ${itemObject?.name} to ${
              friendToSend?.name + '#' + friendToSend?.id + '?'
            }`}
          />
        )}
      </View>
    </>
  );
};

const TransferModalSheet = (props: SheetProps) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);

  return (
    <ActionSheet
      ref={actionSheetRef}
      id={props.sheetId}
      drawUnderStatusBar
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      enableGesturesInScrollView={false}>
      {Platform.OS === 'web' ? (
        <ReShareWebBlurView>
          <View
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <TransferModalContent
                props={props}
                actionSheetRef={actionSheetRef}
              />
            </LinearGradient>
          </View>
        </ReShareWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={{
            borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <TransferModalContent
              props={props}
              actionSheetRef={actionSheetRef}
            />
          </LinearGradient>
        </BlurView>
      )}
    </ActionSheet>
  );
};

export default TransferModalSheet;

const componentStyles = StyleSheet.create({
  confirmModalHeading: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginHorizontal: 15,
    marginTop: 5,
  },
  confirmModalText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    marginHorizontal: 15,
    marginTop: 5,
  },
  confirmModalTextItalic: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    marginHorizontal: 15,
    marginTop: 5,
  },
});
