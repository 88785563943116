import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { Platform } from 'react-native';
import Web3Auth, {
  LOGIN_PROVIDER,
  OPENLOGIN_NETWORK,
} from '@web3auth/react-native-sdk';
import Constants, { AppOwnership } from 'expo-constants';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Buffer } from 'buffer';
import '@ethersproject/shims';
import { ethers, Wallet } from 'ethers';

import { StaticJsonRpcProvider } from '@ethersproject/providers';
const CryptoJS = require('crypto-js');

global.Buffer = global.Buffer || Buffer;

const scheme = 'loot8auth'; // Or your desired app redirection scheme
import {
  APP_STORAGE_NETWORKID,
  APP_STORAGE_USER_ADDRESS,
  APP_STORAGE_USER_KEY,
  SELECTED_APP,
  SHOW_APP_SELECTION,
  WEB_REDIRECT_URL,
  getNetwork,
  getNetworkURI,
  getStaticProvider,
} from '../appconstants';
import { clearAllData, getData, storeData } from '../helpers/AppStorage';
import { AppEnvironment } from '../enums/env.enum';
import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import { Apps } from '../enums/apps.enum';
import { IApps } from '../interfaces/IApp.interface';
import { getCreatorStatus } from '../helpers/MsgHelper';
import { IUser } from '../interfaces/IUser.interface';

const resolvedRedirectUrlForAndroid =
  Constants.appOwnership == AppOwnership.Expo ||
  Constants.appOwnership == AppOwnership.Guest
    ? Linking.createURL('web3auth', {})
    : Linking.createURL('web3auth', { scheme: scheme });

const resolvedRedirectUrl = 'loot8auth://openlogin';

const clientId = Constants.expoConfig.extra.WEB3AUTH_CLIENTID;

/*
  Types
*/
type OnChainProviderType = {
  connect?: (
    provider: any,
    loginHint: string,
    loginConfig: any,
  ) => Promise<void>;
  disconnect?: () => void;
  connected: boolean;
  showAppSelection: boolean;
  setShowAppSelection: (val: boolean) => void;
  appSelected: IApps['app'];
  setAppSelected: (selectedApp: IApps['app']) => void;
  SelectedPassport?: ICollectibleDetail;
  updatePassPort?: (SelectedPassport: ICollectibleDetail) => void;
  clearPassPort?: () => void;
  providerUri?: string;
  key?: string;
  userInfo?: any;
  setUserInfo?: (userInfo: IUser) => void;
  qrShimFlow?: any;
  wallet: any;
  address: string;
  networkId: number;
  networkName: string;
  blockExplorerUrl: string;
  staticProvider: StaticJsonRpcProvider;
  loginInProgress?: boolean;
  setLoginInProgress?: (loginInProgress: boolean) => void;
  setQRShimFlow?: (qrShimFlow: any) => void;
  isWhitelistedWallet?: boolean;
  setIsWhitelistedWallet?: (isWhiteList: boolean) => void; // whitelist wallet who can access configuration details.
  encryptMessage?: (
    message: string,
    receiverPublicKey: string,
  ) => string | null;
  decryptMessage?: (
    cipherText: string,
    receiverPublicKey: string,
  ) => string | null;
};

export type Web3AuthContextData = {
  onChainProvider: OnChainProviderType;
} | null;

const Web3AuthContext = React.createContext<Web3AuthContextData>(null);

export const useWeb3AuthContext = () => {
  const web3AuthContext = useContext(Web3AuthContext);
  let onChainProvider: OnChainProviderType = {
    connected: false,
    address: '',
    networkId: null,
    networkName: '',
    blockExplorerUrl: '',
    staticProvider: undefined,
    wallet: undefined,
  };

  if (web3AuthContext) {
    ({ onChainProvider } = web3AuthContext);
  }

  return useMemo<OnChainProviderType>(() => {
    return { ...onChainProvider };
  }, [onChainProvider]);
};

const Web3AuthContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [connected, setConnected] = useState(false);
  const [showAppSelection, setShowAppSelection] = useState(false);
  const [appSelected, setAppSelected] = useState<IApps['app']>(Apps.PATRON_APP);
  const [providerUri, setProviderUri] = useState('');
  const [key, setKey] = useState('');
  const [userInfo, setUserInfo] = useState<IUser | undefined>(undefined);
  const [iconsole, setConsole] = useState('');
  const [SelectedPassport, setpassPort] = useState<ICollectibleDetail>(null);
  const [wallet, setWallet] = useState<Wallet | null>(null);

  const [address, setAddress] = useState('');

  const [networkId, setNetworkId] = useState(0);
  const [networkName, setNetworkName] = useState('');
  const [blockExplorerUrl, setBlockExplorerUrl] = useState('');
  const [staticProvider, setStaticProvider] = useState<
    StaticJsonRpcProvider | undefined
  >(undefined);
  const [loginInProgress, setLoginInProgress] = React.useState(false);
  const [qrShimFlow, setQRShimFlow] = React.useState(false);
  const [isWhitelistedWallet, setIsWhitelistedWallet] = useState(false);
  const [userSigningKey, setUserSigningKey] =
    useState<ethers.utils.SigningKey>();

  const connect = async (provider: any, loginHint = '', loginConfig = null) => {
    try {
      setConsole('Logging in');
      // todo - uncomment this section once web3auth issue resolved.

      const web3auth = new Web3Auth(WebBrowser, {
        clientId,
        network:
          Constants.expoConfig.extra.ENVIRONMENT == AppEnvironment.PRODUCTION ||
          Constants.expoConfig.extra.ENVIRONMENT == AppEnvironment.STAGING
            ? OPENLOGIN_NETWORK.CYAN
            : OPENLOGIN_NETWORK.TESTNET, // or other networks
        loginConfig: loginConfig,
      });

      let userKey = await getData(APP_STORAGE_USER_KEY);

      // Set value for showAppSelection
      const selectedApp = (await getData(SELECTED_APP)) || Apps.PATRON_APP;

      const showAppSelectionValue = await getData(SHOW_APP_SELECTION);
      const showAppSelection = showAppSelectionValue === undefined;
      setShowAppSelection(showAppSelection);

      if (userKey == null) {
        await storeData('WebLoginInProgress', true);

        if (Platform.OS != 'ios' && Platform.OS != 'android') {
          async function waitUntil() {
            return await new Promise(resolve => {
              const loginInterval = setInterval(async () => {
                const loginInProgress = await getData('WebLoginInProgress');
                if (!loginInProgress) {
                  clearInterval(loginInterval);
                  userKey = await getData(APP_STORAGE_USER_KEY);
                  resolve(userKey);
                }
              }, 1000);
            });
          }
          web3auth
            .login({
              redirectUrl: WEB_REDIRECT_URL,
              mfaLevel: 'none',
              curve: 'secp256k1',
              extraLoginOptions: {
                login_hint:
                  provider == LOGIN_PROVIDER.EMAIL_PASSWORDLESS && loginHint,
              },
              loginProvider: provider,
            })
            .catch(async e => {
              await storeData('WebLoginInProgress', false);
            });
          await waitUntil();
        } else {
          const info = await web3auth.login({
            redirectUrl:
              Platform.OS === 'ios'
                ? resolvedRedirectUrl
                : resolvedRedirectUrlForAndroid,
            mfaLevel: 'none',
            curve: 'secp256k1',
            extraLoginOptions: {
              login_hint:
                provider == LOGIN_PROVIDER.EMAIL_PASSWORDLESS && loginHint,
            },
            loginProvider: provider,
          });
          await storeData(APP_STORAGE_USER_KEY, info);
          userKey = await getData(APP_STORAGE_USER_KEY);
        }

        // Set setShowAppSelection value to TRUE when user logs in
        setShowAppSelection(true);
        await storeData(SHOW_APP_SELECTION, true);
      }

      const wal = new ethers.Wallet(userKey.privKey);
      const address = await wal.address;
      const networkId = getNetwork();
      await storeData(APP_STORAGE_USER_ADDRESS, address);
      await storeData(APP_STORAGE_NETWORKID, networkId);
      setWallet(wal);

      const isOnboarded = await getCreatorStatus({
        networkID: networkId,
        provider: getStaticProvider(),
        address,
      });

      // to remove email domain from username
      if (
        userKey.userInfo?.typeOfLogin === 'jwt' ||
        userKey.userInfo?.typeOfLogin === 'github'
      ) {
        if (userKey.userInfo.name.indexOf('@') > -1) {
          userKey.userInfo.name = userKey.userInfo.name.substring(
            0,
            userKey.userInfo.name.lastIndexOf('@'),
          );
        }
      }
      // clear profile image in case of password less authentication.
      if (
        userKey.userInfo?.typeOfLogin === 'jwt' &&
        userKey.userInfo?.aggregateVerifier &&
        userKey.userInfo?.aggregateVerifier.indexOf('email-passwordless') > -1
      ) {
        userKey.userInfo.profileImage = '';
      }
      setAppSelected(isOnboarded ? selectedApp : Apps.PATRON_APP);
      setUserInfo({ ...userKey.userInfo, isOnboarded });
      uiConsole(userKey);
      setKey(userKey.privKey);
      setUserSigningKey(new ethers.utils.SigningKey('0x' + userKey.privKey));
      setProviderUri(getNetworkURI());
      setStaticProvider(getStaticProvider());
      setAddress(address);
      setNetworkId(networkId);
      setConnected(true);
    } catch (e) {
      setLoginInProgress(false);
      setQRShimFlow(false);
      uiConsole(e);
      setIsWhitelistedWallet(false);
    }
  };

  const disconnect = async () => {
    setKey('');
    setUserSigningKey(null);
    setConnected(false);
    setUserInfo(null);
    setStaticProvider(null);
    setAddress('');
    setNetworkId(null);
    setpassPort(null);
    setProviderUri('');
    setWallet(null);
    setLoginInProgress(false);
    setQRShimFlow(false);
    setIsWhitelistedWallet(false);
    setAppSelected(Apps.PATRON_APP);
    setShowAppSelection(false);
    await clearAllData();
    // await storeData(APP_STORAGE_USER_KEY, null);
  };

  const updatePassPort = (passport: ICollectibleDetail) => {
    setpassPort(passport);
  };
  const clearPassPort = () => {
    setpassPort(null);
  };

  const uiConsole = (...args) => {
    setConsole(
      JSON.stringify(args || {}, null, 2) +
        '\n\n==================================\n\n' +
        console,
    );
  };

  const encryptMessage = (
    message: string,
    receiverPublicKey: string,
  ): string | null => {
    if (userSigningKey) {
      try {
        const sharedKey = userSigningKey.computeSharedSecret(receiverPublicKey);
        return CryptoJS.AES.encrypt(message, sharedKey).toString();
      } catch (error) {
        return null;
      }
    }
  };

  const decryptMessage = (
    cipherText: string,
    receiverPublicKey: string,
  ): string | null => {
    if (userSigningKey) {
      try {
        const sharedKey = userSigningKey.computeSharedSecret(receiverPublicKey);
        const bytes = CryptoJS.AES.decrypt(cipherText, sharedKey);
        return bytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        return '';
      }
    }
  };

  const onChainProvider = useMemo(
    () => ({
      connect,
      disconnect,
      connected,
      showAppSelection,
      setShowAppSelection,
      appSelected,
      setAppSelected,
      providerUri,
      key,
      userInfo,
      setUserInfo,
      SelectedPassport,
      updatePassPort,
      clearPassPort,
      address,
      wallet,
      networkId,
      networkName,
      blockExplorerUrl,
      staticProvider,
      loginInProgress,
      setLoginInProgress,
      qrShimFlow,
      setQRShimFlow,
      isWhitelistedWallet,
      setIsWhitelistedWallet,
      encryptMessage,
      decryptMessage,
    }),
    [
      connect,
      disconnect,
      connected,
      showAppSelection,
      setShowAppSelection,
      appSelected,
      setAppSelected,
      providerUri,
      key,
      userInfo,
      setUserInfo,
      SelectedPassport,
      updatePassPort,
      clearPassPort,
      address,
      wallet,
      networkId,
      networkName,
      blockExplorerUrl,
      staticProvider,
      loginInProgress,
      setLoginInProgress,
      qrShimFlow,
      setQRShimFlow,
      isWhitelistedWallet,
      setIsWhitelistedWallet,
      encryptMessage,
      decryptMessage,
    ],
  );

  return (
    <Web3AuthContext.Provider value={{ onChainProvider }}>
      {children}
    </Web3AuthContext.Provider>
  );
};

export default Web3AuthContextProvider;
