import React from 'react';
import { Platform, Pressable, Text } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import MaskedView from '@react-native-masked-view/masked-view';

import { defaultTheme } from '../../themes/loot8';

interface Props {
  size: number;
  fontFamily?: string;
  children: string;
  onPress?: () => void;
}
const GradientText: React.FC<Props> = ({
  size,
  fontFamily = defaultTheme.FONT_FAMILY_BOLD,
  children,
  onPress,
}) => {
  if (Platform.OS === 'web') {
    const gradientLetters = children.split('').map((letter, index) => (
      <span
        onClick={onPress}
        key={index}
        style={{
          background: `-webkit-linear-gradient(top, ${defaultTheme.GRADIENT_COLOR1}, ${defaultTheme.GRADIENT_COLOR2})`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          fontSize: size,
          fontFamily,
        }}>
        {letter}
      </span>
    ));

    return <div>{gradientLetters}</div>;
  } else {
    return (
      <Pressable onPress={onPress}>
        <MaskedView
          maskElement={
            <Text
              onPress={onPress}
              textBreakStrategy="highQuality"
              style={[
                {
                  fontSize: size,
                  fontFamily,
                },
              ]}>
              {children}
            </Text>
          }>
          <LinearGradient
            colors={[
              defaultTheme.GRADIENT_COLOR2,
              defaultTheme.GRADIENT_COLOR1,
            ]}
            end={{ x: 0.0001, y: 0.2 }}
            start={{ x: 0.00001, y: 0.8 }}>
            <Text style={{ fontSize: size + 2, opacity: 0 }} onPress={onPress}>
              {children}
            </Text>
          </LinearGradient>
        </MaskedView>
      </Pressable>
    );
  }
};

export default GradientText;
