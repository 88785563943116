import React from 'react';
import { useRef } from 'react';
import { Platform, Text, TouchableOpacity, View } from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
} from 'react-native-actions-sheet';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  PassportCategory,
  PassportSubscriptionActions,
  PassportType,
} from '../../enums/passportCategory.enum';
import styles from '../../styles';
import { BlurView } from 'expo-blur';
import { ReShareWebBlurView } from '../WebBlurView';
import { ScreenName } from '../../enums/screen.enum';
import FontAwsome from 'react-native-vector-icons/FontAwesome5';

const PassportOptionsSheetContent = ({ props, passportOptionsSheetRef }) => {
  const {
    selectedEXPass,
    processHidePassportsSheet,
    navigation,
    setShowListingModal,
    isPassportListed,
  } = props.payload;

  const onSellPassportClick = async () => {
    passportOptionsSheetRef.current.hide();
    setTimeout(
      () => {
        setShowListingModal(true);
      },
      Platform.OS === 'ios' ? 500 : 10,
    );
  };

  const onUnsubscribeClick = async () => {
    passportOptionsSheetRef.current.hide();
    navigation.navigate(ScreenName.PASSPORT_BUY_SELL_CONFIRMATION, {
      selectedEXPass: selectedEXPass,
      passportAction: PassportSubscriptionActions.UNSUBSCRIBE,
    });
    processHidePassportsSheet();
  };

  const enableOption = () => {
    // if (selectedEXPass?.passportCategory === PassportCategory.ORGANIZATION) {
    return (
      selectedEXPass?.marketPlaceConfig &&
      selectedEXPass.marketPlaceConfig.allowMarketPlaceOps &&
      selectedEXPass?.marketPlaceConfig?.publicTradeability
    );
    // }
    // else {
    //     return (selectedEXPass?.marketPlaceConfig &&
    //         selectedEXPass?.marketPlaceConfig?.publicTradeability);
    // }
  };

  return (
    <>
      <TouchableOpacity
        style={styles.passportActionContainer}
        onPress={() => onSellPassportClick()}
        disabled={!enableOption()}>
        <FontAwsome
          name="list"
          color={enableOption() ? '#FFFFFF' : 'rgba(255, 255, 255, 0.55)'}
          style={{ marginHorizontal: 10 }}
          size={14}
        />
        <Text
          style={[
            styles.passportActionsText,
            { color: enableOption() ? '#FFFFFF' : 'rgba(255, 255, 255, 0.55)' },
          ]}>
          {selectedEXPass?.marketPlaceConfig && isPassportListed
            ? 'Unlist from marketplace for sell'
            : 'List to marketplace for sell'}
        </Text>
      </TouchableOpacity>
      {selectedEXPass.passportType === PassportType.SUBSCRIBED && (
        <TouchableOpacity
          style={[styles.passportActionContainer, { paddingVertical: 0 }]}
          onPress={() => {
            onUnsubscribeClick();
          }}
          disabled={!enableOption()}>
          <MaterialCommunityIcons
            name="minus-circle-outline"
            size={16}
            color={enableOption() ? '#FFFFFF' : 'rgba(255, 255, 255, 0.55)'}
            style={{ marginHorizontal: 10 }}
          />
          <Text
            style={[
              styles.passportActionsText,
              {
                color: enableOption() ? '#FFFFFF' : 'rgba(255, 255, 255, 0.55)',
              },
            ]}>
            {'Unsubscribe ExPass'}
          </Text>
        </TouchableOpacity>
      )}
    </>
  );
};

const PassportOptionsSheet = (props: SheetProps) => {
  const passportOptionsSheetRef = useRef<ActionSheetRef>(null);
  const { processHidePassportsSheet } = props.payload;

  return (
    <>
      <ActionSheet
        ref={passportOptionsSheetRef}
        drawUnderStatusBar
        containerStyle={{
          ...styles.maxWidthAdjust,
          backgroundColor: 'transparent',
          overflow: 'hidden',
        }}
        gestureEnabled={true}
        backgroundInteractionEnabled={false}
        closeOnTouchBackdrop={true}
        //onClose={processHidePassportsSheet}
      >
        {Platform.OS === 'android' ? (
          <View
            style={[
              styles.passportSubscribeSheetContent,
              {
                ...styles.modalCenteredContainer,
                backgroundColor: 'rgba(169, 175, 179,.95)',
                alignItems: 'flex-start',
              },
            ]}>
            <View style={styles.passportOptionsSheetContainer}>
              <PassportOptionsSheetContent
                props={props}
                passportOptionsSheetRef={passportOptionsSheetRef}
              />
            </View>
          </View>
        ) : Platform.OS == 'ios' ? (
          <BlurView
            tint="light"
            style={[
              styles.passportSubscribeSheetContent,
              { overflow: 'hidden', alignItems: 'flex-start' },
            ]}
            intensity={60}>
            <View style={styles.passportOptionsSheetContainer}>
              <PassportOptionsSheetContent
                props={props}
                passportOptionsSheetRef={passportOptionsSheetRef}
              />
            </View>
          </BlurView>
        ) : (
          <ReShareWebBlurView>
            <View style={styles.passportOptionsSheetContainer}>
              <PassportOptionsSheetContent
                props={props}
                passportOptionsSheetRef={passportOptionsSheetRef}
              />
            </View>
          </ReShareWebBlurView>
        )}
      </ActionSheet>
    </>
  );
};

export default PassportOptionsSheet;
