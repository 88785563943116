import React, { memo, useEffect, useRef, useState } from 'react';
import { View, StyleSheet, FlatList, Pressable } from 'react-native';
import { useAppSelector } from '../hooks';
import { useNavigation } from '@react-navigation/native';
import { defaultTheme } from '../themes/loot8';
import { FriendRequests } from '../components/Friends/request';
import ScreenTitle from '../components/ScreenTitle';
import Friends from '../components/Friends';
import HOCContainer from '../components/HOCContainer';
import useActiveDimensions from '../hooks/useActiveDimensions';
import AnimatedTabButtons from '../components/AnimatedTabButtons';
import useIsResponsive from '../hooks/useIsResponsive';
import { Ionicons } from '@expo/vector-icons';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';

function FriendsScreen({ route, setDynamicTitle }) {
  const navigation = useNavigation();
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();

  // LOCAL STATE HOOKS
  const [isManageFriends, setIsManageFriends] = useState(false);

  const tabsRef = useRef(null);

  // GLOBAL STATE HOOKS
  const pendingRequest =
    useAppSelector(state => state.PrivateMessage.pendingRequest) ?? [];

  const onSelectedFriend = (friend: any) => {
    // @ts-ignore
    navigation.navigate('Chat', {
      screen: 'ChatScreen',
      params: {
        selectedFriend: friend,
      },
    });
  };

  const topTabsButtonData: Array<{
    id: number;
    title: string;
    bubbleCount?: number;
  }> = [
    { id: 1, title: 'Friends' },
    { id: 2, title: 'Requests', bubbleCount: pendingRequest?.length },
  ];

  const goToNextSlide = (index: number) => {
    const offset = isResponsive
      ? index * defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
        defaultTheme.HORIZONTAL_PADDING * 2
      : index * activeWidth - defaultTheme.HORIZONTAL_PADDING * 2;
    tabsRef?.current.scrollToOffset({ offset });
  };
  //REANIMATED
  const progress = useSharedValue(0);
  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      marginTop: progress.value,
    };
  }, []);

  useEffect(() => {
    if (isManageFriends) {
      progress.value = withSpring(-50);
    } else {
      progress.value = withSpring(0);
    }
  }, [isManageFriends]);
  return (
    <>
      <View style={friendsScreenStyles.container}>
        <View style={friendsScreenStyles.row}>
          <Pressable
            style={friendsScreenStyles.backButton}
            disabled={!isManageFriends}
            onPress={() => setIsManageFriends(false)}>
            {isManageFriends && (
              <Ionicons name="arrow-back" size={30} color={'#fff'} />
            )}
          </Pressable>

          <ScreenTitle
            title={isManageFriends ? 'Manage Friends' : 'Messages'}
          />
          <View style={friendsScreenStyles.backButton} />
        </View>

        {/* {!isManageFriends && ( */}
        <Animated.View style={reanimatedStyle}>
          <AnimatedTabButtons
            data={topTabsButtonData}
            changeSlidesOnPress={goToNextSlide}
          />
        </Animated.View>
        {/* )} */}
        <FlatList
          ref={tabsRef}
          data={topTabsButtonData}
          horizontal
          snapToAlignment="start"
          decelerationRate={'fast'}
          snapToInterval={activeWidth}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
          style={{ flex: 1 }}
          renderItem={({ index }) => (
            <Slide
              activeIndex={index}
              onSelectedFriend={onSelectedFriend}
              isManageFriends={isManageFriends}
              setIsManageFriends={setIsManageFriends}
            />
          )}
          scrollEnabled={false}
        />
      </View>
    </>
  );
}

export default HOCContainer({
  OriginalComponent: FriendsScreen,
  isScrollEnabled: false,
});

const Slide = memo(
  ({
    activeIndex,
    onSelectedFriend,
    isManageFriends,
    setIsManageFriends,
  }: {
    activeIndex: number;
    onSelectedFriend: Function;
    isManageFriends: boolean;
    setIsManageFriends: Function;
  }) => {
    const isResponsive = useIsResponsive();

    const activeWidth = useActiveDimensions().width;
    const renderContent = () => {
      switch (activeIndex) {
        case 0:
          return (
            <Friends
              onSelectedFriend={onSelectedFriend}
              isManageFriends={isManageFriends}
              setIsManageFriends={setIsManageFriends}
            />
          );
        case 1:
          return <FriendRequests onSelectedFriend={onSelectedFriend} />;

        default:
          return (
            <Friends
              onSelectedFriend={onSelectedFriend}
              isManageFriends={isManageFriends}
              setIsManageFriends={setIsManageFriends}
            />
          );
      }
    };
    return (
      <View
        style={
          isResponsive
            ? {
                width:
                  defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
                  defaultTheme.HORIZONTAL_PADDING * 2,
              }
            : {
                width: activeWidth - defaultTheme.HORIZONTAL_PADDING * 2,
              }
        }>
        {renderContent()}
      </View>
    );
  },
);

const friendsScreenStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerResponsive: {
    height: '86%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  noDMContainer: {
    width: '68%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderLeftWidth: 1,
    borderLeftColor: 'gray',
    height: '95%',
    top: '3%',
  },
  noDMText: {
    fontFamily: 'Poppins',
    fontSize: 15,
    lineHeight: 17,
    letterSpacing: 0,
    color: '#DBE2ED',
    opacity: 0.65,
  },
  row: {
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    zIndex: 20,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
  },
  backButton: {
    height: 35,
    width: 35,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
