import {
  View,
  Text,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import React, { Component, useState } from 'react';
import { Image, ImageContentFit } from 'expo-image';

import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import { CatalogTypes } from '../../enums/catalog.enum';
import { formatPriceUpToTrillion } from '../../helpers/Gadgets';
import { PassportType } from '../../enums/passportCategory.enum';

import EightIcon from '../../assets/eightIcon.png';
import varifiedAccountTick from '../../assets/verified_account.png';

const ImageComponent = ({ contentFit, imageStyle, source }) => {
  const [brokenImage, setBrokenImage] = useState(false);
  return Platform.OS == 'web' ? (
    <Image
      contentFit={contentFit}
      style={
        imageStyle
          ? [
              imageStyle,
              inlineStyles.backgroundImage,
              (brokenImage || !source?.uri) && { width: '0%', height: '0%' },
            ]
          : [
              inlineStyles.backgroundImage,
              (brokenImage || !source?.uri) && { width: '0%', height: '0%' },
            ]
      }
      source={source}
      onError={() => {
        setBrokenImage(true);
      }}></Image>
  ) : (
    <Image
      contentFit={contentFit}
      style={
        imageStyle
          ? [
              imageStyle,
              inlineStyles.backgroundImage,
              (brokenImage || !source?.uri) && { width: '0%', height: '0%' },
            ]
          : [
              inlineStyles.backgroundImage,
              (brokenImage || !source?.uri) && { width: '0%', height: '0%' },
            ]
      }
      source={source}
      cachePolicy="memory-disk"
      onError={() => {
        setBrokenImage(true);
      }}></Image>
  );
};

const areEqual = (prevProps, nextProps) => {
  let isEqualItem = false;
  if (prevProps?.source?.uri == nextProps?.source?.uri) {
    isEqualItem = true;
  }
  return isEqualItem;
};
const MemoisedImage = React.memo(ImageComponent, areEqual);
type IImageBgProps = {
  source: {
    uri: string;
    cache: string;
  };
  style?: any;
  imageStyle?: any;
  contentFit?: ImageContentFit | undefined;
  type?: CatalogTypes;
  item?: any;
  isResponsive?: boolean;
  hovered?: boolean;
  children: any;
};

export default class CustomImageBackground extends Component<IImageBgProps> {
  render() {
    let resizeMode = this.props?.contentFit;
    let item = this.props?.item;
    let type = this.props?.type;
    // let contentFit = this.props.contentFit;
    //  resizeMode && (resizeMode === 'contain' || resizeMode === 'cover') ?
    // resizeMode : resizeMode && resizeMode === 'stretch' ? 'fill' : 'none';
    return (
      <View
        style={
          this.props.style
            ? [this.props.style, inlineStyles.imageContainer]
            : inlineStyles.imageContainer
        }>
        {this.props?.isResponsive && this.props?.hovered && (
          <View
            style={{
              backgroundColor: '#000000',
              backgroundPositionX: '0%',
              backgroundPositionY: '0%',
              borderRadius: 6,
              opacity: 0.8,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 2,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}>
            <View style={{ flex: 1 }} />
            <View style={{ flex: 1, marginTop: 10 }}>
              <View
                style={[
                  styles.passportNameIdContainer,
                  {
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  },
                ]}>
                <View
                  style={[
                    resizeMode != 'cover'
                      ? {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignSelf: 'flex-start',
                        }
                      : {},
                    {
                      flexGrow: 1,
                      flexShrink: 1,
                      flexBasis: 'auto',
                      alignItems: 'flex-start',
                    },
                    { maxWidth: '85%', overflow: 'hidden' },
                  ]}>
                  <Text
                    style={[
                      styles.passportNameIdText,
                      {
                        fontSize: defaultTheme.FONT_SIZE_MEDIUM,
                        fontWeight: '800',
                      },
                    ]}
                    ellipsizeMode="tail"
                    numberOfLines={1}>
                    <Text>{item?.name}</Text>
                    {item?.passportType !== PassportType.SUBSCRIPTION && (
                      <Text
                        style={[
                          styles.passportNameIdTextWrap,
                          Platform.OS == 'web'
                            ? { wordBreak: 'break-word' }
                            : {},
                        ]}>
                        {item?.collectibleIds?.length > 0
                          ? ' #' + item?.collectibleIds[0]
                          : ''}
                      </Text>
                    )}
                  </Text>
                  {resizeMode == 'cover' &&
                    item?.passportType !== PassportType.REGULAR && (
                      <View>
                        <TouchableOpacity
                          activeOpacity={1}
                          onPress={this.props.onPriceTagPress}
                          style={{
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                          }}>
                          <Image
                            source={EightIcon}
                            contentFit="contain"
                            style={{ width: 15, height: 15 }}
                          />
                          <Text
                            style={[
                              styles.passportNameIdText,
                              styles.passportNameIdTextWrap,
                              Platform.OS == 'web'
                                ? { wordBreak: 'break-word', marginLeft: 5 }
                                : { marginLeft: 5 },
                            ]}>
                            {formatPriceUpToTrillion(
                              type === CatalogTypes.PREMIUMCHAT
                                ? item?.price
                                : item?.buyPrice,
                            )}
                            {/* {item?.passportType === PassportType.SUBSCRIBED ? item?.initialBuyPrice?.toFixed(2) : item?.buyPrice?.toFixed(2)} */}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}
                </View>
                {resizeMode == 'cover' ? (
                  <View style={{ alignSelf: 'flex-start' }}>
                    {item?.thirdPartyVerifiedURL?.length > 0 && (
                      <View style={styles.verifiedAccountTickContainer}>
                        <Image
                          source={varifiedAccountTick}
                          contentFit="contain"
                          style={styles.verifiedAccountTickImage}
                        />
                        <Text style={styles.verifiedAccountTickTitle}>
                          {'Verified'}
                        </Text>
                      </View>
                    )}
                  </View>
                ) : (
                  <View>
                    {item?.thirdPartyVerifiedURL?.length > 0 && (
                      <View
                        style={{ alignSelf: 'flex-start', marginBottom: 5 }}>
                        <View style={styles.verifiedAccountTickContainer}>
                          <Image
                            source={varifiedAccountTick}
                            contentFit="contain"
                            style={styles.verifiedAccountTickImage}
                          />
                          <Text style={styles.verifiedAccountTickTitle}>
                            {'Verified'}
                          </Text>
                        </View>
                      </View>
                    )}
                    <View>
                      {item?.passportType !== PassportType.REGULAR &&
                        item?.passportType !== PassportType.SUBSCRIBED && (
                          <View>
                            <View
                              style={{
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                              }}>
                              <Image
                                source={EightIcon}
                                contentFit="contain"
                                style={{ width: 15, height: 15 }}
                              />
                              <Text
                                style={[
                                  styles.passportNameIdText,
                                  styles.passportNameIdTextWrap,
                                  Platform.OS == 'web'
                                    ? { wordBreak: 'break-word', marginLeft: 5 }
                                    : { marginLeft: 5 },
                                ]}>
                                {formatPriceUpToTrillion(item?.price)}
                                {/* {item?.passportType === PassportType.SUBSCRIBED ? item?.initialBuyPrice?.toFixed(2) : item?.buyPrice?.toFixed(2)} */}
                              </Text>
                            </View>
                          </View>
                        )}
                    </View>
                  </View>
                )}
              </View>
            </View>
            <View style={{ flex: 1, justifyContent: 'flex-end' }}>
              <View
                style={[
                  styles.tokenBuyButton,
                  {
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '90%',
                    height: 41,
                    borderRadius: 6,
                    alignSelf: 'center',
                    alignItems: 'center',
                  },
                ]}>
                <Text
                  style={{
                    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
                    fontFamily: defaultTheme.FONT_FAMILY_MAIN,
                    fontWeight: defaultTheme.FONT_WEIGHT_MEDIUM,
                    letterSpacing: 0.5,
                  }}>
                  Explore Expass
                </Text>
              </View>
            </View>
          </View>
        )}
        <MemoisedImage
          contentFit={this.props?.contentFit}
          imageStyle={this.props.imageStyle}
          source={this.props.source}></MemoisedImage>
        {this.props?.children}
      </View>
    );
  }
}

const inlineStyles = StyleSheet.create({
  imageContainer: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    position: 'absolute',
    zIndex: 0,
    width: '100%',
    height: '100%',
  },
});
