import { StyleSheet, Text, View, Image } from 'react-native';
import React, { useEffect, useState } from 'react';
import { defaultTheme } from '../../themes/loot8';
import styles from '../../styles';
import { CatalogTypes } from '../../enums/catalog.enum';
import {
  getSocialMediaAccess,
  readMessages,
  subscribeToPassportMessages,
} from '../../slices/PassportMessageSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import {
  formatPriceUpToTrillion,
  showToastMessage,
} from '../../helpers/Gadgets';
import {
  approveForSubscription,
  approveForUnsubscribe,
  getPassportMarketSellPrice,
  subscribePassport,
  unsubscribePassport,
} from '../../slices/PassportSlice';
import ModalComponent from '../Modal';
import AppLoaderComponent from '../Loader';
import { ScreenName } from '../../enums/screen.enum';
import HOCContainer from '../HOCContainer';
import GradientButton from '../GradientButton';

let heading;

const ChatroomSubscribe = ({ navigation, route }) => {
  const { userInfo, networkId, staticProvider, address, wallet } =
    useWeb3AuthContext();

  const selectedEXPass = route.params.selectedEXPass;
  const premiumChatCollectible = useAppSelector(
    state => state.Offers.premiumChatCollectibles[0],
  );
  const type = route.params.type;
  heading =
    type === 'sub'
      ? 'Subscribe to Premium Chatroom'
      : 'Unsubscribe From Chatroom';
  const [isSocialAvailable, setSocialAvailable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [sellMarketPrice, setSellMarketPrice] = useState('0.00');

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getMarketSellPrice = async () => {
      let marketSellPrice = 0;
      marketSellPrice = await getPassportMarketSellPrice(
        premiumChatCollectible?.address,
        premiumChatCollectible?.tokenId,
      );
      setSellMarketPrice(Number(marketSellPrice).toFixed(2));
    };
    getMarketSellPrice();
  }, []);

  const navigateToSuccessScreen = () => {
    setIsSubscribed(false);
    navigation.navigate('Success', {
      screen: ScreenName.SUCCESS_SCREEN,
      params: {
        message: `You have successfully unsubscribed from Premium chat ${premiumChatCollectible?.name}`,
        navigateTo: ScreenName.PASSPORT_DETAIL,
        navigationParams: {
          params: {
            chainId: selectedEXPass?.chainId,
            passportAddress: selectedEXPass.address,
          },
        },
      },
    });
  };

  const updateData = async data => {
    if (data?.fromCollectibleDetails && selectedEXPass) {
      await getSocialMediaAccess(
        selectedEXPass.address,
        selectedEXPass.chainId,
      ).then(isSocialAvailable => {
        setSocialAvailable(isSocialAvailable);
        if (isSocialAvailable) {
          dispatch(
            readMessages({
              address: selectedEXPass.address,
              chainId: networkId,
              networkID: networkId,
              provider: staticProvider,
              wallet,
            }),
          ).then(() => {
            dispatch(
              subscribeToPassportMessages({
                address: selectedEXPass.address,
                chainId: networkId,
                networkID: networkId,
                provider: staticProvider,
                wallet,
              }),
            );
          });
        }
      });
    }
  };

  const onConfirmClick = async () => {
    if (type === 'sub') {
      setIsLoading(true);
      await dispatch(
        approveForSubscription({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
        }),
      );
      const tokenID = await dispatch(
        subscribePassport({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
          passportAddress: premiumChatCollectible?.address,
          passportSubscribePrice: premiumChatCollectible?.price,
          userInfo: userInfo,
          collectionType: CatalogTypes.PREMIUMCHAT,
        }),
      );
      setIsLoading(false);
      if (tokenID.payload !== '') {
        setIsSubscribed(true);
        setTimeout(() => {
          navigation.navigate('Success', {
            screen: ScreenName.SUCCESS_SCREEN,
            params: {
              message: `You have successfully subscribed to the Premium Chatroom`,
              buttonLabel: 'Open Chatroom',
              navigateTo: ScreenName.PASSPORT_DETAIL,
              navigationParams: {
                screen: ScreenName.PASSPORT_DETAIL,
                params: {
                  catalog: premiumChatCollectible,
                  availableCatalogs: [],
                  type: CatalogTypes.PREMIUMCHAT,
                  from: ScreenName.PREMIUMCHAT_SUB_UNSUB + '_' + type,
                  updateParent: updateData,
                  passport: selectedEXPass,
                },
              },
            },
          });
        }, 500);
      } else {
        showToastMessage();
      }
    } else {
      setIsLoading(true);
      if (premiumChatCollectible?.collectibleIds?.length > 0) {
        let tokenID: number[] = [];
        tokenID.push(premiumChatCollectible?.collectibleIds[0]);
        await dispatch(
          approveForUnsubscribe({
            networkID: networkId,
            provider: staticProvider,
            wallet: wallet,
            address: address,
            passportAddress: premiumChatCollectible?.address,
            tokenId: tokenID[0],
          }),
        );
        await dispatch(
          unsubscribePassport({
            networkID: networkId,
            provider: staticProvider,
            wallet: wallet,
            address: address,
            passportAddress: premiumChatCollectible?.address,
            passportIds: tokenID,
            userInfo: userInfo,
            collectionType: CatalogTypes.PREMIUMCHAT,
          }),
        );
      }
      setIsLoading(false);
      setTimeout(() => {
        navigateToSuccessScreen();
      }, 500);
    }
  };
  return (
    <>
      <View
        style={{ height: '100%', width: '100%', overflow: 'hidden', flex: 1 }}>
        <View style={[{ paddingTop: 0, justifyContent: 'space-between' }]}>
          {/* Header Container */}

          <View style={{ justifyContent: 'center', padding: 20 }}>
            <Text style={localStyles.subHeadingDescription}>{heading}</Text>
          </View>

          <View
            style={{
              flexDirection: 'column',
              justifyContent: 'space-between',
              flex: 1,
            }}>
            <View
              style={{
                flexDirection: 'column',
                backgroundColor: 'rgba(255,255,255,0.13)',
                width: '100%',
                padding: 15,
                borderRadius: 6,
              }}>
              {/* ChatRoom Name */}
              <View style={styles.tokenBuyItemContainer}>
                <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
                  Chatroom Name
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 0.6,
                    justifyContent: 'flex-end',
                  }}>
                  <Text
                    style={{
                      ...styles.tokenBuyItemCaption,
                      maxWidth: '75%',
                      textAlign: 'right',
                    }}>
                    {premiumChatCollectible?.name}
                  </Text>
                </View>
              </View>
              <View style={styles.tokenBuyHairline} />

              {/* Associated EXPass */}
              <View style={styles.tokenBuyItemContainer}>
                <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
                  Associated ExPass
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 0.6,
                    justifyContent: 'flex-end',
                  }}>
                  <Text
                    style={{
                      ...styles.tokenBuyItemCaption,
                      maxWidth: '75%',
                      textAlign: 'right',
                    }}>{`${selectedEXPass?.name?.trim()} ${
                    selectedEXPass.subTitle
                      ? `#${selectedEXPass.subTitle.split('#')[1]}`
                      : ''
                  }`}</Text>
                </View>
              </View>
              <View style={styles.tokenBuyHairline} />

              {/* Initial Subscription Price */}
              {/* {type==='unsub' &&
                             <>
                             <View style={styles.tokenBuyItemContainer}>
                                <Text style={{...styles.tokenBuyItemCaption,flex:.6}}>{'Initial Subscription Price'}</Text>
                                <View style={{flexDirection:'row',flex:.4,justifyContent:'flex-end'}}>
                                    <Image source={require('../../assets/eightIcon.png')} style={{...localStyles.contentUserImage,borderWidth:0}}/>
                                    <Text style={{...styles.tokenBuyItemCaption,maxWidth:'75%',textAlign:'right'}}>{chatInitialBuyPrice}</Text>
                                </View>
                            </View>
                            <View style={styles.tokenBuyHairline} />
                            </> 
                            } */}
              {/* Amount (Loot8 Token) */}
              <View style={styles.tokenBuyItemContainer}>
                <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.6 }}>
                  {type === 'sub'
                    ? 'Amount (Loot8 Token)'
                    : 'Tokens Expected After Unsubscribing'}
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 0.4,
                    justifyContent: 'flex-end',
                  }}>
                  <Image
                    source={require('../../assets/eightIcon.png')}
                    style={{ ...localStyles.contentUserImage, borderWidth: 0 }}
                  />
                  <Text
                    style={{
                      ...styles.tokenBuyItemCaption,
                      maxWidth: '75%',
                      textAlign: 'right',
                    }}>
                    {type === 'sub'
                      ? premiumChatCollectible?.price?.toFixed(2)
                      : formatPriceUpToTrillion(sellMarketPrice)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ bottom: 30, position: 'absolute', width: '100%' }}>
          <GradientButton
            buttonLabel={
              isSubscribed
                ? 'Subscribed'
                : type === 'sub'
                  ? 'Subscribe'
                  : 'Unsubscribe'
            }
            disabled={isSubscribed || isLoading}
            loading={isLoading}
            onPress={() => onConfirmClick()}
          />
        </View>
      </View>
      {isLoading && (
        <ModalComponent
          showModal={isLoading}
          enableHeader={false}
          dismissable={false}
          loader>
          <AppLoaderComponent />
        </ModalComponent>
      )}
    </>
  );
};

export default HOCContainer({
  OriginalComponent: ChatroomSubscribe,
  title: heading,
});

const localStyles = StyleSheet.create({
  contentUserImage: {
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
    backgroundColor: 'lightblue',
    borderColor: '#fff',
    borderWidth: 1,
    marginRight: 5,
  },
  subHeadingDescription: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    textAlign: 'center',
    color: '#fff',
  },
});
