import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  APP_VERSION,
  MUTUALFRIENDS_MESSAGES_LAST_READ,
  NOTIFICATIONS,
  PASSPORT_MESSAGES_LAST_READ,
  PRIVATE_MESSAGE_FRIEND_REQUESTS,
} from '../appconstants';
import { LogToLoot8Console } from './Loot8ConsoleLogger';

export const storeData = async (key: string, value: any) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    LogToLoot8Console('storeData throws error', e);
  }
};

export const getData = async (key: string) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
  } catch (e) {
    LogToLoot8Console('getData throws error', key, e);
  }
};

export const removeData = async (key: string) => {
  try {
    const value = await AsyncStorage.removeItem(key);
    return value;
  } catch (e) {
    LogToLoot8Console('removeData throws error', key, e);
  }
};
export const getAllKeys = async () => {
  try {
    const value = await AsyncStorage.getAllKeys();
    return value;
  } catch (e) {
    LogToLoot8Console('getAllKeys throws error', e);
  }
};

export const clearAllData = async () => {
  try {
    const allKeys = await AsyncStorage.getAllKeys();
    const value = await AsyncStorage.multiRemove(
      allKeys.filter(p => PERMANENT_KEYS.findIndex(key => p.startsWith(key)) === -1),
    );
    return value;
  } catch (e) {
    LogToLoot8Console('clearAllData throws error', e);
  }
};

export const filterKeys = async (keyPrefix: string) => {
  try {
    let filteredKeys = [];
    const value = await AsyncStorage.getAllKeys();
    if (value?.length > 0) {
      filteredKeys = value?.filter(x => x.toLowerCase().startsWith(keyPrefix.toLowerCase()));
    }
    return filteredKeys;
  } catch (e) {
    LogToLoot8Console('filterKeys throws error', e);
  }
};

const PERMANENT_KEYS = [
  NOTIFICATIONS,
  APP_VERSION,
  PASSPORT_MESSAGES_LAST_READ('', ''),
  MUTUALFRIENDS_MESSAGES_LAST_READ('', ''),
  PRIVATE_MESSAGE_FRIEND_REQUESTS,
];
