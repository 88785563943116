import { IMessageList } from '../interfaces/IMessages';

let storedMessages: { [key: string]: IMessageList[] } = {};

class PassportMessagesStorage {
  storeMessages = (key: string, messages: IMessageList[]) => {
    storedMessages[key] = messages;
  };

  getMessages = (key: string): IMessageList[] => {
    if (storedMessages && storedMessages[key]) {
      return storedMessages[key];
    }
    return null;
  };
}

const singletonPassportMessagesStorage = Object.freeze(new PassportMessagesStorage());
export default singletonPassportMessagesStorage;
