import React, { useState } from 'react';
import { FlatList, ScrollView, View } from 'react-native';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { SheetManager } from 'react-native-actions-sheet';

import { styles } from './styles';
import TextInputComponent from '../TextInputComponent';
import GradientFilterButton from '../GradientFilterButton';
import SectionHeading from '../SectionHeading';
import GradientBorderBox from '../GradientBorderBox';
import {
  ArtistCategorySvg,
  BusinessCategorySvg,
  CelebritiesCategorySvg,
  EducatorsCategorySvg,
  FoundationsCategorySvg,
  GamingCategorySvg,
  MusicCategorySvg,
  OtherCategorySvg,
  SportsCategorySvg,
} from '../../assets/svg/HomeSvgs';
import TrendingExpasses from '../TrendingExpasses';
import SeeAllTextPressable from '../SeeAllTextPressable';
import { CategoriesList as CatList, SortingOptions } from '../../appconstants';
import { searchPassport } from '../../slices/PassportSlice';
import { defaultTheme } from '../../themes/loot8';
import { Category } from '../../enums/category.enum';

const CategoriesList = CatList.reverse();

const ExploreTabSection: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [selectedSortingOption, setSelectedSortingOption] = useState(
    SortingOptions.NONE,
  );
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const closeSortingOptionsMenu = async () => {
    setSelectedSortingOption(SortingOptions.NONE);
    await SheetManager.hide('SortModalSheet');
  };
  const navigateToSeeAll = () => {
    navigation.navigate('SeeAll', {
      screenTitle: 'Trending Profiles',
      type: 'trending',
      closeSortingOptionsMenu: closeSortingOptionsMenu,
    });
  };

  const onFilterPressed = async () => {
    await SheetManager.show('SortModalSheet', {
      payload: {
        sortingMenuOptions: [
          {
            id: 1,
            SortingOptions: SortingOptions.NONE,
            label: 'Default',
          },
          {
            id: 2,
            SortingOptions: SortingOptions.ALPHABETICAL_ASC,
            label: 'Sorted By: A-Z',
          },
          {
            id: 3,
            SortingOptions: SortingOptions.ALPHABETICAL_DESC,
            label: 'Sorted By: Z-A',
          },
        ],
        setSelectedSortingOption: setSelectedSortingOption,
        selectedSortingOption: selectedSortingOption,
      },
    });
  };

  const onPassportSearch = (searchText: string) => {
    setSearchText(searchText);
    dispatch(debounce(searchPassport({ searchText }), 500));
  };

  const onCategorySelect = (item: any) => {
    if (selectedCategory?.id == item.id) {
      setSelectedCategory(undefined);
    } else {
      setSelectedCategory(item);
    }
  };

  return (
    <View style={styles.container}>
      <View style={{ marginVertical: 10 }} />
      <TextInputComponent
        placeholder={'Search for categories, artist, collection...'}
        value={searchText}
        setOnChange={onPassportSearch}
        type="primary"
        leftIcon="magnify"
        rightIcon={!!searchText ? 'close' : null}
        onRightIconPress={() => setSearchText('')}
        customRight={
          <GradientFilterButton size={34} onPress={onFilterPressed} />
        }
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, borderRadius: defaultTheme.CONTENT_RADIUS }}
        contentContainerStyle={{
          flexGrow: 1,
          paddingTop: 5,
          paddingBottom: 150,
        }}>
        <SectionHeading title={`Categories`} />
        <FlatList
          numColumns={4}
          data={CategoriesList}
          renderItem={({ item, index }) => (
            <GradientBorderBox
              label
              catSvg
              selectable
              item={item}
              numLinesForLabel={1}
              height={undefined}
              activeTab={selectedCategory?.id}
              index={CategoriesList.length - index - 1}
              onPress={() => onCategorySelect(item)}
            />
          )}
          keyExtractor={item => item.id.toString()}
          columnWrapperStyle={{ justifyContent: 'space-between' }}
          scrollEnabled={false}
          showsVerticalScrollIndicator={false}
          style={styles.flatlistStyle}
          ItemSeparatorComponent={() => <View style={{ marginVertical: 5 }} />}
        />
        <SectionHeading
          title={`Trending Profiles`}
          renderRight={<SeeAllTextPressable onPress={navigateToSeeAll} />}
        />
        <TrendingExpasses
          closeSortingOptionsMenu={closeSortingOptionsMenu}
          selectedSortingOption={selectedSortingOption}
          selectedCategory={selectedCategory}
        />
      </ScrollView>
    </View>
  );
};

export default ExploreTabSection;

export const getCategorySvg = (id: number) => {
  switch (id) {
    case Category.OTHER:
      return <OtherCategorySvg />;
    case Category.SPORTS:
      return <SportsCategorySvg />;
    case Category.MUSIC:
      return <MusicCategorySvg />;
    case Category.CELEBRITIES:
      return <CelebritiesCategorySvg />;
    case Category.EDUCATORS:
      return <EducatorsCategorySvg />;
    case Category.BUSINESS:
      return <BusinessCategorySvg />;
    case Category.GAMING:
      return <GamingCategorySvg />;
    case Category.ARTIST:
      return <ArtistCategorySvg />;
    case Category.FOUNDATIONS:
      return <FoundationsCategorySvg />;
    default:
      return null;
  }
};
