import React, { useEffect, useState } from 'react';
import HOCContainer from '../components/HOCContainer';
import { FlatList, View, Text } from 'react-native';
import AppLoaderComponent from '../components/Loader';
import EmptyList from '../components/EmptyList';
import styles from '../styles';
import ExPassAssetsCard from '../components/ExPassAssetsCard';
import { innerStyles } from '../components/Passport/Tabs/Collectibles/styles';
import { ScreenName } from '../enums/screen.enum';
import { useAppSelector } from '../hooks';
import useExpassEventManager from '../hooks/useExpassEventManager';
import { defaultTheme } from '../themes/loot8';

const WhitelistedEvents = ({ navigation }) => {
  const { getEventsMetadata, loadingEvents } = useExpassEventManager();
  const eventAddresses = useAppSelector(state => state.AppUser.gatekeeperAt);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const onPressEventCard = item => {
    navigation.navigate(ScreenName.TICKET_SCAN, {
      item,
    });
  };

  useEffect(() => {
    const fetchEventsData = async () => {
      try {
        setLoading(true);
        const parsed = eventAddresses.map(event => {
          return { event };
        });

        let data = await getEventsMetadata(parsed);
        data = data.filter(d => d.eData);

        setEvents(data);
      } catch {
      } finally {
        setLoading(false);
      }
    };

    fetchEventsData();
  }, [eventAddresses]);

  return (
    <>
      <View style={[styles.passportBtnContainer]}>
        <Text
          style={{
            color: defaultTheme.PRIMARY_TEXT_COLOR,
            fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
            textAlign: 'center',
            paddingBottom: 24,
            paddingHorizontal: 12,
            fontStyle: 'italic',
          }}>
          Select the relevent event to redeem the QR code
        </Text>
        <View style={styles.passportContainer}>
          {!loadingEvents && !loading ? (
            <FlatList
              numColumns={2}
              data={events}
              scrollEnabled={false}
              style={innerStyles.flatList}
              contentContainerStyle={innerStyles.contentContainer}
              renderItem={({ item }) => {
                const { eData, dateString } = item;

                return (
                  <ExPassAssetsCard
                    item={{ ...eData, subtitle: dateString }}
                    onPress={() => onPressEventCard(item)}
                  />
                );
              }}
              keyExtractor={item => item?.eData?.name}
              columnWrapperStyle={innerStyles.columnWrapper}
              ItemSeparatorComponent={() => (
                <View style={innerStyles.separator} />
              )}
              ListEmptyComponent={
                <View style={{ marginVertical: 30 }}>
                  <EmptyList message="No events available." />
                </View>
              }
            />
          ) : (
            <View style={styles.passportCollectibleItemsLoaderContainer}>
              <AppLoaderComponent
                deemBg={false}
                extraStyle={{ borderRadius: 6 }}
                loaderText={{
                  text: 'Loading Events...',
                }}
              />
            </View>
          )}
        </View>
      </View>
    </>
  );
};

export default HOCContainer({
  OriginalComponent: WhitelistedEvents,
  isScrollEnabled: true,
  title: 'Whitelisted Events',
});
