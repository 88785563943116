import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import { addresses, getAnynetStaticProvider } from '../appconstants';
import { mintLinkedCollectibles as sendMintingRequest } from '../slices/DigitalCollectibleSlice';
import { useAppDispatch, useAppSelector } from '.';
import { useWeb3AuthContext } from './web3authContext';
import {
  CollectionManager__factory,
  Loot8Collection__factory,
} from '../typechain';
import { CollectionType } from '../enums/collection.enum';
import { OfferType } from '../enums/offers.enum';
import { convertAreaData, isLocationAvailable } from '../slices/helpers';
import { isActiveTimeStamp } from '../helpers/DateHelper';

export function useCollectibleMint() {
  const dispatch = useAppDispatch();
  const { address, wallet } = useWeb3AuthContext();
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );

  async function mintLinkedCollectibles(collection: ICollectibleDetail) {
    //* Provider based on chainID
    const providerToPass = getAnynetStaticProvider(collection?.chainId);

    //* Init Contract
    const collectionManager = CollectionManager__factory.connect(
      addresses[collection?.chainId].CollectionManager,
      providerToPass,
    );

    //* Minting Checks for limiting unnecessary contract calls

    //* 1. Should have Linked Collections
    if (collection?.linkCollectible?.length > 0) {
      // Iterate all linked collections
      for (const linkedAddress of collection?.linkCollectible) {
        try {
          const collectibleContract = Loot8Collection__factory.connect(
            linkedAddress,
            providerToPass,
          );
          const userBalance =
            +(await collectibleContract.balanceOf(address)) || 0;

          //* 2. User does not own the collectible
          if (userBalance === 0) {
            const info = await collectionManager.getCollectionInfo(
              linkedAddress,
            );
            const data = await collectionManager.collectionData(linkedAddress);

            //* 3. Should have Linked Collectibles
            if (
              info._collectionType === CollectionType.COLLECTION &&
              (data.offerType === OfferType.NOTANOFFER ||
                data.offerType === OfferType.REGULAR)
            ) {
              const isActive = info._isActive;
              const timeRange = [
                Number(data.start) * 1000,
                Number(data.end) * 1000,
              ];
              const isTimeStampValid =
                (timeRange[0] === 0 && timeRange[1] === 0) ||
                isActiveTimeStamp(timeRange[0], timeRange[1]);
              const isGeofenceValid = isLocationAvailable(
                currenLocation,
                convertAreaData(info?._areaPoints, info?._areaRadius),
              );
              const nextTokenId =
                await collectibleContract.collectionCollectibleIds();
              const isLimitAvailable =
                Number(data.maxMint) === 0 ||
                Number(nextTokenId) - 1 < Number(data.maxMint);

              //* 4. Satisfies Basic minting Criteria
              if (
                isActive &&
                isTimeStampValid &&
                isGeofenceValid &&
                isLimitAvailable
              ) {
                //* 5. Satisfies mintWithLinked Flag
                if (data.mintWithLinked) {
                  return await dispatch(
                    sendMintingRequest({
                      networkID: collection?.chainId,
                      provider: providerToPass,
                      address,
                      passportAddress: collection.address,
                      wallet,
                    }),
                  );
                }
              }
            }
          }
        } catch {}
      }
    }
  }

  return { mintLinkedCollectibles };
}
