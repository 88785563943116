import React, { useEffect } from 'react';
import {
  ActivityIndicator,
  FlatList,
  Platform,
  Pressable,
  StyleSheet,
  View
} from 'react-native';
import { Avatar, Button, Text } from 'react-native-paper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import {
  NotificationType,
  notificationsShown,
  setShowNotificationModal,
} from '../../slices/NotificationSlice';
import commonStyles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import { wait } from '../../helpers/ipfs';
import { ScreenName } from '../../enums/screen.enum';
import { useNavigation } from '@react-navigation/native';
import { FriendsTabs } from '../../enums/friendsPageTabs.enum';
import ModalComponent from '../Modal';
import { CatalogTypes } from '../../enums/catalog.enum';
import { OfferType, UICustomOfferType } from '../../enums/offers.enum';
import ProfileAvatar from '../ProfileAvatar';

const NotificationComponent = () => {
  const dispatch = useAppDispatch();
  const { staticProvider } = useWeb3AuthContext();
  const loadingNotifications = useAppSelector(
    state => state.notification.loading,
  );
  const notifications = useAppSelector(
    state => state.notification.notifications,
  );
  const notificationToDisplay = notifications
    .filter(f => true)
    .sort((a, b) => b.timeStamp - a.timeStamp);

  const navigation = useNavigation();

  const defaultIcon = require('../../assets/images/notification-default.png');
  const tokenIcon = require('../../assets/images/mint-token.png');
  const offerIcon = require('../../assets/images/mint-offer.png');
  const collectibleIcon = require('../../assets/images/mint-collectible.png');
  const passportIcon = require('../../assets/images/mint-passport.png');
  const defaultAvatarIcon = require('../../assets/loot8-default-avatar.png');
  const groupNotificationAvatarIcon = require('../../assets/friends/group_notification_icon.png');
  // list of mutual friends.
  const mutualFriends = useAppSelector(state => state.friends.mutualFriends);
  const { showNotificationModal } = useAppSelector(state => state.notification);
  const getDefaultIcon = (notificationType: NotificationType) => {
    switch (notificationType) {
      case NotificationType.LoyaltyTokenMint:
        return tokenIcon;
      case NotificationType.OfferMint:
        return offerIcon;
      case NotificationType.CollectibleMint || NotificationType.CollectibleSold:
        return collectibleIcon;
      case NotificationType.PassportMint || NotificationType.PassportSold:
        return passportIcon;
      case NotificationType.NewPrivateMessage:
        return defaultAvatarIcon;
      case NotificationType.CouponMint:
        return passportIcon;
      case NotificationType.PrivateMsgRequestReceived:
        return groupNotificationAvatarIcon;
      default:
        return defaultIcon;
    }
  };

  const onRedirectToPrivateMessage = data => {
    if (data?.dataObject) {
      onClearNotification(data?.id);
      if (
        data.notificationType === NotificationType.NewPrivateMessage ||
        data.notificationType === NotificationType.PrivateMsgRequestAccepted
      ) {
        const mutualFriend = mutualFriends?.filter(
          x =>
            x.isMutualFriend &&
            x.wallet.toLocaleLowerCase() ==
              data?.dataObject.wallet.toLocaleLowerCase(),
        );
        if (mutualFriend?.length > 0) {
          onNotificationClosed();
          navigation.navigate(ScreenName.FRIENDS_TAB, {
            selectedTab: FriendsTabs.DIRECTMESSAGE,
            selectedFriend: data?.dataObject,
            navigationFrom: FriendsTabs.FRIENDSMESSAGES,
            friendAddress: data?.dataObject?.wallet,
          });
        }
      }
    }
  };

  const onRedirectToCatalogDetails = data => {
    if (data?.dataObject) {
      onClearNotification(data?.id);
      onNotificationClosed();
      navigation.navigate(ScreenName.CATALOG_TAB, {
        screen: ScreenName.CATALOG_DETAILS,
        params: {
          catalog: data?.dataObject,
          availableCatalogs: null,
          type: CatalogTypes.COLLECTIBLES,
          from: ScreenName.CATALOG_TAB,
        },
        initial: false,
      });
    }
  };

  const onRedirectToWallet = data => {
    onClearNotification(data?.id);
    onNotificationClosed();
    navigation.navigate(ScreenName.WALLET_TAB);
  };

  const onRedirectToPassportDetails = data => {
    if (data?.dataObject) {
      onClearNotification(data?.id);
      onNotificationClosed();
      navigation.navigate(ScreenName.CATALOG_TAB, {
        screen: ScreenName.CATALOG_DETAILS,
        params: {
          catalog: data?.dataObject,
          availableCatalogs: null,
          type: CatalogTypes.PASSPORT,
          from: ScreenName.CATALOG_TAB,
        },
        initial: false,
      });
    }
  };

  const onRedirectToEventDetails = data => {
    if (data?.dataObject) {
      onClearNotification(data?.id);
      onNotificationClosed();
      navigation.navigate(ScreenName.CATALOG_TAB, {
        screen: ScreenName.CATALOG_DETAILS,
        params: {
          catalog: data?.dataObject,
          availableCatalogs: null,
          type: CatalogTypes.TICKET,
          from: ScreenName.CATALOG_TAB,
        },
        initial: false,
      });
    }
  };

  const onRedirectToCouponDetails = data => {
    if (data?.dataObject) {
      onClearNotification(data?.id);
      onNotificationClosed();
      if (data?.dataObject?.offerType == OfferType.FEATURED) {
        navigation.navigate(ScreenName.OFFERS_TAB, {
          screen: ScreenName.OFFERS_SCREEN,
          params: {
            landingTab: UICustomOfferType.FEATURED_OFFER,
          },
        });
      } else {
        navigation.navigate(ScreenName.OFFERS_TAB, {
          screen: ScreenName.OFFERS_SCREEN,
          params: {
            landingTab: UICustomOfferType.REGULAR,
          },
        });
      }
    }
  };

  const onUserStatusChange = data => {
    onClearNotification(data?.id);
  };

  const onCollectibleSold = data => {
    onClearNotification(data?.id);
  };

  const onPassportSold = data => {
    onClearNotification(data?.id);
  };

  const onRedirectToRequestScreen = data => {
    if (data) {
      onClearNotification(data?.id);
      if (
        data.notificationType === NotificationType.PrivateMsgRequestReceived
      ) {
        onNotificationClosed();
        navigation.navigate(ScreenName.FRIENDS_TAB, {
          selectedTab: FriendsTabs.REQUESTS,
          navigationFrom: FriendsTabs.FRIENDSMESSAGES,
        });
      }
    }
  };

  const renderItem = ({ item, index }) => (
    <Pressable
      key={item.id}
      style={[
        styles.notifications,
        {
          borderBottomWidth: index === notificationToDisplay.length - 1 ? 0 : 1,
        },
      ]}
      onPress={() => {
        if (
          item.notificationType === NotificationType.NewPrivateMessage ||
          item.notificationType === NotificationType.PrivateMsgRequestAccepted
        ) {
          onRedirectToPrivateMessage(item);
        } else if (item.notificationType === NotificationType.CollectibleMint) {
          onRedirectToCatalogDetails(item);
        } else if (
          item.notificationType === NotificationType.LoyaltyTokenMint
        ) {
          onRedirectToWallet(item);
        } else if (item.notificationType === NotificationType.PassportMint) {
          onRedirectToPassportDetails(item);
        } else if (item.notificationType === NotificationType.EventMint) {
          onRedirectToEventDetails(item);
        } else if (item.notificationType === NotificationType.CouponMint) {
          onRedirectToCouponDetails(item);
        } else if (
          item.notificationType === NotificationType.UserStatusChange
        ) {
          onUserStatusChange(item);
        } else if (item.notificationType === NotificationType.CollectibleSold) {
          onCollectibleSold(item);
        } else if (item.notificationType === NotificationType.PassportSold) {
          onPassportSold(item);
        } else if (
          item.notificationType === NotificationType.PrivateMsgRequestReceived
        ) {
          onRedirectToRequestScreen(item);
        }
      }}>
      {item.notificationType == NotificationType.NewPrivateMessage ||
      item.notificationType == NotificationType.PrivateMsgRequestReceived ? (
        <View>
          <ProfileAvatar
            style={[
              commonStyles.notificationImage,
              { backgroundColor: '#5E5EAA' },
            ]}
            size={36}
            source={
              item?.dataObject
                ? getDefaultIcon(item.notificationType)
                : item.uri
                  ? { uri: item.uri }
                  : defaultAvatarIcon
            }
            userAddress={item?.dataObject.wallet}
          />
        </View>
      ) : (
        <Avatar.Image
          style={[
            commonStyles.notificationImage,
            { backgroundColor: '#5E5EAA' },
          ]}
          size={36}
          source={
            item.uri ? { uri: item.uri } : getDefaultIcon(item.notificationType)
          }
        />
      )}
      <View style={styles.innerContainer}>
        <Text style={styles.notificationSubjText}>{item.subject}</Text>
        <Text style={styles.notificationText}>{item.body}</Text>
      </View>
      {!item.shown && (
        <View style={styles.isReadDotContainer}>
          <View style={styles.isReadDot}></View>
        </View>
      )}
    </Pressable>
  );

  const onNotificationClosed = async () => {
    dispatch(setShowNotificationModal(false));
  };

  const onClearNotification = async notificationId => {
    //Update Notification to latest block number
    await dispatch(notificationsShown(notificationId));
    //const currBlockNumber = await staticProvider.getBlockNumber();
    //await storeData(LAST_BLOCK_NUMBER, currBlockNumber);
    //await dispatch(setLastBlockNumber(currBlockNumber));
    //dispatch(clearNotifications());
  };

  return (
    <ModalComponent
      showModal={showNotificationModal}
      modalStyle={{
        marginTop:
          Platform.OS == 'android' ? 30 : Platform.OS == 'web' ? '3rem' : 100,
        maxHeight: Platform.OS == 'web' ? '70vh' : '70%',
        // minHeight: Platform.OS == 'web' ? '72vh' : '87%',
      }}
      enableHeaderhairline={false}
      enableHeader={false}
      needCloseButton={true}
      onDismiss={onNotificationClosed}
      modalBodyStyle={{
        width: '100%',
        height: '100%',
      }}>
      <View style={styles.notificationsHeaderContainer}>
        <Text style={styles.notificationsHeaderText}>My Notifications</Text>
      </View>
      {notificationToDisplay && notificationToDisplay.length > 0 ? (
        <View style={{ flex: 1 }}>
          <Pressable style={{ padding: 0 }}>
            <Button
              mode="text"
              onPress={() => onClearNotification(null)}
              disabled={
                !notificationToDisplay.find(notification => !notification.shown)
              }
              labelStyle={[
                styles.markAllAsRead,
                !notificationToDisplay.find(
                  notification => !notification.shown,
                ) && { color: defaultTheme.SECONDARY_TEXT_COLOR },
              ]}
              style={{
                alignSelf: 'flex-end',
                marginTop: 15,
                backgroundColor: 'rgba(0,0,0,0.15)',
              }}>
              {'Mark all as read'}
            </Button>
          </Pressable>

          <FlatList
            data={notificationToDisplay}
            style={{ marginHorizontal: 5, flex: 1 }}
            contentContainerStyle={{ flexGrow: 1 }}
            renderItem={renderItem}
            showsVerticalScrollIndicator={false}
            keyExtractor={item => item.id}
            scrollEventThrottle={0}
            ListFooterComponent={() => {
              return loadingNotifications ? (
                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <View
                    style={[
                      {
                        alignSelf: 'center',
                        paddingTop: 10,
                        paddingBottom: 20,
                      },
                    ]}>
                    <ActivityIndicator
                      size={'small'}
                      color={defaultTheme.PRIMARY_TEXT_COLOR}
                    />
                  </View>
                  {/* <LottieView source={Loader} autoPlay loop style={{ padding: 0, width: 150, height: 100 }}/> */}
                </View>
              ) : null;
            }}
          />
        </View>
      ) : (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 5,
          }}>
          {loadingNotifications ? (
            <View
              style={[
                { alignSelf: 'center', paddingTop: 5, paddingBottom: 20 },
              ]}>
              <ActivityIndicator
                size={'small'}
                color={defaultTheme.MAIN_BACKGROUND_COLOR}
              />
            </View>
          ) : (
            // <LottieView source={Loader} autoPlay loop style={{ padding: 0, width: 150, height: 100 }}/>
            <View
              style={{
                alignSelf: 'center',
                marginVertical: 15,
                paddingHorizontal: 15,
              }}>
              <Text
                style={{
                  alignSelf: 'center',
                  color: defaultTheme.SECONDARY_TEXT_COLOR,
                  fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                }}>
                {!loadingNotifications && 'No new notifications!'}
              </Text>
            </View>
          )}
        </View>
      )}
    </ModalComponent>
  );
};
const styles = StyleSheet.create({
  notifications: {
    paddingVertical: 15,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor:
      Platform.OS === 'android'
        ? 'rgba(191,191,191,0.7)'
        : 'rgba(191,191,191,0.3)',
  },
  innerContainer: {
    flexDirection: 'column',
    marginLeft: 10,
    flex: 1,
  },
  notificationsHeaderContainer: {
    height: 65,
    width: '95%',
    alignSelf: 'center',
    borderBottomColor: defaultTheme.PRIMARY_TEXT_COLOR,
    borderBottomWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationsHeaderText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  markAllNotificationText: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    textDecorationLine: 'underline',
    padding: 3,
  },
  notificationSubjText: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    marginBottom: 5,
    width: '90%',
  },
  notificationText: {
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    width: '90%',
  },
  isReadDotContainer: {
    width: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  isReadDot: {
    height: 6,
    width: 6,
    borderRadius: 6 / 2,
    backgroundColor: '#fff',
  },
  markAllAsRead: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    textDecorationLine: 'underline',
  },
});

export default NotificationComponent;
