import * as FileSystem from 'expo-file-system';

export const ensureDirExists = async dir => {
  const dirInfo = await FileSystem.getInfoAsync(dir);
  if (!dirInfo.exists) {
    console.log("Logs directory doesn't exist, creating...");
    await FileSystem.makeDirectoryAsync(dir, { intermediates: true });
  }
};

export const storeContentToFile = async (filePath: string, content: string) => {
  await FileSystem.writeAsStringAsync(filePath, content);
};

export const getStoredContentByFile = async (filePath: string): Promise<string> => {
  try {
    return await FileSystem.readAsStringAsync(filePath);
  } catch (error) {
    return null;
  }
};
