import React, { useState } from 'react';
import { View } from 'react-native';

import { styles } from './styles';
import { ScreenName } from '../../../../enums/screen.enum';
import PassportMessageComponent from '../../../PassportMessage';

type CommunityDiscussionProps = {
  passportAddress: any;
  selectPassport: any;
  chainId: any;
  scrollToMessages: any;
  setScrollToMessages: any;
  isSocialAvailable: any;
  scrollToText: any;
  scrollView: any;
};

const CommunityDiscussion: React.FC<CommunityDiscussionProps> = ({
  selectPassport,
  passportAddress,
  chainId,
  scrollToMessages,
  setScrollToMessages,
  isSocialAvailable,
  scrollToText,
  scrollView,
}) => {
  const [yCoordinate, setYCoordinate] = useState(200);

  return (
    <View style={styles.container}>
      {/* passport messages */}
      <View
        onLayout={event => {
          setYCoordinate(event.nativeEvent.layout.y);
        }}>
        <PassportMessageComponent
          selectedPassport={selectPassport}
          scrollToMessages={scrollToMessages}
          setScrollToMessages={setScrollToMessages}
          scrollViewRef={scrollView}
          yCoordinate={yCoordinate}
          scrollToText={scrollToText}
          navigationFrom={{
            screen: ScreenName.HOME_SCREEN,
            params: {
              passportAddress,
              chainId,
            },
          }}
          isSocialAvailable={isSocialAvailable}
        />
      </View>
    </View>
  );
};

export default CommunityDiscussion;
