import React, { useEffect, useRef, useState } from 'react';
import {
  FlatList,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
  findNodeHandle,
  Text,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import HOCContainer from '../../../HOCContainer';
import CachedImage from '../../../CachedImage';
import useIsResponsive from '../../../../hooks/useIsResponsive';
import useActiveDimensions from '../../../../hooks/useActiveDimensions';
import { defaultTheme } from '../../../../themes/loot8';
import GradientBorderBox from '../../../GradientBorderBox';
import EventTicket from './EventTicket';
import Collectibles from '../Collectibles';
import FeaturedOffers from '../FeaturedOffers';
import { wait } from '../../../../helpers/ipfs';
import CommunityDiscussion from '../CommunityDiscussion';
import { useNavigation } from '@react-navigation/native';
import styles from '../../../../styles';
import { useWeb3AuthContext } from '../../../../hooks/web3authContext';
import useExpassEventManager, {
  AssociatedEvent,
} from '../../../../hooks/useExpassEventManager';
import { useAppDispatch } from '../../../../hooks';
import {
  getSocialMediaAccess,
  readMessages,
  setinitialMessageLoading,
  subscribeToPassportMessages,
} from '../../../../slices/PassportMessageSlice';
import ScreenTitle from '../../../ScreenTitle';
import SectionHeading from '../../../SectionHeading';
import { CollectibleWebBlurView } from '../../../WebBlurView';
import { BlurView } from 'expo-blur';

const EventDetails = ({ route }) => {
  const dispatch = useAppDispatch();
  const paramsData: AssociatedEvent = route?.params?.payload?.selectedEvent;
  const { address, wallet, networkId, staticProvider } = useWeb3AuthContext();
  const navigation = useNavigation();
  const tabsRef = useRef(null);
  const scrollView = useRef<KeyboardAwareScrollView>();
  const { loadingMetadata, getCollectionsMetadataForEvent } =
    useExpassEventManager();

  const isResponsive = useIsResponsive();
  const { width: activeWidth, height: activeHeight } = useActiveDimensions();

  const [activeTab, setActiveTab] = useState(0);
  const [scrollToMessages, setScrollToMessages] = useState(false);
  const [isSocialAvailable, setSocialAvailable] = useState(true);
  const [isTicketPurchased, setIsTicketPurchased] = useState(false);
  const [isTicketRedeemed, setIsTicketRedeemed] = useState(false);
  const [selectedEvent, setSelectedEvent] =
    useState<AssociatedEvent>(paramsData);
  const boxWidth = isResponsive
    ? defaultTheme.CONTENT_CONTAINER_MAX_WIDTH / 6
    : activeWidth / 5;

  const loadSocialMediaMessages = async () => {
    dispatch(setinitialMessageLoading(true));
    await getSocialMediaAccess(selectedEvent?.event, networkId).then(
      isSocialAvailable => {
        setSocialAvailable(isSocialAvailable);

        if (isSocialAvailable) {
          dispatch(
            readMessages({
              address: selectedEvent?.event,
              chainId: networkId,
              networkID: networkId,
              provider: staticProvider,
              wallet,
            }),
          ).then(() => {
            dispatch(
              subscribeToPassportMessages({
                address: selectedEvent?.event,
                chainId: networkId,
                networkID: networkId,
                provider: staticProvider,
                wallet,
              }),
            );
            dispatch(setinitialMessageLoading(false));
          });
        }
      },
    );
  };

  const fetchMetadata = async () => {
    const response = await getCollectionsMetadataForEvent(paramsData);

    const tOwned =
      response?.tData?.collectibleCount > 0 &&
      response?.tData?.collectibleIds?.length > 0;

    if (tOwned) {
      // @ts-ignore
      let redemptionStatus = response.tData?.redemptionStatus || false;

      if (redemptionStatus && redemptionStatus?.redeemed) {
        setIsTicketRedeemed(true);
      }
    }

    setIsTicketPurchased(tOwned);
    setSelectedEvent(response);
  };

  useEffect(() => {
    fetchMetadata();
  }, []);

  useEffect(() => {
    if (selectedEvent) {
      loadSocialMediaMessages();
    }
  }, [selectedEvent]);

  const onTabPress = index => {
    if (activeTab !== index) {
      setActiveTab(index);
    }
    const offset = isResponsive
      ? index *
        (defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
          defaultTheme.HORIZONTAL_PADDING * 2)
      : index * (activeWidth - defaultTheme.HORIZONTAL_PADDING * 2);

    tabsRef?.current.scrollToOffset({ offset });
  };

  const scrollToText = async event => {
    if (Platform.OS !== 'web') {
      const node = findNodeHandle(event.target);
      await wait(50);
      scrollView.current.scrollToFocusedInput(node, 290);
    } else {
      if (
        event &&
        event?.nativeEvent?.layout?.top +
          event?.nativeEvent?.layout?.height +
          90 >
          window.innerHeight
      ) {
        scrollView.current.scrollToFocusedInput(
          findNodeHandle(event.nativeEvent.target),
          0,
          100,
        );
      }
    }
  };

  const onCreatePost = () => {
    // @ts-ignore
    navigation.navigate('CreatePostScreen', {
      passportAddress: selectedEvent?.event,
      chainId: selectedEvent?.tData?.chainId,
      setScrollToMessages: setScrollToMessages,
    });
  };

  return (
    <>
      <ScreenTitle title={selectedEvent?.eData?.name} />
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        style={{ flex: 1 }}
        ref={scrollView}>
        <View
          style={[
            componentStyles.bannerContainer,
            { height: isResponsive ? activeHeight / 3 : activeHeight / 4 },
          ]}>
          <CachedImage
            isBackground
            source={{ uri: selectedEvent?.eData?.image }}
            imageSize={100}
            noImageFile={'no-image-text.png'}
            style={{
              ...componentStyles.bannerImage,
              minHeight: isResponsive ? activeHeight / 3 : activeHeight / 4,
              height: isResponsive ? activeHeight / 3 : activeHeight / 4,
            }}
            contentFit="contain"></CachedImage>
        </View>
        <View>
          <FlatList
            horizontal
            data={tabsData}
            renderItem={({ item, index }) => (
              <GradientBorderBox
                label
                selectable
                item={item}
                index={index}
                numLinesForLabel={2}
                activeTab={activeTab}
                width={boxWidth}
                height={isResponsive ? 62 : 44}
                onPress={() => onTabPress(index)}
              />
            )}
            showsVerticalScrollIndicator={false}
            keyExtractor={item => item.id.toString()}
            style={{ flexGrow: 0, marginTop: 25, marginBottom: 16 }}
            contentContainerStyle={{
              justifyContent: 'space-between',
              flex: 1,
            }}
            ItemSeparatorComponent={() => (
              <View style={{ marginHorizontal: 5 }} />
            )}
          />

          <FlatList
            horizontal
            ref={tabsRef}
            data={tabsData}
            style={{ flex: 1 }}
            scrollEnabled={false}
            snapToAlignment="start"
            decelerationRate={'fast'}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{ flexGrow: 1 }}
            renderItem={({ index }) => (
              <Slide
                activeIndex={activeTab}
                scrollToMessages={scrollToMessages}
                scrollToText={scrollToText}
                scrollView={scrollView}
                setScrollToMessages={setScrollToMessages}
                isSocialAvailable={isSocialAvailable}
                selectedEvent={selectedEvent}
                loadingMetadata={loadingMetadata}
                networkId={networkId}
                isTicketPurchased={isTicketPurchased}
                isTicketRedeemed={isTicketRedeemed}
              />
            )}
            snapToInterval={activeWidth - defaultTheme.HORIZONTAL_PADDING * 2}
          />

          <View style={{ height: 150 }} />
        </View>
      </KeyboardAwareScrollView>
      {isTicketPurchased && !loadingMetadata && activeTab === 3 && (
        <Pressable
          style={[
            styles.fabIcon,
            {
              width: 46,
              height: 46,
              borderWidth: 2,
              borderRadius: 100,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'transparent',
              borderColor: isSocialAvailable
                ? defaultTheme.GRADIENT_COLOR1
                : defaultTheme.PRIMARY_SHADOW_COLOR,
              right: 0,
              top: '65%',
            },
          ]}
          disabled={!isSocialAvailable}
          onPress={isSocialAvailable && onCreatePost}>
          <Icon
            name="chat-plus-outline"
            size={26}
            color={
              isSocialAvailable
                ? defaultTheme.PRIMARY_TEXT_COLOR
                : defaultTheme.PRIMARY_SHADOW_COLOR
            }
            onPress={isSocialAvailable && onCreatePost}
          />
        </Pressable>
      )}
    </>
  );
};

export default HOCContainer({
  OriginalComponent: EventDetails,
  isScrollEnabled: false,
});

const Slide = ({
  activeIndex = 0,
  scrollToMessages,
  scrollToText,
  setScrollToMessages,
  scrollView,
  isSocialAvailable,
  selectedEvent,
  networkId,
  loadingMetadata = false,
  isTicketPurchased,
  isTicketRedeemed,
}) => {
  const _selectedEvent: AssociatedEvent = selectedEvent;

  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();

  const renderContent = () => {
    switch (activeIndex) {
      case 0: {
        return (
          <EventTicket
            isLoading={loadingMetadata}
            selectedEvent={_selectedEvent}
            isTicketPurchased={isTicketPurchased}
            isTicketRedeemed={isTicketRedeemed}
          />
        );
      }
      case 1: {
        if (!isTicketPurchased && !loadingMetadata) {
          return (
            <LockedBlurView description="Unlock Featured Offers by booking the ticket" />
          );
        }

        return (
          <FeaturedOffers
            overrideOffers={_selectedEvent?.oData}
            isLoading={loadingMetadata}
          />
        );
      }
      case 2: {
        if (!isTicketPurchased && !loadingMetadata) {
          return (
            <LockedBlurView description="Unlock your free collectibles by booking the ticket" />
          );
        }

        return (
          <Collectibles
            selectPassport={null}
            overrideCollectibles={_selectedEvent?.cData || []}
            isLoading={loadingMetadata}
          />
        );
      }
      case 3: {
        if (loadingMetadata) {
          return null;
        }

        if (!isTicketPurchased) {
          return (
            <LockedBlurView description="Unlock Community discussion by booking the ticket" />
          );
        }

        return (
          <CommunityDiscussion
            scrollToMessages={scrollToMessages}
            scrollToText={scrollToText}
            setScrollToMessages={setScrollToMessages}
            scrollView={scrollView}
            passportAddress={_selectedEvent?.event}
            selectPassport={_selectedEvent}
            isSocialAvailable={isSocialAvailable}
            chainId={networkId}
          />
        );
      }
      default: {
        return (
          <EventTicket
            isLoading={loadingMetadata}
            selectedEvent={_selectedEvent}
            isTicketPurchased={isTicketPurchased}
            isTicketRedeemed={isTicketRedeemed}
          />
        );
      }
    }
  };

  return (
    <View
      style={
        isResponsive
          ? {
              width:
                defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
                defaultTheme.HORIZONTAL_PADDING * 2,
            }
          : {
              width: activeWidth - defaultTheme.HORIZONTAL_PADDING * 2,
            }
      }>
      {renderContent()}
    </View>
  );
};

const LockedBlurView = ({ title = '', description = '' }) => {
  return (
    <>
      <SectionHeading title={title} />

      {Platform.OS === 'web' ? (
        <CollectibleWebBlurView>
          <View style={componentStyles.blurView}>
            <Icon
              name="lock-outline"
              size={26}
              color={defaultTheme.PRIMARY_TEXT_COLOR}
            />
            <Text style={componentStyles.blurText}>{description}</Text>
          </View>
        </CollectibleWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={componentStyles.blurView}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <Icon
            name="lock-outline"
            size={26}
            color={defaultTheme.PRIMARY_TEXT_COLOR}
          />
          <Text style={componentStyles.blurText}>{description}</Text>
        </BlurView>
      )}
    </>
  );
};

const componentStyles = StyleSheet.create({
  postButton: {
    position: 'absolute',
    right: 15,
    top: '65%',
    width: 46,
    height: 46,
    borderWidth: 2,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    zIndex: 110,
  },
  bannerContainer: {
    borderWidth: 1,
    borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    overflow: 'hidden',
  },
  bannerImage: {
    maxWidth: '100%',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  videoPlayer: {
    width: '100%',
    height: '100%',
  },
  video: {
    position: 'relative',
    flex: 1,
    padding: 5,
  },
  playerControls: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    height: 50,
    zIndex: 6,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  title: {
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  descriptionTitle: {
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    marginBottom: 2.5,
  },
  description: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  text: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 6,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  roundBtn: {
    height: 35,
    width: 35,
    borderRadius: 35,
    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    marginVertical: 10,
  },
  blurView: {
    overflow: 'hidden',
    backgroundColor: 'rgba(0,0,0,0.2)',
    minHeight: 170,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  blurText: {
    marginTop: 10,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XMEDIUM + 2,
  },
});

const tabsData = [
  {
    id: 1,
    name: 'Event Detail',
  },
  {
    id: 2,
    name: `Featured Offers`,
  },
  {
    id: 3,
    name: `Featured Collectibles`,
  },
  {
    id: 4,
    name: `Community Discussions`,
  },
];
