import React, { FC, useEffect } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { getPathXCenterByIndex } from './utils/path';
import usePath from './hooks/usePath';
import { HEIGHT, WIDTH, defaultTheme } from '../../themes/loot8';
import AnimatedCircle from './AnimatedCircle';
import { RESPONSIVE, RESPONSIVE_WIDTH } from '../../appconstants';
import useBigScreen from './hooks/useBigScreen';
import useActiveDimensions from '../../hooks/useActiveDimensions';
export type TabProps = {
  label: string;
  index: number;
  activeIndex: number;
  onTabPress: () => void;
};
const ICON_SIZE = 25;
const LABEL_WIDTH = WIDTH / 4;

const TabItem: FC<TabProps> = ({ label, index, activeIndex, onTabPress }) => {
  const { curvedPaths } = usePath();
  const isBigScreen = useBigScreen();
  const activeWidth = useActiveDimensions().width;

  const animatedActiveIndex = useSharedValue(activeIndex);
  const animatedActiveSize = useSharedValue(34);
  const animatedActiveTabPosYSize = useSharedValue(15);
  const animatedActiveLabelPosYSize = useSharedValue(100);
  const iconPosition = getPathXCenterByIndex(curvedPaths[index]);
  const labelPosition = getPathXCenterByIndex(curvedPaths[index]);

  const tabStyle = useAnimatedStyle(() => {
    const iconPositionX = iconPosition - index * ICON_SIZE;
    return {
      width: ICON_SIZE,
      height: ICON_SIZE,
      transform: [
        { translateY: animatedActiveTabPosYSize.value },
        { translateX: iconPositionX - ICON_SIZE / 2 },
      ],
    };
  });
  const labelContainerStyle = useAnimatedStyle(() => {
    return {
      transform: [
        { translateY: animatedActiveLabelPosYSize.value },
        { translateX: labelPosition - LABEL_WIDTH / 2 },
      ],
    };
  });

  useEffect(() => {
    const translateY =
      activeIndex === index + 1
        ? -35
        : isBigScreen && activeWidth > 450
          ? 15
          : 15;
    const translateYLabel =
      activeIndex === index + 1
        ? isBigScreen && activeWidth > 450
          ? 50
          : 45
        : 100;

    if (activeIndex === index + 1) {
      animatedActiveSize.value = withTiming(64);
      animatedActiveTabPosYSize.value = withTiming(translateY);
      animatedActiveLabelPosYSize.value = withTiming(translateYLabel);
    } else {
      animatedActiveSize.value = withTiming(34);
      animatedActiveTabPosYSize.value = withTiming(translateY);
      animatedActiveLabelPosYSize.value = withTiming(translateYLabel);
    }
  }, [activeIndex, isBigScreen, activeWidth]);

  return (
    <>
      {iconPosition && (
        <Animated.View style={[tabStyle]}>
          <Pressable
            testID={`tab${label}`}
            //Increasing touchable Area
            hitSlop={{ top: 30, bottom: 30, left: 50, right: 50 }}
            onPress={onTabPress}>
            <AnimatedCircle expand={activeIndex === index + 1} index={index} />
          </Pressable>
        </Animated.View>
      )}
      {labelPosition && (
        <Animated.View style={[labelContainerStyle, styles.labelContainer]}>
          <Text style={styles.label}>{label}</Text>
        </Animated.View>
      )}
    </>
  );
};

export default TabItem;

const styles = StyleSheet.create({
  labelContainer: {
    position: 'absolute',
    alignItems: 'center',
    width: LABEL_WIDTH,
  },
  label: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    lineHeight: 15,
    marginLeft: 2.5,
  },
  iconContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,

    elevation: 15,
  },
});
