import React, { memo, useEffect, useState } from 'react';
import {
  DeviceEventEmitter,
  NativeEventEmitter,
  Platform,
  StyleSheet,
  Text,
  View,
  Image,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { defaultTheme } from '../../themes/loot8';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import { APP_STORAGE_GET_COLLECTIBLEDETAILS } from '../../appconstants';
import { TouchableRipple } from 'react-native-paper';
import { CatalogTypes } from '../../enums/catalog.enum';
import { PassportSubscriptionActions } from '../../enums/passportCategory.enum';
import GradientPrice from '../GradientPrice';
import { formatPriceUpToTrillion } from '../../helpers/Gadgets';
import { CustomDeviceEvents } from '../../enums/screen.enum';
import { getData } from '../../helpers/AppStorage';
import {
  getPassportFinalBuyPrice,
  updatePassportBuyPrice,
} from '../../slices/PassportSlice';
import { useAppDispatch } from '../../hooks';

type SubscribeModalContentProps = {
  selectedEXPass: any; // Define the type for selectedEXPass
  collectionType: string; // Assuming it's a string
  currentBalance: number; // Assuming it's a number
  navigateToTokenPackages: () => void; // Assuming it's a function
  onSubscribeClick: () => void; // Assuming it's a function
  hasCurrentBalanceLoaded: boolean; // Assuming it's a boolean
  title: string; // Assuming it's a string
};

const SubscribeModalContent: React.FC<SubscribeModalContentProps> = ({
  selectedEXPass,
  collectionType,
  currentBalance,
  navigateToTokenPackages,
  onSubscribeClick,
  hasCurrentBalanceLoaded,
  title,
}) => {
  const objectToRender = selectedEXPass?.collectionData;
  let collectionPrice =
    collectionType === CatalogTypes.PASSPORT
      ? selectedEXPass?.price
      : selectedEXPass?.buyPrice;
  const activeHeight = useActiveDimensions().height;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Image
        source={{
          uri:
            objectToRender?.thumbnailImage !== ''
              ? objectToRender?.thumbnailImage
              : objectToRender?.image,
        }}
        style={[
          styles.image,
          {
            height: activeHeight / 5,
          },
        ]}
        resizeMode="contain"
      />

      <View style={styles.row}>
        <View>
          <Text style={[styles.title, { marginBottom: 2.5 }]}>
            {objectToRender?.name}
          </Text>
          <Text style={styles.text}>{objectToRender?.name}</Text>
        </View>
        <PassportPriceComponent
          type={CatalogTypes.PASSPORT}
          item={selectedEXPass}
          formattedprice={collectionPrice}
        />
      </View>
      <View style={styles.separator} />
      <View style={{ marginBottom: 25 }}>
        <Text style={[styles.title, { marginBottom: 2.5, textAlign: 'left' }]}>
          Description
        </Text>
        <ScrollView
          nestedScrollEnabled={true}
          showsVerticalScrollIndicator={false}
          style={[styles.scroll, { maxHeight: activeHeight / 10 }]}>
          <Text style={styles.text}>{objectToRender?.details}</Text>
        </ScrollView>
      </View>
      <View style={[styles.row, { marginBottom: 15, marginTop: 25 }]}>
        <Text style={styles.italicText}>
          Available LOOT8 Balance: {currentBalance}
        </Text>
        {!hasCurrentBalanceLoaded && collectionPrice > currentBalance && (
          <Text
            style={[
              styles.italicText,
              {
                color: 'red',
                textDecorationLine: 'none',
              },
            ]}>
            Insufficient Funds
          </Text>
        )}
      </View>
      <ConfirmButton
        onPress={
          !hasCurrentBalanceLoaded && collectionPrice > currentBalance
            ? () => navigateToTokenPackages()
            : () => onSubscribeClick(PassportSubscriptionActions.SUBSCRIBE)
        }
        title={
          title
            ? title
            : !hasCurrentBalanceLoaded && collectionPrice > currentBalance
              ? 'Deposit Funds'
              : 'Subscribe'
        }
      />
    </View>
  );
};

export default SubscribeModalContent;

const styles = StyleSheet.create({
  container: { paddingHorizontal: 15, paddingVertical: 25 },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginBottom: 15,
  },
  text: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  italicText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textDecorationLine: 'underline',
  },
  btnText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  buttonContainer: {
    height: 48,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_TEXT_COLOR,
    opacity: 0.6,
    marginTop: 15,
    marginBottom: 15,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  image: {
    width: '100%',
    marginHorizontal: defaultTheme.HORIZONTAL_PADDING,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    borderWidth: 1,
    borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
    backgroundColor: 'rgba(0,0,0,0.5)',
    alignSelf: 'center',
  },
  scroll: {
    borderRadius: defaultTheme.CONTENT_RADIUS,
    overflow: 'hidden',
  },
});

const ConfirmButton = ({ onPress, title }) => {
  return (
    <View style={[styles.buttonContainer]}>
      <TouchableRipple
        style={styles.ripple}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}>
        <Text style={styles.btnText}>{title}</Text>
      </TouchableRipple>
    </View>
  );
};

const PassportPriceComponent = memo(
  ({ type, item, updateBuyPrice, onPriceTagPress }) => {
    const dispatch = useAppDispatch();
    const [latestBuyPrice, setLatestBuyPrice] = useState(0);

    useEffect(() => {
      if (updateBuyPrice) {
        (async () => {
          // Fetch latest price before proceeding for purchase
          const buyPrice = await getPassportFinalBuyPrice(item?.address);
          dispatch(
            updatePassportBuyPrice({
              address: item?.address,
              buyPrice: buyPrice,
            }),
          );
          setLatestBuyPrice(buyPrice);
        })();
      }

      let event: any = DeviceEventEmitter;
      if (Platform.OS === 'android') {
        event = new NativeEventEmitter();
      }
      event.addListener(CustomDeviceEvents.ExPassPriceUpdated, async () => {
        const localStoredData = await getData(
          APP_STORAGE_GET_COLLECTIBLEDETAILS(item.address),
        );
        if (localStoredData) {
          setLatestBuyPrice(localStoredData.buyPrice);
        }
      });
      return () =>
        event.removeAllListeners(CustomDeviceEvents.ExPassPriceUpdated);
    }, []);

    useEffect(() => {
      // update local price state when item state changes
      if (type === CatalogTypes.PASSPORT) {
        setLatestBuyPrice(item.price);
      } else if (type === CatalogTypes.PREMIUMCHAT) {
        setLatestBuyPrice(item.buyPrice);
      }
    }, [item?.buyPrice, item?.price]);

    return (
      <GradientPrice
        onPress={onPriceTagPress}
        price={formatPriceUpToTrillion(latestBuyPrice)}
      />
    );
  },
);

