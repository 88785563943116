import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { defaultTheme } from '../../themes/loot8';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import useIsResponsive from '../../hooks/useIsResponsive';
import GradientBoxAnimated from './GradientBoxAnimated';
import { Text } from 'react-native';

const AnimatedTabBoxes = ({
  data,
  changeSlidesOnPress,
  height = 48,
  fullWidth,
  numColumns = 2,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();

  const tabWidth = isResponsive
    ? defaultTheme.CONTENT_CONTAINER_MAX_WIDTH / data.length -
      defaultTheme.HORIZONTAL_PADDING
    : activeWidth / data.length - defaultTheme.HORIZONTAL_PADDING;

  const onTabPress = index => {
    if (data[index]?.disabled) {
      return;
    } else {
      changeSlidesOnPress(index);
      setActiveIndex(index);
    }
  };
  useEffect(() => {
    onTabPress(activeIndex);
  }, [activeWidth, isResponsive]);
  return (
    <View>
      <FlatList
        style={{ flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1,
        }}
        numColumns={numColumns}
        scrollEnabled={false}
        data={data}
        initialNumToRender={data.length}
        renderItem={({ item, index }) => (
          <GradientBoxAnimated
            title={item.title}
            onPress={() => onTabPress(index)}
            height={height}
            fullWidth={fullWidth}
            active={index === activeIndex}
            disabled={item?.disabled}
          />
        )}
        keyExtractor={item => item.id}
        columnWrapperStyle={{ justifyContent: 'space-between' }}
      />
    </View>
  );
};

export default AnimatedTabBoxes;
