import { Platform, Pressable, StyleSheet, View } from 'react-native';
import React, { useEffect } from 'react';
import { TouchableRipple } from 'react-native-paper';
import { BackIconSvg, LogoSvg } from '../../assets/svg/HeaderSvgs';
import { useRoute } from '@react-navigation/native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

const leftSpacing = -52.5;
const AnimatedBackButton = ({ navigation }) => {
  const route = useRoute();
  const isWeb = Platform.OS === 'web';
  const fromUserDeactivate = isWeb
    ? new URLSearchParams(window.location.search).get('user') === 'deactivate'
    : false;

  const marginProgress = useSharedValue(
    navigation.getState().index > 1 ? -10 : leftSpacing,
  );

  const animatedMarginStyle = useAnimatedStyle(() => {
    return {
      marginLeft: marginProgress.value,
    };
  });

  const resetQueryParams = () => {
    if (isWeb) {
      window.history.replaceState(null, '', '/');
    }
  }
  useEffect(() => {
    if (route.name === 'SuccessScreen') {
      marginProgress.value = withTiming(leftSpacing);
    } else if (navigation.getState().index < 2) {
      if (tabData.includes(route.name)) {
        marginProgress.value = withTiming(leftSpacing);
      } else {
        marginProgress.value = withTiming(-10);
      }
    }
  }, [route.name]);
  const backHandler = () => {
    if (fromUserDeactivate) {
      resetQueryParams();
    }
    async function backAnimation() {
      if (navigation.getState().index === 1) {
        marginProgress.value = withTiming(leftSpacing);
      }
      return true;
    }
    backAnimation().then(res => {
      if (res) {
        if (
          navigation.getState().index === 0 &&
          navigation.getState().routes[0].name === 'TokenPackages'
        ) {
          navigation.replace('Wallet');
        } else if (
          navigation.getState().index === 0 &&
          navigation.getState().routes[0].name === 'Profile'
        ) {
          navigation.replace('MoreTab');
        } else {
          navigation.goBack();
        }
      }
    });
  };

  const onLogoPress = () => {
    if (navigation.getState().routes[0].name === 'HomeScreen') {
      if (navigation.getState().index > 0) {
        navigation.reset({
          index: 0,
          routes: [{ name: 'HomeTab' }],
        });
      }
    } else {
      navigation.reset({
        index: 0,
        routes: [{ name: 'HomeTab' }],
      });
    }
  };

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.backButtonContainer, animatedMarginStyle]}>
        <TouchableRipple
          style={styles.ripple}
          onPress={backHandler}
          rippleColor={'rgba(0,0,0,0.25)'}>
          <BackIconSvg />
        </TouchableRipple>
      </Animated.View>
      <Pressable onPress={onLogoPress}>
        <LogoSvg />
      </Pressable>
    </View>
  );
};

export default AnimatedBackButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logoImg: {
    height: 25,
    width: 75,
  },
  backButtonContainer: {
    width: 45,
    height: 45,
    borderRadius: 50,
    overflow: 'hidden',
    marginRight: 10,
  },
  ripple: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const tabData = ['HomeScreen', 'Friends', 'Map', 'Wallet', 'MoreTab'];
