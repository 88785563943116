import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Text,
  View,
  FlatList,
  Platform,
  Pressable,
  ScrollView,
  ActivityIndicator,
  useWindowDimensions,
} from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {
  RESPONSIVE,
  SortingOptions,
  RESPONSIVE_WIDTH,
  getAppConfiguration,
} from '../../../appconstants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useWeb3AuthContext } from '../../../hooks/web3authContext';
import { ListRetrivalStatus } from '../../../enums/marketPlace.enum';
import { LogToLoot8Console } from '../../../helpers/Loot8ConsoleLogger';
import {
  clearExpassess,
  setLoadingExpass,
  setOlderListLoading,
  loadPublicMarketPlaceData,
} from '../../../slices/MarketPlaceSlice';
import { defaultTheme } from '../../../themes/loot8';
import AppLoaderComponent from '../../Loader';
import ExpassesItems from './ExpassItem';

const isWeb = Platform.OS === 'web';
const DEFAULT_PAGE_SIZE = isWeb ? 16 : 8;

let appConfig;
const numOfColumns = 2;

const MPExpasses = ({
  navigation,
  searchExpassText,
  setSearchExpassText,
  selectedSortingOption,
}) => {
  const { networkId, staticProvider, address, wallet } = useWeb3AuthContext();

  const listedExpassesList = useAppSelector(
    state => state.MarketPlace.listedExpassesSearchList,
  );
  const loadingExpass = useAppSelector(
    state => state.MarketPlace.loadingExpass,
  );
  const olderListLoading = useAppSelector(
    state => state.MarketPlace.olderListLoading,
  );
  const initialListLoading = useAppSelector(
    state => state.MarketPlace.initialListLoading,
  );

  const { width } = useWindowDimensions();
  const windowWidth = width > 600 ? 600 : width;
  const isResponsive =
    RESPONSIVE == 'true' && width > parseInt(RESPONSIVE_WIDTH);
  const tileWidth =
    width > 600
      ? isResponsive
        ? 300
        : windowWidth / numOfColumns - 30
      : windowWidth / numOfColumns - 30;
  const dispatch = useAppDispatch();

  // const [searchExpassText, setSearchExpassText] = useState('');
  const [skip, setSkip] = useState(1);
  const [take, setTake] = useState(DEFAULT_PAGE_SIZE);
  const [listRetrivalStatus, setListRetrivalStatus] = useState(
    ListRetrivalStatus.INITIAL_LIST,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hovered, setHovered] = useState(false);
  const ScrollViewRef = useRef<ScrollView>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(true);

  // Inside your component
  const prevSortedExpassesListRef = useRef([]);

  // Update the sortedExpassesList useMemo hook to include the logic for hiding the "Load More" button
  const sortedExpassesList = useMemo(() => {
    const availableExpasses = listedExpassesList?.filter(x => {
      if (searchExpassText) {
        if (searchExpassText.length === 1) {
          return (
            x?.collectionData?.name
              ?.toLowerCase()
              ?.startsWith(searchExpassText.toLowerCase().trim()) === true
          );
        } else {
          return (
            x?.collectionData?.name
              ?.toLowerCase()
              .indexOf(searchExpassText.toLowerCase().trim()) > -1
          );
        }
      }
      return x;
    });

    // Sorting logic with clear cases for different sort options.
    const sortedActivePassports = availableExpasses?.sort((a, b) => {
      const textA = a?.collectionData?.name?.toUpperCase();
      const textB = b?.collectionData?.name?.toUpperCase();

      switch (selectedSortingOption) {
        case SortingOptions.ALPHABETICAL_ASC:
          return textA?.localeCompare(textB);
        case SortingOptions.ALPHABETICAL_DESC:
          return textB?.localeCompare(textA);
        case SortingOptions.COST_ASC:
          return a?.price - b?.price;
        case SortingOptions.COST_DESC:
          return b?.price - a?.price;
        default: // Default sort based on timestamps
          return;
      }
    });

    // Compare previous and current sorted lists
    if (
      prevSortedExpassesListRef?.current?.length ===
      sortedActivePassports.length
    ) {
      setShowLoadMore(false);
    } else {
      setShowLoadMore(true);
    }
    prevSortedExpassesListRef.current = sortedActivePassports;

    return sortedActivePassports;
  }, [
    listedExpassesList,
    searchExpassText,
    selectedSortingOption,
    olderListLoading,
  ]);

  const pullExpass = async () => {
    if (
      appConfig &&
      appConfig.indexerService &&
      appConfig.indexerService.marketplaceListing
    ) {
      setSkip(1);
    } else {
      setSkip(0);
    }
    setTake(DEFAULT_PAGE_SIZE);
    await dispatch(setLoadingExpass(true));
    await dispatch(clearExpassess());
    await dispatch(
      loadPublicMarketPlaceData({
        networkID: networkId,
        provider: staticProvider,
        address: address,
        wallet: wallet,
        orderBy: 'created',
        order: 'DESC',
        skip: 0,
        take: take,
        isClear: true,
        isCollectibleData: false,
        searchByName: searchExpassText,
      }),
    );
    setIsLoading(false);
  };

  // update Expass list based on filter selection.
  useEffect(() => {
    setIsLoading(true);
    pullExpass();
  }, [selectedSortingOption, navigation.isFocused()]);

  const pullOlderList = async () => {
    setListRetrivalStatus(ListRetrivalStatus.OLDER_LIST);
    await dispatch(setOlderListLoading(true));
    let nextPage = 0;
    let orderBy = 'created';
    if (
      appConfig &&
      appConfig.indexerService &&
      appConfig.indexerService.marketplaceListing
    ) {
      nextPage = skip + 1;
      orderBy = 'name';
    } else {
      nextPage = skip + take;
    }
    setSkip(nextPage);
    await dispatch(
      loadPublicMarketPlaceData({
        networkID: networkId,
        provider: staticProvider,
        address: address,
        wallet: wallet,
        orderBy: 'name',
        order: 'DESC',
        skip: nextPage,
        take: take,
        isClear: false,
        isOlderMessages: true,
        isCollectibleData: false,
        searchByName: searchExpassText?.toLowerCase().trim(),
      }),
    );
  };

  const onPullOlderMarketPlaceList = async ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    // pull older list.
    if (layoutMeasurement.height + contentOffset.y >= contentSize.height - 20) {
      await pullOlderList();
    }
  };

  useEffect(() => {
    (async () => {
      try {
        appConfig = await getAppConfiguration();
      } catch (err) {
        LogToLoot8Console(
          'ExPass | pullOlderList: Error while reading app config',
        );
      }
    })();

    return () => {
      // Clear data
      dispatch(setLoadingExpass(true));
      dispatch(clearExpassess());
    };
  }, []);

  const scrollLeft = () => {
    if (ScrollViewRef.current && Platform.OS === 'web') {
      ScrollViewRef.current.scrollTo({
        x: Math.max(scrollPosition - tileWidth, 0),
        animated: true,
      });
    }
  };

  const scrollRight = () => {
    if (ScrollViewRef.current && Platform.OS === 'web') {
      pullOlderList();
      ScrollViewRef.current.scrollTo({
        x: scrollPosition + tileWidth,
        animated: true,
      });
    }
  };

  return (
    <>
      <View style={{ marginTop: 5, flex: 1 }}>
        <FlatList
          id={'ex-passes'}
          data={sortedExpassesList}
          keyExtractor={item => item?.id?.toString()}
          renderItem={({ item }) => (
            <ExpassesItems item={item} navigation={navigation} />
          )}
          // showsVerticalScrollIndicator={isResponsive}
          key={'ex-passes' + isResponsive}
          numColumns={isResponsive ? 2 : 1}
          contentContainerStyle={{ flexGrow: 1, paddingBottom: 150 }}
          scrollEnabled
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          ItemSeparatorComponent={() => <View style={{ marginVertical: 5 }} />}
          scrollToOverflowEnabled
          overScrollMode={'always'}
          style={{
            flex: 1,
            paddingTop: 10,
            borderRadius: defaultTheme.CONTENT_RADIUS,
          }}
          columnWrapperStyle={
            isResponsive && { justifyContent: 'space-between' }
          }
          horizontal={false}
          ListFooterComponent={() => (
            <>
              {!loadingExpass &&
              !olderListLoading &&
              sortedExpassesList?.length == 0 ? (
                <View style={{ marginTop: 20 }}>
                  <Text
                    style={{
                      alignSelf: 'center',
                      textAlign: 'center',
                      paddingVertical: 10,
                      fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
                      fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                      color: '#FFFFFF',
                    }}>
                    {'No ExPasses available.'}
                  </Text>
                </View>
              ) : (
                (loadingExpass ||
                  (sortedExpassesList?.length == 0 &&
                    searchExpassText == '')) && (
                  <View style={{ marginTop: 20 }}>
                    <AppLoaderComponent
                      deemBg={false}
                      loaderText={{
                        text: 'Loading Digital Experiences for Sale',
                      }}
                      size={'small'}
                      extraStyle={{
                        position: 'relative',
                      }}
                    />
                  </View>
                )
              )}
              {olderListLoading &&
                !loadingExpass &&
                sortedExpassesList?.length > 0 &&
                searchExpassText == '' &&
                listRetrivalStatus == ListRetrivalStatus.OLDER_LIST && (
                  <View
                    style={{
                      marginTop: 20,
                      ...(isResponsive
                        ? {
                            width: tileWidth,
                            alignItems: 'center',
                            justifyContent: 'center',
                            height:
                              RESPONSIVE == 'true' &&
                              width > parseInt(RESPONSIVE_WIDTH)
                                ? 250
                                : 150,
                          }
                        : {}),
                    }}>
                    <ActivityIndicator
                      size="small"
                      color={defaultTheme.PRIMARY_TEXT_COLOR}
                    />
                  </View>
                )}
            </>
          )}
          onScroll={({ nativeEvent }) => {
            if (listedExpassesList?.length > 0 && initialListLoading) {
              onPullOlderMarketPlaceList(nativeEvent);
            }
          }}
        />
      </View>

      {Platform.OS === 'web' && hovered && (
        <>
          <View style={{ position: 'absolute', top: '50%', left: 0 }}>
            <Pressable
              onHoverIn={() => setHovered(true)}
              style={{ backgroundColor: '#FFFFFFE0', borderRadius: 20 }}
              onPress={scrollLeft}>
              <MaterialCommunityIcons
                name={'chevron-left'}
                size={40}
                color="#000000"
              />
            </Pressable>
          </View>
          <View style={{ position: 'absolute', top: '50%', right: 0 }}>
            <Pressable
              onHoverIn={() => setHovered(true)}
              style={{ backgroundColor: '#FFFFFFE0', borderRadius: 20 }}
              onPress={scrollRight}>
              <MaterialCommunityIcons
                name={'chevron-right'}
                size={40}
                color="#000000"
              />
            </Pressable>
          </View>
        </>
      )}
    </>
  );
};

export default MPExpasses;
