import React, { useEffect } from 'react';
import { Text, Platform, StyleSheet, View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { useAppDispatch } from '../hooks';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { logoutUserActions } from '../slices/AppSlice';
import { deleteAllCachedImages } from '../components/CachedImage';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { defaultTheme } from '../themes/loot8';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import HOCContainer from '../components/HOCContainer';

function LogoutScren() {
  const { disconnect, clearPassPort, wallet } = useWeb3AuthContext();
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  useEffect(() => {
    setTimeout(() => {
      dispatch(
        logoutUserActions({ networkID: null, provider: null, wallet }),
      ).then(async () => {
        try {
          if (Platform.OS == 'web') {
            // location.reload();
          } else if (Platform.OS == 'android' || Platform.OS == 'ios') {
            /* Currently with Expo SDK 47, this line is crashing in original app.
                     once we upgrade Expo SDK, we would have to check again as per this thread.
                     https://github.com/expo/expo/issues/21347 */
            //    await Updates.reloadAsync();
          }
        } catch (error) {
          LogToLoot8Console('Error while logging out');
        }
      });

      clearPassPort();
      disconnect();
      deleteAllCachedImages();

      // todo: uncomment this code when background service is need.
      // const unregisterdTask = async () => {
      //     await TaskManager.unregisterAllTasksAsync();
      // };
      // unregisterdTask();
    }, 500);
  }, [isFocused]);

  return (
    <View style={styles.container}>
      <Icon name="logout" size={24} color="#fff" />
      <Text style={styles.text}>{'Logging Out...'}</Text>
    </View>
  );
}

export default HOCContainer({ OriginalComponent: LogoutScren, title: '' });

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontWeight: 'bold',
    marginLeft: 5,
  },
});
