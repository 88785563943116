import React from 'react';
import GradientText from '../GradientText';
import { Pressable } from 'react-native';

interface Props {
  size?: number;
  text?: string;
  onPress?: any;
}

const SeeAllTextPressable: React.FC<Props> = ({
  size = 12,
  text = 'See All',
  onPress,
}) => {
  return (
    <Pressable>
      <GradientText size={12} onPress={onPress}>
        {text}
      </GradientText>
    </Pressable>
  );
};

export default SeeAllTextPressable;
