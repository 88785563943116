export enum FriendsTabs {
  FRIEND = 'Friends',
  FRIENDSMESSAGES = 'friendsmessages',
  DIRECTMESSAGE = 'directmessage',
  REQUESTS = 'Requests',
}
export enum FriendsModalTypes {
  SHOWOPTIONS = 'showOptions',
  USERDETAIL = 'userDetail',
  SENDREQUESTMSG = 'sendRequestMsg',
  CANCELREQUEST = 'cancelRequest',
  ALERT3HRS = 'alert3hrs',
  REQUESTALREADYSENT = 'requestAlreadySent',
  UNFOLLOWMODAL = 'unFollowModal',
  REQUESTSUCESSFULLYSENDED = 'requestSuccessfullySended',
}
