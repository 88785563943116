import { FlatList, Text, View, Image, Platform } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { styles } from './styles';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { defaultTheme } from '../../themes/loot8';
import AsyncStorage from '@react-native-async-storage/async-storage';
import GradientButton from '../GradientButton';
import useActiveDimensions from '../../hooks/useActiveDimensions';

const EstOnboardingComponent = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [hideModal, setHideModal] = useState(false);
  const ref = useRef();
  const activeWidth = useActiveDimensions().width;
  const activeHeight = useActiveDimensions().height;
  const progressIndicator = useSharedValue(8);
  const progressIndicator1 = useSharedValue(8);
  const progressIndicator2 = useSharedValue(8);
  const leftLoading = useSharedValue(-activeWidth);

  const reanimatedIndicatorStyle = useAnimatedStyle(() => {
    return {
      width: progressIndicator.value,
      height: progressIndicator.value,
    };
  }, []);
  const reanimatedIndicator1Style = useAnimatedStyle(() => {
    return {
      width: progressIndicator1.value,
      height: progressIndicator1.value,
    };
  }, []);

  const reanimatedIndicator2Style = useAnimatedStyle(() => {
    return {
      width: progressIndicator2.value,
      height: progressIndicator2.value,
    };
  }, []);

  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      left: leftLoading.value,
    };
  }, []);

  useEffect(() => {
    if (currentSlideIndex === 0) {
      progressIndicator.value = withSpring(12);
    } else {
      progressIndicator.value = withSpring(8);
    }
    if (currentSlideIndex === 1) {
      progressIndicator1.value = withSpring(12);
    } else {
      progressIndicator1.value = withSpring(8);
    }
    if (currentSlideIndex === 2) {
      progressIndicator2.value = withSpring(12);
    } else {
      progressIndicator2.value = withSpring(8);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlideIndex]);

  const updateCurrentSlideIndex = e => {
    const contentOffset =
      activeWidth > 450
        ? e.nativeEvent.contentOffset.y
        : e.nativeEvent.contentOffset.x;
    const currentIndex =
      activeWidth > 450
        ? Math.round(contentOffset / activeHeight)
        : Math.round(contentOffset / activeWidth);
    setCurrentSlideIndex(currentIndex);
  };

  const goToNextSlide = () => {
    const nextSlideIndex = currentSlideIndex + 1;
    if (currentSlideIndex === 2) {
      leftLoading.value = withTiming(-activeWidth);
      AsyncStorage.setItem('EstOnBoardCompleted', 'done');
      return;
    }
    if (nextSlideIndex != slides.length) {
      const offset =
        activeWidth > 450
          ? nextSlideIndex * activeHeight
          : nextSlideIndex * activeWidth;
      ref?.current.scrollToOffset({ offset });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkOnboardStatus();
    }, 1000);
  }, []);
  const checkOnboardStatus = async () => {
    const check = await AsyncStorage.getItem('EstOnBoardCompleted');
    if (check) {
      setHideModal(true);
      return;
    } else {
      leftLoading.value = withTiming(0);
    }
  };

  const slides = [
    {
      id: 1,
      title: 'Create and Share Exclusive Content',
      subtitle:
        'Upload your latest videos, write captivating blogs, and share your daily experiences',
      image: require('../../assets/estOnboard1.png'),
    },
    {
      id: 2,
      title: 'Host Events and Sell Your NFTs',
      subtitle:
        'Create exciting events, sell tickets, and interact with your fans live. Showcase and sell your exclusive NFTs in our integrated marketplace',
      image: require('../../assets/estOnboard2.png'),
    },
    {
      id: 3,
      title: `Turn Your Passion into Profit`,
      subtitle: `Monetize your content through subscriptions, exclusive access, and sponsored collaborations`,
      image: require('../../assets/estOnboard3.png'),
    },
  ];
  if (hideModal) {
    return <></>;
  }
  return (
    <Animated.View
      style={[styles.container, { width: activeWidth }, reanimatedStyle]}>
      <FlatList
        ref={ref}
        onMomentumScrollEnd={updateCurrentSlideIndex}
        onScrollEndDrag={updateCurrentSlideIndex}
        onScroll={updateCurrentSlideIndex}
        disableIntervalMomentum={true}
        data={slides}
        horizontal={activeWidth > 450 ? false : true}
        snapToAlignment="start"
        decelerationRate={Platform.OS === 'web' ? 0 : 'fast'}
        snapToInterval={activeWidth}
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        bounces={false}
        renderItem={({ item }) => <Slide item={item} />}
        keyExtractor={item => item.id.toString()}
      />
      <Indicators
        slides={slides}
        currentSlideIndex={currentSlideIndex}
        reanimatedIndicatorStyle={reanimatedIndicatorStyle}
        reanimatedIndicator1Style={reanimatedIndicator1Style}
        reanimatedIndicator2Style={reanimatedIndicator2Style}
        onPress={goToNextSlide}
      />
    </Animated.View>
  );
};

export default EstOnboardingComponent;

const Slide = ({ item }) => {
  const activeWidth = useActiveDimensions().width;
  const activeHeight = useActiveDimensions().height;

  return (
    <View
      style={[
        styles.slidesContainer,
        {
          height: activeHeight,
          width: activeWidth,
          flexDirection: activeWidth > 450 ? 'row' : 'column',
        },
      ]}>
      <View>
        <Text
          style={[
            styles.title,
            {
              width: activeWidth > 450 ? '60%' : '90%',
              marginLeft:
                activeWidth > 450
                  ? activeWidth / 20
                  : defaultTheme.HORIZONTAL_PADDING,
              fontSize:
                activeWidth > 450 ? activeWidth / 25 : activeWidth / 6.5,
              lineHeight:
                activeWidth > 450 ? activeWidth / 22.5 : activeWidth / 6.5,
              marginTop:
                activeWidth > 450 ? activeHeight / 3.75 : activeHeight / 9,
            },
          ]}>
          {item.title}
        </Text>
        <Text
          style={[
            styles.subTitle,
            {
              width: activeWidth > 450 ? '75%' : '90%',

              marginLeft:
                activeWidth > 450
                  ? activeWidth / 20
                  : defaultTheme.HORIZONTAL_PADDING,
              lineHeight:
                activeWidth > 450 ? activeWidth / 60 : activeWidth / 15,
              fontSize:
                activeWidth > 450 ? activeWidth / 100 : activeWidth / 22.5,
            },
          ]}>
          {item.subtitle}
        </Text>
      </View>
      <Image
        source={item.image}
        resizeMode={'contain'}
        style={[
          styles.slidesImage,
          {
            alignSelf: activeWidth > 450 ? 'flex-end' : 'center',
            height:
              activeWidth > 450
                ? activeHeight / 1.15
                : item.id === 1
                ? activeHeight / 2.75
                : activeHeight / 2.5,
            right:
              activeWidth > 450
                ? -activeWidth / 3.25
                : item.id === 1
                ? -60
                : -50,
            bottom: activeWidth > 450 ? 50 : 100,

            width: activeWidth,
          },
        ]}
      />
    </View>
  );
};

const Indicators = ({
  currentSlideIndex,
  reanimatedIndicatorStyle,
  reanimatedIndicator1Style,
  reanimatedIndicator2Style,
  slides,
  onPress,
}) => {
  const activeWidth = useActiveDimensions().width;
  const activeHeight = useActiveDimensions().height;

  return (
    <>
      <View
        style={[
          styles.btnContainer,
          activeWidth > 450 && {
            width: '25%',
            marginLeft: activeWidth / 20,
            bottom: activeHeight / 2.5,
          },
        ]}>
        <GradientButton
          buttonLabel={currentSlideIndex === 2 ? 'Get Started' : 'Next'}
          onPress={onPress}
        />
      </View>
      <View
        style={[
          styles.indicatorContainer,
          activeWidth > 450 && {
            width: '25%',
            marginLeft: activeWidth / 20,
            bottom: activeHeight / 2.75,
          },
        ]}>
        {/* Indicator container */}
        <View style={styles.row}>
          {/* Render indicator */}
          {slides.map((_, index) => {
            return (
              <Animated.View
                key={index}
                style={[
                  styles.indicator,
                  index === 0 && reanimatedIndicatorStyle,
                  index === 1 && reanimatedIndicator1Style,
                  index === 2 && reanimatedIndicator2Style,
                  currentSlideIndex == index && {
                    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
                  },
                ]}
              />
            );
          })}
        </View>
      </View>
    </>
  );
};
