import {
  ImageBackground,
  StatusBar,
  StyleSheet,
  Image,
} from 'react-native';
import React from 'react';
import LottieView from 'lottie-react-native';
import Loader from '../../assets/loader.json';
import background from '../../assets/loading-bg.png';
import backgroundXL from '../../assets/loading-bgXL.png';
import logoImg from '../../assets/images/loot8Logo.png';
import { defaultTheme } from '../../themes/loot8';
import useIsResponsive from '../../hooks/useIsResponsive';

export default function AnimatedLoading() {
  const isResponsive = useIsResponsive();

  return (
    <>
      <StatusBar
        barStyle="light-content"
        backgroundColor="transparent"
        translucent
      />

      <ImageBackground
        source={isResponsive ? backgroundXL : background}
        resizeMode="cover"
        style={styles.container}>
        <Image
          resizeMode="contain"
          source={logoImg}
          style={isResponsive ? styles.logoImgWeb : styles.logoImg}
        />
        <LottieView
          source={Loader}
          autoPlay
          loop
          style={{ width: 150, height: 150 }}
        />
      </ImageBackground>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImg: {
    height: 40,
    width: 125,
  },
  logoImgWeb: {
    height: 60,
    width: 150,
  },
});
