import React from 'react';
import { View, Text, Pressable, Image } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import {
  getTruncatedName,
  formatPriceUpToTrillion,
} from '../../helpers/Gadgets';
import styles from '../../styles';
import CachedImage from '../CachedImage';
import { useAppSelector } from '../../hooks';
import { defaultTheme } from '../../themes/loot8';
import { ScreenName } from '../../enums/screen.enum';

import token_icon from '../../assets/token_icon.png';
import notFoundIcon from '../../assets/notFoundIcon.png';

const FeaturedOfferItem = ({
  props,
  navigation,
  updateParent,
  navigateToCollectibleDetails,
}) => {
  return (
    <View style={styles.offersItemContainer}>
      {
        <Pressable
          style={styles.offersTileContainer}
          onPress={() =>
            props.tokenId > 0
              ? navigateToCollectibleDetails(props)
              : navigation.navigate('FeaturedOfferDetail', {
                  offer: {
                    name: props.name,
                    price: props.price,
                    type: props.type,
                    details: props.details,
                    image: props.image,
                    offerAddress: props.address,
                    isCoupon: props.isCoupon,
                    tokenId: props.tokenId,
                    navigationFrom: ScreenName.OFFERS_TAB,
                    maxPurchase: props.maxPurchase,
                    maxBalance: props.maxBalance,
                    imageSize: props.imageSize,
                    area: props.area,
                  },
                  updateParent,
                })
          }>
          <View style={styles.offersContainer}>
            <View style={styles.offersCaptionContainer}>
              <Text style={styles.offersCaption}>
                {getTruncatedName(props.name, 45)}
              </Text>
            </View>

            <View style={styles.offersDataContainer}>
              <View style={styles.offersImageContainer}>
                <CachedImage
                  isBackground
                  source={{ uri: props.image }}
                  style={styles.Offersimage}
                  contentFit="contain"
                  imageSize={props?.imageSize}
                />
              </View>

              <LinearGradient
                colors={[
                  defaultTheme.GRADIENT_COLOR2,
                  defaultTheme.GRADIENT_COLOR1,
                ]}
                start={{ x: 0, y: 0.3 }}
                end={{ x: 0, y: 1.3 }}
                style={styles.offersValueContainer}>
                <View style={styles.offersValue}>
                  <View style={styles.offersPriceContainer}>
                    <Text
                      style={[
                        styles.offersPrice,
                        { fontSize: defaultTheme.FONT_SIZE_LARGE },
                      ]}>
                      {Number(props.price) == 0
                        ? 'Free'
                        : formatPriceUpToTrillion(props?.price)}{' '}
                    </Text>
                    <Image
                      source={token_icon}
                      style={{ height: 30, width: 30 }}
                    />
                  </View>
                </View>
              </LinearGradient>
            </View>
            <View style={styles.offersOrderContainer}>
              {props.isCoupon ? (
                <View
                  style={[styles.offersOrderCaptionContainer, { height: 45 }]}>
                  <Text style={styles.offersOrderCaption}>REDEEM</Text>
                </View>
              ) : (
                <View
                  style={{ ...styles.offersOrderCaptionContainer, height: 45 }}>
                  <Text style={styles.offersOrderCaption}>View Offer</Text>
                </View>
              )}
            </View>
          </View>
        </Pressable>
      }
    </View>
  );
};

const FeaturedOffer = ({ navigation, SelectedPassport, updateParent }) => {
  const allOffers = useAppSelector(state => state.Offers);
  const selectedOffers = allOffers?.FeaturedEntityOffer;
  const catalogCollectible = useAppSelector(
    state => state.Catalogs.CatalogCollectiableDetailsList,
  );

  const navigateToCollectibleDetails = async offer => {
    const collectible = catalogCollectible.find(
      item => item.address.toLowerCase() === offer.address.toLowerCase(),
    );
    navigation.navigate(ScreenName.CATALOG_DETAILS, {
      catalog: collectible,
      availableCatalogs: [],
      type: 'Collectibles',
      from: ScreenName.OFFERS_TAB,
      passport: SelectedPassport,
      updateParent: updateParent,
    });
  };

  return (
    <View
      style={[
        styles.offerContainer,
        styles.maxWidthAdjust,
        { paddingLeft: 5, paddingRight: 5 },
      ]}>
      <View style={styles.offersLayout}>
        {selectedOffers && selectedOffers?.length > 0 ? (
          selectedOffers.map((item, index) => (
            <FeaturedOfferItem
              key={index}
              props={item}
              navigation={navigation}
              updateParent={updateParent}
              navigateToCollectibleDetails={navigateToCollectibleDetails}
            />
          ))
        ) : (
          <View style={styles.notFoundIconContainer}>
            <Image source={notFoundIcon} style={{ height: 30, width: 30 }} />
            <Text style={styles.emptyMessageText}>
              No featured offers found!
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default FeaturedOffer;
