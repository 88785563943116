import React, { useMemo } from 'react';
import { FlatList, StyleSheet, View, Text } from 'react-native';

import UserCard from '../UserCard';
import SectionHeading from '../SectionHeading';
import ListSortButton from '../ListSortButton';
import { defaultTheme } from '../../themes/loot8';
import useIsResponsive from '../../hooks/useIsResponsive';
import { useNavigation } from '@react-navigation/native';
import { useAppSelector } from '../../hooks';
import { PassportType } from '../../enums/passportCategory.enum';
import useStateRef from 'react-usestateref';
import { SortingOptions } from '../../appconstants';
import { ICollectibleDetail } from '../../interfaces/ICollectibleDetail.interface';

interface FollowingTabProps {
  closeSortingOptionsMenu: () => void;
  selectedSortingOption: number;
}

const FollowingTabSection: React.FC<FollowingTabProps> = ({
  selectedSortingOption,
}) => {
  const [searchText, setSearchCreatorsText, searchCreatorsTextRef] =
    useStateRef('');

  // app selectors
  const availableCreatorsDetails = useAppSelector(
    state => state.Creators.AllCreatorsDetailsSearchList,
    (oldValue, newValue) => {
      return JSON.stringify(oldValue) === JSON.stringify(newValue)
        ? true
        : false;
    },
  );
  const loadingCreators = useAppSelector(state => state.Passports.loading);
  const initialPassportLoaded = useAppSelector(
    state => state.Passports.initialPassportLoaded,
  );

  const followedCreators = useMemo(() => {
    const freeCreators = availableCreatorsDetails?.filter(
      passport => passport.isPremium === false,
    );

    const activeCreatorsDetails = freeCreators?.filter((item, index) => {
      return (
        item.isActive &&
        (item.passportType === PassportType.SUBSCRIPTION ||
          item.collectibleIds?.length > 0) &&
        (searchCreatorsTextRef?.current?.length === 1
          ? item.name
              .toLowerCase()
              .startsWith(searchCreatorsTextRef?.current?.toLowerCase()) ===
            true
          : item.name
              .toLowerCase()
              .indexOf(searchCreatorsTextRef?.current?.toLowerCase()) > -1)
      );
    });

    const localFollowedCreators = activeCreatorsDetails
      ?.filter((item, index) => {
        return (
          item.passportType === PassportType.REGULAR ||
          item.passportType === PassportType.SUBSCRIBED
        );
      })
      .sort((a, b) => {
        if (selectedSortingOption === SortingOptions.ALPHABETICAL_ASC) {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        } else if (selectedSortingOption === SortingOptions.ALPHABETICAL_DESC) {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
          return textA < textB ? 1 : textA > textB ? -1 : 0;
        } else if (selectedSortingOption === SortingOptions.COST_ASC) {
          return a.buyPrice - b.buyPrice;
        } else if (selectedSortingOption === SortingOptions.COST_DESC) {
          return b.buyPrice - a.buyPrice;
        } else {
          let textA = a.timestamp;
          let textB = b.timestamp;
          return textA < textB ? 1 : textA > textB ? -1 : 0;
        }
      });

    return localFollowedCreators;
  }, [selectedSortingOption, availableCreatorsDetails]);

  const isResponsive = useIsResponsive();
  const navigation = useNavigation();
  const navigateToFollowingDetail = (item: ICollectibleDetail) => {
    navigation.navigate('FollowingDetails', {
      passportAddress: item.address,
      chainId: item.chainId,
    });
  };

  return (
    <>
      {!loadingCreators && followedCreators && followedCreators.length > 0 ? (
        <FlatList
          ListHeaderComponent={() => (
            <>
              <SectionHeading
                title={`Followings (${
                  followedCreators.length > 0 ? followedCreators.length : 0
                })`}
                subtitle={'These are the users who you follow'}
                // renderRight={<ListSortButton onPress={() => {}} />}
              />
              <View style={{ marginVertical: 10 }} />
            </>
          )}
          style={{
            flex: 1,
            paddingTop: 10,
            borderRadius: defaultTheme.CONTENT_RADIUS,
          }}
          contentContainerStyle={{ flexGrow: 1, paddingBottom: 150 }}
          data={followedCreators}
          renderItem={({ item }) => (
            <UserCard
              item={item}
              onPress={navigateToFollowingDetail}
              navigation={navigation}
            />
          )}
          keyExtractor={item => item?.address}
          ItemSeparatorComponent={() => <View style={{ marginVertical: 5 }} />}
          numColumns={isResponsive ? 2 : 1}
          key={isResponsive}
          columnWrapperStyle={
            isResponsive && { justifyContent: 'space-between' }
          }
          showsVerticalScrollIndicator={false}
        />
      ) : (
        <View>
          <Text
            style={{
              alignSelf: 'center',
              textAlign: 'center',
              paddingVertical: 10,
              fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
              fontSize: defaultTheme.FONT_SIZE_XXSMALL,
              color: defaultTheme.PRIMARY_TEXT_COLOR,
            }}>
            {searchText === ''
              ? !initialPassportLoaded
                ? 'Collecting Your Digital Assets'
                : 'No Users are followed yet.'
              : 'No search result found.'}
          </Text>
        </View>
      )}
      {!loadingCreators &&
      followedCreators?.length === 0 &&
      searchText === '' ? (
        <View style={styles.alertContainer}>
          <Text style={styles.alertContainerText}>
            <Text>
              We are looking to get the users for you! Meanwhile, please check
              out your{' '}
            </Text>
            <Text
              style={{
                textDecorationLine: 'underline',
                color: defaultTheme.GRADIENT_COLOR1,
              }}
              onPress={() => {
                navigation.navigate('FriendsTab');
              }}>
              friends.
            </Text>
          </Text>
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

export default FollowingTabSection;

const styles = StyleSheet.create({
  alertContainer: {
    backgroundColor: 'rgba(255,255,255,0.15)',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertContainerText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
});
