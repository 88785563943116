import React from 'react';
import { StyleSheet } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { TouchableRipple } from 'react-native-paper';
import { defaultTheme } from '../../themes/loot8';
import { ThreeDotsSvg } from '../../assets/svg/HomeSvgs';

interface ThreeDotsSettingsButtonProps {
  extraStyles?: object;
  onPress: () => void;
}

const ThreeDotsSettingsButton: React.FC<ThreeDotsSettingsButtonProps> = ({
  extraStyles,
  onPress,
}) => {
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 1 }}
      style={[styles.container, extraStyles]}>
      <TouchableRipple
        style={styles.ripple}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}>
        <ThreeDotsSvg />
      </TouchableRipple>
    </LinearGradient>
  );
};

export default ThreeDotsSettingsButton;

const styles = StyleSheet.create({
  container: {
    width: 24,
    height: 20,
    borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
  },
  ripple: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
