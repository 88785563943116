import { BlurView } from 'expo-blur';
import React, { useEffect, useRef, useState } from 'react';
import { Platform, View } from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
} from 'react-native-actions-sheet';
import { addresses } from '../../appconstants';
import { CatalogTypes } from '../../enums/catalog.enum';
import { ScreenName } from '../../enums/screen.enum';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { getWalletTokenDetails } from '../../slices/helpers';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import { ReShareWebBlurView } from '../WebBlurView';
import { LinearGradient } from 'expo-linear-gradient';
import SubscribeModalContent from '../SubscribeModalContent';
import { CollectionType } from '../../enums/collection.enum';

const SubscribePassportSheet = (props: SheetProps) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const { selectedEXPass, navigation } = props.payload;
  const hideSubscribePassportsModal = props.payload.hideSubscribePassportsModal;
  const setSearchPassportText = props.payload.setSearchPassportText;
  const collectionType = props.payload.collectionType;
  const forMarketPlace = props?.payload?.forMarketPlace;
  const type = props?.payload?.type;
  const { networkId, address, SelectedPassport } = useWeb3AuthContext();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [hasCurrentBalanceLoaded, setHasCurrentBalanceLoaded] = useState(true);

  useEffect(() => {
    (async () => {
      setHasCurrentBalanceLoaded(true);
      const currentLoot8TokenBalance = await getWalletTokenDetails(
        addresses[networkId].Loot8Token,
        address,
        true,
      );
      if (currentLoot8TokenBalance && currentLoot8TokenBalance.walletBalance) {
        setCurrentBalance(currentLoot8TokenBalance.walletBalance);
      }
      setHasCurrentBalanceLoaded(false);
    })();

    return () => {
      setHasCurrentBalanceLoaded(true);
    };
  }, []);

  const navigateToTokenPackages = () => {
    actionSheetRef.current.hide();
    navigation.navigate(ScreenName.HOME_TAB, {
      screen: ScreenName.TOKEN_PACKAGES,
    });
  };

  const isWeb = Platform.OS === 'web';

  let pathConfig = null;

  // if (isWeb) {
  //   const queryParams = new URLSearchParams(window.location.search);

  //   pathConfig = {
  //     path: window.location.pathname,
  //     queryParams: queryParams,
  //     getParam: query => {
  //       return queryParams.has(query) ? queryParams.get(query) : null;
  //     },
  //   };
  // }

  // let type =
  //   pathConfig && pathConfig.getParam('type')
  //     ? pathConfig.getParam('type')
  //     : route?.params?.type;

  const onSubscribeClick = selectedAction => {
    actionSheetRef.current.hide();
    if (forMarketPlace) {
      navigation.navigate(ScreenName.MARKET_PLACE_BUY_COLLECTIBLE, {
        collection: selectedEXPass,
        type:
          type == CatalogTypes.COLLECTIBLES
            ? CollectionType.COLLECTION
            : CollectionType.PASSPORT,
      });
      return;
    }

    if (collectionType && collectionType === CatalogTypes.PREMIUMCHAT) {
      navigation.navigate(ScreenName.PREMIUMCHAT_SUB_UNSUB, {
        selectedEXPass: SelectedPassport,
        currentLoot8Balance: currentBalance,
        type: 'sub',
      });
    } else {
      navigation.navigate(ScreenName.PASSPORT_BUY_SELL_CONFIRMATION, {
        selectedEXPass: selectedEXPass,
        listSellPrice: 0, //sellPrice ? sellPrice : 0,
        passportAction: selectedAction,
        //onPassportSelection: onPassportSelection,
        currentLoot8Balance: currentBalance,
        sellMarketPrice: 0,
        setSearchPassportText: setSearchPassportText,
      });
      hideSubscribePassportsModal();
    }
  };

  return (
    <ActionSheet
      id={props.sheetId}
      ref={actionSheetRef}
      drawUnderStatusBar
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      enableGesturesInScrollView={false}
      onClose={hideSubscribePassportsModal}>
      {Platform.OS === 'web' ? (
        <ReShareWebBlurView>
          <View
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <SubscribeModalContent
                title={
                  forMarketPlace
                    ? `Buy ${
                        type == CatalogTypes.PASSPORT ? 'ExPass' : 'Collectible'
                      }`
                    : collectionType === CatalogTypes.PREMIUMCHAT
                      ? 'Subscribe to Premium Chat'
                      : 'Subscribe to ExPass'
                }
                selectedEXPass={selectedEXPass}
                collectionType={collectionType}
                currentBalance={currentBalance}
                navigateToTokenPackages={navigateToTokenPackages}
                onSubscribeClick={onSubscribeClick}
                hasCurrentBalanceLoaded={hasCurrentBalanceLoaded}
              />
            </LinearGradient>
          </View>
        </ReShareWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={{
            borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <SubscribeModalContent
              title={
                forMarketPlace
                  ? `Buy ${
                      type == CatalogTypes.PASSPORT ? 'ExPass' : 'Collectible'
                    }`
                  : collectionType === CatalogTypes.PREMIUMCHAT
                    ? 'Subscribe to Premium Chat'
                    : 'Subscribe to ExPass'
              }
              selectedEXPass={selectedEXPass}
              collectionType={collectionType}
              currentBalance={currentBalance}
              navigateToTokenPackages={navigateToTokenPackages}
              onSubscribeClick={onSubscribeClick}
              hasCurrentBalanceLoaded={hasCurrentBalanceLoaded}
            />
          </LinearGradient>
        </BlurView>
      )}
    </ActionSheet>
  );
};

export default SubscribePassportSheet;
