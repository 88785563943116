import React from 'react';
import coinImage from '../../assets/images/coin_image.png';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { defaultTheme } from '../../themes/loot8';
import { ScreenName } from '../../enums/screen.enum';

interface TokenPackageCardProps {
  item: any;
  navigation: any;
}

const TokenPackageCard: React.FC<TokenPackageCardProps> = ({
  item,
  navigation,
}) => {
  const navigateToBuyToken = (pack: any) => {
    navigation.navigate(ScreenName.WALLET_TAB, {
      screen: ScreenName.BUY_TOKEN,
      params: {
        navigationFrom: ScreenName.TOKEN_PACKAGES,
        packageIdentifier: pack?.identifier,
      },
      initial: false,
    });
  };
  return (
    <View key={item?.identifier} style={styles.packageBox}>
      <View style={styles.rippleContainer}>
        <TouchableRipple
          onPress={() => navigateToBuyToken(item)}
          style={styles.ripple}
          rippleColor={'rgba(0,0,0,0.25)'}>
          <>
            <Image
              source={coinImage}
              style={styles.image}
              resizeMode="contain"
            />
            <View style={{ marginLeft: 10 }}>
              <Text style={styles.tokenPackageTitle}>
                {Platform.OS === 'web' ? item?.title?.replace(/loot8/gi, 'LOOT8') : item?.product?.title?.replace(/loot8/gi, 'LOOT8')}
              </Text>
              <Text style={styles.tokenPackageSubtitle}>
                {Platform.OS === 'web'
                  ? item?.currencySymbol
                  : item?.product?.currencyCode}
                {Platform.OS === 'web' ? item?.price : item?.product?.price}
              </Text>
            </View>
          </>
        </TouchableRipple>
      </View>
    </View>
  );
};

export default TokenPackageCard;

const styles = StyleSheet.create({
  packageBox: {
    height: 85,
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    marginTop: 15,
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
    elevation: 15,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  rippleContainer: {
    flex: 1,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  image: { height: 70, width: 53 },
  tokenPackageTitle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  tokenPackageSubtitle: {
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
});
