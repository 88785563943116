import React from 'react';
import { Text, View, ViewStyle, TextStyle, DimensionValue } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';

import { styles } from './styles';
import { defaultTheme } from '../../themes/loot8';
import { getCategorySvg } from '../ExploreTabSection';

interface GradientBorderBoxProps {
  numLinesForLabel?: number;
  height?: number;
  width?: DimensionValue;
  item: {
    id: number;
    name: string;
    icon?: React.ReactNode;
  };
  onPress: () => void;
  label?: boolean;
  activeTab?: number;
  index?: number;
  selectable?: boolean;
  catSvg?: boolean;
}

const GradientBorderBox: React.FC<GradientBorderBoxProps> = ({
  numLinesForLabel,
  height,
  width,
  item,
  onPress,
  label,
  activeTab,
  index,
  selectable,
  catSvg = false,
}) => {
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR2, defaultTheme.GRADIENT_COLOR1]}
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={[
        styles.container,
        width ? { width } : ({ flex: 0.225 } as ViewStyle),
      ]}>
      <View style={styles.rippleWrapper}>
        <TouchableRipple
          style={[
            styles.contentContainer,
            height ? { height } : { paddingVertical: 15 },
            selectable
              ? {
                  backgroundColor:
                    activeTab === index
                      ? 'transparent'
                      : defaultTheme.MAIN_BACKGROUND_COLOR,
                }
              : { backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR },
          ]}
          rippleColor={'rgba(0,0,0,0.25)'}
          onPress={onPress}>
          <>
            {item.icon && item.icon}
            {catSvg && getCategorySvg(item.id)}
            {label && (
              <Text
                style={[
                  styles.label,
                  (item.icon || catSvg) && ({ marginTop: 5 } as TextStyle),
                ]}
                numberOfLines={numLinesForLabel || 1}>
                {item.name}
              </Text>
            )}
          </>
        </TouchableRipple>
      </View>
    </LinearGradient>
  );
};

export default GradientBorderBox;
