import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { Apps } from '../enums/apps.enum';
import MainAppNavigator from './MainAppNavigator';
import EstPortalNavigator from './EstPortalNavigator';
import { getAppConfiguration } from '../appconstants';
import AppSelectionNavigator from './AppSelectionNavigator';
import { useWeb3AuthContext } from '../hooks/web3authContext';

let isEnabled = false;
const Stack = createStackNavigator();

function RootNavigator() {
  const { showAppSelection, appSelected } = useWeb3AuthContext();

  React.useEffect(() => {
    (async () => {
      const appConfig = await getAppConfiguration();
      isEnabled = appConfig?.estModule?.isEnabledOnPatron;
    })();
  }, []);

  const renderNavigators = () => {
    if (isEnabled) {
      if (showAppSelection) {
        return (
          <Stack.Screen name="AppSelection" component={AppSelectionNavigator} />
        );
      }
      if (appSelected === Apps.PATRON_APP) {
        return <Stack.Screen name="PatronApp" component={MainAppNavigator} />;
      }
      if (appSelected === Apps.EST_PORTAL) {
        return <Stack.Screen name="EstPortal" component={EstPortalNavigator} />;
      }
    }

    // default case
    return <Stack.Screen name="PatronApp" component={MainAppNavigator} />;
  };

  const initialRouteName = showAppSelection
    ? 'AppSelection'
    : (appSelected as any);

  return (
    <Stack.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{ headerShown: false }}>
      {renderNavigators()}
    </Stack.Navigator>
  );
}

export default RootNavigator;
