import React, { useEffect, useState } from 'react';
import { FlatList, ScrollView, StyleSheet, View } from 'react-native';
import useIsResponsive from '../../../../hooks/useIsResponsive';
import ExPassAssetsCard from '../../../ExPassAssetsCard';
import EmptyList from '../../../EmptyList';
import styles from '../../../../styles';
import { innerStyles } from '../Collectibles/styles';
import AppLoaderComponent from '../../../Loader';
import { defaultTheme } from '../../../../themes/loot8';
import ScreenTitle from '../../../ScreenTitle';
import { useNavigation } from '@react-navigation/native';

const EventList = ({
  events,
  showLimitedEvents = false,
  isLoading,
  onEventClick,
  route,
}) => {
  const navigation = useNavigation();
  const [eventsList, setEventsList] = useState(events);
  const isResponsive = useIsResponsive();
  const isPage = route?.params?.payload?.isPage;
  const onPressEventCard = item => {
    if (!isPage) {
      onEventClick(item);
    } else {
      // @ts-ignore
      navigation.navigate('EventDetails', {
        payload: { selectedEvent: item },
      });
    }
  };
  const heading = route?.params?.payload?.sectionHeading
    ? route?.params?.payload?.sectionHeading + '-' + ' Events'
    : '';

  useEffect(() => {
    if (route?.params?.payload?.events) {
      setEventsList(route?.params?.payload?.events);
    } else {
      setEventsList(events);
    }
  }, [route?.params, events]);
  return (
    <>
      {!route?.params?.payload?.isPage ? (
        <View style={styles.passportBtnContainer}>
          <View style={styles.passportContainer}>
            <EventListRenderingComponent
              isLoading={isLoading}
              eventsList={eventsList}
              showLimitedEvents={showLimitedEvents}
              onPressEventCard={onPressEventCard}
            />
          </View>
        </View>
      ) : (
        <View style={componentStyles.container}>
          {heading && <ScreenTitle title={heading} />}
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={componentStyles.scrollContainer}
            bounces={false}
            contentContainerStyle={[
              componentStyles.scrollContent,
              { paddingBottom: 150 },
            ]}>
            <View
              style={[
                isResponsive
                  ? componentStyles.desktopContainer
                  : componentStyles.contentContainer,
              ]}>
              <EventListRenderingComponent
                isLoading={isLoading}
                eventsList={eventsList}
                showLimitedEvents={showLimitedEvents}
                onPressEventCard={onPressEventCard}
              />
            </View>
          </ScrollView>
        </View>
      )}
    </>
  );
};

const EventListRenderingComponent = ({
  isLoading,
  eventsList,
  showLimitedEvents,
  onPressEventCard,
}) => {
  return (
    <>
      {!isLoading ? (
        <FlatList
          numColumns={2}
          data={showLimitedEvents ? eventsList?.slice(0, 4) : eventsList}
          scrollEnabled={false}
          style={innerStyles.flatList}
          contentContainerStyle={innerStyles.contentContainer}
          renderItem={({ item }) => {
            const { eData, dateString } = item;

            return (
              <ExPassAssetsCard
                item={{ ...eData, subtitle: dateString }}
                onPress={() => onPressEventCard(item)}
              />
            );
          }}
          keyExtractor={item => item?.eData?.name}
          columnWrapperStyle={innerStyles.columnWrapper}
          ItemSeparatorComponent={() => <View style={innerStyles.separator} />}
          ListEmptyComponent={
            <View style={{ marginVertical: 30 }}>
              <EmptyList message="No events available." />
            </View>
          }
        />
      ) : (
        <View style={styles.passportCollectibleItemsLoaderContainer}>
          <AppLoaderComponent
            deemBg={false}
            extraStyle={{ borderRadius: 6 }}
            loaderText={{
              text: 'Loading Events',
            }}
          />
        </View>
      )}
    </>
  );
};
export default EventList;

const componentStyles = StyleSheet.create({
  container: { flex: 1, backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR },
  scrollContainer: {
    flex: 1,
    marginTop: 10,
  },
  scrollContent: {
    flexGrow: 1,
    paddingHorizontal: defaultTheme.HORIZONTAL_PADDING,
  },
  desktopContainer: {
    width: '100%',
    maxHeight: '100%',
    maxWidth: defaultTheme.CONTENT_CONTAINER_MAX_WIDTH,
    alignSelf: 'center',
    flex: 1,
  },
  contentContainer: {
    flex: 1,
  },
});
