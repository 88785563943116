import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  Image,
  Pressable,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { Button } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';

import styles from '../../styles';
import ModalComponent from '../Modal';
import { AppDispatch } from '../../store';
import { defaultTheme } from '../../themes/loot8';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import {
  mintOffers,
  getCollectibleDetails,
  loadPassportOfferList,
} from '../../slices/OfferSlice';
import {
  getPatronOfferActive,
  loadParticularOrderDetail,
} from '../../slices/OrderSlice';
import CachedImage from '../CachedImage';
import { wait } from '../../helpers/ipfs';
import InfoLoader from '../Loader/InfoLoader';
import { ScreenName } from '../../enums/screen.enum';
import {
  loadCatalogOffers,
  loadCatalogCollectiable,
} from '../../slices/CatalogSlice';
import { storeData } from '../../helpers/AppStorage';
import {
  addresses,
  APP_STORAGE_GET_COLLECTIBLEDETAILS,
} from '../../appconstants';
import AppLoaderComponent from '../Loader';
import {
  getTruncatedName,
  formatPriceUpToTrillion,
} from '../../helpers/Gadgets';
import { useAppSelector } from '../../hooks';
import { Dispatcher__factory, Loot8Collection__factory } from '../../typechain';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import {
  isLocationAvailable,
  getWalletTokenDetails,
} from '../../slices/helpers';
import HOCContainer from '../HOCContainer';
import { approveForSubscription } from '../../slices/PassportSlice';

import cash_icon from '../../assets/cash_icon.png';
import token_icon from '../../assets/token_icon.png';

const FeaturedOfferDetail = ({ route, navigation }) => {
  const {
    name,
    price,
    type,
    details,
    image,
    offerAddress,
    isCoupon,
    tokenId,
    navigationFrom,
    maxBalance,
    maxPurchase,
    imageSize,
    area,
    updateParent,
  } = route.params.offer;
  const {
    networkId,
    staticProvider,
    address,
    wallet,
    userInfo,
    SelectedPassport,
  } = useWeb3AuthContext();
  const dispatch = useDispatch<AppDispatch>();
  const catalogCollectible = useAppSelector(
    state => state.Catalogs.CatalogCollectiableDetailsList,
  );

  const [showCashModal, setShowCashModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setISAlert] = useState(false);
  const [failedMessage, showFailedMessage] = useState(false);
  const [showRedeemCashModal, setShowRedeemCashModal] = useState(false);
  const [isRedeemLoading, setRedeemIsLoading] = useState(false);
  const [isRedeemAlert, setISRedeemAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const [isRadius, setIsRadius] = useState(false);
  useEffect(() => {
    getPassportLocationDistance();
  }, []);

  const getPassportLocationDistance = () => {
    if (isLocationAvailable(currenLocation, area)) setIsRadius(true);
    else setIsRadius(false);
  };

  const hideCashModalPopup = () => {
    setShowCashModal(false);
  };
  const hideRedeemModalPopup = () => {
    setShowRedeemCashModal(false);
  };

  const onCashOrderPlaced = async () => {
    setShowCashModal(false);
    await wait(100);
    setIsLoading(true);
    const success: boolean = await processCashOrder();
    if (success) {
      let collectible = await getCollectibleDetails(
        {
          networkID: networkId,
          provider: staticProvider,
          collectibleAddress: offerAddress,
          address,
          wallet,
        },
        { entityData: null },
        { isCache: true, isBalanceRefresh: true, isMarketPlaceRefresh: true },
      );

      await dispatch(
        loadCatalogCollectiable({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
          userInfo,
          isCache: false,
        }),
      );
      await dispatch(
        loadPassportOfferList({
          networkID: networkId,
          provider: staticProvider,
          passport: SelectedPassport,
          wallet,
          address,
        }),
      );
      navigation.navigate('Success', {
        screen: ScreenName.SUCCESS_SCREEN,
        params: {
          message: 'Featured offer bought successfully',
          navigateTo: SelectedPassport.isPremium
            ? 'PassportDetail'
            : 'FollowingDetails',
          navigationParams: {
            passportAddress: SelectedPassport.address,
            chainId: SelectedPassport?.chainId,
          },
        },
      });
      // navigation.navigate(ScreenName.CATALOG_DETAILS, {
      //   catalog: collectible,
      //   availableCatalogs: [],
      //   type: 'Collectibles',
      //   from: 'PassportPage',
      //   passport: SelectedPassport,
      //   updateParent: route.params?.updateParent,
      // });
    } else {
      setIsLoading(false);
      await 100;
      showFailedMessage(true);
    }
    setIsLoading(false);
  };

  const onRedeemOrderPlaced = async () => {
    setShowRedeemCashModal(false);
    await wait(100);
    setRedeemIsLoading(true);
    const success: boolean = await processRedeemOrder();
    if (success) {
      await storeData(APP_STORAGE_GET_COLLECTIBLEDETAILS(offerAddress), null);
      await dispatch(
        loadCatalogOffers({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
          userInfo,
          isCache: true,
        }),
      );
      dispatch(
        loadPassportOfferList({
          networkID: networkId,
          provider: staticProvider,
          passport: SelectedPassport,
          wallet,
          address,
        }),
      );

      // navigation.navigate('My Orders', { screen: "Orders" });
      navigation.navigate(ScreenName.CATALOG_TAB, {
        screen: ScreenName.CATALOG_DETAILS,
        params: {
          catalog: null,
          availableCatalogs: null,
          type: 'Collectibles',
          from: navigationFrom,
          extraParams: { tokenId: tokenId, offerAddress: offerAddress },
        },
        initial: false,
      });
    } else {
      setRedeemIsLoading(false);
      await 100;
      showFailedMessage(true);
    }
    setRedeemIsLoading(false);
  };

  const CheckCash = async () => {
    //this need to call validate once again the event
    setRedeemIsLoading(true);
    try {
      const currentLoot8TokenBalance = await getWalletTokenDetails(
        addresses[networkId].Loot8Token,
        address,
        true,
      );
      if (currentLoot8TokenBalance && currentLoot8TokenBalance.walletBalance) {
        if (Number(price) > Number(currentLoot8TokenBalance.walletBalance)) {
          setAlertText('Insufficient Balance.\n Please buy more Loot8 Tokens');
          setISAlert(true);
          return;
        }
      }
      let isActiveOffer = await getPatronOfferActive({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        offerAddress,
      });
      LogToLoot8Console('isActiveOffer', isActiveOffer);
      if (isActiveOffer) {
        setAlertText(
          'Sorry! Your previous order is pending for fulfillment.\n Please get that served, cancelled or wait till it expires before making another reservation',
        );
        setISAlert(true);
        return;
      } else if (maxBalance || maxPurchase) {
        if (maxPurchase) {
          const dispatcher = Dispatcher__factory.connect(
            addresses[networkId].OrderDispatcher,
            staticProvider,
          );
          const offerContext = await dispatcher.offerContext(offerAddress);
          if (
            +offerContext.maxPurchase &&
            +offerContext.totalPurchases +
              +offerContext.activeReservationsCount >=
              +offerContext.maxPurchase
          ) {
            setAlertText(
              'Sorry! This order cannot be placed. Maximum order limit for the offer is reached.',
            );
            setISAlert(true);
            return;
          }
        }
        if (maxBalance) {
          const offerContract = Loot8Collection__factory.connect(
            offerAddress,
            staticProvider,
          );
          const patronBalance = await offerContract.balanceOf(address);
          if (patronBalance >= maxBalance) {
            setAlertText(
              'Sorry! This order cannot be placed. Your maximum order limit for the offer is reached.',
            );
            setISAlert(true);
            return;
          }
        }
      }
    } finally {
      setRedeemIsLoading(false);
    }

    setShowCashModal(true);
  };

  const redeemOffer = async () => {
    //this need to call validate once again the event
    setRedeemIsLoading(true);
    let isActiveOffer = await getPatronOfferActive({
      networkID: networkId,
      provider: staticProvider,
      address,
      wallet,
      offerAddress,
    });
    LogToLoot8Console('isActiveOffer', isActiveOffer);
    setRedeemIsLoading(false);
    if (isActiveOffer) {
      setISRedeemAlert(true);
      return;
    }
    setShowRedeemCashModal(true);
  };

  const processCashOrder = async () => {
    // approve loot8 token transfer
    await dispatch(
      approveForSubscription({
        networkID: networkId,
        provider: staticProvider,
        wallet: wallet,
        address: address,
        subscription: false,
      }),
    );

    // call mint offer collectible
    const data = await dispatch(
      mintOffers({
        networkID: networkId,
        provider: staticProvider,
        offerAddress: offerAddress,
        cashPayment: false,
        wallet: wallet,
        // offerType: type,
        address: address,
        passportAddress: SelectedPassport?.address,
        offerId: 0,
      }),
    );

    if (data?.payload !== 0) {
      dispatch(
        loadParticularOrderDetail({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
          reservationID: data?.payload,
        }),
      );
      return true;
    }
    return false;
  };

  const processRedeemOrder = async () => {
    const data = await dispatch(
      mintOffers({
        networkID: networkId,
        provider: staticProvider,
        offerAddress: offerAddress,
        cashPayment: false,
        wallet: wallet,
        // offerType: type,
        address: address,
        passportAddress: SelectedPassport?.address,
        offerId: tokenId,
      }),
    );

    if (data?.payload !== 0) {
      // await dispatch(loadParticularOrderDetail({ networkID: networkId, provider: staticProvider, address, wallet, reservationID: data?.payload }));
      return true;
    }
    return false;
  };

  const navigateToCollectibleDetails = async () => {
    const collectible = catalogCollectible.find(
      item => item.address.toLowerCase() === offerAddress.toLowerCase(),
    );
    navigation.navigate(ScreenName.CATALOG_DETAILS, {
      catalog: collectible,
      availableCatalogs: [],
      type: 'Collectibles',
      from: navigationFrom,
      passport: SelectedPassport,
      updateParent: route.params?.updateParent,
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ ...styles.mainContainer, padding: 0 }}>
          <View style={[styles.offerContainer, styles.maxWidthAdjust]}>
            <View style={[styles.offersItemContainer, { marginTop: 0 }]}>
              {
                <View style={styles.offersDetailTileContainer}>
                  <View style={styles.offersContainer}>
                    <View style={styles.offersCaptionContainer}>
                      <Text style={styles.offersCaption}>
                        {getTruncatedName(name, 45)}
                      </Text>
                    </View>

                    <View style={styles.offersDetailDataContainer}>
                      <View style={styles.offersImageContainer}>
                        <CachedImage
                          isBackground={false}
                          source={{ uri: image }}
                          style={styles.OffersDetailImage}
                          contentFit="contain"
                          imageSize={imageSize}></CachedImage>
                      </View>

                      <LinearGradient
                        colors={[
                          defaultTheme.GRADIENT_COLOR2,
                          defaultTheme.GRADIENT_COLOR1,
                        ]}
                        start={{ x: 0, y: 0.3 }}
                        end={{ x: 0, y: 1.3 }}
                        style={styles.offersValueContainer}>
                        <View style={styles.offersValue}>
                          <View style={styles.offersPriceContainer}>
                            {/* <Text style={[styles.offersPriceCurrency, { fontSize: defaultTheme.FONT_SIZE_LARGE }]}>{price ? '$' : ''}</Text> */}
                            <Text
                              style={[
                                styles.offersTokenEarnedValue,
                                { fontSize: defaultTheme.FONT_SIZE_LARGE },
                              ]}>
                              {Number(price) == 0
                                ? 'Free'
                                : formatPriceUpToTrillion(price)}{' '}
                            </Text>
                            <Image
                              source={token_icon}
                              style={{ width: 30, height: 30 }}
                            />
                          </View>
                        </View>
                      </LinearGradient>
                    </View>
                    {tokenId > 0 ? (
                      <View style={styles.offersBottomBarContainer}>
                        <Pressable
                          onPress={navigateToCollectibleDetails}
                          style={[
                            styles.offersBottomButtonContainer,
                            {
                              backgroundColor: defaultTheme.GRADIENT_COLOR1,
                            },
                          ]}>
                          <Text
                            style={{
                              ...styles.offersBottomButtonText,
                              color: '#FFF',
                            }}>
                            View Collection
                          </Text>
                        </Pressable>
                      </View>
                    ) : (
                      <>
                        {isCoupon ? (
                          <View style={styles.offersBottomBarContainer}>
                            <TouchableOpacity
                              style={[
                                styles.offersBottomButtonContainer,
                                {
                                  backgroundColor:
                                    defaultTheme.ACTIVE_PRIMARY_COLOR,
                                  width: 135,
                                },
                              ]}
                              onPress={() => redeemOffer()}>
                              <Image
                                source={cash_icon}
                                style={styles.cashPayIcon}
                              />
                              <Text
                                style={[
                                  styles.offersBottomButtonText,
                                  { color: '#FFF' },
                                ]}>
                                REDEEM
                              </Text>
                            </TouchableOpacity>
                          </View>
                        ) : (
                          <View style={styles.offersBottomBarContainer}>
                            <Pressable
                              disabled={isRadius ? false : true}
                              onPress={() => (isRadius ? CheckCash() : {})}
                              style={[
                                styles.offersBottomButtonContainer,
                                {
                                  backgroundColor: isRadius
                                    ? defaultTheme.GRADIENT_COLOR1
                                    : '#DBE2ED',
                                },
                              ]}>
                              <Image
                                source={cash_icon}
                                style={styles.cashPayIcon}
                              />
                              <Text
                                style={{
                                  ...styles.offersBottomButtonText,
                                  color: isRadius ? '#FFF' : '#BDC6D5',
                                }}>
                                Buy
                              </Text>
                            </Pressable>
                          </View>
                        )}
                      </>
                    )}
                  </View>
                </View>
              }
            </View>

            <View style={styles.offersDetailContainer}>
              <Text style={styles.offersDetailValue}>{details}</Text>
            </View>

            {showCashModal ? (
              <ModalComponent
                showModal={showCashModal}
                headerText="Confirm Reservation"
                dismissable={false}>
                <View style={{ paddingTop: 30 }}>
                  <Text style={styles.modalTextStyle}>
                    Please confirm your reservation
                  </Text>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={styles.modalSecondaryText}>
                      {name} |{' '}
                      {Number(price) === 0
                        ? 'Free'
                        : `${formatPriceUpToTrillion(price)} `}
                    </Text>
                    <Image
                      source={token_icon}
                      style={{ width: 20, height: 20 }}
                    />
                  </View>
                </View>
                <View style={styles.modalButtonContainer}>
                  <Button
                    onPress={onCashOrderPlaced}
                    style={styles.modalYesButtonStyle}
                    labelStyle={styles.modalYesButtonLabelStyle}>
                    CONFIRM
                  </Button>
                  <Button
                    onPress={() => hideCashModalPopup()}
                    style={styles.modalNoButtonStyle}
                    labelStyle={styles.modalNoButtonLabelStyle}>
                    CANCEL
                  </Button>
                </View>
              </ModalComponent>
            ) : (
              <></>
            )}
            {showRedeemCashModal ? (
              <ModalComponent
                showModal={showRedeemCashModal}
                headerText="Confirm Redemption"
                dismissable={false}>
                <View style={{ paddingTop: 30 }}>
                  <Text style={styles.modalTextStyle}>
                    Please confirm offer redemption
                  </Text>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={styles.modalSecondaryText}>
                      {name} |{' '}
                      {Number(price) === 0
                        ? 'Free'
                        : `${formatPriceUpToTrillion(price)} `}
                    </Text>
                    <Image
                      source={token_icon}
                      style={{ width: 20, height: 20 }}
                    />
                  </View>
                </View>
                <View style={styles.modalButtonContainer}>
                  <Button
                    onPress={onRedeemOrderPlaced}
                    style={styles.modalYesButtonStyle}
                    labelStyle={styles.modalYesButtonLabelStyle}>
                    Confirm
                  </Button>
                  <Button
                    onPress={() => hideRedeemModalPopup()}
                    style={styles.modalNoButtonStyle}
                    labelStyle={styles.modalNoButtonLabelStyle}>
                    Cancel
                  </Button>
                </View>
              </ModalComponent>
            ) : (
              <></>
            )}
            {isLoading ? (
              <InfoLoader
                text1={'Hey! Please hold on..'}
                text2={'We are placing your order.'}
              />
            ) : null}

            {isAlert && (
              <ModalComponent
                showModal={isAlert}
                dismissable={false}
                enableHeader={false}>
                <View
                  style={{
                    paddingTop: 25,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingBottom: 15,
                  }}>
                  <Text style={styles.modalTextStyle}>{alertText}</Text>
                </View>
                <View
                  style={[
                    styles.modalButtonContainer,
                    { justifyContent: 'center' },
                  ]}>
                  <Button
                    onPress={() => {
                      setISAlert(false);
                      setAlertText('');
                    }}
                    style={styles.modalYesButtonStyle}
                    labelStyle={styles.modalYesButtonLabelStyle}>
                    OK
                  </Button>
                </View>
              </ModalComponent>
            )}
            {isRedeemAlert && (
              <ModalComponent
                showModal={isRedeemAlert}
                dismissable={false}
                enableHeader={false}>
                <View
                  style={{
                    paddingTop: 25,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingBottom: 15,
                  }}>
                  <Text style={styles.modalTextStyle}>
                    {'The offer is already redeemed!'}
                  </Text>
                </View>
                <View
                  style={[
                    styles.modalButtonContainer,
                    { justifyContent: 'center' },
                  ]}>
                  <Button
                    onPress={() => setISRedeemAlert(false)}
                    style={styles.modalYesButtonStyle}
                    labelStyle={styles.modalYesButtonLabelStyle}>
                    OK
                  </Button>
                </View>
              </ModalComponent>
            )}
            {failedMessage && (
              <ModalComponent
                showModal={failedMessage}
                dismissable={true}
                enableHeader={false}
                onDismiss={() => showFailedMessage(false)}
                needCloseButton={true}>
                <View
                  style={{
                    paddingTop: 25,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingBottom: 25,
                  }}>
                  <Text style={styles.modalNormalTextStyle}>
                    {
                      'Sorry! Your order could not be placed.\nPlease try again later.'
                    }
                  </Text>
                </View>
              </ModalComponent>
            )}
          </View>
        </View>
      </ScrollView>
      {isRedeemLoading && <AppLoaderComponent />}
    </View>
  );
};

export default HOCContainer({
  OriginalComponent: FeaturedOfferDetail,
  title: 'FEATURED OFFER DETAIL',
});
