import { Pressable, StyleSheet, Text, View } from 'react-native';
import React, { useState } from 'react';
import { defaultTheme } from '../../themes/loot8';
import { RadioButton } from 'react-native-paper';
import { SortingOptions } from '../../appconstants';
import { FlatList } from 'react-native';

interface SortModalSheetContentProps {
  setSelectedSortingOption: (option: string) => void;
  selectedSortingOption: number;
  sortingMenuOptions?: [];
  hideModal: () => void;
}

const SortModalSheetContent: React.FC<SortModalSheetContentProps> = ({
  setSelectedSortingOption,
  selectedSortingOption,
  sortingMenuOptions,
  hideModal,
}) => {
  const [active, setActive] = useState(selectedSortingOption);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Sort by</Text>
      <View style={styles.separator} />
      <FlatList
        data={sortingMenuOptions ? sortingMenuOptions : data}
        renderItem={({ item }) => (
          <RowComponent
            isDataFromProps={!!sortingMenuOptions?.length}
            item={item}
            setSelectedSortingOption={setSelectedSortingOption}
            setActive={setActive}
            active={active}
            selectedSortingOption={selectedSortingOption}
            hideModal={hideModal}
          />
        )}
        keyExtractor={item => item.SortingOptions.toString()}
      />
    </View>
  );
};

export default SortModalSheetContent;

const RowComponent = ({
  item,
  setSelectedSortingOption,
  isDataFromProps,
  selectedSortingOption,
  setActive,
  active,
  hideModal,
}) => {
  const pressHandler = () => {
    hideModal();
    setSelectedSortingOption(item.SortingOptions);
    setActive(item.SortingOptions);
  };
  return (
    <Pressable style={styles.row} onPress={pressHandler}>
      <Text style={styles.textLeft}>{item.label}</Text>
      <RadioButton.Android
        value="first"
        status={
          selectedSortingOption == item?.SortingOptions
            ? 'checked'
            : 'unchecked'
        }
        onPress={pressHandler}
        uncheckedColor={defaultTheme.PRIMARY_TEXT_COLOR}
        color={defaultTheme.GRADIENT_COLOR1}
      />
    </Pressable>
  );
};
const styles = StyleSheet.create({
  container: { paddingHorizontal: 15, paddingVertical: 25 },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginLeft: 10,
  },
  textLeft: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginLeft: 10,
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_TEXT_COLOR,
    opacity: 0.6,
    marginTop: 15,
    marginBottom: 25,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 2.5,
  },
});

const data = [
  {
    id: 1,
    SortingOptions: SortingOptions.NONE,
    label: 'Default',
  },
  {
    id: 2,
    SortingOptions: SortingOptions.ALPHABETICAL_ASC,
    label: 'Sorted By: A-Z',
  },
  {
    id: 3,
    SortingOptions: SortingOptions.ALPHABETICAL_DESC,
    label: 'Sorted By: Z-A',
  },
  {
    id: 4,
    SortingOptions: SortingOptions.COST_ASC,
    label: 'Low to High',
  },
  {
    id: 5,
    SortingOptions: SortingOptions.COST_DESC,
    label: 'High to Low',
  },
];
