import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  Platform,
  Keyboard,
  Pressable,
  Dimensions,
  useWindowDimensions,
} from 'react-native';
import { BlurView } from 'expo-blur';
import { Avatar, Title } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import { FlatList } from 'react-native-gesture-handler';
import ActionSheet, { useScrollHandlers } from 'react-native-actions-sheet';

import styles from '../../styles';
import AppLoaderComponent from '../Loader';
import { useAppSelector } from '../../hooks';
import { defaultTheme } from '../../themes/loot8';
import { ReShareWebBlurView } from '../WebBlurView';
import TextInputComponent from '../TextInputComponent';
import { isSmartAppBannerPresent } from '../../slices/helpers';
import { getFriendsInitialLoadPromise } from '../../slices/friendsSlice';

const customSort = array => {
  return array
    .filter(f => f.isFriend === false)
    .sort((a, b) => {
      let textA = a.name.toUpperCase();
      let textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .concat(
      array
        .filter(f => f.isFriend === true)
        .sort((a, b) => {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }),
    );
};

let initialLoadPromise = false;
let initialAvatarLoaded = null;

export const ReshareModalSheetContent = ({
  scrollHandlers,
  noFriendsText,
  onSelectedFriend,
}) => {
  const [searchText, setSearchText] = React.useState('');

  const currentFriendsData = useAppSelector(
    state => state.friends.currentFriends,
  );
  const allAvatarsLoaded = useAppSelector(
    state => state.friends.allAvatarsLoaded,
  );
  const [sortedCurrentFriends, setSortedCurrentFriends] = useState(
    customSort(currentFriendsData),
  );
  const initialSortedFriends = sortedCurrentFriends;
  const [loadingFriends, setLoadingFriends] = useState(false);
  const [isAppBannerPresent, setIsAppBannerPresent] = useState(false);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      setIsKeyboardVisible(true);
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      setIsKeyboardVisible(false);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const RenderItem = ({ item, index }) => (
    <View
      onTouchMove={e => e.stopPropagation()}
      onStartShouldSetResponder={e => true}
      style={{
        ...styles.friendsListContainer,
        paddingBottom:
          index !== sortedCurrentFriends.length - 1
            ? 0
            : isKeyboardVisible
              ? Dimensions.get('window').height * 0.2
              : Dimensions.get('window').height * 0.1,
      }}
      key={index}>
      <Pressable
        onPress={() => {
          onSelectedFriend(item);
        }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Avatar.Image
            style={[
              styles.friendsAvatar,
              { backgroundColor: item.bgColor, alignSelf: 'center' },
            ]}
            size={35}
            source={
              item.avatarURI &&
              item.avatarURI != '' &&
              item.avatarURI != 'no-avatar' &&
              !item.avatarURI.includes('ipfs://') &&
              !item.avatarURI.includes('assets_avatars_p_') &&
              !item.avatarURI.includes('file:///')
                ? { uri: item.avatarURI }
                : require('../../assets/loot8-default-avatar.png')
            }
          />

          <View
            style={[
              styles.friendsUserDetailContainer,
              { justifyContent: 'center' },
            ]}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Title
                style={[
                  styles.userTitle,
                  {
                    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                    maxWidth: '98%',
                    textAlign: 'left',
                    lineHeight: 17,
                  },
                ]}>
                {`${item?.name}`}{' '}
                <Text style={styles.friendUserIdText}>{`#${item?.id}`}</Text>
              </Title>
            </View>
          </View>
        </View>
      </Pressable>
    </View>
  );

  const onFriendSearch = async text => {
    setSearchText(text);
    if (text && text.length > 0) {
      setSortedCurrentFriends(
        customSort(currentFriendsData)?.filter(
          p => p.name.toLowerCase().indexOf(text.toLowerCase()) > -1,
        ),
      );
    } else {
      setSortedCurrentFriends(customSort(currentFriendsData));
    }
  };

  const _listEmptyComponent = () => {
    return (
      <Text
        style={[
          styles.addFriendsMsgText,
          { color: defaultTheme.PRIMARY_TEXT_COLOR, marginTop: 60 },
        ]}>
        {'No results found.'}
      </Text>
    );
  };

  useEffect(() => {
    if (!currentFriendsData || currentFriendsData?.length == 0) {
      setLoadingFriends(true);
      getFriendsInitialLoadPromise().then(() => {
        initialLoadPromise = true;
        initialAvatarLoaded = false;
      });
    } else if (!initialLoadPromise) {
      setLoadingFriends(false);
      setSortedCurrentFriends(customSort(currentFriendsData));
    }
  }, [currentFriendsData]);

  useEffect(() => {
    if (initialAvatarLoaded === false) {
      initialAvatarLoaded = true;
      setSortedCurrentFriends(customSort(currentFriendsData));
    }
    if (allAvatarsLoaded && currentFriendsData?.length == 0) {
      setLoadingFriends(false);
    }
  }, [allAvatarsLoaded]);

  useEffect(() => {
    setIsAppBannerPresent(isSmartAppBannerPresent());
  }, [useWindowDimensions().height]);

  return (
    <>
      <View
        style={[
          {
            paddingHorizontal: 16,
            alignContent: 'center',
            maxHeight:
              Platform.OS !== 'web'
                ? Dimensions.get('window').height * 0.7
                : '60vh',
          },
          loadingFriends && { minHeight: 100 },
        ]}>
        {(sortedCurrentFriends && sortedCurrentFriends.length > 0) ||
        searchText ? (
          <View
            style={
              Platform.OS !== 'web'
                ? { height: '100%', width: '100%' }
                : { flex: 1 }
            }>
            <View
              style={{
                height: 50,
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                // marginVertical: 10,
              }}>
              <Text
                style={{
                  fontFamily: defaultTheme.FONT_FAMILY_BOLD,
                  fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                  color: defaultTheme.PRIMARY_TEXT_COLOR,
                  textAlign: 'center',
                }}>
                Select Friend
              </Text>
            </View>
            <View
              style={{
                height: 1,
                backgroundColor: defaultTheme.SECONDARY_TEXT_COLOR,
                opacity: 0.6,
                marginBottom: 15,
              }}
            />

            <TextInputComponent
              value={searchText}
              type="primary"
              leftIcon="magnify"
              placeholder="Search friends..."
              setOnChange={onFriendSearch}
              rightIcon={!!searchText ? 'close' : null}
              onRightIconPress={() => setSearchText('')}
            />

            <FlatList
              style={{ flex: 1, marginVertical: 10 }}
              data={sortedCurrentFriends}
              renderItem={RenderItem}
              nestedScrollEnabled
              keyboardDismissMode="on-drag"
              showsVerticalScrollIndicator={false}
              ListEmptyComponent={_listEmptyComponent}
            />
          </View>
        ) : (
          <>
            {!loadingFriends && (
              <Text
                style={[
                  styles.addFriendsMsgText,
                  { alignSelf: 'center', textAlign: 'center', padding: 30 },
                ]}>
                {sortedCurrentFriends.length == 0 &&
                  !searchText &&
                  noFriendsText}
              </Text>
            )}
          </>
        )}
        {loadingFriends && currentFriendsData?.length == 0 && (
          <AppLoaderComponent deemBg={false} />
        )}
      </View>
    </>
  );
};

const FriendsModal = ({
  actionSheetRef,
  onSelectedFriend,
  noFriendsText = '',
}) => {
  const scrollHandlers = useScrollHandlers<FlatList>(null);
  return (
    <ActionSheet
      ref={actionSheetRef}
      drawUnderStatusBar
      keyboardHandlerEnabled
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      enableGesturesInScrollView={false}
      backgroundInteractionEnabled={false}
      overdrawEnabled={false}
      closeOnTouchBackdrop={true}
      onClose={() => Keyboard.dismiss()}
      onBeforeClose={() => Keyboard.dismiss()}>
      {Platform.OS === 'android' ? (
        <View
          style={{
            ...styles.modalCenteredContainer,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <ReshareModalSheetContent
              noFriendsText={noFriendsText}
              onSelectedFriend={onSelectedFriend}
              scrollHandlers={scrollHandlers}
            />
          </LinearGradient>
        </View>
      ) : Platform.OS == 'ios' ? (
        <BlurView
          tint="light"
          style={{
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            overflow: 'hidden',
          }}
          intensity={60}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <ReshareModalSheetContent
              noFriendsText={noFriendsText}
              onSelectedFriend={onSelectedFriend}
              scrollHandlers={scrollHandlers}
            />
          </LinearGradient>
        </BlurView>
      ) : (
        <ReShareWebBlurView>
          <View
            style={{
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              overflow: 'hidden',
            }}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <ReshareModalSheetContent
                noFriendsText={noFriendsText}
                onSelectedFriend={onSelectedFriend}
                scrollHandlers={scrollHandlers}
              />
            </LinearGradient>
          </View>
        </ReShareWebBlurView>
      )}
    </ActionSheet>
  );
};

export default FriendsModal;
