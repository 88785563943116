import { BigNumber } from 'ethers';
import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { addresses } from '../../appconstants';
import { IAddressBaseAsyncThunk } from '../../slices/interfaces';
import { Loot8Onboarder__factory, User__factory } from '../../typechain';

export const IsUserExists = async ({
    networkID,
    provider,
    address,
}: IAddressBaseAsyncThunk): Promise<{
    userAttributes: {
        id: BigNumber;
        name: string;
        wallet: string;
        avatarURI: string;
        dataURI: string;
    };
}> => {
    const userFactory = User__factory.connect(
        addresses[networkID].User,
        provider,
    );
    const userAttributes = await userFactory.userAttributes(address);
    return { userAttributes };
};

export const getUserOnboarded = async ({
    networkID,
    provider,
    address,
}: IAddressBaseAsyncThunk): Promise<boolean> => {
    const onboarder = Loot8Onboarder__factory.connect(
        addresses[networkID].Onboarder,
        provider,
    );

    const isOnboarded = await onboarder.userOnboarded(address);

    return isOnboarded;
};

export interface IAppUserSliceData {
    readonly UserData?: IUser;
    readonly loading: boolean;
    readonly AllUsersData: IUser[];
    readonly MutedUsers: any[];
    readonly SocialAccessLoading: boolean;
    readonly MailStatus: Number;
    readonly InvitesSent: Number;
    readonly maxInvites: Number;
    readonly socialAccountVerifiedCodeLoading: boolean;
    readonly isNewUser: boolean;
}

const initialState: IAppUserSliceData = {
    UserData: null,
    loading: false,
    AllUsersData: [],
    MutedUsers: null,
    SocialAccessLoading: false,
    MailStatus: 0,
    InvitesSent: 0,
    maxInvites: 0,
    socialAccountVerifiedCodeLoading: false,
    isNewUser: false,
};

const AppUserSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        //TODO: setUserOnboarded needs to be removed
        setUserOnboarded(state, action) {
            if (state.UserData) {
                state.UserData.isOnboarded = action.payload;
            }
        },
        setMailStatus(state, action) {
            state.MailStatus = action.payload;
        },
    },
    extraReducers: builder => { },
});

export const AppUserSliceReducer = AppUserSlice.reducer;

const baseInfo = (state: RootState) => state.AppUser;

export const { setUserOnboarded, setMailStatus } = AppUserSlice.actions;

export const getUserState = createSelector(baseInfo, User => User);
