import React, { memo, useRef, useState } from 'react';
import { View } from 'react-native';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { FlatList } from 'react-native-gesture-handler';
import { SheetManager } from 'react-native-actions-sheet';

import TextInputComponent from '../TextInputComponent';
import GradientFilterButton from '../GradientFilterButton';
import AnimatedUnderlineTabs from '../AnimatedUnderlineTabs';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import FollowingTabSection from '../FollowingTabSection';
import ExperienceTabSection from '../ExperienceTabSection';
import useIsResponsive from '../../hooks/useIsResponsive';
import { styles } from './styles';
import { defaultTheme } from '../../themes/loot8';
import { searchPassport } from '../../slices/PassportSlice';
import { SortingOptions } from '../../appconstants';
import { searchCreator } from '../../slices/CreatorsSlice';

const HomeTabSection: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const [selectedSortingOption, setSelectedSortingOption] = useState(
    SortingOptions.NONE,
  );
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabsRef = useRef(null);
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();
  const dispatch = useDispatch();

  const closeSortingOptionsMenu = async () => {
    setSelectedSortingOption(SortingOptions.NONE);
    await SheetManager.hide('SortModalSheet');
  };

  const onFilterPressed = async () => {
    if (activeTabIndex === 0) {
      await SheetManager.show('SortModalSheet', {
        payload: {
          sortingMenuOptions: [
            {
              id: 1,
              SortingOptions: SortingOptions.NONE,
              label: 'Default',
            },
            {
              id: 2,
              SortingOptions: SortingOptions.ALPHABETICAL_ASC,
              label: 'Sorted By: A-Z',
            },
            {
              id: 3,
              SortingOptions: SortingOptions.ALPHABETICAL_DESC,
              label: 'Sorted By: Z-A',
            },
          ],
          setSelectedSortingOption: setSelectedSortingOption,
          selectedSortingOption: selectedSortingOption,
        },
      });
    } else {
      await SheetManager.show('SortModalSheet', {
        payload: {
          setSelectedSortingOption: setSelectedSortingOption,
          selectedSortingOption: selectedSortingOption,
        },
      });
    }
  };
  const goToNextSlide = (index: number) => {
    setActiveTabIndex(index);
    const offset = isResponsive
      ? index * defaultTheme.CONTENT_CONTAINER_MAX_WIDTH
      : index * (activeWidth - defaultTheme.HORIZONTAL_PADDING * 2);
    tabsRef?.current.scrollToOffset({ offset });
  };

  const onPassportSearch = (searchText: string) => {
    setSearchText(searchText);
    dispatch(debounce(searchPassport({ searchText }), 500));
    // Add Creator search logic here as well as the Search bar is shared for both Passports and Creators
    dispatch(debounce(searchCreator({ searchText }), 500));
  };

  return (
    <View style={styles.container}>
      <View style={{ marginVertical: 2.5 }} />

      <AnimatedUnderlineTabs
        data={topTabsButtonData}
        changeSlidesOnPress={goToNextSlide}
      />
      <View style={{ marginVertical: 10 }} />
      <TextInputComponent
        placeholder={'Search for categories, artist, collection...'}
        value={searchText}
        setOnChange={onPassportSearch}
        type="primary"
        leftIcon="magnify"
        rightIcon={!!searchText ? 'close' : null}
        onRightIconPress={() => setSearchText('')}
        customRight={
          <GradientFilterButton size={34} onPress={onFilterPressed} />
        }
        onFocus={() => {
          closeSortingOptionsMenu();
        }}
      />
      <FlatList
        ref={tabsRef}
        data={topTabsButtonData}
        horizontal
        snapToAlignment="start"
        decelerationRate={'fast'}
        snapToInterval={activeWidth}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ flex: 1 }}
        renderItem={({ item, index }) => (
          <Slide
            index={index}
            activeTabIndex={activeTabIndex}
            closeSortingOptionsMenu={closeSortingOptionsMenu}
            selectedSortingOption={selectedSortingOption}
          />
        )}
        scrollEnabled={false}
      />
    </View>
  );
};

export default HomeTabSection;

const topTabsButtonData = [
  { id: 1, title: 'Following' },
  { id: 2, title: 'Experience Passports' },
];

const Slide = memo(
  ({
    index,
    activeTabIndex,
    selectedSortingOption,
    closeSortingOptionsMenu,
  }: {
    index: number;
    activeTabIndex: number;
    selectedSortingOption: number;
    closeSortingOptionsMenu: () => void;
  }) => {
    const activeWidth = useActiveDimensions().width;
    const isResponsive = useIsResponsive();

    const renderContent = () => {
      if (index !== activeTabIndex) {
        return null; // If the index does not match the active tab index, return null to prevent unnecessary rendering
      }

      if (activeTabIndex === 0) {
        return (
          <FollowingTabSection
            closeSortingOptionsMenu={closeSortingOptionsMenu}
            selectedSortingOption={selectedSortingOption}
          />
        );
      } else if (activeTabIndex === 1) {
        return (
          <ExperienceTabSection
            closeSortingOptionsMenu={closeSortingOptionsMenu}
            selectedSortingOption={selectedSortingOption}
          />
        );
      }
      return (
        <FollowingTabSection
          closeSortingOptionsMenu={closeSortingOptionsMenu}
          selectedSortingOption={selectedSortingOption}
        />
      );
    };

    return (
      <View
        style={
          isResponsive
            ? {
                width:
                  defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
                  defaultTheme.HORIZONTAL_PADDING * 2,
              }
            : {
                width: activeWidth - defaultTheme.HORIZONTAL_PADDING * 2,
              }
        }>
        {renderContent()}
      </View>
    );
  },
);
