import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    fontSize: 12,
    flex: 1,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    height: 48,
  },
  wrapper: {
    width: '100%',
    borderRadius: defaultTheme.BUTTON_RADIUS,
    height: 48,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
