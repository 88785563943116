import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { ILatLng } from '../interfaces/ILocation.interface';
import { RootState } from '../store';
import { setAll } from './helpers';

export interface ILocationSlice {
  readonly currentLocation: ILatLng;
}

const initialState: ILocationSlice = {
  currentLocation: null,
};

export const loadLocation = createAsyncThunk(
  'location/loadLocation',
  async ({ latitude, longitude }: ILatLng): Promise<any> => {
    let userLocation: ILatLng = {
      latitude: latitude,
      longitude: longitude,
    };
    return {
      currentLocation: userLocation,
    };
  },
);

const LocationSlice = createSlice({
  name: 'Location',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(loadLocation.fulfilled, (state, action) => {
      if (action.payload.currentLocation && state.currentLocation) {
        if (
          state.currentLocation.latitude !=
            action.payload.currentLocation.latitude ||
          state.currentLocation.longitude !=
            action.payload.currentLocation.longitude
        ) {
          state.currentLocation = action.payload.currentLocation;
        }
      } else if (!state.currentLocation && action.payload.currentLocation) {
        state.currentLocation = action.payload.currentLocation;
      }
    });
  },
});

export const LocationSliceReducer = LocationSlice.reducer;

const baseInfo = (state: RootState) => state.Location;

export const { fetchAppSuccess } = LocationSlice.actions;

export const getLocationState = createSelector(
  baseInfo,
  LocationSlice => LocationSlice,
);
