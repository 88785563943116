import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import styles from '../../../../styles';
import { defaultTheme } from '../../../../themes/loot8';
import GradientButton from '../../../GradientButton';
import { SheetManager } from 'react-native-actions-sheet';
import { IEventData } from '../../../../interfaces/IEventData';
import { AssociatedEvent } from '../../../../hooks/useExpassEventManager';
import {
  getWalletTokenDetails,
  isLocationAvailable,
} from '../../../../slices/helpers';
import { addresses, isNativeChain } from '../../../../appconstants';
import { useWeb3AuthContext } from '../../../../hooks/web3authContext';
import { Avatar } from 'react-native-paper';
import blackBgToken from '../../../../assets/Loot8TokenBlack.png';
import usdcLogo from '../../../../assets/usdcLogo.png';
import { formatPriceUpToTrillion } from '../../../../helpers/Gadgets';
import ModalComponent from '../../../Modal';
import { useAppSelector } from '../../../../hooks';
import { Button } from 'react-native-paper';
import {
  getPatronOfferActive,
  loadParticularOrderDetail,
} from '../../../../slices/OrderSlice';
import {
  Dispatcher__factory,
  Loot8Collection__factory,
} from '../../../../typechain';
import { approveForSubscription } from '../../../../slices/PassportSlice';
import {
  getCollectibleDetails,
  mintOffers,
} from '../../../../slices/OfferSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { useNavigation } from '@react-navigation/native';
import { ScreenName } from '../../../../enums/screen.enum';
import { LogCustomError } from '../../../../helpers/AppLogger';
import AppLoaderComponent from '../../../Loader';

interface Props {
  selectedEvent: AssociatedEvent;
  isLoading: boolean;
  isTicketPurchased?: boolean;
  isTicketRedeemed?: boolean;
}

const EventTicket = ({
  selectedEvent,
  isLoading,
  isTicketPurchased,
  isTicketRedeemed,
}: Props) => {
  const navigation = useNavigation();
  const dispatch = useDispatch<AppDispatch>();
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const { networkId, address, staticProvider, wallet, SelectedPassport } =
    useWeb3AuthContext();

  const [currentBalance, setCurrentBalance] = useState(0);
  const [hasCurrentBalanceLoaded, setHasCurrentBalanceLoaded] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [booking, setBooking] = useState(false);

  const [isAlert, setISAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [failedMessage, showFailedMessage] = useState(false);

  const onPressRedeemTicket = async () => {
    await SheetManager.show('Loot8RedeemTicketSheet', {
      payload: {
        eventAddress: selectedEvent.event,
        ticket: selectedEvent.tData,
      },
    } as any);
  };

  const onBookTicket = async () => {
    try {
      setBooking(true);
      setIsModalVisible(false);

      const ticket = selectedEvent?.tData;

      //* Check for Geofence
      if (!isLocationAvailable(currenLocation, ticket?.area)) {
        setAlertText(
          'Geofence Constraint\nThis ticket is not available in your area.',
        );
        setISAlert(true);
        return;
      }

      //* Check of Booking/Purchase is already in progress
      let isActiveOffer = await getPatronOfferActive({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        offerAddress: ticket?.address,
      });

      if (isActiveOffer) {
        setAlertText(
          'Sorry! Your previous order is pending for fulfillment.\n Please get that served, cancelled or wait till it expires before making another booking',
        );
        setISAlert(true);
        return;
      }

      //* Check if Max Purchase Limit is not reached
      if (ticket?.maxPurchase) {
        const dispatcher = Dispatcher__factory.connect(
          addresses[networkId].OrderDispatcher,
          staticProvider,
        );
        const offerContext = await dispatcher.offerContext(ticket?.address);
        if (
          +offerContext.maxPurchase &&
          +offerContext.totalPurchases +
            +offerContext.activeReservationsCount >=
            +offerContext.maxPurchase
        ) {
          setAlertText(
            'Sorry! This ticket cannot be booked. Maximum Ticket limit for the Event is reached.',
          );
          setISAlert(true);
          return;
        }
      }

      //* Check if Max Order Limit is not reached
      if (ticket?.maxBalance) {
        const offerContract = Loot8Collection__factory.connect(
          ticket?.address,
          staticProvider,
        );
        const patronBalance = await offerContract.balanceOf(address);
        if (+patronBalance >= ticket?.maxBalance) {
          setAlertText(
            'Sorry! This ticket cannot be booked. Your maximum ticket limit for the event is reached.',
          );
          setISAlert(true);
          return;
        }
      }

      //* Initiate Ticket purchase

      //* Get Approval for Purchase/Booking
      await dispatch(
        approveForSubscription({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
          subscription: false,
        }),
      );

      //* Request for Ticket minting
      const data = await dispatch(
        mintOffers({
          networkID: networkId,
          provider: staticProvider,
          offerAddress: ticket.address,
          cashPayment: false,
          wallet: wallet,
          address: address,
          passportAddress: selectedEvent.event,
          offerId: 0,
        }),
      );

      if (data?.payload !== 0) {
        //* Manage Ticket/Order Reservation data including unfullfilled orders
        dispatch(
          loadParticularOrderDetail({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            reservationID: data?.payload,
          }),
        );

        //* Ticket Booking is a success

        //* Fetch updated data for the Ticket
        let collectible = await getCollectibleDetails(
          {
            networkID: networkId,
            provider: staticProvider,
            collectibleAddress: ticket.address,
            address,
            wallet,
          },
          { entityData: null },
          {
            isCache: false,
            isBalanceRefresh: false,
            isMarketPlaceRefresh: false,
          },
        );

        // @ts-ignore
        navigation.navigate('Success', {
          screen: ScreenName.SUCCESS_SCREEN,
          params: {
            message: 'Ticket booked successfully!',
            navigateTo: SelectedPassport.isPremium
              ? 'PassportDetail'
              : 'FollowingDetails',
            navigationParams: {
              params: {
                passportAddress: SelectedPassport.address,
                chainId: SelectedPassport?.chainId,
              },
            },
          },
        });
      } else {
        //* Failed to book ticket
        showFailedMessage(true);
        return;
      }
    } catch (error) {
      LogCustomError(
        '~ onBookTicket-event-ticket-' + selectedEvent?.tData?.address,
        error?.method,
        error?.reason,
        ' ',
      );
      showFailedMessage(true);
    } finally {
      setBooking(false);
    }
  };

  const onRequestBooking = async () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    (async () => {
      setHasCurrentBalanceLoaded(true);
      const currentLoot8TokenBalance = await getWalletTokenDetails(
        addresses[networkId].Loot8Token,
        address,
        true,
      );
      if (currentLoot8TokenBalance && currentLoot8TokenBalance.walletBalance) {
        setCurrentBalance(currentLoot8TokenBalance.walletBalance);
      }
      setHasCurrentBalanceLoaded(false);
    })();

    return () => {
      setHasCurrentBalanceLoaded(true);
    };
  }, []);

  if (isLoading) {
    return (
      <View style={{ width: '100%', height: 350 }}>
        <AppLoaderComponent
          deemBg={false}
          loaderText={{ text: 'Loading Event details...' }}
        />
      </View>
    );
  }

  return (
    <>
      <View style={{ marginBottom: 12, flex: 1, width: '100%' }}>
        <View style={styles.tokenBuyItemContainer}>
          <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
            Event Name
          </Text>
          <View
            style={{
              flexDirection: 'row',
              flex: 0.6,
              justifyContent: 'flex-end',
            }}>
            <Text
              style={{
                ...styles.tokenBuyItemCaption,
                maxWidth: '75%',
                textAlign: 'right',
              }}>
              {selectedEvent?.eData?.name}
            </Text>
          </View>
        </View>
        <View style={styles.tokenBuyHairline} />
        <View style={styles.tokenBuyItemContainer}>
          <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
            Event Ticket Price
          </Text>
          <View
            style={{
              flexDirection: 'row',
              flex: 0.6,
              justifyContent: 'flex-end',
              columnGap: 5,
              alignItems: 'center',
            }}>
            <Text
              style={{
                ...styles.tokenBuyItemCaption,
                maxWidth: '75%',
                textAlign: 'right',
              }}>
              {isLoading
                ? '--.--'
                : Number(selectedEvent?.tData?.price) === 0
                ? 'Free'
                : formatPriceUpToTrillion(selectedEvent?.tData?.price)}{' '}
            </Text>
            <Avatar.Image
              style={{
                alignSelf: 'center',
              }}
              size={22}
              source={isNativeChain(networkId) ? blackBgToken : usdcLogo}
            />
          </View>
        </View>
        <View style={styles.tokenBuyHairline} />
        <View style={{ padding: 5, alignItems: 'flex-start', minHeight: 200 }}>
          <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.2 }}>
            Event Description
          </Text>
          <View
            style={{
              flex: 0.8,
              justifyContent: 'flex-start',
            }}>
            <Text
              style={{
                ...styles.tokenBuyItemCaption,
                textAlign: 'left',
                flexShrink: 1,
                flexWrap: 'wrap',
              }}>
              {selectedEvent?.eData?.description}
            </Text>
          </View>
        </View>
        <View style={styles.tokenBuyHairline} />
        {selectedEvent?.dateString && (
          <>
            <View style={styles.tokenBuyItemContainer}>
              <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
                Dates
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  flex: 0.6,
                  justifyContent: 'flex-end',
                }}>
                <Text
                  style={{
                    ...styles.tokenBuyItemCaption,
                    maxWidth: '75%',
                    textAlign: 'right',
                  }}>
                  {selectedEvent?.dateString}
                </Text>
              </View>
            </View>
            <View style={styles.tokenBuyHairline} />
          </>
        )}
        {isTicketRedeemed &&
          // @ts-ignore
          selectedEvent?.tData?.redemptionStatus?.redeemedAt && (
            <>
              <View style={styles.tokenBuyItemContainer}>
                <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
                  Ticket Redeemed
                </Text>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 0.6,
                    justifyContent: 'flex-end',
                  }}>
                  <Text
                    style={{
                      ...styles.tokenBuyItemCaption,
                      maxWidth: '75%',
                      textAlign: 'right',
                    }}>
                    {/* @ts-ignore */}
                    {selectedEvent?.tData?.redemptionStatus?.redeemedAt}
                  </Text>
                </View>
              </View>
              <View style={styles.tokenBuyHairline} />
            </>
          )}

        <View style={{ flex: 1 }}>
          {!isTicketPurchased && (
            <View
              style={[
                componentStyles.row,
                { marginBottom: 15, marginTop: 25 },
              ]}>
              <Text style={componentStyles.italicText}>
                Available LOOT8 Balance: {currentBalance}
              </Text>
              {!hasCurrentBalanceLoaded &&
                !isLoading &&
                +formatPriceUpToTrillion(selectedEvent?.tData?.price) >
                  +currentBalance && (
                  <Text
                    style={[
                      componentStyles.italicText,
                      {
                        color: 'red',
                        textDecorationLine: 'none',
                      },
                    ]}>
                    Insufficient Funds
                  </Text>
                )}
            </View>
          )}
        </View>

        {isTicketRedeemed ? (
          <></>
        ) : isTicketPurchased ? (
          <GradientButton
            // @ts-ignore
            disabled={isLoading || !selectedEvent?.tData?.isValid}
            onPress={onPressRedeemTicket}
            buttonLabel={'Redeem Ticket'}
            loading={isLoading}
          />
        ) : (
          <GradientButton
            disabled={
              // @ts-ignore
              !selectedEvent?.tData?.isValid ||
              isLoading ||
              booking ||
              +formatPriceUpToTrillion(selectedEvent?.tData?.price) >
                +currentBalance
            }
            onPress={onRequestBooking}
            buttonLabel={'Book ticket'}
            loading={isLoading || booking}
          />
        )}

        <ModalComponent
          showModal={isModalVisible}
          needCloseButton={true}
          enableHeader={false}
          onDismiss={() => setIsModalVisible(false)}>
          <View
            style={{
              padding: 30,
              flexDirection: 'row',
            }}>
            <Text style={{ color: defaultTheme.PRIMARY_TEXT_COLOR, lineHeight: 24 }}>
              Are you sure you want to book the ticket for{' '}
              {isLoading
                ? '--.-- '
                : Number(selectedEvent?.tData?.price) === 0
                ? 'Free '
                : formatPriceUpToTrillion(selectedEvent?.tData?.price)}
              <View style={{paddingLeft: 4}}>
                <Avatar.Image
                  size={20}
                  source={isNativeChain(networkId) ? blackBgToken : usdcLogo}
                  style={{marginBottom: -4}}
                />
              </View>
            </Text>
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: 10,
              marginBottom: 30,
              paddingHorizontal: Platform.OS === 'web' ? 15 : 25,
            }}>
            <GradientButton
              width="48%"
              type="secondary"
              buttonLabel="Cancel"
              onPress={() => setIsModalVisible(false)}
            />

            <GradientButton
              width="48%"
              buttonLabel="Book Ticket!"
              type="alternate"
              onPress={onBookTicket}
            />
          </View>
        </ModalComponent>

        {isAlert && (
          <ModalComponent
            showModal={isAlert}
            dismissable={false}
            enableHeader={false}>
            <View
              style={{
                paddingTop: 25,
                paddingLeft: 15,
                paddingRight: 15,
                paddingBottom: 15,
              }}>
              <Text style={styles.modalTextStyle}>{alertText}</Text>
            </View>
            <View
              style={[
                styles.modalButtonContainer,
                { justifyContent: 'center' },
              ]}>
              <Button
                onPress={() => {
                  setISAlert(false);
                  setAlertText('');
                }}
                style={styles.modalYesButtonStyle}
                labelStyle={styles.modalYesButtonLabelStyle}>
                OK
              </Button>
            </View>
          </ModalComponent>
        )}

        {failedMessage && (
          <ModalComponent
            showModal={failedMessage}
            dismissable={true}
            enableHeader={false}
            onDismiss={() => showFailedMessage(false)}
            needCloseButton={true}>
            <View
              style={{
                paddingTop: 25,
                paddingLeft: 15,
                paddingRight: 15,
                paddingBottom: 25,
              }}>
              <Text style={styles.modalNormalTextStyle}>
                {
                  'Sorry! Your ticket could not be booked.\nPlease try again later.'
                }
              </Text>
            </View>
          </ModalComponent>
        )}
      </View>
    </>
  );
};

export default EventTicket;

const componentStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginTop: 5,
  },
  subtitle: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginTop: 10,
  },
  italicText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textDecorationLine: 'underline',
  },
});
