import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

interface UserPreferences {
  toggleTheme: () => void;
  isThemeDark: boolean;
  //selectedPassportCategory: PassportCategory,

  // availablePassesExpanded: {
  //   [PassportCategory.ORGANIZATION]: boolean,
  //   [PassportCategory.PEOPLE]: boolean
  // },

  // myPassesExpanded: {
  //   [PassportCategory.ORGANIZATION]: boolean,
  //   [PassportCategory.PEOPLE]: boolean
  // },

  availablePassesExpanded: boolean;

  myPassesExpanded: boolean;

  // setSelectedPassportCategory : (category: PassportCategory) => void,

  setAvailablePassesExpanded: (value: any) => void;

  setMyPassesExpanded: (value: any) => void;
}

export const PreferencesContext = createContext<UserPreferences | null>(null);

export const UserPreferences = () => {
  const preferencesContext = useContext(PreferencesContext) as UserPreferences;
  let defaultPreferences: UserPreferences = {
    toggleTheme: (): void => {},
    isThemeDark: true,
    //selectedPassportCategory: null,
    // setSelectedPassportCategory: (category: PassportCategory): void => {},
    // availablePassesExpanded: {
    //   [PassportCategory.ORGANIZATION]: false,
    //   [PassportCategory.PEOPLE]: false
    // },
    // setAvailablePassesExpanded: ({}): void => {},
    // myPassesExpanded: {
    //   [PassportCategory.ORGANIZATION]: false,
    //   [PassportCategory.PEOPLE]: false
    // },
    //setSelectedPassportCategory: (category: PassportCategory): void => {},
    setMyPassesExpanded: ({}): void => {},
    availablePassesExpanded: false,
    setAvailablePassesExpanded: ({}): void => {},
    myPassesExpanded: false,
  };

  if (preferencesContext) {
    defaultPreferences = preferencesContext;
  }

  return useMemo<UserPreferences>(() => defaultPreferences, [defaultPreferences]);
};

export const UserPreferencesProvider = ({ children }) => {
  const [isThemeDark, setIsThemeDark] = React.useState(true);
  //const [selectedPassportCategory, setSelectedPassportCategory] = useState<PassportCategory>(PassportCategory.ORGANIZATION);

  // const [myPassesExpanded, setMyPassesExpanded] = useState({
  //   [PassportCategory.ORGANIZATION] : true,
  //   [PassportCategory.PEOPLE]: true
  // });
  // const [availablePassesExpanded, setAvailablePassesExpanded] = useState({
  //   [PassportCategory.ORGANIZATION] : true,
  //   [PassportCategory.PEOPLE]: true
  // });
  const [myPassesExpanded, setMyPassesExpanded] = useState(true);
  const [availablePassesExpanded, setAvailablePassesExpanded] = useState(true);

  const toggleTheme = useCallback(() => {
    return setIsThemeDark(!isThemeDark);
  }, [isThemeDark]);

  const preferences = useMemo(
    () => ({
      toggleTheme,
      isThemeDark,
      //selectedPassportCategory,
      //setSelectedPassportCategory,
      myPassesExpanded,
      setMyPassesExpanded,
      availablePassesExpanded,
      setAvailablePassesExpanded,
    }),
    [
      toggleTheme,
      isThemeDark,
      //selectedPassportCategory,
      //setSelectedPassportCategory,
      myPassesExpanded,
      setMyPassesExpanded,
      availablePassesExpanded,
      setAvailablePassesExpanded,
    ],
  );

  return <PreferencesContext.Provider value={preferences}>{children}</PreferencesContext.Provider>;
};
