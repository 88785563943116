import React, { useEffect, useState } from 'react';
import styles from '../../styles';
import { View, Text, Image, Linking, Pressable } from 'react-native';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { IPNS_UPLOAD_LAMBDA_URL, MASTER_KEY } from '../../appconstants';
import { ethers } from 'ethers';
import htmlParser from 'react-native-html-parser';
import { LogCustomError } from '../../helpers/AppLogger';
import urlParser from 'url';
import { getIPNSLink } from '../../helpers/ipfs';
import Constants from 'expo-constants';

export interface URLPreviewParams {
  message: string;
  url: string;
  children: React.ReactNode;
  smallPreviewCard?: boolean;
  hasURLPreview?: (val: any) => void;
}

const URLPreview = ({
  message,
  url,
  children,
  smallPreviewCard = true,
  hasURLPreview,
}: URLPreviewParams) => {
  const { address, wallet } = useWeb3AuthContext();
  const [linkPreview, setLinkPreview] = useState(null);

  const readURL = async () => {
    try {
      const data = Buffer.from(url, 'utf-8');
      const canonicalizedData = data;

      // sign data
      const signature = await wallet.signMessage(canonicalizedData);

      const masterWallet = new ethers.Wallet(MASTER_KEY);
      const masterSignature = await masterWallet.signMessage(signature);

      const responseMetaTags = await fetch(
        IPNS_UPLOAD_LAMBDA_URL +
          'user/' +
          address +
          '/fetch/' +
          encodeURIComponent(url) +
          '/' +
          signature +
          '/' +
          masterSignature,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        },
      );

      var linkPreview = {
        url: url,
        title: null,
        image: null,
        description: null,
      };

      if (responseMetaTags && responseMetaTags.status == 200) {
        const pageHtml = await responseMetaTags.text();
        var parser = new htmlParser.DOMParser();
        var doc = parser?.parseFromString(pageHtml, 'text/html');
        var titleTag = doc?.getElementsByTagName('title');
        var metaTags = doc?.getElementsByTagName('meta');
        var bodyTags = doc?.getElementsByTagName('body');

        // get title
        if (titleTag && titleTag?.length > 0) {
          let title = titleTag[0].textContent || titleTag[0].innerText;
          linkPreview = { ...linkPreview, title: title };
        }

        // get description
        // if(metaTags && metaTags?.length > 0) {
        //     for (let i = 0; i < metaTags.length; i++) {
        //     const tagName = metaTags[i].getAttribute('name');
        //     if(tagName.toLowerCase() == "description") {
        //         const description = metaTags[i].getAttribute('content');
        //         if(description) {
        //         linkPreview = {...linkPreview, description: description};
        //         }
        //     }
        //     }
        // }

        // fetch all images uri from body tag.
        let imageList = [];
        if (bodyTags?.length > 0) {
          const imageTags = bodyTags[0].getElementsByTagName('img');
          if (imageTags && imageTags?.length > 0) {
            for (let i = 0; i < imageTags.length; i++) {
              const srcValue = imageTags[i].getAttribute('src');
              if (
                srcValue &&
                (String(srcValue).endsWith('png') ||
                  String(srcValue).endsWith('jpeg') ||
                  String(srcValue).endsWith('jpg'))
              ) {
                imageList.push(srcValue);
              }
            }
          }
        }

        // get image from meta tags
        if (imageList?.length == 0 && metaTags && metaTags?.length > 0) {
          for (let i = 0; i < metaTags.length; i++) {
            const tagName = metaTags[i].getAttribute('property');
            if (tagName.toLowerCase() == 'og:image') {
              const tagValue = metaTags[i].getAttribute('content');
              if (
                tagValue &&
                (String(tagValue).includes('.png') ||
                  String(tagValue).includes('jpeg') ||
                  String(tagValue).includes('jpg'))
              ) {
                imageList.push(tagValue);
              }
            }
          }
        }

        let uParser = null;
        try {
          uParser = urlParser.parse(url);
        } catch (e) {
          uParser = null;
        }

        // parse url using url npm package.
        if (uParser?.href) {
          // extract name from url.
          let urlName = '';
          let urlHostName = uParser?.hostname || uParser?.host;
          if (urlHostName) {
            const urlNameSplit = urlHostName.split('.');
            if (urlNameSplit?.length > 1) {
              urlName = urlNameSplit[1];
            }
          }

          // pick related images to show on preview card.
          let image = imageList?.filter(x => {
            return x && x.toLowerCase().indexOf(urlName) > -1;
          });

          // pick first image in case domain specific image not found.
          if (!image && imageList?.length > 0) {
            image = [...imageList[0]];
          }

          // set image path.
          if (image?.length > 0 && image[0] != '') {
            if (
              image.length > 0 &&
              image[0]?.toLocaleLowerCase().startsWith('http')
            ) {
              linkPreview = { ...linkPreview, image: `${image[0]}` };
            } else {
              if (
                image.length > 0 &&
                image[0]?.toLocaleLowerCase().startsWith('/')
              ) {
                linkPreview = {
                  ...linkPreview,
                  image: `${uParser?.protocol}//${urlHostName}${image[0]}`,
                };
              } else {
                linkPreview = {
                  ...linkPreview,
                  image: `${uParser?.protocol}//${urlHostName}/${image[0]}`,
                };
              }
            }
          }
        } else {
          /// add custom logic in case URL packge fails to parse.

          // extract name from url.
          let urlName = '';
          if (imageList?.length > 0) {
            const urlSplit = url?.split('/');
            if (urlSplit?.length > 2) {
              const urlNameSplit = urlSplit[2].split('.');
              if (urlNameSplit?.length > 1) {
                urlName = urlNameSplit[1];
              }
            }
          }

          // pick related images to show on preview card.
          const image = imageList?.filter(x => {
            return x && x.toLowerCase().indexOf(urlName) > -1;
          });

          // set image path.
          if (image?.length > 0 && image[0] != '') {
            const urlSplit = url?.split('/');
            let urlData = url;
            if (urlSplit?.length > 2) {
              const urlNameSplit = urlSplit[2].split('.');
              if (urlNameSplit?.length > 1) {
                urlData = `${urlNameSplit[0]}.${urlNameSplit[1]}.${urlNameSplit[2]}`;
              }
            }
            if (
              image.length > 0 &&
              image[0]?.toLocaleLowerCase().startsWith('http')
            ) {
              linkPreview = { ...linkPreview, image: `${image}` };
            } else {
              if (
                image.length > 0 &&
                image[0]?.toLocaleLowerCase().startsWith('/')
              ) {
                linkPreview = {
                  ...linkPreview,
                  image: `${urlSplit[0]}//${urlData}${image}`,
                };
              } else {
                linkPreview = {
                  ...linkPreview,
                  image: `${urlSplit[0]}//${urlData}/${image}`,
                };
              }
            }
          }
        }

        if (linkPreview?.title) {
          setLinkPreview(linkPreview);
          urlPreviewAvailable(true);
        }
      } else {
        urlPreviewAvailable(false);
      }
    } catch (e) {
      urlPreviewAvailable(false);
      LogCustomError('LinkPreview | readURL', e.name, e.message, e.stack);
    }
  };

  useEffect(() => {
    readURL();
  }, []);

  const launchURL = async () => {
    if (url) {
      await Linking.openURL(url);
    }
  };

  const getImageURI = imageUrl => {
    if (!imageUrl) {
      imageUrl =
        getIPNSLink(Constants.expoConfig.extra.APP_CONFIGURATION_IPNS_KEY) +
        '/' +
        'no-image.png';
    }
    return imageUrl;
  };

  const urlPreviewAvailable = val => {
    if (hasURLPreview) {
      hasURLPreview(val);
    }
  };

  if (smallPreviewCard) {
    return (
      <>
        {linkPreview?.title && (
          <Pressable
            onPress={() => {
              launchURL();
            }}>
            <View style={styles.urlSmallPreviewMainContainer}>
              {linkPreview?.image && (
                <View style={styles.urlSmallPreviewImageContainer}>
                  <Image
                    source={{ uri: getImageURI(linkPreview?.image) }}
                    style={{ height: '100%', width: '100%' }}
                    resizeMode="cover"
                  />
                </View>
              )}
              <View style={styles.urlSmallPreviewTitleContainer}>
                <Text style={styles.urlPreviewTitleTextStyle}>
                  {linkPreview?.title}
                </Text>
              </View>
            </View>
          </Pressable>
        )}
      </>
    );
  } else {
    return (
      <>
        {linkPreview?.title ? (
          <Pressable
            style={styles.urlPreviewMainContainer}
            onPress={() => {
              launchURL();
            }}>
            <View style={styles.urlPreviewTextContainer}>
              <Text style={[styles.urlPreviewMessageTextStyle]}>{message}</Text>
            </View>
            {linkPreview?.image ? (
              <View style={styles.urlPreviewImageContainer}>
                <Image
                  source={{ uri: getImageURI(linkPreview?.image) }}
                  style={{ height: '100%', width: '100%' }}
                  resizeMode="cover"
                />
              </View>
            ) : (
              <View
                style={{
                  ...styles.urlPreviewImageContainer,
                  backgroundColor: 'rgba(0,0,0,.4)',
                }}
              />
            )}
            <View style={styles.urlPreviewTitleContainer}>
              <Text style={styles.urlPreviewTitleTextStyle}>
                {linkPreview?.title}
              </Text>
            </View>
          </Pressable>
        ) : (
          <>{children}</>
        )}
      </>
    );
  }
};

export default URLPreview;
