import {
  Linking,
  Pressable,
  StyleSheet,
  Text,
  View,
  Image,
} from 'react-native';
import React, { useEffect, useState } from 'react';
import ModalComponent from '.';
import { Avatar } from 'react-native-paper';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import { IUser } from '../../interfaces/IUser.interface';
import ProfileAvatar from '../ProfileAvatar';
import { getThirdPartyVerifiedURL } from '../../slices/AppUserSlice';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { identifySocialWebsiteFromURL } from '../../helpers/Gadgets';
import facebookIcon from '../../assets/socialicons/facebook.png';
import twitterIcon from '../../assets/socialicons/twitter.png';
import tiktokIcon from '../../assets/socialicons/tiktok.png';
import instagramIcon from '../../assets/socialicons/instagram.png';
import verifiedIcoun from '../../assets/verified_account.png';
import { SOCIAL_WEBSITE } from '../../enums/socialWebsite.enum';
import { SocialLinkType } from '../../enums/passportCategory.enum';
import ImagePreviewModal from './ImagePreviewModal';

type userDetailProps = {
  user: IUser;
  isVisible: boolean;
  onDismiss: () => void;
};

const SocialLinksColors = link => {
  switch (link) {
    case SocialLinkType.FACEBOOK:
      return '#3A559F';
    case SocialLinkType.TWITTER:
      return '#03A9F4';
    case SocialLinkType.INSTAGRAM:
      return '#3A559F';
    case SocialLinkType.TIKTOK:
      return '#000000';
    default:
      return '#FFFFFF';
  }
};

const UserDetailModal = ({ user, isVisible, onDismiss }: userDetailProps) => {
  const { networkId, staticProvider, address, wallet, userInfo } =
    useWeb3AuthContext();
  const [thirdPartyVerifiedURL, setThirdPartyVerifiedURL] = useState([]);

  const [isVisibleImagePreviewModal, setIsVisibleImagePreviewModal] =
    useState(false);
  const [imagePreviewSource, setImagePreviewSource] = useState(null);

  const onImagePreview = item => {
    setImagePreviewSource(item);
    setIsVisibleImagePreviewModal(true);
  };

  const pullThirdPartyVerifiedURL = async () => {
    if (user?.wallet) {
      const thirdPartyVerifiedURL: any = await getThirdPartyVerifiedURL({
        networkID: networkId,
        provider: staticProvider,
        address: user?.wallet,
      });
      if (thirdPartyVerifiedURL?.length > 0) {
        let socialURLs = [];
        thirdPartyVerifiedURL?.map(url => {
          const account = socailProfile(url);
          if (account) {
            socialURLs.push({
              socialIcon: account?.socialIcon,
              title: account?.title,
              color: '#FFFFFF',
              url: url,
            });
          }
        });
        setThirdPartyVerifiedURL(socialURLs);
      } else {
        setThirdPartyVerifiedURL([]);
      }
    }
  };
  useEffect(() => {
    pullThirdPartyVerifiedURL();
  }, []);

  const socailProfile = url => {
    const socialWebsite = identifySocialWebsiteFromURL(url);
    let socialIcon = verifiedIcoun;
    let title = '';
    switch (socialWebsite) {
      case SOCIAL_WEBSITE.FACEBOOK:
        socialIcon = facebookIcon;
        title = SOCIAL_WEBSITE.FACEBOOK;
        break;
      case SOCIAL_WEBSITE.INSTAGRAM:
        socialIcon = instagramIcon;
        title = SOCIAL_WEBSITE.INSTAGRAM;
        break;
      case SOCIAL_WEBSITE.TIKTOK:
        socialIcon = tiktokIcon;
        title = SOCIAL_WEBSITE.TIKTOK;
        break;
      case SOCIAL_WEBSITE.TWITTER:
        socialIcon = twitterIcon;
        title = SOCIAL_WEBSITE.TWITTER;
        break;
    }
    return {
      socialIcon: socialIcon,
      title: title,
    };
  };

  return (
    <ModalComponent
      showModal={isVisible}
      needCloseButton={false}
      enableHeader={false}
      modalBodyStyle={{ width: '100%' }}
      onDismiss={() => onDismiss()}>
      <View style={[styles.userInfoModalContainer, { marginBottom: 5 }]}>
        <Pressable
          onPress={() => onImagePreview(user.avatarURI)}
          style={{ alignSelf: 'center', marginBottom: 2 }}>
          <ProfileAvatar
            style={[styles.friendsAvatar, { backgroundColor: '#000' }]}
            size={60}
            source={
              user?.avatarURI &&
              user?.avatarURI != '' &&
              user?.avatarURI != 'no-avatar' &&
              !user?.avatarURI.includes('ipfs://') &&
              !user?.avatarURI.includes('assets_avatars_p_') &&
              !user?.avatarURI.includes('file:///')
                ? { uri: user?.avatarURI }
                : null
            }
            userAddress={user?.wallet}
          />
        </Pressable>
        {user?.name && (
          <Text
            style={[
              [styles.NameUserInfoModal, { marginBottom: 3 }],
            ]}>{`${user?.name} #${user?.id}`}</Text>
        )}
        {thirdPartyVerifiedURL?.length > 0 && (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginBottom: 5,
            }}>
            {thirdPartyVerifiedURL?.map((url, index) => {
              return (
                <Pressable
                  key={index}
                  onPress={() => Linking.openURL(url?.url)}
                  style={{
                    paddingRight: 2,
                    marginBottom: 5,
                    alignItems: 'center',
                  }}>
                  <View style={{ width: 20, height: 20, paddingRight: 2 }}>
                    <Image
                      source={url?.socialIcon}
                      resizeMode="contain"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </View>
                  <Text
                    style={{
                      fontFamily: defaultTheme.FONT_FAMILY_MAIN,
                      fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
                      color: url?.color
                        ? url?.color
                        : defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
                      textTransform: 'uppercase',
                    }}>
                    {url?.title}
                  </Text>
                </Pressable>
              );
            })}
          </View>
        )}
        {user?.status && (
          <Text style={[styles.StatusUserInfoModal]}>{user?.status}</Text>
        )}
        {
          <ImagePreviewModal
            onDismiss={() => {
              setIsVisibleImagePreviewModal(false);
            }}
            isVisible={isVisibleImagePreviewModal}
            imagePreviewSource={imagePreviewSource}
          />
        }
      </View>
    </ModalComponent>
  );
};

export default UserDetailModal;
