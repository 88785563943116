import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import GradientButton from '../GradientButton';
import { defaultTheme } from '../../themes/loot8';
import useIsResponsive from '../../hooks/useIsResponsive';

interface Props {
  heading: string;
  text: string;
  height?: number;
  primaryBtnLabel: string;
  secondaryBtnLabel?: string;
  primaryBtnPress: any;
  secondaryBtnPress?: any;
}

const HorizontalCard: React.FC<Props> = ({
  heading,
  text,
  height = 76,
  primaryBtnLabel,
  primaryBtnPress,
  secondaryBtnLabel,
  secondaryBtnPress,
}) => {
  let isResponsive = useIsResponsive();
  return (
    <View
      style={[
        styles.container,
        { width: isResponsive ? '95%' : '100%' },
        height && { height: height },
      ]}>
      <View style={styles.cardBody}>
        <Text
          style={{
            fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
            color: defaultTheme.PRIMARY_TEXT_COLOR,
            fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
          }}>
          {heading}
        </Text>
        {text && (
          <Text
            style={{
              fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
              color: defaultTheme.PRIMARY_TEXT_COLOR,
              fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
            }}>
            {text}
          </Text>
        )}
      </View>
      <View style={styles.cardButtonsContainer}>
        {secondaryBtnLabel && (
          <GradientButton
            onPress={secondaryBtnPress ? secondaryBtnPress : () => {}}
            buttonLabel={secondaryBtnLabel}
            type="secondary"
            fontSize={12}
            customStyle={styles.customBtnStyle}
          />
        )}
        <GradientButton
          onPress={primaryBtnPress ? primaryBtnPress : () => {}}
          buttonLabel={primaryBtnLabel}
          fontSize={12}
          fontFamily={defaultTheme.FONT_FAMILY_MEDIUM}
          customStyle={styles.customBtnStyle}
        />
      </View>
    </View>
  );
};

export default HorizontalCard;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    padding: 10,
    paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    columnGap: 4,
  },
  cardBody: {
    flex: 1,
  },
  cardButtonsContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    columnGap: 4,
    flex: 1,
  },
  customBtnStyle: {
    width: 84,
    height: 46,
  },
});
