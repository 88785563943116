import React from 'react';
import { ActivityIndicator, StyleSheet, Text } from 'react-native';
import { defaultTheme } from '../../themes/loot8';
import { TouchableRipple } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';

interface FollowUnFollowButtonProps {
  onPress: () => void;
  width?: number;
  status: boolean;
  loading?: boolean;
}

const FollowUnFollowButton: React.FC<FollowUnFollowButtonProps> = ({
  onPress,
  width,
  status,
  loading = false,
}) => {
  return (
    <LinearGradient
      colors={
        status
          ? [defaultTheme.SECONDARY_COLOR, defaultTheme.SECONDARY_COLOR]
          : [defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]
      }
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={[styles.container, width ? { width: width } : { flex: 1 }]}>
      <TouchableRipple
        style={styles.ripple}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={loading ? () => {} : onPress}>
        {loading ? (
          <ActivityIndicator
            size={'small'}
            color={defaultTheme.PRIMARY_TEXT_COLOR}
          />
        ) : (
          <Text style={styles.text}>{status ? 'Unfollow' : 'Follow'}</Text>
        )}
      </TouchableRipple>
    </LinearGradient>
  );
};

export default FollowUnFollowButton;

const styles = StyleSheet.create({
  container: {
    borderRadius: defaultTheme.BUTTON_RADIUS,
    height: 36,

    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textTransform: 'capitalize',
  },
});
