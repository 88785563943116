import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { useAppSelector } from '../hooks';
import { ScreenName } from '../enums/screen.enum';
import LogoutScreen from '../screens/LogoutScreen';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import EstPortalAuth from '../EstPortal/screens/EstPortalAuth';
import AnimatedLoading from '../components/Loader/animatedLoading';
import EstPortalCreateProfile from '../EstPortal/screens/EstPortalCreateProfile';
import EstPortalDashboard from '../EstPortal/screens/EstPortalDashboard';
import EstPortalRequestCode from '../EstPortal/screens/EstPortalRequestCode';
import EstPortalSuccessScreen from '../EstPortal/screens/EstPortalSuccessScreen';
import EstOnboardingComponent from '../components/EstOnboardingComponent';

const Stack = createStackNavigator();

function EstPortalNavigator() {
  const { userInfo } = useWeb3AuthContext();
  const { isOnboarded } = userInfo;

  const passports = useAppSelector(
    state => state.EstPortalPassports.AllEntityPassportDetails,
  );

  const isFreeProfileCreated =
    passports?.some(passport => passport.isActive && !passport.isPremium) ??
    false;

  const passportsLoading = useAppSelector(
    state => state.EstPortalPassports.loading,
  );

  if (passportsLoading && !isFreeProfileCreated) {
    return <AnimatedLoading />;
  }

  const getNavigationScreens = () => {
    if (isOnboarded) {
      if (isFreeProfileCreated) {
        return (
          <>
            <Stack.Screen
              name="EstPortalDashboard"
              component={EstPortalDashboard}
            />
            <Stack.Screen
              name={ScreenName.LOGOUT_SCREEN}
              component={LogoutScreen}
            />
          </>
        );
      }
      return (
        <Stack.Screen
          name="EstPortalCreateProfile"
          component={EstPortalCreateProfile}
        />
      );
    }

    return (
      <>
        <Stack.Screen name="EstPortalAuth" component={EstPortalAuth} />
        <Stack.Screen
          name="EstPortalRequestCode"
          component={EstPortalRequestCode}
        />
      </>
    );
  };

  return (
    <>
      <Stack.Navigator
        initialRouteName="EstPortalAuth"
        screenOptions={{
          headerShown: false,
          cardStyle: {
            flex: 1,
          },
        }}>
        {getNavigationScreens()}
        <Stack.Screen
          name="EstPortalSuccessScreen"
          component={EstPortalSuccessScreen}
        />
      </Stack.Navigator>
      <EstOnboardingComponent />
    </>
  );
}

export default EstPortalNavigator;
