import React, { useRef } from 'react';
import { Platform, View } from 'react-native';
import { BlurView } from 'expo-blur';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
} from 'react-native-actions-sheet';
import { LinearGradient } from 'expo-linear-gradient';

import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import { ReShareWebBlurView } from '../WebBlurView';
import SortModalSheetContent from '../SortModalSheetContent';

interface SortModalSheetProps {
  sheetId: SheetProps['sheetId'];
  payload: {
    sortingMenuOptions: [];
    selectedSortingOption: number;
    setSelectedSortingOption: () => {};
  };
}

const SortModalSheet = (props: SortModalSheetProps) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);
  // const setSelectedSortingOption = props?.payload?.setSelectedSortingOption;
  const setSelectedSortingOption = props?.payload?.setSelectedSortingOption
    ? props.payload?.setSelectedSortingOption
    : () => {};

  const selectedSortingOption = props?.payload?.selectedSortingOption;

  const sortingMenuOptions = props?.payload?.sortingMenuOptions;

  return (
    <ActionSheet
      id={props?.sheetId}
      ref={actionSheetRef}
      drawUnderStatusBar
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      enableGesturesInScrollView={false}>
      {Platform.OS === 'web' ? (
        <ReShareWebBlurView>
          <View
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <SortModalSheetContent
                sortingMenuOptions={sortingMenuOptions}
                setSelectedSortingOption={setSelectedSortingOption}
                selectedSortingOption={selectedSortingOption}
                hideModal={() => actionSheetRef?.current?.hide()}
              />
            </LinearGradient>
          </View>
        </ReShareWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={{
            borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <SortModalSheetContent
              sortingMenuOptions={sortingMenuOptions}
              setSelectedSortingOption={setSelectedSortingOption}
              selectedSortingOption={selectedSortingOption}
              hideModal={() => actionSheetRef?.current?.hide()}
            />
          </LinearGradient>
        </BlurView>
      )}
    </ActionSheet>
  );
};

export default SortModalSheet;
