import { gql } from 'urql';

export const usersDataQuery = gql`
  query ($page: Int, $pageSize: Int) {
    users(page: $page, pageSize: $pageSize) {
      records {
        wallet
        userId
        name
        dataURI
        avatarURI
      }
      count
    }
  }
`;

export const userSubscriptionsQuery = gql`
  query ($user: String!, $collection: String!) {
    subscriptionManagerTradeEvents(trader: $user, passport: $collection) {
      count
      records {
        transactionHash
        # user: trader
        # collection: passport
        collectionAmount
        price
        isBuy
        transactionIndex
        blockNumber
      }
    }
  }
`;
