import React, { useEffect } from 'react';
import { View, Text, Platform } from 'react-native';

import styles from '../../styles';
import ModalComponent from '../Modal';
import GradientButton from '../GradientButton';
import { defaultTheme } from '../../themes/loot8';
import { DeleteUser } from '../../slices/AppUserSlice';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { useDispatch } from 'react-redux';
import { ScreenName } from '../../enums/screen.enum';
import { useNavigation } from '@react-navigation/native';
import { useAppSelector } from '../../hooks';

const DeleteAccountConfirmationModal = ({
  showModal,
  onDismiss,
  onDeleteAccount,
  longMessage = true,
}) => {
  const isWeb = Platform.OS === 'web';
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { networkId, staticProvider, address, wallet, disconnect } =
    useWeb3AuthContext();
  const [loading, setLoading] = React.useState(false);
  const { currentFriends, loadingFriendsData } = useAppSelector(
    state => state.friends,
  );

  const deactivateAccount = async () => {
    setLoading(true);
  };

  const deleteAccountAndNavigate = async () => {
    onDeleteAccount();
    await dispatch(
      DeleteUser({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        userFriends: currentFriends,
      }),
    );
    if (
      isWeb &&
      new URLSearchParams(window.location.search).get('user') === 'deactivate'
    ) {
      window.history.replaceState(null, '', '/');
    }
    navigation.navigate(ScreenName.LOGOUT_SCREEN);
  };

  useEffect(() => {
    if (loading && !loadingFriendsData) {
      deleteAccountAndNavigate();
      setLoading(false);
    }
  }, [loadingFriendsData, currentFriends, loading]);
  return (
    <ModalComponent
      showModal={showModal}
      showLoading={true}
      modalStyle={{ justifyContent: 'center' }}
      enableHeader={false}
      enableHeaderhairline={false}
      onDismiss={onDismiss}
      dismissable={false}>
      <View style={{ margin: 10 }}>
        <Text
          style={{
            ...styles.deleteAccountText,
            fontSize: defaultTheme.FONT_SIZE_XSMALL,
            fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
            marginTop: 20,
          }}>
          Delete Account
        </Text>
        <View
          style={{
            marginTop: 24,
          }}>
          {longMessage && (
            <Text style={styles.deleteAccountText}>
              By deleting your account you will delete all your assets, profile,
              and messages!
            </Text>
          )}
          <Text
            style={{
              ...styles.deleteAccountText,
              marginTop: 16,
              fontFamily: defaultTheme.FONT_FAMILY_BOLD,
            }}>
            Are you sure you want to delete your LOOT8 Account?
          </Text>
        </View>
      </View>
      <View style={[styles.modalButtonContainer, { marginVertical: 24 }]}>
        <GradientButton
          width={'45%'}
          type="secondary"
          buttonLabel={'Delete'}
          onPress={deactivateAccount}
          disabled={loading}
          loading={loading}
        />
        <GradientButton
          width={'45%'}
          buttonLabel={'Cancel'}
          onPress={onDismiss}
          type="alternate"
          disabled={loading}
        />
      </View>
    </ModalComponent>
  );
};

export default DeleteAccountConfirmationModal;