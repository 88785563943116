import React from 'react';
import { Text, View, Image, StyleSheet, DimensionValue } from 'react-native';

import { defaultTheme } from '../../themes/loot8';
import tokenLogo from '../../assets/empty_list_icon.png';

interface IEmptyList {
  showImage?: boolean;
  message?: string;
  secondaryMessage?: string;
  width?: DimensionValue;
}

const EmptyList: React.FC<IEmptyList> = ({
  showImage = true,
  message = 'No items found',
  secondaryMessage,
  width = '60%',
}) => (
  <View style={{ ...styles.container, width }}>
    {showImage && (
      <Image
        source={tokenLogo}
        style={{
          width: 42,
          height: 42,
          marginBottom: 10,
        }}
        resizeMode="contain"
      />
    )}
    <Text style={styles.text}>{message}</Text>
    {secondaryMessage && (
      <Text style={[styles.text, { marginTop: 8 }]}>{secondaryMessage}</Text>
    )}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: '#FFF',
    marginTop: 4,
    letterSpacing: 0,
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
});

export default EmptyList;
