import {
  Platform,
  View,
  Image,
  useWindowDimensions,
  Pressable,
  SafeAreaView,
} from 'react-native';
import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import { defaultTheme } from '../../themes/loot8';
import Modal from 'react-native-modal';
import styles from '../../styles';

const ImagePreviewModal = ({
  onDismiss,
  isVisible,
  imagePreviewSource,
  theme = 'rgba(0, 0, 0, 0.5)',
}) => {
  const windowDimensions = useWindowDimensions();

  return (
    <Modal
      isVisible={isVisible}
      onBackdropPress={() => isVisible && onDismiss()}
      onDismiss={onDismiss}
      style={{ flex: 1 }}
      animationIn="zoomInDown"
      animationInTiming={300}
      hasBackdrop={true}
      backdropColor={theme}
      hideModalContentWhileAnimating={true}
      deviceHeight={Platform.OS === 'web' ? windowDimensions.height : null}
      deviceWidth={Platform.OS === 'web' ? windowDimensions.width : null}>
      <SafeAreaView
        style={{
          ...styles.imagePreviewMainContainer,
          height:
            Platform.OS === 'web' && windowDimensions.width > 480
              ? windowDimensions.height - 140
              : windowDimensions.height,
          width: windowDimensions.width,
        }}>
        <View
          style={{
            ...styles.imagePreviewHeaderContainer,
          }}>
          <Pressable
            onPress={() => onDismiss()}
            style={{
              ...styles.headerBackButton,
              width: 40,
              height: 40,
            }}>
            <Ionicons name="arrow-back" size={30} color={'#fff'} />
          </Pressable>
        </View>
        <View style={{ paddingBottom: 20, height: '90%', width: '100%' }}>
          <Image
            source={{ uri: imagePreviewSource }}
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: '#111',
            }}
            resizeMode="contain"
          />
        </View>
      </SafeAreaView>
    </Modal>
  );
};

export default ImagePreviewModal;
