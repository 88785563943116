import React from 'react';
import { Text, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { defaultTheme } from '../../themes/loot8';
import { TouchableRipple } from 'react-native-paper';
import { gradientBoxStyles } from './styles';

interface Props {
  title: string;
  height: number;
  onPress: any;
  active?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}
const GradientBoxAnimated: React.FC<Props> = ({
  title,
  onPress,
  height,
  active,
  disabled,
  fullWidth = true,
}) => {
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR2, defaultTheme.GRADIENT_COLOR1]}
      end={{ x: 0.0001, y: 0.2 }}
      start={{ x: 0.00001, y: 0.8 }}
      style={[
        gradientBoxStyles.container,
        { height: height ? height : 82 },
        !fullWidth ? {} : { flex: 0.48 },
      ]}>
      <View style={gradientBoxStyles.rippleWrapper}>
        <TouchableRipple
          disabled={disabled}
          style={[
            !active && { backgroundColor: defaultTheme.CARD_BG_COLOR },
            gradientBoxStyles.contentContainer,
          ]}
          rippleColor={'rgba(0,0,0,0.25)'}
          onPress={onPress}>
          <View style={gradientBoxStyles.tabItem}>
            <Text
              style={[
                gradientBoxStyles.tabText,
                disabled && { color: 'rgba(255, 255, 255, 0.5)' },
              ]}>
              {title}
            </Text>
          </View>
        </TouchableRipple>
      </View>
    </LinearGradient>
  );
};

export default GradientBoxAnimated;
