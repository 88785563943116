import React, { useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { Button, Headline, Modal, TextInput } from 'react-native-paper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { setUserStatus } from '../../slices/AppUserSlice';
import AppLoaderComponent from '../Loader';
import ModalComponent from '../Modal';

type StatusModalComponentProps = {
  showStatusModal: boolean;
  setShowStatusModal: (show: boolean) => void;
};

const UserStatusComponent = ({
  showStatusModal,
  setShowStatusModal,
}: StatusModalComponentProps) => {
  const { networkId, staticProvider, address, wallet } = useWeb3AuthContext();
  const userStatus = useAppSelector(state => state.AppUser.UserData?.status);
  const dispatch = useAppDispatch();

  const [statusText, setUserStatusText] = useState(userStatus);

  const onStatusSave = async () => {
    if (userStatus !== statusText) {
      await dispatch(
        setUserStatus({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
          newStatus: statusText,
        }),
      );
    }
    setShowStatusModal(false);
  };

  return (
    <ModalComponent
      showModal={showStatusModal}
      headerText="Tagline Update"
      needCloseButton={true}
      modalStyle={{ justifyContent: 'flex-start', top: 40, zIndex: 4 }}
      onDismiss={() => {
        setShowStatusModal(false);
      }}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Headline style={{ fontFamily: 'Poppins', color: '#53536E' }}>
          Tagline:
        </Headline>
        <TextInput
          style={{ backgroundColor: 'white', marginLeft: 10, width: '70%' }}
          textColor="black"
          value={statusText}
          onChangeText={text => setUserStatusText(text)}
          mode="outlined"
          activeOutlineColor="#707070"
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginRight: 5,
        }}>
        <Button
          onPress={onStatusSave}
          style={styles.modalYesButtonStyle}
          labelStyle={styles.modalYesButtonLabelStyle}>
          Save
        </Button>
      </View>
    </ModalComponent>
  );
};
const styles = StyleSheet.create({
  modalYesButtonStyle: {
    backgroundColor: '#4D4D6F',
    borderRadius: 5,
    borderColor: '#FFFFFF',
    borderWidth: 1.5,
    borderStyle: 'solid',
    width: 164,
    height: 44,
    marginTop: 10,
    justifyContent: 'center',
  },
  modalYesButtonLabelStyle: {
    color: '#FFF',
    textTransform: 'capitalize',
    fontSize: 20,
    lineHeight: 22,
  },
});

export default UserStatusComponent;
