import React, { useRef } from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { AntDesign, Entypo } from '@expo/vector-icons';
import { defaultTheme } from '../../themes/loot8';
import { CATEGORIES } from '../../EstPortal/enums/offers.enum';

export interface MenuItem {
  id: number;
  name: string;
  value: CATEGORIES;
  icon: React.ComponentType<{ width: number }>;
}

interface AnimatedMenuProps {
  data: MenuItem[];
  selectedCategory: null | MenuItem;
  setSelectedCategory: (item: MenuItem) => void;
}

const AnimatedMenu: React.FC<AnimatedMenuProps> = ({
  data,
  setSelectedCategory,
  selectedCategory,
}) => {
  const animatedHeight = useSharedValue(48);
  const rotateDeg = useSharedValue(0);
  const scrollViewRef = useRef(null);
  const itemPositions = useRef({});
  const reanimatedHeightStyle = useAnimatedStyle(() => {
    return {
      height: animatedHeight.value,
    };
  }, []);
  const iconRotationStyle = useAnimatedStyle(() => {
    return {
      transform: [{ rotate: `${rotateDeg.value}deg` }],
    };
  }, []);

  const toggleHandler = () => {
    if (animatedHeight.value === 48) {
      // open menu
      animatedHeight.value = withSpring(200);
      rotateDeg.value = withSpring(90);
      if (selectedCategory) {
        scrollToItem(selectedCategory.id);
      }
    } else {
      // close menu
      animatedHeight.value = withTiming(48);
      rotateDeg.value = withSpring(0);
    }
  };

  const handleItemPress = (item: MenuItem) => {
    setSelectedCategory(item);
    toggleHandler();
  };
  const handleLayout = (event, id) => {
    const { y } = event.nativeEvent.layout;
    itemPositions.current[id] = y;
  };
  const scrollToItem = id => {
    if (itemPositions.current[id] !== undefined) {
      scrollViewRef.current.scrollTo({
        y: itemPositions.current[id],
        animated: true,
      });
    }
  };
  return (
    <Animated.View style={[reanimatedHeightStyle, styles.container]}>
      <TouchableOpacity
        onPress={toggleHandler}
        style={[
          styles.row,
          {
            borderBottomWidth: 1,
            borderBottomColor: defaultTheme.SECONDARY_COLOR,
          },
        ]}>
        <View style={styles.row}>
          {selectedCategory && <selectedCategory.icon width={18} />}
          <Text
            style={selectedCategory ? styles.itemText : styles.placeholderText}>
            {selectedCategory ? selectedCategory.name : 'Choose Category'}
          </Text>
        </View>
        <Animated.View style={iconRotationStyle}>
          <Entypo
            name="chevron-small-right"
            size={24}
            color={defaultTheme.PRIMARY_TEXT_COLOR}
          />
        </Animated.View>
      </TouchableOpacity>
      <ScrollView
        ref={scrollViewRef}
        style={styles.itemContainer}
        showsVerticalScrollIndicator={false}>
        {data.map((x, i) => (
          <TouchableOpacity
            onLayout={event => handleLayout(event, x.id)}
            onPress={() => handleItemPress(x)}
            style={styles.row}
            key={i}>
            <View style={styles.row}>
              <x.icon width={18} />
              <Text style={styles.itemText}>{x.name}</Text>
            </View>
            {selectedCategory?.id === x.id && (
              <AntDesign
                name="checkcircle"
                size={14}
                color={defaultTheme.PRIMARY_TEXT_COLOR}
              />
            )}
          </TouchableOpacity>
        ))}
      </ScrollView>
    </Animated.View>
  );
};

export default AnimatedMenu;

const styles = StyleSheet.create({
  container: {
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    paddingHorizontal: 10,
    overflow: 'hidden',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
  },
  placeholderText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  itemText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    marginLeft: 10,
  },
  itemContainer: {},
});
