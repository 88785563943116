import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import CustomHeader from './AnimatedHeader/CustomHeader';
import SuccessScreen from '../screens/SuccessScreen';

const Stack = createStackNavigator();

function SuccessScreenNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="SuccessScreen"
      screenOptions={{
        headerShown: true,
        headerStyle: {
          borderBottomWidth: 0,
          backgroundColor: 'transparent',
          height: Platform.OS === 'ios' ? 120 : 70,
        },
        headerMode: 'float',
        header: props => <CustomHeader {...props} />,
      }}>
      <Stack.Screen name="SuccessScreen" component={SuccessScreen} />
    </Stack.Navigator>
  );
}

export default SuccessScreenNavigator;
