import React, { useCallback, useEffect, useState } from 'react';
import { Linking, Platform, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import NotificationComponent from '../components/NotificationComponent';
import UserStatusComponent from '../components/UserStatus';
import { useWeb3AuthContext } from '../hooks/web3authContext';
// import signup from '../assets/signup.png';
import { useDispatch } from 'react-redux';
import {
  APPSTORE_URL,
  APP_STORAGE_USER_KEY,
  getAppConfiguration,
  PLAYSTORE_URL,
  refreshURLs,
  APP_CONFIGURATION_FILE,
  getMaintenanceInfo,
  TWITCH_LOGIN_VERIFIER_NAME,
  TWITCH_CLIENT_ID,
  ensureDirExists,
} from '../appconstants';
import { clearAllData, getData, removeData } from '../helpers/AppStorage';
import { useAppSelector } from '../hooks';
import {
  CreateUserDetail,
  getAllUsersData,
  loadUserDetail,
} from '../slices/AppUserSlice';
import { mintThirdPartyCollectibles } from '../slices/ThirdPartyCollectiblesSlice';
import store, { AppDispatch } from '../store';
import { throttle } from 'lodash';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { NavigationContainer } from '@react-navigation/native';
import { LOGIN_PROVIDER } from '@web3auth/react-native-sdk';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import { DeviceType } from 'expo-device';
import * as Location from 'expo-location';
import AppPromptComponent from '../components/AppPromptComponent';
import {
  CheckMintedTokenError,
  isTokenAlreadyMinted,
} from '../components/BarcodeReader';
import AnimatedLoading from '../components/Loader/animatedLoading';
import { LogCustomError } from '../helpers/AppLogger';
import { clearCatalog, getTruncatedName } from '../helpers/Gadgets';
import { getIPNSData } from '../helpers/ipfs';
import {
  getAppState,
  loadAvatarData,
  logoutUserActions,
  setNativeNotificationPermission,
} from '../slices/AppSlice';
import { loadLocation } from '../slices/LocationSlice';
import { getUnsubsribedNotifications } from '../slices/NotificationSlice';
import {
  clearPassportMintingQueue,
  refreshDataCache,
} from '../slices/PassportSlice';
import { defaultTheme } from '../themes/loot8';
import { clearCollectibleMintingQueue } from '../slices/DigitalCollectibleSlice';
import { getThirdPartyCollectiableDetails } from '../slices/OfferSlice';
import Maintenance from '../components/Loader/maintenance';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import AppUpdateComponent from '../components/AppUpdate';
import {
  dismissNotification,
  getAppVersionFromAppStore,
  getAppVersionFromPlayStore,
  getScaledAppVersion,
} from '../slices/helpers';
import { getSyncedTime } from '../helpers/DateHelper';
import * as Notifications from 'expo-notifications';
import * as LinkingExpo from 'expo-linking';
import { registerForPushNotificationsAsync } from '../App';
import * as TaskManager from 'expo-task-manager';
import {
  addLocalNotification,
  getPrivateMsgPendingRequestForUser,
  loadLastMessageDetailsForMutualFriends,
} from '../slices/PrivateMessageSlice';
import AppBackgroundRefreshComponent from '../components/AppBackgroundRefresh';
import * as BackgroundFetch from 'expo-background-fetch';
import { initializeDataObjects } from '../data';
import { SheetProvider } from 'react-native-actions-sheet';
import './../helpers/sheets.tsx';
import { loadFriendsData } from '../slices/friendsSlice';
import AuthComponent from '../components/AuthComponent';
import RootNavigator from '../navigators/RootNavigator';
import {
  checkSuperAdmin,
  getEntityState,
  loadAllDetails,
  loadEntityDetails,
  setSuperAdmin,
} from '../EstPortal/slices/EntitySlice';

let refreshCacheExecutionStarted = false;

export const loadAppOrRedirectToPlaystore = setNavChoice => {
  let now = new Date().valueOf();
  const win: Window = window;

  if (win) {
    setTimeout(function () {
      if (new Date().valueOf() - now > 2000) return;
      win.location = Device.osName == 'Android' ? PLAYSTORE_URL : APPSTORE_URL;
    }, 25);

    win.location = 'loot8auth://';
    setNavChoice('app');
  }
};

const handleNewNotification = async notificationData => {
  try {
    if (Platform.OS === 'ios') {
      const existingBadgeCount = await Notifications.getBadgeCountAsync();
      const nextBadgeCount = existingBadgeCount ? existingBadgeCount + 1 : 1;
      await Notifications.setBadgeCountAsync(nextBadgeCount);
    }
  } catch (error) {
    LogToLoot8Console(error);
  }
};

const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK';

TaskManager.defineTask(
  BACKGROUND_NOTIFICATION_TASK,
  ({ data, error, executionInfo }) => {
    handleNewNotification(data);
  },
);

function LandingScreen() {
  const {
    connected,
    connect,
    disconnect,
    networkId,
    staticProvider,
    address,
    wallet,
    userInfo,
    loginInProgress,
    setLoginInProgress,
    setQRShimFlow,
    key,
    setIsWhitelistedWallet,
    decryptMessage,
  } = useWeb3AuthContext();
  const dispatch = useDispatch<AppDispatch>();
  const [showStatusModal, setShowStatusModal] = React.useState(false);
  const [navChoice, setNavChoice] = React.useState('');
  const [isThinClient, setIsThinClient] = React.useState(false);

  useEffect(() => {
    Device.getDeviceTypeAsync().then(deviceType => {
      if (deviceType == DeviceType.PHONE || deviceType == DeviceType.TABLET) {
        setIsThinClient(true);
      }
    });
  }, []);

  const [showAppPromptComponent, setShowAppPromptComponent] =
    React.useState(true);
  const [showMintingErrorMessage, setShowMintingErrorMessage] =
    React.useState(false);
  const [redeemedQRScanned, setRedeemedQRScanned] = React.useState(false);
  const [mintSuccess, setMintSuccess] = React.useState(false);
  const [isRedirectingToApp, setIsRedirectingToApp] = React.useState(false);
  const [mintingInProgress, setMintingInProgress] = React.useState(false);
  const { UserData: userData, AllUsersData } = useAppSelector(
    state => state.AppUser,
  );
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const [isQRShimFlow, setIsQRShimFlow] = React.useState(false);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [inMaintenanceMode, setInMaintenanceMode] = React.useState(false);
  const [maintenanceText, setMaintenanceText] = React.useState('');
  const [showUpdateAppModal, setShowUpdateAppModal] = React.useState(false);
  const [forceAppUpdateRequired, setForceAppUpdateRequired] =
    React.useState(false);
  const [latestAvailableVersion, setLatestAvailableVersion] =
    React.useState('');
  const [showBackgroundRefreshAppModal, setShowBackgroundRefreshAppModal] =
    React.useState(false);
  const [signupLoader, setSignupLoader] = useState(false);
  const loadingPassport = useAppSelector(state => state.Passports.loading);
  // User data states
  const [avatarUri, setSelectedImage] = useState(
    userData?.isExist
      ? userData?.avatarURI
      : userInfo
      ? userInfo.profileImage
      : '',
  );
  const [statusText, setUserStatusText] = useState(
    userData?.isExist ? userData?.status : '',
  );
  const [nameText, setUserNameText] = useState(
    userData?.isExist
      ? getTruncatedName(userData?.name)
      : userInfo
      ? getTruncatedName(userInfo.name)
      : '',
  );
  const { notifications, showNotificationModal } = useAppSelector(
    state => state.notification,
  );
  const { currentFriends: friendsData, loadingFriendsData } = useAppSelector(
    state => state.friends,
  );

  const setQRShim = async () => {
    let qrshimFlag = await getData('QRShimFlow');
    if (qrshimFlag) {
      setIsQRShimFlow(true);
      setShowLoginModal(true);
      setQRShimFlow(true);
    }
    await removeData('QRShimFlow');
  };

  setQRShim();

  const [loginEmail, setLoginEmail] = React.useState('');
  const forceLogoutUser = useAppSelector(state => state.App.forceLogoutUser);
  const avatarData = useAppSelector(state => state.App.avatarData);

  useEffect(() => {
    if (Platform.OS === 'web') {
      history.pushState(null, document.title, window.location.href);
      // history.back();
      window.onpopstate = () =>
        history.pushState(null, document.title, window.location.href);
    }
  }, []);

  useEffect(() => {
    const isSessionExist = async () => {
      let userExist = await getData(APP_STORAGE_USER_KEY);
      if (userExist) {
        await connect(null, null, null);
      }
    };
    isSessionExist();
  }, []);

  const setLocation = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== 'granted') {
      LogToLoot8Console('Permission to access location was denied');
      return;
    }

    try {
      let location = await Location.getLastKnownPositionAsync();
      if (location == null) {
        location = await Location.getCurrentPositionAsync();
      }

      if (location?.coords) {
        store.dispatch(
          loadLocation({
            latitude: location?.coords?.latitude,
            longitude: location?.coords?.longitude,
          }),
        );
      }
    } catch (e) {
      LogToLoot8Console('Error while trying to get location: ', e);
      LogCustomError(
        'setLocation: Error while trying to get location',
        e.name,
        e.message,
        e.stack,
      );
    }
    // set timer
    setTimeout(() => {
      setLocation();
    }, 30 * 1000);
  };

  useEffect(() => {
    // ask user location in case its not provided at signin screen.
    setTimeout(() => {
      setLocation();
    }, 500);
  }, []);

  useEffect(() => {
    // register task to run whenever is received while the app is in the background
    if (Platform.OS === 'ios' && Device.isDevice) {
      Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK);
    }

    // add in-app notification.
    const addInAppNotification = async receivedNotification => {
      if (receivedNotification) {
        await dispatch(
          addLocalNotification({
            networkID: networkId,
            wallet: wallet,
            notification: receivedNotification,
          }),
        );
      }
    };

    // listener triggered whenever a notification is received while the app is in the foreground
    const foregroundReceivedNotificationSubscription =
      Notifications.addNotificationReceivedListener(notification => {
        handleNewNotification(notification);
        if (notification) {
          addInAppNotification(notification);
        }
      });

    return () => {
      // cleanup the listener and task registry
      foregroundReceivedNotificationSubscription.remove();
      if (Platform.OS === 'ios' && Device.isDevice) {
        Notifications.unregisterTaskAsync(BACKGROUND_NOTIFICATION_TASK);
      }
    };
  }, []);

  // Effect to redirect on successful minting of 3rd party collectible
  useEffect(() => {
    if (mintSuccess) {
      // if (navChoice == "app") {
      //   setIsRedirectingToApp(true);
      //   loadAppOrRedirectToPlaystore(setNavChoice);
      //   setTimeout(function () {
      //     setIsRedirectingToApp(false);
      //   }, 25);
      // } else if (navChoice == "web" || !isThinClient) {
      //   setShowAppPromptComponent(false);
      // }

      setShowAppPromptComponent(false);
    }
  }, [mintSuccess]);

  const onLogin = async () => {
    //clear all required cache before login
    clearAllData();
    setShowLoginModal(true);
  };

  const signupUser = async userDetail => {
    // * Check if User Id Exists
    // ? if user id is non zero, then user is already signed up
    const userId = parseInt(userDetail?.payload?.UserData?.id, 16);
    if (userId != 0) {
      return;
    } else {
      setSignupLoader(true);
      await dispatch(
        CreateUserDetail({
          networkID: networkId,
          provider: staticProvider,
          address,
          userInfo,
          wallet,
          userName: nameText,
          userStatus: statusText,
          userAvatarURI: avatarUri,
          userLocation: currenLocation,
          decryptMessage,
        }),
      );
      setSignupLoader(false);
    }
  };

  // useEffect(() => {
  //   if(connected){
  //     setUserInfo();
  //   }
  // },[connected])
  const onLoginProviderSelected = async (
    provider: any,
    loginEmail: string,
    loginConfig: any,
  ) => {
    setLoginInProgress(true);
    setShowLoginModal(false);
    if (provider === LOGIN_PROVIDER.TWITCH) {
      loginConfig = {
        twitch: {
          verifier: TWITCH_LOGIN_VERIFIER_NAME, // from web3auth dashboard
          typeOfLogin: 'twitch',
          clientId: TWITCH_CLIENT_ID, // twitch's client id
        },
      };
    }
    await connect(
      provider,
      provider == LOGIN_PROVIDER.EMAIL_PASSWORDLESS && loginEmail,
      loginConfig,
    );
  };

  const checkBackgroundRefreshEnabled = async () => {
    try {
      const status = await BackgroundFetch.getStatusAsync();
      if (status === BackgroundFetch.BackgroundFetchStatus.Denied) {
        setShowBackgroundRefreshAppModal(true);
      } else {
        setShowBackgroundRefreshAppModal(false);
      }
    } catch (ex) {
      LogToLoot8Console(
        'error occured while getting background refresh status',
        ex.message,
      );
      LogCustomError(
        'error occured while getting background refresh status',
        ex.name,
        ex.message,
        ex.stack,
      );
    }
  };

  useEffect(() => {
    const invokeQRShimFlow = async () => {
      if (connected && wallet && isQRShimFlow) {
        // Clear up previous state if any
        setNavChoice('');
        setIsRedirectingToApp(false);
        setShowAppPromptComponent(true);

        setShowMintingErrorMessage(false);
        setRedeemedQRScanned(false);
        setMintingInProgress(true);

        let contract = await getData('contract');
        let func = await getData('function');
        let chain = await getData('chain');
        let types = await getData('types');
        let args = await getData('args');

        // load app configuration.
        try {
          const configData = await getIPNSData(
            Constants.expoConfig.extra.APP_CONFIGURATION_IPNS_KEY,
            APP_CONFIGURATION_FILE,
          );
          if (configData) {
            const config = JSON.parse(configData);
            if (config && config.qrConfig) {
              const filterContract = contract
                ? config?.qrConfig?.find(
                    x =>
                      x.contract.toString().toLocaleLowerCase() ==
                      contract.toString().toLocaleLowerCase(),
                  )
                : null;
              // override chain id and function.
              if (filterContract) {
                chain =
                  filterContract && filterContract.chainId
                    ? filterContract.chainId
                    : chain;
                func =
                  filterContract && filterContract.function
                    ? filterContract.function
                    : func;
              }
            }
          }
        } catch (e) {
          LogToLoot8Console('Failed to load app configuratino in qr shim', e);
          LogCustomError(
            'Failed to load app configuratino in qr shim',
            e.name,
            e.message,
            e.stack,
          );
        }

        if (
          contract &&
          func &&
          chain &&
          types &&
          args &&
          args.split(',')[1].length > 0
        ) {
          let isTokenMinted = CheckMintedTokenError.TOKEN_NOT_MINTED;
          let tokenId =
            func.toLowerCase() == 'mint' && args.split(',').length == 3
              ? args.split(',')[2]
              : args.split(',')[1];
          isTokenMinted = await isTokenAlreadyMinted(
            contract,
            chain,
            tokenId,
            address,
            networkId,
          );

          if (isTokenMinted == CheckMintedTokenError.TOKEN_NOT_MINTED) {
            setMintingInProgress(true);
            setRedeemedQRScanned(false);

            // Mint 3rd party collectible
            dispatch(
              mintThirdPartyCollectibles({
                key: key,
                contract: contract,
                chain: chain,
                mintFunc: func,
                types: types.split(','),
                args: args.split(','),
                isScanCall: true,
              }),
            ).then(async (res: any) => {
              if (
                res.payload?.isCollectibleMinted &&
                res.payload?.createdTokenId
              ) {
                // Reload collectibles in portfolio
                clearCatalog('Collectibles');
                // await dispatch(loadAllCatList({ networkID: networkId, provider: staticProvider, address, wallet, userInfo }));
                // dispatch(reloadCatCollectibleList({ networkID: networkId, provider: staticProvider, address, wallet, userInfo }));

                const catalogsOwned = await getThirdPartyCollectiableDetails(
                  {
                    chainId: chain,
                    provider: staticProvider,
                    collectiableAddress: contract,
                    address,
                  },
                  false,
                );
                const catalog =
                  catalogsOwned &&
                  catalogsOwned.length > 0 &&
                  catalogsOwned?.sort((a, b) => b?.timestamp - a?.timestamp)[0];

                setQRShimFlow(catalog);
                setMintingInProgress(false);

                setMintSuccess(true);

                setShowMintingErrorMessage(false);

                setIsQRShimFlow(false);
              } else {
                // Show error message
                setMintSuccess(false);
                // setShowButtonsToContinue(false);
                setShowMintingErrorMessage(true);
                setRedeemedQRScanned(false);
              }
            });
          } else {
            // Error: Token Already Minted
            setMintSuccess(false);
            setShowMintingErrorMessage(true);
            setRedeemedQRScanned(true);
          }
        } else {
          // Error: Missing Required Params
          setMintSuccess(false);
          setShowMintingErrorMessage(true);
          setRedeemedQRScanned(false);
        }
      }
    };

    invokeQRShimFlow();

    const checkForUpdateAppReq = async () => {
      const currentAppVersion = getScaledAppVersion(
        Constants.expoConfig.version,
      );
      //console.log('current version:', currentAppVersion);

      let latestAppVersion = '';
      let minVersionReq = '';

      if (Platform.OS === 'ios') {
        latestAppVersion = await getAppVersionFromAppStore();
      }
      if (Platform.OS === 'android') {
        latestAppVersion = await getAppVersionFromPlayStore();
      }
      if (latestAppVersion && latestAppVersion != '') {
        //console.log('latest version', getScaledAppVersion(latestAppVersion));
        setLatestAvailableVersion(latestAppVersion);
        if (currentAppVersion < getScaledAppVersion(latestAppVersion)) {
          const config = await getAppConfiguration();
          if (config && config.minSupportedAppVersion) {
            if (
              Platform.OS === 'android' &&
              config.minSupportedAppVersion.android
            ) {
              minVersionReq = config.minSupportedAppVersion.android;
            }
            if (Platform.OS === 'ios' && config.minSupportedAppVersion.ios) {
              minVersionReq = config.minSupportedAppVersion.ios;
            }
            //console.log('min supported version', getScaledAppVersion(minVersionReq));
          }
          if (currentAppVersion >= getScaledAppVersion(minVersionReq)) {
            setForceAppUpdateRequired(false);
          } else {
            setForceAppUpdateRequired(true);
          }
          setShowUpdateAppModal(true);
        }
      }
    };

    if (connected) {
      checkForUpdateAppReq();
      //check if background refresh permission is granted by user, if not request to do so for notification badges to work properly in ios
      if (Platform.OS === 'ios') {
        checkBackgroundRefreshEnabled();
      }
    }

    const loadDetail = async () => {
      if (connected && wallet) {
        setLoginEmail('');
        setLoginInProgress(true);
        await refreshURLs();
        refreshAllUserData();
        let isUnderLocalMaitenance = false;
        const config = await getAppConfiguration();

        switch (Platform.OS) {
          //* Maintenance mode check for Android OS
          case 'android': {
            isUnderLocalMaitenance =
              config?.UnderMaintenance?.platform?.android || false;
            break;
          }
          //* Maintenance mode check for iOS OS
          case 'ios': {
            isUnderLocalMaitenance =
              config?.UnderMaintenance?.platform?.ios || false;
            break;
          }
          //* Maintenance mode check for Web App
          case 'web': {
            isUnderLocalMaitenance =
              config?.UnderMaintenance?.platform?.web || false;
            break;
          }
          /*
           * Maintenance mode check fallback:
           * If any of the 3 supported OS is under maintenance,
           * the fallback will be under maintenance
           */
          default: {
            isUnderLocalMaitenance =
              config?.UnderMaintenance?.platform?.android ||
              config?.UnderMaintenance?.platform?.ios ||
              config?.UnderMaintenance?.platform?.web ||
              false;
            break;
          }
        }

        const maintenanceInfo = getMaintenanceInfo();
        if (isUnderLocalMaitenance || maintenanceInfo.inMaintenance) {
          setMaintenanceText(
            isUnderLocalMaitenance
              ? config?.UnderMaintenance?.message
              : maintenanceInfo.maintenanceInfo,
          );
          setInMaintenanceMode(true);
        } else {
          setMaintenanceText('');
          setInMaintenanceMode(false);

          // initialize app configuration
          await getAppConfiguration();

          // get whitelisted wallet
          if (config && config.whitelistedWallet) {
            const wallet = config.whitelistedWallet.find(
              x => x.toLowerCase() === address.toLowerCase(),
            );
            if (wallet) {
              setIsWhitelistedWallet(true);
            } else {
              setIsWhitelistedWallet(false);
            }
          }

          const userDetail = await dispatch(
            loadUserDetail({
              networkID: networkId,
              provider: staticProvider,
              address,
              userInfo: userInfo,
              wallet: wallet,
              userLocation: currenLocation,
              decryptMessage,
            }),
          );
          // * Sign up after loading data
          // ? This is to make sure we don't make duplicate calls to create user
          // ? Passing the userDetail to signupUser to avoid making another call to loadUserDetail
          // ? And Also to avoid using Redux State because it might not be updated yet
          signupUser(userDetail);
          setTimeout(() => {
            refreshCacheForEntityData(config);
          }, 1 * 60 * 1000);
          refreshAllUserData();
          setTimeout(() => {
            setLocation();
          }, 30 * 1000); // update location
        }
        setLoginInProgress(false);
      }
    };
    loadDetail();

    const refreshCacheForEntityData = async (config: any) => {
      let isCacheExpired = true;
      const currentExecTime = await AsyncStorage.getItem(
        '@refreshCacheLastExecutionTime',
      );
      if (currentExecTime) {
        if (
          getSyncedTime() - Number(currentExecTime) <=
          (config.refreshCacheFrequency || 15 * 60 * 1000)
        ) {
          isCacheExpired = false;
        }
      }
      if (
        connected &&
        wallet &&
        isCacheExpired &&
        !refreshCacheExecutionStarted &&
        !loadingPassport
      ) {
        try {
          let ethProvider: any = staticProvider;
          refreshCacheExecutionStarted = true;
          dispatch(
            refreshDataCache({
              networkID: networkId,
              ethProvider,
              provider: staticProvider,
              address,
              userInfo: userInfo,
              wallet: wallet,
              userLocation: currenLocation,
            }),
          )
            .then(() => {
              refreshCacheExecutionStarted = false;
            })
            .catch(() => {
              refreshCacheExecutionStarted = false;
            });

          await refreshURLs();

          // load app configuration
          await getAppConfiguration();

          // update last execution time cache.
          const lastExecutionTime = getSyncedTime();
          await AsyncStorage.setItem(
            '@refreshCacheLastExecutionTime',
            lastExecutionTime.toString(),
          );
        } catch (e) {
          LogToLoot8Console('Error on refresh', e);
        }
      }
      setTimeout(() => {
        refreshCacheForEntityData(config);
      }, 1 * 60 * 1000);
    };
    //get latest user details every 30 mins to reflect updated user data in social messaging section.
    const refreshAllUserData = async () => {
      if (connected && wallet) {
        try {
          await dispatch(
            getAllUsersData({ networkID: networkId, provider: staticProvider }),
          );
        } catch (e) {
          LogToLoot8Console('Error on refreshing all user data.', e);
        }
      }
    };

    //Load avatar data if its not present already through app loading
    const loadAvatarFromJson = async () => {
      await dispatch(loadAvatarData({ networkID: null, provider: null }));
    };

    if (
      connected &&
      (!avatarData || !avatarData.data || avatarData.data.length === 0)
    ) {
      loadAvatarFromJson();
    }

    // load native notification permission to state variable in case of logout-login
    if (connected && Device.isDevice && Platform.OS !== 'web') {
      registerForPushNotificationsAsync(false).then(permissionGranted => {
        store.dispatch(
          setNativeNotificationPermission({
            permissionGranted: permissionGranted,
          }),
        );
      });
    }

    // clear minting queue list
    const clearMintingQueue = async () => {
      // clear passport minting queue.
      await dispatch(
        clearPassportMintingQueue({ networkID: null, provider: null }),
      );

      // clear collectible minting queue.
      await dispatch(
        clearCollectibleMintingQueue({ networkID: null, provider: null }),
      );
    };

    if (connected) {
      // clear minting queue
      clearMintingQueue();
    }

    if (connected) {
      initializeDataObjects(staticProvider);
    }

    // load est portal passports
    const loadEstPortalDetails = async () => {
      if (connected && wallet) {
        const result = await dispatch(
          loadEntityDetails({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
          }),
        );

        const isSuperAdmin = await checkSuperAdmin({
          networkID: networkId,
          provider: staticProvider,
          walletAddress: wallet?.address,
          entityAddress: result?.payload?.AllEntityData[0]?.address,
        });

        dispatch(setSuperAdmin(isSuperAdmin));

        refreshCacheExecutionStarted = true;

        await dispatch(
          loadAllDetails({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            isCache: false,
            isSuperAdmin,
          }),
        ).then(() => {
          refreshCacheExecutionStarted = false;
        });

        // if (passports?.length == 0 && !passportsLoading) {
        //   setRun(true);
        // }
      }
    };
    loadEstPortalDetails();
  }, [connected]);

  // Set Notifications of friends if theres any friend requests or message requests
  const refreshNotifications = useCallback(() => {
    dispatch(
      getPrivateMsgPendingRequestForUser({
        networkID: networkId,
        provider: staticProvider,
        address: address,
        wallet: wallet,
        timestamp: null,
        decryptMessage: decryptMessage,
        publicKey: null,
        directionType: null,
      }),
    ).then(() => {
      dispatch(
        getUnsubsribedNotifications({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
        }),
      );
    });
  }, [connected]);

  useEffect(() => {
    if (AllUsersData && AllUsersData.length && AllUsersData.length > 1) {
      dispatch(
        loadFriendsData({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
        }),
      ).then(({ payload }) => {
        dispatch(
          loadLastMessageDetailsForMutualFriends({
            networkID: networkId,
            provider: staticProvider,
            address: address,
            wallet: wallet,
            friendsAddresses: payload?.currentFriends?.map(friend => {
              return { ...friend, friendAddress: friend.wallet };
            }),
          }),
        );
      });
    }
  }, [AllUsersData?.length]);

  useEffect(() => {
    let intervalId = null;

    if (connected && !loadingFriendsData) {
      // Use throttle to ensure refreshNotifications is called at most once every 10000 milliseconds
      const throttledRefreshNotifications = throttle(
        refreshNotifications,
        10000,
      );
      intervalId = setInterval(throttledRefreshNotifications, 10000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [connected, loadingFriendsData]);

  useEffect(() => {
    if (connected && forceLogoutUser) {
      dispatch(logoutUserActions({ networkID: null, provider: null, wallet }));
      disconnect();
    }
  }, [forceLogoutUser]);

  const openWebLink = async (link: string) => {
    const supported = await Linking.canOpenURL(link);
    if (supported) {
      await Linking.openURL(link);
    } else {
      LogToLoot8Console('Unable to open url', link);
    }
  };

  const navigationConfig = {
    screens: {
      Home: {
        screens: {
          FriendsTab: {
            path: 'friends/:selectedTab?/:navigationFrom?/:friendAddress?',
          },
          HomeTab: {
            screens: {
              HomeScreen: {
                path: 'home',
              },
              PassportDetail: {
                path: 'home/:chainId/passport/:passportAddress',
              },
            },
          },
        },
      },
    },
  };

  const linkingObj =
    Platform.OS !== 'web' && Device.isDevice
      ? {
          prefixes: [LinkingExpo.createURL('/'), 'loot8auth://'],

          config: navigationConfig,

          async getInitialURL() {
            // First, you may want to do the default deep link handling
            // Check if app was opened from a deep link
            const url = await Linking.getInitialURL();

            if (url != null) {
              return url;
            }

            // Handle URL from expo push notifications
            const response =
              await Notifications.getLastNotificationResponseAsync();
            const responseURL =
              response?.notification.request.content.data.url.toString();

            return responseURL;
          },

          subscribe(listener) {
            const onReceiveURL = ({ url }: { url: string }) => {
              listener(url);
            };

            // Listen to incoming links from deep linking
            const eventListenerSubscription = LinkingExpo.addEventListener(
              'url',
              onReceiveURL,
            );

            // Listen to expo push notifications
            const responseListener =
              Notifications.addNotificationResponseReceivedListener(
                async response => {
                  const url =
                    response.notification?.request?.content?.data?.url;
                  listener(url);
                  dismissNotification(response, null);
                },
              );

            return () => {
              // Clean up the event listeners
              eventListenerSubscription.remove();
              responseListener.remove();
            };
          },
        }
      : null;

  // create loot8 image directory
  useEffect(() => {
    const createLoot8Directory = async () => {
      if (Platform.OS === 'android' || Platform.OS === 'ios') {
        await ensureDirExists();
      }
    };

    createLoot8Directory();
  }, []);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
        overflow: 'hidden',
      }}>
      {!inMaintenanceMode ? (
        !loginInProgress ? (
          connected ? (
            isQRShimFlow && showAppPromptComponent ? (
              <NavigationContainer>
                <AppPromptComponent
                  isThinClient={isThinClient}
                  redeemedQRScanned={redeemedQRScanned}
                  mintingInProgress={mintingInProgress}
                  isRedirectingToApp={isRedirectingToApp}
                  showMintingErrorMessage={showMintingErrorMessage}
                />
              </NavigationContainer>
            ) : signupLoader && !userData?.isExist ? (
              <AnimatedLoading />
            ) : (
              <>
                <SheetProvider>
                  <NavigationContainer
                    linking={linkingObj}
                    fallback={<Text>{'Loading...'}</Text>}>
                    <RootNavigator />
                    <NotificationComponent />
                    {showStatusModal && (
                      <UserStatusComponent
                        showStatusModal={showStatusModal}
                        setShowStatusModal={setShowStatusModal}
                      />
                    )}
                    {showUpdateAppModal && (
                      <AppUpdateComponent
                        forceAppUpdateRequired={forceAppUpdateRequired}
                        latestAvailableVersion={latestAvailableVersion}
                        showUpdateAppModal={showUpdateAppModal}
                        setShowUpdateAppModal={setShowUpdateAppModal}
                      />
                    )}
                    {showBackgroundRefreshAppModal && (
                      <AppBackgroundRefreshComponent
                        showBackgroundRefreshAppModal={
                          showBackgroundRefreshAppModal
                        }
                        setShowBackgroundRefreshAppModal={
                          setShowBackgroundRefreshAppModal
                        }
                      />
                    )}
                  </NavigationContainer>
                </SheetProvider>
              </>
            )
          ) : (
            <SafeAreaView style={{ flex: 1 }}>
              <AuthComponent
                loginEmail={loginEmail}
                onOpenWebLink={openWebLink}
                setLoginEmail={setLoginEmail}
                onLoginProviderSelected={onLoginProviderSelected}
              />
            </SafeAreaView>
          )
        ) : (
          <AnimatedLoading />
        )
      ) : (
        <Maintenance maintenanceText={maintenanceText} />
      )}
    </View>
  );
}

export default LandingScreen;
