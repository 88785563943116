import React from 'react';
import {
  Text,
  View,
  Image,
  StyleSheet,
  KeyboardType,
  DataDetectorTypes,
  ImageSourcePropType,
} from 'react-native';

import { defaultTheme } from '../../themes/loot8';
import { TouchableRipple } from 'react-native-paper';
import TextInputComponent from '../TextInputComponent';
import { LinearGradient } from 'expo-linear-gradient';
import GradientButton from '../GradientButton';

interface ExpassListPopupContentProps {
  title: string;
  confirmButtonOnPress: () => void;
  confirmButtonText: string;
  confirmButtonDisabled?: boolean;
  alternateConfirmButton?: boolean;
  textInputValue: string;
  textInputSetValue: (value: string) => void;
  textInputPlaceholder: string;
  textInputRightImage?: ImageSourcePropType;
  textInputKeyBoardType?: KeyboardType;
  textInputDataDetectorTypes?: DataDetectorTypes;
  textInputMaxLength?: number;
}
const ExpassListPopupContent: React.FC<ExpassListPopupContentProps> = ({
  title,
  confirmButtonOnPress,
  confirmButtonText,
  confirmButtonDisabled,
  textInputValue,
  textInputSetValue,
  textInputPlaceholder,
  textInputRightImage,
  textInputKeyBoardType,
  textInputDataDetectorTypes,
  textInputMaxLength,
  alternateConfirmButton,
}) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.separator} />
      <TextInputComponent
        placeholder={textInputPlaceholder}
        value={textInputValue}
        setOnChange={textInputSetValue}
        type="primary"
        rightIcon={!!textInputValue ? 'close' : null}
        onRightIconPress={() => textInputSetValue('')}
        keyboardType={textInputKeyBoardType}
        dataDetectorTypes={textInputDataDetectorTypes}
        maxLength={textInputMaxLength}
        customRight={
          <Image
            source={textInputRightImage}
            resizeMode="contain"
            style={styles.rightTextInputImg}
          />
        }
      />
      <View style={{ marginVertical: 10 }} />

      <ConfirmButton
        onPress={confirmButtonOnPress}
        title={confirmButtonText}
        disabled={confirmButtonDisabled}
      />
    </View>
  );
};

export default ExpassListPopupContent;

const styles = StyleSheet.create({
  container: { paddingHorizontal: 15, paddingVertical: 15 },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  btnText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_TEXT_COLOR,
    opacity: 0.6,
    marginTop: 15,
    marginBottom: 25,
  },
  buttonContainer: {
    height: 48,
    backgroundColor: 'rgba(33, 29, 40,0.8)',
    borderRadius: defaultTheme.BUTTON_RADIUS,
    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightTextInputImg: {
    height: 30,
    width: 30,
    borderRadius: 30,
    overflow: 'hidden',
  },
});

export const ConfirmButton = ({
  onPress,
  title,
  disabled,
  alternateConfirmButton,
}) => {
  return (
    <>
      {alternateConfirmButton ? (
        <View style={styles.buttonContainer}>
          <GradientButton onPress={onPress} buttonLabel={title} />
        </View>
      ) : (
        <View style={[styles.buttonContainer, { opacity: disabled ? 0.6 : 1 }]}>
          <TouchableRipple
            disabled={disabled}
            style={styles.ripple}
            rippleColor={'rgba(0,0,0,0.25)'}
            onPress={onPress}>
            <Text style={styles.btnText}>{title}</Text>
          </TouchableRipple>
        </View>
      )}
    </>
  );
};
