import React, { useEffect, useRef, useState } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View, Platform, Text, StyleSheet } from 'react-native';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import blackBgToken from '../../assets/Loot8TokenBlack.png';
import usdcLogo from '../../assets/usdcLogo.png';
import { CatalogTypes } from '../../enums/catalog.enum';
import { ScreenName } from '../../enums/screen.enum';
import { PublicMarketPlaceTabs } from '../../enums/marketPlace.enum';
import { BlurView } from 'expo-blur';
import { ReShareWebBlurView } from '../WebBlurView';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
} from 'react-native-actions-sheet';
import {
  ToastCustomMessageType,
  ZERO_ADDRESS,
  getAnynetStaticProvider,
  isNativeChain,
} from '../../appconstants';
import { CollectionType, ListingType } from '../../enums/collection.enum';
import ExpassListPopupContent, {
  ConfirmButton,
} from '../ExpassListPopupContent';
import ExpassListPopupConfimation from '../ExpassListPopupConfimation';
import { wait } from '../../helpers/ipfs';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import {
  approveForListing,
  delistCollectionForMarketPlace,
  listCollectionForMarketPlace,
} from '../../slices/MarketPlaceSlice';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { getCollectibleDetails } from '../../slices/OfferSlice';
import { updatePassportMarketPlaceConfig } from '../../slices/PassportSlice';
import {
  loadCatalogCollectiable,
  loadCatalogPassport,
} from '../../slices/CatalogSlice';
import { showToastMessage } from '../../helpers/Gadgets';
import { LogCustomError } from '../../helpers/AppLogger';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { useAppSelector } from '../../hooks';
import { hideListingModal, showListingModal } from '../../slices/ModalsSlice';

export const ConfirmationModal = ({
  showConfirmation,
  setShowConfirmation,
  listed,
  catalogType,
  selectedCatalog,
  failedOp,
  navigation,
  navigationFrom,
  availableCatalogs,
}) => {
  return (
    <View style={{ justifyContent: 'center', alignItems: 'center' }}>
      {failedOp ? (
        <View
          style={{
            margin: 10,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={[
              componentStyles.confirmModalHeading,
              { paddingVertical: 3 },
            ]}>{`Failed!!`}</Text>
          <Text style={[styles.confirmModalText, { paddingVertical: 3 }]}>
            {catalogType == CatalogTypes.COLLECTIBLES
              ? 'Your collectible'
              : 'Your ExPass'}
            {listed ? ' unlisting failed' : ' listing failed'}
          </Text>
        </View>
      ) : (
        <View
          style={{
            margin: 10,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {!listed && (
            <Text
              style={[
                componentStyles.confirmModalHeading,
                { paddingVertical: 3 },
              ]}>{`Congratulations!!`}</Text>
          )}
          <Text
            style={[
              componentStyles.confirmModalHeading,
              { paddingVertical: 3 },
            ]}>
            {catalogType == CatalogTypes.COLLECTIBLES
              ? 'Your collectible'
              : 'Your ExPass'}
            {' is successfully '}
            {listed ? 'unlisted' : 'listed'}
          </Text>
          {/* TODO: Need to change this later */}
          <Text
            style={[
              componentStyles.confirmModalText,
              { paddingVertical: 3, color: defaultTheme.PRIMARY_TEXT_COLOR },
            ]}>{`Please wait a few minutes while your ${
            catalogType == CatalogTypes.COLLECTIBLES ? 'collectible' : 'ExPass'
          } is ${listed ? 'deleted from' : 'uploaded to'} LOOT8!`}</Text>
          {/* <Pressable
            onPress={() =>
              navigation.navigate('WalletTab', {
                screen: ScreenName.MARKET_PLACE,
                params: {
                  selectedPassportCategory:
                    catalogType == CatalogTypes.COLLECTIBLES
                      ? PublicMarketPlaceTabs.COLLECTIBLES
                      : PublicMarketPlaceTabs.EXPASSES,
                  navigationFrom: navigationFrom,
                  selectedCatalog: selectedCatalog,
                  availableCatalogs: availableCatalogs,
                  catalogType: catalogType,
                },
              })
            }> */}
          {/* <Text
              style={[
                componentStyles.confirmModalTextItalic,
                {
                  paddingVertical: 3,
                  textDecorationLine: 'underline',
                },
              ]}>
              Go to Marketplace
            </Text> */}
          <View style={{ width: 250, paddingTop: 20 }}>
            <ConfirmButton
              onPress={() => {
                navigation.navigate('WalletTab', {
                  screen: ScreenName.MARKET_PLACE,
                  params: {
                    selectedPassportCategory:
                      catalogType == CatalogTypes.COLLECTIBLES
                        ? PublicMarketPlaceTabs.COLLECTIBLES
                        : PublicMarketPlaceTabs.EXPASSES,
                    navigationFrom: navigationFrom,
                    selectedCatalog: selectedCatalog,
                    availableCatalogs: availableCatalogs,
                    catalogType: catalogType,
                  },
                });
              }}
              title={'Go to Marketplace'}
              disabled={false}
            />
          </View>
          {/* </Pressable> */}
        </View>
      )}
    </View>
  );
};

const ListingModalContent = ({ props, actionSheetRef }) => {
  const [listingConfirmation, setListingConfirmation] = useState(false);
  const [isListed, setIsListed] = useState(false);
  const [salePrice, setSalePrice] = useState('');

  const dispatch = useDispatch<AppDispatch>();
  const { address, networkId, staticProvider, wallet, userInfo } =
    useWeb3AuthContext();
  const entityData = useAppSelector(state => state.Entity.EntityData);

  const { itemObject, navigation, type, updateStateDataFunc } = props.payload;

  useEffect(() => {
    if (itemObject) {
      if (type === 'Expass') {
        if (itemObject.fromMarketPlace) {
          setIsListed(true);
          return;
        }
        const checkIfListed =
          itemObject &&
          itemObject?.collectibleIds.length > 0 &&
          itemObject?.marketPlaceConfig?.listingIndex &&
          itemObject?.marketPlaceConfig?.listingIndex?.findIndex(
            obj => obj.tokenId === Number(itemObject?.collectibleIds[0]),
          ) !== -1
            ? true
            : false;
        setIsListed(checkIfListed);
      } else {
        if (itemObject.fromMarketPlace) {
          setIsListed(true);
          return;
        }
        const checkIfListed =
          itemObject &&
          itemObject?.marketPlaceConfig?.listingIndex &&
          itemObject?.marketPlaceConfig?.listingIndex?.findIndex(
            obj => obj.tokenId === Number(itemObject.collectibleId),
          ) !== -1;
        setIsListed(checkIfListed);
      }
    }
  }, [itemObject]);

  const closeSheet = () => {
    if (isListed) {
      actionSheetRef.current.hide();
      setSalePrice('');
    } else {
      setListingConfirmation(false);
    }
  };

  const list = () => {
    actionSheetRef.current.hide();
    listForMarketPlace(salePrice, itemObject);
  };
  const onValueTyped = async text => {
    !isNaN(Number(text)) && setSalePrice(text.trim());
  };

  const listForMarketPlace = async (price, passportObj) => {
    if (passportObj) {
      let chainId = passportObj.chainId;
      let provider = getAnynetStaticProvider(chainId);
      const tokenID =
        type === 'Expass'
          ? passportObj?.collectibleIds?.length > 0
            ? Number(passportObj?.collectibleIds[0])
            : 0
          : passportObj.collectibleId ?? passportObj.tokenId;
      const passportAddress = ZERO_ADDRESS;
      try {
        if (Platform.OS === 'ios') {
          await wait(500);
        }
        dispatch(showListingModal(isListed));
        let response = null;
        if (type === 'Expass') {
          if (isListed && passportObj?.collectibleIds?.length > 0) {
            const selectedPassportListingIndex = (
              passportObj &&
              passportObj?.marketPlaceConfig?.listingIndex?.find(
                obj => obj.tokenId === Number(passportObj?.collectibleIds[0]),
              )
            ).listingId;

            if (tokenID) {
              response = await dispatch(
                delistCollectionForMarketPlace({
                  networkID: chainId,
                  provider,
                  wallet,
                  address,
                  collectibleAddress: passportObj.address,
                  tokenId: tokenID,
                  passport: passportAddress,
                  chainId: passportObj.chainId,
                  price: price,
                  listingType: ListingType.PUBLIC,
                  listingID: selectedPassportListingIndex,
                }),
              );
            } else {
              LogToLoot8Console(
                'unlistForMarketPlace: invalid passport tokenID',
              );
            }
          } else {
            if (tokenID !== 0) {
              await dispatch(
                approveForListing({
                  networkID: networkId,
                  provider: provider,
                  wallet,
                  address,
                  collectibleAddress: passportObj.address,
                  tokenId: tokenID,
                }),
              );
              response = await dispatch(
                listCollectionForMarketPlace({
                  networkID: networkId,
                  provider: getAnynetStaticProvider(chainId),
                  wallet,
                  address,
                  collectibleAddress: passportObj.address,
                  tokenId: tokenID,
                  passport: passportAddress,
                  chainId: passportObj.chainId,
                  price: price,
                  listingType: ListingType.PUBLIC,
                }),
              );
            } else {
              LogToLoot8Console('listForMarketPlace: invalid passport tokenID');
            }
          }
          if (response && response.payload) {
            const passport = await getCollectibleDetails(
              {
                networkID: passportObj.chainId,
                provider: getAnynetStaticProvider(passportObj.chainId),
                collectibleAddress: passportObj.address,
                address,
                wallet,
              },
              { entityData },
              {
                isCache: true,
                isBalanceRefresh: false,
                isMarketPlaceRefresh: true,
              },
            );
            if (passport && passport?.marketPlaceConfig) {
              await dispatch(
                updatePassportMarketPlaceConfig({
                  address: passport.address,
                  marketPlaceConfig: passport.marketPlaceConfig,
                }),
              );
            }
            let updatedPassportObj = {
              ...itemObject,
              marketPlaceConfig: passport.marketPlaceConfig,
            };
            updateStateDataFunc(updatedPassportObj);
            // setSearchPassportText('');
            await dispatch(
              loadCatalogPassport({
                networkID: networkId,
                provider: staticProvider,
                address,
                wallet,
                userInfo,
              }),
            );

            dispatch(hideListingModal());
            setTimeout(() => {
              navigation.navigate('Success', {
                screen: ScreenName.SUCCESS_SCREEN,
                params: {
                  message: `${
                    type == 'Collectible' ? `Your Collectible` : `Your ExPass`
                  } is successfully ${isListed ? `unlisted` : `listed`}`,
                },
              });
            }, 500);
          } else {
            if (isListed) {
              showToastMessage(
                ToastCustomMessageType.INFO,
                `Failed to unlist  ${
                  type == 'Collectible' ? `Collectible` : `ExPass`
                }!`,
              );
            } else {
              showToastMessage(
                ToastCustomMessageType.INFO,
                `Failed to list ${
                  type == 'Collectible' ? `Collectible` : `ExPass`
                }!`,
              );
            }
          }
        } else {
          if (isListed) {
            const selectedCollectibleListingIndex = (
              passportObj &&
              passportObj?.marketPlaceConfig?.listingIndex?.find(
                obj => obj.tokenId === Number(tokenID),
              )
            ).listingId;

            response = await dispatch(
              delistCollectionForMarketPlace({
                networkID: chainId,
                provider: provider,
                wallet,
                address,
                collectibleAddress: passportObj.address,
                tokenId: Number(tokenID),
                passport: passportAddress,
                chainId: passportObj.chainId,
                price: salePrice,
                listingType: ListingType.PUBLIC,
                listingID: selectedCollectibleListingIndex,
              }),
            );
          } else {
            await dispatch(
              approveForListing({
                networkID: chainId,
                provider: provider,
                wallet,
                address,
                collectibleAddress: passportObj.address,
                tokenId: Number(tokenID),
              }),
            );

            response = await dispatch(
              listCollectionForMarketPlace({
                networkID: chainId,
                provider: provider,
                wallet,
                address,
                collectibleAddress: passportObj.address,
                tokenId: Number(tokenID),
                passport: passportAddress,
                chainId: passportObj.chainId,
                price: salePrice,
                listingType: ListingType.PUBLIC,
              }),
            );
          }

          if (response && response.payload) {
            const collectibleDetails = await getCollectibleDetails(
              {
                networkID: chainId,
                provider: provider,
                collectibleAddress: passportObj.address,
                address,
                wallet,
              },
              { entityData },
              {
                isCache: true,
                isBalanceRefresh: false,
                isMarketPlaceRefresh: true,
              },
            );

            if (type === 'Collectible') {
              await dispatch(
                loadCatalogCollectiable({
                  networkID: networkId,
                  provider: staticProvider,
                  address,
                  wallet,
                  userInfo,
                }),
              );
            } else {
              await dispatch(
                updatePassportMarketPlaceConfig({
                  address: passportObj.address,
                  marketPlaceConfig: collectibleDetails.marketPlaceConfig,
                }),
              );
              await dispatch(
                loadCatalogPassport({
                  networkID: networkId,
                  provider: staticProvider,
                  address,
                  wallet,
                  userInfo,
                }),
              );
            }
            let updatedCatalog = {
              ...itemObject,
              marketPlaceConfig: collectibleDetails.marketPlaceConfig,
            };

            updateStateDataFunc(updatedCatalog);

            dispatch(hideListingModal());
            setTimeout(() => {
              navigation.navigate('Success', {
                screen: ScreenName.SUCCESS_SCREEN,
                params: {
                  message: `${
                    type == 'Collectible' ? `Your Collectible` : `Your ExPass`
                  } is successfully ${isListed ? `unlisted` : `listed`}`,
                },
              });
            }, 500);
          } else {
            if (isListed) {
              showToastMessage(
                ToastCustomMessageType.INFO,
                `Failed to unlist  ${
                  type == 'Collectible' ? `Collectible` : `ExPass`
                }!`,
              );
            } else {
              showToastMessage(
                ToastCustomMessageType.INFO,
                `Failed to list ${
                  type == 'Collectible' ? `Collectible` : `ExPass`
                }!`,
              );
            }
          }
        }
      } catch (e) {
        LogCustomError(
          'Error: Listing in Market Place',
          e.name,
          e.message,
          e.stack,
        );
      }
      dispatch(hideListingModal());
    }
  };
  return (
    <>
      <View>
        {!(listingConfirmation || isListed) ? (
          <>
            <ExpassListPopupContent
              title={
                itemObject?.catalogType === CatalogTypes.PASSPORT ||
                itemObject?.collectionType == CollectionType.PASSPORT
                  ? 'Quote a price for your ExPass'
                  : ' Quote a price for your collectible'
              }
              confirmButtonText={'List on Marketplace'}
              confirmButtonOnPress={() => setListingConfirmation(true)}
              confirmButtonDisabled={!(Number(salePrice) >= 0.01)}
              textInputValue={salePrice}
              textInputSetValue={onValueTyped}
              textInputPlaceholder={'Enter the price of item'}
              textInputRightImage={
                isNativeChain(itemObject?.chainId) ? blackBgToken : usdcLogo
              }
              textInputKeyBoardType={'decimal-pad'}
              textInputDataDetectorTypes={'phoneNumber'}
              textInputMaxLength={10}
            />
          </>
        ) : (
          <ExpassListPopupConfimation
            confirmButtonOnPress={() => list()}
            cancelButtonOnPress={() => closeSheet()}
            listed={isListed}
            title={
              isListed
                ? itemObject?.catalogType === CatalogTypes.PASSPORT ||
                  itemObject?.collectionType === CollectionType.PASSPORT
                  ? 'Are you sure you want to unlist your ExPass from the Marketplace?'
                  : 'Are you sure you want to unlist this collectible from the Marketplace'
                : itemObject?.catalogType === CatalogTypes.PASSPORT ||
                    itemObject?.collectionType === CollectionType.PASSPORT
                  ? 'Are you sure you want to list your ExPass on the Marketplace?'
                  : 'Are you sure you want to list this collectible on the Marketplace'
            }
          />
        )}
      </View>
    </>
  );
};

const ListingModalSheet = (props: SheetProps) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);

  return (
    <ActionSheet
      ref={actionSheetRef}
      id={props.sheetId}
      drawUnderStatusBar
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      enableGesturesInScrollView={false}>
      {Platform.OS === 'web' ? (
        <ReShareWebBlurView>
          <View
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <ListingModalContent
                props={props}
                actionSheetRef={actionSheetRef}
              />
            </LinearGradient>
          </View>
        </ReShareWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={{
            borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <ListingModalContent
              props={props}
              actionSheetRef={actionSheetRef}
            />
          </LinearGradient>
        </BlurView>
      )}
    </ActionSheet>
  );
};

export default ListingModalSheet;

const componentStyles = StyleSheet.create({
  confirmModalHeading: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginHorizontal: 15,
    marginTop: 5,
  },
  confirmModalText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    marginHorizontal: 15,
    marginTop: 5,
  },
  confirmModalTextItalic: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    marginHorizontal: 15,
    marginTop: 5,
  },
});
