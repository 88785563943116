import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  Pressable,
  Platform,
  Text,
  ActivityIndicator,
  Keyboard,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet,
} from 'react-native';
import { defaultTheme } from '../../themes/loot8';
import { TextInput, Title } from 'react-native-paper';
import Checkbox from 'expo-checkbox';
import styles from '../../styles';
import { useAppSelector } from '../../hooks';
import AppLoaderComponent from '../Loader';
import { getFriendsInitialLoadPromise } from '../../slices/friendsSlice';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import Feather from 'react-native-vector-icons/Feather';
import { FlatList } from 'react-native-gesture-handler';
import { getUserPublicKey } from '../../slices/AppUserSlice';
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { sendPrivateMessageToFriend } from '../../slices/PrivateMessageSlice';
import { MessageType } from '../../interfaces/IMessages';
import { showToastMessage } from '../../helpers/Gadgets';
import { ToastCustomMessageType } from '../../appconstants';
import { LogErrors } from '../../helpers/AppLogger';
import { readRepliesAndLikesForMessage } from '../../slices/PassportMessageSlice';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';
import { BlurView } from 'expo-blur';
import { ReShareWebBlurView } from '../WebBlurView';
import ProfileAvatar from '../ProfileAvatar';
import { hideKeyboard } from '../../slices/helpers';
import { LinearGradient } from 'expo-linear-gradient';

const customSort = array => {
  return array
    .filter(f => f.isFriend === false)
    .sort((a, b) => {
      let textA = a.name.toUpperCase();
      let textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .concat(
      array
        .filter(f => f.isFriend === true)
        .sort((a, b) => {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }),
    );
};

let initialLoadPromise = false;
let initialAvatarLoaded = null;

const ReshareModalSheetContent = ({
  props,
  actionSheetRef,
  scrollHandlers,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    networkId,
    wallet,
    staticProvider,
    encryptMessage,
    address,
    decryptMessage,
  } = useWeb3AuthContext();
  const userData = useAppSelector(state => state.AppUser.UserData);

  const mutualFriendsData = useAppSelector(
    state => state.friends.mutualFriends,
  );
  const allAvatarsLoaded = useAppSelector(
    state => state.friends.allAvatarsLoaded,
  );
  const [sortedCurrentFriends, setSortedCurrentFriends] = useState(
    customSort(mutualFriendsData),
  );
  const [loadingFriends, setLoadingFriends] = useState(false);
  const [messageText, setMessageText] = useState('');

  const [searchText, setSearchText] = useState('');

  const [isMsgLoading, setIsMsgLoading] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState([]);

  const {
    message,
    getSenderName,
    getSenderID,
    getTimeTextFromTimestamp,
    selectedPassport,
  } = props.payload;

  // const actionSheetRef = useRef<ActionSheetRef>(null);

  const addFriendToReshare = (friend: { wallet: any }) => {
    if (selectedFriend.indexOf(friend.wallet) > -1) {
      setSelectedFriend(selectedFriend.filter(item => item !== friend?.wallet));
    } else {
      setSelectedFriend([...selectedFriend, friend?.wallet]);
    }
  };

  const sendReshareMessage = async () => {
    if (Keyboard && Keyboard.dismiss) {
      Keyboard.dismiss();
    }
    setIsMsgLoading(true);
    await Promise.all(
      selectedFriend.map(async (userAddress, index) => {
        const user = mutualFriendsData.find(
          obj => obj.wallet?.toLowerCase() === userAddress?.toLowerCase(),
        );
        if (user && user?.wallet) {
          const friendPublicKey = await getUserPublicKey(user?.wallet);
          if (friendPublicKey) {
            const encrptedMessage = encryptMessage(
              messageText.trim(),
              friendPublicKey,
            );
            if (encrptedMessage) {
              let response = await dispatch(
                sendPrivateMessageToFriend({
                  networkID: networkId,
                  provider: staticProvider,
                  address: user.wallet,
                  wallet: wallet,
                  publicKey: friendPublicKey,
                  text: encrptedMessage,
                  decryptMessage,
                  parentId: message?.messageId,
                  messageType: MessageType.reshare,
                }),
              );
              if (
                response &&
                response.payload.status &&
                response.payload.status == 200
              ) {
                setMessageText('');
              } else {
                showToastMessage(
                  ToastCustomMessageType.INFO,
                  'Unable to post message. Please try again later!',
                );
              }
            } else {
              LogErrors(
                new Error(`Error in Encrypting Message`),
                address,
                networkId,
                [
                  { tag: 'error', value: 'PRIVATE MESSAGE ERROR' },
                  { tag: 'friendAddress', value: user?.wallet },
                  { tag: 'Message', value: `${messageText}` },
                ],
              );
              showToastMessage(
                ToastCustomMessageType.INFO,
                'Unable to post message. Please try again later!',
              );
            }
          }
        }
      }),
    );
    // call update reshare count
    await dispatch(
      readRepliesAndLikesForMessage({
        networkID: networkId,
        provider: staticProvider,
        address: selectedPassport.address,
        chainId: selectedPassport.chainId,
        messageId: message?.data?.parent
          ? message?.data?.parent
          : message?.messageId,
      }),
    );

    actionSheetRef.current.hide();
    setTimeout(() => {
      setIsMsgLoading(false);
    }, 500);
  };

  const renderItem = ({ item, index }) => {
    const checked = selectedFriend.indexOf(item.wallet) > -1;
    return (
      <TouchableOpacity
        activeOpacity={1}
        key={item.wallet}
        onPress={() => addFriendToReshare(item)}
        disabled={
          (selectedFriend.length >= 5 &&
            selectedFriend.indexOf(item.wallet) === -1) ||
          isMsgLoading
        }>
        <View style={componentStyles.row}>
          <ProfileAvatar
            size={36}
            source={
              item.avatarURI &&
              item.avatarURI != '' &&
              item.avatarURI != 'no-avatar' &&
              !item.avatarURI.includes('ipfs://') &&
              !item.avatarURI.includes('assets_avatars_p_') &&
              !item.avatarURI.includes('file:///')
                ? { uri: item.avatarURI }
                : require('../../assets/loot8-default-avatar.png')
            }
            userAddress={item?.wallet}
            tickStyle={{ right: 1, top: -8, height: 12, width: 12 }}
          />
          <View
            style={[
              componentStyles.row,
              { flex: 1, justifyContent: 'space-between', marginLeft: 15 },
            ]}>
            <Title
              style={[
                styles.userTitle,
                {
                  fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
                  maxWidth: '90%',
                  textAlign: 'left',
                  lineHeight: 17,
                },
              ]}>
              {`${item?.name}`}{' '}
              <Text style={styles.friendUserIdText}>{`#${item?.id}`}</Text>
            </Title>
            <Checkbox
              value={checked}
              onValueChange={() => addFriendToReshare(item)}
              color={
                checked
                  ? defaultTheme.MAIN_BACKGROUND_COLOR
                  : defaultTheme.SECONDARY_TEXT_COLOR
              }
              style={{
                borderRadius: 6,
                borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
              }}
              disabled={
                (selectedFriend.length >= 5 &&
                  selectedFriend.indexOf(item.wallet) === -1) ||
                isMsgLoading
              }
            />
          </View>
        </View>
        {index != sortedCurrentFriends.length - 1 && (
          <View style={[componentStyles.separator, { marginBottom: 15 }]} />
        )}
      </TouchableOpacity>
    );
  };

  const onFriendSearch = async text => {
    setSearchText(text);
    if (text && text.length > 0) {
      setSortedCurrentFriends(
        customSort(mutualFriendsData)?.filter(
          p => p.name.toLowerCase().indexOf(text.toLowerCase()) > -1,
        ),
      );
    } else {
      setSortedCurrentFriends(customSort(mutualFriendsData));
    }
  };

  const _listEmptyComponent = () => {
    return (
      <Text
        style={[
          styles.addFriendsMsgText,
          { marginVertical: 10, color: defaultTheme.TEXT_COLOR_SECONDARY },
        ]}>
        {'No Friends found.'}
      </Text>
    );
  };

  useEffect(() => {
    if (!mutualFriendsData || mutualFriendsData?.length == 0) {
      setLoadingFriends(true);
      getFriendsInitialLoadPromise().then(() => {
        initialLoadPromise = true;
        initialAvatarLoaded = false;
      });
    } else if (!initialLoadPromise) {
      setLoadingFriends(false);
      setSortedCurrentFriends(customSort(mutualFriendsData));
    }
  }, [mutualFriendsData]);

  useEffect(() => {
    if (initialAvatarLoaded === false) {
      initialAvatarLoaded = true;
      setSortedCurrentFriends(customSort(mutualFriendsData));
    }
    if (allAvatarsLoaded && mutualFriendsData?.length == 0) {
      setLoadingFriends(false);
    }
  }, [allAvatarsLoaded]);

  return (
    <View style={componentStyles.container}>
      <Text style={componentStyles.modalTitle}>Send Post</Text>
      <View style={componentStyles.separator} />

      {/* passport message */}
      <View style={[componentStyles.row, { marginBottom: 15 }]}>
        <ProfileAvatar
          size={36}
          source={
            message.user &&
            message.user.avatarURI &&
            message.user.avatarURI != '' &&
            message.user.avatarURI != 'no-avatar' &&
            !message.user.avatarURI.includes('ipfs://') &&
            !message.user.avatarURI.includes('assets_avatars_p_') &&
            !message.user.avatarURI.includes('file:///')
              ? { uri: message.user.avatarURI }
              : require('../../assets/loot8-default-avatar.png')
          }
          userAddress={message?.user?.wallet}
          tickStyle={{ right: 3, top: -7 }}
        />
        <View
          style={{
            flex: 1,
            marginLeft: 10,
            justifyContent: 'center',
          }}>
          <View
            style={[
              componentStyles.row,
              {
                alignItems: 'flex-start',
                justifyContent: 'space-between',
              },
            ]}>
            <Text
              style={[
                styles.drawerUserNameLongText,
                Platform.OS == 'web' ? { wordBreak: 'break-word' } : {},
              ]}>
              <Text style={styles.socialMessagingUserNameText}>
                {getSenderName(message)}
              </Text>
              <Text style={styles.socialMessagingUserIDText}>
                {getSenderID(message)}
              </Text>
            </Text>
            <Text style={styles.socialMessagingTimestampText}>
              {getTimeTextFromTimestamp(message.data?.senderTimestamp)}
            </Text>
          </View>

          {message?.data?.data?.content && (
            <Text
              numberOfLines={3}
              ellipsizeMode="tail"
              style={[
                styles.socialMessageText,
                { textAlign: 'left', marginTop: 1.5 },
              ]}>
              {message.data?.data.content?.text}
            </Text>
          )}
          {message?.data?.data?.attachments &&
            message?.data?.data?.attachments?.uri !== '' && (
              <Icons
                name="image"
                color={defaultTheme.PRIMARY_TEXT_COLOR}
                size={30}
                style={{ marginTop: 2.5 }}
              />
            )}
        </View>
      </View>

      {/* Direct message input */}
      <View style={componentStyles.commentBox}>
        {userData && (
          <ProfileAvatar
            size={36}
            source={
              userData.avatarURI &&
              userData.avatarURI != '' &&
              userData.avatarURI != 'no-avatar' &&
              !userData.avatarURI.includes('ipfs://') &&
              !userData.avatarURI.includes('assets_avatars_p_') &&
              !userData.avatarURI.includes('file:///')
                ? { uri: userData.avatarURI }
                : require('../../assets/loot8-default-avatar.png')
            }
            userAddress={userData?.wallet}
          />
        )}
        <View style={{ flex: 1 }}>
          <TextInput
            style={{
              fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
              fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
              backgroundColor: 'transparent',
              padding: 0,
              maxHeight: 50,
            }}
            value={messageText}
            onChangeText={text => {
              if (text && text.length <= 360) {
                setMessageText(text);
              } else if (text.length === 0) {
                setMessageText(text);
              }
            }}
            maxLength={360}
            placeholder={'Add your comment'}
            placeholderTextColor={defaultTheme.PRIMARY_TEXT_COLOR}
            cursorColor={defaultTheme.PRIMARY_TEXT_COLOR}
            textColor={defaultTheme.PRIMARY_TEXT_COLOR}
            mode="outlined"
            selectionColor={defaultTheme.PRIMARY_TEXT_COLOR}
            activeOutlineColor="transparent"
            outlineColor="transparent"
            returnKeyType="default"
            multiline={true}
            editable={!isMsgLoading}
          />
          <Text style={componentStyles.commentBoxWordCounter}>
            {' '}
            {messageText.length.toString()} / {360}
          </Text>
        </View>
      </View>
      {(sortedCurrentFriends && sortedCurrentFriends.length > 0) ||
      searchText ? (
        <View
          style={{
            marginTop: 15,
          }}>
          <TextInput
            autoFocus
            style={componentStyles.searchFriendInput}
            contentStyle={{ height: 48 }}
            placeholder={'Search friends'}
            placeholderTextColor={defaultTheme.PRIMARY_TEXT_COLOR}
            value={searchText}
            autoCorrect={false}
            activeOutlineColor="transparent"
            outlineStyle={styles.friendSearchTextInputOutline}
            mode="outlined"
            selectionColor={defaultTheme.PRIMARY_TEXT_COLOR}
            textColor={defaultTheme.PRIMARY_TEXT_COLOR}
            onChangeText={text => onFriendSearch(text)}
            left={
              <TextInput.Icon
                icon="magnify"
                color={defaultTheme.PRIMARY_TEXT_COLOR}
              />
            }
            right={
              searchText && searchText != '' ? (
                <TextInput.Icon
                  color={defaultTheme.PRIMARY_TEXT_COLOR}
                  icon="close"
                  onPress={() => {
                    onFriendSearch('');
                  }}
                />
              ) : (
                <></>
              )
            }
            returnKeyType="done"
            editable={!isMsgLoading}
          />
          <TouchableWithoutFeedback>
            <FlatList
              contentContainerStyle={{
                minHeight: 420,
                paddingBottom: 100,
                paddingTop: 25,
              }}
              style={[
                {
                  marginBottom: 15,
                  height: 310,
                  borderRadius: defaultTheme.CONTENT_RADIUS,
                },
              ]}
              data={sortedCurrentFriends}
              renderItem={renderItem}
              ListEmptyComponent={_listEmptyComponent}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item, index) => item.wallet}
              nestedScrollEnabled
              {...scrollHandlers}
              keyboardShouldPersistTaps="handled"
            />
          </TouchableWithoutFeedback>
          {selectedFriend.length > 0 ? (
            isMsgLoading ? (
              <ActivityIndicator
                size={18}
                color={defaultTheme.PRIMARY_TEXT_COLOR}
                style={componentStyles.sendPostBtn}
              />
            ) : (
              <Pressable
                style={componentStyles.sendPostBtn}
                onPress={sendReshareMessage}>
                <Feather name="send" color={'#F0F0F0'} size={18} />
              </Pressable>
            )
          ) : (
            <></>
          )}
        </View>
      ) : (
        <>
          {!loadingFriends && (
            <Text
              style={[
                styles.addFriendsMsgText,
                {
                  alignSelf: 'center',
                  textAlign: 'center',
                  padding: 30,
                  color: defaultTheme.PRIMARY_TEXT_COLOR,
                },
              ]}>
              {sortedCurrentFriends.length == 0 &&
                !searchText &&
                'No Friends Added'}
            </Text>
          )}
        </>
      )}
      {loadingFriends && mutualFriendsData?.length == 0 && (
        <AppLoaderComponent deemBg={false} />
      )}
    </View>
  );
};

const ReshareModalSheet = (props: SheetProps) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const scrollHandlers = useScrollHandlers<FlatList>(null);

  return (
    <ActionSheet
      ref={actionSheetRef}
      id={props.sheetId}
      drawUnderStatusBar
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      enableGesturesInScrollView={false}>
      <TouchableWithoutFeedback
        onPress={() => {
          hideKeyboard();
        }}>
        {Platform.OS === 'web' ? (
          <ReShareWebBlurView>
            <View
              style={{
                borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
                borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
                overflow: 'hidden',
                backgroundColor: 'rgba(0,0,0,0.2)',
              }}>
              <LinearGradient
                colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
                start={{ x: 0.2, y: 0 }}
                end={{ x: 1, y: 1 }}
                style={{ ...styles.maxWidthAdjust }}>
                <ReshareModalSheetContent
                  props={props}
                  actionSheetRef={actionSheetRef}
                  scrollHandlers={scrollHandlers}
                />
              </LinearGradient>
            </View>
          </ReShareWebBlurView>
        ) : (
          <BlurView
            tint="dark"
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}
            intensity={Platform.OS === 'android' ? 15 : 25}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <ReshareModalSheetContent
                props={props}
                actionSheetRef={actionSheetRef}
                scrollHandlers={scrollHandlers}
              />
            </LinearGradient>
          </BlurView>
        )}
      </TouchableWithoutFeedback>
    </ActionSheet>
  );
};
export default ReshareModalSheet;

const componentStyles = StyleSheet.create({
  container: { paddingHorizontal: 15, paddingVertical: 25 },
  row: { flexDirection: 'row', alignItems: 'center' },
  modalTitle: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_TEXT_COLOR,
    opacity: 0.6,
    marginTop: 15,
    marginBottom: 25,
  },
  commentBox: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    flexDirection: 'row',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    padding: 5,
    alignItems: 'center',
  },
  commentBoxWordCounter: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    marginLeft: 15,
  },
  sendPostBtn: {
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    alignSelf: 'flex-end',
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchFriendInput: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XXMEDIUM + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    height: 48,
  },
});
