import React, { FC } from 'react';
import { DataDetectorTypes, KeyboardType, View } from 'react-native';
import { TextInput, DefaultTheme } from 'react-native-paper';

import { styles } from './styles';
import { defaultTheme } from '../../themes/loot8';

interface TextInputComponentProps {
  type?: 'primary' | 'secondary';
  placeholder: string;
  setOnChange: (text: string) => void;
  value: string;
  leftIcon?: string | null;
  rightIcon?: string | null;
  onLeftIconPress?: () => void;
  onRightIconPress?: () => void;
  onFocus?: () => void;
  customRight?: any;
  keyboardType?: KeyboardType;
  dataDetectorTypes?: DataDetectorTypes;
  maxLength?: number;
  multiline?: boolean;
  height?: number;
}

const TextInputComponent: FC<TextInputComponentProps> = ({
  type = 'primary',
  placeholder,
  setOnChange,
  value,
  leftIcon,
  rightIcon,
  onLeftIconPress,
  onRightIconPress,
  customRight,
  keyboardType = 'default',
  dataDetectorTypes = 'none',
  maxLength = null,
  multiline = false,
  height = 48,
  onFocus,
}) => {
  const customTheme = {
    ...DefaultTheme,
    roundness: defaultTheme.BUTTON_RADIUS,
  };

  const renderIcon = (icon: string | null, onPress: () => void) =>
    icon ? <TextInput.Icon color="#fff" icon={icon} onPress={onPress} /> : null;

  return (
    <View
      style={[
        styles.wrapper,
        {
          height,
          backgroundColor:
            type === 'primary' ? defaultTheme.SECONDARY_COLOR : '#190733',
        },
        customRight && {
          paddingRight: 7.5,
        },
      ]}>
      <TextInput
        theme={customTheme}
        placeholder={placeholder}
        placeholderTextColor={defaultTheme.SECONDARY_TEXT_COLOR}
        underlineColor="transparent"
        activeUnderlineColor="transparent"
        cursorColor="rgb(219, 226, 237)"
        selectionColor="rgb(219, 226, 237)"
        autoCapitalize="none"
        style={{
          ...styles.container,
          height,
        }}
        multiline={multiline}
        contentStyle={{ fontFamily: defaultTheme.FONT_FAMILY_MEDIUM }}
        textColor={defaultTheme.PRIMARY_TEXT_COLOR}
        onChangeText={text => setOnChange(text)}
        value={value}
        autoCorrect={false}
        returnKeyType="done"
        left={renderIcon(leftIcon, onLeftIconPress)}
        right={!customRight ? renderIcon(rightIcon, onRightIconPress) : null}
        keyboardType={keyboardType}
        maxLength={maxLength}
        dataDetectorTypes={dataDetectorTypes}
        blurOnSubmit
        onFocus={onFocus}
      />
      {customRight && customRight}
    </View>
  );
};

export default TextInputComponent;
