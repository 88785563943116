export enum Category {
  OTHER,
  SPORTS,
  MUSIC,
  CELEBRITIES,
  EDUCATORS,
  BUSINESS,
  GAMING,
  ARTIST,
  FOUNDATIONS,
}
