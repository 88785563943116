import React, { useState, useEffect, useMemo, memo } from 'react';
import { Text, Linking } from 'react-native';
import { detectURL } from '../../helpers/Gadgets';
import urlParser from 'url';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { defaultTheme } from '../../themes/loot8';

const CreateURLs = ({ message, messageId }) => {
  const [messageURLs, setMessageURLs] = useState(null);

  const isMultiLineMessage = useMemo(
    () => (message?.includes('\n') ? true : false),
    [message],
  );
  const [multilineMessage, setMultiLineMessage] = useState([]);

  const splitMessage = useMemo(
    () => (isMultiLineMessage ? message.split('\n') : message?.split(' ')),
    [isMultiLineMessage, message],
  );

  useEffect(() => {
    if (isMultiLineMessage) {
      try {
        let concatList = [];
        splitMessage?.map((item, index) => {
          const val = item?.split(' ');
          concatList.push(val);
        });
        setMultiLineMessage(concatList);
      } catch (e) {
        LogToLoot8Console(e);
      }
    }
  }, [isMultiLineMessage]);

  useEffect(() => {
    if (splitMessage?.length > 0) {
      const urls = detectURL(message);
      let mapUrls = [];

      urls?.map((url, index) => {
        const uParser = urlParser.parse(url);
        mapUrls.push({
          url: url,
          hostName: uParser.hostname || uParser.host,
        });
      });
      setMessageURLs(mapUrls);
    }
  }, [splitMessage]);

  return (
    <>
      {!isMultiLineMessage &&
        splitMessage?.map((item, index) => {
          let havingURL = messageURLs?.filter((x: any) => {
            return item.indexOf(x?.hostName) > -1;
          });

          return (
            <Text
              style={{ color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR }}
              key={`${messageId}-${item}-${index}`}>
              {havingURL?.length > 0 ? (
                <>
                  <Text
                    style={{ textDecorationLine: 'underline' }}
                    onPress={() => Linking.openURL(havingURL[0].url)}>
                    {`${item}`}
                  </Text>
                  <Text> </Text>
                </>
              ) : (
                <Text>{`${item} `}</Text>
              )}
            </Text>
          );
        })}

      {isMultiLineMessage &&
        splitMessage?.map((item, index) => {
          return (
            <Text
              style={{ color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR }}
              key={`${messageId}-${item}-${index}`}>
              {multilineMessage[index]?.map((msg, indx) => {
                let havingURL = messageURLs?.filter((x: any) => {
                  return msg.indexOf(x?.hostName) > -1;
                });
                return (
                  <Text key={indx}>
                    {havingURL?.length > 0 ? (
                      <>
                        <Text
                          style={{
                            color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
                            textDecorationLine: 'underline',
                          }}
                          onPress={() => Linking.openURL(havingURL[0].url)}>
                          {`${msg}`}
                        </Text>
                        <Text> </Text>
                      </>
                    ) : (
                      <Text>{`${msg} `}</Text>
                    )}
                  </Text>
                );
              })}
              <Text>{'\n'}</Text>
            </Text>
          );
        })}
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  let isEqualItem = false;
  if (
    prevProps?.message === nextProps?.message &&
    prevProps?.messageId === nextProps?.messageId
  ) {
    isEqualItem = true;
  }
  return isEqualItem;
};

export default memo(CreateURLs, areEqual);
