import React, { useEffect, useState } from 'react';

import HOCContainer from '../components/HOCContainer';
import DirectMessage from '../components/Friends/directMessage';

function ChatScreen({ route }) {
  const { selectedFriend } = route?.params;
  const [explicitIsMutualFriend, setExplicitIsMutualFriend] = useState(
    route?.params?.friendAddress ? true : false,
  ); //coming from notification

  useEffect(() => {
    //Need this to handle scenario wherein application is in background with Friends page opened.
    if (route?.params?.friendAddress && route?.params?.friendAddress != '') {
      setExplicitIsMutualFriend(true);
      // getAndSetSelectedFriend(route?.params?.friendAddress);
    }
  }, [route?.params?.friendAddress, route?.params?.isManageFriend]);

  // LOCAL METHODS
  // const getAndSetSelectedFriend = async friendAddress => {
  //   let friend = friendsData.find(
  //     f => f.wallet?.toLowerCase() === friendAddress?.toLowerCase(),
  //   );
  //   if (!friend) {
  //     let friendData: any = allUsersData.find(
  //       (f: any) => f.wallet?.toLowerCase() === friendAddress?.toLowerCase(),
  //     );
  //     if (!friendData || friendData?.wallet === ZERO_ADDRESS) {
  //       let { userAttributes } = await IsUserExists({
  //         networkID: networkId,
  //         provider: staticProvider,
  //         address: friendAddress,
  //       });
  //       friendData = userAttributes;
  //     }
  //     if (friendData) {
  //       let status = (await getUserJSONdata(friendData?.wallet))?.status;
  //       let avatarURI = await getUserDetailsFromIPFS(friendData.avatarURI);
  //       friend = {
  //         id: friendData.id,
  //         wallet: friendData.wallet,
  //         name: friendData.name,
  //         avatarURI: avatarURI,
  //         status: status,
  //         oldStatus: status,
  //         dataURI: friendData.dataURI,
  //         isFriend: true,
  //         isMutualFriend: true,
  //         bgColor: pickBgColor(),
  //         collectibles: pickCollectibles(),
  //         isExist: true,
  //       };
  //     }
  //   }
  // };
  return (
    <DirectMessage
      user={selectedFriend}
      isMutualFriend={explicitIsMutualFriend}
    />
  );
}

export default HOCContainer({
  OriginalComponent: ChatScreen,
  withBottomTabs: false,
  isScrollEnabled: false,
});
