import { getAppConfiguration } from '../appconstants';
import { LogToLoot8Console } from './Loot8ConsoleLogger';

// Define the type for the app configuration
interface AppConfig {
  babeAndTheKids: {
    loginURL: string;
    collection: string;
    chainId: number;
  };
}

// Define the type for the wallet object
interface Wallet {
  address: string;
  signMessage(message: any): Promise<string>;
}

function getDigest(
  erc721contract: string,
  erc721chainId: number,
  timestamp: number,
): Buffer {
  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(`${erc721contract}:${erc721chainId}`, 'utf-8'));
  chunks.push(Buffer.from([timestamp]));

  return Buffer.concat(chunks);
}

async function generateSignature(
  erc721contract: string,
  erc721chainId: number,
  timestamp: number,
  wallet: Wallet,
): Promise<string> {
  const digest = getDigest(erc721contract, erc721chainId, timestamp);
  const signature = await wallet.signMessage(digest);

  return signature;
}

export const generateAccessDigitalExperienceURL = async (
  wallet: Wallet,
  setIsGeneratingUrl: (isGenerating: boolean) => void,
): Promise<string | undefined> => {
  setIsGeneratingUrl(true);
  try {
    const appConfig: AppConfig | undefined = await getAppConfiguration();

    if (!appConfig) {
      throw new Error('App configuration is missing');
    }

    // Stage #1. Generate signature
    LogToLoot8Console('Stage #1. Generating signature for the login');

    // ipfs configuration settings
    const {
      loginURL = undefined,
      collection = undefined,
      chainId = undefined,
    } = appConfig?.babeAndTheKids;

    if (!loginURL || !collection || !chainId) {
      throw new Error(
        'One or more babeAndTheKids configuration values are missing',
      );
    }

    const timestamp = Math.trunc(Date.now() / 1_000); // unix date in seconds
    const signature = await generateSignature(
      collection,
      chainId,
      timestamp,
      wallet,
    );
    const url = `${loginURL}?account=${wallet.address}&collection=${collection}:${chainId}&timestamp=${timestamp}&signature=${signature}`;
    LogToLoot8Console(`Signature generated, a full URL:\n\t${url}`);
    return url;
  } catch (e) {
    LogToLoot8Console('accessDigitalExperience Error: ', e);
    throw e; // Propagate the error so it can be handled by the caller
  } finally {
    setIsGeneratingUrl(false);
  }
};
