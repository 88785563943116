import React, { useState, useRef, useEffect } from 'react';
import { defaultTheme } from '../../themes/loot8';
import {
  Text,
  View,
  Dimensions,
  Clipboard,
  Platform,
  StyleSheet,
} from 'react-native';
import styles from '../../styles';
import { CatalogTypes } from '../../enums/catalog.enum';
import { delistCollectionForMarketPlace } from '../../slices/MarketPlaceSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import InfoLoader from '../Loader/InfoLoader';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import {
  loadCatalogCollectiable,
  loadCatalogPassport,
} from '../../slices/CatalogSlice';
import { ScreenName } from '../../enums/screen.enum';
import MarketPlaceConfirmationSheet from './MarketPlaceConfirmationSheet';
import { ActionSheetRef } from 'react-native-actions-sheet';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { getAnynetStaticProvider, isNativeChain } from '../../appconstants';
import { updatePassportMarketPlaceConfig } from '../../slices/PassportSlice';
import { getCollectibleDetails } from '../../slices/OfferSlice';
import { useAppSelector } from '../../hooks';
import { PublicMarketPlaceTabs } from '../../enums/marketPlace.enum';
import HOCContainer from '../HOCContainer';
import GradientPrice from '../GradientPrice';
import GradientButton from '../GradientButton';

const MarketPlaceUnlistCollection = ({
  route,
  navigation,
  setDynamicTitle,
}) => {
  const isWeb = Platform.OS === 'web';

  let pathConfig = null;

  if (isWeb) {
    const queryParams = new URLSearchParams(window.location.search);

    pathConfig = {
      path: window.location.pathname,
      queryParams: queryParams,
      getParam: query => {
        return queryParams.has(query) ? queryParams.get(query) : null;
      },
    };
  }

  let type = pathConfig ? pathConfig.getParam('type') : route?.params?.type;
  const collectionItem =
    pathConfig &&
    type === 'Passports' &&
    pathConfig.getParam('id') &&
    pathConfig.getParam('chainId') &&
    pathConfig.getParam('tokenId') &&
    pathConfig.getParam('type') &&
    pathConfig.getParam('price') &&
    pathConfig.getParam('seller') &&
    pathConfig.getParam('collectionData') &&
    pathConfig.getParam('uniqueId') &&
    pathConfig.getParam('listingType') &&
    pathConfig.getParam('passport') &&
    pathConfig.getParam('collection')
      ? {
          id: +pathConfig.getParam('id'),
          chainId: +pathConfig.getParam('chainId'),
          tokenId: +pathConfig.getParam('tokenId'),
          type: pathConfig.getParam('type'),
          price: +pathConfig.getParam('price'),
          seller: pathConfig.getParam('seller'),
          collectionData: JSON.parse(pathConfig.getParam('collectionData')),
          unique_id: +pathConfig.getParam('uniqueId'),
          listingType: +pathConfig.getParam('listingType'),
          passport: pathConfig.getParam('passport'),
          collection: pathConfig.getParam('collection'),
        }
      : route?.params?.collection;

  const dispatch = useDispatch<AppDispatch>();
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const { networkId, staticProvider, address, wallet, userInfo } =
    useWeb3AuthContext();

  const [showMessageTitle, setShowMessageTitle] = useState('Congratulations');
  const [showMessageSubTitle, setShowMessageSubTitle] = useState(
    `${
      type == CatalogTypes.COLLECTIBLES ? 'collectible' : 'ExPass'
    } has been unlisted`,
  );
  const [purchaseInProgress, setPurchaseInProgress] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const entityData = useAppSelector(state => state.Entity.EntityData);

  let selectCollectionType =
    type == CatalogTypes.PASSPORT ? 'ExPass' : 'Collectible';

  const navigateBack = () => {
    if (Platform.OS === 'web') {
      window.history.replaceState(null, '', '/');
    }
    actionSheetRef.current.hide();
    if (transactionSuccess) {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: ScreenName.WALLET_TAB,
            params: {
              screen: ScreenName.MARKET_PLACE,
              params: {
                selectedPassportCategory:
                  type == CatalogTypes.COLLECTIBLES
                    ? PublicMarketPlaceTabs.COLLECTIBLES
                    : PublicMarketPlaceTabs.EXPASSES,
                catalogType: type,
              },
            },
          },
        ],
      });
    } else if (route.params.fromHome) {
      navigation.reset({
        index: 0,
        routes: [{ name: ScreenName.HOME_TAB }],
      });
    } else {
      navigation.goBack();
    }
  };

  const navigateToCollectible = () => {
    actionSheetRef.current.hide();
    navigation.navigate('MoreTab', {
      screen: ScreenName.CATALOG_DETAILS,
      params: {
        catalog: collectionItem?.collectionData,
        availableCatalogs: null,
        type: type,
        from: ScreenName.MARKET_PLACE_BUY_COLLECTIBLE,
      },
      initial: false,
    });
  };

  const onUnlistCollection = async () => {
    setPurchaseInProgress(true);

    let chainId = collectionItem.chainId;
    let provider = getAnynetStaticProvider(chainId);

    let response = await dispatch(
      delistCollectionForMarketPlace({
        networkID: chainId,
        provider: provider,
        wallet,
        address,
        collectibleAddress: collectionItem?.collection,
        tokenId: collectionItem?.tokenId,
        listingType: collectionItem?.listingType,
        price: collectionItem?.price,
        chainId: collectionItem?.chainId,
        listingID: collectionItem?.id,
      }),
    );

    if (response && response.payload) {
      const collectibleDetails = await getCollectibleDetails(
        {
          networkID: chainId,
          provider: provider,
          collectibleAddress: collectionItem?.collection,
          address,
          wallet,
        },
        { entityData },
        { isCache: true, isBalanceRefresh: false, isMarketPlaceRefresh: true },
      );

      if (type == CatalogTypes.COLLECTIBLES) {
        await dispatch(
          loadCatalogCollectiable({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            userInfo,
          }),
        );
      } else {
        await dispatch(
          updatePassportMarketPlaceConfig({
            address: collectionItem?.collection,
            marketPlaceConfig: collectibleDetails.marketPlaceConfig,
          }),
        );
        await dispatch(
          loadCatalogPassport({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            userInfo,
          }),
        );
      }
      navigation.navigate('Success', {
        screen: ScreenName.SUCCESS_SCREEN,
        params: {
          message: `${
            type == CatalogTypes.COLLECTIBLES ? 'collectible' : 'ExPass'
          } has been unlisted`,
          navigateTo: 'MoreTab',
          navigationParams: {
            screen: ScreenName.CATALOG_DETAILS,
            params: {
              catalog: collectionItem?.collectionData,
              availableCatalogs: null,
              type: type,
              from: ScreenName.MARKET_PLACE_BUY_COLLECTIBLE,
            },
            initial: false,
          },
          buttonLabel: `Open ${selectCollectionType}`,
        },
      });
    } else {
      setTransactionSuccess(false);
      setShowMessageTitle('Transaction Failed');
      setShowMessageSubTitle('Please try after sometime');
      setTimeout(() => {
        actionSheetRef.current.show();
      }, 500);
    }

    setPurchaseInProgress(false);
  };
  const copyToClipboard = () => {
    Clipboard.setString(collectionItem?.collectionData?.address);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  useEffect(() => {
    //Setting dynamic title for hoc
    setDynamicTitle(`Unlist ${selectCollectionType}`);
  }, []);
  return (
    <>
      <Text style={componentStyles.headline}>
        Please verify the details below. Once confirmed, the transaction cannot
        be undone.
      </Text>
      <View style={componentStyles.contentContainer}>
        <CardRow
          leftText={`${selectCollectionType} Name`}
          rightText={collectionItem?.collectionData?.name}
        />
        <View style={componentStyles.separator} />
        <CardRow
          copyToClipboard={copyToClipboard}
          isCopied={isCopied}
          leftText={`Collection Address`}
          rightText={
            Dimensions.get('window').width > 480
              ? collectionItem?.collectionData?.address
              : collectionItem?.collectionData?.address.substring(0, 5) +
                '...' +
                collectionItem?.collectionData?.address.substring(
                  collectionItem?.collectionData?.address.length - 5,
                  collectionItem?.collectionData?.address.length,
                )
          }
        />
        <View style={componentStyles.separator} />
        <CardRow
          leftText={`Price`}
          rightText={collectionItem?.price}
          rightTokenImage
        />
        <View style={componentStyles.separator} />
        <CardRow leftText={`Token Id`} rightText={collectionItem?.tokenId} />
      </View>
      <View style={{ flex: 1 }} />
      <GradientButton
        disabled={purchaseInProgress}
        onPress={() =>
          transactionSuccess ? navigateToCollectible() : onUnlistCollection()
        }
        buttonLabel={
          purchaseInProgress
            ? `Unlist ${selectCollectionType}`
            : transactionSuccess
              ? `Open ${selectCollectionType}`
              : `Unlist ${selectCollectionType}`
        }
      />

      {/* Confirmation Sheet */}
      <MarketPlaceConfirmationSheet
        actionSheetRef={actionSheetRef}
        transactionSuccess={transactionSuccess}
        onHideConfirmationSheet={navigateBack}
        showMessageTitle={showMessageTitle}
        showMessageSubTitle={showMessageSubTitle}
        buttonTitle={
          transactionSuccess ? `Open ${selectCollectionType}` : 'Okay'
        }
        onPressButton={navigateToCollectible}
      />

      {purchaseInProgress && (
        <InfoLoader text1="Please sit tight while we are processing your request." />
      )}
    </>
  );
};

export default HOCContainer({
  OriginalComponent: MarketPlaceUnlistCollection,
});
const componentStyles = StyleSheet.create({
  headline: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    width: '60%',
    alignSelf: 'center',
    marginBottom: 25,
  },
  contentContainer: {
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    padding: 15,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardLeftText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  cardRightText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  leftSubtitleText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    marginTop: 2.5,
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    marginVertical: 15,
  },
});

interface CardRowProps {
  leftText?: string;
  subTitle?: string;
  rightText?: any;
  rightTokenImage?: boolean;
  socialRight?: boolean;
}

const CardRow: React.FC<CardRowProps> = ({
  leftText,
  subTitle,
  rightText,
  rightTokenImage,
  copyToClipboard,
  isCopied,
}) => {
  return (
    <>
      <View style={componentStyles.row}>
        <Text style={componentStyles.cardLeftText}>{leftText}</Text>
        <View style={componentStyles.row}>
          {rightTokenImage ? (
            <GradientPrice price={rightText} />
          ) : (
            <View style={componentStyles.row}>
              <Text style={componentStyles.cardRightText}>{rightText}</Text>
              {leftText === 'Collection Address' && (
                <>
                  <Icon
                    style={{ marginLeft: 5 }}
                    name="clipboard-multiple"
                    size={14}
                    color="#FFFFFF"
                    onPress={() => {
                      copyToClipboard();
                    }}
                  />
                  {isCopied && (
                    <View style={styles.drawerCopyTextContainer}>
                      <Text
                        style={{
                          color: defaultTheme.PRIMARY_TEXT_COLOR,
                          fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                          fontSize: 8,
                        }}>
                        Copied!
                      </Text>
                    </View>
                  )}
                </>
              )}
            </View>
          )}
        </View>
      </View>
    </>
  );
};
