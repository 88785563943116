import { Dimensions, Platform, StyleSheet, ViewStyle } from 'react-native';
import { defaultTheme } from './themes/loot8';

const landingStyles = {
  landingContainer: {
    backgroundColor: defaultTheme.MAIN_SECOND_BACKGROUND_COLOR,
    flex: 1,
  },
  loginContainer: {
    flex: 1,
  },
  landingHeader: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#333360',
    height: 80,
  },
  loginProviderContainer: {
    margin: 10,
  },
  loginProviderInnerContainer: {
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    padding: 10,
    borderRadius: 58,
    borderWidth: 2,
    borderColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
  },
  loginProviderImage: {
    height: 35,
    width: 35,
  },
  loginProviderSurface: {
    borderRadius: 60,
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
  },
  loginProviderCaption: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    alignSelf: 'center',
  },
  loginProvierGradientContiner: {
    flex: 1,
    borderRadius: 5,
  },
};

const passportStyles = {
  ...landingStyles,
  closeParent: {
    // width:'100%',
    position: 'absolute',
    zIndex: 10,
    right: -10,
    top: 5,
  },
  closeBtnContainer: {
    alignSelf: 'flex-end',
    zIndex: 4,
    // top: 40,
    // right: 0,
    position: 'absolute',
  },
  passportView: {
    paddingTop: 0,
  },
  passportTileContainer: {
    width: '100%',
    height: '100%',
  },
  passportTile: {
    padding: 5,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    width: '100%',
    height: 200,
    marginBottom: 0,
  },
  passportTileBackgroundImage: {
    width: '100%',
    height: '100%',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  passportImageContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  passportNameContainer: {
    // backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    top: '35%',
    // marginTop: 'auto',
    // marginBottom: 'auto',
    alignSelf: 'flex-end',
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
    minHeight: 90,
    maxHeight: 145,
    paddingHorizontal: 10,

    position: 'absolute',
  },
  passportName: {
    marginTop: 'auto',
    marginBottom: 'auto',
    alignSelf: 'flex-end',
    padding: 5,
    flexDirection: 'row',
  },
  passportNameIdContainer: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  passportNameIdText: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    textAlign: 'left',
    fontSize: defaultTheme.FONT_SIZE_11,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  passportNameIdTextWrap: {
    flexWrap: 'wrap',
    // wordBreak: 'break-word'
  },
  passportId: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    lineHeight: 26,
    paddingBottom: 2,
  },
  passportText: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'right',
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    lineHeight: 28,
  },
  actionSheetHideButton: {
    height: 7,
    width: 70,
    alignSelf: 'center',
    backgroundColor: '#fff',
    marginBottom: 4,
    borderRadius: 7 / 2,
  },
  passportActionConfirmationHeaderText: {
    marginLeft: -35,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  passportActionConfirmationSubHeadingText: {
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    textAlign: 'center',
    color: '#DBE2ED',
  },
  passportActionSummaryItemTitle: {
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  passportActionSummaryItemValue: {
    flexWrap: 'wrap',
    // wordBreak: 'break-word',
    marginLeft: 5,
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'right',
    textAlignLast: 'right',
  },
  passportActionDescriptionText: {
    flexWrap: 'wrap',
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'left',
    textAlignLast: 'left',
  },
  passportActionSuccessModalHeaderText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_SMALLX,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  passportActionSuccessModalSubHeaderText: {
    marginTop: 10,
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: '#DBE2ED',
    textAlign: 'center',
  },
  passportActionSuccessModalButton: {
    height: 50,
    backgroundColor: '#F4B540',
    justifyContent: 'center',
    borderRadius: 6,
  },
  passportActionSuccessModalButtonText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    color: '#000000',
  },
  passportSubscribeSheetContent: {
    alignItems: 'center',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  passportOptionsSheetContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  passportOptionsMenu: {
    width: 32,
    height: 23,
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
    backgroundColor: '#FFFFFFE0',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
  },
  passportSortingMenuContainer: {
    width: 195,
    height: 175,
    flexDirection: 'column',
    padding: 10,
    borderRadius: 6,
    position: 'absolute',
    right: 0,
    top: 50,
    zIndex: 1000,
  },
  passportSortingHeaderText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_11,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  passportSortingOptionsText: {
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_11,
    marginLeft: 8,
  },
  passportActionsText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
  },
  passportActionContainer: {
    flexDirection: 'row',
    paddingVertical: 10,
    width: '100%',
    alignItems: 'center',
  },
  passportSubscribeModalHeaderText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    alignSelf: 'center',
    marginVertical: 15,
  },
  contentMultiHeader: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  contentHeaderLeft: {
    width: '10%',
    // backgroundColor: defaultTheme.TEXT_COLOR_SUBTITLE_SHAWDOW,
    borderRadius: 50,
  },
  contentHeaderContent: {
    width: '80%',
    textAlign: 'center',
  },
  contentHeaderText: {
    fontWeight: defaultTheme.FONT_WEIGHT_XLARGE,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  contentHeaderRight: {
    width: '10%',
  },
  passportMessageContainer: {
    width: '100%',
    backgroundColor: '#F4B540',
    height: 60,
    justifyContent: 'center',
    flexDirection: 'row',
  },
  passportMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  passportMessageText: {
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  flexDisplay: {
    flexDirection: 'row',
    borderRadius: 5,
    //margin: 5
  },
  bottomDisplay: {
    flexDirection: 'row',
    marginVertical: 10,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    justifyContent: 'space-between',
    // padding:7,
    // paddingBottom:14,
    //maxHeight:'65%'
    marginBottom: 25,
    //borderRadius: 10
  },
  passportBtnContainer: {
    textAlign: 'center',
    // flex: 0.48,
  },
  passpordBtn: {
    // height: 50,
    backgroundColor: '#DBE2ED',
    borderRadius: 10,
    justifyContent: 'center',
    textAlign: 'center',
    margin: 5,
    paddingTop: 5,
    paddingBottom: 5,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  passportContainer: {
    flex: 1,
  },
  passpordBtnText: {
    textAlign: 'center',
    color: '#BDC6D5',
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  passpordCMSText: {
    textAlign: 'center',
    color: '#BDC6D5',
    letterSpacing: 1,
    // fontWeight: defaultTheme.FONT_WEIGHT_XLARGE,
  },
  boxWithShadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 3,
  },
  backgroundImageBadge: {
    width: '100%',
    height: '100%',
  },
  boldText: {
    fontWeight: defaultTheme.FONT_WEIGHT_XLARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    textAlign: 'center',
    justifyContent: 'center',
  },
  featureHeaderText: {
    // fontWeight: defaultTheme.FONT_WEIGHT_XLARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    textAlign: 'center',
    justifyContent: 'center',
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
  },
  featureHeaderContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  PassportDetailNoOfferContainer: {
    flex: 1,
    backgroundColor: '#0A2850',
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 160,
  },
  passportDetailNoOfferText: {
    marginVertical: '30%',
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    alignSelf: 'center',
    marginHorizontal: 10,
    textAlign: 'center',
    color: '#fff',
  },
  passportDetailViewAllText: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
  },
  boldTextSmall: {
    fontWeight: defaultTheme.FONT_WEIGHT_XLARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    textAlign: 'center',
    justifyContent: 'center',
  },
  infoMessageContainer: {
    // margin: 'auto',
    // width: '100%',
    // height: '100%',
    textAlign: 'center',
    // justifyContent: 'center',
    paddingTop: 10,
  },
  passportCollectibleItemsLoaderContainer: {
    minHeight: 170,
    marginTop: 10,
  },
  infoMessage: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
  },
  collectionHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 1,
    flexWrap: 'wrap',
    alignSelf: 'center',
  },
  counterCircle: {
    width: 18,
    height: 18,
    marginLeft: 6,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFD400',
  },
  counterBubbleText: {
    color: '#111',
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
  },
  counterText: {
    fontSize: 11,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    alignSelf: 'center',
    textAlign: 'center',
  },
  passportCollectionBottom: {
    backgroundColor: '#0A2850',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
  },
  scrollHeight: {
    maxHeight: '100%',
    //height:'90%',
    //overflow:'hidden'
  },
  passportCollectiable: {
    flex: 1,
  },
  passportHTMLCollectiable: {
    position: 'absolute',
    left: 0,
    top: 0,
    flex: 1,
    width: '100%',
    height: '100%',
  },
  passportCollectiableContainor: {
    flex: 1,
    backgroundColor: '#0A2850',
    borderRadius: 6,
  },
  passportoffersItemContainer: {
    flex: 1,
    width: '100%',
    marginTop: 2,
  },
  passportoffersContainer: {
    width: '100%',
    flex: 1,
  },
  passportoffersCaptionContainer: {
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    alignItems: 'center',
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
    height: 30,
    justifyContent: 'center',
  },
  passportoffersCaption: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontWeight: '600',
    letterSpacing: 1,
    padding: 5,
    textAlign: 'center',
  },
  passportOffersimage: {
    height: '100%',
    aspectRatio: 1 / 1,
    borderWidth: 0.5,
    borderColor: '#5A5A5A1F',
    borderRadius: 0,
  },
  passportoffersDataContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  passportoffersImageContainer: {
    flex: 0.6,
  },
  passportoffersValueContainer: {
    flex: 0.5,
    height: '100%',
  },
  passportoffersOrderContainer: {
    width: '100%',
    height: 25,
  },
  passportoffersTileContainer: {
    borderColor: defaultTheme.BORDER_COLOR_PRIMARY,
    //   borderWidth: 1,
    //  borderStyle: 'solid',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    height: 190,
  },
  messageActionView: {
    minWidth: 50,
  },
};

const catalogStyles = {
  ...passportStyles,
  catalogImageContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  catlogContentHeader: {
    padding: 10,
  },
  catalogBannerImage: {
    width: '100%',
    height: '100%',
    //resizeMode:"cover",
    //borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  catalogTileContainer: {
    height: 140,
    width: '100%',
    paddingHorizontal: 20,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    justifyContent: 'center',
    alignContent: 'center',
    // backgroundColor: '#333360',
    alignSelf: 'center',
    padding: 5,
    margin: 10,
  },
  catalogTitleContainerLg: {
    height: 90,
    paddingHorizontal: 20,
    minWidth: '30vw',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    marginTop: '1rem',
  },
  catalogActiveTab: {
    shadowOffset: {
      width: 0,
      height: 10,
    },
    shadowRadius: 20,
    shadowColor: '#0000004D',
  },
  catalogNameContainer: {
    backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    top: '30%',
    marginTop: 'auto',
    marginBottom: 'auto',
    alignSelf: 'flex-end',
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
    width: '45%',
    height: '45%',
    position: 'absolute',
    padding: 15,
    justifyContents: 'center',
  },
  catalogName: {
    marginTop: 'auto',
    marginBottom: 'auto',
    alignSelf: 'center',
    paddingBottom: 10,
  },
  catalogText: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    textAlign: 'center',
    fontWeight: defaultTheme.FONT_WEIGHT_XLARGE,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  backButtonTextCat: {
    color: '#f569a6',
    textAlign: 'center',
    fontWeight: defaultTheme.FONT_WEIGHT_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  catContentContainer: {
    backgroundColor: defaultTheme.CONTENT_BACKGROUND_COLOR,
    paddingTop: 0,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    paddingBottom: 30,
  },
  catContentContainerDetails: {
    backgroundColor: defaultTheme.CONTENT_BACKGROUND_COLOR,
    paddingTop: 0,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    paddingBottom: 20,
  },
  catContentContainerEmpty: {
    backgroundColor: defaultTheme.CONTENT_BACKGROUND_COLOR,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    height: '100%',
    maxHeight: '100%',
  },
  catalogDetailsTop: {
    width: '100%',
    height: Platform.OS == 'ios' ? '55%' : '65%',
    maxHeight: '65%',
  },
  catalogDetailsBottom: {
    width: '100%',
    height: '20%',
    maxHeight: '20%',
    margin: 5,
    alignSelf: 'center',
  },
  catalogDetailsText: {
    lineHeight: 18,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
  collectibleNameContainer: {
    backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    bottom: '15%',
    marginTop: 'auto',
    marginBottom: 'auto',
    alignSelf: 'flex-end',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    width: '75%',
    height: 80,
    paddingHorizontal: 10,
    position: 'absolute',
  },
  collectibleName: {
    marginTop: 'auto',
    marginBottom: 'auto',
    alignSelf: 'flex-end',
    // paddingBottom:10
  },
  collectibleText: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  collectibleId: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: defaultTheme.TEXT_COLOR_SUBTITLE,
    textAlign: 'right',
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  extCollectible: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    textAlign: 'right',
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  collectibleImageContainer: {
    width: 66,
    height: 54,
    borderRadius: 8,
    backgroundColor: '#000',
    overflow: 'hidden',
    // border: '1px solid #FFFFFF66',
    borderWidth: 1,
    borderColor: '#FFFFFF66',
  },
  collectibleItemMenuButton: {
    alignSelf: 'flex-end',
    top: -1,
    right: 0,
    // height: 25,
    // width: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  collectibleImageCanvas: {
    width: '100%',
    height: '100%',
    // aspectRatio:0.9/1
  },
  portfolioNameContainer: {
    backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    top: '22%',
    marginTop: 'auto',
    marginBottom: 'auto',
    alignSelf: 'flex-end',
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
    minHeight: 90,
    maxHeight: 145,
    position: 'absolute',
    //maxWidth:'60%',
    paddingHorizontal: 10,
    shadowColor:
      Platform.OS === 'ios' ? 'rgba(0,0,0, 0.1)' : 'rgba(0,0,0, 0.3)', // ())?ISO:Android
    shadowOffset: { height: 1, width: 1 }, // IOS
    shadowOpacity: 1, // IOS
    shadowRadius: 1, //IOS
    elevation: 7, // Android,
  },
  portfolioName: {
    marginTop: 'auto',
    marginBottom: 'auto',
    padding: 5,
  },
  portfolioId: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: defaultTheme.TEXT_COLOR_SUBTITLE,
    textAlign: 'right',
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    lineHeight: 26,
  },
  portfolioText: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'right',
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    lineHeight: 28,
    //width:'100%'
  },

  catalogDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },

  portfolioListTileContainer: {
    width: '100%',
    height: '60px',
    //border-radius: 8 0 0 0;
    //opacity: 0,
    //background: '#232336',
    boxShadow: '0px 4px 4px 0px #00000040',
  },
};
const walletStyles = {
  ...catalogStyles,
  rowContainer: {
    flexDirection: 'row',
  },
  columnContainer: {
    flexDirection: 'column',
  },
  centerContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  walletHeaderContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 10,
  },
  walletButtonContainer: {
    marginVertical: 10,
    alignContent: 'center',
    height: 120,
    flexDirection: 'row',
    borderRadius: 10,
    backgroundColor: defaultTheme.BUTTON_QUARTERNARY_BACKGROUND_COLOR,
    width: '100%',
    maxWidth: 600,
  },
  balanceContainerEllipse: {
    alignContent: 'center',
    width: 27,
    height: 27,
    position: 'absolute',
    borderRadius: 15,
    backgroundColor: defaultTheme.TEXT_COLOR_SECONDARY,
    left: 212,
    top: 33,
  },
  balanceOuter: {
    width: 300,
    height: 300,
    alignSelf: 'center',
  },
  balanceContainer: {
    width: 280,
    height: 280,
    backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    alignSelf: 'center',
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  balanceContainerText: {
    fontSize: 60,
    //fontWeight:defaultTheme.FONT_WEIGHT_XLARGE,
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    marginHorizontal: 10,
    //padding:5,
    lineHeight: defaultTheme.FONT_SIZE_XXLARGE * 1.2,
    top: Platform.OS === 'ios' ? 7 : 12,
  },
  pointsContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  pointContainerText: {
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontWeight: defaultTheme.FONT_WEIGHT_MEDIUM,
    bottom: 5,
    // backgroundColor:defaultTheme.MAIN_BACKGROUND_COLOR,
  },
  purchaseButtonWallet: {
    width: '95%',
    height: '100%',
    backgroundColor: '#DBE2ED', //defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    alignSelf: 'center',
    borderRadius: defaultTheme.BUTTON_RADIUS,
    alignItems: 'center',
    padding: 5,
    justifyContent: 'center',
    flex: 1,
  },
  bottomButtonWallet: {
    width: '49%',
    height: '100%',
    backgroundColor: defaultTheme.BUTTON_QUARTERNARY_BACKGROUND_COLOR,
    borderBottomStartRadius: defaultTheme.BUTTON_RADIUS,
    borderBottomRightRadius: defaultTheme.BUTTON_RADIUS,
    borderColor: defaultTheme.BUTTON_BORDER_COLOR,
    alignItems: 'center',
  },
  bottomButtonText: {
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    textAlign: 'center',
    color: '#F7F6F8', //defaultTheme.TEXT_COLOR_SECONDARY,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    opacity: 0.2,
    lineHeight: 33,
  },
  purchaseText: {
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    color: '#BDC6D5', //defaultTheme.TEXT_COLOR_SUBTITLE_SHAWDOW,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    //fontWeight:defaultTheme.FONT_WEIGHT_LARGE,
    justifyContent: 'center',
    //paddingTop:5,
    lineHeight: 25,
  },
  comingSoonContainer: {
    position: 'absolute',
    alignSelf: 'center',
    flexDirection: 'row',
    width: 160,
    height: 25,
    zIndex: 3,
    top: -10,
  },
  qButtonContainer: {
    position: 'absolute',
    alignSelf: 'flex-end',
    top: 5,
    right: 10,
    width: 50,
    height: 50,
  },
  qIcon: {
    color: '#53536E',
    justifyContent: 'center',
    fontWeight: '600',
    fontSize: 30,
  },
  walletNolinkedText: {
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
  },
  walletAddressText: {
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
  },

  walletButton: {
    width: '48%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  walletButtonHairline: {
    height: '75%',
    width: 3,
    backgroundColor: defaultTheme.HAIRLINE,
    alignSelf: 'center',
    opacity: 0.2,
  },

  linkAccountTitleText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    color: defaultTheme.TEXT_COLOR_SECONDARY,
  },
};

const mainStyles = {
  ...walletStyles,

  containerInnerTop: {
    flex: 1,
    alignContent: 'center',
    minHeight: '80%',
    justifyContent: 'center',
  },
  containerInnerBottom: {
    flex: 1,
    alignContent: 'center',
    width: '100%',
  },
  pageTitleContainer: {
    alignItems: 'center',
  },
  pageTitleArea: {
    backgroundColor: '#4D4D6F',
    width: Dimensions.get('window').width - 200,
    alignItems: 'center',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  pageTitle: {
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    letterSpacing: 2,
  },

  mainContainer: {
    paddingVertical: 5,
    // paddingHorizontal: 15,
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  mainContainerPassDetails: {
    // backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    alignSelf: 'center',
    width: '100%',
  },
  mainGradientContainer: {
    padding: 15,
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    // alignItems: "center",
  },

  contentContainer: {
    paddingBottm: 10,
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  mobileNoLocationSubContainer: {
    paddingBottm: 10,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    padding: 5,
  },
  MapLocationOffContainer: {
    paddingBottm: 10,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    // width: Dimensions.get('window').width <= 480 ? '100%' : '80%',
    margin: 20,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    paddingRight: 5,
  },
  mapLocationOffTextContainer: {
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  manageFriendHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 4,
    // height: 60,
  },
  headerBackButton: {
    width: 20,
    height: 20,
    alignItems: 'center',
    marginTop: 8,
  },
  headerButtonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerButton: {
    height: 30,
    width: 30,
    backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30 / 2,
    marginRight: 5,
  },
  headerTextContainer: {
    // width: '90%',
    flex: 0.6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tileContainer: {
    width: '100%',
    // height: '100%',
    paddingBottom: 10,
  },

  tileContainerPassDet: {
    width: '100%',
    // height: '100%',
  },
  tile: {
    padding: 5,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    // width: '100%',
    // height: 200,
    marginBottom: 0,
  },
  tileBackgroundImage: {
    // width: '100%',
    aspectRatio: 41 / 25,
    // height: '100%',
    minHeight: 150,
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  tilesWrap: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  contentHeaderText: {
    textAlign: 'center',
    justifyContent: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
  },
  mapContentHeaderText: {
    width: '100%',
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  pageHeaderText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  contentHeaderTextSecondary: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  contentHeaderTextXXSmall: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_XXSMALL,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  contentHeaderTextXXSmallSecondary: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_XXSMALL,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
  },
  contentHeaderTextXSmall: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_XSMALL,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
  },
  contentHeaderTextXSmallSecondary: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_XSMALL,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
  },
  contentHeaderTextSmall: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_SMALL,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
  },
  contentHeaderTextSmallSecondary: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_SMALL,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
  },
  contentHeaderTextMedium: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
  },
  contentHeaderTextMediumSecondary: {
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  mapContentHeaderTextMediumSecondary: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    textAlign: 'center',
    width: '95%',
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
  },
  contentHeaderTextLarge: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
  },
  contentHeaderTextLargeSecondary: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontSize: 33,
  },
  contentHeaderTextXL: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontWeight: defaultTheme.FONT_WEIGHT_XLARGE,
    fontSize: defaultTheme.FONT_SIZE_XLARGE,
  },
  contentHeaderTextXLSecondary: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
    //justifyContent:"center",
    fontFamily: 'Poppins-Black',
    // fontWeight: defaultTheme.FONT_WEIGHT_XLARGE,
    fontSize: 43,
  },

  text: {
    color: defaultTheme.TEXT_SEARCH_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
  },

  backButtonText: {
    height: 40,
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    alignSelf: 'flex-start',
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    justifyContent: 'center',
    padding: 10,
    flexDirection: 'row',
  },

  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    fontWeight: '600',
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontWeight: '400',
  },
  highlight: {
    fontWeight: '700',
  },
  appBackground: {
    backgroundColor: '#0A2850',
  },
  dockBackground: {
    backgroundColor: '#0A2850',
  },
  dockIconInactive: {
    backgroundColor: '#53536E',
  },
  dockIconActive: {
    backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
  },
  backgroundImage1: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    // backgroundColor: '#28284A'
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
  },
  closeContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'flex-end',
    paddingRight: 5,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIcon: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    justifyContent: 'center',
    fontWeight: '600',
  },
  button: {
    backgroundColor: '#3388EE',
    minWidth: 50,
    minHeight: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    borderColor: defaultTheme.BORDER_COLOR_PRIMARY,
    borderWidth: 1,
    margin: 2,
    padding: 2,
  },

  // Header Styles //
  headerLogoView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: Platform.OS === 'ios' ? 45 : 0,
  },
  headerLogoImage: {
    height: 35,
    width: 120,
    resizeMode: 'contain',
  },
  headerProfileView: {
    flexDirection: 'row',
    marginRight: 10,
  },
  headerProfileImage: {
    borderRadius: 55,
    width: 55,
    height: 55,
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationIconView: {
    height: 27,
    width: 17,
    borderRadius: 60,
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
  },
  notificationIcon: {
    backgroundColor: 'white',
    marginLeft: -10,
    marginTop: -25,
    borderRadius: 5,
    minWidth: 20,
  },
  notificationIconText: {
    fontSize: 10,
    alignSelf: 'center',
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
  },
  fab: {
    position: 'absolute',
    width: 35,
    height: 35,
    borderRadius: 35 / 2,
    //right: 0,
    //right: Dimensions.get('window').width > 480 ? 50 : 5,
    //bottom: 5,
    backgroundColor: defaultTheme.BUTTON_FAB_BACKGROUND_COLOR,
  },

  // Modal Styles //

  modalBody: {
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },

  modalTextStyle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    letterSpacing: 1,
    textAlign: 'center',
  },
  modalNormalTextStyle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    letterSpacing: 0,
    textAlign: 'center',
  },
  // Transaction Styles //
  ordersList: {
    height: '93%',
    with: '100%',
  },

  OrdersValidityBanner: {
    width: '100%',
    justifyContent: 'center',
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR_DARKEN,
    paddingVertical: 7,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 9 },
    shadowOpacity: 0.4,
    shadowRadius: 4.65,
    elevation: 10,
  },

  orderListContainer: {
    marginVertical: 10,
    borderRadius: 5,
    overflow: 'hidden',
  },
  orderHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#0A2850',
  },
  orderTitle: {
    marginVertical: 4,
    marginLeft: 7,
  },
  orderStatusText: {
    color: '#FCFCFC',
    fontFamily: 'Poppins-Medium',
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    paddingHorizontal: 10,
    textAlign: 'center',
  },
  orderNameText: {
    color: '#FCFCFC',
    fontFamily: 'Poppins-Medium',
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
  },
  orderTileContainer: {
    // height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  orderStatus: {
    borderRadius: 16,
    paddingVertical: 2,
    marginRight: 7,
    alignItems: 'center',
    // maxWidth: '44%'
  },
  orderTile: {
    // height: 80,
    maxHeight: 120,
    flexDirection: 'row',
  },
  orderSubDetail: {
    backgroundColor: '#C63FB4',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subDetailText: {
    fontSize: 15,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    marginHorizontal: 20,
    marginVertical: 3,
    fontFamily: 'Poppins-Medium',
  },
  orderDetailContainer: {
    backgroundColor: '#782773',
    // height: 30,
    width: '100%',
    marginTop: -1,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
  },
  listImg: {
    width: '45%',
  },
  modalButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: 5,
  },
  disconnectButtonStyle: {
    backgroundColor: defaultTheme.BUTTON_TERTIARY_BACKGROUND_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderWidth: 1.5,
    borderStyle: 'solid',
    minWidth: 110,
    justifyContent: 'center',
  },
  disconnectButtonLabelStyle: {
    color: '#0A2850',
    fontSize: 20,
    lineHeight: 22,
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  modalNoButtonStyle: {
    backgroundColor: defaultTheme.BUTTON_TERTIARY_BACKGROUND_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderWidth: 1.5,
    borderStyle: 'solid',
    margin: 10,
    minWidth: 130,
    height: 44,
    justifyContent: 'center',
    marginHorizontal: 2,
  },
  modalNoButtonLabelStyle: {
    color: '#0A2850',
    //textTransform: 'capitalize',
    fontSize: 20,
    lineHeight: 22,
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    // paddingTop: Platform.OS != 'web' ? 3 : 0,
    paddingTop: Platform.OS === 'android' ? 3 : Platform.OS === 'ios' ? 4 : 0,
  },
  modalYesButtonStyle: {
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderWidth: 1.5,
    borderStyle: 'solid',
    margin: 10,
    minWidth: 130,
    height: 44,
    justifyContent: 'center',
    marginHorizontal: 2,
  },
  modalYesButtonLabelStyle: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    ///textTransform: 'capitalize',
    fontSize: 20,
    lineHeight: 22,
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    //paddingTop: Platform.OS != 'web' ? 3 : 0,
    paddingTop: Platform.OS === 'android' ? 3 : Platform.OS === 'ios' ? 4 : 0,
  },
  quantity: {
    fontSize: 26,
    lineHeight: 40,
    textAlignVertical: 'center',
    fontFamily: 'Poppins-SemiBold',
    color: '#FCFCFC',
  },
  hairline: {
    backgroundColor: '#A2A2A250',
    width: 1.2,
    marginVertical: 10,
  },

  modalSecondaryText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    fontSize: 22,
    // letterSpacing:1
    fontFamily: 'Poppins',
  },

  // Home Styles //
  passports: {
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
  },

  // Friends Styles //
  friendsListContainer: {
    paddingBottom: 4,
    flexDirection: 'column',
    // marginHorizontal: 15,
    marginBottom: 10,
    marginTop: 5,
  },
  friendsAvatar: {
    zIndex: 3,
    borderRadius: 30,
    // borderWidth: 2,
    // borderColor: defaultTheme.AVATAR_BORDER_COLOR,
    // borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    // paddingTop: -5,
    // paddingLeft: -5,
    padding: 16,
  },
  passportsAvatar: {
    zIndex: 3,
    borderWidth: 0.1,
    borderColor: defaultTheme.AVATAR_BORDER_COLOR,
    backgroundColor: '#AB339B',
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: -5,
    paddingLeft: -5,
    marginTop: 7,
    marginLeft: 3,
    height: 30,
    width: 30,
    borderRadius: 30 / 2,
  },
  notificationImage: {
    zIndex: 3,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: defaultTheme.AVATAR_BORDER_COLOR,
    borderStyle: 'solid',
    justifyContent: 'center',
  },
  userInfoModalContainer: {
    marginTop: 20,
    maxWidth: '100%',
  },
  friendsUserDetailContainer: {
    //backgroundColor: '#FCFCFC',
    //borderRadius: 6,
    //borderColor: '#DBE2ED',
    //borderWidth: 1,
    //borderStyle: 'solid',
    flex: 1,
    zIndex: 2,
    //paddingLeft: 15,
    flexDirection: 'column',
    marginLeft: 15,
    paddingRight: 8,
    justifyContent: 'center',
  },
  userTitle: {
    //flex: 10,
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    color: '#fff',
    // color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    //fontWeight: defaultTheme.FONT_WEIGHT_XLARGE
  },
  NameUserInfoModal: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    marginBottom: 10,
    textAlign: 'center',
    width: '90%',
    alignSelf: 'center',
  },
  StatusUserInfoModal: {
    color: defaultTheme.ACTIVE_PRIMARY_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    marginBottom: 20,
    textAlign: 'center',
    maxWidth: '90%',
    alignSelf: 'center',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD_ITALIC,
  },
  userStatusText: {
    color: defaultTheme.ACTIVE_PRIMARY_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
  },
  addFriendBtnContainer: {
    position: 'absolute',
    right: 15,
    top: 15,
  },
  addFriendBtn: {
    minWidth: 65,
    height: 30,
    padding: 5,
    paddingHorizontal: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F4B540',
    borderRadius: 8,
  },
  removeFriendBtnContainer: {
    flex: 1,
    marginTop: -1,
    marginRight: -2,
    backgroundColor: defaultTheme.BUTTON_TERTIARY_BACKGROUND_COLOR,
    // width: 18,
    height: 18,
    borderBottomLeftRadius: 2,
    borderTopRightRadius: 6,
  },
  modalAddFriend: {
    justifyContent: 'flex-start',
    //height: Dimensions.get('window').height,
    //width: Dimensions.get('window').width,
    // backgroundColor: 'rgba(37,37,70,0.80)',
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15,
    marginTop: 45,
    marginHorizontal: 5,
  },
  modalAddFriendContainer: {
    flexDirection: 'column',
    backgroundColor: defaultTheme.CONTENT_BACKGROUND_COLOR,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 3,
    marginTop: 50,
    //maxHeight: 600
  },
  friendSearchTextInput: {
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    backgroundColor: defaultTheme.TEXT_INPUT_BACKGROUND_COLOR,
    alignSelf: 'center',
    width: '95%',
    marginBottom: 15,
    paddingLeft: -20,
  },
  loginEmailContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: 15,
  },
  loginEmailButton: {
    // alignItems: "center",
    // paddingBottom: "auto",
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderColor: '#a2a2a24f',
    width: '95%',
    padding: 0,
    height: 52,
    alignSelf: 'center',
    marginBottom: '2%',
  },
  loginEmailTextInput: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    width: '95%',
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    borderBottomColor: '#A2A2A2',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderColor: '#d4d2d2',
    borderRadius: 4,
    borderStyle: 'solid',
  },
  addFriendsHeaderContainer: {
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    justifyContent: 'space-around',
    flexDirection: 'row',
    alignContent: 'center',
  },
  addFriendsHairLine: {
    borderBottomColor: '#DBE2ED',
    borderBottomWidth: 1,
    marginTop: 10,
    marginBottom: 10,
  },
  friendsHairLine: {
    borderBottomColor: '#DBE2ED',
    borderBottomWidth: 1,
    //marginTop: 5,
    marginRight: 15,
    marginLeft: 15,
    marginBottom: 15,
  },
  addFriendsMsgContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    marginTop: 100,
    marginBottom: 20,
  },
  addFriendsMsgText: {
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    textAlign: 'center',
    lineHeight: 20,
  },
  addFriendModelParent: {
    width: '100%',
    position: 'absolute',
  },
  addFriendCloseBtnContainerMain: {
    alignSelf: 'flex-end',
    zIndex: 4,
    top: -10,
    right: -15,
    position: 'absolute',
  },
  addFriendCloseBtnContainer: {
    alignSelf: 'flex-end',
    zIndex: 4,
    top: -10,
    right: -25,
    position: 'absolute',
  },
  addFriendListCotainer: {
    marginTop: '5%',
    marginBottom: '5%',
    //maxHeight: '82%'
  },
  closeBtnOuter: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.25)',
    width: 28,
    height: 28,
    borderRadius: 15,
  },
  friendSearchTextInputOutline: {
    // borderRadius: 6,
    borderColor: 'transparent',
    maxHeight: defaultTheme.INPUT_HEIGHT,
    borderRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
  },
  friendList: {
    paddingTop: 25,
    backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  friendsCountContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  friendsCountText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  friendsOptionsText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    marginTop: 1,
  },
  friendsMessgesHairLine: {
    borderBottomColor: '#D7E2EF',
    opacity: 0.2,
    borderBottomWidth: 1,
    marginRight: 25,
    marginLeft: 20,
    marginBottom: 5,
    marginTop: 5,
  },
  friendRequestButtonText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: '#000',
  },
  friendsRequestCountContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 5,
    paddingTop: 20,
    paddingBottom: 10,
  },
  friendRequestCountText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
  },

  // Catalog Styles //
  catalogContainer: {
    flex: 1,
    padding: 10,
    alignItems: 'center',
  },

  catalogBackgroundImage: {
    width: '100%',
    height: '100%',
  },

  // Offers Styles //
  offersLayout: {
    width: '100%',
  },
  offersMainContainer: {
    // backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    paddingbottom: 15,
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  offerTabsContainer: {
    flexDirection: 'row',
    height: 60,
    // backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    paddingBottom: 5,
  },
  offerTabsItems: {
    flex: 33.33,
    justifyContent: 'center',
    alignItems: 'center',
    shadowOffset: { width: 2, height: 0 },
    shadowOpacity: 0.7,
    shadowRadius: 15,
    elevation: 10,
  },
  offerTabsInActive: {
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    zIndex: 10,
  },
  offerTabsActive: {
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    zIndex: 100,
  },
  offerTabsRightBorder: {
    borderRightWidth: 0.2,
    borderRightStyle: 'solid',
    borderRightColor: '#5D0333',
  },
  offerTabsCaption: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    width: '80%',
  },
  offerContainer: {
    // backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    // paddingHorizontal: 20,
    paddingBottom: 30,
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  offersTypeHeader: {
    paddingTop: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  offersTypeHeaderTitle: {
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  offersTextColor: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
  },
  offersItemContainer: {
    flex: 1,
    width: '100%',
    marginTop: 20,
  },
  offersTileContainer: {
    borderRadius: defaultTheme.CONTENT_RADIUS,
    flex: 1,
    minHeight: 250,
    borderStyle: 'solid',
    borderColor: defaultTheme.CONTAINER_BORDER_COLOR,
    borderWidth: 1,
  },
  offersContainer: {
    width: '100%',
    flex: 1,
  },
  offersCaptionContainer: {
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    alignItems: 'center',
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
    height: 55,
    justifyContent: 'center',
  },
  offersCaption: {
    padding: 3,
    letterSpacing: 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
  },
  offersDataContainer: {
    flexDirection: 'row',
  },
  offersOrderContainer: {
    flex: 0.2,
  },
  offersOrderCaptionContainer: {
    height: 30,
    paddingBottom: 10,
    alignItems: 'center',
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderBottomRightRadius: defaultTheme.CONTENT_RADIUS,
  },
  offersOrderCaption: {
    padding: 5,
    marginTop: 12,
    letterSpacing: 1,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  offersImageContainer: {
    flex: 0.6,
  },
  offersValueContainer: {
    flex: 0.4,
  },
  offersValue: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  Offersimage: {
    width: '100%',
    aspectRatio: 1.6 / 1,
    minHeight: 150,
    backgroundColor: '#0A2850',
  },
  OffersDetailImage: {
    width: '100%',
    aspectRatio: 1.6 / 1,
    backgroundColor: '#0A2850',
    minHeight: 150,
  },
  offersPriceContainer: {
    flex: 0.4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // borderBottomWidth:1,
    // borderBottomStyle:'solid',
    width: '80%',
    alignSelf: 'center',
    // borderBottomColor: defaultTheme.BORDER_COLOR_PRIMARY
  },
  offersTokenEarnedContainer: {
    flex: 0.6,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  offersPriceCurrency: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  offersPrice: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  offersTokenEarnedValue: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  offersTokenEarnedImage: {
    height: 15,
    width: 15,
  },

  // offer details
  offersDetailContainer: {
    padding: 5,
    paddingTop: 20,
  },
  offersDetailValue: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    lineHeight: 22,
    fontWeight: defaultTheme.FONT_WEIGHT_XSMALL,
  },
  offersDetailDataContainer: {
    // flex: 0.67,
    flexDirection: 'row',
  },

  offersBottomBarContainer: {
    height: 60,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderBottomRightRadius: defaultTheme.CONTENT_RADIUS,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  offersDetailTileContainer: {
    borderColor: defaultTheme.CONTAINER_BORDER_COLOR,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    flex: 1,
  },
  offersDetailLayout: {
    // height:'70%',
  },
  offersDetailPurchaseButtonContainer: {
    //borderColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: '#DBE2ED', //defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR
    borderRadius: defaultTheme.BUTTON_RADIUS,
    minWidth: 120,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  offersBottomButtonContainer: {
    borderStyle: 'solid',
    backgroundColor: '#DBE2ED', //defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR
    borderRadius: defaultTheme.BUTTON_RADIUS,
    // padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    height: '70%',
    padding: 5,
    minWidth: 120,
  },
  offersBottomButtonText: {
    color: '#BDC6D5', //'#FFF' ,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  offersDetailCloseContainer: {
    alignItems: 'flex-end',
    marginTop: -25,
    left: 30,
  },
  offersDetailButtonContainer: {
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    minWidth: 30,
    minHeight: 30,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
  },
  offersDetailCloseIcon: {
    color: '#FFF',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: defaultTheme.FONT_WEIGHT_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  onlinePayIcon: {
    height: 23,
    width: 25,
    resizeMode: 'contain',
  },
  cashPayIcon: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
    marginRight: 5,
  },
  // events Styles //
  eventsLayout: {
    width: '100%',
  },
  eventsMainContainer: {
    // backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    alignSelf: 'center',
    width: '100%',
    height: '100%',
  },
  eventsTabsCaption: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontWeight: 'bold',
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  eventContainer: {
    // backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    // paddingHorizontal: 20,
    paddingBottom: 30,
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  eventsTypeHeader: {
    paddingTop: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  eventsTypeHeaderTitle: {
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  eventsTextColor: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
  },
  eventsItemContainer: {
    flex: 1,
    width: '100%',
    marginTop: 20,
  },
  eventsTileContainer: {
    //  borderColor: defaultTheme.BORDER_COLOR_PRIMARY,
    //borderWidth: 1,
    // borderStyle: 'solid',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    minHeight: 250,
  },
  eventsContainer: {
    width: '100%',
    flex: 1,
  },
  eventImageContainer: {
    minHeight: 250,
  },
  eventsCaptionContainer: {
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    alignItems: 'center',
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
    height: 55,
    justifyContent: 'center',
  },
  eventsCaption: {
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontWeight: '600',
    letterSpacing: 2,
    padding: 3,
  },
  eventsDataContainer: {
    flexDirection: 'row',
  },
  eventsOrderContainer: {
    flex: 0.2,
  },
  eventsOrderCaptionContainer: {
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    alignItems: 'center',
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderBottomRightRadius: defaultTheme.CONTENT_RADIUS,
  },
  eventsOrderCaption: {
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    padding: 5,
    letterSpacing: 1,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  eventsImageContainer: {
    flex: 0.6,
    backgtoundColor: '#0A2850',
  },
  eventsValueContainer: {
    flex: 0.4,
  },
  eventsValue: {
    flex: 1,
    flexDirection: 'column',
  },
  eventsimage: {
    width: '100%',
    aspectRatio: 1.6 / 1,
    minHeight: 150,
    backgroundColor: '#0A2850',
  },
  eventsPriceContainer: {
    flex: 0.4,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    width: '80%',
    alignSelf: 'center',
    borderBottomColor: defaultTheme.BORDER_COLOR_PRIMARY,
  },
  eventsTokenEarnedContainer: {
    flex: 0.6,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eventsPriceCurrency: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  eventsPrice: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  eventsTokenEarnedValue: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  eventsTokenEarnedImage: {
    height: 20,
    width: 20,
  },
  // events details

  eventsDetailContainer: {
    padding: 5,
    paddingTop: 20,
  },
  eventsDetailValue: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    lineHeight: 22,
    fontWeight: defaultTheme.FONT_WEIGHT_XSMALL,
  },
  eventsDetailPaymentContainer: {
    height: 75,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderBottomRightRadius: defaultTheme.CONTENT_RADIUS,
  },

  eventsDetailPaymentButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flex: 1,
  },
  eventsDetailTileContainer: {
    borderColor: defaultTheme.BORDER_COLOR_PRIMARY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    flex: 1,
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
  },
  eventsDetailLayout: {
    // height:'73%',
  },
  eventsDetailPurchaseButtonContainer: {
    borderColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    borderWidth: 1,
    borderStyle: 'solid',
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    width: 120,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  eventsDetailPurchaseButtonCaption: {
    color: '#FFF',
    justifyContent: 'center',
    marginHorizontal: 5,
    alignSelf: 'center',
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  eventsDetailCloseContainer: {
    alignItems: 'flex-end',
    marginTop: -25,
    left: 10,
  },
  eventsDetailButtonContainer: {
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    minWidth: 30,
    minHeight: 30,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    borderRadius: 100,
    borderWidth: 1,
    borderColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
  },
  eventsDetailCloseIcon: {
    color: '#FFF',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: defaultTheme.FONT_WEIGHT_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  eventsDetailsImage: {
    width: '100%',
    aspectRatio: 1.6 / 1.2,
  },
  eventsDetailCaptionContainer: {
    backgroundColor: 'rgba(37, 37, 70, 0.5)',
    alignItems: 'center',
    height: 65,
    justifyContent: 'center',
  },
  eventsDetailViewValue: {
    flex: 1,
    flexDirection: 'row',
  },
  eventsDetailValueContainer: {
    height: 55,
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    flexDirection: 'row',
  },
  eventsDetailPriceContainer: {
    flex: 0.5,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'center',
  },
  eventsDetailTokenEarnedContainer: {
    flex: 0.5,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  eventsPriceFree: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  eventsDetailPriceFreeContainer: {
    flex: 0.6,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'center',
  },
  eventsDetailTokenEarnedFreeContainer: {
    flex: 0.4,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
  },
  eventsTokenEarnedFreeValue: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  // coming soon
  comingSoonBtn: {
    backgroundColor: '#DBE2ED',
    borderRadius: defaultTheme.BUTTON_RADIUS,
    justifyContent: 'center',
    textAlign: 'center',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  comingSoonBtnText: {
    textAlign: 'center',
    color: '#BDC6D5',
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },

  // Wallet Styles //

  //Drawer Navigation Styles
  drawerProfileImageContainer: {
    marginTop: 10,
    zIndex: 3,
    /*borderColor: defaultTheme.PRIMARY_TEXT_COLOR, 
    borderStyle: 'solid', 
    borderWidth: 2, 
    borderRadius: 55*/
  },
  drawerLogoutContainer: {
    backgroundColor: '#DBE2ED',
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
  },
  drawerUserNameContainer: {
    width: '70%',
    paddingVertical: 10,
    //height: '33%',
    borderRadius: 6,
    backgroundColor: '#0A2850',
    marginTop: -70,
    //zIndex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  drawerUserDetailsContainer: {
    alignItems: 'center',
  },
  drawerUserStatusContainer: {
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
    width: '88%',
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 15,
    paddingBottom: 12,
    marginBottom: 10,
  },
  drawerUserNameText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    paddingTop: 2,
    paddingBottom: 2,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    textAlign: 'center',
  },
  drawerUserNameLongText: {
    flexWrap: 'wrap',
    // wordBreak: 'break-word'
  },
  drawerStatusText: {
    color: '#0A2850',
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: 8,
    paddingRight: 8,
  },
  drawerStatusTextContainer: {
    flexWrap: 'wrap',
    //wordWrap: 'break-word',
    // wordBreak: 'break-word'
  },
  drawerAddressText: {
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: '#0A2850',
  },
  drawerLogoSection: {
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    padding: 5,
  },
  drawerTitle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    //fontStyle: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_LARGE,
    marginTop: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  bottomDrawerSmall: {
    height: '23%',
  },
  bottomDrawerLarge: {
    height: '27%',
  },
  drawerLogoutLabel: {
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontWeight: 'bold',
    color: '#0A2850',
    paddingTop: 2,
  },
  drawerLogoutBtnContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 6,
  },
  drawerCopyTextContainer: {
    backgroundColor: defaultTheme.TEXT_COLOR_PRIMARY,
    padding: 2,
    width: 50,
    height: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    position: 'absolute',
    top: 25,
    right: Dimensions.get('window').width > 480 ? 116 : 12,
  },

  //Modal Styles
  modalHairline: {
    backgroundColor: defaultTheme.HAIRLINE,
    height: 1,
    width: '80%',
    marginVertical: 5,
  },
  emptyMessageText: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    letterSpacing: 0,
    alignSelf: 'center',
    marginVertical: 20,
    textAlign: 'center',
  },
  loginHairline: {
    backgroundColor: defaultTheme.MAIN_SECOND_BACKGROUND_COLOR,
    height: 1,
    width: '90%',
    alignSelf: 'center',
    //marginVertical:'1%'
  },
  loginWeb3AuthMessage: {
    paddingVertical: 12,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    letterSpacing: 1,
    textAlign: 'center',
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
  },
  loginTopLine: {
    backgroundColor: '#50054E',
    height: 5,
    width: '100%',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    //marginVertical:'1%'
  },
  modalCenteredContainer: {
    // margin: 24,
    width: '100%',
    maxWidth: 650,
    alignSelf: 'center',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  modalMainView: {
    borderRadius: defaultTheme.CONTENT_RADIUS,
    overflow: 'hidden',
    alignItems: 'center',
    padding: 4,
  },
  modalMainBody: {
    borderRadius: defaultTheme.BUTTON_RADIUS,
    overflow: 'hidden',
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    letterSpacing: 1,
    textAlign: 'center',
  },
  modalHeaderText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    //     fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    //     fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontWeight: '700',
    letterSpacing: 1,
    lineHeight: 25,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginHorizontal: 15,
    marginTop: 5,
  },
  confirmModalText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    // fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    letterSpacing: 1,
    lineHeight: 25,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginHorizontal: 15,
    marginTop: 5,
  },
  modalHeaderView: {
    padding: 5,
    marginTop: 10,
  },

  // profile page
  profileTitleText: {
    marginTop: 0,
    alignSelf: 'center',
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
  profileInput: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '88%',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  profileInputLabel: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
  },
  profileInputText: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    width: '100%',
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    //marginTop: -5,
    borderBottomColor: '#A2A2A2',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    //fontWeight: defaultTheme.FONT_WEIGHT_XLARGE,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderColor: '#d4d2d2',
    borderRadius: 4,
    borderStyle: 'solid',
    outlineStyle: 'none',
  },
  profileBoxWithShadow: {
    shadowColor: '#000',
    shadowOffset: { width: 2, height: 3 },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 5,
  },
  profileHairline: {
    backgroundColor: '#A2A2A250',
    height: 1,
    width: '88%',
    marginVertical: 10,
  },
  profileMainGradientContainer: {
    padding: 15,
    paddingLeft: 10,
    paddingRight: 10,
    alignSelf: 'center',
    width: '100%',
    height: '100%',
  },

  profileCatContentContainer: {
    // backgroundColor: defaultTheme.CONTENT_BACKGROUND_COLOR,
    paddingTop: 0,
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  profileContainerTitle: {
    marginVertical: 15,
  },
  profileAvatar: {
    zIndex: 3,
    width: 112,
    height: 112,
    borderRadius: 112 / 2,
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
    borderWidth: 3,
    borderColor: '#fff',
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileAvatarContainer: {
    zIndex: 3,
    width: 112,
    height: 112,
    borderRadius: 112 / 2,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  profileAvatarModalContainer: {
    zIndex: 3,
    width: 165,
    height: 165,
    borderRadius: 80,
    // borderWidth: 2,
    // borderColor: '#fff',
    // borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: '#FFF',
    marginVertical: 10,
  },
  avatarModalButtonContainer: {},
  profileAvatarModalText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  profileAvatarA: {
    zIndex: 3,
    width: 60,
    height: 60,
    borderRadius: 50,
    //backgroundColor: '#fff',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileAvatarContainerA: {
    zIndex: 3,
    width: 63,
    height: 63,
    borderRadius: 50,
    borderWidth: 5,
    //borderColor: '#FFF',
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    margin: 10,
  },

  profileSaveButtonStyle: {
    backgroundColor: '#C0136F',
    borderRadius: 6,
    width: '45%',
    height: 44,
    justifyContent: 'center',
    marginHorizontal: 2,
  },
  profileSaveButtonLabelStyle: {
    color: '#FCFCFC',
    textTransform: 'uppercase',
    fontSize: 20,
    lineHeight: 22,
    fontWeight: '700',
    paddingTop: Platform.OS === 'android' ? 3 : Platform.OS === 'ios' ? 5 : 0,
  },
  profileDeleteButtonLabelStyle: {
    color: '#FCFCFC',
    textTransform: 'uppercase',
    lineHeight: 22,
  },
  profileCancelButtonStyle: {
    backgroundColor: '#DBE2ED',
    borderRadius: 5,
    width: '45%',
    height: 44,
    justifyContent: 'center',
    marginHorizontal: 2,
  },
  profileCancelButtonLabelStyle: {
    color: '#0A2850',
    textTransform: 'uppercase',
    fontSize: 20,
    lineHeight: 22,
    fontWeight: '700',
    paddingTop: Platform.OS === 'android' ? 3 : Platform.OS === 'ios' ? 5 : 0,
  },
  profilePayButtonStyle: {
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
    borderWidth: 2,
    borderRadius: 5,
    width: '45%',
    height: 44,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginHorizontal: 2,
  },
  profilePayButtonLabelStyle: {
    color: 'black',
    backgroundColor: '#452',
    height: '100%',
    width: '100%',
  },
  profileEditIconContainer: {
    flex: 1,
    alignItems: 'flex-end',
    top: Platform.OS === 'web' ? -25 : -20,
    left: 46,
    zIndex: 4,
  },
  profileStatusCharacterCountContainer: {
    flex: 1,
    width: '88%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: -10,
  },
  profileStatusCharacterCountCaption: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
  },
  profileNameCharacterCountContainer: {
    flex: 1,
    width: '88%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: 0,
  },
  profileNameCharacterCountCaption: {
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
  },
  //loading screen
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  imagePickerHeaderText: {
    textAlign: 'left',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  imagePickerContainer: {
    //flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  imagePickerButtonContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagePickerIconContainer: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imagePickerButtonCaption: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    justifyContent: 'center',
    alignSelf: 'center',
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    marginTop: 5,
  },

  // max width for bigger screen
  maxWidthAdjust: {
    width: '100%',
    maxHeight: '100%',
    maxWidth: defaultTheme.CONTENT_CONTAINER_MAX_WIDTH,
    alignSelf: 'center',
  },

  desktopContainer: {
    width: '100%',
    maxHeight: '100%',
    maxWidth: '90%',
    alignSelf: 'center',
  },

  mainMarketPlaceContainer: {
    alignSelf: 'center',
    width: '100%',
    height: '100%',
  },
  maxWidthCloseAdjust: {
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
    zIndex: 4,
    marginTop: 0,
    marginBottom: 0,
  },
  avatarSelectButtonCaption: {
    color: '#FCFCFC',
    textTransform: 'uppercase',
    fontSize: 20,
    lineHeight: 22,
    fontWeight: '600',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    paddingTop: Platform.OS === 'android' ? 3 : Platform.OS === 'ios' ? 2 : 0,
  },
  avatarSelectButtonContainer: {
    backgroundColor: '#C0136F',
    borderRadius: 5,
    width: 110,
    height: 38,
    justifyContent: 'center',
    marginHorizontal: 2,
  },
  avatarCancelBtnContainer: {
    backgroundColor: '#DBE2ED',
    borderRadius: 5,
    width: 110,
    height: 38,
    justifyContent: 'center',
    marginHorizontal: 2,
  },
  avatarCancelBtnCaption: {
    color: '#0A2850',
    textTransform: 'uppercase',
    fontSize: 20,
    lineHeight: 22,
    fontWeight: '600',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    paddingTop: Platform.OS === 'android' ? 3 : Platform.OS === 'ios' ? 2 : 0,
  },
  maxHeightAdjust: {
    height: '100%',
    maxHeight: 725,
  },
  noPassportMsgContainer: {
    alignSelf: 'center',
    marginVertical: 20,
    marginHorizontal: 35,
  },
  noPassportMsgText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },

  // map style
  mapContainer: {
    flex: 1,
  },
  // default message
  messageContainer: {
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
  mobileNoLocationContainer: {
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    position: 'absolute',
    top: '5%',
    alignSelf: 'center',
    width: '95%',
    padding: 10,
    paddingVertical: 30,
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  mapBlurPopupContainer: {
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: Dimensions.get('window').width <= 480 ? '95%' : '80%',
    top: Dimensions.get('window').width <= 480 ? '8%' : '4%',
  },
  mapBackgroudBlackLayerContainer: {
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,.7)',
  },
  messageCaption: {
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    textAlign: 'center',
    color: defaultTheme.BORDER_COLOR_PRIMARY,
  },
  deleteAccountText: {
    letterSpacing: 1,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
  disconnectAccountText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    textAlign: 'center',
    letterSpacing: 0,
  },
  //Social Messages
  socialMessagingMainContainer: {
    flexDirection: 'column',
    marginBottom: 5,
  },
  socialMessagingItemRadius: {
    borderBottomLeftRadius: defaultTheme.CONTENT_RADIUS,
    borderBottomRightRadius: defaultTheme.CONTENT_RADIUS,
  },
  socialMessagingHeaderContainer: {
    padding: 10,
    width: '100%',
    borderRadius: 8,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
  },
  socialMessagingHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  socialMessagingHeaderText: {
    color: '#FCFCFC',
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
  },
  socialMessagingHeaderCountContainer: {
    backgroundColor: '#D20071',
    width: 50,
    height: 35,
    borderRadius: 24,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
    paddingHorizontal: 2,
  },
  socialMessagingHeaderCountTexxt: {
    color: '#FCFCFC',
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    textAlign: 'center',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  socialMessagingHairLine: {
    borderBottomWidth: 1,
    width: '95%',
    alignSelf: 'center',
  },
  socialMessagingReadHairLine: {
    borderBottomColor: 'rgba(191,191,191,0.2)',
  },
  socialMessagingUnreadHairLine: {
    borderBottomColor: 'rgba(255,255,255,.1)',
  },
  socialMessagingUserNameText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_MEDIUM_TEXT,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  socialMessagingUserIDText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  socialMessagingTimestampText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    marginLeft: 5,
    paddingTop: 1,
  },
  socialMessageText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    width: '99%',
  },
  socialReadMore: {
    textDecorationLine: 'underline',
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  socialMessagingMainActionsContainer: {
    flexDirection: 'row',
    paddingTop: 10,
    paddingBottom: 15,
    justifyContent: 'space-between',
    width: '70%',
    alignItems: 'center',
    alignContent: 'center',
  },
  socialMessagingMainActionText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 12,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    marginTop: 0,
    marginLeft: 4,
    textAlign: 'center',
    paddingTop: Platform.OS === 'android' ? 2 : 0,
  },
  socialMessagingActionTextDisabled: {
    color: 'rgba(115, 136, 163, 0.27)',
  },
  socialMessagingUnreadBackground: {
    backgroundColor: '#782773',
  },

  // create post
  fabIcon: {
    position: 'absolute',
    margin: 10,
    right: 10,
    bottom: 0,
    zIndex: 999,
  },
  createPostWebContainer: {
    flex: 1,
    // backgroundColor: '#FFF',
    borderRadius: defaultTheme.BUTTON_RADIUS,
    padding: 10,
    width: '100%',
  },
  createPostContainer: {
    // margin:10,
    flex: 1,
    // backgroundColor: '#FFF',
    alignSelf: 'center',
    borderRadius: defaultTheme.BUTTON_RADIUS,
    padding: 10,
    width: '100%',
  },
  createCancelButtonStyle: {
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderWidth: 1.5,
    borderStyle: 'solid',
    margin: 10,
    height: 40,
    justifyContent: 'center',
    marginHorizontal: 2,
  },
  createCancelButtonLabelStyle: {
    color: '#0A2850',
    //textTransform: 'capitalize',
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    lineHeight: 22,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  createPostButtonStyle: {
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderWidth: 1.5,
    borderStyle: 'solid',
    margin: 10,
    minWidth: 100,
    height: 40,
    justifyContent: 'center',
    marginHorizontal: 2,
  },

  createPostButtonContainer: {
    width: 80,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: defaultTheme.INACTIVE_PRIMARY_COLOR,
  },
  createPostButtonLabelStyle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_MEDIUM_TEXT,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  createPostHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  createPostBodyContainer: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  createPostProfile: {
    width: 70,
  },
  createPostMessageContainer: {
    flex: 1,
  },
  createPostWebViewInputText: {
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    backgroundColor: 'transparent',
    paddingTop: 0,
    height: 150,
    width: '100%',
    textAlignVertical: 'top',
    outlineStyle: 'none',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  createPostMobileInputText: {
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    backgroundColor: 'transparent',
    paddingTop: 0,
    height: 150,
    textAlignVertical: 'top',
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
  },
  createPostCharacterCountContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingTop: 5,
  },
  createPostCharacterCountCaption: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: 'rgba(255,255,255,.5)',
  },
  createPostWebViewContainer: {
    height: '100%',
    flex: 1,
    flexDirection: 'row',
    width: '55%',
    alignSelf: 'center',
  },
  createPostMobileViewContainer: {
    //  backgroundColor: '#FFF',
    height: '100%',
  },
  createPostModalBackground: {
    flex: 1,
    height: '100%',
  },
  createPostHairline: {
    backgroundColor: '#D7E2EF',
    height: 1,
    width: '100%',
    //marginVertical:'1%'
  },
  // app info screen
  appInfoItemContainer: {
    marginBottom: 5,
  },
  appInfoItem: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  drawerNavigationDrawerLabel: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    lineHeight: 24,
  },
  drawerNavigationDrawerIcon: {
    lineHeight: 24,
    marginTop: -10,
  },
  drawerNavigationIconView: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 30,
    height: 30,
    marginTop: -10,
    borderStyle: 'solid',
    borderWidth: 0,
  },
  drawerNavigationLabelView: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    height: 35,
    marginTop: -10,
    marginLeft: -20,
    borderStyle: 'solid',
    borderWidth: 0,
  },
  friendsTabsContainer: {
    flexDirection: 'row',
    height: 60,
    paddingBottom: 5,
  },
  friendsTabsItems: {
    flex: 33.33,
    flexDirection: 'row',
    paddingHorizontal: 1,
    justifyContent: 'center',
    alignItems: 'center',
    shadowOffset: { width: 2, height: 0 },
    shadowOpacity: 0.7,
    shadowRadius: 15,
    elevation: 10,
    borderRightWidth: 0.1,
    borderRightStyle: 'solid',
    borderRightColor: '#5D0333',
  },
  friendsTabsSubContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '70%',
    maxHeight: 50,
  },
  friendsTabsInActive: {
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
    zIndex: 10,
  },
  friendsTabsActive: {
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    zIndex: 100,
  },
  friendsTabBorderView: {
    height: 3,
    width: '100%',
  },
  friendsTabsCaption: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  //Passport Home page
  passportTabsText: {
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    lineHeight: Platform.OS == 'web' ? '1' : null,
    flexDirection: 'column',
  },
  passportTabsInActiveText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  passportTabsActiveText: {
    color: '#0A2850',
  },
  passportSearchTextInput: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    backgroundColor: defaultTheme.TEXT_INPUT_BACKGROUND_COLOR,
    alignSelf: 'center',
    width: '100%',
    color: defaultTheme.TEXT_SEARCH_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    //paddingLeft: -20
  },
  passportSearchInputOutline: {
    borderRadius: 6,
    borderColor: defaultTheme.TEXT_SEARCH_COLOR,
  },
  passportSortMenuHairLine: {
    borderBottomColor: '#FFFFFF17',
    borderBottomWidth: 1,
    marginVertical: 5,
  },
  appCopyTextContainer: {
    backgroundColor: defaultTheme.TEXT_COLOR_PRIMARY,
    padding: 2,
    width: 50,
    height: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
    position: 'absolute',
  },

  // direct message
  directMessageCotainer: {
    backgroundColor: defaultTheme.CONTENT_BACKGROUND_COLOR,
    paddingTop: 0,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    marginTop: 10,
  },
  directMessageHeaderContainer: {
    flexDirection: 'row',
    height: 50,
    alignItems: 'center',
  },
  directMessageBackButton: {
    color: defaultTheme.TEXT_COLOR_PRIMARY,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
  },
  directMessageContainer: {
    flex: 1,
  },
  directMessageBottomContainer: {
    backgroundColor: '#7E2279',
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  directMessageProfileContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 10,
  },
  directMessageItemContainer: {
    // flex:1
  },
  directMessageItemChatContainer: {
    padding: 10,
    flexDirection: 'row',
  },
  directMessageItemProfilePic: {},
  directMessageItemMessageContainer: {
    flexDirection: 'column',
    paddingLeft: 5,
    width: '100%',
  },
  directMessageItemUserLayout: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  directMessageItemUser: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    maxWidth: '80%',
  },
  friendUserIdText: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
  directMessageItemMessageLayout: {
    width: '100%',
    paddingRight: 5,
    paddingVertical: 2.5,
  },
  directMessageItemMessage: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    width: '90%',
  },
  directMessageReplyingHeaderText: {
    width: '90%',
    opacity: 0.8,
    marginLeft: 3,
    fontSize: defaultTheme.FONT_SIZE_11,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
  },
  directMessageReplyingText: {
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: '#A5A1A1',
    width: '90%',
  },
  directMessageReplyingBoxCloseBtn: {
    height: 15,
    width: 15,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: 7.5,
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  sharedItemPassportName: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: '#F4B540',
    width: '100%',
  },
  directMessageStatusText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    //fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    color: defaultTheme.ACTIVE_PRIMARY_COLOR,
  },
  userMessageText: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
  },
  userMessageTextNormal: {
    fontFamily: defaultTheme.FONT_FAMILY_LIGHT,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
  },
  userMessageTextBold: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  userUnReadMessageCountView: {
    backgroundColor: defaultTheme.BUTTON_FAB_BACKGROUND_COLOR,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
  },
  userUnReadMessageCount: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  unReadCountContainer: {
    width: 18,
    height: 18,
    borderRadius: 18 / 2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: defaultTheme.BUTTON_PRIMARY_BACKGROUND_COLOR,
  },
  unReadCountText: {
    color: '#fff',
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    textAlign: 'center',
  },
  userUnReadMessageCountPlus: {
    width: 25,
    height: 25,
    borderRadius: 15,
  },
  mutualFriendIndicatorContainer: {
    // borderColor: '#F4B540',
    // backgroundColor: '#F4B540',
    // width: Platform.OS === 'android' ? 13.5 : 14,
    // height: Platform.OS === 'android' ? 13.5 : 14,
    // borderWidth: 1.25,
    // borderRadius: Platform.OS === 'android' ? 13.5 / 2 : 14 / 2,
    zIndex: 5,
    justifyContent: 'center',
    position: 'absolute',
    right: -4,
    top: 35,
    alignItems: Platform.OS === 'android' ? 'flex-start' : 'center',
  },
  sendRequestIndicatorContainer: {
    width: Platform.OS === 'android' ? 13.5 : 13,
    height: Platform.OS === 'android' ? 13.5 : 13,
    borderWidth: 1.25,
    borderRadius: Platform.OS === 'android' ? 13.5 / 2 : 13 / 2,
    justifyContent: 'center',
    backgroundColor: defaultTheme.BUTTON_TERTIARY_BACKGROUND_COLOR,
    borderColor: defaultTheme.BUTTON_TERTIARY_BACKGROUND_COLOR,
    alignItems: 'center',
  },
  mutualFriendIndicator: {
    marginLeft: Platform.OS === 'web' ? 0 : -1,
  },
  // map
  mapComponentContainer: {
    width: '100%',
    height: '100%',
  },
  mapUserLocation: {
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
    borderRadius: 2,
    height: 40,
    width: 40,
    right: 0,
    bottom: 200,
    margin: 10,
    position: 'absolute',
    elevation: 4,
  },
  mapImageCardContainer: {
    padding: 5,
    backgroundColor: '#FFF',
    borderRadius: 10,
    width: 80,
    height: 100,
  },
  mapImageMultipleCardContainer: {
    padding: 2,
    backgroundColor: '#FFF',
    borderRadius: 10,
    width: 200,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  mapImageCardCanvasContainer: {
    flex: 1,
  },
  mapImageCardCanvas: {
    flex: 1,
    backgroundColor: '#000',
    borderRadius: 10,
    margin: '3%',
    padding: 3,
  },
  mapImageMultipleCardCanvas: {
    backgroundColor: '#000',
    borderRadius: 10,
    margin: 3,
    width: 80,
    height: 80,
    padding: 3,
  },
  mapImageCardCaption: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    alignSelf: 'flex-start',
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    paddingLeft: 2,
    textAlign: 'center',
    width: '100%',
  },
  mapImageMarkerCaption: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  mapParametersCardContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 20,
    height: '100%',
    paddingBottom: 20,
  },
  mapParametersButtons: {
    justifyContent: 'center',
    width: '12%',
    alignSelf: 'center',
  },
  mapParametersCardItemContainer: {
    width: '76%',
    margin: 2,
    height: '100%',
  },
  mapParametersCard: {
    // borderWidth:1,
    // borderColor:'#A2A2A250',
    borderRadius: 5,
    height: '100%',
    flex: 1,
    paddingBottom: 5,
  },
  mapParameterContentHeader: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'center',
    paddingBottom: 10,
  },
  mapParameterItemCaption: {
    width: '100%',
    backgroundColor: defaultTheme.BUTTON_QUARTERNARY_BACKGROUND_COLOR,
    padding: 7,
    alignItems: 'center',
    justifyContent: 'center',
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
  mapParameterConstraintDetail: {
    marginLeft: 5,
    marginRight: 5,
    marginTop: 5,
    padding: 5,
  },
  mapParameterDetailsText: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontWeight: defaultTheme.FONT_WEIGHT_XSMALL,
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    lineHeight: 22,
    paddingBottom: 5,
    textAlign: 'left',
  },
  mapMultiParameterContiner: {
    flexDirection: 'row',
  },
  mapPinCircleContainer: {
    height: 40,
    width: 40,
  },
  mapOuterCircle: {
    backgroundColor: defaultTheme.CONTENT_BACKGROUND_COLOR,
    borderRadius: 30,
    padding: 5,
    borderWidth: 1,
    borderColor: '#404079',
    height: '100%',
    width: '100%',
  },
  mapInnerCircle: {
    backgroundColor: '#404079',
    borderRadius: 30,
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mapCicleContent: {
    color: defaultTheme.CONTENT_BACKGROUND_COLOR,
  },
  mapPinImageContainer: {
    alignSelf: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  mapMultipinImageMainContainer: {
    width: 98,
    alignContent: 'center',
  },
  mapImageMultiCardCanvasContainer: {
    alignSelf: 'center',
  },
  mapNonGeofencedButtonLocation: {
    left: 0,
    top: 50,
    margin: 10,
    position: 'absolute',
    flexDirection: 'row',
  },
  mapNonGeofencedMobileView: {
    left: 0,
    bottom: 15,
    margin: 10,
    position: 'absolute',
    padding: 4,
    flexDirection: 'row',
  },
  mapNonGeofencedButtonLocationContainer: {
    backgroundColor: '#FFF',
    padding: 5,
    borderRadius: 2,
  },
  mapNonGeofencedButton: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  mapNonGeofencedButtonView: {
    backgroundColor: '#FFF',
    padding: 5,
    borderRadius: 2,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mapNonGeofencedButtonLocationTextStyle: {
    color: '#000',
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    paddingLeft: 2,
  },
  mapNonGeofencedCounterContainer: {
    marginLeft: -10,
    marginTop: -20,
  },
  mapNonGeofencedCounter: {
    right: 0,
    height: 30,
    width: 30,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  mapNonGeofencedCounterText: {
    color: defaultTheme.CONTENT_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  mapPinCircleOuterContainer: {
    padding: 2,
  },
  mapVideoButtonContainer: {
    position: 'absolute',
    top: '35%',
    width: 20,
    height: 20,
    borderRadius: 50,
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
    opacity: 0.4,
    alignSelf: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  mapVideoButton: {
    alignSelf: 'center',
    top: -13,
  },
  mapPinImageInnerContainer: {
    width: '100%',
    height: '100%',
  },
  mapPinCachedImageContainer: {
    width: '100%',
    height: '100%',
  },
  mapParameterInnerContainer: {
    flex: 1,
  },
  mapParameterScrollView: {
    height: '100%',
  },
  mapParameterScrollViewContainer: {
    backgroundColor: '#000',
    aspectRatio: 1.2 / 1,
    borderBottomRightRadius: 6,
    borderBottomLeftRadius: 6,
  },
  mapParameterScrollViewCachedImage: {
    width: '100%',
    height: '100%',
  },
  mapParameterVideoContainer: {
    height: '100%',
  },
  mapParameterVideoInnerContainer: {
    width: '100%',
    height: '90%',
  },
  mapParameterVideo: {
    alignSelf: 'center',
    width: '100%',
    height: '100%',
  },
  mapParameterVideoStyle: {
    position: 'relative',
    flex: 1,
    padding: 5,
  },
  mapParameterVideoButtonContainer: {
    height: '10%',
  },
  mapParameterVideoButton: {
    alignContent: 'center',
    zIndex: 6,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#000000',
    opacity: 0.8,
  },
  mapParameterArrowLight: {
    alignSelf: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  mapParameterArrowDim: {
    alignSelf: 'center',
    color: '#A39E9E',
  },
  privateMessageReqCancelButton: {
    color: '#fff',
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
  },
  privateMessageReqStatusText: {
    textAlign: 'center',
    fontSize: 12,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontWeight: defaultTheme.FONT_WEIGHT_MEDIUM,
  },
  urlPreviewMainContainer: {
    // flexDirection:'column',
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#DBE2ED',
  },
  urlPreviewTextContainer: {
    padding: 5,
    minHeight: 30,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  urlPreviewTitleContainer: {
    padding: 5,
    borderBottomTopLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  urlPreviewImageContainer: {
    alignSelf: 'center',
    height: 150,
    width: '100%',
    margin: 5,
  },
  urlPreviewMessageTextStyle: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.CONTENT_BACKGROUND_COLOR,
    width: '100%',
    padding: 10,
  },
  urlPreviewTitleTextStyle: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    width: '100%',
    padding: 10,
  },
  urlSmallPreviewMainContainer: {
    marginTop: 5,
    flexDirection: 'row',
    flex: 1,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(219, 226, 237, 0.15)',
  },
  urlSmallPreviewImageContainer: {
    alignSelf: 'center',
    height: 80,
    width: '40%',
    margin: 5,
  },
  urlSmallPreviewTitleContainer: {
    padding: 5,
    borderBottomTopLeftRadius: 5,
    borderBottomRightRadius: 5,
    width: '60%',
  },
  notFoundIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: Platform.OS === 'web' ? '100vh' : 600,
    marginTop: Platform.OS === 'web' ? '-90px' : 0,
  },
  resharePostCaption: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    width: '95%',
    alignSelf: 'center',
    textAlign: 'left',
  },
  resharePostSecondCaption: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    width: '95%',
    alignSelf: 'center',
    textAlign: 'left',
  },
  gradientHeaderSubContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(37, 37, 70, .5)',
  },
  gradientBottomSubContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: 'rgba(37, 37, 70, .6)',
  },
  bottomTabBtnContainer: {
    height: Platform.OS === 'ios' ? 80 : 85,
    width: '15%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  markAllReadText: {
    color: '#000',
    textDecorationLine: 'underline',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
  },
  deleteAccountBtnText: {
    color: 'rgba(244, 181, 64,1)',
    paddingTop: 15,
    fontSize: Platform.OS === 'web' ? 14 : 15,
    textDecorationLine: 'underline',
  },
  bottomActiveTabText: {
    color: defaultTheme.ACTIVE_PRIMARY_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  bottomInActiveTabText: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  // imageInMessage
  imageInMessageContainer: {
    minHeight: 150,
  },
  tokenPackagesSecondTitleCotainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    paddingVertical: 14,
  },
  tokenPackagesSecondTitle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    textAlign: 'center',
  },
  tokenPackageItemsContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: 83,
    width: '100%',
    borderRadius: 8,
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    paddingHorizontal: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
    elevation: 15,
  },
  tokenPackageListContainer: {
    width: '100%',
    marginTop: 10,
  },
  tokenPackageItemsCaptionContainer: {
    alignItems: 'center',
    padding: 20,
  },
  tokenPackageItemsCaption: {
    color: '#FFF',
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  tokenPackageItemsPriceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 5,
    marginRight: 10,
    alignItems: 'center',
  },
  tokenPackageItemsPrice: {
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    color: '#FFF',
  },
  tokenBuyTokenDetails: {
    width: '100%',
    marginTop: 12,
    borderRadius: 8,
    backgroundColor: defaultTheme.CARD_BG_COLOR,
  },
  tokenBuyItemsContainer: {
    padding: 5,
  },
  tokenBuyItemContainer: {
    padding: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 45,
    alignItems: 'center',
  },
  tokenBuyItemCaption: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    color: '#FFF',
  },
  tokenBuyHairline: {
    backgroundColor: '#9A4DFF1A',
    height: 1.5,
    width: '98%',
    alignSelf: 'center',
  },
  tokenBuyButtonContainer: {
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderWidth: 1.5,
    borderStyle: 'solid',
    margin: 10,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 2,
    width: '80%',
    alignSelf: 'center',
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
  },
  tokenBuyModalTitle: {
    fontSize: defaultTheme.FONT_SIZE_SMALLX,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  tokenBuyModalSecondTitle: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    color: '#DBE2ED',
    textAlign: 'center',
  },
  tokenItemDiscounts: {
    alignSelf: 'flex-end',
    right: 20,
    position: 'absolute',
    backgroundColor: '#F4B540',
    padding: 5,
    borderRadius: 6,
    top: 0,
  },
  tokenItemDiscountsCaption: {
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    color: '#000000',
  },
  tokenBuyButton: {
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderWidth: 1.5,
    borderStyle: 'solid',
    margin: 10,
    minWidth: 130,
    justifyContent: 'center',
    marginHorizontal: 2,
  },
  cashOutButton: {
    backgroundColor: defaultTheme.GRAY_BACKGROUND_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    borderWidth: 0,
    borderStyle: 'solid',
    opacity: 0.5,
    margin: 10,
    minWidth: 130,
    justifyContent: 'center',
    marginHorizontal: 2,
  },
  cashoutTextHeading: {
    color: defaultTheme.TEXT_COLOR_BLACK,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    textAlign: 'center',
    // lineHeight: 22,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    //paddingTop: Platform.OS != 'web' ? 3 : 0,
  },
  cashoutTextSubHeading: {
    color: defaultTheme.TEXT_COLOR_BLACK,
    fontSize: defaultTheme.FONT_SIZE_11,
    textAlign: 'center',
    // lineHeight: 22,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  tokenBuyButtonLableStyle: {
    color: defaultTheme.TEXT_COLOR_SECONDARY,
    fontSize: defaultTheme.FONT_SIZE_SMALL,
    // lineHeight: 22,
    fontWeight: defaultTheme.FONT_WEIGHT_LARGE,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    //paddingTop: Platform.OS != 'web' ? 3 : 0,
    paddingTop: Platform.OS === 'ios' ? 4 : 0,
  },
  splashMainContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  splashContentContainer: {
    flex: 0.3,
    width: '100%',
    alignItems: 'center',
    paddingVertical: 20,
  },
  splashContentTextContainer: {
    width: '90%',
    flex: 0.5,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  splashContentHeadingText: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_MEDIUM,
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center',
  },
  splashContentText: {
    color: defaultTheme.GRAY_BACKGROUND_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    width: '80%',
    textAlign: 'center',
    alignSelf: 'center',
  },
  splashButtonContainer: {
    width: '90%',
    flex: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  splashExploreButton: {
    width: '100%',
    height: 52,
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    borderRadius: defaultTheme.BUTTON_RADIUS,
  },
  splashExploreButtonText: {
    color: defaultTheme.TEXT_COLOR_BLACK,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
  },
  splashBackButtonText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    textDecorationLine: 'underline',
  },
  passportNameIdContainer: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  MPExpassItemMainContainer: {
    flexDirection: 'column',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  MPExpassItemSubContainer: {
    height: 150,
    width: '100%',
    borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
    backgroundColor: 'rgba(0,0,0,.4)',
  },
  MPExpassImage: {
    height: '100%',
    width: '100%',
    borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
  },

  listByContainer: {
    position: 'absolute',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderWidth: 1,
    borderColor: '#fff',
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    right: 10,
    top: 10,
    borderRadius: 13,
  },
  listByText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 8,
    color: '#000',
  },
  mpCollectibleContainer: {
    flexDirection: 'column',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    overflow: 'hidden',
    // padding:10,
    // borderColor: '#DBE2ED80',
    // borderWidth: 1
  },
  mpCollectibleImageContainer: {
    height: 150,
    width: '100%',
    borderTopRightRadius: defaultTheme.CONTENT_RADIUS,
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
    backgroundColor: 'rgba(0,0,0,.4)',
  },
  mpCollectiblesCaptionItem: {
    padding: 10,
    flexDirection: 'row',
    alignItems: 'center',
  },
  mpCollectiblesCaptionContainer: {
    flex: 1,
    alignItems: 'flex-start',
  },
  mpCollectiblesCaption: {
    lineHeight: 13,
    color: '#fff',
  },
  mpCollectiblesCaptionName: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_11,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  mpCollectiblesTokenContainer: {
    minWidth: 60,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mpCollectiblesValue: {
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_11,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    paddingLeft: 5,
  },
  mpCollectibleImage: {
    height: '100%',
    width: '100%',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    borderTopLeftRadius: defaultTheme.CONTENT_RADIUS,
  },
  mpCollectibleTag: {
    backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
    alignSelf: 'flex-end',
    alignItems: 'center',
    marginTop: 10,
    marginRight: 10,
    borderRadius: 13,
    padding: 5,
    borderWidth: 1,
    borderColor: '#FFF',
  },
  mpCollectibleTagCaption: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: 8,
  },
  contentDetailContainer: {
    width: '95%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    minHeight: 40,
  },
  contentUserImage: {
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
    backgroundColor: 'lightblue',
    borderColor: '#fff',
    borderWidth: 1,
    marginRight: 5,
  },
  contentDetailText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    color: '#fff',
  },
  ownerNameText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    color: '#fff',
  },
  collectibleDetailAccordion: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingVertical: 0,
    borderRadius: 6,
    lineHeight: 25,
  },
  collectibleDetailAccordionCaption: {
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    padding: 10,
  },
  collectibleDetailAccordionContentContainer: {
    backgroundColor: defaultTheme.ACCORDION_BACKGROUND_COLOR,
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    flexDirection: 'row',
  },
  collectibleDetailAccordionContent: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
    color: '#FFFFFFAD',
  },
  collectibleDetailTraitsCardContainer: {
    height: '100%',
    width: '30%',
    overflow: 'hidden',
    borderRadius: 6,
    borderColor: defaultTheme.PRIMARY_TEXT_COLOR,
    borderWidth: 1,
    alignItems: 'center',
    minHeight: 60,
    justifyContent: 'center',
    margin: 5,
  },
  collectibleOwnerProfileAvatar: {
    zIndex: 3,
    borderWidth: 0.1,
    borderColor: defaultTheme.AVATAR_BORDER_COLOR,
    backgroundColor: '#AB339B',
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: -5,
    paddingLeft: -5,
    marginRight: 3,
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
  },
  verifiedAccountTickContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 2,
  },
  verifiedAccountTickImage: {
    width: 10,
    height: 10,
  },
  verifiedAccountTickTitle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: 10,
    paddingLeft: 2,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  MPConfirmationSheetContainer: {
    width: '90%',
    alignSelf: 'center',
    alignItems: 'center',
    paddingVertical: 10,
  },
  MPConfirmationSheetHeadingText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_SMALLX,
    color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
  },
  MPConfirmationSheetSubHeadingText: {
    ontFamily: defaultTheme.FONT_FAMILY_MAIN_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.BUTTON_TERTIARY_BACKGROUND_COLOR,
    marginVertical: 10,
  },
  imagePreviewMainContainer: {
    maxWidth: 650,
    alignSelf: 'center',
    backgroundColor: defaultTheme.TEXT_COLOR_BLACK,
  },
  imagePreviewHeaderContainer: {
    height: 70,
    width: '100%',
    paddingHorizontal: Platform.OS === 'web' ? 15 : 10,
    alignItems: 'center',
    flexDirection: 'row',
  },
} as const;

const capdefiStyle = { ...mainStyles };

const styles = StyleSheet.create(capdefiStyle);

export default styles;
