import {
  ImageBackground,
  Text,
  View,
  Image,
  Pressable,
  Platform,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { defaultTheme } from '../../themes/loot8';
import { useAppSelector } from '../../hooks';
import CachedImage from '../CachedImage';
import WebView from 'react-native-webview';
import { webViewHelper } from '../../helpers/Gadgets';
import { ResizeMode, Video } from 'expo-av';
import { IconButton } from 'react-native-paper';
import { CatalogTypes } from '../../enums/catalog.enum';
import HorizontalCard from '../Card/HorizontalCard';
import { ScreenName } from '../../enums/screen.enum';

const ChatRoomCard = ({
  navigation,
  navigateToCollectibleDetails,
  navigateToUnsub,
}) => {
  const premiumChatCollectibles = useAppSelector(
    state => state.Offers.premiumChatCollectibles[0],
  );
  const playbackInstance = useRef(null);
  const isSubscribe =
    premiumChatCollectibles?.collectibleIds?.length > 0 ? true : false;
  const [playbackInstanceInfo, setPlaybackInstanceInfo] = useState({
    position: 0,
    duration: 0,
    state: 'Buffering',
    positionMillis: 0,
  });

  useEffect(() => {
    return () => {
      if (playbackInstance.current) {
        playbackInstance.current.setStatusAsync({
          shouldPlay: false,
        });
      }
    };
  }, []);

  const togglePlay = async () => {
    const shouldPlay = playbackInstanceInfo.state !== 'Playing';
    if (playbackInstance.current !== null) {
      await playbackInstance.current.setStatusAsync({
        shouldPlay,
        ...(playbackInstanceInfo.state === 'Ended' && { positionMillis: 0 }),
      });
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        positionMillis:
          playbackInstanceInfo.state === 'Ended'
            ? 0
            : playbackInstanceInfo.positionMillis,
        state: playbackInstanceInfo.state === 'Playing' ? 'Paused' : 'Playing',
      });
    }
  };

  const stopVideo = async () => {
    if (playbackInstance.current) {
      playbackInstance.current.setStatusAsync({
        shouldPlay: false,
        isPlaying: false,
        state: 'Ended',
        didJustFinish: true,
        positionMillis: -1,
        durationMillis: 0,
      });
    }
  };

  const updatePlaybackCallback = status => {
    if (status.isLoaded) {
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        position: status.positionMillis,
        duration: status.durationMillis || 0,
        state: status.didJustFinish
          ? 'Ended'
          : status.isBuffering
            ? 'Buffering'
            : status.isPlaying
              ? 'Playing'
              : 'Paused',
      });
    } else {
      if (status.isLoaded === false && status.error) {
        const errorMsg = `Encountered a fatal error during playback: ${status.error}`;
      }
    }
  };

  const onSubscribePassportClick = async item => {
    navigation.navigate(ScreenName.PREMIUMCHAT_SUB_UNSUB, {
      selectedEXPass: item,
      currentLoot8Balance: 0,
      type: 'sub',
    });
  };

  return (
    <>
      <Text
        style={{
          fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
          color: defaultTheme.PRIMARY_TEXT_COLOR,
          textAlign: 'left',
          margin: 6,
        }}>
        {isSubscribe ? 'Active' : 'Available to subscribe'}
      </Text>
      <HorizontalCard
        primaryBtnLabel={isSubscribe ? 'Open' : 'Subscribe'}
        heading={isSubscribe ? 'Premium Chatroom' : 'Unlock Premium Chatroom'}
        text={
          isSubscribe
            ? ''
            : 'A chatroom allows users to engage in group chats with multiple people at once.'
        }
        primaryBtnPress={() => {
          isSubscribe
            ? navigateToCollectibleDetails(
                premiumChatCollectibles,
                CatalogTypes.PREMIUMCHAT,
              )
            : onSubscribePassportClick(premiumChatCollectibles);
        }}
        secondaryBtnLabel={isSubscribe ? 'Unsub' : ''}
        secondaryBtnPress={isSubscribe ? navigateToUnsub : null}
      />
      {/* <View
        style={{
          backgroundColor: defaultTheme.MAIN_SECOND_BACKGROUND_COLOR,
          height: 130,
          width: '100%',
          borderRadius: 16,
          marginTop: 20,
        }}>
        <ImageBackground
          source={require('../../assets/PremiumChat/premiumChatCardBg.png')}
          resizeMode="cover"
          style={{ height: '100%', width: '100%', flexDirection: 'row' }}
          imageStyle={{
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
          }}>
          <View
            style={{
              flex: 0.4,
              borderTopLeftRadius: 16,
              borderBottomLeftRadius: 16,
              backgroundColor: 'rgba(0,0,0,0.5)',
            }}>
            {!premiumChatCollectibles?.isMp4Collectible &&
              (premiumChatCollectibles?.animationUrl ? (
                <View style={{ flex: 1 }}>
                  <WebView
                    nestedScrollEnabled={true}
                    containerStyle={{ marginTop: -25, zIndex: 998 }}
                    style={Platform.OS == 'web' && { marginTop: -25 }}
                    source={{ uri: premiumChatCollectibles?.animationUrl }}
                    javaScriptEnabledAndroid={true}
                    onMessage={event => {
                      const data = event?.nativeEvent?.data;
                      if (data && data.indexOf && data.indexOf('loot8') > -1) {
                        webViewHelper(event.nativeEvent.data);
                      }
                    }}
                    androidLayerType={'software'}
                  />
                </View>
              ) : (
                <CachedImage
                  isBackground
                  imageStyle={{
                    borderTopLeftRadius: 16,
                    borderBottomLeftRadius: 16,
                  }}
                  imageSize={premiumChatCollectibles?.imageSize}
                  source={{ uri: premiumChatCollectibles?.image }}
                  noImageFile={'no-image-chatroom.png'}
                  style={{ width: '100%', height: '100%' }}
                  contentFit="cover"></CachedImage>
              ))}
            {premiumChatCollectibles?.isMp4Collectible && (
              <View style={{ flex: 1 }}>
                <View style={{ width: '100%', aspectRatio: 4 / 3 }}>
                  <Video
                    ref={playbackInstance}
                    style={{
                      alignSelf: 'center',
                      width: '100%',
                      height: '100%',
                      borderTopLeftRadius: 16,
                      borderBottomLeftRadius: 16,
                    }}
                    source={{ uri: premiumChatCollectibles?.video }}
                    //useNativeControls
                    resizeMode={ResizeMode.COVER}
                    onPlaybackStatusUpdate={updatePlaybackCallback}
                    videoStyle={{ position: 'relative', flex: 1, padding: 5 }}
                  />
                </View>

                <View style={{ height: 50 }}>
                  <View
                    style={{
                      alignContent: 'center',
                      zIndex: 6,
                      flex: 1,
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      backgroundColor: '#000000',
                      opacity: 0.8,
                    }}>
                    <IconButton
                      icon={
                        playbackInstanceInfo.state === 'Playing'
                          ? 'pause'
                          : 'play'
                      }
                      size={30}
                      style={{ margin: -7 }}
                      iconColor={'#FFFFFF'}
                      onPress={() => {
                        togglePlay();
                      }}
                    />
                    <IconButton
                      icon="stop"
                      size={30}
                      iconColor={'#FFFFFF'}
                      style={{ margin: -7 }}
                      onPress={() => {
                        stopVideo();
                      }}
                    />
                  </View>
                </View>
              </View>
            )}
          </View>
          <View style={{ flex: 0.6, justifyContent: 'center', padding: 10 }}>
            <View
              style={{
                flex: 0.6,
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}>
              <Text
                style={{
                  fontSize: 15,
                  fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                  color: '#fff',
                  width: '70%',
                  textAlign: 'right',
                }}>
                {isSubscribe ? 'Premium\nChatroom' : 'Unlock Premium\nChatroom'}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <Image
                  source={require('../../assets/eightIcon.png')}
                  style={{ height: 18, width: 18, borderRadius: 18 / 2 }}
                />
                <Text
                  style={{
                    fontFamily: defaultTheme.FONT_FAMILY_MAIN,
                    color: '#fff',
                    fontSize: 10,
                    marginLeft: 10,
                  }}>
                  {premiumChatCollectibles
                    ? premiumChatCollectibles?.price?.toFixed(2)
                    : (0.0)?.toFixed(2)}
                </Text>
              </View>
            </View>
            <View
              style={{
                flex: 0.4,
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}>
              <Pressable
                onPress={() => {
                  isSubscribe
                    ? navigateToCollectibleDetails(
                        premiumChatCollectibles,
                        CatalogTypes.PREMIUMCHAT,
                      )
                    : onSubscribePassportClick(premiumChatCollectibles);
                }}
                style={{
                  height: 40,
                  width: '60%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#F4B540',
                  borderRadius: 10,
                  marginRight: -2,
                }}>
                <Text
                  style={{
                    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
                    color: '#000',
                    fontSize: 12,
                  }}>
                  {isSubscribe ? 'Open Chatroom' : 'Subscribe Now'}
                </Text>
              </Pressable>
            </View>
          </View>
        </ImageBackground>
      </View> */}
    </>
  );
};

export default ChatRoomCard;
