import { useState } from 'react';
import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import { LogCustomError } from '../helpers/AppLogger';
import {
  CollectionHelper__factory,
  CollectionManager__factory,
  Event__factory,
  EventRegistry__factory,
} from '../typechain';
import { addresses, getAppConfiguration } from '../appconstants';
import { useWeb3AuthContext } from './web3authContext';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { fetchAssociatedEventForExpass } from '../helpers/GraphQLHelper';
import { CollectionType } from '../enums/collection.enum';
import { OfferType } from '../enums/offers.enum';
import {
  getCollectibleDetails,
  getCollectibleIdsForPatron,
} from '../slices/OfferSlice';
import { getIPFSData, getIPFSLink } from '../helpers/ipfs';
import { IEventData } from '../interfaces/IEventData';
import { useAppSelector } from '.';
import { formatDate, isActiveTimeStamp } from '../helpers/DateHelper';
import { isLocationAvailable } from '../slices/helpers';
import { useCollectibleMint } from './useCollectibleMint';

export interface AssociatedEvent {
  event: string;
  ticket?: string;
  collectibles?: string[];
  offers?: string[];
  eData?: IEventData;
  tData?: ICollectibleDetail;
  cData?: ICollectibleDetail[];
  oData?: ICollectibleDetail[];
  dateString?: string;
}

const useExpassEventManager = () => {
  const { networkId, staticProvider, address, wallet } = useWeb3AuthContext();
  const { mintLinkedCollectibles } = useCollectibleMint();

  const entityData = useAppSelector(state => state.Entity.EntityData);

  const [associatedEvents, setAssociatedEvents] = useState<AssociatedEvent[]>(
    [],
  );
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingMetadata, setLoadingMetadata] = useState(false);

  const initContractFactories = () => {
    const eventRegistry = EventRegistry__factory.connect(
      addresses[networkId].EventRegistry,
      staticProvider,
    );
    const collectionHelper = CollectionHelper__factory.connect(
      addresses[networkId].CollectionHelper,
      staticProvider,
    );
    const collectionManager = CollectionManager__factory.connect(
      addresses[networkId].CollectionManager,
      staticProvider,
    );

    return { eventRegistry, collectionHelper, collectionManager };
  };

  const getAssociatedCollectionsForEvent = async (
    events: AssociatedEvent[],
  ) => {
    const { collectionHelper, collectionManager } = initContractFactories();

    let _associatedEvents: AssociatedEvent[] = events;

    for (const [index, { event }] of _associatedEvents.entries()) {
      try {
        //* Step 1: Fetch linked collections for event
        const linkedCollections = await collectionHelper.getLinkedCollections(
          event,
        );

        if (linkedCollections && linkedCollections?.length > 0) {
          for (const collection of linkedCollections) {
            //* Step 2: Fetch the collection type of linked collection
            const collectionType = await collectionManager.getCollectionType(
              collection,
            );

            //* Step 3: Check if type of collection is Ticket
            if (collectionType === CollectionType.TICKET) {
              //? The current collection is a ticket for the given event
              _associatedEvents[index].ticket = collection;
              break;
            }
          }
        }

        //* Once ticket is fetched, we will fetch linked collections for ticket
        //* Step 1: Fetch linked collections for ticket
        const ticketLinkedCollections =
          await collectionHelper.getLinkedCollections(
            _associatedEvents[index].ticket,
          );

        if (ticketLinkedCollections && ticketLinkedCollections?.length > 0) {
          for (const collection of ticketLinkedCollections) {
            //* Step 2: Fetch the collection type of linked collection
            const collectionType = await collectionManager.getCollectionType(
              collection,
            );

            if (collectionType === CollectionType.COLLECTION) {
              //? The collection is either a Collectible or an Offer
              let isFeaturedOffer = false;

              //* Fetch offerType for collectible
              const collectionData = await collectionManager.getCollectionData(
                collection,
              );

              if (collectionData.offerType === OfferType.FEATURED) {
                //* Fetch Token Owned for collectible
                let tokenOwned = await getCollectibleIdsForPatron(
                  {
                    networkID: networkId,
                    provider: staticProvider,
                    collectibleAddress: collection,
                    address,
                  },
                  true,
                );

                if (Number(tokenOwned?.length) === 0) {
                  isFeaturedOffer = true;
                }
              }

              if (isFeaturedOffer) {
                let offers = _associatedEvents[index]?.offers || [];
                offers.push(collection);
                _associatedEvents[index].offers = offers;
              } else {
                let collectibles = _associatedEvents[index]?.collectibles || [];
                collectibles.push(collection);
                _associatedEvents[index].collectibles = collectibles;
              }
            }
          }
        }
      } catch (error) {
        LogCustomError(
          '~ getAssociatedCollectionsForEvent-collectionHelper-collectionManager-' +
            event,
          error?.method,
          error?.reason,
          ' ',
        );
      }
    }

    //* Ignore any event that is missing a ticket
    _associatedEvents = _associatedEvents.filter(e => e?.ticket);

    return _associatedEvents;
  };

  const getAssociatedEventsForExpass = async (expass: ICollectibleDetail) => {
    try {
      setLoadingEvents(true);
      //? Initialize Contract Factories
      const { eventRegistry, collectionHelper } = initContractFactories();

      let _associatedEvents: AssociatedEvent[] = [];

      let appConfig;
      try {
        appConfig = await getAppConfiguration();
      } catch (err) {
        LogToLoot8Console(
          'useExpassEventManager: Error while reading app config',
        );
      }

      const isSourceIndexer =
        appConfig &&
        appConfig.indexerService &&
        appConfig.indexerService.events;

      if (isSourceIndexer) {
        //? Fetch Events data from Indexer

        _associatedEvents = await fetchAssociatedEventForExpass(expass.address);
      } else {
        //? Fetch Events data from Chain

        //* Step 1: Fetch All Events for given Expass Entity
        const events = await eventRegistry.getAllEvents(expass.entityAddress);

        if (events && events?.length > 0) {
          for (const event of events) {
            try {
              //* Step 2: For every event fetch the linked collections
              const linkedCollections =
                await collectionHelper.getLinkedCollections(event);

              if (linkedCollections && linkedCollections?.length > 0) {
                for (const linkedCollection of linkedCollections) {
                  //* Step 3: For every linked collection, check is current Expass is present
                  if (
                    linkedCollection.toLowerCase() ===
                    expass.address.toLowerCase()
                  ) {
                    //? The current event is associated with the Expass
                    _associatedEvents.push({ event });
                  }
                }
              }
            } catch (error) {
              LogCustomError(
                '~ getAssociatedEventsForExpass-collectionHelper-' +
                  expass?.address,
                error?.method,
                error?.reason,
                ' ',
              );
            }
          }
        }
      }

      if (_associatedEvents.length > 0) {
        _associatedEvents = await getAssociatedCollectionsForEvent(
          _associatedEvents,
        );

        _associatedEvents = await getEventsMetadata(_associatedEvents);

        setAssociatedEvents(_associatedEvents);
      }
    } catch (error) {
      LogCustomError(
        '~ useExpassEventManager-getAssociatedEventsForExpass-' +
          expass?.address,
        error?.name,
        error?.message,
        '',
      );
    } finally {
      setLoadingEvents(false);
    }
  };

  const getEventsMetadata = async (events: AssociatedEvent[]) => {
    let _associatedEvents: AssociatedEvent[] = events;
    setLoadingEvents(true);
    for (const [index, eventObj] of _associatedEvents.entries()) {
      try {
        //? Fetch the Event Metadata
        const eventContract = Event__factory.connect(
          eventObj.event,
          staticProvider,
        );

        const metadata = await eventContract.getEventData();

        let response = await getIPFSData(metadata.dataURI);
        let ipfsData = await response?.json();

        let eData: IEventData = {
          ...ipfsData,
          name: metadata?.name,
          entity: metadata?.entity,
          dataURI: metadata?.dataURI,
          start: parseInt(metadata?.start?._hex),
          end: parseInt(metadata?.end?._hex),
          image: ipfsData ? getIPFSLink(ipfsData?.image) : '',
          thumbnailImage:
            ipfsData && ipfsData?.thumbnailImage
              ? getIPFSLink(ipfsData?.thumbnailImage)
              : '',
          optimizedImage:
            ipfsData && ipfsData?.optimizedImage
              ? getIPFSLink(ipfsData?.optimizedImage)
              : '',
        };

        _associatedEvents[index].eData = eData;
        _associatedEvents[index].dateString =
          eData.start !== 0 && eData.end !== 0
            ? `${formatDate(eData.start)} - ${formatDate(eData.end)}`
            : undefined;
      } catch (error) {
        LogCustomError(
          '~ getAssociatedEventsForExpass-event-details-' + eventObj.event,
          error?.method,
          error?.reason,
          ' ',
        );
      } finally {
        setLoadingEvents(false);
      }
    }

    return _associatedEvents;
  };

  const getCollectionsMetadataForEvent = async (
    selectedEvent: AssociatedEvent,
  ) => {
    setLoadingMetadata(true);

    let _event = selectedEvent;

    //* Fetch Ticket Metadata
    try {
      let ticket = _event?.ticket || '';
      let isValid = false;

      if (!ticket) {
        //? Refetch the associated ticket
        const { collectionHelper, collectionManager } = initContractFactories();

        const linkedCollections = await collectionHelper.getLinkedCollections(
          _event.event,
        );

        if (linkedCollections && linkedCollections?.length > 0) {
          for (const collection of linkedCollections) {
            //* Step 2: Fetch the collection type of linked collection
            const collectionType = await collectionManager.getCollectionType(
              collection,
            );

            //* Step 3: Check if type of collection is Ticket
            if (collectionType === CollectionType.TICKET) {
              //? The current collection is a ticket for the given event
              ticket = collection;
            }
          }
        }
      }

      //? Validate again in case ticket is not linked to the event
      if (ticket) {
        //* Fetch Ticket Metadata
        const collectibleDetails = await getCollectibleDetails(
          {
            networkID: networkId,
            provider: staticProvider,
            collectibleAddress: ticket,
            address,
            wallet,
          },
          { entityData },
          {},
        );

        if (collectibleDetails != null && collectibleDetails.dataURI != '') {
          //* Conditions for a Valid Ticket
          //? 1. Must be active
          //? 2. Must satisfy at least one of the following:
          //? 2.1 Should be Available or
          //? 2.2 Should be Owned or
          //? 2.3 Must have an active timestamp or
          //? 2.4 If No time is specified, then it should not be restricted by a Geofence

          //* If none of the above conditions are satisfied, the Ticket is invalid.

          if (
            collectibleDetails.isActive &&
            (collectibleDetails.isAvailable ||
              collectibleDetails.collectibleCount > 0 ||
              isActiveTimeStamp(
                collectibleDetails.start,
                collectibleDetails.end,
              ) ||
              (!collectibleDetails.start &&
                !collectibleDetails.end &&
                isLocationAvailable(null, collectibleDetails.area)))
          ) {
            //? The current ticket is valid
            isValid = true;
          }
          _event.tData = { ...collectibleDetails, isValid };

          //? Determine if the user has purchased the Ticket
          let tOwned =
            collectibleDetails?.collectibleCount > 0 &&
            collectibleDetails?.collectibleIds?.length > 0;

          //* If ticket is not owned by the user, skip fetching Collectible/Offer metadata
          if (!tOwned) {
            setLoadingMetadata(false);
            return _event;
          } else {
            //* Ticket is owned by the User
            //? Check for Redemption status
            mintLinkedCollectibles({
              ...collectibleDetails,
              linkCollectible: _event?.collectibles || [],
            });
            const eventContract = Event__factory.connect(
              _event.event,
              staticProvider,
            );

            const ticketStatus = await eventContract.redeemed(
              ticket,
              collectibleDetails.tokenId,
            );

            if (ticketStatus?.redeemed) {
              let redemptionStatus = {
                redeemed: ticketStatus?.redeemed,
                redeemedAt: formatDate(
                  +ticketStatus?.redemptionTimestamp,
                  true,
                ),
              };

              _event.tData = { ...collectibleDetails, redemptionStatus };
            }
          }
        }
      }
    } catch (error) {
      LogCustomError(
        '~ getCollectionsMetadataForEvent-event-ticket-getCollectibleDetails',
        error?.method,
        error?.reason,
        ' ',
      );
    }

    //* Fetch Collectibles Metadata
    try {
      const collectibles = _event?.collectibles || [];
      let _collectibles: ICollectibleDetail[] = [];

      //? Loop over all collectibles for given event
      for (const collectible of collectibles) {
        //* Fetch Collectible Metadata
        const collectibleDetails = await getCollectibleDetails(
          {
            networkID: networkId,
            provider: staticProvider,
            collectibleAddress: collectible,
            address,
            wallet,
          },
          { entityData },
          {},
        );

        if (
          collectibleDetails &&
          collectibleDetails.isActive &&
          (collectibleDetails.isAvailable ||
            collectibleDetails.collectibleCount > 0)
        ) {
          _collectibles.push(collectibleDetails);
        }
      }

      if (_collectibles.length > 0) {
        _event.cData = _collectibles;
      }
    } catch (error) {
      LogCustomError(
        '~ getCollectionsMetadataForEvent-event-collectibles-getCollectibleDetails',
        error?.method,
        error?.reason,
        ' ',
      );
    }

    //* Fetch Offers Metadata
    try {
      const offers = _event?.offers || [];
      const offerToCollectible = [];
      let _offers: ICollectibleDetail[] = [];

      //? Loop over all offers for given event
      for (const offer of offers) {
        //* Fetch Offer Metadata
        const collectibleDetails = await getCollectibleDetails(
          {
            networkID: networkId,
            provider: staticProvider,
            collectibleAddress: offer,
            address,
            wallet,
          },
          { entityData },
          {},
        );

        if (
          collectibleDetails &&
          collectibleDetails.isActive &&
          (collectibleDetails.isAvailable ||
            collectibleDetails.collectibleCount > 0)
        ) {
          if (collectibleDetails.collectibleCount > 0) {
            offerToCollectible.push(collectibleDetails);
          } else {
            _offers.push(collectibleDetails);
          }
        }
      }

      if (_offers.length > 0) {
        _event.oData = _offers;
      }
      if (offerToCollectible.length > 0) {
        _event.cData = [..._event.cData, ...offerToCollectible];
      }
    } catch (error) {
      LogCustomError(
        '~ getCollectionsMetadataForEvent-event-offers-getCollectibleDetails',
        error?.method,
        error?.reason,
        ' ',
      );
    }

    setLoadingMetadata(false);

    return _event;
  };

  return {
    loadingEvents,
    loadingMetadata,
    associatedEvents,
    getCollectionsMetadataForEvent,
    getAssociatedEventsForExpass,
    getEventsMetadata,
  };
};

export default useExpassEventManager;
