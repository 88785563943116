import React from 'react';
import { Text, View, StyleSheet, SafeAreaView } from 'react-native';
import LottieView from 'lottie-react-native';

import { defaultTheme } from '../../themes/loot8';
import HOCContainer from '../../components/HOCContainer';
import GradientButton from '../../components/GradientButton';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import congratulationsAnimation from '../../assets/animations/congratulationAnimation.json';

const EstPortalSuccessScreen: React.FC<{ route: any; navigation: any }> = ({
  route,
  navigation,
}) => {
  const activeHeight = useActiveDimensions().height;
  const {
    message,
    buttonLabel,
    navigateTo,
    navigationParams,
    onPressActonButton,
  } = route.params ?? {};
  const handleNavigation = () => {
    if (onPressActonButton) {
      onPressActonButton();
      return;
    }
    if (navigateTo) {
      navigation.navigate(navigateTo, navigationParams);
    } else {
      navigation.goBack();
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <LottieView
        autoPlay
        loop={false}
        style={[
          {
            width: activeHeight / 3.5,
            height: activeHeight / 3.5,
          },
          styles.animationStyles,
        ]}
        source={congratulationsAnimation}
      />
      <View style={styles.textContainer}>
        <Text style={styles.textPrimary}>Congratulations!</Text>
        <Text style={styles.textSecondary}>{message ? message : ''}</Text>
      </View>

      <GradientButton
        buttonLabel={buttonLabel ? buttonLabel : 'Return'}
        onPress={() => handleNavigation()}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 60,
  },
  animationStyles: {
    marginTop: 40,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    flex: 1,
    marginTop: 80,
    alignItems: 'center',
  },
  textPrimary: {
    fontSize: 18,
    textTransform: 'uppercase',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
  textSecondary: {
    fontSize: 16,
    width: '75%',
    marginTop: 10,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
});

export default HOCContainer({
  OriginalComponent: EstPortalSuccessScreen,
  withBottomTabs: false,
  isScrollEnabled: false,
});
