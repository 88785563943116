import React, { useState, useEffect } from 'react';
import {
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Modal,
  ActivityIndicator,
  Platform,
  useWindowDimensions,
} from 'react-native';
import { WebView } from 'react-native-webview';
import { useDispatch } from 'react-redux';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { AppDispatch } from '../../store';
import { getData, storeData } from '../../helpers/AppStorage';
import Constants from 'expo-constants';

const WebPayment = ({ onMessage, onDismiss }) => {
  const [showGateway, setShowGateway] = useState(true);
  const { networkId, staticProvider, address, wallet, userInfo } =
    useWeb3AuthContext();
  const [prog, setProg] = useState(false);
  const [progClr, setProgClr] = useState('#000');

  const { height, width } = useWindowDimensions();
  let paymentInterval = null;

  function onlocalDismiss() {
    setShowGateway(false);
    onDismiss(false);
  }

  const checkWebPurchaseStatus = async () => {
    const packagePurchaseStatus = await getData('@package_purchase_status');
    if (packagePurchaseStatus) {
      setShowGateway(false);
      onDismiss(true);
      onMessage(packagePurchaseStatus);
    }
    await clearLocalStoreData();
  };

  const clearLocalStoreData = async () => {
    await storeData('@package_purchase_status', '');
  };

  useEffect(() => {
    if (Platform.OS == 'web') {
      paymentInterval = setInterval(checkWebPurchaseStatus, 500);
    }
    return () => {
      if (Platform.OS == 'web' && paymentInterval) {
        clearInterval(paymentInterval);
        clearLocalStoreData();
      }
    };
  }, []);

  return (
    <>
      {showGateway && (
        <>
          <Modal
            visible={true}
            onDismiss={onlocalDismiss}
            onRequestClose={onlocalDismiss}
            animationType={'fade'}
            transparent>
            <View style={localStyles.webViewCon}>
              <SafeAreaView style={{ flex: 1 }}>
                <View style={localStyles.wbHead}>
                  <TouchableOpacity
                    style={localStyles.closeBTN}
                    onPress={onlocalDismiss}>
                    <Text
                      style={{
                        fontSize: 20,
                        fontWeight: '900',
                        color: '#ffffff',
                      }}>
                      X
                    </Text>
                  </TouchableOpacity>
                  <Text
                    style={{
                      flex: 1,
                      textAlign: 'center',
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: '#ffffff',
                    }}>
                    PayPal Gateway
                  </Text>
                  <View style={{ padding: 13, opacity: prog ? 1 : 0 }}>
                    <ActivityIndicator size={'small'} color={'#000'} />
                  </View>
                </View>
                <WebView
                  useWebKit={true}
                  source={{ uri: Constants.expoConfig.extra.WEB_PAYMENT_LINK }}
                  style={{ flex: 1 }}
                  onLoadStart={() => {
                    setProg(true);
                    setProgClr('#000');
                  }}
                  onLoadProgress={() => {
                    setProg(true);
                    setProgClr('#000');
                  }}
                  onLoadEnd={() => {
                    setProg(false);
                  }}
                  onLoad={() => {
                    setProg(false);
                  }}
                  // onMessage={onlocalMessage}
                  // injectedJavaScript={runscript}
                  // javaScriptEnabled={true}
                />
              </SafeAreaView>
            </View>
          </Modal>
        </>
      )}
    </>
  );
};

export default WebPayment;

const localStyles = StyleSheet.create({
  webViewCon: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#FFFFFF',
  },
  wbHead: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#53536e',
    zIndex: 25,
    elevation: 2,
  },
  closeBTN: {
    color: '#ffffff',
    backgroundColor: '#28284A',
    fontWeight: '900',
    padding: 13,
  },
});
