import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Text,
  View,
  RefreshControl,
  ActivityIndicator,
  FlatList,
} from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { SheetManager } from 'react-native-actions-sheet';
import { CatalogTypes } from '../../enums/catalog.enum';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { clearCatalog } from '../../helpers/Gadgets';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import {
  loadCatalogEvent,
  loadCatalogPassport,
  reloadCatCollectibleList,
  reloadCatEventList,
  reloadCatPassportList,
} from '../../slices/CatalogSlice';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import AppLoaderComponent from '../Loader';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { ScreenName } from '../../enums/screen.enum';
import EmptyList from '../EmptyList';
import HOCContainer from '../HOCContainer';
import TextInputComponent from '../TextInputComponent';
import useIsResponsive from '../../hooks/useIsResponsive';
import PortfolioCard from '../PortfolioCard';
import { useFocusEffect } from '@react-navigation/native';

function arrayUnion(arr1, arr2) {
  let union = [...arr1];

  arr2.forEach(item1 => {
    if (
      arr1.findIndex(
        item2 =>
          item1?.address.toLowerCase() === item2?.address.toLowerCase() &&
          Number(item1?.collectibleId) === Number(item2?.collectibleId),
      ) === -1
    ) {
      union.push(item1);
    }
  });

  return union;
}

function removeDuplicates(array: any) {
  return array.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        t =>
          t['collectibleId'] === item['collectibleId'] &&
          t?.address.toLowerCase() === item.address.toLowerCase(),
      ),
  );
}

export const CatalogList = ({
  route,
  navigation,
  activeTab,
  previousTab,
  isFocused,
}) => {
  const { networkId, staticProvider, address, userInfo, wallet, connected } =
    useWeb3AuthContext();
  const [availableCatalogs, setAvailableCatalogs] = useState([]);
  const [catalogListPassport, setCatalogListPassport] = useState([]);
  const [filteredCatalogs, setFilteredCatalogs] = useState([]);
  const [passportCollectible, setPassportCollectible] = useState([]);
  const [loadingCatalog, setLoadingCatalog] = useState(true);
  let catalogType: string = route.params?.type || activeTab;
  const loadingPassport = useAppSelector(
    state => state.Catalogs.loadingPassport,
  );
  const loadingEvent = useAppSelector(state => state.Catalogs.loadingEvent);
  const loadingCollectiable = useAppSelector(
    state => state.Catalogs.loadingCollectiable,
  );
  const loadingThirdParty = useAppSelector(
    state => state.Catalogs.loadingThirdParty,
  );
  const loadingOffers = useAppSelector(state => state.Catalogs.loadingOffers);

  //const catalogListPassport = useAppSelector((state) => state.Catalogs.CatalogPassportDetailsList);

  const catalogListEvent = useAppSelector(
    state => state.Catalogs.CatalogEventDetailsList,
  );
  const catalogListCollectiable = useAppSelector(
    state => state.Catalogs.CatalogCollectiableDetailsList,
  );
  const catalogListThirdParty = useAppSelector(
    state => state.Catalogs.CatalogThirdyPartyCollectiableDetailsList,
  );
  const catalogListOffers = useAppSelector(
    state => state.Catalogs.CatalogOffersDetailsList,
  );
  const isResponsive = useIsResponsive();
  const dispatch = useAppDispatch();
  const [refreshing, setRefreshing] = useState(false);
  const [collectibleToSend, setCollectibleToSend] = useState<any>();
  const [initialCatalogLoading, setInitialCatalogLoading] = React.useState(
    catalogType == CatalogTypes.COLLECTIBLES ? true : false,
  );
  const [search, setSearch] = useState<string>('');
  const flatListRef = useRef<FlashList<any>>(null);

  let selectedPassport = route.params?.passport;
  useEffect(() => {
    if (route.params?.passportCollectible) {
      setPassportCollectible(route.params?.passportCollectible);
    }
  }, []);

  const selectedCollectibleListing = useMemo(
    () =>
      collectibleToSend &&
      collectibleToSend?.marketPlaceConfig?.listingIndex &&
      collectibleToSend?.marketPlaceConfig?.listingIndex?.findIndex(
        obj => obj.tokenId === Number(collectibleToSend.collectibleId),
      ) !== -1,
    [collectibleToSend],
  );

  const onRefresh = React.useCallback(async () => {
    // setRefreshing(true);
    if (catalogType == CatalogTypes.PASSPORT) {
      await clearCatalog('Passports');
      await dispatch(
        reloadCatPassportList({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
          userInfo,
        }),
      ).then(() => setRefreshing(false));
    } else if (catalogType == CatalogTypes.TICKET) {
      await clearCatalog('Events');
      await dispatch(
        reloadCatEventList({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
          userInfo,
        }),
      ).then(() => setRefreshing(false));
    } else if (catalogType == CatalogTypes.COLLECTIBLES) {
      await clearCatalog('Collectibles');
      await dispatch(
        reloadCatCollectibleList({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
          userInfo,
        }),
      ).then(() => setRefreshing(false));
    }
  }, [catalogType]);

  useEffect(() => {
    const loadRefreshContent = async () => {
      if (connected && wallet && !selectedPassport) {
        if (catalogType == CatalogTypes.TICKET) {
          await dispatch(
            loadCatalogEvent({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet,
              userInfo,
            }),
          );
        } else if (catalogType == CatalogTypes.PASSPORT) {
          const data = await dispatch(
            loadCatalogPassport({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet,
              userInfo,
              isCache: false,
            }),
          );
          setCatalogListPassport(data.payload.CatalogPassportDetailsList);
        } else if (
          catalogType == CatalogTypes.COLLECTIBLES &&
          !loadingCollectiable
        ) {
          await dispatch(
            reloadCatCollectibleList({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet,
              userInfo,
              isCache: false,
            }),
          );
        }
      }
    };
    loadRefreshContent();
  }, [connected, activeTab]);

  useEffect(() => {
    LogToLoot8Console('rerender catalog page');
    if (catalogType == CatalogTypes.PASSPORT) {
      setLoadingCatalog(loadingPassport);
    } else if (catalogType == CatalogTypes.TICKET) {
      setLoadingCatalog(loadingEvent);
    } else if (catalogType == CatalogTypes.COLLECTIBLES) {
      setLoadingCatalog(
        loadingCollectiable || loadingThirdParty || loadingOffers,
      );
      //setLoadingCollectibles(loadingCollectiable || loadingThirdParty);

      let catalogList = null;
      if (catalogListCollectiable) {
        catalogList = catalogList
          ? arrayUnion(catalogList, catalogListCollectiable)
          : [...catalogListCollectiable];
        catalogList = catalogList.sort((a, b) => b.timestamp - a.timestamp);
      }
      if (catalogListThirdParty) {
        catalogList = catalogList
          ? arrayUnion(catalogList, catalogListThirdParty)
          : [...catalogListThirdParty];
        catalogList = catalogList.sort((a, b) => b.timestamp - a.timestamp);
      }
      if (catalogListOffers) {
        catalogList = catalogList
          ? arrayUnion(catalogList, catalogListOffers)
          : [...catalogListOffers];
        catalogList = catalogList.sort((a, b) => b.timestamp - a.timestamp);
      }
      // Remove duplicates based on collectibleId
      catalogList = removeDuplicates(catalogList);
      setTimeout(() => {
        setAvailableCatalogs(
          catalogList?.sort((a, b) =>
            a.interactiveCollectible === b.interactiveCollectible
              ? 0
              : a.interactiveCollectible
              ? -1
              : 1,
          ),
        );

        // set passport catalog when page redirect from passport list page.
        if (
          selectedPassport &&
          passportCollectible &&
          passportCollectible.length > 0
        ) {
          setAvailableCatalogs(
            passportCollectible?.sort((a, b) =>
              a.interactiveCollectible === b.interactiveCollectible
                ? 0
                : a.interactiveCollectible
                ? -1
                : 1,
            ),
          );
        }
        setInitialCatalogLoading(false);
      }, 500);
    }
  }, [
    loadingPassport,
    loadingEvent,
    loadingCollectiable,
    loadingThirdParty,
    loadingOffers,
    passportCollectible,
  ]);

  // load events
  useEffect(() => {
    LogToLoot8Console('rerender event catalog page');
    if (catalogType == CatalogTypes.TICKET) {
      if (catalogListEvent?.length > 0) {
        setAvailableCatalogs(catalogListEvent);
      }
    }
  }, [catalogListEvent]);

  // load expass
  useEffect(() => {
    LogToLoot8Console('rerender expass page');
    if (catalogType == CatalogTypes.PASSPORT) {
      if (catalogListPassport?.length > 0) {
        let sortedCatalogList = [...catalogListPassport];
        sortedCatalogList = sortedCatalogList
          ?.filter(pass => pass.isPremium === true)
          ?.sort((a, b) => b.timestamp - a.timestamp);
        const filterDuplicates = removeDuplicates(sortedCatalogList);
        setAvailableCatalogs(filterDuplicates);
      }
    }
  }, [catalogListPassport, isFocused]);

  //load at every catlog open
  // useEffect(() => {
  //   console.log("rerender catalog page");
  //   if (catalogType == CatalogTypes.PASSPORT) {
  //     dispatch(loadCatalogPassport({ networkID: networkId, provider: staticProvider, address, wallet, userInfo }));

  //   }
  //   else if (catalogType == CatalogTypes.TICKET) {
  //     dispatch(loadCatalogEvent({ networkID: networkId, provider: staticProvider, address, wallet, userInfo }));

  //   }
  //   else if (catalogType == CatalogTypes.COLLECTIBLES) {
  //     // dispatch(loadCatalogCollectiable({ networkID: networkId, provider: staticProvider, address, wallet, userInfo }));
  //     dispatch(loadCatalogThirdPartyCollectiable({ networkID: networkId, provider: staticProvider, address, wallet, userInfo }));
  //   }
  // }, [catalogType]);

  const navigateToCollectibleDetails = (
    item,
    showListOnMarketplace = false,
  ) => {
    let from = selectedPassport ? 'PassportPage' : 'Catalog';
    // Temporarily disabling URL functionality
    // if (Platform.OS === 'web') {
    //   const urlSearchParams = new URLSearchParams();
    //   urlSearchParams.set('page', 'portfolio-expasses');
    //   urlSearchParams.set('type', catalogType);
    //   urlSearchParams.set('from', from);
    //   urlSearchParams.set('chainId', item.chainId);
    //   urlSearchParams.set('address', item.address);
    //   urlSearchParams.set('image', item.image);
    //   urlSearchParams.set('name', item.name);
    //   if (item.isMp4Collectible) {
    //     urlSearchParams.set('isMp4Collectible', item.isMp4Collectible);
    //     item.video ? urlSearchParams.set('video', item.video) : null;
    //   }
    //   if (item.animationUrl) {
    //     urlSearchParams.set('animationUrl', item.animationUrl);
    //   }
    //   const newUrl = window.location.origin + '?' + urlSearchParams.toString();
    //   window.history.pushState(null, 'portfolio-expasses', newUrl);
    // }
    if (catalogType === CatalogTypes.PASSPORT) {
      navigation.navigate(ScreenName.PASSPORT_DETAIL, {
        passportAddress: item.address,
        chainId: item.chainId,
        catalog: item,
        availableCatalogs: availableCatalogs,
        type: catalogType,
        passportCollectible: passportCollectible,
        from: from,
        passport: selectedPassport,
        showListOnMarketplace: showListOnMarketplace,
        showUnlistFromMarketplace:
          collectibleToSend?.marketPlaceConfig && selectedCollectibleListing,
        updateParent:
          selectedPassport && route.params?.updateParent
            ? route.params?.updateParent
            : null,
      });
    } else {
      collectibleToSend?.marketPlaceConfig && selectedCollectibleListing;
      navigation.navigate('CatalogDetails', {
        catalog: item,
        availableCatalogs: availableCatalogs,
        type: catalogType,
        passportCollectible: passportCollectible,
        from: from,
        passport: selectedPassport,
        showListOnMarketplace: showListOnMarketplace,
        showUnlistFromMarketplace:
          collectibleToSend?.marketPlaceConfig && selectedCollectibleListing,
        updateParent:
          selectedPassport && route.params?.updateParent
            ? route.params?.updateParent
            : null,
      });
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);

    // Use optional chaining to handle the case when availableCatalogs is undefined
    const filteredCatalogs = availableCatalogs?.filter((catalog: any) => {
      const { name = '', subTitle = '', details = '' } = catalog;
      const searchLower = value?.toLowerCase();

      // Case-insensitive search for name, subTitle, and catalogSubDetails
      if (searchLower) {
        return (
          name?.toLowerCase()?.includes(searchLower) ||
          subTitle?.toLowerCase()?.includes(searchLower) ||
          details?.toLowerCase()?.includes(searchLower)
        );
      }

      // Return false when searchLower is falsy (undefined or empty string)
      return false;
    });

    // Set the state with the new filtered array
    setFilteredCatalogs(filteredCatalogs);
  };

  const showBackground = useMemo(
    () => isResponsive && navigation && navigation?.getState()?.index === 0,
    [isResponsive, navigation],
  );
  /// Marketplace Listing
  const onMyPassportsOptionsClick = async selectedItem => {
    await SheetManager.show('ExpassThreeDotSettingsSheet', {
      payload: {
        selectedEXPass: selectedItem,
        navigation,
        setSearchPassportText: setSearch,
        onListForSalePressed: () => onListForSalePressed(selectedItem),
        type: catalogType === CatalogTypes.PASSPORT ? 'Expass' : 'Collectible',
        updateStateDataFunc: success => {
          if (success) {
            setAvailableCatalogs(prevState => {
              return prevState.filter(
                obj => obj.address !== selectedItem.address,
              );
            });
          }
        },
      },
    });
  };
  const onListForSalePressed = async itemFromParams => {
    if (itemFromParams) {
      await SheetManager.show('ListingModalSheet', {
        payload: {
          itemObject: itemFromParams,
          navigation,
          type:
            catalogType === CatalogTypes.PASSPORT ? 'Expass' : 'Collectible',
          updateStateDataFunc: updatedObject => {
            if (passportCollectible.length > 0) {
              setPassportCollectible(prevState => {
                return prevState.map(obj => {
                  if (obj.address === itemFromParams.address) {
                    return {
                      ...obj,
                      marketPlaceConfig: updatedObject.marketPlaceConfig,
                    };
                  }
                  return obj;
                });
              });
            }
            setAvailableCatalogs(prevState => {
              return prevState.map(obj => {
                if (obj.address === itemFromParams.address) {
                  return {
                    ...obj,
                    marketPlaceConfig: updatedObject.marketPlaceConfig,
                  };
                }
                return obj;
              });
            });
          },
        },
      });
    }
  };
  /// Marketplace Listing End
  //update collectibles data after transfer from details screen START
  useFocusEffect(
    useCallback(() => {
      if (route.params?.updateDataAfterTransfer) {
        setTimeout(async () => {
          setLoadingCatalog(true);
          await dispatch(
            reloadCatCollectibleList({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet,
              userInfo,
              isCache: false,
            }),
          );
          setLoadingCatalog(false);
        }, 500);
      }
    }, [route.params?.updateDataAfterTransfer]),
  );
  //update collectibles data after transfer from details screen END

  return (
    <>
      <View style={{ flex: 1 }}>
        {!showBackground && (
          <View
            style={{
              paddingBottom: 10,
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 4,
              justifyContent: 'center',
            }}>
            <View style={{ flex: 0.1 }} />
            {route?.name == 'CatalogList' && (
              <View
                style={{
                  flex: 0.8,
                }}>
                {selectedPassport ? (
                  <Text
                    style={[
                      styles.contentHeaderText,
                      {
                        color: defaultTheme.PRIMARY_TEXT_COLOR,
                        fontSize: 18,
                        fontWeight: '700',
                      },
                    ]}>
                    {selectedPassport.name} -{' '}
                    {catalogType === CatalogTypes.PASSPORT
                      ? `My ExPasses`
                      : `Associated ${catalogType}`}
                  </Text>
                ) : (
                  <Text
                    style={[
                      styles.contentHeaderText,
                      {
                        color: defaultTheme.PRIMARY_TEXT_COLOR,
                        fontSize: 18,
                        fontWeight: '700',
                      },
                    ]}>
                    {catalogType === CatalogTypes.PASSPORT
                      ? `My ExPasses`
                      : `Associated ${catalogType}`}
                  </Text>
                )}
              </View>
            )}
          </View>
        )}

        {!initialCatalogLoading &&
          !loadingCatalog &&
          availableCatalogs &&
          availableCatalogs.length > 0 && (
            <>
              <View style={{ marginTop: 7.5 }} />
              <TextInputComponent
                value={search}
                type="primary"
                leftIcon="magnify"
                placeholder="Search..."
                setOnChange={handleSearch}
                rightIcon={!!search ? 'close' : null}
                onRightIconPress={() => setSearch('')}
              />
              <FlatList
                ref={flatListRef}
                style={{ flex: 1 }}
                contentContainerStyle={{
                  flexGrow: 1,
                  paddingTop: 15,
                  paddingBottom: 150,
                }}
                estimatedItemSize={200}
                showsVerticalScrollIndicator={false}
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                  />
                }
                data={search ? filteredCatalogs : availableCatalogs}
                renderItem={({ item, index }) => (
                  <PortfolioCard
                    item={item}
                    index={index}
                    catalogType={catalogType}
                    navigateToCollectibleDetails={navigateToCollectibleDetails}
                    onDotsMenuPress={onMyPassportsOptionsClick}
                  />
                )}
                extraData={[catalogType, availableCatalogs]}
                numColumns={isResponsive ? 2 : 1}
                key={isResponsive}
                columnWrapperStyle={
                  isResponsive && { justifyContent: 'space-between' }
                }
                keyExtractor={(item, index) =>
                  `${item.address}-${item.collectibleId}`
                }
                ItemSeparatorComponent={() => (
                  <View style={{ marginVertical: 5 }} />
                )}
                ListEmptyComponent={
                  search ? (
                    <EmptyList message="No search results found." />
                  ) : null
                }
              />
            </>
          )}

        {!initialCatalogLoading &&
          !loadingCatalog &&
          (!availableCatalogs || availableCatalogs.length == 0) && (
            <View
              style={{
                width: '95%',
                marginBottom: 10,
                maxHeight: '100%',
                minHeight: 350,
                marginHorizontal: 10,
                justifyContent: 'center',
                alignSelf: 'center',
              }}>
              <View
                style={{
                  alignSelf: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '5%',
                  marginTop: '5%',
                  paddingBottom: '5%',
                }}>
                <View style={{ marginTop: 30 }}>
                  <EmptyList
                    message={`No ${
                      catalogType === CatalogTypes.PASSPORT
                        ? `ExPasses`
                        : `${catalogType}`
                    } to display at the moment`}
                  />
                </View>
              </View>
            </View>
          )}
        {initialCatalogLoading || loadingCatalog ? (
          <View
            style={{
              width: '95%',
              marginBottom: 10,
              maxHeight: '100%',
              minHeight: 350,
              marginHorizontal: 10,
              justifyContent: 'center',
              alignSelf: 'center',
            }}>
            <View
              style={{
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '5%',
                marginTop: '5%',
                paddingBottom: '5%',
              }}>
              <View style={{ marginTop: 30 }}>
                <AppLoaderComponent />
              </View>
            </View>
          </View>
        ) : null}
        {initialCatalogLoading && (
          <View
            style={{
              width: '95%',
              marginBottom: 10,
              maxHeight: '100%',
              minHeight: 150,
              marginHorizontal: 10,
              justifyContent: 'center',
              alignSelf: 'center',
            }}>
            <View style={[styles.tile, { paddingBottom: 15 }]}>
              <View
                style={[{ justifyContent: 'center', alignItems: 'center' }]}>
                <ActivityIndicator
                  size={'large'}
                  color={defaultTheme.PRIMARY_TEXT_COLOR}
                />
              </View>
            </View>
          </View>
        )}
      </View>
    </>
  );
};

export default HOCContainer({
  OriginalComponent: CatalogList,
  isScrollEnabled: false,
});
