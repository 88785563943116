import {
  ActivityIndicator,
  Image,
  Keyboard,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
  findNodeHandle,
} from 'react-native';
import React, { ReactNode, useRef } from 'react';
import { defaultTheme } from '../../themes/loot8';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { LinearGradient } from 'expo-linear-gradient';
import {
  PRIVATE_MESSAGE_FRIEND_REQUESTS,
  ToastCustomMessageType,
} from '../../appconstants';
import { LogErrors } from '../../helpers/AppLogger';
import { showToastMessage } from '../../helpers/Gadgets';
import { removeFriend, updateFriendRequest } from '../../slices/friendsSlice';
import { getData, storeData } from '../../helpers/AppStorage';
import {
  IFriendMessageRequest,
  addClearMessageInDraft,
  deletePrivateMsgRequestToFriend,
  sendPrivateMessageToFriend,
  sendPrivateMsgRequestToFriend,
} from '../../slices/PrivateMessageSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { TextInput } from 'react-native-paper';
import { uploadUserData, wait } from '../../helpers/ipfs';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { MessageRetrivalStatus } from '../Friends/directMessage';
import { MessageType } from '../../interfaces/IMessages';
import micIcon from '../../assets/mic.png';
import smileyIcon from '../../assets/smiley.png';
import attachmentIcon from '../../assets/attachment.png';
// initialize textinput height for web version.
const INITIAL_TEXTINPUT_HEIGHT = 50;

const MessageInputComponent = ({
  latestFriendRequest,
  setIsMsgRequestLoading,
  friendsPublicKey,
  user,
  setNextFriendRequestAllowed,
  setMsgFriendRequestSent,
  setFriendRequestMsg,
  isMsgRequestLoading,
  msgFriendRequestSent,
  isNextFriendReqAllowed,
  isUserMutualFriend,
  setShowAddImage,
  isLoading,
  messageText,
  setMessageText,
  MESSAGE_MAX_LENGTH,
  textInputHeight,
  onMessageDraft,
  setTextInputHeight,
  selectedImage,
  setSelectedImage,
  setIsLoading,
  setMessageRetrivalStatus,
  setLatestFriendRequest,
  setIsMessageSent,
  quoteMsg,
  setQuoteMsg,
  setSelectedMsg,
}) => {
  const KeyboardScrollView = useRef<KeyboardAwareScrollView>();

  const dispatch = useDispatch<AppDispatch>();
  const {
    networkId,
    wallet,
    staticProvider,
    encryptMessage,
    address,
    decryptMessage,
  } = useWeb3AuthContext();

  const cancelFriendRequest = async () => {
    setIsMsgRequestLoading(true);
    if (latestFriendRequest && latestFriendRequest.messageId) {
      const response = await dispatch(
        deletePrivateMsgRequestToFriend({
          networkID: networkId,
          address: user?.wallet,
          wallet: wallet,
          messageId: latestFriendRequest.messageId,
          requestAccepted: false,
          publicKey: friendsPublicKey,
          decryptMessage: decryptMessage,
        }),
      );
      if (
        response &&
        response.payload.status &&
        response.payload.status == 200
      ) {
        setNextFriendRequestAllowed(latestFriendRequest.timestamp);
        setMsgFriendRequestSent(false);
        setFriendRequestMsg(false);
        // setLatestFriendRequest(null);
        //remove stored message request when user cancels request
        await dispatch(
          updateFriendRequest({
            wallet: user?.wallet,
            isRequestSend: true,
            isCancelled: true,
            messageID: response.payload.latestMsgRequest?.messageId,
            timestamp: response.payload.latestMsgRequest?.timestamp,
            type: 'remove',
          }),
        );
        getData(PRIVATE_MESSAGE_FRIEND_REQUESTS).then(
          (friendRequestList: IFriendMessageRequest[] = []) => {
            if (friendRequestList && friendRequestList.length > 0) {
              friendRequestList = friendRequestList.filter(
                r => r.requestMsgId != latestFriendRequest.messageId,
              );
            }
            storeData(PRIVATE_MESSAGE_FRIEND_REQUESTS, friendRequestList);
          },
        );
        await dispatch(
          removeFriend({
            networkID: networkId,
            provider: staticProvider,
            address: address,
            wallet: wallet,
            followUserAddress: user?.wallet,
          }),
        );
      } else {
        showToastMessage(
          ToastCustomMessageType.INFO,
          'Unable to cancel request. Please try again later!',
        );
      }
    } else {
      LogErrors(
        new Error(
          `Unable to find request or request ID ${latestFriendRequest}`,
        ),
        address,
        networkId,
        [
          { tag: 'error', value: 'PRIVATE MESSAGE REQUEST CANCEL ERROR' },
          { tag: 'friendAddress', value: user?.wallet },
        ],
      );
      showToastMessage(
        ToastCustomMessageType.INFO,
        'Unable to cancel request. Please try again later!',
      );
    }
    setIsMsgRequestLoading(false);
  };

  const scrollToText = async (node: ReactNode) => {
    await wait(50);
    const offset = Platform.OS === 'android' ? 290 : 220;
    KeyboardScrollView.current.scrollToFocusedInput(node, offset);
  };

  // adjust textarea height based on input data.
  // this is applicable on web version only.
  const adjustTextInputSize = evt => {
    if (Platform.OS === 'web') {
      const el = evt?.target || evt?.nativeEvent?.target;
      if (el) {
        const MAX_HEIGHT = 110;
        el.style.height = 0;
        const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight;
        el.style.height =
          newHeight < MAX_HEIGHT ? `${newHeight}px` : `${MAX_HEIGHT}px`;
        setTextInputHeight(newHeight < MAX_HEIGHT ? newHeight : MAX_HEIGHT);
      }
    }
  };

  const hasValue = () => {
    if (isUserMutualFriend) {
      return (
        (messageText && messageText.trim().length > 0) ||
        (selectedImage && selectedImage !== '')
      );
    } else {
      return (
        !isMsgRequestLoading &&
        !msgFriendRequestSent &&
        isNextFriendReqAllowed &&
        messageText &&
        messageText.length > 0
      );
    }
  };

  const onSendMessage = async () => {
    if (
      (messageText && messageText.trim().length > 0) ||
      (selectedImage && selectedImage !== '') ||
      !isUserMutualFriend
    ) {
      if (Keyboard && Keyboard.dismiss) {
        Keyboard.dismiss();
      }
      setMessageRetrivalStatus(MessageRetrivalStatus.MESSAGE_SEND);
      setIsLoading(true);

      if (friendsPublicKey) {
        // encrypt the message
        if (isUserMutualFriend) {
          const encrptedMessage = encryptMessage(
            messageText.trim(),
            friendsPublicKey,
          );
          if (encrptedMessage) {
            let response = null;
            let attachments: any = {};
            if (selectedImage && selectedImage !== '') {
              attachments.name = encryptMessage(
                `Direct Message Image_${Date.now().toString()}`,
                friendsPublicKey,
              );
              const encrptedImage = encryptMessage(
                selectedImage,
                friendsPublicKey,
              );
              attachments.uri = await uploadUserData(
                encrptedImage,
                wallet,
                `DirectMessage_${Date.now().toString()}.txt`,
              );
            }

            response = await dispatch(
              sendPrivateMessageToFriend({
                networkID: networkId,
                provider: staticProvider,
                address: user?.wallet,
                wallet: wallet,
                publicKey: friendsPublicKey,
                text: encrptedMessage,
                decryptMessage,
                parentId: quoteMsg?.messageId ?? undefined,
                messageType: quoteMsg ? MessageType.quote : MessageType.text,
                attachments,
              }),
            );
            if (
              response &&
              response.payload.status &&
              response.payload.status == 200
            ) {
              setIsMessageSent(true);
              setMessageText('');
              setQuoteMsg(null);
              setSelectedMsg(null);
              setSelectedImage('');
              // set textinput height for web version only
              setTextInputHeight(INITIAL_TEXTINPUT_HEIGHT);
              // clear message from draft
              dispatch(
                addClearMessageInDraft({
                  networkID: networkId,
                  provider: staticProvider,
                  address: user?.wallet,
                  wallet: wallet,
                  publicKey: friendsPublicKey,
                  text: null,
                }),
              );
            } else {
              showToastMessage(
                ToastCustomMessageType.INFO,
                'Unable to post message. Please try again later!',
              );
            }
          } else {
            LogErrors(
              new Error(`Error in Encrypting Message`),
              address,
              networkId,
              [
                { tag: 'error', value: 'PRIVATE MESSAGE ERROR' },
                { tag: 'friendAddress', value: user?.wallet },
                { tag: 'Message', value: `${messageText}` },
              ],
            );
            showToastMessage(
              ToastCustomMessageType.INFO,
              'Unable to post message. Please try again later!',
            );
          }
        } else {
          await sendFriendRequestMsg();
        }
      } else {
        LogErrors(
          new Error(`Unable to find key for ${user?.name}`),
          address,
          networkId,
          [
            { tag: 'error', value: 'PRIVATE MESSAGE ERROR' },
            { tag: 'friendAddress', value: user?.wallet },
          ],
        );
        showToastMessage(
          ToastCustomMessageType.INFO,
          'Unable to post message. Please try again later!',
        );
      }

      setIsLoading(false);
    }
  };

  const sendFriendRequestMsg = async () => {
    let msgToSend = messageText.trim();
    setMessageText(msgToSend);
    const encryptedMsgRequest = encryptMessage(msgToSend, friendsPublicKey);
    if (encryptedMsgRequest) {
      const response = await dispatch(
        sendPrivateMsgRequestToFriend({
          networkID: networkId,
          address: user?.wallet,
          wallet: wallet,
          publicKey: friendsPublicKey,
          text: encryptedMsgRequest,
          decryptMessage,
        }),
      );
      if (
        response &&
        response.payload.status &&
        response.payload.status == 200
      ) {
        setMsgFriendRequestSent(true);
        setFriendRequestMsg(true);
        setLatestFriendRequest(response.payload.latestMsgRequest);
        setNextFriendRequestAllowed(
          response.payload.latestMsgRequest?.timestamp,
        );
        await dispatch(
          updateFriendRequest({
            wallet: user?.wallet,
            isRequestSend: true,
            isCancelled: false,
            messageID: response.payload.latestMsgRequest?.messageId,
            timestamp: response.payload.latestMsgRequest?.timestamp,
            type: 'add',
          }),
        );
      } else {
        showToastMessage(
          ToastCustomMessageType.INFO,
          'Unable to send friend request. Please try again later!',
        );
      }
    }
  };
  return (
    <>
      {!isMsgRequestLoading && (
        <>
          {!msgFriendRequestSent ? (
            (isNextFriendReqAllowed || isUserMutualFriend) && (
              <View style={componentStyles.inputContainer}>
                <View
                  style={[
                    componentStyles.row,
                    {
                      flex: 1,
                    },
                  ]}>
                  {/* Camera button start */}
                  {isUserMutualFriend && (
                    <LinearGradient
                      colors={[
                        defaultTheme.GRADIENT_COLOR1,
                        defaultTheme.GRADIENT_COLOR2,
                      ]}
                      end={{ x: 0.0001, y: 0.2 }}
                      start={{ x: 0.00001, y: 0.8 }}
                      style={[
                        componentStyles.gradientBtnContainer,
                        { marginLeft: 4 },
                      ]}>
                      <Pressable
                        style={componentStyles.cameraBtn}
                        disabled={isLoading}
                        onPress={() => setShowAddImage(true)}>
                        <MaterialCommunityIcons
                          name="camera"
                          color={defaultTheme.PRIMARY_TEXT_COLOR}
                          size={20}
                        />
                      </Pressable>
                    </LinearGradient>
                  )}
                  {/* Camera button End */}

                  <View
                    style={[
                      componentStyles.row,
                      {
                        flex: 1,
                      },
                    ]}>
                    {!msgFriendRequestSent ? (
                      <TextInput
                        onFocus={event => {
                          Platform.OS === 'ios' &&
                            scrollToText(findNodeHandle(event.target));
                        }}
                        style={[
                          componentStyles.input,
                          Platform.OS === 'android' && { height: 55 },
                        ]}
                        value={messageText}
                        onChangeText={text => {
                          if (text && text.length <= MESSAGE_MAX_LENGTH) {
                            setMessageText(text);
                          } else if (text.length === 0) {
                            setMessageText(text);
                          }
                        }}
                        maxLength={MESSAGE_MAX_LENGTH}
                        placeholder={'Message...'}
                        placeholderTextColor={defaultTheme.SECONDARY_TEXT_COLOR}
                        contentStyle={[
                          {
                            fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                          },
                          Platform.OS === 'web' && { height: textInputHeight },
                        ]}
                        cursorColor="#fff"
                        textColor={
                          isUserMutualFriend
                            ? '#FFFFFF'
                            : !msgFriendRequestSent &&
                                isNextFriendReqAllowed &&
                                !isMsgRequestLoading
                              ? '#FFFFFF'
                              : '#bdc6d5'
                        }
                        mode="outlined"
                        selectionColor={defaultTheme.SECONDARY_TEXT_COLOR}
                        activeOutlineColor="transparent"
                        outlineColor="transparent"
                        returnKeyType="default"
                        onBlur={() => {
                          if (isUserMutualFriend) onMessageDraft();
                        }}
                        multiline={true}
                        editable={
                          isUserMutualFriend
                            ? !isLoading
                            : !msgFriendRequestSent &&
                              isNextFriendReqAllowed &&
                              !isMsgRequestLoading
                        }
                        onChange={adjustTextInputSize}
                        onLayout={adjustTextInputSize}
                      />
                    ) : (
                      <ScrollView
                        contentContainerStyle={{ flexGrow: 1, padding: 15 }}
                        style={{
                          maxHeight: 120,
                        }}
                        showsVerticalScrollIndicator={false}>
                        <Text style={componentStyles.msgText}>
                          {messageText}
                        </Text>
                      </ScrollView>
                    )}
                    {selectedImage && selectedImage !== '' && (
                      <View style={componentStyles.selectedImageContainer}>
                        <Image
                          source={{ uri: selectedImage }}
                          style={componentStyles.selectedImage}
                          resizeMethod="scale"
                          resizeMode="contain"
                        />
                        {!isLoading && (
                          <Pressable
                            onPress={() => setSelectedImage(null)}
                            style={componentStyles.closeBtn}>
                            <MaterialCommunityIcons
                              name="window-close"
                              size={17}
                              style={{ color: '#404040' }}
                            />
                          </Pressable>
                        )}
                      </View>
                    )}
                  </View>
                </View>
                <View style={[componentStyles.row, { marginRight: 5 }]}>
                  <Pressable
                    onPress={() => setShowAddImage(true)}
                    style={componentStyles.btnContainer}>
                    <Image
                      source={attachmentIcon}
                      style={componentStyles.icon}
                    />
                  </Pressable>
                  <Pressable
                    onPress={() => null}
                    style={componentStyles.btnContainer}>
                    <Image
                      source={smileyIcon}
                      style={[
                        componentStyles.icon,
                        { tintColor: defaultTheme.PRIMARY_TEXT_COLOR },
                      ]}
                    />
                  </Pressable>
                  {/* Voice message button start */}
                  {isLoading ? (
                    <ActivityIndicator
                      style={{ marginLeft: 5 }}
                      size={20}
                      color={defaultTheme.PRIMARY_TEXT_COLOR}
                    />
                  ) : (
                    <LinearGradient
                      colors={
                        hasValue()
                          ? [
                              defaultTheme.GRADIENT_COLOR1,
                              defaultTheme.GRADIENT_COLOR2,
                            ]
                          : ['transparent', 'transparent']
                      }
                      end={{ x: 0.0001, y: 0.2 }}
                      start={{ x: 0.00001, y: 0.8 }}
                      style={componentStyles.gradientBtnContainer}>
                      <Pressable
                        onPress={() => onSendMessage()}
                        style={[
                          componentStyles.btnContainer,
                          {
                            borderRadius: hasValue() ? 15 : 0,
                          },
                        ]}>
                        {hasValue() ? (
                          <MaterialCommunityIcons
                            name={'send'}
                            size={16}
                            color={defaultTheme.PRIMARY_TEXT_COLOR}
                            style={{ marginLeft: 5 }}
                          />
                        ) : (
                          <Image
                            source={micIcon}
                            style={[
                              componentStyles.icon,
                              { tintColor: defaultTheme.PRIMARY_TEXT_COLOR },
                            ]}
                          />
                        )}
                      </Pressable>
                    </LinearGradient>
                  )}
                  {/* Voice message button end */}
                </View>
              </View>
            )
          ) : (
            <View style={componentStyles.cancelContainer}>
              <Text
                style={
                  componentStyles.title
                }>{`Invite to chat has been sent to ${user?.name}`}</Text>
              <Text style={componentStyles.subtitle}>
                Once your invitation is accepted, you'll have the ability to
                send additional messages.
              </Text>
              <Pressable onPress={cancelFriendRequest}>
                <Text style={componentStyles.cancelBtn}>CANCEL INVITE</Text>
              </Pressable>
            </View>
          )}
        </>
      )}
    </>
  );
};

export default MessageInputComponent;

const componentStyles = StyleSheet.create({
  input: {
    textAlignVertical: 'center',
    backgroundColor: 'transparent',
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    flex: 1,
    paddingTop: 7.5,
  },
  title: {
    fontSize: 12,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 12,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: '#DBE2ED',
    textAlign: 'center',
    marginBottom: 10,
  },
  cancelBtn: {
    color: '#fff',
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
  },
  cancelContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 15,
    borderTopWidth: 1,
    borderColor: '#FFFFFF17',
  },
  inputContainer: {
    borderRadius: 56,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 5,
  },
  btnContainer: {
    width: 30,
    height: 30,
    marginRight: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  gradientBtnContainer: {
    width: 40,
    height: 40,
    borderRadius: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  cameraBtn: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 20,
    height: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedImage: {
    height: 70,
    width: 70,
    backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
    borderColor: '#dfdfdf',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedImageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 70,
    height: 70,
  },
  closeBtn: {
    position: 'absolute',
    backgroundColor: '#F0F0F0B0',
    height: 23,
    width: 23,
    borderRadius: 30,
    top: 5,
    right: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  msgText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    textAlignVertical: 'center',
    color: '#bdc6d5',
  },
});
