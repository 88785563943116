import React from 'react';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { defaultTheme } from '../../../themes/loot8';
import TextInputComponent from '../../../components/TextInputComponent';

import facebookIcon from '../../../assets/socialicons/facebook.png';
import twitterIcon from '../../../assets/socialicons/twitter.png';
import tikTokIcon from '../../../assets/socialicons/tiktok.png';
import instagramIcon from '../../../assets/socialicons/instagram.png';

type ProfileCreationSocialsProps = {
  handleSocialsChange: (platform: string, value: string) => void;
  socialLinks: any;
};

const ProfileCreationSocials: React.FC<ProfileCreationSocialsProps> = ({
  handleSocialsChange,
  socialLinks,
}) => {
  return (
    <>
      <Text style={styles.title}>Add/Connect your social links</Text>
      <Text style={styles.subTitle}>
        To verify your identity to your fans we recommend connecting at least
        one existing social account!
      </Text>
      <ScrollView
        contentContainerStyle={styles.scrollViewContent}
        style={styles.scrollView}
        showsVerticalScrollIndicator={false}>
        <View style={styles.inputContainer}>
          {socialPlatforms.map(platform => (
            <View key={platform.id}>
              <View style={styles.labelContainer}>
                <Image
                  resizeMode="contain"
                  source={platform?.icon}
                  style={styles.image}
                />
                <Text style={styles.label}>{platform.label}</Text>
              </View>

              <TextInputComponent
                value={socialLinks[platform.key] || ''}
                setOnChange={value => handleSocialsChange(platform.key, value)}
                placeholder={platform.placeholder}
              />
            </View>
          ))}
        </View>

        <View style={styles.buttonSpace} />
      </ScrollView>
    </>
  );
};

export default ProfileCreationSocials;

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    paddingBottom: 100,
  },

  scrollView: {
    flex: 1,
  },
  title: {
    marginBottom: 6,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
  subTitle: {
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    marginBottom: 10,
  },
  inputContainer: {
    marginTop: 26,
  },
  buttonSpace: {
    marginTop: 72,
  },
  labelContainer: {
    marginTop: 20,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginLeft: 6,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  image: { width: 18, height: 18 },
});

const socialPlatforms = [
  {
    id: 1,
    icon: instagramIcon,
    label: 'Instagram',
    key: 'instagram',
    placeholder: 'Add your Instagram link',
  },
  {
    id: 2,
    icon: twitterIcon,
    label: 'X (Formally Twitter)',
    key: 'twitter',
    placeholder: 'Add your X link',
  },
  {
    id: 3,
    icon: tikTokIcon,
    label: 'TikTok',
    key: 'tiktok',
    placeholder: 'Add your TikTok link',
  },
  {
    id: 4,
    icon: facebookIcon,
    label: 'Facebook',
    key: 'facebook',
    placeholder: 'Add your Facebook link',
  },
];
