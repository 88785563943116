import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { defaultTheme } from '../../themes/loot8';
import { styles } from './styles';
import { TouchableRipple } from 'react-native-paper';
import { FilterSvg } from '../../assets/svg/HomeSvgs';

const GradientFilterButton = ({ onPress, size }) => {
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 1 }}
      style={[styles.container, { width: size, height: size }]}>
      <TouchableRipple
        style={styles.contentContainer}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}>
        <>
          <FilterSvg />
        </>
      </TouchableRipple>
    </LinearGradient>
  );
};

export default GradientFilterButton;
