import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  DirectionType,
  IMessageList,
  IMessageNotificationEvent,
  MessageNotificationType,
  MessageType,
} from '../interfaces/IMessages';
import {
  deletePrivateMessage,
  cancelPrivateMessageFriendRequest,
  getCachedDataByMessageId,
  getPrivateMessages,
  getPrivateMsgFriendRequest,
  sendPrivateMessage,
  sendPrivateMsgFriendRequest,
  getPrivateMsgPendingRequest,
  privateMessageDir,
} from '../helpers/Messages';
import { LogCustomError } from '../helpers/AppLogger';
import { RootState } from '../store';
import {
  IPrivateMessageAsyncThunk,
  IPrivateMessageBaseAsyncThunk,
  IPrivateMessageByIdAsyncThunk,
  IPrivateMessageMutualFriendsAsyncThunk,
} from './interfaces';
import {
  IsUserExists,
  getUserAvatar,
  getUserPublicKey,
  publicKeys,
  pushUserPublicKey,
} from './AppUserSlice';
import {
  FRIEND_PRIVATE_MESSAGES_LAST_READ,
  FRIEND_PRIVATE_MESSAGES_LAST_REQSENT,
  LOOT8_FEED,
  MUTUALFRIENDS_MESSAGES_LAST_READ,
  NETWORK_CALL_BATCHSIZE,
  PRIVATE_MESSAGE_FRIEND_REQUESTS,
  ZERO_ADDRESS,
  getAppConfiguration,
} from '../appconstants';
import { IFriends, getUsers, setLatestChatFriend } from './friendsSlice';
import { getData, storeData } from '../helpers/AppStorage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { resetPassportMessage } from './PassportMessageSlice';
import {
  getIPFSData,
  getIPFSLink,
  subscribeToIPFSPubSub,
} from '../helpers/ipfs';
import { ethers } from 'ethers';
import { IUser } from '../interfaces/IUser.interface';
import { Platform } from 'react-native';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { detectURL } from '../helpers/Gadgets';
import { NotificationType, pushNotification } from './NotificationSlice';
import { getSyncedTime } from '../helpers/DateHelper';
import { getThirdPartyCollectiableDetails } from './OfferSlice';
import { getAnynetStaticProvider } from '../appconstants';
import { MessagesType } from '../enums/messages.enum';
import { getBatchedUserJsonData } from './helpers';

export interface IGroupMessages {
  user: IUser;
  messages: IMessageList[];
  senderTimestamp: number;
}

export interface IPrivateMessageSliceData {
  readonly messagesLoading: boolean;
  readonly messages: IMessageList[];
  //readonly lastReadTimestamp: number;
  readonly lastFetchTimestamp: number;
  readonly mutualFriendMessagesDetails: IMutualFriendsMessage[];
  readonly draftMessages: any[];
  readonly latestMessageLoading: boolean;
  readonly groupByMessages: IGroupMessages[];
  readonly friendAddress?: string;
  readonly initialMessageLoading: boolean;
  readonly olderMessageLoading: boolean;
  readonly msgRequest: IMessageList;
  readonly pendingRequest: IMessageList[];
  readonly requestLoading: boolean;
  readonly latestMsgListLoaded: boolean;
}

export interface IMutualFriendsMessage {
  friendAddress: string;
  latestMessage: any;
  newMessageCount: number;
  friendPublicKey: string;
  draftMessage: any;
}

export interface IFriendMessageRequest {
  sender: string;
  receiver: string;
  requestMsgId: string;
}

const initialState: IPrivateMessageSliceData = {
  messagesLoading: false,
  messages: [],
  //lastReadTimestamp: null,
  lastFetchTimestamp: null,
  mutualFriendMessagesDetails: [],
  draftMessages: [],
  latestMessageLoading: false,
  groupByMessages: [],
  friendAddress: null,
  initialMessageLoading: false,
  olderMessageLoading: false,
  msgRequest: null,
  pendingRequest: null,
  requestLoading: false,
  latestMsgListLoaded: false,
};

let subscription;

export const checkUserExists = async ({
  networkID,
  provider,
  allUsersData,
  userAddress,
}) => {
  let userData = allUsersData?.find(
    x => x.wallet?.toLowerCase() === userAddress?.toLowerCase(),
  );

  if (!userData || userData?.wallet === ZERO_ADDRESS) {
    let { userAttributes } = await IsUserExists({
      networkID,
      provider,
      address: userAddress,
    });
    userData = userAttributes;
  }
  return userData;
};

const getUserDetail = async ({
  networkID,
  provider,
  allUsersData,
  userAddress,
}) => {
  let userDetails = {};
  let userData = await checkUserExists({
    networkID,
    provider,
    allUsersData,
    userAddress,
  });
  if (userData) {
    let userStoredAvatarUri = await getUserAvatar(userData.avatarURI ?? '');
    userDetails = {
      name: userData.name,
      wallet: userData.wallet,
      id: +userData.id,
      avatarURI: userStoredAvatarUri,
      dataURI: userData?.dataURI ?? '',
      status: userData?.status,
    };
  }

  return userDetails;
};

const processReadResponse = async (
  dispatch,
  getState,
  friendAddress,
  rootState,
  response,
  networkID,
  provider,
  readTime,
  decryptMessage,
  friendsPublicKey,
  loadData,
) => {
  if (
    (getState() as RootState).PrivateMessage.friendAddress &&
    response?.messages &&
    response.messages.length > 0
  ) {
    let messagesTobePushed = [];
    let currentUserData = rootState.AppUser.UserData;
    let passportDataList = rootState.Passports.AllPassportDetailsList;
    let DigitalCollectibleList =
      rootState.DigitalCollectible.AllDigitalCollectibleList;
    let friendUserData = await getUserDetail({
      networkID: networkID,
      provider: provider,
      allUsersData: rootState.friends.mutualFriends,
      userAddress: friendAddress,
    });
    try {
      for (let i = 0; i < response.messages.length; i++) {
        let message = response.messages[i];

        if (!loadData && !message.data) {
          message.data = await getCachedDataByMessageId(
            privateMessageDir,
            message.messageId,
          );
        }
        if (message.messageId && message.data) {
          // if (!message.data.parent) {
          //get other relevant details to show on UI
          let userData;
          if (
            message.data.sender?.toLowerCase() == friendAddress?.toLowerCase()
          ) {
            userData = friendUserData;
          } else {
            userData = currentUserData;
          }

          message = {
            ...message,
            isRead: readTime
              ? message.timestamp < readTime
                ? true
                : false
              : false,
            user: userData,
            isSenderFriend: true, //senderFriend && senderFriend.length > 0 ? senderFriend[0].isFriend : false
          };
          if (
            message.data?.data.content?.text &&
            message.data?.data.content?.text !== ''
          ) {
            message.data.data.content.decryptedText = decryptMessage(
              message.data?.data.content?.text,
              friendsPublicKey,
            );
          }
          if (
            message?.data?.data?.attachments &&
            message?.data?.data?.attachments[0]
          ) {
            let encryptedIPFSuri = message.data.data.attachments[0]?.uri;

            if (encryptedIPFSuri && encryptedIPFSuri.includes('ipfs://')) {
              message.data.data.attachments = {
                ...message?.data?.data?.attachments[0],
                uri: encryptedIPFSuri,
                friendsPublicKey,
              };
            }
          }
          if (message?.data?.parent) {
            //Getting parentMessageData
            let response = await getIPFSData(
              `ipfs://${message.data.parent}`,
              5 * 1000,
            );
            let parentData = response && (await response.json());
            if (parentData) {
              // parentName added in parent data
              let parentUser: any = await getUserDetail({
                networkID: networkID,
                provider: provider,
                allUsersData: rootState.friends.mutualFriends,
                userAddress: parentData.data.sender,
              });
              parentData.data.name = parentUser?.name; //parent name added
              parentData.data.id = parentUser?.id; //parent id added
              parentData.data.avatarURI = parentUser?.avatarURI; //parent avatarUri added

              //For reshare added passport name
              if (message.data.data._type === 'reshare') {
                let passportAddress = parentData?.data?.feed
                  .split(':')[0]
                  .trim();
                let chainId = parentData?.data?.feed.split(':')[1].trim();

                //For ThirdParty Collectible data
                let address = friendAddress;

                let passportData = passportDataList.find(
                  obj => obj?.address === passportAddress,
                );

                //For Digital Collectible data
                let DigitalCollectibleData = DigitalCollectibleList.find(
                  obj => obj?.address === passportAddress,
                );
                if (passportData) {
                  parentData.data.passportName = passportData?.name;
                  parentData.data.type = MessagesType.PASSPORT;
                } else {
                  let getThirdPartyCollectible =
                    await getThirdPartyCollectiableDetails({
                      chainId: chainId,
                      provider: getAnynetStaticProvider(chainId),
                      collectiableAddress: passportAddress,
                      address,
                    });
                  if (getThirdPartyCollectible) {
                    parentData.data.passportName =
                      getThirdPartyCollectible[0].name;
                    parentData.data.type = MessagesType.COLLECTIBLES;
                  } else {
                    parentData.data.passportName = DigitalCollectibleData?.name;
                    parentData.data.type = MessagesType.COLLECTIBLES;
                  }
                }
                if (
                  parentData?.data?.data?.content?.text &&
                  parentData?.data?.data?.content?.text !== ''
                ) {
                  parentData.data.data.content.messageURLs = detectURL(
                    parentData?.data?.data?.content.text,
                  );
                }
              }

              if (
                parentData?.data?.data?.content?.text &&
                parentData?.data?.data?.content?.text !== '' &&
                message?.data?.data?._type === 'quote'
              ) {
                parentData.data.data.content.decryptedText = decryptMessage(
                  parentData?.data?.data?.content?.text,
                  friendsPublicKey,
                );
              }
              if (
                parentData?.data?.data?.attachments &&
                parentData?.data?.data?.attachments[0] &&
                message.data?.data?._type === 'reshare'
              ) {
                let parentAttachmentIpfsURI =
                  parentData.data.data.attachments[0]?.uri;

                if (
                  parentAttachmentIpfsURI &&
                  parentAttachmentIpfsURI.includes('ipfs://')
                ) {
                  parentData.data.data.attachments = {
                    ...parentData.data.data.attachments[0],
                    uri: parentAttachmentIpfsURI,
                  };
                }
              }
            }
            message = { ...message, parentData: parentData };
          }
          try {
            message.data.data.content.messageURLs = detectURL(
              message.data.data.content.decryptedText,
            );
          } catch (e) {
            message.data.data.content.messageURLs = null;
          }
          messagesTobePushed.push(message);
        }
      }
    } catch (e) {
      LogToLoot8Console('processReadResponse', e);
    }
    if (
      (getState() as RootState).PrivateMessage.friendAddress &&
      (getState() as RootState).PrivateMessage.friendAddress?.toLowerCase() ==
        friendAddress?.toLowerCase()
    ) {
      await dispatch(pushPrivateMessage(messagesTobePushed));
    }
    return null;
  }
};

export const sendPrivateMessageToFriend = createAsyncThunk(
  'privateMessage/sendPrivateMessageToFriend',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      publicKey,
      text,
      decryptMessage,
      parentId,
      messageType,
      attachments,
    }: IPrivateMessageAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    // set timestamp before message posting
    let timestamp = getSyncedTime();
    const state = getState() as RootState;
    const senderName = state.AppUser.UserData.name;
    const response = await sendPrivateMessage(
      networkID,
      address,
      text,
      wallet,
      senderName,
      messageType,
      parentId,
      attachments,
    );
    let responseError = {};

    if (response.status == 200) {
      // fatch direct messages based on timestamp.
      dispatch(setLatestChatFriend(address));
      if (messageType !== MessageType.reshare)
        dispatch(
          getPrivateMessagesFromFriend({
            networkID: networkID,
            provider: provider,
            address: address,
            wallet: wallet,
            isCache: true,
            isClear: false,
            timestamp: timestamp,
            decryptMessage,
            publicKey,
            directionType: DirectionType.later,
          }),
        );
    } else {
      LogCustomError(
        'sendPrivateMessageToFriend',
        response?.status?.toString(),
        response?.statusText,
        null,
      );
      if (response.status === 500) {
        const res = await response.json();
        if (res && res.error) {
          responseError = res.error;
        }
      }
    }
    return {
      status: response.status,
      message: response.statusText,
      error: responseError,
    };
  },
);

export const deletePrivateUserMessage = createAsyncThunk(
  'privateMessage/deletePrivateUserMessage',
  async (
    { networkID, address, wallet, messageById }: IPrivateMessageByIdAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    // set timestamp before message posting
    let timestamp = getSyncedTime();

    const response = await deletePrivateMessage(
      networkID,
      address,
      wallet,
      messageById,
    );
    let responseError = {};

    if (response.status == 200) {
      // fatch direct messages based on timestamp.
      dispatch(popPrivateMessage(messageById));
    } else {
      LogCustomError(
        'deletePrivateMessageToFriend',
        response?.status?.toString(),
        response?.statusText,
        null,
      );
      if (response.status === 500) {
        const res = await response.json();
        if (res && res.error) {
          responseError = res.error;
        }
      }
    }
    return {
      status: response.status,
      message: response.statusText,
      error: responseError,
    };
  },
);

export const getPrivateMessagesFromFriend = createAsyncThunk(
  'directMessage/getPrivateMessagesFromFriend',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      isCache = true,
      isClear = true,
      timestamp,
      decryptMessage,
      publicKey,
      directionType,
      isOlderMessages = false,
    }: IPrivateMessageBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    await dispatch(setFriendAddress(address));

    // avoid to clear the message list if user is on private message window.
    if (isClear) {
      await dispatch(clearPrivateMessage());
      await getData(FRIEND_PRIVATE_MESSAGES_LAST_READ(wallet.address, address));
      await dispatch(setinitialMessageLoading(true));
    }
    // set earlier message flag when fetching older messages.
    if (isOlderMessages) {
      await dispatch(setOlderMessageLoading(true));
    }

    let direction = directionType ?? DirectionType.later;
    let loadData = true;
    const appConfig = await getAppConfiguration();
    if (
      (Platform.OS == 'ios' && appConfig.ios.cachePrivateMessages) ||
      (Platform.OS == 'android' && appConfig.android.cachePrivateMessages)
    ) {
      loadData = false;
    }
    let response = await getPrivateMessages(
      networkID,
      address,
      wallet,
      timestamp ?? null,
      direction,
      null,
      11,
      loadData,
    );
    if (response && response?.messages && response?.messages.length > 0) {
      let rootState = getState() as RootState;
      await processReadResponse(
        dispatch,
        getState,
        address,
        rootState,
        response,
        networkID,
        provider,
        null,
        decryptMessage,
        publicKey,
        loadData,
      );
      await dispatch(setinitialMessageLoading(false));
      rootState = getState() as RootState;
      let timeStamp = null;
      direction = DirectionType.earlier;
      if (direction === DirectionType.earlier) {
        timeStamp = rootState.PrivateMessage.messages
          .filter(p => true)
          .sort((a, b) => a.timestamp - b.timestamp)[0].timestamp;
      } else {
        timeStamp = rootState.PrivateMessage.messages
          .filter(p => true)
          .sort((a, b) => b.timestamp - a.timestamp)[0].timestamp;
      }

      if (isClear) {
        await storeData(
          FRIEND_PRIVATE_MESSAGES_LAST_READ(wallet.address, address),
          timeStamp,
        );
      } else if (isOlderMessages) {
        await dispatch(setOlderMessageLoading(false));
        await storeData(
          FRIEND_PRIVATE_MESSAGES_LAST_READ(wallet.address, address),
          timeStamp,
        );
      }

      // while (response.messages.length > 0) {
      //   rootState = getState() as RootState;

      //   let timeStamp = null;
      //   direction = DirectionType.earlier;
      //   if (direction === DirectionType.earlier) {
      //     timeStamp = rootState.PrivateMessage.messages
      //     .filter(p => true)
      //     .sort((a, b) => a.timestamp - b.timestamp)[0].timestamp;
      //   } else {
      //     timeStamp = rootState.PrivateMessage.messages
      //     .filter(p => true)
      //     .sort((a, b) => b.timestamp - a.timestamp)[0].timestamp;
      //   }
      //   response = await getPrivateMessages(networkID, address, wallet,  timeStamp, direction);
      //   if (response && response?.messages && response?.messages.length > 0) {
      //     await processReadResponse(dispatch, getState, address, rootState, response, networkID, provider, null, decryptMessage, publicKey);
      //   }
      // }
    } else {
      await dispatch(setinitialMessageLoading(false));
      if (isOlderMessages) {
        await dispatch(setOlderMessageLoading(false));
        await storeData(
          FRIEND_PRIVATE_MESSAGES_LAST_READ(wallet.address, address),
          null,
        );
      }
    }
  },
);

export const loadLastMessageDetailsForMutualFriends = createAsyncThunk(
  'directMessage/loadLastMessageDetailsForMutualFriends',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      friendsAddresses,
    }: IPrivateMessageMutualFriendsAsyncThunk,
    { dispatch, getState },
  ): Promise<{ mutualFriends: IFriends[] }> => {
    const rootState = getState() as RootState;
    const draftMessages = rootState.PrivateMessage.draftMessages;
    let mutualFriends = rootState.friends.mutualFriends;

    let mutualFriendsMessageList: IMutualFriendsMessage[] = [];
    let mutualFriendUnreadMessageList: IMutualFriendsMessage[] = [];

    let toGetPublicKeys = [];
    friendsAddresses.map(item => {
      const localPubKey = publicKeys.find(
        p => p.wallet.toLowerCase() == item?.friendAddress.toLowerCase(),
      );
      if (!localPubKey) {
        toGetPublicKeys.push(item?.friendAddress);
      }
    });
    if (toGetPublicKeys.length > 0) {
      const allPrivateKeys = await getBatchedUserJsonData(
        toGetPublicKeys,
        NETWORK_CALL_BATCHSIZE * 5, // setting batch size 50 here to make it load faster and make sure it doesn't crash
        'details',
      );

      for (const addr in allPrivateKeys) {
        if (allPrivateKeys[addr]) {
          pushUserPublicKey(addr, allPrivateKeys[addr]?.publicKey);
        }
      }
    }

    await Promise.all(
      friendsAddresses.map(async data => {
        //TODO: move this code to probably friends slice to load public keys of mutual friends.
        //get public key for decrypting message on UI
        let friendPublicKey = '';
        try {
          const pubKey = publicKeys.find(
            p => p.wallet.toLowerCase() == data?.friendAddress.toLowerCase(),
          )?.publicKey;
          friendPublicKey = pubKey || '';
        } catch (e) {
          //TODO: apply sentry logs
          LogToLoot8Console('coudnt load public key: ' + data.friendAddress);
        }

        const lastReadtime = await getData(
          MUTUALFRIENDS_MESSAGES_LAST_READ(wallet.address, data.friendAddress),
        );
        let direction = DirectionType.later;
        let loadData = true;
        const appConfig = await getAppConfiguration();
        if (
          (Platform.OS == 'ios' && appConfig.ios.cachePrivateMessages) ||
          (Platform.OS == 'android' && appConfig.android.cachePrivateMessages)
        ) {
          loadData = false;
        }
        const response = await getPrivateMessages(
          networkID,
          data.friendAddress,
          wallet,
          null,
          direction,
          null,
          11,
          loadData,
        );
        if (response && response?.messages && response?.messages.length > 0) {
          if (!loadData) {
            await Promise.all(
              response?.messages.map(async message => {
                message.data = await getCachedDataByMessageId(
                  privateMessageDir,
                  message.messageId,
                );
                return message;
              }),
            );
          }
          const sortedMessages = response?.messages.sort(
            (a, b) => b.timestamp - a.timestamp,
          );
          const latestMessage = sortedMessages[0];
          let newMessageCount = 0;
          const friendsMessages = response?.messages.filter(
            msg => msg.data?.sender !== wallet.address,
          );
          if (
            friendsMessages &&
            friendsMessages.length > 0 &&
            latestMessage.data?.sender !== wallet.address
          ) {
            if (!lastReadtime) {
              newMessageCount = friendsMessages.length;
            } else {
              newMessageCount = friendsMessages.filter(
                m => m.timestamp > lastReadtime,
              ).length;
            }
          }

          //Check if draft message available
          let friendDraftMsg;
          if (draftMessages && draftMessages.length > 0) {
            friendDraftMsg = draftMessages.find(
              dm => dm.friendAddress === data.friendAddress,
            );
          }

          let friendMsg = {
            friendAddress: data.friendAddress,
            latestMessage: latestMessage,
            newMessageCount: newMessageCount,
            friendPublicKey: friendPublicKey,
            draftMessage: friendDraftMsg,
            data, 
          };

          mutualFriendsMessageList = [...mutualFriendsMessageList, friendMsg];

          // in case there is a new unread message in the list.
          if (newMessageCount) {
            mutualFriendUnreadMessageList = [
              ...mutualFriendUnreadMessageList,
              friendMsg,
            ];
          }
          dispatch(pushLastMessageMutualFriend(friendMsg));
        } else {
          //Check for draft messages even if no other message is exchanged yet
          let friendDraftMsg;
          if (draftMessages && draftMessages.length > 0) {
            friendDraftMsg = draftMessages.find(
              dm => dm.friendAddress === data.friendAddress,
            );
            if (friendDraftMsg) {
              let mutualFriendData: any = mutualFriends.find(
                mf =>
                  mf.wallet?.toLowerCase() ===
                  friendDraftMsg.friendAddress?.toLowerCase(),
              );
              if (mutualFriendData) {
                let friendMsg = {
                  friendAddress: data.friendAddress,
                  latestMessage: {},
                  newMessageCount: 0,
                  friendPublicKey: friendPublicKey,
                  draftMessage: friendDraftMsg,
                };
                mutualFriendsMessageList = [
                  ...mutualFriendsMessageList,
                  friendMsg,
                ];
                dispatch(pushLastMessageMutualFriend(friendMsg));
              }
            }
          }
        }
      }),
    );

    //sort by drafted message & timestamp
    mutualFriendsMessageList = mutualFriendsMessageList.sort((a, b) => {
      let timeStampA = a.draftMessage
        ? a.draftMessage.timestamp
        : a.latestMessage.timestamp;
      let timeStampB = b.draftMessage
        ? b.draftMessage.timestamp
        : b.latestMessage.timestamp;
      return timeStampB - timeStampA;
    });

    // push notification
    if (mutualFriendUnreadMessageList?.length > 0) {
      mutualFriendUnreadMessageList?.map(async (item, index) => {
        // get friend data - need to take latest root state as AvatarURI is updated in state after a while [fix for loot8-1804]
        const friendState = getState() as RootState;
        mutualFriends = friendState.friends.mutualFriends;
        let mutualFriend: any = mutualFriends.find(
          mf => mf.wallet?.toLowerCase() === item.friendAddress?.toLowerCase(),
        );
        if (mutualFriend) {
          const userProfileImage = await getUserAvatar(
            mutualFriend.avatarURI ?? '',
          );
          // push notification
          dispatch(
            pushNotification({
              subject: `Hey! You have ${item?.newMessageCount} unread message${
                item?.newMessageCount > 1 ? 's' : ''
              }`,
              body: `Please check out Messages! You have new message(s) from ${mutualFriend?.name}`,
              uri: userProfileImage ? userProfileImage : null,
              timeStamp: item?.latestMessage?.timestamp.toString(),
              // blockNumber: await provider.getBlockNumber(),
              id: item?.friendAddress,
              notificationType: NotificationType.NewPrivateMessage,
              dataObject: mutualFriend,
            }),
          );
        }
      });
    }
    return {
      mutualFriends,
    };
  },
);

export const addClearMessageInDraft = createAsyncThunk(
  'privateMessage/pushMessageInDraft',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      publicKey,
      text,
      parent,
    }: IPrivateMessageAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    if (text || parent) {
      dispatch(
        pushMessageInDraft({
          friendAddress: address,
          message: text,
          parent: parent,
        }),
      );
    } else {
      dispatch(clearMessageInDraft(address));
    }
  },
);

export const getPrivateMessageById = createAsyncThunk(
  'directMessage/getPrivateMessageById',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      messageById,
      decryptMessage,
      publicKey,
    }: IPrivateMessageByIdAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const response = await getPrivateMessages(
      networkID,
      address,
      wallet,
      null,
      DirectionType.later,
      messageById,
    );
    if (response && response?.messages && response?.messages.length > 0) {
      const rootState = getState() as RootState;
      await processReadResponse(
        dispatch,
        getState,
        address,
        rootState,
        response,
        networkID,
        provider,
        null,
        decryptMessage,
        publicKey,
        true,
      );
    }
  },
);

export const subscribeToPrivateMessages = createAsyncThunk(
  'privateMessage/subscribeToPrivateMessages',
  async (
    {
      networkID,
      provider,
      address /* friend address */,
      wallet,
      decryptMessage,
      publicKey,
    }: IPrivateMessageBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const rootState = getState() as RootState;

    const feed =
      address < wallet.address
        ? `${address}-${wallet.address}`
        : `${wallet.address}-${address}`;
    const topic: string = Buffer.from(
      LOOT8_FEED +
        ethers.utils
          .keccak256(Buffer.from(feed.toLowerCase(), 'utf-8'))
          .slice(2),
      'utf-8',
    ).toString();
    subscription = subscribeToIPFSPubSub(
      topic,
      async (response: IMessageNotificationEvent) => {
        if (
          response?.data?.sender?.toLowerCase() !== wallet.address.toLowerCase()
        ) {
          if (
            response.event == MessageNotificationType.msgPosted &&
            response.data.feedType == MessageType.private
          ) {
            switch (response.data._type) {
              case MessageType.text:
                dispatch(
                  getPrivateMessageById({
                    networkID,
                    address,
                    provider,
                    wallet,
                    messageById:
                      response.data.parent || response.data.messageId,
                    decryptMessage,
                    publicKey,
                  }),
                );
                break;
              default:
                break;
            }
          }
        }
      },
      true,
    );
  },
);

export function setAll(state: any, properties: any, avoidKeys: any[] = []) {
  if (properties) {
    const props = Object.keys(properties);
    props.forEach(key => {
      if (!avoidKeys.includes(key)) {
        state[key] = properties[key];
      }
    });
  }
}

// group by message under same profile.
export const groupByMessages = (messageList: IMessageList[]) => {
  // declare varible.
  let groupMessages: IGroupMessages[] = [];

  // sort message by timestamp.
  messageList = messageList
    ?.filter(m => 1 === 1)
    .sort((a, b) => a.timestamp - b.timestamp);

  // iterate each item in message list.
  messageList?.forEach((currentItem, index) => {
    if (index === 0) {
      // by default, add first item in the array as it is.
      let msg: IGroupMessages = {
        user: currentItem?.user,
        messages: [currentItem],
        senderTimestamp: currentItem?.data?.senderTimestamp,
      };
      groupMessages.push(msg);
    } else {
      // pick last item as previous item from temp array.
      let previousItem =
        groupMessages.length > 0
          ? groupMessages[groupMessages.length - 1]
          : null;

      if (previousItem) {
        // compare previous wallet with current wallet,
        // if both match, add current item under the previous wallet.
        if (
          previousItem?.user.wallet.toLocaleLowerCase() ===
          currentItem?.user.wallet.toLocaleLowerCase()
        ) {
          previousItem.messages.push(currentItem);
          previousItem.senderTimestamp = currentItem.data?.senderTimestamp;
          groupMessages[groupMessages.length - 1] = previousItem;
        } else {
          // if both not match, add current item as separate message.
          let msg: IGroupMessages = {
            user: currentItem?.user,
            messages: [currentItem],
            senderTimestamp: currentItem?.data.senderTimestamp,
          };
          groupMessages.push(msg);
        }
      } else {
        // in case previous item null, add message as separate message.
        let msg: IGroupMessages = {
          user: currentItem?.user,
          messages: [currentItem],
          senderTimestamp: currentItem?.data.senderTimestamp,
        };
        groupMessages.push(msg);
      }
    }
  });

  // return final array.
  return groupMessages;
};

export const sendPrivateMsgRequestToFriend = createAsyncThunk(
  'privateMessage/sendPrivateMsgRequestToFriend',
  async (
    {
      networkID,
      address,
      wallet,
      publicKey,
      text,
      decryptMessage,
    }: { networkID; address; wallet; publicKey; text; decryptMessage },
    { dispatch },
  ): Promise<any> => {
    let timestamp = getSyncedTime();

    const response = await sendPrivateMsgFriendRequest(
      networkID,
      address,
      text,
      wallet,
    );
    let responseError = {};
    let latestMsgRequest = null;
    if (response.status == 200) {
      storeData(
        FRIEND_PRIVATE_MESSAGES_LAST_REQSENT(wallet.address, address),
        timestamp,
      );
      const lastRequest = await dispatch(
        getPrivateMsgRequestForFriend({
          networkID: networkID,
          address: address,
          wallet: wallet,
          timestamp: timestamp,
          decryptMessage,
          publicKey,
          directionType: DirectionType.later,
        }),
      );
      if (
        lastRequest &&
        lastRequest.payload &&
        lastRequest.payload.latestRequest &&
        lastRequest.payload.latestRequest.messageId
      ) {
        await getData(PRIVATE_MESSAGE_FRIEND_REQUESTS).then(
          (friendRequestList: IFriendMessageRequest[] = []) => {
            const friendRequest: IFriendMessageRequest = {
              sender: wallet.address,
              receiver: address,
              requestMsgId: lastRequest.payload.latestRequest.messageId,
            };
            friendRequestList.push(friendRequest);
            storeData(PRIVATE_MESSAGE_FRIEND_REQUESTS, friendRequestList);
            latestMsgRequest = lastRequest.payload.latestRequest;
          },
        );
      }
    } else {
      LogCustomError(
        'sendPrivateMsgRequestToFriend',
        response?.status?.toString(),
        response?.statusText,
        null,
      );
      if (response.status === 500) {
        const res = await response.json();
        if (res && res.error) {
          responseError = res.error;
        }
      }
    }
    return {
      status: response.status,
      message: response.statusText,
      error: responseError,
      latestMsgRequest: latestMsgRequest,
    };
  },
);

export const getPrivateMsgRequestForFriend = createAsyncThunk(
  'directMessage/getPrivateMsgRequestForFriend',
  async (
    {
      networkID,
      address,
      wallet,
      timestamp,
      decryptMessage,
      publicKey,
      directionType,
    }: {
      networkID;
      address;
      wallet;
      timestamp;
      decryptMessage;
      publicKey;
      directionType;
    },
    { dispatch, getState },
  ): Promise<any> => {
    await dispatch(setFriendAddress(address));

    //get last friend request sent timestamp
    if (!timestamp) {
      timestamp = await getData(
        FRIEND_PRIVATE_MESSAGES_LAST_REQSENT(wallet.address, address),
      );
    }
    let direction = directionType ?? DirectionType.later;

    let response = await getPrivateMsgFriendRequest(
      networkID,
      address,
      wallet,
      timestamp ?? null,
      direction,
      null,
      25,
      true,
    );
    if (response && response?.messages && response?.messages.length > 0) {
      let rootState = getState() as RootState;
      let msgRequests = [];
      let latestMsgRequest = null;
      //await processReadResponse(dispatch, getState, address, rootState, response, networkID, provider, null, decryptMessage, publicKey);
      for (let i = 0; i < response.messages.length; i++) {
        let msgRequest = response.messages[i];
        if (msgRequest.messageId && msgRequest.data) {
          if (!msgRequest.data.parent) {
            if (msgRequest.data?.data.content?.text) {
              msgRequest.data.data.content.decryptedText = decryptMessage(
                msgRequest.data?.data.content?.text,
                publicKey,
              );
            }
            msgRequests.push(msgRequest);
          }
        }
      }
      if (msgRequests.length > 1) {
        latestMsgRequest = msgRequests.sort(
          (a, b) => b.timestamp - a.timestamp,
        )[0];
      } else {
        latestMsgRequest = msgRequests[0];
      }
      // if ((getState() as RootState).PrivateMessage.friendAddress && (getState() as RootState).PrivateMessage.friendAddress == address) {
      //   await dispatch(setLatestMsgRequest(latestMsgRequest));
      // }
      return { latestRequest: latestMsgRequest };
    }
  },
);

export const processPendingRequest = async ({
  networkID,
  provider,
  response,
  rootState,
  dispatch,
  decryptMessage,
}) => {
  let pendingRequest = [];
  for (let i = 0; i < response.messages.length; i++) {
    let msgRequest = response.messages[i];
    if (msgRequest.messageId && msgRequest.data) {
      // get all users
      let allUsersData = rootState.AppUser.AllUsersData;
      const isFriendExist = allUsersData.findIndex(
        (obj: any) =>
          obj.wallet.toLowerCase() === msgRequest.data?.sender.toLowerCase(),
      );
      if (isFriendExist < 0) {
        allUsersData = await getUsers({ networkID, provider, dispatch });
      }
      const friend: any = allUsersData.find(
        (obj: any) =>
          obj.wallet.toLowerCase() === msgRequest.data?.sender.toLowerCase(),
      );

      let friendPublicKey = '';
      if (friend) {
        try {
          const pubKey = await getUserPublicKey(friend?.wallet);
          friendPublicKey = pubKey || '';
        } catch (e) {
          //TODO: apply sentry logs
          LogToLoot8Console('coudnt load public key: ' + friend.wallet);
        }
      }

      let friendUserData = await getUserDetail({
        networkID: networkID,
        provider: provider,
        allUsersData: rootState.friends.mutualFriends,
        userAddress: msgRequest.data?.sender,
      });

      msgRequest = {
        ...msgRequest,
        user: friendUserData,
        isSenderFriend: false,
      };

      if (msgRequest?.data?.data?.content?.text && decryptMessage) {
        msgRequest.data.data.content.decryptedText = decryptMessage(
          msgRequest.data?.data.content?.text,
          friendPublicKey,
        );
      }
      pendingRequest.push(msgRequest);
      dispatch(pushPendingRequest(msgRequest));
      dispatch(setRequestLoading(false));
    }
  }

  pendingRequest.sort((a, b) => b.timeStamp - a.timeStamp);
  return pendingRequest;
};

export const getPrivateMsgPendingRequestForUser = createAsyncThunk(
  'directMessage/getPrivateMsgPendingRequestForUser',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      timestamp,
      decryptMessage,
      directionType,
    }: any,
    { dispatch, getState },
  ): Promise<any> => {
    let rootState = getState() as RootState;
    if (!rootState?.PrivateMessage?.pendingRequest) {
      await dispatch(setRequestLoading(true));
    }
    let direction = directionType ?? DirectionType.later;
    let response = await getPrivateMsgPendingRequest(
      wallet,
      timestamp ?? null,
      direction,
      25,
      true,
    );
    let pendingRequest = null;
    if (response && response?.messages && response?.messages.length > 0) {
      pendingRequest = await processPendingRequest({
        networkID,
        provider,
        response,
        rootState,
        dispatch,
        decryptMessage,
      });

      while (
        response &&
        response?.messages &&
        response?.messages.length === 25
      ) {
        direction = DirectionType.earlier;
        timestamp = pendingRequest[pendingRequest.length - 1]?.timeStamp;
        response = await getPrivateMsgPendingRequest(
          wallet,
          timestamp ?? null,
          direction,
          25,
          true,
        );

        if (response && response?.messages && response?.messages.length > 0) {
          let extraPendingRequest = await processPendingRequest({
            networkID,
            provider,
            response,
            rootState,
            dispatch,
            decryptMessage,
          });
          pendingRequest.concat(extraPendingRequest);
        } else {
          break;
        }
      }
    }

    return {
      pendingRequest: pendingRequest,
    };
  },
);

export const deletePrivateMsgRequestToFriend = createAsyncThunk(
  'privateMessage/deletePrivateMsgRequestToFriend',
  async (
    {
      networkID,
      address,
      wallet,
      messageId,
      requestAccepted,
      publicKey,
      decryptMessage,
    }: {
      networkID;
      address;
      wallet;
      messageId;
      requestAccepted;
      publicKey;
      decryptMessage;
    },
    { dispatch },
  ): Promise<any> => {
    let timestamp = getSyncedTime();

    const response = await cancelPrivateMessageFriendRequest(
      networkID,
      address,
      wallet,
      messageId,
      requestAccepted,
    );
    let responseError = {};
    if (response.status == 200) {
      dispatch(popPendingRequest(messageId));
      await dispatch(
        getPrivateMsgRequestForFriend({
          networkID: networkID,
          address: address,
          wallet: wallet,
          timestamp: timestamp,
          decryptMessage,
          publicKey,
          directionType: DirectionType.later,
        }),
      );
    } else {
      LogCustomError(
        'cancelPrivateMsgRequestToFriend',
        response?.status?.toString(),
        response?.statusText,
        null,
      );
      if (response.status === 500) {
        const res = await response.json();
        if (res && res.error) {
          responseError = res.error;
        }
      }
    }
    return {
      status: response.status,
      message: response.statusText,
      error: responseError,
    };
  },
);

export const getPrivateMsgRequestById = createAsyncThunk(
  'directMessage/getPrivateMsgRequestById',
  async (
    {
      networkID,
      address,
      wallet,
      messageById,
    }: { networkID; address; wallet; messageById },
    { getState, dispatch },
  ): Promise<any> => {
    const response = await getPrivateMsgFriendRequest(
      networkID,
      address,
      wallet,
      null,
      DirectionType.later,
      messageById,
    );
    return response;
    // if (response && response?.messages && response?.messages.length > 0) {
    //   const rootState = getState() as RootState;
    //   await processReadResponse(dispatch, getState, address, rootState, response, networkID, provider, null, decryptMessage, publicKey, true);
    // }
  },
);

// add in-app notification.
export const addLocalNotification = createAsyncThunk(
  'directMessage/addLocalNotification',
  async (
    { networkID, wallet, notification }: { networkID; wallet; notification },
    { getState, dispatch },
  ): Promise<any> => {
    let messageTitle = null;
    let messageSentTime = null;
    let messageSenderWallet = null;

    //The notification object returned by ios and android are different - specially the 'trigger' object within it
    if (Platform.OS === 'ios') {
      messageTitle = notification?.request?.content?.title;
      messageSentTime = getSyncedTime();
      messageSenderWallet =
        notification?.request?.trigger?.payload?.body?.friendWallet;
    } else {
      const notificationBody =
        notification?.request?.trigger?.remoteMessage?.data?.body;
      messageTitle = notification?.request?.trigger?.remoteMessage?.data?.title;
      messageSentTime = notification?.request?.trigger?.remoteMessage?.sentTime;
      if (notificationBody) {
        messageSenderWallet = JSON.parse(notificationBody).friendWallet;
      }
    }
    if (messageSenderWallet) {
      const sentTime = messageSentTime;
      // get friend data.
      const rootState = getState() as RootState;
      const currentFriends = rootState.friends.currentFriends;
      let currentFriend: any = currentFriends?.find(
        mf => mf.wallet?.toLowerCase() === messageSenderWallet?.toLowerCase(),
      );
      const friendName = currentFriend ? currentFriend?.name : messageTitle;
      if (friendName) {
        const userProfileImage = await getUserAvatar(
          currentFriend.avatarURI ?? '',
        );
        // push notification
        dispatch(
          pushNotification({
            subject: `Hey! You have 1 unread message`,
            body: `Please check out Messages! You have new message(s) from ${friendName}`,
            uri: userProfileImage ? userProfileImage : null,
            timeStamp: sentTime.toString(),
            // blockNumber: await provider.getBlockNumber(),
            id: messageSenderWallet,
            notificationType: NotificationType.NewPrivateMessage,
            dataObject: currentFriend,
          }),
        );
      }
    }
  },
);

const PrivateMessageSlice = createSlice({
  name: 'PrivateMessage',
  initialState,
  reducers: {
    pushPrivateMessage(state, action) {
      if (Array.isArray(action.payload)) {
        const currentMessages = state.messages.filter(
          p => action.payload.findIndex(x => x.messageId == p.messageId) == -1,
        );
        state.messages = currentMessages.concat(action.payload);
      } else {
        if (
          state.messages.findIndex(p => p.hash == action.payload.hash) == -1
        ) {
          state.messages.push(action.payload);
        }
      }
      state.groupByMessages = groupByMessages(state.messages);
    },
    popPrivateMessage(state, action) {
      const messageIdTobeDeleted = action.payload;
      state.messages = state.messages.filter(
        message => message.messageId !== messageIdTobeDeleted,
      );
      state.groupByMessages = groupByMessages(state.messages);
    },
    setLatestMsgRequest(state, action) {
      state.msgRequest = action.payload;
    },
    pushLastMessageMutualFriend(state, action) {
      const newList = state.mutualFriendMessagesDetails.filter(
        p => p.friendAddress != action.payload.friendAddress,
      );
      newList.push(action.payload);
      state.mutualFriendMessagesDetails = newList.sort((a, b) => {
        let timeStampA = a.draftMessage
          ? a.draftMessage.timestamp
          : a.latestMessage.timestamp;
        let timeStampB = b.draftMessage
          ? b.draftMessage.timestamp
          : b.latestMessage.timestamp;
        return timeStampB - timeStampA;
      });
      if (newList.length > 1) {
        state.latestMessageLoading = false;
      }
    },
    clearPrivateMessage(state) {
      state.messages = [];
      state.groupByMessages = [];
    },
    clearMutualFriendsMessage(state) {
      state.mutualFriendMessagesDetails = [];
    },
    pushMessageInDraft(state, action) {
      if (action.payload.message || action.payload.parent) {
        let existingDraft = state.draftMessages.find(
          df => df.friendAddress === action.payload.friendAddress,
        );
        if (existingDraft) {
          existingDraft.message = action.payload.message;
          existingDraft.timestamp = getSyncedTime();
          existingDraft.parent = action.payload.parent;
        } else {
          state.draftMessages.push({
            friendAddress: action.payload.friendAddress,
            message: action.payload.message,
            timestamp: getSyncedTime(),
            parent: action.payload.parent,
          });
        }
      }
    },
    clearMessageInDraft(state, action) {
      state.draftMessages = state.draftMessages.filter(x => {
        return x.friendAddress?.toLowerCase() != action.payload?.toLowerCase();
      });
    },
    resetPrivateMessage(state, action) {
      if (action.payload) {
        setAll(state, initialState, action.payload);
      } else {
        setAll(state, initialState);
      }
      if (subscription && subscription.abort) subscription.abort();
    },
    setFriendAddress(state, action) {
      state.friendAddress = action.payload;
    },
    setRequestLoading(state, action) {
      state.requestLoading = action.payload;
    },
    setinitialMessageLoading(state, action) {
      state.initialMessageLoading = action.payload;
    },
    setOlderMessageLoading(state, action) {
      state.olderMessageLoading = action.payload;
    },
    setLatestMsgListLoaded(state, action) {
      state.latestMsgListLoaded = action.payload;
    },
    pushPendingRequest(state, action) {
      if (state.pendingRequest) {
        if (
          state.pendingRequest.findIndex(
            obj => obj.messageId === action.payload.messageId,
          ) < 0
        ) {
          state.pendingRequest.push(action.payload);
        }
      } else {
        state.pendingRequest = [action.payload];
      }
      state.pendingRequest = state.pendingRequest.sort(
        (a, b) => b?.timestamp - a?.timestamp,
      );
    },
    popPendingRequest(state, action) {
      const messageIdTobeDeleted = action.payload;
      if (messageIdTobeDeleted && state.pendingRequest) {
        state.pendingRequest = state.pendingRequest?.filter(
          message => message?.messageId !== messageIdTobeDeleted,
        );
        state.pendingRequest = state.pendingRequest?.sort(
          (a, b) => b?.timestamp - a?.timestamp,
        );
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPrivateMessagesFromFriend.pending, state => {
        state.messagesLoading = true;
      })
      .addCase(getPrivateMessagesFromFriend.fulfilled, (state, action) => {
        state.messagesLoading = false;
      })
      .addCase(getPrivateMessagesFromFriend.rejected, state => {
        state.messagesLoading = false;
      })
      .addCase(getPrivateMessageById.pending, state => {
        state.messagesLoading = true;
      })
      .addCase(getPrivateMessageById.fulfilled, state => {
        state.messagesLoading = false;
      })
      .addCase(getPrivateMessageById.rejected, state => {
        state.messagesLoading = false;
      })
      .addCase(loadLastMessageDetailsForMutualFriends.pending, state => {
        state.latestMessageLoading = true;
      })
      .addCase(
        loadLastMessageDetailsForMutualFriends.fulfilled,
        (state, action) => {
          //Remove if any mutual friend is not mutual now.
          const mutualWallets = action.payload.mutualFriends?.map(
            p => p.wallet,
          );
          const deleted = state.mutualFriendMessagesDetails.filter(
            p =>
              mutualWallets?.findIndex(
                x => x?.toLowerCase() == p.friendAddress?.toLowerCase(),
              ) == -1,
          );
          if (deleted?.length > 0) {
            state.mutualFriendMessagesDetails =
              state.mutualFriendMessagesDetails.filter(
                p =>
                  mutualWallets?.findIndex(
                    x => x?.toLowerCase() == p.friendAddress?.toLowerCase(),
                  ) > -1,
              );
          }
          state.latestMessageLoading = false;
        },
      )
      .addCase(loadLastMessageDetailsForMutualFriends.rejected, state => {
        state.latestMessageLoading = false;
      })
      .addCase(
        getPrivateMsgPendingRequestForUser.fulfilled,
        (state, action) => {
          state.requestLoading = false;
          state.pendingRequest = action.payload.pendingRequest;
        },
      )
      .addCase(getPrivateMsgPendingRequestForUser.rejected, (state, action) => {
        state.requestLoading = false;
      })
      .addCase(
        getPrivateMsgPendingRequestForUser.pending,
        (state, action) => {},
      );
  },
});

export const PrivateMessageSliceReducer = PrivateMessageSlice.reducer;

const baseInfo = (state: RootState) => state.PassportMessage;

export const {
  pushPrivateMessage,
  popPrivateMessage,
  clearPrivateMessage,
  pushLastMessageMutualFriend,
  clearMutualFriendsMessage,
  pushMessageInDraft,
  clearMessageInDraft,
  resetPrivateMessage,
  setFriendAddress,
  setRequestLoading,
  setinitialMessageLoading,
  setOlderMessageLoading,
  setLatestMsgRequest,
  pushPendingRequest,
  popPendingRequest,
  setLatestMsgListLoaded,
} = PrivateMessageSlice.actions;

export const getPrivateMessageState = createSelector(
  baseInfo,
  DirectMessage => DirectMessage,
);
