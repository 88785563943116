import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    zIndex: 999,
  },
  indicator: {
    height: 4,
    width: 30,
    marginHorizontal: 3,
    borderRadius: 8,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
  },
  slidesContainer: {
    alignItems: 'center',
    overflow: 'hidden',
  },
  slidesInnerContainer: {
    flex: 1,
    paddingHorizontal: defaultTheme.HORIZONTAL_PADDING,
  },
  title: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
  slidesImage: {
    position: 'absolute',
  },
  btnContainerMob: {
    position: 'absolute',
    bottom: 55,
    right: defaultTheme.HORIZONTAL_PADDING,
    left: defaultTheme.HORIZONTAL_PADDING,
  },
  btnContainerWeb: { marginTop: 25, width: '25%', alignSelf: 'center' },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicatorContainer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    zIndex: 99,
  },
});
