import React, { memo, useCallback, useEffect, useState } from 'react';
import { ActivityIndicator, Image, Pressable, Text, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { TouchableRipple } from 'react-native-paper';

import { styles } from './styles';
import CachedImage from '../CachedImage';
import { defaultTheme } from '../../themes/loot8';
import { VerifiedSvg } from '../../assets/svg/HomeSvgs';
import useIsResponsive from '../../hooks/useIsResponsive';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import token from '../../assets/Loot8TokenBlack.png';
import SectionHeading from '../SectionHeading';
import { ICollectibleDetail } from '../../interfaces/ICollectibleDetail.interface';
import { getCollectibleDetails } from '../../slices/OfferSlice';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { useAppSelector } from '../../hooks';
import {
  formatPriceUpToTrillion,
  getLoot8Tokens,
  showToastMessage,
} from '../../helpers/Gadgets';
import {
  PassportSubscriptionActions,
  PassportType,
} from '../../enums/passportCategory.enum';
import { ScreenName } from '../../enums/screen.enum';
import ModalComponent from '../Modal';
import GradientButton from '../GradientButton';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import {
  approveForSubscription,
  approveForUnsubscribe,
} from '../../slices/PassportSlice';
import { followCreator, unfollowCreator } from '../../slices/CreatorsSlice';
import { CatalogTypes } from '../../enums/catalog.enum';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { CollectionType } from '../../enums/collection.enum';
import {
  CollectionManager__factory,
  Loot8Collection__factory,
} from '../../typechain';
import {
  addresses,
  getAnynetStaticProvider,
  getNetwork,
  getStaticProvider,
  ToastCustomMessageType,
} from '../../appconstants';
import { useFocusEffect } from '@react-navigation/native';

type FollowingDetailsCardProps = {
  selectPassport: ICollectibleDetail;
  type?: string;
  navigation: any;
  isPublicVisit: boolean;
};

const FollowingDetailsCard: React.FC<FollowingDetailsCardProps> = memo(
  ({ selectPassport, type = 'passport', navigation, isPublicVisit }) => {
    const isResponsive = useIsResponsive();
    const activeWidth = useActiveDimensions().width;
    const imageSize = isResponsive ? 250 : activeWidth / 2.6;

    const [loadingAssociated, setLoadingAssociated] = useState(true);
    const [associatedPassport, setAssociatedPassport] = useState('');

    async function getAssociatedExpass() {
      // ? Associated Passport is the Premium Passport Address in case of Free Passport and vice versa.
      try {
        // Get the chainIds and provider for contract calls
        const networkID = getNetwork();
        const staticProvider = getStaticProvider();

        const collectionManager = CollectionManager__factory.connect(
          addresses[networkID].CollectionManager,
          staticProvider,
        );
        const collectibleContract = Loot8Collection__factory.connect(
          selectPassport?.address,
          staticProvider,
        );

        // Get All Expasses for the Entity of the Current Expass
        const entityAddress = selectPassport?.entityAddress;
        const entityExpasses = await collectionManager.getCollectionsForEntity(
          entityAddress,
          CollectionType.PASSPORT,
          true,
        );
        const expassOwner = await collectibleContract?.owner();
        // Check the Owner of Each Expass

        for (let i = 0; i < entityExpasses.length; i++) {
          const entityExpassContract = Loot8Collection__factory.connect(
            entityExpasses[i].source,
            getAnynetStaticProvider(entityExpasses[i].chainId.toNumber()),
          );
          const entityExpassOwner = await entityExpassContract?.owner();
          // Check If the Owner of the Current Entity Expass is the Same as the Owner of Collectible
          // ? If Yes, then the Current Entity Expass is the Associated Passport (Free or premium depending on the current passport type)
          if (
            entityExpassOwner == expassOwner &&
            entityExpasses[i].source?.toLowerCase() !==
            selectPassport?.address?.toLowerCase()
          ) {
            // Check if the assigned Expass is a Premium Expass
            // Fetch Collection data directly from the chain and check the isPremium Flag
            const collectionInfo = await collectionManager.getCollectionInfo(
              entityExpasses[i].source,
            );
            if (collectionInfo?._additionCollectionData?.isPremium === true) {
              setAssociatedPassport(entityExpasses[i].source);
              break;
            }
          }
        }
      } catch (e) {
        LogToLoot8Console(
          'FollowingDetailsCard-getAssociatedPassport-Associated Passport',
          e,
        );
      } finally {
        setLoadingAssociated(false);
      }
    }

    useEffect(() => {
      selectPassport && getAssociatedExpass();
    }, [selectPassport]);

    return (
      <>
        <View
          style={[
            styles.row,
            {
              alignItems: 'flex-start',
            },
          ]}>
          <CachedImage
            isBackground
            noImageOnError={true}
            source={{ uri: selectPassport?.image }}
            imageStyle={{}}
            style={{
              ...styles.image,
              maxWidth: imageSize,
              minHeight: imageSize,
              height: imageSize,
            }}
            contentFit={'cover'}></CachedImage>
          <View style={styles.rightContainer}>
            <View style={[{ flexDirection: isResponsive ? 'row' : 'column' }]}>
              <View
                style={[
                  { flex: 1 },
                  isResponsive ? {} : { justifyContent: 'center' },
                ]}>
                <Text style={styles.title} ellipsizeMode="tail">
                  {type == 'collectibles'
                    ? `${selectPassport?.name} ${selectPassport?.subTitle} ${selectPassport?.fromExternalWallet ? 'External' : ''
                    }`
                    : `${selectPassport?.name?.trim()} ${selectPassport?.subTitle?.trim()
                      ? '#' + selectPassport.subTitle.split('#')[1]
                      : ''
                    }`}{' '}
                  {selectPassport?.thirdPartyVerifiedURL?.length > 0 && (
                    <>
                      {' '}
                      <VerifiedSvg size={defaultTheme.FONT_SIZE_XMEDIUM} />
                    </>
                  )}
                </Text>
                <Text style={styles.creatorType}>
                  {selectPassport?.category}
                </Text>
                <Text style={styles.subtitle}>
                  • {selectPassport?.followerCount || 0} Followers
                </Text>
              </View>
              <View style={{ flex: 1 }}>
                <CustomButton
                  item={selectPassport}
                  label={isPublicVisit ? 'Follow' : 'unfollow'}
                  gradient={isPublicVisit}
                  navigation={navigation}
                  isPublicVisit={isPublicVisit}
                />

                {isResponsive && loadingAssociated && (
                  <>
                    <View style={{ marginTop: 15 }} />
                    <ActivityIndicator
                      size={'small'}
                      color={defaultTheme.PRIMARY_TEXT_COLOR}
                    />
                  </>
                )}

                {isResponsive && associatedPassport?.length > 0 && (
                  <>
                    <View style={{ marginTop: 15 }} />
                    <JoinComponent
                      navigation={navigation}
                      associatedPassport={associatedPassport}
                    />
                  </>
                )}
              </View>
            </View>
            {isResponsive && (
              <View>
                <SectionHeading SmHeading title={`Description`} />
                <Text style={styles.description}>
                  {selectPassport?.details}
                </Text>
              </View>
            )}
          </View>
        </View>
        {!isResponsive && loadingAssociated && (
          <>
            <View style={{ marginTop: 15 }} />
            <ActivityIndicator
              size={'small'}
              color={defaultTheme.PRIMARY_TEXT_COLOR}
            />
          </>
        )}
        {!isResponsive && associatedPassport?.length > 0 && (
          <View style={{ marginTop: 15 }}>
            <JoinComponent
              navigation={navigation}
              associatedPassport={associatedPassport}
            />
          </View>
        )}
        {!isResponsive && (
          <View style={{ marginTop: 5 }}>
            <SectionHeading SmHeading title={`Description`} />
            <Text style={styles.description}>{selectPassport?.details}</Text>
          </View>
        )}
      </>
    );
  },
);

export default FollowingDetailsCard;

interface FollowUnFollowButtonProps {
  item: ICollectibleDetail;
  gradient?: boolean;
  label: string;
  navigation: any;
  isPublicVisit: boolean;
}

const CustomButton: React.FC<FollowUnFollowButtonProps> = ({
  item,
  gradient = false,
  label,
  navigation,
  isPublicVisit,
}) => {
  const isResponsive = useIsResponsive();

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const { userInfo, networkId, staticProvider, wallet, address } =
    useWeb3AuthContext();

  async function onUnfollowPressed() {
    try {
      setLoading(true);
      setIsModalVisible(false);
      let tokenID: number[] = [];
      tokenID.push(item?.collectibleIds[0]);

      // * Approve For Unsubscribe/Unfollow
      await dispatch(
        approveForUnsubscribe({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
          passportAddress: item?.address,
          tokenId: tokenID[0],
        }),
      );
      // * Unfollow/UnSubscribe Creator
      const response = await dispatch(
        unfollowCreator({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
          passportAddress: item.address,
          passportIds: tokenID,
          userInfo: userInfo,
          collectionType: CatalogTypes.PASSPORT,
        }),
      );
      if (response.payload) {
        navigation.navigate('Success', {
          screen: ScreenName.SUCCESS_SCREEN,
          params: {
            message: `You have successfully unfollowed ${item?.name}`,
            navigateTo: ScreenName.HOME_SCREEN,
          },
        });
      } else {
        setShowErrorModal(true);
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  }

  async function onFollowPressed() {
    try {
      setLoading(true);
      setIsModalVisible(false);

      // * Check if the Free Profile is Limited
      if (
        item?.maxTokens &&
        item.maxTokens > 0 &&
        item.collectibleIds?.length == 0
      ) {
        // * Check if the Mint Limit has not exceeded yet
        let subscriptionAllowed =
          item.collectionCollectibleIds - 2 < item.maxTokens;

        if (!subscriptionAllowed) {
          // * The Limited Expass has reached mint limit
          showToastMessage(
            ToastCustomMessageType.INFO,
            "This Profile has reached it's follow limit",
          );
          setLoading(false);
          return;
        }
      }

      // * Approve For Subscribe/Follow
      await dispatch(
        approveForSubscription({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
        }),
      );

      // * Follow/Subscribe Creator
      const response = await dispatch(
        followCreator({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
          passportAddress: item.address,
          passportSubscribePrice: item?.buyPrice,
          userInfo: userInfo,
          collectionType: CatalogTypes.PASSPORT,
        }),
      );

      if (response.payload) {
        navigation.navigate('Success', {
          screen: ScreenName.SUCCESS_SCREEN,
          params: {
            message: `You have successfully followed ${item?.name}`,
            buttonLabel: 'Open Profile',
            navigateTo: 'BottomTab',
            navigationParams: {
              screen: 'HomeTab',
              params: {
                screen: 'FollowingDetails',
                params: {
                  passportAddress: item.address,
                  chainId: item.chainId,
                },
              },
            },
          },
        });
      } else {
        setShowErrorModal(true);
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <LinearGradient
      colors={
        gradient
          ? [defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]
          : [defaultTheme.SECONDARY_COLOR, defaultTheme.SECONDARY_COLOR]
      }
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={[styles.btnContainer, { marginTop: isResponsive ? 0 : 15 }]}>
      <ModalComponent
        showModal={isModalVisible}
        needCloseButton={true}
        enableHeader={false}
        onDismiss={() => setIsModalVisible(false)}>
        <View style={{ padding: 30, alignContent: 'center' }}>
          <Text style={{ color: 'white' }}>
            {isPublicVisit
              ? 'Are you sure you want to Follow this Creator?'
              : 'Are you sure you want to Unfollow this Creator?'}
          </Text>
        </View>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 30,
          }}>
          <GradientButton
            width="48%"
            type="secondary"
            buttonLabel="Cancel"
            onPress={() => setIsModalVisible(false)}
          />
          {isPublicVisit ? (
            <GradientButton
              width="48%"
              buttonLabel="Follow"
              onPress={onFollowPressed}
              type="alternate"
            />
          ) : (
            <GradientButton
              width="48%"
              buttonLabel="Unfollow"
              type="alternate"
              onPress={onUnfollowPressed}
            />
          )}
        </View>
      </ModalComponent>

      <ModalComponent
        showModal={showErrorModal}
        needCloseButton={true}
        enableHeader={false}
        onDismiss={() => {
          setShowErrorModal(false);
        }}>
        <View style={{ padding: 30, alignContent: 'center' }}>
          <Text style={styles.modalTextStyle}>Something went wrong.</Text>
          <Text style={styles.modalTextStyle}>Please try again!!</Text>
        </View>
      </ModalComponent>

      <TouchableRipple
        style={styles.ripple}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={loading ? () => { } : () => setIsModalVisible(true)}>
        {loading ? (
          <ActivityIndicator
            size={'small'}
            color={defaultTheme.PRIMARY_TEXT_COLOR}
          />
        ) : (
          <Text style={styles.btnText}>{label}</Text>
        )}
      </TouchableRipple>
    </LinearGradient>
  );
};

const JoinComponent = ({
  associatedPassport,
  navigation,
}: {
  associatedPassport: string;
  navigation: any;
}) => {
  const isResponsive = useIsResponsive();

  const [premiumExpass, setPremiumExpass] = useState<ICollectibleDetail>();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isAlreadySubscribed, setIsAlreadySubscribed] = useState(false);

  const { networkId, staticProvider, address, wallet } = useWeb3AuthContext();
  const entityData = useAppSelector(state => state.Entity.EntityData);

  useFocusEffect(
    useCallback(() => {
      // Fetch and render the price of the associated passport
      // Fetch other data that is required for the subscription of the premium passport
      async function fetchDetails() {
        setLoading(true);
        try {
          const balance = await getLoot8Tokens(address);
          const data = await getCollectibleDetails(
            {
              networkID: networkId,
              provider: staticProvider,
              collectibleAddress: associatedPassport,
              address,
              wallet,
            },
            { entityData },
            {
              isCache: false,
              isBalanceRefresh: false,
              isMarketPlaceRefresh: true,
            },
          );

          // Check if the Subscription is allowed for this Expass i.e it is not limited
          let subscriptionAllowed = false;
          let subscribed = data.passportType !== PassportType.SUBSCRIPTION;

          if (
            data?.maxTokens &&
            data.maxTokens > 0 &&
            data.collectibleIds?.length == 0
          ) {
            subscriptionAllowed =
              data.collectionCollectibleIds - 2 < data.maxTokens;
          } else {
            subscriptionAllowed = true;
          }

          if (subscribed || subscriptionAllowed) {
            setCurrentBalance(balance);
            setPremiumExpass(data);
            setIsAlreadySubscribed(subscribed);
          }
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }

      fetchDetails();
    }, []),
  );

  function onSubscribeClick() {
    navigation.navigate(ScreenName.PASSPORT_BUY_SELL_CONFIRMATION, {
      selectedEXPass: premiumExpass,
      passportAction: PassportSubscriptionActions.SUBSCRIBE,
      currentLoot8Balance: formatPriceUpToTrillion(currentBalance),
      setSearchPassportText: undefined,
    });
  }

  // navigate to expass details
  function navigateToPassportDetails() {
    navigation.navigate(ScreenName.PASSPORT_DETAIL, {
      passportAddress: premiumExpass.address,
      chainId: networkId,
    });
  }

  if (loading) {
    return (
      <ActivityIndicator
        size={'small'}
        color={defaultTheme.PRIMARY_TEXT_COLOR}
      />
    );
  }

  if (isAlreadySubscribed) {
    return (
      <Pressable onPress={navigateToPassportDetails}>
        <LinearGradient
          colors={[defaultTheme.GRADIENT_COLOR2, defaultTheme.GRADIENT_COLOR1]}
          start={{ x: 0.2, y: 0.0001 }}
          end={{ x: 1, y: 0.0001 }}
          style={[styles.joinComponentContainer]}>
          <View style={[styles.joinOverlay, { justifyContent: 'center' }]}>
            <Text style={styles.joinText}>Open Experience Passport</Text>
          </View>
        </LinearGradient>
      </Pressable>
    );
  }

  if (!premiumExpass) {
    return;
  }

  return (
    <Pressable onPress={onSubscribeClick}>
      <LinearGradient
        colors={[defaultTheme.GRADIENT_COLOR2, defaultTheme.GRADIENT_COLOR1]}
        start={{ x: 0.2, y: 0.0001 }}
        end={{ x: 1, y: 0.0001 }}
        style={[styles.joinComponentContainer]}>
        <View style={styles.joinOverlay}>
          <Text style={styles.joinText}>Join the Experience Passport!</Text>
          <LinearGradient
            colors={[
              defaultTheme.GRADIENT_COLOR1,
              defaultTheme.GRADIENT_COLOR2,
            ]}
            start={{ x: 0.2, y: 0.0001 }}
            end={{ x: 1, y: 0.0001 }}
            style={[styles.joinPriceWrapper]}>
            <View style={styles.joinPriceContainer}>
              <Image
                source={token}
                resizeMode="contain"
                style={styles.tokenImg}
              />
              <Text style={styles.joinText}>
                {formatPriceUpToTrillion(premiumExpass?.buyPrice)}
              </Text>
            </View>
          </LinearGradient>
        </View>
      </LinearGradient>
    </Pressable>
  );
};
