import React, { useEffect, useState } from 'react';
import {
  Clipboard,
  Dimensions,
  Platform,
  Pressable,
  Text,
  View,
  useWindowDimensions,
  Image,
} from 'react-native';
import styles from '../../styles';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { defaultTheme } from '../../themes/loot8';
import { useDispatch } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { addresses } from '../../appconstants';
import { useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { AppDispatch } from '../../store';
import {
  User__factory,
  Loot8SignatureVerification__factory,
} from '../../typechain';
import { LogCustomError } from '../../helpers/AppLogger';
import {
  GetUserLinkAccountMessage,
  GetUserDeLinkAccountMessage,
} from '../../helpers/MsgHelper';
import { SendMetaTX } from '../../slices/AppSlice';
import {
  pushExternalLinkedAccount,
  removeExternalLinkedAccount,
} from '../../slices/AppUserSlice';
import { IMessageMetaData } from '../../slices/interfaces';
import { getData, removeData, storeData } from '../../helpers/AppStorage';
import AppLoaderComponent from '../Loader';
import ModalComponent from '../Modal';
import HOCContainer from '../HOCContainer';
import EmptyList from '../EmptyList';
import GradientButton from '../GradientButton';

const LinkedWallets = ({ navigation }) => {
  const { networkId, staticProvider, address, wallet } = useWeb3AuthContext();
  const [showDelinkModal, setShowDelinkModal] = React.useState(false);

  const [accountToDelink, setAcountToDelink] = React.useState(String);
  const dispatch = useDispatch<AppDispatch>();
  const linkedAccounts = useAppSelector(
    state => state.AppUser.linkedExternalAccounts,
  );
  const [isCopied, setIsCopied] = useState(false);
  const [currentCopied, setCurrentCopied] = useState('');
  const [loading, setLoading] = useState(false);
  const windowHeight = useWindowDimensions().height;
  const windowWidth = useWindowDimensions().width;

  const [extAccountConnected, setExtAccountConnected] = React.useState(false);
  const [accountToLink, setAcountToLink] = React.useState(String);
  const [showAccountLinkedModal, setShowAccountLinkedModal] =
    React.useState(false);
  const userFactory = User__factory.connect(
    addresses[networkId].User,
    staticProvider,
  );
  const signatureVerifierFactory = Loot8SignatureVerification__factory.connect(
    addresses[networkId].Loot8SignatureVerification,
    staticProvider,
  );

  const copyToClipboard = (userAddr: string) => {
    Clipboard.setString(userAddr);
    setIsCopied(true);
    setCurrentCopied(userAddr);
    setTimeout(() => {
      setIsCopied(false);
      setCurrentCopied(userAddr);
    }, 2000);
  };

  let userSessionId;
  let extAccount;

  const fetchCurrentSessionData = async () => {
    userSessionId = await getData('userSessionId');
    extAccount = await getData('extAccount');
  };

  fetchCurrentSessionData();

  window?.web3modal?.subscribeEvents(async event => {
    if (
      event?.name == 'ACCOUNT_CONNECTED' &&
      userSessionId !== event?.userSessionId
    ) {
      userSessionId = event?.userSessionId;
      await storeData('userSessionId', userSessionId);
      extAccount = (await window?.getAccount()).address;
      await storeData('extAccount', extAccount);
      setExtAccountConnected(true);
    } else if (event?.name == 'ACCOUNT_DISCONNECTED') {
      await removeData('userSessionId');
      await removeData('extAccount');
      setExtAccountConnected(false);
    }
  });

  useEffect(() => {
    if (extAccountConnected) {
      linkExternalAccount();
    }
  }, [extAccountConnected]);

  const connectToWallet = async () => {
    const account = await window?.getAccount();
    if (!account || !account?.isConnected) {
      await window?.web3modal?.openModal();
    } else {
      linkExternalAccount();
    }
  };

  const linkExternalAccount = async () => {
    const accountToLink = (await window?.getAccount()).address;
    setAcountToLink(accountToLink);
    if (!(await isAccountLinked(accountToLink))) {
      const userSignature = await getUserSignatureForLinking();
      const linkAccountsData = GetUserLinkAccountMessage(
        accountToLink,
        userSignature,
      );

      let msg: IMessageMetaData = {
        to: addresses[networkId].User,
        wallet: wallet,
        data: linkAccountsData,
        networkID: networkId,
        provider: staticProvider,
      };

      //navigateToLinkedWallets();

      dispatch(SendMetaTX(msg)).then(response => {
        if (response.payload.status == 'Success') {
          dispatch(pushExternalLinkedAccount(accountToLink));
        }
      });
    } else {
      setShowAccountLinkedModal(true);
    }
  };

  const isAccountLinked = async account => {
    let i;
    let isLinked = false;
    for (i = 0; i < linkedAccounts?.length; i++) {
      if (linkedAccounts[i] == account) {
        isLinked = true;
      }
    }
    return isLinked;
  };

  const getUserSignatureForLinking = async () => {
    const linkMessage = await userFactory.linkMessage();
    const accountToLink = (await window?.getAccount()).address;
    const n = +(await signatureVerifierFactory.nonces(accountToLink));

    const domain = {
      name: 'LOOT8',
      version: '1',
      chainId: window?.location?.hostname.startsWith('app.loot8.io')
        ? 42170
        : 421614,
      verifyingContract: addresses[networkId].Loot8SignatureVerification,
    };

    const types = {
      LinkAccounts: [
        { name: 'account', type: 'address' },
        { name: 'loot8Account', type: 'address' },
        { name: 'message', type: 'string' },
        { name: 'nonce', type: 'uint256' },
      ],
    };

    const message = {
      account: accountToLink,
      loot8Account: address,
      message: linkMessage,
      nonce: n,
    };

    await switchOrAddNetwork();

    const signature = await window?.signTypedData({
      account: accountToLink,
      domain: domain,
      types: types,
      primaryType: 'LinkAccounts',
      message: message,
    });

    return signature;
  };

  const switchOrAddNetwork = async () => {
    const currentNetwork = await window?.ethereumClient?.getNetwork();
    if (currentNetwork?.chain?.id !== networkId) {
      try {
        await window?.ethereumClient?.switchNetwork({ chainId: networkId });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            // Prompt user to add Network if not already configured
            if (window && window?.ethereum) {
              await window?.ethereum?.request({
                method: 'wallet_addEthereumChain',
                params: [window?.defaultNetworkConfig],
              });
            }
          } catch (addError) {
            console.log('Chain Switching Failed');
            LogCustomError(
              'Chain Switching Failed',
              addError.name,
              addError.message,
              addError.stack,
            );
          }
        }
      }
    }
  };

  const LinkedAccount = ({ account }) => {
    const windowWidth = Dimensions.get('window').width;
    const n = windowWidth > 440 ? 13 : 8;

    return (
      <View style={{ width: '100%', marginBottom: 15 }}>
        {account ? (
          <View
            style={{
              flexDirection: 'row',
              justifyContent:
                windowWidth > 600 ? 'space-between' : 'space-around',
              alignItems: 'center',
              backgroundColor: '#ffffff21',
              borderRadius: defaultTheme.CONTENT_RADIUS,
              borderWidth: 2,
              borderColor: '#E0E5EE',
              padding: 10,
            }}>
            <View
              style={{
                width: windowWidth > 600 ? '90%' : '80%',
                justifyContent: 'space-around',
              }}>
              <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
                <Text
                  style={[
                    styles.drawerAddressText,
                    {
                      color: defaultTheme.TEXT_COLOR_SECONDARY,
                      fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
                      fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                    },
                  ]}>
                  {windowWidth > 600
                    ? account
                    : account.substring(0, n) +
                      '...' +
                      account.substring(account.length - n, account.length)}
                </Text>
                <Icon
                  name="clipboard-multiple"
                  style={{ marginLeft: 5, marginTop: 3 }}
                  size={16}
                  color={defaultTheme.PRIMARY_TEXT_COLOR}
                  onPress={() => {
                    copyToClipboard(account);
                  }}></Icon>
              </View>
              {isCopied && currentCopied == account ? (
                <View
                  style={[
                    styles.drawerCopyTextContainer,
                    { top: -10, right: 0 },
                  ]}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontFamily: defaultTheme.FONT_FAMILY_MAIN,
                      fontSize: 8,
                    }}>
                    Copied!
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </View>
            {Platform.OS == 'web' && (
              <Ionicons
                name="remove-circle"
                size={24}
                color={defaultTheme.GRADIENT_COLOR1}
                onPress={() => {
                  setAcountToDelink(account);
                  setShowDelinkModal(true);
                }}
              />
            )}
          </View>
        ) : (
          <View
            style={{
              height: Platform.OS === 'web' ? '75vh' : '100%',
              marginTop: -50,
              width: '80%',
              alignSelf: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <View style={{ height: 50, width: 150, marginBottom: 5 }}>
              <EmptyList />
            </View>
          </View>
        )}
      </View>
    );
  };

  const delinkExternalAccount = async account => {
    const delinkAccountsData = GetUserDeLinkAccountMessage(account);

    let msg: IMessageMetaData = {
      to: addresses[networkId].User,
      wallet: wallet,
      data: delinkAccountsData,
      networkID: networkId,
      provider: staticProvider,
    };

    dispatch(SendMetaTX(msg)).then(response => {
      if (response.payload.status == 'Success') {
        dispatch(removeExternalLinkedAccount(account));
      }
    });

    setShowDelinkModal(false);
  };

  const navigateToWallet = () => {
    navigation.navigate('Wallet');
  };

  return (
    <>
      <View style={{ position: 'relative', flex: 1 }}>
        <View style={{ alignItems: 'center', marginTop: 10 }}>
          {linkedAccounts &&
            linkedAccounts.length > 0 &&
            linkedAccounts.map((item, index) => {
              return (
                <LinkedAccount key={item + index.toString()} account={item} />
              );
            })}

          {linkedAccounts && linkedAccounts.length === 0 && (
            <LinkedAccount key="no-linked" account={false} />
          )}
        </View>
        {Platform.OS == 'web' && (
          <View
            style={{
              bottom: 40,
              position: 'absolute',
              alignSelf: 'center',
              width: '100%',
            }}>
            <>
              <View style={{ marginBottom: 12, width: '100%' }}>
                <GradientButton
                  onPress={connectToWallet}
                  buttonLabel="Link an account"
                />
              </View>

              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 10,
                  marginLeft: 20,
                  marginRight: 20,
                }}>
                <Text
                  numberOfLines={3}
                  adjustsFontSizeToFit
                  style={[
                    styles.bottomButtonText,
                    {
                      width: windowWidth <= 400 ? '90%' : '80%',
                      fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                      fontSize:
                        windowWidth <= 400
                          ? defaultTheme.FONT_SIZE_XXXSMALL
                          : 11,
                      opacity: 1,
                      lineHeight: 14,
                    },
                  ]}>
                  Please ensure that you configure and switch to the Arbitrum
                  network in your external wallet, so that account linking can
                  happen without any issue.
                </Text>
              </View>
            </>
          </View>
        )}
      </View>
      {loading && (
        <View
          style={{
            minHeight: 70,
            width: '100%',
            backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
            opacity: 0.3,
          }}>
          <AppLoaderComponent />
        </View>
      )}
      <ModalComponent
        showModal={showDelinkModal}
        modalBodyStyle={{ width: '100%' }}
        modalStyle={{
          justifyContent: 'flex-start',
          marginTop:
            Platform.OS == 'web' ? '17%' : windowHeight - windowHeight * 0.87,
        }}
        headerText={showDelinkModal ? 'Confirm Delink' : 'Error!!'}>
        <View
          style={{
            paddingTop: 10,
            paddingLeft: 15,
            paddingRight: 15,
            marginBottom: 15,
          }}>
          <Text
            style={
              styles.modalTextStyle
            }>{`Are you sure you want to delink the following account from Loot8`}</Text>
          <Text
            style={[
              styles.modalTextStyle,
              { fontFamily: defaultTheme.FONT_FAMILY_BOLD },
            ]}>
            {accountToDelink}
          </Text>
        </View>
        <View
          style={[
            {
              marginBottom: 15,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            },
          ]}>
          <GradientButton
            onPress={() => {
              setAcountToDelink('');
              setShowDelinkModal(false);
            }}
            type="secondary"
            buttonLabel="CANCEL"
            customStyle={{ width: '47.5%' }}
          />
          <GradientButton
            onPress={() => {
              delinkExternalAccount(accountToDelink);
            }}
            buttonLabel="CONFIRM"
            customStyle={{ width: '47.5%' }}
          />
        </View>
      </ModalComponent>
      {showAccountLinkedModal && (
        <ModalComponent
          showModal={showAccountLinkedModal}
          modalBodyStyle={{ width: '100%' }}
          modalStyle={{
            justifyContent: 'flex-start',
            marginTop:
              Platform.OS == 'web' ? '17%' : windowHeight - windowHeight * 0.87,
          }}
          headerText={'Error!!'}>
          <View
            style={{
              paddingTop: 10,
              paddingLeft: 15,
              paddingRight: 15,
              marginBottom: 15,
            }}>
            <Text
              style={
                styles.modalTextStyle
              }>{`The connected account ${accountToLink} is already linked to Loot8. Please connect another account to the site and try again!`}</Text>
          </View>

          <View style={[styles.modalButtonContainer, { marginBottom: 15 }]}>
            <GradientButton
              onPress={() => {
                setShowAccountLinkedModal(false);
              }}
              buttonLabel="OK"
            />
          </View>
        </ModalComponent>
      )}
    </>
  );
};

export default HOCContainer({
  OriginalComponent: LinkedWallets,
  title: 'Linked Accounts',
});
