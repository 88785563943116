import React from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import styles from '../../styles';
import { getBase64Image } from '../../helpers/ImageHelper';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import {
  AvatarPlaceHolderSvg,
  CameraSvg,
  GallerySvg,
} from '../../assets/svg/ProfileSvgs';
import * as FileSystem from 'expo-file-system';

// import ModalComponent from "../Modal";
// import { defaultTheme } from "../../themes/loot8";
/* 
    It supports to capture the image using camera and choose the images from gallery,
    it will return image in base64 binary format so that will be stored to IPFS.
    Underline, we are using expo imagepicker package(https://docs.expo.dev/versions/latest/sdk/imagepicker/),
    you can extend this component based on your need.

*/
const ImagePickerComponent = ({
  setSelectedImage,
  setSelectImageAction,
  onAvatarButtonClick,
  showAvatar = true,
  isSquare = true,
}) => {
  // choose image from gallery
  const openImagePicker = async () => {
    const permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (permissionResult.granted === false) {
      LogToLoot8Console(
        "You've refused to allow this app to access your photos!",
      );
      return;
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      base64: true,
      quality: 1,
    });
    let imageSize;
    if (!result.canceled) {
      let { height, width, uri } = result.assets[0];

      width = isSquare ? 512 : (512 / height) * width;
      let resizedImage = await getBase64Image(uri, true, 512, width);
      //Get image size
      if (Platform.OS === 'web') {
        try {
          const response = await fetch(uri);
          const blob = await response.blob();
          imageSize = blob.size;
        } catch (error) {
          console.error('Error getting file size on web:', error);
        }
      } else {
        try {
          const fileInfo = await FileSystem.getInfoAsync(uri);
          imageSize = fileInfo.size;
        } catch (error) {
          console.error('Error getting file info on mobile:', error);
        }
      }
      setSelectedImage(
        'data:image/jpeg;base64,' + resizedImage.base64,
        imageSize,
      );
      setSelectImageAction(false);
    }
  };

  // capture photo using camera
  const openCamera = async () => {
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
      LogToLoot8Console(
        "You've refused to allow this appp to access your camera!",
      );
      return;
    }

    const result = await ImagePicker.launchCameraAsync({
      base64: true,
      allowsEditing: true,
      quality: 1,
      aspect: [1, 1],
    });

    if (!result.canceled) {
      let { height, width } = result.assets[0];
      width = isSquare ? 512 : (512 / height) * width;
      let resizedImage = await getBase64Image(
        result.assets[0].uri,
        true,
        512,
        width,
      );
      setSelectedImage('data:image/jpeg;base64,' + resizedImage.base64);
      setSelectImageAction(false);
    }
  };
  return (
    <>
      <View style={[styles.imagePickerContainer]}>
        {(Platform.OS != 'web' || Dimensions.get('window').width < 480) && (
          <TouchableOpacity onPress={openCamera}>
            <View
              style={[
                {
                  paddingLeft: 20,
                  paddingRight: 15,
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              ]}>
              <View style={styles.imagePickerIconContainer}>
                <CameraSvg />
              </View>
              <View>
                <Text style={styles.imagePickerButtonCaption}>Camera</Text>
              </View>
            </View>
          </TouchableOpacity>
        )}
        <TouchableOpacity onPress={openImagePicker}>
          <View
            style={[
              {
                paddingLeft: 15,
                paddingRight: 15,
                justifyContent: 'center',
                alignItems: 'center',
              },
            ]}>
            <View style={styles.imagePickerIconContainer}>
              <GallerySvg />
            </View>
            <View>
              <Text style={styles.imagePickerButtonCaption}>Gallery</Text>
            </View>
          </View>
        </TouchableOpacity>
        {showAvatar && (
          <TouchableOpacity
            onPress={() => {
              onAvatarButtonClick(true);
            }}>
            <View
              style={[
                {
                  paddingLeft: 15,
                  paddingRight: 15,
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              ]}>
              <View style={styles.imagePickerIconContainer}>
                <AvatarPlaceHolderSvg />
              </View>
              <View>
                <Text style={styles.imagePickerButtonCaption}>Avatar</Text>
              </View>
            </View>
          </TouchableOpacity>
        )}
      </View>
    </>
  );
};

export default ImagePickerComponent;
