import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Constants from 'expo-constants';
import Toast from 'react-native-root-toast';
import {
  APP_STORAGE_ALL_PASSPORTDETAILS,
  APP_STORAGE_COLLECTIBLE_LIST,
  APP_STORAGE_ENTITY_LIST,
  APP_STORAGE_EVENT_LIST,
  APP_STORAGE_OFFER_LIST,
  APP_STORAGE_PASSPORT_LIST,
  APP_STORAGE_USER_NONCE,
  APP_STORAGE_USER_THIRDPARTY_LIST,
  DISCORD_APPSTORE_URL,
  FACEBOOK_APPSTORE_URL,
  INSTAGRAM_APPSTORE_URL,
  TELEGRAM_APPSTORE_URL,
  TIKTOK_APPSTORE_URL,
  TWITTER_APPSTORE_URL,
  ToastCustomMessageType,
  addresses,
  getNetwork,
  getStaticProvider,
} from '../appconstants';
import { AppEnvironment } from '../enums/env.enum';
import { StyleSheet, StatusBar, Dimensions, Platform, Linking } from 'react-native';
import { getSyncedTime } from './DateHelper';
import urlParser from 'url';
import * as Device from 'expo-device';
import { isSmartAppBannerPresent } from '../slices/helpers';
import { LogCustomError } from './AppLogger';
import { SocialLinkType } from '../enums/passportCategory.enum';
import { LogToLoot8Console } from './Loot8ConsoleLogger';
import { SOCIAL_WEBSITE } from '../enums/socialWebsite.enum';
import { ethers } from 'ethers';
import { Loot8Token__factory } from '../typechain';

/**
 * returns unix timestamp for x minutes ago
 * @param x minutes as a number
 */
export const minutesAgo = (x: number) => {
  const now = getSyncedTime();
  return getSyncedTime(now - x * 60000);
};

export const clearPassportAllHomeDetails = async () => {
  const allkeys = await AsyncStorage.getAllKeys();
  const allPassport = allkeys.filter(x => x.indexOf('@passport_') != -1);
  const alloffer = allkeys.filter(x => x.indexOf('@offer_') != -1);
  const allevent = allkeys.filter(x => x.indexOf('@event_') != -1);
  const allcollectiable = allkeys.filter(x => x.indexOf('@collectiable_') != -1);
  const allPassportdetails = allkeys.filter(x => x.indexOf('@passportdetails_') != -1);
  const allPassportBalance = allkeys.filter(x => x.indexOf('@passportbalance_') != -1);

  const entityOffer = allkeys.filter(x => x.indexOf('@entityoffer_') != -1);
  const entityEvent = allkeys.filter(x => x.indexOf('@entityevent_') != -1);
  const entityDigital = allkeys.filter(x => x.indexOf('@entitydigital_') != -1);

  const todeleteKeys = [
    alloffer,
    allevent,
    allcollectiable,
    allPassport,
    allPassportdetails,
    allPassportBalance,
    entityOffer,
    entityEvent,
    entityDigital,
  ];
  await Promise.all(
    todeleteKeys.map(async keylist => {
      await Promise.all(
        keylist.map(async item => {
          await AsyncStorage.removeItem(item);
        }),
      );
    }),
  );
  await AsyncStorage.removeItem(APP_STORAGE_ALL_PASSPORTDETAILS);
  await AsyncStorage.removeItem(APP_STORAGE_USER_NONCE);
  await AsyncStorage.removeItem(APP_STORAGE_ENTITY_LIST);
  await AsyncStorage.removeItem(APP_STORAGE_PASSPORT_LIST);
  await AsyncStorage.removeItem(APP_STORAGE_OFFER_LIST);
  await AsyncStorage.removeItem(APP_STORAGE_EVENT_LIST);
  await AsyncStorage.removeItem(APP_STORAGE_COLLECTIBLE_LIST);
  // await AsyncStorage.clear();
};

export const clearCatalog = async catalogType => {
  const allkeys = await AsyncStorage.getAllKeys();
  let removeLst = [];
  if (catalogType == 'Passports') {
    removeLst = allkeys.filter(x => x.indexOf('@passport_') != -1);
  } else if (catalogType == 'Events') {
    removeLst = allkeys.filter(x => x.indexOf('@event_') != -1);
  } else if (catalogType == 'Collectibles') {
    removeLst = allkeys.filter(x => x.indexOf('@collectiable_') != -1);

    const removeLstTP = allkeys.filter(x => x.indexOf('@thirdparty_') != -1);
    if (removeLstTP && removeLstTP.length > 0) {
      removeLst = removeLst.concat(removeLstTP);
    }
  }
  await Promise.all(
    removeLst.map(async item => {
      await AsyncStorage.removeItem(item);
    }),
  );
};

export const clearList = async listType => {
  const allkeys = await AsyncStorage.getAllKeys();
  let removeLst = [];
  if (listType == 'Entity_Digital') {
    removeLst = allkeys.filter(x => x.indexOf('@entitydigital_') != -1);
  } else if (listType == 'Entity_Offer') {
    removeLst = allkeys.filter(x => x.indexOf('@entityoffer_') != -1);
  } else if (listType == 'Entity_Event') {
    removeLst = allkeys.filter(x => x.indexOf('@entityevent_') != -1);
    await AsyncStorage.removeItem(APP_STORAGE_USER_THIRDPARTY_LIST);
  }
  await Promise.all(
    removeLst.map(async item => {
      await AsyncStorage.removeItem(item);
    }),
  );
};

const DEFAULT_ERRORMSG: string = 'Something went wrong. Please try again later.';
const isProduction: boolean = Constants.expoConfig.extra.ENVIRONMENT == AppEnvironment.PRODUCTION ? true : false;

// export function showToastMessage(error: string = DEFAULT_ERRORMSG) {
//   Toast.show(error, { duration: Toast.durations.LONG, });
// }
const toastStyle = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: StatusBar.currentHeight,
    backgroundColor: '#888888',
    padding: 28,
  },
});

const height = Dimensions.get('window').height;

export function showToastMessage(
  msgType: ToastCustomMessageType = ToastCustomMessageType.ERROR,
  msg: string = DEFAULT_ERRORMSG,
) {
  if (msgType == ToastCustomMessageType.INFO) {
    // Toast.show(error, {
    //   containerStyle:toastStyle,
    //   shadow: true,
    //   animation: true,
    //   hideOnPress: true,
    //   position: Toast.positions.CENTER,
    //   // backgroundColor: '#f7f7f7',
    //   // textColor: "#000000",
    //   duration: 0,
    // });
    // ToastView();
    Toast.show(msg, {
      duration: Toast.durations.LONG,
      position:
        Platform.OS === 'web' ? (isSmartAppBannerPresent() ? height - 140 : height - 100) : Toast.positions.BOTTOM,
    });
  } else if (!isProduction) {
    Toast.show(msg, {
      duration: Toast.durations.LONG,
      position:
        Platform.OS === 'web' ? (isSmartAppBannerPresent() ? height - 140 : height - 100) : Toast.positions.BOTTOM,
    });
  }
}

export const getTruncatedName = (name, charLength = 18) => {
  return Dimensions.get('window').width <= 480 && name?.length > charLength
    ? name?.substring(0, charLength) + ' ...'
    : name;
};

export const webViewHelper = (data: string) => {
  const actualData = data?.split('->')[1];
  const [method, value] = actualData?.split('||');
  switch (method?.toLowerCase()) {
    case 'openlink':
      if (Platform.OS == 'web') {
        window.open(value);
      } else {
        Linking.openURL(value);
      }
      break;
    default:
      break;
  }
};

export const detectURL = (message: string) => {
  try {
    let urls = [];
    var urlRegex = new RegExp(
      /((http(s)?):\/\/)?(www\.)([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm,
    );
    const urlList = message ? message?.toLowerCase().match(urlRegex) : null;
    try {
      if (urlList?.length > 0) {
        // build valid url.
        urlList?.map((url, index) => {
          let buildURL = null;
          url = url?.trim();
          try {
            const uParser = urlParser.parse(url);
            if (!uParser?.protocol) {
              buildURL = `https://${url}`;
            } else {
              buildURL = `${url}`;
            }
            urls.push(buildURL);
          } catch (e) {
            urls.push(url);
          }
        });
      }
      return urls;
    } catch (e) {
      // return extracted url in case any issue when build valid url.
      return urlList;
    }
  } catch (e) {
    return null;
  }
};

export const formatTimestamp = timestamp => {
  const date = new Date(timestamp);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().padStart(4, '0');

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const meridiem = hours >= 12 ? 'p.m' : 'a.m';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

  const formattedDate = `${day}/${month}/${year}, ${formattedHours}:${minutes}:${seconds} ${meridiem}`;
  return formattedDate;
};

export const formatPrice = price => {
  if (price < 10) {
    return price.toFixed(2);
  } else if (price >= 10 && price < 100) {
    return price.toFixed(1);
  } else if (price >= 100 && price < 1000) {
    return price.toFixed(0);
  } else if (price >= 1000 && price < 10000) {
    return (price / 1000).toFixed(1) + 'k';
  }
};

export const formatPriceUpToTrillion = (price, precision = 2) => {
  try {
    const priceNumber = Number(price);
    const map = [
      { suffix: 'Q', threshold: 1000000000000000000 },
      { suffix: 'q', threshold: 1000000000000000 },
      { suffix: 'T', threshold: 1000000000000 },
      { suffix: 'B', threshold: 1000000000 },
      { suffix: 'M', threshold: 1000000 },
      { suffix: 'K', threshold: 1000 },
      { suffix: '', threshold: 1 },
    ];

    const found = map.find(x => Math.abs(priceNumber) >= x.threshold);
    if (found) {
      const formatted = (priceNumber / found.threshold).toFixed(precision) + found.suffix;
      return formatted;
    }

    return priceNumber.toFixed(precision);
  } catch (ex) {
    LogCustomError('formatPriceUpToTrillion', ex.code, ex.message, ex.stack, [{ tag: 'price', value: price }]);
    return price;
  }
};

export const getMaxSocialLinksForUI = collectible => {
  let socialLinksData = [];
  let availableSocialLinks = [];
  //console.log('collectible', collectible);
  const isMobileWeb = Platform.OS == 'web' && (Device.osName === 'Android' || Device.osName === 'iOS');
  try {
    socialLinksData.push({
      type: SocialLinkType.DISCORD,
      url: collectible?.socialLinks?.discord,
      appUrl: isMobileWeb
        ? collectible?.socialLinks?.discord?.replace('https://', 'discord://')
        : collectible?.socialLinks?.discord,
      intentUrl: collectible?.socialLinks?.discord,
      appStoreUrl: DISCORD_APPSTORE_URL,
    });
    socialLinksData.push({
      type: SocialLinkType.TELEGRAM,
      url: collectible?.socialLinks?.telegram,
      appUrl: isMobileWeb
        ? collectible?.socialLinks?.telegram?.replace('https://', 'telegram://')
        : collectible?.socialLinks?.telegram,
      intentUrl: collectible?.socialLinks?.telegram,
      appStoreUrl: TELEGRAM_APPSTORE_URL,
    });
    socialLinksData.push({
      type: SocialLinkType.FACEBOOK,
      url: collectible?.socialLinks?.facebook,
      appUrl: isMobileWeb
        ? collectible?.socialLinks?.facebook?.replace('https://', 'facebook://')
        : collectible?.socialLinks?.facebook,
      intentUrl: collectible?.socialLinks?.facebook,
      appStoreUrl: FACEBOOK_APPSTORE_URL,
    });
    socialLinksData.push({
      type: SocialLinkType.INSTAGRAM,
      url: collectible?.socialLinks?.instagram,
      appUrl: isMobileWeb
        ? collectible?.socialLinks?.instagram?.replace('https://', 'instagram://')
        : collectible?.socialLinks?.instagram,
      intentUrl: collectible?.socialLinks?.instagram,
      appStoreUrl: INSTAGRAM_APPSTORE_URL,
    });
    socialLinksData.push({
      type: SocialLinkType.TWITTER,
      url: collectible?.socialLinks?.twitter,
      appUrl: isMobileWeb
        ? collectible?.socialLinks?.twitter?.replace('https://', 'twitter://')
        : collectible?.socialLinks?.twitter,
      intentUrl: collectible?.socialLinks?.twitter,
      appStoreUrl: TWITTER_APPSTORE_URL,
    });
    socialLinksData.push({
      type: SocialLinkType.TIKTOK,
      url: collectible?.socialLinks?.tiktok,
      appUrl: isMobileWeb
        ? collectible?.socialLinks?.tiktok?.replace('https://', 'tiktok://')
        : collectible?.socialLinks?.tiktok,
      intentUrl: collectible?.socialLinks?.tiktok,
      appStoreUrl: TIKTOK_APPSTORE_URL,
    });
    availableSocialLinks = socialLinksData.filter(s => s.url !== null && s.url !== undefined && s.url !== '');
    // if(availableSocialLinks && availableSocialLinks.length > 3)
    // {
    //   //We need to display maximum 3 social links
    //   availableSocialLinks = availableSocialLinks.slice(0, 3);
    // }
  } catch (ex) {
    LogToLoot8Console('Error in getMaxSocialLinksForUI', ex);
  }
  return availableSocialLinks;
};

export const getDiscordSettings = link => {
  if (Platform.OS == 'web' && (Device.osName === 'Android' || Device.osName === 'iOS')) {
    return {
      appUrl: link.replace('https://', 'discord://'),
      intentUrl: link,
      appStoreUrl: DISCORD_APPSTORE_URL,
    };
  }
  return {
    appUrl: link,
  };
};

export const getTelegramSettings = link => {
  if (Platform.OS == 'web' && (Device.osName === 'Android' || Device.osName === 'iOS')) {
    return {
      appUrl: link.replace('https://', 'telegram://'),
      intentUrl: link,
      appStoreUrl: TELEGRAM_APPSTORE_URL,
    };
  }
  return {
    appUrl: link,
  };
};

export const openAppOrAppstore = async (
  httpsUrl: string,
  iOSappURL: string,
  androidAppURL: string,
  appStoreUrl: string,
) => {
  try {
    if (Platform.OS == 'web') {
      if (Device.osName == 'Android') {
        //TODO:
        //Android handles redirecting to playstore when app is not installed with below "intent" syntax
        Linking.openURL(httpsUrl);
      } else if (Device.osName == 'iOS') {
        const win: Window = window;
        if (win) {
          let focusCount = 0;
          let hasFocus;

          win.location = iOSappURL;
          setTimeout(() => {
            hasFocus = document.hasFocus(); //check if document focus was shifted by redirecting to actual app through above line no 45
          }, 2000);

          setTimeout(() => {
            if (hasFocus && focusCount <= 2) {
              win.location = appStoreUrl;
            }
          }, 2500);

          window.onfocus = function () {
            focusCount++; //Keep focus count to check if user has came back to browser by switching between apps
          };
        }
      } else {
        // desktop app
        Linking.openURL(httpsUrl);
      }
    } else {
      Linking.openURL(httpsUrl);
    }
  } catch (error) {
    //In case any error, open https url
    Linking.openURL(httpsUrl);
  }
};

export const convertValue = (value, notation) => {
  if (value) {
    return Number(value) / notation;
  } else {
    return 0;
  }
};

export const identifySocialWebsiteFromURL = url => {
  if (url?.indexOf(SOCIAL_WEBSITE.FACEBOOK) > -1) {
    return SOCIAL_WEBSITE.FACEBOOK;
  } else if (url?.indexOf(SOCIAL_WEBSITE.INSTAGRAM) > -1) {
    return SOCIAL_WEBSITE.INSTAGRAM;
  } else if (url?.indexOf(SOCIAL_WEBSITE.TWITTER) > -1) {
    return SOCIAL_WEBSITE.TWITTER;
  } else if (url?.indexOf(SOCIAL_WEBSITE.TIKTOK) > -1) {
    return SOCIAL_WEBSITE.TIKTOK;
  } else {
    return SOCIAL_WEBSITE.NONE;
  }
};

export const getTokenDecimals = async (token, abi, provider) => {
  try {
    const tokenFactory = new ethers.Contract(token, abi, provider);
    const tokenDecimal = await tokenFactory?.decimals();
    return tokenDecimal ?? 0;
  } catch (e) {
    return 0;
  }
};
export const getLoot8Tokens = async address => {
  try {
    const chainId = getNetwork();
    const walletToken = addresses[chainId].Loot8Token;
    const provider = getStaticProvider();
    const walletBalance = Loot8Token__factory.connect(walletToken, provider);
    const balance = Number(await walletBalance.balanceOf(address)) / Math.pow(10, 18);
    return balance ?? 0;
  } catch (e) {
    return 0;
  }
};
