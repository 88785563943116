import React, { useEffect, useState } from 'react';
import { Camera } from 'expo-camera';
import {
  ImageBackground,
  Platform,
  StyleSheet,
  View,
  Text,
} from 'react-native';
import { defaultTheme } from '../themes/loot8';
import { BarCodeScanner } from 'expo-barcode-scanner';
import HOCContainer from '../components/HOCContainer';
import GradientButton from '../components/GradientButton';
import qrCodePlaceholder from '../assets/images/qrCodePlaceholder.png';
import { wait } from '../helpers/ipfs';
import AppLoaderComponent from '../components/Loader';
import ModalComponent from '../components/Modal';
import styles from '../styles';
import { Button } from 'react-native-paper';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import {
  GetRedeemTicketMessage,
  GetRedeemTicketRevertReason,
} from '../helpers/MsgHelper';
import { IMessageMetaData } from '../slices/interfaces';
import { SendMetaTX } from '../slices/AppSlice';
import { TicketRevertReason } from '../enums/tickets.enum';
import { LogCustomError } from '../helpers/AppLogger';
import useActiveDimensions from '../hooks/useActiveDimensions';

const TicketScanScreen = ({ route, navigation }) => {
  const eventData = route.params?.item || {};

  const { networkId, staticProvider, wallet, address } = useWeb3AuthContext();

  const dispatch = useDispatch<AppDispatch>();

  const [permission, requestCameraPermission] = Camera.useCameraPermissions();
  const [permissionResponse, requestBarcodePermission] =
    BarCodeScanner.usePermissions();
  const { height: activeHeight } = useActiveDimensions();
  const [initialLoading, setInitialLoading] = useState(true);

  const [scanned, setScanned] = useState(false);
  const [cameraReady, setCameraReady] = useState(false);
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [barcodeError, setBarcodeError] = useState('');
  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [redeeming, setRedeeming] = useState(false);

  const rescan = () => {
    setScanned(false);
  };

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'web') {
        if (!permission && !permission?.granted) {
          requestCameraPermission();
        } else {
          setScanned(false);
        }
      } else {
        const { status } = await BarCodeScanner.getPermissionsAsync();
        if (status !== 'granted') {
          requestBarcodePermission();
        } else {
          setScanned(false);
        }
      }
      setCameraReady(Platform.OS === 'ios' || Platform.OS === 'android');
    })();
  }, []);

  useEffect(() => {
    Platform.OS === 'web'
      ? setPermissionGranted(permission?.granted)
      : setPermissionGranted(permissionResponse?.granted);
  }, [permission, permissionResponse]);

  useEffect(() => {
    async function delay() {
      setInitialLoading(true);
      await wait(1000);
      setInitialLoading(false);
    }

    delay();
  }, []);

  const validateAndParseTicketData = (data: string) => {
    try {
      let parsed = JSON.parse(data);

      if (
        parsed &&
        parsed.ticketAddress &&
        parsed.ticketId &&
        parsed.userAddress &&
        parsed.timestamp &&
        parsed.signature
      ) {
        return parsed;
      }

      return false;
    } catch {
      return false;
    }
  };

  const handleBarCodeScanned = async ({ data }) => {
    let scannedData = data;

    try {
      setScanned(true);
      const metadata = validateAndParseTicketData(scannedData);

      //? If not a Ticket QR, reject it.
      if (!metadata) {
        setBarcodeError('The QR code is Not-supported/Invalid!');
        return;
      }

      //? Initialize ticket redemption
      setRedeeming(true);

      const redeemTicketTxData = GetRedeemTicketMessage(
        metadata.ticketAddress,
        metadata.ticketId,
        metadata.userAddress,
        metadata.timestamp,
        metadata.signature,
      );

      let msg: IMessageMetaData = {
        to: eventData.event,
        wallet: wallet,
        data: redeemTicketTxData,
        networkID: networkId,
        provider: staticProvider,
      };

      const res = await dispatch(SendMetaTX(msg));

      if (res.payload.eventLogs && res.payload.eventLogs.length > 0) {
        //* Transaction was successful
        setRedeemSuccess(true);
      } else {
        //* Transaction was reverted for some reason
        const reason = await GetRedeemTicketRevertReason({
          staticProvider,
          address,
          event: eventData.event,
          data: redeemTicketTxData,
        });

        switch (reason) {
          case TicketRevertReason.INVALID_REDEMPTION_SIGNATURE: {
            return setBarcodeError(
              'The QR code has been modified or is not longer valid!',
            );
          }
          case TicketRevertReason.INVALID_TICKET: {
            return setBarcodeError(
              'The scanned ticket is not for current event!',
            );
          }
          case TicketRevertReason.UNAUTHORIZED: {
            return setBarcodeError(
              'You are not whitelisted as a Gatekeeper for this event!',
            );
          }
          case TicketRevertReason.NOT_OWNER_OF_TICKET: {
            return setBarcodeError('User does not own this Ticket!');
          }
          case TicketRevertReason.TICKET_ALREADY_REDEEMED: {
            return setBarcodeError('The ticket has already been redeemed!');
          }
          case TicketRevertReason.INVALID: {
            return setBarcodeError(
              'The QR code has expired, request user to refresh and attempt redemption again!',
            );
          }
          case TicketRevertReason.GENERIC: {
            return setBarcodeError(
              'Ticket redemption failed, please try again later',
            );
          }
          default: {
            return setBarcodeError(
              'Ticket redemption failed, please try again later',
            );
          }
        }
      }
    } catch (error) {
      setBarcodeError('Ticket redemption failed, please try again later');
      LogCustomError(
        '~ handleBarcodeScanned-redeemTicket-' + eventData.event,
        error?.method,
        error?.reason,
        ' ',
      );
    } finally {
      setRedeeming(false);
    }
  };

  if (initialLoading) {
    return <AppLoaderComponent deemBg={false} />;
  }

  if (!permission && Platform.OS === 'web') {
    // Camera permissions are still loading.
    return <View />;
  }

  return (
    <View
      style={{
        flex: 1,
        borderRadius: defaultTheme.CONTENT_RADIUS,
        overflow: 'hidden',
      }}>
      <View style={localStyles.container}>
        {permissionGranted ? (
          <>
            {Platform.OS === 'ios' || Platform.OS === 'android' ? (
              <BarCodeScanner
                type={BarCodeScanner.Constants.Type.back}
                onBarCodeScanned={
                  scanned || redeeming ? undefined : handleBarCodeScanned
                }
                style={[StyleSheet.absoluteFillObject, { flex: 1 }]}
              />
            ) : (
              <Camera
                type={BarCodeScanner.Constants.Type.back}
                onBarCodeScanned={
                  scanned || redeeming ? undefined : handleBarCodeScanned
                }
                style={StyleSheet.absoluteFillObject}
                barCodeScannerSettings={{
                  barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                }}
                onCameraReady={() => setCameraReady(true)}
              />
            )}
            {cameraReady && (
              <>
                <ImageBackground
                  resizeMode="cover"
                  source={qrCodePlaceholder}
                  style={localStyles.contentContainer}>
                  {/* uncomment this line for testing in emulator */}
                  {/* <Button onPress={() => handleBarCodeScanned({type: null, data: "https://ipfs.io/ipfs/QmaKt43urGxNrQK65zwyZvyYskvKncuaQFTXdDipQVTW8Z"}) } >Hit URL</Button> */}
                  {scanned && (
                    <View
                      style={{
                        position: 'absolute',
                        right: 0,
                        left: 0,
                        bottom: `${
                          Platform.OS === 'ios'
                            ? '20%'
                            : activeHeight > 665
                            ? '12%'
                            : activeHeight < 540
                            ? '20%'
                            : '15%'
                        }`,
                      }}>
                      <GradientButton
                        buttonLabel={'Tap to Scan Again'}
                        onPress={rescan}
                        loading={redeeming}
                        disabled={redeeming}
                      />
                    </View>
                  )}
                </ImageBackground>
              </>
            )}
          </>
        ) : (
          <>
            <View style={localStyles.contentContainer}>
              <GradientButton
                buttonLabel={'Allow access to the camera'}
                loading={redeeming}
                disabled={redeeming}
                onPress={() => {
                  Platform.OS === 'web'
                    ? requestCameraPermission
                    : requestBarcodePermission;
                }}
              />
            </View>
          </>
        )}
      </View>

      {redeemSuccess && (
        <ModalComponent
          needCloseButton={false}
          dismissable={false}
          enableHeader={false}
          onDismiss={() => setRedeemSuccess(false)}
          showModal={redeemSuccess}>
          <View style={{ padding: 30, paddingVertical: 0 }}>
            <Text
              style={[
                styles.confirmModalText,
                {
                  paddingVertical: 3,
                  marginBottom: 15,
                  fontSize: defaultTheme.FONT_SIZE_XSMALL,
                  lineHeight: 30,
                },
              ]}>
              Success!
            </Text>

            <Text style={styles.modalTextStyle}>
              The Ticket was redeemed successfully!
            </Text>
            <View
              style={[
                styles.modalButtonContainer,
                { justifyContent: 'center' },
              ]}>
              <Button
                onPress={() => setRedeemSuccess(false)}
                style={[
                  styles.modalYesButtonStyle,
                  {
                    borderRadius: defaultTheme.BUTTON_RADIUS,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    marginTop: 25,
                  },
                ]}
                labelStyle={styles.modalTextStyle}>
                Continue
              </Button>
            </View>
          </View>
        </ModalComponent>
      )}

      {barcodeError.length > 0 && (
        <ModalComponent
          needCloseButton={true}
          dismissable={true}
          enableHeader={false}
          onDismiss={() => setBarcodeError('')}
          showModal={barcodeError.length > 0}>
          <View style={{ padding: 30, paddingVertical: 0 }}>
            <Text
              style={[
                styles.confirmModalText,
                {
                  paddingVertical: 3,
                  marginBottom: 15,
                  fontSize: defaultTheme.FONT_SIZE_XSMALL,
                  lineHeight: 30,
                },
              ]}>
              Failed!
            </Text>

            <Text style={styles.modalTextStyle}>{barcodeError}</Text>
            <View
              style={[
                styles.modalButtonContainer,
                { justifyContent: 'center' },
              ]}>
              <Button
                onPress={() => setBarcodeError('')}
                style={[
                  styles.modalYesButtonStyle,
                  {
                    borderRadius: defaultTheme.BUTTON_RADIUS,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'rgba(255, 255, 255, 0.5)',
                    marginTop: 25,
                  },
                ]}
                labelStyle={styles.modalTextStyle}>
                Okay
              </Button>
            </View>
          </View>
        </ModalComponent>
      )}
    </View>
  );
};

export default HOCContainer({
  OriginalComponent: TicketScanScreen,
  withBottomTabs: false,
  isScrollEnabled: false,
  title: 'Ticket Scan',
});

const localStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    justifyContent: 'center',
    alignContent: 'center',
    position: 'relative',
  },
});
