export enum ScreenName {
  HOME_TAB = 'HomeTab',
  HOME_SCREEN = 'HomeScreen',
  BARCODE_TAB = 'BarcodeTab',
  CATALOG = 'Catalog',
  CATALOG_TAB = 'CatalogTab',
  CATALOG_LIST = 'CatalogList',
  CATALOG_DETAILS = 'CatalogDetails',
  FRIENDS_TAB = 'FriendsTab',
  OFFERS_TAB = 'OffersTab',
  OFFERS_SCREEN = 'Offers',
  PASSPORT_DETAIL = 'PassportDetail',
  WALLET_TAB = 'WalletTab',
  BUY_TOKEN = 'BuyToken',
  TOKEN_PACKAGES = 'TokenPackages',
  WALLET = 'Wallet',
  PASSPORT_BUY_SELL_CONFIRMATION = 'PassportBuySellConfirmation',
  PREMIUMCHAT_SUB_UNSUB = 'ChatroomSubscribe',
  EXPASS_PRIV_MSG_SUB_UNSUB = 'PrivateMessageSubUnsub',
  MARKET_PLACE = 'MarketPlaceScreen',
  MARKET_PLACE_BUY_COLLECTIBLE = 'MarketPlaceBuyCollectible',
  MARKET_PLACE_COLLECTIBLE_DETAIL = 'MPCollectibleDetail',
  LOGOUT_SCREEN = 'LogOutScreen',
  PROFILE_SCREEN = 'Profile',
  ORDERS_SCREEN = 'Orders',
  SUPPORT_SCREEN = 'Support',
  SCAN_QR = 'ScanQR',
  PRIVACY_NOTICE = 'PrivacyNotice',
  TERMS_OF_SERVICES = 'TermsOfService',
  MORE_TAB = 'MoreTab',
  SUCCESS_SCREEN = 'SuccessScreen',
  DEACTIVATE_USER_SCREEN = 'DeactivateUserScreen',
  TICKET_SCAN = 'TicketScan',
  WHITELISTED_EVENTS = 'WhitelistedEvents',
}

export enum CustomDeviceEvents {
  HomeTabPressedAgain = 'HomeTabPressedAgain',
  ExPassPriceUpdated = 'ExPassPriceUpdated',
}

export enum ActionSheetName {
  SUBSCRIBE_PASSPORT_SHEET = 'SubscribePassportSheet',
  PASSPORT_OPTIONS_SHEET = 'PassportOptionsSheet',
}
