import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import AppSelectionScreen from '../screens/AppSelectionScreen';

const Stack = createStackNavigator();

function AppSelectionNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="AppSelectionScreen"
      screenOptions={{ headerShown: false }}>
      <Stack.Screen name="AppSelectionScreen" component={AppSelectionScreen} />
    </Stack.Navigator>
  );
}

export default AppSelectionNavigator;
