import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    height: 166,
    maxWidth: '48%',
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    // alignItems: 'center',
    justifyContent: 'space-between',
    shadowColor: '#00000040',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
    elevation: 15,
  },
  right: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 8,
  },
  title: {
    textAlign: 'center',
    textTransform: 'capitalize',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  subtitle: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textTransform: 'capitalize',
  },
  description: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXXSMALL + 2,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textTransform: 'capitalize',
    marginTop: 7.5,
  },
});
