import { Wallet } from 'ethers';
import { IUser } from './IUser.interface';

export interface IMessageRequest extends IMessage {
  sender?: string;
  senderTimestamp?: number;
  signature?: string;
}

export interface IMessage {
  _type: string;
  feed: string;
  parent?: string;
  data: IMessageData;
}

export interface IMessageAttachment {
  readonly name: string;
  readonly uri: string;
}

export interface IMessageData {
  _type: MessageType;
  content?: IDataType;
  attachments?: IMessageAttachment;
}

export interface IDataType {
  text: string;
  decryptedText?: string;
}

export enum MessageType {
  text = 'text',
  like = 'like',
  private = 'private',
  quote = 'quote',
  reshare = 'reshare',
}

export enum DirectionType {
  earlier = 'earlier',
  later = 'later',
}

export interface IMessageResponse {
  data: IMessageRequest;
  timestamp: number;
  hash: string;
}

export interface IMessageListResponse {
  messages: IMessageList[];
  error?: IMessageResponseError;
}

export interface IMessageResponseError {
  code: number;
  message: string;
}

export interface IMessageBaseList {
  hash: string;
  messageId: string;
  timestamp: number;
}

export interface IMessageList extends IMessageBaseList {
  data?: IMessageRequest;
  replies?: IMessageList[];
  shares?: IMessageBaseList[];
  likes: IMessageBaseList[];
  isRead?: boolean;
  user: IUser;
  isSenderFriend: boolean;
  isMutualFriend: boolean;
  messageURLs: any[];
}

export interface IDeleteMessageRequest {
  feed: string;
  messageId: string;
  sender: string;
  senderTimestamp: number;
  signature: string;
}

export interface IMessageNotificationEvent {
  event: MessageNotificationType;
  signature: string;
  timestamp: number;
  data: IMessageNotificationDataType;
}

export enum MessageNotificationType {
  msgPosted = 'msg-posted',
  msgDeleted = 'msg-deleted',
}

export interface IMessageNotificationDataType {
  feed: string;
  feedId: string;
  hash: string;
  messageId: string;
  sender: string;
  timestamp: number;
  parent: string;
  _type: MessageType;
  feedType: string;
}

export interface INativeNotificationObject {
  receiver: string;
  title: string;
  body: string;
  data: any;
}
