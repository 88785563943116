import { gql } from 'urql';

export const marketplaceListingsDataQuery = gql`
  query ($page: Int, $pageSize: Int, $collectionType: String!, $order: MarketplaceListingOrdering!, $name: String) {
    marketplaceListings(page: $page, pageSize: $pageSize, collectionType: $collectionType, order: $order, name: $name) {
      records {
        listingId
        collection
        tokenId
        price
        listingType
        chainId
        blockNumber
        paymentToken
        price
        chainId
        seller
        passport
        sellerShare
        royaltyRecipients
        amounts
        marketplaceFees
      }
      count
    }
  }
`;
