import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { RootState } from '../store';
import { setAll } from './helpers';
import {
  DirectionType,
  IMessageList,
  IMessageNotificationEvent,
  MessageNotificationType,
  MessageType,
} from '../interfaces/IMessages';
import {
  IPassportMessageAsyncThunk,
  IPassportMessageReplyAsyncThunk,
} from './interfaces';
import {
  readMessage,
  postMessageForPassport,
  likeMessageForPassport,
  deleteMessage,
  getCachedDataByMessageId,
  getCollectionFeedSettings,
  socialMessageDir,
} from '../helpers/Messages';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { subscribeToIPFSPubSub, wait } from '../helpers/ipfs';
import { getData, storeData } from '../helpers/AppStorage';
import {
  APP_STORAGE_PASSPORT_LIST,
  LOOT8_FEED,
  NetworkId,
  PASSPORT_MESSAGES,
  PASSPORT_MESSAGES_LAST_EXECUTION_TIME,
  PASSPORT_MESSAGES_LAST_FETCH,
  PASSPORT_MESSAGES_LAST_READ,
  ZERO_ADDRESS,
  getAppConfiguration,
} from '../appconstants';
import { getUserAvatar, IsUserExists } from './AppUserSlice';
import { IFriends, getFriendsInitialLoadPromise } from './friendsSlice';
import { LogCustomError } from '../helpers/AppLogger';
import { ethers } from 'ethers';
import { Buffer } from 'buffer';
import PassportMessagesStorage from '../helpers/MessagesStorage';
import { Platform } from 'react-native';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { detectURL } from '../helpers/Gadgets';
import { getSyncedTime } from '../helpers/DateHelper';

export interface IMessageSliceData {
  readonly passport?: string;
  readonly messages: IMessageList[];
  readonly lastReadTimestamp: number;
  readonly lastFetchTimestamp: number;
  readonly messagesLoading: boolean;
  readonly activeUserAction: boolean;
  readonly noSocialMessages: boolean;
  readonly initialMessageLoading: boolean;
  readonly replyPosted: boolean;
}

const initialState: IMessageSliceData = {
  passport: null,
  messages: [],
  lastReadTimestamp: null,
  lastFetchTimestamp: null,
  messagesLoading: false,
  activeUserAction: false,
  noSocialMessages: false,
  initialMessageLoading: false,
  replyPosted: false,
};

let subscription;

export const checkUserExists = async ({
  networkID,
  provider,
  allUsersData,
  userAddress,
}) => {
  let userData = allUsersData?.find(
    x => x.wallet?.toLowerCase() === userAddress?.toLowerCase(),
  );

  if (!userData || userData?.wallet === ZERO_ADDRESS) {
    let { userAttributes } = await IsUserExists({
      networkID,
      provider,
      address: userAddress,
    });
    userData = userAttributes;
  }
  return userData;
};

const getUserDetail = async ({
  networkID,
  provider,
  allUsersData,
  userAddress,
}) => {
  let userDetails = {};
  let userData = await checkUserExists({
    networkID,
    provider,
    allUsersData,
    userAddress,
  });
  if (userData) {
    let userStoredAvatarUri = await getUserAvatar(userData.avatarURI ?? '');
    userDetails = {
      name: userData.name,
      wallet: userData.wallet,
      id: +userData.id,
      avatarURI: userStoredAvatarUri,
      dataURI: userData?.dataURI,
    };
  }

  return userDetails;
};

export const updateNameAvatarAllMessages = createAsyncThunk(
  'passportMessage/updateNameAvatarAllMessages',
  async (
    {
      currentUserAddress,
      userName,
      avatarURI,
    }: { currentUserAddress; userName; avatarURI },
    { dispatch, getState },
  ): Promise<any> => {
    let rootState = getState() as RootState;

    let passportList = await getData(APP_STORAGE_PASSPORT_LIST);
    if (passportList && passportList.length > 0) {
      for (let j = 0; j < passportList.length; j++) {
        try {
          //for selected passport, update state as well to reflect change immidiately
          if (
            rootState.PassportMessage.passport &&
            passportList[j] === rootState.PassportMessage.passport
          ) {
            await dispatch(
              updateNameAvatarForSender({
                sender: currentUserAddress,
                name: userName,
                avatarURI: avatarURI,
              }),
            );
          } else {
            let passportMessages = PassportMessagesStorage.getMessages(
              PASSPORT_MESSAGES(passportList[j]),
            );
            if (passportMessages && passportMessages.length > 0) {
              passportMessages = passportMessages.map(m => {
                if (m.user && m.user.name && m.user.avatarURI) {
                  if (m.data?.sender === currentUserAddress) {
                    m.user.name = userName;
                    m.user.avatarURI = avatarURI;
                  }
                  if (m.replies && m.replies.length > 0) {
                    m.replies.map(reply => {
                      if (reply.data?.sender === currentUserAddress) {
                        reply.user.name = userName;
                        reply.user.avatarURI = avatarURI;
                      }
                    });
                  }
                }
                return m;
              });
              PassportMessagesStorage.storeMessages(
                PASSPORT_MESSAGES(passportList[j]),
                passportMessages,
              );
            }
          }
        } catch (e) {
          LogToLoot8Console('error while updating user name or avatar');
        }
      }
    }
  },
);

export const getSocialMediaAccess = async (
  collectionAddress: string,
  chainId: NetworkId,
) => {
  const feed = collectionAddress + ':' + chainId.toString();
  const response = await getCollectionFeedSettings(feed);
  if (response.status === 200) {
    const responseData = await response.json();
    if (responseData.messagingDisabled !== undefined) {
      return !responseData.messagingDisabled;
    }
  } else {
    LogCustomError(
      'getSocialMediaAccess',
      response?.status?.toString(),
      response?.statusText,
      null,
    );
    if (response.status === 500) {
      const res = await response.json();
      if (res && res.error) {
        LogToLoot8Console(res.error);
      }
    }
  }
  return true;
};

export const readMessages = createAsyncThunk(
  'passportMessage/readMessages',
  async (
    {
      networkID,
      provider,
      address /* passport address */,
      wallet,
      chainId,
    }: IPassportMessageAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    await dispatch(setPassportAddress(address));
    const currentExecTime = await AsyncStorage.getItem(
      PASSPORT_MESSAGES_LAST_EXECUTION_TIME(address),
    );
    if (currentExecTime) {
      if (getSyncedTime() - Number(currentExecTime) >= 300000) {
        await dispatch(setLastFetchTimestamp(null));
        await dispatch(setPassportMessages([]));
        PassportMessagesStorage.storeMessages(PASSPORT_MESSAGES(address), []);
        await wait(500);
      }
    }

    //Read current fetch timestamp from localstorage if available and store in state, to read further from it.
    await dispatch(
      setLastFetchTimestamp(
        (await getData(PASSPORT_MESSAGES_LAST_FETCH(address))) || null,
      ),
    );
    await dispatch(
      setPassportMessages(
        PassportMessagesStorage.getMessages(PASSPORT_MESSAGES(address)) || [],
      ),
    );
    const lastReadtimeForUser = await getData(
      PASSPORT_MESSAGES_LAST_READ(address, wallet.address),
    );
    await dispatch(
      setLastReadTimestamp({
        lastReadTimestamp: lastReadtimeForUser || null,
        userAddress: wallet.address,
      }),
    );

    let rootState = getState() as RootState;
    //const isLastReadAvailable = rootState.PassportMessage.lastReadTimestamp ? true : false;

    const existingLocalMessages = rootState.PassportMessage.messages;

    if (
      existingLocalMessages &&
      existingLocalMessages.length == 0 &&
      rootState.PassportMessage.lastFetchTimestamp
    ) {
      //case where state and local messages are removed without timestamp being reset, so do a fresh start.
      await dispatch(setLastFetchTimestamp(null));
      rootState = getState() as RootState;
    }

    const fetchTime =
      rootState.PassportMessage.lastFetchTimestamp || getSyncedTime();
    const readTime = rootState.PassportMessage.lastReadTimestamp;

    let direction = DirectionType.later;
    if (!rootState.PassportMessage.lastFetchTimestamp) {
      direction = DirectionType.earlier;
      await dispatch(setinitialMessageLoading(true));
    }

    let loadData = true;
    const appConfig = await getAppConfiguration();
    if (
      (Platform.OS == 'ios' && appConfig?.ios.cacheSocialMessages) ||
      (Platform.OS == 'android' && appConfig?.android.cacheSocialMessages)
    ) {
      loadData = false;
    }
    //LogToLoot8Console("messageslice/reading", address);
    let response = await readMessage(
      address,
      chainId,
      fetchTime,
      direction,
      socialMessageDir,
      null,
      10,
      loadData,
    );
    //LogToLoot8Console("messageslice/read", address, response?.messages?.length);

    //loot8-951: wait to load initial friends data to be able to show proper friends options in messages.
    try {
      await getFriendsInitialLoadPromise();
      rootState = getState() as RootState;
    } catch (ex) {
      LogToLoot8Console('initial friends loading promise error');
      LogToLoot8Console(ex);
    }

    if (response && response.messages && response.messages.length > 0) {
      await processReadResponse(
        dispatch,
        rootState,
        getState,
        address,
        response,
        networkID,
        provider,
        readTime,
        loadData,
      );

      while (response.messages.length > 0) {
        //This is await call as we need to wait to see if there are messages less than 10 and reload further?
        //Be carefull on changing this line of code.
        rootState = getState() as RootState;
        let timeStamp;
        if (direction === DirectionType.earlier) {
          timeStamp = rootState.PassportMessage.messages
            .filter(p => true)
            .sort((a, b) => a?.timestamp - b?.timestamp)[0]?.timestamp;
        } else {
          const messageItem = rootState.PassportMessage.messages
            .filter(p => true)
            .sort((a, b) => b?.timestamp - a?.timestamp)[0];
          timeStamp = messageItem?.timestamp;

          const resMessageItem = response?.messages
            .filter(p => true)
            .sort((a, b) => b?.timestamp - a?.timestamp)[0];

          if (
            messageItem &&
            resMessageItem &&
            messageItem.messageId?.toLocaleLowerCase() ==
              resMessageItem.messageId?.toLocaleLowerCase()
          ) {
            timeStamp = timeStamp + 1;
          }
        }
        //LogToLoot8Console("messageslice/reading-in-while", address);
        response = await readMessage(
          address,
          chainId,
          timeStamp,
          direction,
          socialMessageDir,
        );
        //LogToLoot8Console("messageslice/read-in-while", address, response?.messages?.length);

        // Loot8-1503: In case user clicks on 'Mark all as Read' while messages still loading thourgh this loop, need to fetch latest read timestamp from state
        let latestRootState = getState() as RootState;
        let latestReadTime = latestRootState.PassportMessage.lastReadTimestamp;
        if (response && response.messages && response.messages.length > 0) {
          await processReadResponse(
            dispatch,
            rootState,
            getState,
            address,
            response,
            networkID,
            provider,
            latestReadTime,
            loadData,
          );
        }
      }
    }

    // If there are any existing messages then update replies and likes for them
    if (existingLocalMessages && existingLocalMessages.length > 0) {
      for (let j = 0; j < existingLocalMessages.length; j++) {
        const localMsg = existingLocalMessages[j];
        if ((getState() as RootState).PassportMessage.passport) {
          await dispatch(
            readRepliesAndLikesForMessage({
              networkID,
              provider,
              address,
              messageId: localMsg.messageId,
              chainId,
            }),
          );
        }
      }
    }

    if (response.messages?.length == 0 && existingLocalMessages?.length == 0) {
      if ((getState() as RootState).PassportMessage.initialMessageLoading) {
        await dispatch(setinitialMessageLoading(false));
        await dispatch(setNoSocialMessages(true));
      }
    }

    if (
      response.error &&
      (!existingLocalMessages ||
        (existingLocalMessages && existingLocalMessages.length == 0))
    ) {
      await dispatch(setNoSocialMessages(true));
    }
  },
);

export const readMessageByMessageId = createAsyncThunk(
  'passportMessage/readMessages',
  async (
    {
      networkID,
      provider,
      address /* passport address */,
      messageId,
      chainId,
    }: IPassportMessageReplyAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let response = await readMessage(
      address,
      chainId,
      getSyncedTime(),
      DirectionType.earlier,
      socialMessageDir,
      messageId,
    );
    if (response && response.messages && response.messages.length > 0) {
      const rootState = getState() as RootState;
      await processReadResponse(
        dispatch,
        rootState,
        getState,
        address,
        response,
        networkID,
        provider,
        rootState.PassportMessage.lastReadTimestamp,
        true,
      );
    }
  },
);

const processReadResponse = async (
  dispatch,
  rootState,
  getState,
  address,
  response,
  networkID,
  provider,
  readTime,
  loadData,
) => {
  if ((getState() as RootState).PassportMessage.passport) {
    let messagesTobePushed: IMessageList[] = [];
    let userDataList = {};
    for (let i = 0; i < response.messages.length; i++) {
      let message: IMessageList = response.messages[i];
      if (!loadData && !message.data) {
        message.data = await getCachedDataByMessageId(
          socialMessageDir,
          message.messageId,
        );
      }
      if (message.messageId && message.data) {
        //const messageContent: IMessageResponse = await (await getIPFSData(message.messageId))?.json();
        if (!message.data.parent) {
          //get other relevant details to show on UI
          let userData: any = userDataList[message.data.sender];
          if (!userData) {
            userData = await getUserDetail({
              networkID: networkID,
              provider: provider,
              allUsersData: rootState.AppUser.AllUsersData,
              userAddress: message.data.sender,
            });
            userDataList[message.data.sender] = userData;
          }

          const senderFriend: IFriends[] =
            userData && rootState
              ? rootState.friends.currentFriends.filter(
                  f => Number(f.id) === Number(userData.id),
                )
              : null;
          const mutualFriend: IFriends[] =
            userData && rootState
              ? rootState.friends.mutualFriends.filter(
                  f => Number(f.id) === Number(userData.id),
                )
              : null;

          let currentUserData = rootState.AppUser.UserData;
          if (
            currentUserData &&
            userData &&
            currentUserData.wallet &&
            userData.wallet
          ) {
            if (
              currentUserData.wallet?.toLowerCase() ===
              userData.wallet?.toLowerCase()
            ) {
              if (
                currentUserData.name &&
                userData.name &&
                currentUserData.name != userData.name
              ) {
                userData = { ...userData, name: currentUserData.name };
              }
              if (
                currentUserData.avatarURI &&
                userData.avatarURI &&
                currentUserData.name != userData.avatarURI
              ) {
                userData = {
                  ...userData,
                  avatarURI: currentUserData.avatarURI,
                };
              }
            }
          }
          message = {
            ...message,
            isRead: readTime
              ? message.timestamp < readTime
                ? true
                : false
              : false,
            user: userData,
            isSenderFriend:
              senderFriend && senderFriend.length > 0
                ? senderFriend[0].isFriend
                : false,
            isMutualFriend:
              mutualFriend && mutualFriend.length > 0
                ? mutualFriend[0].isMutualFriend
                : false,
          };
          if (message?.data?.data?.content?.text) {
            message = {
              ...message,
              messageURLs: detectURL(message?.data?.data?.content?.text),
            };
          }
          if (
            message?.data?.data?.attachments &&
            message?.data?.data?.attachments[0]
          ) {
            let attachmentIpfsURI = message.data.data.attachments[0]?.uri;
            if (attachmentIpfsURI && attachmentIpfsURI.includes('ipfs://')) {
              message.data.data.attachments = {
                ...message?.data?.data?.attachments[0],
                uri: attachmentIpfsURI,
              };
            }
          }
          if (message?.replies && message?.replies.length > 0) {
            for (let i = 0; i < message?.replies.length; i++) {
              let reply = message?.replies[i];
              if (!loadData && !reply.data) {
                reply.data = await getCachedDataByMessageId(
                  socialMessageDir,
                  reply.messageId,
                );
              }
              if (reply.messageId && reply.data) {
                let userData: any = userDataList[reply.data.sender];
                if (!userData) {
                  userData = await getUserDetail({
                    networkID: networkID,
                    provider: provider,
                    allUsersData: rootState.AppUser.AllUsersData,
                    userAddress: reply.data.sender,
                  });
                  userDataList[reply.data.sender] = userData;
                }
                if (
                  currentUserData &&
                  userData &&
                  currentUserData.wallet &&
                  userData.wallet
                ) {
                  if (
                    currentUserData.wallet?.toLowerCase() ===
                    userData.wallet?.toLowerCase()
                  ) {
                    if (
                      currentUserData.name &&
                      userData.name &&
                      currentUserData.name != userData.name
                    ) {
                      userData = { ...userData, name: currentUserData.name };
                    }
                    if (
                      currentUserData.avatarURI &&
                      userData.avatarURI &&
                      currentUserData.name != userData.avatarURI
                    ) {
                      userData = {
                        ...userData,
                        avatarURI: currentUserData.avatarURI,
                      };
                    }
                  }
                }
                reply.user = userData;

                const replySenderFriend: IFriends[] = userData
                  ? rootState.friends.currentFriends.filter(
                      f => Number(f.id) === Number(userData.id),
                    )
                  : null;
                reply.isSenderFriend =
                  replySenderFriend && replySenderFriend.length > 0
                    ? replySenderFriend[0].isFriend
                    : false;
                const replyMutualFriend: IFriends[] =
                  userData && rootState
                    ? rootState.friends.mutualFriends.filter(
                        f => Number(f.id) === Number(userData.id),
                      )
                    : null;
                reply.isMutualFriend =
                  replyMutualFriend && replyMutualFriend.length > 0
                    ? replyMutualFriend[0].isMutualFriend
                    : false;

                if (
                  reply?.data?.data?.attachments &&
                  reply?.data?.data?.attachments[0]
                ) {
                  let attachmentIpfsURI = reply.data.data.attachments[0]?.uri;
                  if (
                    attachmentIpfsURI &&
                    attachmentIpfsURI.includes('ipfs://')
                  ) {
                    reply.data.data.attachments = {
                      ...reply?.data?.data?.attachments[0],
                      uri: attachmentIpfsURI,
                    };
                  }
                }
              }
            }
          }
          messagesTobePushed.push(message);
        }
      }
    }
    if (
      messagesTobePushed.length > 0 &&
      rootState.PassportMessage.noSocialMessages
    ) {
      await dispatch(setNoSocialMessages(false));
    }

    await dispatch(setinitialMessageLoading(false));
    if (
      (getState() as RootState).PassportMessage.passport &&
      (getState() as RootState).PassportMessage.passport == address
    ) {
      await dispatch(pushPassportMessage(messagesTobePushed));
      const latestMessages = (getState() as RootState).PassportMessage.messages;
      //LogToLoot8Console("messageslice/storing-messages", address, latestMessages.length);
      PassportMessagesStorage.storeMessages(
        PASSPORT_MESSAGES(address),
        latestMessages,
      );
      const latestTimestamp = latestMessages
        .filter(p => p)
        .sort((a, b) => b.timestamp - a.timestamp)[0].timestamp;
      await dispatch(setLastFetchTimestamp(latestTimestamp));
    } else if (
      (getState() as RootState).PassportMessage.passport &&
      (getState() as RootState).PassportMessage.passport != address
    ) {
      PassportMessagesStorage.storeMessages(PASSPORT_MESSAGES(address), []);
    }
  }
};

export const postMessage = createAsyncThunk(
  'passportMessage/postMessage',
  async (
    {
      networkID,
      provider,
      address /* passport address */,
      wallet,
      text,
      chainId,
      attachments,
    }: IPassportMessageAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const response = await postMessageForPassport(
      address,
      chainId,
      text,
      wallet,
      null,
      attachments,
    );
    let responseError = {};

    if (response.status == 200) {
      dispatch(readMessages({ networkID, provider, address, wallet, chainId }));
    } else {
      LogCustomError(
        'postMessage',
        response?.status?.toString(),
        response?.statusText,
        null,
      );
      if (response.status === 500) {
        const res = await response.json();
        if (res && res.error) {
          responseError = res.error;
        }
      }
    }
    return {
      status: response.status,
      message: response.statusText,
      error: responseError,
    };
  },
);

export const postMessageReply = createAsyncThunk(
  'passportMessage/postMessageReply',
  async (
    {
      networkID,
      provider,
      address /* passport address */,
      wallet,
      text,
      messageId,
      chainId,
      attachments,
    }: IPassportMessageReplyAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const response = await postMessageForPassport(
      address,
      chainId,
      text,
      wallet,
      messageId,
      attachments,
    );
    let responseError = {};
    if (response.status == 200) {
      await dispatch(
        readRepliesAndLikesForMessage({
          networkID,
          provider,
          address,
          messageId,
          chainId,
        }),
      );
      if (Platform.OS === 'android') {
        dispatch(setIsReplyPosted(true)); //bug fix Loot8-643, LOOT8-829: to be able to hide reply textbox just before showing reply message on UI
      }
    } else {
      LogCustomError(
        'postMessageReply',
        response?.status?.toString(),
        response?.statusText,
        null,
      );
      if (response.status === 500) {
        const res = await response.json();
        if (res && res.error) {
          responseError = res.error;
        }
      }
    }
    return {
      status: response.status,
      message: response.statusText,
      error: responseError,
    };
  },
);

export const postMessageLike = createAsyncThunk(
  'passportMessage/postMessageLike',
  async (
    {
      networkID,
      provider,
      address /* passport address */,
      wallet,
      messageId,
      parentId = null,
      chainId,
    }: IPassportMessageReplyAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const response = await likeMessageForPassport(
      address,
      chainId,
      true,
      wallet,
      messageId,
    );
    let responseError = {};
    if (response.status == 200) {
      await dispatch(
        readRepliesAndLikesForMessage({
          networkID,
          provider,
          address,
          messageId: parentId || messageId,
          chainId,
        }),
      );
    } else {
      LogCustomError(
        'postMessageReply',
        response?.status?.toString(),
        response?.statusText,
        null,
      );
      if (response.status === 500) {
        const res = await response.json();
        if (res && res.error) {
          responseError = res.error;
        }
      }
    }
    return {
      status: response.status,
      message: response.statusText,
      data: await response.json(),
      error: responseError,
    };
  },
);

export const postMessageUnlike = createAsyncThunk(
  'passportMessage/postMessageUnlike',
  async (
    {
      networkID,
      provider,
      address /* passport address */,
      wallet,
      messageId,
      parentId,
      chainId,
    }: IPassportMessageReplyAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const response = await deleteMessage(address, chainId, wallet, messageId);
    let responseError = {};
    if (response.status == 200) {
      await dispatch(
        readRepliesAndLikesForMessage({
          networkID,
          provider,
          address,
          messageId: parentId,
          chainId,
        }),
      );
    } else {
      LogCustomError(
        'postMessageReply',
        response?.status?.toString(),
        response?.statusText,
        null,
      );
      if (response.status === 500) {
        const res = await response.json();
        if (res && res.error) {
          responseError = res.error;
        }
      }
    }
    return {
      status: response.status,
      message: response.statusText,
      error: responseError,
    };
  },
);

export const deleteUserMessage = createAsyncThunk(
  'passportMessage/deleteUserMessage',
  async (
    {
      networkID,
      provider,
      address /* passport address */,
      wallet,
      messageId,
      parentId,
      chainId,
    }: IPassportMessageReplyAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const response = await deleteMessage(address, chainId, wallet, messageId);
    if (response.status == 200) {
      if (parentId) {
        dispatch(
          readRepliesAndLikesForMessage({
            networkID,
            chainId,
            provider,
            address,
            messageId: parentId,
          }),
        );
      } else {
        await dispatch(deletePassportMessage(parentId || messageId));
        dispatch(
          readMessages({ networkID, chainId, provider, address, wallet }),
        );
      }
    }
  },
);

export const readRepliesAndLikesForMessage = createAsyncThunk(
  'passportMessage/readRepliesAndLikesForMessage',
  async (
    {
      networkID,
      provider,
      address /* passport address */,
      messageId,
      chainId,
    }: IPassportMessageReplyAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const fetchTime = getSyncedTime();
    const response = await readMessage(
      address,
      chainId,
      fetchTime,
      DirectionType.earlier,
      socialMessageDir,
      messageId,
    );
    const rootState = getState() as RootState;
    let currentUserData = rootState.AppUser.UserData;
    let userData;

    let currentMessage = rootState.PassportMessage.messages.find(
      p => p.messageId == messageId,
    );
    //If another user has update name or avatar, we need to load userdata from refreshed state.
    if (
      currentMessage &&
      currentMessage.data?.sender &&
      currentMessage.data?.sender != currentUserData.wallet
    ) {
      userData = await getUserDetail({
        networkID: networkID,
        provider: provider,
        allUsersData: rootState.AppUser.AllUsersData,
        userAddress: currentMessage.data?.sender,
      });
      currentMessage = { ...currentMessage, user: userData };
    }
    //If status of friend/mutualFriend is changed; update that in  case messages are loaded from cache
    if (currentMessage && currentMessage.user) {
      const isSenderFriend: IFriends[] = rootState
        ? rootState.friends.currentFriends.filter(
            f => Number(f.id) === Number(currentMessage.user.id),
          )
        : null;
      const isMutualFriend: IFriends[] = rootState
        ? rootState.friends.mutualFriends.filter(
            f => Number(f.id) === Number(currentMessage.user.id),
          )
        : null;
      currentMessage = {
        ...currentMessage,
        isSenderFriend:
          isSenderFriend && isSenderFriend.length > 0
            ? isSenderFriend[0].isFriend
            : false,
        isMutualFriend:
          isMutualFriend && isMutualFriend.length > 0
            ? isMutualFriend[0].isMutualFriend
            : false,
      };
    }

    if (
      response.messages &&
      response.messages.length > 0 &&
      response.messages[0].replies &&
      response.messages[0].replies.length > 0
    ) {
      for (let i = 0; i < response.messages[0].replies.length; i++) {
        let reply = response.messages[0].replies[i];
        if (reply.messageId && reply.data) {
          if (
            currentUserData &&
            reply.data?.sender &&
            reply.data?.sender === currentUserData.wallet
          ) {
            userData = { ...currentUserData }; //assign currently logged in user's data with name/avatar update if any
          } else {
            userData = await getUserDetail({
              networkID: networkID,
              provider: provider,
              allUsersData: rootState.AppUser.AllUsersData,
              userAddress: reply.data.sender,
            });
          }
          reply.user = userData;

          const replySenderFriend: IFriends[] =
            userData && rootState
              ? rootState.friends.currentFriends.filter(
                  f => Number(f.id) === Number(userData.id),
                )
              : null;
          const replyMutualFriend: IFriends[] =
            userData && rootState
              ? rootState.friends.mutualFriends.filter(
                  f => Number(f.id) === Number(userData.id),
                )
              : null;
          reply.isSenderFriend =
            replySenderFriend && replySenderFriend.length > 0
              ? replySenderFriend[0].isFriend
              : false;
          reply.isMutualFriend =
            replyMutualFriend && replyMutualFriend.length > 0
              ? replyMutualFriend[0].isMutualFriend
              : false;

          if (
            reply?.data?.data?.attachments &&
            reply?.data?.data?.attachments[0]
          ) {
            let attachmentIpfsURI = reply.data.data.attachments[0]?.uri;
            if (attachmentIpfsURI && attachmentIpfsURI.includes('ipfs://')) {
              reply.data.data.attachments = {
                ...reply?.data?.data?.attachments[0],
                uri: attachmentIpfsURI,
              };
            }
          }
        }
      }
    }

    if (currentMessage && response.messages.length == 0) {
      // Message has been delete, lets remove from store
      dispatch(deletePassportMessage(messageId));
    } else {
      // if(Platform.OS === 'android')
      // {
      //   dispatch(setIsReplyPosted(true)); //bug fix Loot8-643: to be able to hide reply textbox just before showing reply message on UI
      // }
      const messageToUpdate = {
        ...currentMessage,
        replies: response.messages[0].replies,
        likes: response.messages[0].likes || [],
        shares: response.messages[0].shares || [],
      };
      await dispatch(updatePassportMessage(messageToUpdate));
      PassportMessagesStorage.storeMessages(
        PASSPORT_MESSAGES(address),
        (getState() as RootState).PassportMessage.messages,
      );
    }
  },
);

export const subscribeToPassportMessages = createAsyncThunk(
  'passportMessage/subscribeToPassportMessages',
  async (
    {
      networkID,
      provider,
      address /* passport address */,
      wallet,
      chainId,
    }: IPassportMessageAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const rootState = getState() as RootState;

    if (rootState.PassportMessage.passport) {
      const feed = address + ':' + chainId;
      const topic: string = Buffer.from(
        LOOT8_FEED +
          ethers.utils
            .keccak256(Buffer.from(feed.toLowerCase(), 'utf-8'))
            .slice(2),
        'utf-8',
      ).toString();
      subscription = subscribeToIPFSPubSub(
        topic,
        async (response: IMessageNotificationEvent) => {
          if (
            response.data?.sender?.toLowerCase() !==
            wallet.address.toLowerCase()
          ) {
            if (response.event == MessageNotificationType.msgPosted) {
              switch (response.data._type) {
                case MessageType.text:
                  dispatch(
                    readMessageByMessageId({
                      networkID,
                      provider,
                      address,
                      messageId:
                        response.data.parent || response.data.messageId,
                      chainId,
                    }),
                  );
                  break;
                case MessageType.like:
                  //dispatch(readMessageByMessageId({ networkID, provider, address, messageId: response.data.messageId }));
                  updateLikeForMessage(
                    response.data.parent,
                    dispatch,
                    getState,
                    address,
                    {
                      hash: response.data.hash,
                      messageId: response.data.messageId,
                      timestamp: response.data.timestamp,
                    },
                  );
                  break;
                default:
                  break;
              }
            } else if (response.event == MessageNotificationType.msgDeleted) {
              switch (response.data._type) {
                case MessageType.text:
                  if (response.data.parent) {
                    dispatch(
                      readMessageByMessageId({
                        networkID,
                        provider,
                        address,
                        messageId: response.data.parent,
                        chainId,
                      }),
                    );
                  } else {
                    dispatch(deletePassportMessage(response.data.messageId));
                  }
                  break;
                case MessageType.like:
                  //dispatch(readMessageByMessageId({ networkID, provider, address, messageId: response.data.messageId }));
                  updateLikeForMessage(
                    response.data.parent,
                    dispatch,
                    getState,
                    address,
                    {
                      hash: response.data.hash,
                      messageId: response.data.messageId,
                      timestamp: response.data.timestamp,
                    },
                    true,
                  );
                  break;
                default:
                  break;
              }
            }
          }
        },
        true,
      );
    }
  },
);

const updateLikeForMessage = async (
  parent: string,
  dispatch,
  getState,
  address,
  { hash, messageId, timestamp },
  deleteMessage: boolean = false,
) => {
  const rootState = getState() as RootState;
  const currentMessage = rootState.PassportMessage.messages.find(
    p => p.messageId == parent,
  );

  let messageTobeUpdate = { ...currentMessage };
  if (deleteMessage) {
    messageTobeUpdate.likes = messageTobeUpdate?.likes.filter(
      p => p.messageId != messageId,
    );
  } else {
    if (currentMessage && currentMessage.likes) {
      messageTobeUpdate.likes = [
        ...messageTobeUpdate.likes,
        { hash, messageId, timestamp },
      ];
    } else if (currentMessage) {
      messageTobeUpdate.likes = [{ hash, messageId, timestamp }];
    }
  }

  // If current message is null then like is for reply message
  //TODO: loop throgh reply's like and update it

  if (currentMessage) {
    await dispatch(updatePassportMessage(messageTobeUpdate));
    PassportMessagesStorage.storeMessages(
      PASSPORT_MESSAGES(address),
      (getState() as RootState).PassportMessage.messages,
    );
  }
};

const PassportMessageSlice = createSlice({
  name: 'PassportMessage',
  initialState,
  reducers: {
    resetPassportMessage(state) {
      const lastExecutionTime = getSyncedTime();
      AsyncStorage.setItem(
        PASSPORT_MESSAGES_LAST_EXECUTION_TIME(state.passport),
        lastExecutionTime.toString(),
      );
      setAll(state, initialState);
      if (subscription && subscription.abort) subscription.abort();
    },
    setPassportAddress(state, action) {
      state.passport = action.payload;
    },
    setPassportMessages(state, action) {
      state.messages = action.payload;
    },
    pushPassportMessage(state, action) {
      if (Array.isArray(action.payload)) {
        const currentMessages = state.messages.filter(
          p => action.payload.findIndex(x => x.messageId == p.messageId) == -1,
        );
        state.messages = currentMessages.concat(action.payload);
      } else {
        if (
          state.messages.findIndex(p => p.hash == action.payload.hash) == -1
        ) {
          state.messages.push(action.payload);
        }
      }
    },
    deletePassportMessage(state, action) {
      if (action.payload) {
        const messagesWithoutDeleted = state.messages.filter(
          p => p.messageId != action.payload,
        );
        state.messages = messagesWithoutDeleted;
        // if there are no social messages, set noSocialMessages state to true
        if (messagesWithoutDeleted && messagesWithoutDeleted.length == 0) {
          state.noSocialMessages = true;
        }
        PassportMessagesStorage.storeMessages(
          PASSPORT_MESSAGES(state.passport),
          state.messages,
        );
      }
    },
    updatePassportMessage(state, action) {
      state.messages = state.messages.map(p => {
        if (p.messageId == action.payload.messageId) {
          return action.payload;
        }
        return { ...p };
      });
    },
    setLastReadTimestamp(state, action) {
      state.lastReadTimestamp = action.payload.lastReadTimestamp;
      if (state.passport) {
        storeData(
          PASSPORT_MESSAGES_LAST_READ(
            state.passport,
            action.payload.userAddress,
          ),
          state.lastReadTimestamp,
        );
      }
    },
    setLastFetchTimestamp(state, action) {
      state.lastFetchTimestamp = action.payload;
      if (state.passport) {
        storeData(
          PASSPORT_MESSAGES_LAST_FETCH(state.passport),
          state.lastFetchTimestamp,
        );
      }
    },
    markMessagesAsRead(state, action) {
      state.messages = state.messages.map(x => {
        if (action.payload.includes(x.messageId)) {
          x.isRead = true;
        }
        return x;
      });
      PassportMessagesStorage.storeMessages(
        PASSPORT_MESSAGES(state.passport),
        state.messages,
      );
    },
    setFriendForSender(state, action) {
      state.messages = state.messages.map(m => {
        if (m.data?.sender === action.payload.sender) {
          m.isSenderFriend = action.payload.isFriend;
          if (action.payload.isMutualFriend != undefined) {
            m.isMutualFriend = action.payload.isMutualFriend;
          }
        }
        if (m.replies && m.replies.length > 0) {
          m.replies.map(reply => {
            if (reply.data?.sender === action.payload.sender) {
              reply.isSenderFriend = action.payload.isFriend;
              if (action.payload.isMutualFriend != undefined) {
                reply.isMutualFriend = action.payload.isMutualFriend;
              }
            }
          });
        }
        return m;
      });
      PassportMessagesStorage.storeMessages(
        PASSPORT_MESSAGES(state.passport),
        state.messages,
      );
    },
    updateNameAvatarForSender(state, action) {
      state.messages = state.messages.map(m => {
        if (m.user && m.user.name && m.user.avatarURI) {
          if (m.data?.sender === action.payload.sender) {
            m.user.name = action.payload.name;
            m.user.avatarURI = action.payload.avatarURI;
          }
          if (m.replies && m.replies.length > 0) {
            m.replies.map(reply => {
              if (reply.data?.sender === action.payload.sender) {
                reply.user.name = action.payload.name;
                reply.user.avatarURI = action.payload.avatarURI;
              }
            });
          }
        }
        return m;
      });
      PassportMessagesStorage.storeMessages(
        PASSPORT_MESSAGES(state.passport),
        state.messages,
      );
    },
    setNoSocialMessages(state, action) {
      state.noSocialMessages = action.payload;
    },
    setinitialMessageLoading(state, action) {
      state.initialMessageLoading = action.payload;
    },
    setIsReplyPosted(state, action) {
      state.replyPosted = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(readMessages.pending, state => {
        state.messagesLoading = true;
      })
      .addCase(readMessages.fulfilled, state => {
        state.messagesLoading = false;
      })
      .addCase(readMessages.rejected, (state, { error }: any) => {
        state.messagesLoading = false;
        LogCustomError('readMessages', error.name, error.message, error.stack);
      })
      .addCase(postMessageLike.pending, state => {
        state.activeUserAction = true;
      })
      .addCase(postMessageLike.fulfilled, state => {
        state.activeUserAction = false;
      })
      .addCase(postMessageLike.rejected, (state, { error }: any) => {
        state.activeUserAction = false;
        LogCustomError(
          'postMessageLike',
          error.name,
          error.message,
          error.stack,
        );
      })
      .addCase(postMessageUnlike.pending, state => {
        state.activeUserAction = true;
      })
      .addCase(postMessageUnlike.fulfilled, state => {
        state.activeUserAction = false;
      })
      .addCase(postMessageUnlike.rejected, (state, { error }: any) => {
        state.activeUserAction = false;
        LogCustomError(
          'postMessageUnlike',
          error.name,
          error.message,
          error.stack,
        );
      })
      .addCase(postMessageReply.fulfilled, state => {
        state.replyPosted = false;
      })
      .addCase(postMessageReply.rejected, (state, { error }: any) => {
        state.replyPosted = false;
        LogCustomError(
          'postMessageReply',
          error.name,
          error.message,
          error.stack,
        );
      });
  },
});

export const PassportMessageSliceReducer = PassportMessageSlice.reducer;

const baseInfo = (state: RootState) => state.PassportMessage;

export const {
  resetPassportMessage,
  setPassportAddress,
  pushPassportMessage,
  updatePassportMessage,
  setLastReadTimestamp,
  setLastFetchTimestamp,
  markMessagesAsRead,
  setPassportMessages,
  setFriendForSender,
  setNoSocialMessages,
  setinitialMessageLoading,
  deletePassportMessage,
  updateNameAvatarForSender,
  setIsReplyPosted,
} = PassportMessageSlice.actions;

export const getPassportMessageState = createSelector(
  baseInfo,
  PassportMessage => PassportMessage,
);
