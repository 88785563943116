import React from 'react';
import { View, Text } from 'react-native';

import styles from '../../styles';
import ModalComponent from '../Modal';

type WarningModalProps = {
  message: string;
  show: boolean;
  onDismiss: () => void;
};

const WarningModal = ({ show, message, onDismiss }: WarningModalProps) => {
  return (
    <ModalComponent
      showModal={show}
      onDismiss={onDismiss}
      theme={'rgba(0, 0, 0, 0.80)'}
      needCloseButton
      enableHeader={false}>
      <View style={{ padding: 26 }}>
        <Text style={styles.modalTextStyle}>{message}</Text>
      </View>
    </ModalComponent>
  );
};

export default WarningModal;
