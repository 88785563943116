import React, { useEffect, useState } from 'react';
import { Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import { defaultTheme } from '../../../themes/loot8';
import styles from '../../../styles';
import { TextInput, DefaultTheme, Avatar } from 'react-native-paper';
import { useWeb3AuthContext } from '../../../hooks/web3authContext';
import {
  SigningKey,
  formatEther,
  hexlify,
  parseEther,
  toUtf8Bytes,
} from 'ethers/lib/utils';
import { ethers } from 'ethers';
import { Loot8ERC20__factory } from '../../../typechain/factories/Loot8ERC20__factory';
import { Loot8FiatOffRamp__factory } from '../../../typechain/factories/Loot8FiatOffRamp__factory';
import cryptoJS from 'crypto-js';
import { parse } from 'uuid';
import { debounce } from 'lodash';
import {
  getOffRampTransactionMessage,
  getApprovalTransactionMessage,
  getOfframpTransactionLogsData,
} from '../../../helpers/MsgHelper';
import { IMessageMetaData } from '../../../slices/interfaces';
import {
  APP_STORAGE_TX_TIMESTAMPS,
  NetworkId,
  OFFRAMP_LAMBDA_URL_DEV,
  OFFRAMP_LAMBDA_URL_PROD,
  OFFRAMP_LAMBDA_URL_STAGING,
  addresses,
  getAppConfiguration,
  isNativeChain,
} from '../../../appconstants';
import { SendMetaTX } from '../../../slices/AppSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import InfoLoader from '../../Loader/InfoLoader';
import ModalComponent from '../../Modal';
import { loadUserBalance } from '../../../slices/AppUserSlice';

// App Configuration
import { LogToLoot8Console } from '../../../helpers/Loot8ConsoleLogger';
import { getData, storeData } from '../../../helpers/AppStorage';
import HOCContainer from '../../HOCContainer';
import GradientButton from '../../GradientButton';
import { ScreenName } from '../../../enums/screen.enum';
import AnimatedTabBoxes from '../../AnimatedTabBoxes';
import { AppEnvironment } from '../../../enums/env.enum';

// App Constants

export const FailureModal = ({
  showHeader = true,
  showModal,
  setModal,
  failed,
}) => {
  return (
    <ModalComponent
      showModal={showModal}
      enableHeader={false}
      dismissable={true}
      onDismiss={setModal}
      showLoading={false}
      theme={'rgba(0, 0, 0, 0.5)'}>
      <View style={{ justifyContent: 'center', alignItems: 'center' }}>
        {failed && (
          <View
            style={{
              margin: 10,
              justifyContent: 'center',
              alignItems: 'center',
              padding: 2,
            }}>
            {showHeader && (
              <Text
                style={[
                  styles.confirmModalText,
                  { paddingVertical: 3 },
                ]}>{`Failed!`}</Text>
            )}
            <Text style={[styles.confirmModalText, { paddingVertical: 3 }]}>
              {failed}
            </Text>
          </View>
        )}
      </View>
    </ModalComponent>
  );
};
export const ConfirmModal = ({
  showModal,
  setModal,
  confirmOnPress,
  tokenAmount,
  OFFRAMP_CONTRACT_ADDRESS,
  userWallet,
}) => {
  const [platformFee, setPlatformFee] = useState('0');
  const { staticProvider, wallet } = useWeb3AuthContext();
  useEffect(() => {
    async function getPlatformFee() {
      try {
        // * User Wallet
        // ? For Approving Transactions and Signing Data
        const userWallet = new ethers.Wallet(wallet.privateKey, staticProvider);
        // * Connect with Fiat Off-ramp Contract
        const offRampContractAddress = OFFRAMP_CONTRACT_ADDRESS;
        // Not being used because of Meta TX. Here for future reference
        const offRampContract = Loot8FiatOffRamp__factory.connect(
          offRampContractAddress,
          userWallet,
        );
        if (offRampContract) {
          // 0 is for paypal
          const feeFromContract = await offRampContract.fee(0);
          feeFromContract && setPlatformFee(formatEther(feeFromContract));
        }
      } catch (err) {
        console.log('ERROR fetching cashout fee');
      }
    }
    getPlatformFee();
  }, [showModal]);

  return (
    <ModalComponent
      showModal={showModal}
      enableHeader={false}
      dismissable={false}
      onDismiss={setModal}
      showLoading={false}
      theme={'rgba(0, 0, 0, 0.5)'}
      modalBodyStyle={{ padding: 15, width: '100%' }}>
      <Text
        style={
          componentStyles.confirmModalTitle
        }>{`Confirm Cashout Request`}</Text>
      <View style={componentStyles.separater} />
      <Text style={componentStyles.confirmModalText}>
        Cashout Amount: ${tokenAmount}
      </Text>
      <Text style={componentStyles.confirmModalText}>
        Platform Fee: ${platformFee}
      </Text>
      <Text style={componentStyles.confirmModalText}>
        Receivable Amount: ${parseFloat(tokenAmount) - parseFloat(platformFee)}
      </Text>
      <View
        style={{
          marginTop: 20,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <View style={{ flex: 0.48 }}>
          <GradientButton
            onPress={setModal}
            buttonLabel={'Cancel'}
            type="secondary"
          />
        </View>
        <View style={{ flex: 0.48 }}>
          <GradientButton
            onPress={confirmOnPress}
            buttonLabel={'Confirm'}
            type="alternate"
          />
        </View>
      </View>
    </ModalComponent>
  );
};

const CashOutTokens = ({ navigation }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [getBalanceLoading, setGetbalanceLoading] = useState(false);
  const [cashoutLoading, setCashoutLoading] = useState(false);
  const [requestInProccess, setRequestInProccess] = useState(false);
  const [enableCashout, setEnableCashout] = useState(false);
  const [cashoutConfirmationModal, setCashoutConfirmationModal] =
    useState(false);
  const [cashoutFailureModal, setCashoutFailureModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    failed: '',
    success: '',
  });

  const { networkId, staticProvider, address, connected, wallet } =
    useWeb3AuthContext();

  const [active, setActive] = useState(0);
  const [email, setEmail] = useState('');
  const [tokenAmount, setTokenAmount] = useState('');
  const [showHeader, setShowHeader] = useState(true);
  const [maxLimit, setMaxLimit] = useState('0.00');
  const [minLimit, setMinLimit] = useState('0.00');

  async function fetchLimits() {
    try {
      const userWallet = new ethers.Wallet(wallet.privateKey, staticProvider);
      // * Connect with Fiat Off-ramp Contract
      const offRampContractAddress = OFFRAMP_CONTRACT_ADDRESS;
      // Not being used because of Meta TX. Here for future reference
      const offRampContract = Loot8FiatOffRamp__factory.connect(
        offRampContractAddress,
        userWallet,
      );

      let _maxLimit = await offRampContract.maxLimit();
      let _minLimit = await offRampContract.minLimit();
      setMaxLimit(formatEther(_maxLimit));
      setMinLimit(formatEther(_minLimit));
    } catch (err) {
      console.log('ERROR fetching cashout limits');
    }
  }

  const validateEmail = input => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for basic email validation
    return emailRegex.test(input);
  };

  const goToNextSlide = index => {
    //Venmo is not supported now
    return;
    // setActive(index);
  };

  // * Get User Loot8 Token Balance
  async function getBalance() {
    setGetbalanceLoading(true);
    // * User Wallet
    const userWallet = new ethers.Wallet(wallet.privateKey, staticProvider);

    const loot8TokenContractAddress = addresses[networkId]?.Loot8Token;
    const loot8TokenContract = Loot8ERC20__factory.connect(
      loot8TokenContractAddress,
      userWallet,
    );

    const userBalance = await loot8TokenContract.balanceOf(userWallet.address);
    // keeping balance in sync with drawer content
    await dispatch(
      loadUserBalance({
        networkID: networkId,
        provider: staticProvider,
        address,
      }),
    );

    setUserTokenBalance(+formatEther(userBalance));
    setGetbalanceLoading(false);
  }

  // * Contract Addresses and Server URL
  const [LOOT8_ERC20_ADDRESS, setLOOT8_ERC20_ADDRESS] = useState('');
  const [OFFRAMP_CONTRACT_ADDRESS, setOFFRAMP_CONTRACT_ADDRESS] = useState('');
  const [LAMBDA_SERVER_URL, setLAMBDA_SERVER_URL] = useState('');

  const initialize = async () => {
    try {
      const appConfig = await getAppConfiguration();

      if (appConfig && appConfig.enableCashoutForWhitelistedOnly) {
        // get whitelisted wallet
        if (appConfig && appConfig.whitelistedCashOutAddresses.length) {
          const wallet = appConfig.whitelistedCashOutAddresses.find(
            x => x.toLowerCase() === address.toLowerCase(),
          );
          if (wallet) {
            setEnableCashout(true);
          }
        }
      } else {
        setEnableCashout(true);
      }

      // Arbitrum Sepolia is Testnet: 421614
      if (
        appConfig.env == AppEnvironment.DEVELOPMENT &&
        isNativeChain(networkId)
      ) {
        // * Use Development Lambda Addresses and URL
        // Contracts
        setLOOT8_ERC20_ADDRESS(addresses[networkId].Loot8Token);
        setOFFRAMP_CONTRACT_ADDRESS(addresses[networkId].OffRamp);

        // Server Lambda
        const serverURL = OFFRAMP_LAMBDA_URL_DEV();
        setLAMBDA_SERVER_URL(serverURL);
      } else if (
        isNativeChain(networkId) &&
        appConfig.env === AppEnvironment.PRODUCTION
      ) {
        // * USE PRODUCTION Lambda and Mainnet Addresses
        // Contracts
        setLOOT8_ERC20_ADDRESS(addresses[networkId].Loot8Token);
        setOFFRAMP_CONTRACT_ADDRESS(addresses[networkId].OffRamp);

        // Server Lambda
        const serverURL = OFFRAMP_LAMBDA_URL_PROD();
        setLAMBDA_SERVER_URL(serverURL);
      } else if (
        appConfig.env === AppEnvironment.STAGING &&
        isNativeChain(networkId)
      ) {
        // * USE STAGING Lambda and Mainnet Addresses
        // Contracts
        setLOOT8_ERC20_ADDRESS(addresses[networkId].Loot8Token);
        setOFFRAMP_CONTRACT_ADDRESS(addresses[networkId].OffRamp);

        // Server Lambda
        const serverURL = OFFRAMP_LAMBDA_URL_STAGING();
        setLAMBDA_SERVER_URL(serverURL);
      }
    } catch {}
    //fetch min max cashout limits
    fetchLimits();
  };

  useEffect(() => {
    async function main() {
      await initialize();
      await getBalance();
    }

    main();
  }, [userTokenBalance, responseMessage.success]);

  // * Request Cash Out Helper Functions
  // Transaction Approval
  const approvalTransaction = async (
    spenderAddress,
    amount,
    tokenContract,
    userAddress,
    loot8TokenAddress,
  ) => {
    // * Approval Transaction
    // ? Approve off-ramp Contract to Transfer User Tokens
    try {
      // Get Current Allowance
      const currentAllowance = await tokenContract.allowance(
        userAddress,
        spenderAddress,
      );

      // Check if User Already Approved Allowance
      if (currentAllowance < amount) {
        // Approve Tokens Allowance for Off Ramp Contract
        const approvalTxData = getApprovalTransactionMessage(
          spenderAddress,
          amount,
        );
        let approveTxMsg: IMessageMetaData = {
          to: loot8TokenAddress,
          wallet: wallet,
          data: approvalTxData,
          networkID: networkId,
          provider: staticProvider,
        };

        dispatch(SendMetaTX(approveTxMsg)).then(response => {
          if (response.payload.status == 'Success') {
          }
        });
      }
    } catch (error) {
      // Approval Failed!
      LogToLoot8Console(error);
    }
  };

  // * Server Request for Encryption
  const requestEncryption = async userWallet => {
    // * Server Endpoint
    // ? For requestId and Encrypting Email
    const serverEndpoint = `${LAMBDA_SERVER_URL}api/v1/payments/encryption/request`;

    // * Create Payload for Server
    const payload = {
      publicKey: userWallet.publicKey,
      address: userWallet.address,
    };

    const customHeaders = { 'Content-Type': 'application/json' };

    // * Make the request to Server
    try {
      const res = await fetch(serverEndpoint, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: customHeaders,
      });

      const serverResponseJson = await res.json();

      // ? Needed for Signing Shared Secret
      const serverPublicKey = serverResponseJson.encryption.publicKey;

      // Return Server Response and Server Public Key
      return { serverPublicKey, serverResponseJson };
    } catch (error) {
      // Server Error, Payload Failed!
      console.log(error);
    }
  };

  // * Encrypt Email
  const encryptEmail = (userWallet, serverPublicKey) => {
    // Get User Email
    const userEmail = email;

    // * Encrypt Data
    // ? Encrypt User Email with Server Public Key for on chain verification
    const encryptionKey = new SigningKey(
      userWallet.privateKey,
    ).computeSharedSecret(serverPublicKey);
    const encrypted = cryptoJS.AES.encrypt(
      JSON.stringify({ email: userEmail }),
      encryptionKey,
    );

    return encrypted;
  };

  // * Off Ramp Transaction
  const offRampTransaction = async (
    serverResponseJson,
    amount,
    platform,
    encrypted,
    offRampContractAddress,
    userWallet,
  ) => {
    // * Off-Ramp Transaction
    try {
      const offRampTxData = getOffRampTransactionMessage(
        hexlify(parse(serverResponseJson.encryption.requestId)),
        amount,
        platform,
        hexlify(toUtf8Bytes(encrypted.toString())),
      );

      // * Message for Off Ramp Transaction
      let offRampTxMessage: IMessageMetaData = {
        to: offRampContractAddress,
        wallet: userWallet,
        data: offRampTxData,
        networkID: networkId,
        provider: staticProvider,
      };

      // * Send Meta Transaction
      try {
        dispatch(SendMetaTX(offRampTxMessage)).then(async response => {
          if (response.payload.status == 'Success') {
            if (response.payload.eventLogs.length === 0) {
              // No Event logs received
              setRequestInProccess(false);
              setCashoutLoading(false);
              const debounceModal = debounce(() => {
                setShowHeader(true);
                setCashoutFailureModal(true);
                setResponseMessage({
                  success: '',
                  failed:
                    'Request for cashout failed. Please check your email for further updates',
                });
              }, 100);
              debounceModal();
              setEmail('');
              setTokenAmount('');
              return;
            }

            // Fetch timestamp and Request ID from the Tx Eventlogs for Storage
            if (response?.payload?.eventLogs) {
              const { requestId, timestamp } = getOfframpTransactionLogsData(
                response.payload.eventLogs,
              );

              // If timestamp and Request ID are valid, store in local storage
              if (timestamp && requestId) {
                const storedTxData = await getData(APP_STORAGE_TX_TIMESTAMPS);
                if (!storedTxData) {
                  await storeData(APP_STORAGE_TX_TIMESTAMPS, [
                    { timestamp, requestId },
                  ]);
                } else {
                  await storeData(APP_STORAGE_TX_TIMESTAMPS, [
                    ...storedTxData,
                    { timestamp, requestId },
                  ]);
                }
              }
            }

            // ? Failure or Success will be Emailed to User
            setRequestInProccess(false);
            setCashoutLoading(false);
            const debounceModal = debounce(() => {
              const successMessage =
                'Your LOOT8 withdrawal request is submitted. Please check your email for further updates.';
              setCashoutFailureModal(false);
              setResponseMessage({
                success: successMessage,
                failed: '',
              });
              navigation.navigate('Success', {
                screen: ScreenName.SUCCESS_SCREEN,
                params: {
                  message: successMessage,
                  navigateTo: 'CashOutTokens',
                },
                navigationParams: {},
              });
            }, 100);
            debounceModal();
            setEmail('');
            setTokenAmount('');
          }
        });
      } catch (error) {
        console.log('Off Ramp Transaction Failed!');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // * Cashout Request Function from UI
  const requestCashOut = async () => {
    setCashoutConfirmationModal(false);
    setRequestInProccess(true);
    const showErrorModal = errorMessage => {
      setShowHeader(false);
      setCashoutFailureModal(true);
      const debounceModal = debounce(() => {
        setResponseMessage({ success: '', failed: errorMessage });
      }, 100);
      debounceModal();
    };
    // temporary disable cashout
    // showErrorModal("Apologies, we're experiencing an issue and are temporarily unable to process cashouts to PayPal. Please bear with us as we work to resolve this.");
    // return;
    // temporary disable cashout

    if (!email) {
      showErrorModal('Please enter your email address!');
      setRequestInProccess(false);
      return;
    }

    if (!validateEmail(email)) {
      showErrorModal('Please enter a valid email address');
      setRequestInProccess(false);
      return;
    }

    if (!tokenAmount) {
      showErrorModal('Please enter Loot8 amount to transfer!');
      setRequestInProccess(false);
      return;
    }

    if (Number(tokenAmount) > Number(userTokenBalance)) {
      showErrorModal('Insufficient balance to transfer!');
      setRequestInProccess(false);
      return;
    }

    // * User Wallet
    // ? For Approving Transactions and Signing Data
    const userWallet = new ethers.Wallet(wallet.privateKey, staticProvider);

    // * Platform
    // ? 0 = Paypal, 1 = Venmo
    const platform = 0;

    // * Connect with Token Contract
    const loot8TokenContractAddress = LOOT8_ERC20_ADDRESS;
    const loot8TokenContract = Loot8ERC20__factory.connect(
      loot8TokenContractAddress,
      userWallet,
    );

    // * Connect with Fiat Off-ramp Contract
    const offRampContractAddress = OFFRAMP_CONTRACT_ADDRESS;
    // Not being used because of Meta TX. Here for future reference
    const offRampContract = Loot8FiatOffRamp__factory.connect(
      offRampContractAddress,
      userWallet,
    );

    if (Number(tokenAmount) < Number(minLimit)) {
      showErrorModal(
        `Entered amount is less than minimum limit\nMinimum limit is ${minLimit} Loot8 Tokens`,
      );
      setRequestInProccess(false);
      return;
    }

    if (Number(tokenAmount) > Number(maxLimit)) {
      showErrorModal(
        `Entered amount is greater than maximum limit\nMaximum limit is ${maxLimit} Loot8 Tokens`,
      );
      setRequestInProccess(false);
      return;
    }

    let txInLast24Hours = 0;

    let index = 0;

    while (true) {
      try {
        // Pull the latest timestamp from the Offramp contract
        const timestamp = await offRampContract.patronOffRampTimestamps(
          address,
          index,
        );
        // Parse the timestamps
        let timestampSecs = parseInt(timestamp._hex);
        // Fetch the timestamp from the latest Block
        let latestBlock = await staticProvider.getBlock('latest');
        let nowSecs = latestBlock.timestamp;
        let diffSecs = nowSecs - timestampSecs;
        const _24HourSecs = 24 * 60 * 60;

        // Check whether the transaction was are in the last 24 hours and increment the counter accordingly
        if (diffSecs <= _24HourSecs) {
          txInLast24Hours++;
        }
      } catch (error) {
        break;
      }
      index++;
    }

    if (txInLast24Hours >= 3) {
      showErrorModal(
        `You have already made 3 Cashout requests in the last 24 hours. Please try again later.`,
      );
      setRequestInProccess(false);
      return;
    }

    setCashoutLoading(true);

    // * Parsing the Amount
    // ? Loot8 Token has 18 Decimals
    const amount = parseEther(tokenAmount);

    // * Approve Allowance to Off Ramp Contract
    // ? Approve the Off Ramp Contract to Transfer User Tokens
    try {
      await approvalTransaction(
        offRampContractAddress,
        amount,
        loot8TokenContract,
        userWallet.address,
        loot8TokenContractAddress,
      );
    } catch (error) {
      setRequestInProccess(false);
      setCashoutLoading(false);
      const debounceModal = debounce(() => {
        setShowHeader(true);
        setCashoutFailureModal(true);
        setResponseMessage({
          success: '',
          failed: 'Approval Transaction Failed!',
        });
      }, 100);
      debounceModal();
      return;
    }

    // * Request Encryption from Server
    // ? Needed for Encrypting User Email
    let serverPublicKey;
    let serverResponseJson;
    try {
      const res = await requestEncryption(userWallet);
      serverPublicKey = res.serverPublicKey;
      serverResponseJson = res.serverResponseJson;
    } catch (error) {
      setRequestInProccess(false);
      setCashoutLoading(false);
      const debounceModal = debounce(() => {
        setShowHeader(true);
        setCashoutFailureModal(true);
        setResponseMessage({
          success: '',
          failed: 'Encryption server error. Please try again.',
        });
      }, 100);
      debounceModal();
      return;
    }

    // * Encrypt User Email
    // ? Encrypt User Email with Server Public Key for on chain verification
    let encrypted;
    try {
      encrypted = encryptEmail(userWallet, serverPublicKey);
    } catch (error) {
      setRequestInProccess(false);
      setCashoutLoading(false);
      const debounceModal = debounce(() => {
        setShowHeader(true);
        setCashoutFailureModal(true);
        setResponseMessage({ success: '', failed: 'Encryption Failed!' });
      }, 100);
      debounceModal();
      return;
    }

    // * Off Ramp Transaction
    try {
      // Meta Transaction
      offRampTransaction(
        serverResponseJson,
        amount,
        platform,
        encrypted,
        offRampContractAddress,
        userWallet,
      );
      // ? Non-Meta Transaction for Future Reference
      //   const offRampTx = await offRampContract.requestOffRamp(
      //     hexlify(parse(serverResponseJson.encryption.requestId)),
      //     amount,
      //     platform,
      //     hexlify(toUtf8Bytes(encrypted.toString()))
      // )

      // await offRampTx.wait();
    } catch (error) {
      setRequestInProccess(false);
      setCashoutLoading(false);
      const debounceModal = debounce(() => {
        setShowHeader(true);
        setCashoutFailureModal(true);
        setResponseMessage({
          success: '',
          failed: 'Off Ramp contract transaction failed. Please try again.',
        });
      }, 100);
      debounceModal();
      console.log('Off Ramp Transaction Failed!');
      return;
    }
    return;
  };

  const customTheme = {
    ...DefaultTheme,
    roundness: 24, // Customize other properties as needed
  };

  const confirmCashoutReq = () => {
    setCashoutConfirmationModal(true);
  };
  return (
    <>
      {cashoutLoading && (
        <InfoLoader text1="Sit tight while we cash out your LOOT8 tokens" />
      )}
      <View style={{ flex: 1 }}>
        {/* header */}
        {/* container */}
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ flex: 1 }}
          contentContainerStyle={componentStyles.contentContainer}>
          <AnimatedTabBoxes
            data={[
              { id: 1, title: 'Paypal' },
              { id: 2, title: 'Venmo', disabled: true },
            ]}
            changeSlidesOnPress={goToNextSlide}
            height={68}
          />
          <Text style={componentStyles.label}>
            Enter your Email Address{' '}
            {+userTokenBalance > 0 && !getBalanceLoading ? (
              ''
            ) : (
              <Text style={componentStyles.disabledLabel}>-disabled</Text>
            )}
          </Text>
          <TextInput
            theme={customTheme}
            style={componentStyles.inputContainer}
            contentStyle={{ padding: 0 }}
            placeholder={'i.e., john.smith@domain.com'}
            placeholderTextColor="rgba(219, 226, 237,0.35)"
            value={email}
            autoCorrect={false}
            mode="outlined"
            outlineColor="rgba(219, 226, 237,0.35)"
            activeOutlineColor="rgb(219, 226, 237)"
            textColor="rgb(219, 226, 237)"
            cursorColor="rgb(219, 226, 237)"
            onChangeText={text => setEmail(text)}
            disabled={!+userTokenBalance}
            right={
              email && email != '' ? (
                <TextInput.Icon
                  icon="close"
                  size={20}
                  iconColor="rgb(219, 226, 237)"
                  onPress={() => {
                    setEmail('');
                  }}
                />
              ) : (
                <></>
              )
            }
            returnKeyType="done"
          />
          <Text style={componentStyles.label}>
            Enter Loot8 amount to transfer{' '}
            {+userTokenBalance > 0 && !getBalanceLoading ? (
              ''
            ) : (
              <Text style={componentStyles.disabledLabel}>-disabled</Text>
            )}
          </Text>
          <View>
            <TextInput
              theme={customTheme}
              style={componentStyles.inputContainer}
              contentStyle={{ padding: 0 }}
              placeholder={'0.00'}
              placeholderTextColor="rgba(219, 226, 237,0.35)"
              value={tokenAmount}
              autoCorrect={false}
              mode="outlined"
              outlineColor="rgba(219, 226, 237,0.35)"
              activeOutlineColor="rgb(219, 226, 237)"
              textColor="rgb(219, 226, 237)"
              cursorColor="rgb(219, 226, 237)"
              onChangeText={text => {
                const newtext = text.replace(/[^0-9.]/g, '');
                // .replace(/^\./, "")
                // .replace(/\s/g, "");
                setTokenAmount(newtext);
              }}
              returnKeyType="done"
              keyboardType="decimal-pad"
              disabled={!+userTokenBalance}
            />
            <Avatar.Image
              style={{
                backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
                position: 'absolute',
                top: 15,
                right: 16,
              }}
              size={22}
              source={require('../../../assets/adaptive-icon.png')}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 5,
            }}>
            <Text
              style={[
                componentStyles.label,
                {
                  alignItems: 'center',
                  fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
                  fontSize: defaultTheme.FONT_SIZE_XXSMALL,
                },
              ]}>
              Min: ${minLimit} ~ Max: ${maxLimit}
            </Text>
            <Text
              style={[
                componentStyles.label,
                {
                  fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                  fontSize: defaultTheme.FONT_SIZE_XXSMALL,
                },
              ]}>
              Available Balance:{' '}
              {userTokenBalance > 0 ? userTokenBalance?.toFixed(2) : '0.00'}
            </Text>
          </View>

          {Platform.OS === 'web' && (
            <>
              <Text
                style={[
                  componentStyles.label,
                  {
                    textAlign: 'center',
                    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
                  },
                ]}>
                Important Notice:
              </Text>
              <Text
                style={[
                  componentStyles.label,
                  {
                    textAlign: 'center',
                    alignItems: 'center',
                    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
                    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
                  },
                ]}>
                To comply with mobile app store guidelines, the cash-out feature
                for Loot8 Tokens is now accessible exclusively through our web
                app.
              </Text>
            </>
          )}

          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
              width: '100%',
              paddingBottom: 10,
            }}>
            {!+userTokenBalance && !getBalanceLoading && (
              <Text
                style={[
                  componentStyles.label,
                  {
                    alignItems: 'center',
                    marginTop: 7.5,
                    marginBottom: 10,
                    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
                  },
                ]}>
                You have no LOOT8 tokens to cash out
              </Text>
            )}

            {Platform.OS == 'web' && (
              <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                <GradientButton
                  buttonLabel="Continue"
                  onPress={confirmCashoutReq}
                  disabled={
                    !+userTokenBalance ||
                    getBalanceLoading ||
                    !validateEmail(email) ||
                    !tokenAmount ||
                    requestInProccess ||
                    !enableCashout ||
                    parseFloat(tokenAmount) < parseFloat(minLimit) ||
                    parseFloat(tokenAmount) > parseFloat(maxLimit)
                  }
                  loading={cashoutLoading}
                />
              </View>
            )}
            {(Platform.OS == 'android' || Platform.OS == 'ios') && (
              <GradientButton
                buttonLabel="Continue"
                onPress={confirmCashoutReq}
                disabled={
                  !+userTokenBalance ||
                  getBalanceLoading ||
                  !validateEmail(email) ||
                  !tokenAmount ||
                  requestInProccess ||
                  !enableCashout ||
                  parseFloat(tokenAmount) < parseFloat(minLimit) ||
                  parseFloat(tokenAmount) > parseFloat(maxLimit)
                }
                loading={cashoutLoading}
              />
            )}
          </View>
        </ScrollView>
      </View>

      <FailureModal
        showHeader={showHeader}
        showModal={cashoutFailureModal}
        setModal={() => setCashoutFailureModal(false)}
        failed={responseMessage.failed}
      />
      <ConfirmModal
        showModal={cashoutConfirmationModal}
        setModal={() => setCashoutConfirmationModal(false)}
        confirmOnPress={requestCashOut}
        tokenAmount={tokenAmount}
        OFFRAMP_CONTRACT_ADDRESS={OFFRAMP_CONTRACT_ADDRESS}
      />
    </>
  );
};

export default HOCContainer({
  OriginalComponent: CashOutTokens,
  title: 'Cashout',
});

const componentStyles = StyleSheet.create({
  contentContainer: { flexGrow: 1 },
  inputContainer: {
    backgroundColor: '#474053',
    width: '100%',
    height: 50,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
  },
  label: {
    textAlign: 'left',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: 'rgb(219, 226, 237)',
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    marginBottom: 5,
    marginTop: 15,
  },
  disabledLabel: {
    textAlign: 'left',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    color: 'rgb(219, 226, 237)',
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    marginBottom: 5,
    marginLeft: 5,
    marginTop: 15,
  },
  confirmModalText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginVertical: 5,
  },
  confirmModalTitle: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginVertical: 5,
  },

  separater: {
    height: 1,
    marginVertical: 10,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
  },
});
