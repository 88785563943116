export enum CollectionType {
  ANY,
  PASSPORT,
  OFFER,
  COLLECTION,
  BADGE,
  TICKET,
  PREMIUM_ACCESS,
}

export enum ListingType {
  All,
  PUBLIC,
  PRIVATE,
}
