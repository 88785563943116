import { LogCustomError } from './AppLogger';
import { getAppConfiguration } from '../appconstants';
import { LogToLoot8Console } from './Loot8ConsoleLogger';
import { fetchUsersData } from './GraphQLHelper';

let userQueryPageSize;

/*const getTotalUserCount = async () => {
    const userQuery = `
    query getAllUsersCount {
    users (first: 100) {
        totalCount
    }}`;

    const body = JSON.stringify({ query: userQuery });

    if (!userAPIURL || userAPIURL === null || userAPIURL === "") {
        let appConfig;
        try {
            appConfig = await getAppConfiguration();
        } catch (err) {
            LogToLoot8Console("needLocationChangeTrigger: Error while reading app config");
        }
        if (appConfig && appConfig.queryHelpers && appConfig.queryHelpers.user) {
            userAPIURL = appConfig.queryHelpers.user.apiURL;
        }
    }

    if (userAPIURL && userAPIURL !== '') {
        const response = await retryFetch(() => fetch(userAPIURL,
            { method: 'POST', headers: { 'Content-Type': 'application/json' }, body }));

        try {
            if (response.status == 200) {
                const res = await response.json();
                return res;
            }
            else {
                LogCustomError("getTotalUserCount subquery", response?.status?.toString(), response?.statusText, null);
            }
        }
        catch (ex) {
            LogCustomError("Error occured: getTotalUserCount subquery", null, ex.message, ex.stack, null);
        }
    }
}*/

const getPagedUserData = async (pageSize, pageNumber) => {
  const response = await fetchUsersData(pageNumber, pageSize);
  try {
    if (response?.records?.length > 0) {
      return response;
    } else {
      return null;
    }
  } catch (ex) {
    LogCustomError('Error occured: getPagedUserData subquery', null, ex.message, ex.stack, null);
  }
};

export const getAllUsersDataFromQuery = async (pageSize: number = 0) => {
  try {
    if (!userQueryPageSize || userQueryPageSize === null) {
      let appConfig;
      try {
        appConfig = await getAppConfiguration();
      } catch (err) {
        LogToLoot8Console('needLocationChangeTrigger: Error while reading app config');
      }
      if (appConfig && appConfig.queryHelpers && appConfig.queryHelpers.user) {
        userQueryPageSize = appConfig.queryHelpers.user.pageSize;
      }
    }
    //If pageSize is not provided as function Param then only take from app config
    if (userQueryPageSize && userQueryPageSize > 0 && (pageSize === null || pageSize === 0)) {
      pageSize = userQueryPageSize;
    }

    let allUserData = [];
    let pageNumber = 1;
    let totalPages = 1;
    let totalUsers = 0;

    while (pageNumber <= totalPages) {
      let userData = await getPagedUserData(pageSize, pageNumber);
      if (userData) {
        let data = userData.records;
        if (data && data.length > 0) {
          data.forEach(u => {
            let user = {
              id: Number(u.userId),
              name: u.name,
              wallet: u.wallet,
              avatarURI: u.avatarURI,
              dataURI: u?.dataURI ?? '',
            };
            allUserData.push(user);
          });
          if (pageSize === 0) {
            //in case page size is not specified in app config and also not provided as function param
            pageSize = data.length;
          }
          if (pageNumber === 1) {
            totalUsers = userData.count;
            totalPages = Math.floor(totalUsers / pageSize) + Number(Boolean(totalUsers % pageSize)); //add one page if there is non-zero reminder.
          }
          pageNumber++;
        }
      }
    }

    return allUserData;
  } catch (ex) {
    LogCustomError('Error occured: getAllUsersData query', null, ex.message, ex.stack, null);
  }
};
