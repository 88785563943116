import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import {
  addresses,
  APP_ALL_TRANSACTION,
  getAnynetStaticProvider,
  isNativeChain,
  NetworkId,
} from '../appconstants';
import { LogCustomError } from '../helpers/AppLogger';
import { getData, storeData } from '../helpers/AppStorage';
import { showToastMessage } from '../helpers/Gadgets';
import { TransactionDetail } from '../interfaces/IOffer.interface';
import { RootState } from '../store';
import { Dispatcher__factory } from '../typechain/factories/Dispatcher__factory';
import { setAll } from './helpers';
import { getCollectibleDetails } from './OfferSlice';
import { DispatcherHelper__factory } from '../typechain';
import { getSyncedTime } from '../helpers/DateHelper';
import { CollectionManagerData } from '../data/CollectionManager';

export const getPatronOfferActive = async ({
  networkID,
  provider,
  address,
  wallet,
  offerAddress,
}: any): Promise<any> => {
  const dispatcherHelper = DispatcherHelper__factory.connect(
    addresses[networkID].DispatcherHelper,
    provider,
  );
  return await dispatcherHelper.patronReservationActiveForOffer(
    address,
    offerAddress,
  );
};

export const getPatronRecentReservationForOffer = async ({
  networkID,
  provider,
  address,
  offerAddress,
}: any): Promise<any> => {
  const orderDispatch = Dispatcher__factory.connect(
    addresses[networkID].OrderDispatcher,
    provider,
  );
  return await orderDispatch.getPatronRecentReservationForOffer(
    address,
    offerAddress,
  );
};

export const loadOrderDetail = createAsyncThunk(
  'appuser/loadOrder',
  async (
    { networkID, provider, address, wallet }: any,
    { getState, dispatch },
  ): Promise<any> => {
    const orderDispatcherHelper = DispatcherHelper__factory.connect(
      addresses[networkID].DispatcherHelper,
      provider,
    );
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;

    let allUserOrder = [];
    const allorders = await orderDispatcherHelper.getPatronReservations(
      address,
      false,
    );
    await storeData(
      '@orderlistLastRefreshBlock',
      await provider.getBlockNumber(),
    );

    await Promise.all(
      allorders.map(async orderDetails => {
        const collectibleAddress = orderDetails?.offer;
        const chainId =
          await CollectionManagerData.getCollectionChainId(collectibleAddress);
        let providerToPass = provider;
        if (!isNativeChain(+chainId)) {
          providerToPass = getAnynetStaticProvider(+chainId);
        }
        let collectible = await getCollectibleDetails(
          {
            networkID: +chainId,
            provider: providerToPass,
            collectibleAddress,
            address,
            wallet,
          },
          { entityData },
          {},
        );

        let orderTime = new Date(Number(orderDetails?.created) * 1000);
        let isExpiry = Number(orderDetails?.expiry) * 1000 < getSyncedTime();

        if (!collectible.isCoupon) {
          const transDetail: TransactionDetail = {
            reservationId: Number(orderDetails?.id),
            offerAddress: collectibleAddress,
            offerId:
              orderDetails?.fulfilled && orderDetails?.offerId
                ? Number(orderDetails?.offerId)
                : 0,
            drinkName: collectible.name,
            price: collectible.price,
            quantity: collectible.quantity ?? 1,
            drinkImage: collectible.image,
            date: orderTime,
            isServed: orderDetails?.fulfilled,
            isCancel: orderDetails?.cancelled,
            transactionId: '' + Number(orderDetails?.id),
            isTransaction: true,
            offerType: collectible.OfferType,
            entityName: entityData[collectible.entityAddress]?.name,
            tokensEarned: collectible.tokensEarned,
            isExpiry: isExpiry,
            expiry: Number(orderDetails?.expiry) * 1000,
            timestamp: Number(orderDetails?.created) * 1000,
            isCashPayment: orderDetails?.fiatOrCashPayment,
            isCoupon: collectible.isCoupon,
          };

          allUserOrder.push(transDetail);
          dispatch(pushOrderDetails(transDetail));
        }
      }),
    );

    if (allUserOrder.length > 0) {
      allUserOrder.sort((a, b) => {
        return Number(b.reservationId) - Number(a.reservationId);
      });
      await storeData(APP_ALL_TRANSACTION, allUserOrder);
    }
    return { UserOrders: allUserOrder };
  },
);

export const loadParticularOrderDetail = createAsyncThunk(
  'appuser/loadParticularOrder',
  async (
    { networkID, provider, address, wallet, reservationID }: any,
    { getState },
  ): Promise<any> => {
    const orderDispatch = Dispatcher__factory.connect(
      addresses[networkID].OrderDispatcher,
      provider,
    );
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    let orderDetails = null;

    while (!(orderDetails && Number(orderDetails?.id) !== 0)) {
      try {
        orderDetails = await orderDispatch.reservations(reservationID);
      } catch (e) {
        console.error('Error in fetching Order Details');
        return;
      }
    }

    const collectibleAddress = orderDetails?.offer;
    const chainId =
      await CollectionManagerData.getCollectionChainId(collectibleAddress);
    let providerToPass = provider;
    if (!isNativeChain(+chainId)) {
      providerToPass = getAnynetStaticProvider(+chainId);
    }
    let collectible = await getCollectibleDetails(
      { networkID, provider, collectibleAddress, address, wallet },
      { entityData },
      {},
    );

    let orderTime = new Date(Number(orderDetails?.created) * 1000);
    let isExpiry = Number(orderDetails?.expiry) * 1000 < getSyncedTime();

    const transDetail: TransactionDetail = {
      reservationId: Number(orderDetails?.id),
      offerAddress: collectibleAddress,
      offerId:
        orderDetails?.fulfilled && orderDetails?.offerId
          ? Number(orderDetails?.offerId)
          : 0,
      drinkName: collectible.name,
      price: collectible.price,
      quantity: collectible.quantity ?? 1,
      drinkImage: collectible.image,
      date: orderTime,
      isServed: orderDetails?.fulfilled,
      isCancel: orderDetails?.cancelled,
      transactionId: '' + Number(orderDetails?.id),
      isTransaction: true,
      offerType: collectible.OfferType,
      entityName: entityData[collectible.entityAddress]?.name,
      tokensEarned: collectible.tokensEarned,
      isExpiry: isExpiry,
      expiry: Number(orderDetails?.expiry) * 1000,
      timestamp: Number(orderDetails?.created) * 1000,
      isCashPayment: orderDetails?.cashPayment,
      isCoupon: collectible.isCoupon,
    };

    let allUserOrder = (await getData(APP_ALL_TRANSACTION)) ?? [];

    let unfulfilOrders = allUserOrder?.filter(
      x => !x.isServed && !x.isCancel && !x.isExpiry,
    );
    if (unfulfilOrders && unfulfilOrders.length > 0) {
      for (let i = 0; i < unfulfilOrders.length; i++) {
        let updatingOrder = unfulfilOrders[i];
        if (updatingOrder) {
          let orderDetails = await orderDispatch.reservations(
            updatingOrder.reservationId,
          );

          updatingOrder.offerId = orderDetails.fulfilled
            ? Number(orderDetails.offerId)
            : 0;
          updatingOrder.isServed = orderDetails.fulfilled;
          let isExpiry = Number(orderDetails.expiry) * 1000 < getSyncedTime();
          updatingOrder.offerId = orderDetails.fulfilled
            ? Number(orderDetails.offerId)
            : 0;
          updatingOrder.isServed = orderDetails.fulfilled;
          updatingOrder.isCancel = orderDetails.cancelled;
          updatingOrder.isExpiry = isExpiry;
        }
      }
    }

    let latestUserOrder = [...allUserOrder, transDetail];

    if (latestUserOrder && latestUserOrder.length > 0) {
      latestUserOrder.sort((a, b) => {
        return Number(b.reservationId) - Number(a.reservationId);
      });
      await storeData(APP_ALL_TRANSACTION, latestUserOrder);
    }
    return { UserOrders: latestUserOrder };
  },
);

export const UpdateParticularOrderDetail = createAsyncThunk(
  'appuser/updateOrder',
  async (
    { networkID, provider, address, wallet, userInfo, reservationID }: any,
    { dispatch, getState },
  ): Promise<any> => {
    let allUserOrder = await getData(APP_ALL_TRANSACTION);

    let unfulfilOrders = allUserOrder?.filter(
      x => !x.isServed && !x.isCancel && !x.isExpiry,
    );
    if (unfulfilOrders && unfulfilOrders.length > 0) {
      const orderDispatch = Dispatcher__factory.connect(
        addresses[networkID].OrderDispatcher,
        provider,
      );

      for (let i = 0; i < unfulfilOrders.length; i++) {
        let updatingOrder = unfulfilOrders[i];
        if (updatingOrder) {
          let orderDetails = await orderDispatch.reservations(
            updatingOrder.reservationId,
          );

          updatingOrder.offerId = orderDetails.fulfilled
            ? Number(orderDetails.offerId)
            : 0;
          updatingOrder.isServed = orderDetails.fulfilled;
          let isExpiry = Number(orderDetails.expiry) * 1000 < getSyncedTime();
          updatingOrder.offerId = orderDetails.fulfilled
            ? Number(orderDetails.offerId)
            : 0;
          updatingOrder.isServed = orderDetails.fulfilled;
          updatingOrder.isCancel = orderDetails.cancelled;
          updatingOrder.isExpiry = isExpiry;
        }
      }
      await storeData(APP_ALL_TRANSACTION, allUserOrder);
    }
    if (allUserOrder && allUserOrder.length > 0) {
      allUserOrder.sort((a, b) => {
        return Number(b.reservationId) - Number(a.reservationId);
      });
    }

    return { UserOrders: allUserOrder };
  },
);

export const mintLinkedCollectionsTokensForHolders = async ({
  networkID,
  provider,
  address,
  passportAddress,
}: any): Promise<any> => {
  const orderDispatch = Dispatcher__factory.connect(
    addresses[networkID].OrderDispatcher,
    provider,
  );
  return;
  // return await orderDispatch.mintLinkedCollectionsTokensForHolders(
  //   address,
  //   passportAddress,
  // );
};

export interface IOrderSliceData {
  readonly UserOrders?: TransactionDetail[];
  readonly loading: boolean;
}

const initialState: IOrderSliceData = {
  UserOrders: null,
  loading: false,
};

const UserOrderSlice = createSlice({
  name: 'Orders',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    resetOrder(state) {
      setAll(state, initialState);
    },
    pushOrderDetails(state, action) {
      const allOrders = state.UserOrders ?? [];
      allOrders.push(action.payload);

      state.UserOrders = allOrders.sort((a, b) => {
        return Number(b.reservationId) - Number(a.reservationId);
      });
      state.loading = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadOrderDetail.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(loadOrderDetail.fulfilled, (state, action) => {
        state.UserOrders = action.payload.UserOrders;
        state.loading = false;
      })
      .addCase(
        loadOrderDetail.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'loadOrderDetail',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(
        loadParticularOrderDetail.pending,
        (state: { loading: boolean }) => {
          state.loading = true;
        },
      )
      .addCase(loadParticularOrderDetail.fulfilled, (state, action) => {
        state.UserOrders = action.payload.UserOrders;
        state.loading = false;
      })
      .addCase(
        loadParticularOrderDetail.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'loadParticularOrderDetail',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(UpdateParticularOrderDetail.fulfilled, (state, action) => {
        state.UserOrders = action.payload.UserOrders;
      })
      .addCase(
        UpdateParticularOrderDetail.rejected,
        (state, { error }: any) => {
          showToastMessage();
          LogCustomError(
            'UpdateParticularOrderDetail',
            error.name,
            error.message,
            error.stack,
          );
        },
      );
  },
});

export const UserOrderSliceReducer = UserOrderSlice.reducer;

const baseInfo = (state: RootState) => state.UserOrders;

export const { fetchAppSuccess, resetOrder, pushOrderDetails } =
  UserOrderSlice.actions;

export const getUserState = createSelector(baseInfo, Orders => Orders);
