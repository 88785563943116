import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    borderRadius: defaultTheme.CONTENT_RADIUS,
    overflow: 'hidden',
    padding: 2,
  },
  rippleWrapper: {
    overflow: 'hidden',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  label: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL + 1,
    lineHeight: defaultTheme.FONT_SIZE_XXXSMALL + 4,
    textAlign: 'center',
  },
});
