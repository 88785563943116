import React from 'react';
import styles from '../../styles';
import { View, Text, Linking, Platform } from 'react-native';
import { Button } from 'react-native-paper';
import ModalComponent from '../Modal';
import { APPSTORE_APP_URL, PLAYSTORE_APP_URL } from '../../appconstants';
import Constants from 'expo-constants';

type AppUpdateModalComponentProps = {
  forceAppUpdateRequired: boolean;
  latestAvailableVersion: string;
  showUpdateAppModal: boolean;
  setShowUpdateAppModal: (show: boolean) => void;
};

const AppUpdateComponent = ({
  forceAppUpdateRequired,
  latestAvailableVersion,
  showUpdateAppModal,
  setShowUpdateAppModal,
}: AppUpdateModalComponentProps) => {
  const redirectToAppStore = async () => {
    var redirectUrl =
      Platform.OS === 'android' ? PLAYSTORE_APP_URL : APPSTORE_APP_URL;
    const supported = await Linking.canOpenURL(redirectUrl);
    if (supported) {
      await Linking.openURL(redirectUrl);
      setTimeout(() => {
        if (forceAppUpdateRequired) {
          const currentAppVersion = Constants.expoConfig.version;
          if (currentAppVersion === latestAvailableVersion) {
            setShowUpdateAppModal(false);
          }
        } else {
          setShowUpdateAppModal(false);
        }
      }, 500);
    }
  };

  return (
    <ModalComponent
      showModal={showUpdateAppModal}
      headerText={
        forceAppUpdateRequired ? 'Update Required' : 'Update Available'
      }
      onDismiss={() => {
        setShowUpdateAppModal(false);
      }}
      needCloseButton={false}
      dismissable={false}
      theme={'rgba(0, 0, 0, 0.80)'}>
      <View style={{ paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
        <Text style={styles.modalTextStyle}>
          {'Loot8 Version ' + latestAvailableVersion + ' is now available.'}
        </Text>
        {!forceAppUpdateRequired ? (
          <Text style={[styles.modalTextStyle, { fontWeight: '700' }]}>
            {'Please update to get latest features!'}
          </Text>
        ) : (
          <Text style={[styles.modalTextStyle, { fontWeight: '700' }]}>
            {'Please update to proceed further.'}
          </Text>
        )}
      </View>
      <View style={styles.modalButtonContainer}>
        {!forceAppUpdateRequired && (
          <Button
            onPress={() => {
              setShowUpdateAppModal(false);
            }}
            style={styles.modalNoButtonStyle}
            labelStyle={styles.modalNoButtonLabelStyle}>
            LATER
          </Button>
        )}
        <Button
          onPress={redirectToAppStore}
          style={styles.modalYesButtonStyle}
          labelStyle={styles.modalYesButtonLabelStyle}>
          UPDATE
        </Button>
      </View>
    </ModalComponent>
  );
};

export default AppUpdateComponent;
