import React, { useState } from 'react';
import { View, Text } from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { ActivityIndicator } from 'react-native-paper';

import ModalComponent from '.';
import styles from '../../styles';
import { wait } from '../../helpers/ipfs';
import GradientButton from '../GradientButton';
import { defaultTheme } from '../../themes/loot8';
import { useWeb3AuthContext } from '../../hooks/web3authContext';

export const ModalDisconnected = ({ isDisconnected, navigation }) => {
  const { disconnect } = useWeb3AuthContext();
  const [showModal, setShowModal] = useState(isDisconnected);
  const [showLoader, setShowLoader] = useState(false);

  const retry = async () => {
    setShowLoader(true);
    await wait(1000);
    NetInfo.fetch().then(state => {
      setShowModal(!state.isConnected);
    });
    setShowLoader(false);
  };

  return (
    <ModalComponent
      showModal={showModal}
      enableHeader={false}
      dismissable={false}
      enableHeaderhairline={false}
      showLoading={false}
      theme={'rgba(0, 0, 0, 0.75)'}
      modalStyle={{
        // marginVertical: 10,
        width: '90%',
        maxWidth: 400,
        alignSelf: 'center',
      }}
      modalBodyStyle={{ marginVertical: 0 }}>
      <View
        style={{
          margin: 15,
        }}>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Text style={[styles.disconnectAccountText]}>
            {'Unstable Network connection.'}
          </Text>
          <Text style={[styles.disconnectAccountText, { paddingTop: 5 }]}>
            {'Please try again'}
          </Text>
        </View>

        <View
          style={{
            width: '100%',
            marginTop: 30,
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}>
          {!showLoader ? (
            <>
              <GradientButton
                width={'45%'}
                type="secondary"
                buttonLabel={'Logout'}
                onPress={() => {
                  navigation.canGoBack()
                    ? navigation.navigate('LogOutScreen')
                    : disconnect();
                }}
              />
              <GradientButton
                width={'45%'}
                onPress={retry}
                buttonLabel={'Retry'}
                type="alternate"
              />
            </>
          ) : (
            <ActivityIndicator color={defaultTheme.PRIMARY_TEXT_COLOR} />
          )}
        </View>
      </View>
    </ModalComponent>
  );
};
