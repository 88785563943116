import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: defaultTheme.BUTTON_RADIUS,
    overflow: 'hidden',
  },
  buttonText: {
    lineHeight: 18,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    textAlign: 'center',
  },
  disabled: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.25)',
  },
  borderedStyles: {
    backgroundColor: 'rgba(33, 29, 40,1)',
    margin: 1.5,
    borderRadius: defaultTheme.BUTTON_RADIUS,
  },
});
