import React, { useEffect, useState } from 'react';
import {
  DeviceEventEmitter,
  NativeEventEmitter,
  Platform,
  Pressable,
  Text,
  View,
} from 'react-native';
import { styles } from './styles';
import { defaultTheme } from '../../themes/loot8';
import CachedImage from '../CachedImage';
import ThreeDotsSettingsButton from '../ThreeDotsSettingsButton';
import { CustomDeviceEvents, ScreenName } from '../../enums/screen.enum';
import { CatalogTypes } from '../../enums/catalog.enum';
import { APP_STORAGE_GET_COLLECTIBLEDETAILS } from '../../appconstants';
import { getData } from '../../helpers/AppStorage';
import {
  getPassportFinalBuyPrice,
  updatePassportBuyPrice,
} from '../../slices/PassportSlice';
import { useAppDispatch } from '../../hooks';
import { formatPriceUpToTrillion } from '../../helpers/Gadgets';
import { getMarketplaceListingsData } from '../../helpers/MarketPlace';
import { PassportType } from '../../enums/passportCategory.enum';
import { useNavigation } from '@react-navigation/native';
import GradientPrice from '../GradientPrice';
import useIsResponsive from '../../hooks/useIsResponsive';

interface ExpassCardProps {
  item: any;
  onPress: (item: any) => void;
  onListForSalePressed?: (item: any) => void;
  onThreeDotsSettingsPress?: (item: any) => void;
  updateBuyPrice?: boolean;
}
const isWeb = Platform.OS === 'web';

const ExpassCard: React.FC<ExpassCardProps> = ({
  item,
  onPress,
  updateBuyPrice = false,
  onListForSalePressed,
  onThreeDotsSettingsPress,
}) => {
  const navigation = useNavigation();
  const isResponsive = useIsResponsive();

  const DEFAULT_PAGE_SIZE = isWeb ? 12 : 6;

  const onPriceTagPress = async () => {
    if (item.passportType === PassportType.SUBSCRIBED) {
      const isListed =
        item &&
        item?.collectibleIds.length > 0 &&
        item?.marketPlaceConfig?.listingIndex &&
        item?.marketPlaceConfig?.listingIndex?.findIndex(
          obj => obj.tokenId === Number(item?.collectibleIds[0]),
        ) !== -1
          ? true
          : false;
      if (isListed) {
        // get market place listing data from getMarketplaceListingsData
        //Parameters:
        // orderBy
        // order
        // skip
        // take
        // isCollectibleData
        // searchByName
        const data = await getMarketplaceListingsData(
          'name',
          'DESC',
          0,
          DEFAULT_PAGE_SIZE,
          false,
          item.name.toLowerCase().trim(),
        );
        if (data) {
          //data will have all expasses data which matches the name of the expass user clicked on
          // filter and match token id to get exact expass we need to send in navigation params
          const filteredData = data.filter(
            (x, i) => item.tokenId === x.tokenId,
          );
          let sellerData = null;
          if (item) {
            // pre processing and making obj for next screen with required keys
            const collectibleData = {
              ...filteredData[0],
              collectionData: item,
              sellerData: sellerData,
            };
            navigation.navigate('MarketPlaceUnlistCollection', {
              collection: collectibleData,
              type: CatalogTypes.PASSPORT,
              fromHome: true,
            });
          }
        }
      } else {
        onListForSalePressed(item);
      }
    }
  };
  return (
    <Pressable
      onPress={() => onPress(item)}
      style={[styles.container, { flex: isResponsive ? 0.49 : 1 }]}>
      <RenderCachedImage
        item={item}
        isResponsive={false}
        resizeMode={'cover'}
        tileWidth={144}
        tileHeight={114}
      />
      <View style={styles.right}>
        <Text style={styles.title} numberOfLines={1}>
          {item?.name} {item.subTitle && item.subTitle}
        </Text>
        <Text style={styles.subtitle} numberOfLines={1}>
          {item?.category}
        </Text>
        <PassportPriceComponent
          onPriceTagPress={onPriceTagPress}
          type={CatalogTypes.PASSPORT}
          item={item}
          updateBuyPrice={updateBuyPrice}
        />
        {item.details && (
          <Text style={styles.description} numberOfLines={2}>
            {item.details}
          </Text>
        )}
      </View>
      <ThreeDotsSettingsButton
        extraStyles={{
          position: 'absolute',
          right: 0,
          top: 0,
        }}
        onPress={() => onThreeDotsSettingsPress(item)}
      />
    </Pressable>
  );
};

export default ExpassCard;

const RenderCachedImage = ({
  item,
  isResponsive,
  type,
  hovered,
  tileWidth,
  tileHeight,
  resizeMode,
}) => {
  return (
    <CachedImage
      isBackground
      isResponsive={isResponsive}
      item={item}
      type={type}
      hovered={hovered}
      noImageOnError={true}
      source={{
        uri: item?.thumbnailImage !== '' ? item?.thumbnailImage : item?.image,
      }}
      isThumbnailImage={item?.thumbnailImage !== '' ? true : false}
      imageStyle={{ borderRadius: defaultTheme.CONTENT_RADIUS }}
      style={{
        maxWidth: isResponsive ? '100%' : tileWidth - 12,
        minHeight: tileHeight !== null ? tileHeight : tileWidth - 60,
        aspectRatio: tileHeight !== null ? tileWidth / tileHeight : 41 / 25,
        borderRadius: defaultTheme.CONTENT_RADIUS,
        borderWidth: 1,
        borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
      }}
      contentFit={Platform.OS == 'android' ? 'cover' : resizeMode}
      imageSize={item?.imageSize}
      thumbnailImageSize={item?.thumbnailImageSize}
      optimizedImageSize={item?.optimizedImageSize}></CachedImage>
  );
};

const PassportPriceComponent = ({
  type,
  item,
  updateBuyPrice,
  onPriceTagPress,
}) => {
  const dispatch = useAppDispatch();
  const [latestBuyPrice, setLatestBuyPrice] = useState(
    type === CatalogTypes.PREMIUMCHAT ? item?.price : item?.buyPrice,
  );

  useEffect(() => {
    if (updateBuyPrice) {
      (async () => {
        // Fetch latest price before proceeding for purchase
        const buyPrice = await getPassportFinalBuyPrice(item?.address);
        dispatch(
          updatePassportBuyPrice({
            address: item?.address,
            buyPrice: buyPrice,
          }),
        );
        setLatestBuyPrice(buyPrice);
      })();
    }

    let event: any = DeviceEventEmitter;
    if (Platform.OS === 'android') {
      event = new NativeEventEmitter();
    }
    event.addListener(CustomDeviceEvents.ExPassPriceUpdated, async e => {
      const localStoredData = await getData(
        APP_STORAGE_GET_COLLECTIBLEDETAILS(item.address),
      );
      if (localStoredData) {
        setLatestBuyPrice(localStoredData.buyPrice);
      }
    });
    return () =>
      event.removeAllListeners(CustomDeviceEvents.ExPassPriceUpdated);
  }, []);

  useEffect(() => {
    // update local price state when item state changes
    setLatestBuyPrice(
      type === CatalogTypes.PREMIUMCHAT ? item?.price : item?.buyPrice,
    );
  }, [item?.buyPrice, item?.price]);

  return (
    <GradientPrice
      onPress={onPriceTagPress}
      price={formatPriceUpToTrillion(latestBuyPrice)}
    />
  );
};
