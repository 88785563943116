import { Wallet, ethers } from 'ethers';
import { getSyncedTime } from './DateHelper';
import { Buffer } from 'buffer';
import { BATCHIFY_LAMBDA_URL, MASTER_KEY, NETWORKS } from '../appconstants';
import { IBatchRequest } from '../interfaces/IBatchRequest';
import { IBatchResponse } from '../interfaces/IBatchResponse';
import { RequestFunctionType } from '../enums/batchRequest.enum';

export const separator: Buffer = Buffer.from([0x01]);
let __nextId = 0;

const getMergedRequest = async (reqMethod: string, path: string, body: any, timestamp: number) => {
  const chunks: Buffer[] = [];

  chunks.push(Buffer.from(reqMethod, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from('/' + path, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(timestamp.toString(), 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(body, 'utf-8'));

  return Buffer.concat(chunks);
};

export const getRequestHeaders = async (
  reqMethod: string,
  path: string,
  body: any,
  timestamp: number,
  wallet: Wallet,
) => {
  if (timestamp === undefined || timestamp === null) {
    timestamp = getSyncedTime();
  }

  const headers: any = {};
  headers['Content-Type'] = 'application/json';
  headers['X-Loot8-Timestamp'] = timestamp?.toString();

  let mergedRequest = await getMergedRequest(reqMethod, path, body ? body : '{}', timestamp);
  if (wallet) {
    const accSignature = await wallet.signMessage(mergedRequest);
    headers['X-Loot8-Signature'] = accSignature;

    mergedRequest = Buffer.concat([Buffer.from(accSignature, 'utf-8')]);
  }

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(mergedRequest);
  headers['X-Loot8-MasterSignature'] = masterSignature;

  return headers;
};

export const batchExecuteCalls = async (batchRequests: Array<IBatchRequest>): Promise<Array<IBatchResponse>> => {
  let requests = [];
  let responses = [];
  let reqInterfaces = new Map(); // for decoding
  let reqFunctionNames = new Map(); // for decoding
  let reqQueryFilters = new Map(); // for decide decoding needed or not

  batchRequests.map((batchRequest: IBatchRequest) => {
    let payload = null;
    if (batchRequest.functionType === RequestFunctionType.QUERYFILTER) {
      if (batchRequest.queryFilterParams?.fromBlock) {
        //skip request in case fromBlock is not available
        payload = {
          id: ++__nextId,
          jsonrpc: '2.0',
          method: 'eth_getLogs',
          params: [
            {
              address: batchRequest.contractAddress,
              fromBlock: batchRequest.queryFilterParams?.fromBlock,
              toBlock: batchRequest.queryFilterParams?.toBlock ? batchRequest.queryFilterParams?.toBlock : 'latest',
              topics: batchRequest.queryFilterParams?.topics ? batchRequest.queryFilterParams?.topics : [],
            },
          ],
        };
      }
    } else if (batchRequest.functionType === RequestFunctionType.TRANSACTION_RECEIPT) {
      payload = {
        id: ++__nextId,
        jsonrpc: '2.0',
        method: 'eth_getTransactionReceipt',
        params: batchRequest.transactionReceiptParams,
      };
    } else if (batchRequest.functionType === RequestFunctionType.BLOCK_BY_HASH) {
      payload = {
        id: ++__nextId,
        jsonrpc: '2.0',
        method: 'eth_getBlockByHash',
        params: batchRequest.blockByHashParams,
      };
    } else {
      const encodedData = batchRequest.interface.encodeFunctionData(batchRequest.functionName, batchRequest.params);

      payload = {
        id: ++__nextId,
        jsonrpc: '2.0',
        method: 'eth_call',
        params: [
          {
            to: batchRequest.contractAddress,
            data: encodedData,
          },
          'latest',
        ],
      };
    }

    if (payload) {
      requests.push({
        id: batchRequest.id,
        method: 'POST',
        url: NETWORKS[batchRequest.chainId].uri(),
        headers: {
          'Content-Type': 'application/json',
        },
        body: Buffer.from(JSON.stringify(payload)).toString('base64'),
      });

      // for decoding
      reqInterfaces.set(batchRequest.id, batchRequest.interface);
      reqFunctionNames.set(batchRequest.id, batchRequest.functionName);
      reqQueryFilters.set(batchRequest.id, batchRequest.functionType);
    }
  });

  const response = await fetch(`${BATCHIFY_LAMBDA_URL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ requests: requests }),
  });

  if (response.status == 200) {
    const responseJSON = await response.json();
    responseJSON?.responses.map((response: any) => {
      if (response.statusCode == 200) {
        let respId = response.id;
        let resp = JSON.parse(Buffer.from(response.body, 'base64').toString());
        let decodedResponse =
          reqQueryFilters.get(respId) === RequestFunctionType.QUERYFILTER ||
          reqQueryFilters.get(respId) === RequestFunctionType.BLOCK_BY_HASH ||
          reqQueryFilters.get(respId) === RequestFunctionType.TRANSACTION_RECEIPT
            ? resp?.result
            : reqInterfaces.get(respId).decodeFunctionResult(reqFunctionNames.get(respId), resp?.result);
        responses.push({
          id: respId,
          response: decodedResponse,
        });
      }
    });
  }

  return responses;
};
