import { BlurView } from 'expo-blur';
import { LinearGradient } from 'expo-linear-gradient';
import React, { memo, useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import ActionSheet from 'react-native-actions-sheet';
import QRcode from 'react-native-qrcode-svg';
import logoImg from '../../../../assets/icon.png';
import { LogCustomError } from '../../../../helpers/AppLogger';
import { useInterval } from '../../../../hooks/useInterval';
import { useWeb3AuthContext } from '../../../../hooks/web3authContext';
import { ICollectibleDetail } from '../../../../interfaces/ICollectibleDetail.interface';
import styles from '../../../../styles';
import { defaultTheme } from '../../../../themes/loot8';
import { Event__factory } from '../../../../typechain';
import { ReShareWebBlurView } from '../../../WebBlurView';
import { wait } from '../../../../helpers/ipfs';
import { getTicketTypedData } from '../../../../helpers/MsgHelper';
import Icon from 'react-native-vector-icons/Entypo';
import { TouchableRipple } from 'react-native-paper';

interface Props {
  sheetId: string;
  payload: {
    eventAddress: string;
    ticket: ICollectibleDetail;
  };
}

const REFRESH_INTERVAL = 30;

const RedeemTicketSheet = (props: Props) => {
  return (
    <>
      <ActionSheet
        drawUnderStatusBar
        containerStyle={{
          ...styles.maxWidthAdjust,
          backgroundColor: 'transparent',
          overflow: 'hidden',
        }}
        gestureEnabled={true}
        backgroundInteractionEnabled={false}
        closeOnTouchBackdrop={true}>
        {Platform.OS !== 'web' ? (
          <BlurView
            tint="light"
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}
            intensity={Platform.OS == 'android' ? 15 : 25}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <ActionSheetContent {...props} />
            </LinearGradient>
          </BlurView>
        ) : (
          <ReShareWebBlurView>
            <View
              style={{
                borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
                borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
                overflow: 'hidden',
                backgroundColor: 'rgba(0,0,0,0.2)',
              }}>
              <LinearGradient
                colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
                start={{ x: 0.2, y: 0 }}
                end={{ x: 1, y: 1 }}
                style={{ ...styles.maxWidthAdjust }}>
                <ActionSheetContent {...props} />
              </LinearGradient>
            </View>
          </ReShareWebBlurView>
        )}
      </ActionSheet>
    </>
  );
};

const ActionSheetContent = memo(
  ({ payload: { eventAddress, ticket } }: Props) => {
    const { address, wallet, staticProvider, networkId } = useWeb3AuthContext();

    const [redeemable, setRedeemable] = useState('');
    const [loading, setLoading] = useState(true);

    //* Build data on mount
    useEffect(() => {
      buildQRCode();
    }, []);

    //* Refresh data every 30 seconds
    useInterval(buildQRCode, REFRESH_INTERVAL * 1000);

    async function buildQRCode() {
      try {
        setLoading(true);
        await wait(1000);

        //? QR Code metadata
        const metadata = {
          ticketAddress: ticket.address,
          ticketId: ticket.tokenId,
          userAddress: address,
          timestamp: 0,
          signature: '',
        };

        const eventContract = Event__factory.connect(
          eventAddress,
          staticProvider,
        );

        //? Signature metadata
        const message = {
          event: eventAddress,
          ticket: ticket.address,
          ticketId: ticket.tokenId,
          owner: address,
          timestamp: 0,
          message: '',
        };

        const redemptionMessage = await eventContract.redemptionMessage();
        const { domain, types } = getTicketTypedData({ networkId });
        const timestamp = Math.floor(Date.now() / 1000);

        message['timestamp'] = timestamp;
        message['message'] = redemptionMessage;

        const signature = await wallet._signTypedData(domain, types, message);

        metadata['timestamp'] = timestamp;
        metadata['signature'] = signature;

        setRedeemable(JSON.stringify(metadata));
      } catch (error) {
        LogCustomError(
          '~ buildQRCode-' + ticket.address,
          error?.method,
          error?.reason,
          ' ',
        );
      } finally {
        setLoading(false);
      }
    }

    return (
      <View
        style={{
          alignItems: 'center',
          paddingVertical: 12,
          paddingHorizontal: '15%',
        }}>
        <View style={{ alignItems: 'center' }}>
          <Text style={componentStyles.heading}>Redeem QR Code</Text>
          <Text style={componentStyles.text}>
            This QR code will expire in 30 seconds and will refresh
            automatically after that.
          </Text>
        </View>
        <View style={componentStyles.qrContainer}>
          {loading ? (
            <View style={{ width: 200, height: 200, justifyContent: 'center' }}>
              <ActivityIndicator
                size="large"
                color={defaultTheme.PRIMARY_TEXT_COLOR}
              />
            </View>
          ) : (
            <QRcode
              value={redeemable}
              size={200}
              color="black"
              quietZone={10}
              backgroundColor="white"
              logo={logoImg}
              logoSize={50}
              logoBorderRadius={10}
              onError={(error: any) =>
                LogCustomError(
                  '~ QRGeneratorPackage',
                  error,
                  error?.message,
                  ' ',
                )
              }
            />
          )}
        </View>

        <TouchableRipple
          onPress={buildQRCode}
          rippleColor={'rgba(0,0,0,0.25)'}
          style={componentStyles.button}>
          <>
            <Text style={componentStyles.buttonText}>Refresh</Text>
            <Icon
              name="cycle"
              size={14}
              color={defaultTheme.PRIMARY_TEXT_COLOR}
            />
          </>
        </TouchableRipple>

        <Text
          style={[componentStyles.text, { marginBottom: 14, marginTop: 0 }]}>
          Show this QR code to the gatekeeper to scan for event entry.
        </Text>
      </View>
    );
  },
);

export default memo(RedeemTicketSheet);

const componentStyles = StyleSheet.create({
  qrContainer: {
    marginVertical: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  },
  heading: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
    textAlign: 'center',
  },
  text: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    textAlign: 'center',
    marginVertical: 8,
    lineHeight: 18,
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: 150,
    height: 36,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    marginBottom: 16,
  },
  buttonText: {
    lineHeight: 18,
    fontSize: 14,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    marginRight: 12,
  },
});
