import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import {
  APP_STORAGE_GET_THIRDPARTYCOLLECTDETAILS,
  getAnynetStaticProvider,
  getNetworkID,
} from '../appconstants';
import { LogCustomError } from '../helpers/AppLogger';
import { showToastMessage } from '../helpers/Gadgets';
import {
  GetThirdPartyContractMintMessage,
  GetTransferNFTCallLogsData,
} from '../helpers/MsgHelper';
import { RootState } from '../store';
import { SendMetaTX } from './AppSlice';
import { setAll } from './helpers';
import {
  IMessageMetaData,
  IThirdPartyCollectiblesAsyncThunk,
} from './interfaces';
import { storeData } from '../helpers/AppStorage';

export const mintThirdPartyCollectibles = createAsyncThunk(
  'thirdPartyCollectibles/mintThirdPartyCollectibles',
  async (
    {
      key,
      contract,
      chain,
      mintFunc,
      types,
      args,
      isScanCall = false,
    }: IThirdPartyCollectiblesAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    const networkID = getNetworkID(Number(chain));
    const provider = getAnynetStaticProvider(networkID);
    const wallet = new ethers.Wallet(key, provider);
    const recipientAddress = await wallet.getAddress();
    const toAddressIndex = args.indexOf('to');

    // clear cache
    await storeData(
      APP_STORAGE_GET_THIRDPARTYCOLLECTDETAILS(contract, chain),
      null,
    );

    if (types[toAddressIndex] == 'address') {
      args[toAddressIndex] = recipientAddress;
    } else {
      // Handle type error through config
    }

    types = types.map((type: string) => {
      return type == 'number' ? 'uint256' : type;
    });
    let data = GetThirdPartyContractMintMessage(mintFunc, types, args);
    let msg: IMessageMetaData = {
      to: contract,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider,
      checkUserDetail: !isScanCall,
    };
    const waitTransaction = await dispatch(SendMetaTX(msg));
    let transactionCompleted = false;
    let createdTokenId = '';
    if (
      waitTransaction.payload.status &&
      waitTransaction.payload.status.toString().toLowerCase() == 'success'
    ) {
      transactionCompleted = true;

      if (waitTransaction.payload?.eventLogs) {
        createdTokenId = GetTransferNFTCallLogsData(
          waitTransaction.payload.eventLogs,
        );
      }
    }

    return {
      isCollectibleMinted: transactionCompleted,
      createdTokenId: createdTokenId,
    };
  },
);

export interface IThirdPartyCollectiblesSliceData {
  readonly loading: boolean;
}

const initialState: IThirdPartyCollectiblesSliceData = {
  loading: false,
};

const ThirdPartyCollectiblesSlice = createSlice({
  name: 'ThirdPartyCollectibles',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        mintThirdPartyCollectibles.pending,
        (state: { loading: boolean }) => {
          state.loading = true;
        },
      )
      .addCase(
        mintThirdPartyCollectibles.fulfilled,
        (state: { loading: boolean }) => {
          state.loading = false;
        },
      )
      .addCase(
        mintThirdPartyCollectibles.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          showToastMessage();
          LogCustomError(
            'mintThirdPartyCollectibles',
            error.name,
            error.message,
            error.stack,
          );
        },
      );
  },
});

export const ThirdPartyCollectiblesSliceReducer =
  ThirdPartyCollectiblesSlice.reducer;

const baseInfo = (state: RootState) => state.ThirdPartyCollectibles;

export const { fetchAppSuccess } = ThirdPartyCollectiblesSlice.actions;

export const getThirdPartyCollectiblesState = createSelector(
  baseInfo,
  ThirdPartyCollectiblesSlice => ThirdPartyCollectiblesSlice,
);
