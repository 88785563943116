import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';

const useActiveDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    Dimensions.get('window'),
  );

  const handleDimensionsChange = ({ window }) => {
    setWindowDimensions(window);
  };

  useEffect(() => {
    // Subscribe to dimension changes
    const dimensionsHandler = Dimensions.addEventListener(
      'change',
      handleDimensionsChange,
    );

    // Cleanup the subscription when the component unmounts
    return () => {
      dimensionsHandler.remove();
    };
  }, []); // Empty dependency array ensures that the effect runs only once (on mount)

  return windowDimensions;
};

export default useActiveDimensions;
