import React, { useEffect, useState, memo, useMemo } from 'react';
import {
  Text,
  View,
  Image,
  Platform,
  Pressable,
  StyleSheet,
  ImageResizeMode,
  DeviceEventEmitter,
  NativeEventEmitter,
} from 'react-native';
import useStateRef from 'react-usestateref';
import { ImageContentFit } from 'expo-image';
import { SheetManager } from 'react-native-actions-sheet';

import {
  getTruncatedName,
  formatPriceUpToTrillion,
} from '../../../helpers/Gadgets';
import {
  updatePassportBuyPrice,
  getPassportFinalBuyPrice,
} from '../../../slices/PassportSlice';
import CachedImage from '../../CachedImage';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import GradientPrice from '../../GradientPrice';
import { defaultTheme } from '../../../themes/loot8';
import { getData } from '../../../helpers/AppStorage';
import { CatalogTypes } from '../../../enums/catalog.enum';
import useIsResponsive from '../../../hooks/useIsResponsive';
import { PassportType } from '../../../enums/passportCategory.enum';
import { getMarketplaceListingsData } from '../../../helpers/MarketPlace';
import { APP_STORAGE_GET_COLLECTIBLEDETAILS } from '../../../appconstants';
import { CustomDeviceEvents, ScreenName } from '../../../enums/screen.enum';
import { useWeb3AuthContext } from '../../../hooks/web3authContext';
import { removeUnlistedCollectible } from '../../../slices/MarketPlaceSlice';

const isIOS = Platform.OS === 'ios';
const isWeb = Platform.OS === 'web';
const DEFAULT_PAGE_SIZE = isWeb ? 12 : 6;

interface RenderCachedImageProps {
  item: any;
  isResponsive: boolean;
  type?: CatalogTypes;
  hovered?: boolean;
  tileWidth: number;
  tileHeight: number;
  resizeMode: ImageContentFit & ImageResizeMode;
}

const RenderCachedImage: React.FC<RenderCachedImageProps> = memo(
  ({
    item,
    type,
    hovered,
    tileWidth,
    tileHeight,
    resizeMode,
    isResponsive,
  }) => {
    const imageURI =
      item?.thumbnailImage !== '' ? item?.thumbnailImage : item?.image;
    const defaultStyle = {
      borderWidth: 1,
      borderRadius: defaultTheme.CONTENT_RADIUS,
      borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
      aspectRatio: tileHeight !== null ? tileWidth / tileHeight : 41 / 25,
    };

    return isIOS ? (
      <Image
        source={{
          uri: imageURI,
        }}
        style={{
          ...defaultStyle,
          width: isResponsive ? '100%' : tileWidth - 12,
          height: tileHeight !== null ? tileHeight : tileWidth - 60,
        }}
        resizeMode={resizeMode}
        borderRadius={defaultTheme.CONTENT_RADIUS}
      />
    ) : (
      <CachedImage
        isBackground
        isResponsive={isResponsive}
        item={item}
        type={type}
        hovered={hovered}
        noImageOnError
        source={{
          uri: imageURI,
        }}
        isThumbnailImage={item?.thumbnailImage !== '' ? true : false}
        imageStyle={{ borderRadius: defaultTheme.CONTENT_RADIUS }}
        style={{
          ...defaultStyle,
          maxWidth: isResponsive ? '100%' : tileWidth - 12,
          minHeight: tileHeight !== null ? tileHeight : tileWidth - 60,
        }}
        contentFit={resizeMode}
        imageSize={item?.imageSize}
        thumbnailImageSize={item?.thumbnailImageSize}
        optimizedImageSize={item?.optimizedImageSize}
      />
    );
  },
);

const PassportPriceComponent = memo(
  ({ item, updateBuyPrice, onPriceTagPress }) => {
    const dispatch = useAppDispatch();
    const [latestBuyPrice, setLatestBuyPrice] = useState(item?.price);

    useEffect(() => {
      if (updateBuyPrice) {
        (async () => {
          // Fetch latest price before proceeding for purchase
          const buyPrice = await getPassportFinalBuyPrice(item?.address);
          dispatch(
            updatePassportBuyPrice({
              address: item?.address,
              buyPrice: buyPrice,
            }),
          );
          setLatestBuyPrice(buyPrice);
        })();
      }

      let event: any = DeviceEventEmitter;
      if (Platform.OS === 'android') {
        event = new NativeEventEmitter();
      }
      event.addListener(CustomDeviceEvents.ExPassPriceUpdated, async () => {
        const localStoredData = await getData(
          APP_STORAGE_GET_COLLECTIBLEDETAILS(item.address),
        );
        if (localStoredData) {
          setLatestBuyPrice(localStoredData.buyPrice);
        }
      });
      return () =>
        event.removeAllListeners(CustomDeviceEvents.ExPassPriceUpdated);
    }, []);

    useEffect(() => {
      // update local price state when item state changes
      setLatestBuyPrice(item?.price);
    }, [item?.buyPrice, item?.price]);

    return (
      <GradientPrice
        onPress={onPriceTagPress}
        price={formatPriceUpToTrillion(latestBuyPrice)}
      />
    );
  },
);

const MPCollectibleItem = memo(({ item, navigation }) => {
  const dispatch = useAppDispatch();
  const isResponsive = useIsResponsive();
  const { address } = useWeb3AuthContext();

  const onPriceTagPress = async () => {
    if (item.passportType === PassportType.SUBSCRIBED) {
      const isListed =
        item &&
        item?.collectibleIds.length > 0 &&
        item?.marketPlaceConfig?.listingIndex &&
        item?.marketPlaceConfig?.listingIndex?.findIndex(
          obj => obj.tokenId === Number(item?.collectibleIds[0]),
        ) !== -1
          ? true
          : false;
      if (isListed) {
        const data = await getMarketplaceListingsData(
          'name',
          'DESC',
          0,
          DEFAULT_PAGE_SIZE,
          false,
          item.name.toLowerCase().trim(),
        );
        if (data) {
          //data will have all expasses data which matches the name of the expass user clicked on
          // filter and match token id to get exact expass we need to send in navigation params
          const filteredData = data.filter(x => item.tokenId === x.tokenId);
          let sellerData = null;
          if (item) {
            // pre processing and making obj for next screen with required keys
            const collectibleData = {
              ...filteredData[0],
              collectionData: item,
              sellerData: sellerData,
            };
            navigation.navigate(ScreenName.WALLET_TAB, {
              screen: 'MarketPlaceUnlistCollection',
              params: {
                collection: collectibleData,
                type: CatalogTypes.PASSPORT,
                fromHome: true,
              },
            });
          }
        }
      } else {
        // onListForSalePressed(item);
      }
    }
  };

  const hideSubscribePassportsModal = () => {
    if (Platform.OS === 'web') {
      window.history.replaceState(null, '', '/');
    }
  };

  const [searchText, setSearchPassportText, searchPassportTextRef] =
    useStateRef('');

  const addressLowerCase = address ? address.toLocaleLowerCase() : '';

  const listedBySelf = useMemo(() => {
    const sellerLowerCase = item?.seller?.toLocaleLowerCase() || '';
    return sellerLowerCase === addressLowerCase;
  }, [item?.seller, addressLowerCase]);

  const handleClick = async () => {
    if (listedBySelf) {
      SheetManager.show('ListingModalSheet', {
        payload: {
          itemObject: { ...item.collectionData, fromMarketPlace: true },
          navigation,
          type: 'Collectible',
          updateStateDataFunc: (updatedObj) => {
            dispatch(removeUnlistedCollectible(updatedObj));
          },
        },
      });
      return;
    }
    await SheetManager.show('SubscribePassportSheet', {
      payload: {
        hideSubscribePassportsModal: hideSubscribePassportsModal,
        selectedEXPass: item,
        setSearchPassportText: setSearchPassportText,
        navigation,
        collectionType: CatalogTypes.COLLECTIBLES,
        type: CatalogTypes.COLLECTIBLES,
        forMarketPlace: true,
      },
    });
  };

  return (
    <Pressable
      onPress={handleClick}
      style={[localStyle.container, { flex: isResponsive ? 0.49 : 1 }]}>
      {listedBySelf && (
        <View style={localStyle.mpCollectibleTag}>
          <Text style={localStyle.mpCollectibleTagCaption}>
            {'Listed by You'}
          </Text>
        </View>
      )}
      <RenderCachedImage
        item={item?.collectionData}
        isResponsive={false}
        resizeMode={'cover'}
        tileWidth={144}
        tileHeight={114}
      />
      <View style={localStyle.right}>
        <Text style={localStyle.title} numberOfLines={2}>
          {getTruncatedName(item?.collectionData?.name, 20)}
        </Text>
        <PassportPriceComponent
          onPriceTagPress={onPriceTagPress}
          type={CatalogTypes.COLLECTIBLES}
          item={item}
          updateBuyPrice={false}
        />
        {item.subtitle && (
          <Text style={localStyle.subtitle} numberOfLines={1}>
            {item?.collectionData?.subtitle}
          </Text>
        )}
        {item.details && (
          <Text style={localStyle.description} numberOfLines={4}>
            {item?.collectionData?.details}
          </Text>
        )}
      </View>
    </Pressable>
  );
});

export default MPCollectibleItem;

const localStyle = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    height: 124,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 7.5,
    shadowColor: '#00000040',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
    elevation: 15,
  },
  right: {
    marginLeft: 10,
    marginTop: 10,
    flex: 1,
    alignSelf: 'flex-start',
  },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XMEDIUM + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textTransform: 'capitalize',
    width: '96%',
    marginBottom: 5,
  },
  subtitle: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textTransform: 'capitalize',
  },
  description: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXXSMALL + 2,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textTransform: 'capitalize',
    marginTop: 7.5,
  },
  mpCollectibleTag: {
    top: 14,
    left: 12,
    padding: 4,
    zIndex: 99,
    opacity: 0.8,
    borderWidth: 1,
    borderRadius: 13,
    position: 'absolute',
    borderColor: '#FFFFFF',
    backgroundColor: defaultTheme.CARD_BG_COLOR,
  },
  mpCollectibleTagCaption: {
    fontSize: 8,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRALIGHT,
  },
});
