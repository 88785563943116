import React from 'react';
import Svg, {
  G,
  Path,
  Line,
  Defs,
  Stop,
  Circle,
  LinearGradient,
  Rect,
  ClipPath,
  Mask,
} from 'react-native-svg';

export function EditIconSvg() {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <Circle
        cx="12"
        cy="12"
        r="11.5"
        fill="url(#paint0_linear_0_1)"
        stroke="white"
      />
      <Path
        d="M7 18V15.4028L15.0667 7.35139C15.1889 7.23935 15.3239 7.15278 15.4718 7.09167C15.6197 7.03056 15.7749 7 15.9375 7C16.1005 7 16.2583 7.03056 16.4111 7.09167C16.5639 7.15278 16.6963 7.24444 16.8083 7.36667L17.6486 8.22222C17.7708 8.33426 17.8601 8.46667 17.9163 8.61944C17.9725 8.77222 18.0004 8.925 18 9.07778C18 9.24074 17.9721 9.39617 17.9163 9.54406C17.8605 9.69194 17.7712 9.8268 17.6486 9.94861L9.59722 18H7ZM15.9222 9.93333L16.7778 9.07778L15.9222 8.22222L15.0667 9.07778L15.9222 9.93333Z"
        fill="white"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_0_1"
          x1="12"
          y1="0"
          x2="12"
          y2="24"
          gradientUnits="userSpaceOnUse">
          <Stop stopColor="#F600DD" />
          <Stop offset="1" stopColor="#9A4DFF" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}

export const CameraSvg = () => {
  return (
    <Svg width="40" height="36" viewBox="0 0 26 26" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3336 22H14.6664C18.4116 22 20.2848 22 21.63 21.1017C22.2104 20.7142 22.7103 20.2142 23.1012 19.6301C24 18.2857 24 16.412 24 12.6671C24 8.92103 24 7.04859 23.1012 5.70415C22.7104 5.12007 22.2104 4.62005 21.63 4.2326C20.766 3.65449 19.6836 3.44794 18.0264 3.3746C17.2356 3.3746 16.5552 2.77572 16.4004 1.99961C16.2821 1.43111 15.9747 0.921654 15.5302 0.557329C15.0857 0.193003 14.5313 -0.00384365 13.9608 5.68708e-05H10.0392C8.8536 5.68708e-05 7.8324 0.837277 7.5996 1.99961C7.4448 2.77572 6.7644 3.3746 5.9736 3.3746C4.3176 3.44794 3.2352 3.65571 2.37 4.2326C1.78994 4.62012 1.29045 5.12014 0.9 5.70415C0 7.04859 0 8.92103 0 12.6671C0 16.412 7.15256e-08 18.2845 0.8988 19.6301C1.2876 20.2119 1.7868 20.7118 2.37 21.1017C3.7152 22 5.5884 22 9.3336 22ZM12 7.66704C9.2388 7.66704 6.9996 9.90492 6.9996 12.6659C6.9996 15.4281 9.2388 17.6672 12 17.6672C14.7612 17.6672 17.0004 15.4281 17.0004 12.6671C17.0004 9.90492 14.7612 7.66704 12 7.66704ZM12 9.66659C10.344 9.66659 9 11.0098 9 12.6671C9 14.3232 10.344 15.6665 12 15.6665C13.656 15.6665 15 14.3232 15 12.6671C15 11.0098 13.656 9.66659 12 9.66659ZM17.6664 8.66681C17.6664 8.11437 18.114 7.66704 18.6672 7.66704H19.9992C20.5512 7.66704 21 8.11437 21 8.66681C20.9975 8.93435 20.8908 9.18993 20.7033 9.37739C20.5159 9.56485 20.2631 9.66887 20.0004 9.66659H18.6672C18.537 9.66788 18.4079 9.64304 18.2871 9.5935C18.1664 9.54395 18.0564 9.47066 17.9635 9.37782C17.8706 9.28499 17.7965 9.17441 17.7455 9.05242C17.6945 8.93042 17.6677 8.79939 17.6664 8.66681Z"
        fill="white"
      />
    </Svg>
  );
};

export const GallerySvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M22.2851 8.59349C22.2851 9.41471 21.9589 10.2023 21.3782 10.783C20.7975 11.3637 20.0099 11.6899 19.1887 11.6899C18.3675 11.6899 17.5799 11.3637 16.9992 10.783C16.4185 10.2023 16.0923 9.41471 16.0923 8.59349C16.0923 7.77227 16.4185 6.98468 16.9992 6.40399C17.5799 5.8233 18.3675 5.49707 19.1887 5.49707C20.0099 5.49707 20.7975 5.8233 21.3782 6.40399C21.9589 6.98468 22.2851 7.77227 22.2851 8.59349Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7764 0H15.9438C19.3358 0 21.9932 8.75592e-08 24.0674 0.27911C26.1887 0.564096 27.8633 1.16051 29.1781 2.4738C30.4928 3.78855 31.0878 5.46321 31.3728 7.58592C31.6519 9.65868 31.6519 12.3161 31.6519 15.708V15.8373C31.6519 18.6416 31.6519 20.9362 31.4991 22.8048C31.3463 24.6851 31.0334 26.2525 30.3312 27.557C30.0227 28.1314 29.6408 28.647 29.1781 29.1097C27.8633 30.4245 26.1887 31.0194 24.0659 31.3044C21.9932 31.5835 19.3358 31.5835 15.9438 31.5835H15.7764C12.3845 31.5835 9.72704 31.5835 7.65281 31.3044C5.53157 31.0194 3.85691 30.423 2.54216 29.1097C1.37724 27.9448 0.774949 26.4949 0.456176 24.6939C0.14034 22.9267 0.0830493 20.7276 0.0712973 17.9982C0.0683593 17.3034 0.0683594 16.5689 0.0683594 15.7932V15.708C0.0683594 12.3161 0.0683595 9.65868 0.34747 7.58445C0.632456 5.46321 1.22887 3.78855 2.54216 2.4738C3.85691 1.15904 5.53157 0.564096 7.65428 0.27911C9.72704 8.75592e-08 12.3845 0 15.7764 0ZM7.94661 2.46204C6.06923 2.71471 4.93663 3.19654 4.10076 4.03241C3.26343 4.86974 2.78307 6.00087 2.5304 7.87972C2.2748 9.78942 2.27186 12.2985 2.27186 15.7918V17.0316L3.74233 15.7447C4.38733 15.1801 5.22282 14.8818 6.07956 14.9102C6.93629 14.9385 7.75022 15.2915 8.35646 15.8975L14.6585 22.1995C15.1475 22.6885 15.7933 22.9892 16.4823 23.0489C17.1713 23.1085 17.8592 22.9233 18.425 22.5256L18.8627 22.2172C19.679 21.6436 20.6658 21.3639 21.6617 21.424C22.6576 21.484 23.6036 21.8802 24.3451 22.5477L28.5023 26.2892C28.9225 25.4108 29.1707 24.2561 29.3029 22.627C29.4469 20.8569 29.4484 18.6504 29.4484 15.7918C29.4484 12.2985 29.4454 9.78942 29.1898 7.87972C28.9372 6.00087 28.4553 4.86827 27.6195 4.03094C26.7821 3.19508 25.651 2.71471 23.7721 2.46204C21.8624 2.20644 19.3534 2.2035 15.8601 2.2035C12.3668 2.2035 9.85631 2.20644 7.94661 2.46204Z"
        fill="white"
      />
    </Svg>
  );
};

export const AvatarPlaceHolderSvg = () => {
  return (
    <Svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <Path
        d="M28.2933 25.8644C29.9144 23.9156 31.0419 21.6053 31.5804 19.129C32.1189 16.6528 32.0525 14.0833 31.387 11.638C30.7214 9.19279 29.4762 6.94367 27.7568 5.08095C26.0373 3.21822 23.8941 1.7967 21.5085 0.936624C19.1229 0.0765495 16.565 -0.196771 14.0514 0.139783C11.5377 0.476337 9.14209 1.41286 7.06726 2.87014C4.99244 4.32742 3.2994 6.26257 2.13138 8.5119C0.963361 10.7612 0.354718 13.2586 0.35694 15.7926C0.357792 19.4764 1.65702 23.0423 4.02663 25.8644L4.00406 25.8836C4.08307 25.9783 4.17337 26.0596 4.25465 26.1532C4.35624 26.2693 4.46573 26.3787 4.57071 26.4915C4.88677 26.8344 5.21186 27.1637 5.55275 27.4728C5.6566 27.5675 5.76384 27.6555 5.86881 27.7457C6.23003 28.057 6.6014 28.3525 6.98631 28.6277C7.03598 28.6615 7.08113 28.7055 7.1308 28.7405V28.7269C9.77454 30.5859 12.9284 31.5835 16.1611 31.5835C19.3938 31.5835 22.5477 30.5859 25.1914 28.7269V28.7405C25.2411 28.7055 25.2851 28.6615 25.3359 28.6277C25.7197 28.3514 26.0922 28.057 26.4534 27.7457C26.5584 27.6555 26.6656 27.5664 26.7695 27.4728C27.1104 27.1626 27.4354 26.8344 27.7515 26.4915C27.8565 26.3787 27.9648 26.2693 28.0676 26.1532C28.1477 26.0596 28.2391 25.9783 28.3182 25.8825L28.2933 25.8644ZM16.16 6.76972C17.1646 6.76972 18.1467 7.06739 18.982 7.62508C19.8174 8.18277 20.4684 8.97543 20.8529 9.90284C21.2373 10.8302 21.3379 11.8507 21.1419 12.8353C20.9459 13.8198 20.4622 14.7241 19.7518 15.4339C19.0414 16.1437 18.1363 16.6271 17.1509 16.823C16.1656 17.0188 15.1443 16.9183 14.2161 16.5341C13.288 16.15 12.4946 15.4995 11.9365 14.6648C11.3783 13.8302 11.0804 12.8489 11.0804 11.8451C11.0804 10.499 11.6156 9.20808 12.5682 8.25626C13.5208 7.30445 14.8128 6.76972 16.16 6.76972ZM7.13757 25.8644C7.15715 24.3835 7.75948 22.9698 8.81423 21.9292C9.86898 20.8887 11.2913 20.3048 12.7736 20.3041H19.5463C21.0286 20.3048 22.451 20.8887 23.5057 21.9292C24.5605 22.9698 25.1628 24.3835 25.1824 25.8644C22.7068 28.0935 19.4925 29.3271 16.16 29.3271C12.8275 29.3271 9.61318 28.0935 7.13757 25.8644Z"
        fill="white"
      />
    </Svg>
  );
};
