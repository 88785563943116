import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    height: 124,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 7.5,
    shadowColor: '#00000040',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
    elevation: 15,
  },
  right: {
    marginLeft: 10,
    marginTop: 10,
    flex: 1,
    alignSelf: 'flex-start',
  },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XMEDIUM + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textTransform: 'capitalize',
    width: '75%',
    marginBottom: 5,
  },
  subtitle: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textTransform: 'capitalize',
    marginBottom: 5,
  },
  description: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXXSMALL + 2,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textTransform: 'capitalize',
    marginTop: 7.5,
  },
});
