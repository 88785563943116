import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  View,
  Pressable,
  Platform,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  Clipboard,
  Keyboard,
  TouchableWithoutFeedback,
  StyleSheet,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import { FlatList, ScrollView } from 'react-native-gesture-handler';
import styles from '../../styles';
import { BlurView } from 'expo-blur';
import { ReShareWebBlurView } from '../WebBlurView';
import { useAppSelector } from '../../hooks';
import { Avatar, TextInput, Title } from 'react-native-paper';
import Checkbox from 'expo-checkbox';
import { defaultTheme } from '../../themes/loot8';
import Feather from 'react-native-vector-icons/Feather';
import { FontAwesome5 } from '@expo/vector-icons';
import { getFriendsInitialLoadPromise } from '../../slices/friendsSlice';
import { getUserPublicKey } from '../../slices/AppUserSlice';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { sendPrivateMessageToFriend } from '../../slices/PrivateMessageSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { MessageType } from '../../interfaces/IMessages';
import { LogErrors } from '../../helpers/AppLogger';
import { ToastCustomMessageType } from '../../appconstants';
import { showToastMessage } from '../../helpers/Gadgets';
import Constants from 'expo-constants';
import { hideKeyboard } from '../../slices/helpers';
import { LinearGradient } from 'expo-linear-gradient';

const customSort = array => {
  return array
    .filter(f => f.isFriend === false)
    .sort((a, b) => {
      let textA = a.name.toUpperCase();
      let textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    })
    .concat(
      array
        .filter(f => f.isFriend === true)
        .sort((a, b) => {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }),
    );
};

const buildEXPasslink = (chainId: string, passportAddress: string) => {
  return `${Constants.expoConfig.extra.LOOT8_WEB_URL}deeplinkredirection.html?path=home/${chainId}/passport/${passportAddress}`;
};

const buildEXPassLinkMessage = (
  chainId: string,
  passportAddress: string,
  name: string,
) => {
  return `hey, please check out ${
    name ? '"' + name + '"' : 'this'
  } ExPass on Loot8. ${buildEXPasslink(chainId, passportAddress)}`;
};

let initialLoadPromise = false;
let initialAvatarLoaded = null;

const RenderFriendItem = ({
  item,
  index,
  selectedFriend,
  addFriendToReshare,
  isContentLoading,
  sortedCurrentFriends,
}) => {
  const checked = useMemo(
    () => selectedFriend.indexOf(item?.wallet) > -1,
    [selectedFriend, item?.wallet],
  );
  return (
    <View
      key={item.wallet}
      onTouchStart={e => e.stopPropagation()}
      onTouchMove={e => e.stopPropagation()}
      onStartShouldSetResponder={e => true}>
      <View
        style={{ marginHorizontal: 10, marginVertical: 5, paddingVertical: 5 }}>
        <Pressable
          onPress={() => addFriendToReshare(item)}
          disabled={
            (selectedFriend.length >= 5 &&
              selectedFriend.indexOf(item.wallet) === -1) ||
            isContentLoading
          }>
          <View style={{ flexDirection: 'row' }}>
            <Avatar.Image
              size={36}
              source={
                item.avatarURI &&
                item.avatarURI != '' &&
                item.avatarURI != 'no-avatar' &&
                !item.avatarURI.includes('ipfs://') &&
                !item.avatarURI.includes('assets_avatars_p_') &&
                !item.avatarURI.includes('file:///')
                  ? { uri: item.avatarURI }
                  : require('../../assets/loot8-default-avatar.png')
              }
            />
            <View
              style={[{ flex: 1, justifyContent: 'center', marginLeft: 15 }]}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Title
                  style={[
                    styles.userTitle,
                    {
                      fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                      maxWidth: '90%',
                      textAlign: 'left',
                      lineHeight: 17,
                    },
                  ]}>
                  {`${item?.name}`}{' '}
                  <Text style={styles.friendUserIdText}>{`#${item?.id}`}</Text>
                </Title>
                <Checkbox
                  value={checked}
                  onValueChange={() => addFriendToReshare(item)}
                  color={
                    checked
                      ? defaultTheme.MAIN_BACKGROUND_COLOR
                      : defaultTheme.SECONDARY_TEXT_COLOR
                  }
                  style={{
                    borderRadius: 6,
                    borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
                  }}
                  disabled={
                    (selectedFriend.length >= 5 &&
                      selectedFriend.indexOf(item.wallet) === -1) ||
                    isContentLoading
                  }
                />
              </View>
            </View>
          </View>
        </Pressable>
      </View>
      {index != sortedCurrentFriends.length - 1 && (
        <View
          style={[
            styles.socialMessagingHairLine,
            styles.socialMessagingReadHairLine,
          ]}></View>
      )}
    </View>
  );
};

const _listEmptyComponent = () => {
  return (
    <Text
      style={[
        styles.addFriendsMsgText,
        { marginVertical: 10, color: defaultTheme.TEXT_COLOR_SECONDARY },
      ]}>
      {'No Friends found.'}
    </Text>
  );
};

const SharePassportSheetContent = ({
  props,
  actionSheetRef,
  scrollHandlers,
}) => {
  const { selectedPassport } = props.payload;
  const dispatch = useDispatch<AppDispatch>();
  const {
    networkId,
    wallet,
    staticProvider,
    encryptMessage,
    address,
    decryptMessage,
  } = useWeb3AuthContext();
  const mutualFriendsData = useAppSelector(
    state => state.friends.mutualFriends,
  );
  const allAvatarsLoaded = useAppSelector(
    state => state.friends.allAvatarsLoaded,
  );
  const [sortedCurrentFriends, setSortedCurrentFriends] = useState(
    customSort(mutualFriendsData),
  );
  const [serachText, setSearchText] = useState('');
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState([]);
  const [loadingFriends, setLoadingFriends] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState('Copy Link');

  const addFriendToReshare = (friend: { wallet: any }) => {
    if (selectedFriend.indexOf(friend.wallet) > -1) {
      setSelectedFriend(selectedFriend.filter(item => item !== friend?.wallet));
    } else {
      setSelectedFriend([...selectedFriend, friend?.wallet]);
    }
  };

  const onFriendSearch = async text => {
    setSearchText(text);
    if (text && text.length > 0) {
      setSortedCurrentFriends(
        customSort(mutualFriendsData)?.filter(
          p => p.name.toLowerCase().indexOf(text.toLowerCase()) > -1,
        ),
      );
    } else {
      setSortedCurrentFriends(customSort(mutualFriendsData));
    }
  };

  const buildAndCopyEXPassLink = () => {
    const link = buildEXPasslink(
      selectedPassport.chainId,
      selectedPassport.address,
    );
    Clipboard.setString(link);
    setCopyButtonText('Copied!');
    setTimeout(() => {
      setCopyButtonText('Copy Link');
    }, 2000);
  };

  const sendEXPasslink = async () => {
    if (Keyboard && Keyboard.dismiss) {
      Keyboard.dismiss();
    }
    setIsContentLoading(true);
    await Promise.all(
      selectedFriend.map(async (userAddress, index) => {
        const user = mutualFriendsData.find(
          obj => obj.wallet?.toLowerCase() === userAddress?.toLowerCase(),
        );
        if (user && user?.wallet) {
          const friendPublicKey = await getUserPublicKey(user?.wallet);
          if (friendPublicKey) {
            const encrptedMessage = encryptMessage(
              buildEXPassLinkMessage(
                selectedPassport.chainId,
                selectedPassport.address,
                selectedPassport.name,
              ),
              friendPublicKey,
            );
            if (encrptedMessage) {
              let response = await dispatch(
                sendPrivateMessageToFriend({
                  networkID: networkId,
                  provider: staticProvider,
                  address: user.wallet,
                  wallet: wallet,
                  publicKey: friendPublicKey,
                  text: encrptedMessage,
                  decryptMessage,
                  messageType: MessageType.text,
                }),
              );
              if (
                response &&
                response.payload.status &&
                response.payload.status == 200
              ) {
                showToastMessage(
                  ToastCustomMessageType.INFO,
                  'ExPass link has been shared with selected friends via direct messages',
                );
              } else {
                showToastMessage(
                  ToastCustomMessageType.INFO,
                  'Unable to send link. Please try again later!',
                );
              }
            } else {
              LogErrors(
                new Error(`sendExPasslink: Error in Encrypting Message`),
                address,
                networkId,
                [
                  { tag: 'error', value: 'PRIVATE MESSAGE ERROR' },
                  { tag: 'friendAddress', value: user?.wallet },
                ],
              );
              showToastMessage(
                ToastCustomMessageType.INFO,
                'Unable to send link. Please try again later!',
              );
            }
          }
        }
      }),
    );

    actionSheetRef.current.hide();
    setTimeout(() => {
      setIsContentLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (!mutualFriendsData || mutualFriendsData?.length == 0) {
      setLoadingFriends(true);
      getFriendsInitialLoadPromise().then(() => {
        initialLoadPromise = true;
        initialAvatarLoaded = false;
      });
    } else if (!initialLoadPromise) {
      setLoadingFriends(false);
      setSortedCurrentFriends(customSort(mutualFriendsData));
    }
  }, [mutualFriendsData]);

  useEffect(() => {
    if (initialAvatarLoaded === false) {
      initialAvatarLoaded = true;
      setSortedCurrentFriends(customSort(mutualFriendsData));
    }
    if (allAvatarsLoaded && mutualFriendsData?.length == 0) {
      setLoadingFriends(false);
    }
  }, [allAvatarsLoaded]);

  return (
    <View style={componentStyles.container}>
      <Text style={componentStyles.modalTitle}>Share your ExPass!</Text>
      <Text style={[componentStyles.modalSubtitle, { marginTop: 5 }]}>
        You can either copy your ExPass link to share through other social
        channels or share it with your Loot8 friends below.
      </Text>
      <View style={componentStyles.separator} />

      <TouchableOpacity
        style={[styles.resharePostSecondCaption, { flexDirection: 'row' }]}
        onPress={buildAndCopyEXPassLink}>
        <FontAwesome5
          name="link"
          size={15}
          color={defaultTheme.PRIMARY_TEXT_COLOR}
        />
        <Text
          style={[
            componentStyles.modalSubtitle,
            {
              alignSelf: 'flex-start',
              marginLeft: 7.5,
            },
          ]}>
          {copyButtonText}
        </Text>
      </TouchableOpacity>
      {(sortedCurrentFriends && sortedCurrentFriends.length > 0) ||
      serachText ? (
        <View
          style={{
            marginTop: 15,
          }}>
          <TextInput
            style={componentStyles.searchFriendInput}
            contentStyle={{ height: 48 }}
            placeholder={'Search friends'}
            placeholderTextColor={defaultTheme.PRIMARY_TEXT_COLOR}
            value={serachText}
            autoCorrect={false}
            activeOutlineColor="transparent"
            outlineStyle={styles.friendSearchTextInputOutline}
            mode="outlined"
            selectionColor={defaultTheme.PRIMARY_TEXT_COLOR}
            textColor={defaultTheme.PRIMARY_TEXT_COLOR}
            onChangeText={text => onFriendSearch(text)}
            left={
              <TextInput.Icon
                icon="magnify"
                color={defaultTheme.PRIMARY_TEXT_COLOR}
              />
            }
            right={
              serachText && serachText != '' ? (
                <TextInput.Icon
                  icon="close"
                  color={defaultTheme.PRIMARY_TEXT_COLOR}
                  onPress={() => {
                    onFriendSearch('');
                  }}
                />
              ) : (
                <></>
              )
            }
            returnKeyType="done"
            editable={!isContentLoading}
          />
          <TouchableWithoutFeedback>
            <FlatList
              {...scrollHandlers}
              contentContainerStyle={{
                minHeight: 420,
                paddingBottom: 100,
                paddingTop: 25,
              }}
              style={[
                {
                  marginBottom: 15,
                  height: 310,
                  borderRadius: defaultTheme.CONTENT_RADIUS,
                },
              ]}
              data={sortedCurrentFriends}
              renderItem={({ item, index }) => (
                <RenderFriendItem
                  item={item}
                  index={index}
                  selectedFriend={selectedFriend}
                  addFriendToReshare={addFriendToReshare}
                  isContentLoading={isContentLoading}
                  sortedCurrentFriends={sortedCurrentFriends}
                />
              )}
              ListEmptyComponent={_listEmptyComponent}
              scrollEnabled={true}
              keyboardDismissMode="on-drag"
              showsVerticalScrollIndicator={false}
              keyExtractor={(item, index) => item.wallet}
              nestedScrollEnabled={true}
              onTouchStart={e => e.stopPropagation()}
              onTouchMove={e => e.stopPropagation()}
              onStartShouldSetResponder={e => true}
            />
          </TouchableWithoutFeedback>
          {selectedFriend.length > 0 &&
            (isContentLoading ? (
              <ActivityIndicator
                size={18}
                color={defaultTheme.PRIMARY_TEXT_COLOR}
                style={componentStyles.sendPostBtn}
              />
            ) : (
              <Pressable
                style={componentStyles.sendPostBtn}
                onPress={sendEXPasslink}>
                <Feather name="send" color={'#F0F0F0'} size={18} />
              </Pressable>
            ))}
        </View>
      ) : !loadingFriends ? (
        <Text
          style={[
            styles.addFriendsMsgText,
            {
              alignSelf: 'center',
              textAlign: 'center',
              padding: 30,
              color: defaultTheme.TEXT_COLOR_SECONDARY,
            },
          ]}>
          {sortedCurrentFriends.length == 0 &&
            !serachText &&
            'No Friends Added'}
        </Text>
      ) : (
        <></>
      )}
    </View>
  );
};

const SharePassportSheet = (props: SheetProps) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const scrollHandlers = useScrollHandlers<ScrollView>({
    refreshControlBoundary: 0,
    hasRefreshControl: false,
  });

  return (
    <ActionSheet
      ref={actionSheetRef}
      id={props.sheetId}
      drawUnderStatusBar
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      enableGesturesInScrollView={false}>
      {Platform.OS === 'web' ? (
        <ReShareWebBlurView>
          <View
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <SharePassportSheetContent
                props={props}
                actionSheetRef={actionSheetRef}
                scrollHandlers={scrollHandlers}
              />
            </LinearGradient>
          </View>
        </ReShareWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={{
            borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <SharePassportSheetContent
              props={props}
              actionSheetRef={actionSheetRef}
              scrollHandlers={scrollHandlers}
            />
          </LinearGradient>
        </BlurView>
      )}
      {/* <TouchableWithoutFeedback
        onPress={() => {
          hideKeyboard();
        }}>
        {Platform.OS === 'android' ? (
          <View
            style={{
              ...styles.modalCenteredContainer,
              backgroundColor: 'rgba(169, 175, 179,.97)',
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}>
            <View style={{ ...styles.maxWidthAdjust, paddingVertical: 10 }}>
              <SharePassportSheetContent
                props={props}
                actionSheetRef={actionSheetRef}
                scrollHandlers={scrollHandlers}
              />
            </View>
          </View>
        ) : Platform.OS == 'ios' ? (
          <BlurView
            tint="light"
            style={{
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              overflow: 'hidden',
            }}
            intensity={60}>
            <View
              style={{
                ...styles.maxWidthAdjust,
                paddingVertical: 10,
                backgroundColor: 'rgba(120, 120, 120,.5)',
              }}>
              <SharePassportSheetContent
                props={props}
                actionSheetRef={actionSheetRef}
                scrollHandlers={scrollHandlers}
              />
            </View>
          </BlurView>
        ) : (
          <ReShareWebBlurView>
            <View
              style={{
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
                overflow: 'hidden',
              }}>
              <View style={{ ...styles.maxWidthAdjust, paddingVertical: 10 }}>
                <SharePassportSheetContent
                  props={props}
                  actionSheetRef={actionSheetRef}
                  scrollHandlers={scrollHandlers}
                />
              </View>
            </View>
          </ReShareWebBlurView>
        )}
      </TouchableWithoutFeedback> */}
    </ActionSheet>
  );
};

export default SharePassportSheet;

const componentStyles = StyleSheet.create({
  container: { paddingHorizontal: 15, paddingVertical: 25 },
  row: { flexDirection: 'row', alignItems: 'center' },
  modalTitle: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  modalSubtitle: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_TEXT_COLOR,
    opacity: 0.6,
    marginTop: 15,
    marginBottom: 25,
  },
  commentBox: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    flexDirection: 'row',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    padding: 5,
    alignItems: 'center',
  },
  commentBoxWordCounter: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    marginLeft: 15,
  },
  sendPostBtn: {
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    alignSelf: 'flex-end',
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchFriendInput: {
    backgroundColor: 'rgba(255,255,255,0.2)',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XXMEDIUM + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    height: 48,
  },
});
