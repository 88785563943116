import { ethers } from 'ethers';
import { BarCodeScanner } from 'expo-barcode-scanner';
import Constants from 'expo-constants';
import React, { useEffect, useState } from 'react';
import {
  Dimensions,
  ImageBackground,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { ActivityIndicator, Button, Modal, Portal } from 'react-native-paper';
import qrCodePlaceholder from '../../assets/images/qrCodePlaceholder.png';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { mintThirdPartyCollectibles } from '../../slices/ThirdPartyCollectiblesSlice';
import ModalComponent from '../Modal';

import { Camera } from 'expo-camera';
import { storeData } from '../../helpers/AppStorage';
import { clearCatalog } from '../../helpers/Gadgets';
import { reloadCatCollectibleList } from '../../slices/CatalogSlice';
import {
  getAllThirdParty,
  getThirdPartyCollectiableDetails,
  loadPassportOfferList,
} from '../../slices/OfferSlice';
import globalStyle from '../../styles';
import { Buffer } from 'buffer';
import htmlParser from 'react-native-html-parser';
import {
  addresses,
  APP_CONFIGURATION_FILE,
  getAnynetStaticProvider,
  IPNS_UPLOAD_LAMBDA_URL,
  MASTER_KEY,
  ZERO_ADDRESS,
} from '../../appconstants';
import { LogErrors, LogWarning } from '../../helpers/AppLogger';
import { getIPNSData } from '../../helpers/ipfs';
import { getUnsubsribedNotifications } from '../../slices/NotificationSlice';
import {
  ExternalCollectionManager__factory,
  HQNFTsERC721Mint__factory,
  Loot8POAPCollection__factory,
} from '../../typechain';
import { ScreenName } from '../../enums/screen.enum';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { defaultTheme } from '../../themes/loot8';
import GradientButton from '../GradientButton';
import { CatalogTypes } from '../../enums/catalog.enum';
import useActiveDimensions from '../../hooks/useActiveDimensions';

export enum CheckMintedTokenError {
  TOKEN_ALREADY_MINTED = 1,
  TOKEN_NOT_MINTED = 2,
  TOKEN_EXCEPTION = 3,
}

export enum MetaTagsEnum {
  CONTRACT = 'contract',
  FUNCTION = 'function',
  CHAIN_ID = 'chain',
  ARGUMENTS = 'args',
}

// Check if token is already minted.
// Error code:
// 1 - Token already minted.
// 2 - Token not minted.
// 3 - Exception.
export const isTokenAlreadyMinted = async (
  contract: string,
  chainId: string,
  tokenId: string,
  address: string,
  networkId: number,
) => {
  try {
    const thirdPartyCollectible =
      tokenId && tokenId.startsWith('ipfs')
        ? Loot8POAPCollection__factory.connect(
            contract,
            getAnynetStaticProvider(Number(chainId)),
          )
        : HQNFTsERC721Mint__factory.connect(
            contract,
            getAnynetStaticProvider(Number(chainId)),
          );

    let tokenMinted = CheckMintedTokenError.TOKEN_NOT_MINTED;

    try {
      // get owner of the token id if token id exists.
      const token =
        tokenId && tokenId.startsWith('ipfs')
          ? await thirdPartyCollectible.patronMintedPOAP(address, tokenId)
          : await thirdPartyCollectible.ownerOf(Number(tokenId));
      if (token) {
        tokenMinted = CheckMintedTokenError.TOKEN_ALREADY_MINTED;
      }
    } catch (e) {
      tokenMinted = CheckMintedTokenError.TOKEN_NOT_MINTED;
    }
    return tokenMinted;
  } catch (err) {
    LogErrors(err, address, networkId, [
      { tag: 'contract', value: contract },
      { tag: 'chain-id', value: chainId },
      { tag: 'token-id', value: tokenId },
      { tag: 'qr-code-error', value: 'QR TOKEN MINTED CHECK FAILED' },
      { tag: 'error', value: 'QR CODE' },
    ]);

    return CheckMintedTokenError.TOKEN_EXCEPTION;
  }
};

const BarcodeReader = ({
  navigation,
  route,
  setScanned,
  scanned,
  cameraReady,
  setCameraReady,
  permissionGranted,
  requestPermissions,
}) => {
  const {
    networkId,
    staticProvider,
    address,
    userInfo,
    wallet,
    key,
    SelectedPassport,
  } = useWeb3AuthContext();
  const { height: activeHeight } = useActiveDimensions();
  const [catalog, setCatalog] = useState(null);
  const [navigateToCatalog, setNavigateToCatalog] = useState(false);
  const [isReadingData, setIsReadingData] = useState(false);
  const [showBarcodeError, setShowBarcodeError] = useState(false);
  const [barcodeError, setBarcodeError] = useState('');
  const [showCollectibleMintedMsg, setShowCollectibleMintedMsg] =
    useState(false);
  const [isLoadingCatalog, setIsLoadingCatalog] = useState(false);
  const [statusMessage, setStatusMessage] = useState('Fetching details...');
  const [configurationData, setConfigurationData] = useState(null);
  const entityData = useAppSelector(state => state.Entity.EntityData);
  const navigationFrom: string = route?.params?.from ? route?.params?.from : '';

  const dispatch = useAppDispatch();

  const handleBarCodeScanned = async ({ type, data }) => {
    // const whiteListURL: string[] = [Constants.expoConfig.extra.HQNFTs_URL, "https://ipfs.io", "https://loot8.dev:8080"];
    if (data) {
      setScanned(true);
      setIsReadingData(true);

      // pull whitelist contract in case of config variable is null
      let whitelistURLs = null;
      let configData = configurationData
        ? configurationData
        : await getQRConfiguration();
      if (configData && configData.qrWhitelistURLs) {
        whitelistURLs = configData.qrWhitelistURLs;
      }

      let validURL = whitelistURLs?.find(item => data.startsWith(item));
      if (validURL && validURL.length > 0) {
        setStatusMessage('Fetching details...');

        LogToLoot8Console('page url', data);
        let metaTags = null;
        // qr shim flow
        metaTags = await readQueryParams(data, configData);

        if (!metaTags || (metaTags && !metaTags.contract)) {
          // regular flow
          metaTags = await readMetaTags(data, configData);
        }

        setStatusMessage('Processing details...');
        LogToLoot8Console('responseMetaTags', metaTags);
        if (
          metaTags &&
          metaTags[MetaTagsEnum.CONTRACT] !== '' &&
          metaTags[MetaTagsEnum.CHAIN_ID] !== '' &&
          metaTags['types'].length > 0 &&
          metaTags['args'].length > 0 &&
          metaTags[MetaTagsEnum.FUNCTION] !== ''
        ) {
          const isWhiteLsisted = await isContractWhiteListed(
            metaTags[MetaTagsEnum.CONTRACT],
          );
          if (isWhiteLsisted) {
            let tokenMinted = CheckMintedTokenError.TOKEN_NOT_MINTED;
            // check if tokenid is existed,
            // considering arg2 will be having a tokenID only (and it could be ipfs link)
            if (
              metaTags['args'].length > 1 &&
              metaTags['types'].length > 1 &&
              metaTags['types'][1]?.length > 0
            ) {
              // Use TokenURI for POAPs mint function
              let tokenId =
                metaTags['function'].toLowerCase() == 'mint' &&
                metaTags['args'].length == 3
                  ? metaTags['args'][2]
                  : metaTags['args'][1];
              tokenMinted = await isTokenAlreadyMinted(
                metaTags[MetaTagsEnum.CONTRACT],
                metaTags[MetaTagsEnum.CHAIN_ID],
                tokenId,
                address,
                networkId,
              );
            }
            if (tokenMinted == CheckMintedTokenError.TOKEN_NOT_MINTED) {
              setStatusMessage('Adding collectible...');
              await dispatchNFT(metaTags);
            } else if (
              tokenMinted == CheckMintedTokenError.TOKEN_ALREADY_MINTED
            ) {
              LogToLoot8Console(
                'QR already scanned. Please scan a different QR code!',
              );
              setBarcodeError(
                'QR already scanned. Please scan a different QR code!',
              );
              setShowBarcodeError(true);
              setIsReadingData(false);

              LogErrors(
                new Error(
                  'QR already scanned. Please scan a different QR code',
                ),
                address,
                networkId,
                [
                  { tag: 'meta-tag-contract', value: metaTags['contract'] },
                  { tag: 'meta-tag-chain', value: metaTags['chain'] },
                  { tag: 'meta-tag-arg2', value: metaTags['arg2'] },
                  { tag: 'meta-tag-function', value: metaTags['function'] },
                  {
                    tag: 'qr-code-error',
                    value: 'QR META TAGS ALREADY SCANNED',
                  },
                  { tag: 'error', value: 'QR CODE' },
                ],
              );
            } else {
              LogToLoot8Console(
                'Something went wrong, please try after sometime!',
              );
              setBarcodeError(
                'Something went wrong, please try after sometime!',
              );
              setShowBarcodeError(true);
              setIsReadingData(false);

              LogErrors(
                new Error('Something went wrong, please try after sometime!'),
                address,
                networkId,
                [
                  { tag: 'meta-tag-contract', value: metaTags['contract'] },
                  { tag: 'meta-tag-chain', value: metaTags['chain'] },
                  { tag: 'meta-tag-arg2', value: metaTags['arg2'] },
                  { tag: 'meta-tag-function', value: metaTags['function'] },
                  {
                    tag: 'qr-code-error',
                    value: 'QR META TAGS MINTED CHECK FAILED',
                  },
                  { tag: 'error', value: 'QR CODE' },
                ],
              );
            }
          } else {
            LogToLoot8Console('Scanned barcode contract is not whitelisted');
            setBarcodeError('Sorry!! This QR code is not supported in Loot 8');
            setShowBarcodeError(true);
            setIsReadingData(false);

            LogErrors(
              new Error('Scanned barcode contract is not whitelisted'),
              address,
              networkId,
              [
                { tag: 'meta-tag-contract', value: metaTags['contract'] },
                { tag: 'meta-tag-chain', value: metaTags['chain'] },
                { tag: 'meta-tag-arg2', value: metaTags['arg2'] },
                { tag: 'meta-tag-function', value: metaTags['function'] },
                { tag: 'qr-code-error', value: 'QR CODE NOT SUPPORTED' },
                { tag: 'error', value: 'QR CODE' },
              ],
            );
          }
        } else {
          // setBarcodeError('Sorry! Required data not present in QR to add collectible');
          // setShowBarcodeError(true);
          setIsReadingData(false);
          setScanned(true);

          // LogErrors(new Error("Sorry! Required data not present in QR to add collectible"), address, networkId,
          // [
          //     { tag: "meta-tag-contract", value: metaTags['contract'] },
          //     { tag: "meta-tag-chain", value: metaTags['chain'] },
          //     { tag: "meta-tag-arg2", value: metaTags['arg2'] },
          //     { tag: "meta-tag-function", value: metaTags['function'] },
          //     { tag: "qr-code-error", value: 'QR REQUIRED DATA NO PRESENT' },
          //     { tag: "error", value: 'QR CODE' }
          // ]);
        }
      } else {
        LogToLoot8Console(
          'QR code does not contain link, or a link is not to whitelisted domain',
        );
        setBarcodeError('Sorry!! This QR code is not supported in Loot 8');
        setShowBarcodeError(true);
        // setUniqueString('');
        setIsReadingData(false);

        LogErrors(
          new Error(
            'QR code does not contain link, or a link is not to whitelisted domain',
          ),
          address,
          networkId,
          [
            { tag: 'url', value: JSON.stringify(data) },
            { tag: 'qr-code-error', value: 'QR REQUIRED DATA NO PRESENT' },
            { tag: 'error', value: 'QR CODE' },
          ],
        );
      }
    } else {
      alert('Unable to read QR code, try again!');
      setScanned(true);

      LogErrors(
        new Error('Unable to read QR code, try again!'),
        address,
        networkId,
        [
          { tag: 'url', value: JSON.stringify(data) },
          { tag: 'qr-code-error', value: 'QR UNABLE TO READ CODE' },
          { tag: 'error', value: 'QR CODE' },
        ],
      );
    }
  };

  const dispatchNFT = async (metaTags: any) => {
    if (metaTags['contract'] !== '') {
      let transactionComplete = false;
      let waitTransaction = await dispatch(
        mintThirdPartyCollectibles({
          key: key,
          contract: metaTags['contract'],
          chain: metaTags['chain'],
          mintFunc: metaTags['function'],
          types: metaTags['types'],
          args: metaTags['args'],
        }),
      );
      if (waitTransaction?.payload?.isCollectibleMinted) {
        transactionComplete = true;
      }
      if (transactionComplete) {
        setShowCollectibleMintedMsg(true);
        await clearCatalog('Collectibles');
        await dispatch(
          reloadCatCollectibleList({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            userInfo,
          }),
        );
        if (SelectedPassport) {
          await dispatch(
            loadPassportOfferList({
              networkID: networkId,
              provider: staticProvider,
              passport: SelectedPassport,
              wallet,
              address,
            }),
          );
        }

        const catalogsOwned = await getThirdPartyCollectiableDetails(
          {
            chainId: metaTags['chain'],
            provider: staticProvider,
            collectiableAddress: metaTags['contract'],
            address,
          },
          false,
        );
        const catalog =
          catalogsOwned &&
          catalogsOwned.length > 0 &&
          catalogsOwned?.sort((a, b) => b?.timestamp - a?.timestamp)[0];
        setCatalog(catalog);
      } else {
        setBarcodeError(
          'Sorry!! Transaction failed, please try after sometime.',
        );
        setShowBarcodeError(true);
        setScanned(true);

        LogErrors(
          new Error('Transaction failed, please try after sometime'),
          address,
          networkId,
          [
            { tag: 'meta-tag-contract', value: metaTags['contract'] },
            { tag: 'meta-tag-chain', value: metaTags['chain'] },
            { tag: 'meta-tag-arg2', value: metaTags['arg2'] },
            { tag: 'meta-tag-function', value: metaTags['function'] },
            {
              tag: 'payload-status',
              value: JSON.stringify(waitTransaction?.payload?.status),
            },
            { tag: 'qr-code-error', value: 'QR TRANSACTION FAILED' },
            { tag: 'error', value: 'QR CODE' },
          ],
        );
      }
      setIsReadingData(false);
      await storeData('@notificationLastExecutionTime', null); //To Get the latest notifications on minting collectibles quickly
      dispatch(
        getUnsubsribedNotifications({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
        }),
      );
    } else {
      setScanned(true);
      setIsReadingData(false);
    }
  };

  useEffect(() => {
    setShowCollectibleMintedMsg(false);
    if (catalog && navigateToCatalog) {
      navigation.navigate('BottomTab', {
        screen: ScreenName.MORE_TAB,
        params: {
          screen: ScreenName.CATALOG_DETAILS,
          params: {
            catalog: catalog,
            availableCatalogs: [],
            type: 'Collectible',
            from: navigationFrom,
            passport: SelectedPassport,
            updateParent: route.params?.updateParent,
          },
        },
      });
      // Reset flag after navigation
      setNavigateToCatalog(false);
      setIsLoadingCatalog(false);
    }
  }, [catalog, navigateToCatalog]);

  const redirectBack = () => {
    setNavigateToCatalog(true);
    setIsLoadingCatalog(true);
  };

  const rescan = () => {
    setScanned(false);
  };

  const readMetaTags = async (url: string, configData: any) => {
    if (url) {
      const data = Buffer.from(url, 'utf-8');
      const canonicalizedData = data;

      // sign data
      const signature = await wallet.signMessage(canonicalizedData);

      const masterWallet = new ethers.Wallet(MASTER_KEY);
      const masterSignature = await masterWallet.signMessage(signature);

      // start timer
      const startTimer = performance.now();

      try {
        const responseMetaTags = await fetch(
          IPNS_UPLOAD_LAMBDA_URL +
            'user/' +
            address +
            '/fetch/' +
            encodeURIComponent(url) +
            '/' +
            signature +
            '/' +
            masterSignature,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
          },
        );

        if (responseMetaTags && responseMetaTags.status != 200) {
          setBarcodeError(
            'Sorry! Got an error while reading URL, Please try again!',
          );
          setShowBarcodeError(true);

          // end timer
          const endTimer = performance.now();
          const executionTime = endTimer - startTimer;

          LogErrors(
            new Error(
              'PAGE URL FAILED. Http status: ' + responseMetaTags.status,
            ),
            address,
            networkId,
            [
              { tag: 'url', value: url },
              { tag: 'qr-code-error', value: 'QR PAGE URL FAILED' },
              { tag: 'time-taken', value: `${executionTime}ms` },
              { tag: 'error', value: 'QR CODE' },
            ],
          );

          return null;
        }

        // pull configuration
        let configuraton = null;
        let configData = configurationData
          ? configurationData
          : await getQRConfiguration();

        if (configData && configData.qrConfig) {
          configuraton = configData.qrConfig;
        }

        const pageHtml = await responseMetaTags.text();
        if (pageHtml) {
          let metaDict = {
            contract: '',
            chain: '',
            function: '',
            types: [],
            args: [],
          };
          // Initialize the DOM parser
          var parser = new htmlParser.DOMParser();
          var doc = parser?.parseFromString(pageHtml, 'text/html');
          var meta = doc?.getElementsByTagName('meta');
          const metaTags = ['contract', 'chain', 'function'];
          let contractAddress = null;
          if (meta) {
            for (let i = 0; i < meta.length; i++) {
              const tagName = meta[i].getAttribute('name');
              // get contract address
              if (tagName.toLocaleLowerCase() == MetaTagsEnum.CONTRACT) {
                contractAddress = meta[i].getAttribute('content');
              }
              if (tagName.startsWith('arg')) {
                metaTags.push(tagName);
              }
            }
          }

          // get configuration
          const filterContract =
            contractAddress && configuraton
              ? configuraton?.find(
                  x =>
                    x.contract.toString().toLocaleLowerCase() ==
                    contractAddress.toString().toLocaleLowerCase(),
                )
              : null;
          if (filterContract == null || filterContract == undefined) {
            LogWarning(
              'Contract not found in configuration file.',
              address,
              networkId,
              [
                { tag: 'url', value: url },
                { tag: 'meta-tag-contract', value: metaTags['contract'] },
                {
                  tag: 'qr-code-error',
                  value: 'QR CONTRACT NOT FOUND IN FILE',
                },
                { tag: 'error', value: 'QR CODE' },
              ],
            );
          }

          metaTags.forEach(tag => {
            if (meta) {
              for (let i = 0; i < meta.length; i++) {
                let metaTag = meta[i].getAttribute('name');
                if (metaTag === tag) {
                  if (metaTag.startsWith('arg')) {
                    metaDict['args'].push(meta[i].getAttribute('content'));
                    // update types
                    // configuration file
                    /*
                                            {
                                                "qrConfig": [
                                                    {
                                                        "contract": "0x4c9022c0b5e3907f252259fe5f1002c24be4551d",
                                                        "chainId": "80001",
                                                        "function": "mint",
                                                        "args": [
                                                            { "param": "arg1", "type":"address" },
                                                            { "param": "arg2", "type":"number" }
                                                        ]
                                                    }
                                                ]
                                            }
                                        */
                    if (
                      filterContract &&
                      filterContract.args &&
                      filterContract.args.length > 0
                    ) {
                      // use configuration to update type
                      const argType = filterContract.args.find(
                        x =>
                          x.param.toString().toLocaleLowerCase() ==
                          metaTag.toString().toLocaleLowerCase(),
                      );
                      if (argType) {
                        metaDict['types'].push(argType.type);
                      } else {
                        // use existing type in case configuration is not available.
                        metaDict['types'].push(meta[i].getAttribute('type'));
                      }
                    } else {
                      // use existing type in case configuration is not available.
                      metaDict['types'].push(meta[i].getAttribute('type'));
                    }
                  } else {
                    metaDict[tag] = meta[i].getAttribute('content');
                  }
                }
              }
            }
          });

          // update chain id and function if that is missing
          if (filterContract) {
            metaDict[MetaTagsEnum.CHAIN_ID] =
              filterContract && filterContract.chainId
                ? filterContract.chainId
                : metaDict[MetaTagsEnum.CHAIN_ID];
            metaDict[MetaTagsEnum.FUNCTION] =
              filterContract && filterContract.function
                ? filterContract.function
                : metaDict[MetaTagsEnum.FUNCTION];
          }

          // todo - uncomment this section after upgrading sentry
          // https://github.com/expo/sentry-expo/issues/295
          // https://github.com/expo/sentry-expo/issues/305
          // // end timer
          // const endTimer = performance.now();
          // const executionTime = endTimer - startTimer;
          // // logs
          // LogInformation("QR-META-TAGS-SUCCESS", address, networkId,
          // [{ tag: "url", value: url },
          // { tag: "time-taken", value: `${executionTime}ms` }]);

          // map contract configuration
          // if(contractAddress) {
          //     //let configuraton = qrConfiguration;
          //     if(configuraton == null || configuraton == undefined) {
          //         configuraton = await getQRConfiguration();
          //     }
          //     const filterContract = configuraton?.find(x => x.contract.toString().toLocaleLowerCase() == contractAddress.toString().toLocaleLowerCase());
          //     if(filterContract) {
          //         metaDict["chain"] = filterContract.chainId ? filterContract.chainId : metaDict["chain"];
          //         metaDict["function"] = filterContract.function ? filterContract.function : metaDict["function"];
          //     } else {
          //         LogErrors(new Error("Contract not found in configuration file."), address, networkId,
          //         [{ tag: "url", value: url },
          //         { tag: "meta-tag-contract", value: metaTags['contract'] },
          //         { tag: "qr-code-error", value: 'QR CONTRACT NOT FOUND IN FILE' },
          //         { tag: "error", value: 'QR CODE' }]);
          //     }
          // }

          if (
            metaDict &&
            metaDict[MetaTagsEnum.CONTRACT] !== '' &&
            metaDict[MetaTagsEnum.CHAIN_ID] !== '' &&
            metaDict[MetaTagsEnum.FUNCTION] !== '' &&
            metaDict[MetaTagsEnum.ARGUMENTS].length > 1 &&
            metaDict[MetaTagsEnum.ARGUMENTS][1] !== ''
          ) {
            return metaDict.contract ? metaDict : null;
          } else {
            setBarcodeError(
              'Sorry! Required data not present in QR to add collectible',
            );
            setShowBarcodeError(true);

            // end timer
            const endTimer = performance.now();
            const executionTime = endTimer - startTimer;

            LogErrors(
              new Error(
                'Sorry! Required data not present in QR to add collectible',
              ),
              address,
              networkId,
              [
                { tag: 'url', value: url },
                { tag: 'meta-tag-contract', value: metaTags['contract'] },
                { tag: 'meta-tag-chain', value: metaTags['chain'] },
                { tag: 'meta-tag-arg2', value: metaTags['arg2'] },
                { tag: 'meta-tag-function', value: metaTags['function'] },
                { tag: 'qr-code-error', value: 'QR META TAGS FAILED' },
                { tag: 'time-taken', value: `${executionTime}ms` },
                { tag: 'error', value: 'QR CODE' },
              ],
            );

            return null;
          }
        }
      } catch (err) {
        LogToLoot8Console(err);
        setBarcodeError(
          'Sorry! Got an error while reading URL, Please try again!',
        );
        setShowBarcodeError(true);
        setIsReadingData(false);
        setScanned(true);

        // end timer
        const endTimer = performance.now();
        const executionTime = endTimer - startTimer;

        LogErrors(err, address, networkId, [
          { tag: 'url', value: url },
          { tag: 'qr-code-error', value: 'QR DOCUMENT PARSER FAILED' },
          { tag: 'time-taken', value: `${executionTime}ms` },
          { tag: 'error', value: 'QR CODE' },
        ]);
      }
    }
    return null;
  };

  const isContractWhiteListed = async (contract: string) => {
    try {
      const collectionManager = ExternalCollectionManager__factory.connect(
        addresses[networkId].ExternalCollectionManager,
        staticProvider,
      );
      let findContract;
      if (SelectedPassport) {
        let thirdPartyList =
          await collectionManager.getWhitelistedCollectionsForPassport(
            SelectedPassport?.address,
          );
        findContract = thirdPartyList?.find(item => {
          if (
            item &&
            item.length > 0 &&
            item[0].toLocaleLowerCase() === contract.toLocaleLowerCase()
          ) {
            return true;
          }
          return false;
        });

        if (findContract) {
          return true;
        }
      }

      findContract = (
        await getAllThirdParty(
          {
            networkID: networkId,
            provider: staticProvider,
            entityData,
            address,
            wallet,
          },
          true,
        )
      ).find(
        x =>
          x !== ZERO_ADDRESS &&
          x.source.toLocaleLowerCase() === contract.toLocaleLowerCase(),
      );

      if (findContract) {
        return true;
      }

      return false;
    } catch (err) {
      LogErrors(err, address, networkId, [
        { tag: 'contract', value: contract },
        { tag: 'qr-code-error', value: 'QR CONTRACT WHITELISTED FAILED' },
        { tag: 'error', value: 'QR CODE' },
      ]);

      return false;
    }
  };

  const getQRConfiguration = async () => {
    try {
      const configData = await getIPNSData(
        Constants.expoConfig.extra.APP_CONFIGURATION_IPNS_KEY,
        APP_CONFIGURATION_FILE,
      );
      if (configData) {
        const config = JSON.parse(configData);
        return config;
      }
    } catch (err) {
      LogErrors(err, address, networkId, [
        { tag: 'qr-code-error', value: 'QR CONFIGURATION FAILED' },
        { tag: 'error', value: 'QR CODE' },
      ]);
      return null;
    }
  };

  useEffect(() => {
    (async () => {
      const data = await getQRConfiguration();
      if (data) {
        setConfigurationData(data);
      }
    })();
  }, []);

  // read query parameters
  const readQueryParams = async (url: string, configurationData: any) => {
    if (url) {
      let metaDict = {
        contract: '',
        chain: '',
        function: '',
        types: [],
        args: [],
      };
      const splitAll = url.split('?');
      if (splitAll && splitAll.length > 1) {
        const splitParams = splitAll[1].split('&');
        if (splitParams && splitParams.length > 0) {
          splitParams.forEach((item, index) => {
            const splitItem = item.split('=');
            if (splitItem && splitItem.length > 1) {
              if (
                splitItem[0] != '' &&
                splitItem[0].toLowerCase() == 'contract'
              ) {
                metaDict.contract = splitItem[1];
              }
              if (
                splitItem[0] != '' &&
                splitItem[0].toLowerCase() == 'function'
              ) {
                metaDict.function = splitItem[1];
              }
              if (splitItem[0] != '' && splitItem[0].toLowerCase() == 'chain') {
                metaDict.chain = splitItem[1];
              }
              if (splitItem[0] != '' && splitItem[0].toLowerCase() == 'types') {
                const typesArray = splitItem[1];
                if (typesArray) {
                  metaDict.types = typesArray.split(',');
                }
              }
              if (splitItem[0] != '' && splitItem[0].toLowerCase() == 'args') {
                const args = splitItem[1];
                if (args) {
                  metaDict.args = args.split(',');
                }
              }
            }
          });
        }
      }
      if (
        metaDict.contract != '' &&
        metaDict.types.length > 0 &&
        metaDict.args.length > 0
      ) {
        // pull configuration
        let configuraton = null;
        let configData = configurationData
          ? configurationData
          : await getQRConfiguration();

        if (configData && configData.qrConfig) {
          configuraton = configData.qrConfig;
        }

        // get configuration
        const filterContract =
          metaDict.contract && configuraton
            ? configuraton?.find(
                x =>
                  x.contract.toString().toLocaleLowerCase() ==
                  metaDict.contract.toString().toLocaleLowerCase(),
              )
            : null;
        if (!filterContract) {
          LogWarning(
            'QR shim: Contract not found in configuration file.',
            address,
            networkId,
            [
              { tag: 'url', value: url },
              { tag: 'meta-tag-contract', value: metaDict.contract },
              { tag: 'qr-code-error', value: 'QR CONTRACT NOT FOUND IN FILE' },
              { tag: 'error', value: 'QR CODE' },
            ],
          );
        }

        // update chain id and function if that is missing
        if (filterContract) {
          metaDict[MetaTagsEnum.CHAIN_ID] =
            filterContract && filterContract.chainId
              ? filterContract.chainId
              : metaDict[MetaTagsEnum.CHAIN_ID];
          metaDict[MetaTagsEnum.FUNCTION] =
            filterContract && filterContract.function
              ? filterContract.function
              : metaDict[MetaTagsEnum.FUNCTION];
        }
        return metaDict;
      }
    }
    return null;
  };
  return (
    <View style={styles.container}>
      {permissionGranted ? (
        <React.Fragment>
          {Platform.OS === 'ios' || Platform.OS === 'android' ? (
            <BarCodeScanner
              type={BarCodeScanner.Constants.Type.back}
              onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
              style={[
                StyleSheet.absoluteFillObject,
                {
                  flex: 1,
                },
              ]}
            />
          ) : (
            <Camera
              type={BarCodeScanner.Constants.Type.back}
              onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
              style={StyleSheet.absoluteFillObject}
              barCodeScannerSettings={{
                barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
              }}
              onCameraReady={() => setCameraReady(true)}
            />
          )}
          {cameraReady && (
            <>
              <ImageBackground
                resizeMode="cover"
                source={qrCodePlaceholder}
                style={styles.contentContainer}>
                {/* uncomment this line for testing in emulator */}
                {/* <Button onPress={() => handleBarCodeScanned({type: null, data: "https://ipfs.io/ipfs/QmaKt43urGxNrQK65zwyZvyYskvKncuaQFTXdDipQVTW8Z"}) } >Hit URL</Button> */}
                {scanned && (
                  <View
                    style={{
                      position: 'absolute',
                      right: 0,
                      left: 0,
                      bottom: `${
                        Platform.OS === 'ios'
                          ? '20%'
                          : activeHeight > 665
                          ? '12%'
                          : activeHeight < 540
                          ? '20%'
                          : '15%'
                      }`,
                    }}>
                    <GradientButton
                      buttonLabel={'Tap to Scan Again'}
                      onPress={rescan}
                    />
                  </View>
                )}
              </ImageBackground>
            </>
          )}
        </React.Fragment>
      ) : (
        <>
          <React.Fragment>
            <View style={styles.contentContainer}>
              <GradientButton
                buttonLabel={'Allow access to the camera'}
                onPress={requestPermissions}
              />
            </View>
          </React.Fragment>
        </>
      )}
      {isLoadingCatalog ? (
        <Portal>
          <Modal
            visible={isLoadingCatalog}
            dismissable={false}
            style={{ margin: 0 }}>
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" />
              <Text style={{ color: '#FFF' }}>
                Successfully minted your collectible
              </Text>
              <Text style={{ color: '#FFF' }}>Loading your collectible...</Text>
            </View>
          </Modal>
        </Portal>
      ) : (
        <></>
      )}

      {isReadingData ? (
        <Portal>
          <Modal
            visible={isReadingData}
            dismissable={false}
            style={{ margin: 0 }}>
            <View style={styles.loadingContainer}>
              <ActivityIndicator size="large" />
              <Text style={{ color: '#FFF' }}>{statusMessage}</Text>
              <Text style={{ color: '#FFF' }}>
                Hang on! This is worth waiting for..
              </Text>
            </View>
          </Modal>
        </Portal>
      ) : (
        <></>
      )}
      {showBarcodeError ? (
        <ModalComponent
          showModal={showBarcodeError}
          dismissable={false}
          enableHeader={false}>
          <View style={{ paddingTop: 25, paddingLeft: 15, paddingRight: 15 }}>
            <Text style={globalStyle.modalTextStyle}>{barcodeError}</Text>
          </View>
          <View
            style={[
              globalStyle.modalButtonContainer,
              { justifyContent: 'center' },
            ]}>
            <Button
              onPress={() => setShowBarcodeError(false)}
              style={globalStyle.modalYesButtonStyle}
              labelStyle={globalStyle.modalYesButtonLabelStyle}>
              OK
            </Button>
          </View>
        </ModalComponent>
      ) : (
        <></>
      )}
      {showCollectibleMintedMsg ? (
        <ModalComponent
          showModal={showCollectibleMintedMsg}
          dismissable={false}
          enableHeader={false}>
          <View style={{ paddingTop: 25, paddingLeft: 15, paddingRight: 15 }}>
            <Text style={globalStyle.modalTextStyle}>
              {'QR code scanned successfully.'}
            </Text>
          </View>
          <View
            style={[
              globalStyle.modalButtonContainer,
              { justifyContent: 'center' },
            ]}>
            <Button
              onPress={() => redirectBack()}
              style={globalStyle.modalYesButtonStyle}
              labelStyle={globalStyle.modalYesButtonLabelStyle}>
              OK
            </Button>
          </View>
        </ModalComponent>
      ) : (
        <></>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    justifyContent: 'center',
    alignContent: 'center',
    position: 'relative',
  },
  titleContainer: {
    minHeight: 50,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(20,20,20,0.7)',
  },
  title: {
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  loadingContainer: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    borderColor: '#FFFFFF',
    borderWidth: 1.5,
    borderStyle: 'solid',
    borderRadius: 5,
  },
  buttonCaption: {
    color: '#FFF',
    justifyContent: 'center',
    margin: 5,
    alignSelf: 'center',
    fontSize: 18,
  },
});

export default BarcodeReader;
