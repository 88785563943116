import { APP_MAP_COLLECTIBLE_CONSTRAINT, MAX_DATE_TIME, MIN_DATE_TIME, getAnynetStaticProvider } from '../appconstants';
import { CollectionType } from '../enums/collection.enum';
import { PassportType } from '../enums/passportCategory.enum';
import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import { getThirdPartyCollectiableDetails, getThirdPartyCollectiableListByPassport } from '../slices/OfferSlice';
import { Loot8TieredPOAPCollection__factory } from '../typechain';
import { LogCustomError } from './AppLogger';
import { getData, storeData } from './AppStorage';
import { getLocalDateAndTime } from './DateHelper';
import { formatPriceUpToTrillion } from './Gadgets';
import { getMeterToFeet, getMeterToMile } from './locationHelper';

// get collection constraint which needs to fullfil to mint the passport/collectible.
export const getCollectionConstraint = async (
  walletAddres: string,
  activePassportDetailsList: ICollectibleDetail[],
  activeDigitalCollectibleList: ICollectibleDetail[],
  item: ICollectibleDetail,
  staticProvider,
  networkId,
  isCache = true,
) => {
  let collectibleConstrain = isCache ? await getData(APP_MAP_COLLECTIBLE_CONSTRAINT(item?.address)) : null;
  if (collectibleConstrain) return collectibleConstrain;

  let constraint = [];

  // set radius constraint
  if (item?.area[1]) {
    constraint.push(
      `Be within ${
        Number(item?.area[1]) > 100 ? getMeterToMile(item?.area[1]) : getMeterToFeet(item?.area[1])
      } radius around this location!`,
    );
  }
  // set timestamp constraint
  if (item?.start && item?.end && item?.start !== MIN_DATE_TIME && item?.end !== MAX_DATE_TIME) {
    constraint.push(`${getLocalDateAndTime(item?.start)}. - ${getLocalDateAndTime(item?.end)}.`);
  } else if (item?.start && item?.start !== MIN_DATE_TIME) {
    constraint.push(`Available from ${getLocalDateAndTime(item?.start)}.`);
  } else if (item?.end && item?.end !== MAX_DATE_TIME) {
    constraint.push(`Available till ${getLocalDateAndTime(item?.end)}.`);
  }

  //Set Loot8 token constrain

  if (
    item?.passportType &&
    (item?.passportType === PassportType.SUBSCRIPTION || item?.passportType === PassportType.SUBSCRIBED)
  ) {
    if (item?.buyPrice && item?.buyPrice > 0) {
      const formattedPrice = formatPriceUpToTrillion(item?.buyPrice);
      constraint.push(`Subscribe with ${formattedPrice} Loot8 tokens.`);
    } else {
      constraint.push(`Subscribe for Free!`);
    }
  }

  // set collectible linking with passport constraint.
  if (
    item?.collectionType === CollectionType.COLLECTION ||
    item?.collectionType === CollectionType.OFFER ||
    item?.collectionType === CollectionType.TICKET
  ) {
    let attachedPassport = [];
    item?.linkCollectible?.map((collection, index) => {
      const passport = activePassportDetailsList?.find(
        x => x?.address?.toLocaleLowerCase() === collection?.toLocaleLowerCase(),
      );
      if (passport?.name && passport?.name.trim() != '') {
        attachedPassport.push(passport?.name);
      }
    });

    if (attachedPassport && attachedPassport.length > 0) {
      if (attachedPassport.length === 1) {
        constraint.push(`Hold passport "${attachedPassport[0]}" in your wallet.`);
      } else {
        constraint.push(`Hold passport at least one from "${attachedPassport.join(', ')}" in your wallet.`);
      }
    }
  }

  // set mint with linked only constrain.
  if (item?.mintWithLinkedOnly && item?.collectionType === CollectionType.PASSPORT) {
    // check POAP and Tiered POAP
    let attachedPOAPCollectible = [];
    const whiteListedCollections = await getThirdPartyCollectiableListByPassport({
      networkID: networkId,
      provider: staticProvider,
      passportAddress: item.address,
    });
    if (whiteListedCollections && whiteListedCollections.length > 0) {
      for (let j = 0; j < whiteListedCollections.length; j++) {
        const whiteListedColl = whiteListedCollections[j];
        let collectiableDetails = await getThirdPartyCollectiableDetails({
          chainId: whiteListedColl.chainId,
          provider: getAnynetStaticProvider(whiteListedColl.chainId),
          collectiableAddress: whiteListedColl.source,
          address: walletAddres,
        });
        if (collectiableDetails && collectiableDetails.length > 0) {
          const collectionName = collectiableDetails[0]?.name;
          if (collectionName && collectionName.trim() != '') {
            attachedPOAPCollectible.push(collectionName);
          }
        }
      }
    }

    // check collectible
    let attachedCollectible = [];
    item?.linkCollectible?.map((collection, index) => {
      const collectible = activeDigitalCollectibleList?.find(
        x => x?.address?.toLocaleLowerCase() === collection?.toLocaleLowerCase(),
      );
      if (collectible && collectible?.collectionType === CollectionType.COLLECTION) {
        // avoid to show constraint message in case collectible attached with only one passport.
        if (
          !(
            collectible?.linkCollectible?.length === 1 &&
            collectible?.linkCollectible.find(x => x?.toLocaleLowerCase() === item?.address.toLocaleLowerCase())
          )
        ) {
          if (collectible?.name && collectible?.name.trim() != '') {
            attachedCollectible.push(collectible?.name);
          }
        }
      }
    });

    // set POAP message.
    let collectibleMessage = '';
    if (attachedPOAPCollectible && attachedPOAPCollectible.length > 0) {
      if (attachedPOAPCollectible.length === 1) {
        collectibleMessage = `Scan the phygital, "${attachedPOAPCollectible[0]}".`;
      } else {
        collectibleMessage = `Scan the phygital at least one from "${attachedPOAPCollectible.join(', ')}".`;
      }
    }
    // set Collectible message.
    if (attachedCollectible && attachedCollectible.length > 0) {
      collectibleMessage = collectibleMessage ? (collectibleMessage += ` OR `) : collectibleMessage;
      if (attachedCollectible.length === 1) {
        collectibleMessage += `Hold collectible, "${attachedCollectible[0]}" in your wallet.`;
      } else {
        collectibleMessage += `Hold collectible at least one from "${attachedCollectible.join(', ')}" in your wallet.`;
      }
    }
    if (collectibleMessage) {
      constraint.push(collectibleMessage);
    }
  }

  // set constrain for coupon
  if (item?.collectionType === CollectionType.OFFER && item?.isCoupon) {
    // check POAP and Tiered POAP
    let attachedPOAPCollectible = [];
    try {
      if (item?.tierCollections && item?.tierCollections.length > 0) {
        for (let j = 0; j < item?.tierCollections.length; j++) {
          const tierCollection = item?.tierCollections[j];
          const collectibleContract = Loot8TieredPOAPCollection__factory.connect(
            tierCollection,
            getAnynetStaticProvider(item?.chainId),
          );
          const couponTierNames = await collectibleContract.getCouponTierNames(item?.address);
          if (couponTierNames && couponTierNames.length > 0) {
            couponTierNames?.map(collectionName => {
              if (collectionName && collectionName.trim() != '') {
                attachedPOAPCollectible.push(collectionName);
              }
            });
          }
        }
      }
    } catch (error) {
      LogCustomError(`MAP CONSTRAINT -> Coupon Tiered POAP - ${item?.address}`, error.name, error.message, error.stack);
    }
    // set POAP message.
    let collectibleMessage = '';
    if (attachedPOAPCollectible && attachedPOAPCollectible.length > 0) {
      if (attachedPOAPCollectible.length === 1) {
        collectibleMessage = `Scan the phygital, "${attachedPOAPCollectible[0]}".`;
      } else {
        collectibleMessage = `Scan the phygital at least one from "${attachedPOAPCollectible.join(', ')}".`;
      }
    }
    if (collectibleMessage) {
      constraint.push(collectibleMessage);
    }
  }

  await storeData(APP_MAP_COLLECTIBLE_CONSTRAINT(item?.address), constraint);

  return constraint;
};
