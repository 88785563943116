import { Pressable, StyleSheet, Text, View } from 'react-native';
import React from 'react';
import ProfileAvatar from '../ProfileAvatar';
import { Title } from 'react-native-paper';
import { defaultTheme } from '../../themes/loot8';

const MessageInviteComponent = ({
  openModalPopup,
  user,
  friendRequestStatusMsg,
  latestFriendRequest,
  isNextFriendReqAllowed,
  threeHoursWarningText,
}) => {
  return (
    <>
      <View style={styles.directMessageProfileContainer}>
        <Pressable onPress={() => openModalPopup(user, true)}>
          {user && (
            <ProfileAvatar
              style={{ backgroundColor: user?.bgColor }}
              size={55}
              source={
                user?.avatarURI &&
                user?.avatarURI != '' &&
                user?.avatarURI != 'no-avatar' &&
                !user?.avatarURI.includes('ipfs://') &&
                !user?.avatarURI.includes('assets_avatars_p_') &&
                !user?.avatarURI.includes('file:///')
                  ? { uri: user?.avatarURI }
                  : null
              }
              userAddress={user?.wallet}
            />
          )}
        </Pressable>
        <Title style={styles.userTitle}>
          {`${user?.name}`}
          <Text style={styles.friendUserIdText}>{` #${user?.id}`}</Text>
        </Title>
        {user?.status && (
          <Text style={styles.directMessageStatusText}>{user.status}</Text>
        )}
      </View>
      <View style={styles.warningContainer}>
        <Text style={styles.warningText}>{`Invite ${user?.name} to chat`}</Text>
        <Text style={[styles.warningText, { marginTop: 10 }]}>
          {friendRequestStatusMsg}
        </Text>

        {latestFriendRequest && !isNextFriendReqAllowed && (
          <Text style={styles.warningCancelBtn}>{threeHoursWarningText}</Text>
        )}
      </View>
    </>
  );
};

export default MessageInviteComponent;

const styles = StyleSheet.create({
  directMessageProfileContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 10,
  },
  userTitle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    maxWidth: '90%',
    textAlign: 'center',
  },
  friendUserIdText: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
  directMessageStatusText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    maxWidth: '90%',
  },

  warningCancelBtn: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    marginTop: 10,
    textAlign: 'center',
  },
  warningText: {
    fontSize: 12,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  warningContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
    borderTopWidth: 1,
    borderColor: '#FFFFFF17',
  },
});
