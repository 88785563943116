import React, { useEffect, useState } from 'react';
import { View, Text, Platform, StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';
import { useRevenueCat } from '../../hooks/RevenueCatProvider';
import { getAppConfiguration } from '../../appconstants';
import { LogCustomError } from '../../helpers/AppLogger';
import { AppEnvironment } from '../../enums/env.enum';
import HOCContainer from '../HOCContainer';
import AppLoaderComponent from '../Loader';
import EmptyList from '../EmptyList';
import TokenPackageCard from '../TokenPackageCard';

const PackageItems = ({ onPackagesAvailable, navigation }) => {
  const { packages, purchasePackage, restorePermissions } = useRevenueCat();
  const [webPackages, setWebPackages] = useState([]);
  const [isNativeDeviceAndDev, setIsNativeDeviceAndDev] = useState(false);

  const loadAppConfig = async () => {
    try {
      let config = await getAppConfiguration();
      if (
        Platform.OS !== 'web' &&
        (config.env == AppEnvironment.DEVELOPMENT ||
          config.env == AppEnvironment.STAGING)
      ) {
        setIsNativeDeviceAndDev(true);
      }
      if (config && Platform.OS == 'web') {
        const webInAppPurchase = config?.webInAppPurchase;
        if (webInAppPurchase?.packages?.length > 0) {
          setWebPackages(webInAppPurchase?.packages);
        }
      }
    } catch (e) {
      LogCustomError(
        'TokenPackages | loadAppConfig',
        e.name,
        e.message,
        e.stack,
      );
    }
  };

  useEffect(() => {
    loadAppConfig();
  }, []);

  useEffect(() => {
    if (webPackages?.length == 0 && packages?.length == 0) {
      onPackagesAvailable(false);
    } else if (isNativeDeviceAndDev) {
      onPackagesAvailable(false);
    } else {
      onPackagesAvailable(true);
    }
  }, [webPackages, packages, isNativeDeviceAndDev]);

  const filteredData = Platform.OS === 'web' ? webPackages : packages;
  return (
    <>
      {filteredData?.map(pack => (
        <TokenPackageCard item={pack} navigation={navigation} />
      ))}
      {filteredData?.length == 0 && (
        <Text style={componentStyles.noPackageWarning}>
          No packages available currently for sale
        </Text>
      )}
    </>
  );
};

const TokenPackages = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [packagesAvailable, setPackagesAvailable] = useState(true);

  const onPackagesAvailable = packages => {
    if (packages) {
      setPackagesAvailable(true);
    } else {
      setPackagesAvailable(false);
    }
  };

  return (
    <>
      {/* container */}
      <View style={componentStyles.container}>
        {!loading && packagesAvailable ? (
          <>
            <Text style={componentStyles.tokenPackagesListTitle}>
              Select amount to Deposit
            </Text>
            <View style={{ marginTop: 10 }} />
            <PackageItems
              navigation={navigation}
              onPackagesAvailable={onPackagesAvailable}
            />
          </>
        ) : (
          <EmptyList />
        )}
      </View>
      {loading && <AppLoaderComponent />}
    </>
  );
};

export default HOCContainer({
  OriginalComponent: TokenPackages,
  title: 'Deposit Funds',
});

const componentStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tokenPackagesListTitle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    textAlign: 'center',
  },
  noPackageWarning: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    alignSelf: 'center',
    marginVertical: 25,
  },
});
