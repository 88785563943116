import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import FriendsScreen from '../screens/FriendsScreen';
import CustomHeader from './AnimatedHeader/CustomHeader';

const Stack = createStackNavigator();

function FriendsNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={'Friends'}
      screenOptions={{
        headerShown: true,
        headerStyle: {
          borderBottomWidth: 0,
          backgroundColor: 'transparent',
          height: Platform.OS === 'ios' ? 120 : 70,
        },
        headerMode: 'float',
        header: props => <CustomHeader {...props} />,
      }}>
      <Stack.Screen name={'Friends'} component={FriendsScreen} />
    </Stack.Navigator>
  );
}

export default FriendsNavigator;
