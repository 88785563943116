import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';

import ScreenTitle from '../ScreenTitle';
import { defaultTheme } from '../../themes/loot8';
import useIsResponsive from '../../hooks/useIsResponsive';
import InfoLoader from '../Loader/InfoLoader';
import { useAppSelector } from '../../hooks';
import { ModalDisconnected } from '../Modal/DisconnectedModal';
import { useNetInfo } from '@react-native-community/netinfo';

interface HOCContainerProps {
  OriginalComponent: React.ComponentType<any>;
  title?: string;
  withBottomTabs?: boolean;
  isScrollEnabled?: boolean;
}

const HOCContainer: React.FC<HOCContainerProps> = ({
  OriginalComponent,
  title,
  withBottomTabs = true,
  isScrollEnabled = true,
}) => {
  function ChildComponent() {
    const [dynamicTitle, setDynamicTitle] = useState('');
    const [networkDisconnected, setNetworkDisconnected] = useState(false);
    const navigation = useNavigation();
    const netInfo = useNetInfo();
    const route = useRoute();
    const isResponsive = useIsResponsive();
    const {
      showListingModal: { isListed, showModal: showListingModal },
      showTransferModal: { showModal: showTransferModal },
      showEstInviteCodeValidationModal: {
        showModal: showEstInviteCodeValidationModal,
      },
      showEstFreeProfileCreationModal: {
        showModal: showEstFreeProfileCreationModal,
      },
    } = useAppSelector(state => state.Modals);
    useEffect(() => {
      const handleDisconnectivity = async () => {
        if (netInfo.isConnected == false) {
          setNetworkDisconnected(true);
        } else {
          setNetworkDisconnected(false);
        }
      };
      if (navigation.isFocused()) handleDisconnectivity();
    }, [netInfo.isConnected]);
    return (
      <View style={styles.container}>
        {title && <ScreenTitle title={title} />}
        {dynamicTitle && <ScreenTitle title={dynamicTitle} />}
        {isScrollEnabled ? (
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={styles.scrollContainer}
            bounces={false}
            contentContainerStyle={[
              styles.scrollContent,
              withBottomTabs && { paddingBottom: 150 },
            ]}>
            <View
              style={[
                isResponsive
                  ? styles.desktopContainer
                  : styles.contentContainer,
              ]}>
              <OriginalComponent
                navigation={navigation}
                route={route}
                setDynamicTitle={setDynamicTitle}
              />
            </View>
          </ScrollView>
        ) : (
          <View
            style={[
              { flex: 1, paddingHorizontal: defaultTheme.HORIZONTAL_PADDING },
              isResponsive ? styles.desktopContainer : styles.contentContainer,
            ]}>
            <OriginalComponent
              navigation={navigation}
              route={route}
              setDynamicTitle={setDynamicTitle}
            />
          </View>
        )}
        {showListingModal && (
          <InfoLoader
            text1={
              'Please sit tight while your ExPass is being ' +
              (isListed
                ? 'unlisted from the Marketplace'
                : 'listed on the Marketplace')
            }
            text2={''}
            needChangeMessage={false}
          />
        )}
        {showTransferModal && (
          <InfoLoader
            text1={
              'Please sit tight while your Collectible is being transferred'
            }
            text2={''}
            needChangeMessage={false}
          />
        )}
        {showEstInviteCodeValidationModal && (
          <InfoLoader
            text1={'Please sit tight while your Code is being validated'}
            text2={''}
            needChangeMessage={false}
          />
        )}
        {showEstFreeProfileCreationModal && (
          <InfoLoader
            text1={'Please sit tight while your Free profile is being created'}
            text2={''}
            needChangeMessage={false}
          />
        )}
        {networkDisconnected && (
          <ModalDisconnected
            isDisconnected={networkDisconnected}
            navigation={navigation}
          />
        )}
      </View>
    );
  }
  return ChildComponent;
};

export default HOCContainer;

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR },
  scrollContainer: {
    flex: 1,
  },
  scrollContent: {
    flexGrow: 1,
    paddingHorizontal: defaultTheme.HORIZONTAL_PADDING,
  },
  desktopContainer: {
    width: '100%',
    maxHeight: '100%',
    maxWidth: defaultTheme.CONTENT_CONTAINER_MAX_WIDTH,
    alignSelf: 'center',
    flex: 1,
  },
  contentContainer: {
    flex: 1,
  },
});
