import { addresses, getNetwork } from '../appconstants';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { CollectionManager, CollectionManager__factory } from '../typechain';
import { StaticJsonRpcProvider } from '@ethersproject/providers';

export class CollectionManagerData {
  private static collectionManger: CollectionManager;
  private static cachedData: ICollectionMangerCachedData = {};
  public static CollectionManagerDataCacheKey = '@loot8-ColManagerData-';

  public static async initialize(provider: StaticJsonRpcProvider) {
    if (!this.collectionManger)
      this.collectionManger = CollectionManager__factory.connect(
        addresses[getNetwork()].CollectionManager,
        provider,
      );
  }

  public static async getCollectionChainId(
    collectionAddress: string,
  ): Promise<number> {
    try {
      if (this.cachedData?.collectionChainIds) {
        const collectionInfo = this.cachedData.collectionChainIds.find(
          p => p.collection.toLowerCase() == collectionAddress.toLowerCase(),
        );
        if (collectionInfo?.chainId) {
          return collectionInfo.chainId;
        }
      }
    } catch (error) {
      LogToLoot8Console(
        'getCollectionChainId: error while reading cached chain id',
      );
    }

    const chainId =
      await this.collectionManger.collectionChainId(collectionAddress);
    try {
      this.cachedData.collectionChainIds = this.cachedData?.collectionChainIds
        ? [
            ...this.cachedData?.collectionChainIds,
            {
              chainId: +chainId,
              collection: collectionAddress,
            },
          ]
        : [
            {
              chainId: +chainId,
              collection: collectionAddress,
            },
          ];
    } catch (error) {
      LogToLoot8Console('getCollectionChainId: error while storing chain id');
    }
    return +chainId;
  }
}

export interface ICollectionMangerCachedData {
  collectionChainIds?: ICollectionChainIds[];
}

export interface ICollectionChainIds {
  collection: string;
  chainId: number;
}
