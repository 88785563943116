import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    height: 48,
    borderRadius: 25,
    overflow: 'hidden',
    padding: 2,
  },
  activeBg: {
    flex: 1,
    borderRadius: 25,
    overflow: 'hidden',
  },
  gradientBg: {
    flex: 1,
    borderRadius: 25,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 1,
  },
  contentContainer: {
    flex: 1,
    borderRadius: 25,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    overflow: 'hidden',
  },
  itemContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  itemText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  counterCircle: {
    width: 16,
    height: 16,
    marginLeft: 6,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFD400',
  },
  counterBubbleText: {
    color: '#111',
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
  },
});
