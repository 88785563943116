import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  APP_STORAGE_GET_FRIENDS,
  FRIEND_PRIVATE_MESSAGES_LAST_REQSENT,
  getAnynetStaticProvider,
  NETWORK_CALL_BATCHSIZE,
  NetworkId,
  timeout,
} from '../appconstants';
import { LogCustomError } from '../helpers/AppLogger';
import { getData, storeData } from '../helpers/AppStorage';
import { showToastMessage } from '../helpers/Gadgets';
import {
  getUserDetailsFromIPFS,
  subscribeToIPFSPubSub,
  publishMessageToIPFSTopic,
  getUserJSONdata,
} from '../helpers/ipfs';
import { IUser } from '../interfaces/IUser.interface';
import { RootState } from '../store';
import {
  IAddressOnlyAsyncThunk,
  IFetchAllUserAsyncThunk,
  IFollowUserBaseAsyncThunk,
  IMessageMetaData,
  IWalletBaseAsyncThunk,
} from './interfaces';
import { NotificationType, pushNotification } from './NotificationSlice';
import { setFriendForSender } from './PassportMessageSlice';
import {
  getMutualFriendStatus,
  getPrivateMsgFriendRequest,
  updateFriendsOnLambda,
} from '../helpers/Messages';
import {
  GetERC721TransferMessage,
  GetTransferNFTCallLogsData,
} from '../helpers/MsgHelper';
import { Wallet } from 'ethers';
import { SendMetaTX } from './AppSlice';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
//import { getAllUsersData } from "./AppUserSlice";
import { getSyncedTime } from '../helpers/DateHelper';
import { Platform } from 'react-native';
import {
  getStoredContentByFile,
  storeContentToFile,
} from '../helpers/AppFileStorage';
import * as FileSystem from 'expo-file-system';
import { getAllUsersData } from './AppUserSlice';
import { DirectionType } from '../interfaces/IMessages';
import { batchifyRequests, getBatchedUserJsonData } from './helpers';

export const allUsersFile = `loot8-all-users.txt`;

export interface FriendsState {
  currentFriends: IFriends[];
  possibleFriends: IFriends[];
  mutualFriends: IFriends[];
  loading: boolean;
  pendingIPNSPublishCount: number;
  currentFriendsCount: number;
  allAvatarsLoaded: boolean;
  loadingFriendsData: boolean;
  selectedFriendStore: any;
  latestChatFriend: IMutualFriend;
  requestSentId: string;
}

let _initialFriendsLoadPromise;

export const getFriendsInitialLoadPromise = () => {
  return _initialFriendsLoadPromise;
};

export const processInitialFriendsLoadPromise = () => {
  let internalResolve;
  _initialFriendsLoadPromise = new Promise<boolean>((resolve, reject) => {
    internalResolve = resolve;
  });
  return internalResolve;
};

export interface IIpnsFriendsObj {
  wallet: string;
}
export interface IFriendsIPNS {
  friends: IIpnsFriendsObj[];
}

export interface ISearchFriendsThunk {
  searchText: string;
  isMutualFriend: boolean;
  isManageFriends: boolean;
}

export interface ISendNFTToFriend {
  networkID: NetworkId;
  tokenAddress: string;
  friendWalletAddress: string;
  wallet: Wallet;
  tokenId: number | string;
}

export interface IOldStatusObj {
  oldStatus: string;
}

export interface IFriends extends IUser, IOldStatusObj {
  isFriend: boolean;
  bgColor: string;
  collectibles?: Array<string>;
  isMutualFriend: boolean;
  friendRequest: IFriendRequestStaus;
}

export interface IMutualFriend {
  friendWallet: string;
  isMutualFriend: boolean;
}
export interface IFriendRequestStaus {
  friendWallet: string;
  isRequestSend: boolean;
  isCancelled: boolean;
  messageId: string;
  timestamp: any;
}

const enum FriendListUpdKey {
  IS_FRIEND = 'isFriend',
  STATUS = 'status',
  AVATAR_URI = 'avatarURI',
  NAME = 'name',
  IS_MUTUAL_FRIEND = 'isMutualFriend',
  IS_SEND_REQUEST = 'isSendRequest',
}
const colors = [
  '#EF972C',
  '#EF233C',
  '#EDF2F4',
  '#388BEE',
  '#4E7E9E',
  '#8265D3',
  '#32BEA6',
  '#F64B4B',
  '#D43D2D',
  '#AB339B',
  '#3333AA',
  '#D21818',
];
const collectibles = [
  'badge_mayor',
  'badge_money_bag',
  'badge_gift',
  'badge_driver',
];

export const pickBgColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

//Temp function to provide mock collectibles as of now
export const pickCollectibles = () => {
  let totalCollctables = Math.floor(Math.random() * collectibles.length);
  let returnCollectables = [];
  for (let i = 0; i <= totalCollctables; i++) {
    returnCollectables.push(collectibles[i]);
  }
  //return returnCollectables;
  return ['badge_coming_soon']; //As of now we will display same badge for each user.
};

let usersFriendsList: IFriends[] = [];

export const getCurrentFriends = () => {
  return usersFriendsList.filter(p => p.isFriend);
};

const getMutualFriends = () => {
  return usersFriendsList.filter(p => p.isMutualFriend);
};

const updateUsersFriendsList = (
  userAddress: string,
  updateKey: string,
  newStatus: string = '',
  newAvatarURI: string = '',
  name: string = '',
  isRequest: boolean = false,
  messageID: string = '',
  timestamp: string = '',
): { friends: IFriends[]; hasUpdated: boolean } => {
  let hasUpdated = false;
  const elementIndex = usersFriendsList.findIndex(
    element => element.wallet?.toLowerCase() === userAddress?.toLowerCase(),
  );
  let friends = [...usersFriendsList];
  if (elementIndex > -1) {
    if (updateKey == FriendListUpdKey.IS_FRIEND) {
      friends[elementIndex] = {
        ...friends[elementIndex],
        isFriend: !friends[elementIndex].isFriend,
      };
      hasUpdated = true;
    } else if (updateKey == FriendListUpdKey.STATUS) {
      const existingData = friends[elementIndex];
      if (existingData?.status !== newStatus) {
        friends[elementIndex] = { ...existingData, status: newStatus };
        hasUpdated = true;
      }
    } else if (updateKey == FriendListUpdKey.AVATAR_URI) {
      const existingData = friends[elementIndex];
      if (existingData?.avatarURI !== newAvatarURI) {
        friends[elementIndex] = { ...existingData, avatarURI: newAvatarURI };
        hasUpdated = true;
      }
    } else if (updateKey == FriendListUpdKey.NAME) {
      friends[elementIndex] = { ...friends[elementIndex], name: name };
      hasUpdated = true;
    } else if (updateKey == FriendListUpdKey.IS_MUTUAL_FRIEND) {
      friends[elementIndex] = {
        ...friends[elementIndex],
        isMutualFriend: !friends[elementIndex].isMutualFriend,
      };
      hasUpdated = true;
    } else if (updateKey == FriendListUpdKey.IS_SEND_REQUEST) {
      friends[elementIndex] = {
        ...friends[elementIndex],
        friendRequest: {
          friendWallet: friends[elementIndex].wallet,
          isCancelled: isRequest ? false : true,
          isRequestSend: true,
          timestamp: timestamp,
          messageId: messageID,
        },
      };
      hasUpdated = true;
    }
  }
  return { friends, hasUpdated };
};

const updateLocalStore = async (userAddress: string, frndWallets) => {
  const storeKey = APP_STORAGE_GET_FRIENDS(userAddress);
  const storeLastUpd = getSyncedTime();

  //LogToLoot8Console('friends data store time:' + new Date(storeLastUpd));
  let storedFriendsData = { lastUpd: storeLastUpd, friends: frndWallets };
  storeData(storeKey, storedFriendsData);
};

export const getUsers = async (
  { networkID, provider, dispatch }: { networkID; provider; dispatch },
  isCached = false,
): Promise<any> => {
  let users;
  if (isCached && (Platform.OS == 'ios' || Platform.OS == 'android')) {
    let allCachedUsers;
    if (Platform.OS == 'ios') {
      allCachedUsers = await getData(allUsersFile);
    } else {
      allCachedUsers = await getStoredContentByFile(
        `${FileSystem.documentDirectory}${allUsersFile}`,
      );
    }
    // update new users list to cache
    users = (await dispatch(getAllUsersData({ networkID, provider }))).payload;
    //Loot8-1881: coverting user data which is here a userAttributes struct to string omits property names like id, name, wallet etc
    //hence we do not get those proeprties later while converting string back to JSON object.
    //so creating array of objects with property names to be used later while loading friends data.
    let allUserData = [];
    users.forEach(u => {
      let user = {
        id: Number(u.id),
        name: u.name,
        wallet: u.wallet,
        avatarURI: u.avatarURI,
        dataURI: u?.dataURI ?? '',
      };
      allUserData.push(user);
    });
    if (Platform.OS == 'ios') {
      await storeData(allUsersFile, JSON.stringify(allUserData));
    } else {
      await storeContentToFile(
        `${FileSystem.documentDirectory}${allUsersFile}`,
        JSON.stringify(allUserData),
      );
    }

    if (allCachedUsers) {
      return JSON.parse(allCachedUsers);
    }
  } else {
    users = (await dispatch(getAllUsersData({ networkID, provider }))).payload;
  }
  return users;
};

// LOOT8-5651 Commenting this function to avoid uploading friends data directly

// export const publishFriendsToMFS = createAsyncThunk(
//   'friends/publishFriendsToMFS',
//   async (
//     { wallet, friendsJson }: { wallet; friendsJson },
//     { getState, dispatch },
//   ) => {
//     await timeout(3000);
//     //LogToLoot8Console('ipns publish upd time' + new Date(friendsJson.lastUpd));
//     await uploadFriendsDataToMFS(wallet, friendsJson);
//   },
// );

export const loadFriendsData = createAsyncThunk(
  'app/loadFriendsData',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      decryptMessage,
      cachedUserData = false,
    }: IFetchAllUserAsyncThunk,
    { getState, dispatch },
  ): Promise<any> => {
    // Setting batchsize to 50, in order to avoid the network calls avalanche for getting status/isMutualFriend/avatars
    const NetworkCallsBatchSize = NETWORK_CALL_BATCHSIZE * 5; //setting it to 50 for now to make it load faster and make sure it doesn't crash
    let usersData: IUser[] = await getUsers(
      { networkID, provider, dispatch },
      cachedUserData,
    );

    if (usersData) {
      usersData = usersData.filter(
        user => user.wallet?.toLowerCase() != address?.toLowerCase(),
      ); //Exclude logged in user from list

      const storeKey = APP_STORAGE_GET_FRIENDS(address);
      const state = getState() as RootState;
      const userData = state.AppUser.UserData;
      const pendingIPNSPublishCount = state.friends.pendingIPNSPublishCount;
      let friendsWallets: string[] = [];
      let storedStatus = [];
      let storeLastUpd = null;
      let ipnsLastUpd = null;
      let friendsData = null;
      let frndWallets = null;
      let friendsJson = null;
      //Fetch friends data
      try {
        let storedFriendsData = await getData(storeKey);
        if (storedFriendsData) {
          storeLastUpd = storedFriendsData.lastUpd;
          //LogToLoot8Console('stored data time' + new Date(storeLastUpd));
        }
        if (
          userData &&
          userData?.wallet != '' &&
          pendingIPNSPublishCount === 0
        ) {
          friendsData = (await getUserJSONdata(userData?.wallet))?.friendList;

          if (friendsData && friendsData.friends) {
            ipnsLastUpd = friendsData.lastUpd;
            //LogToLoot8Console('stored ipns data time' + new Date(ipnsLastUpd));
            if (ipnsLastUpd < storeLastUpd) {
              friendsData = storedFriendsData;
              frndWallets = friendsData.friends ? friendsData.friends : [];
              const frndWalletAddresses = frndWallets.map(f => f.wallet);
              //LogToLoot8Console('ipns copy is older than stored copy; publishing latest data to ipns...');
              // dispatch(publishFriendsToMFS({ wallet, friendsJson }));

              // LOOT8-5651 Adding this function to avoid uploading friends data directly with publishFriendsToMFS function
              await updateFriendsOnLambda(frndWalletAddresses, wallet, 'add');
            } else {
              frndWallets = friendsData.friends ? friendsData.friends : [];
              friendsJson = { lastUpd: ipnsLastUpd, friends: frndWallets };
            }
            storeData(storeKey, friendsJson);
          }
        }
        if (!friendsData || !friendsData.friends) {
          if (storedFriendsData) {
            friendsData = storedFriendsData;
          }
        }
      } catch (e) {
        LogToLoot8Console(e);
        LogCustomError('FriendsSlice: LoadFriends', e.name, e.message, null);
      }

      if (friendsData) {
        friendsWallets = friendsData.friends.map(frnd => {
          return frnd.wallet;
        });
      }
      //Check for mutual friendship
      let mutualFriendList: IMutualFriend[] = [];
      await batchifyRequests(
        friendsWallets,
        NetworkCallsBatchSize,
        async frndAddress => {
          const isMutualFriend = await getLatestMutualFriendStatus(
            frndAddress,
            wallet,
          );
          if (isMutualFriend) {
            mutualFriendList.push({
              friendWallet: frndAddress,
              isMutualFriend: isMutualFriend,
            });
          }
        },
      );

      let direction = DirectionType.later;
      let friendResquestStaus: IFriendRequestStaus[] = [];
      await batchifyRequests(
        friendsWallets,
        NetworkCallsBatchSize,
        async frndAddress => {
          // Get last friend request sent timestamp
          let timestamp = await getData(
            FRIEND_PRIVATE_MESSAGES_LAST_REQSENT(frndAddress, address),
          );
          if (!timestamp) {
            timestamp = getSyncedTime();
          }

          let resData = await getPrivateMsgFriendRequest(
            networkID,
            frndAddress,
            wallet,
            timestamp ?? null,
            direction,
            null,
            25,
            true,
          );
          if (resData && resData?.messages && resData?.messages.length > 0) {
            if (resData.messages[0]?.isCancelled) {
              friendResquestStaus.push({
                friendWallet: frndAddress,
                isRequestSend: true,
                isCancelled: true,
                messageId: resData.messages[0].messageId,
                timestamp: resData.messages[0].timestamp,
              });
            } else {
              friendResquestStaus.push({
                friendWallet: frndAddress,
                isRequestSend: true,
                isCancelled: false,
                messageId: resData.messages[0].messageId,
                timestamp: resData.messages[0].timestamp,
              });
            }
          } else {
            friendResquestStaus.push({
              friendWallet: frndAddress,
              isRequestSend: false,
              isCancelled: false,
              messageId: '',
              timestamp: null,
            });
          }
        },
      );

      //Check for FriendRequest
      let friendUsersData: IUser[] = usersData.filter(
        u =>
          friendsWallets.findIndex(
            f => f?.toLowerCase() == u.wallet?.toLowerCase(),
          ) >= 0,
      );
      const friendsStatuses = await getBatchedUserJsonData(
        friendUsersData.flatMap((user: { wallet: string }) =>
          user?.wallet.toLowerCase(),
        ),
        10,
        'status',
      );
      await batchifyRequests(
        friendUsersData,
        NetworkCallsBatchSize,
        async user => {
          const status = friendsStatuses
            ? friendsStatuses[user?.wallet.toLowerCase()]
            : '';
          if (status) {
            const friendsList = state.friends.currentFriends;
            if (friendsList && friendsList.length > 0) {
              let currFriend = state.friends.currentFriends.find(
                p => p.wallet?.toLowerCase() == user.wallet?.toLowerCase(),
              );
              if (
                currFriend &&
                currFriend.status &&
                currFriend.status != '' &&
                currFriend.status !== status
              ) {
                dispatch(
                  pushNotification({
                    subject: currFriend.name + ' updated tagline',
                    body: status,
                    uri: currFriend.avatarURI,
                    timeStamp: Number.parseInt(getSyncedTime().toString()),
                    id: currFriend.wallet,
                    notificationType: NotificationType.UserStatusChange,
                  }),
                );
              }
            }
            const { friends, hasUpdated } = updateUsersFriendsList(
              user.wallet,
              FriendListUpdKey.STATUS,
              status,
            );
            if (hasUpdated) {
              usersFriendsList = friends;
              dispatch(updateFriendStatus({ wallet: user.wallet, status }));
            }
          }
        },
      );

      //Get user avatars from IPFS - call this only after populating users list above
      let usersIPFSAvatarData: IUser[] = usersData.filter(u =>
        u.avatarURI.includes('ipfs://'),
      );
      await batchifyRequests(
        usersIPFSAvatarData,
        NetworkCallsBatchSize,
        async user => {
          const avatarURI: any = await getUserDetailsFromIPFS(user.avatarURI);
          if (avatarURI) {
            const { friends, hasUpdated } = updateUsersFriendsList(
              user.wallet,
              FriendListUpdKey.AVATAR_URI,
              user.status,
              avatarURI,
            );
            if (hasUpdated) {
              usersFriendsList = friends;
              dispatch(updateFriendAvatar({ wallet: user.wallet, avatarURI }));
            }
          }
        },
      );
      // Dispatch setAvatarsLoaded after all batched requests are done
      dispatch(setAvatarsLoaded(true));

      usersFriendsList = usersData.map(user => {
        let isMutualFriend = false;
        let isRequestSend = false;
        let isCancelled = false;
        let messageId = '';
        let timestamp = '';

        if (mutualFriendList.length > 0) {
          let data: IMutualFriend[] = mutualFriendList.filter(
            f => user.wallet?.toLowerCase() === f.friendWallet?.toLowerCase(),
          );
          if (data && data.length > 0) {
            isMutualFriend = data[0].isMutualFriend;
          }
        }
        if (friendResquestStaus.length > 0) {
          let data: IFriendRequestStaus[] = friendResquestStaus.filter(
            f => user.wallet?.toLowerCase() === f.friendWallet?.toLowerCase(),
          );
          if (data && data.length > 0) {
            isRequestSend = data[0].isRequestSend;
            isCancelled = data[0].isCancelled;
            messageId = data[0].messageId;
            timestamp = data[0].timestamp;
          }
        }

        let avatarURI = null;
        let status = null;
        if (usersFriendsList?.length > 0) {
          const existingValue = usersFriendsList.filter(
            f => user.wallet?.toLowerCase() === f.wallet?.toLowerCase(),
          );
          if (existingValue?.length > 0) {
            avatarURI = existingValue[0].avatarURI;
            status = existingValue[0].status;
            // if mutual initially but now removed that lets make it false.
            if (
              existingValue[0].isMutualFriend &&
              !mutualFriendList.find(
                f =>
                  user.wallet?.toLowerCase() === f.friendWallet?.toLowerCase(),
              )
            ) {
              isMutualFriend = false;
            }
          }
        }
        return {
          id: user.id,
          wallet: user.wallet,
          name: user.name,
          avatarURI: avatarURI || user.avatarURI,
          status: status || user.status,
          oldStatus: status || user.status,
          dataURI: user.dataURI ?? '',
          isFriend:
            friendsWallets.length > 0 &&
            friendsWallets.filter(
              f => user.wallet?.toLowerCase() === f?.toLowerCase(),
            ).length > 0
              ? true
              : false,
          isMutualFriend: isMutualFriend,
          friendRequest: {
            friendWallet: user.wallet,
            isCancelled: isCancelled,
            isRequestSend: isRequestSend,
            timestamp: timestamp,
            messageId: messageId,
          },

          bgColor: pickBgColor(),
          collectibles: pickCollectibles(),
          isExist: true,
        };
      });
    }
    return {
      currentFriends: usersFriendsList.filter(f => f.isFriend),
      possibleFriends: usersFriendsList.filter(f => !f.isFriend),
      mutualFriends: usersFriendsList.filter(f => f.isMutualFriend),
    };
  },
);

export const loadFriendsStatusFromIPNS = createAsyncThunk(
  'app/loadFriendsStatusFromIPNS',
  async (
    { networkID, provider, address }: IAddressOnlyAsyncThunk,
    { getState, dispatch },
  ): Promise<any> => {
    //Get friends status(es) from IPNS
    const state = getState() as RootState;

    let friendUsersData: IUser[] = state.friends.currentFriends;
    if (friendUsersData && friendUsersData.length > 0) {
      const friendsStatuses = await getBatchedUserJsonData(
        friendUsersData.flatMap((user: { wallet: string }) =>
          user?.wallet.toLowerCase(),
        ),
        10,
        'status',
      );

      Promise.all(
        friendUsersData.map(async user => {
          const status = friendsStatuses
            ? friendsStatuses[user?.wallet.toLowerCase()]
            : '';
          if (status) {
            let currFriend = state.friends.currentFriends.find(
              p => p.wallet?.toLowerCase() == user.wallet?.toLowerCase(),
            );
            const friendsList = state.friends.currentFriends;
            if (friendsList && friendsList.length > 0) {
              if (
                currFriend &&
                currFriend.status &&
                currFriend.status !== status &&
                status !== currFriend.oldStatus
              ) {
                dispatch(
                  pushNotification({
                    subject: currFriend.name + ' changed tagline',
                    body: status,
                    uri: currFriend.avatarURI,
                    timeStamp: Number.parseInt(getSyncedTime().toString()),
                    id: currFriend.wallet,
                    notificationType: NotificationType.UserStatusChange,
                  }),
                );
              }
            }

            if (currFriend && status !== currFriend.oldStatus) {
              dispatch(updateFriendStatus({ wallet: user.wallet, status }));
            }
          }
        }),
      );
    }
  },
);

export const subscribeToFriendsStatus = createAsyncThunk(
  'app/subscribeToFriendsStatus',
  async (
    { networkID, provider, address, wallet }: IWalletBaseAsyncThunk,
    { getState, dispatch },
  ): Promise<any> => {
    subscribeToIPFSPubSub(
      'loot8-friends-status-changes',
      async (friend, status) => {
        const rootState = getState() as RootState;
        const currentFriends = rootState.friends.currentFriends;
        const currFriend = currentFriends.find(
          p => p.wallet?.toLowerCase() == friend?.toLowerCase(),
        );
        if (currentFriends && currentFriends.length > 0 && currFriend) {
          await dispatch(updateFriendStatus({ wallet: friend, status }));
          if (friend) {
            dispatch(
              pushNotification({
                subject: currFriend.name + ' updated tagline',
                body: status,
                uri: currFriend.avatarURI,
                timeStamp: Number.parseInt(getSyncedTime().toString()),
                id: currFriend.wallet,
                notificationType: NotificationType.UserStatusChange,
              }),
            );
          }
        }

        //storeData(friend.wallet.substring(2, friend.wallet.length).toUpperCase(), status);
      },
    );

    subscribeToIPFSPubSub(
      'loot8-friends-name-changes',
      async (friend, name) => {
        const rootState = getState() as RootState;
        const currentFriends = rootState.friends.currentFriends;
        const currFriend = currentFriends.find(
          p => p.wallet?.toLowerCase() == friend?.toLowerCase(),
        );
        if (currentFriends && currentFriends.length > 0 && currFriend) {
          await dispatch(updateFriendName({ wallet: friend, name }));
        }

        //storeData(friend.wallet.substring(2, friend.wallet.length).toUpperCase(), status);
      },
    );

    subscribeToIPFSPubSub(
      'loot8-friends-unfollow',
      async (friend, friendAddress) => {
        if (address.toLocaleLowerCase() == friendAddress.toLocaleLowerCase()) {
          const rootState = getState() as RootState;
          const currentFriends = rootState.friends.currentFriends;
          const currFriend = currentFriends.find(
            p => p.wallet?.toLowerCase() == friend?.toLowerCase(),
          );
          if (currentFriends && currentFriends.length > 0 && currFriend) {
            await dispatch(
              removeMutualFriend({
                networkID: networkID,
                provider: provider,
                address: address,
                wallet: wallet,
                followUserAddress: currFriend.wallet,
              }),
            );
          }
        }
      },
    );
  },
);

export const addFriend = createAsyncThunk(
  'friends/addFriend',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      followUserAddress,
      newUser,
      isMutual = false,
    }: IFollowUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let userExist = usersFriendsList.findIndex(
      u => u.wallet?.toLowerCase() === followUserAddress?.toLowerCase(),
    );
    if (isMutual && newUser && userExist < 0) {
      let newUserFriend = {
        id: newUser.id,
        wallet: newUser.wallet,
        name: newUser.name,
        avatarURI: newUser.avatarURI,
        status: newUser.status,
        oldStatus: newUser.status,
        dataURI: newUser.dataURI ?? '',
        isFriend: true,
        isMutualFriend: true,
        friendRequest: {
          friendWallet: newUser.wallet,
          isCancelled: false,
          isRequestSend: true,
          timestamp: '',
          messageId: '',
        },
        bgColor: pickBgColor(),
        collectibles: pickCollectibles(),
        isExist: true,
      };
      usersFriendsList.push(newUserFriend);
    } else {
      usersFriendsList = updateUsersFriendsList(
        followUserAddress,
        FriendListUpdKey.IS_FRIEND,
      ).friends;
    }
    // const state = getState() as RootState;
    // let curFrnds = [...state.friends.currentFriends];
    // let posFrnds = [...state.friends.possibleFriends];
    // let mutualFriends = [...state.friends.mutualFriends];

    //update isFriend flag for selected element in CurrentFriends state
    dispatch(
      updateCurrentFriends({ wallet: followUserAddress, isFriend: true }),
    );

    //update isMutulFriend flag for selected element after checking mutual friendship
    let isMutualFriend = isMutual;
    isMutualFriend = await getLatestMutualFriendStatus(
      followUserAddress,
      wallet,
      true,
    );

    if (isMutualFriend) {
      dispatch(
        updateMutualFriends({
          wallet: followUserAddress,
          isMutualFriend: isMutualFriend,
        }),
      );
      //update users friends list
      usersFriendsList = updateUsersFriendsList(
        followUserAddress,
        FriendListUpdKey.IS_MUTUAL_FRIEND,
      ).friends;
    }

    /*let selectedFrdInx = curFrnds.findIndex(frnd => frnd.wallet === followUserAddress);
  if(selectedFrdInx >= 0)
  {
    curFrnds[selectedFrdInx] = {...curFrnds[selectedFrdInx], isFriend: true};
    
    const response = await getMutualFriendStatus(networkID, followUserAddress, wallet, true);
      if (response.status == 200) {
        const resData = await response.json();
        if(resData && resData.hasAccess !== null)
        {
          isMutualFriend = resData.hasAccess;
        }
    }
    if(isMutualFriend)
    {
      //update flag in current friend list as well
      curFrnds[selectedFrdInx] = {...curFrnds[selectedFrdInx], isMutualFriend: isMutualFriend};

      //add that record into mutualFriend list
      let selectedFrd: IFriends = usersFriendsList.find(frnd => frnd.wallet === followUserAddress);
      if(selectedFrd)
      {
        selectedFrd = {...selectedFrd, isMutualFriend: isMutualFriend };
        mutualFriends = [...mutualFriends, selectedFrd];
      }
      //update users friends list
      usersFriendsList = updateUsersFriendsList(followUserAddress, FriendListUpdKey.IS_MUTUAL_FRIEND).friends;
    }
  }
  else{

    let selectedFrd: IFriends = usersFriendsList.find(frnd => frnd.wallet === followUserAddress);
    // if friend exist but not in current friend then add friend
    selectedFrd = { ...selectedFrd, isFriend: true }
    // get mutual status
    const response = await getMutualFriendStatus(networkID, followUserAddress, wallet, true);
      if (response.status == 200) {
        const resData = await response.json();
        if(resData && resData.hasAccess !== null)
        {
          isMutualFriend = resData.hasAccess;
        }
    }
    if(isMutualFriend)
    {
      if(selectedFrd)
      {
         //update flag in selected friend list as well
        selectedFrd = {...selectedFrd, isMutualFriend: isMutualFriend };
        mutualFriends = [...mutualFriends, selectedFrd];
      }
      //update users friends list
      usersFriendsList = updateUsersFriendsList(followUserAddress, FriendListUpdKey.IS_MUTUAL_FRIEND).friends;
    }
    curFrnds = [...curFrnds, selectedFrd];
  }*/

    const state = getState() as RootState;
    let curFrnds = [...state.friends.currentFriends];
    let selectedFrd: IFriends = curFrnds.find(
      frnd => frnd.wallet?.toLowerCase() === followUserAddress?.toLowerCase(),
    );
    if (selectedFrd) {
      //get user status from MFS
      getUserJSONdata(selectedFrd?.wallet).then(response => {
        const { friends, hasUpdated } = updateUsersFriendsList(
          selectedFrd.wallet,
          FriendListUpdKey.STATUS,
          response?.status,
        );
        if (hasUpdated) {
          usersFriendsList = friends;
          dispatch(
            updateFriendStatus({
              wallet: selectedFrd.wallet,
              status: response?.status,
            }),
          );
        }
      });

      //get user avatar from IPFS
      if (selectedFrd.avatarURI.includes('ipfs://')) {
        getUserDetailsFromIPFS(selectedFrd.avatarURI).then(avatarURI => {
          const { friends, hasUpdated } = updateUsersFriendsList(
            selectedFrd.wallet,
            FriendListUpdKey.AVATAR_URI,
            selectedFrd.status,
            avatarURI ? avatarURI.toString() : '',
          );
          if (hasUpdated) {
            usersFriendsList = friends;
            dispatch(
              updateFriendAvatar({ wallet: selectedFrd.wallet, avatarURI }),
            );
          }
        });
      } else if (selectedFrd.avatarURI.includes('base64')) {
        usersFriendsList = updateUsersFriendsList(
          selectedFrd.wallet,
          FriendListUpdKey.AVATAR_URI,
          selectedFrd.status,
          selectedFrd.avatarURI,
        ).friends;
      }

      selectedFrd = { ...selectedFrd, isFriend: true };
      //curFrnds = [...curFrnds, selectedFrd];
    }
    //posFrnds = posFrnds.filter(f => f.wallet !== followUserAddress); //we will have unique user address [or unique key] here, so this will work.

    //update friends json and store it to local store + upload to MFS
    let frndWallets = getCurrentFriends().map(frnd => {
      return { wallet: frnd.wallet };
    });

    updateLocalStore(wallet.address, frndWallets);
    //update friend status for passport messages sender
    dispatch(
      setFriendForSender({
        sender: followUserAddress,
        isFriend: true,
        isMutualFriend: isMutualFriend,
      }),
    );

    // LOOT8-5651 Adding this function to avoid uploading friends data directly with publishFriendsToMFS function
    // dispatch(publishFriendsToMFS({ wallet, friendsJson }));
    await updateFriendsOnLambda([followUserAddress], wallet, 'add');

    // return {
    //   currentFriends: curFrnds,
    //   possibleFriends: posFrnds, //we are closing modal popup on friend add; so next time possible friends should be empty on screen
    //   mutualFriends: mutualFriends
    // };
  },
);

export const removeFriend = createAsyncThunk(
  'friends/removeFriend',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      followUserAddress,
    }: IFollowUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    if (usersFriendsList) {
      usersFriendsList = updateUsersFriendsList(
        followUserAddress,
        FriendListUpdKey.IS_FRIEND,
      ).friends;
    }
    const state = getState() as RootState;
    let curFrnds = [...state.friends.currentFriends];
    let posFrnds = [...state.friends.possibleFriends];
    let mutualFriends = [...state.friends.mutualFriends];

    let selectedFrdInx = curFrnds.findIndex(
      frnd => frnd.wallet?.toLowerCase() === followUserAddress?.toLowerCase(),
    );
    if (selectedFrdInx >= 0) {
      //Remove friend from friend list
      curFrnds = curFrnds.filter(
        frnd => frnd.wallet?.toLowerCase() !== followUserAddress?.toLowerCase(),
      );
    }

    // remove the friend item from mutual friend list
    mutualFriends = mutualFriends.filter(
      frnd => frnd.wallet?.toLowerCase() !== followUserAddress?.toLowerCase(),
    );
    // let selectedMututalFrdInx = mutualFriends.findIndex(frnd => frnd.wallet === followUserAddress);
    // if(selectedMututalFrdInx >= 0)
    // {
    //   mutualFriends[selectedMututalFrdInx] = {...mutualFriends[selectedMututalFrdInx], isMutualFriend: false};
    // }

    //We display possible friends based on search term; so no need to add this element in possible friends here
    let selectedEle: IFriends = curFrnds.find(
      frnd => frnd.wallet?.toLowerCase() === followUserAddress?.toLowerCase(),
    );
    if (selectedEle) {
      selectedEle = { ...selectedEle, isFriend: false, isMutualFriend: false };
      posFrnds = [...posFrnds, selectedEle];
    }
    //curFrnds = curFrnds.filter(f => f.wallet !== followUserAddress); //we will have unique user address [or unique key] here, so this will work.

    //update friends json and store it to local store + upload to MFS
    let frndWallets = getCurrentFriends().map(frnd => {
      return { wallet: frnd.wallet };
    });

    updateLocalStore(wallet.address, frndWallets);
    //update friend status for passport messages sender
    dispatch(
      setFriendForSender({
        sender: followUserAddress,
        isFriend: false,
        isMutualFriend: false,
      }),
    );

    // LOOT8-5651 Adding this function to avoid uploading friends data directly with publishFriendsToMFS function
    // dispatch(publishFriendsToMFS({ wallet, friendsJson }));
    await updateFriendsOnLambda([followUserAddress], wallet, 'remove');

    const signature = await wallet.signMessage(followUserAddress);
    publishMessageToIPFSTopic(
      'loot8-friends-unfollow',
      wallet.address,
      followUserAddress,
      signature,
    );

    return {
      currentFriends: curFrnds,
      possibleFriends: [], //in Add Friends page, we display list of users only when user search a name
      mutualFriends: mutualFriends,
    };
  },
);

export const removeMutualFriend = createAsyncThunk(
  'friends/removeMutualFriend',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      followUserAddress,
    }: IFollowUserBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    usersFriendsList = updateUsersFriendsList(
      followUserAddress,
      FriendListUpdKey.IS_FRIEND,
    ).friends;

    const state = getState() as RootState;
    let curFrnds = [...state.friends.currentFriends];
    let posFrnds = [...state.friends.possibleFriends];
    let mutualFriends = [...state.friends.mutualFriends];

    // remove the friend item from mutual friend list
    mutualFriends = mutualFriends.filter(
      frnd => frnd.wallet?.toLowerCase() !== followUserAddress?.toLowerCase(),
    );

    return {
      currentFriends: curFrnds,
      possibleFriends: [], //in Add Friends page, we display list of users only when user search a name
      mutualFriends: mutualFriends,
    };
  },
);

export const searchFriend = createAsyncThunk(
  'friends/searchFriend',
  async (
    {
      searchText,
      isMutualFriend = false,
      isManageFriends = false,
    }: ISearchFriendsThunk,
    { getState, dispatch },
  ) => {
    const state = getState() as RootState;
    let currFriends = [...state.friends.currentFriends];
    let possibleFriends = [...state.friends.possibleFriends];
    let mutualFriends = [...state.friends.mutualFriends];

    if (searchText && searchText.length > 0) {
      let searchTextLower = searchText.toLowerCase();

      if (isMutualFriend) {
        mutualFriends = usersFriendsList.filter(
          p =>
            p.isMutualFriend &&
            p.name.toLowerCase().indexOf(searchTextLower) > -1,
        );
      } else {
        currFriends = isManageFriends
          ? usersFriendsList.filter(
              p =>
                p.isFriend &&
                p.name.toLowerCase().indexOf(searchTextLower) > -1,
            )
          : usersFriendsList.filter(
              p => p.name.toLowerCase().indexOf(searchTextLower) > -1,
            );
      }
      // isCurrentFriend ? currFriends = usersFriendsList.filter(p => p.isFriend && p.name.toLowerCase().indexOf(searchTextLower) > -1) :
      // possibleFriends = usersFriendsList.filter(p => !p.isFriend && p.name.toLowerCase().indexOf(searchTextLower) > -1);
    } else {
      //isCurrentFriend ? currFriends = usersFriendsList.filter(p => p.isFriend) : possibleFriends = [];
      isMutualFriend
        ? (mutualFriends = usersFriendsList.filter(p => p.isMutualFriend))
        : (currFriends = usersFriendsList.filter(p => p.isFriend));
    }

    return {
      currentFriends: currFriends,
      possibleFriends: possibleFriends,
      mutualFriends: mutualFriends,
    };
  },
);

export const transferNFTToFriend = createAsyncThunk(
  'friends/transferNFTToFriend',
  async (
    {
      networkID,
      tokenAddress,
      friendWalletAddress,
      wallet,
      tokenId,
    }: ISendNFTToFriend,
    { getState, dispatch },
  ) => {
    let provider = getAnynetStaticProvider(networkID);

    const data = GetERC721TransferMessage(
      wallet.address,
      friendWalletAddress,
      tokenId,
    );
    let msg: IMessageMetaData = {
      to: tokenAddress,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider,
      checkUserDetail: false,
    };
    LogToLoot8Console(msg);
    let res = await dispatch(SendMetaTX(msg));

    if (
      res.payload.status &&
      res.payload.status.toString().toLowerCase() == 'success'
    ) {
      if (res.payload?.eventLogs) {
        const createdTokenId = GetTransferNFTCallLogsData(
          res.payload.eventLogs,
        );
        if (createdTokenId && createdTokenId.toString() == tokenId.toString()) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  },
);

export const getLatestMutualFriendStatus = async (
  friendAddress,
  userAddress,
  unidirectional = false,
) => {
  let isMutualFriend = false;
  const response = await getMutualFriendStatus(
    friendAddress,
    userAddress,
    unidirectional,
  );
  if (response.status == 200) {
    const resData = await response.json();
    if (resData && resData.hasAccess !== null) {
      isMutualFriend = resData.hasAccess;
    }
  }
  return isMutualFriend;
};

const initialState: FriendsState = {
  currentFriends: [],
  possibleFriends: [],
  mutualFriends: [],
  loading: false,
  pendingIPNSPublishCount: 0,
  currentFriendsCount: 0,
  allAvatarsLoaded: false,
  loadingFriendsData: false,
  selectedFriendStore: '',
  latestChatFriend: {
    friendWallet: null,
    isMutualFriend: false,
  },
  requestSentId: null,
};

const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {
    resetState(state) {
      state.currentFriends = getCurrentFriends();
      state.possibleFriends = [];
    },
    resetMutualFriendsState(state) {
      state.mutualFriends = getMutualFriends();
    },
    clearFriendsState(state) {
      state = initialState;
      usersFriendsList = [];
    },
    updateCurrentFriends(state, action) {
      let currFriend = state.currentFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (currFriend) {
        currFriend.isFriend = action.payload.isFriend;
      } else {
        let selectedFrd: IFriends = usersFriendsList.find(
          frnd =>
            frnd.wallet?.toLowerCase() === action.payload.wallet?.toLowerCase(),
        );
        if (selectedFrd) {
          selectedFrd = { ...selectedFrd, isFriend: action.payload.isFriend };
          state.currentFriends.push(selectedFrd);
        }
      }
    },
    updateFriendRequest(state, action) {
      let ind = state.currentFriends.findIndex(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (ind != -1) {
        let curFriends = [...state.currentFriends];
        curFriends[ind].friendRequest.isRequestSend =
          action.payload.isRequestSend;
        curFriends[ind].friendRequest.isCancelled = action.payload.isCancelled;
        curFriends[ind].friendRequest.messageId = action.payload.messageID;
        curFriends[ind].friendRequest.timestamp = action.payload.timestamp;
        state.currentFriends = curFriends;
        if (action.payload.type === 'add') {
          usersFriendsList = updateUsersFriendsList(
            action.payload.wallet,
            FriendListUpdKey.IS_SEND_REQUEST,
            '',
            '',
            '',
            true,
            action.payload.messageID,
            action.payload.timestamp,
          ).friends;
        } else {
          usersFriendsList = updateUsersFriendsList(
            action.payload.wallet,
            FriendListUpdKey.IS_SEND_REQUEST,
            '',
            '',
            '',
            false,
            action.payload.messageID,
            action.payload.timestamp,
          ).friends;
        }
      }
    },
    updateMutualFriends(state, action) {
      let mutualFriend = state.mutualFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (mutualFriend) {
        mutualFriend.isMutualFriend = action.payload.isMutualFriend;
      } else {
        let selectedFrd: IFriends = usersFriendsList.find(
          frnd =>
            frnd.wallet?.toLowerCase() === action.payload.wallet?.toLowerCase(),
        );
        selectedFrd = {
          ...selectedFrd,
          isMutualFriend: action.payload.isMutualFriend,
        };
        state.mutualFriends.push(selectedFrd);
      }
      let currFriend = state.currentFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (currFriend) {
        currFriend.isMutualFriend = action.payload.isMutualFriend;
      }
    },
    updateFriendStatus(state, action) {
      let currFriend = state.currentFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (currFriend) {
        currFriend.oldStatus = currFriend.status;
        currFriend.status = action.payload.status;
      }
      let mutualFriend = state.mutualFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (mutualFriend) {
        mutualFriend.oldStatus = mutualFriend.status;
        mutualFriend.status = action.payload.status;
      }
    },
    updateFriendName(state, action) {
      let currFriend = state.currentFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (currFriend) {
        currFriend.name = action.payload.name;

        if (usersFriendsList) {
          usersFriendsList = updateUsersFriendsList(
            action.payload.wallet,
            FriendListUpdKey.NAME,
            '',
            '',
            action.payload.name,
          ).friends;
        }
      }
      let mututalFriend = state.mutualFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (mututalFriend) {
        mututalFriend.name = action.payload.name;
      }
    },
    updateFriendAvatar(state, action) {
      let currFriend = state.currentFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (currFriend) {
        currFriend.avatarURI = action.payload.avatarURI;
      }
      let posblFriend = state.possibleFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (posblFriend) {
        posblFriend.avatarURI = action.payload.avatarURI;
      }
      let mutualFriend = state.mutualFriends.find(
        p => p.wallet?.toLowerCase() == action.payload.wallet?.toLowerCase(),
      );
      if (mutualFriend) {
        mutualFriend.avatarURI = action.payload.avatarURI;
      }
    },
    setAvatarsLoaded(state, action) {
      if (!state.allAvatarsLoaded) {
        state.allAvatarsLoaded = action.payload;
      }
    },
    setFriendsDataLoading(state, action) {
      state.loadingFriendsData = action.payload;
    },
    setSelectedFriendStore(state, action) {
      state.selectedFriendStore = action.payload;
    },
    setLatestChatFriend(state, action) {
      state.latestChatFriend.friendWallet = action.payload;
    },
    // This action is used to set the request sent id for the friend request is sent so we can update the disable and loading state of each friend item accordingly
    setRequestSentId(state, action) {
      state.requestSentId = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadFriendsData.pending, (state, action) => {
        state.loading = true;
        state.loadingFriendsData = true;
      })
      .addCase(loadFriendsData.rejected, (state, action) => {
        state.loading = false;
        state.loadingFriendsData = false;
      })
      .addCase(loadFriendsData.fulfilled, (state, action) => {
        state.currentFriends = action.payload.currentFriends;
        state.possibleFriends = []; //possible friends list is populated only on search
        state.mutualFriends = action.payload.mutualFriends;
        state.currentFriendsCount = state.currentFriends.filter(
          f => f.isFriend === true,
        ).length;
        state.loading = false;
        state.loadingFriendsData = false;
      })
      .addCase(addFriend.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addFriend.rejected, (state, { error }: any) => {
        state.loading = false;
        showToastMessage();
        LogCustomError('addFriend', error.name, error.message, error.stack);
      })
      .addCase(addFriend.fulfilled, (state, action) => {
        //state.possibleFriends = action.payload.possibleFriends;
        //state.currentFriends = action.payload.currentFriends;
        //state.mutualFriends = action.payload.mutualFriends;
        state.currentFriendsCount = state.currentFriends.filter(
          f => f.isFriend === true,
        ).length;
        state.loading = false;
      })
      .addCase(removeFriend.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeFriend.rejected, (state, { error }: any) => {
        state.loading = false;
        showToastMessage();
        LogCustomError('removeFriend', error.name, error.message, error.stack);
      })
      .addCase(removeFriend.fulfilled, (state, action) => {
        state.possibleFriends = action.payload.possibleFriends;
        state.currentFriends = action.payload.currentFriends;
        state.mutualFriends = action.payload.mutualFriends;
        state.currentFriendsCount = state.currentFriends.filter(
          f => f.isFriend === true,
        ).length;
        state.loading = false;
      })
      .addCase(removeMutualFriend.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(removeMutualFriend.rejected, (state, { error }: any) => {
        state.loading = false;
        showToastMessage();
        LogCustomError(
          'removeMutualFriend',
          error.name,
          error.message,
          error.stack,
        );
      })
      .addCase(removeMutualFriend.fulfilled, (state, action) => {
        state.possibleFriends = action.payload.possibleFriends;
        state.currentFriends = action.payload.currentFriends;
        state.mutualFriends = action.payload.mutualFriends;
        state.currentFriendsCount = state.currentFriends.filter(
          f => f.isFriend === true,
        ).length;
        state.loading = false;
      })
      .addCase(searchFriend.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(searchFriend.fulfilled, (state, action) => {
        state.possibleFriends = action.payload.possibleFriends;
        state.currentFriends = action.payload.currentFriends;
        state.mutualFriends = action.payload.mutualFriends;
        state.loading = false;
      });
    // .addCase(publishFriendsToMFS.pending, (state, action) => {
    //   state.pendingIPNSPublishCount = state.pendingIPNSPublishCount + 1;
    // })
    // .addCase(publishFriendsToMFS.rejected, (state, { error }: any) => {
    //   state.pendingIPNSPublishCount = state.pendingIPNSPublishCount - 1;
    //   LogCustomError(
    //     'publishFriendsToMFS',
    //     error.name,
    //     error.message,
    //     error.stack,
    //   );
    // })
    // .addCase(publishFriendsToMFS.fulfilled, (state, action) => {
    //   state.pendingIPNSPublishCount = state.pendingIPNSPublishCount - 1;
    // });
  },
});

export default friendsSlice.reducer;

export const {
  resetState,
  updateFriendStatus,
  updateFriendAvatar,
  clearFriendsState,
  updateFriendName,
  resetMutualFriendsState,
  setAvatarsLoaded,
  updateCurrentFriends,
  updateFriendRequest,
  updateMutualFriends,
  setFriendsDataLoading,
  setSelectedFriendStore,
  setLatestChatFriend,
  setRequestSentId
} = friendsSlice.actions;
