import React, { useEffect, useRef, useState } from 'react';

import {
  View,
  Image,
  FlatList,
  Pressable,
  Dimensions,
  RefreshControl,
  TouchableOpacity,
  StyleSheet,
  Platform,
} from 'react-native';
import { debounce } from 'lodash';
import { Text } from 'react-native-paper';
import { ActionSheetRef } from 'react-native-actions-sheet';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  addFriend,
  removeFriend,
  searchFriend,
  resetState,
  loadFriendsData,
  IFriends,
  getCurrentFriends,
  updateFriendRequest,
  getLatestMutualFriendStatus,
  setSelectedFriendStore,
  setRequestSentId,
} from '../../slices/friendsSlice';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import AppLoaderComponent from '../../components/Loader';
import { defaultTheme } from '../../themes/loot8';
import { FriendsModalTypes } from '../../enums/friendsPageTabs.enum';
import { getUserPublicKey } from '../../slices/AppUserSlice';
import FriendItem from './friendItem';
import UserDetailModal from '../Modal/UserDetailModal';
import {
  PRIVATE_MESSAGE_FRIEND_REQUESTS,
  ToastCustomMessageType,
} from '../../appconstants';
import { showToastMessage } from '../../helpers/Gadgets';
import {
  IFriendMessageRequest,
  deletePrivateMsgRequestToFriend,
  loadLastMessageDetailsForMutualFriends,
  popPendingRequest,
  sendPrivateMsgRequestToFriend,
} from '../../slices/PrivateMessageSlice';
import EmptyList from '../EmptyList';
import GradientText from '../GradientText';
import PopupsActionSheet from './popupsActionSheet';
import { hideKeyboard } from '../../slices/helpers';
import TextInputComponent from '../TextInputComponent';
import { getSyncedTime } from '../../helpers/DateHelper';
import useIsResponsive from '../../hooks/useIsResponsive';
import { getData, storeData } from '../../helpers/AppStorage';
import { LogCustomError, LogErrors } from '../../helpers/AppLogger';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';

import ManageFriendsIcon from '../../assets/ManageFriendsIcon.png';

export const getFriendName = (text, type) => {
  let tempWidth = 35;
  if (type == 'name') tempWidth = 35;
  else tempWidth = 45;

  return Dimensions.get('window').width <= 480 && text.length > 10
    ? text.substring(0, 10) + ' ...'
    : text.length > tempWidth
      ? text.substring(0, tempWidth) + ' ...'
      : text;
};
let MESSAGE_MAX_LENGTH = 140;

const Friends = ({ onSelectedFriend, isManageFriends, setIsManageFriends }) => {
  // CUSTOM HOOKS
  const {
    networkId,
    staticProvider,
    address,
    wallet,
    encryptMessage,
    decryptMessage,
  } = useWeb3AuthContext();
  const dispatch = useAppDispatch();
  const actionSheetRef = useRef<ActionSheetRef>(null);

  const isResponsive = useIsResponsive();
  // LOCAL STATE HOOKS
  const [showModal, setShowModal] = React.useState(false);
  const [selectedFriend, setSelectedFriend] = React.useState(null);
  const [addFriendAction, setAddFriendAction] = React.useState(false);
  const [viewFriendDetailItem, setViewFriendDetailActionItem] =
    React.useState(null);
  const [refreshingFriends, setFriendsRefreshing] = React.useState(false);
  const [enablePrivateMenuItem, setEnablePrivateMenuItem] = useState(false);
  const [isSendRequestLoader, setIsSendRequestLoader] = useState(false);
  const [searchText, setSearchText] = React.useState('');
  const [modalType, setModalType] = useState('');

  // GLOBAL STATE HOOKS

  const {
    latestMessageLoading,
    mutualFriendMessagesDetails: mutualFriendsMessagesData,
  } = useAppSelector(state => state.PrivateMessage);

  const {
    loadingFriendsData,
    currentFriends: currentFriendsData,
    latestChatFriend,
    loading: loadingFriends,
  } = useAppSelector(state => state.friends);
  const pendingRequest =
    useAppSelector(state => state.PrivateMessage.pendingRequest) ?? [];

  // LIFECYCLE METHODS

  useEffect(() => {
    return () => {
      // clear the search text upon exiting on this page
      setSearchText('');
      dispatch(
        debounce(
          searchFriend({
            searchText: searchText,
            isMutualFriend: false,
            isManageFriends: isManageFriends,
          }),
          100,
        ),
      );
    };
  }, []);

  useEffect(() => {
    if (!loadingFriendsData && searchText.length > 0) {
      dispatch(
        debounce(
          searchFriend({
            searchText: searchText,
            isMutualFriend: false,
            isManageFriends: isManageFriends,
          }),
          250,
        ),
      );
    }
  }, [loadingFriendsData, searchText]);

  // LOCAL METHODS
  const onRefresh = React.useCallback(async () => {
    setFriendsRefreshing(true);
    await dispatch(
      loadFriendsData({
        networkID: networkId,
        provider: staticProvider,
        wallet: wallet,
        address: address,
        decryptMessage,
      }),
    ).then(() => {
      dispatch(
        loadLastMessageDetailsForMutualFriends({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
          friendsAddresses: getCurrentFriends().map(data => {
            return {
              ...data,
              friendAddress: data.wallet,
              dataURI: data?.dataURI ?? '',
            };
          }),
        }),
      );
      setFriendsRefreshing(false);
    });
  }, []);

  const sortedCurrentFriends = React.useMemo(() => {
    if (
      mutualFriendsMessagesData.length &&
      currentFriendsData?.length &&
      !latestMessageLoading
    ) {
      const friendLatestChat = currentFriendsData.map(friend => {
        const friendChat = mutualFriendsMessagesData.find(
          latestChat => latestChat.friendAddress === friend.wallet,
        );
        return {
          ...friend,
          chat: friendChat || null,
        };
      });
      const sortFriendDataWithLatestChat = friendLatestChat.sort((a, b) => {
        const timestampA = a.chat?.latestMessage?.timestamp
          ? a.chat?.latestMessage?.timestamp || 0
          : 0;
        const timestampB = b.chat?.latestMessage?.timestamp
          ? b.chat?.latestMessage?.timestamp || 0
          : 0;

        return timestampB - timestampA;
      });
      // moving latest messaged friend to the top so when the message is being successfully sent the user on bigger screens will moved to top
      if (latestChatFriend.friendWallet) {
        const indexToMoveLatestChatFriend =
          sortFriendDataWithLatestChat.findIndex(
            friend => friend.wallet === latestChatFriend.friendWallet,
          );
        const MoveObject = sortFriendDataWithLatestChat.splice(
          indexToMoveLatestChatFriend,
          1,
        )[0];
        sortFriendDataWithLatestChat.unshift(MoveObject);
      }

      return sortFriendDataWithLatestChat;
    } else if (currentFriendsData?.length) {
      // Use an object to track unique ids
      return currentFriendsData;
    } else {
      return [];
    }
  }, [
    currentFriendsData,
    mutualFriendsMessagesData,
    latestChatFriend.friendWallet,
  ]);

  const openModalPopup = (item, type) => {
    actionSheetRef.current.hide();
    setSelectedFriend(item);
    setTimeout(() => {
      setModalType(type);
      actionSheetRef.current.show();
    }, 500);
  };

  const openModalUserDetailPopup = item => {
    setViewFriendDetailActionItem(item);
    setTimeout(() => {
      setShowModal(true);
    });
  };

  const onNameSectionClick = item => {
    // onSelectedTab(FriendsTabs.FRIEND);
    setSelectedFriend(item);
    onSelectedFriend(item);
    dispatch(setSelectedFriendStore(`${item.wallet + item.id}`));
  };

  const openOptionsModal = async (item, dots) => {
    if (item) {
      if (item?.wallet && item?.isFriend) {
        const publicKey = await getUserPublicKey(item?.wallet);
        if (publicKey) {
          setEnablePrivateMenuItem(true);
        } else {
          setEnablePrivateMenuItem(false);
        }
      } else {
        setEnablePrivateMenuItem(false);
      }
      setSelectedFriend(item);
      if (dots) {
        setModalType(FriendsModalTypes.SHOWOPTIONS);
      } else {
        setModalType(FriendsModalTypes.UNFOLLOWMODAL);
      }
      actionSheetRef.current.show();
    }
  };

  const setNextFriendRequestAllowed = requestTimestamp => {
    const currentTime = getSyncedTime();
    const allowedDuration = 3 * 60 * 60 * 1000; //3 Hours
    if (currentTime - requestTimestamp < allowedDuration) {
      return false;
    } else {
      return true;
    }
  };

  const acceptFriendRequest = async (item, messageItem) => {
    const friendPublicKey = await getUserPublicKey(item?.wallet);
    const response = await dispatch(
      deletePrivateMsgRequestToFriend({
        networkID: networkId,
        address: address,
        wallet: wallet,
        messageId: messageItem?.messageId,
        requestAccepted: true,
        publicKey: friendPublicKey,
        decryptMessage: decryptMessage,
      }),
    );

    if (response && response.payload.status && response.payload.status == 200) {
      dispatch(popPendingRequest(messageItem?.messageId));
      dispatch(
        updateFriendRequest({
          wallet: item?.wallet,
          isRequestSend: true,
          isCancelled: false,
          messageId: messageItem?.messageId,
          timestamp: messageItem?.timestamp,
          type: 'update',
        }),
      );
      dispatch(setRequestSentId(null));
    } else {
      LogCustomError(
        'acceptFriendRequest: could not update friend request',
        '',
        '',
        null,
      );
      dispatch(setRequestSentId(null));
    }
  };

  const OnPressFollow = async item => {
    setIsSendRequestLoader(true);
    setSelectedFriend(item);
    dispatch(setRequestSentId(item?.wallet));
    if (setNextFriendRequestAllowed(item?.friendRequest?.timestamp)) {
      if (item.isFriend) {
        sendFriendRequestMsg(item);
      } else {
        await onAddFriend(item).then(async () => {
          const isMutualFriend = await getLatestMutualFriendStatus(
            item.wallet,
            wallet,
            true,
          );
          if (!isMutualFriend) {
            sendFriendRequestMsg(item);
          } else {
            //If a friend request already exist from the user being added as friend, execute accpet request flow as well.
            const friendReqFromFriend = pendingRequest?.find(
              r =>
                r.user?.wallet.toLocaleLowerCase() ===
                item.wallet.toLocaleLowerCase(),
            );
            if (friendReqFromFriend) {
              acceptFriendRequest(item, friendReqFromFriend);
            }
          }
        });
      }
    } else {
      setModalType(FriendsModalTypes.ALERT3HRS);
      actionSheetRef.current.show();
      setIsSendRequestLoader(false);
      dispatch(setRequestSentId(null));
    }
  };

  const sendFriendRequestMsg = async item => {
    setIsSendRequestLoader(true);
    if (item && item?.wallet) {
      const friendPublicKey = await getUserPublicKey(item?.wallet);
      let msgToSend =
        'Hey ' + item?.name.trim() + ', Please add me as your friend.';
      const encryptedMsgRequest = encryptMessage(msgToSend, friendPublicKey);
      if (encryptedMsgRequest) {
        const response = await dispatch(
          sendPrivateMsgRequestToFriend({
            networkID: networkId,
            address: item?.wallet,
            wallet: wallet,
            publicKey: friendPublicKey,
            text: encryptedMsgRequest,
            decryptMessage,
          }),
        );
        if (
          response &&
          response.payload.status &&
          response.payload.status == 200
        ) {
          await dispatch(
            updateFriendRequest({
              wallet: item?.wallet,
              isRequestSend: true,
              isCancelled: false,
              messageID: response.payload.latestMsgRequest?.messageId,
              timestamp: response.payload.latestMsgRequest?.timestamp,
              type: 'add',
            }),
          );
          let currentSelectedFriendData = { ...item };
          currentSelectedFriendData.friendRequest = {
            isRequestSend: true,
            isCancelled: false,
            messageId: response.payload.latestMsgRequest?.messageId,
            timestamp: response.payload.latestMsgRequest?.timestamp,
          };
          onSetSelectedFriend(currentSelectedFriendData);
          setIsSendRequestLoader(false);
          dispatch(setRequestSentId(null));
        } else {
          setIsSendRequestLoader(false);
          actionSheetRef.current.hide();
          showToastMessage(
            ToastCustomMessageType.INFO,
            'Unable to send friend request. Please try again later!',
          );
        }
      }
    } else {
      setIsSendRequestLoader(false);
      actionSheetRef.current.hide();
      showToastMessage(
        ToastCustomMessageType.INFO,
        'Unable to send friend request. Please try again later!',
      );
    }
  };

  const unFollowFriend = async () => {
    setIsSendRequestLoader(true);
    if (selectedFriend && selectedFriend?.wallet) {
      if (
        selectedFriend?.friendRequest?.isRequestSend &&
        !selectedFriend.friendRequest.isCancelled &&
        selectedFriend.friendRequest.messageId
      ) {
        const friendPublicKey = await getUserPublicKey(selectedFriend?.wallet);
        const response = await dispatch(
          deletePrivateMsgRequestToFriend({
            //messageID
            networkID: networkId,
            address: selectedFriend?.wallet,
            wallet: wallet,
            messageId: selectedFriend.friendRequest.messageId,
            requestAccepted: false,
            publicKey: friendPublicKey,
            decryptMessage: decryptMessage,
          }),
        );
        if (
          response &&
          response.payload.status &&
          response.payload.status == 200
        ) {
          getData(PRIVATE_MESSAGE_FRIEND_REQUESTS).then(
            (friendRequestList: IFriendMessageRequest[] = []) => {
              if (friendRequestList && friendRequestList.length > 0) {
                friendRequestList = friendRequestList.filter(
                  r => r.requestMsgId != selectedFriend.friendRequest.messageId,
                );
              }
              storeData(PRIVATE_MESSAGE_FRIEND_REQUESTS, friendRequestList);
            },
          );
          await dispatch(
            updateFriendRequest({
              wallet: selectedFriend?.wallet,
              isRequestSend: true,
              isCancelled: true,
              messageId: selectedFriend.friendRequest.messageId,
              timestamp: selectedFriend.friendRequest.timestamp,
              type: 'cancel',
            }),
          );
          let currentSelectedFriendData = { ...selectedFriend };
          currentSelectedFriendData.friendRequest = {
            isRequestSend: true,
            isCancelled: true,
            messageId: selectedFriend.friendRequest.messageId,
            timestamp: selectedFriend.friendRequest.timestamp,
          };
          onUnFriend();
          setSelectedFriend(currentSelectedFriendData);
          setModalType('');
          // setShowOptionsModal(false);
          actionSheetRef.current.hide();
          setIsSendRequestLoader(false);
        } else {
          showToastMessage(
            ToastCustomMessageType.INFO,
            'Unable to cancel request. Please try again later!',
          );
          // setShowOptionsModal(false);
          actionSheetRef.current.hide();
          setModalType('');
          setIsSendRequestLoader(false);
        }
      } else {
        onUnFriend();
        // setShowOptionsModal(false);
        actionSheetRef.current.hide();
        setModalType('');
        setIsSendRequestLoader(false);
      }
    } else {
      LogErrors(
        new Error(`Unable to find request or request ID `),
        address,
        networkId,
        [
          { tag: 'error', value: 'PRIVATE MESSAGE REQUEST CANCEL ERROR' },
          { tag: 'friendAddress', value: selectedFriend?.wallet },
        ],
      );
      showToastMessage(
        ToastCustomMessageType.INFO,
        'Unable to cancel request. Please try again later!',
      );
      // setShowOptionsModal(false);
      actionSheetRef.current.hide();
      setModalType('');
      setIsSendRequestLoader(false);
    }
  };

  const onUnFriend = async () => {
    if (selectedFriend) {
      await dispatch(
        removeFriend({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
          followUserAddress: selectedFriend.wallet,
        }),
      );
      if (searchText === '') {
        dispatch(resetState());
      } else if (isManageFriends) {
        //Fix for loot8-1846: need to refresh search result when a friend is removed from search result
        dispatch(
          debounce(
            searchFriend({
              searchText: searchText,
              isMutualFriend: false,
              isManageFriends: isManageFriends,
            }),
            500,
          ),
        );
      }
      actionSheetRef.current.hide();
      setModalType('');
      setIsSendRequestLoader(false);
    }
  };

  const onAddFriend = async (item: IFriends) => {
    if (item) {
      dispatch(
        addFriend({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
          followUserAddress: item.wallet,
        }),
      );
    }
  };

  const renderItem = React.useCallback(
    ({ item, index }: { item: IFriends; index: number }) => (
      <FriendItem
        item={item}
        index={index}
        searchText={searchText}
        onAddFriend={onAddFriend}
        OnPressFollow={OnPressFollow}
        openModalPopup={openModalPopup}
        selectedFriend={selectedFriend}
        isManageFriends={isManageFriends}
        getDecryptedMsg={getDecryptedMsg}
        openOptionsModal={openOptionsModal}
        onNameSectionClick={onNameSectionClick}
        unReadMessagesData={unReadMessagesData}
        openModalUserDetailPopup={openModalUserDetailPopup}
        mutualFriendsMessagesData={mutualFriendsMessagesData}
      />
    ),
    [mutualFriendsMessagesData, isManageFriends],
  );

  const onFriendSearch = async text => {
    setSearchText(text);
    if (!loadingFriendsData) {
      dispatch(
        debounce(
          searchFriend({
            searchText: text,
            isMutualFriend: false,
            isManageFriends: isManageFriends,
          }),
          250,
        ),
      );
    }
  };

  useEffect(() => {
    const onManageFriendToggle = () => {
      setSearchText('');
      dispatch(resetState());
    };
    // Reset states when manage friend toggles
    onManageFriendToggle();
  }, [isManageFriends]);

  const getDecryptedMsg = (msgText, friendPublicKey) => {
    let decryptedText = '';
    try {
      if (friendPublicKey && friendPublicKey != '') {
        decryptedText = decryptMessage(msgText, friendPublicKey);
        return decryptedText;
      }
    } catch (e) {
      LogCustomError('decryptMessage', e.name, e.message, null);
      LogToLoot8Console('error occured while decrypting message');
    }
  };

  const onChangeModalType = type => {
    setModalType(type);
  };

  const onSetSelectedFriend = data => {
    setSelectedFriend(data);
  };

  const unReadMessagesData = (frndAddress, type) => {
    let temp = mutualFriendsMessagesData.find(
      f => f.friendAddress === frndAddress,
    );
    let value = null;
    if (temp) {
      if (type === 'count') {
        value = temp?.newMessageCount;
      } else {
        if (temp?.friendPublicKey) {
          if (temp?.latestMessage?.data?.data?.attachments) {
            value =
              temp?.latestMessage?.data?.sender === address
                ? 'You:Image'
                : 'Image';
          } else {
            value = decryptMessage(
              temp?.latestMessage?.data?.data?.content?.text,
              temp?.friendPublicKey,
            );
            if (
              temp?.latestMessage?.data?.data?._type === 'reshare' &&
              !value
            ) {
              value =
                temp?.latestMessage?.data?.sender === address
                  ? 'You: ' + 'Shared from Social Media'
                  : 'Shared from Social Media';
            } else {
              value =
                temp?.latestMessage?.data?.sender === address
                  ? 'You: ' + value
                  : value;
            }
          }
        } else
          value = temp.newMessageCount > 1 ? 'New Messages ' : 'New Message ';
      }
    }
    return value;
  };

  const currentFriendCount = () => {
    let count = 0;
    let frndData = getCurrentFriends();
    frndData = frndData.filter(
      f => f.wallet && (f.isFriend || f.isMutualFriend),
    );
    if (frndData) {
      count = frndData.length;
    }
    return count;
  };

  const Separator = () => (
    <TouchableOpacity
      activeOpacity={1}
      style={{ backgroundColor: 'transparent', height: 16 }}
    />
  );

  return (
    <>
      <View style={{ marginTop: 15 }} />
      <TextInputComponent
        placeholder={
          sortedCurrentFriends && sortedCurrentFriends.length > 0
            ? isManageFriends
              ? 'Search friends'
              : 'Add or search friends'
            : 'Add friends'
        }
        value={searchText}
        setOnChange={onFriendSearch}
        type="primary"
        leftIcon="magnify"
        rightIcon={!!searchText ? 'close' : null}
        onRightIconPress={() => onFriendSearch('')}
      />

      {currentFriendCount() > 0 && (
        <View
          style={[
            componentStyles.row,
            { justifyContent: 'space-between', marginVertical: 10 },
          ]}>
          <Text style={componentStyles.friendsCountText}>
            {currentFriendCount() + ' Friends'}
          </Text>
          {!isManageFriends && (
            <Pressable
              onPress={() => {
                setIsManageFriends(true);
              }}
              style={componentStyles.row}>
              <Image
                style={[
                  componentStyles.manageIcon,
                  Platform.OS !== 'web' && { height: 12 },
                ]}
                resizeMode="contain"
                source={ManageFriendsIcon}
              />
              <GradientText
                onPress={() => setIsManageFriends(true)}
                fontFamily={defaultTheme.FONT_FAMILY_MEDIUM}
                size={12}>
                {' Manage'}
              </GradientText>
            </Pressable>
          )}
        </View>
      )}

      {!loadingFriends &&
        !loadingFriendsData &&
        !latestMessageLoading &&
        sortedCurrentFriends.length > 0 && (
          <FlatList
            data={sortedCurrentFriends}
            showsVerticalScrollIndicator={false}
            renderItem={renderItem}
            ItemSeparatorComponent={Separator}
            keyExtractor={(i, index) => i?.wallet + index}
            contentContainerStyle={componentStyles.flatlistContent}
            style={componentStyles.flatlist}
            keyboardShouldPersistTaps="handled"
            refreshControl={
              <RefreshControl
                refreshing={refreshingFriends}
                onRefresh={onRefresh}
              />
            }
            removeClippedSubviews
            maxToRenderPerBatch={8}
            numColumns={isResponsive ? 2 : 1}
            key={isResponsive as any}
            columnWrapperStyle={
              isResponsive && { justifyContent: 'space-between' }
            }
          />
        )}
      {!loadingFriends &&
        !loadingFriendsData &&
        !latestMessageLoading &&
        sortedCurrentFriends.length === 0 && (
          <>
            {searchText && searchText !== '' ? (
              <EmptyList message="No results found." />
            ) : (
              <EmptyList
                width={'100%'}
                message="Your first conversation on LOOT8 begins here!"
                secondaryMessage="Search for a friend using the search bar above, or compose a new message to begin a conversation!"
              />
            )}
          </>
        )}
      {(loadingFriends || loadingFriendsData || latestMessageLoading) &&
        !refreshingFriends &&
        sortedCurrentFriends.length === 0 && (
          <AppLoaderComponent
            extraStyle={{ paddingTop: 90, paddingBottom: 40 }}
            deemBg={false}
          />
        )}
      {showModal && viewFriendDetailItem && !addFriendAction && (
        <UserDetailModal
          user={viewFriendDetailItem}
          isVisible={showModal}
          onDismiss={() => {
            setViewFriendDetailActionItem(null);
            actionSheetRef.current.hide();
          }}
        />
      )}
      <PopupsActionSheet
        actionSheetRef={actionSheetRef}
        selectedFriend={selectedFriend}
        modalType={modalType}
        enablePrivateMenuItem={enablePrivateMenuItem}
        openModalPopup={openModalPopup}
        onUnFriend={unFollowFriend}
        onNameSectionClick={onNameSectionClick}
        isSendRequestLoader={isSendRequestLoader}
        onChangeModalType={onChangeModalType}
        onSetSelectedFriend={onSetSelectedFriend}
        isManageFriends={isManageFriends}
        setIsManageFriends={setIsManageFriends}
        MESSAGE_MAX_LENGTH={MESSAGE_MAX_LENGTH}
      />
    </>
  );
};

export default Friends;

const componentStyles = StyleSheet.create({
  flatlistContent: {
    flexGrow: 1,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    paddingBottom: 150,
  },
  flatlist: { flex: 1 },
  row: {
    flexDirection: 'row',
    alignContent: 'center',
  },
  manageIcon: {
    width: 12,
    marginRight: 2,
    marginTop: 1.5,
  },
  friendsCountText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
});
