import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { defaultTheme } from '../../themes/loot8';
import { styles } from './styles';
import { TouchableRipple } from 'react-native-paper';
import {
  FacebookSvg,
  InstagramSvg,
  TikTokSvg,
  TwitterXSvg,
} from '../../assets/svg/AuthSvgs';
import { openAppOrAppstore } from '../../helpers/Gadgets';

const GradientSocialButton = ({ item, size }) => {
  const routeToExternalLink = async function (
    link: string,
    appUrl: string,
    intentUrl: string,
    appStoreUrl: string,
  ) {
    openAppOrAppstore(link, appUrl, intentUrl, appStoreUrl);
  };
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 1 }}
      style={[styles.container, { width: size, height: size }]}>
      <TouchableRipple
        style={styles.contentContainer}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={evt =>
          routeToExternalLink(
            item.url,
            item.appUrl,
            item.intentUrl,
            item.appStoreUrl,
          )
        }>
        <>{socialData.filter(x => x.name === item.type)[0].icon}</>
      </TouchableRipple>
    </LinearGradient>
  );
};

export default GradientSocialButton;

export const socialData = [
  {
    id: 1,
    name: 'facebook',
    icon: <FacebookSvg size={15} />,
  },
  {
    id: 2,
    name: 'twitter',
    icon: <TwitterXSvg size={15} />,
  },
  {
    id: 3,
    name: 'instagram',
    icon: <InstagramSvg size={15} />,
  },
  {
    id: 4,
    name: 'tiktok',
    icon: <TikTokSvg size={15} />,
  },
];
