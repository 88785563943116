import React, { memo, useRef, useState } from 'react';
import { View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import { SheetManager } from 'react-native-actions-sheet';

import { defaultTheme } from '../themes/loot8';
import { SortingOptions } from '../appconstants';
import useIsResponsive from '../hooks/useIsResponsive';
import MPExpasses from '../components/MarketPlace/MPExpasses';
import useActiveDimensions from '../hooks/useActiveDimensions';
import TextInputComponent from '../components/TextInputComponent';
import GradientFilterButton from '../components/GradientFilterButton';
import AnimatedUnderlineTabs from '../components/AnimatedUnderlineTabs';
import MPCollectibles from '../components/MarketPlace/MPCollectibles/Index';

const sortingMenuOptions = [
  {
    key: SortingOptions.ALPHABETICAL_ASC,
    SortingOptions: SortingOptions.ALPHABETICAL_ASC,
    title: 'Alphabetical Asc. (A -> Z)',
    label: 'Alphabetical Asc. (A -> Z)',
    iconName: 'sort-alpha-up',
    orderBy: 'created',
    order: 'ASC',
  },
  {
    key: SortingOptions.ALPHABETICAL_DESC,
    SortingOptions: SortingOptions.ALPHABETICAL_DESC,

    title: 'Alphabetical Dsc. (Z -> A)',
    label: 'Alphabetical Dsc. (Z -> A)',
    iconName: 'sort-alpha-down-alt',
    orderBy: 'created',
    order: 'DESC',
  },
  {
    key: SortingOptions.COST_ASC,
    title: 'Low cost to high cost',
    label: 'Low cost to high cost',
    SortingOptions: SortingOptions.COST_ASC,
    iconName: 'long-arrow-alt-up',
    orderBy: 'price',
    order: 'ASC',
  },
  {
    key: SortingOptions.COST_DESC,
    title: 'High cost to low cost',
    label: 'High cost to low cost',
    SortingOptions: SortingOptions.COST_DESC,
    iconName: 'long-arrow-alt-down',
    orderBy: 'price',
    order: 'DESC',
  },
];

const topTabsButtonData = [
  { id: 1, title: 'ExPasses' },
  { id: 2, title: 'Collectibles' },
];

export const MarketPlaceScreenNew = memo(() => {
  const navigation = useNavigation();
  const [searchText, setSearchText] = useState<string>('');
  const isResponsive = useIsResponsive();
  const tabsRef = useRef(null);
  const activeWidth = useActiveDimensions().width;
  const [selectedSortingOption, setSelectedSortingOption] = useState(
    SortingOptions.NONE,
  );
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const closeSortingOptionsMenu = async () => {
    setSelectedSortingOption(SortingOptions.NONE);
    await SheetManager.hide('SortModalSheet');
  };

  const onFilterPressed = async () => {
    await SheetManager.show('SortModalSheet', {
      payload: {
        setSelectedSortingOption,
        selectedSortingOption,
      },
    });
  };

  const Slide = memo(
    ({
      index,
      activeTabIndex,
      selectedSortingOption,
    }: {
      index: number;
      activeTabIndex: number;
      selectedSortingOption: number;
    }) => {
      const activeWidth = useActiveDimensions().width;
      const isResponsive = useIsResponsive();

      const renderContent = () => {
        if (index !== activeTabIndex) {
          return null; // If the index does not match the active tab index, return null to prevent unnecessary rendering
        }

        if (activeTabIndex === 0) {
          return (
            <MPExpasses
              selectedSortingOption={selectedSortingOption}
              setSearchExpassText={setSearchText}
              searchExpassText={searchText}
              navigation={navigation}
            />
          );
        } else if (activeTabIndex === 1) {
          return (
            <MPCollectibles
              selectedSortingOption={selectedSortingOption}
              searchCollectibleText={searchText}
              setSearchCollectibleText={setSearchText}
              navigation={navigation}
            />
          );
        }

        return (
          <MPExpasses
            selectedSortingOption={selectedSortingOption}
            searchExpassText={searchText}
            setSearchExpassText={setSearchText}
            navigation={navigation}
          />
        ); // Default case
      };

      return (
        <View
          style={
            isResponsive
              ? {
                  width:
                    defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
                    defaultTheme.HORIZONTAL_PADDING * 2,
                }
              : {
                  width: activeWidth - defaultTheme.HORIZONTAL_PADDING * 2,
                }
          }>
          {renderContent()}
        </View>
      );
    },
  );

  const goToNextSlide = (index: number) => {
    setActiveTabIndex(index);
    const offset = isResponsive
      ? index * defaultTheme.CONTENT_CONTAINER_MAX_WIDTH
      : index * (activeWidth - defaultTheme.HORIZONTAL_PADDING * 2);
    tabsRef?.current && tabsRef?.current?.scrollToOffset({ offset });
    setSearchText('');
    setSelectedSortingOption(SortingOptions.NONE);
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={{ marginVertical: 2.5 }} />
      <AnimatedUnderlineTabs
        changeSlidesOnPress={goToNextSlide}
        data={topTabsButtonData}
      />
      <View style={{ marginVertical: 10 }} />
      <TextInputComponent
        placeholder={'Search for categories, artist, collection...'}
        value={searchText}
        setOnChange={val => setSearchText(val)}
        type="primary"
        leftIcon="magnify"
        rightIcon={!!searchText ? 'close' : null}
        onRightIconPress={() => setSearchText('')}
        customRight={
          <GradientFilterButton size={34} onPress={onFilterPressed} />
        }
        onFocus={() => {
          closeSortingOptionsMenu();
        }}
      />
      <FlatList
        ref={tabsRef}
        data={topTabsButtonData}
        horizontal
        snapToAlignment="start"
        decelerationRate={'fast'}
        snapToInterval={activeWidth}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ flex: 1 }}
        renderItem={({ index }) => (
          <Slide
            index={index}
            activeTabIndex={activeTabIndex}
            selectedSortingOption={selectedSortingOption}
          />
        )}
        scrollEnabled={false}
      />
    </View>
  );
});
