import React, { useEffect, useState, useRef } from 'react';
import {
  View,
  Text,
  Linking,
  Platform,
  Pressable,
  Clipboard,
  Dimensions,
  findNodeHandle,
  StyleSheet,
} from 'react-native';
import WebView from 'react-native-webview';
import { Video, ResizeMode } from 'expo-av';
import { IconButton, TouchableRipple } from 'react-native-paper';
import FontAwesome from 'react-native-vector-icons/FontAwesome5';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { SheetManager } from 'react-native-actions-sheet';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { wait } from '../../helpers/ipfs';
import {
  readMessages,
  getSocialMediaAccess,
  resetPassportMessage,
  setinitialMessageLoading,
  subscribeToPassportMessages,
} from '../../slices/PassportMessageSlice';
import { defaultTheme } from '../../themes/loot8';
import { CatalogTypes } from '../../enums/catalog.enum';
import PassportMessageComponent from '../PassportMessage';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import CachedImage from '../CachedImage';
import AppLoaderComponent from '../Loader';
import { ScreenName } from '../../enums/screen.enum';
import { showToastMessage, webViewHelper } from '../../helpers/Gadgets';
import { PassportType } from '../../enums/passportCategory.enum';
import HOCContainer from '../HOCContainer';
import useIsResponsive from '../../hooks/useIsResponsive';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import ThreeDotsSettingsButton from '../ThreeDotsSettingsButton';
import { VerifiedSvg } from '../../assets/svg/HomeSvgs';
import { DiscordSvg } from '../../assets/svg/AuthSvgs';
import {
  getAppConfiguration,
  ToastCustomMessageType,
} from '../../appconstants';
import GradientButton from '../GradientButton';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { generateAccessDigitalExperienceURL } from '../../helpers/AccessDigitalExperience';

const CatalogDetail = ({ route, navigation, setDynamicTitle }) => {
  const isWeb = Platform.OS === 'web';
  let pathConfig = null;

  if (isWeb) {
    const queryParams = new URLSearchParams(window.location.search);

    pathConfig = {
      path: window.location.pathname,
      queryParams: queryParams,
      getParam: query => {
        return queryParams.has(query) ? queryParams.get(query) : null;
      },
    };
  }

  const { networkId, staticProvider, wallet, SelectedPassport } =
    useWeb3AuthContext();
  let catalog =
    pathConfig &&
    pathConfig.getParam('chainId') &&
    pathConfig.getParam('address') &&
    pathConfig.getParam('name')
      ? {
          address: pathConfig.getParam('address'),
          chainId: pathConfig.getParam('chainId'),
          name: pathConfig.getParam('name'),
        }
      : route.params?.catalog;
  if (pathConfig && pathConfig.getParam('image')) {
    catalog = { ...catalog };
    catalog['image'] = pathConfig.getParam('image');
  }
  if (pathConfig && pathConfig.getParam('animationUrl')) {
    catalog['animationUrl'] = pathConfig.getParam('animationUrl');
  }
  if (pathConfig && pathConfig.getParam('isMp4Collectible')) {
    catalog = { ...catalog };
    catalog['isMp4Collectible'] = pathConfig.getParam('isMp4Collectible');
    pathConfig.getParam('video')
      ? (catalog['video'] = pathConfig.getParam('video'))
      : null;
  }
  const availableCatalogs = route.params?.availableCatalogs;
  const [selectedCatalog, setSelectedCatalog] = useState(catalog);
  let catalogType: string =
    pathConfig && pathConfig.getParam('type')
      ? pathConfig.getParam('type')
      : route.params?.type;
  let navigationFrom: string =
    pathConfig && pathConfig.getParam('from')
      ? pathConfig.getParam('from')
      : route.params?.from;
  let extraParams: { tokenId: number; offerAddress: string } | undefined =
    route.params?.extraParams;
  let passportCollectible: string = route.params?.passportCollectible;

  const dispatch = useAppDispatch();
  const scrollView = useRef<KeyboardAwareScrollView>();
  const [yCoordinate, setYCoordinate] = useState(200);
  const [scrollToMessages, setScrollToMessages] = useState(false);
  const catalogListThirdParty = useAppSelector(
    state => state.Catalogs.CatalogThirdyPartyCollectiableDetailsList,
  );
  const loadingThirdParty = useAppSelector(
    state => state.Catalogs.loadingThirdParty,
  );
  const [isSocialAvailable, setSocialAvailable] = useState(true);
  const catalogListOffers = useAppSelector(
    state => state.Catalogs.CatalogOffersDetailsList,
  );
  const loadingOffers = useAppSelector(state => state.Catalogs.loadingOffers);
  const [isCopied, setIsCopied] = useState(false);
  const [search, setSearch] = useState('');
  const playbackInstance = React.useRef(null);
  const [playbackInstanceInfo, setPlaybackInstanceInfo] = useState({
    position: 0,
    duration: 0,
    state: 'Buffering',
    positionMillis: 0,
  });
  const [isGeneratingUrl, setIsGeneratingUrl] = useState(false);
  const [babeAndTheKidsEnabled, setBabeAndTheKidsEnabled] = useState(false);

  const isResponsive = useIsResponsive();
  const activeHeight = useActiveDimensions().height;

  useEffect(() => {
    (async () => {
      try {
        const appConfig = await getAppConfiguration();
        const isWhitelistedUser =
          appConfig?.babeAndTheKids?.users?.filter?.(
            (address: string) =>
              address.toLowerCase() === wallet?.address?.toLowerCase(),
          )?.length > 0 || false;
        if (
          isWhitelistedUser &&
          catalog?.address?.toLowerCase() ===
            appConfig?.babeAndTheKids?.collection?.toLowerCase()
        ) {
          setBabeAndTheKidsEnabled(true);
        }
      } catch {}
    })();
  }, []);

  useEffect(() => {
    if (catalogType === CatalogTypes.TICKET) {
      setDynamicTitle('Ticket');
    } else {
      setDynamicTitle('Collectible');
    }
  }, [catalogType]);

  useEffect(() => {
    if (
      catalog &&
      pathConfig &&
      pathConfig.getParam('chainId') &&
      pathConfig.getParam('address') &&
      !selectedCatalog
    ) {
      setSelectedCatalog(catalog);
    } else if (route.params?.catalog) {
      setSelectedCatalog(route.params?.catalog);
    }
  }, [catalog, route.params]);

  useEffect(() => {
    if (isWeb) {
      navigation.addListener('blur', () => {
        history.replaceState(null, document.title, window.location.origin);
      });
    }
  }, [navigation]);

  // const moveBack = () => {
  //   if (isWeb) {
  //     window.history.replaceState(document.title, window.location.origin);
  //   }
  //   if (
  //     catalogType == CatalogTypes.PASSPORT ||
  //     catalogType == CatalogTypes.COLLECTIBLES
  //   ) {
  //     dispatch(resetPassportMessage());
  //   }
  //   if (navigationFrom == ScreenName.BARCODE_TAB) {
  //     navigation.navigate(ScreenName.HOME_TAB, {
  //       screen: ScreenName.HOME_SCREEN,
  //     });
  //   } else if (navigationFrom == ScreenName.OFFERS_TAB) {
  //     navigation.goBack();
  //     route.params?.updateParent({ fromCollectibleDetails: true });
  //   } else if (
  //     navigationFrom == ScreenName.PASSPORT_DETAIL ||
  //     navigationFrom == ScreenName.PREMIUMCHAT_SUB_UNSUB + '_unsub'
  //   ) {
  //     navigation.navigate(ScreenName.HOME_TAB, {
  //       screen: ScreenName.PASSPORT_DETAIL,
  //       params: {
  //         passportAddress: selectedPassport.address,
  //         chainId: selectedPassport.chainId,
  //       },
  //     });
  //   } else if (navigationFrom == ScreenName.PREMIUMCHAT_SUB_UNSUB + '_sub') {
  //     navigation.navigate(ScreenName.HOME_TAB, {
  //       screen: ScreenName.PASSPORT_DETAIL,
  //       params: {
  //         passportAddress: selectedPassport.address,
  //         chainId: selectedPassport.chainId,
  //       },
  //     });
  //   } else if (navigationFrom == ScreenName.CATALOG) {
  //     navigation.goBack();
  //   } else if (navigationFrom == ScreenName.CATALOG_TAB) {
  //     navigation.goBack();
  //   } else if (navigationFrom != 'PassportPage') {
  //     navigation.navigate('CatalogList', {
  //       availableCatalogs: allCatalogs,
  //       type: catalogType,
  //     });
  //   } else if (navigationFrom == 'PassportPage') {
  //     if (passportCollectible && passportCollectible.length > 0) {
  //       navigation.navigate('CatalogList', {
  //         type: 'Collectibles',
  //         passport: SelectedPassport,
  //         passportCollectible: passportCollectible,
  //         from: navigationFrom,
  //         initial: false,
  //         updateParent: route.params?.updateParent,
  //       });
  //     } else {
  //       navigation.navigate(ScreenName.PASSPORT_DETAIL, {
  //         passportAddress: selectedPassport?.address,
  //         chainId: selectedPassport?.chainId,
  //       });
  //       if (route.params?.updateParent) {
  //         route.params?.updateParent({ fromCollectibleDetails: true });
  //       }
  //     }
  //   } else {
  //     if (
  //       catalogType == CatalogTypes.COLLECTIBLES ||
  //       (catalogType == CatalogTypes.PREMIUMCHAT && route.params?.updateParent)
  //     ) {
  //       route.params?.updateParent({ fromCollectibleDetails: true });
  //     }
  //     navigation.goBack();
  //   }
  // };

  useEffect(() => {
    return () => {
      if (playbackInstance.current) {
        playbackInstance.current.setStatusAsync({
          shouldPlay: false,
        });
      }
    };
  }, []);

  const togglePlay = async () => {
    const shouldPlay = playbackInstanceInfo.state !== 'Playing';
    if (playbackInstance.current !== null) {
      await playbackInstance.current.setStatusAsync({
        shouldPlay,
        ...(playbackInstanceInfo.state === 'Ended' && { positionMillis: 0 }),
      });
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        positionMillis:
          playbackInstanceInfo.state === 'Ended'
            ? 0
            : playbackInstanceInfo.positionMillis,
        state: playbackInstanceInfo.state === 'Playing' ? 'Paused' : 'Playing',
      });
    }
  };

  const stopVideo = async () => {
    if (playbackInstance.current) {
      playbackInstance.current.setStatusAsync({
        shouldPlay: false,
        isPlaying: false,
        state: 'Ended',
        didJustFinish: true,
        positionMillis: -1,
        durationMillis: 0,
      });
    }
  };

  const updatePlaybackCallback = status => {
    if (status.isLoaded) {
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        position: status.positionMillis,
        duration: status.durationMillis || 0,
        state: status.didJustFinish
          ? 'Ended'
          : status.isBuffering
          ? 'Buffering'
          : status.isPlaying
          ? 'Playing'
          : 'Paused',
      });
    } else {
      if (status.isLoaded === false && status.error) {
        const errorMsg = `Encountered a fatal error during playback: ${status.error}`;
      }
    }
  };

  useEffect(() => {
    const loadSocialMediaMessages = async () => {
      await dispatch(resetPassportMessage());
      dispatch(setinitialMessageLoading(true));
      await getSocialMediaAccess(
        selectedCatalog.address,
        selectedCatalog.chainId,
      ).then(isSocialAvailable => {
        dispatch(setinitialMessageLoading(false));
        setSocialAvailable(isSocialAvailable);
        if (isSocialAvailable) {
          dispatch(
            readMessages({
              address: selectedCatalog.address,
              chainId: selectedCatalog.chainId,
              networkID: networkId,
              provider: staticProvider,
              wallet,
            }),
          ).then(() => {
            dispatch(
              subscribeToPassportMessages({
                address: selectedCatalog.address,
                chainId: selectedCatalog.chainId,
                networkID: networkId,
                provider: staticProvider,
                wallet,
              }),
            );
          });
        }
      });
    };
    if (
      (catalogType == CatalogTypes.PASSPORT ||
        catalogType == CatalogTypes.COLLECTIBLES ||
        catalogType == CatalogTypes.PREMIUMCHAT) &&
      selectedCatalog
    ) {
      loadSocialMediaMessages();
    }
  }, [selectedCatalog]);

  const scrollToText = async event => {
    if (Platform.OS !== 'web') {
      const node = findNodeHandle(event.target);
      await wait(50);
      scrollView.current.scrollToFocusedInput(node, 290);
    } else {
      if (
        event &&
        event?.nativeEvent?.layout?.top +
          event?.nativeEvent?.layout?.height +
          90 >
          window.innerHeight
      ) {
        scrollView.current.scrollToFocusedInput(
          findNodeHandle(event.nativeEvent.target),
          0,
          100,
        );
      }
    }
  };

  const onCreatePost = () => {
    navigation.navigate('CreatePostScreen', {
      passportAddress: selectedCatalog.address,
      chainId: selectedCatalog.chainId,
      setScrollToMessages: setScrollToMessages,
    });
  };

  // get third party collectible in case navigation call is coming from barcode screen.
  useEffect(() => {
    const loadThirdPartyCollectible = () => {
      if (
        navigationFrom == ScreenName.BARCODE_TAB &&
        catalogListThirdParty &&
        catalogListThirdParty.length > 0
      ) {
        let catalogList = [...catalogListThirdParty];
        catalogList = catalogList.sort((a, b) => b.timestamp - a.timestamp);
        if (!selectedCatalog && catalogList && catalogList.length > 0) {
          setSelectedCatalog(catalogList[0]);
        }
      }
    };

    if (navigationFrom == ScreenName.BARCODE_TAB) {
      loadThirdPartyCollectible();
    }
  }, [loadingThirdParty]);

  // get coupon in case navigation call is coming from offer screen.
  useEffect(() => {
    const loadCouponCollectible = () => {
      if (
        (navigationFrom == ScreenName.OFFERS_TAB ||
          navigationFrom == ScreenName.PASSPORT_DETAIL) &&
        catalogListOffers &&
        catalogListOffers.length > 0
      ) {
        let filterCatalog = null;
        if (extraParams && extraParams?.offerAddress && extraParams?.tokenId) {
          filterCatalog = catalogListOffers.filter(
            x =>
              x.address.toLocaleLowerCase() ===
                extraParams?.offerAddress.toLocaleLowerCase() &&
              x.tokenId === extraParams?.tokenId,
          );
        }

        let catalogList = filterCatalog
          ? [...filterCatalog]
          : [...catalogListOffers];
        catalogList = catalogList.sort((a, b) => b.timestamp - a.timestamp);
        if (!selectedCatalog && catalogList && catalogList.length > 0) {
          setSelectedCatalog(catalogList[0]);
        }
      }
    };

    if (
      navigationFrom == ScreenName.OFFERS_TAB ||
      navigationFrom == ScreenName.PASSPORT_DETAIL
    ) {
      loadCouponCollectible();
    }
  }, [loadingOffers]);

  const copyToClipboard = (userAddr: string) => {
    Clipboard.setString(userAddr);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const routeToExternalLink = function (link: string) {
    Linking.openURL(link);
  };

  /// Marketplace Listing
  const onMyPassportsOptionsClick = async selectedItem => {
    await SheetManager.show('ExpassThreeDotSettingsSheet', {
      payload: {
        selectedEXPass: selectedItem,
        navigation,
        setSearchPassportText: setSearch,
        onListForSalePressed: onListForSalePressed,
        type: 'Collectible',
        transferSuccessNavigation: {
          screen: ScreenName.CATALOG_TAB,
          params: { updateDataAfterTransfer: true },
        },
        updateStateDataFunc: success => {},
      },
    });
  };

  const onListForSalePressed = async itemFromParams => {
    if (itemFromParams) {
      await SheetManager.show('ListingModalSheet', {
        payload: {
          itemObject: itemFromParams,
          navigation,
          type: 'Collectible',
          setSelectedCatalog: setSelectedCatalog,
          updateStateDataFunc: updatedObject => {
            setSelectedCatalog(prevState => ({
              ...prevState,
              marketPlaceConfig: updatedObject.marketPlaceConfig,
            }));
          },
        },
      });
    }
  };
  /// Marketplace Listing End

  const showToast = (message: string) => {
    showToastMessage(ToastCustomMessageType.INFO, message);
  };

  const handleAccessDigitalExperience = async () => {
    try {
      const generatedUrl = await generateAccessDigitalExperienceURL(
        wallet,
        setIsGeneratingUrl,
      );
      if (generatedUrl) {
        const supported = await Linking.canOpenURL(generatedUrl);
        if (supported) {
          await Linking.openURL(generatedUrl);
        } else {
          showToast('Unable to open url ' + generatedUrl);
        }
      } else {
        showToast('Something went wrong, please try again later!');
      }
    } catch (e) {
      LogToLoot8Console('accessDigitalExperience Error: ', e);
      showToast('Something went wrong, please try again later!');
    }
  };

  return (
    <>
      {/* create post */}
      {(catalogType === CatalogTypes.PASSPORT ||
        catalogType === CatalogTypes.COLLECTIBLES ||
        catalogType === CatalogTypes.PREMIUMCHAT) && (
        <Pressable
          style={[
            componentStyles.postButton,
            {
              borderColor: isSocialAvailable
                ? defaultTheme.GRADIENT_COLOR1
                : defaultTheme.PRIMARY_SHADOW_COLOR,
            },
          ]}
          disabled={!isSocialAvailable}
          onPress={isSocialAvailable && onCreatePost}>
          <Icon
            name="chat-plus-outline"
            size={26}
            color={
              isSocialAvailable
                ? defaultTheme.PRIMARY_TEXT_COLOR
                : defaultTheme.PRIMARY_SHADOW_COLOR
            }
            onPress={isSocialAvailable && onCreatePost}
          />
        </Pressable>
      )}
      {/* create post end */}

      <KeyboardAwareScrollView
        style={{ flex: 1 }}
        ref={scrollView}
        bounces={false}
        enableOnAndroid={false}
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 150,
        }}
        resetScrollToCoords={{ x: 0, y: yCoordinate }}
        enableResetScrollToCoords={false}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        viewIsInsideTabBar={true}
        enableAutomaticScroll
        extraHeight={170}
        extraScrollHeight={120}
        keyboardOpeningTime={0}>
        <View
          style={[
            componentStyles.bannerContainer,
            { height: isResponsive ? activeHeight / 3 : activeHeight / 4 },
          ]}>
          {/* Header Image Start*/}
          {!selectedCatalog?.isMp4Collectible &&
            (selectedCatalog.animationUrl ? (
              <>
                {(catalogType == CatalogTypes.COLLECTIBLES ||
                  ((catalogType === CatalogTypes.PASSPORT ||
                    catalogType === CatalogTypes.PREMIUMCHAT) &&
                    selectedCatalog?.marketPlaceConfig &&
                    selectedCatalog.marketPlaceConfig.allowMarketPlaceOps &&
                    selectedCatalog?.marketPlaceConfig?.publicTradeability)) &&
                  !selectedCatalog.fromExternalWallet && (
                    <ThreeDotsSettingsButton
                      extraStyles={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        zIndex: 100,
                      }}
                      onPress={() => onMyPassportsOptionsClick(selectedCatalog)}
                    />
                  )}
                <WebView
                  nestedScrollEnabled={true}
                  containerStyle={{ flex: 1 }}
                  style={{
                    flex: 1,
                    borderRadius: defaultTheme.CONTENT_RADIUS,
                  }}
                  source={{
                    uri: selectedCatalog.animationUrl,
                  }}
                  javaScriptEnabledAndroid={true}
                  onMessage={event => {
                    const data = event?.nativeEvent?.data;
                    if (data && data.indexOf && data.indexOf('loot8') > -1) {
                      webViewHelper(event.nativeEvent.data);
                    }
                  }}
                  androidLayerType={'software'}
                />
              </>
            ) : (
              <CachedImage
                isBackground
                source={{ uri: selectedCatalog?.image }}
                imageSize={selectedCatalog?.imageSize as Number}
                noImageFile={
                  catalogType === CatalogTypes.PREMIUMCHAT
                    ? 'no-image-chatroom.png'
                    : 'no-image-text.png'
                }
                style={{
                  ...componentStyles.bannerImage,
                  minHeight: isResponsive ? activeHeight / 3 : activeHeight / 4,
                  height: isResponsive ? activeHeight / 3 : activeHeight / 4,
                }}
                contentFit="contain">
                {(catalogType == CatalogTypes.COLLECTIBLES ||
                  (catalogType === CatalogTypes.PASSPORT &&
                    selectedCatalog?.passportType ===
                      PassportType.SUBSCRIBED) ||
                  ((catalogType === CatalogTypes.PASSPORT ||
                    catalogType === CatalogTypes.PREMIUMCHAT) &&
                    selectedCatalog?.marketPlaceConfig &&
                    selectedCatalog?.marketPlaceConfig?.allowMarketPlaceOps &&
                    selectedCatalog?.marketPlaceConfig?.publicTradeability)) &&
                  !selectedCatalog.fromExternalWallet && (
                    <ThreeDotsSettingsButton
                      extraStyles={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        zIndex: 100,
                      }}
                      onPress={() => onMyPassportsOptionsClick(selectedCatalog)}
                    />
                  )}
              </CachedImage>
            ))}
          {/* Header Image End*/}

          {/* VIDEO PLAYER START */}
          {selectedCatalog?.isMp4Collectible && (
            <>
              {(catalogType == CatalogTypes.COLLECTIBLES ||
                catalogType === CatalogTypes.PREMIUMCHAT) &&
                !selectedCatalog.fromExternalWallet && (
                  <ThreeDotsSettingsButton
                    extraStyles={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      zIndex: 100,
                    }}
                    onPress={() => onMyPassportsOptionsClick(selectedCatalog)}
                  />
                )}

              <Video
                ref={playbackInstance}
                style={componentStyles.videoPlayer}
                videoStyle={componentStyles.video}
                source={{ uri: selectedCatalog?.video }}
                //useNativeControls
                resizeMode={ResizeMode.CONTAIN}
                onPlaybackStatusUpdate={updatePlaybackCallback}
              />

              <View style={componentStyles.playerControls}>
                <IconButton
                  icon={
                    playbackInstanceInfo.state === 'Playing' ? 'pause' : 'play'
                  }
                  size={30}
                  iconColor={defaultTheme.PRIMARY_TEXT_COLOR}
                  onPress={togglePlay}
                />
                <IconButton
                  icon="stop"
                  size={30}
                  iconColor={defaultTheme.PRIMARY_TEXT_COLOR}
                  onPress={stopVideo}
                />
              </View>
            </>
          )}
          {/* VIDEO PLAYER END */}
        </View>
        <>
          {selectedCatalog ? (
            <>
              <View>
                {catalogType === CatalogTypes.PREMIUMCHAT ? (
                  <Text style={componentStyles.title}>
                    {selectedCatalog?.name}
                  </Text>
                ) : (
                  <View style={componentStyles.titleContainer}>
                    {/* Title */}
                    <Text style={componentStyles.title}>
                      {selectedCatalog?.name +
                        (selectedCatalog?.subTitle
                          ? ' ' + selectedCatalog?.subTitle
                          : '')}
                      {/* SHOW VERIFIED BADGE */}
                      {catalogType === CatalogTypes.PASSPORT &&
                        selectedCatalog?.thirdPartyVerifiedURL?.length > 0 && (
                          <>
                            {' '}
                            <VerifiedSvg
                              size={defaultTheme.FONT_SIZE_XMEDIUM}
                            />
                          </>
                        )}
                    </Text>
                    {/* SOCIAL BUTTONS START */}
                    {(selectedCatalog.discord || selectedCatalog.telegram) && (
                      <View style={componentStyles.row}>
                        {/* telegram Button*/}
                        {selectedCatalog.telegram && (
                          <View style={componentStyles.roundBtn}>
                            <TouchableRipple
                              rippleColor={'rgba(0,0,0,0.25)'}
                              onPress={evt =>
                                routeToExternalLink(selectedCatalog.telegram)
                              }
                              style={componentStyles.ripple}>
                              <FontAwesome
                                name="telegram-plane"
                                size={24}
                                color="#fff"
                              />
                            </TouchableRipple>
                          </View>
                        )}
                        {/* discord Button*/}
                        {selectedCatalog.discord && (
                          <View
                            style={[
                              componentStyles.roundBtn,
                              { marginLeft: 5 },
                            ]}>
                            <TouchableRipple
                              rippleColor={'rgba(0,0,0,0.25)'}
                              onPress={evt =>
                                routeToExternalLink(selectedCatalog.discord)
                              }
                              style={componentStyles.ripple}>
                              <DiscordSvg />
                            </TouchableRipple>
                          </View>
                        )}
                      </View>
                    )}
                    {/* SOCIAL BUTTONS END */}
                  </View>
                )}
              </View>
              <View style={componentStyles.separator} />
              {/* Description  */}
              <Text style={componentStyles.descriptionTitle}>Description</Text>
              <Text style={componentStyles.description}>
                {selectedCatalog?.details?.trim()}
              </Text>

              {babeAndTheKidsEnabled && (
                <GradientButton
                  disabled={isGeneratingUrl}
                  loading={isGeneratingUrl}
                  customStyle={{ marginTop: 12 }}
                  buttonLabel={'Access Digital Experience'}
                  onPress={handleAccessDigitalExperience}
                />
              )}

              {selectedCatalog.fromExternalWallet && (
                <View style={{ marginVertical: 15 }}>
                  <View style={componentStyles.row}>
                    <Text style={componentStyles.text}>
                      {`Note: `}
                      {`This Collectible is from a wallet external to Loot8 Application`}
                    </Text>
                  </View>

                  <View style={componentStyles.row}>
                    <Text style={componentStyles.text}>
                      {`Wallet Address: `}{' '}
                      {Dimensions.get('window').width > 480
                        ? selectedCatalog.extWalletAddress
                        : selectedCatalog.extWalletAddress?.substring(0, 5) +
                          '...' +
                          selectedCatalog.extWalletAddress?.substring(
                            selectedCatalog.extWalletAddress?.length - 5,
                            selectedCatalog.extWalletAddress?.length,
                          )}
                    </Text>
                    <Icon
                      name="clipboard-multiple"
                      style={{ marginLeft: 5 }}
                      size={16}
                      color={defaultTheme.PRIMARY_TEXT_COLOR}
                      onPress={() => {
                        copyToClipboard(selectedCatalog.extWalletAddress);
                      }}
                    />
                    {isCopied && (
                      <Text
                        style={[
                          componentStyles.text,
                          {
                            fontSize: 8,
                            position: 'relative',
                            marginLeft: 5,
                          },
                        ]}>
                        Copied!
                      </Text>
                    )}
                  </View>
                </View>
              )}
            </>
          ) : (
            <AppLoaderComponent
              deemBg={false}
              loaderText={{
                text: 'Loading Your Collectible',
              }}
            />
          )}
        </>
        <View
          style={{ flexBasis: 'auto', marginTop: 25 }}
          onLayout={event => {
            setYCoordinate(event.nativeEvent.layout.y);
          }}>
          {(catalogType === CatalogTypes.PASSPORT ||
            catalogType == CatalogTypes.COLLECTIBLES ||
            catalogType === CatalogTypes.PREMIUMCHAT) &&
            selectedCatalog && (
              <PassportMessageComponent
                selectedPassport={selectedCatalog}
                navigationFrom={{
                  screen: ScreenName.CATALOG_DETAILS,
                  params: {
                    catalog: selectedCatalog,
                    availableCatalogs: availableCatalogs,
                    type: catalogType,
                    from: navigationFrom,
                    passport: SelectedPassport,
                    passportCollectible: passportCollectible,
                    updateParent: route.params?.updateParent,
                  },
                }}
                scrollToText={scrollToText}
                isSocialAvailable={isSocialAvailable}
                yCoordinate={yCoordinate}
                setScrollToMessages={setScrollToMessages}
                scrollViewRef={scrollView}
                scrollToMessages={scrollToMessages}
              />
            )}
        </View>
      </KeyboardAwareScrollView>
    </>
  );
};

export default HOCContainer({
  OriginalComponent: CatalogDetail,
  isScrollEnabled: false,
});

const componentStyles = StyleSheet.create({
  postButton: {
    position: 'absolute',
    right: 15,
    top: '65%',
    width: 46,
    height: 46,
    borderWidth: 2,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    zIndex: 110,
  },
  bannerContainer: {
    borderWidth: 1,
    borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    overflow: 'hidden',
  },
  bannerImage: {
    maxWidth: '100%',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  videoPlayer: {
    width: '100%',
    height: '100%',
  },
  video: {
    position: 'relative',
    flex: 1,
    padding: 5,
  },
  playerControls: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0,
    height: 50,
    zIndex: 6,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  title: {
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  descriptionTitle: {
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    marginBottom: 2.5,
  },
  description: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  text: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 6,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  roundBtn: {
    height: 35,
    width: 35,
    borderRadius: 35,
    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    marginVertical: 10,
  },
});
