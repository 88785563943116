import React from 'react';
import styles from '../../styles';
import { View, Text, Linking, Platform, Dimensions } from 'react-native';
import { Button } from 'react-native-paper';
import ModalComponent from '../Modal';
import { APPSTORE_URL, PLAYSTORE_URL } from '../../appconstants';
import * as Device from 'expo-device';
import Constants from 'expo-constants';

type AppBackgroundRefreshModalComponentProps = {
  showBackgroundRefreshAppModal: boolean;
  setShowBackgroundRefreshAppModal: (show: boolean) => void;
};

const AppBackgroundRefreshComponent = ({
  showBackgroundRefreshAppModal,
  setShowBackgroundRefreshAppModal,
}: AppBackgroundRefreshModalComponentProps) => {
  const redirectToAppSettings = async () => {
    await Linking.openSettings();
    setShowBackgroundRefreshAppModal(false);
  };

  return (
    <ModalComponent
      showModal={showBackgroundRefreshAppModal}
      enableHeader={true}
      headerText={'Stay Updated on the Go!'}
      onDismiss={() => {
        setShowBackgroundRefreshAppModal(false);
      }}
      needCloseButton={true}
      dismissable={false}
      theme={'rgba(0, 0, 0, 0.80)'}>
      <View style={{ paddingTop: 10, paddingLeft: 15, paddingRight: 15 }}>
        <Text style={styles.modalTextStyle}>
          {'Get the most out of LOOT8!'}
        </Text>
        <Text style={[styles.modalTextStyle, { fontWeight: '700' }]}>
          {
            'Enable Background App Refresh on your device to stay better notified and updated.'
          }
        </Text>
      </View>
      <View style={styles.modalButtonContainer}>
        <Button
          onPress={() => {
            setShowBackgroundRefreshAppModal(false);
          }}
          style={styles.modalNoButtonStyle}
          labelStyle={styles.modalNoButtonLabelStyle}>
          CANCEL
        </Button>
        <Button
          onPress={redirectToAppSettings}
          style={styles.modalYesButtonStyle}
          labelStyle={styles.modalYesButtonLabelStyle}>
          OPEN SETTINGS
        </Button>
      </View>
    </ModalComponent>
  );
};

export default AppBackgroundRefreshComponent;
