import React, { useState, useRef, ReactNode } from 'react';
import {
  Text,
  View,
  Image,
  FlatList,
  Platform,
  Pressable,
  StyleSheet,
  ScrollView,
  findNodeHandle,
  ActivityIndicator,
} from 'react-native';
import { TextInput } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import { SafeAreaView } from 'react-native-safe-area-context';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { wait } from '../helpers/ipfs';
import { defaultTheme } from '../themes/loot8';
import ModalComponent from '../components/Modal';
import HOCContainer from '../components/HOCContainer';
import ImagePickerComponent from '../components/ImagePickerComponent';

import micIcon from '../assets/mic.png';
import smileyIcon from '../assets/smiley.png';
import attachmentIcon from '../assets/attachment.png';

let MESSAGE_MAX_LENGTH = 360;

const PrivateChatRoomScreen = () => {
  const [messages, setMessages] = useState([
    { id: 1, text: 'Hello!', sender: 'friend' },
    { id: 2, text: 'Hi there!', sender: 'user' },
    { id: 3, text: 'How are you?', sender: 'friend' },
  ]);
  const KeyboardScrollView = useRef<KeyboardAwareScrollView>();

  const [textInputHeight, setTextInputHeight] = useState(35);
  const [msgFriendRequestSent, setMsgFriendRequestSent] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [messageText, setMessageText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [newMessage, setNewMessage] = useState('');

  const flatListRef = useRef(null);

  const adjustTextInputSize = evt => {
    if (Platform.OS === 'web') {
      const el = evt?.target || evt?.nativeEvent?.target;
      if (el) {
        const MAX_HEIGHT = 110;
        el.style.height = 0;
        const newHeight = el.offsetHeight - el.clientHeight + el.scrollHeight;
        el.style.height =
          newHeight < MAX_HEIGHT ? `${newHeight}px` : `${MAX_HEIGHT}px`;
        setTextInputHeight(newHeight < MAX_HEIGHT ? newHeight : MAX_HEIGHT);
      }
    }
  };

  const renderMessage = ({ item }) => (
    <LinearGradient
      colors={
        item.sender === 'user'
          ? [defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]
          : [defaultTheme.SECONDARY_COLOR, defaultTheme.SECONDARY_COLOR]
      }
      end={{ x: 0.0001, y: 0.2 }}
      start={{ x: 0.00001, y: 0.8 }}
      style={[
        styles.message,
        { alignSelf: item.sender === 'user' ? 'flex-end' : 'flex-start' },
      ]}>
      <Text style={styles.messageText}>{item.text}</Text>
    </LinearGradient>
  );

  const renderEmptyList = () => (
    <View style={styles.emptyListContainer}>
      <Text style={styles.emptyListText}>Start the conversation!</Text>
    </View>
  );

  const scrollToText = async (node: ReactNode) => {
    await wait(50);
    const offset = Platform.OS === 'android' ? 290 : 220;
    KeyboardScrollView.current.scrollToFocusedInput(node, offset);
  };

  const onSendMessage = () => {};

  const hasValue = () => {
    return messageText.length > 0;
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <FlatList
        ref={flatListRef}
        data={messages}
        renderItem={renderMessage}
        keyExtractor={item => item.id.toString()}
        ListEmptyComponent={renderEmptyList}
        contentContainerStyle={{
          flexGrow: 1,
        }}
        inverted
      />

      <View style={styles.inputContainer}>
        <View style={styles.inputInnerWrap}>
          <LinearGradient
            colors={['#B353FF', '#4E008B']}
            end={{ x: 0.0001, y: 0.2 }}
            start={{ x: 0.00001, y: 0.8 }}
            style={styles.cameraIcon}>
            <Pressable onPress={() => setShowAddImage(true)}>
              <MaterialCommunityIcons
                name="camera"
                color={'#FCFCFC'}
                size={20}
              />
            </Pressable>
          </LinearGradient>

          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <View style={{ flex: 1 }}>
              {!msgFriendRequestSent ? (
                <TextInput
                  onFocus={event => {
                    Platform.OS === 'ios' &&
                      scrollToText(findNodeHandle(event.target));
                  }}
                  style={styles.inputWrap}
                  value={messageText}
                  onChangeText={text => {
                    if (text && text.length <= MESSAGE_MAX_LENGTH) {
                      setMessageText(text);
                    } else if (text.length === 0) {
                      setMessageText(text);
                    }
                  }}
                  textColor="#FFFFFF"
                  maxLength={MESSAGE_MAX_LENGTH}
                  placeholder={'Message...'}
                  placeholderTextColor="#bdc6d5"
                  cursorColor="#FFFFFF"
                  selectionColor="rgba(37,37,70,0.39)"
                  returnKeyType="default"
                  mode="outlined"
                  textInputHeight={textInputHeight}
                  activeOutlineColor="transparent"
                  outlineColor="transparent"
                  multiline
                  onChange={adjustTextInputSize}
                  onLayout={adjustTextInputSize}
                />
              ) : (
                <ScrollView
                  contentContainerStyle={{ flexGrow: 1, padding: 15 }}
                  style={{
                    backgroundColor: 'transparent',
                    maxHeight: 120,
                  }}
                  showsVerticalScrollIndicator={false}>
                  <Text
                    style={{
                      fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                      fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                      textAlignVertical: 'center',
                      color: '#bdc6d5',
                    }}>
                    {messageText}
                  </Text>
                </ScrollView>
              )}
            </View>
            {selectedImage && selectedImage !== '' ? (
              <View
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 70,
                  height: 70,
                }}>
                <Image
                  source={{ uri: selectedImage }}
                  style={{
                    height: 70,
                    width: 70,
                    backgroundColor: '#FFFFFF',
                    borderColor: '#dfdfdf',
                    borderWidth: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  resizeMethod="scale"
                  resizeMode="contain"
                />
                {!isLoading && (
                  <Pressable
                    onPress={() => setSelectedImage(null)}
                    style={{
                      position: 'absolute',
                      backgroundColor: '#F0F0F0B0',
                      height: 23,
                      width: 23,
                      borderRadius: 30,
                      top: 5,
                      right: 5,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <MaterialCommunityIcons
                      name="window-close"
                      size={17}
                      style={{ color: '#404040' }}
                    />
                  </Pressable>
                )}
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
        <View
          style={{
            paddingRight: 4,
            alignSelf: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <Pressable
            onPress={() => setShowAddImage(true)}
            style={{
              width: 30,
              height: 30,
              marginRight: 4,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Image source={attachmentIcon} style={{ width: 20, height: 20 }} />
          </Pressable>
          <Pressable
            onPress={() => null}
            style={{
              width: 30,
              height: 30,
              marginRight: 4,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Image
              source={smileyIcon}
              style={{ width: 20, height: 20, tintColor: '#fff' }}
            />
          </Pressable>
          {isLoading ? (
            <ActivityIndicator
              style={{ paddingRight: 5 }}
              size={20}
              color={defaultTheme.PRIMARY_TEXT_COLOR}
            />
          ) : (
            <Pressable
              onPress={() => onSendMessage()}
              style={{
                width: 30,
                height: 30,
                borderRadius: hasValue() ? 15 : 0,
                backgroundColor: hasValue()
                  ? defaultTheme.GRADIENT_COLOR1
                  : 'transparent',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              {hasValue() ? (
                <MaterialCommunityIcons
                  name={'send'}
                  size={16}
                  color={'#FFFFFF'}
                  style={{ marginLeft: 2 }}
                />
              ) : (
                <Image
                  source={micIcon}
                  style={{
                    width: 18,
                    height: 18,
                    marginRight: 8,
                    tintColor: '#fff',
                  }}
                />
              )}
            </Pressable>
          )}
        </View>
      </View>
      {showAddImage && (
        <ModalComponent
          showModal={showAddImage}
          enableHeader={false}
          modalStyle={{ bottom: 0, justifyContent: 'flex-end' }}
          modalBodyStyle={{ alignSelf: 'flex-start' }}
          onDismiss={() => {
            setShowAddImage(false);
          }}>
          <View style={{ padding: 10 }}>
            <Text style={styles.imagePickerHeaderText}>Attach Photo</Text>
            <ImagePickerComponent
              onAvatarButtonClick={() => {}}
              setSelectedImage={setSelectedImage}
              setSelectImageAction={setShowAddImage}
              showAvatar={false}
              isSquare={false}
            />
          </View>
        </ModalComponent>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  message: {
    minHeight: 40,
    maxWidth: '70%',
    borderRadius: 30,
    marginVertical: 5,
    alignItems: 'center',
    paddingHorizontal: 16,
    justifyContent: 'center',
  },
  messageText: {
    fontSize: 12,
    color: '#FFFFFF',
    textAlign: 'center',
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
  inputContainer: {
    padding: 10,
    width: '100%',
    minHeight: 50,
    borderRadius: 56,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: defaultTheme.SECONDARY_COLOR,
  },
  inputInnerWrap: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  cameraIcon: {
    width: 40,
    height: 40,
    padding: 10,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputWrap: {
    textAlignVertical: 'center',
    backgroundColor: 'transparent',
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    marginTop: 5,
    padding: 0,
  },
  emptyListContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyListText: {
    fontSize: 18,
    color: '#555',
  },
  imagePickerHeaderText: {
    textAlign: 'left',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
});

export default HOCContainer({
  OriginalComponent: PrivateChatRoomScreen,
  withBottomTabs: false,
  isScrollEnabled: false,
});
