import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import HomeScreen from '../screens/HomeScreen';
import FeaturedOfferDetail from '../components/FeaturedOfferDetail';
import CatalogDetail from '../components/CatalogDetails';
import CatalogList from '../components/CatalogList';
import PassportBuySellConfirmation from '../components/Passport/PassportBuySellConfirmation';
import OffersScreen from '../screens/OffersScreen';
import OfferDetail from '../components/OfferDetail';
import EventsDetail from '../components/EventsDetail';
import PassportDetail from '../components/Passport/PassportDetail';
import ChatroomSubscribe from '../components/PremiumChatroom/chatroomSubscribe';
import TokenPackages from '../components/WalletHelp/TokenPackages';
import CustomHeader from './AnimatedHeader/CustomHeader';
import SeeAll from '../screens/SeeAll';
import CreatePost from '../components/CreatePost';
import PrivateMessageSubUnsub from '../components/Passport/Tabs/DirectMessaging/PrivateMessage/PrivateMessageSubUnsub';
import MPCollectibleDetails from '../components/MarketPlace/MPCollectibles/MPCollectibleDetails';
import FollowingDetailsScreen from '../screens/FollowingDetailsScreen';
import MarketPlaceUnlistCollection from '../components/MarketPlace/MarketPlaceUnlistCollection';
import MarketPlaceBuyCollection from '../components/MarketPlace/MarketPlaceBuyCollection';
import CatalogScreen from '../screens/CatalogScreen';
import { ScreenName } from '../enums/screen.enum';
import EventDetails from '../components/Passport/Tabs/Events/EventDetails';
import EventList from '../components/Passport/Tabs/Events/EventList';

const Stack = createStackNavigator();

function HomeNavigator() {
  const navigation = useNavigation();
  useEffect(() => {
    navigation.addListener('blur', () => {
      if (
        Platform.OS === 'web' &&
        new URLSearchParams(window.location.search).get('page') === 'expasses'
      ) {
        history.replaceState(null, document.title, window.location.origin);
      }
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      initialRouteName="HomeScreen"
      screenOptions={{
        headerShown: true,
        headerStyle: {
          height: Platform.OS === 'ios' ? 120 : 70,
          backgroundColor: 'transparent',
          borderBottomWidth: 0,
        },
        headerMode: 'float',
        header: props => <CustomHeader {...props} />,
      }}>
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
      <Stack.Screen name="PassportDetail" component={PassportDetail} />
      <Stack.Screen
        name="FollowingDetails"
        component={FollowingDetailsScreen}
      />
      <Stack.Screen name="CreatePostScreen" component={CreatePost} />

      <Stack.Screen name="Offers" component={OffersScreen} />
      <Stack.Screen
        name="FeaturedOfferDetail"
        component={FeaturedOfferDetail}
      />
      <Stack.Screen name="CatalogTab" component={CatalogScreen} />
      <Stack.Screen name="CatalogDetails" component={CatalogDetail} />
      <Stack.Screen name="CatalogList" component={CatalogList} />
      <Stack.Screen
        name="PassportBuySellConfirmation"
        component={PassportBuySellConfirmation}
      />
      <Stack.Screen
        name="MPCollectibleDetail"
        component={MPCollectibleDetails}
      />
      <Stack.Screen
        name={ScreenName.MARKET_PLACE_BUY_COLLECTIBLE}
        component={MarketPlaceBuyCollection}
      />
      <Stack.Screen name="OfferDetail" component={OfferDetail} />
      <Stack.Screen name="EventsDetail" component={EventsDetail} />
      {/* Below EventDetails Screen in used under free profile and Premium Expasses Tabs */}
      <Stack.Screen name="EventDetails" component={EventDetails} />
      <Stack.Screen name="EventList" component={EventList} />
      <Stack.Screen name="ChatroomSubscribe" component={ChatroomSubscribe} />
      <Stack.Screen
        name="PrivateMessageSubUnsub"
        component={PrivateMessageSubUnsub}
      />
      <Stack.Screen name="TokenPackages" component={TokenPackages} />
      <Stack.Screen name="SeeAll" component={SeeAll} />
      <Stack.Screen
        name="MarketPlaceUnlistCollection"
        component={MarketPlaceUnlistCollection}
      />
    </Stack.Navigator>
  );
}

export default HomeNavigator;
