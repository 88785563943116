import { Dimensions, Pressable, StyleSheet, Text, View } from 'react-native';
import React, { useState } from 'react';
import { defaultTheme } from '../../themes/loot8';
import {
  TXFailedSvg,
  TXPendingSvg,
  TXSuccessSvg,
} from '../../assets/TXItemSvgs';
import { RESPONSIVE, RESPONSIVE_WIDTH } from '../../appconstants';
import { formatEther } from 'ethers/lib/utils'; // import { utils } from "ethers";
import { Entypo } from '@expo/vector-icons';
import IconBox from '../WalletHelp/txHistory/IconBox';

const WIDTH = Dimensions.get('window').width;
const isResponsive = RESPONSIVE == 'true' && WIDTH > parseInt(RESPONSIVE_WIDTH);
const TXItem = ({ item, expandable }) => {
  const [expand, setExpand] = useState(false);
  function formatDateTimeForUI(dateTimeString) {
    // Create a Date object from the provided date-time string
    const dateObject = new Date(dateTimeString);

    if (dateObject?.toString?.() === 'Invalid Date') {
      return '---';
    }

    // Define options for formatting the date
    const dateOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };

    // Define options for formatting the time
    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
    };

    // Format the date and time separately
    const formattedDate = dateObject.toLocaleString('en-US', dateOptions);
    const formattedTime = dateObject.toLocaleString('en-US', timeOptions);

    // Combine the formatted date and time with a line break
    const formattedDateTime = `${formattedDate}\nat ${formattedTime}`;

    return formattedDateTime;
  }
  const renderTxIcon = () => {
    switch (true) {
      case item.completionTxHash === null &&
        item.failureTxHash === null &&
        item.payoutItemStatus === 'UNCLAIMED':
        return <TXSuccessSvg />;
      case item.completionTxHash !== null && item.failureTxHash === null:
        return <TXSuccessSvg />;
      case item.completionTxHash === null && item.failureTxHash !== null:
        return <TXFailedSvg />;
      default:
        return <TXPendingSvg />;
    }
  };
  const renderTxTitle = () => {
    switch (true) {
      case item.completionTxHash === null &&
        item.failureTxHash === null &&
        item.payoutItemStatus === 'UNCLAIMED':
        return 'Unclaimed Cashout';
      case item.completionTxHash !== null && item.failureTxHash === null:
        return 'Token Cashout';
      case item.completionTxHash === null && item.failureTxHash !== null:
        return 'Transaction Failed';
      default:
        return 'Pending';
    }
  };
  const processedAmount = formatEther(item.amount.toString());
  return (
    <Pressable
      onPress={expandable ? () => setExpand(!expand) : null}
      style={styles.container}>
      <View style={[styles.row, { flex: 1 }]}>
        <IconBox>{renderTxIcon()}</IconBox>
        <View style={{ flex: 1, marginLeft: 5.5 }}>
          <View style={[styles.row, { justifyContent: 'space-between' }]}>
            <View>
              <Text style={styles.title}>
                {renderTxTitle()}
                <Text style={styles.amount}>
                  {'  '}
                  {item.amount && processedAmount} Loot8
                </Text>
              </Text>
              <Text
                style={[
                  styles.title,
                  styles.amount,
                  { textTransform: 'capitalize' },
                ]}>
                {item?.platform}
              </Text>
              <Text style={styles.textLeft} numberOfLines={1}>
                {item?.requestId && `Request Id #${item?.requestId}`}
              </Text>
            </View>
            <View>
              <Text style={styles.textRight}>
                {item.blockTimestamp
                  ? formatDateTimeForUI(item?.blockTimestamp)
                  : '---'}
              </Text>
              {expandable && (
                <Entypo
                  style={{ textAlign: 'right', marginTop: 5 }}
                  name={expand ? 'chevron-up' : 'chevron-down'}
                  size={16}
                  color="white"
                />
              )}
            </View>
          </View>
        </View>
      </View>
      {expand && (
        <>
          {Boolean(item?.updatedOn) && (
            <>
              <View style={styles.separator} />
              <View
                style={[
                  styles.row,
                  {
                    justifyContent: 'space-between',
                  },
                ]}>
                <Text style={styles.textLeft}>Last status update</Text>
                <Text style={styles.textRight}>
                  {' '}
                  {item.updatedOn
                    ? formatDateTimeForUI(item?.updatedOn)
                    : '---'}
                </Text>
              </View>
            </>
          )}
          <View style={styles.separator} />
          <View
            style={[
              styles.row,
              {
                justifyContent: 'space-between',
              },
            ]}>
            <Text style={styles.textLeft}>
              {item.completionTxHash !== null && item.failureTxHash === null
                ? 'Completed at'
                : 'Estimated completion time'}
            </Text>
            <Text style={styles.textRight}>
              {' '}
              {item.completionTxHash !== null && item.failureTxHash === null
                ? item.updatedOn
                  ? formatDateTimeForUI(item?.updatedOn)
                  : '---'
                : item.estCompletionTime
                ? formatDateTimeForUI(item?.estCompletionTime)
                : '---'}
            </Text>
          </View>
          <View style={styles.separator} />
          <View>
            <Text style={styles.textLeft}>Message:</Text>
            <Text style={[styles.textRight, { textAlign: 'left' }]}>
              {item.statusReason ? item?.statusReason : ' '}
            </Text>
          </View>
        </>
      )}
    </Pressable>
  );
};

export default TXItem;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#232336',
    borderRadius: 8,
    // height: 55,
    paddingVertical: 7.5,
    paddingHorizontal: 7.5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconBox: {
    backgroundColor: '#AAA4A4',
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#00000029',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
    elevation: 10,
    borderRadius: 8,
  },
  title: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: '#fff',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    width: isResponsive ? RESPONSIVE_WIDTH / 3.5 : WIDTH / 2.5,
  },
  textRight: {
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: '#fff',
    fontFamily: defaultTheme.FONT_FAMILY_MAIN,
    fontWeight: '300',
    textAlign: 'right',
    alignSelf: 'flex-start',
  },
  textLeft: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: '#fff',
    fontFamily: defaultTheme.FONT_FAMILY_MAIN,
    fontWeight: '300',
    lineHeight: 18,
    width: isResponsive ? RESPONSIVE_WIDTH / 3.5 : WIDTH / 2.5,
  },
  amount: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    opacity: 0.8,
    paddingLeft: 4,
  },
  separator: {
    height: 1,
    backgroundColor: 'rgba(255,255,255,0.05)',
    marginVertical: 10,
  },
});
