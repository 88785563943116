import { configureStore } from '@reduxjs/toolkit';
import friendsReducer from './slices/friendsSlice';
import { EntitySliceReducer } from './slices/EntitySlice';
import { PassportSliceReducer } from './slices/PassportSlice';
import { OfferSliceReducer } from './slices/OfferSlice';
import { AppSliceReducer } from './slices/AppSlice';
import { AppUserSliceReducer } from './slices/AppUserSlice';
import { CatalogSliceReducer } from './slices/CatalogSlice';
import notificationReducer from './slices/NotificationSlice';
import { UserOrderSliceReducer } from './slices/OrderSlice';
import { ThirdPartyCollectiblesSliceReducer } from './slices/ThirdPartyCollectiblesSlice';
import { LocationSliceReducer } from './slices/LocationSlice';
import { PassportMessageSliceReducer } from './slices/PassportMessageSlice';
import { DigitalCollectibleSliceReducer } from './slices/DigitalCollectibleSlice';
import { PrivateMessageSliceReducer } from './slices/PrivateMessageSlice';
import { MarketPlaceSliceReducer } from './slices/MarketPlaceSlice';
import { CreatorsSliceReducer } from './slices/CreatorsSlice';
import { ModalSliceReducer } from './slices/ModalsSlice';
import { EstPortalEntitySliceReducer } from './EstPortal/slices/EntitySlice';
import { EstPortalCollectibleSliceReducer } from './EstPortal/slices/collectibleSlice';
import { EstPortalPassportSliceReducer } from './EstPortal/slices/PassportSlice';
import { EstPortalAppSliceReducer } from './EstPortal/slices/AppSlice';

const store = configureStore({
  reducer: {
    App: AppSliceReducer,
    AppUser: AppUserSliceReducer,
    Entity: EntitySliceReducer,
    Passports: PassportSliceReducer,
    Creators: CreatorsSliceReducer,
    Offers: OfferSliceReducer,
    friends: friendsReducer,
    Catalogs: CatalogSliceReducer,
    notification: notificationReducer,
    UserOrders: UserOrderSliceReducer,
    ThirdPartyCollectibles: ThirdPartyCollectiblesSliceReducer,
    Location: LocationSliceReducer,
    PassportMessage: PassportMessageSliceReducer,
    DigitalCollectible: DigitalCollectibleSliceReducer,
    PrivateMessage: PrivateMessageSliceReducer,
    MarketPlace: MarketPlaceSliceReducer,
    Modals: ModalSliceReducer,
    EstPortalEntity: EstPortalEntitySliceReducer,
    EstPortalCollectibles: EstPortalCollectibleSliceReducer,
    EstPortalPassports: EstPortalPassportSliceReducer,
    EstPortalApp: EstPortalAppSliceReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
