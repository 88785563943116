import React from 'react';
import { View, Text } from 'react-native';
import { styles } from './styles';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import ChatRoomCard from '../../../PremiumChatroom/chatRoomCard';
import { useNavigation } from '@react-navigation/native';
import {
  getSocialMediaAccess,
  readMessages,
  resetPassportMessage,
  subscribeToPassportMessages,
} from '../../../../slices/PassportMessageSlice';
import { useWeb3AuthContext } from '../../../../hooks/web3authContext';
import { ScreenName } from '../../../../enums/screen.enum';
import ExpassPrivateMessage from './PrivateMessage';

const DirectMessaging: React.FC<{ selectPassport: { address; chainId } }> = ({
  selectPassport,
}) => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { networkId, staticProvider, wallet } = useWeb3AuthContext();
  const { premiumChatCollectibles } = useAppSelector(state => state.Offers);

  const onPremiumChatUnSubscribeClick = async () => {
    // @ts-ignore
    navigation.navigate(ScreenName.PREMIUMCHAT_SUB_UNSUB, {
      selectedEXPass: selectPassport,
      premiumChatCollectible: [],
      currentLoot8Balance: 0,
      type: 'unsub',
    });
  };

  const updateData = async data => {
    //reset messages, so it does not show old message for a sec when a new messages are getting loaded
    await dispatch(resetPassportMessage());
    if (selectPassport) {
      await getSocialMediaAccess(
        selectPassport.address,
        selectPassport.chainId,
      ).then(isSocialAvailable => {
        if (isSocialAvailable) {
          dispatch(
            readMessages({
              address: selectPassport.address,
              chainId: networkId,
              networkID: networkId,
              provider: staticProvider,
              wallet,
            }),
          ).then(() => {
            dispatch(
              subscribeToPassportMessages({
                address: selectPassport.address,
                chainId: networkId,
                networkID: networkId,
                provider: staticProvider,
                wallet,
              }),
            );
          });
        }
      });
    }
  };
  const navigateToCollectibleDetails = (item, type) => {
    // @ts-ignore
    navigation.navigate('CatalogDetails', {
      catalog: item,
      availableCatalogs: [],
      type: type,
      from: 'PassportPage',
      updateParent: updateData,
      passport: selectPassport,
    });
  };
  return (
    <View style={styles.container}>
      <View>
        {premiumChatCollectibles && premiumChatCollectibles.length ? (
          <ChatRoomCard
            navigateToCollectibleDetails={navigateToCollectibleDetails}
            navigation={navigation}
            navigateToUnsub={onPremiumChatUnSubscribeClick}
          />
        ) : (
          ''
        )}
      </View>
      {/* Temporarily commenting this out as it's not functional */}
      {/* <View>
        <ExpassPrivateMessage navigation={navigation}/>
      </View> */}
    </View>
  );
};

export default DirectMessaging;
