import { ethers } from 'ethers';
import {
  CollectionFactory__factory,
  CollectionHelper__factory,
  CollectionManager__factory,
  Entity__factory,
  Loot8Onboarder__factory,
  SubscriptionManager__factory,
} from '../../typechain';
import { ZERO_ADDRESS } from '../../appconstants';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';

export const GetEncodedHash = (inviteCode: string) => {
  const abi = ethers.utils.defaultAbiCoder;
  return ethers.utils.keccak256(abi.encode(['string'], [inviteCode]));
};

export const GetCreateEntityDataMessage = (
  area,
  entityName,
  dataURI,
  walletAddress,
  authority,
  userContract,
  onboarder,
) => {
  const IEntity = new ethers.utils.Interface(Entity__factory.abi);
  return IEntity.encodeFunctionData('initializeV2', [
    area,
    entityName,
    dataURI,
    walletAddress,
    authority,
    userContract,
    onboarder,
  ]);
};

export const GetOnboardEntityDataMessage = (inviteCode, creationData) => {
  const IOnboarder = new ethers.utils.Interface(Loot8Onboarder__factory.abi);
  return IOnboarder.encodeFunctionData('onboard', [inviteCode, creationData]);
};

export const GetEntityOnboardedLogData = logs => {
  let createdEntity = ZERO_ADDRESS;
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const IOnboarder = new ethers.utils.Interface(
        Loot8Onboarder__factory.abi,
      );
      let item = logs[logs.length - 1];
      try {
        const decodedEventDataItem = IOnboarder.parseLog(item);
        if (
          decodedEventDataItem &&
          decodedEventDataItem?.name.toLocaleLowerCase() ===
            'EntityOnboarded'.toLocaleLowerCase() &&
          decodedEventDataItem?.args &&
          decodedEventDataItem?.args?._entity
        ) {
          createdEntity = decodedEventDataItem?.args?._entity;
        }
      } catch (e) {
        LogToLoot8Console('error in decoding onboarding entity event log');
      }
    }
  } catch (e) {
    LogToLoot8Console('error in decoding onboarding entity event logs', e);
  }
  return createdEntity;
};

export const GetCreateCollectionMessage = (
  address,
  name,
  symbol,
  dataURI,
  _transferable,
  manager,
  helper,
  subscriptionManager,
  _layerZeroEndpoint,
) => {
  const ICollectionFactory = new ethers.utils.Interface(
    CollectionFactory__factory.abi,
  );
  return ICollectionFactory.encodeFunctionData('createCollection', [
    address,
    name,
    symbol,
    dataURI,
    _transferable,
    manager,
    helper,
    subscriptionManager,
    _layerZeroEndpoint,
  ]);
};

export const GetCollectionCreatedLogData = logs => {
  let createdCollection = ZERO_ADDRESS;
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const ICollectionFactory = new ethers.utils.Interface(
        CollectionFactory__factory.abi,
      );
      logs?.map((item, index) => {
        try {
          const decodedEventDataItem = ICollectionFactory.parseLog(item);
          if (
            decodedEventDataItem &&
            decodedEventDataItem?.name.toLocaleLowerCase() ===
              'CreatedCollection'.toLocaleLowerCase() &&
            decodedEventDataItem?.args &&
            decodedEventDataItem?.args?._collection
          ) {
            createdCollection = decodedEventDataItem?.args?._collection;
          }
        } catch (e) {}
      });
    }
  } catch (e) {
    LogToLoot8Console('error in decoding collection created event logs', e);
  }
  return createdCollection;
};

export const GetAddCollectionMessage = (
  address,
  chainId,
  collectionType,
  collectibleData,
  additionalCollectionData,
  area,
  _tradability,
) => {
  const ICollection = new ethers.utils.Interface(
    CollectionManager__factory.abi,
  );
  return ICollection.encodeFunctionData('addCollection', [
    address,
    chainId,
    collectionType,
    collectibleData,
    additionalCollectionData,
    area,
    _tradability,
  ]);
};

export const GetSubscriptionPassportDataMessage = (
  _passport,
  _peopleFeeReceiver,
  _floorPrice,
  _priceRate,
  _tradingEnabled,
  _startSubscription,
) => {
  const ISubscription = new ethers.utils.Interface(
    SubscriptionManager__factory.abi,
  );
  return ISubscription.encodeFunctionData('setCollectionSubscriptionConfig', [
    _passport,
    _peopleFeeReceiver,
    _floorPrice,
    _priceRate,
    _tradingEnabled,
    _startSubscription,
  ]);
};

export const GetAddSubscriptionPassportLogData = logs => {
  let subscribedPassport = ZERO_ADDRESS;
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      //SubscriptionManager__factory
      const ISubscriptionPassport = new ethers.utils.Interface(
        SubscriptionManager__factory.abi,
      );
      logs?.map((item, index) => {
        try {
          const decodedEventDataItem = ISubscriptionPassport.parseLog(item);
          if (
            decodedEventDataItem &&
            decodedEventDataItem?.name.toLocaleLowerCase() ===
              'SubscriptionConfigSet'.toLocaleLowerCase() &&
            decodedEventDataItem?.args &&
            decodedEventDataItem?.args?._collection
          ) {
            subscribedPassport = decodedEventDataItem?.args?._collection;
          }
        } catch (e) {}
      });
    }
  } catch (e) {
    LogToLoot8Console('error in decoding collection created event logs', e);
  }
  return subscribedPassport;
};

export const GetMarketPlaceTradablityMessage = (
  _collection,
  _privateTradeAllowed,
  _publicTradeAllowed,
) => {
  const ICollectionHelper = new ethers.utils.Interface(
    CollectionHelper__factory.abi,
  );
  return ICollectionHelper.encodeFunctionData('setTradeablity', [
    _collection,
    _privateTradeAllowed,
    _publicTradeAllowed,
  ]);
};
