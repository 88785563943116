import React, { useEffect } from 'react';
import { Image, Platform, Pressable, Text, View } from 'react-native';
import useState from 'react-usestateref';
import { ZERO_ADDRESS } from '../../appconstants';
import reshare from '../../assets/arrows-retweet-2x.png';
import like from '../../assets/heart-2x.png';
import likeFill from '../../assets/heart-fill-2x.png';
import { getTimeTextFromTimestamp } from '../../helpers/DateHelper';
import { useAppDispatch } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { IMessageList } from '../../interfaces/IMessages';
import {
  postMessageLike,
  postMessageUnlike,
} from '../../slices/PassportMessageSlice';
import styles from '../../styles';
import URLPreview from '../URLPreview';
import { detectURL } from '../../helpers/Gadgets';
import CreateURLs from '../CreateURLs';
import ImageInMessage from '../ImageInMessage';
import { defaultTheme } from '../../themes/loot8';
import { SheetManager } from 'react-native-actions-sheet';
import ProfileAvatar from '../ProfileAvatar';
import { getSenderName, getSenderID } from '.';
import { ThreeDotsSvg } from '../../assets/svg/HomeSvgs';

const MESSAGE_READ_MORE_LENGTH = 100;
const MESSAGE_READ_MORE_LINES = 3;

export const PassportReplyItem = ({
  item,
  onMessageMenuClick,
  selectedPassport,
  onSetShowUserInfoModal,
}: {
  item: IMessageList;
  onMessageMenuClick;
  selectedPassport;
  onSetShowUserInfoModal;
}) => {
  const { networkId, staticProvider, wallet, address } = useWeb3AuthContext();
  const dispatch = useAppDispatch();

  const [activePostMessage, setActivePostMessage] = useState(false);
  const [localLike, setLocalLike, localLikeRef] = useState(
    item.likes?.findIndex(p => p.hash?.toLowerCase() == address.toLowerCase()) >
      -1,
  );
  const [likeClicked, setLikeClicked] = React.useState(false);
  const [localLikeLength, setLocalLikeLength] = React.useState(
    item.likes?.length || 0,
  );
  const [showReadMore, setShowReadMore] = useState(false);

  const messageURLs = detectURL(item?.data?.data?.content?.text);

  const multilineLength = item?.data?.data?.content?.text?.trim()?.split('\n')
    ?.length;
  const [joinLines, setJoinLines] = useState(null);

  const handleResponse = (
    response: any,
    messageId: string,
    likeMessage: boolean,
  ) => {
    setActivePostMessage(false);

    if (
      response &&
      response.payload &&
      response.payload.status &&
      response.payload.status == 200
    ) {
      if (likeMessage && !localLikeRef.current) {
        postLikeUnlikeMessage(messageId, false, response.payload.data.itemId);
      } else if (!likeMessage && localLikeRef.current) {
        postLikeUnlikeMessage(messageId, true, null);
      }
    }
  };

  const postLikeUnlikeMessage = async (
    messageId: string,
    likeMessage: boolean,
    likeMessageId: string | null,
  ) => {
    setActivePostMessage(true);

    if (likeMessage) {
      dispatch(
        postMessageLike({
          networkID: networkId,
          provider: staticProvider,
          address: selectedPassport.address,
          wallet,
          messageId,
          chainId: selectedPassport.chainId,
        }),
      ).then((response: any) => {
        handleResponse(response, messageId, likeMessage);
      });
    } else {
      dispatch(
        postMessageUnlike({
          networkID: networkId,
          provider: staticProvider,
          address: selectedPassport.address,
          wallet,
          messageId: likeMessageId,
          parentId: messageId,
          chainId: selectedPassport.chainId,
        }),
      ).then((response: any) => {
        handleResponse(response, messageId, likeMessage);
      });
    }
  };

  useEffect(() => {
    if (likeClicked) {
      if (localLike && !activePostMessage && item) {
        postLikeUnlikeMessage(item.messageId, true, null);
      } else if (!localLike && !activePostMessage && item && item.likes) {
        const userLike = item.likes.find(
          p => p.hash?.toLowerCase() == address.toLowerCase(),
        );
        if (userLike) {
          postLikeUnlikeMessage(item.messageId, false, userLike.messageId);
        }
      }
    }
  }, [likeClicked]);

  const resetClickedFlag = () => {
    setTimeout(() => {
      setLikeClicked(false);
    }, 100);
  };

  const likeMessage = async () => {
    setLocalLike(true);
    setLocalLikeLength(localLikeLength + 1);
    resetClickedFlag();
  };

  const unlikeMessage = async () => {
    setLocalLike(false);
    setLocalLikeLength(localLikeLength - 1);
    resetClickedFlag();
  };

  const onReshareClicked = async () => {
    // setMsgAsRead(parentItem.messageId);
    await SheetManager.show('ReshareModalSheet', {
      payload: {
        selectedPassport: selectedPassport,
        message: item,
        getSenderName: getSenderName,
        getSenderID: getSenderID,
        getTimeTextFromTimestamp: getTimeTextFromTimestamp,
      },
    });
  };

  useEffect(() => {
    // pick first three lines
    if (multilineLength > MESSAGE_READ_MORE_LINES) {
      const splitLines = item?.data?.data?.content?.text?.trim()?.split('\n');
      let lines = [];
      if (splitLines?.length > 0) {
        for (let l = 0; l < MESSAGE_READ_MORE_LINES; l++) {
          lines.push(splitLines[l]);
        }
        setJoinLines(lines?.join('\n'));
      }
    }
  }, []);

  return (
    <View>
      <View style={[{ flexDirection: 'row', marginVertical: 5 }]}>
        <View>
          <Pressable
            onPress={() => {
              onSetShowUserInfoModal(item.user);
              // setMsgAsRead(parentItem.messageId);
            }}>
            <ProfileAvatar
              size={36}
              source={
                item.user &&
                item.user.avatarURI &&
                item.user.avatarURI != '' &&
                item.user.avatarURI != 'no-avatar' &&
                !item.user.avatarURI.includes('ipfs://') &&
                !item.user.avatarURI.includes('assets_avatars_p_') &&
                !item.user.avatarURI.includes('file:///')
                  ? { uri: item.user.avatarURI }
                  : null
              }
              userAddress={item?.user?.wallet}
            />
          </Pressable>
          {/* <Pressable style={{flex:1}}/> */}
        </View>
        <View style={{ flexDirection: 'column', flex: 6, paddingLeft: 10 }}>
          <Pressable
            onLongPress={() => onMessageMenuClick(item)}
            delayLongPress={1000}>
            {/* onPress={() => {setMsgAsRead(parentItem.messageId)}} */}
            {state => (
              <View style={state.pressed && { backgroundColor: '#FFFFFF08' }}>
                <View style={[{ flexDirection: 'row', flex: 1 }]}>
                  <View
                    style={{
                      flex: 1,
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginRight: 3,
                    }}>
                    <Text
                      style={[
                        styles.drawerUserNameLongText,
                        Platform.OS == 'web' ? { wordBreak: 'break-word' } : {},
                      ]}>
                      <Text style={styles.socialMessagingUserNameText}>
                        {getSenderName(item)}
                      </Text>
                      <Text style={styles.socialMessagingUserIDText}>
                        {getSenderID(item)}
                      </Text>
                    </Text>
                  </View>
                  <View
                    style={{
                      flex: 0.18,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    <Text style={styles.socialMessagingTimestampText}>
                      {getTimeTextFromTimestamp(item.data?.senderTimestamp)}
                    </Text>
                    {!item.user ||
                    (item.user && item.user.wallet === ZERO_ADDRESS) ? (
                      <></>
                    ) : (
                      <Pressable
                        hitSlop={{ bottom: 20, left: 20, right: 20, top: 20 }}
                        style={{
                          width: 18,
                          height: 5,
                          justifyContent: 'flex-start',
                          paddingTop: 1.5,
                          paddingLeft: 5,
                          marginLeft: 5,
                        }}
                        onPress={() => {
                          // setMsgAsRead(parentItem.messageId);
                          onMessageMenuClick(item);
                        }}>
                        <ThreeDotsSvg />
                      </Pressable>
                    )}
                  </View>
                </View>
                <View>
                  {
                    messageURLs && messageURLs?.length > 0 ? (
                      // <Text style={styles.socialMessageText}>
                      //     <CreateURLs message={item.data?.data.content?.text} messageId={item.messageId} />
                      // </Text>
                      <>
                        {!showReadMore &&
                        item.data?.data.content?.text.trim() ? (
                          <>
                            {multilineLength > MESSAGE_READ_MORE_LINES ? (
                              <>
                                <Text style={styles.socialMessageText}>
                                  <CreateURLs
                                    key={item.messageId}
                                    message={joinLines}
                                    messageId={item.messageId}
                                  />
                                </Text>
                                <Pressable
                                  onPress={() => {
                                    setShowReadMore(!showReadMore);
                                  }}>
                                  <Text style={styles.socialReadMore}>
                                    {'Read more'}
                                  </Text>
                                </Pressable>
                              </>
                            ) : item.data?.data.content?.text.trim().length <
                              MESSAGE_READ_MORE_LENGTH ? (
                              <Text style={styles.socialMessageText}>
                                <CreateURLs
                                  key={item.messageId}
                                  message={item.data?.data.content?.text}
                                  messageId={item.messageId}
                                />
                              </Text>
                            ) : (
                              <>
                                <Text style={styles.socialMessageText}>
                                  <CreateURLs
                                    key={item.messageId}
                                    message={item.data?.data.content?.text.substring(
                                      0,
                                      MESSAGE_READ_MORE_LENGTH,
                                    )}
                                    messageId={item.messageId}
                                  />
                                </Text>
                                <Pressable
                                  onPress={() => {
                                    setShowReadMore(!showReadMore);
                                  }}>
                                  <Text style={styles.socialReadMore}>
                                    {'Read more'}
                                  </Text>
                                </Pressable>
                              </>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {showReadMore &&
                          messageURLs &&
                          messageURLs?.length > 0 && (
                            <>
                              <Text style={styles.socialMessageText}>
                                <CreateURLs
                                  key={item.messageId}
                                  message={item.data?.data.content?.text}
                                  messageId={item.messageId}
                                />
                              </Text>
                              <Pressable
                                onPress={() => {
                                  setShowReadMore(!showReadMore);
                                }}>
                                <Text style={styles.socialReadMore}>
                                  {'Show less'}
                                </Text>
                              </Pressable>
                            </>
                          )}
                      </>
                    ) : (
                      <>
                        {!showReadMore &&
                        item.data?.data.content?.text.trim() ? (
                          multilineLength > MESSAGE_READ_MORE_LINES ? (
                            <>
                              <Text style={styles.socialMessageText}>
                                {joinLines}
                              </Text>
                              <Pressable
                                onPress={() => {
                                  setShowReadMore(!showReadMore);
                                }}>
                                <Text style={styles.socialReadMore}>
                                  {'Read more'}
                                </Text>
                              </Pressable>
                            </>
                          ) : item.data?.data.content?.text?.length <
                            MESSAGE_READ_MORE_LENGTH ? (
                            <Text style={styles.socialMessageText}>
                              {item.data?.data.content?.text.trim()}
                            </Text>
                          ) : (
                            <>
                              <Text style={styles.socialMessageText}>
                                {`${item.data?.data.content?.text?.substring(
                                  0,
                                  MESSAGE_READ_MORE_LENGTH,
                                )}...`}
                                <Pressable
                                  onPress={() => {
                                    setShowReadMore(!showReadMore);
                                  }}>
                                  <Text style={styles.socialReadMore}>
                                    {'Read more'}
                                  </Text>
                                </Pressable>
                              </Text>
                            </>
                          )
                        ) : (
                          <></>
                        )}
                        {showReadMore && (
                          <>
                            <Text style={styles.socialMessageText}>
                              {item.data?.data.content?.text.trim()}
                            </Text>
                            <Pressable
                              onPress={() => {
                                setShowReadMore(!showReadMore);
                              }}>
                              <Text style={styles.socialReadMore}>
                                {'Show less'}
                              </Text>
                            </Pressable>
                          </>
                        )}
                      </>
                    )
                    // <Text style={styles.socialMessageText}>{item.data?.data.content?.text}</Text>
                  }
                  {messageURLs &&
                    messageURLs?.length > 0 &&
                    messageURLs
                      ?.filter((x, i) => i == 0)
                      .map((url, index) => {
                        return (
                          <URLPreview
                            key={index}
                            url={url}
                            message={item.data?.data.content?.text}
                            children={''}></URLPreview>
                        );
                      })}
                  {item.data?.data?.attachments &&
                  item.data?.data?.attachments?.uri !== '' ? (
                    <ImageInMessage uri={item.data?.data?.attachments?.uri} />
                  ) : (
                    <></>
                  )}
                </View>
              </View>
            )}
          </Pressable>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginVertical: 10,
            }}>
            <Pressable
              style={{
                justifyContent: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}
              onPress={() => {
                setLikeClicked(true);
                if (localLike) {
                  unlikeMessage();
                } else {
                  likeMessage();
                }
              }}>
              <Image
                source={localLike ? likeFill : like}
                style={{
                  width: 14,
                  height: 12,
                  marginRight: 5,
                  tintColor: defaultTheme.PRIMARY_TEXT_COLOR,
                }}
              />
            </Pressable>
            <Pressable
              style={{
                justifyContent: 'center',
                paddingLeft: 5,
                paddingRight: 5,
              }}
              onPress={onReshareClicked}>
              <Image
                source={reshare}
                style={{
                  width: 18,
                  height: 16,
                  marginRight: 5,
                  tintColor: defaultTheme.PRIMARY_TEXT_COLOR,
                }}
                resizeMode="contain"
              />
            </Pressable>
          </View>
          <View style={{ alignItems: 'flex-start' }}>
            <Text style={[styles.socialMessagingMainActionText]}>
              {localLikeLength} likes, {item.shares?.length ?? 0} shares
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};
