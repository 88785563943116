import React, { useEffect, useState } from 'react';
import {
  View,
  Linking,
  StyleSheet,
  SafeAreaView,
  useWindowDimensions,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';
import { Apps } from '../../enums/apps.enum';
import { SELECTED_APP, getAppConfiguration } from '../../appconstants';
import { storeData } from '../../helpers/AppStorage';
import { ScreenName } from '../../enums/screen.enum';
import HOCContainer from '../../components/HOCContainer';
import GradientButton from '../../components/GradientButton';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import MoreGradientTab from '../../components/More/MoreGradientTab.tsx';
import {
  EventsSvg,
  ExpassHolderSvg,
  FeatureOffersSvg,
  FreeFollowerSvg,
  LogoutSvg,
  NewExpassSvg,
  RevenueSvg,
  SaleSvg,
  SwitchSvg,
  TotalCollectibleSvg,
} from '../../assets/svg/EstDashboardSvgs';
import ScreenTitle from '../../components/ScreenTitle';
import { AppEnvironment } from '../../enums/env.enum';
import BackButton from '../components/BackButton';

const numColumns = 2;

function EstPortalDashboard() {
  const navigation = useNavigation();
  const { width: screenWidth } = useWindowDimensions();

  const { setAppSelected, userInfo } = useWeb3AuthContext();

  const [itemWidth, setItemWidth] = useState(
    (screenWidth - (numColumns + 1) * 4) / numColumns,
  );

  const Tabs = [
    {
      id: 1,
      name: 'New ExPasss Minted',
      screen: ScreenName.ORDERS_SCREEN,
      icon: <NewExpassSvg />,
    },
    {
      id: 2,
      name: 'Total Collectible Minted',
      screen: ScreenName.PROFILE_SCREEN,
      icon: <TotalCollectibleSvg />,
    },
    {
      id: 3,
      name: 'Free Followers',
      screen: ScreenName.SCAN_QR,
      icon: <FreeFollowerSvg />,
    },
    {
      id: 4,
      name: 'ExPass Holders',
      screen: ScreenName.CATALOG_TAB,
      icon: <ExpassHolderSvg />,
    },
    {
      id: 5,
      name: 'Feature Offers',
      screen: ScreenName.TERMS_OF_SERVICES,
      icon: <FeatureOffersSvg />,
    },
    {
      id: 6,
      name: 'Events',
      screen: ScreenName.SUPPORT_SCREEN,
      icon: <EventsSvg />,
    },
    {
      id: 7,
      name: 'Highest Sale',
      screen: ScreenName.PRIVACY_NOTICE,
      icon: <SaleSvg />,
    },
    {
      id: 8,
      name: 'Total Revenue',
      key: 'DeactivateAccount',
      icon: <RevenueSvg />,
    },
    {
      id: 9,
      name: 'Switch Fan/Patron',
      key: 'SwitchToPatron',
      icon: <SwitchSvg />,
    },
    {
      id: 10,
      name: 'Logout',
      screen: ScreenName.LOGOUT_SCREEN,
      icon: <LogoutSvg />,
    },
  ];

  const updateItemWidth = () => {
    const newWidth = (screenWidth - (numColumns + 1) * 2) / numColumns;
    setItemWidth(newWidth);
  };

  const handleTabPress = async item => {
    if (item.screen) {
      navigation.navigate(item.screen);
    } else if (!item.screen && item.key && item.key === 'SwitchToPatron') {
      setAppSelected(Apps.PATRON_APP);
      // Set selectedApp value in local storage
      await storeData(SELECTED_APP, Apps.PATRON_APP);
    }
  };

  useEffect(() => {
    if (screenWidth != itemWidth) {
      updateItemWidth();
    }
  }, [screenWidth]);

  const handleRedirectToWebPortal = async () => {
    const appConfig = await getAppConfiguration();
    const isProd = appConfig.env === AppEnvironment.PRODUCTION;

    Linking.openURL(`https://establishment.loot8.${isProd ? 'app' : 'dev'}/`);
  };

  return (
    <SafeAreaView style={styles.container}>
      <BackButton hideBackIcon />
      <FlatList
        data={Tabs}
        style={styles.flatListStyle}
        ListHeaderComponent={
          <>
            <ScreenTitle title="Welcome to the Loot8 CMS Dashboard!" />
            <View style={{ marginVertical: 15 }} />
          </>
        }
        keyExtractor={item => item?.id?.toString()}
        renderItem={({ item }) => (
          <MoreGradientTab
            fullWidth
            item={item}
            gradient={item.name !== 'Logout'}
            gradientFill={item.id === 9}
            bgColor="#F72045"
            onPress={() => {
              handleTabPress(item);
            }}
            disabled={item.id !== 9 && item.id !== 10}
          />
        )}
        contentContainerStyle={styles.tabsContainer}
        columnWrapperStyle={{ justifyContent: 'space-between' }}
        ItemSeparatorComponent={() => <View style={{ marginVertical: 5 }} />}
        scrollEnabled={false}
        numColumns={numColumns}
        ListFooterComponent={
          <GradientButton
            buttonLabel={'Redirect to EST Web Portal'}
            onPress={handleRedirectToWebPortal}
            bordered
          />
        }
        ListFooterComponentStyle={{ marginTop: 50 }}
      />
    </SafeAreaView>
  );
}

export default HOCContainer({
  OriginalComponent: EstPortalDashboard,
});

const styles = StyleSheet.create({
  container: { flex: 1 },
  flatListStyle: { flex: 1, marginTop: 10 },
  headingContainer: {
    alignItems: 'center',
  },
  tabsContainer: {
    flexGrow: 1,
  },
});
