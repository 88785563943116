import React from 'react';
import { Platform, Pressable, Text, View } from 'react-native';
import WebView from 'react-native-webview';
import { IconButton } from 'react-native-paper';

import { defaultTheme } from '../../themes/loot8';
import useIsResponsive from '../../hooks/useIsResponsive';
import { PassportType } from '../../enums/passportCategory.enum';
import { styles } from './styles';
import { CatalogTypes } from '../../enums/catalog.enum';
import ThreeDotsSettingsButton from '../ThreeDotsSettingsButton';
import CachedImage from '../CachedImage';
import { VerifiedSvg } from '../../assets/svg/HomeSvgs';

const PortfolioCard = ({
  item,
  index,
  navigateToCollectibleDetails,
  catalogType,
  onDotsMenuPress,
}) => {
  const showOptionsMenu =
    (item?.marketPlaceConfig &&
      item?.marketPlaceConfig?.allowMarketPlaceOps &&
      item?.marketPlaceConfig?.publicTradeability) ||
    item?.passportType === PassportType.SUBSCRIBED;

  const isResponsive = useIsResponsive();
  const htmlStyles = { body: { overflow: 'hidden' } };
  const MP4IconButtonMemo = React.memo(MP4IconButton);

  return (
    <Pressable
      onPress={() => navigateToCollectibleDetails(item)}
      style={[styles.container, { flex: isResponsive ? 0.49 : 1 }]}>
      {(catalogType === CatalogTypes.COLLECTIBLES ||
        (catalogType === CatalogTypes.PASSPORT && showOptionsMenu)) &&
        !item.fromExternalWallet && (
          <ThreeDotsSettingsButton
            extraStyles={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
            onPress={() => {
              onDotsMenuPress(item);
            }}
          />
        )}
      {catalogType == CatalogTypes.COLLECTIBLES ? (
        <>
          {/* <RenderCachedImage
            isResponsive={false}
            resizeMode={'cover'}
            tileWidth={76}
            tileHeight={54}
            item={item}
            renderContent={
              <View
                style={{
                  flex: 1,
                  backgroundColor: 'rgba(0,0,0,.2)',
                }}>
                {item.isMp4Collectible && <MP4IconButtonMemo />}
              </View>
            }
          /> */}
          {item?.animationUrl ? (
            <View style={styles.webview}>
              <WebView
                tagsStyles={htmlStyles}
                source={{ uri: item?.animationUrl }}
                style={{ flex: 1, overflow: 'hidden' }}
                containerStyle={{ flex: 1, overflow: 'hidden' }}
                scalesPageToFit={Platform.OS === 'android' ? true : false}
                bounces={false}
                contentMode="recommended"
                scrollEnabled={false}
                nestedScrollEnabled={false}
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                androidLayerType={'software'}
                automaticallyAdjustContentInsets={false}
                contentInset={{
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              />
            </View>
          ) : (
            <RenderCachedImage
              isResponsive={false}
              resizeMode={'cover'}
              tileWidth={77}
              tileHeight={54}
              item={item}
              renderContent={
                <View
                  style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,.2)',
                  }}>
                  {item.isMp4Collectible && <MP4IconButtonMemo />}
                </View>
              }
            />
          )}
        </>
      ) : (
        <>
          {/* Passport List */}
          {catalogType === CatalogTypes.PASSPORT && showOptionsMenu ? (
            <RenderCachedImage
              isResponsive={false}
              resizeMode={'cover'}
              tileWidth={66}
              tileHeight={54}
              item={item}
              renderContent={
                <View
                  style={{
                    flex: 1,
                    backgroundColor: 'rgba(0,0,0,.2)',
                  }}>
                  {item.isMp4Collectible && <MP4IconButtonMemo />}
                </View>
              }
            />
          ) : (
            <RenderCachedImage
              isResponsive={false}
              resizeMode={'cover'}
              tileWidth={66}
              tileHeight={54}
              item={item}
              renderContent={
                <View
                  style={{
                    backgroundColor: 'rgba(0,0,0,.1)',
                    flex: 1,
                  }}>
                  {item.isMp4Collectible && <MP4IconButtonMemo />}
                  {catalogType == 'Events' &&
                    item.catalogCount &&
                    Number(item.catalogCount) > 0 &&
                    !item.is3rdPartyCollectible && (
                      <View
                        style={{
                          paddingHorizontal: 5,
                          width: '90%',
                          height: '18%',
                          alignSelf: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                          bottom: '2%',
                          position: 'absolute',
                          backgroundColor: '#AF1265',
                          borderRadius: 20,
                          opacity: 0.8,
                        }}>
                        <Text
                          numberOfLines={1}
                          adjustsFontSizeToFit
                          style={{
                            color: defaultTheme.TEXT_COLOR_SECONDARY,
                            fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                            fontSize: defaultTheme.FONT_SIZE_XXSMALL,
                          }}>
                          You have got
                          <Text
                            numberOfLines={1}
                            adjustsFontSizeToFit
                            style={{
                              fontSize: defaultTheme.FONT_SIZE_SMALL,
                              fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
                            }}>
                            {<> {item.catalogCount} </>}
                          </Text>
                          so far. Keep collecting!
                        </Text>
                      </View>
                    )}
                </View>
              }
            />
          )}
        </>
      )}

      <View style={{ marginLeft: 10, flex: 1 }}>
        <Text style={styles.title} numberOfLines={1}>
          {catalogType == CatalogTypes.COLLECTIBLES
            ? `${item?.name} ${item?.subTitle} ${
                item?.fromExternalWallet ? 'External' : ''
              }`
            : `${item?.name?.trim()} #${
                item?.subTitle?.trim() ? item.subTitle.split('#')[1] : ''
              }`}{' '}
          {item?.thirdPartyVerifiedURL?.length > 0 && <VerifiedSvg />}
        </Text>
        {catalogType === CatalogTypes.PASSPORT && (
          <Text style={styles.subtitle}>{item.category}</Text>
        )}
      </View>
    </Pressable>
  );
};

export default PortfolioCard;

const RenderCachedImage = ({
  item,
  isResponsive,
  type = undefined,
  hovered = undefined,
  tileWidth,
  tileHeight,
  resizeMode,
  renderContent,
}) => {
  return (
    <CachedImage
      isBackground
      isResponsive={isResponsive}
      item={item}
      type={type}
      hovered={hovered}
      noImageOnError={true}
      source={{
        uri: item.isMp4Collectible
          ? item?.thumbnail
          : item?.optimizedImage !== ''
          ? item?.optimizedImage
          : item?.image,
      }}
      isThumbnailImage={item?.thumbnailImage !== '' ? true : false}
      imageStyle={{ borderRadius: defaultTheme.CONTENT_RADIUS }}
      style={{
        maxWidth: isResponsive ? '100%' : tileWidth - 12,
        minHeight: tileHeight !== null ? tileHeight : tileWidth - 60,
        aspectRatio: tileHeight !== null ? tileWidth / tileHeight : 41 / 25,
        borderRadius: defaultTheme.CONTENT_RADIUS,
        borderWidth: 1,
        borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
      }}
      contentFit={'cover'}
      imageSize={item?.imageSize}
      thumbnailImageSize={item?.thumbnailImageSize}
      optimizedImageSize={item?.optimizedImageSize}>
      {renderContent}
    </CachedImage>
  );
};

const MP4IconButton = () => {
  return (
    <View
      style={{
        position: 'absolute',
        top: '30%',
        width: 25,
        height: 25,
        borderRadius: 50,
        backgroundColor: '#FFFFFF',
        opacity: 0.4,
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}>
      <IconButton
        icon="play"
        iconColor={'#000'}
        size={25}
        style={{ alignSelf: 'center', top: -14 }}
      />
    </View>
  );
};
