import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  View,
  Text,
  ImageBackground,
  TouchableOpacity,
  Alert,
  Pressable,
  ScrollView,
  Image,
} from 'react-native';
import { IOffer } from '../../interfaces/IOffer.interface';
import ModalComponent from '../Modal';
import { Button } from 'react-native-paper';

import styles from '../../styles';
import { LinearGradient } from 'expo-linear-gradient';
import token_icon from '../../assets/token_icon.png';
import cash_icon from '../../assets/cash_icon.png';
import pay_icon from '../../assets/pay_icon.png';
import { defaultTheme } from '../../themes/loot8';
import { CloseIcon } from '../HelperComponent';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { loadPassportOfferList, mintOffers } from '../../slices/OfferSlice';
import {
  getPatronOfferActive,
  loadParticularOrderDetail,
} from '../../slices/OrderSlice';
import { useAppSelector } from '../../hooks';
import InfoLoader from '../Loader/InfoLoader';
import { wait } from '../../helpers/ipfs';
import CachedImage from '../CachedImage';
import { ScreenName } from '../../enums/screen.enum';
import { loadCatalogOffers } from '../../slices/CatalogSlice';
import { storeData } from '../../helpers/AppStorage';
import {
  APP_STORAGE_GET_COLLECTIBLEDETAILS,
  addresses,
} from '../../appconstants';
import AppLoaderComponent from '../Loader';
import { getTruncatedName } from '../../helpers/Gadgets';
import { Dispatcher__factory, Loot8Collection__factory } from '../../typechain';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { Ionicons } from '@expo/vector-icons';
import { isLocationAvailable } from '../../slices/helpers';

const OfferDetail = ({ route, navigation }) => {
  const {
    name,
    price,
    type,
    tokensEarned,
    details,
    image,
    offerAddress,
    isCoupon,
    tokenId,
    navigationFrom,
    maxBalance,
    maxPurchase,
    imageSize,
    area,
  } = route.params.offer;

  const {
    networkId,
    staticProvider,
    address,
    wallet,
    userInfo,
    SelectedPassport,
  } = useWeb3AuthContext();
  const dispatch = useDispatch<AppDispatch>();

  const [showCashModal, setShowCashModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setISAlert] = useState(false);
  const [failedMessage, showFailedMessage] = useState(false);
  const [showRedeemCashModal, setShowRedeemCashModal] = useState(false);
  const [isRedeemLoading, setRedeemIsLoading] = useState(false);
  const [isRedeemAlert, setISRedeemAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const [isRadius, setIsRadius] = useState(false);

  useEffect(() => {
    getPassportLocationDistance();
  }, []);

  const getPassportLocationDistance = () => {
    if (isLocationAvailable(currenLocation, area)) setIsRadius(true);
    else setIsRadius(false);
  };

  const hideCashModalPopup = () => {
    setShowCashModal(false);
  };
  const hideRedeemModalPopup = () => {
    setShowRedeemCashModal(false);
  };
  const onCashOrderPlaced = async () => {
    setShowCashModal(false);
    await wait(100);
    setIsLoading(true);
    const success: boolean = await processCashOrder();
    if (success) {
      navigation.navigate('My Orders', { screen: 'Orders' });
    } else {
      setIsLoading(false);
      await 100;
      showFailedMessage(true);
    }
    setIsLoading(false);
  };

  const onRedeemOrderPlaced = async () => {
    setShowRedeemCashModal(false);
    await wait(100);
    setRedeemIsLoading(true);
    const success: boolean = await processRedeemOrder();
    if (success) {
      await storeData(APP_STORAGE_GET_COLLECTIBLEDETAILS(offerAddress), null);
      await dispatch(
        loadCatalogOffers({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
          userInfo,
          isCache: false,
        }),
      );
      dispatch(
        loadPassportOfferList({
          networkID: networkId,
          provider: staticProvider,
          passport: SelectedPassport,
          wallet,
          address,
        }),
      );

      // navigation.navigate('My Orders', { screen: "Orders" });
      navigation.navigate(ScreenName.CATALOG_TAB, {
        screen: ScreenName.CATALOG_DETAILS,
        params: {
          catalog: null,
          availableCatalogs: null,
          type: 'Collectibles',
          from: navigationFrom,
          extraParams: { tokenId: tokenId, offerAddress: offerAddress },
        },
        initial: false,
      });
    } else {
      setRedeemIsLoading(false);
      await 100;
      showFailedMessage(true);
    }
    setRedeemIsLoading(false);
  };

  const CheckCash = async () => {
    //this need to call validate once again the event
    setRedeemIsLoading(true);
    try {
      let isActiveOffer = await getPatronOfferActive({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        offerAddress,
      });
      LogToLoot8Console('isActiveOffer', isActiveOffer);
      if (isActiveOffer) {
        setAlertText(
          'Sorry! Your previous order is pending for fulfillment.\n Please get that served, cancelled or wait till it expires before making another reservation',
        );
        setISAlert(true);
        return;
      } else if (maxBalance || maxPurchase) {
        if (maxPurchase) {
          const dispatcher = Dispatcher__factory.connect(
            addresses[networkId].OrderDispatcher,
            staticProvider,
          );
          const offerContext = await dispatcher.offerContext(offerAddress);
          if (
            +offerContext.maxPurchase &&
            +offerContext.totalPurchases +
              +offerContext.activeReservationsCount >=
              +offerContext.maxPurchase
          ) {
            setAlertText(
              'Sorry! This order cannot be placed. Maximum order limit for the offer is reached.',
            );
            setISAlert(true);
            return;
          }
        }
        if (maxBalance) {
          const offerContract = Loot8Collection__factory.connect(
            offerAddress,
            staticProvider,
          );
          const patronBalance = await offerContract.balanceOf(address);
          if (patronBalance >= maxBalance) {
            setAlertText(
              'Sorry! This order cannot be placed. Your maximum order limit for the offer is reached.',
            );
            setISAlert(true);
            return;
          }
        }
      }
    } finally {
      setRedeemIsLoading(false);
    }
    setShowCashModal(true);
  };

  const redeemOffer = async () => {
    //this need to call validate once again the event
    setRedeemIsLoading(true);
    let isActiveOffer = await getPatronOfferActive({
      networkID: networkId,
      provider: staticProvider,
      address,
      wallet,
      offerAddress,
    });
    LogToLoot8Console('isActiveOffer', isActiveOffer);
    setRedeemIsLoading(false);
    if (isActiveOffer) {
      setISRedeemAlert(true);
      return;
    }
    setShowRedeemCashModal(true);
  };

  const processCashOrder = async () => {
    const data = await dispatch(
      mintOffers({
        networkID: networkId,
        provider: staticProvider,
        offerAddress: offerAddress,
        cashPayment: true,
        wallet: wallet,
        // offerType: type,
        address: address,
        passportAddress: SelectedPassport?.address,
        offerId: tokenId,
      }),
    );

    if (data?.payload !== 0) {
      await dispatch(
        loadParticularOrderDetail({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
          reservationID: data?.payload,
        }),
      );
      return true;
    }
    return false;
  };

  const processRedeemOrder = async () => {
    const data = await dispatch(
      mintOffers({
        networkID: networkId,
        provider: staticProvider,
        offerAddress: offerAddress,
        cashPayment: false,
        wallet: wallet,
        // offerType: type,
        address: address,
        passportAddress: SelectedPassport?.address,
        offerId: tokenId,
      }),
    );

    if (data?.payload !== 0) {
      // await dispatch(loadParticularOrderDetail({ networkID: networkId, provider: staticProvider, address, wallet, reservationID: data?.payload }));
      return true;
    }
    return false;
  };

  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
      style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ ...styles.mainContainer, padding: 0 }}>
          <View style={[styles.offerContainer, styles.maxWidthAdjust]}>
            {/* Header Container */}
            <View
              style={{
                ...styles.contentHeader,
                justifyContent: 'center',
                height: 70,
                width: '100%',
              }}>
              <Pressable
                onPress={() => navigation.goBack()}
                style={styles.headerBackButton}>
                <Ionicons
                  name="arrow-back"
                  size={30}
                  color={defaultTheme.CONTENT_NAME_BACKGROUND_COLOR}
                />
              </Pressable>

              <View
                style={{
                  width: '90%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={{ ...styles.profileTitleText, marginLeft: '-5%' }}>
                  {'STANDARD OFFER DETAIL'}
                </Text>
              </View>
            </View>

            <View style={styles.eventsTileContainer}>
              <View style={styles.eventsContainer}>
                <View style={styles.eventsCaptionContainer}>
                  <Text style={styles.eventsCaption}>
                    {getTruncatedName(name, 45)}
                  </Text>
                </View>

                <View style={styles.eventsDataContainer}>
                  <View style={styles.eventsImageContainer}>
                    <CachedImage
                      isBackground={false}
                      source={{ uri: image }}
                      style={styles.eventsimage}
                      contentFit="contain"
                      imageSize={imageSize}></CachedImage>
                  </View>
                  <LinearGradient
                    colors={[
                      defaultTheme.GRADIENT_COLOR2,
                      defaultTheme.GRADIENT_COLOR1,
                    ]}
                    start={{ x: 0, y: 0.3 }}
                    end={{ x: 0, y: 1.3 }}
                    style={styles.eventsValueContainer}>
                    <View style={styles.eventsValue}>
                      <View style={styles.eventsPriceContainer}>
                        <Text
                          style={[
                            styles.eventsPriceCurrency,
                            { fontSize: defaultTheme.FONT_SIZE_LARGE },
                          ]}>
                          $
                        </Text>
                        <Text style={styles.eventsPrice}>{price}</Text>
                      </View>
                      <View style={styles.eventsTokenEarnedContainer}>
                        <Text
                          style={[
                            styles.eventsTokenEarnedValue,
                            { fontSize: defaultTheme.FONT_SIZE_LARGE },
                          ]}>
                          {' '}
                          {Number(tokensEarned).toFixed(2)}{' '}
                        </Text>
                        <Image
                          source={token_icon}
                          style={{ height: 25, width: 25 }}
                        />
                      </View>
                    </View>
                  </LinearGradient>
                </View>
                {isCoupon ? (
                  <View style={styles.offersBottomBarContainer}>
                    <Pressable
                      style={[
                        styles.offersBottomButtonContainer,
                        {
                          backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
                          width: 135,
                        },
                      ]}
                      onPress={() => redeemOffer()}>
                      <Image source={cash_icon} style={styles.cashPayIcon} />
                      <Text
                        style={[
                          styles.offersBottomButtonText,
                          { color: '#FFF' },
                        ]}>
                        REDEEM
                      </Text>
                    </Pressable>
                  </View>
                ) : (
                  <View style={styles.offersBottomBarContainer}>
                    <Pressable
                      onPress={() => (isRadius ? CheckCash() : {})}
                      style={[
                        styles.offersBottomButtonContainer,
                        {
                          backgroundColor: isRadius
                            ? defaultTheme.ACTIVE_PRIMARY_COLOR
                            : '#DBE2ED',
                        },
                      ]}>
                      <Image source={cash_icon} style={styles.cashPayIcon} />
                      <Text
                        style={{
                          ...styles.offersBottomButtonText,
                          color: isRadius ? '#FFF' : '#BDC6D5',
                        }}>
                        CASH
                      </Text>
                    </Pressable>
                    <Pressable
                      disabled={true}
                      style={[
                        styles.offersBottomButtonContainer,
                        { width: 135 },
                      ]}>
                      <Image source={pay_icon} style={styles.onlinePayIcon} />
                      <Text
                        style={[
                          styles.offersBottomButtonText,
                          { paddingLeft: 5 },
                        ]}>
                        ONLINE
                      </Text>
                      {/* <View>
                                  <Text numberOfLines={1} adjustsFontSizeToFit style={{ alignSelf: 'center', lineHeight: 12, opacity: 1, fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD, color: '#BDC6D5', fontSize: defaultTheme.FONT_SIZE_XXXSMALL }}>
                                    COMING SOON
                                  </Text>
                                </View> */}
                    </Pressable>
                  </View>
                )}
              </View>
            </View>

            <View style={styles.offersDetailContainer}>
              <Text style={styles.offersDetailValue}>{details}</Text>
            </View>
          </View>
          {showCashModal ? (
            <ModalComponent
              showModal={showCashModal}
              headerText="Confirm Reservation"
              dismissable={false}>
              <View style={{ paddingTop: 30 }}>
                <Text style={styles.modalTextStyle}>
                  Please confirm your reservation
                </Text>
                <Text style={styles.modalSecondaryText}>
                  {name} |{' '}
                  {price && price.toString() === 'free' ? 'Free' : `$${price}`}
                </Text>
              </View>
              <View style={styles.modalButtonContainer}>
                <Button
                  onPress={onCashOrderPlaced}
                  style={styles.modalYesButtonStyle}
                  labelStyle={styles.modalYesButtonLabelStyle}>
                  CONFIRM
                </Button>
                <Button
                  onPress={() => hideCashModalPopup()}
                  style={styles.modalNoButtonStyle}
                  labelStyle={styles.modalNoButtonLabelStyle}>
                  CANCEL
                </Button>
              </View>
            </ModalComponent>
          ) : (
            <></>
          )}
          {showRedeemCashModal ? (
            <ModalComponent
              showModal={showRedeemCashModal}
              headerText="Confirm Redemption"
              dismissable={false}>
              <View style={{ paddingTop: 30 }}>
                <Text style={styles.modalTextStyle}>
                  Please confirm offer redemption
                </Text>
                <Text style={styles.modalSecondaryText}>
                  {name} |{' '}
                  {price && price.toString() === 'free' ? 'Free' : `$${price}`}
                </Text>
              </View>
              <View style={styles.modalButtonContainer}>
                <Button
                  onPress={onRedeemOrderPlaced}
                  style={styles.modalYesButtonStyle}
                  labelStyle={styles.modalYesButtonLabelStyle}>
                  Confirm
                </Button>
                <Button
                  onPress={() => hideRedeemModalPopup()}
                  style={styles.modalNoButtonStyle}
                  labelStyle={styles.modalNoButtonLabelStyle}>
                  Cancel
                </Button>
              </View>
            </ModalComponent>
          ) : (
            <></>
          )}
          {isLoading ? (
            <InfoLoader
              text1={'Hey! Please hold on..'}
              text2={'We are placing your order.'}
            />
          ) : null}

          {isAlert && (
            <ModalComponent
              showModal={isAlert}
              dismissable={false}
              enableHeader={false}>
              <View
                style={{
                  paddingTop: 25,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingBottom: 15,
                }}>
                <Text style={styles.modalTextStyle}>{alertText}</Text>
              </View>
              <View
                style={[
                  styles.modalButtonContainer,
                  { justifyContent: 'center' },
                ]}>
                <Button
                  onPress={() => {
                    setISAlert(false);
                    setAlertText('');
                  }}
                  style={styles.modalYesButtonStyle}
                  labelStyle={styles.modalYesButtonLabelStyle}>
                  OK
                </Button>
              </View>
            </ModalComponent>
          )}
          {isRedeemAlert && (
            <ModalComponent
              showModal={isRedeemAlert}
              dismissable={false}
              enableHeader={false}>
              <View
                style={{
                  paddingTop: 25,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingBottom: 15,
                }}>
                <Text style={styles.modalTextStyle}>
                  {'The offer is already redeemed!'}
                </Text>
              </View>
              <View
                style={[
                  styles.modalButtonContainer,
                  { justifyContent: 'center' },
                ]}>
                <Button
                  onPress={() => setISRedeemAlert(false)}
                  style={styles.modalYesButtonStyle}
                  labelStyle={styles.modalYesButtonLabelStyle}>
                  OK
                </Button>
              </View>
            </ModalComponent>
          )}
          {failedMessage && (
            <ModalComponent
              showModal={failedMessage}
              dismissable={true}
              enableHeader={false}
              onDismiss={() => showFailedMessage(false)}
              needCloseButton={true}>
              <View
                style={{
                  paddingTop: 25,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingBottom: 25,
                }}>
                <Text style={styles.modalNormalTextStyle}>
                  {'Sorry! Your order could not be placed.\nPlease try again.'}
                </Text>
              </View>
            </ModalComponent>
          )}
        </View>
      </ScrollView>
      {isRedeemLoading && <AppLoaderComponent />}
    </LinearGradient>
  );
};

export default OfferDetail;
