import React, { useEffect, useRef } from 'react';
import { View, Image, Text, ScrollView } from 'react-native';
import styles from '../../styles';
import { CollectionType } from '../../enums/collection.enum';
import { OfferType } from '../../enums/offers.enum';
import CachedImage from '../CachedImage';
import { ResizeMode, Video } from 'expo-av';
import { IconButton } from 'react-native-paper';
import { defaultTheme } from '../../themes/loot8';
import varifiedAccountTick from '../../assets/verified_account.png';

const MapParameterItem = ({ item, itemIndex, customMessage = null }) => {
  const scrollRef = useRef<ScrollView>(null);

  const playbackInstance = React.useRef(null);
  const [playbackInstanceInfo, setPlaybackInstanceInfo] = React.useState({
    position: 0,
    duration: 0,
    state: 'Buffering',
    positionMillis: 0,
  });

  useEffect(() => {
    return () => {
      if (playbackInstance.current) {
        playbackInstance.current.setStatusAsync({
          shouldPlay: false,
        });
      }
    };
  }, []);

  const togglePlay = async () => {
    const shouldPlay = playbackInstanceInfo.state !== 'Playing';
    if (playbackInstance.current !== null) {
      await playbackInstance.current.setStatusAsync({
        shouldPlay,
        ...(playbackInstanceInfo.state === 'Ended' && { positionMillis: 0 }),
      });
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        positionMillis:
          playbackInstanceInfo.state === 'Ended'
            ? 0
            : playbackInstanceInfo.positionMillis,
        state: playbackInstanceInfo.state === 'Playing' ? 'Paused' : 'Playing',
      });
    }
  };

  const stopVideo = async () => {
    if (playbackInstance.current) {
      playbackInstance.current.setStatusAsync({
        shouldPlay: false,
        isPlaying: false,
        state: 'Ended',
        didJustFinish: true,
        positionMillis: -1,
        durationMillis: 0,
      });
    }
  };

  const updatePlaybackCallback = status => {
    if (status.isLoaded) {
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        position: status.positionMillis,
        duration: status.durationMillis || 0,
        state: status.didJustFinish
          ? 'Ended'
          : status.isBuffering
            ? 'Buffering'
            : status.isPlaying
              ? 'Playing'
              : 'Paused',
      });
    } else {
      if (status.isLoaded === false && status.error) {
        const errorMsg = `Encountered a fatal error during playback: ${status.error}`;
      }
    }
  };

  useEffect(() => {
    const scrollToTop = () => {
      scrollRef?.current?.scrollTo({
        y: 0,
        animated: true,
      });
    };
    scrollToTop();
  }, [item]);

  return (
    <View style={[styles.mapParametersCard]}>
      <View>
        <View style={styles.mapParameterContentHeader}>
          {item?.type === CollectionType.PASSPORT && (
            <Text style={styles.mapContentHeaderText}>{'Passport'}</Text>
          )}
          {item?.type === CollectionType.COLLECTION && (
            <Text style={styles.mapContentHeaderText}>{'Collectible'}</Text>
          )}
          {item?.type === CollectionType.OFFER &&
            item?.offerType === OfferType.FEATURED && (
              <Text style={styles.mapContentHeaderText}>
                {'Featured Offer'}
              </Text>
            )}
          {item?.type === CollectionType.OFFER &&
            item?.offerType === OfferType.REGULAR && (
              <Text style={styles.mapContentHeaderText}>{'Offer'}</Text>
            )}
          {item?.type === CollectionType.TICKET && (
            <Text style={styles.mapContentHeaderText}>{'Ticket'}</Text>
          )}
        </View>
      </View>
      <View style={styles.mapParameterInnerContainer}>
        <ScrollView
          style={styles.mapParameterScrollView}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          ref={scrollRef}>
          <View style={styles.mapParameterItemCaption}>
            <Text style={styles.mapContentHeaderTextMediumSecondary}>
              {item.title}
            </Text>
            {item?.type === CollectionType.PASSPORT &&
              item?.thirdPartyVerifiedURL?.length > 0 && (
                <View style={styles.verifiedAccountTickContainer}>
                  <Image
                    source={varifiedAccountTick}
                    resizeMode="contain"
                    style={styles.verifiedAccountTickImage}
                  />
                  <Text style={styles.verifiedAccountTickTitle}>
                    {'Verified'}
                  </Text>
                </View>
              )}
          </View>
          <View style={styles.mapParameterScrollViewContainer}>
            {!item.isMp4Collectible && (
              <CachedImage
                isBackground={false}
                source={{
                  uri: item.isMp4Collectible ? item.thumbnail : item.image,
                }}
                style={styles.mapParameterScrollViewCachedImage}
                contentFit="contain"
                imageSize={item?.imageSize}
              />
            )}
            {item.isMp4Collectible && (
              <View style={styles.mapParameterVideoContainer}>
                <View style={styles.mapParameterVideoInnerContainer}>
                  <Video
                    ref={playbackInstance}
                    style={styles.mapParameterVideo}
                    source={{ uri: item?.video }}
                    //useNativeControls
                    resizeMode={ResizeMode.CONTAIN}
                    onPlaybackStatusUpdate={updatePlaybackCallback}
                    videoStyle={styles.mapParameterVideoStyle}
                  />
                </View>

                <View style={styles.mapParameterVideoButtonContainer}>
                  <View style={styles.mapParameterVideoButton}>
                    <IconButton
                      icon={
                        playbackInstanceInfo.state === 'Playing'
                          ? 'pause'
                          : 'play'
                      }
                      size={30}
                      style={{ margin: -7 }}
                      iconColor={'#FFFFFF'}
                      onPress={() => {
                        togglePlay();
                      }}
                    />
                    <IconButton
                      icon="stop"
                      size={30}
                      iconColor={'#FFFFFF'}
                      style={{ margin: -7 }}
                      onPress={() => {
                        stopVideo();
                      }}
                    />
                  </View>
                </View>
              </View>
            )}
          </View>
          <View
            style={[
              styles.mapParameterConstraintDetail,
              { paddingTop: 10, paddingBottom: 10 },
            ]}>
            {customMessage && (
              <Text
                style={[
                  styles.mapParameterDetailsText,
                  {
                    fontSize: defaultTheme.FONT_SIZE_11,
                    lineHeight: 15,
                    textAlign: 'justify',
                    paddingBottom: 10,
                  },
                ]}>{`${customMessage}`}</Text>
            )}
            {item?.constraintList?.length > 0 &&
              item?.constraintList?.map((item, index) => {
                return (
                  <View key={index} style={styles.mapMultiParameterContiner}>
                    <View>
                      <Text style={[styles.mapParameterDetailsText]}>
                        {'\u2022 '}
                      </Text>
                    </View>
                    <Text
                      style={[
                        styles.mapParameterDetailsText,
                      ]}>{`${item}`}</Text>
                  </View>
                );
              })}
            {item?.constraintList?.length == 0 && (
              <Text
                style={[
                  styles.mapParameterDetailsText,
                ]}>{`\u2022 Just login and get the drop!`}</Text>
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default MapParameterItem;
