import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { defaultTheme } from '../../../themes/loot8';
import { StyleSheet, View } from 'react-native';

interface Props {
  children: any;
}
export const IconBox: React.FC<Props> = ({ children }) => {
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR2, defaultTheme.GRADIENT_COLOR1]}
      end={{ x: 0.002, y: 0.02 }}
      start={{ x: 0.002, y: 0.08 }}
      style={styles.container}>
      {[<View style={styles.iconContainer}>{children}</View>]}
    </LinearGradient>
  );
};

export default IconBox;

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
  },
  iconContainer: {
    height: 26,
    width: 26,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
