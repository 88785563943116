import React, { useEffect, useState } from 'react';
import TypoIcon from 'react-native-vector-icons/Entypo';
import LineIcon from 'react-native-vector-icons/SimpleLineIcons';
import {
  View,
  Image,
  FlatList,
  Dimensions,
  StyleSheet,
  RefreshControl,
  ActivityIndicator,
} from 'react-native';
import { useDispatch } from 'react-redux';
import { Text } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';

import styles from '../styles';
import { AppDispatch } from '../store';
import { useAppSelector } from '../hooks';
import { defaultTheme } from '../themes/loot8';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { TransactionDetail } from '../interfaces/IOffer.interface';
import {
  loadOrderDetail,
  UpdateParticularOrderDetail,
} from '../slices/OrderSlice';
import { ModalDisconnected } from '../components/Modal/DisconnectedModal';
import { wait } from '../helpers/ipfs';
import {
  clearCatalog,
  formatTimestamp,
  getTruncatedName,
  formatPriceUpToTrillion,
} from '../helpers/Gadgets';
import EmptyList from '../components/EmptyList';
import { TABLET_DIMENTION } from '../appconstants';
import HOCContainer from '../components/HOCContainer';
import { reloadCatEventList } from '../slices/CatalogSlice';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';

import tokenLogo from '../assets/loot8-token.png';

const getOrderStatus = (item: TransactionDetail) => {
  if (!item.isTransaction) return { status: 'Failed', color: '#D32E2E' };
  else if (item.isCancel) return { status: 'Canceled', color: '#D32E2E' };
  else if (item.isServed)
    return { status: 'Collection Added', color: '#5B9A60' };
  else if (item.isExpiry) return { status: 'Expired', color: '#D32E2E' };
  else if (item.isCashPayment)
    return {
      status: 'Reserved',
      color: defaultTheme.ACTIVE_PRIMARY_COLOR_DARKEN,
    };
  else if (!item.isCashPayment) return { status: 'Paid', color: '#C63FB4' };
  else if (item.isCoupon)
    return {
      status: 'Redemption Requested',
      color: defaultTheme.ACTIVE_PRIMARY_COLOR_DARKEN,
    };
};

const { width } = Dimensions.get('window');

const orderNameWidth = (item: TransactionDetail) => {
  let len = getOrderStatus(item).status.length;
  if (len < 12)
    if (width > TABLET_DIMENTION.width) return '80%';
    else return '70%';
  else if (width > TABLET_DIMENTION.width) return '70%';
  else return '50%';
};

const renderItem = ({ item }: { item: TransactionDetail }) => {
  return (
    <>
      <View key={item.transactionId} style={[styles.orderListContainer]}>
        <View style={[styles.orderHeader, { minHeight: 39 }]}>
          <View style={[styles.orderTitle, { maxWidth: orderNameWidth(item) }]}>
            <Text
              numberOfLines={1}
              adjustsFontSizeToFit
              style={styles.orderNameText}>
              {getTruncatedName(item.drinkName, 25)}
            </Text>
          </View>
          <View
            style={[
              { backgroundColor: getOrderStatus(item).color, maxWidth: 160 },
              styles.orderStatus,
            ]}>
            <Text
              adjustsFontSizeToFit
              numberOfLines={1}
              style={styles.orderStatusText}>
              {getOrderStatus(item).status}
            </Text>
          </View>
        </View>
        <LinearGradient
          colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
          start={{ x: 0, y: 0.5 }}
          end={{ x: 0.9, y: 0.1 }}>
          <View style={[styles.orderTile]}>
            <Image
              source={{ uri: item.drinkImage }}
              resizeMode="contain"
              style={{ flex: 1, aspectRatio: 1.35 / 1 }}
            />
            <View style={[styles.orderTileContainer, { flex: 1 }]}>
              <View>
                <Text
                  numberOfLines={1}
                  adjustsFontSizeToFit
                  style={styles.quantity}>
                  {Number(item.price) > 0
                    ? formatPriceUpToTrillion(item.price)
                    : 'Free'}
                </Text>
              </View>
              <Image
                source={tokenLogo}
                style={{
                  width: 22,
                  height: 22,
                  marginHorizontal: 5,
                  marginBottom: 5,
                  alignItems: 'flex-start',
                }}
                resizeMode="contain"
              />
            </View>
          </View>
        </LinearGradient>
        <View style={styles.orderSubDetail}>
          <Text style={styles.subDetailText}>{item.entityName}</Text>
          <Text style={styles.subDetailText}>{'Qty: ' + item.quantity}</Text>
        </View>
        <View style={styles.orderDetailContainer}>
          <TypoIcon
            name="flow-line"
            color={'#FFFFFF'}
            size={30}
            style={{ paddingVertical: 5 }}></TypoIcon>
          <View
            style={{
              paddingVertical: 3,
              flexDirection: 'column',
              width: '85%',
            }}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
              <View style={{ width: '95%' }}>
                <Text
                  numberOfLines={1}
                  style={{
                    color: '#FFFFFF',
                    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
                  }}>
                  {' '}
                  {'Order # : ' + item.transactionId}
                </Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <LineIcon
                name="clock"
                color={'#FFFFFF'}
                size={15}
                style={{ marginBottom: 3 }}
              />
              <Text style={{ color: '#FFFFFF', marginLeft: 4 }}>
                {formatTimestamp(item.date)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </>
  );
};

function TransactionScreen({ navigation }) {
  const { networkId, staticProvider, address, wallet, connected, userInfo } =
    useWeb3AuthContext();
  const transactionsData = useAppSelector(state => state.UserOrders.UserOrders);
  const loadingOrder = useAppSelector(state => state.UserOrders.loading);
  const [refreshing, setRefreshing] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>();

  // clear catalog items
  const clearCatalogList = async () => {
    await clearCatalog('Events');
    await dispatch(
      reloadCatEventList({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        userInfo,
      }),
    );
  };

  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    await dispatch(
      loadOrderDetail({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
      }),
    ).then(() => setRefreshing(false));
    await clearCatalogList();
  }, []);

  useEffect(() => {
    const loadOrderContent = async () => {
      if (
        navigation.isFocused() &&
        connected &&
        wallet &&
        transactionsData &&
        transactionsData?.length > 0 &&
        !loadingOrder
      ) {
        LogToLoot8Console('UpdateParticularOrderDetail refresh');
        await dispatch(
          UpdateParticularOrderDetail({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            userInfo,
          }),
        );
        await clearCatalogList();
        setTimeout(() => {
          loadOrderContent();
        }, 30000);
      }
    };
    if (
      navigation.isFocused() &&
      connected &&
      wallet &&
      transactionsData &&
      transactionsData?.length > 0 &&
      !loadingOrder
    ) {
      setTimeout(() => {
        loadOrderContent();
      }, 30000);
    }
  }, [connected, loadingOrder]);

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.mainContainer}>
        <View style={[styles.contentContainer, styles.maxWidthAdjust]}>
          <View style={styles.ordersList}>
            {loadingOrder || !transactionsData ? (
              <View style={innerStyles.loadingWrap}>
                <View style={styles.tile}>
                  <View
                    style={[
                      {
                        padding: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: defaultTheme.CONTAINER_BORDER_COLOR,
                      },
                    ]}>
                    <ActivityIndicator
                      size={'large'}
                      color={defaultTheme.PRIMARY_TEXT_COLOR}
                    />
                  </View>
                </View>
              </View>
            ) : (
              <FlatList
                ListHeaderComponent={
                  transactionsData?.length && (
                    <View style={styles.OrdersValidityBanner}>
                      <Text
                        numberOfLines={1}
                        adjustsFontSizeToFit
                        style={innerStyles.subTitle}>
                        Reserved Orders will be valid for 30 minutes!!
                      </Text>
                    </View>
                  )
                }
                refreshControl={
                  <RefreshControl
                    refreshing={refreshing}
                    onRefresh={onRefresh}
                  />
                }
                data={transactionsData}
                renderItem={renderItem}
                horizontal={false}
                showsVerticalScrollIndicator={false}
                style={{ paddingBottom: 10 }}
                contentContainerStyle={innerStyles.listContent}
                keyExtractor={item => item?.transactionId}
                ListEmptyComponent={
                  <View style={innerStyles.emptyWrapper}>
                    <EmptyList message="No new orders!" />
                  </View>
                }
              />
            )}
          </View>
        </View>
      </View>
    </View>
  );
}

const innerStyles = StyleSheet.create({
  loadingWrap: {
    width: '100%',
    minHeight: 20,
    marginBottom: 10,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  subTitle: {
    alignSelf: 'center',
    textAlign: 'center',
    marginHorizontal: 10,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_BLACK,
  },
  listContent: {
    marginTop: 5,
    paddingBottom: 80,
  },
  emptyWrapper: {
    marginTop: 30,
  },
});

export default HOCContainer({
  title: 'My Orders',
  isScrollEnabled: false,
  OriginalComponent: TransactionScreen,
});
