import React, { useState } from 'react';
import {
  Text,
  View,
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';

import BackButton from '../BackButton';
import { defaultTheme } from '../../../themes/loot8';
import { MenuItem } from '../../../components/AnimatedMenu';

import tiktokIcon from '../../../assets/socialicons/tiktok.png';
import twitterIcon from '../../../assets/socialicons/twitter.png';
import facebookIcon from '../../../assets/socialicons/facebook.png';
import instagramIcon from '../../../assets/socialicons/instagram.png';

export interface SocialLinks {
  facebook: string;
  twitter: string;
  instagram: string;
  tiktok: string;
}

const ProfileCreationConfirmation = ({
  exPassName,
  selectedCategory,
  socialLinks,
  description,
}: {
  exPassName: string;
  description: string;
  selectedCategory: null | MenuItem;
  socialLinks: SocialLinks;
}) => {
  const [showMore, setShowMore] = useState(false);

  const filteredSocialPlatforms = socialPlatforms.filter(
    platform => socialLinks[platform.key],
  );

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      <Text style={styles.title}>Create Free profile</Text>
      <Text style={styles.subTitle}>
        Please verify the following detail before Finish
      </Text>
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.card}>
          <View style={styles.cardContent}>
            <Text style={styles.label}>Free profile name</Text>
            <View style={styles.cardRightContentContainer}>
              <Text style={{ ...styles.label, ...styles.value }}>
                {exPassName}
              </Text>
              <Image
                style={{ width: 12, height: 12, marginLeft: 4 }}
                source={require('../../../assets/images/verified.png')}
              />
            </View>
          </View>
          <View style={styles.cardContentBorder} />
          <View style={styles.cardContent}>
            <Text style={styles.label}>Category</Text>
            <View style={styles.cardRightContentContainer}>
              <Text style={{ ...styles.label, ...styles.value }}>
                {selectedCategory?.name}
              </Text>
            </View>
          </View>
          <View style={styles.cardContentBorder} />
          <View style={styles.cardContent}>
            <Text style={styles.label}>Description</Text>
            <View style={styles.cardRightContentContainer}>
              <Text style={{ ...styles.label, ...styles.value }}>
                {showMore
                  ? description
                  : `${description.substring(0, 100)} ${
                      description?.length > 100 ? '...' : ''
                    }`}
                {description?.length > 100 && (
                  <TouchableOpacity onPress={toggleShowMore}>
                    <Text style={styles.showMoreLessText}>
                      {showMore ? 'Show less' : 'Show more'}
                    </Text>
                  </TouchableOpacity>
                )}
              </Text>
            </View>
          </View>
          <View style={styles.cardContentBorder} />
          <View style={styles.cardContent}>
            <Text
              numberOfLines={3}
              style={{
                ...styles.label,
                maxWidth: 150,
              }}>
              Connected social accounts
            </Text>
            <View style={styles.cardRightContentContainer}>
              {filteredSocialPlatforms.map(platform => (
                <View key={platform.label}>
                  <Image
                    style={styles.image}
                    resizeMode="contain"
                    source={platform?.icon}
                  />
                </View>
              ))}
            </View>
          </View>
        </View>
        <View style={styles.buttonSpace} />
      </ScrollView>
    </>
  );
};

export default ProfileCreationConfirmation;

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    paddingBottom: 100,
  },
  scrollView: {
    flex: 1,
  },
  title: {
    marginBottom: 6,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
  subTitle: {
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
  },
  card: {
    marginTop: 24,
    minHeight: 130,
    borderRadius: 8,
    paddingHorizontal: 14,
    backgroundColor: '#26243C',
  },
  cardContent: {
    minHeight: 48,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  cardRightContentContainer: {
    flexDirection: 'row',
    width: '51%',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cardContentBorder: {
    height: 1,
    borderBottomWidth: 1,
    borderBottomColor: defaultTheme.GRADIENT_COLOR1,
  },
  buttonSpace: {
    marginTop: 72,
  },
  label: {
    marginLeft: 6,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  value: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
  image: { width: 18, height: 18, marginLeft: 4 },

  ripple: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  showMoreLessText: {
    color: 'gray',
    marginTop: 5,
  },
});

const socialPlatforms = [
  {
    id: 1,
    icon: instagramIcon,
    label: 'Instagram',
    key: 'instagram',
    placeholder: 'Add your Instagram link',
  },
  {
    id: 2,
    icon: twitterIcon,
    label: 'X (Formally Twitter)',
    key: 'twitter',
    placeholder: 'Add your X link',
  },
  {
    id: 3,
    icon: tiktokIcon,
    label: 'TikTok',
    key: 'tiktok',
    placeholder: 'Add your TikTok link',
  },
  {
    id: 4,
    icon: facebookIcon,
    label: 'Facebook',
    key: 'facebook',
    placeholder: 'Add your Facebook link',
  },
];
