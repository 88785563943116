import React, { useEffect, useState } from 'react';
import { Avatar } from 'react-native-paper';
import { View, Image } from 'react-native';
import { getThirdPartyVerifiedURL } from '../../slices/AppUserSlice';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import loot8DefaultImage from '../../assets/loot8-default-avatar.png';
import varifiedAccountTick from '../../assets/verified_account.png';
import { defaultTheme } from '../../themes/loot8';

const ProfileAvatar = ({
  userAddress,
  size,
  source,
  style = {},
  tickStyle = {},
}) => {
  const { networkId, staticProvider, address, wallet, userInfo } =
    useWeb3AuthContext();
  const [isVerifiedProfile, setIsVerifiedProfile] = useState(false);

  const pullThirdPartyVerifiedURL = async () => {
    if (userAddress) {
      const thirdPartyVerifiedURL: any = await getThirdPartyVerifiedURL({
        networkID: networkId,
        provider: staticProvider,
        address: userAddress,
      });
      if (thirdPartyVerifiedURL?.length > 0) {
        setIsVerifiedProfile(true);
      } else {
        setIsVerifiedProfile(false);
      }
    }
  };
  useEffect(() => {
    pullThirdPartyVerifiedURL();
  }, []);

  return (
    <View
      style={{
        padding: 2,
        backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
        borderRadius: 100,
      }}>
      {source ? (
        <Avatar.Image size={size} style={style} source={source} />
      ) : (
        <Avatar.Image size={size} style={style} source={loot8DefaultImage} />
      )}
      {isVerifiedProfile && (
        <View
          style={[
            {
              height: 14,
              width: 14,
              position: 'absolute',
              right: 4,
              top: -5,
              zIndex: 999,
            },
            tickStyle,
          ]}>
          <Image
            source={varifiedAccountTick}
            style={{ height: '100%', width: '100%' }}
          />
        </View>
      )}
    </View>
  );
};

export default ProfileAvatar;
