import { Wallet, ethers } from 'ethers';
import { MARKET_PLACE_LAMBDA_URL, MASTER_KEY, isNativeChain } from '../appconstants';
import { retryFetch } from './ipfs';
import { getRequestHeaders } from './APIHelper';
import { IListing } from '../interfaces/IListing.interface';
import { convertValue } from './Gadgets';
import { LogCustomError } from './AppLogger';
import { fetchMarketplaceListings } from './GraphQLHelper';

export const separator: Buffer = Buffer.from([0x01]);

export const validateForMarketPlace = async (
  wallet: Wallet,
  patron: string,
  passport: string,
  collection: string,
  tokenId: number,
  paymentToken: string,
  price: string,
  action: string,
  listingType: number,
) => {
  let data = { patron, passport, collection, tokenId, paymentToken, price, action, listingType };

  const body = JSON.stringify(data);

  const signature = await wallet.signMessage(Buffer.from(body, 'utf-8'));

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(signature);

  let requestURL = `${MARKET_PLACE_LAMBDA_URL}validate/${signature}/${masterSignature}/`;

  const response = await retryFetch(() =>
    fetch(requestURL, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body }),
  );

  return response;
};

export const listForMarketPlace = async (wallet: Wallet, patron: string, listingId: number, chainId: number) => {
  let data = { patron, listingId, chainId };

  const body = JSON.stringify(data);

  const signature = await wallet.signMessage(Buffer.from(body, 'utf-8'));

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(signature);

  let requestURL = MARKET_PLACE_LAMBDA_URL + `list/${signature}/${masterSignature}/`;

  const response = await retryFetch(() =>
    fetch(requestURL, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body }),
  );

  return response;
};

export const delistForMarketPlace = async (wallet: Wallet, patron: string, listingId: number, chainId: number) => {
  let data = { patron, listingId, chainId };
  const body = JSON.stringify(data);

  const signature = await wallet.signMessage(Buffer.from(body, 'utf-8'));

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(signature);

  let requestURL = MARKET_PLACE_LAMBDA_URL + `delist/${signature}/${masterSignature}/`;

  const response = await retryFetch(() =>
    fetch(requestURL, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body }),
  );

  return response;
};

const filterNullParams = param => {
  for (var propName in param) {
    if (param[propName] === null || param[propName] === undefined) {
      delete param[propName];
    }
  }
  return param;
};

const convertContractDataToListing = data => {
  let iListingData: IListing[] = [];
  if (data?.length > 0) {
    data?.map(collectible => {
      const collectibleData: IListing = {
        unique_id: Number(collectible?.listing_id),
        id: Number(collectible?.listing_listing_id),
        collection: collectible?.listing_collection_address,
        listingType: collectible?.listing_listing_type,
        marketplaceFees: Number(collectible?.listing_marketplace_fees),
        passport: collectible?.listing_passport,
        paymentToken: collectible?.listing_payment_token,
        price: convertValue(
          Number(collectible?.listing_price),
          isNativeChain(collectible?.listing_chain_id) ? 1e18 : 1e6,
        ),
        seller: collectible?.listing_seller,
        sellerShare: convertValue(
          Number(collectible?.listing_seller_share),
          isNativeChain(collectible?.listing_chain_id) ? 1e18 : 1e6,
        ),
        tokenId: Number(collectible?.listing_token_id),
        chainId: Number(collectible?.listing_chain_id),
      };
      iListingData.push(collectibleData);
    });
  }
  return iListingData;
};

export const getPublicListingCollectionData = async (
  orderBy: string,
  order: 'ASC' | 'DESC',
  skip: number,
  take: number,
  isCollectibleData = false,
): Promise<IListing[]> => {
  try {
    let data: any = {
      orderBy: orderBy,
      order: order,
      skip: skip,
      take: take,
    };

    data = filterNullParams(data);

    let buildParameters = '';
    Object.keys(data).map(x => {
      buildParameters += `${x}=${data[x]}&`;
    });

    let path = null;
    if (isCollectibleData) {
      path = `listings/collectibles`;
    } else {
      path = `listings/expasses`;
    }

    let buildURL = MARKET_PLACE_LAMBDA_URL + path;
    if (buildParameters && buildParameters != '') {
      buildURL += `?${buildParameters}`;
    }

    const reqHeaders = await getRequestHeaders('GET', path, null, null, null);
    let response = await retryFetch(() => fetch(buildURL, { method: 'GET', headers: reqHeaders }));

    if (response.status === 400) {
    }

    const responseJson = await response?.json();

    let iListingData: IListing[] = [];
    if (responseJson?.result) {
      iListingData = convertContractDataToListing(responseJson?.result);
    }
    return iListingData;
  } catch (err) {
    LogCustomError('getPublicListingCollectionData', err.name, err.message, err.stack);
    return [];
  }
};

const convertMarketplaceDataToListing = data => {
  let iListingData: IListing[] = [];
  if (data?.length > 0) {
    data?.map(collectible => {
      const collectibleData: IListing = {
        unique_id: Number(collectible?.listingId),
        id: Number(collectible?.listingId),
        collection: collectible?.collection,
        listingType: collectible?.listingType,
        marketplaceFees: Number(collectible?.marketplaceFees),
        passport: collectible?.passport,
        paymentToken: collectible?.paymentToken,
        price: convertValue(Number(collectible?.price), isNativeChain(collectible?.chainId) ? 1e18 : 1e6),
        seller: collectible?.seller,
        sellerShare: convertValue(Number(collectible?.sellerShare), isNativeChain(collectible?.chainId) ? 1e18 : 1e6),
        tokenId: Number(collectible?.tokenId),
        chainId: Number(collectible?.chainId),
      };
      iListingData.push(collectibleData);
    });
  }
  return iListingData;
};

export const getMarketplaceListingsData = async (
  orderBy: string,
  order: 'ASC' | 'DESC',
  skip: number,
  take: number,
  isCollectibleData = false,
  searchByName: string,
): Promise<IListing[]> => {
  try {
    let sortOrder: IMarketplaceListingOrdering = {
      key: orderBy.toLocaleLowerCase() == 'created' ? 'blockNumber' : orderBy,
      value: order,
    };

    let collectionType = isCollectibleData ? 'collectibles' : 'expasses';
    let pageNumber = skip == 0 ? 1 : skip;

    const response = await fetchMarketplaceListings(pageNumber, take, collectionType, sortOrder, searchByName);

    let iListingData: IListing[] = [];
    if (response?.length > 0) {
      iListingData = convertMarketplaceDataToListing(response);
    }
    return iListingData;
  } catch (err) {
    LogCustomError('getMarketplaceListingsData', err.name, err.message, err.stack);
    return [];
  }
};
