import React, { useEffect, useRef, useState } from 'react';
import {
  ActivityIndicator,
  LayoutChangeEvent,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { IconButton } from 'react-native-paper';
import { useDispatch } from 'react-redux';
import { useRoute } from '@react-navigation/native';
import {
  FRIEND_PRIVATE_MESSAGES_LAST_READ,
  MUTUALFRIENDS_MESSAGES_LAST_READ,
} from '../../appconstants';
import { LogCustomError } from '../../helpers/AppLogger';
import { getData, storeData } from '../../helpers/AppStorage';
import { getSyncedTime } from '../../helpers/DateHelper';
import { useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { DirectionType } from '../../interfaces/IMessages';
import { getUserPublicKey } from '../../slices/AppUserSlice';
import {
  NotificationType,
  notificationsShown,
} from '../../slices/NotificationSlice';
import {
  addClearMessageInDraft,
  deletePrivateUserMessage,
  getPrivateMessagesFromFriend,
  getPrivateMsgRequestForFriend,
  loadLastMessageDetailsForMutualFriends,
  resetPrivateMessage,
  setOlderMessageLoading,
  subscribeToPrivateMessages,
} from '../../slices/PrivateMessageSlice';
import { dismissNotification } from '../../slices/helpers';
import { AppDispatch } from '../../store';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import ImagePickerComponent from '../ImagePickerComponent';
import ModalComponent from '../Modal';
import UserDetailModal from '../Modal/UserDetailModal';
import useIsResponsive from '../../hooks/useIsResponsive';
import MessageInviteComponent from '../MessageInviteComponent';
import MessagesHeaderComponent from '../MessagesHeaderComponent';
import MessagesInitialComponent from '../MessagesInitialComponent';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import MessagesReplyComponent from '../MessagesReplyComponent';
import MessageInputComponent from '../MessageInputComponent';
import MessageSentPreviewComponent from '../MessageSentPreviewComponent';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import MessageBubble from '../MessageBubble';

let MESSAGE_MAX_LENGTH = 360;

export enum MessageRetrivalStatus {
  INITIAL_MESSAGES = 'INITIAL_MESSAGES',
  OLDER_MESSAGES = 'OLDER_MESSAGES',
  MESSAGE_SEND = 'MESSAGE_SEND',
}

const getRepliedToText = (parentMsgData, MsgData, address, msgSender) => {
  if (parentMsgData?.sender?.toLowerCase() === address?.toLowerCase()) {
    if (MsgData?.sender?.toLowerCase() === address?.toLowerCase()) {
      return 'Replied to yourself';
    } else {
      return 'Replied to You';
    }
  } else {
    if (MsgData?.sender?.toLowerCase() === address?.toLowerCase()) {
      return 'Replied to ' + parentMsgData?.name;
    } else {
      return 'Replied to self';
    }
  }
};

const DirectMessageItem = ({
  messages,
  friendsPublicKey,
  setShowOptions,
  setSelectedMsg,
  selectedMsg,
  scrollToMessageItem,
  layoutData,
  onLayout,
  isUserMutualFriend,
}) => {
  const [highLight, setHighlight] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [viewFriendDetailItem, setViewFriendDetailActionItem] = useState(null);
  const [hovered, setHovered] = useState(null);
  const { address } = useWeb3AuthContext();
  const allMessages = useAppSelector(state => state.PrivateMessage.messages);

  const scrollToMessage = messageId => {
    setHighlight(messageId);
    scrollToMessageItem(messageId);
    setTimeout(() => {
      setHighlight(null);
    }, 1500);
  };
  const handleLayout = (event: LayoutChangeEvent, outerIndex) => {
    const { y } = event.nativeEvent.layout;
    let item = { msgYCoordinate: y, index: outerIndex };
    const messageItemIndex = layoutData.message?.findIndex(
      item => item.index === outerIndex,
    );

    if (messageItemIndex !== null && messageItemIndex !== -1) {
      layoutData.message[messageItemIndex].msgYCoordinate = y;
      layoutData.message[messageItemIndex].index = outerIndex;
    } else {
      layoutData.message.push(item);
    }
  };

  return (
    <View style={{ flex: 1 }} onLayout={onLayout}>
      {messages?.map((item, outerIndex) => {
        return (
          <View
            style={{}}
            key={outerIndex}
            onLayout={event => handleLayout(event, outerIndex)}>
            {item?.messages?.map((message, innerIndex) => {
              return (
                <MessageBubble
                  key={message.messageId}
                  message={message}
                  address={address}
                  friendsPublicKey={friendsPublicKey}
                  index={innerIndex}
                  allMessages={allMessages}
                  selectedMsg={selectedMsg}
                  setSelectedMsg={setSelectedMsg}
                  hovered={hovered}
                  setHovered={setHovered}
                  setShowOptions={setShowOptions}
                  layoutData={layoutData}
                  highLight={highLight}
                  scrollToMessage={scrollToMessage}
                  outerIndex={outerIndex}
                  user={item.user}
                  isUserMutualFriend={isUserMutualFriend}
                  getRepliedToText={getRepliedToText}
                />
              );
            })}
          </View>
        );
      })}
      {showModal && viewFriendDetailItem && (
        <UserDetailModal
          user={viewFriendDetailItem}
          isVisible={showModal}
          onDismiss={() => {
            setViewFriendDetailActionItem(null);
            setShowModal(false);
          }}
        />
      )}
    </View>
  );
};

const DirectMessage = ({ user, isMutualFriend = false }) => {
  const [friendsPublicKey, setFriendsPublicKey] = useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [messageText, setMessageText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [viewFriendDetailItem, setViewFriendDetailActionItem] = useState(null);
  const [textInputHeight, setTextInputHeight] = useState(50);
  const [msgFriendRequestSent, setMsgFriendRequestSent] = useState(false);
  const [isMsgRequestLoading, setIsMsgRequestLoading] = useState(true);
  const [friendRequestStatusMsg, setFriendRequestStatusMsg] = useState('');
  const [latestFriendRequest, setLatestFriendRequest] = useState(null);
  const [isNextFriendReqAllowed, setIsNextFriendReqAllowed] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState(null);
  const [quoteMsg, setQuoteMsg] = useState(null);
  const [showAddImage, setShowAddImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [dynamicHeight, setDynamicHeight] = useState(0);
  const [messageRetrivalStatus, setMessageRetrivalStatus] = useState(
    MessageRetrivalStatus.INITIAL_MESSAGES,
  );
  const scrollView = useRef<ScrollView>();
  const route = useRoute();

  const dispatch = useDispatch<AppDispatch>();
  const { networkId, wallet, staticProvider, address, decryptMessage } =
    useWeb3AuthContext();
  //Checking mutual friendship from friends state as the 'user' data being passed from passport/portfolio social screen does not contain that information
  const mutualFriendsList = useAppSelector(
    state => state.friends.mutualFriends,
  );
  const isUserMutualFriend = isMutualFriend
    ? true
    : mutualFriendsList.filter(
          f => f?.wallet.toLowerCase() === user?.wallet.toLowerCase(),
        ).length > 0
      ? mutualFriendsList.filter(
          f => f.wallet.toLowerCase() === user?.wallet.toLowerCase(),
        )[0].isMutualFriend
      : false;
  const userData = useAppSelector(state => state.AppUser.UserData);
  const notifications = useAppSelector(
    state => state.notification.notifications,
  );
  const messageDetails = useAppSelector(
    state => state.PrivateMessage.groupByMessages,
  );
  const draftMessages = useAppSelector(
    state => state.PrivateMessage.draftMessages,
  );
  const initialMessageLoading = useAppSelector(
    state => state.PrivateMessage.initialMessageLoading,
  );
  const olderMessageLoading = useAppSelector(
    state => state.PrivateMessage.olderMessageLoading,
  );
  const isNativeNotificationPermitted = useAppSelector(
    state => state.App.nativeNotificationPermitted,
  );
  let timestamp = getSyncedTime();
  useEffect(() => {
    storeData(
      MUTUALFRIENDS_MESSAGES_LAST_READ(wallet.address, user?.wallet),
      getSyncedTime(),
    );

    return () => {
      // reset private message state excluding draft messages when component unmount.
      dispatch(
        resetPrivateMessage([
          'draftMessages',
          'pendingRequest',
          'mutualFriendMessagesDetails',
        ]),
      );

      // clear last read message timestamp
      storeData(
        FRIEND_PRIVATE_MESSAGES_LAST_READ(wallet.address, user?.wallet),
        null,
      );

      // clear message text.
      setMessageText('');
    };
  }, []);

  useEffect(() => {
    const msgSent = isMessageSent;
    return () => {
      if (msgSent) {
        dispatch(
          loadLastMessageDetailsForMutualFriends({
            networkID: networkId,
            provider: staticProvider,
            address: address,
            wallet: wallet,
            friendsAddresses: mutualFriendsList
              .filter(
                f => f?.wallet.toLowerCase() === user?.wallet.toLowerCase(),
              )
              .map(f => {
                return { ...f, friendAddress: f.wallet };
              }),
          }),
        );
        setIsMessageSent(false);
      }
    };
  }, [isMessageSent]);

  useEffect(() => {
    const dismissNativeNotifications = async () => {
      dismissNotification(null, user?.wallet);
    };
    const fromNotificationTap =
      route?.params?.friendAddress && route?.params?.friendAddress !== null
        ? true
        : false;
    if (
      isNativeNotificationPermitted &&
      !fromNotificationTap &&
      !initialMessageLoading
    ) {
      dismissNativeNotifications();
    }
  }, [initialMessageLoading]);

  // const onNavigateBack = async () => {
  //   //Apart from navigating away from DM window to any other tab, if user clicks on back button - the last read tiemstamp should be set at that time
  //   await storeData(
  //     MUTUALFRIENDS_MESSAGES_LAST_READ(wallet.address, user?.wallet),
  //     Date.now(),
  //   );

  //   if (
  //     navigationFrom == FriendsTabs.FRIEND ||
  //     navigationFrom == FriendsTabs.FRIENDSMESSAGES ||
  //     navigationFrom == FriendsTabs.REQUESTS
  //   ) {
  //     // onSelectedTab(FriendsTabs.FRIEND);
  //     dispatch(resetMutualFriendsState());
  //   } else if (
  //     navigationFrom?.screen == ScreenName.CATALOG_DETAILS &&
  //     navigationFrom?.params?.from !== 'PassportPage'
  //   ) {
  //     // @ts-ignore
  //     navigation.navigate(ScreenName.CATALOG_TAB, {
  //       screen: ScreenName.CATALOG_DETAILS,
  //       params: {
  //         ...navigationFrom?.params,
  //         // catalog: navigationFrom?.params?.catalog,
  //         // availableCatalogs: navigationFrom?.params?.availableCatalogs,
  //         // type: navigationFrom?.params?.type,
  //         // from: navigationFrom?.params?.from
  //       },
  //       initial: false,
  //     });
  //   } else if (navigationFrom?.params?.from === 'PassportPage') {
  //     // @ts-ignore
  //     navigation.navigate(ScreenName.HOME_TAB, {
  //       screen: ScreenName.CATALOG_DETAILS,
  //       params: {
  //         ...navigationFrom?.params,
  //         // catalog: navigationFrom?.params?.catalog,
  //         // availableCatalogs: navigationFrom?.params?.availableCatalogs,
  //         // type: navigationFrom?.params?.type,
  //         // from: navigationFrom?.params?.from
  //       },
  //       initial: false,
  //     });
  //   } else if (navigationFrom?.screen == ScreenName.HOME_SCREEN) {
  //     // @ts-ignore
  //     navigation.navigate(ScreenName.HOME_TAB, {
  //       screen: ScreenName.PASSPORT_DETAIL,
  //       params: {
  //         passportAddress: navigationFrom?.params?.passportAddress,
  //         chainId: navigationFrom?.params?.chainId,
  //       },
  //     });
  //   } else {
  //     navigation.goBack();
  //   }
  // };

  useEffect(() => {
    // pull back message from draft
    if (draftMessages && draftMessages.length > 0) {
      const draft = draftMessages.filter(
        x => x.friendAddress?.toLowerCase() == user?.wallet?.toLowerCase(),
      );
      if (draft && draft.length > 0) {
        setMessageText(draft[0].message);
        if (draft[0]?.parent) {
          setQuoteMsg(draft[0]?.parent);
        }
      }
    }
  }, []);
  useEffect(() => {
    // add message to draft on component unmount
    return () => {
      if (isUserMutualFriend) {
        onMessageDraft();
      }
    };
  }, [messageText, quoteMsg, isUserMutualFriend]);

  const onMessageDraft = () => {
    // add message in draft
    dispatch(
      addClearMessageInDraft({
        networkID: networkId,
        provider: staticProvider,
        address: user?.wallet,
        wallet: wallet,
        publicKey: friendsPublicKey,
        text: messageText,
        parent: quoteMsg,
      }),
    );
  };

  // get friend messages
  const getFriendMessages = async () => {
    setIsLoading(true);
    const pullFriendPublicKey = async () => {
      try {
        if (user && user?.wallet) {
          const friendPublicKey = await getUserPublicKey(user?.wallet);
          if (friendPublicKey) {
            setFriendsPublicKey(friendPublicKey);
            return friendPublicKey;
          }
        }
      } catch (error) {
        LogCustomError(
          'PrivateMessage -> pullFriendPublicKey',
          error.name,
          error.message,
          error.stack,
        );
      }
    };
    await pullFriendPublicKey().then(async publicKey => {
      setIsMsgRequestLoading(true);
      if (publicKey) {
        if (isUserMutualFriend) {
          setMsgFriendRequestSent(false);
          setFriendRequestMsg(false);
          MESSAGE_MAX_LENGTH = 360;
          await dispatch(
            getPrivateMessagesFromFriend({
              networkID: networkId,
              provider: staticProvider,
              address: user?.wallet,
              wallet: wallet,
              isCache: true,
              isClear: true,
              decryptMessage,
              publicKey,
            }),
          ).then(() => {
            dispatch(
              subscribeToPrivateMessages({
                networkID: networkId,
                provider: staticProvider,
                address: user?.wallet,
                wallet,
                decryptMessage,
                publicKey,
              }),
            );
          });
          setIsLoading(false);
        } else {
          MESSAGE_MAX_LENGTH = 140;
          await dispatch(
            getPrivateMessagesFromFriend({
              networkID: networkId,
              provider: staticProvider,
              address: user?.wallet,
              wallet: wallet,
              isCache: true,
              isClear: true,
              decryptMessage,
              publicKey,
            }),
          );
          await dispatch(
            getPrivateMsgRequestForFriend({
              networkID: networkId,
              address: user?.wallet,
              wallet: wallet,
              timestamp: null,
              decryptMessage: decryptMessage,
              publicKey: publicKey,
              directionType: null,
            }),
          ).then(latestMsgRequest => {
            if (
              latestMsgRequest &&
              latestMsgRequest.payload &&
              latestMsgRequest.payload.latestRequest
            ) {
              //Logic to check if request is approved or cancelled
              const latestReq = latestMsgRequest.payload.latestRequest;
              const isCancelled = latestReq?.isCancelled;
              setLatestFriendRequest(latestReq);
              setNextFriendRequestAllowed(latestReq?.timestamp);
              setMsgFriendRequestSent(!isCancelled);
              if (latestReq.data?.data.content?.decryptedText) {
                setMessageText(latestReq.data?.data?.content?.decryptedText);
              }
              setFriendRequestMsg(!isCancelled);
              setIsLoading(false);
            } else {
              setLatestFriendRequest(null);
              setMsgFriendRequestSent(false);
              setFriendRequestMsg(false);
              setNextFriendRequestAllowed(true);
              setIsLoading(false);
            }
          });
        }
      } else {
        setIsLoading(false);
      }
      setIsMsgRequestLoading(false);
    });

    // commenting this out for last read message timestamp
    // // clear last read message timestamp
    // await storeData(FRIEND_PRIVATE_MESSAGES_LAST_READ(wallet.address, user?.wallet), null);
  };

  const setFriendRequestMsg = reqSent => {
    if (reqSent) {
      setFriendRequestStatusMsg(
        'Friend request sent. Awaiting response from ' + user?.name + '.',
      );
    } else {
      setMessageText('');
      setFriendRequestStatusMsg(
        `You and ${user?.name.trim()} don't follow each other on Loot8. You can send one message to invite ${user?.name.trim()} for a chat.`,
      );
    }
  };

  useEffect(() => {
    getFriendMessages();
  }, [user]);

  useEffect(() => {
    if (notifications.length > 0) {
      // Selected friend's notifications mark as read
      let userNotifications = notifications.filter(
        f =>
          f?.dataObject?.wallet?.toLowerCase() ===
            user?.wallet?.toLowerCase() &&
          (f.notificationType === NotificationType.NewPrivateMessage ||
            f.notificationType === NotificationType.PrivateMsgRequestAccepted),
      );
      if (userNotifications.length > 0) {
        for (let i = 0; i < userNotifications.length; i++) {
          dispatch(notificationsShown(userNotifications[i].id));
        }
      }
    }
  }, [notifications]);

  useEffect(() => {
    if (
      !olderMessageLoading &&
      messageRetrivalStatus === MessageRetrivalStatus.OLDER_MESSAGES
    ) {
      scrollView?.current?.scrollTo({ y: 20 });
    }
  }, [olderMessageLoading]);

  const openModalPopup = (item, viewDetails) => {
    if (item && viewDetails) {
      setViewFriendDetailActionItem(item);
    }
    setShowModal(true);
  };

  const pullOlderMessages = async () => {
    setMessageRetrivalStatus(MessageRetrivalStatus.OLDER_MESSAGES);
    const timestamp = await getData(
      FRIEND_PRIVATE_MESSAGES_LAST_READ(wallet.address, user?.wallet),
    );
    if (timestamp) {
      await dispatch(setOlderMessageLoading(true));
      await dispatch(
        getPrivateMessagesFromFriend({
          networkID: networkId,
          provider: staticProvider,
          address: user?.wallet,
          wallet: wallet,
          isCache: true,
          isClear: false,
          timestamp: timestamp,
          decryptMessage,
          publicKey: friendsPublicKey,
          directionType: DirectionType.earlier,
          isOlderMessages: true,
        }),
      );
    }
  };

  const onPullOlderMessages = async e => {
    // pull older messages.
    if (e?.nativeEvent?.contentOffset?.y == 0 && isUserMutualFriend) {
      //We show message window for non-mutual friend as well; in that case we do not need to show older messages.
      await pullOlderMessages();
    }
  };

  const setNextFriendRequestAllowed = requestTimestamp => {
    const currentTime = getSyncedTime();
    const allowedDuration = 3 * 60 * 60 * 1000; //3 Hours
    if (currentTime - requestTimestamp < allowedDuration) {
      setIsNextFriendReqAllowed(false);
      return false;
    } else {
      setIsNextFriendReqAllowed(true);
      return true;
    }
  };

  const onDeleteMessage = message => {
    setShowModal(false);
    if (quoteMsg && message.messageId === quoteMsg.messageId) {
      setQuoteMsg(null);
    }
    dispatch(
      deletePrivateUserMessage({
        networkID: networkId,
        provider: staticProvider,
        address: user?.wallet,
        wallet,
        messageById: message.messageId,
      }),
    );
    setTimeout(() => {
      setSelectedMsg(null);
    }, 3000);
  };

  const onQouteMessage = message => {
    setQuoteMsg(message);
    setShowModal(false);
    setSelectedMsg(null);
  };

  // scroll to the quoted message

  const [layoutData, setLayoutData] = useState({
    message: [],
    messageItem: [],
  });
  const [messageCoordinate, setMessageCoordinate] = useState(0);
  const threeHoursWarningText =
    'Please wait! New request can be sent only after 3 hours from your previous request.';
  const scrollToMessageItem = itemId => {
    const itemLayout = layoutData?.messageItem.find(
      item => item.messageId === itemId,
    );
    const messageIndex = messageDetails?.findIndex(
      cluster =>
        cluster.messages?.findIndex(item => item?.messageId === itemId) !== -1,
    );
    const msgLayout = layoutData?.message?.find(
      item => item?.index === messageIndex,
    );
    if (itemLayout && msgLayout) {
      let scrollHeight =
        messageCoordinate +
        msgLayout?.msgYCoordinate +
        itemLayout?.itemYCoordinate;
      // -20 for viewing the user name
      scrollView?.current?.scrollTo({ y: scrollHeight - 20, animated: true });
    }
  };

  useEffect(() => {
    // set MessageRetrivalStatus as INITIAL_MESSAGES in case user data changed.
    setMessageRetrivalStatus(MessageRetrivalStatus.INITIAL_MESSAGES);
  }, [user]);

  return (
    <View
      style={{
        flex: 1,
        paddingBottom: 20,
      }}>
      {/* Direct Messages Header */}
      <MessagesHeaderComponent user={user} openModalPopup={openModalPopup} />
      <KeyboardAwareScrollView
        keyboardShouldPersistTaps="handled"
        scrollEnabled={false}
        extraScrollHeight={100}
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-end' }}
        style={{ flex: 1 }}>
        {/* Direct Messages Users Avatars, center of screen when chat initiates */}
        {!messageDetails?.length && !isLoading && (
          <MessagesInitialComponent
            openModalPopup={openModalPopup}
            user={user}
            userData={userData}
          />
        )}

        {/* Direct Messages Content */}
        <View
          onLayout={e => {
            setDynamicHeight(e.nativeEvent.layout.height);
            scrollView?.current?.scrollToEnd({ animated: true });
          }}
          style={{
            flex: 1,
          }}>
          {dynamicHeight > 0 && (
            <View style={{ height: dynamicHeight - 10 }}>
              <ScrollView
                keyboardShouldPersistTaps="handled"
                ref={scrollView}
                onScroll={event => {
                  if (messageDetails?.length > 0) {
                    onPullOlderMessages(event);
                  }
                }}
                scrollEventThrottle={400}
                onContentSizeChange={() => {
                  if (
                    messageRetrivalStatus ===
                    MessageRetrivalStatus.INITIAL_MESSAGES
                  ) {
                    scrollView?.current?.scrollToEnd({ animated: true });
                  }
                  if (
                    messageRetrivalStatus === MessageRetrivalStatus.MESSAGE_SEND
                  ) {
                    scrollView?.current?.scrollToEnd({ animated: false });
                  }
                }}
                onLayout={() => {
                  if (quoteMsg) {
                    scrollView?.current?.scrollToEnd({ animated: true });
                  }
                }}
                showsVerticalScrollIndicator={false}
                style={{ flex: 1 }}
                contentContainerStyle={{
                  flexGrow: 1,
                  justifyContent: 'flex-end',
                  // paddingBottom: 40,
                }}>
                {!msgFriendRequestSent &&
                  !isUserMutualFriend &&
                  !isMsgRequestLoading &&
                  messageDetails.length === 0 &&
                  !isLoading && (
                    <MessageInviteComponent
                      openModalPopup={openModalPopup}
                      user={user}
                      friendRequestStatusMsg={friendRequestStatusMsg}
                      latestFriendRequest={latestFriendRequest}
                      isNextFriendReqAllowed={isNextFriendReqAllowed}
                      threeHoursWarningText={threeHoursWarningText}
                    />
                  )}
                {olderMessageLoading &&
                  messageDetails &&
                  messageDetails.length > 0 && (
                    <ActivityIndicator
                      size="large"
                      color={defaultTheme.PRIMARY_TEXT_COLOR}
                    />
                  )}
                {(isMsgRequestLoading || initialMessageLoading) &&
                !olderMessageLoading ? (
                  <View style={componentStyles.loaderContainer}>
                    <ActivityIndicator
                      size="large"
                      color={defaultTheme.PRIMARY_TEXT_COLOR}
                    />
                  </View>
                ) : (
                  <>
                    <DirectMessageItem
                      onLayout={event =>
                        setMessageCoordinate(event.nativeEvent.layout.y)
                      }
                      messages={messageDetails}
                      friendsPublicKey={friendsPublicKey}
                      setShowOptions={setShowModal}
                      setSelectedMsg={setSelectedMsg}
                      scrollToMessageItem={scrollToMessageItem}
                      layoutData={layoutData}
                      selectedMsg={selectedMsg}
                      isUserMutualFriend={isUserMutualFriend}
                    />

                    {msgFriendRequestSent && !isUserMutualFriend && (
                      <MessageSentPreviewComponent
                        userData={userData}
                        messageText={messageText}
                        latestFriendRequest={latestFriendRequest}
                      />
                    )}
                  </>
                )}
                {!msgFriendRequestSent &&
                  !isUserMutualFriend &&
                  !isMsgRequestLoading &&
                  messageDetails.length > 0 &&
                  !isLoading && (
                    <>
                      <View style={componentStyles.warningContainer}>
                        <Text style={componentStyles.warningText}>
                          {`You and ${user?.name.trim()} don't follow each other anymore. Invite ${user?.name.trim()} to chat`}
                        </Text>
                      </View>
                      {latestFriendRequest && !isNextFriendReqAllowed && (
                        <Text style={componentStyles.warningCancelBtn}>
                          {threeHoursWarningText}
                        </Text>
                      )}
                    </>
                  )}
              </ScrollView>
              {quoteMsg && (
                <MessagesReplyComponent
                  quoteMsg={quoteMsg}
                  isLoading={isLoading}
                  setQuoteMsg={setQuoteMsg}
                  setSelectedMsg={setSelectedMsg}
                  friendsPublicKey={friendsPublicKey}
                  address={address}
                />
              )}
            </View>
          )}
        </View>
        <MessageInputComponent
          latestFriendRequest={latestFriendRequest}
          setIsMsgRequestLoading={setIsMsgRequestLoading}
          friendsPublicKey={friendsPublicKey}
          user={user}
          setNextFriendRequestAllowed={setNextFriendRequestAllowed}
          setMsgFriendRequestSent={setMsgFriendRequestSent}
          setFriendRequestMsg={setFriendRequestMsg}
          isMsgRequestLoading={isMsgRequestLoading}
          msgFriendRequestSent={msgFriendRequestSent}
          isNextFriendReqAllowed={isNextFriendReqAllowed}
          isUserMutualFriend={isUserMutualFriend}
          setShowAddImage={setShowAddImage}
          isLoading={isLoading}
          messageText={messageText}
          setMessageText={setMessageText}
          MESSAGE_MAX_LENGTH={MESSAGE_MAX_LENGTH}
          textInputHeight={textInputHeight}
          onMessageDraft={onMessageDraft}
          setTextInputHeight={setTextInputHeight}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          setIsLoading={setIsLoading}
          setMessageRetrivalStatus={setMessageRetrivalStatus}
          setLatestFriendRequest={setLatestFriendRequest}
          setIsMessageSent={setIsMessageSent}
          quoteMsg={quoteMsg}
          setQuoteMsg={setQuoteMsg}
          setSelectedMsg={setSelectedMsg}
        />
      </KeyboardAwareScrollView>

      <UserDetailModal
        user={viewFriendDetailItem}
        isVisible={showModal && viewFriendDetailItem}
        onDismiss={() => {
          setViewFriendDetailActionItem(null);
          setShowModal(false);
        }}
      />
      {showModal && selectedMsg && (
        <ModalComponent
          showModal={showModal}
          onDismiss={() => {
            setSelectedMsg(null);
            setShowModal(false);
          }}
          enableHeader={false}
          modalStyle={{
            justifyContent: 'flex-end',
            marginBottom: Platform.OS == 'web' ? 60 : 25,
          }}
          modalBodyStyle={{
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            width: '100%',
          }}>
          <View style={{ padding: 5 }}>
            {selectedMsg.data.sender?.toLowerCase() ==
              address?.toLowerCase() && (
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 10,
                }}
                onPress={() => {
                  onDeleteMessage(selectedMsg);
                }}>
                <IconButton
                  icon="trash-can-outline"
                  iconColor="#FFFFFF"
                  style={{
                    height: 22,
                    marginLeft: 5,
                    marginRight: 10,
                  }}
                />
                <Text
                  style={{
                    color: '#FFFFFF',
                    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                    marginTop: 5,
                  }}>
                  Delete
                </Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                padding: 10,
              }}
              onPress={() => {
                onQouteMessage(selectedMsg);
              }}>
              <IconButton
                icon="reply-outline"
                iconColor="#FFFFFF"
                style={{
                  height: 22,
                  marginLeft: 5,
                  marginRight: 10,
                }}
              />
              <Text
                style={{
                  color: '#FFFFFF',
                  fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                  fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                  marginTop: 5,
                }}>
                Reply
              </Text>
            </TouchableOpacity>
          </View>
        </ModalComponent>
      )}
      {showAddImage && (
        <ModalComponent
          showModal={showAddImage}
          enableHeader={false}
          needCloseButton={true}
          modalStyle={{ bottom: 0, justifyContent: 'flex-end' }}
          modalBodyStyle={{ alignSelf: 'flex-start' }}
          onDismiss={() => {
            setShowAddImage(false);
          }}>
          <View style={{ padding: 10 }}>
            <Text style={styles.imagePickerHeaderText}>Attach Photo</Text>
            <ImagePickerComponent
              onAvatarButtonClick={() => {}}
              setSelectedImage={setSelectedImage}
              setSelectImageAction={setShowAddImage}
              showAvatar={false}
              isSquare={false}
            />
          </View>
        </ModalComponent>
      )}
    </View>
  );
};

export default DirectMessage;

const componentStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  warningText: {
    fontSize: 12,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  warningContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
    borderTopWidth: 1,
    borderColor: '#FFFFFF17',
  },
  warningCancelBtn: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    marginTop: 10,
    textAlign: 'center',
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
