// compare past date with current date and return difference in minutes.

import { LogCustomError } from './AppLogger';
import { LogToLoot8Console } from './Loot8ConsoleLogger';
import { getClockSkew } from '../appconstants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

// pass ISO format in timestamp parameter.
export const getCurrentDateDiffInMinutes = (timestamp: string) => {
  const fDate = new Date(timestamp).getTime();
  const lDate = new Date().getTime();
  let difference = (lDate - fDate) / (1000 * 60); // in minutes
  return difference;
};

export const getTimeTextFromTimestamp = (timestamp: number) => {
  let returnTime = '';

  try {
    const msgDate = new Date(timestamp).getTime(); //no need to sync message timestamp
    const currentDate = getSyncedTime();
    let dateDiff = currentDate - msgDate;

    let seconds = dateDiff / 1000;
    let minutes = seconds / 60;
    let hours = minutes / 60;
    let days = hours / 24;
    let weeks = days / 8;
    let months = weeks / 4;
    let years = months / 12;

    if (years > 1) {
      returnTime = Math.floor(years) + 'y';
    } else if (months >= 1) {
      returnTime = Math.floor(months) + 'mo';
    } else if (weeks >= 1) {
      returnTime = Math.floor(weeks) + 'w';
    } else if (days >= 1) {
      returnTime = Math.floor(days) + 'd';
    } else if (hours >= 1) {
      returnTime = Math.floor(hours) + 'h';
    } else if (minutes >= 1) {
      returnTime = Math.floor(minutes) + 'm';
    } else {
      returnTime = Math.floor(seconds) + 's';
    }
  } catch (error) {
    LogToLoot8Console(error);
    LogCustomError(
      'getTimeTextFromTimestamp',
      error.name,
      error.message,
      error.stack,
    );
  }

  return returnTime;
};

// Check active timestamp.
// By default, return false in case start & end time not present.
export const isActiveTimeStamp = (startTime: number, endTime: number) => {
  if (startTime && endTime) {
    const startDateTime = new Date(startTime);
    const endDateTime = new Date(endTime);
    const currentDate = new Date();

    if (currentDate >= startDateTime && currentDate <= endDateTime) {
      return true;
    }
  }
  return false;
};

// Check collection expiry.
// By default, return false in case end time not present.
export const isCollectionExpire = (endTime: number) => {
  if (endTime) {
    const endDateTime = new Date(endTime);
    const currentDate = new Date();
    if (currentDate > endDateTime) {
      return true;
    }
  }
  return false;
};

// Convert date & time in local format.
// By default, it returns date & time in this (12:00 AM, May 30th 2023) format.
export const getLocalDateAndTime = (
  date: number,
  dateFormat: string = 'h:mm A z, MMMM Do YYYY',
) => {
  if (date) {
    try {
      let tz = dayjs.tz.guess();
      if (tz) {
        return dayjs(date).tz(tz).format(dateFormat);
      } else {
        return dayjs(date).format(dateFormat);
      }
    } catch (error) {
      LogCustomError(
        'FAILED TO CONVERT TIMESTAMP IN LOCAL TIMEZONE',
        error.name,
        error.message,
        error.stack,
      );
      return dayjs(date).format(dateFormat);
    }
  }
  return null;
};

//Return current time adjusted with clock skew if any
export const getSyncedTime = (fromTimestamp: number = null) => {
  const clockSkew = getClockSkew();
  const originalTime = fromTimestamp
    ? new Date(fromTimestamp).getTime()
    : Date.now();

  if (clockSkew && clockSkew !== 0) {
    return originalTime + clockSkew;
  } else {
    return originalTime;
  }
};

// get Epoch Unix Timestamp.
// The unix time stamp is a way to track time as a running total of seconds.
// This count starts at the Unix Epoch on January 1st, 1970 at UTC.
// https://www.unixtimestamp.com/
// For example.
//      const d = new Date(0) -- Thu Jan 01 1970 05:30:00 GMT+0530 (India Standard Time)
//      d.setUTCSeconds(1692337535) -- 1692337535(unix time in seconds)
//      1692337535000 -- get current unix time in seconds.
//      new Date(1692337535000) -- Fri Aug 18 2023 11:15:35 GMT+0530 (India Standard Time)
export const getCurrentEpochUnixTimeStamp = (seconds: number) => {
  if (seconds) {
    // current date.
    const cDate = Date.now();
    // epoch date.
    const date = new Date(0);
    const convertedDate = date.setUTCSeconds(seconds);
    // compare both date.
    if (convertedDate <= cDate) {
      // return converted timestamp.
      return convertedDate;
    } else {
      // return original timestamp..
      return seconds;
    }
  }
};

export function formatTime(timestamp: string) {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // Convert midnight (0 hours) to 12 AM

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const formatDate = (unixTimestamp: number, withTimeString = false) => {
  const date = new Date(unixTimestamp * 1000);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  if (withTimeString) {
    options['hour'] = 'numeric';
    options['minute'] = 'numeric';
    options['hour12'] = true;
  }

  return date.toLocaleDateString('en-US', options);
};

// export function formatTime(timestamp: string) {
//   const date = new Date(timestamp);
//   const currentDate = new Date(); // Current date

//   // Check if the date is today's date
//   const isToday =
//     date.getDate() === currentDate.getDate() &&
//     date.getMonth() === currentDate.getMonth() &&
//     date.getFullYear() === currentDate.getFullYear();

//   let timeString = '';

//   if (isToday) {
//     // Format time only
//     const hours = date.getHours();
//     const minutes = date.getMinutes();
//     const ampm = hours >= 12 ? 'PM' : 'AM';
//     const formattedHours = String(hours % 12 || 12).padStart(2, '0'); // Handle midnight (0 hours)
//     const formattedMinutes = String(minutes).padStart(2, '0');
//     timeString = `${formattedHours}:${formattedMinutes} ${ampm}`;
//   } else {
//     // Format both date and time
//     const options = {
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit',
//       hour: '2-digit',
//       minute: '2-digit',
//     };
//     timeString = date.toLocaleDateString('en-US', options);
//   }

//   return timeString;
// }
