import { useState, useEffect } from 'react';
import { Dimensions } from 'react-native';

const useBigScreen = () => {
  const [isBigScreen, setIsBigScreen] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const updateScreenInfo = () => {
      const { width, height } = Dimensions.get('window');
      const isTablet = width > 600; // Adjust the threshold for what you consider a tablet
      const isLandscapeMode = width > height;

      setIsBigScreen(isTablet);
      setIsLandscape(isLandscapeMode);
    };

    // Call the function when the component mounts
    updateScreenInfo();

    // Subscribe to dimension changes
    const subscription = Dimensions.addEventListener(
      'change',
      updateScreenInfo,
    );

    // Clean up the subscription on component unmount
    return () => subscription.remove();
  }, []);

  // Check if in landscape mode or on a specific device type
  const isBigScreenAndNotMobile =
    isLandscape ||
    isBigScreen ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  return isBigScreenAndNotMobile;
};

export default useBigScreen;
