import React, { useState } from 'react';
import { Platform, Pressable, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { styles } from './styles';
import CachedImage from '../CachedImage';
import { defaultTheme } from '../../themes/loot8';
import FollowUnFollowButton from '../FollowUnFollowButton';
import useIsResponsive from '../../hooks/useIsResponsive';
import { VerifiedSvg } from '../../assets/svg/HomeSvgs';
import { ICollectibleDetail } from '../../interfaces/ICollectibleDetail.interface';
import ModalComponent from '../Modal';
import GradientButton from '../GradientButton';
import { AppDispatch } from '../../store';
import { unfollowCreator } from '../../slices/CreatorsSlice';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { CatalogTypes } from '../../enums/catalog.enum';
import { approveForUnsubscribe } from '../../slices/PassportSlice';
import { ScreenName } from '../../enums/screen.enum';

interface UserCardProps {
  item: ICollectibleDetail;
  onPress: (item: ICollectibleDetail) => void;
  navigation: any;
}

const UserCard: React.FC<UserCardProps> = ({ item, onPress, navigation }) => {
  const isResponsive = useIsResponsive();
  const dispatch = useDispatch<AppDispatch>();

  const { userInfo, networkId, staticProvider, wallet, address } =
    useWeb3AuthContext();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [unsubscribing, setUnsubscribing] = useState(false);

  async function onUnfollowPressed() {
    try {
      setUnsubscribing(true);
      setIsModalVisible(false);

      let tokenID: number[] = [];
      tokenID.push(item?.collectibleIds[0]);
      console.log('Clicked Unfollow', item?.address, tokenID[0]);
      // * Approve For Unsubscribe/Unfollow
      await dispatch(
        approveForUnsubscribe({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
          passportAddress: item?.address,
          tokenId: tokenID[0],
        }),
      );
      // * Unfollow/UnSubscribe Creator
      const response = await dispatch(
        unfollowCreator({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
          passportAddress: item.address,
          passportIds: tokenID,
          userInfo: userInfo,
          collectionType: CatalogTypes.PASSPORT,
        }),
      );

      if (response.payload) {
        navigation.navigate('Success', {
          screen: ScreenName.SUCCESS_SCREEN,
          params: {
            message: `You have successfully unfollowed ${item?.name}`,
          },
        });
      } else {
        setShowErrorModal(true);
      }
    } catch (error) {
      setShowErrorModal(true);
    } finally {
      setUnsubscribing(false);
    }
  }

  return (
    <Pressable
      onPress={() => onPress(item)}
      style={[styles.container, { flex: isResponsive ? 0.49 : 1 }]}>
      <View style={styles.row}>
        <>
          <RenderCachedImage
            isResponsive={false}
            resizeMode={'cover'}
            tileWidth={66}
            tileHeight={54}
            item={item}
          />
          <View style={{ marginLeft: 10, flex: 1 }}>
            <Text style={styles.title} numberOfLines={1}>
              {item.name}{' '}
              {item?.thirdPartyVerifiedURL?.length > 0 && <VerifiedSvg />}
            </Text>
            <Text style={styles.subtitle} numberOfLines={1}>
              {item.category}
            </Text>
          </View>
        </>
      </View>

      <ModalComponent
        showModal={isModalVisible}
        needCloseButton={true}
        enableHeader={false}
        onDismiss={() => setIsModalVisible(false)}>
        <View style={{ padding: 30, alignContent: 'center' }}>
          <Text style={{ color: 'white' }}>
            Are you sure you want to Unfollow this Creator?
          </Text>
        </View>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 30,
          }}>
          <GradientButton
            width="48%"
            type="secondary"
            buttonLabel="Cancel"
            onPress={() => setIsModalVisible(false)}
          />
          <GradientButton
            width="48%"
            buttonLabel="Unfollow"
            onPress={onUnfollowPressed}
            type="alternate"
          />
        </View>
      </ModalComponent>

      <ModalComponent
        showModal={showErrorModal}
        needCloseButton={true}
        enableHeader={false}
        onDismiss={() => {
          setShowErrorModal(false);
        }}>
        <View style={{ padding: 30, alignContent: 'center' }}>
          <Text style={styles.modalTextStyle}>Something went wrong.</Text>
          <Text style={styles.modalTextStyle}>Please try again!!</Text>
        </View>
      </ModalComponent>

      <FollowUnFollowButton
        onPress={() => setIsModalVisible(true)}
        width={95}
        status={true}
        loading={unsubscribing}
      />
    </Pressable>
  );
};

export default UserCard;

const RenderCachedImage = ({
  item,
  isResponsive,
  type = undefined,
  hovered = undefined,
  tileWidth,
  tileHeight,
  resizeMode,
}) => {
  return (
    <CachedImage
      isBackground
      isResponsive={isResponsive}
      item={item}
      type={type}
      hovered={hovered}
      noImageOnError={true}
      source={{
        uri: item?.thumbnailImage !== '' ? item?.thumbnailImage : item?.image,
      }}
      isThumbnailImage={item?.thumbnailImage !== '' ? true : false}
      imageStyle={{ borderRadius: defaultTheme.CONTENT_RADIUS }}
      style={{
        maxWidth: isResponsive ? '100%' : tileWidth - 12,
        minHeight: tileHeight !== null ? tileHeight : tileWidth - 60,
        aspectRatio: tileHeight !== null ? tileWidth / tileHeight : 41 / 25,
        borderRadius: defaultTheme.CONTENT_RADIUS,
        borderWidth: 1,
        borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
      }}
      contentFit={Platform.OS == 'android' ? 'cover' : resizeMode}
      imageSize={item?.imageSize}
      thumbnailImageSize={item?.thumbnailImageSize}
      optimizedImageSize={item?.optimizedImageSize}></CachedImage>
  );
};
