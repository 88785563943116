import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { Apps } from '../enums/apps.enum';
import { defaultTheme } from '../themes/loot8';
import { IApps } from '../interfaces/IApp.interface';
import GradientButton from '../components/GradientButton';
import { getData, storeData } from '../helpers/AppStorage';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { SELECTED_APP, SHOW_APP_SELECTION } from '../appconstants';
import HOCContainer from '../components/HOCContainer';

const AppSelectionScreen = () => {
  const { setShowAppSelection, setAppSelected } = useWeb3AuthContext();

  const handleSelectApp = async (key: IApps['app']) => {
    setShowAppSelection(false);
    setAppSelected(key);

    // Set selectedApp value in local storage
    await storeData(SELECTED_APP, key);

    // Set showAppSelection value in local storage
    await storeData(SHOW_APP_SELECTION, false);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Welcome to the Loot8 CMS portal!</Text>
      <Text style={styles.subTitle}>
        How would you like to engage with your community?
      </Text>

      <View style={styles.row}>
        <GradientButton
          width={'47.5%'}
          buttonLabel={'Join as a Creator'}
          onPress={() => handleSelectApp(Apps.EST_PORTAL)}
        />
        <GradientButton
          width={'47.5%'}
          buttonLabel={'Join as a Fan'}
          onPress={() => handleSelectApp(Apps.PATRON_APP)}
          bordered
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 90,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 25,
  },
  subTitle: {
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    marginTop: 50,
  },
  buttonWrap: {
    width: '100%',
    marginBottom: 20,
    alignItems: 'center',
  },
  title: {
    marginBottom: 6,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
});

export default HOCContainer({
  OriginalComponent: AppSelectionScreen,
  withBottomTabs: false,
  isScrollEnabled: false,
});
