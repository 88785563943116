import * as Sentry from 'sentry-expo';
import { IAppLoggerTags } from '../interfaces/IAppLoggerTags';
import { Platform } from 'react-native';
import { getData } from './AppStorage';
import { APP_STORAGE_NETWORKID, APP_STORAGE_USER_ADDRESS, IsLogEnable, getAppConfiguration } from '../appconstants';
import * as FileSystem from 'expo-file-system';
import { ensureDirExists } from './AppFileStorage';
import { LogToLoot8Console } from './Loot8ConsoleLogger';

const SENTRY = Platform.OS != 'ios' && Platform.OS != 'android' ? Sentry.Browser : Sentry.Native;
export const logsDir = `${FileSystem.documentDirectory}loot8-logs/`;

let config: any = {};
(async function loadAppConfing() {
  setTimeout(async () => {
    config = await getAppConfiguration();
  }, 1000);
})();

/* Enhance this file in case of any specific requirement. 

    Sample Warning Request
    --------------------------------
        LogInformation("Landing Screen",
            address,
            networkId,
            [{
                tag:"Screen",
                value: "Landing"
            },
            {
                tag: "Event",
                value: "On Login Button"
            }]
        ); 
    ----------------------------------
    Sample Error Request 
    ----------------------------------
        LogErrors(e,
            address,
            networkId,  
            [{
                tag: "LandingScreen",
                value: "On Login Button"
            }]
        );
*/

export const LogInformation = async (message: string, address: string, networkId: number, tags: IAppLoggerTags[]) => {
  LogMessage(message, address, networkId, tags, 'info');
};

export const LogWarning = (message: string, address: string, networkId: number, tags: IAppLoggerTags[]) => {
  LogMessage(message, address, networkId, tags, 'warning');
};

export const LogErrors = (error: any, address: string, networkId: number, tags: IAppLoggerTags[]) => {
  LogError(error, address, networkId, tags, 'error');
};

const LogMessage = (message: string, address: string, networkId: number, tags: IAppLoggerTags[], level: any) => {
  if (config?.sentry?.logMessageEnabled) {
    SENTRY.withScope(function (scope) {
      tags?.map(tag => {
        scope.setTag(tag.tag, tag.value);
      });
      if (address) {
        scope.setTag('wallet', address);
      }
      if (networkId) {
        scope.setTag('networkId', networkId);
      }
      scope.setLevel(level);
      SENTRY.captureMessage(message);
    });
  } else {
    LogToLoot8Console(message, address, networkId, tags, level);
  }
  writeToLocalLogs(message, address, networkId, tags, level);
};

const LogError = (error: any, address: string, networkId: number, tags: IAppLoggerTags[], level: any) => {
  if (config?.sentry?.logErrorEnabled) {
    SENTRY.withScope(function (scope) {
      tags?.map(tag => {
        scope.setTag(tag.tag, tag.value);
      });
      if (address) {
        scope.setTag('wallet', address);
      }
      if (networkId) {
        scope.setTag('networkId', networkId);
      }
      scope.setLevel(level);
      SENTRY.captureException(error);
    });
  } else {
    console.error(address, networkId, error, tags, level);
  }
  writeToLocalLogs(error?.toString(), address, networkId, [], 'error');
};

export const LogCustomError = async (
  method: any,
  name: string,
  messages: string,
  stack: string,
  tags: IAppLoggerTags[] = null,
) => {
  let address = await getData(APP_STORAGE_USER_ADDRESS);
  let networkId = await getData(APP_STORAGE_NETWORKID);

  if (!address) address = 'ADDRESS-UNDEFINED';
  if (!networkId) networkId = 'NETWORKID-UNDEFINED';

  if (config?.sentry?.logErrorEnabled) {
    SENTRY.withScope(function (scope) {
      scope.setTag('from', address);
      scope.setTag('networkId', networkId);
      scope.setTag('errorName', name);
      scope.setTag('errorMessage', messages);
      scope.setTag('stack', stack);
      tags?.map(tag => {
        scope.setTag(tag.tag, tag.value);
      });
      scope.setLevel('error');
      scope.setTag('dateTime', new Date().getTime());
      SENTRY.captureException(`APPLICATION-ERROR | ${method}`);
    });
  } else {
    console.error(address, networkId, method, name, messages, stack);
  }
  writeToLocalLogs(
    `APPLICATION-ERROR | ${method}`,
    address,
    networkId,
    [
      { tag: 'errorName', value: name },
      { tag: 'errorMessage', value: messages },
    ],
    'error',
  );
};

const writeToLocalLogs = async (
  message: string,
  address: string,
  networkId: number,
  tags: IAppLoggerTags[],
  level: any,
) => {
  try {
    if ((Platform.OS === 'android' || Platform.OS === 'ios') && config.localLogs && config.localLogs.enabled) {
      if (!address) address = await getData(APP_STORAGE_USER_ADDRESS);
      await ensureDirExists(logsDir);
      deleteOldLogs();
      const filename = new Date().toISOString().split('T')[0];
      const filePath = `${logsDir}${address}--${filename}.txt`;
      const fileInfo = await FileSystem.getInfoAsync(filePath);
      if (!fileInfo.exists) {
        await FileSystem.writeAsStringAsync(
          filePath,
          `**************** Logs ${address}-${filename} ****************\r\n`,
        );
      }
      const existingLogs = await FileSystem.readAsStringAsync(filePath);
      let combinedTags = '';
      if (tags && tags.length > 0) {
        tags.map(tag => (combinedTags += `${tag.tag}:${tag.value}&&`));
      }

      let log = `${existingLogs}\r\n${networkId}|${level}|${message}`;

      if (combinedTags) log += `|${combinedTags}`;

      await FileSystem.writeAsStringAsync(filePath, log + '\r\n');
    }
  } catch (error) {
    LogErrors(new Error('Exception in writing Log Files'), address, networkId, [
      { tag: 'error', value: 'Exception-writeToLocalLogs' },
      { tag: 'message', value: error.message },
      { tag: 'stack', value: error.stack },
    ]);
  }
};

const deleteOldLogs = async () => {
  try {
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      const files = await FileSystem.readDirectoryAsync(logsDir);

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        try {
          const fileInfo = await FileSystem.getInfoAsync(logsDir + file);
          const lastModifiedTime = Number.parseInt(fileInfo.modificationTime.toString()) * 1000;
          if ((Date.now() - lastModifiedTime) / 1000 > (config.localLogs.expiry || 432000)) {
            await FileSystem.deleteAsync(logsDir + file, { idempotent: true });
          }
        } catch {}
      }
    }
  } catch {}
};

export const readLocalLogFileList = async (walletAddress = '') => {
  try {
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      if (!walletAddress) walletAddress = await getData(APP_STORAGE_USER_ADDRESS);
      if (walletAddress) {
        const files = await FileSystem.readDirectoryAsync(logsDir);
        return files.filter(p => p.indexOf(walletAddress) > -1);
      }
      return await FileSystem.readDirectoryAsync(logsDir);
    }
  } catch {
    return null;
  }
};

export const readLocalLogFile = async logFileName => {
  try {
    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      return await FileSystem.readAsStringAsync(logsDir + logFileName);
    }
  } catch {
    return null;
  }
};
