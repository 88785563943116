import React, { createContext, useContext, useEffect, useState } from 'react';
import { Platform } from 'react-native';
// import Purchases, { PurchasesPackage } from 'react-native-purchases';

// import { CustomerInfo } from 'react-native-purchases';
import Constants, { ExecutionEnvironment } from 'expo-constants';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { useWeb3AuthContext } from './web3authContext';
import { LogCustomError } from '../helpers/AppLogger';

let Purchases, PurchasesPackage, CustomerInfo;

const APIKeys = {
  apple: Constants.expoConfig.extra.IOS_IN_APP_PURCHASE_REVENUECAT_KEY,
  google: Constants.expoConfig.extra.ANDROID_IN_APP_PURCHASE_REVENUECAT_KEY,
  web: null,
};

// `true` when running in Expo Go.
const isExpoGo = Constants.executionEnvironment === ExecutionEnvironment.StoreClient;
if (!isExpoGo) {
  let reactNativePurchase = require('react-native-purchases');
  Purchases = reactNativePurchase?.default;
  PurchasesPackage = reactNativePurchase?.PurchasesPackage;
  CustomerInfo = reactNativePurchase?.CustomerInfo;
}

interface RevenueCatProps {
  purchasePackage?: (pack: typeof PurchasesPackage) => Promise<boolean>;
  restorePermissions?: () => Promise<typeof CustomerInfo>;
  packages: (typeof PurchasesPackage)[];
}

const RevenueCatContext = createContext<RevenueCatProps | null>(null);

export const useRevenueCat = () => {
  return useContext(RevenueCatContext) as RevenueCatProps;
};

export const RevenueCatProvider = ({ children }: any) => {
  const { address, wallet, connected } = useWeb3AuthContext();

  const [packages, setPackages] = useState<(typeof PurchasesPackage)[]>([]);
  const [isReady, setIsReady] = useState(true);

  useEffect(() => {
    const init = async () => {
      if (!isExpoGo) {
        if (Platform.OS == 'android') {
          await Purchases.configure({ apiKey: APIKeys.google, appUserID: address });
        } else if (Platform.OS == 'ios') {
          await Purchases.configure({ apiKey: APIKeys.apple, appUserID: address });
        } else {
        }

        if (Platform.OS == 'android' || Platform.OS == 'ios') {
          await loadOfferings();
        }
      }

      setIsReady(true);
    };
    if (connected) {
      init();
    }
  }, [connected]);

  // load all offerings a user can purchase.
  const loadOfferings = async () => {
    try {
      const offerings = await Purchases.getOfferings();
      if (offerings.current) {
        setPackages(offerings.current.availablePackages);
      }
    } catch (e) {
      LogCustomError('RevenueCat | loadOfferings', e.name, e.message, e.stack);
    }
  };

  // purchase a package
  const purchasePackage = async (pack: typeof PurchasesPackage) => {
    try {
      await Purchases.purchasePackage(pack);
    } catch (e: any) {
      LogToLoot8Console(e);
      LogCustomError('RevenueCat | purchasePackage', e.name, e.message, e.stack, [
        {
          tag: 'packageIdentifier',
          value: pack?.identifier,
        },
      ]);
      return false;
    }
    return true;
  };

  const restorePermissions = async () => {
    const customer = await Purchases.restorePurchases();
    return customer;
  };

  const value = {
    restorePermissions,
    packages,
    purchasePackage,
  };

  if (!isReady) return <></>;

  return <RevenueCatContext.Provider value={value}>{children}</RevenueCatContext.Provider>;
};
