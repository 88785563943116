import { StyleSheet, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import { defaultTheme } from '../../themes/loot8';
import { getLoot8Tokens } from '../../helpers/Gadgets';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { useAppSelector } from '../../hooks';
import { LinearGradient } from 'expo-linear-gradient';
import { ActivityIndicator, TouchableRipple } from 'react-native-paper';
import token from '../../assets/Loot8TokenBlack.png';
import { Image } from 'react-native';

const GradientTokenBalance = () => {
  const [tokens, setTokens] = useState(0);
  const [refreshBalance, setRefreshBalance] = useState(false);
  const { userInfo, address, isWhitelistedWallet } = useWeb3AuthContext();
  const { UserData: userData, UserWalletBalance } = useAppSelector(
    state => state.AppUser,
  );
  const getLoot8Balance = async () => {
    let Loot8Tokens = await getLoot8Tokens(address);
    setTokens(Loot8Tokens);
  };
  useEffect(() => {
    getLoot8Balance();
  }, [UserWalletBalance]);
  const onRefreshBalance = async () => {
    setRefreshBalance(true);
    await getLoot8Balance();
    setRefreshBalance(false);
  };
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={[styles.container]}>
      <TouchableRipple
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onRefreshBalance}
        style={styles.ripple}>
        <>
          {refreshBalance ? (
            <>
              <ActivityIndicator
                size={15}
                color={defaultTheme.PRIMARY_TEXT_COLOR}
              />
            </>
          ) : (
            <Image
              source={token}
              resizeMode="contain"
              style={styles.tokenImg}
            />
          )}
          <Text style={styles.tokenText}>{tokens.toFixed(2)}</Text>
        </>
      </TouchableRipple>
    </LinearGradient>
  );
};

export default GradientTokenBalance;

const styles = StyleSheet.create({
  container: {
    height: 25,
    borderRadius: 32,
    overflow: 'hidden',
  },
  tokenText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: '#fff',
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    marginLeft: 10,
  },
  tokenImg: {
    height: 19,
    width: 19,
  },
  ripple: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 5,
  },
});
