import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  activeBg: {
    flex: 1,
    borderRadius: 25,
    overflow: 'hidden',
  },
  gradientBg: {
    flex: 1,
    borderRadius: 25,
    overflow: 'hidden',
    position: 'absolute',
    height: 2,
    bottom: 0,
    zIndex: 1,
  },
  contentContainer: {
    height: 48,
  },
  itemContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
});
