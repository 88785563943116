import React, { useState } from 'react';
import {
  View,
  Text,
  StyleSheet,
  SafeAreaView,
  TouchableOpacity,
} from 'react-native';
import { useDispatch } from 'react-redux';

import {
  onboardEntity,
  loadEntityDetails,
  checkInviteCodeValid,
} from '../slices/EntitySlice';
import {
  hideEstInviteCodeValidationModal,
  showEstInviteCodeValidationModal,
} from '../../slices/ModalsSlice';
import { wait } from '../../helpers/ipfs';
import { AppDispatch } from '../../store';
import { Apps } from '../../enums/apps.enum';
import { defaultTheme } from '../../themes/loot8';
import { SELECTED_APP } from '../../appconstants';
import ModalComponent from '../../components/Modal';
import { storeData } from '../../helpers/AppStorage';
import HOCContainer from '../../components/HOCContainer';
import { getUserOnboarded } from '../slices/AppUserSlice';
import GradientButton from '../../components/GradientButton';
import { jsonIpfsUpload } from '../services/ipfsUpload.service';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import TextInputComponent from '../../components/TextInputComponent';
import BackButton from '../components/BackButton';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';

const INVITE_CODE_LENGTH = 10;

const EstPortalAuthScreen = ({ navigation }) => {
  const [entityName, setEntityName] = useState('');
  const [error, setError] = useState(false);
  const [inviteCode, setInviteCode] = useState(null);
  const dispatch = useDispatch<AppDispatch>();
  const {
    userInfo,
    setUserInfo,
    networkId,
    staticProvider,
    address,
    wallet,
    setAppSelected,
  } = useWeb3AuthContext();

  const handleValidateCode = async () => {
    try {
      dispatch(showEstInviteCodeValidationModal());
      const isValidCode = await checkInviteCodeValid({
        networkID: networkId,
        provider: staticProvider,
        inviteCode: inviteCode.trim(),
        isOnboarding: true,
      });

      if (isValidCode) {
        const entity_obj = {
          // "name": "",
          description: '',
        };

        const ipfsURI = await jsonIpfsUpload(entity_obj, wallet, 'Entity.txt');

        const data = await dispatch(
          onboardEntity({
            networkID: networkId,
            provider: staticProvider,
            address,
            wallet,
            inviteCode: inviteCode.trim(),
            entityName: entityName.trim(),
            dataURI: ipfsURI,
          }),
        );
        await wait(100);
        if (data && data.payload) {
          const isOnboarded = await getUserOnboarded({
            networkID: networkId,
            provider: staticProvider,
            address,
          });
          await dispatch(
            loadEntityDetails({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet: wallet,
            }),
          );
          dispatch(hideEstInviteCodeValidationModal());

          setUserInfo({
            ...userInfo,
            isOnboarded,
          });
        } else {
          dispatch(hideEstInviteCodeValidationModal());
          setTimeout(() => {
            setError(true);
          }, 500);
        }
      } else {
        dispatch(hideEstInviteCodeValidationModal());
        setTimeout(() => {
          setError(true);
        }, 500);
      }
    } catch (err) {
      dispatch(hideEstInviteCodeValidationModal());
      LogToLoot8Console(`Invite Code Validation Error: ${err.message}`);
    }
  };

  const handleRequestCode = () => {
    navigation.navigate('EstPortalRequestCode');
  };

  const handleSwitchToPatronApp = async () => {
    setAppSelected(Apps.PATRON_APP);
    // Set selectedApp value in local storage
    await storeData(SELECTED_APP, Apps.PATRON_APP);
  };

  return (
    <>
      <SafeAreaView style={styles.container}>
        <BackButton hideBackIcon />

        <Text style={styles.description}>Hi, {userInfo.name}</Text>
        <Text style={styles.title}>You want to become a creator</Text>
        <Text style={[styles.subTitle, { marginBottom: 60 }]}>
          All you need is an access code!
        </Text>
        <TextInputComponent
          value={inviteCode}
          placeholder="Enter your access code"
          setOnChange={setInviteCode}
        />

        <View style={{ marginTop: 15 }} />
        <GradientButton
          disabled={inviteCode?.length !== INVITE_CODE_LENGTH}
          onPress={handleValidateCode}
          buttonLabel={'Get Started'}
        />
        <View style={{ marginTop: 15 }} />
        <View style={styles.footer}>
          <Text style={styles.subTitle}>Don’t have an access code?</Text>
          <TouchableOpacity activeOpacity={0.7} onPress={handleRequestCode}>
            <Text style={styles.linkText}>Request one from our team</Text>
          </TouchableOpacity>
        </View>
        <GradientButton
          onPress={handleSwitchToPatronApp}
          buttonLabel={
            userInfo?.isOnboarded ? 'Switch to Patron App' : 'Join as Fan'
          }
          bordered
        />
      </SafeAreaView>

      <ModalComponent
        showModal={error}
        needCloseButton={true}
        enableHeader={false}
        onDismiss={() => {
          setError(false);
        }}
        modalBodyStyle={styles.modalBody}>
        <Text style={styles.modalTitleStyle}>Invalid Code</Text>
        <View style={styles.separator} />
        <Text style={styles.modalTextStyle}>Please try again!</Text>
      </ModalComponent>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,

    paddingBottom: 15,
    alignItems: 'center',
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
  },
  title: {
    marginBottom: 6,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
  footer: {
    flex: 1,
    paddingBottom: 20,
    justifyContent: 'flex-end',
  },
  subTitle: {
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
  },
  description: {
    marginTop: 130,
    marginBottom: 20,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  modalBody: {
    width: '100%',
    paddingVertical: 25,
    paddingHorizontal: 15,
  },
  modalTitleStyle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    letterSpacing: 1,
    textAlign: 'center',
  },
  modalTextStyle: {
    letterSpacing: 1,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  linkText: {
    marginTop: 4,
    letterSpacing: 1,
    textAlign: 'center',
    textDecorationLine: 'underline',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    marginVertical: 10,
  },
});

export default HOCContainer({
  OriginalComponent: EstPortalAuthScreen,
  withBottomTabs: false,
  isScrollEnabled: false,
});
