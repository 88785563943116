import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import ProfileAvatar from '../ProfileAvatar';
import { getTimeTextFromTimestamp } from '../../helpers/DateHelper';
import { defaultTheme } from '../../themes/loot8';

const MessageSentPreviewComponent = ({
  userData,
  messageText,
  latestFriendRequest,
}) => {
  return (
    <View style={styles.container}>
      <ProfileAvatar
        style={{ backgroundColor: '#fff' }}
        size={40}
        source={
          userData?.avatarURI &&
          userData?.avatarURI != '' &&
          userData?.avatarURI != 'no-avatar' &&
          !userData?.avatarURI.includes('ipfs://') &&
          !userData?.avatarURI.includes('assets_avatars_p_') &&
          !userData?.avatarURI.includes('file:///')
            ? { uri: userData?.avatarURI }
            : null
        }
        userAddress={userData?.wallet}
        tickStyle={{ height: 12, width: 12 }}
      />
      <View style={styles.rightContainer}>
        <View style={styles.row}>
          <Text style={styles.userTitle}>{userData?.name}</Text>
          {latestFriendRequest?.timestamp && (
            <Text style={[styles.socialMessagingTimestampText, {}]}>
              {` ${getTimeTextFromTimestamp(latestFriendRequest?.timestamp)}`}
            </Text>
          )}
        </View>
        <Text style={styles.msgText}>{messageText}</Text>
      </View>
    </View>
  );
};

export default MessageSentPreviewComponent;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 15,
  },
  rightContainer: {
    marginLeft: 10,
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userTitle: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    maxWidth: '80%',
  },
  socialMessagingTimestampText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  msgText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
});
