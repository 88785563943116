import { Text, View } from 'react-native';
import React, { useState, useEffect } from 'react';
import LottieView from 'lottie-react-native';
import Loader from '../../assets/loader.json';
import styles from '../../styles';
import ModalComponent from '../Modal';

export default function InfoLoader({
  text1 = '',
  text2 = '',
  needChangeMessage = true,
}) {
  const [msgLine1, setMsgLine1] = useState(text1);
  const [msgLine2, setMsgLine2] = useState(text2);

  useEffect(() => {
    const changeMessage = () => {
      setMsgLine1('We apologize..!');
      setMsgLine2('We are almost there. Hold tight !!');
    };
    if (needChangeMessage) {
      setTimeout(() => {
        changeMessage();
      }, 10000);
    }
  }, []);

  return (
    <>
      <ModalComponent
        showModal={true}
        enableHeader={false}
        dismissable={false}
        showLoading={false}
        theme={'rgba(0, 0, 0, 0.75)'}>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <View
            style={{
              margin: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {msgLine1 !== '' && (
              <Text style={[styles.modalHeaderText, { paddingVertical: 3 }]}>
                {msgLine1}
              </Text>
            )}
            {msgLine2 !== '' && (
              <Text style={[styles.modalHeaderText, { paddingVertical: 3 }]}>
                {msgLine2}
              </Text>
            )}
          </View>
          <LottieView
            source={Loader}
            autoPlay
            loop
            style={{ width: 150, height: 100 }}
          />
        </View>
      </ModalComponent>
    </>
  );
}
