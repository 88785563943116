import { useMemo } from 'react';
import { curveBasis, line } from 'd3-shape';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { parse } from 'react-native-redash';
import useActiveDimensions from '../../../hooks/useActiveDimensions';
import useBigScreen from './useBigScreen';

type GenerateTabShapePath = (
  position: number,
  adjustedHeight: number,
  activeWidth: number,
  isBigScreen: boolean,
) => string;

const NUM_TABS = 5;
const SCALE = 1;
let TAB_BAR_HEIGHT = 64;
const generateTabShapePath: GenerateTabShapePath = (
  position,
  adjustedHeight,
  activeWidth,
  isBigScreen,
) => {
  const adjustedWidth = activeWidth / NUM_TABS;
  const tabX = adjustedWidth * position;
  const hollowDepth = isBigScreen && activeWidth > 450 ? 35 : 25;
  const lineGenerator = line().curve(curveBasis);
  const tab = lineGenerator([
    [tabX - 100 * SCALE, 0],
    [tabX - (65 + 35) * SCALE, 0],
    [tabX - (50 - 10) * SCALE, -4 * SCALE],
    [tabX - (50 - 15) * SCALE, (adjustedHeight - hollowDepth) * SCALE],
    [tabX + (50 - 15) * SCALE, (adjustedHeight - hollowDepth) * SCALE],
    [tabX + (50 - 10) * SCALE, -4 * SCALE],
    [tabX + (65 + 35) * SCALE, 0],
    [tabX + 100 * SCALE, 0],
  ]);

  return `${tab}`;
};

const usePath = () => {
  const activeWidth = useActiveDimensions().width;
  const insets = useSafeAreaInsets();
  const isBigScreen = useBigScreen();

  const activeHeight = useMemo(() => {
    return isBigScreen && activeWidth > 450 ? 80 : 64;
  }, [isBigScreen, activeWidth]);

  const tHeight = useMemo(() => {
    return activeHeight + insets.bottom;
  }, [activeHeight, insets]);

  const adjustedHeight = useMemo(() => {
    return tHeight - insets.bottom;
  }, [tHeight, insets]);

  const containerPath = useMemo(() => {
    return `M0,0L${activeWidth},0L${activeWidth},0L${activeWidth},${tHeight}L0,${tHeight}L0,0`;
  }, [tHeight, activeWidth]);

  const curvedPaths = useMemo(() => {
    return Array.from({ length: NUM_TABS }, (_, index) => {
      const tabShapePath = generateTabShapePath(
        index + 0.5,
        adjustedHeight,
        activeWidth,
        isBigScreen,
      );
      return parse(`${tabShapePath}`);
    });
  }, [adjustedHeight, activeWidth, isBigScreen]);

  return { containerPath, curvedPaths, tHeight };
};

export default usePath;
