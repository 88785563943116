import React, { memo, useEffect, useRef, useState } from 'react';
import { Text, View, Platform, FlatList } from 'react-native';
import { useDispatch } from 'react-redux';
import { Button } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import styles from '../styles';
import ModalComponent from '../components/Modal';
import HOCContainer from '../components/HOCContainer';
import AnimatedTabButtons from '../components/AnimatedTabButtons';
import useActiveDimensions from '../hooks/useActiveDimensions';
import HomeTabSection from '../components/HomeTabSection';
import ExploreTabSection from '../components/ExploreTabSection';
import useIsResponsive from '../hooks/useIsResponsive';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { AppDispatch } from '../store';
import { getUnsubsribedNotifications } from '../slices/NotificationSlice';
import { ModalDisconnected } from '../components/Modal/DisconnectedModal';
import { ScreenName } from '../enums/screen.enum';
import { useAppSelector } from '../hooks';
import {
  approveForSubscription,
  getArbitrumOnePassports,
  loadAvailablePassporDetails,
  subscribePassport,
  updateSubscriptionPrices,
} from '../slices/PassportSlice';
import {
  MIGRATION_STATUS,
  getAnynetStaticProvider,
  getAppConfiguration,
  getNetwork,
  getOldNetwork,
} from '../appconstants';
import { getData, storeData } from '../helpers/AppStorage';
import { CatalogTypes } from '../enums/catalog.enum';
import { Loot8Collection__factory } from '../typechain';
import { fetchUserSubscriptionsEvents } from '../helpers/GraphQLHelper';
import { defaultTheme } from '../themes/loot8';
// import { loadAvailableDigitalCollectibleDetails } from '../slices/DigitalCollectibleSlice';
import { needLocationChangeTrigger } from '../helpers/locationHelper';
import { MarketPlaceScreenNew } from './MarketPlaceScreen_New';

const isWeb = Platform.OS === 'web';

function MigrationComponent({
  address,
  wallet,
  userInfo,
  staticProvider,
  networkId,
}) {
  const dispatch = useDispatch<AppDispatch>();
  const allPassportDetailsList = useAppSelector(
    state => state.Passports.AllPassportDetailsList,
  );
  const initialPassportLoaded = useAppSelector(
    state => state.Passports.initialPassportLoaded,
  );
  const [showMigrationWindow, setShowMigrationWindow] = useState(false);

  useEffect(() => {
    (async () => {
      if (initialPassportLoaded) {
        const oldPassports = await getArbitrumOnePassports({
          networkID: getOldNetwork(),
          provider: getAnynetStaticProvider(getOldNetwork()),
          address,
        });

        if (oldPassports?.length > 0) {
          const appConfig = await getAppConfiguration();
          if (appConfig?.migration?.collections?.length > 0) {
            const allMigrationPassports = appConfig?.migration?.collections
              .filter(p => oldPassports.find(old => p[getOldNetwork()] == old))
              .map(p => p[getNetwork()]);

            if (allMigrationPassports?.length > 0) {
              const newPassportsToBeMigrated = allPassportDetailsList.filter(
                p =>
                  allMigrationPassports.includes(p.address) &&
                  p.collectibleIds?.length == 0,
              );
              if (newPassportsToBeMigrated?.length > 0) {
                newPassportsToBeMigrated.map(async (passport, index) => {
                  let subscribedPassports: IUserSubscriptions[] = [];
                  if (appConfig && appConfig.indexerService) {
                    subscribedPassports = await fetchUserSubscriptionsEvents(
                      address,
                      passport.address,
                    );
                  }
                  const hasOnceUnSubscribed = subscribedPassports.find(
                    p =>
                      p.isBuy == false &&
                      p.collectionAmount &&
                      Number(p.collectionAmount) > 0,
                  );
                  if (!hasOnceUnSubscribed) {
                    if (index == 0) {
                      const migrationStarted = await getData(MIGRATION_STATUS);
                      if (migrationStarted == undefined) {
                        setShowMigrationWindow(true);
                        await storeData(MIGRATION_STATUS, 'true');
                      }
                    }
                    const alreadyMinting = await getData(
                      '@migration_' + passport?.address,
                    );
                    if (alreadyMinting == undefined) {
                      await storeData(
                        '@migration_' + passport?.address,
                        'true',
                      );
                      //Final check on balance of passport, to avoid duplicate minting
                      const loot8Collectible = Loot8Collection__factory.connect(
                        passport.address,
                        staticProvider,
                      );
                      if (
                        !(Number(await loot8Collectible.balanceOf(address)) > 0)
                      ) {
                        let tokenID;
                        try {
                          await dispatch(
                            approveForSubscription({
                              networkID: networkId,
                              provider: staticProvider,
                              wallet: wallet,
                              address: address,
                            }),
                          );
                          tokenID = await dispatch(
                            subscribePassport({
                              networkID: networkId,
                              provider: staticProvider,
                              wallet: wallet,
                              address: address,
                              passportAddress: passport?.address,
                              passportSubscribePrice: 0,
                              userInfo: userInfo,
                              collectionType: CatalogTypes.PASSPORT,
                            }),
                          );
                        } catch (error) {
                          await AsyncStorage.removeItem(
                            '@migration_' + passport?.address,
                          );
                        }

                        if (tokenID?.payload !== '') {
                          await storeData(
                            '@migration_' + passport?.address,
                            tokenID.payload,
                          );
                        } else {
                          await AsyncStorage.removeItem(
                            '@migration_' + passport?.address,
                          );
                        }
                      }
                    }
                  }
                });
              }
            }
          }
        }
      }
    })();
  }, [initialPassportLoaded, allPassportDetailsList]);

  const onStartMigration = () => {
    setShowMigrationWindow(false);
  };

  return (
    <>
      {showMigrationWindow && (
        <ModalComponent
          showModal={showMigrationWindow}
          headerText="EXPass Migration"
          dismissable={false}>
          <View style={{ paddingTop: 30 }}>
            <Text style={[styles.modalTextStyle, { paddingHorizontal: 10 }]}>
              Kindly take a note that your existing ExPasses will not be visible
              under "My ExPasses" for sometime, during our ongoing transition.
              We appreciate your patience.
            </Text>
          </View>
          <View style={styles.modalButtonContainer}>
            <Button
              onPress={onStartMigration}
              style={styles.modalYesButtonStyle}
              labelStyle={styles.modalYesButtonLabelStyle}>
              Got it!
            </Button>
          </View>
        </ModalComponent>
      )}
    </>
  );
}

function HomeScreen({ navigation }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabsRef = useRef(null);
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();
  const dispatch = useDispatch<AppDispatch>();

  const userData = useAppSelector(state => state.AppUser.UserData);
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const {
    connected,
    networkId,
    staticProvider,
    address,
    userInfo,
    wallet,
    qrShimFlow,
    setQRShimFlow,
  } = useWeb3AuthContext();

  useEffect(() => {
    if (connected) {
      dispatch(
        getUnsubsribedNotifications({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
        }),
      );
      dispatch(updateSubscriptionPrices());
    }
    if (isWeb) {
      history.pushState(null, document.title, window.location.href);
      // history.back();
      window.onpopstate = () =>
        history.pushState(null, document.title, window.location.href);
    }
  }, []);

  useEffect(() => {
    if (
      isWeb &&
      new URLSearchParams(window.location.search).get('page') ===
        'marketplace-expasses'
    ) {
      navigation.navigate('WalletTab');
    } else if (
      isWeb &&
      new URLSearchParams(window.location.search).get('page') ===
        'portfolio-expasses'
    ) {
      navigation.navigate(ScreenName.CATALOG_TAB);
    } else if (isWeb && new URLSearchParams(window.location.search).get('user') === 'deactivate' ) {
      navigation.navigate('deactivate');
    }
  }, []);

  useEffect(() => {
    if (qrShimFlow) {
      setQRShimFlow(false);
      navigation.navigate(ScreenName.MORE_TAB, {
        screen: ScreenName.CATALOG_DETAILS,
        params: {
          catalog: qrShimFlow,
          availableCatalogs: null,
          type: 'Collectibles',
          from: ScreenName.BARCODE_TAB,
        },
      });
    }
  }, []);

  const goToNextSlide = (index: number) => {
    setActiveTabIndex(index);
    const offset = isResponsive
      ? index * defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
        defaultTheme.HORIZONTAL_PADDING * 2
      : index * activeWidth - defaultTheme.HORIZONTAL_PADDING * 2;
    tabsRef?.current.scrollToOffset({ offset });
  };

  useEffect(() => {
    const refreshPassportLoad = async () => {
      //need to check the debounce works
      if (userData && userData.isExist && currenLocation != null) {
        const trigger = await needLocationChangeTrigger(
          currenLocation.latitude,
          currenLocation.longitude,
        );
        if (trigger) {
          await dispatch(
            loadAvailablePassporDetails({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet,
              entities: [],
              userInfo,
              userLocation: currenLocation,
            }),
          );
          // await dispatch(
          //   loadAvailableDigitalCollectibleDetails({
          //     networkID: networkId,
          //     provider: staticProvider,
          //     address,
          //     wallet,
          //     entities: [],
          //     userInfo,
          //     userLocation: currenLocation,
          //   }),
          // );
        }
        // comment out whitelist digital collectible.
        // https://neuralmetrics.atlassian.net/browse/LOOT8-665
        // dispatch whitelist digital collectible.
        //await dispatch(loadAvailableWhitelistDigitalCollectible({ networkID: networkId, provider: staticProvider, address, wallet, entities: [], userInfo, userLocation: currenLocation }));
      }
    };
    setTimeout(() => {
      refreshPassportLoad();
    }, 100);
  }, [currenLocation]);
  return (
    <>
      <AnimatedTabButtons
        data={topTabsButtonData}
        changeSlidesOnPress={goToNextSlide}
      />
      <FlatList
        ref={tabsRef}
        data={topTabsButtonData}
        horizontal
        snapToAlignment="start"
        decelerationRate={'fast'}
        snapToInterval={activeWidth - defaultTheme.HORIZONTAL_PADDING * 2}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ flex: 1 }}
        renderItem={({ index }) => (
          <Slide activeTabIndex={activeTabIndex} index={index} />
        )}
        scrollEnabled={false}
      />
      {/* {connected && <Passport />} */}
      <MigrationComponent
        address={address}
        userInfo={userInfo}
        wallet={wallet}
        staticProvider={staticProvider}
        networkId={networkId}
      />
    </>
  );
}

const WrappedScreen = HOCContainer({
  OriginalComponent: HomeScreen,
  isScrollEnabled: false,
});

export default WrappedScreen;

const topTabsButtonData = [
  { id: 1, title: 'Home' },
  { id: 2, title: 'Explore' },
  { id: 3, title: 'Marketplace' },
];

const Slide = memo(
  ({ index, activeTabIndex }: { index: number; activeTabIndex: number }) => {
    const activeWidth = useActiveDimensions().width;
    const isResponsive = useIsResponsive();

    const renderContent = () => {
      if (index !== activeTabIndex) {
        return null; // If the index does not match the active tab index, return null to prevent unnecessary rendering
      }

      if (activeTabIndex === 0) {
        return <HomeTabSection />;
      } else if (activeTabIndex === 1) {
        return <ExploreTabSection />;
      } else if (activeTabIndex === 2) {
        return <MarketPlaceScreenNew />;
      }

      return <HomeTabSection />; // Default case
    };

    return (
      <View
        style={
          isResponsive
            ? {
                width:
                  defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
                  defaultTheme.HORIZONTAL_PADDING * 2,
              }
            : {
                width: activeWidth - defaultTheme.HORIZONTAL_PADDING * 2,
              }
        }>
        {renderContent()}
      </View>
    );
  },
);
