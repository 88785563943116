import React, { useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  Pressable,
  Text,
  View,
  Image,
  Platform,
  Linking,
  ScrollView,
  StyleSheet,
  FlatList,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Avatar, TouchableRipple } from 'react-native-paper';
import { Feather } from '@expo/vector-icons';
import { useDispatch } from 'react-redux';
import ActionSheet, { ActionSheetRef } from 'react-native-actions-sheet';
import { defaultTheme } from '../../themes/loot8';
import styles from '../../styles';
import ModalComponent from '../Modal';
import {
  PassportSubscriptionActions,
  PassportType,
} from '../../enums/passportCategory.enum';
import { AppDispatch } from '../../store';
import {
  approveForSubscription,
  approveForUnsubscribe,
  getPassportMarketSellPrice,
  pushPassportDetails,
  subscribePassport,
  unsubscribePassport,
} from '../../slices/PassportSlice';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import AppLoaderComponent from '../Loader';
import { BlurView } from 'expo-blur';
import { ReShareWebBlurView } from '../WebBlurView';
import { ScreenName } from '../../enums/screen.enum';
import {
  formatPriceUpToTrillion,
  identifySocialWebsiteFromURL,
  showToastMessage,
} from '../../helpers/Gadgets';
import { getCollectibleDetails } from '../../slices/OfferSlice';
import { useAppSelector } from '../../hooks';
import { CatalogTypes } from '../../enums/catalog.enum';
import facebookIcon from '../../assets/socialicons/facebook.png';
import twitterIcon from '../../assets/socialicons/twitter.png';
import tiktokIcon from '../../assets/socialicons/tiktok.png';
import instagramIcon from '../../assets/socialicons/instagram.png';
import verifiedIcon from '../../assets/verified_account.png';
import { SOCIAL_WEBSITE } from '../../enums/socialWebsite.enum';
import {
  getPassportFinalBuyPrice,
  updatePassportBuyPrice,
} from '../../slices/PassportSlice';
import HOCContainer from '../HOCContainer';
import GradientPrice from '../GradientPrice';
import { GradientInfoSvg } from '../../assets/svg/HomeSvgs';
import GradientButton from '../GradientButton';
import { getWalletTokenDetails } from '../../slices/helpers';
import { addresses } from '../../appconstants';

const zeroPrice = '0.00';

const ConfirmationSheetContent = ({
  passportAction,
  selectedEXPass,
  onCloseClick,
  windowWidth,
  EXPassName,
}) => {
  return (
    <View
      style={{
        padding: defaultTheme.HORIZONTAL_PADDING,
        alignItems: 'center',
      }}>
      {passportAction !== PassportSubscriptionActions.UNSUBSCRIBE && (
        <Text style={componentStyles.title}>{'Congratulations'}</Text>
      )}
      <Text
        style={
          passportAction !== PassportSubscriptionActions.UNSUBSCRIBE
            ? componentStyles.subtitle
            : componentStyles.title
        }>
        {passportAction === PassportSubscriptionActions.LISTORSELLEXPASS
          ? 'Your ExPass is listed for sale on the public marketplace'
          : passportAction === PassportSubscriptionActions.UNSUBSCRIBE
            ? 'You have successfully unsubscribed from ExPass ' + EXPassName
            : 'You have successfully subscribed to ' +
              selectedEXPass?.name?.trim() +
              ' ExPass'}
      </Text>

      <ConfirmButton
        onPress={() => {
          onCloseClick();
        }}
        title={
          passportAction === PassportSubscriptionActions.LISTORSELLEXPASS
            ? 'Open Market Place'
            : passportAction === PassportSubscriptionActions.UNSUBSCRIBE
              ? 'Return to Home'
              : 'Open ExPass'
        }
      />
    </View>
  );
};
const PassportBuySellConfirmation = ({
  route,
  navigation,
  setDynamicTitle,
}) => {
  let navigationFrom = route?.params?.navigationFrom;
  let selectedEXPass = route?.params?.selectedEXPass;
  let listSellPrice = route?.params?.listSellPrice
    ? Number(route?.params?.listSellPrice).toFixed(2)
    : zeroPrice;
  let currentLoot8Balance = route?.params?.currentLoot8Balance
    ? route?.params?.currentLoot8Balance
    : zeroPrice;
  //let sellMarketPrice =  route.params.sellMarketPrice ? Number(route.params.sellMarketPrice).toFixed(2) : zeroPrice;
  let passportAction: PassportSubscriptionActions =
    route?.params?.passportAction;
  //const onPassportSelection = route.params.onPassportSelection;
  const [passportBuyPrice, setPassportBuyPrice] = useState(
    selectedEXPass?.buyPrice
      ? Number(selectedEXPass?.buyPrice).toFixed(2)
      : zeroPrice,
  );
  const loading = useAppSelector(state => state.App.msgLoading);

  const { userInfo, networkId, staticProvider, address, wallet } =
    useWeb3AuthContext();
  const dispatch = useDispatch<AppDispatch>();
  const entityData = useAppSelector(state => state.Entity.EntityData);
  const EXPassId =
    selectedEXPass?.collectibleIds?.length > 0
      ? ' #' + selectedEXPass?.collectibleIds[0]
      : '';
  const EXPassName = selectedEXPass?.name + EXPassId;
  const [showConfirmationItems, setShowConfirmationItems] = useState(false);
  const [unsubscribCheck, setUnsubscribCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sellMarketPrice, setSellMarketPrice] = useState('0.00');
  const [currentBalance, setCurrentBalance] = useState(0);
  const [hasCurrentBalanceLoaded, setHasCurrentBalanceLoaded] = useState(true);
  const { width } = Dimensions.get('window');

  const windowWidth =
    width > defaultTheme.CONTENT_CONTAINER_MAX_WIDTH
      ? defaultTheme.CONTENT_CONTAINER_MAX_WIDTH
      : width;
  const confirmationSheetRef = useRef<ActionSheetRef>(null);
  let collectionPrice = selectedEXPass?.buyPrice;

  useEffect(() => {
    (async () => {
      setHasCurrentBalanceLoaded(true);
      const currentLoot8TokenBalance = await getWalletTokenDetails(
        addresses[networkId].Loot8Token,
        address,
        true,
      );
      if (currentLoot8TokenBalance && currentLoot8TokenBalance.walletBalance) {
        setCurrentBalance(currentLoot8TokenBalance.walletBalance);
      }
      setHasCurrentBalanceLoaded(false);
    })();

    return () => {
      setHasCurrentBalanceLoaded(true);
    };
  }, []);
  const sellXPassSummaryList =
    passportAction === PassportSubscriptionActions.LISTORSELLEXPASS
      ? [
          {
            id: 1,
            title: 'ExPass Name',
            value: EXPassName,
            isShow: true,
            showTokenSymbol: false,
          },
          {
            id: 2,
            title: 'Amount (Loot8 Token)',
            value: formatPriceUpToTrillion(listSellPrice),
            isShow: true,
            showTokenSymbol: true,
          },
          //{title: "From", value: userData, isShow: false, showTokenSymbol: false},
          //{title: "From address", value: userWallet, isShow: false, showTokenSymbol: false}
        ]
      : passportAction === PassportSubscriptionActions.UNSUBSCRIBE
        ? [
            {
              id: 1,
              title: 'ExPass Name',
              value: EXPassName,
              isShow: true,
              showTokenSymbol: false,
            },
            // {title: "Associated collectibles", value: selectedEXPass.collectibleCount ,isShow: false, showTokenSymbol: false},
            //{id: 2,title: "Initial Subscription Price", value: formatPriceUpToTrillion(passportInitialBuyPrice), isShow: true, showTokenSymbol: true},
            {
              id: 3,
              title: 'Tokens Expected After Unsubscribing',
              value: formatPriceUpToTrillion(sellMarketPrice),
              isShow: true,
              showTokenSymbol: true,
            },
            // {title: "From", value: userData, isShow: false, showTokenSymbol: false},
            // {title: "From address", value: userWallet, isShow: false, showTokenSymbol: false}
          ]
        : [
            {
              id: 1,
              title: 'ExPass Name',
              value: selectedEXPass?.name,
              isShow: true,
              showTokenSymbol: false,
            },
            {
              id: 2,
              title: 'Amount (Loot8 Token)',
              value: formatPriceUpToTrillion(passportBuyPrice),
              isShow: true,
              showTokenSymbol: true,
            },
            {
              id: 3,
              title: 'Current Balance',
              value: currentLoot8Balance,
              isShow: true,
              showTokenSymbol: true,
            },
            // {title: "From", value: userData, isShow: false, showTokenSymbol: false},
            // {title: "From address", value: userWallet, isShow: false, showTokenSymbol: false}
          ];

  const onConfirmClick = async () => {
    if (passportAction === PassportSubscriptionActions.SUBSCRIBE) {
      setIsLoading(true);
      await dispatch(
        approveForSubscription({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
        }),
      );
      const tokenID = await dispatch(
        subscribePassport({
          networkID: networkId,
          provider: staticProvider,
          wallet: wallet,
          address: address,
          passportAddress: selectedEXPass?.address,
          passportSubscribePrice: selectedEXPass?.buyPrice,
          userInfo: userInfo,
          collectionType: CatalogTypes.PASSPORT,
        }),
      );

      if (tokenID.payload !== '') {
        // Push to passport slice once the subscription for premium is verified
        if (selectedEXPass?.isPremium === true) {
          let passport = await getCollectibleDetails(
            {
              networkID: networkId,
              provider: staticProvider,
              collectibleAddress: selectedEXPass?.address,
              address,
              wallet,
            },
            { entityData },
            {
              isCache: false,
              isBalanceRefresh: false,
              isMarketPlaceRefresh: true,
            },
          );
          dispatch(
            pushPassportDetails({
              ...passport,
              passportType: PassportType.SUBSCRIBED,
            }),
          );
        }

        setShowConfirmationItems(true);
        onCloseClick();
      } else {
        setIsLoading(false);
        showToastMessage();
      }
    } else if (passportAction === PassportSubscriptionActions.UNSUBSCRIBE) {
      setIsLoading(true);
      if (selectedEXPass?.collectibleIds?.length > 0) {
        let tokenID: number[] = [];
        tokenID.push(selectedEXPass?.collectibleIds[0]);
        await dispatch(
          approveForUnsubscribe({
            networkID: networkId,
            provider: staticProvider,
            wallet: wallet,
            address: address,
            passportAddress: selectedEXPass?.address,
            tokenId: tokenID[0],
          }),
        );
        const txReceipt = await dispatch(
          unsubscribePassport({
            networkID: networkId,
            provider: staticProvider,
            wallet: wallet,
            address: address,
            passportAddress: selectedEXPass?.address,
            passportIds: tokenID,
            userInfo: userInfo,
            collectionType: CatalogTypes.PASSPORT,
          }),
        );

        // * Check for Event Logs emitted by the contract
        // * If event logs are present, then the transaction is successful
        if (txReceipt.payload.eventLogs.length > 0) {
          setIsLoading(false);
          setShowConfirmationItems(true);
          setTimeout(() => {
            confirmationSheetRef.current.show();
          }, 500);
        } else {
          setIsLoading(false);
          showToastMessage();
        }
      }
    } else if (
      passportAction === PassportSubscriptionActions.LISTORSELLEXPASS
    ) {
    }
  };
  const onCloseClick = async () => {
    setShowConfirmationItems(false);
    if (passportAction === PassportSubscriptionActions.LISTORSELLEXPASS) {
      confirmationSheetRef?.current?.hide();
    } else if (passportAction === PassportSubscriptionActions.SUBSCRIBE) {
      const subscribedEXPass = await getCollectibleDetails(
        {
          networkID: networkId,
          provider: staticProvider,
          collectibleAddress: selectedEXPass?.address,
          address,
          wallet,
        },
        { entityData },
        {},
      );
      if (subscribedEXPass) {
        setIsLoading(false);
        confirmationSheetRef?.current?.hide();
        navigation.navigate('Success', {
          screen: ScreenName.SUCCESS_SCREEN,
          params: {
            message: `You have successfully subscribed to ${selectedEXPass?.name}`,
            buttonLabel: 'Open ExPass',
            navigateTo: 'BottomTab',
            navigationParams: {
              screen: 'HomeTab',
              params: {
                screen: ScreenName.PASSPORT_DETAIL,
                params: {
                  passportAddress: subscribedEXPass.address,
                  chainId: subscribedEXPass.chainId,
                },
              },
            },
          },
        });
      }
    } else {
      if (navigationFrom && navigationFrom === ScreenName.CATALOG_DETAILS) {
        confirmationSheetRef?.current?.hide();
        navigation.goBack();
      } else {
        confirmationSheetRef?.current?.hide();
      }
    }
  };

  const onActionSheetClosed = () => {
    if (passportAction === PassportSubscriptionActions.SUBSCRIBE) {
      navigation.goBack();
    } else {
      if (navigationFrom && navigationFrom === ScreenName.CATALOG_LIST) {
        navigation.goBack();
      } else if (
        navigationFrom &&
        navigationFrom === ScreenName.CATALOG_DETAILS
      ) {
        confirmationSheetRef.current.hide();
        navigation.navigate('CatalogList', {
          type: CatalogTypes.PASSPORT,
        });
      } else {
        navigation.reset({
          index: 0,
          routes: [
            { name: 'HomeTab', params: { screen: 'HomeScreen', params: {} } },
          ],
        });
      }
    }
  };

  useEffect(() => {
    const getMarketSellPrice = async () => {
      if (
        passportAction === PassportSubscriptionActions.UNSUBSCRIBE &&
        selectedEXPass &&
        selectedEXPass.address
      ) {
        let marketSellPrice = 0;
        //console.log('selectedEXPass?.address', selectedEXPass?.address);
        marketSellPrice = await getPassportMarketSellPrice(
          selectedEXPass?.address,
          selectedEXPass?.collectibleIds?.length > 0
            ? selectedEXPass?.collectibleIds[0]
            : selectedEXPass?.tokenId ?? selectedEXPass.collectibleId,
        );
        //console.log('marketSellPrice', marketSellPrice);
        setSellMarketPrice(Number(marketSellPrice).toFixed(2));
      }
    };
    getMarketSellPrice();

    (async () => {
      // Fetch latest price before proceeding for purchase
      const buyPrice = await getPassportFinalBuyPrice(selectedEXPass?.address);
      dispatch(
        updatePassportBuyPrice({
          address: selectedEXPass?.address,
          buyPrice: buyPrice,
        }),
      );
      setPassportBuyPrice(buyPrice.toString());
    })();
  }, []);

  useEffect(() => {
    if (passportAction === PassportSubscriptionActions.UNSUBSCRIBE) {
      setDynamicTitle('Unsubscribe ExPass');
    } else if (
      passportAction === PassportSubscriptionActions.LISTORSELLEXPASS
    ) {
      setDynamicTitle('Sell ExPass');
    } else if (passportAction === PassportSubscriptionActions.SUBSCRIBE) {
      setDynamicTitle('Subscribe ExPass');
    }
  }, [passportAction]);
  return (
    <>
      <Text style={componentStyles.headline}>
        {passportAction === PassportSubscriptionActions.LISTORSELLEXPASS &&
          'Please verify the following details, before you list your ExPass on Market place'}
        {passportAction === PassportSubscriptionActions.UNSUBSCRIBE &&
          'Please verify the following details before you confirm to unsubscribe'}
        {passportAction === PassportSubscriptionActions.SUBSCRIBE &&
          'Please verify the following details, before you subscribe'}
      </Text>
      <View style={componentStyles.contentContainer}>
        <FlatList
          data={sellXPassSummaryList}
          renderItem={({ item, index }) => (
            <CardRow
              leftText={item.title}
              rightText={item.value}
              rightTokenImage={item.showTokenSymbol}
            />
          )}
          keyExtractor={(item, index) => index.toString()}
          ItemSeparatorComponent={() => (
            <View style={componentStyles.separator} />
          )}
        />
        {passportAction === PassportSubscriptionActions.SUBSCRIBE &&
          selectedEXPass?.thirdPartyVerifiedURL?.length > 0 && (
            <>
              <View style={componentStyles.separator} />
              <CardRow
                leftText={'Connected Profile'}
                subTitle={selectedEXPass?.thirdPartyVerifiedURL?.map(
                  (url, index) => url,
                )}
                socialRight
              />
            </>
          )}
        <View style={componentStyles.separator} />
        <CardRow
          leftText={'ExPass Description (Bio)'}
          subTitle={selectedEXPass?.details}
        />
      </View>
      {passportAction === PassportSubscriptionActions.SUBSCRIBE &&
        selectedEXPass?.thirdPartyVerifiedURL?.length > 0 && (
          <View style={componentStyles.warningContainer}>
            <GradientInfoSvg />
            <Text
              style={[
                componentStyles.headline,
                { textAlign: 'left', flex: 1, marginLeft: 5 },
              ]}>
              {
                'Before you subscribe to ExPass, please verify the connected profile of the creator.'
              }
            </Text>
          </View>
        )}
      <View style={{ flex: 1 }} />
      {passportAction === PassportSubscriptionActions.SUBSCRIBE && (
        <View
          style={[componentStyles.row, { marginBottom: 15, marginTop: 25 }]}>
          <Text style={componentStyles.italicText}>
            Available LOOT8 Balance: {currentBalance}
          </Text>
          {!hasCurrentBalanceLoaded && collectionPrice > currentBalance && (
            <Text
              style={[
                componentStyles.italicText,
                {
                  color: 'red',
                  textDecorationLine: 'none',
                },
              ]}>
              Insufficient Funds
            </Text>
          )}
        </View>
      )}
      <GradientButton
        disabled={
          (passportAction === PassportSubscriptionActions.UNSUBSCRIBE &&
            !unsubscribCheck) ||
          (passportAction === PassportSubscriptionActions.SUBSCRIBE &&
            !hasCurrentBalanceLoaded &&
            collectionPrice > currentBalance)
        }
        onPress={() => {
          onConfirmClick();
        }}
        buttonLabel={
          passportAction === PassportSubscriptionActions.LISTORSELLEXPASS
            ? 'List on Market Place'
            : passportAction === PassportSubscriptionActions.UNSUBSCRIBE
              ? 'Yes, Unsubscribe'
              : 'Subscribe'
        }
      />
      {passportAction === PassportSubscriptionActions.UNSUBSCRIBE && (
        <View style={componentStyles.warningContainer}>
          <Pressable
            onPress={() => setUnsubscribCheck(!unsubscribCheck)}
            style={{
              width: 14,
              height: 14,
              borderRadius: 2,
              alignItems: 'center',
              borderWidth: unsubscribCheck ? 0 : 2,
              backgroundColor: unsubscribCheck
                ? defaultTheme.GRADIENT_COLOR1
                : 'rgba(52, 52, 52, 0.5)',
              borderColor: unsubscribCheck
                ? 'rgba(52, 52, 52, 0.5)'
                : defaultTheme.GRADIENT_COLOR1,
              marginTop: 1,
              marginLeft: 3,
            }}
            hitSlop={{ bottom: 25, left: 25, right: 25, top: 25 }}>
            {unsubscribCheck && (
              <Feather
                name="check"
                size={14}
                color={defaultTheme.PRIMARY_TEXT_COLOR}
              />
            )}
          </Pressable>
          <Text
            style={[
              componentStyles.headline,
              { textAlign: 'left', flex: 1, marginLeft: 5 },
            ]}>
            Unsubscribing from an ExPass will forfeit the ability to sell it on
            the open market. Please Confirm if you would like to proceed
          </Text>
        </View>
      )}

      <ActionSheet
        ref={confirmationSheetRef}
        drawUnderStatusBar
        containerStyle={{
          ...styles.maxWidthAdjust,
          backgroundColor: 'transparent',
          overflow: 'hidden',
        }}
        gestureEnabled={true}
        backgroundInteractionEnabled={false}
        onClose={onActionSheetClosed}>
        {Platform.OS === 'web' ? (
          <ReShareWebBlurView>
            <View
              style={{
                borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
                borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
                overflow: 'hidden',
                backgroundColor: 'rgba(0,0,0,0.2)',
              }}>
              <LinearGradient
                colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
                start={{ x: 0.2, y: 0 }}
                end={{ x: 1, y: 1 }}
                style={{ ...styles.maxWidthAdjust }}>
                <ConfirmationSheetContent
                  passportAction={passportAction}
                  selectedEXPass={selectedEXPass}
                  onCloseClick={onCloseClick}
                  windowWidth={windowWidth}
                  EXPassName={EXPassName}
                />
              </LinearGradient>
            </View>
          </ReShareWebBlurView>
        ) : (
          <BlurView
            tint="dark"
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}
            intensity={Platform.OS === 'android' ? 15 : 25}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <ConfirmationSheetContent
                passportAction={passportAction}
                selectedEXPass={selectedEXPass}
                onCloseClick={onCloseClick}
                windowWidth={windowWidth}
                EXPassName={EXPassName}
              />
            </LinearGradient>
          </BlurView>
        )}
      </ActionSheet>

      {isLoading && !loading && <AppLoaderComponent deemBg={false} />}
    </>
  );
};

export default HOCContainer({
  OriginalComponent: PassportBuySellConfirmation,
});

const componentStyles = StyleSheet.create({
  headline: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    width: '60%',
    alignSelf: 'center',
    marginBottom: 25,
  },
  warningContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 25,
  },
  contentContainer: {
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    padding: 15,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardLeftText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  cardRightText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  leftSubtitleText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    marginTop: 2.5,
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    marginVertical: 15,
  },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginTop: 5,
  },
  subtitle: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginTop: 10,
  },
  btnText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  buttonContainer: {
    height: 48,
    width: '100%',
    borderRadius: defaultTheme.BUTTON_RADIUS,
    overflow: 'hidden',
    marginTop: 25,
  },
  ripple: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  italicText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textDecorationLine: 'underline',
  },
});

interface CardRowProps {
  leftText?: string;
  subTitle?: string;
  rightText?: any;
  rightTokenImage?: boolean;
  socialRight?: boolean;
}

const CardRow: React.FC<CardRowProps> = ({
  leftText,
  subTitle,
  rightText,
  rightTokenImage,
  socialRight,
}) => {
  const socialProfile = url => {
    const socialWebsite = identifySocialWebsiteFromURL(url);
    let socialIcon = verifiedIcon;
    switch (socialWebsite) {
      case SOCIAL_WEBSITE.FACEBOOK:
        socialIcon = facebookIcon;
        break;
      case SOCIAL_WEBSITE.INSTAGRAM:
        socialIcon = instagramIcon;
        break;
      case SOCIAL_WEBSITE.TIKTOK:
        socialIcon = tiktokIcon;
        break;
      case SOCIAL_WEBSITE.TWITTER:
        socialIcon = twitterIcon;
        break;
    }
    return socialIcon;
  };
  return (
    <>
      <View style={componentStyles.row}>
        <View style={{ flex: 1 }}>
          <Text style={componentStyles.cardLeftText}>{leftText}</Text>
          {subTitle && (
            <ScrollView
              contentContainerStyle={{ flexGrow: 1 }}
              style={{
                flex: 1,
                maxHeight: 120,
              }}
              showsVerticalScrollIndicator={false}>
              <Text style={componentStyles.leftSubtitleText}>{subTitle}</Text>
            </ScrollView>
          )}
        </View>
        <View style={componentStyles.row}>
          {rightTokenImage ? (
            <GradientPrice price={rightText} />
          ) : leftText === 'From' ? (
            <>
              <Avatar.Image
                style={[
                  styles.friendsAvatar,
                  {
                    backgroundColor: rightText.bgColor,
                    alignSelf: 'center',
                  },
                ]}
                size={20}
                source={
                  rightText.avatarURI &&
                  rightText.avatarURI != '' &&
                  rightText.avatarURI != 'no-avatar' &&
                  !rightText.avatarURI.includes('ipfs://') &&
                  !rightText.avatarURI.includes('assets_avatars_p_') &&
                  !rightText.avatarURI.includes('file:///')
                    ? { uri: rightText.avatarURI }
                    : require('../../assets/loot8-default-avatar.png')
                }
              />
              <Text style={componentStyles.cardRightText}>
                {rightText.name + ' #' + rightText.id}
              </Text>
            </>
          ) : socialRight ? (
            <Pressable
              onPress={() => Linking.openURL(`${subTitle}`)}
              style={{ width: 25, height: 25 }}>
              <Image
                source={socialProfile(`${subTitle}`)}
                resizeMode="contain"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Pressable>
          ) : (
            <Text style={componentStyles.cardRightText}>{rightText}</Text>
          )}
        </View>
      </View>
    </>
  );
};

const ConfirmButton = ({
  onPress,
  title,
  alternate,
}: {
  onPress: () => void;
  title: string;
  alternate?: string;
}) => {
  return (
    <View
      style={[
        componentStyles.buttonContainer,
        {
          backgroundColor: alternate
            ? 'rgba(255,255,255,0.2)'
            : defaultTheme.MAIN_BACKGROUND_COLOR,
        },
      ]}>
      <TouchableRipple
        style={componentStyles.ripple}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}>
        <Text style={componentStyles.btnText}>{title}</Text>
      </TouchableRipple>
    </View>
  );
};
