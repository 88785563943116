import React, { useCallback } from 'react';
import { StyleSheet, Text, View, Pressable } from 'react-native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { defaultTheme } from '../../themes/loot8';
import { ScreenName } from '../../enums/screen.enum';
import {
  PassportSubscriptionActions,
  PassportType,
} from '../../enums/passportCategory.enum';
import { getTruncatedName } from '../../helpers/Gadgets';

type ExpassThreeDotSettingsModalContentProps = {
  selectedEXPass: any;
  type: any;
  navigation: any;
  hideThreeDotSettingsModal: () => void;
  setSearchPassportText: () => void;
  onSellPassportClick: () => void;
  onTransferPress: () => void;
  isPassportListed: boolean;
};

const ExpassThreeDotSettingsModalContent: React.FC<
  ExpassThreeDotSettingsModalContentProps
> = ({
  selectedEXPass,
  isPassportListed,
  setSearchPassportText,
  hideThreeDotSettingsModal,
  onSellPassportClick,
  navigation,
  type,
  onTransferPress,
}) => {
  const enableOption = useCallback(
    () =>
      selectedEXPass?.marketPlaceConfig &&
      selectedEXPass.marketPlaceConfig.allowMarketPlaceOps &&
      selectedEXPass?.marketPlaceConfig?.publicTradeability,
    [selectedEXPass],
  );

  const onUnsubscribeClick = async () => {
    navigation.navigate(ScreenName.PASSPORT_BUY_SELL_CONFIRMATION, {
      selectedEXPass: selectedEXPass,
      passportAction: PassportSubscriptionActions.UNSUBSCRIBE,
    });
    setSearchPassportText('');
    hideThreeDotSettingsModal();
  };
  return (
    <View style={styles.container}>
      {enableOption() && (
        <Pressable
          onPress={onSellPassportClick}
          disabled={!enableOption()}
          style={styles.row}>
          <MaterialCommunityIcons
            name="format-list-bulleted"
            size={18}
            color={
              enableOption()
                ? defaultTheme.PRIMARY_TEXT_COLOR
                : 'rgba(255, 255, 255, 0.55)'
            }
          />
          <Text
            style={[
              styles.title,
              {
                color: enableOption()
                  ? defaultTheme.PRIMARY_TEXT_COLOR
                  : 'rgba(255, 255, 255, 0.55)',
              },
            ]}>
            {selectedEXPass?.marketPlaceConfig && isPassportListed
              ? 'Unlist from Marketplace'
              : 'List on Marketplace'}
          </Text>
        </Pressable>
      )}
      {selectedEXPass.passportType === PassportType.SUBSCRIBED && (
        <Pressable
          onPress={() => {
            onUnsubscribeClick();
          }}
          disabled={isPassportListed}
          style={styles.row}>
          <MaterialCommunityIcons
            name="minus-circle-outline"
            size={18}
            color={
              isPassportListed
                ? 'rgba(255, 255, 255, 0.55)'
                : defaultTheme.PRIMARY_TEXT_COLOR
            }
          />
          <Text
            style={[
              styles.title,
              {
                color: isPassportListed
                  ? 'rgba(255, 255, 255, 0.55)'
                  : defaultTheme.PRIMARY_TEXT_COLOR,
              },
            ]}>
            Unsubscribe ExPass
          </Text>
        </Pressable>
      )}
      {type === 'Collectible' && (
        <Pressable
          onPress={() => {
            onTransferPress();
          }}
          disabled={isPassportListed}
          style={styles.row}>
          <MaterialCommunityIcons
            name="arrow-right"
            size={18}
            color={
              isPassportListed
                ? 'rgba(255, 255, 255, 0.55)'
                : defaultTheme.PRIMARY_TEXT_COLOR
            }
          />

          <Text
            style={[
              styles.title,
              {
                color: isPassportListed
                  ? 'rgba(255, 255, 255, 0.55)'
                  : defaultTheme.PRIMARY_TEXT_COLOR,
              },
            ]}>
            {`Transfer ${getTruncatedName(selectedEXPass?.name)}`}
          </Text>
        </Pressable>
      )}
    </View>
  );
};

export default ExpassThreeDotSettingsModalContent;

const styles = StyleSheet.create({
  container: { paddingHorizontal: 15, paddingVertical: 25 },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginLeft: 10,
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // marginTop: 5,
    paddingVertical: 10,
  },
});
