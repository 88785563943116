import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { LinearGradient } from 'expo-linear-gradient';
import { defaultTheme } from '../../themes/loot8';
import {
  HomeSvg,
  MapSvg,
  MessageSvg,
  MoreSvg,
  WalletSvg,
} from '../../assets/svg/TabSvgs';

const AnimatedCircle = ({ expand, index }) => {
  const animatedActiveSize = useSharedValue(45);
  const bgOpacity = useSharedValue(0);

  const iconContainerStyle = useAnimatedStyle(() => {
    return {
      width: animatedActiveSize.value,
      height: animatedActiveSize.value,
    };
  });
  const bgOpacityStyle = useAnimatedStyle(() => {
    return {
      opacity: bgOpacity.value,
    };
  });
  //Icon container Gradient

  const renderTabIcon = () => {
    switch (true) {
      case index === 0:
        return <MessageSvg expand={expand} />;
      case index === 1:
        return <MapSvg expand={expand} />;
      case index === 2:
        return <HomeSvg expand={expand} />;
      case index === 3:
        return <WalletSvg expand={expand} />;
      case index === 4:
        return <MoreSvg expand={expand} />;

      default:
        return <MessageSvg expand={expand} />;
    }
  };
  useEffect(() => {
    if (expand) {
      animatedActiveSize.value = withTiming(64);
      bgOpacity.value = withTiming(1);
    } else {
      animatedActiveSize.value = withTiming(45);
      bgOpacity.value = withTiming(0);
    }
  }, [expand]);
  return (
    <Animated.View
      style={[
        iconContainerStyle,
        styles.iconContainer,
        expand && styles.shadowStyles,
      ]}>
      <View style={{ zIndex: 99 }}>{renderTabIcon()}</View>
      <Animated.View style={[styles.bg, bgOpacityStyle]}>
        <LinearGradient
          colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
          start={{ x: 0.2, y: 0.0001 }}
          end={{ x: 1, y: 1 }}
          style={styles.linearGradient}
        />
      </Animated.View>
    </Animated.View>
  );
};

export default AnimatedCircle;

const styles = StyleSheet.create({
  iconContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
  shadowStyles: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,

    elevation: 15,
  },
  bg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 50,
    overflow: 'hidden',
  },
  linearGradient: { flex: 1 },
});
