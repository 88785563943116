import { StyleSheet, Text, View } from 'react-native';
import React, { ReactNode } from 'react';
import { styles } from './styles';
import { defaultTheme } from '../../themes/loot8';

interface SectionHeadingProps {
  subtitle?: string;
  title: string;
  SmHeading?: boolean;
  renderRight?: ReactNode;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  subtitle,
  title,
  renderRight,
  SmHeading,
}) => {
  return (
    <>
      <View style={styles.row}>
        <Text
          style={[
            styles.title,
            {
              fontSize: SmHeading
                ? defaultTheme.FONT_SIZE_XXMEDIUM
                : defaultTheme.FONT_SIZE_XSMALL,
            },
          ]}>
          {title}
        </Text>
        {renderRight && renderRight}
      </View>
      {subtitle && <Text style={styles.subtitle}>{subtitle}</Text>}
    </>
  );
};

export default SectionHeading;
