import React from "react";
import Svg, {
  G,
  Path,
  Line,
  Defs,
  Stop,
  Circle,
  LinearGradient,
  Rect,
  ClipPath,
  Ellipse,
  RadialGradient,
  Image,
  Use,
} from "react-native-svg";
export function TXPendingSvg({ color }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.569"
      height="13.569"
      viewBox="0 0 13.569 13.569"
    >
      <G id="process" transform="translate(-17.164 494.836)">
        <Path
          id="Path_69104"
          dataName="Path 69104"
          d="M78.454-494.622a1.571,1.571,0,0,1-.031.329c-.04.145-.148.19-.443.19a6.112,6.112,0,0,0-3.659,1.4,6.588,6.588,0,0,0-1.261,1.443l-.06.108.136.045a1.54,1.54,0,0,1,.545.347l.128.125.176-.264a5.038,5.038,0,0,1,3.929-2.227c.352-.017.358-.014.44.054s.082.082.094.361c.008.2.023.287.045.287s1.21-.989,1.381-1.153c.037-.037.028-.057-.085-.162-.284-.264-1.256-1.085-1.3-1.094S78.454-494.8,78.454-494.622Z"
          transform="translate(-54.25 0)"
          fill="#fff"
        />
        <Path
          id="Path_69105"
          dataName="Path 69105"
          d="M347.274-438.052a1.51,1.51,0,0,1-.347.545l-.128.131.128.074a5.292,5.292,0,0,1,1.2,1.043,5.134,5.134,0,0,1,1.179,3.13c0,.19-.009.23-.068.3s-.082.077-.355.088c-.2.008-.287.023-.287.048s1.148,1.386,1.19,1.4,1.2-1.344,1.219-1.4c.009-.031-.034-.04-.213-.04-.446,0-.52-.065-.52-.46a6.144,6.144,0,0,0-.986-3.119,6.207,6.207,0,0,0-1.636-1.673C347.311-438.217,347.328-438.214,347.274-438.052Z"
          transform="translate(-320.272 -55.058)"
          fill="#fff"
        />
        <Path
          id="Path_69106"
          dataName="Path 69106"
          d="M17.764-315.127c-.324.378-.594.7-.6.727s.034.04.213.04c.446,0,.52.065.52.46,0,.131.014.347.028.477a6.091,6.091,0,0,0,2.7,4.38c.21.139.213.139.239.074a1.851,1.851,0,0,1,.347-.588l.153-.165-.173-.108a5.293,5.293,0,0,1-1.148-1.008,5.134,5.134,0,0,1-1.179-3.13c0-.19.009-.23.068-.3s.082-.077.355-.088c.2-.009.287-.023.287-.048s-1.148-1.386-1.193-1.4C18.37-315.808,18.091-315.5,17.764-315.127Z"
          transform="translate(0 -173.947)"
          fill="#fff"
        />
        <Path
          id="Path_69107"
          dataName="Path 69107"
          d="M202.2-164.864a5.1,5.1,0,0,1-3.707,2.332,4.319,4.319,0,0,1-.46.031c-.19,0-.23-.009-.3-.068s-.077-.082-.088-.355c-.009-.2-.023-.287-.048-.287s-1.386,1.148-1.4,1.193c-.011.028,1.344,1.2,1.4,1.216.031.009.04-.034.04-.213,0-.446.065-.52.46-.52a6.148,6.148,0,0,0,3.684-1.4,6.831,6.831,0,0,0,1.25-1.437l.06-.108-.136-.045a1.54,1.54,0,0,1-.545-.347l-.128-.125Z"
          transform="translate(-173.948 -320.466)"
          fill="#fff"
        />
      </G>
    </Svg>
  );
}
export function TXSuccessSvg({ color }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="12"
      viewBox="0 0 14 12"
    >
      <Path
        id="Path_69007"
        dataName="Path 69007"
        d="M29,619.094A3.059,3.059,0,0,1,32.021,616h7.958A3.059,3.059,0,0,1,43,619.094a3.094,3.094,0,0,1-1.954,2.9v2.963c0,.2,0,.391,0,.569a.488.488,0,0,1,0,.106,6.84,6.84,0,0,1-.048.779,1.616,1.616,0,0,1-1.547,1.541,11.944,11.944,0,0,1-1.46.052H34.013a11.944,11.944,0,0,1-1.46-.052,1.616,1.616,0,0,1-1.547-1.541,6.887,6.887,0,0,1-.048-.779.5.5,0,0,1,0-.106c0-.178,0-.368,0-.569V621.99A3.094,3.094,0,0,1,29,619.094Zm1.954,1.81a10.964,10.964,0,0,1,.052-1.365A1.615,1.615,0,0,1,32.552,618a11.96,11.96,0,0,1,1.461-.052h3.975a11.937,11.937,0,0,1,1.46.052,1.615,1.615,0,0,1,1.547,1.54,10.981,10.981,0,0,1,.052,1.366,2.137,2.137,0,0,0,.977-1.81,2.084,2.084,0,0,0-2.044-2.121H32.021a2.084,2.084,0,0,0-2.044,2.121A2.136,2.136,0,0,0,30.954,620.9Zm4.559-1.985H34.047a11.639,11.639,0,0,0-1.364.043.67.67,0,0,0-.709.706,11.641,11.641,0,0,0-.043,1.359v4.054h8.14v-4.054a11.45,11.45,0,0,0-.044-1.359.818.818,0,0,0-.2-.512.83.83,0,0,0-.513-.194,11.641,11.641,0,0,0-1.364-.043H36.489v2.739l.443-.515a.5.5,0,0,1,.15-.117.491.491,0,0,1,.184-.052.486.486,0,0,1,.409.8l-1.3,1.514a.494.494,0,0,1-.371.17.481.481,0,0,1-.2-.045.486.486,0,0,1-.167-.125l-1.3-1.514a.485.485,0,0,1,.053-.686.489.489,0,0,1,.689.053l.443.515Zm4.537,7.135h-8.1c.006.08.013.154.023.224a.818.818,0,0,0,.2.512.83.83,0,0,0,.513.194,11.639,11.639,0,0,0,1.364.043h3.907a11.641,11.641,0,0,0,1.364-.043.67.67,0,0,0,.709-.706C40.036,626.208,40.044,626.134,40.05,626.054Z"
        transform="translate(-29 -616)"
        fill="#fff"
        fillRule="evenodd"
      />
    </Svg>
  );
}
export function TXFailedSvg({ color }) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      viewBox="0 0 16 11"
    >
      <Defs>
        <ClipPath id="clip-path">
          <Rect
            id="Rectangle_8037"
            dataName="Rectangle 8037"
            width="16"
            height="11"
            transform="translate(-0.498)"
            fill="#fff"
          />
        </ClipPath>
      </Defs>
      <G
        id="Group_24255"
        dataName="Group 24255"
        transform="translate(0.498)"
        clipPath="url(#clip-path)"
      >
        <Path
          id="Path_68995"
          dataName="Path 68995"
          d="M29.784,686.567a.368.368,0,0,0,.147-.027.348.348,0,0,0,.122-.084l1.153-1.112a.358.358,0,0,0,.11-.258.363.363,0,0,0-.111-.259.387.387,0,0,0-.267-.107.38.38,0,0,0-.268.106l-.5.482v-4.3a.337.337,0,0,1,.023-.145.345.345,0,0,1,.082-.124.355.355,0,0,1,.128-.079.367.367,0,0,1,.15-.023h5.754a.392.392,0,0,0,.271-.108.362.362,0,0,0,0-.524.392.392,0,0,0-.271-.108H30.551a1.153,1.153,0,0,0-.82.319,1.1,1.1,0,0,0-.249.364,1.059,1.059,0,0,0-.082.428v4.3l-.5-.482a.392.392,0,0,0-.123-.08.388.388,0,0,0-.292,0,.394.394,0,0,0-.124.08.369.369,0,0,0-.083.119.358.358,0,0,0-.029.141.351.351,0,0,0,.03.141.361.361,0,0,0,.083.119l1.151,1.112a.351.351,0,0,0,.27.11Zm10.741-1.482a.383.383,0,0,0-.15.022.372.372,0,0,0-.128.08.35.35,0,0,0-.083.123.34.34,0,0,0-.023.145v2.223a.342.342,0,0,1-.023.145.35.35,0,0,1-.082.123.372.372,0,0,1-.128.08.381.381,0,0,1-.15.022H29.784a.392.392,0,0,0-.271.108.362.362,0,0,0,0,.524.392.392,0,0,0,.271.108h9.973a1.159,1.159,0,0,0,.444-.079,1.132,1.132,0,0,0,.376-.24,1.094,1.094,0,0,0,.249-.364,1.054,1.054,0,0,0,.082-.428v-2.221a.338.338,0,0,0-.023-.145.344.344,0,0,0-.083-.124.371.371,0,0,0-.128-.08A.384.384,0,0,0,40.525,685.085Zm2.187-5.816a3.142,3.142,0,0,0-4.3.029,2.89,2.89,0,0,0-.031,4.157,2.97,2.97,0,0,0,.98.657,3.054,3.054,0,0,0,1.169.232,3.314,3.314,0,0,0,2.187-.852,2.949,2.949,0,0,0,0-4.223Zm-3.8.555a2.278,2.278,0,0,1,.736-.489,2.337,2.337,0,0,1,.875-.177,2.574,2.574,0,0,1,1.342.407l-3.185,3.075a2.1,2.1,0,0,1-.43-1.449,2.121,2.121,0,0,1,.661-1.366Zm3.8,3.334a3.209,3.209,0,0,1-3.529,0l3.184-3.074C43.019,680.974,43.517,682.38,42.712,683.158Z"
          transform="translate(-28 -678)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.5"
        />
      </G>
    </Svg>
  );
}
