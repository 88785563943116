import React, { useState } from 'react';
import { View, Text, SafeAreaView, StyleSheet } from 'react-native';
import GradientButton from '../components/GradientButton';
import useActiveDimensions from '../hooks/useActiveDimensions';
import HOCContainer from '../components/HOCContainer';
import { defaultTheme } from '../themes/loot8';
import DeleteAccountConfirmationModal from '../components/Profile/DeleteAccountConfirmationModal';
import Checkbox from 'expo-checkbox';

const AccountDeletionSteps = () => {
  return (
    <View style={{ alignSelf: 'flex-start', marginTop: 22 }}>
      <Text
        style={{
          fontFamily: defaultTheme.FONT_FAMILY_BOLD,
          color: defaultTheme.PRIMARY_TEXT_COLOR,
          marginBottom: 10,
        }}>
        Steps To Delete Your Account
      </Text>
      <Text style={styles.stepItem}>- Press the delete button below</Text>
      <Text style={styles.stepItem}>- Confirm the deletion</Text>
      <Text style={styles.stepItem}>- Your account will be deleted</Text>

      <Text
        style={{
          fontFamily: defaultTheme.FONT_FAMILY_BOLD,
          color: defaultTheme.PRIMARY_TEXT_COLOR,
          marginBottom: 10,
          marginTop: 10,
        }}>
        Or You Can Delete Your Account By The Following Steps
      </Text>
      <Text style={styles.stepItem}>- Go to 'More' Tab</Text>
      <Text style={styles.stepItem}>- Click on 'Deactivate Account'</Text>
      <Text style={styles.stepItem}>- Confirm the deletion</Text>
      <Text style={styles.stepItem}>- Your account will be deleted</Text>
    </View>
  );
};

const StoreListingInfo = () => {
  return (
    <View
      style={{
        marginTop: 22,
        flexDirection: 'row',
        alignSelf: 'flex-start',
        gap: 4,
      }}>
      <View>
        <Text style={{ color: defaultTheme.PRIMARY_TEXT_COLOR }}>
          Application Name:
        </Text>
        <Text style={{ color: defaultTheme.PRIMARY_TEXT_COLOR }}>
          Developer Name:
        </Text>
      </View>
      <View>
        <Text style={{ color: defaultTheme.PRIMARY_TEXT_COLOR }}>LOOT8</Text>
        <Text style={{ color: defaultTheme.PRIMARY_TEXT_COLOR }}>
          Marcus Daley
        </Text>
      </View>
    </View>
  );
};

function DeactivateUserScreen() {
  const [showAccountDeletionModal, setShowAccountDeletionModal] =
    useState(false);
  const [checked, setChecked] = useState(false);
  const activeHeight = useActiveDimensions().height;

  const onDeleteAccount = async () => {
    setShowAccountDeletionModal(false);
  };

  const handleCheckbox = item => {
    setChecked(!checked);
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.item}>
        <Text style={styles.textPrimary}>Deactivate Your LOOT8 Account</Text>
        <Text style={styles.textSecondary}>
          {
            'By deleting your LOOT8 account, you will permanently erase your profile information and messages. Assets on the blockchain will remain associated with your account. but you will not access to the account with your email.'
          }
        </Text>
        <StoreListingInfo />
        <AccountDeletionSteps />
      </View>
      <View style={styles.checkboxContainer}>
        <Checkbox
          value={checked}
          onValueChange={newValue => handleCheckbox(newValue)}
          color={
            checked
              ? defaultTheme.MAIN_BACKGROUND_COLOR
              : defaultTheme.SECONDARY_TEXT_COLOR
          }
          style={{
            borderRadius: 6,
            borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
          }}
        />
        <Text
          style={{
            fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
            color: defaultTheme.PRIMARY_TEXT_COLOR,
            marginLeft: 2,
            textAlign: 'left',
          }}>
          <Text
            style={{
              fontFamily: defaultTheme.FONT_FAMILY_BOLD,
              color: defaultTheme.PRIMARY_TEXT_COLOR,
            }}>
            Note:
          </Text>{' '}
          This action is irreversible. Once your account is deleted, it cannot
          be restored.
        </Text>
      </View>
      <GradientButton
        buttonLabel={'Delete Account'}
        onPress={() => setShowAccountDeletionModal(true)}
        disabled={!checked}
      />

      {showAccountDeletionModal && (
        <DeleteAccountConfirmationModal
          showModal={showAccountDeletionModal}
          onDismiss={() => {
            setShowAccountDeletionModal(false);
          }}
          onDeleteAccount={onDeleteAccount}
          longMessage={false}
        />
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 20,
  },
  animationStyles: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  item: {
    flex: 1,
    marginTop: 50,
    alignItems: 'center',
  },
  textPrimary: {
    fontSize: 18,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
    textTransform: 'uppercase',
  },
  textSecondary: {
    fontSize: 16,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    textAlign: 'left',
    marginTop: 16,
  },
  checkboxContainer: {
    flex: 1,
    flexDirection: 'row',
    columnGap: 4,
    marginLeft: 10,
    alignItems: 'center',
    marginBottom: 20,
    maxHeight: 10,
  },
  stepItem: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    marginVertical: 3,
  },
});

export default HOCContainer({
  OriginalComponent: DeactivateUserScreen,
  withBottomTabs: false,
  isScrollEnabled: false,
});
