import React from 'react';
import { StyleSheet } from 'react-native';
import UserOwnedExpasses from '../UserOwnedExpasses';

interface ExperienceTabProps {
  closeSortingOptionsMenu: () => void;
  selectedSortingOption: number;
}
const ExperienceTabSection: React.FC<ExperienceTabProps> = ({
  closeSortingOptionsMenu,
  selectedSortingOption,
}) => {
  return (
    <UserOwnedExpasses
      showLimitedExpasses
      closeSortingOptionsMenu={closeSortingOptionsMenu}
      selectedSortingOption={selectedSortingOption}
    />
  );
};

export default ExperienceTabSection;

const styles = StyleSheet.create({});
