import React, { useEffect } from 'react';
import { Platform, ScrollView, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styles from '../../styles';
import { defaultTheme } from '../../themes/loot8';
import InfoLoader from '../Loader/InfoLoader';
import ModalComponent from '../Modal';

const AppPromptComponent = ({
  isThinClient,
  redeemedQRScanned,
  mintingInProgress,
  isRedirectingToApp,
  showMintingErrorMessage,
}) => {
  useEffect(() => {
    if (Platform.OS === 'web') {
      history.pushState(null, document.title, window.location.href);
      window.onpopstate = () =>
        history.pushState(null, document.title, window.location.href);
    }
  });

  return (
    <ScrollView style={{ backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR }}>
      <SafeAreaView style={[styles.landingContainer, { height: '100%' }]}>
        {
          // (isThinClient && navChoice == 'app' && mintSuccess && !mintingInProgress && !showMintingErrorMessage && !isRedirectingToApp) ?
          // ( <View style={{height: '100%'}}>
          //         <ModalComponent showModal={true} modalStyle={{marginTop: Platform.OS == 'android' ? 55 : Platform.OS == 'web' ? '4rem' : 100, justifyContent: 'center' }} headerText="Success!!" modalHeaderStyle={{ color: defaultTheme.TEXT_COLOR_PRIMARY }}
          //     modalBodyStyle={{ width: '80%', overflow: 'scroll', height: Platform.OS == 'web' ? '24vh' : Platform.OS == 'ios' ? '40%' : '30%' }}>
          //             <View style={{ margin: 10, justifyContent:'center', alignItems: 'center'}}>
          //                 <Text style={[styles.modalHeaderText, { paddingVertical: 3, fontSize: defaultTheme.FONT_SIZE_XSMALL }]}>Scan has been successful. You may now close this window.</Text>
          //             </View>
          //         </ModalComponent>
          //     </View>
          // ) :
          //(((navChoice || !isThinClient) && mintingInProgress && !showMintingErrorMessage) || isRedirectingToApp)
          mintingInProgress && !showMintingErrorMessage ? (
            <InfoLoader
              text1={
                isRedirectingToApp
                  ? 'Redirecting To App ...'
                  : 'Adding the scanned collectible to your portfolio...'
              }
              text2={
                isRedirectingToApp
                  ? ''
                  : 'You will be redirected as soon as addition completes'
              }
            />
          ) : (
            <View style={{ height: '100%' }}>
              {/* <ModalComponent showModal={showButtonsToContinue} modalStyle={{marginTop: Platform.OS == 'android' ? 55 : Platform.OS == 'web' ? '4rem' : 100, justifyContent: 'center' }} headerText="Choose an option to Continue..." modalHeaderStyle={{ color: defaultTheme.TEXT_COLOR_PRIMARY }}
                modalBodyStyle={{ width: '60%', overflow: 'scroll', height: Platform.OS == 'web' ? '18vh' : Platform.OS == 'ios' ? '40%' : '30%' }}>
                        <View style={{alignContent: 'center'}}>
                            <View style={{ display: 'flex' }}>
                                <Pressable>
                                    <Button mode="text" disabled = { navChoice == "web" } onPress={ () => { mintingInProgress ? setNavChoice("app") : loadAppOrRedirectToPlaystore(setNavChoice) } } labelStyle={{ color: "#000000", fontFamily: defaultTheme.FONT_FAMILY_MAIN }} style={{borderRadius: 5, marginRight: 10, marginTop: 10, backgroundColor: "rgb(235, 55, 151)"}}>
                                        {'Open in App'}
                                    </Button>
                                </Pressable>
                                <Pressable>
                                    <Button mode="text" disabled = { navChoice == "app" } onPress={ () => { mintingInProgress ? setNavChoice("web") : ( redeemedQRScanned ? setShowAppPromptComponent(true) : setShowAppPromptComponent(false) ) } } labelStyle={{ color: "#000000", fontFamily: defaultTheme.FONT_FAMILY_MAIN }} style={{borderRadius: 5, marginRight: 10, marginTop: 10, backgroundColor: "rgb(235, 55, 151)"}}>
                                        {'Continue on web'}
                                    </Button>
                                </Pressable>
                            </View>
                        </View>
                    </ModalComponent> */}
              <ModalComponent
                showModal={showMintingErrorMessage}
                modalStyle={{
                  marginTop:
                    Platform.OS == 'android'
                      ? 55
                      : Platform.OS == 'web'
                        ? '4rem'
                        : 100,
                  justifyContent: 'center',
                }}
                headerText="Error!!"
                modalHeaderStyle={{ color: defaultTheme.TEXT_COLOR_PRIMARY }}
                modalBodyStyle={{
                  width: '80%',
                  overflow: 'none',
                  height:
                    Platform.OS == 'web'
                      ? isThinClient
                        ? redeemedQRScanned
                          ? '18vh'
                          : '28vh'
                        : '18vh'
                      : Platform.OS == 'ios'
                        ? '40%'
                        : '30%',
                }}>
                <View
                  style={{
                    margin: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {redeemedQRScanned ? (
                    <Text
                      style={[
                        styles.modalHeaderText,
                        {
                          paddingVertical: 3,
                          fontSize: defaultTheme.FONT_SIZE_XSMALL,
                        },
                      ]}>
                      The scanned QR is already redeemed. Please try scanning
                      another QR code.
                    </Text>
                  ) : (
                    <Text
                      style={[
                        styles.modalHeaderText,
                        {
                          paddingVertical: 3,
                          fontSize: defaultTheme.FONT_SIZE_XSMALL,
                        },
                      ]}>
                      Failed to add the Collectible to your portfolio. This may
                      happen due to an invalid QR code or network congestion.
                    </Text>
                  )}
                </View>
              </ModalComponent>
            </View>
          )
        }
      </SafeAreaView>
    </ScrollView>
  );
};

export default AppPromptComponent;
