import { registerSheet } from 'react-native-actions-sheet';
import ReshareModalSheet from '../components/PassportMessage/ReshareModalSheet';
import PassportOptionsSheet from '../components/Passport/PassportOptionsSheet';
import SharePassportSheet from '../components/SharePassportSheet';
import SubscribePassportSheet from '../components/Passport/SubscribePassportSheet';
import ListingModalSheet from '../components/CatalogList/ListingModal';
import MarketPlaceConfirmationSheet from '../components/MarketPlace/MarketPlaceConfirmationSheet';
import ExpassThreeDotSettingsSheet from '../components/Passport/ExpassThreeDotSettingsSheet';
import SortModalSheet from '../components/Passport/SortModalSheet';
import TransferModalSheet from '../components/CatalogList/TransferModal';
import RedeemTicketSheet from '../components/Passport/Tabs/Events/RedeemTicketSheet';

registerSheet('ReshareModalSheet', ReshareModalSheet);
registerSheet('PassportOptionsSheet', PassportOptionsSheet);
registerSheet('SharePassportSheet', SharePassportSheet);
registerSheet('SubscribePassportSheet', SubscribePassportSheet);
registerSheet('ListingModalSheet', ListingModalSheet);
registerSheet('TransferModalSheet', TransferModalSheet);
registerSheet('MarketPlaceConfirmationSheet', MarketPlaceConfirmationSheet);
registerSheet('ExpassThreeDotSettingsSheet', ExpassThreeDotSettingsSheet);
registerSheet('SortModalSheet', SortModalSheet);
registerSheet('Loot8RedeemTicketSheet', RedeemTicketSheet);

export {};
