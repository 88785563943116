import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import '@web3auth/react-native-sdk';
import * as React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { configureFonts, Provider as PaperProvider } from 'react-native-paper';
import Web3AuthContextProvider from './hooks/web3authContext';
import { defaultTheme, fontConfig } from './themes/loot8';
import { UserPreferencesProvider } from './hooks/preferencesContext';
import * as Font from 'expo-font';
import { useEffect } from 'react';
import LandingScreen from './screens/LandingScreen';
import store from './store';
import { Provider } from 'react-redux';
import {
  LogBox,
  BackHandler,
  Platform,
  StatusBar,
  useColorScheme,
} from 'react-native';
import * as Device from 'expo-device';

import * as Sentry from 'sentry-expo';
import Constants from 'expo-constants';
import {
  APP_STORAGE_USER_KEY,
  APP_VERSION,
  NOTIFICATIONS,
  getAppConfiguration,
} from './appconstants';
import NetInfo from '@react-native-community/netinfo';
import AnimatedLoading from './components/Loader/animatedLoading';
import { RootSiblingParent } from 'react-native-root-siblings';
import { getData, storeData } from './helpers/AppStorage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  loadAvatarData,
  setNativeNotificationPermission,
} from './slices/AppSlice';
import { LogCustomError, LogInformation } from './helpers/AppLogger';
import { LogToLoot8Console } from './helpers/Loot8ConsoleLogger';
import * as Notifications from 'expo-notifications';
import { deleteAllCachedImages } from './components/CachedImage';
import { RevenueCatProvider } from './hooks/RevenueCatProvider';
import OnboardingComponent from './components/OnboardingComponent';
import { useAppSelector } from './hooks';
import AppLoaderComponent from './components/Loader';

// sentry will catch any unexpected crash.
// we will enhance sentry configuration based on different use cases later.
Sentry.init({
  dsn: 'https://e62df876aca744d885ce3ba34dd47836@o4504452626644992.ingest.sentry.io/4504452631953408',
  enableInExpoDevelopment: true,
  release: Constants.expoConfig.version,
  environment: Constants.expoConfig.extra.ENVIRONMENT,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

let customFonts = {
  'Inter-Black': require('./assets/fonts/Inter-Black.ttf'),
  'Inter-ExtraBold': require('./assets/fonts/Inter-ExtraBold.ttf'),
  'Inter-Bold': require('./assets/fonts/Inter-Bold.ttf'),
  'Inter-SemiBold': require('./assets/fonts/Inter-SemiBold.ttf'),
  'Inter-Medium': require('./assets/fonts/Inter-Medium.ttf'),
  'Inter-Regular': require('./assets/fonts/Inter-Regular.ttf'),
  'Inter-Light': require('./assets/fonts/Inter-Light.ttf'),
  'Inter-ExtraLight': require('./assets/fonts/Inter-ExtraLight.ttf'),
  'Inter-Thin': require('./assets/fonts/Inter-Thin.ttf'),
  'Inter-MediumItalic': require('./assets/fonts/Inter-MediumItalic.ttf'),
};

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export const registerForPushNotificationsAsync = async (
  isReqPermission = true,
) => {
  let permissionGranted = false;
  if (Device.isDevice && Platform.OS !== 'web') {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted' && isReqPermission) {
      const { status } = await Notifications.requestPermissionsAsync({
        ios: {
          allowAlert: true,
          allowBadge: true,
          allowSound: true,
        },
      });
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      LogToLoot8Console('Notification permission denied by user.');
      LogInformation('Notification permission denied by user.', null, 0, []);
      permissionGranted = false;
    } else {
      LogToLoot8Console('Notification permission granted by user.');
      LogInformation('Notification permission granted by user.', null, 0, []);
      permissionGranted = true;
    }
  } else {
    LogToLoot8Console(
      'Must use physical device & should be mobile app for Native Notifications',
    );
  }

  if (Platform.OS === 'android' && Device.isDevice) {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return permissionGranted;
};

// todo: uncomment this code when background service is need.
// TaskManager.defineTask(LOCATION_API_TASK, ({ data: { locations }, error }) => {
//   if (error) {
//     LogToLoot8Console(`Task - ${LOCATION_API_TASK} - error`, error);
//     return;
//   }
//   if(locations && locations.length > 0) {
//     //LogToLoot8Console('Received new locations', locations[0].coords?.latitude, locations[0].coords?.longitude );
//     store.dispatch(loadLocation({
//       latitude: locations[0].coords?.latitude,
//       longitude: locations[0].coords?.longitude
//     }));
//   }
// });

// TODO: remove warning logs
// Ignore log notification by message
LogBox.ignoreLogs(['Warning: ...']);

//Ignore all log notifications
LogBox.ignoreAllLogs();

export default function App() {
  const [fontsLoaded, setFontsLoaded] = React.useState(false);
  const [networkConnect, setNetworkConnect] = React.useState(null);
  const [avatarsLoaded, setAvatarsLoaded] = React.useState(false);

  const loadFontsAsync = async () => {
    const PERMANENT_KEYS = [NOTIFICATIONS, APP_STORAGE_USER_KEY];

    const userKey = await getData(APP_STORAGE_USER_KEY);
    const versionNumber = await getData(APP_VERSION);
    if (userKey && Constants.expoConfig.version !== versionNumber) {
      try {
        const allKeys = await AsyncStorage.getAllKeys();
        await AsyncStorage.multiRemove(
          allKeys.filter(
            p => PERMANENT_KEYS.findIndex(key => p.startsWith(key)) === -1,
          ),
        );
        if (
          String(versionNumber).startsWith('4') &&
          Constants.expoConfig.version.startsWith('5')
        ) {
          LogToLoot8Console('enter into migration version');
          //migration version
          await AsyncStorage.removeItem(APP_STORAGE_USER_KEY);
        }
        await deleteAllCachedImages(); //Loot8-1898: gif stops animating after app upgrade, clearing cache from File System to resolve it
      } catch (e) {
        LogToLoot8Console('Error during clearing data', e);
        LogCustomError(
          'Error during clearing data',
          e.name,
          e.message,
          e.stack,
        );
      }
    }

    await storeData(APP_VERSION, Constants.expoConfig.version);
    LogInformation('App/Version' + Constants.expoConfig.version, null, 0, []);

    await Font.loadAsync(customFonts);
    setFontsLoaded(true);

    if (Platform.OS != 'web') {
      BackHandler.addEventListener('hardwareBackPress', () => {
        return true;
      });
    }
  };

  const loadAvatarFromJson = async () => {
    await store.dispatch(loadAvatarData({ networkID: null, provider: null }));
    setAvatarsLoaded(true);
  };

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      if (networkConnect != state.isConnected)
        setNetworkConnect(state.isConnected);
    });
    unsubscribe();
    loadAvatarFromJson();
    // load app configuration
    getAppConfiguration().then(() => {
      loadFontsAsync();
    });
    if (Device.isDevice) {
      registerForPushNotificationsAsync().then(permissionGranted => {
        store.dispatch(
          setNativeNotificationPermission({
            permissionGranted: permissionGranted,
          }),
        );
      });
    }
  }, []);

  let theme = {
    ...defaultTheme,
    fonts: configureFonts({ config: fontConfig }),
  };

  function FullScreenLoader() {
    const loading = useAppSelector(state => state.App.msgLoading);

    return (
      loading && (
        <AppLoaderComponent
          extraStyle={{ backgroundColor: 'rgba(0,0,0,0.6)' }}
        />
      )
    );
  }

  const isDarkMode = useColorScheme() === 'light';
  return (
    fontsLoaded &&
    avatarsLoaded && (
      <SafeAreaProvider>
        <Web3AuthContextProvider>
          <RevenueCatProvider>
            <Provider store={store}>
              <UserPreferencesProvider>
                <PaperProvider theme={theme}>
                  <RootSiblingParent>
                    <StatusBar
                      barStyle={isDarkMode ? 'light-content' : 'dark-content'}
                      backgroundColor={theme.MAIN_BACKGROUND_COLOR}
                    />
                    {networkConnect ? <LandingScreen /> : <AnimatedLoading />}
                    {/* <AnimatedLoading /> */}
                    <OnboardingComponent />
                    <FullScreenLoader />
                  </RootSiblingParent>
                </PaperProvider>
              </UserPreferencesProvider>
            </Provider>
          </RevenueCatProvider>
        </Web3AuthContextProvider>
      </SafeAreaProvider>
    )
  );
}
