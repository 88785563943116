import { Pressable, StyleSheet, Text, View } from 'react-native';
import React from 'react';
import ProfileAvatar from '../ProfileAvatar';
import { Title } from 'react-native-paper';
import { defaultTheme } from '../../themes/loot8';

const MessagesHeaderComponent = ({ user, openModalPopup }) => {
  return (
    <View style={styles.container}>
      <Pressable onPress={() => openModalPopup(user, true)}>
        {user && (
          <ProfileAvatar
            style={{ backgroundColor: user.bgColor }}
            size={30}
            source={
              user.avatarURI &&
              user.avatarURI != '' &&
              user.avatarURI != 'no-avatar' &&
              !user.avatarURI.includes('ipfs://') &&
              !user.avatarURI.includes('assets_avatars_p_') &&
              !user.avatarURI.includes('file:///')
                ? { uri: user.avatarURI }
                : null
            }
            userAddress={user?.wallet}
          />
        )}
      </Pressable>

      <Title style={styles.userTitle}>
        {`${user?.name.trim()}`}
        <Text style={styles.friendUserIdText}>{` #${user?.id}`}</Text>
      </Title>
    </View>
  );
};

export default MessagesHeaderComponent;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5,
  },
  userTitle: {
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    marginLeft: 5,
  },
  friendUserIdText: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
});
