import { StyleSheet } from 'react-native';

export const innerStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  flatList: {
    flex: 1,
  },
  contentContainer: {
    flexGrow: 1,
  },
  columnWrapper: {
    justifyContent: 'space-between',
  },
  separator: {
    marginVertical: 5,
  },
});
