export enum UICustomOfferType {
  EMPTY = '',
  REGULAR = 'Regular',
  FEATURED_OFFER = 'Featured',
  EXPERIENCE = 'Experience',
  TICKET = 'Ticket',
  REGULAR_OR_FEATURED_OFFER = 'REGULAR_OR_FEATURED_OFFER',
  DIGITAL_COLLECTIBLES = 'DIGITAL_COLLECTIBLES',
  COUPON = 'Coupon',
}

export enum OfferType {
  NOTANOFFER,
  FEATURED,
  REGULAR,
}
