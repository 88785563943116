import React, { useCallback, useEffect, useState } from 'react';
import {
  Platform,
  Pressable,
  useWindowDimensions,
  View,
  Image,
} from 'react-native';
import MapView, { Marker } from 'react-native-maps';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { useAppSelector } from '../../hooks';
import { IMapMarker } from '../../interfaces/ILocation.interface';
import { defaultTheme } from '../../themes/loot8';
import styles from '../../styles';
import ModalComponent from '../Modal';
import { TABLET_DIMENTION, getAppConfiguration } from '../../appconstants';
import Markers from './Markers';
import MapParameterItem from './MapParameterItem';

const HOTEL_ZIGGY = {
  id: '0',
  title: 'Hotel Ziggy',
  coordinate: { latitude: 34.0943304, longitude: -118.3770287 },
  description: '',
};

type MapComponentProps = {
  initialLocation: IMapMarker;
};

const INITIAL_WEB_ZOOM = 12;
const WEB_ZOOM_LEVEL_TO_SHOW_IMAGE = 6;
const MOBILE_ZOOM_LEVEL_TO_SHOW_IMAGE = 9;
const IOS_ZOOM_LEVEL_TO_SHOW_IMAGE = 3000;

const MapComponent = ({ initialLocation }: MapComponentProps) => {
  const mapRef = React.useRef<MapView>(null);
  const userStoredLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const { height, width } = useWindowDimensions();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showParameters, setShowParameters] = useState(false);
  const [currentSelectedMarkerIndex, setCurrentSelectedMarkerIndex] =
    useState(0);
  const [showPinImage, setShowPinImage] = useState(false);
  const [currentRegion, setCurrentRegion] = useState(
    initialLocation?.coordinate,
  );
  const [mapZoomLevel, setMapZoomLevel] = useState(
    width >= TABLET_DIMENTION.width
      ? WEB_ZOOM_LEVEL_TO_SHOW_IMAGE
      : Platform.OS == 'ios'
        ? IOS_ZOOM_LEVEL_TO_SHOW_IMAGE
        : MOBILE_ZOOM_LEVEL_TO_SHOW_IMAGE,
  );
  const [withoutGeofencedItems, setWithoutGeofencedItems] = useState([]);
  const [initialWebZoom, setInitialWebZoom] = useState(true);

  const fitMapToLocation = async () => {
    const coordinates = initialLocation?.coordinate;
    mapRef.current?.animateToRegion(
      {
        latitude: coordinates?.latitude,
        longitude: coordinates?.longitude,
        latitudeDelta: Platform.OS === 'web' ? 0.001 : 0.01,
        longitudeDelta: Platform.OS === 'web' ? 0.001 : 0.01,
      },
      2000,
    );

    if (Platform.OS === 'web') {
      const zoom = 15;
      mapRef.current?.animateCamera(
        {
          zoom,
          center: {
            latitude: coordinates?.latitude,
            longitude: coordinates?.longitude,
          },
        },
        { duration: 2000 },
      );
    }
  };

  const selectedMarkerIndex = (next: boolean) => {
    if (
      next &&
      currentSelectedMarkerIndex !== selectedMarker?.locationList?.length - 1
    ) {
      setCurrentSelectedMarkerIndex(currentSelectedMarkerIndex + 1);
    } else {
      setCurrentSelectedMarkerIndex(currentSelectedMarkerIndex - 1);
    }
  };

  const resetSelectedMarker = () => {
    setCurrentSelectedMarkerIndex(0);
    setSelectedMarker(null);
    setShowParameters(false);
  };

  useEffect(() => {
    if (initialLocation?.coordinate) {
      setCurrentRegion(initialLocation?.coordinate);
    }
  }, [initialLocation]);

  const withoutGeofencedCallback = useCallback(
    value => {
      setWithoutGeofencedItems(value);
    },
    [withoutGeofencedItems],
  );

  const getZoomlevelFromAppConfig = async () => {
    const appConfig = await getAppConfiguration();
    if (width >= TABLET_DIMENTION.width) {
      if (appConfig?.map?.webZoomLevelToShowImage) {
        setMapZoomLevel(appConfig?.map?.webZoomLevelToShowImage);
      }
    } else {
      if (Platform.OS == 'ios') {
        if (appConfig?.map?.iosMobileZoomLevelToShowImage) {
          setMapZoomLevel(appConfig?.map?.iosMobileZoomLevelToShowImage);
        }
      } else {
        if (appConfig?.map?.mobileZoomLevelToShowImage) {
          setMapZoomLevel(appConfig?.map?.mobileZoomLevelToShowImage);
        }
      }
    }
  };

  useEffect(() => {
    getZoomlevelFromAppConfig();
  }, []);

  return (
    <>
      {!(
        isNaN(initialLocation?.coordinate?.latitude) &&
        isNaN(initialLocation?.coordinate?.longitude)
      ) ? (
        <>
          <MapView
            style={styles.mapComponentContainer}
            ref={mapRef}
            showsUserLocation={true}
            showsMyLocationButton={true}
            // onRegionChangeComplete={updatedLocation}
            initialRegion={{
              latitude: initialLocation.coordinate.latitude,
              longitude: initialLocation.coordinate.longitude,
              latitudeDelta: 0.1,
              longitudeDelta: 0.1,
            }}
            provider={
              Platform.OS === 'web'
                ? 'google'
                : Platform.OS === 'android'
                  ? 'google'
                  : undefined
            }
            loadingEnabled={true}
            minZoomLevel={
              Platform.OS === 'web'
                ? initialWebZoom
                  ? INITIAL_WEB_ZOOM
                  : 0
                : 0
            }
            loadingIndicatorColor={defaultTheme.MAIN_BACKGROUND_COLOR}
            onRegionChange={() => setInitialWebZoom(false)}
            onRegionChangeComplete={async region => {
              const coords = await mapRef?.current?.getCamera();
              if (Platform.OS == 'ios') {
                if (coords.altitude <= mapZoomLevel) {
                  setShowPinImage(true);
                } else {
                  setShowPinImage(false);
                }
              } else {
                if (coords.zoom >= mapZoomLevel) {
                  setShowPinImage(true);
                } else {
                  setShowPinImage(false);
                }
              }
              setCurrentRegion(region);
            }}>
            {Platform.OS !== 'ios' &&
              Platform.OS != 'android' &&
              initialLocation?.coordinate && (
                <Marker
                  coordinate={initialLocation?.coordinate}
                  title={initialLocation.title}
                  description="">
                  <Image
                    source={require('../../assets/map-pin.png')}
                    style={{ width: 32, height: 32 }}
                  />
                </Marker>
              )}

            {/* location pins   */}
            <Markers
              setSelectedMarker={setSelectedMarker}
              setShowParameters={setShowParameters}
              showPinImage={showPinImage}
              currentRegion={currentRegion}
              setNonGeoFencedItems={withoutGeofencedCallback}
            />

            {Platform.OS !== 'ios' && Platform.OS != 'android' ? (
              <View style={styles.mapUserLocation}>
                <Pressable onPress={() => fitMapToLocation()}>
                  <Icon
                    name="my-location"
                    size={22}
                    color="#707070"
                    style={{ padding: 9 }}
                  />
                </Pressable>
              </View>
            ) : (
              <></>
            )}

            {/* Non Geofenced button */}
            {/* {
              Platform.OS !== 'ios' && Platform.OS != 'android' && withoutGeofencedItems?.locationList?.length > 0 ?               
              <Pressable style={styles.mapNonGeofencedButtonLocation} onPress = {() => showNonGeofencedItems()}>                          
                <View style={[styles.mapNonGeofencedButtonView, width >= TABLET_DIMENTION.width ? { } : { width:203 }]} >
                  <View style={styles.mapNonGeofencedButton}>
                    <Icon name="gps-off" size={22} color={"#000"} style={{ padding: 2 }} />
                    <Text style={styles.mapNonGeofencedButtonLocationTextStyle}>{"Non Geofenced Drops"}</Text>
                  </View>
                </View>
                <View style={styles.mapNonGeofencedCounterContainer}>
                  <View style={styles.mapNonGeofencedCounter}>
                      <Text style={styles.mapNonGeofencedCounterText}>{withoutGeofencedItems?.locationList?.length}</Text>
                  </View>
                </View>
              </Pressable>
              :<></>
            } */}
          </MapView>
          {Platform.OS === 'ios' ? (
            <View style={[styles.mapUserLocation, { top: height - 300 }]}>
              <Pressable onPress={() => fitMapToLocation()}>
                <Icon
                  name="my-location"
                  size={22}
                  color="#707070"
                  style={{ padding: 9 }}
                />
              </Pressable>
            </View>
          ) : (
            <></>
          )}

          {/* Non Geofenced button */}
          {/* {
          Platform.OS !== 'web' && withoutGeofencedItems?.locationList?.length > 0 ?
          <Pressable style={styles.mapNonGeofencedMobileView} onPress = {() => showNonGeofencedItems()}>                          
            <View style={styles.mapNonGeofencedButtonView} >
              <View style={styles.mapNonGeofencedButton}>
                <Icon name="gps-off" size={22} color={"#000"} style={{ padding: 2 }} />
                <Text style={styles.mapNonGeofencedButtonLocationTextStyle}>{"Non Geofenced Drops"}</Text>
              </View>
            </View>
            <View style={styles.mapNonGeofencedCounterContainer}>
              <View style={styles.mapNonGeofencedCounter}>
                  <Text style={styles.mapNonGeofencedCounterText}>{withoutGeofencedItems?.locationList?.length}</Text>
              </View>
            </View>
          </Pressable>
          :<></>
        } */}
        </>
      ) : (
        <>
          <MapView
            style={styles.mapComponentContainer}
            ref={mapRef}
            // onRegionChangeComplete={updatedLocation}
            initialRegion={{
              latitude: userStoredLocation
                ? userStoredLocation.latitude
                : HOTEL_ZIGGY.coordinate.latitude,
              longitude: userStoredLocation
                ? userStoredLocation.longitude
                : HOTEL_ZIGGY.coordinate.longitude,
              latitudeDelta: 0.1,
              longitudeDelta: 0.1,
            }}
            provider={
              Platform.OS === 'web'
                ? 'google'
                : Platform.OS === 'android'
                  ? 'google'
                  : undefined
            }
            loadingEnabled={true}
            minZoomLevel={
              Platform.OS === 'web'
                ? initialWebZoom
                  ? INITIAL_WEB_ZOOM
                  : 0
                : 0
            }
            loadingIndicatorColor={defaultTheme.MAIN_BACKGROUND_COLOR}
            onRegionChange={() => setInitialWebZoom(false)}
            onRegionChangeComplete={async region => {
              const coords = await mapRef?.current?.getCamera();
              if (Platform.OS == 'ios') {
                if (coords.altitude <= mapZoomLevel) {
                  setShowPinImage(true);
                } else {
                  setShowPinImage(false);
                }
              } else {
                if (coords.zoom >= mapZoomLevel) {
                  setShowPinImage(true);
                } else {
                  setShowPinImage(false);
                }
              }
              setCurrentRegion(region);
            }}>
            {/* location pins   */}
            <Markers
              setSelectedMarker={setSelectedMarker}
              setShowParameters={setShowParameters}
              showPinImage={showPinImage}
              currentRegion={currentRegion}
              setNonGeoFencedItems={withoutGeofencedCallback}
            />

            {/* Non Geofenced button */}
            {/* {
              Platform.OS !== 'ios' && Platform.OS != 'android' && withoutGeofencedItems?.locationList?.length > 0 ?               
              <Pressable style={styles.mapNonGeofencedButtonLocation} onPress = {() => showNonGeofencedItems()}>                          
                <View style={[styles.mapNonGeofencedButtonView, width >= TABLET_DIMENTION.width ? { } : { width:203 }]} >
                  <View style={styles.mapNonGeofencedButton}>
                    <Icon name="gps-off" size={22} color={"#000"} style={{ padding: 2 }} />
                    <Text style={styles.mapNonGeofencedButtonLocationTextStyle}>{"Non Geofenced Drops"}</Text>
                  </View>
                </View>
                <View style={styles.mapNonGeofencedCounterContainer}>
                  <View style={styles.mapNonGeofencedCounter}>
                      <Text style={styles.mapNonGeofencedCounterText}>{withoutGeofencedItems?.locationList?.length}</Text>
                  </View>
                </View>
              </Pressable>
              :<></>
            } */}
          </MapView>
          {/* Non Geofenced button */}
          {/* {
          Platform.OS !== 'web' && withoutGeofencedItems?.locationList?.length > 0 ?
          <Pressable style={styles.mapNonGeofencedMobileView} onPress = {() => showNonGeofencedItems()}>                          
            <View style={styles.mapNonGeofencedButtonView} >
              <View style={styles.mapNonGeofencedButton}>
                <Icon name="gps-off" size={22} color={"#000"} style={{ padding: 2 }} />
                <Text style={styles.mapNonGeofencedButtonLocationTextStyle}>{"Non Geofenced Drops"}</Text>
              </View>
            </View>
            <View style={styles.mapNonGeofencedCounterContainer}>
              <View style={styles.mapNonGeofencedCounter}>
                  <Text style={styles.mapNonGeofencedCounterText}>{withoutGeofencedItems?.locationList?.length}</Text>
              </View>
            </View>
          </Pressable>
          :<></>
        } */}
        </>
      )}
      {/* UI for collection parameters */}
      {showParameters && selectedMarker ? (
        <ModalComponent
          showModal={showParameters}
          needCloseButton={true}
          enableHeader={false}
          dismissable={false}
          onDismiss={() => {
            resetSelectedMarker();
          }}
          modalStyle={{
            marginTop:
              Platform.OS == 'android'
                ? 55
                : Platform.OS == 'web'
                  ? '2rem'
                  : 100,
            justifyContent: 'center',
          }}
          modalBodyStyle={{
            width: '100%',
            height:
              Platform.OS == 'web'
                ? width >= TABLET_DIMENTION.width
                  ? '72vh'
                  : '65vh'
                : Platform.OS == 'ios'
                  ? '99%'
                  : '99%',
          }}
          modalCenterdStyle={{
            backgroundColor: 'rgba(137, 137, 137,0.8)',
            borderRadius: 6,
            height:
              Platform.OS == 'web'
                ? width >= TABLET_DIMENTION.width
                  ? '72vh'
                  : '65vh'
                : Platform.OS == 'ios'
                  ? '80%'
                  : '80%',
          }}
          theme="rgba(0, 0, 0, 1)">
          <View style={[styles.mapParametersCardContainer]}>
            <View style={styles.mapParametersButtons}>
              {selectedMarker?.totalPin != 1 ? (
                currentSelectedMarkerIndex != 0 ? (
                  <Pressable
                    style={{ paddingLeft: 9 }}
                    onPress={() => {
                      selectedMarkerIndex(false);
                    }}>
                    <Icon
                      name="arrow-back-ios"
                      size={30}
                      style={styles.mapParameterArrowLight}
                    />
                  </Pressable>
                ) : (
                  <Pressable style={{ paddingLeft: 9 }}>
                    <Icon
                      name="arrow-back-ios"
                      size={30}
                      style={styles.mapParameterArrowDim}
                    />
                  </Pressable>
                )
              ) : (
                <></>
              )}
            </View>
            <View style={styles.mapParametersCardItemContainer}>
              {selectedMarker?.locationList && (
                <MapParameterItem
                  item={
                    selectedMarker?.locationList[currentSelectedMarkerIndex]
                  }
                  itemIndex={currentSelectedMarkerIndex}
                />
              )}
            </View>
            <View style={styles.mapParametersButtons}>
              {selectedMarker?.totalPin != 1 ? (
                currentSelectedMarkerIndex !==
                selectedMarker?.locationList?.length - 1 ? (
                  <Pressable
                    onPress={() => {
                      selectedMarkerIndex(true);
                    }}>
                    <Icon
                      name="arrow-forward-ios"
                      size={30}
                      style={styles.mapParameterArrowLight}
                    />
                  </Pressable>
                ) : (
                  <Pressable>
                    <Icon
                      name="arrow-forward-ios"
                      size={30}
                      style={styles.mapParameterArrowDim}
                    />
                  </Pressable>
                )
              ) : (
                <></>
              )}
            </View>
          </View>
        </ModalComponent>
      ) : (
        <></>
      )}
    </>
  );
};

export default MapComponent;
