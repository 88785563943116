import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList, Platform, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { debounce } from 'lodash';
import ExpassCard from '../ExpassCard';
import useStateRef from 'react-usestateref';
import useIsResponsive from '../../hooks/useIsResponsive';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { useAppSelector } from '../../hooks';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import { clearPassportAllHomeDetails } from '../../helpers/Gadgets';
import {
  loadAllPassporDetails,
  loadAvailablePassporDetails,
  loadMyPassportDetails,
  searchPassport,
  setPassportSearchText,
} from '../../slices/PassportSlice';
import {
  loadAllDigitalCollectible,
  // loadAvailableDigitalCollectibleDetails,
} from '../../slices/DigitalCollectibleSlice';
import { loadAllCatList } from '../../slices/CatalogSlice';
import { PassportType } from '../../enums/passportCategory.enum';
import { defaultTheme } from '../../themes/loot8';
import { ScreenName } from '../../enums/screen.enum';
import { AppDispatch } from '../../store';
import { SheetManager } from 'react-native-actions-sheet';
import WarningModal from '../WarningModal';
import SectionHeading from '../SectionHeading';
import SeeAllTextPressable from '../SeeAllTextPressable';
import { CatalogTypes } from '../../enums/catalog.enum';

const enum SortingOptions {
  NONE = 0,
  ALPHABETICAL_ASC = 1,
  ALPHABETICAL_DESC = 2,
  COST_ASC = 3,
  COST_DESC = 4,
}

interface UserOwnedExpassesProps {
  closeSortingOptionsMenu: () => void;
  selectedSortingOption?: number;
  showLimitedExpasses: boolean;
  showSeeAll?: boolean;
}
const UserOwnedExpasses: React.FC<UserOwnedExpassesProps> = ({
  closeSortingOptionsMenu,
  selectedSortingOption = SortingOptions.ALPHABETICAL_ASC,
  showLimitedExpasses,
  showSeeAll = true,
}) => {
  // local states
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [searchText, setSearchPassportText, searchPassportTextRef] =
    useStateRef('');

  // Hooks
  const navigation = useNavigation();
  const isResponsive = useIsResponsive();
  const { address, networkId, staticProvider, wallet, userInfo } =
    useWeb3AuthContext();
  const dispatch = useDispatch<AppDispatch>();

  // app selectors
  const availablePassportDetails = useAppSelector(
    state => state.Passports.AllPassportDetailsSearchList,
    (oldValue, newValue) => {
      return JSON.stringify(oldValue) === JSON.stringify(newValue)
        ? true
        : false;
    },
  );
  const loadingPassport = useAppSelector(state => state.Passports.loading);
  const mintingload = useAppSelector(state => state.Passports.isMintloading);
  const initialPassportLoaded = useAppSelector(
    state => state.Passports.initialPassportLoaded,
  );
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const storedSearchText = useAppSelector(
    state => state.Passports.passportSearchText,
  );

  // refreshing passports
  const onRefresh = React.useCallback(async () => {
    LogToLoot8Console('on passport refresh');
    setRefreshing(true);
    setSearchPassportText('');
    await clearPassportAllHomeDetails();
    await dispatch(
      loadMyPassportDetails({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        entities: [],
        userInfo,
        userLocation: currenLocation,
        isCache: false,
      }),
    );
    await dispatch(
      loadAllPassporDetails({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        entities: [],
        userInfo,
        userLocation: currenLocation,
        isCache: false,
      }),
    );
    await dispatch(
      loadAvailablePassporDetails({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        entities: [],
        userInfo,
        userLocation: currenLocation,
      }),
    );
    setRefreshing(false);

    await dispatch(
      loadAllDigitalCollectible({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        entities: [],
        userInfo,
        userLocation: currenLocation,
        isCache: false,
      }),
    );
    // await dispatch(
    //   loadAvailableDigitalCollectibleDetails({
    //     networkID: networkId,
    //     provider: staticProvider,
    //     address,
    //     wallet,
    //     entities: [],
    //     userInfo,
    //     userLocation: currenLocation,
    //   }),
    // );

    dispatch(
      loadAllCatList({
        networkID: networkId,
        provider: staticProvider,
        address,
        wallet,
        userInfo,
      }),
    );
  }, []);

  const onPassportSearch = serachText => {
    setSearchPassportText(serachText);
    dispatch(debounce(searchPassport({ searchText: serachText }), 500));
  };

  useEffect(() => {
    onPassportSearch(storedSearchText);
    return () => {
      dispatch(setPassportSearchText(searchPassportTextRef.current));
    };
  }, []);

  useEffect(() => {
    if (Platform.OS === 'web') {
      const searchParams = new URLSearchParams(window.location.search);
      const passportAddress = searchParams.get('passportAddress');
      const chainId = searchParams.get('chainId');

      if (
        new URLSearchParams(window.location.search).get('page') ===
          'expasses' &&
        passportAddress &&
        chainId
      ) {
        navigateToPassportDetails(passportAddress, chainId);
      }
    }
  }, []);

  const myActivePassports = useMemo(() => {
    const premiumPassports = availablePassportDetails?.filter(
      passport => passport.isPremium === true,
    );

    const activePassportDetails = premiumPassports?.filter((item, index) => {
      return (
        item.isActive &&
        (item.passportType === PassportType.SUBSCRIPTION ||
          item.collectibleIds?.length > 0) &&
        (searchPassportTextRef?.current?.length === 1
          ? item.name
              .toLowerCase()
              .startsWith(searchPassportTextRef?.current?.toLowerCase()) ===
            true
          : item.name
              .toLowerCase()
              .indexOf(searchPassportTextRef?.current?.toLowerCase()) > -1)
      );
    });

    const localMyActivePassports = activePassportDetails
      ?.filter((item, index) => {
        return (
          item.passportType === PassportType.REGULAR ||
          item.passportType === PassportType.SUBSCRIBED
        );
      })
      .sort((a, b) => {
        if (selectedSortingOption === SortingOptions.ALPHABETICAL_ASC) {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        } else if (selectedSortingOption === SortingOptions.ALPHABETICAL_DESC) {
          let textA = a.name.toUpperCase();
          let textB = b.name.toUpperCase();
          return textA < textB ? 1 : textA > textB ? -1 : 0;
        } else if (selectedSortingOption === SortingOptions.COST_ASC) {
          return a.buyPrice - b.buyPrice;
        } else if (selectedSortingOption === SortingOptions.COST_DESC) {
          return b.buyPrice - a.buyPrice;
        } else {
          let textA = a.timestamp;
          let textB = b.timestamp;
          return textA < textB ? 1 : textA > textB ? -1 : 0;
        }
      });

    return localMyActivePassports;
  }, [selectedSortingOption, availablePassportDetails]);

  // navigate to expass details
  const navigateToPassportDetails = (passportAddress, chainId) => {
    navigation.navigate(ScreenName.PASSPORT_DETAIL, {
      passportAddress,
      chainId,
    });
  };

  const onPassportSelection = useCallback(item => {
    navigateToPassportDetails(item.address, item.chainId);
    // setting url route in web to save the history state in url
    // if (Platform.OS === 'web') {
    //   const urlSearchParams = new URLSearchParams();
    //   urlSearchParams.set('page', 'expasses');
    //   urlSearchParams.set('passportAddress', item.address);
    //   urlSearchParams.set('chainId', item.chainId);
    //   const newURL = window.location.origin + '?' + urlSearchParams.toString();
    //   window.history.pushState(null, 'expasses', newURL);
    // }
    setSearchPassportText('');
  }, []);

  /// Marketplace Listing Start
  const onMyPassportsOptionsClick = async selectedItem => {
    closeSortingOptionsMenu();
    await SheetManager.show('ExpassThreeDotSettingsSheet', {
      payload: {
        selectedEXPass: selectedItem,
        navigation,
        setSearchPassportText: setSearchPassportText,
        onListForSalePressed: onListForSalePressed,
        type: 'Expass',
      },
    });
  };

  const onListForSalePressed = async itemFromParams => {
    if (itemFromParams) {
      await SheetManager.show('ListingModalSheet', {
        payload: {
          itemObject: itemFromParams,
          navigation,
          type: 'Expass',
          updateStateDataFunc: () => {},
        },
      });
    }
  };
  /// Marketplace Listing End

  const onExpassPress = item => {
    onPassportSelection(item);
  };

  const navigateToSeeAll = () => {
    navigation.navigate('SeeAll', {
      screenTitle: 'My Expasses',
      type: 'userOwned',
      closeSortingOptionsMenu: closeSortingOptionsMenu,
    });
  };

  return (
    <>
      {!loadingPassport && myActivePassports && myActivePassports.length > 0 ? (
        <FlatList
          ListHeaderComponent={() => (
            <>
              <SectionHeading
                title={`Experience Passport (${
                  myActivePassports.length > 0 ? myActivePassports.length : 0
                })`}
                subtitle={'These ExPasses which are owned and minted by you'}
                renderRight={
                  showSeeAll && (
                    <SeeAllTextPressable onPress={navigateToSeeAll} />
                  )
                }
              />
              <View style={{ marginVertical: 10 }} />
            </>
          )}
          showsVerticalScrollIndicator={false}
          style={styles.flatlist}
          contentContainerStyle={styles.flatlistContainer}
          data={
            showLimitedExpasses
              ? myActivePassports?.slice(0, 20)
              : myActivePassports
          }
          renderItem={({ item }) => (
            <ExpassCard
              item={item}
              onPress={onExpassPress}
              onListForSalePressed={onListForSalePressed}
              onThreeDotsSettingsPress={onMyPassportsOptionsClick}
            />
          )}
          keyExtractor={(item: any) => item?.address}
          ItemSeparatorComponent={() => <View style={{ marginVertical: 5 }} />}
          numColumns={isResponsive ? 2 : 1}
          key={isResponsive as any}
          columnWrapperStyle={
            isResponsive && { justifyContent: 'space-between' }
          }
        />
      ) : (
        <View>
          <Text style={styles.loadingMsg}>
            {searchText === ''
              ? !initialPassportLoaded
                ? 'Collecting Your Digital Assets'
                : 'No ExPasses are subscribed yet.'
              : 'No search result found.'}
          </Text>
        </View>
      )}
      {!loadingPassport &&
        myActivePassports?.length === 0 &&
        !mintingload &&
        searchText === '' && (
          <View style={styles.alertContainer}>
            <Text style={styles.alertContainerText}>
              <Text>
                We are looking to get the passports for you! Meanwhile, please
                check out your{' '}
              </Text>
              <Text
                style={{
                  textDecorationLine: 'underline',
                  color: defaultTheme.GRADIENT_COLOR1,
                }}
                onPress={() => {
                  navigation.navigate('FriendsTab');
                }}>
                friends.
              </Text>
            </Text>
          </View>
        )}
      <WarningModal
        show={showWarningModal}
        message="You needs to unlist from marketplace first, before unsubscribing "
        onDismiss={() => setShowWarningModal(false)}
      />
    </>
  );
};

export default UserOwnedExpasses;

const styles = StyleSheet.create({
  alertContainer: {
    backgroundColor: 'rgba(255,255,255,0.15)',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  alertContainerText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  flatlist: {
    flex: 1,
    paddingTop: 10,
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  flatlistContainer: { flexGrow: 1, paddingBottom: 150 },
  loadingMsg: {
    alignSelf: 'center',
    textAlign: 'center',
    paddingVertical: 10,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
});
