import {
  ActivityIndicator,
  FlatList,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import React, { useMemo, useRef, useState } from 'react';
import { defaultTheme } from '../../../themes/loot8';
import TXItem from '../../TXItem';
import { useAppSelector } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { getUserTXHistory } from '../../../slices/AppUserSlice';
import { useWeb3AuthContext } from '../../../hooks/web3authContext';
import HOCContainer from '../../HOCContainer';
import EmptyList from '../../EmptyList';
import AnimatedUnderlineTabs from '../../AnimatedUnderlineTabs';
import useActiveDimensions from '../../../hooks/useActiveDimensions';
import useIsResponsive from '../../../hooks/useIsResponsive';
import AnimatedTabBoxes from '../../AnimatedTabBoxes';
import GradientText from '../../GradientText';

const TXHistory = ({ navigation }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTimeFilter, setActiveTimeFilter] = useState(4);
  const tabsRef = useRef(null);
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();
  const dispatch = useDispatch();
  const { networkId, address } = useWeb3AuthContext();
  const UserTxHistory = useAppSelector(state => state.AppUser.UserTxHistory);
  const txLoading = useAppSelector(state => state.AppUser.txLoading);

  const navigateToWallet = () => {
    navigation.goBack();
  };

  const goToNextSlide = index => {
    const offset = isResponsive
      ? index *
        (defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
          defaultTheme.HORIZONTAL_PADDING * 2)
      : index * (activeWidth - defaultTheme.HORIZONTAL_PADDING * 2);
    tabsRef?.current.scrollToOffset({ offset });
  };
  const handleTimeFilters = _filter => {
    setActiveTimeFilter(_filter);
  };
  const refetchTxHistory = async () => {
    await dispatch(getUserTXHistory({ address, networkId }));
  };

  return (
    <View style={{ flex: 1 }}>
      {/* tabs container */}
      <AnimatedUnderlineTabs
        data={topTabsButtonData}
        changeSlidesOnPress={goToNextSlide}
        height={35}
      />
      {/* Filters are to be commented out till next release */}
      {/* <View style={{ marginTop: 16 }}>
        <AnimatedTabBoxes
          data={filterButtonsData}
          changeSlidesOnPress={handleTimeFilters}
          height={35}
          numColumns={5}
          fullWidth={false}
        />
      </View> */}
      {/* tx list */}
      {txLoading && !UserTxHistory ? (
        <View style={componentStyles.loadingContainer}>
          <ActivityIndicator
            size={'large'}
            color={defaultTheme.SECONDARY_TEXT_COLOR}
          />
        </View>
      ) : (
        <FlatList
          ref={tabsRef}
          data={topTabsButtonData}
          horizontal
          snapToAlignment="start"
          decelerationRate={'fast'}
          snapToInterval={activeWidth}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
          style={{ flex: 1 }}
          renderItem={({ item, index }) => (
            <Slide
              item={item}
              activeIndex={index}
              activeTimeFilter={activeTimeFilter}
            />
          )}
          scrollEnabled={false}
        />
      )}
    </View>
  );
};

export default HOCContainer({
  OriginalComponent: TXHistory,
  title: 'Transaction History',
  isScrollEnabled: false,
});

function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
const Slide = ({ item, activeIndex, activeTimeFilter }) => {
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();
  const { UserTxHistory, txLoading } = useAppSelector(state => state.AppUser);

  const returnFilteredData = React.useMemo(() => {
    if (!UserTxHistory?.length) return UserTxHistory;

    switch (activeIndex) {
      case 1:
        return UserTxHistory.filter(
          x =>
            x.completionTxHash === null &&
            x.failureTxHash === null &&
            x.payoutItemStatus !== 'UNCLAIMED',
        );
      case 2:
        return UserTxHistory.filter(
          x =>
            x.payoutItemStatus === 'UNCLAIMED' ||
            (x.completionTxHash !== null && x.failureTxHash === null),
        );
      case 3:
        return UserTxHistory.filter(
          x => x.completionTxHash === null && x.failureTxHash !== null,
        );
      default:
        return UserTxHistory;
    }
  }, [UserTxHistory, activeIndex]);

  // TImebase filters are commented in this release

  // const returnTimeBasedFilteredData = React.useMemo(() => {
  //   if (returnFilteredData.length) {
  //     switch (activeTimeFilter) {
  //       case 0:
  //         // Today's data
  //         return returnFilteredData.filter(item => {
  //           return isSameDay(
  //             new Date(),
  //             new Date(item.blockTimestamp), // Convert seconds to milliseconds
  //           );
  //         });
  //       case 1:
  //         // Yesterday and today's data
  //         const yesterday = new Date();
  //         yesterday.setDate(yesterday.getDate() - 1);
  //         return returnFilteredData.filter(
  //           item => new Date(item.blockTimestamp) >= yesterday,
  //         );

  //       case 2:
  //         // Data from 5 days back to today
  //         const fiveDaysAgo = new Date();
  //         fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5);
  //         return returnFilteredData.filter(
  //           item => new Date(item.blockTimestamp) >= fiveDaysAgo,
  //         );

  //       case 3:
  //         // Data from the last year
  //         const oneYearAgo = new Date();
  //         oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  //         return returnFilteredData.filter(
  //           item => new Date(item.blockTimestamp) >= oneYearAgo,
  //         );

  //       case 4:
  //         // Data from the last 5 years
  //         // const fiveYearsAgo = new Date();
  //         // fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);
  //         // return returnFilteredData.filter(
  //         //   item => new Date(item.blockTimestamp) >= fiveYearsAgo,
  //         // );

  //         return returnFilteredData;

  //       default:
  //         return returnFilteredData;
  //     }
  //   } else {
  //     return returnFilteredData;
  //   }
  // }, [returnFilteredData, activeTimeFilter]);

  // const addDayLabels = useMemo(() => {
  //   const data = returnTimeBasedFilteredData;

  //   const groups = {};
  //   for (const item of data) {
  //     const date = new Date(item.blockTimestamp);
  //     const year = date.getFullYear();
  //     const month = date.getMonth();
  //     const day = date.getDate();

  //     const dateKey = `${year}-${month + 1}-${day}`;

  //     if (!groups[dateKey]) {
  //       groups[dateKey] = [];
  //     }

  //     groups[dateKey].push(item);
  //   }
  //   return [groups];
  // }, [returnTimeBasedFilteredData, returnFilteredData, activeTimeFilter]);

  return (
    <View
      style={[
        isResponsive
          ? {
              width:
                defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
                defaultTheme.HORIZONTAL_PADDING * 2,
            }
          : {
              width: activeWidth - defaultTheme.HORIZONTAL_PADDING * 2,
            },
      ]}>
      {txLoading && returnFilteredData?.length < 1 ? (
        <View style={componentStyles.loadingContainer}>
          <ActivityIndicator
            size={'small'}
            color={defaultTheme.SECONDARY_TEXT_COLOR}
          />
        </View>
      ) : returnFilteredData?.length > 0 ? (
        <>
          {/* {activeTimeFilter === 0 ? (
            <FlatList
              extraData={UserTxHistory}
              showsVerticalScrollIndicator={false}
              data={returnFilteredData}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => (
                <TXItem expandable item={item} />
              )}
              style={{ flex: 1 }}
              ItemSeparatorComponent={() => (
                <View style={{ marginVertical: 5 }} />
              )}
              contentContainerStyle={componentStyles.contentContainer}
            />
          ) : (
            <FlatList
              extraData={UserTxHistory}
              showsVerticalScrollIndicator={false}
              data={addDayLabels}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => <GroupTxItem item={item} />}
              style={{ flex: 1 }}
              ItemSeparatorComponent={() => (
                <View style={{ marginVertical: 5 }} />
              )}
              contentContainerStyle={componentStyles.contentContainer}
            />
          )} */}

          <FlatList
            extraData={UserTxHistory}
            showsVerticalScrollIndicator={false}
            data={returnFilteredData}
            keyExtractor={(item, index) => index.toString()}
            renderItem={({ item, index }) => <TXItem expandable item={item} />}
            style={{ flex: 1 }}
            ItemSeparatorComponent={() => (
              <View style={{ marginVertical: 5 }} />
            )}
            contentContainerStyle={componentStyles.contentContainer}
          />
        </>
      ) : (
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}>
          <EmptyList
            message="No Transactions Found"
            secondaryMessage="Engage with collectibles and experiences on LOOT8 to see your activity
            here!"
          />
        </View>
      )}
    </View>
  );
};
const renderGroupedData = data => {
  const output = [];

  for (const key in data) {
    if (!data.hasOwnProperty(key)) {
      continue;
    }
    const dateObject = new Date(key);
    if (isNaN(dateObject.getTime())) {
      continue;
    }
    const day = dateObject.getDate();
    const month = dateObject.toLocaleDateString('en-US', { month: 'long' });
    const year = dateObject.getFullYear();
    const formattedDate = `${day} ${month} ${year}`;

    output.push(
      <>
        {data[key] && data[key].length ? (
          <>
            <View style={{ alignSelf: 'center' }}>
              <GradientText size={12}>{formattedDate}</GradientText>
            </View>
            <View style={{ rowGap: 6 }}>
              {data[key].map(item => (
                <TXItem expandable item={item} />
              ))}
            </View>
          </>
        ) : null}
      </>,
    );
  }
  return output;
};

const GroupTxItem = ({ item }) => {
  const renderData = renderGroupedData(item);
  return <View style={{ rowGap: 5, marginTop: 8 }}>{renderData}</View>;
};

const componentStyles = StyleSheet.create({
  contentContainer: { flexGrow: 1, paddingBottom: 150, paddingTop: 15 },
  headerContainer: {
    marginVertical: 10,
    justifyContent: 'center',
    marginHorizontal: 15,
  },
  tabContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 25,
    marginTop: 1,
  },
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabTitle: {
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: '#fff',
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontWeight: '300',
  },
  tabTitleActive: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  line: { height: 1, backgroundColor: '#F4B540' },
  separator: { marginVertical: 2.5 },
  btn: { position: 'absolute', zIndex: 10 },
  noTxAvailable: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: '#FFFFFF',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    textAlign: 'center',
  },
});

const topTabsButtonData = [
  { id: 1, title: 'All Transactions' },
  { id: 2, title: 'In Process' },
  { id: 3, title: 'Successful' },
  { id: 4, title: 'Failed' },
];
const filterButtonsData = [
  { id: 1, title: 'Today' },
  { id: 2, title: '1D' },
  { id: 3, title: '5D' },
  { id: 4, title: '1Y' },
  { id: 5, title: '5Y' },
];
