import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    borderRadius: defaultTheme.CONTENT_RADIUS,
    overflow: 'hidden',
    padding: 1,
    flex: 0.225,
  },
  nonGradientContainer: {},
  rippleWrapper: {
    overflow: 'hidden',
    borderRadius: defaultTheme.CONTENT_RADIUS,
  },
  contentContainer: {
    paddingHorizontal: 5,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: defaultTheme.CONTENT_RADIUS,
    rowGap: 3,
  },
  tabItem: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabText: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontWeight: defaultTheme.FONT_WEIGHT_MEDIUM,
  },
});
