import { ethers, Wallet } from 'ethers';
import { SOCIAL_MEDIA_API, MASTER_KEY } from '../appconstants';
import {
  DirectionType,
  IDeleteMessageRequest,
  IMessageListResponse,
  IMessageRequest,
  INativeNotificationObject,
  MessageType,
} from '../interfaces/IMessages';
import { getIPFSData, retryFetch } from './ipfs';
import { Buffer } from 'buffer';
import { LogCustomError } from './AppLogger';
import * as FileSystem from 'expo-file-system';
import {
  ensureDirExists,
  getStoredContentByFile,
  storeContentToFile,
} from './AppFileStorage';
import { getSyncedTime } from './DateHelper';
import { IMessageAttachment } from '../slices/interfaces';
import { Platform } from 'react-native';
import { getData, storeData } from './AppStorage';
import { getAddress } from 'ethers/lib/utils';

type friendsOperationType = 'add' | 'remove';
export const separator: Buffer = Buffer.from([0x01]);
export const socialMessageDir = `${FileSystem.cacheDirectory}loot8-sm/`;
export const privateMessageDir = `${FileSystem.cacheDirectory}loot8-pm/`;

const pmNotificationBodyText = 'a new private message';

const filterNullParams = param => {
  //Loot8-1403: new lambda API does not support null values and throw error
  for (var propName in param) {
    if (param[propName] === null || param[propName] === undefined) {
      delete param[propName];
    }
  }
  return param;
};

const getRequestHeaders = async (
  reqMethod: string,
  path: string,
  body: any,
  timestamp: number,
  wallet: Wallet,
) => {
  if (timestamp === undefined || timestamp === null) {
    timestamp = getSyncedTime();
  }

  const headers: any = {};
  headers['Content-Type'] = 'application/json';
  headers['X-Loot8-Timestamp'] = timestamp?.toString();

  let mergedRequest = await getMergedRequest(
    reqMethod,
    path,
    body ? body : '{}',
    timestamp,
  );
  if (wallet) {
    const accSignature = await wallet.signMessage(mergedRequest);
    headers['X-Loot8-Signature'] = accSignature;

    mergedRequest = Buffer.concat([Buffer.from(accSignature, 'utf-8')]);
  }

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(mergedRequest);
  headers['X-Loot8-MasterSignature'] = masterSignature;

  return headers;
};

const checkAndGetCorrectTimestamp = async (
  response,
  ReqSentTimeStamp,
  ResReceivedTimestamp,
) => {
  //Check for 201 error code in case timestamp is outdated/wrong and calculae clock skew to reissue request with corrected timestamp
  let correctedTimestamp = null;
  const res = await response.json();
  let timestampDiff = 0;
  if (
    res &&
    res.error &&
    res.error.code &&
    res.error.code === 201 &&
    res.timestamp
  ) {
    timestampDiff = Math.round(
      res.timestamp -
      ReqSentTimeStamp -
      (ResReceivedTimestamp - ReqSentTimeStamp) / 2,
    );
    if (timestampDiff) {
      correctedTimestamp = getSyncedTime() + timestampDiff;
    }
  }
  return { correctedTimestamp: correctedTimestamp, offset: timestampDiff };
};

export const readMessage = async (
  passport: string,
  chainId: number,
  lastTimestamp: number,
  direction: DirectionType,
  messageDir: string,
  messageId: string = null,
  limit: number = 10,
  loadData: boolean = true,
): Promise<IMessageListResponse> => {
  let data: any = {
    feed: passport + ':' + chainId,
    lastTimestamp,
    direction,
    loadData,
    limit,
  };
  data = filterNullParams(data);

  if (!loadData) {
    ensureDirExists(messageDir);
  }

  if (messageId) {
    data.messageId = messageId;
  }

  let body = JSON.stringify({ params: data });

  const path = 'api/v1/social/feed/';
  const timestamp = getSyncedTime();
  const reqHeaders = await getRequestHeaders('POST', path, body, null, null);
  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (
      correctedTimeData &&
      correctedTimeData.correctedTimestamp &&
      correctedTimeData.offset
    ) {
      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        null,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );
    }
  }

  return await response.json();
};

export const postMessageForPassport = async (
  passport: string,
  chainId: number,
  text: string,
  wallet: Wallet,
  parentId?: string,
  attachments?: IMessageAttachment,
) => {
  const timestamp = getSyncedTime();
  const textMessage = createTextMessage(
    passport + ':' + chainId,
    text,
    MessageType.text,
    parentId,
    attachments,
  );
  const signedTextMessage = await signMessage(textMessage, timestamp, wallet);

  const body = JSON.stringify({ message: signedTextMessage });

  const path = 'api/v1/social/feed/post';
  const reqHeaders = await getRequestHeaders('POST', path, body, null, null);
  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (correctedTimeData && correctedTimeData.correctedTimestamp) {
      const signedTextMessage = await signMessage(
        textMessage,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      const body = JSON.stringify({ message: signedTextMessage });

      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        null,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );
    }
  }

  return response;
};

export const likeMessageForPassport = async (
  passport: string,
  chainId: number,
  like: boolean,
  wallet: Wallet,
  messageId: string,
) => {
  const timestamp = getSyncedTime();
  const textMessage = createLikeMessage(passport + ':' + chainId, messageId);
  const signedTextMessage = await signMessage(textMessage, timestamp, wallet);

  const body = JSON.stringify({ message: signedTextMessage });

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(body);

  const path = 'api/v1/social/feed/post';
  const reqHeaders = await getRequestHeaders('POST', path, body, null, null);
  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (correctedTimeData && correctedTimeData.correctedTimestamp) {
      const signedTextMessage = await signMessage(
        textMessage,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      const body = JSON.stringify({ message: signedTextMessage });

      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        null,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );
    }
  }

  return response;
};

export const deleteMessage = async (
  passport: string,
  chainId: number,
  wallet: Wallet,
  messageId: string,
) => {
  let data: any = {
    feed: passport + ':' + chainId,
    messageId,
  };

  const timestamp = getSyncedTime();
  const signedMessage = await signMessageTobeDelete(data, timestamp, wallet);

  const body = JSON.stringify({ delete: signedMessage });

  const path = 'api/v1/social/feed/delete/';
  const reqHeaders = await getRequestHeaders('POST', path, body, null, null);
  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (correctedTimeData && correctedTimeData.correctedTimestamp) {
      const signedMessage = await signMessageTobeDelete(
        data,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      const body = JSON.stringify({ delete: signedMessage });

      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        null,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );
    }
  }

  return response;
};

const createTextMessage = (
  feed: string,
  text: string,
  messageType: MessageType,
  parentID?: string | undefined,
  attachments?: IMessageAttachment,
): IMessageRequest => {
  let message: IMessageRequest = {
    _type: 'loot8/message/v1',

    feed: feed,
    parent: parentID ?? undefined,

    data: {
      _type: messageType,
      content: text
        ? {
          text: text,
        }
        : undefined,
    },
  };
  if (attachments && attachments.uri) {
    message.data = { ...message.data, attachments: [attachments] };
  }
  return message;
};

const createLikeMessage = (feed: string, parentID: string): IMessageRequest => {
  return {
    _type: 'loot8/message/v1',

    feed: feed,
    parent: parentID,

    data: {
      _type: MessageType.like,
    },
  };
};

const signMessage = async (
  message: IMessageRequest,
  timestamp: number,
  wallet: Wallet,
): Promise<IMessageRequest> => {
  message.sender = await wallet.getAddress();
  message.senderTimestamp = timestamp;

  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(message._type, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.feed, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.parent ?? '', 'utf-8'));
  chunks.push(separator);

  chunks.push(Buffer.from(message.data._type, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.data.content?.text ?? '', 'utf-8'));
  // chunks.push(separator);

  if (
    message.data.attachments &&
    message.data.attachments[0] &&
    message.data.attachments[0].uri
  ) {
    chunks.push(separator);
    chunks.push(Buffer.from(message.data.attachments[0].name ?? '', 'utf-8'));
    chunks.push(separator);
    chunks.push(Buffer.from(message.data.attachments[0].uri ?? '', 'utf-8'));
  }

  chunks.push(separator);
  chunks.push(Buffer.from(message.sender, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.senderTimestamp.toString(), 'utf-8'));

  message.signature = await wallet.signMessage(Buffer.concat(chunks));

  return message;
};

const signNativeNotificationMessage = async (
  nativeNotification: INativeNotificationObject[],
  timestamp: number,
  wallet: Wallet,
): Promise<any> => {
  let message: any = {};
  message.notifications = nativeNotification;
  message.sender = await wallet.getAddress();
  message.senderTimestamp = timestamp;

  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(JSON.stringify(message.notifications), 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.sender, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.senderTimestamp.toString(), 'utf-8'));

  message.signature = await wallet.signMessage(Buffer.concat(chunks));
  return message;
};

const signMessageTobeDelete = async (
  message: IDeleteMessageRequest,
  timestamp: number,
  wallet: Wallet,
): Promise<IDeleteMessageRequest> => {
  message.sender = await wallet.getAddress();
  message.senderTimestamp = timestamp;

  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(message.feed, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.messageId, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.sender ?? '', 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.senderTimestamp.toString(), 'utf-8'));

  message.signature = await wallet.signMessage(Buffer.concat(chunks));

  return message;
};

const getMergedRequest = async (
  reqMethod: string,
  path: string,
  body: any,
  timestamp: number,
) => {
  const chunks: Buffer[] = [];

  chunks.push(Buffer.from(reqMethod, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from('/' + path, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(timestamp.toString(), 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(body, 'utf-8'));

  return Buffer.concat(chunks);
};

export const sendPrivateMessage = async (
  chainId: number,
  account: string,
  text: string,
  wallet: Wallet,
  senderName: string,
  messageType: MessageType,
  parentId?: string,
  attachments?: IMessageAttachment,
) => {
  const timestamp = getSyncedTime();
  const textMessage = createTextMessage(
    getAddress(account),
    text,
    messageType,
    parentId,
    attachments,
  );
  const signedTextMessage = await signMessage(textMessage, timestamp, wallet);

  //get notification object and send with message object
  const nativeNotificationObj = await getSignedNativeNotificationObject(
    getAddress(account),
    pmNotificationBodyText,
    wallet,
    timestamp,
    senderName,
  );

  const body = JSON.stringify({
    message: signedTextMessage,
    nativeNotification: nativeNotificationObj,
  });
  const path = 'api/v1/social/private/post/';
  const reqHeaders = await getRequestHeaders('POST', path, body, null, null);
  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (correctedTimeData && correctedTimeData.correctedTimestamp) {
      const signedTextMessage = await signMessage(
        textMessage,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      const nativeNotificationObj = await getSignedNativeNotificationObject(
        account,
        pmNotificationBodyText,
        wallet,
        correctedTimeData.correctedTimestamp,
        senderName,
      );
      const body = JSON.stringify({
        message: signedTextMessage,
        nativeNotification: nativeNotificationObj,
      });

      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        null,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );
    }
  }

  return response;
};

export const getSignedNativeNotificationObject = async (
  account: string,
  text: string,
  wallet: Wallet,
  timeStamp: number,
  senderName: string,
) => {
  const nativeNotification: INativeNotificationObject[] = [];

  nativeNotification.push({
    receiver: account,
    title: senderName,
    body: text,
    data: {
      url:
        'loot8auth://friends/directmessage/friendsmessages/' +
        wallet.address +
        '',
      friendWallet: wallet.address,
    },
  });

  const signedNotificationObj = await signNativeNotificationMessage(
    nativeNotification,
    timeStamp,
    wallet,
  );

  return signedNotificationObj;
};

export const getPrivateMessages = async (
  chainId: number,
  account: string,
  wallet: Wallet,
  lastTimestamp: number,
  direction: DirectionType,
  messageId: string = null,
  limit = 11,
  loadData = true,
) => {
  let params: any = {
    feed: account,
    lastTimestamp,
    direction,
    loadData,
    limit: limit,
  };
  params = filterNullParams(params);

  if (!loadData) {
    ensureDirExists(privateMessageDir);
  }

  if (params && messageId) {
    params.messageId = messageId;
  }

  const data = { params: params };

  const body = JSON.stringify(data);

  //const signature = await wallet.signMessage(JSON.stringify(data));

  const path = 'api/v1/social/private/' + '' + wallet.address;
  const reqHeaders = await getRequestHeaders('POST', path, body, null, wallet);
  const timestamp = getSyncedTime();

  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + '' + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status == 200) {
    return await response.json();
  } else if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (
      correctedTimeData &&
      correctedTimeData.correctedTimestamp &&
      correctedTimeData.offset
    ) {
      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + '' + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );

      if (response.status == 200) {
        return await response.json();
      } else {
        LogCustomError(
          'getPrivateMessages',
          response?.status?.toString(),
          response?.statusText,
          null,
        );
      }
    }
  } else {
    LogCustomError(
      'getPrivateMessages',
      response?.status?.toString(),
      response?.statusText,
      null,
    );
  }
};

export const sendPrivateMsgFriendRequest = async (
  chainId: number,
  account: string,
  text: string,
  wallet: Wallet,
  parentId?: string,
) => {
  const timestamp = getSyncedTime();
  const textMessage = createTextMessage(
    getAddress(account),
    text,
    MessageType.text,
  );
  const signedTextMessage = await signMessage(textMessage, timestamp, wallet);

  const body = JSON.stringify({ message: signedTextMessage });

  const path = 'api/v1/social/private/requests/post/';
  const reqHeaders = await getRequestHeaders('POST', path, body, null, null);

  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (correctedTimeData && correctedTimeData.correctedTimestamp) {
      const signedTextMessage = await signMessage(
        textMessage,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      const body = JSON.stringify({ message: signedTextMessage });

      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        null,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );
    }
  }
  return response;
};

export const getPrivateMsgFriendRequest = async (
  chainId: number,
  account: string,
  wallet: Wallet,
  lastTimestamp: number,
  direction: DirectionType,
  messageId: string = null,
  limit = 25,
  loadData = true,
) => {
  let params: any = {
    feed: account,
    lastTimestamp,
    direction,
    loadData,
    limit: limit,
  };
  params = filterNullParams(params);

  if (!loadData) {
    ensureDirExists(privateMessageDir);
  }

  if (params && messageId) {
    params.messageId = messageId;
  }

  const data = { params: params };

  const body = JSON.stringify(data);

  const path = 'api/v1/social/private/requests/' + '' + wallet.address;
  const reqHeaders = await getRequestHeaders('POST', path, body, null, wallet);
  const timestamp = getSyncedTime();
  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status == 200) {
    return await response.json();
  } else if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (
      correctedTimeData &&
      correctedTimeData.correctedTimestamp &&
      correctedTimeData.offset
    ) {
      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );

      if (response.status == 200) {
        return await response.json();
      } else {
        LogCustomError(
          'getPrivateMessages',
          response?.status?.toString(),
          response?.statusText,
          null,
        );
      }
    }
  } else {
    LogCustomError(
      'getPrivateMsgFriendRequest',
      response?.status?.toString(),
      response?.statusText,
      null,
    );
  }
};

export const getPrivateMsgPendingRequest = async (
  wallet: Wallet,
  lastTimestamp: number,
  direction: DirectionType,
  limit = 25,
  loadData = true,
) => {
  let params: any = {
    feed: wallet.address,
    lastTimestamp,
    direction,
    loadData,
    limit: limit,
  };
  params = filterNullParams(params);

  const data = { params: params };

  const body = JSON.stringify(data);

  const path = 'api/v1/social/private/requests/' + '' + wallet.address;
  const reqHeaders = await getRequestHeaders('POST', path, body, null, wallet);
  const timestamp = getSyncedTime();

  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status == 200) {
    return await response.json();
  } else if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (
      correctedTimeData &&
      correctedTimeData.correctedTimestamp &&
      correctedTimeData.offset
    ) {
      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );

      if (response.status == 200) {
        return await response.json();
      } else {
        LogCustomError(
          'getPrivateMessages',
          response?.status?.toString(),
          response?.statusText,
          null,
        );
      }
    }
  } else {
    LogCustomError(
      'getPrivateMsgPendingRequest',
      response?.status?.toString(),
      response?.statusText,
      null,
    );
  }
};

export const cancelPrivateMessageFriendRequest = async (
  chainId: number,
  account: string,
  wallet: Wallet,
  messageId: string,
  requestAccepted: boolean,
) => {
  let data: any = {
    feed: getAddress(account),
    messageId,
    //isRequest: true,
    requestAccepted: requestAccepted,
  };

  const timestamp = getSyncedTime();
  const signedMessage = await signMessageTobeDelete(data, timestamp, wallet);

  const body = JSON.stringify({ delete: signedMessage });

  const path = 'api/v1/social/private/requests/delete/';
  const reqHeaders = await getRequestHeaders('POST', path, body, null, null);

  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (correctedTimeData && correctedTimeData.correctedTimestamp) {
      const signedMessage = await signMessageTobeDelete(
        data,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      const body = JSON.stringify({ delete: signedMessage });

      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        null,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );
    }
  }
  return response;
};

export const getMutualFriendStatus = async (
  account: string,
  sender: Wallet,
  unidirectional: boolean = false,
) => {
  const path =
    'api/v1/social/private/' +
    account +
    '/status/' +
    sender.address +
    '?unidirectional=' +
    unidirectional;
  const timestamp = getSyncedTime();
  const reqHeaders = await getRequestHeaders('POST', path, null, null, sender);

  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, { method: 'POST', headers: reqHeaders }),
  );

  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (correctedTimeData && correctedTimeData.correctedTimestamp) {
      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        null,
        correctedTimeData.correctedTimestamp,
        sender,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, { method: 'POST', headers: reqHeaders }),
      );
    }
  }

  return response;
};

// LOOT8-5651 Adding this function to avoid uploading friends data directly with uploadFriendsDataToMFS function
export const updateFriendsOnLambda = async (
  accounts: [string],
  sender: Wallet,
  type: friendsOperationType,
) => {
  const path = 'api/v1/account/' + sender.address + '/friends/update';
  const timestamp = getSyncedTime();
  const body = JSON.stringify({
    friends:
      type === 'remove'
        ? { add: [], remove: accounts }
        : { add: accounts, remove: [] },
  });
  const reqHeaders = await getRequestHeaders(
    'POST',
    path,
    body,
    timestamp,
    sender,
  );

  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );
  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (correctedTimeData && correctedTimeData.correctedTimestamp) {
      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        sender,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );
    }
  }

  return response;
};

export const deletePrivateMessage = async (
  chainId: number,
  account: string,
  wallet: Wallet,
  messageId: string,
) => {
  let data: any = {
    feed: getAddress(account),
    messageId,
  };

  const timestamp = getSyncedTime();
  const signedMessage = await signMessageTobeDelete(data, timestamp, wallet);

  const body = JSON.stringify({ delete: signedMessage });

  const path = 'api/v1/social/private/delete';
  const reqHeaders = await getRequestHeaders('POST', path, body, null, null);

  let response = await retryFetch(() =>
    fetch(SOCIAL_MEDIA_API + path, {
      method: 'POST',
      headers: reqHeaders,
      body,
    }),
  );

  if (response.status === 400) {
    const correctedTimeData = await checkAndGetCorrectTimestamp(
      response,
      timestamp,
      getSyncedTime(),
    );
    if (correctedTimeData && correctedTimeData.correctedTimestamp) {
      const signedMessage = await signMessageTobeDelete(
        data,
        correctedTimeData.correctedTimestamp,
        wallet,
      );
      const body = JSON.stringify({ delete: signedMessage });

      const reqHeaders = await getRequestHeaders(
        'POST',
        path,
        body,
        correctedTimeData.correctedTimestamp,
        null,
      );
      response = await retryFetch(() =>
        fetch(SOCIAL_MEDIA_API + path, {
          method: 'POST',
          headers: reqHeaders,
          body,
        }),
      );
    }
  }
  return response;
};

export const getCachedDataByMessageId = async (
  dir: string,
  messageId: string,
) => {
  let cachedData;
  if (Platform.OS == 'ios') {
    cachedData = await getData(messageId);
  } else {
    cachedData = await getStoredContentByFile(dir + messageId);
  }
  if (!cachedData) {
    const response = await getIPFSData(messageId, 5 * 1000);
    if (response && response.ok) {
      const data = (await response.json()).data;
      if (data) {
        if (Platform.OS == 'ios') {
          storeData(messageId, JSON.stringify(data));
        } else {
          storeContentToFile(dir + messageId, JSON.stringify(data));
        }
      }
      return data;
    }
  } else {
    return JSON.parse(cachedData);
  }
};

export async function getCollectionFeedSettings(feed: string) {
  const chunks: Buffer[] = [];
  chunks.push(Buffer.from(feed, 'utf-8'));

  const masterWallet = new ethers.Wallet(MASTER_KEY);

  const signature = await masterWallet.signMessage(Buffer.concat(chunks));

  let requestURL =
    SOCIAL_MEDIA_API + `admin/feed/${feed}/settings/${signature}`;

  const response = await retryFetch(() =>
    fetch(requestURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: null,
    }),
  );

  return response;
}

const signNotificationSettingsObj = async (
  wallet: Wallet,
  timestamp: number,
  notificationSettingsObj: string,
) => {
  let message: any = {};
  message.notification = notificationSettingsObj;

  message.sender = await wallet.getAddress();
  message.senderTimestamp = timestamp;

  const chunks: Buffer[] = [];
  if (message.notification) {
    chunks.push(Buffer.from(JSON.stringify(message.notification), 'utf-8'));
    chunks.push(separator);
  }
  chunks.push(Buffer.from(message.sender, 'utf-8'));
  chunks.push(separator);
  chunks.push(Buffer.from(message.senderTimestamp.toString(), 'utf-8'));

  message.signature = await wallet.signMessage(Buffer.concat(chunks));
  return message;
};

const getSignedNotificaitonSettingsObj = async (
  wallet: Wallet,
  timestamp: number,
  isDeleteNotificationToken: boolean,
  notificationToken: string,
) => {
  let notificationSettings: any = null;
  if (!isDeleteNotificationToken) {
    notificationSettings = {
      expoPushToken: notificationToken,
    };
  } else {
    notificationSettings = {
      expoPushToken: null,
      deleteExpoPushToken: isDeleteNotificationToken,
    };
  }

  const signedMessage = await signNotificationSettingsObj(
    wallet,
    timestamp,
    notificationSettings,
  );

  return signedMessage;
};

export const setNativeNotificationToken = async (
  notificationToken: string,
  wallet: Wallet,
) => {
  const signedNotificationObj = await getSignedNotificaitonSettingsObj(
    wallet,
    Date.now(),
    false,
    notificationToken,
  );

  const body = JSON.stringify(signedNotificationObj);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(body);

  const response = await retryFetch(() =>
    fetch(
      SOCIAL_MEDIA_API +
      'notification/' +
      signedNotificationObj.sender +
      '/expotoken/' +
      masterSignature,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      },
    ),
  );

  if (response.status == 200) {
    const res = await response.text();
    //console.log(JSON.stringify(res));
  } else {
    LogCustomError(
      'setNativeNotificationToken',
      response?.status?.toString(),
      response?.statusText,
      null,
    );
  }
};

// export const getNativeNotificationToken = async(wallet: Wallet) => {
//     const response = await retryFetch(() => fetch(SOCIAL_MEDIA_API + "notification/"+ wallet.address +"/expotoken/",
//         { method: 'GET', headers: { 'Content-Type': 'application/json' } }));

//     if (response.status == 200) {
//         const res = await response.text();
//         return res;
//     } else {
//         LogCustomError("getNativeNotificationToken", response?.status?.toString(), response?.statusText, null);
//     }
// }

export const deleteNativeNotificationToken = async (wallet: Wallet) => {
  const signedNotificationObj = await getSignedNotificaitonSettingsObj(
    wallet,
    Date.now(),
    true,
    null,
  );

  const body = JSON.stringify(signedNotificationObj);

  const masterWallet = new ethers.Wallet(MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(body);

  const response = await retryFetch(() =>
    fetch(
      SOCIAL_MEDIA_API +
      'notification/' +
      signedNotificationObj.sender +
      '/expotoken/delete/' +
      masterSignature,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      },
    ),
  );

  if (response.status == 200) {
    return response;
  } else {
    LogCustomError(
      'deleteNativeNotificationToken',
      response?.status?.toString(),
      response?.statusText,
      null,
    );
  }
};
