import React, { FC, useEffect } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import Animated, {
  runOnJS,
  useAnimatedProps,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { interpolatePath } from 'react-native-redash';
import usePath from './hooks/usePath';
import { getPathXCenter } from './utils/path';
import TabItem from './TabItem';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { defaultTheme } from '../../themes/loot8';
import useActiveDimensions from '../../hooks/useActiveDimensions';

const AnimatedPath = Animated.createAnimatedComponent(Path);
export const CustomBottomTab: FC<BottomTabBarProps> = ({
  state,
  descriptors,
  navigation,
}) => {
  const { containerPath, curvedPaths, tHeight } = usePath();
  const activeWidth = useActiveDimensions().width;
  const circleXCoordinate = useSharedValue(0);
  const progress = useSharedValue(3);
  const handleMoveCircle = (currentPath: string) => {
    circleXCoordinate.value = getPathXCenter(currentPath);
  };
  const animatedProps = useAnimatedProps(() => {
    const currentPath = interpolatePath(
      progress.value,
      Array.from({ length: curvedPaths.length }, (_, index) => index + 1),
      curvedPaths,
    );
    runOnJS(handleMoveCircle)(currentPath);
    return {
      d: `${containerPath} ${currentPath}`,
    };
  }, [activeWidth, containerPath]);

  const handleTabPress = (index: number, tab: string) => {
    if (state.index + 1 === index) {
      if (state.index + 1 === 3) {
        navigation.reset({
          index: 0,
          routes: [{ name: 'HomeTab', params: { screen: 'HomeScreen' } }],
        });
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: tab }],
        });
      }
    } else {
      navigation.navigate(tab);
      progress.value = withTiming(index);
    }
  };
  useEffect(() => {
    if (state.index + 1 !== progress.value) {
      progress.value = withTiming(state.index + 1);
    }
  }, [state.index]);
  return (
    <View style={styles.tabBarContainer}>
      <Svg
        width={activeWidth}
        height={tHeight}
        style={Platform.OS === 'web' ? styles.shadowWeb : styles.shadowMd}>
        <AnimatedPath
          fill={defaultTheme.MAIN_BACKGROUND_COLOR}
          animatedProps={animatedProps}
        />
      </Svg>
      <View
        style={[
          styles.tabItemsContainer,
          {
            height: tHeight,
          },
        ]}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label = options.title ? options.title : route.name;
          return (
            <TabItem
              key={index.toString()}
              label={label as string}
              activeIndex={state.index + 1}
              index={index}
              onTabPress={() => handleTabPress(index + 1, route.name)}
            />
          );
        })}
      </View>
    </View>
  );
};
export default CustomBottomTab;
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  tabBarContainer: {
    position: 'absolute',
    bottom: 0,
    zIndex: 2,
  },
  tabItemsContainer: {
    position: 'absolute',
    flexDirection: 'row',
    width: '100%',
  },
  shadowMd: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.58,
    shadowRadius: 16.0,
    elevation: 24,
  },
  shadowWeb: {
    filter: 'drop-shadow(rgba(0, 0, 0, 0.28) 0px 1px 16px)',
  },
});
