import { Dimensions } from 'react-native';

type FONT_WEIGHTS =
  | 'normal'
  | 'bold'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';
const WEIGHTS: FONT_WEIGHTS[] = ['300', '400', '500', '600', '700', '800'];

//DIMENSIONS
export const WIDTH = Dimensions.get('window').width;
export const HEIGHT = Dimensions.get('window').height;

export const defaultTheme = {
  //COLORS
  SECONDARY_COLOR: '#FFFFFF26',
  MAIN_BACKGROUND_COLOR: '#211D28',
  CARD_BG_COLOR: '#232336',
  PRIMARY_TEXT_COLOR: '#FFFFFF',
  SECONDARY_TEXT_COLOR: '#A4A4A4',

  //GRADIENTS AS PRIMARY COLOR
  GRADIENT_COLOR1: '#9A4DFF',
  GRADIENT_COLOR2: '#F600DD',

  //BOX SHADOWS
  PRIMARY_SHADOW_COLOR: '#00000040',

  //BORDER RADIUS
  CONTENT_RADIUS: 8,
  BUTTON_RADIUS: 24,

  INPUT_HEIGHT: 48,
  DEFAULT_BORDER_RADIUS: 24,

  //HORIZONTAL PADDING
  HORIZONTAL_PADDING: 15,

  //CONTAINER STYLES
  CONTENT_CONTAINER_MAX_WIDTH: 1024,

  //FONT SIZES
  FONT_SIZE_XXXSMALL: 10,
  FONT_SIZE_XXSMALL: 12,
  FONT_SIZE_XXXMEDIUM: 13,
  FONT_SIZE_XXMEDIUM: 14,
  FONT_SIZE_MEDIUM_TEXT: 15,
  FONT_SIZE_XMEDIUM: 16,
  FONT_SIZE_XSMALL: 18,
  FONT_SIZE_SMALL: 20,
  FONT_SIZE_SMALLX: 22,
  FONT_SIZE_MEDIUM: 24,
  FONT_SIZE_LARGE: 30,
  FONT_SIZE_XLARGE: 56,
  FONT_SIZE_XXLARGE: 85,
  FONT_SIZE_11: 11,
  FONT_SIZE_12: 12,

  //FONT FAMILY
  FONT_FAMILY_BLACK: 'Inter-Black', //900
  FONT_FAMILY_EXTRABOLD: 'Inter-ExtraBold', //800
  FONT_FAMILY_BOLD: 'Inter-Bold', //700
  FONT_FAMILY_SEMI_BOLD: 'Inter-SemiBold', //600
  FONT_FAMILY_MEDIUM: 'Inter-Medium', //500
  FONT_FAMILY_REGULAR: 'Inter-Regular', // 400
  FONT_FAMILY_LIGHT: 'Inter-Light', // 300
  FONT_FAMILY_EXTRALIGHT: 'Inter-ExtraLight', // 200
  FONT_FAMILY_THIN: 'Inter-Thin', // 100
  FONT_FAMILY_MEDIUM_ITALIC: 'Inter-MediumItalic', // 500 in Italic

  //FONT WEIGHTS
  FONT_WEIGHT_XXSMALL: WEIGHTS[0],
  FONT_WEIGHT_XSMALL: WEIGHTS[1],
  FONT_WEIGHT_SMALL: WEIGHTS[2],
  FONT_WEIGHT_MEDIUM: WEIGHTS[3],
  FONT_WEIGHT_LARGE: WEIGHTS[4],
  FONT_WEIGHT_XLARGE: WEIGHTS[5],

  ///OLD ===> STYLES

  // CONTENT_BACKGROUND_COLOR: '#FFFFFFF5', //'#28284A',
  // CONTENT_NAME_BACKGROUND_COLOR: '#FFFFFF', //'#28284A',
  // BUTTON_PRIMARY_BACKGROUND_COLOR: '#C0136F', //'#28284A',
  // BUTTON_SECONDARY_BACKGROUND_COLOR: '#5E5EAA', //'#28284A',
  // BUTTON_TERTIARY_BACKGROUND_COLOR: '#DBE2ED', //'#28284A',
  // BUTTON_QUARTERNARY_BACKGROUND_COLOR: '#36264F',
  BUTTON_FAB_BACKGROUND_COLOR: '#EB3797',
  // BUTTON_BORDER_COLOR: '#666691',
  // ACTIVE_PRIMARY_COLOR: '#F4B540', //'#28284A',
  // ACTIVE_PRIMARY_COLOR_DARKEN: '#F2A71B', //'#28284A',
  // INACTIVE_PRIMARY_COLOR: '#FCFCFC', //'#28284A',
  // GRAY_BACKGROUND_COLOR: '#DEE3EA',

  // MAIN_SECOND_BACKGROUND_COLOR: '#8D2A80',
  // CONTAINER_BORDER_COLOR: '#ffffff08',

  // TEXT_COLOR_PRIMARY: '#4E4E93',
  // TEXT_COLOR_DARK: '#333333',
  TEXT_COLOR_SECONDARY: '#FCFCFC',
  // TEXT_SEARCH_COLOR: '#DBE2ED',
  // TEXT_INPUT_BACKGROUND_COLOR: '#F5F7FA',
  // BORDER_COLOR_PRIMARY: '#ffffff',
  // TEXT_COLOR_BLACK: '#000000',
  // TEXT_COLOR_SUBTITLE: '#EB3797',
  // TEXT_COLOR_SUBTITLE_SHAWDOW: '#dfb3ca',
  // AVATAR_BORDER_COLOR: '#707070',
  // MODAL_HEADER_COLOR: '#252546',
  // ICON_COLOR_LIGHT: '#FCFCFC',
  // CONTENT_HEADER_TEXT_COLOR: '#4E4E93',
  // COUNT_CONTAINER: '#D20071',
  // HAIRLINE: '#F7F6F7',
  FRIEND_ADD_BUTTON_BACKGROUND: '#41ED0E',
  // ACCORDION_BACKGROUND_COLOR: '#0A28504F',
  // ACCORDION_DESC_BGCOLOR: '#2f3047AF',
};

//FONTS CONFIG RN PAPER
export const fontConfig = {
  bodyLarge: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 16,
    fontWeight: '400',
    letterSpacing: 0.15,
    lineHeight: 24,
  },
  bodyMedium: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 14,
    fontWeight: '400',
    letterSpacing: 0.25,
    lineHeight: 20,
  },
  bodySmall: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 12,
    fontWeight: '400',
    letterSpacing: 0.4,
    lineHeight: 16,
  },
  displayLarge: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 57,
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: 64,
  },
  displayMedium: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 45,
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: 52,
  },
  displaySmall: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 36,
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: 44,
  },
  headlineLarge: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 32,
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: 40,
  },
  headlineMedium: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 28,
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: 36,
  },
  headlineSmall: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: 24,
    fontWeight: '400',
    letterSpacing: 0,
    lineHeight: 32,
  },
  labelLarge: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0.1,
    lineHeight: 20,
  },
  labelMedium: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 12,
    fontWeight: '500',
    letterSpacing: 0.5,
    lineHeight: 16,
  },
  labelSmall: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 11,
    fontWeight: '500',
    letterSpacing: 0.5,
    lineHeight: 16,
  },
  titleLarge: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 22,
    fontWeight: '500',
    letterSpacing: 0,
    lineHeight: 28,
  },
  titleMedium: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 16,
    fontWeight: '500',
    letterSpacing: 0.15,
    lineHeight: 24,
  },
  titleSmall: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: 14,
    fontWeight: '500',
    letterSpacing: 0.1,
    lineHeight: 20,
  },
};
