import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { BackIconSvg, LogoSvg } from '../../../assets/svg/HeaderSvgs';

const BackButton = ({
  onPress,
  hideBackIcon,
}: {
  onPress?: () => void;
  hideBackIcon?: boolean;
}) => {
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();

  const handleBackPress = () => {
    onPress ? onPress() : navigation.goBack();
  };

  return (
    <View
      style={[
        styles.container,
        {
          height: 50,
        },
      ]}>
      {!hideBackIcon && (
        <View style={styles.backButtonContainer}>
          <TouchableRipple
            style={styles.ripple}
            onPress={handleBackPress}
            rippleColor={'rgba(0,0,0,0.25)'}>
            <BackIconSvg />
          </TouchableRipple>
        </View>
      )}
      <LogoSvg />
    </View>
  );
};

export default BackButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  backButtonContainer: {
    width: 45,
    height: 45,
    marginRight: 10,
    borderRadius: 50,
    overflow: 'hidden',
  },
  ripple: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
