import { View } from 'react-native';
import React from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { defaultTheme } from '../../themes/loot8';
import { styles } from './styles';
import { TouchableRipple } from 'react-native-paper';
import { AntDesign } from '@expo/vector-icons';

interface Props {
  size: number;
  onPress?: () => {};
}

const GradientPlusButton: React.FC<Props> = ({ onPress, size }) => {
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR2, defaultTheme.GRADIENT_COLOR1]}
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={[styles.container, { width: size, height: size }]}>
      <View style={styles.rippleWrapper}>
        <TouchableRipple
          style={styles.contentContainer}
          rippleColor={'rgba(0,0,0,0.25)'}
          onPress={onPress}>
          <>
            <AntDesign name="plus" size={size / 2} color={'#FFF'} />
          </>
        </TouchableRipple>
      </View>
    </LinearGradient>
  );
};

export default GradientPlusButton;
