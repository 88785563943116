import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    height: 60,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 7.5,
    shadowColor: '#00000040',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,
    elevation: 15,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0.9,
  },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XMEDIUM + 2,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textTransform: 'capitalize',
  },
  subtitle: {
    marginTop: 4,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textTransform: 'capitalize',
  },
  modalTextStyle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    letterSpacing: 1,
    textAlign: 'center',
  },
});
