import { Platform, StyleSheet, Text, View } from 'react-native';
import React from 'react';
import ActionSheet from 'react-native-actions-sheet';
import styles from '../../styles';
import { BlurView } from 'expo-blur';
import { ReShareWebBlurView } from '../WebBlurView';
import { defaultTheme } from '../../themes/loot8';
import { TouchableRipple } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';

const ActionSheetContent = ({ props }) => {
  return (
    <View style={componentStyles.container}>
      {!props?.insufficientBalance && (
        <>
          <Text style={componentStyles.title}>{props.showMessageTitle}</Text>
          <Text style={componentStyles.subtitle}>
            {props.showMessageSubTitle}
          </Text>
          <View style={componentStyles.separator} />
          <ConfirmButton
            onPress={() => props.onPressButton()}
            title={props.buttonTitle}
          />
        </>
      )}

      {props?.insufficientBalance && (
        <>
          <Text
            style={{
              marginTop: 5,
              textAlign: 'justify',
              fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
              fontSize: defaultTheme.FONT_SIZE_XXSMALL,
              color: defaultTheme.PRIMARY_TEXT_COLOR,
            }}>
            {props?.isNativeChain
              ? 'Your Loot8 token balance is insufficient to complete this transaction. Please purchase more tokens and try again.'
              : 'Your USDC balance is insufficient to complete this transaction. Please add USDC tokens to the wallet and then try again.'}
          </Text>
          {props?.isNativeChain && (
            <View style={{ marginTop: 15, marginBottom: 25 }}>
              <ConfirmButton
                onPress={props?.navigateToTokenPackages}
                title={'Deposit Funds'}
              />
            </View>
          )}
        </>
      )}
    </View>
  );
};
const MarketPlaceConfirmationSheet = props => {
  return (
    <ActionSheet
      ref={props.actionSheetRef}
      id={props.sheetId}
      drawUnderStatusBar
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      enableGesturesInScrollView={false}>
      {Platform.OS === 'web' ? (
        <ReShareWebBlurView>
          <View
            style={{
              borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
              overflow: 'hidden',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <ActionSheetContent props={props} />
            </LinearGradient>
          </View>
        </ReShareWebBlurView>
      ) : (
        <BlurView
          tint="dark"
          style={{
            borderTopRightRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            borderTopLeftRadius: defaultTheme.DEFAULT_BORDER_RADIUS,
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0.2)',
          }}
          intensity={Platform.OS === 'android' ? 15 : 25}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <ActionSheetContent props={props} />
          </LinearGradient>
        </BlurView>
      )}
    </ActionSheet>
  );
};

export default MarketPlaceConfirmationSheet;

const componentStyles = StyleSheet.create({
  container: { paddingHorizontal: 15, paddingVertical: 25 },
  title: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginTop: 2.5,
  },
  btnText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_TEXT_COLOR,
    opacity: 0.6,
    marginTop: 15,
    marginBottom: 25,
  },
  buttonContainer: {
    height: 48,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightTextInputImg: {
    height: 30,
    width: 30,
    borderRadius: 30,
    overflow: 'hidden',
  },
});

interface ConfirmButtonProps {
  onPress: () => void;
  title: string;
  disabled?: boolean;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  onPress,
  title,
  disabled = false,
}) => {
  return (
    <View
      style={[
        componentStyles.buttonContainer,
        { opacity: disabled ? 0.6 : 0.8 },
      ]}>
      <TouchableRipple
        disabled={disabled}
        style={componentStyles.ripple}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}>
        <Text style={componentStyles.btnText}>{title}</Text>
      </TouchableRipple>
    </View>
  );
};
