import React from 'react';
import {
  AppleSvg,
  DiscordSvg,
  FacebookSvg,
  GithubSvg,
  GoogleSvg,
  LinkedInSvg,
  TwitchSvg,
  TwitterXSvg,
  RedditSvg,
} from '../../assets/svg/AuthSvgs';
import { LOGIN_PROVIDER } from '@web3auth/react-native-sdk';

export const signInProviderData = [
  {
    id: 1,
    name: 'google',
    icon: <GoogleSvg />,
    loginProvider: LOGIN_PROVIDER.GOOGLE,
  },
  {
    id: 2,
    name: 'discord',
    icon: <DiscordSvg />,
    loginProvider: LOGIN_PROVIDER.DISCORD,
  },
  {
    id: 3,
    name: 'facebook',
    icon: <FacebookSvg />,
    loginProvider: LOGIN_PROVIDER.FACEBOOK,
  },
  {
    id: 4,
    name: 'twitterX',
    icon: <TwitterXSvg />,
    loginProvider: LOGIN_PROVIDER.TWITTER,
  },
  {
    id: 5,
    name: 'apple',
    icon: <AppleSvg />,
    loginProvider: LOGIN_PROVIDER.APPLE,
  },
  {
    id: 6,
    name: 'github',
    icon: <GithubSvg />,
    loginProvider: LOGIN_PROVIDER.GITHUB,
  },
  {
    id: 7,
    name: 'linkedIn',
    icon: <LinkedInSvg />,
    loginProvider: LOGIN_PROVIDER.LINKEDIN,
  },
  {
    id: 8,
    name: 'twitch',
    icon: <TwitchSvg />,
    loginProvider: LOGIN_PROVIDER.TWITCH,
  },
  {
    id: 9,
    name: 'reddit',
    icon: <RedditSvg />,
    loginProvider: LOGIN_PROVIDER.REDDIT,
  },
];
