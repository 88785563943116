import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import ChatScreen from '../screens/ChatScreen';
import CustomHeader from './AnimatedHeader/CustomHeader';
import PrivateChatRoomScreen from '../screens/PrivateChatRoomScreen';

const Stack = createStackNavigator();

function ChatNavigator() {
  const navigation = useNavigation();

  useEffect(() => {
    navigation.addListener('blur', () => {
      if (Platform.OS === 'web') {
        history.replaceState(null, document.title, window.location.origin);
      }
    });
  }, [navigation]);

  return (
    <Stack.Navigator
      initialRouteName="ChatScreen"
      screenOptions={{
        headerShown: true,
        headerStyle: {
          borderBottomWidth: 0,
          backgroundColor: 'transparent',
          height: Platform.OS === 'ios' ? 120 : 70,
        },
        headerMode: 'float',
        header: props => <CustomHeader {...props} />,
      }}>
      <Stack.Screen name="ChatScreen" component={ChatScreen} />
      <Stack.Screen
        name="PrivateChatRoomScreen"
        component={PrivateChatRoomScreen}
      />
    </Stack.Navigator>
  );
}

export default ChatNavigator;
