import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  container: {
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,

    elevation: 15,
  },
  contentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
});
