import React, { useEffect, useRef, useState } from 'react';
import { View, Text, Image, Platform, Dimensions } from 'react-native';
import { defaultTheme } from '../../themes/loot8';
import styles from '../../styles';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import AppLoaderComponent from '../Loader';
import { ScreenName } from '../../enums/screen.enum';
import EightIcon from '../../assets/eightIcon.png';
import { Button } from 'react-native-paper';
import { useRevenueCat } from '../../hooks/RevenueCatProvider';
import { wait } from '../../helpers/ipfs';
import { getAppConfiguration } from '../../appconstants';
import { LogCustomError } from '../../helpers/AppLogger';
import WebPayment from '../WebPayment';
import { getData, storeData } from '../../helpers/AppStorage';
import ActionSheet, { ActionSheetRef } from 'react-native-actions-sheet';
import { BlurView } from 'expo-blur';
import { ReShareWebBlurView } from '../WebBlurView';
import HOCContainer from '../HOCContainer';
import GradientButton from '../GradientButton';

const BuyTokenItems = ({ packageItem }) => {
  return (
    <View style={styles.tokenBuyItemsContainer}>
      <View style={styles.tokenBuyItemContainer}>
        <Text style={styles.tokenBuyItemCaption}>Token Name</Text>
        <View style={{ flexDirection: 'row' }}>
          <Image
            source={EightIcon}
            resizeMode="contain"
            style={{ marginLeft: 5, width: 20, height: 20, marginRight: 5 }}
          />
          <Text style={styles.tokenBuyItemCaption}>LOOT8</Text>
        </View>
      </View>
      <View style={styles.tokenBuyHairline}></View>
      <View style={styles.tokenBuyItemContainer}>
        <Text style={styles.tokenBuyItemCaption}>Package</Text>
        {Platform.OS == 'web' ? (
          <Text style={styles.tokenBuyItemCaption}>{packageItem?.title?.replace(/loot8/gi, 'LOOT8')}</Text>
        ) : (
          <Text style={styles.tokenBuyItemCaption}>
            {packageItem?.product?.title?.replace(/loot8/gi, 'LOOT8')}
          </Text>
        )}
      </View>
      <View style={styles.tokenBuyHairline}></View>
      <View style={styles.tokenBuyHairline}></View>
      <View style={styles.tokenBuyItemContainer}>
        <Text style={styles.tokenBuyItemCaption}>Amount</Text>
        {Platform.OS == 'web' ? (
          <Text style={styles.tokenBuyItemCaption}>
            {packageItem?.currencySymbol}
            {packageItem?.price}{' '}
          </Text>
        ) : (
          <Text style={styles.tokenBuyItemCaption}>
            {packageItem?.product?.priceString}
          </Text>
        )}
      </View>
    </View>
  );
};

const BuyTokenMessage = ({
  showMessageTitle,
  showMessageSubTitle,
  windowWidth,
  navigateToWallet,
}) => {
  return (
    <View style={{ padding: 10, alignItems: 'center' }}>
      <Text style={[styles.tokenBuyModalTitle, { paddingBottom: 5 }]}>
        {showMessageTitle}
      </Text>
      {showMessageSubTitle && (
        <Text style={styles.tokenBuyModalSecondTitle}>
          {showMessageSubTitle}
        </Text>
      )}
      {/* <Text style={styles.tokenBuyModalSecondTitle}>{"Please check your wallet balance after couple of minutes."}</Text> */}
      <View style={{ flexDirection: 'row' }}>
        <Button
          onPress={navigateToWallet}
          style={[
            styles.tokenBuyButton,
            {
              width: '50%',
              alignSelf: 'center',
              backgroundColor: defaultTheme.ACTIVE_PRIMARY_COLOR,
            },
          ]}
          labelStyle={[
            styles.tokenBuyButtonLableStyle,
            {
              fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
              color: defaultTheme.TEXT_COLOR_BLACK,
            },
          ]}>
          Okay!
        </Button>
      </View>
    </View>
  );
};

const BuyToken = ({ navigation, route }) => {
  const { packages, purchasePackage } = useRevenueCat();

  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [packageItem, setPackageItem] = useState(null);
  const [showMessageModel, setShowMessageModel] = useState(false);
  const windowWidth = Dimensions.get('window').width;

  const packageIdentifier = route.params?.packageIdentifier;
  const navigationFrom = route.params?.navigationFrom;
  const [purchaseInProgress, setPurchaseInProgress] = useState(false);
  const [showPaypalGateway, setShowPaypalGateway] = useState(false);
  const [webPackages, setWebPackages] = useState(null);
  const [showMessageTitle, setShowMessageTitle] = useState('Congratulations');
  const [showMessageSubTitle, setShowMessageSubTitle] = useState(
    'Transaction successful. Please check your wallet balance after sometime.',
  );
  // const [tokenQuantity, setTokenQuantity] = useState(0);

  const subscribeSheetRef = useRef<ActionSheetRef>(null);

  const loadAppConfig = async () => {
    try {
      let config = await getAppConfiguration();

      // if(config && Platform.OS == "android") {
      //     const androidInAppPurchase = config?.androidInAppPurchase;
      //     if(androidInAppPurchase?.packages?.length > 0 && packageItem?.product) {
      //         const pack = androidInAppPurchase?.packages.find(x => x.identifier == packageItem?.product?.identifier);
      //         if(pack) {
      //             setTokenQuantity(pack.quantity);
      //         }
      //     }
      // } else if(config && Platform.OS == "ios") {
      //     const iosInAppPurchase = config?.iosInAppPurchase;
      //     if(iosInAppPurchase?.packages?.length > 0 && packageItem?.product) {
      //         const pack = iosInAppPurchase?.packages.find(x => x.identifier == packageItem?.product?.identifier);
      //         if(pack) {
      //             setTokenQuantity(pack.quantity);
      //         }
      //     }
      // }

      if (config && Platform.OS == 'web') {
        const webInAppPurchase = config?.webInAppPurchase;
        if (webInAppPurchase?.packages?.length > 0) {
          setWebPackages(webInAppPurchase?.packages);
        }
      }
    } catch (e) {
      LogCustomError(
        'TokenPackages | loadAppConfig',
        e.name,
        e.message,
        e.stack,
      );
    }
  };

  useEffect(() => {
    loadAppConfig();
    let packgage = null;
    if (Platform.OS == 'web' && webPackages?.length > 0) {
      packgage = webPackages?.find(
        x =>
          x.identifier?.toLocaleLowerCase() ==
          packageIdentifier?.toLocaleLowerCase(),
      );
    }
    if (packages?.length > 0) {
      packgage = packages?.find(
        x =>
          x.identifier?.toLocaleLowerCase() ==
          packageIdentifier?.toLocaleLowerCase(),
      );
    }

    if (packgage) {
      setPackageItem(packgage);
    }
  }, [packageIdentifier, webPackages]);

  const navigateToWallet = () => {
    setShowMessageModel(false);
    subscribeSheetRef?.current.hide();
    navigation.navigate(ScreenName.WALLET_TAB, {
      screen: ScreenName.WALLET,
      params: {
        refreshBalance: true,
      },
      initial: false,
    });
  };

  const onPurchase = async () => {
    setPurchaseInProgress(true);
    let success = false;
    if (packageItem && (Platform.OS == 'android' || Platform.OS == 'ios')) {
      // Purchase the package
      success = await purchasePackage(packageItem);
    }
    await wait(1500);
    if (success) {
      navigation.navigate('Success', {
        screen: ScreenName.SUCCESS_SCREEN,
        params: {
          message:
            'Transaction successful. Please check your wallet balance after sometime.',
          navigateTo: ScreenName.BUY_TOKEN,
        },
        navigationParams: {},
      });
    }
    setPurchaseInProgress(false);
  };

  const onWebPurchase = async () => {
    const userAddress = await getData('@user_address');
    let userSelectedPackage = { ...packageItem, appUserId: userAddress };
    await storeData('@selected_package', userSelectedPackage);

    setPurchaseInProgress(true);
    setShowPaypalGateway(true);
  };

  const navigateToPackages = () => {
    navigation.goBack();
  };

  const navigateToPassport = () => {
    setShowMessageModel(false);
    navigation.goBack();
  };

  const onWebPaymentDissmiss = async () => {
    await storeData('@selected_package', '');
    setPurchaseInProgress(false);
    setShowPaypalGateway(false);
  };

  const onWebPaymentMessage = async (data: string) => {
    await storeData('@selected_package', '');
    setPurchaseInProgress(false);
    setShowPaypalGateway(false);
    if (data.toLocaleLowerCase() == 'success') {
      navigation.navigate('Success', {
        screen: ScreenName.SUCCESS_SCREEN,
        params: {
          message:
            'Transaction successful. Please check your wallet balance after sometime.',
          navigateTo: ScreenName.BUY_TOKEN,
        },
        navigationParams: {},
      });
    } else if (data.toLocaleLowerCase() == 'failed') {
      setShowMessageModel(true);
      setTimeout(() => {
        subscribeSheetRef.current.show();
      }, 500);
      setShowMessageTitle('Transaction Failed.');
      setShowMessageSubTitle(null);
    }
  };

  const hideMessageModal = () => {
    setShowMessageModel(false);
    subscribeSheetRef?.current?.hide();
  };

  return (
    <>
      <View style={{ flex: 1 }}>
        {/* container */}
        <View style={{ alignItems: 'center', flex: 1 }}>
          <View style={styles.tokenPackagesSecondTitleCotainer}>
            <Text style={styles.tokenPackagesSecondTitle}>
              Please verify the below details before confirming your purchase
            </Text>
          </View>
          <View style={styles.tokenBuyTokenDetails}>
            <BuyTokenItems packageItem={packageItem} />
          </View>
          <View style={{ bottom: 30, position: 'absolute', width: '100%' }}>
            <GradientButton
              buttonLabel="Deposit Funds"
              disabled={purchaseInProgress}
              loading={purchaseInProgress}
              onPress={() => {
                if (Platform.OS != 'web') {
                  onPurchase();
                } else {
                  onWebPurchase();
                }
              }}
            />
            {Platform.OS == 'web' && showPaypalGateway && (
              <WebPayment
                onDismiss={() => {
                  onWebPaymentDissmiss();
                }}
                onMessage={e => {
                  onWebPaymentMessage(e);
                }}
              />
            )}
          </View>
        </View>
      </View>
      {loading && (
        <View
          style={{
            minHeight: 70,
            width: '100%',
            backgroundColor: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
            opacity: 0.3,
          }}>
          <AppLoaderComponent />
        </View>
      )}
      {showMessageModel && (
        <ActionSheet
          ref={subscribeSheetRef}
          drawUnderStatusBar
          containerStyle={{
            ...styles.maxWidthAdjust,
            backgroundColor: 'transparent',
            overflow: 'hidden',
          }}
          gestureEnabled={true}
          backgroundInteractionEnabled={false}
          closeOnTouchBackdrop={false}
          onClose={hideMessageModal}>
          {Platform.OS === 'android' ? (
            <View
              style={[
                styles.passportSubscribeSheetContent,
                {
                  ...styles.modalCenteredContainer,
                  backgroundColor: 'rgba(169, 175, 179,.95)',
                },
              ]}>
              <BuyTokenMessage
                showMessageTitle={showMessageTitle}
                showMessageSubTitle={showMessageSubTitle}
                windowWidth={windowWidth}
                navigateToWallet={navigateToWallet}
              />
            </View>
          ) : Platform.OS == 'ios' ? (
            <BlurView
              tint="light"
              style={[
                styles.passportSubscribeSheetContent,
                { overflow: 'hidden' },
              ]}
              intensity={60}>
              <BuyTokenMessage
                showMessageTitle={showMessageTitle}
                showMessageSubTitle={showMessageSubTitle}
                windowWidth={windowWidth}
                navigateToWallet={navigateToWallet}
              />
            </BlurView>
          ) : (
            <ReShareWebBlurView>
              <BuyTokenMessage
                showMessageTitle={showMessageTitle}
                showMessageSubTitle={showMessageSubTitle}
                windowWidth={windowWidth}
                navigateToWallet={navigateToWallet}
              />
            </ReShareWebBlurView>
          )}
        </ActionSheet>
      )}
    </>
  );
};

export default HOCContainer({
  OriginalComponent: BuyToken,
  title: 'Deposit Funds',
});
