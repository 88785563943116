import {
  FlatList,
  ImageBackground,
  Text,
  View,
  Image,
  Platform,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { styles } from './styles';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';
import { defaultTheme } from '../../themes/loot8';
import AsyncStorage from '@react-native-async-storage/async-storage';
import background from '../../assets/onboard-bg.png';
import backgroundXL from '../../assets/onboard-bgXL.png';
import { LinearGradient } from 'expo-linear-gradient';
import GradientButton from '../GradientButton';
import useIsResponsive from '../../hooks/useIsResponsive';
import useActiveDimensions from '../../hooks/useActiveDimensions';

const OnboardingComponent = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [hideModal, setHideModal] = useState(false);
  const ref = useRef();
  const progressIndicator = useSharedValue(8);
  const progressIndicator1 = useSharedValue(8);
  const progressIndicator2 = useSharedValue(8);
  const activeWidth = useActiveDimensions().width;
  const activeHeight = useActiveDimensions().height;
  const reanimatedIndicatorStyle = useAnimatedStyle(() => {
    return {
      width: progressIndicator.value,
    };
  }, []);
  const reanimatedIndicator1Style = useAnimatedStyle(() => {
    return {
      width: progressIndicator1.value,
    };
  }, []);
  const reanimatedIndicator2Style = useAnimatedStyle(() => {
    return {
      width: progressIndicator2.value,
    };
  }, []);
  useEffect(() => {
    if (currentSlideIndex === 0) {
      progressIndicator.value = withSpring(56);
    } else {
      progressIndicator.value = withSpring(30);
    }
    if (currentSlideIndex === 1) {
      progressIndicator1.value = withSpring(56);
    } else {
      progressIndicator1.value = withSpring(30);
    }
    if (currentSlideIndex === 2) {
      progressIndicator2.value = withSpring(56);
    } else {
      progressIndicator2.value = withSpring(30);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlideIndex]);
  const updateCurrentSlideIndex = e => {
    const contentOffset =
      activeWidth > 450
        ? e.nativeEvent.contentOffset.y
        : e.nativeEvent.contentOffset.x;
    const currentIndex =
      activeWidth > 450
        ? Math.round(contentOffset / activeHeight)
        : Math.round(contentOffset / activeWidth);
    setCurrentSlideIndex(currentIndex);
  };

  const leftLoading = useSharedValue(-activeWidth);
  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      left: leftLoading.value,
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      checkOnboardStatus();
    }, 1000);
  }, []);
  const checkOnboardStatus = async () => {
    const check = await AsyncStorage.getItem('onBoardCompleted');
    if (check) {
      setHideModal(true);
      return;
    } else {
      leftLoading.value = withTiming(0);
    }
  };

  const slides = [
    {
      id: 1,
      title: 'The Ultimate Fan Experience Ecosystem',
      image:
        activeWidth > 450
          ? require('../../assets/onboard1XL.png')
          : require('../../assets/onboard1.png'),
    },
    {
      id: 2,
      title: 'Collect, Own and Share Your digital items!',
      image:
        activeWidth > 450
          ? require('../../assets/onboard2XL.png')
          : require('../../assets/onboard2.png'),
    },
    {
      id: 3,
      title: `Join Your Favorite Personalitie's Inner Circle!`,
      image: require('../../assets/onboard3.png'),
    },
  ];
  if (hideModal) {
    return <></>;
  }
  return (
    <Animated.View
      style={[styles.container, { width: activeWidth }, reanimatedStyle]}>
      <ImageBackground
        source={activeWidth > 450 ? backgroundXL : background}
        resizeMode="cover"
        style={{ flex: 1 }}>
        <FlatList
          ref={ref}
          onMomentumScrollEnd={updateCurrentSlideIndex}
          onScrollEndDrag={updateCurrentSlideIndex}
          onScroll={updateCurrentSlideIndex}
          disableIntervalMomentum={true}
          data={slides}
          horizontal={activeWidth > 450 ? false : true}
          snapToAlignment="start"
          decelerationRate={Platform.OS === 'web' ? 0 : 'fast'}
          snapToInterval={activeWidth}
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          bounces={false}
          renderItem={({ item }) => (
            <Slide
              item={item}
              currentSlideIndex={currentSlideIndex}
              onPress={() => {
                leftLoading.value = withTiming(-activeWidth);
                AsyncStorage.setItem('onBoardCompleted', 'done');
              }}
            />
          )}
          keyExtractor={item => item.id.toString()}
        />
        <Indicators
          slides={slides}
          currentSlideIndex={currentSlideIndex}
          reanimatedIndicatorStyle={reanimatedIndicatorStyle}
          reanimatedIndicator1Style={reanimatedIndicator1Style}
          reanimatedIndicator2Style={reanimatedIndicator2Style}
        />
      </ImageBackground>
    </Animated.View>
  );
};

export default OnboardingComponent;

const Slide = ({ item, onPress, currentSlideIndex }) => {
  const isResponsive = useIsResponsive();
  const activeWidth = useActiveDimensions().width;
  const activeHeight = useActiveDimensions().height;

  return (
    <View
      style={[
        styles.slidesContainer,
        {
          height: activeHeight,
          width: activeWidth,
        },
      ]}>
      <Image
        source={item.image}
        resizeMode={'contain'}
        style={[
          styles.slidesImage,
          {
            alignSelf: activeWidth > 450 ? 'flex-end' : 'center',
            height: activeHeight,
            marginRight: activeWidth > 450 ? -activeWidth / 25 : 0,
            width: activeWidth > 450 ? activeWidth / 2 : activeWidth,
          },
        ]}
      />
      <LinearGradient
        colors={['#00000000', '#25253AA1']}
        start={{ x: 0.1, y: 0.2 }}
        style={[
          styles.slidesInnerContainer,
          {
            width: activeWidth,
            paddingTop:
              activeWidth > 450 ? activeHeight / 2.25 : activeHeight / 1.8,
          },
        ]}>
        <Text
          style={[
            styles.title,
            {
              paddingHorizontal: activeWidth > 450 ? activeWidth / 5 : 0,
              textAlign: activeWidth > 450 ? 'center' : 'left',
              fontSize: activeWidth > 450 ? activeWidth / 25 : activeWidth / 10,
              lineHeight:
                activeWidth > 450 ? activeWidth / 22.5 : activeWidth / 8.5,
            },
          ]}>
          {item.title}
        </Text>
        {item.id === 3 && (
          <View
            style={
              activeWidth > 450
                ? styles.btnContainerWeb
                : styles.btnContainerMob
            }>
            <GradientButton buttonLabel={'Get Started'} onPress={onPress} />
          </View>
        )}
      </LinearGradient>
    </View>
  );
};

const Indicators = ({
  currentSlideIndex,
  reanimatedIndicatorStyle,
  reanimatedIndicator1Style,
  reanimatedIndicator2Style,
  slides,
}) => {
  return (
    <View style={styles.indicatorContainer}>
      {/* Indicator container */}
      <View style={styles.row}>
        {/* Render indicator */}
        {slides.map((_, index) => {
          return (
            <Animated.View
              key={index}
              style={[
                styles.indicator,
                index === 0 && reanimatedIndicatorStyle,
                index === 1 && reanimatedIndicator1Style,
                index === 2 && reanimatedIndicator2Style,
                currentSlideIndex == index && {
                  backgroundColor: defaultTheme.PRIMARY_TEXT_COLOR,
                },
              ]}
            />
          );
        })}
      </View>
    </View>
  );
};
