import React from 'react';
import { Text, View, StyleSheet, SafeAreaView } from 'react-native';
import LottieView from 'lottie-react-native';
import { defaultTheme } from '../themes/loot8';
import HOCContainer from '../components/HOCContainer';
import GradientButton from '../components/GradientButton';
import useActiveDimensions from '../hooks/useActiveDimensions';
import congratulationsAnimation from '../assets/animations/congratulationAnimation.json';

const SuccessScreen: React.FC<{ route: any; navigation: any }> = ({
  route,
  navigation,
}) => {
  const activeHeight = useActiveDimensions().height;
  const { message, buttonLabel, navigateTo, navigationParams } =
    route.params ?? {};
  const handleNavigation = () => {
    if (navigateTo) {
      navigation.navigate(navigateTo, navigationParams);
    } else {
      navigation.goBack();
    }
  };

  return (
    <SafeAreaView style={styles.container}>
      <LottieView
        autoPlay
        loop={false}
        style={[
          {
            width: activeHeight / 3.5,
            height: activeHeight / 3.5,
          },
          styles.animationStyles,
        ]}
        source={congratulationsAnimation}
      />
      <View style={styles.item}>
        <Text style={styles.textPrimary}>Congratulations!</Text>
        <Text style={styles.textSecondary}>{message ? message : ''}</Text>
      </View>

      <GradientButton
        buttonLabel={buttonLabel ? buttonLabel : 'Return'}
        onPress={() => handleNavigation()}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 60,
  },
  animationStyles: {
    alignSelf: 'center',
    justifyContent: 'center',
  },
  item: {
    flex: 1,
    marginTop: 50,
    alignItems: 'center',
  },
  textPrimary: {
    fontSize: 18,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
    textTransform: 'uppercase',
  },
  textSecondary: {
    fontSize: 16,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    textAlign: 'center',
    width: '75%',
    marginTop: 10,
  },
});

export default HOCContainer({
  OriginalComponent: SuccessScreen,
  withBottomTabs: false,
  isScrollEnabled: false,
});
