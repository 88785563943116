import * as ImageManipulator from 'expo-image-manipulator';
import { LogCustomError } from './AppLogger';
import { LogToLoot8Console } from './Loot8ConsoleLogger';

export const getBase64Image = async (ImageURI, isResizeImg: boolean = true, height = 512, width = 512) => {
  let result;
  if (isResizeImg) {
    //LogToLoot8Console('perform resize')
    result = await ImageManipulator.manipulateAsync(ImageURI, [{ resize: { height: height, width: width } }], {
      base64: true,
      format: ImageManipulator.SaveFormat.JPEG,
    }).catch(error => {
      LogToLoot8Console('Error in Image Manipulator while resizing image');
      console.error(error);
      LogCustomError('getBase64Image', error.name, error.message, error.stack);
    });
  } else {
    //LogToLoot8Console('do not perform resize')
    result = await ImageManipulator.manipulateAsync(ImageURI, [], {
      base64: true,
      format: ImageManipulator.SaveFormat.JPEG,
    }).catch(error => {
      LogToLoot8Console('Error in Image Manipulator converting image');
      console.error(error);
      LogCustomError('getBase64Image', error.name, error.message, error.stack);
    });
  }

  return result;
};
