import styled from '@emotion/styled';

const WebBlurView = styled.div`
  background-color: rgba(180, 180, 180, 0.6);
  color: white;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
`;
const ModalWebBlurView = styled.div`
  background-color: rgba(180, 180, 180, 0.3);
  color: white;
  backdrop-filter: blur(10px);
  max-width: 650px;
  align-self: center;
  width: 100%;
  border-radius: 6px;
`;
const ReShareWebBlurView = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
`;
const CollectibleWebBlurView = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  backdrop-filter: blur(5px);
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;
const PassportNameWebBlurView = styled.div`
  background-color: rgba(180, 180, 180, 0.6);
  color: white;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  top: 35%;
  max-width: 80%;
  right: 0;
  position: absolute;
  padding: 18px 15px 18px 15px;
`;
const CollectiblesNameeWebBlurView = styled.div`
  background-color: rgba(180, 180, 180, 0.6);
  color: white;
  backdrop-filter: blur(10px);
  justify-content: center;
  align-items: center;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  top: 40%;
  max-width: 80%;
  right: 0;
  position: absolute;
  padding: 18px 15px 18px 15px;
`;

export {
  WebBlurView,
  ReShareWebBlurView,
  ModalWebBlurView,
  PassportNameWebBlurView,
  CollectibleWebBlurView,
  CollectiblesNameeWebBlurView,
};
