import React, { useState, useEffect, useCallback } from 'react';
import {
  View,
  Text,
  Image,
  Platform,
  Keyboard,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { FlashList } from '@shopify/flash-list';
import { IconButton } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import styles from '../../styles';
import ModalComponent from '../Modal';
import {
  checkUserExists,
  setIsReplyPosted,
  deleteUserMessage,
  markMessagesAsRead,
  setLastReadTimestamp,
} from '../../slices/PassportMessageSlice';
import AppLoaderComponent from '../Loader';
import GradientButton from '../GradientButton';
import { defaultTheme } from '../../themes/loot8';
import { ScreenName } from '../../enums/screen.enum';
import { getUserJSONdata } from '../../helpers/ipfs';
import UserDetailModal from '../Modal/UserDetailModal';
import { getSyncedTime } from '../../helpers/DateHelper';
import { showToastMessage } from '../../helpers/Gadgets';
import { IMessageList } from '../../interfaces/IMessages';
import { IUser } from '../../interfaces/IUser.interface';
import { PassportMessageItem } from './passportMessageItem';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getUserPublicKey } from '../../slices/AppUserSlice';
import { FriendsTabs } from '../../enums/friendsPageTabs.enum';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { addFriend, removeFriend } from '../../slices/friendsSlice';
import { ToastCustomMessageType, ZERO_ADDRESS } from '../../appconstants';

import user_add from '../../assets/user-plus.png';
import user_remove from '../../assets/user-minus.png';

export const getSenderName = msgItem => {
  return msgItem && msgItem.user && msgItem.user.wallet === ZERO_ADDRESS
    ? 'Deleted User'
    : msgItem.user.name && msgItem.user.name != ''
      ? msgItem.user.name
      : 'Loot8User';
};

export const getSenderID = msgItem => {
  return msgItem.user &&
    msgItem.user.id &&
    !isNaN(Number(msgItem.user.id)) &&
    Number(msgItem.user.id) != 0
    ? ' #' + +msgItem.user.id
    : '';
};

const PassportMessageComponent = ({
  isSocialAvailable,
  yCoordinate,
  setScrollToMessages,
  scrollViewRef,
  scrollToMessages,
  selectedPassport,
  navigationFrom,
  scrollToText,
}) => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();
  const { height } = useWindowDimensions();

  const { networkId, staticProvider, wallet, address } = useWeb3AuthContext();

  const messageDetails = useAppSelector(
    state => state.PassportMessage.messages,
  );
  const AllUserDetails = useAppSelector(state => state.AppUser.AllUsersData);
  const noSocialMessages = useAppSelector(
    state => state.PassportMessage.noSocialMessages,
  );
  const initialMessageLoading = useAppSelector(
    state => state.PassportMessage.initialMessageLoading,
  );

  // const newMsgCount = messageDetails.filter(m => !m.isRead && m.data?.sender != address).length;
  const [sortedMessages, setSortedMessages] = useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showUserInfoModal, setShowUserInfoModal] = React.useState(false);
  const [showDeletePostModal, setShowDeletePostModal] = React.useState(false);
  const [selectedMsg, setSelectedMsg] = React.useState<IMessageList>(null);
  const [showAddReply, setShowAddReply] = useState(false);
  const [replyMessageID, setReplyMessageID] = useState(null);
  const [activeDeleteAction, setActiveDeleteAction] = useState<string>();

  const [enablePrivateMenuItem, setEnablePrivateMenuItem] = useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const setMsgAsRead = msgId => {
    if (msgId) {
      dispatch(markMessagesAsRead([msgId]));
      dispatch(
        setLastReadTimestamp({
          lastReadTimestamp: getSyncedTime(),
          userAddress: address,
        }),
      );
    }
  };

  const onMessageMenuClick = async (msgItem: IMessageList) => {
    if (Keyboard && Keyboard.dismiss) {
      Keyboard.dismiss();
    }
    if (msgItem) {
      if (
        msgItem?.data?.sender == address &&
        msgItem?.messageId == activeDeleteAction
      ) {
        return;
      }
      setMsgAsRead(msgItem?.messageId);
      setShowModal(true);
      setSelectedMsg(msgItem);
      if (msgItem?.user?.wallet && msgItem?.isSenderFriend) {
        const publicKey = await getUserPublicKey(msgItem?.user?.wallet);
        if (publicKey) {
          setEnablePrivateMenuItem(true);
        } else {
          setEnablePrivateMenuItem(false);
        }
      } else {
        setEnablePrivateMenuItem(false);
      }
    }
  };

  const addSenderAsFriend = async msgItem => {
    if (msgItem) {
      const senderAddress = msgItem.data?.sender;
      let userData = await checkUserExists({
        networkID: networkId,
        provider: staticProvider,
        allUsersData: AllUserDetails,
        userAddress: senderAddress,
      });
      if (!userData || userData?.wallet === ZERO_ADDRESS) {
        showToastMessage(
          ToastCustomMessageType.INFO,
          'Unable to add ' +
            getSenderName(msgItem) +
            ' as a friend, as the account has been deleted',
        );
      } else {
        dispatch(
          addFriend({
            networkID: networkId,
            provider: staticProvider,
            address: address,
            wallet: wallet,
            followUserAddress: senderAddress,
          }),
        ).then(() => {
          showToastMessage(
            ToastCustomMessageType.INFO,
            getSenderName(msgItem) + ' has been added to your friends list',
          );
        });
        //dispatch(setFriendForSender({sender: senderAddress, isFriend: true}));
      }
    }
    setShowModal(false);
    setSelectedMsg(null);
  };

  const removeSenderFromFriend = async msgItem => {
    if (msgItem) {
      const senderAddress = msgItem.data?.sender;
      let userData = await checkUserExists({
        networkID: networkId,
        provider: staticProvider,
        allUsersData: AllUserDetails,
        userAddress: senderAddress,
      });
      if (!userData || userData?.wallet === ZERO_ADDRESS) {
        showToastMessage(
          ToastCustomMessageType.INFO,
          getSenderName(msgItem) +
            ' is not your friend anymore, as the account has been deleted!',
        );
      } else {
        dispatch(
          removeFriend({
            networkID: networkId,
            provider: staticProvider,
            address: address,
            wallet: wallet,
            followUserAddress: senderAddress,
          }),
        ).then(() => {
          showToastMessage(
            ToastCustomMessageType.INFO,
            getSenderName(msgItem) + ' has been removed from your friends list',
          );
        });
        //dispatch(setFriendForSender({sender: senderAddress, isFriend: false}));
      }
    }
    setShowModal(false);
    setSelectedMsg(null);
  };

  const closeReply = () => {
    setShowAddReply(false);
    setReplyMessageID(null);
    dispatch(setIsReplyPosted(false));
  };

  const addReply = messageID => {
    if (messageID) {
      dispatch(markMessagesAsRead([messageID]));
      setShowAddReply(true);
      setReplyMessageID(messageID);
      if (Platform.OS === 'android') {
        dispatch(setIsReplyPosted(false)); //Bug LOOT8-800: Android App - Not able to reply in pages shifting scenario
      }
    }
  };

  // const onMarkAllRead = () => {
  //     if(messageDetails && messageDetails.length > 0)
  //     {
  //         const unreadMessage = messageDetails.filter(m => !m.isRead && m.data?.sender != address)
  //             .map(m => m.messageId );
  //         if(unreadMessage && unreadMessage.length > 0){
  //             dispatch(markMessagesAsRead(unreadMessage));
  //             dispatch(setLastReadTimestamp({ lastReadTimestamp: getSyncedTime(), userAddress: address }));
  //         }
  //     }
  // }

  const deletePost = () => {
    setShowModal(false);
    setTimeout(() => {
      setShowDeletePostModal(true);
    }, 500);
  };

  const onDeletePost = (message: IMessageList) => {
    setActiveDeleteAction(message.messageId);
    dispatch(
      deleteUserMessage({
        networkID: networkId,
        chainId: selectedPassport.chainId,
        provider: staticProvider,
        address: selectedPassport.address,
        wallet,
        messageId: message.messageId,
        parentId: message.data.parent || null,
      }),
    );
    setShowDeletePostModal(false);
    setTimeout(() => {
      setActiveDeleteAction(null);
    }, 3000);
  };

  const onRedirectDirectMessage = (user: IUser) => {
    navigation.navigate(ScreenName.FRIENDS_TAB, {
      selectedTab: FriendsTabs.DIRECTMESSAGE,
      selectedFriend: user,
      navigationFrom: navigationFrom,
    });
  };

  const onSetShowUserInfoModal = useCallback(
    async item => {
      if (!showUserInfoModal) {
        let userStatus =
          item.wallet && (await getUserJSONdata(item?.wallet))?.status;
        if (userStatus) {
          item = { ...item, status: userStatus };
        }
        setSelectedUser(item);
        setTimeout(() => {
          setShowUserInfoModal(true);
        }, 500);
      } else {
        setShowUserInfoModal(false);
      }
    },
    [showUserInfoModal],
  );

  useEffect(() => {
    const filteredMessages = messageDetails
      ?.filter(m => 1 === 1)
      .sort((a, b) => {
        return a?.timestamp > b?.timestamp
          ? -1
          : a?.timestamp < b?.timestamp
            ? 1
            : 0;
      });
    setSortedMessages(filteredMessages);
  }, [messageDetails]);

  useEffect(() => {
    if (scrollToMessages && sortedMessages.length > 0) {
      setTimeout(() => {
        scrollViewRef?.current?.scrollToPosition(0, yCoordinate - 100, true);
        setScrollToMessages(false);
      }, 500);
    }
  }, [sortedMessages.length, initialMessageLoading]);

  return (
    <View style={styles.socialMessagingMainContainer}>
      {!isSocialAvailable ? (
        <Text
          style={[
            styles.noPassportMsgContainer,
            styles.noPassportMsgText,
            { flex: 1 },
          ]}>
          <Text style={{ color: defaultTheme.PRIMARY_TEXT_COLOR }}>
            Social Media feature has been currently disabled for{' '}
          </Text>
          <Text style={{ fontFamily: defaultTheme.FONT_FAMILY_BOLD }}>
            {selectedPassport?.name?.trim() ||
              'this ' +
                selectedPassport?.catalogType?.substring(
                  0,
                  selectedPassport?.catalogType?.length - 1,
                )}
          </Text>
        </Text>
      ) : noSocialMessages ? (
        <Text
          style={[
            styles.noPassportMsgContainer,
            styles.noPassportMsgText,
            { flex: 1 },
          ]}>
          <Text>No messages published in the </Text>
          <Text
            style={{
              fontFamily: defaultTheme.FONT_FAMILY_BOLD,
              color: defaultTheme.PRIMARY_TEXT_COLOR,
            }}>
            {selectedPassport?.name?.trim() || 'feed'}{' '}
          </Text>
          <Text style={{ color: defaultTheme.PRIMARY_TEXT_COLOR }}>
            yet. Take the chance and be the first.!!
          </Text>
        </Text>
      ) : (
        <View style={{ minHeight: 300 }}>
          {initialMessageLoading ? (
            <View
              style={{
                minHeight: 50,
                margin: 20,
                opacity: 0.3,
                marginTop: 25,
              }}>
              <AppLoaderComponent loaderText={{ text: 'Loading Messages' }} />
            </View>
          ) : (
            <FlashList
              scrollEnabled={Platform.OS === 'web'}
              showsVerticalScrollIndicator={false}
              data={sortedMessages}
              estimatedItemSize={200}
              renderItem={({ item, index }) => (
                <PassportMessageItem
                  item={item}
                  index={index}
                  messagesCount={sortedMessages ? sortedMessages.length - 1 : 0}
                  selectedPassport={selectedPassport}
                  onMessageMenuClick={onMessageMenuClick}
                  closeReply={closeReply}
                  addReply={addReply}
                  showAddReply={showAddReply}
                  replyMessageID={replyMessageID}
                  messageDetails={messageDetails}
                  scrollToText={scrollToText}
                  onSetShowUserInfoModal={onSetShowUserInfoModal}
                />
              )}
              extraData={[selectedPassport, showAddReply, replyMessageID]}
              keyExtractor={item => item?.messageId}
            />
          )}
        </View>
      )}
      {showModal && selectedMsg ? (
        <ModalComponent
          showModal={showModal}
          onDismiss={() => {
            setSelectedMsg(null);
            setShowModal(false);
          }}
          enableHeader={false}
          modalStyle={{
            justifyContent: 'flex-end',
            marginBottom: Platform.OS == 'web' ? 60 : height - height * 0.93,
          }}
          modalBodyStyle={{
            justifyContent: 'flex-start',
            alignSelf: 'flex-start',
            width: '100%',
          }}>
          <View style={{ padding: 5 }}>
            {selectedMsg.data.sender == address && (
              <TouchableOpacity
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 15,
                }}
                onPress={() => deletePost()}>
                <IconButton
                  icon="trash-can-outline"
                  iconColor={defaultTheme.PRIMARY_TEXT_COLOR}
                  style={{
                    height: 22,
                    marginLeft: 5,
                    marginRight: 10,
                  }}
                />
                <Text
                  style={{
                    color: defaultTheme.PRIMARY_TEXT_COLOR,
                    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                    marginTop: 1,
                  }}>
                  Delete Post
                </Text>
              </TouchableOpacity>
            )}
            {selectedMsg.data.sender !== address ? (
              <>
                {selectedMsg.isSenderFriend ? (
                  <TouchableOpacity
                    style={{ flexDirection: 'row', padding: 15 }}
                    onPress={() => removeSenderFromFriend(selectedMsg)}>
                    <Image
                      source={user_remove}
                      style={{
                        width: 24,
                        height: 20,
                        marginLeft: 5,
                        marginRight: 10,
                        tintColor: defaultTheme.PRIMARY_TEXT_COLOR,
                      }}
                    />
                    <Text
                      style={{
                        color: defaultTheme.PRIMARY_TEXT_COLOR,
                        fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                        fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                        marginTop: 1,
                      }}>
                      Remove Friend
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    style={{ flexDirection: 'row', padding: 15 }}
                    onPress={() => addSenderAsFriend(selectedMsg)}>
                    <Image
                      source={user_add}
                      style={{
                        width: 24,
                        height: 20,
                        marginLeft: 5,
                        marginRight: 10,
                        tintColor: defaultTheme.PRIMARY_TEXT_COLOR,
                      }}
                    />
                    <Text
                      style={{
                        color: defaultTheme.PRIMARY_TEXT_COLOR,
                        fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                        fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                        marginTop: 1,
                      }}>
                      Add Friend
                    </Text>
                  </TouchableOpacity>
                )}
                <View
                  style={{
                    alignSelf: 'center',
                    width: '95%',
                    height: 1,
                    backgroundColor: '#DBE2ED',
                    opacity: 0.4,
                  }}
                />
                {!selectedMsg.isSenderFriend || !enablePrivateMenuItem ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: 15,
                    }}>
                    <IconButton
                      icon="chat-outline"
                      iconColor={defaultTheme.PRIMARY_TEXT_COLOR}
                      style={{
                        width: 24,
                        height: 24,
                        marginLeft: 5,
                        marginRight: 10,
                        opacity: 0.4,
                      }}
                    />
                    <Text
                      style={{
                        color: defaultTheme.PRIMARY_TEXT_COLOR,
                        opacity: 0.4,
                        fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                        fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                        marginTop: 1,
                      }}>
                      Send Private Message
                    </Text>
                  </View>
                ) : (
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: 15,
                    }}
                    onPress={() => {
                      onRedirectDirectMessage(selectedMsg?.user);
                    }}>
                    <IconButton
                      icon="chat-outline"
                      iconColor={defaultTheme.PRIMARY_TEXT_COLOR}
                      style={{
                        width: 24,
                        height: 24,
                        marginLeft: 5,
                        marginRight: 10,
                      }}
                    />
                    <Text
                      style={{
                        color: defaultTheme.PRIMARY_TEXT_COLOR,
                        fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
                        fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
                        marginTop: 1,
                      }}>
                      Send Private Message
                    </Text>
                  </TouchableOpacity>
                )}
              </>
            ) : (
              <></>
            )}
          </View>
        </ModalComponent>
      ) : (
        <></>
      )}
      {showDeletePostModal ? (
        <ModalComponent
          showModal={showDeletePostModal}
          modalStyle={{
            justifyContent: 'flex-start',
            marginTop: Platform.OS == 'web' ? '17%' : height - height * 0.87,
          }}
          headerText="Delete Post22"
          onDismiss={() => {
            setShowDeletePostModal(false);
          }}>
          <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
            <Text style={styles.modalTextStyle}>
              {'Are you sure you want to delete this post?'}
            </Text>
          </View>
          <View style={styles.modalButtonContainer}>
            <GradientButton
              width={'45%'}
              type="secondary"
              disabled={false}
              buttonLabel={'NO'}
              onPress={() => {
                setShowDeletePostModal(false);
              }}
            />
            <GradientButton
              width={'45%'}
              buttonLabel={'YES'}
              onPress={() => {
                onDeletePost(selectedMsg);
              }}
            />
          </View>
        </ModalComponent>
      ) : (
        <></>
      )}
      {showUserInfoModal && selectedUser && (
        <UserDetailModal
          user={selectedUser}
          isVisible={showUserInfoModal}
          onDismiss={() => onSetShowUserInfoModal(false)}
        />
      )}
    </View>
  );
};

export default PassportMessageComponent;
