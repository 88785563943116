import React, { useState } from 'react';
import { SafeAreaView, StyleSheet, Text, TextInput, View } from 'react-native';
import { useDispatch } from 'react-redux';

import {
  hideEstReqInviteCodeModal,
  showEstReqInviteCodeModal,
} from '../../slices/ModalsSlice';
import {
  sendRequestEmail,
  getRequestMailStatus,
} from '../services/Message.service';
import { AppDispatch } from '../../store';
import { defaultTheme } from '../../themes/loot8';
import BackButton from '../components/BackButton';
import ModalComponent from '../../components/Modal';
import { setMailStatus } from '../slices/AppUserSlice';
import HOCContainer from '../../components/HOCContainer';
import GradientButton from '../../components/GradientButton';
import { useWeb3AuthContext } from '../../hooks/web3authContext';

const EstPortalRequestCode = ({ navigation }) => {
  const dispatch = useDispatch<AppDispatch>();

  const { userInfo, address, wallet } = useWeb3AuthContext();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [responseModal, setResponseModal] = useState({
    show: false,
    title: '',
    message: '',
  });

  const handleRequestCode = async () => {
    setLoading(true);
    dispatch(showEstReqInviteCodeModal());
    const sendMessage = {
      walletAddress: address,
      replyTo: userInfo.email.trim(),
      userName: userInfo.name,
      text: message.trim(),
    };

    try {
      const response = await sendRequestEmail(sendMessage, wallet);
      if (response && response.status === 200) {
        const getMailStatus = await getRequestMailStatus(wallet);
        dispatch(setMailStatus(getMailStatus));
        navigation.navigate('EstPortalSuccessScreen', {
          message:
            'Your request for a LOOT8 Access Code was successful. Please wait while our team reviews your request!',
          buttonLabel: 'Return',
          onPressActonButton: () => {
            navigation.navigate('EstPortalAuth');
          },
        });
      } else {
        setTimeout(() => {
          setResponseModal({
            show: true,
            title: 'Fail',
            message: 'Too Many Requests. Please try again later!',
          });
        }, 500);
      }
    } catch (e) {
      setTimeout(() => {
        setResponseModal({
          show: true,
          title: 'Fail',
          message: 'Something went wrong',
        });
      }, 500);
    } finally {
      setLoading(false);
      dispatch(hideEstReqInviteCodeModal());
    }
  };

  return (
    <SafeAreaView>
      <BackButton />

      <View style={styles.container}>
        <Text style={styles.title}>Request Code</Text>
        <Text style={styles.subTitle}>
          Tell us a little about yourself and why you should get a{' '}
          <Text style={styles.description}>LOOT8 Access Code!</Text>
        </Text>
        <TextInput
          multiline
          blurOnSubmit
          autoCapitalize="none"
          cursorColor="rgb(219, 226, 237)"
          selectionColor="rgb(219, 226, 237)"
          placeholder={'Write your message...'}
          maxLength={360}
          autoCorrect={false}
          returnKeyType="done"
          value={message}
          onChangeText={text => setMessage(text)}
          style={styles.textContainer}
          placeholderTextColor={defaultTheme.SECONDARY_TEXT_COLOR}
        />
        <View style={styles.button} />
        <GradientButton
          disabled={!message?.length || loading}
          onPress={handleRequestCode}
          buttonLabel={'Request Code'}
        />
        <ModalComponent
          needCloseButton
          enableHeader={false}
          showModal={responseModal?.show}
          onDismiss={() => {
            setResponseModal({ show: false, title: '', message: '' });
          }}
          modalBodyStyle={styles.modalBody}>
          <Text style={styles.modalTitleStyle}>{responseModal.title}</Text>
          <View style={styles.separator} />
          <Text style={styles.modalTextStyle}>{responseModal.message}</Text>
        </ModalComponent>
      </View>
    </SafeAreaView>
  );
};

export default HOCContainer({
  OriginalComponent: EstPortalRequestCode,
  withBottomTabs: false,
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 10,
    alignItems: 'center',
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
  },
  title: {
    marginBottom: 6,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
  subTitle: {
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
  },
  description: {
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
  textContainer: {
    height: 200,
    padding: 16,
    fontSize: 12,
    width: '100%',
    marginTop: 68,
    borderRadius: 12,
    textAlign: 'left',
    fontWeight: '500',
    textAlignVertical: 'top',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
  },
  button: {
    marginTop: 16,
  },
  modalBody: {
    width: '100%',
    paddingVertical: 25,
    paddingHorizontal: 15,
  },
  modalTitleStyle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    letterSpacing: 1,
    textAlign: 'center',
  },
  modalTextStyle: {
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    letterSpacing: 1,
    textAlign: 'center',
  },
  separator: {
    height: 1,
    marginVertical: 10,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
  },
});
