import React from 'react';
import { View, Text, Platform, TouchableOpacity } from 'react-native';
import { BlurView } from 'expo-blur';
import { IconButton } from 'react-native-paper';
import ActionSheet from 'react-native-actions-sheet';
import { LinearGradient } from 'expo-linear-gradient';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

import { getFriendName } from '.';
import styles from '../../styles';
import GradientButton from '../GradientButton';
import { defaultTheme } from '../../themes/loot8';
import { ReShareWebBlurView } from '../WebBlurView';
import { FriendsModalTypes } from '../../enums/friendsPageTabs.enum';

const SelectedModalContent = ({ props }) => {
  return props.modalType === FriendsModalTypes.SHOWOPTIONS ? (
    <View style={{ paddingVertical: 20, paddingHorizontal: 20 }}>
      {!props.selectedFriend.isFriend || !props.enablePrivateMenuItem ? (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <IconButton
            icon="chat-outline"
            iconColor="#FFFFFF"
            style={{
              width: 24,
              height: 24,
              marginLeft: 5,
              marginRight: 10,
              opacity: 0.4,
            }}
          />
          <Text style={[styles.friendsOptionsText, { opacity: 0.4 }]}>
            {`Message ${getFriendName(props.selectedFriend.name, 'name')}`}
          </Text>
        </View>
      ) : (
        <TouchableOpacity
          style={{ flexDirection: 'row', alignItems: 'center' }}
          onPress={() => props.onNameSectionClick(props.selectedFriend)}>
          <IconButton
            icon="chat-outline"
            iconColor="#FFFFFF"
            style={{
              width: 24,
              height: 24,
              marginLeft: 5,
              marginRight: 10,
              marginTop: 8,
            }}
          />
          <Text style={styles.friendsOptionsText}>
            {`Message ${getFriendName(props.selectedFriend.name, 'name')}`}
          </Text>
        </TouchableOpacity>
      )}

      {
        <TouchableOpacity
          style={{ flexDirection: 'row', marginTop: 20 }}
          onPress={() =>
            props.openModalPopup(
              props.selectedFriend,
              FriendsModalTypes.UNFOLLOWMODAL,
            )
          }>
          <MaterialCommunityIcons
            name="account-cancel-outline"
            size={24}
            color="#FFFFFF"
            style={{ marginLeft: 5, marginRight: 10, marginTop: 3 }}
          />
          <View style={{ flexDirection: 'column' }}>
            <Text style={styles.friendsOptionsText}>{'Unfollow'}</Text>
            <Text
              style={{
                marginTop: 1,
                color: defaultTheme.PRIMARY_TEXT_COLOR,
                fontSize: defaultTheme.FONT_SIZE_XXSMALL,
                fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
              }}>
              {'Remove ' +
                getFriendName(props.selectedFriend.name, 'name') +
                ' from your friend list'}
            </Text>
          </View>
        </TouchableOpacity>
      }
    </View>
  ) : props.modalType === FriendsModalTypes.UNFOLLOWMODAL ? (
    <View style={{ paddingVertical: 10, paddingHorizontal: 20 }}>
      <Text
        style={{
          fontSize: 14,
          width: '95%',
          color: '#fff',
          marginTop: 10,
          textAlign: 'center',
          alignSelf: 'center',
          fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
        }}>
        {`Are you sure you want to stop following ${props.selectedFriend.name} ?`}
      </Text>

      <View
        style={{
          width: '100%',
          height: 70,
          flexDirection: 'row',
          alignItems: 'center',
          padding: 10,
          justifyContent: 'space-evenly',
          alignSelf: 'center',
        }}>
        <GradientButton
          width={'45%'}
          type="secondary"
          disabled={false}
          buttonLabel={'Cancel'}
          onPress={() => props.actionSheetRef.current.hide()}
        />
        <GradientButton
          width={'45%'}
          buttonLabel={'Confirm'}
          disabled={props.isSendRequestLoader}
          loading={props.isSendRequestLoader}
          onPress={() => props.onUnFriend()}
          type="alternate"
        />
      </View>
    </View>
  ) : props.modalType === FriendsModalTypes.ALERT3HRS ? (
    <View style={{ paddingVertical: 10, paddingHorizontal: 20 }}>
      <Text
        style={{
          fontSize: 13,
          fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
          color: defaultTheme.CONTENT_NAME_BACKGROUND_COLOR,
          textAlign: 'center',
          width: '85%',
          alignSelf: 'center',
          marginTop: 10,
        }}>
        {`"Please wait! New request can be sent only after 3 hrs from your previous request."`}
      </Text>
    </View>
  ) : (
    <></>
  );
};
const popupsActionSheet = props => {
  return (
    <ActionSheet
      ref={props.actionSheetRef}
      drawUnderStatusBar
      containerStyle={{
        ...styles.maxWidthAdjust,
        backgroundColor: 'transparent',
        overflow: 'hidden',
      }}
      gestureEnabled={true}
      backgroundInteractionEnabled={false}
      // closeOnTouchBackdrop={false}
    >
      {Platform.OS === 'android' ? (
        <View
          style={{
            ...styles.modalCenteredContainer,
            backgroundColor: 'rgba(169, 175, 179,.97)',
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
          }}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <SelectedModalContent props={props} />
          </LinearGradient>
        </View>
      ) : Platform.OS === 'ios' ? (
        <BlurView
          tint="light"
          style={{
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            overflow: 'hidden',
          }}
          intensity={60}>
          <LinearGradient
            colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={{ ...styles.maxWidthAdjust }}>
            <SelectedModalContent props={props} />
          </LinearGradient>
        </BlurView>
      ) : (
        <ReShareWebBlurView>
          <View
            style={{
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              overflow: 'hidden',
            }}>
            <LinearGradient
              colors={['rgba(154, 77, 255, 0.5)', 'rgba(246, 0, 221, 0.5)']}
              start={{ x: 0.2, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={{ ...styles.maxWidthAdjust }}>
              <SelectedModalContent props={props} />
            </LinearGradient>
          </View>
        </ReShareWebBlurView>
      )}
    </ActionSheet>
  );
};

export default popupsActionSheet;
