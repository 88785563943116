import React from 'react';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';
import { Text } from 'react-native-paper';
import Ionicons from 'react-native-vector-icons/Ionicons';
import ProfileAvatar from '../ProfileAvatar';
import GradientButton from '../GradientButton';
import { IFriends } from '../../slices/friendsSlice';
import useIsResponsive from '../../hooks/useIsResponsive';
import LinkVariantIcon from '../../assets/LinkVariant.png';
import TimerOutlineIcon from '../../assets/TimerOutline.png';
import LinkVariantOffIcon from '../../assets/LinkVariantOff.png';
import ThreeDotsSettingsButton from '../ThreeDotsSettingsButton';
import { defaultTheme } from '../../themes/loot8';
import { useAppSelector } from '../../hooks';

const FriendItem = ({
  item,
  openOptionsModal,
  openModalUserDetailPopup,
  mutualFriendsMessagesData,
  searchText,
  isManageFriends,
  onNameSectionClick,
  unReadMessagesData,
  OnPressFollow,
}: {
  item: IFriends;
  openModalUserDetailPopup: any;
  openOptionsModal: any;
  mutualFriendsMessagesData: any;
  searchText: string;
  isManageFriends: boolean;
  onNameSectionClick: any;
  unReadMessagesData: any;
  OnPressFollow: any;
}) => {
  const isResponsive = useIsResponsive();
  const { requestSentId } = useAppSelector(state => state.friends);
  return (
    <TouchableOpacity
      onPress={() => (item?.isFriend ? onNameSectionClick(item) : {})}
      activeOpacity={1}
      style={[{ flex: isResponsive ? 0.49 : 1 }, componentStyles.container]}
      key={item?.wallet}>
      {/* Avatar Start */}
      <TouchableOpacity onPress={() => openModalUserDetailPopup(item)}>
        <ProfileAvatar
          size={45}
          source={
            item?.avatarURI &&
            item?.avatarURI != '' &&
            item?.avatarURI != 'no-avatar' &&
            !item?.avatarURI.includes('ipfs://') &&
            !item?.avatarURI.includes('assets_avatars_p_') &&
            !item?.avatarURI.includes('file:///')
              ? { uri: item?.avatarURI }
              : null
          }
          userAddress={item?.wallet}
        />
        {item?.isMutualFriend && item?.isFriend && (
          <Image
            source={LinkVariantIcon}
            style={componentStyles.floatingIcon}
            resizeMode="contain"
          />
        )}
        {!item?.isMutualFriend && item?.isFriend && (
          <Image
            source={LinkVariantOffIcon}
            style={componentStyles.floatingIcon}
            resizeMode="contain"
          />
        )}
      </TouchableOpacity>
      {/* Avatar End */}
      <View style={componentStyles.middleInnerContainer}>
        {/* Title Row Start */}
        <View style={componentStyles.row}>
          <Text style={componentStyles.userTitle} numberOfLines={1}>
            {`${item?.name} `}
            <Text style={componentStyles.userIdText}>{`#${item?.id}  `}</Text>
          </Text>
          {item?.isFriend &&
            !item?.isMutualFriend &&
            item?.friendRequest.isRequestSend &&
            !item?.friendRequest.isCancelled &&
            item?.friendRequest.messageId && (
              <Image
                source={TimerOutlineIcon}
                style={{ width: 16, height: 16 }}
                resizeMode="contain"
              />
            )}
          {unReadMessagesData(item?.wallet, 'count') > 0 && (
            <View style={componentStyles.counterCircle}>
              <Text style={componentStyles.counterBubbleText}>
                {unReadMessagesData(item?.wallet, 'count')
                  ? unReadMessagesData(item?.wallet, 'count') > 10
                    ? '10+'
                    : unReadMessagesData(item?.wallet, 'count')
                  : ''}{' '}
              </Text>
            </View>
          )}
        </View>
        {/* Title Row End */}

        {/* Subtitle row Start */}
        {!item?.isMutualFriend && item?.isFriend && (
          <Text style={componentStyles.italicText}>
            Friend request has been sent. Pending approval
          </Text>
        )}

        {item?.isMutualFriend && !isManageFriends && !searchText && (
          <>
            {mutualFriendsMessagesData &&
              mutualFriendsMessagesData.length > 0 && (
                <>
                  {unReadMessagesData(item?.wallet, 'text') == 'Image' ? (
                    <View style={componentStyles.row}>
                      <Ionicons
                        name="image"
                        size={15}
                        color={defaultTheme.SECONDARY_TEXT_COLOR}
                        style={{ marginLeft: 1 }}
                      />
                      <Text
                        style={[componentStyles.italicText, { marginTop: 0 }]}>
                        {' Photo'}
                      </Text>
                    </View>
                  ) : unReadMessagesData(item?.wallet, 'text') ==
                    'You:Image' ? (
                    <View style={componentStyles.row}>
                      <Text style={componentStyles.italicText}>{'You: '}</Text>
                      <Ionicons
                        name="image"
                        size={15}
                        color={defaultTheme.SECONDARY_TEXT_COLOR}
                        style={{ marginLeft: 1 }}
                      />
                      <Text
                        style={[componentStyles.italicText, { marginTop: 0 }]}>
                        {' Photo'}
                      </Text>
                    </View>
                  ) : (
                    <Text
                      style={componentStyles.italicText}
                      numberOfLines={1}
                      ellipsizeMode="tail">
                      {unReadMessagesData(item?.wallet, 'text')
                        ? unReadMessagesData(item?.wallet, 'text')
                        : ''}
                    </Text>
                  )}
                </>
              )}
          </>
        )}

        {/* Subtitle row End */}
      </View>
      {/*  Right Follow/Unfollow Button */}
      <View style={componentStyles.rightInnerContainer}>
        {!isManageFriends ? (
          <>
            {item?.isFriend ? (
              <View style={{ height: 20, width: 24 }} />
            ) : (
              <GradientButton
                width={76}
                height={36}
                fontSize={10}
                disabled={requestSentId && requestSentId !== item?.wallet}
                loading={requestSentId && requestSentId == item?.wallet}
                buttonLabel={'FOLLOW'}
                onPress={() => OnPressFollow(item)}
              />
            )}
          </>
        ) : (
          <GradientButton
            width={84}
            height={36}
            fontSize={10}
            buttonLabel={'UNFOLLOW'}
            onPress={() => openOptionsModal(item, false)}
          />
        )}
      </View>
      {!isManageFriends && item?.isFriend && (
        <ThreeDotsSettingsButton
          extraStyles={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          onPress={() => openOptionsModal(item, true)}
        />
      )}
    </TouchableOpacity>
  );
};

const areEqual = (
  prevProps: {
    index: number;
    item: IFriends;
    searchText: string;
    isManageFriends: boolean;
    mutualFriendsMessagesData;
    selectedFriend: any;
  },
  nextProps: {
    index: number;
    item: IFriends;
    searchText: string;
    isManageFriends: boolean;
    mutualFriendsMessagesData: any;
  },
) => {
  let isEqualItem = true;

  if (prevProps?.item?.avatarURI !== nextProps?.item?.avatarURI) {
    isEqualItem = false;
  } else if (prevProps?.item?.isExist !== nextProps?.item?.isExist) {
    isEqualItem = false;
  } else if (prevProps?.item?.isFriend !== nextProps?.item?.isFriend) {
    isEqualItem = false;
  } else if (
    prevProps?.item?.isMutualFriend !== nextProps?.item?.isMutualFriend
  ) {
    isEqualItem = false;
  } else if (
    prevProps?.item?.friendRequest?.isRequestSend !==
    nextProps?.item?.friendRequest?.isRequestSend
  ) {
    isEqualItem = false;
  } else if (
    prevProps?.item?.friendRequest?.isCancelled !==
    nextProps?.item?.friendRequest?.isCancelled
  ) {
    isEqualItem = false;
  } else if (prevProps?.item?.name !== nextProps?.item?.name) {
    isEqualItem = false;
  } else if (prevProps?.item?.status !== nextProps?.item?.status) {
    isEqualItem = false;
  } else if (
    nextProps?.searchText === '' &&
    prevProps?.searchText !== nextProps?.searchText
  ) {
    isEqualItem = false;
  } else if (prevProps?.isManageFriends !== nextProps?.isManageFriends) {
    isEqualItem = false;
  } else if (
    prevProps?.mutualFriendsMessagesData !==
    nextProps?.mutualFriendsMessagesData
  ) {
    isEqualItem = false;
  }

  return isEqualItem;
};

export default React.memo(FriendItem, areEqual);

const componentStyles = StyleSheet.create({
  row: { flexDirection: 'row', alignItems: 'center' },
  container: {
    minHeight: 56,
    padding: 8,
    borderRadius: 8,
    flexDirection: 'row',
    backgroundColor: defaultTheme.CARD_BG_COLOR,
  },
  floatingIcon: {
    width: 18,
    height: 18,
    position: 'absolute',
    bottom: -2.5,
    right: -5,
  },
  userIdText: {
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    marginRight: 2,
  },
  userTitle: {
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    color: '#fff',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    lineHeight: 17,
  },
  counterBubbleText: {
    color: defaultTheme.MAIN_BACKGROUND_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    marginLeft: 1.5,
  },
  counterCircle: {
    width: 18,
    height: 18,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F4B540',
  },
  italicText: {
    fontSize: 12,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    marginTop: 2.5,
  },
  rightInnerContainer: { marginLeft: 'auto', justifyContent: 'center' },
  middleInnerContainer: { marginLeft: 12.5, flex: 1 },
});
