import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showListingModal: {
    showModal: false,
    isListed: false,
  },
  showTransferModal: {
    showModal: false,
  },
  showEstInviteCodeValidationModal: {
    showModal: false,
  },
  showEstFreeProfileCreationModal: {
    showModal: false,
  },
  showEstReqInviteCodeModal: {
    showModal: false,
  },
};

const ModalSlice = createSlice({
  name: 'Modals',
  initialState,
  reducers: {
    showListingModal: (state, action) => {
      state.showListingModal.showModal = true;
      state.showListingModal.isListed = action.payload;
    },
    hideListingModal: state => {
      state.showListingModal.showModal = false;
      state.showListingModal.isListed = false;
    },
    showTransferModal: state => {
      state.showTransferModal.showModal = true;
    },
    hideTransferModal: state => {
      state.showTransferModal.showModal = false;
    },
    showEstInviteCodeValidationModal: state => {
      state.showEstInviteCodeValidationModal.showModal = true;
    },
    hideEstInviteCodeValidationModal: state => {
      state.showEstInviteCodeValidationModal.showModal = false;
    },
    showEstFreeProfileCreationModal: state => {
      state.showEstFreeProfileCreationModal.showModal = true;
    },
    hideEstFreeProfileCreationModal: state => {
      state.showEstFreeProfileCreationModal.showModal = false;
    },
    showEstReqInviteCodeModal: state => {
      state.showEstReqInviteCodeModal.showModal = true;
    },
    hideEstReqInviteCodeModal: state => {
      state.showEstReqInviteCodeModal.showModal = false;
    },
  },
});

export const {
  showListingModal,
  hideListingModal,
  showTransferModal,
  hideTransferModal,
  showEstInviteCodeValidationModal,
  hideEstInviteCodeValidationModal,
  showEstReqInviteCodeModal,
  hideEstReqInviteCodeModal,
  showEstFreeProfileCreationModal,
  hideEstFreeProfileCreationModal,
} = ModalSlice.actions;

export const ModalSliceReducer = ModalSlice.reducer;
