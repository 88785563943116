import React, { useEffect, useState } from 'react';
import { useWindowDimensions, StyleSheet, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { useNavigation } from '@react-navigation/native';

import {
  OrderSvg,
  PrivacySvg,
  ProfileSvg,
  ScanQRSvg,
  SupportSvg,
  PortfolioSvg,
  LogoutWhiteSvg,
  DeactivateAccSvg,
  TermsOfServicesSvg,
  SwitchGradientSvg,
  TicketScanSvg,
} from '../assets/svg/MoreSvgs';
import { Apps } from '../enums/apps.enum';
import { storeData } from '../helpers/AppStorage';
import { ScreenName } from '../enums/screen.enum';
import HOCContainer from '../components/HOCContainer';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { SELECTED_APP, getAppConfiguration } from '../appconstants';
import MoreGradientTab from '../components/More/MoreGradientTab.tsx';
import DeleteAccountConfirmationModal from '../components/Profile/DeleteAccountConfirmationModal';
import { useAppSelector } from '../hooks';

const numColumns = 4;

function MoreScreen() {
  const navigation = useNavigation();
  const { width: screenWidth } = useWindowDimensions();
  const { setAppSelected, userInfo } = useWeb3AuthContext();
  const isGateKeeper = useAppSelector(
    state => state.AppUser.gatekeeperAt?.length > 0,
  );

  const [isEnabled, setIsEnabled] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [itemWidth, setItemWidth] = useState(
    (screenWidth - (numColumns + 1) * 4) / numColumns,
  );
  const [showAccountDeletionModal, setShowAccountDeletionModal] =
    useState(false);

  useEffect(() => {
    (async () => {
      const appConfig = await getAppConfiguration();
      setIsEnabled(appConfig?.estModule?.isEnabledOnPatron || false);
    })();
  }, []);

  useEffect(() => {
    const settingTabs = [
      {
        id: 1,
        name: 'Order',
        screen: ScreenName.ORDERS_SCREEN,
        icon: <OrderSvg />,
      },
      {
        id: 2,
        name: 'Profile',
        screen: ScreenName.PROFILE_SCREEN,
        icon: <ProfileSvg />,
      },
      {
        id: 3,
        name: 'Scan QR',
        screen: ScreenName.SCAN_QR,
        icon: <ScanQRSvg />,
      },
      {
        id: 4,
        name: 'Portfolio',
        screen: ScreenName.CATALOG_TAB,
        icon: <PortfolioSvg />,
      },
      {
        id: 5,
        name: 'Terms of Services',
        screen: ScreenName.TERMS_OF_SERVICES,
        icon: <TermsOfServicesSvg />,
      },
      {
        id: 6,
        name: 'Support',
        screen: ScreenName.SUPPORT_SCREEN,
        icon: <SupportSvg />,
      },
      {
        id: 7,
        name: 'Privacy Notice',
        screen: ScreenName.PRIVACY_NOTICE,
        icon: <PrivacySvg />,
      },
      {
        id: 8,
        name: 'Delete Account',
        key: 'DeactivateAccount',
        icon: <DeactivateAccSvg />,
      },
      {
        id: 9,
        name: 'LOOT8 Ticket Scan',
        screen: ScreenName.WHITELISTED_EVENTS,
        icon: <TicketScanSvg />,
      },
      ...(isEnabled
        ? [
            {
              id: 10,
              name: userInfo?.isOnboarded
                ? 'Switch to EST Portal'
                : 'Become a Creator',
              key: 'SwitchToEstPortal',
              icon: <SwitchGradientSvg />,
            },
          ]
        : []),
      {
        id: 11,
        name: 'Logout',
        screen: ScreenName.LOGOUT_SCREEN,
        icon: <LogoutWhiteSvg />,
      },
    ];

    // Adding empty objects so they can be in a min multiple of 4 for styling reasons
    settingTabs.push({}, {}, {});

    const filteredTabs = isGateKeeper
      ? settingTabs
      : settingTabs.filter(item => item?.id !== 9);
    setTabs(filteredTabs);
  }, [isEnabled, userInfo, isGateKeeper]);

  const updateItemWidth = () => {
    const newWidth = (screenWidth - (numColumns + 1) * 4) / numColumns;
    setItemWidth(newWidth);
  };

  const onDeleteAccount = async () => {
    setShowAccountDeletionModal(false);
  };

  const handleTabPress = async item => {
    if (item.screen) {
      navigation.navigate(item.screen);
    } else if (!item.screen && item.key && item.key === 'DeactivateAccount') {
      setShowAccountDeletionModal(true);
    } else if (!item.screen && item.key && item.key === 'SwitchToEstPortal') {
      setAppSelected(Apps.EST_PORTAL);
      // Set selectedApp value in local storage
      await storeData(SELECTED_APP, Apps.EST_PORTAL);
    }
  };

  useEffect(() => {
    if (screenWidth !== itemWidth) {
      updateItemWidth();
    }
  }, [screenWidth]);

  return (
    <>
      {showAccountDeletionModal && (
        <DeleteAccountConfirmationModal
          showModal={showAccountDeletionModal}
          onDismiss={() => setShowAccountDeletionModal(false)}
          onDeleteAccount={onDeleteAccount}
        />
      )}
      <FlatList
        style={styles.flatListStyle}
        data={tabs}
        keyExtractor={item => item?.id?.toString()}
        renderItem={({ item }) =>
          item.id ? (
            <MoreGradientTab
              item={item}
              gradient={item.name !== 'Logout'}
              bgColor="#F72045"
              onPress={() => handleTabPress(item)}
              disabled={item.id === 1}
            />
          ) : (
            <View style={{ flex: 0.225 }} />
          )
        }
        contentContainerStyle={styles.tabsContainer}
        columnWrapperStyle={{ justifyContent: 'space-between' }}
        ItemSeparatorComponent={() => <View style={{ marginVertical: 5 }} />}
        scrollEnabled={false}
        numColumns={numColumns}
      />
    </>
  );
}

export default HOCContainer({
  OriginalComponent: MoreScreen,
  title: 'Settings',
});

const styles = StyleSheet.create({
  flatListStyle: { flex: 1 },
  headingContainer: { alignItems: 'center' },
  tabsContainer: { flexGrow: 1 },
});
