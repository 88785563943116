import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  scrollContainer: {
    flex: 1,
  },
  scrollContentContainer: {
    flexGrow: 1,
  },
  logoImg: {
    height: 33,
    width: 100,
    alignSelf: 'center',
    marginTop: 15,
  },
  contentContainer: {
    paddingHorizontal: defaultTheme.HORIZONTAL_PADDING,
    paddingTop: 35,
    maxWidth: defaultTheme.CONTENT_CONTAINER_MAX_WIDTH,
  },
  bottomTextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: defaultTheme.HORIZONTAL_PADDING,
    marginBottom: 15,
  },
  bottomText: {
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    marginTop: 2.5,
    lineHeight: 12.1,
  },
  bottomBoldText: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textDecorationLine: 'underline',
  },
  legacyText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  warningText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    lineHeight: 16,
    textAlign: 'left',
    flex: 1,
  },
  socialText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    lineHeight: 20,
    textAlign: 'center',
  },
  socialTextBold: {
    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
  },
  desktopContainer: {
    paddingTop: 35,
    width: '100%',
    maxHeight: '100%',
    maxWidth: defaultTheme.CONTENT_CONTAINER_MAX_WIDTH,
    alignSelf: 'center',
  },
  spacingVertical: {
    marginVertical: 5,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  line: {
    flex: 1,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    height: 1,
  },
  lineText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    marginHorizontal: 5,
    lineHeight: 12.1,
  },
  flatlistStyle: { marginVertical: 20, flexGrow: 0 },
  warningImage: { height: 16, width: 16, marginTop: 2.5, marginRight: 10 },
});
