import { Wallet } from 'ethers';
import { Platform } from 'react-native';

import {
  getIpfsCID,
  getUploadIPFSData,
  uploadImageToIPFSData,
} from '../helpers/ipfs';
import { SMALL_IMAGE_SIZE_LIMIT } from '../../appconstants';

export const jsonIpfsUpload = async (
  dataObj: any,
  wallet: Wallet,
  fileName: string = 'collectibles.txt',
) => {
  const val = new Uint8Array(Buffer.from(JSON.stringify(dataObj))).buffer;
  const ipfsURI = await getUploadIPFSData(val, wallet, fileName);

  return ipfsURI;
};

export const fileIpfsUpload = async (
  uri: string,
  size: number,
  data: string,
  wallet: Wallet,
  fileName: string = 'collectibles.txt',
) => {
  let IPFSURI = '';

  if (uri) {
    try {
      let arrayBuffer: ArrayBuffer;

      if (Platform.OS === 'web') {
        // Web-specific code to fetch the file and convert to ArrayBuffer
        const response = await fetch(uri);
        const blob = await response.blob();
        arrayBuffer = await blob.arrayBuffer();
      } else {
        // Mobile-specific code to handle base64 image URI
        const base64String = uri.split(',')[1]; // Remove the data:image/jpeg;base64, prefix
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        arrayBuffer = new Uint8Array(byteNumbers).buffer;
      }

      IPFSURI = size > SMALL_IMAGE_SIZE_LIMIT
        ? await uploadImageToIPFSData(arrayBuffer, wallet, fileName)
        : await getUploadIPFSData(arrayBuffer, wallet, fileName);

    } catch (err) {
      console.error('Profile creation IPFS upload error:', err);
    }
  } else {
    IPFSURI = getIpfsCID(uri);
  }

  return IPFSURI;
};