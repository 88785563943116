import React from 'react';
import { Pressable, View, Text, Platform } from 'react-native';
import styles from '../../styles';
import CachedImage from '../CachedImage';
import { IconButton } from 'react-native-paper';

const MarkerImageItem = ({
  caption,
  totalPin,
  totalItemCount,
  itemList,
  multipinWebHeight = 0,
}) => {
  return (
    <>
      {totalItemCount > 0 && (
        <View
          style={
            totalPin > 1
              ? [
                  styles.mapMultipinImageMainContainer,
                  Platform.OS == 'web' ? { height: multipinWebHeight } : {},
                ]
              : { flex: 1 }
          }>
          <Text style={styles.mapImageCardCaption}>
            {caption}
            {totalItemCount > 1
              ? `s(${totalItemCount})`
              : totalPin > 1
                ? `(${totalItemCount})`
                : ''}{' '}
          </Text>
          <View
            style={
              totalPin > 1
                ? styles.mapImageMultiCardCanvasContainer
                : styles.mapImageCardCanvasContainer
            }>
            {totalItemCount > 0 &&
              itemList
                ?.filter((x, i) => i == 0)
                .map((item, index) => {
                  return (
                    <View
                      key={item.address}
                      style={
                        totalPin > 1
                          ? styles.mapImageMultipleCardCanvas
                          : styles.mapImageCardCanvas
                      }>
                      <Pressable style={styles.mapPinImageInnerContainer}>
                        <CachedImage
                          isBackground
                          source={{
                            uri: item.isMp4Collectible
                              ? item.thumbnail
                              : item.image,
                          }}
                          style={styles.mapPinCachedImageContainer}
                          contentFit="contain"
                          imageSize={item?.imageSize}>
                          {item.isMp4Collectible && (
                            <View style={styles.mapVideoButtonContainer}>
                              <IconButton
                                icon="play"
                                iconColor={'#000'}
                                size={20}
                                style={styles.mapVideoButton}
                              />
                            </View>
                          )}
                        </CachedImage>
                      </Pressable>
                    </View>
                  );
                })}
          </View>
        </View>
      )}
    </>
  );
};

const areEqual = (
  prevProps: { caption; totalPin; totalItemCount; itemList },
  nextProps: { caption; totalPin; totalItemCount; itemList },
) => {
  let isEqualItem = true;
  if (prevProps?.caption !== nextProps?.caption) {
    isEqualItem = false;
  } else if (prevProps?.totalPin !== nextProps?.totalPin) {
    isEqualItem = false;
  } else if (prevProps?.totalItemCount !== nextProps?.totalItemCount) {
    isEqualItem = false;
  } else if (prevProps?.itemList?.length !== nextProps?.itemList?.length) {
    isEqualItem = false;
  }
  return isEqualItem;
};

export default React.memo(MarkerImageItem, areEqual);
