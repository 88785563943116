import { Wallet, ethers } from 'ethers';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';
import {
  EST_PORTAL_MASTER_KEY,
  IPNS_UPLOAD_LAMBDA_URL,
  EST_PORTAL_API_REQUEST_TIMEOUT,
} from '../../appconstants';

export const getUploadIPFSData = async (
  newData: any,
  userWallet: Wallet,
  filename: string,
) => {
  const data = Buffer.from(newData, 'utf-8');
  const pin: boolean = true;
  const canonicalizedData = getBufferForSigning(filename, data);
  const lambda_signature = await userWallet.signMessage(canonicalizedData);

  const masterWallet = new ethers.Wallet(EST_PORTAL_MASTER_KEY);
  const masterSignature = await masterWallet.signMessage(lambda_signature);

  //post new URI to IPFS
  let ipfsImageUri = '';
  const postImageURL =
    IPNS_UPLOAD_LAMBDA_URL +
    'user/' +
    userWallet.address +
    '/secure-upload/raw/' +
    filename +
    '/' +
    lambda_signature +
    '/' +
    masterSignature +
    '?ipns=false&pin=' +
    pin;

  const response = await fetchWithTimeout(postImageURL, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    body: newData,
  });

  if (response.status === 200) {
    const cid = JSON.parse(await response.text()).fileCID;
    LogToLoot8Console('cid-for-upload', cid);
    ipfsImageUri = 'ipfs://' + cid;
    return ipfsImageUri;
  }
  return '';
};
export const fetchWithTimeout = async (
  resources,
  options,
  timeout = 1 * 60 * 1000,
) => {
  // pull request timout from config
  const apiTimeout = EST_PORTAL_API_REQUEST_TIMEOUT();
  if (apiTimeout) {
    timeout = Number(apiTimeout);
  }

  // initilize abort controller
  const controller = new AbortController();

  // append signal parameter with request
  const params = { ...options, signal: controller.signal };

  // set timeout
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  // call api to pull the data
  const response = await fetch(resources, params);

  // clear timeout
  clearTimeout(timeoutId);

  // return api response
  return response;
};

export type Signeable = Buffer | string;
const separator: Buffer = Buffer.from([0x01]);

export function getBufferForSigning(...args: Signeable[]): Buffer {
  const chunks: Buffer[] = [];

  for (let i = 0; i < args.length; i++) {
    if (i > 0) chunks.push(separator);

    if (args[i] instanceof Buffer) {
      chunks.push(args[i] as Buffer);
    } else {
      chunks.push(Buffer.from(args[i].toString(), 'utf-8'));
    }
  }
  return Buffer.concat(chunks);
}

export const retryFetch = (
  fn,
  retriesLeft = 3,
  interval = 1000,
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retryFetch(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const getIpfsCID = (path: string): string => {
  if (path && path !== '') {
    return 'ipfs://' + path.split('ipfs/')[1];
  }
  return '';
};

export const uploadImageToIPFSData = async (
  newData: any,
  userWallet: Wallet,
  filename: string,
) => {
  const data = Buffer.from(newData, 'utf-8');
  const masterWallet = new ethers.Wallet(EST_PORTAL_MASTER_KEY);
  const pin: boolean = true;
  let userAddress = userWallet.address;

  let signature = await userWallet.signMessage(
    getBufferForSigning(filename, '/upload/begin'),
  );

  let masterSignature = await masterWallet.signMessage(signature);

  //post new URI to IPFS
  let ipfsImageUri = '';
  const postImageURL =
    IPNS_UPLOAD_LAMBDA_URL +
    `user/${userAddress}/upload/begin/${filename}/${signature}/${masterSignature}`;

  let response = await fetchWithTimeout(postImageURL, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  let responseJson = JSON.parse(await response.text());

  // upload file
  response = await fetch(responseJson.url, {
    method: 'PUT',
    body: data,
  });

  const filehash = ethers.utils.keccak256(data).slice(2);
  signature = await userWallet.signMessage(
    getBufferForSigning(filename, filehash, '/upload/complete'),
  );
  masterSignature = await masterWallet.signMessage(signature);

  const completionUrl =
    IPNS_UPLOAD_LAMBDA_URL +
    `user/${userAddress}/upload/complete/${filename}/${filehash}/${signature}/${masterSignature}?ipns=false&pin=${pin}`;

  response = await fetch(completionUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  let result = await response.json();

  if (response.status === 200) {
    const cid = result.fileCID;
    LogToLoot8Console('cid-for-upload', cid);
    ipfsImageUri = 'ipfs://' + cid;
    return ipfsImageUri;
  }
  return '';
};
