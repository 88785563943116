import React, { memo, useRef, useState } from 'react';
import { View, Text, StyleSheet, SafeAreaView, FlatList } from 'react-native';
import { ethers } from 'ethers';
import { useDispatch } from 'react-redux';

import {
  AddFreeCollection,
  CreateCollection,
  AddSubscriptionPassport,
  SetMarketPlaceTradability,
} from '../slices/PassportSlice';
import {
  hideEstFreeProfileCreationModal,
  showEstFreeProfileCreationModal,
} from '../../slices/ModalsSlice';
import { AppDispatch } from '../../store';
import { getIpfsCID } from '../helpers/ipfs';
import { useAppSelector } from '../../hooks';
import { Apps } from '../../enums/apps.enum';
import { OfferType } from '../enums/offers.enum';
import { defaultTheme } from '../../themes/loot8';
import ModalComponent from '../../components/Modal';
import ProfileCreationConfirmation, {
  SocialLinks,
} from '../components/ProfileCreationConfirmation';
import { storeData } from '../../helpers/AppStorage';
import { MenuItem } from '../../components/AnimatedMenu';
import HOCContainer from '../../components/HOCContainer';
import useIsResponsive from '../../hooks/useIsResponsive';
import { CollectionType } from '../../enums/collection.enum';
import GradientButton from '../../components/GradientButton';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import ImagePickerComponent from '../../components/ImagePickerComponent';
import ProfileCreationSocials from '../components/ProfileCreationSocials';
import ProfileCreationDetails from '../components/ProfileCreationDetails';
import { loadAllDetails, loadEntityDetails } from '../slices/EntitySlice';
import { SELECTED_APP, ZERO_ADDRESS, getNetwork } from '../../appconstants';
import { fileIpfsUpload, jsonIpfsUpload } from '../services/ipfsUpload.service';
import BackButton from '../components/BackButton';
import { LogToLoot8Console } from '../../helpers/Loot8ConsoleLogger';

export interface ImageState {
  image: null | string;
  size: number;
}

const EstPortalCreateProfile = ({ navigation }) => {
  const { setAppSelected } = useWeb3AuthContext();
  const [exPassName, setExPassName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [description, setDescription] = useState('');
  const [thumbnailImage, setThumbnailImage] = useState<ImageState>({
    image: null,
    size: 0,
  });
  const [optimisedImage, setOptimisedImage] = useState<ImageState>({
    image: null,
    size: 0,
  });
  const [originalImage, setOriginalImage] = useState<ImageState>({
    image: null,
    size: 0,
  });
  const [showImageSelectionModal, setShowImageSelectionModal] = useState({
    state: false,
    type: '',
  });
  const [socialLinks, setSocialLinks] = useState<SocialLinks>({
    facebook: '',
    twitter: '',
    instagram: '',
    tiktok: '',
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [responseModal, setResponseModal] = useState({
    show: false,
    title: '',
    message: '',
  });
  const tabsRef = useRef(null);
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();
  const dispatch = useDispatch<AppDispatch>();
  const entityAddress = useAppSelector(
    state => state.EstPortalEntity.EntityAddress,
  );
  const { wallet, connected, networkId, staticProvider, address, userInfo } =
    useWeb3AuthContext();

  const handleContinue = async () => {
    if (activeTabIndex === 2) {
      const ipfsDataURI = await ipfsDataGeneration();
      if (ipfsDataURI && ipfsDataURI != '') {
        await onFormCreation(ipfsDataURI);
      }
    } else {
      goToNextSlide(activeTabIndex + 1);
    }
  };

  const ipfsDataGeneration = async () => {
    dispatch(showEstFreeProfileCreationModal());

    let collectionImageThumbnail = {
        uri: getIpfsCID(''),
        imageSize: thumbnailImage.size ?? 0,
      },
      collectionImageOptimized = {
        uri: getIpfsCID(''),
        imageSize: optimisedImage.size ?? 0,
      },
      collectionOriginalImage = {
        uri: getIpfsCID(''),
        imageSize: originalImage.size ?? 0,
      };

    collectionImageThumbnail.uri = await fileIpfsUpload(
      thumbnailImage.image,
      thumbnailImage.size,
      '',
      wallet,
    );
    collectionImageOptimized.uri = await fileIpfsUpload(
      optimisedImage.image,
      optimisedImage.size,
      '',
      wallet,
    );
    collectionOriginalImage.uri = await fileIpfsUpload(
      originalImage.image,
      originalImage.size,
      '',
      wallet,
    );

    let collectionObj = {
      name: exPassName.trim(),
      description: description.trim(),
      image: collectionOriginalImage?.uri,
      imageSize: collectionOriginalImage?.imageSize,
      thumbnailImage: collectionImageThumbnail?.uri,
      thumbnailImageSize: collectionImageThumbnail?.imageSize,
      optimizedImage: collectionImageOptimized?.uri,
      optimizedImageSize: collectionImageOptimized?.imageSize,
    };

    // push social link keys to collectionObj
    if (socialLinks && Object.keys(socialLinks).length > 0) {
      Object.keys(socialLinks).forEach(key => {
        if (socialLinks[key] !== '') {
          collectionObj[key as keyof SocialLinks] = socialLinks[key];
        }
      });
    }

    const ipfsDataURI = await jsonIpfsUpload(collectionObj, wallet);

    return ipfsDataURI;
  };

  const onFormCreation = async ipfsDataURI => {
    let payloadObj = {
      name: exPassName.trim(),
      category: selectedCategory.value,
      chain: getNetwork(),
      dataURI: '',
      details: description.trim(),
      address: '',
      symbol: '',
      isActive: true,
      entityAddress: '',
      price: '0.00',
      totalSupply: 0,
      linkCollectible: [],
      socialMedia: true,
      offerType: OfferType.NOTANYOFFER,
      collectionType: CollectionType.PASSPORT,
      passportType: 1,
      subscriptionSet: false,
      marketPlaceConfig: {
        allowMarketplaceOps: true,
        privateTradeAllowed: true,
        publicTradeAllowed: true,
      },
      area: {
        latitude: '',
        longitude: '',
        radius: 0,
      },
      subTitle: '',
      whitelistedCollections: [],
      tokensEarned: 0,
      maxMint: 0,
      maxPurchase: 0,
      maxBalance: 0,
      mintWithLinked: true,
      mintWithLinkedOnly: false,
      isVideoCollectible: false,
      video: '',
      isCoupon: false,
      priceRate: 0,
      start: null,
      end: null,
      socialLinks: {
        tiktok: socialLinks?.tiktok ?? '',
        instagram: socialLinks?.instagram ?? '',
        twitter: socialLinks?.twitter ?? '',
        facebook: socialLinks?.facebook ?? '',
      },
      optimised: {
        image: optimisedImage?.image ?? '',
        size: optimisedImage?.size ?? '',
      },
      thumbnail: {
        image: thumbnailImage?.image ?? '',
        size: thumbnailImage?.size ?? '',
      },
      original: {
        image: originalImage?.image ?? '',
        size: originalImage?.size ?? '',
      },
    };
    const data = await dispatch(
      CreateCollection({
        networkID: networkId,
        provider: staticProvider,
        address: address,
        EntityAddress: entityAddress,
        collectibleData: payloadObj,
        dataURI: ipfsDataURI,
        wallet,
        chainID: payloadObj?.chain,
      }),
    );

    if (data.payload && data?.payload !== ZERO_ADDRESS) {
      let collectionAddress = data?.payload;
      await dispatch(
        AddFreeCollection({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          EntityAddress: entityAddress,
          collectibleAddress: collectionAddress,
          category: selectedCategory.value,
          isPremium: false,
          collectibleData: payloadObj,
          wallet,
          chainID: payloadObj?.chain,
        }),
      );
      // Adding subscrition
      const floorPrice = ethers.utils
        .parseUnits(payloadObj?.price?.toString(), 18)
        .toString();
      await dispatch(
        AddSubscriptionPassport({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          collectibleAddress: collectionAddress,
          wallet,
          floorPrice: floorPrice,
          priceRate: Number(payloadObj?.priceRate),
        }),
      );
      // Setting MarketPlace tradability
      await dispatch(
        SetMarketPlaceTradability({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          collectibleAddress: collectionAddress,
          wallet,
          marketPlaceConfig: payloadObj?.marketPlaceConfig,
        }),
      );
      dispatch(hideEstFreeProfileCreationModal());
      navigation.navigate('EstPortalSuccessScreen', {
        message: 'You have successfully created your free Profile/ExPass',
        buttonLabel: 'Open Dashboard',
        onPressActonButton: () => {
          loadEstPortalDetails();
        },
      });
      // history.replace({ pathname: '/dashboard' });
      // dispatch(setPassportLoading(true));
      // const result = await checkSuperAdmin({
      //   networkID: networkId,
      //   provider: staticProvider,
      //   walletAddress: wallet?.address,
      //   entityAddress: entityAddress,
      // });
      // dispatch(setSuperAdmin(result));
      return collectionAddress;
    } else {
      dispatch(hideEstFreeProfileCreationModal());

      LogToLoot8Console('free profile creation Error');
    }
  };

  // load est portal passports
  const loadEstPortalDetails = async () => {
    if (connected && wallet) {
      await dispatch(
        loadEntityDetails({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
        }),
      );
      await dispatch(
        loadAllDetails({
          networkID: networkId,
          provider: staticProvider,
          address,
          wallet,
          isCache: false,
          isSuperAdmin: true,
        }),
      );
    }
  };

  const handleOpenImageModal = (key: string) => {
    setShowImageSelectionModal({
      state: true,
      type: key,
    });
  };

  const handleExpassImage = (uri: string, size: string) => {
    if (showImageSelectionModal.type === 'thumbnail') {
      setThumbnailImage({
        image: uri,
        size: parseInt(size),
      });
    } else if (showImageSelectionModal.type === 'optimised') {
      setOptimisedImage({
        image: uri,
        size: parseInt(size),
      });
    } else if (showImageSelectionModal.type === 'original') {
      setOriginalImage({
        image: uri,
        size: parseInt(size),
      });
    }
    setShowImageSelectionModal({
      state: false,
      type: '',
    });
  };

  const handleRemoveImage = (key: string) => {
    if (key === 'thumbnail') {
      setThumbnailImage({
        image: null,
        size: 0,
      });
    } else if (key === 'optimised') {
      setOptimisedImage({
        image: null,
        size: 0,
      });
    } else if (key === 'original') {
      setOriginalImage({
        image: null,
        size: 0,
      });
    }
  };

  // This is the condition to enable the button
  const isButtonEnabled =
    !exPassName?.length ||
    !selectedCategory ||
    !thumbnailImage.image ||
    !optimisedImage.image ||
    !originalImage.image;

  const goToNextSlide = (index: number) => {
    setActiveTabIndex(index);
    const offset = isResponsive
      ? index * defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
        defaultTheme.HORIZONTAL_PADDING * 2
      : index * (activeWidth - defaultTheme.HORIZONTAL_PADDING * 2);
    tabsRef?.current.scrollToOffset({ offset });
  };
  const goToPrevSlide = () => {
    const index = activeTabIndex - 1;
    setActiveTabIndex(index);
    const offset = isResponsive
      ? index * defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
        defaultTheme.HORIZONTAL_PADDING * 2
      : index * (activeWidth - defaultTheme.HORIZONTAL_PADDING * 2);
    tabsRef?.current.scrollToOffset({ offset });
  };

  const handleSocialsChange = (platform: string, value: string) => {
    setSocialLinks(prevState => {
      const newState = { ...prevState };
      if (value.length === 0) {
        delete newState[platform];
      } else {
        newState[platform] = value;
      }
      return newState;
    });
  };

  const handleSwitchToPatronApp = async () => {
    setAppSelected(Apps.PATRON_APP);
    // Set selectedApp value in local storage
    await storeData(SELECTED_APP, Apps.PATRON_APP);
  };
  const backBtnOnPress = () => {
    goToPrevSlide();
  };
  return (
    <SafeAreaView style={styles.container}>
      <BackButton
        hideBackIcon={activeTabIndex === 0 ? true : false}
        onPress={backBtnOnPress}
      />

      <FlatList
        ref={tabsRef}
        data={[1, 2, 3]}
        horizontal
        snapToAlignment="start"
        decelerationRate={'fast'}
        snapToInterval={activeWidth}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ flex: 1, marginTop: 10 }}
        renderItem={({ item, index }) => (
          <Slide
            index={index}
            exPassName={exPassName}
            setExPassName={setExPassName}
            description={description}
            setDescription={setDescription}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            handleOpenImageModal={handleOpenImageModal}
            handleSocialsChange={handleSocialsChange}
            socialLinks={socialLinks}
            goToNextSlide={goToNextSlide}
            thumbnailImage={thumbnailImage}
            optimisedImage={optimisedImage}
            originalImage={originalImage}
            handleRemoveImage={handleRemoveImage}
          />
        )}
        scrollEnabled={false}
      />

      <GradientButton
        onPress={handleContinue}
        buttonLabel={'Continue'}
        disabled={isButtonEnabled}
        customStyle={{ marginVertical: 15 }}
      />
      <GradientButton
        bordered
        onPress={handleSwitchToPatronApp}
        buttonLabel={
          userInfo?.isOnboarded ? 'Switch to Patron App' : 'Join as Fan'
        }
      />
      <ModalComponent
        needCloseButton
        enableHeader={false}
        showModal={responseModal?.show}
        onDismiss={() =>
          setResponseModal({ show: false, title: '', message: '' })
        }
        modalBodyStyle={styles.modalBody}>
        <Text style={styles.modalTitle}>{responseModal.title}</Text>
        <View style={styles.separator} />
        <Text
          style={{
            ...styles.modalTitle,
            fontSize: defaultTheme.FONT_SIZE_XXSMALL,
          }}>
          {responseModal.message}
        </Text>
      </ModalComponent>

      {/* Image selection options modal */}
      <ModalComponent
        needCloseButton
        showModal={showImageSelectionModal.state}
        enableHeader={false}
        modalBodyStyle={{ alignSelf: 'center' }}
        modalStyle={styles.modalStyles}
        onDismiss={() =>
          setShowImageSelectionModal({ state: false, type: '' })
        }>
        <View style={styles.imageSelectionModal}>
          <ImagePickerComponent
            showAvatar={false}
            onAvatarButtonClick={null}
            setSelectImageAction={null}
            setSelectedImage={handleExpassImage}
          />
        </View>
      </ModalComponent>
    </SafeAreaView>
  );
};

export default HOCContainer({
  OriginalComponent: EstPortalCreateProfile,
  withBottomTabs: false,
  isScrollEnabled: false,
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  modalBody: {
    width: '100%',
    paddingVertical: 25,
    paddingHorizontal: 15,
  },
  modalTitle: {
    letterSpacing: 1,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
  },
  separator: {
    height: 1,
    marginVertical: 10,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
  },
  imagePickerHeaderText: {
    textAlign: 'left',
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  modalStyles: {
    bottom: 0,
    justifyContent: 'flex-end',
  },
  imageSelectionModal: {
    padding: 10,
  },
});

const Slide = memo(
  ({
    index,
    exPassName,
    setExPassName,
    selectedCategory,
    setSelectedCategory,
    handleOpenImageModal,
    handleSocialsChange,
    socialLinks,
    goToNextSlide,
    thumbnailImage,
    optimisedImage,
    originalImage,
    handleRemoveImage,
    description,
    setDescription,
  }: {
    index: number;
    goToNextSlide: (index: number) => void;
    exPassName: string;
    setExPassName: (value: string) => void;
    description: string;
    setDescription: (value: string) => void;
    selectedCategory: null | MenuItem;
    setSelectedCategory: (item: MenuItem) => void;
    handleOpenImageModal: (key: string) => void;
    handleRemoveImage: (key: string) => void;
    handleSocialsChange: (platform: string, value: string) => void;
    socialLinks: any;
    thumbnailImage: ImageState;
    optimisedImage: ImageState;
    originalImage: ImageState;
  }) => {
    const activeWidth = useActiveDimensions().width;
    const isResponsive = useIsResponsive();

    const renderContent = () => {
      if (index === 0) {
        return (
          <ProfileCreationDetails
            exPassName={exPassName}
            setExPassName={setExPassName}
            message={description}
            setMessage={setDescription}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            handleOpenImageModal={handleOpenImageModal}
            handleRemoveImage={handleRemoveImage}
            thumbnailImage={thumbnailImage}
            optimisedImage={optimisedImage}
            originalImage={originalImage}
          />
        );
      } else if (index === 1) {
        return (
          <ProfileCreationSocials
            socialLinks={socialLinks}
            handleSocialsChange={handleSocialsChange}
          />
        );
      } else if (index === 2) {
        return (
          <ProfileCreationConfirmation
            exPassName={exPassName}
            description={description}
            selectedCategory={selectedCategory}
            socialLinks={socialLinks}
          />
        );
      }
      return (
        <ProfileCreationDetails
          exPassName={exPassName}
          setExPassName={setExPassName}
          message={description}
          setMessage={setDescription}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          handleOpenImageModal={handleOpenImageModal}
          handleRemoveImage={handleRemoveImage}
          thumbnailImage={thumbnailImage}
          optimisedImage={optimisedImage}
          originalImage={originalImage}
        />
      );
    };

    return (
      <View
        style={
          isResponsive
            ? {
                width:
                  defaultTheme.CONTENT_CONTAINER_MAX_WIDTH -
                  defaultTheme.HORIZONTAL_PADDING * 2,
              }
            : {
                width: activeWidth - defaultTheme.HORIZONTAL_PADDING * 2,
              }
        }>
        {renderContent()}
      </View>
    );
  },
);
