import React, { useEffect, useState } from 'react';
import { View, FlatList } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import { innerStyles } from './styles';
import styles from '../../../../styles';
import EmptyList from '../../../EmptyList';
import {
  readMessages,
  resetPassportMessage,
  getSocialMediaAccess,
  subscribeToPassportMessages,
} from '../../../../slices/PassportMessageSlice';
import AppLoaderComponent from '../../../Loader';
import SectionHeading from '../../../SectionHeading';
import ExPassAssetsCard from '../../../ExPassAssetsCard';
import { ScreenName } from '../../../../enums/screen.enum';
import { CatalogTypes } from '../../../../enums/catalog.enum';
import SeeAllTextPressable from '../../../SeeAllTextPressable';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useWeb3AuthContext } from '../../../../hooks/web3authContext';

function arrayUnion(arr1, arr2) {
  let union = [...arr1];

  arr2.forEach(item1 => {
    if (
      arr1.findIndex(
        item2 =>
          item1?.address.toLowerCase() === item2?.address.toLowerCase() &&
          Number(item1.collectibleId) === Number(item2.collectibleId),
      ) === -1
    ) {
      union.push(item1);
    }
  });

  return union;
}

const Collectibles = ({
  selectPassport,
  showLimitedCollectibles = false,
  overrideCollectibles = undefined,
  isLoading = false,
}) => {
  const navigation: any = useNavigation();

  const { networkId, staticProvider, wallet } = useWeb3AuthContext();
  const dispatch = useAppDispatch();
  const allOffers = useAppSelector(state => state.Offers);
  const [collectibleBalance, setCollectibleBalance] = useState(0);
  const loadingOffer = overrideCollectibles
    ? isLoading
    : useAppSelector(state => state.Offers.loading);

  const [availableCatalogs, setAvailableCatalogs] = React.useState([]);
  const [isSocialAvailable, setSocialAvailable] = useState(true);

  const loadingThirdPartyCollectible = overrideCollectibles
    ? isLoading
    : useAppSelector(state => state.Offers.loadingThirdpartyCollecible);

  useEffect(() => {
    return () => {
      // Clear data
      setAvailableCatalogs(undefined);
    };
  }, []);

  useEffect(() => {
    if (
      (!loadingOffer || !loadingThirdPartyCollectible) &&
      !overrideCollectibles
    ) {
      let catalogList = null;
      if (allOffers?.PassportCollectiables?.length > 0) {
        catalogList = catalogList
          ? arrayUnion(catalogList, allOffers.PassportCollectiables)
          : [...allOffers.PassportCollectiables];
        catalogList = catalogList.sort((a, b) => b.timestamp - a.timestamp);
      }
      if (allOffers?.ThirdPartyCollectiables?.length > 0) {
        catalogList = catalogList
          ? arrayUnion(catalogList, allOffers.ThirdPartyCollectiables)
          : [...allOffers.ThirdPartyCollectiables];
        catalogList = catalogList.sort((a, b) => b.timestamp - a.timestamp);
      }
      if (allOffers?.allMintedCoupon?.length > 0) {
        catalogList = catalogList
          ? arrayUnion(catalogList, allOffers.allMintedCoupon)
          : [...allOffers.allMintedCoupon];
        catalogList = catalogList.sort((a, b) => b.timestamp - a.timestamp);
      }
      if (catalogList && catalogList.length > 0) {
        setCollectibleBalance(catalogList.length);
      } else {
        setCollectibleBalance(0);
      }
      setAvailableCatalogs(
        catalogList?.sort((a, b) =>
          a.interactiveCollectible === b.interactiveCollectible
            ? 0
            : a.interactiveCollectible
            ? -1
            : 1,
        ),
      );
    } else if (overrideCollectibles) {
      setAvailableCatalogs(overrideCollectibles);
    }
  }, [loadingOffer, loadingThirdPartyCollectible, isLoading]);

  const updateData = async data => {
    //reset messages, so it does not show old message for a sec when a new messages are getting loaded
    await dispatch(resetPassportMessage());
    if (data?.fromCollectibleDetails && selectPassport) {
      await getSocialMediaAccess(
        selectPassport.address,
        selectPassport.chainId,
      ).then(isSocialAvailable => {
        setSocialAvailable(isSocialAvailable);
        if (isSocialAvailable) {
          dispatch(
            readMessages({
              address: selectPassport.address,
              chainId: networkId,
              networkID: networkId,
              provider: staticProvider,
              wallet,
            }),
          ).then(() => {
            dispatch(
              subscribeToPassportMessages({
                address: selectPassport.address,
                chainId: networkId,
                networkID: networkId,
                provider: staticProvider,
                wallet,
              }),
            );
          });
        }
      });
    }
  };

  const navigateToCollectibleDetails = (item, type) => {
    // @ts-ignore
    navigation.navigate('CatalogDetails', {
      catalog: item,
      availableCatalogs: [],
      type: type,
      from: 'PassportPage',
      updateParent: updateData,
      passport: selectPassport,
    });
  };

  const handleSeeAll = () => {
    collectibleBalance > 0 &&
      navigation.navigate(ScreenName.HOME_TAB, {
        screen: ScreenName.CATALOG_LIST,
        params: {
          type: 'Collectibles',
          passport: selectPassport,
          passportCollectible: availableCatalogs,
          from: 'PassportPage',
          initial: false,
          updateParent: updateData,
          catalogType: CatalogTypes.COLLECTIBLES,
        },
      });
  };

  return (
    <>
      <SectionHeading
        title={`Associated Collectibles (${availableCatalogs?.length || 0})`}
        renderRight={
          availableCatalogs?.length && !overrideCollectibles ? (
            <SeeAllTextPressable onPress={handleSeeAll} />
          ) : null
        }
      />
      <View style={styles.passportBtnContainer}>
        <View style={styles.passportContainer}>
          {(!loadingOffer && !loadingThirdPartyCollectible) ||
          (loadingOffer &&
            loadingThirdPartyCollectible &&
            availableCatalogs &&
            availableCatalogs.length > 0) ? (
            <FlatList
              numColumns={2}
              data={
                showLimitedCollectibles
                  ? availableCatalogs?.slice(0, 4)
                  : availableCatalogs
              }
              scrollEnabled={false}
              style={innerStyles.flatList}
              contentContainerStyle={innerStyles.contentContainer}
              renderItem={({ item }) => (
                <ExPassAssetsCard
                  item={item}
                  onPress={() =>
                    navigateToCollectibleDetails(
                      item,
                      CatalogTypes.COLLECTIBLES,
                    )
                  }
                />
              )}
              keyExtractor={item => item?.address}
              columnWrapperStyle={innerStyles.columnWrapper}
              ItemSeparatorComponent={() => (
                <View style={innerStyles.separator} />
              )}
              ListEmptyComponent={
                <View style={{ marginVertical: 30 }}>
                  <EmptyList message="No associated collectibles available." />
                </View>
              }
            />
          ) : (
            <View style={styles.passportCollectibleItemsLoaderContainer}>
              <AppLoaderComponent
                deemBg={false}
                extraStyle={{ borderRadius: 6 }}
                loaderText={{
                  text: 'Loading Collectibles',
                }}
              />
            </View>
          )}
        </View>
      </View>
    </>
  );
};

export default Collectibles;
