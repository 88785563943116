import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../store';
import { getAppConfiguration, addresses } from '../../appconstants';
import { ExternalCollectionManager__factory } from '../../typechain';
import { fetchWhitelistedCollections } from '../../helpers/GraphQLHelper';
import { IExternalCollectionManager } from '../../typechain/CollectionManager';

export const getAllWhitelistedCollectibleList = async ({
  networkID,
  provider,
  passportAddress,
  fetchLatest = false,
}: {
  networkID;
  provider;
  passportAddress;
  fetchLatest?: boolean;
}): Promise<IExternalCollectionManager.ContractDetailsStructOutput[]> => {
  let whitelistedCollections = null;
  try {
    let appConfig;
    try {
      appConfig = await getAppConfiguration();
    } catch (err) {
      // LogToConsoleError("getWhitelistedCollectionsForPassport: Error while reading app config");
    }

    if (
      appConfig &&
      appConfig.indexerService &&
      appConfig.indexerService.whitelistedCollections &&
      !fetchLatest
    ) {
      whitelistedCollections = await fetchWhitelistedCollections(
        passportAddress,
      );
    }
  } catch (err) {
    // LogToConsoleError("getWhitelistedCollectionsForPassport", err.name, err.message, err.stack);
  }
  //if indexer is disable or any error occurred while fetching data from indexer then fetch data from contract
  if (whitelistedCollections === null) {
    const ExternalCollectionManager =
      ExternalCollectionManager__factory.connect(
        addresses[networkID].ExternalCollectionManager,
        provider,
      );
    const whitelisted =
      await ExternalCollectionManager.getWhitelistedCollectionsForPassport(
        passportAddress,
      );
    whitelistedCollections = whitelisted.flatMap(({ source, chainId }) => ({
      source,
      chainId: Number(chainId),
      passport: passportAddress,
    }));
  }
  return whitelistedCollections;
};

export interface ICollectibleSliceData { }

const initialState: ICollectibleSliceData = {};

const CollectibleSlice = createSlice({
  name: 'CollectibleDetails',
  initialState,
  reducers: {},
  extraReducers: builder => { },
});

export const EstPortalCollectibleSliceReducer = CollectibleSlice.reducer;

const baseInfo = (state: RootState) => state.EstPortalCollectibles;

export const { } = CollectibleSlice.actions;

export const getCollectibleState = createSelector(
  baseInfo,
  CollectibleSlice => CollectibleSlice,
);
