import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  NetworkId,
  addresses,
  getAnynetStaticProvider,
  getAppConfiguration,
  isNativeChain,
} from '../appconstants';
import { LogCustomError } from '../helpers/AppLogger';
import { getTokenDecimals, showToastMessage } from '../helpers/Gadgets';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import {
  delistForMarketPlace,
  getMarketplaceListingsData,
  getPublicListingCollectionData,
  listForMarketPlace,
  validateForMarketPlace,
} from '../helpers/MarketPlace';
import {
  GetApprovalForBuyListing,
  GetApprovalForListMarketPlace,
  GetBuyCollectibleMarketPlace,
  GetDeListCollectibleMarketPlace,
  GetForeignChainApprovalForBuyListing,
  GetListCollectibleMarketPlace,
  decodeListingCollectibleMarketPlace,
  decodeUnlistingCollectibleMarketPlace,
} from '../helpers/MsgHelper';
import { ICatalog } from '../interfaces/ICatalog.interface';
import { IListing } from '../interfaces/IListing.interface';
import { RootState } from '../store';
import {
  Loot8Collection__factory,
  Loot8Marketplace__factory,
  Loot8Token__factory,
} from '../typechain';
import { SendMetaTX } from './AppSlice';
import { getCollectibleDetails } from './OfferSlice';
import { setAll } from './helpers';
import {
  IMarketPlaceAppprovalForListing,
  IMarketPlaceDeListing,
  IMarketPlaceListing,
  IMarketPlaceListingAsyncThunk,
  IMarketPlaceListingFilterAsyncThunk,
  IMessageMetaData,
} from './interfaces';
import { ethers } from 'ethers';
import { AppEnvironment } from '../enums/env.enum';

export const approveForListing = createAsyncThunk(
  'marketPlace/approveForListing',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      collectibleAddress,
      tokenId,
    }: IMarketPlaceListing,
    { dispatch, getState },
  ): Promise<any> => {
    const collectibleContract = Loot8Collection__factory.connect(
      collectibleAddress,
      provider,
    );

    const approval = await collectibleContract.getApproved(Number(tokenId));

    if (approval !== addresses[networkID].MarketPlace) {
      const data = GetApprovalForListMarketPlace(
        addresses[networkID].MarketPlace,
        Number(tokenId),
      );
      let msg: IMessageMetaData = {
        to: collectibleAddress,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      const res = await dispatch(SendMetaTX(msg));
    }
  },
);

export const listCollectionForMarketPlace = createAsyncThunk(
  'marketPlace/listCollection',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      collectibleAddress,
      tokenId,
      price,
      passport,
      listingType,
      chainId,
    }: IMarketPlaceListing,
    { dispatch, getState },
  ): Promise<any> => {
    let paymentToken = isNativeChain(chainId)
      ? addresses[networkID].Loot8Token
      : addresses[networkID].USDC;

    const tokenDecimal = await getTokenDecimals(
      paymentToken,
      Loot8Token__factory.abi,
      provider,
    );
    const marketPrice = ethers.utils.parseUnits(
      price ? price?.toString() : '0',
      tokenDecimal ?? 18,
    );

    const response = await validateForMarketPlace(
      wallet,
      address,
      passport,
      collectibleAddress,
      tokenId.valueOf(),
      paymentToken,
      marketPrice.toString(),
      'list',
      listingType,
    );
    let resp = await response.json();
    LogToLoot8Console(resp);

    if (
      resp &&
      resp.status == 'success' &&
      resp.result == 'success' &&
      resp.signature
    ) {
      const data = GetListCollectibleMarketPlace(
        passport,
        collectibleAddress,
        tokenId,
        paymentToken,
        marketPrice.toString(),
        resp.signature,
        resp.expiry,
        listingType,
      );
      let msg: IMessageMetaData = {
        to: addresses[networkID].MarketPlace,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      const res = await dispatch(SendMetaTX(msg));

      if (res && res.payload?.eventLogs) {
        const listingID = decodeListingCollectibleMarketPlace(
          res.payload?.eventLogs,
        );
        LogToLoot8Console('Listing Index', listingID);
        if (Number(listingID) !== 0) {
          // off-chain replica
          const response = await listForMarketPlace(
            wallet,
            address,
            listingID,
            chainId,
          );
          const resOffChain = await response?.json();
          if (resOffChain?.status.toLowerCase() == 'success') {
            return true;
          } else {
            // de-list on-chain item in case any error in off-chain listing.
            await dispatch(
              delistCollectionForMarketPlace({
                networkID: chainId,
                provider: provider,
                wallet,
                address,
                collectibleAddress: collectibleAddress,
                tokenId: tokenId,
                passport: passport,
                chainId: chainId,
                price: marketPrice.toString(),
                listingType: listingType,
                listingID: Number(listingID),
              }),
            );

            return false;
          }
        }
      }
    }

    return false;
  },
);

export const delistCollectionForMarketPlace = createAsyncThunk(
  'marketPlace/delistCollection',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      collectibleAddress,
      tokenId,
      price,
      passport,
      listingType,
      chainId,
      listingID,
    }: IMarketPlaceDeListing,
    { dispatch, getState },
  ): Promise<any> => {
    // off-chain replica
    const response = await delistForMarketPlace(
      wallet,
      address,
      Number(listingID),
      chainId,
    );
    const resOffChain = await response?.json();

    if (resOffChain?.status?.toLowerCase() == 'success') {
      try {
        const data = GetDeListCollectibleMarketPlace(listingID);
        let msg: IMessageMetaData = {
          to: addresses[networkID].MarketPlace,
          wallet: wallet,
          data: data,
          networkID: networkID,
          provider: provider,
        };
        const res = await dispatch(SendMetaTX(msg));
        if (res && res.payload?.eventLogs) {
          const listingID = decodeUnlistingCollectibleMarketPlace(
            res.payload?.eventLogs,
          );
          LogToLoot8Console('Listing Index', listingID);
          if (Number(listingID) !== 0) {
            return true;
          } else {
            // list item again in off-chain in case of any error in on-chain.
            const response = await listForMarketPlace(
              wallet,
              address,
              Number(listingID),
              chainId,
            );
            return false;
          }
        } else {
          // list item again in off-chain in case of any error in on-chain.
          const response = await listForMarketPlace(
            wallet,
            address,
            Number(listingID),
            chainId,
          );
          return false;
        }
      } catch (e) {
        // list item again in off-chain in case of any error in on-chain.
        const response = await listForMarketPlace(
          wallet,
          address,
          Number(listingID),
          chainId,
        );
        return false;
      }
    }
    return false;
  },
);

export const getAllListingsForCollection = async ({
  networkID,
  provider,
  collectionAddress,
  collectionType,
}) => {
  const loot8MarketPlace = Loot8Marketplace__factory.connect(
    addresses[networkID].Loot8MarketPlace,
    provider,
  );
  const listedCollection = await loot8MarketPlace.getAllListingsForCollection(
    collectionAddress,
  );

  const listings = listedCollection?.filter(
    x => x.listingType?.toLocaleString() == collectionType?.toLocaleString(),
  );
  if (listings?.length > 0) {
    return listings;
  }
  return null;
};

export const loadPublicMarketPlaceData = createAsyncThunk(
  'marketPlace/loadPublicMarketPlaceData',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      orderBy,
      order,
      skip,
      take,
      isCache = true,
      isClear = false,
      isOlderMessages = false,
      isCollectibleData = false,
      searchByName = null,
    }: IMarketPlaceListingFilterAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;

    // const allUsersData = await getUsers({ networkID, provider, dispatch });;

    if (isClear) {
      if (isCollectibleData) {
        await dispatch(clearCollectibles());
      } else {
        await dispatch(clearExpassess());
      }
      await dispatch(setinitialListLoading(true));
    }

    // set older list flag when fetching older listing.
    if (isOlderMessages) {
      await dispatch(setOlderListLoading(true));
    }

    let data = null;

    let appConfig;
    try {
      appConfig = await getAppConfiguration();
    } catch (err) {
      LogToLoot8Console(
        'loadPublicMarketPlaceData: Error while reading app config',
      );
    }
    if (
      appConfig &&
      appConfig.indexerService &&
      appConfig.indexerService.marketplaceListing
    ) {
      data = await getMarketplaceListingsData(
        orderBy,
        order,
        skip,
        take,
        isCollectibleData,
        searchByName,
      );
    } else {
      data = await getPublicListingCollectionData(
        orderBy,
        order,
        skip,
        take,
        isCollectibleData,
      );
    }

    Promise.all(
      data?.map(async (collectible, index) => {
        if (collectible) {
          //* Filter out any expasses that belong to other ENVs::Prod = [42170, 137, 42161], Stag = [42170999], Dev = [421614]

          switch (appConfig?.env) {
            case AppEnvironment.DEVELOPMENT: {
              if (collectible?.chainId !== NetworkId.ARBITRUM_SEPOLIA) {
                //* Expass is not Allowed
                return;
              }
              break;
            }
            case AppEnvironment.STAGING: {
              if (collectible?.chainId !== NetworkId.STAGING_ARBITRUM_NOVA) {
                //* Expass is not Allowed
                return;
              }
              break;
            }
            case AppEnvironment.PRODUCTION: {
              if (
                ![
                  NetworkId.ARBITRUM,
                  NetworkId.ARBITRUM_NOVA,
                  NetworkId.POLYGON_MAINNET,
                ].includes(collectible?.chainId)
              ) {
                //* Expass is not Allowed
                return;
              }
              break;
            }
            default: {
              if (
                ![
                  NetworkId.ARBITRUM,
                  NetworkId.ARBITRUM_NOVA,
                  NetworkId.POLYGON_MAINNET,
                ].includes(collectible?.chainId)
              ) {
                //* Expass is not Allowed
                return;
              }
              break;
            }
          }

          // get collection details.
          const collection = await getCollectibleDetails(
            {
              networkID: collectible?.chainId,
              provider: getAnynetStaticProvider(+collectible?.chainId),
              collectibleAddress: collectible?.collection,
              address,
              wallet,
            },
            { entityData },
            {
              isCache: true,
              isBalanceRefresh: false,
              isMarketPlaceRefresh: false,
            },
          );

          // get seller data
          let sellerData = null;
          // if(allUsersData?.length > 0) {
          //   const ownerData = allUsersData.find(user => user.wallet?.toLowerCase() == collectible?.seller?.toLowerCase())
          //   if(ownerData) {
          //     const avatarURI = await getUserAvatar(ownerData?.avatarURI)
          //     sellerData = {...ownerData, avatarURI: avatarURI}
          //   }
          // }

          // add collectible
          if (collection) {
            const collectibleData = {
              ...collectible,
              collectionData: collection,
              sellerData: sellerData,
            };

            let listingData;

            try {
              const marketplace = Loot8Marketplace__factory.connect(
                addresses[networkID].MarketPlace,
                provider,
              );
              listingData = await marketplace.listingExists(
                collectible?.collection,
                collectible?.tokenId,
                collectible?.listingType,
              );
            } catch (e) {
              LogCustomError(
                `Error: loadPublicMarketPlaceData-listingExists-${
                  collectible?.collection +
                  '-' +
                  collectible?.tokenId +
                  '-' +
                  collectible?.listingType +
                  '-'
                }`,
                e.name,
                e.message,
                e.stack,
              );
            }

            // Only push collections/expasses on the UI if the listing is valid
            if (listingData?._exists) {
              if (isCollectibleData) {
                await dispatch(pushCollectiables(collectibleData));
              } else {
                // Only push Premium Expasses in the Marketplace listing
                if (collection.isPremium === true) {
                  await dispatch(pushExpass(collectibleData));
                }
              }
            }
          }
        }
      }),
    ).then(() => {
      if (isCollectibleData) {
        dispatch(setLoadingCollectible(false));
      } else {
        dispatch(setLoadingExpass(false));
      }
    });

    if (data?.length == 0) {
      dispatch(setOlderListLoading(false));
      dispatch(setinitialListLoading(false));
      if (isCollectibleData) {
        dispatch(setLoadingCollectible(false));
      } else {
        dispatch(setLoadingExpass(false));
      }
    }
  },
);

export const approveForBuyListing = createAsyncThunk(
  'marketPlace/approveForBuyListing',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      amount,
    }: IMarketPlaceAppprovalForListing,
    { dispatch, getState },
  ): Promise<any> => {
    try {
      let paymentToken = isNativeChain(networkID)
        ? addresses[networkID].Loot8Token
        : addresses[networkID].USDC;

      const tokenDecimal = await getTokenDecimals(
        paymentToken,
        Loot8Token__factory.abi,
        provider,
      );
      const convertValue = ethers.utils.parseUnits(
        amount ? amount?.toString() : '0',
        tokenDecimal ?? 18,
      );

      let data = null;
      if (isNativeChain(networkID)) {
        data = GetApprovalForBuyListing(
          addresses[networkID].MarketPlace,
          convertValue,
        );
      } else {
        data = await GetForeignChainApprovalForBuyListing(
          networkID,
          provider,
          wallet,
          convertValue,
        );
      }
      let msg: IMessageMetaData = {
        to: paymentToken,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };
      const res = await dispatch(SendMetaTX(msg));
      if (res && res.payload?.eventLogs) {
        console.log('approveForBuyListing', res);
      }
    } catch (err) {
      LogToLoot8Console(
        'approveForBuyListing - falied',
        err.name,
        err.message,
        err.stack,
      );
    }
  },
);

export const buyPublicMarketPlaceCollection = createAsyncThunk(
  'marketPlace/buyPublicMarketPlaceCollection',
  async (
    {
      networkID,
      provider,
      address,
      wallet,
      listingId,
      isCache = true,
      isCollectibleData = false,
    }: IMarketPlaceListingAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    let allListing = null;

    if (isCollectibleData) {
      allListing = state.MarketPlace.listedCollectibles;
    } else {
      allListing = state.MarketPlace.listedExpass;
    }

    const listingData = allListing?.find(x => x.id == listingId);

    let paymentToken = isNativeChain(listingData?.chainId)
      ? addresses[listingData?.chainId].Loot8Token
      : addresses[listingData?.chainId].USDC;

    const tokenDecimal = await getTokenDecimals(
      paymentToken,
      Loot8Token__factory.abi,
      provider,
    );
    const marketPrice = ethers.utils.parseUnits(
      listingData?.price ? listingData?.price?.toString() : '0',
      tokenDecimal ?? 18,
    );

    const response = await validateForMarketPlace(
      wallet,
      address,
      listingData?.passport,
      listingData?.collection,
      listingData?.tokenId,
      paymentToken,
      marketPrice.toString(),
      'buy',
      listingData?.listingType,
    );
    let resp = await response.json();

    // let signature = '0x';
    // let expiry = 0;
    // if(listingData && listingData?.chainId !== NetworkId.ARBITRUM_NOVA && listingData?.chainId !== NetworkId.ARBITRUM_SEPOLIA) {
    //   let paymentToken = addresses[listingData?.chainId].USDC;

    //   const tokenDecimal = await getTokenDecimals(paymentToken, Loot8Token__factory.abi, provider);
    //   const marketPrice = ethers.utils.parseUnits(listingData?.price ? listingData?.price?.toString()  : "0", tokenDecimal ?? 18)

    //   const response = await validateForMarketPlace(wallet, address, listingData?.passport, listingData?.collection, listingData?.tokenId, paymentToken, marketPrice.toString(), "buy", listingData?.listingType);
    //   let resp = await response.json();
    //   if(resp && resp.status == "success" && resp.result == "success" && resp.signature) {
    //     signature = resp.signature;
    //     expiry = resp.expiry;
    //   } else {
    //     signature = null;
    //   }
    // }

    if (
      resp &&
      resp.status == 'success' &&
      resp.result == 'success' &&
      resp.signature
    ) {
      const data = GetBuyCollectibleMarketPlace(
        listingId,
        resp.signature,
        resp.expiry,
      );

      let msg: IMessageMetaData = {
        to: addresses[networkID].MarketPlace,
        wallet: wallet,
        data: data,
        networkID: networkID,
        provider: provider,
      };

      try {
        // need to check reponse
        const res = await dispatch(SendMetaTX(msg));
        if (res && res.payload?.eventLogs) {
          const _listingId = decodeUnlistingCollectibleMarketPlace(
            res.payload?.eventLogs,
          );
          LogToLoot8Console(
            'buyPublicMarketPlaceCollection - Purchased Listing ID',
            _listingId,
          );
          if (_listingId) {
            // off-chain replica
            const response = await delistForMarketPlace(
              wallet,
              address,
              listingId,
              networkID,
            );
            return true;
          }
          return Number(_listingId) !== 0;
        }
      } catch (e) {
        console.log('buyPublicMarketPlaceCollection', e);
      }
    }

    return false;
  },
);

export const searchCollectible = createAsyncThunk(
  'marketPlace/searchCollectible',
  async ({ searchText }: { searchText }, { getState, dispatch }) => {
    const state = getState() as RootState;
    let allCollectibleSearchList = [
      ...state.MarketPlace.listedCollectibleSearchList,
    ];
    let AllCollectibleList = [...state.MarketPlace.listedCollectibles];

    if (searchText && searchText.length > 0) {
      let searchTextLower = searchText.toLowerCase().trim();

      if (searchText.length === 1) {
        allCollectibleSearchList = AllCollectibleList.filter(
          p =>
            p?.collectionData?.name
              .toLowerCase()
              .startsWith(searchTextLower) === true,
        );
      } else {
        allCollectibleSearchList = AllCollectibleList.filter(
          p =>
            p?.collectionData?.name.toLowerCase().indexOf(searchTextLower) > -1,
        );
      }
    } else {
      allCollectibleSearchList = AllCollectibleList;
    }

    return {
      listedCollectibleSearchList: allCollectibleSearchList,
    };
  },
);

export const searchExpass = createAsyncThunk(
  'marketPlace/searchExpass',
  async ({ searchText }: { searchText }, { getState, dispatch }) => {
    const state = getState() as RootState;
    let allExpassesSearchList = [...state.MarketPlace.listedExpassesSearchList];
    let allExpassesList = [...state.MarketPlace.listedExpass];

    if (searchText && searchText.length > 0) {
      let searchTextLower = searchText.toLowerCase().trim();

      if (searchText.length === 1) {
        allExpassesSearchList = allExpassesList.filter(
          p =>
            p?.collectionData?.name
              .toLowerCase()
              .startsWith(searchTextLower) === true,
        );
      } else {
        allExpassesSearchList = allExpassesList.filter(
          p =>
            p?.collectionData?.name.toLowerCase().indexOf(searchTextLower) > -1,
        );
      }
    } else {
      allExpassesSearchList = allExpassesList;
    }

    return {
      listedExpassesSearchList: allExpassesSearchList,
    };
  },
);

export interface IMarketPlaceSliceData {
  readonly loading: boolean;
  readonly loadingExpass: boolean;
  readonly listedExpass: IListing[];
  readonly loadingCollectible: boolean;
  readonly listedCollectibles: IListing[];
  readonly dropdownPassportData: any[];
  readonly listedCollectibleSearchList: IListing[];
  readonly listedExpassesSearchList: IListing[];
  readonly selectedCollectiblePassport: ICatalog;
  readonly loadingSelectedCollectiblePassport: boolean;
  readonly initialListLoading: boolean;
  readonly olderListLoading: boolean;
}

const initialState: IMarketPlaceSliceData = {
  loading: false,
  loadingExpass: true,
  listedExpass: [],
  loadingCollectible: true,
  listedCollectibles: [],
  dropdownPassportData: [],
  listedCollectibleSearchList: [],
  listedExpassesSearchList: [],
  selectedCollectiblePassport: null,
  loadingSelectedCollectiblePassport: false,
  initialListLoading: false,
  olderListLoading: false,
};

const MarketPlaceSlice = createSlice({
  name: 'MarketPlace',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    pushExpass(state, action) {
      state.listedExpass = state.listedExpass.filter(x => {
        return x.unique_id != action.payload.unique_id;
      });
      state.listedExpass.push(action.payload);
      state.listedExpassesSearchList = state.listedExpass;
    },
    removeUnlistedExpass(state, action) {
      state.listedExpassesSearchList = state.listedExpassesSearchList.filter(
        x => {
          return (
            x.collection.toLowerCase() != action.payload.address.toLowerCase()
          );
        },
      );
      state.listedExpass = state.listedExpass.filter(x => {
        return (
          x.collection.toLowerCase() != action.payload.address.toLowerCase()
        );
      });
    },
    pushCollectiables(state, action) {
      state.listedCollectibles = state.listedCollectibles.filter(x => {
        return x.unique_id != action.payload.unique_id;
      });
      state.listedCollectibles.push(action.payload);
      state.listedCollectibleSearchList = state.listedCollectibles;
    },
    removeUnlistedCollectible(state, action) {
      state.listedCollectibleSearchList =
        state.listedCollectibleSearchList.filter(x => {
          return (
            x.collection.toLowerCase() != action.payload.address.toLowerCase()
          );
        });
      state.listedCollectibles = state.listedCollectibles.filter(x => {
        return (
          x.collection.toLowerCase() != action.payload.address.toLowerCase()
        );
      });
    },
    clearCollectibles(state) {
      state.listedCollectibles = [];
      state.listedCollectibleSearchList = [];
    },
    setinitialListLoading(state, action) {
      state.initialListLoading = action.payload;
    },
    setOlderListLoading(state, action) {
      state.olderListLoading = action.payload;
    },
    setLoadingCollectible(state, action) {
      state.loadingCollectible = action.payload;
    },
    setLoadingExpass(state, action) {
      state.loadingExpass = action.payload;
    },
    clearExpassess(state) {
      state.listedExpass = [];
      state.listedExpassesSearchList = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        listCollectionForMarketPlace.pending,
        (state: { loading: boolean }) => {
          state.loading = true;
        },
      )
      .addCase(
        listCollectionForMarketPlace.fulfilled,
        (state: { loading: boolean }) => {
          state.loading = false;
        },
      )
      .addCase(
        listCollectionForMarketPlace.rejected,
        (state, { error }: any) => {
          state.loading = false;
          LogCustomError(
            'listCollectionForMarketPlace',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(loadPublicMarketPlaceData.fulfilled, (state, action) => {
        // state.loadingExpass = false;
      })
      .addCase(
        loadPublicMarketPlaceData.pending,
        (state: { loadingExpass: boolean }) => {
          // state.loadingExpass = true;
        },
      )
      .addCase(
        loadPublicMarketPlaceData.rejected,
        (state: { loadingExpass: boolean }, { error }: any) => {
          //  state.loadingExpass = false;
          showToastMessage();
          LogCustomError(
            'loadPublicMarketPlaceExpass',
            error.name,
            error.message,
            error.stack,
          );
        },
      )
      .addCase(searchCollectible.pending, (state, action) => {
        state.loadingCollectible = true;
      })
      .addCase(searchCollectible.fulfilled, (state, action) => {
        state.listedCollectibleSearchList =
          action.payload.listedCollectibleSearchList;
        state.loadingCollectible = false;
      })
      .addCase(searchCollectible.rejected, (state, { error }: any) => {
        state.loadingCollectible = false;
      })
      .addCase(searchExpass.pending, (state, action) => {
        state.loadingExpass = true;
      })
      .addCase(searchExpass.fulfilled, (state, action) => {
        state.listedExpassesSearchList =
          action.payload.listedExpassesSearchList;
        state.loadingExpass = false;
      })
      .addCase(searchExpass.rejected, (state, { error }: any) => {
        state.loadingExpass = false;
      });
  },
});

export const MarketPlaceSliceReducer = MarketPlaceSlice.reducer;

const baseInfo = (state: RootState) => state.MarketPlace;

export const {
  fetchAppSuccess,
  pushCollectiables,
  pushExpass,
  clearCollectibles,
  setinitialListLoading,
  setOlderListLoading,
  clearExpassess,
  setLoadingCollectible,
  setLoadingExpass,
  removeUnlistedCollectible,
  removeUnlistedExpass,
} = MarketPlaceSlice.actions;

export const getUserState = createSelector(
  baseInfo,
  MarketPlace => MarketPlace,
);
