import React from 'react';
import { StyleSheet, Text, Image } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { TouchableRipple } from 'react-native-paper';

import { defaultTheme } from '../../themes/loot8';
import token from '../../assets/Loot8TokenBlack.png';

interface GradientPriceProps {
  price: string;
  onPress?: () => void;
  backgroundColor?: string;
}

const GradientPrice: React.FC<GradientPriceProps> = ({
  price,
  onPress,
  backgroundColor,
}) => {
  return (
    <LinearGradient
      colors={[
        backgroundColor ? backgroundColor : defaultTheme.GRADIENT_COLOR1,
        backgroundColor ? backgroundColor : defaultTheme.GRADIENT_COLOR2,
      ]}
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={[styles.container]}>
      <TouchableRipple
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}
        style={styles.ripple}>
        <>
          <Image source={token} resizeMode="contain" style={styles.tokenImg} />
          <Text style={styles.tokenText}>{price}</Text>
        </>
      </TouchableRipple>
    </LinearGradient>
  );
};

export default GradientPrice;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
    height: 20,
    borderRadius: 32,
    overflow: 'hidden',
  },
  tokenText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: '#fff',
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    marginLeft: 5,
  },
  tokenImg: {
    height: 14,
    width: 14,
  },
  ripple: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 5,
    backgroundColor: 'rgba(0,0,0,0)',
  },
});
