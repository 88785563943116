import React, { useEffect } from 'react';
import SectionHeading from '../../../SectionHeading';
import { useNavigation } from '@react-navigation/native';
import SeeAllTextPressable from '../../../SeeAllTextPressable';
import useExpassEventManager from '../../../../hooks/useExpassEventManager';
import EventList from './EventList';

const Events = ({ selectPassport }) => {
  const navigation = useNavigation();
  const { loadingEvents, associatedEvents, getAssociatedEventsForExpass } =
    useExpassEventManager();

  useEffect(() => {
    selectPassport && getAssociatedEventsForExpass(selectPassport);
  }, [selectPassport]);

  const onPressEventCard = item => {
    // @ts-ignore
    navigation.navigate('EventDetails', {
      payload: { selectedEvent: item },
    });
  };

  const handleSeeAll = () => {
    // @ts-ignore
    navigation.navigate('EventList', {
      payload: {
        events: associatedEvents,
        isPage: true,
        sectionHeading: selectPassport?.name,
      },
    });
  };

  return (
    <>
      <SectionHeading
        title={`Events (${associatedEvents?.length || 0})`}
        renderRight={
          !loadingEvents && associatedEvents?.length ? (
            <SeeAllTextPressable onPress={handleSeeAll} />
          ) : null
        }
      />
      <EventList
        events={associatedEvents}
        onEventClick={onPressEventCard}
        isLoading={loadingEvents}
        showLimitedEvents
        route={null}
      />
    </>
  );
};

export default Events;
