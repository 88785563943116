import { Keyboard, Platform, Pressable, StyleSheet, View } from 'react-native';
import React from 'react';
import { defaultTheme } from '../../themes/loot8';
import AnimatedBackButton from './AnimatedBackButton';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import ProfileAvatar from '../../components/ProfileAvatar';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { ScreenName } from '../../enums/screen.enum';
import GradientPlusButton from '../../components/GradientPlusButton';
import GradientTokenBalance from './GradientTokenBalance';
import {
  getUnsubsribedNotifications,
  setShowNotificationModal,
} from '../../slices/NotificationSlice';
import {
  NotificationSvg,
  NotificationUnreadSvg,
} from '../../assets/svg/HeaderSvgs';

const CustomHeader = ({ navigation }) => {
  const isWeb = Platform.OS === 'web';
  const fromUserDeactivate = isWeb
    ? new URLSearchParams(window.location.search).get('user') === 'deactivate'
    : false;
  const insets = useSafeAreaInsets();
  const dispatch = useAppDispatch();
  const { connected, networkId, staticProvider, address, wallet } =
    useWeb3AuthContext();
  const userData = useAppSelector(state => state.AppUser.UserData);
  const { notifications } = useAppSelector(state => state.notification);

  const newNotifications = notifications.filter(p => !p.shown);

  const resetQueryParams = () => {
    if (isWeb) {
      window.history.replaceState(null, '', '/');
    }
  }
  const onUserIconPressed = () => {
    Keyboard.dismiss();
    if (fromUserDeactivate) {
      resetQueryParams();
    }
    navigation.navigate('MoreTab', {
      screen: ScreenName.PROFILE_SCREEN,
    });
  };
  
  const onPlusIconPressed = () => {
    Keyboard.dismiss();
    if (fromUserDeactivate) {
      resetQueryParams();
    }
    navigation.navigate('WalletTab', {
      screen: 'TokenPackages',
    });
  };

  const onNotificationIconPressed = async () => {
    dispatch(setShowNotificationModal(true));
    Keyboard.dismiss();
    await dispatch(
      getUnsubsribedNotifications({
        networkID: networkId,
        provider: staticProvider,
        address: address,
        wallet: wallet,
      }),
    );
  };
  return (
    <View
      style={[
        styles.container,
        {
          height: Platform.OS === 'ios' ? 120 : 70,
          paddingTop: insets.top,
        },
      ]}>
      <View style={styles.row}>
        <AnimatedBackButton navigation={navigation} />
        {connected && userData && userData.name && (
          <View style={styles.rightContent}>
            <GradientTokenBalance />
            <View style={{ marginHorizontal: 5 }} />
            <GradientPlusButton size={24} onPress={onPlusIconPressed} />
            <Pressable
              onPress={onNotificationIconPressed}
              style={styles.notificationIcon}>
              {newNotifications.length > 1 ? (
                <NotificationUnreadSvg />
              ) : (
                <NotificationSvg />
              )}
            </Pressable>
            <Pressable style={{ marginLeft: 5 }} onPress={onUserIconPressed}>
              <ProfileAvatar
                size={32}
                source={
                  userData.avatarURI &&
                  userData.avatarURI != '' &&
                  userData.avatarURI != 'no-avatar' &&
                  !userData.avatarURI.includes('ipfs://') &&
                  !userData.avatarURI.includes('assets_avatars_p_') &&
                  !userData.avatarURI.includes('file:///')
                    ? { uri: userData.avatarURI }
                    : null
                }
                userAddress={userData?.wallet}
              />
            </Pressable>
          </View>
        )}
      </View>
    </View>
  );
};

export default CustomHeader;

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    paddingHorizontal: defaultTheme.HORIZONTAL_PADDING,
    backgroundColor: defaultTheme.MAIN_BACKGROUND_COLOR,
  },
  row: {
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightContent: { flexDirection: 'row', alignItems: 'center' },
  notificationIcon: {
    borderRadius: 15,
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
  },
});
