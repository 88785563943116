import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import WalletScreen from '../screens/WalletScreen';
import LinkedWallets from '../components/WalletHelp/LinkedWallets';
import BuyToken from '../components/WalletHelp/BuyToken';
import TokenPackages from '../components/WalletHelp/TokenPackages';
import MarketPlaceScreen from '../screens/MarketPlaceScreen';
import MPCollectibleDetails from '../components/MarketPlace/MPCollectibles/MPCollectibleDetails';
import MarketPlaceBuyCollection from '../components/MarketPlace/MarketPlaceBuyCollection';
import CashOutTokens from '../components/WalletHelp/cashOutTokens/CashOutTokens';
import TXHistory from '../components/WalletHelp/txHistory/TXHistory';
import CustomHeader from './AnimatedHeader/CustomHeader';
import SwapScreen from '../screens/SwapScreen';

const Stack = createStackNavigator();

function WalletNavigator() {
  const navigation = useNavigation();
  useEffect(() => {
    navigation.addListener('blur', () => {
      if (Platform.OS === 'web') {
        history.replaceState(null, document.title, window.location.origin);
      }
    });
  }, [navigation]);
  return (
    <Stack.Navigator
      initialRouteName="Wallet"
      screenOptions={{
        headerShown: true,
        headerStyle: {
          borderBottomWidth: 0,
          backgroundColor: 'transparent',
          height: Platform.OS === 'ios' ? 120 : 70,
        },
        headerMode: 'float',
        header: props => <CustomHeader {...props} />,
      }}>
      <Stack.Screen name="Wallet" component={WalletScreen} />
      <Stack.Screen name="LinkedWallet" component={LinkedWallets} />
      <Stack.Screen name="TokenPackages" component={TokenPackages} />
      <Stack.Screen name="CashOutTokens" component={CashOutTokens} />
      <Stack.Screen name="SwapScreen" component={SwapScreen} />
      <Stack.Screen name="TXHistory" component={TXHistory} />
      <Stack.Screen name="BuyToken" component={BuyToken} />
      <Stack.Screen name="MarketPlaceScreen" component={MarketPlaceScreen} />
      <Stack.Screen
        name="MPCollectibleDetail"
        component={MPCollectibleDetails}
      />
    </Stack.Navigator>
  );
}

export default WalletNavigator;
