import React from 'react';
import {
  Text,
  View,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  TextInput,
} from 'react-native';
import Icon from 'react-native-vector-icons/Entypo';

import AnimatedMenu, { MenuItem } from '../../../components/AnimatedMenu';
import TextInputComponent from '../../../components/TextInputComponent';
import {
  ArtistCategorySvg,
  BusinessCategorySvg,
  CelebritiesCategorySvg,
  EducatorsCategorySvg,
  FoundationsCategorySvg,
  GamingCategorySvg,
  MusicCategorySvg,
  OtherCategorySvg,
  SportsCategorySvg,
} from '../../../assets/svg/HomeSvgs';
import { defaultTheme } from '../../../themes/loot8';
import { ImageState } from '../../screens/EstPortalCreateProfile';
import { CATEGORIES } from '../../enums/offers.enum';

const isWeb = Platform.OS === 'web';

interface ProfileCreationDetailsProps {
  message: string;
  exPassName: string;
  setExPassName: (value: string) => void;
  setMessage: (value: string) => void;
  selectedCategory: null | MenuItem;
  setSelectedCategory: (item: MenuItem) => void;
  thumbnailImage: ImageState;
  optimisedImage: ImageState;
  originalImage: ImageState;
  handleOpenImageModal: (key: string) => void;
  handleRemoveImage: (key: string) => void;
}

const categoriesMenuData: MenuItem[] = [
  {
    id: 1,
    name: 'Sports',
    value: CATEGORIES.SPORTS,
    icon: SportsCategorySvg,
  },
  {
    id: 2,
    name: 'Music',
    value: CATEGORIES.MUSIC,
    icon: MusicCategorySvg,
  },
  {
    id: 3,
    name: 'Celebrities',
    value: CATEGORIES.CELEBRITIES,
    icon: CelebritiesCategorySvg,
  },
  {
    id: 4,
    name: 'Educators',
    value: CATEGORIES.EDUCATORS,
    icon: EducatorsCategorySvg,
  },
  {
    id: 5,
    name: 'Business',
    value: CATEGORIES.BUSINESS,
    icon: BusinessCategorySvg,
  },
  {
    id: 6,
    name: 'Gaming',
    value: CATEGORIES.GAMING,
    icon: GamingCategorySvg,
  },
  {
    id: 7,
    name: 'Artist',
    value: CATEGORIES.ARTIST,
    icon: ArtistCategorySvg,
  },
  {
    id: 8,
    name: 'Foundations',
    value: CATEGORIES.FOUNDATIONS,
    icon: FoundationsCategorySvg,
  },
  {
    id: 9,
    name: 'Others',
    value: CATEGORIES.OTHER,
    icon: OtherCategorySvg,
  },
];

const imageTypes = [
  {
    id: 1,
    key: 'original',
    label: 'Upload ExPass image',
  },
  {
    id: 2,
    key: 'optimised',
    label: 'Upload Optimized image',
  },
  {
    id: 3,
    key: 'thumbnail',
    label: 'Upload Thumbnail image',
  },
];

const ProfileCreationDetails: React.FC<ProfileCreationDetailsProps> = ({
  exPassName,
  setExPassName,
  selectedCategory,
  setSelectedCategory,
  thumbnailImage,
  optimisedImage,
  originalImage,
  handleOpenImageModal,
  handleRemoveImage,
  message,
  setMessage,
}) => {
  const renderImageURI = (key: string) => {
    if (key === 'thumbnail') {
      return thumbnailImage.image;
    } else if (key === 'optimised') {
      return optimisedImage.image;
    } else if (key === 'original') {
      return originalImage.image;
    }
  };
  const isImageSelected = (key: string) => {
    if (key === 'thumbnail' && thumbnailImage.image !== null) {
      return true;
    } else if (key === 'optimised' && optimisedImage.image !== null) {
      return true;
    } else if (key === 'original' && originalImage.image !== null) {
      return true;
    }
    return false;
  };
  return (
    <>
      <Text style={styles.title}>Create your free profile</Text>
      <Text style={styles.subTitle}>
        This profile is the first step in recruiting fans. Anyone can follow
        this account for free!
      </Text>
      <ScrollView
        contentContainerStyle={styles.scrollViewContent}
        style={styles.scrollView}
        showsVerticalScrollIndicator={false}>
        <Text style={styles.label}>ExPass name</Text>
        <TextInputComponent
          value={exPassName}
          setOnChange={setExPassName}
          placeholder="Give your ExPass a unique name"
        />
        <Text style={styles.label}>Category</Text>
        <AnimatedMenu
          data={categoriesMenuData}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
        <Text style={styles.label}>Description</Text>
        <TextInput
          multiline
          blurOnSubmit
          autoCapitalize="none"
          cursorColor="rgb(219, 226, 237)"
          selectionColor="rgb(219, 226, 237)"
          placeholder={'Write your message...'}
          maxLength={360}
          autoCorrect={false}
          returnKeyType="done"
          value={message}
          onChangeText={text => setMessage(text)}
          style={styles.textContainer}
          placeholderTextColor={defaultTheme.SECONDARY_TEXT_COLOR}
        />
        <Text style={styles.label}>Upload image for free profile</Text>
        <View style={styles.imageContainer}>
          {imageTypes?.map(item => {
            return (
              <TouchableOpacity
                key={item.id}
                activeOpacity={0.8}
                style={styles.profileImageContainer}
                onPress={() => handleOpenImageModal(item.key)}>
                {isImageSelected(item.key) ? (
                  <>
                    <Image
                      style={styles.image}
                      resizeMode={isWeb ? 'contain' : 'cover'}
                      source={{ uri: renderImageURI(item.key) }}
                    />
                    <Icon
                      size={22}
                      name="circle-with-cross"
                      style={styles.clearImageButton}
                      color={defaultTheme.GRADIENT_COLOR1}
                      onPress={() => handleRemoveImage(item.key)}
                    />
                  </>
                ) : (
                  <>
                    <Icon
                      size={30}
                      name="circle-with-plus"
                      color={defaultTheme.GRADIENT_COLOR1}
                    />
                    <Text style={styles.noImageLabel}>{item.label}</Text>
                  </>
                )}
              </TouchableOpacity>
            );
          })}
        </View>
        <View style={styles.buttonSpace} />
      </ScrollView>
    </>
  );
};

export default ProfileCreationDetails;

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
    paddingBottom: 100,
    paddingTop: 25,
  },
  scrollView: {
    flex: 1,
  },
  buttonSpace: {
    marginTop: 72,
  },
  title: {
    marginBottom: 6,
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
  },
  subTitle: {
    textAlign: 'center',
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
    marginBottom: 15,
  },
  label: {
    marginTop: 20,
    marginBottom: 8,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  imageContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  profileImageContainer: {
    height: 136,
    width: isWeb ? '32%' : '31%',
    marginTop: 2,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: '#fff',
    borderStyle: 'dashed',
    alignItems: 'center',
    justifyContent: 'center',
    borderCurve: 'continuous',
  },
  clearImageButton: { position: 'absolute', top: 3, right: 3, zIndex: 99 },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 12,
  },
  noImageLabel: {
    marginTop: 4,
    textAlign: 'center',
    paddingHorizontal: 6,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
  },
  textContainer: {
    height: 146,
    padding: 16,
    fontSize: 12,
    width: '100%',
    borderRadius: 12,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontWeight: '500',
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    outlineStyle: 'none', // for web
  },
});
