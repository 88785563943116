export enum UICustomOfferType {
  EMPTY = '',
  REGULAR = 'Regular',
  FEATURED_OFFER = 'Featured',
  EXPERIENCE = 'Experience',
  EVENT = 'Event',
  REGULAR_OR_FEATURED_OFFER = 'REGULAR_OR_FEATURED_OFFER',
  DIGITAL_COLLECTIBLES = 'DIGITAL_COLLECTIBLES',
  COUPON = 'Coupon',
}

export enum EstPortalPassportType {
  REGULAR,
  SUBSCRIPTON,
}

export enum OfferType {
  NOTANYOFFER,
  FEATURED,
  REGULAR,
}

export enum CATEGORIES {
  OTHER,
  SPORTS,
  MUSIC,
  CELEBRITIES,
  EDUCATORS,
  BUSINESS,
  GAMING,
  ARTIST,
  FOUNDATIONS
}

