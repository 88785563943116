import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { defaultTheme } from '../../themes/loot8';
import { useAppSelector } from '../../hooks';
import EmptyList from '../EmptyList';
import AppLoaderComponent from '../Loader';
import FriendReqCard from '../FriendReqCard';

export const FriendRequests = ({ onSelectedFriend }) => {
  const pendingRequest =
    useAppSelector(state => state.PrivateMessage.pendingRequest) ?? [];
  const requestLoading = useAppSelector(
    state => state.PrivateMessage.requestLoading,
  );
  const [latestRequest, setLatestRequest] = useState(pendingRequest);
  const loadingFriends = useAppSelector(
    state => state.friends.loadingFriendsData,
  );
  const loadingUsers = useAppSelector(state => state.AppUser.loading);
  const [requestsLoading, setRequestsLoading] = useState({
    once: false,
    loading: false,
  });
  const [deletedOrAcceptedRequest, setDeletedOrAcceptedRequest] = useState([]);

  // getting the deleted or accepted request from the child component so we can remove it from the list when the list is updated by the refereshNotifications function and the action for deleting or accepting is just dispatched successfully. Removing it on success of delete and accept and they won't appear when next time the refereshNotification occurs.
  const userPopped = wallet => {
    setDeletedOrAcceptedRequest([...deletedOrAcceptedRequest, wallet]);
  };

  useEffect(() => {
    // to show the loader only the first time when the requests are loading, Once the user gets to requests page the loader shouldn't show up again & again.
    // the requestLoading is set to true with the refresh notifications thatswhy the loader shows up after some time.
    if (requestLoading && !requestsLoading.once) {
      setRequestsLoading({ once: true, loading: true });
    } else if (requestLoading && requestsLoading.once) {
      setRequestsLoading({ once: true, loading: false });
    } else if (!requestLoading && requestsLoading.once) {
      setRequestsLoading({ once: true, loading: false });
    }
  }, [requestLoading]);

  useEffect(() => {
    if (pendingRequest.length !== latestRequest.length) {
      if (deletedOrAcceptedRequest.length > 0) {
        const updatedRequests = latestRequest.filter(
          request => !deletedOrAcceptedRequest.includes(request.user.wallet),
        );
        setLatestRequest(updatedRequests);
      } else {
        setLatestRequest(pendingRequest);
      }
    }
  }, [pendingRequest]);

  useEffect(() => {
    return () => {
      setDeletedOrAcceptedRequest([]);
    };
  }, []);

  //Loot8-2034: when a friend request from new user is accepted; previous friends are lost sometimes as AddFriends needs previous friend list loaded first.
  let loading = loadingFriends || loadingUsers || requestsLoading.loading;

  return (
    <>
      {!loading && latestRequest ? (
        <>
          {latestRequest.length > 0 && (
            <Text style={componentStyles.friendRequestCountText}>
              {'Pending Requests'}
              <Text style={{ marginLeft: 5, color: '#F4B540' }}>
                {latestRequest.length}
              </Text>
            </Text>
          )}
          {latestRequest?.length > 0 ? (
            <FlatList
              data={latestRequest}
              showsVerticalScrollIndicator={false}
              renderItem={({ item }) => (
                <FriendReqCard
                  item={item}
                  onSelectedFriend={onSelectedFriend}
                  onPopUser={userPopped}
                />
              )}
              style={{ flex: 1 }}
              contentContainerStyle={{ flexGrow: 1, paddingBottom: 150 }}
              keyboardShouldPersistTaps="handled"
              ItemSeparatorComponent={() => (
                <View style={componentStyles.separator} />
              )}
            />
          ) : (
            <View style={{ flex: 1 }}>
              <EmptyList
                width={'100%'}
                message="There is no friend request at this moment!"
              />
            </View>
          )}
        </>
      ) : (
        <View style={{ flex: 1 }}>
          <AppLoaderComponent deemBg={false} />
        </View>
      )}
    </>
  );
};

const componentStyles = StyleSheet.create({
  friendRequestCountText: {
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    marginTop: 20,
    marginBottom: 10,
  },
  separator: {
    height: 1,
    backgroundColor: defaultTheme.SECONDARY_COLOR,
    marginVertical: 20,
  },
});
