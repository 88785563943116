import {
  Image,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { IMessageList } from '../../interfaces/IMessages';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { getUserPublicKey } from '../../slices/AppUserSlice';
import { addFriend, updateFriendRequest } from '../../slices/friendsSlice';
import {
  deletePrivateMsgRequestToFriend,
  popPendingRequest,
} from '../../slices/PrivateMessageSlice';
import { showToastMessage } from '../../helpers/Gadgets';
import { ToastCustomMessageType } from '../../appconstants';
import { getTimeTextFromTimestamp } from '../../helpers/DateHelper';
import { ActivityIndicator } from 'react-native';
import { defaultTheme } from '../../themes/loot8';
import ProfileAvatar from '../ProfileAvatar';
import LinkVariantIcon from '../../assets/LinkVariant.png';
import GradientButton from '../GradientButton';
import useIsResponsive from '../../hooks/useIsResponsive';

const NUM_OF_LINES = 2;

const FriendReqCard = ({
  item,
  onSelectedFriend,
  onPopUser,
}: {
  item: IMessageList;
  onSelectedFriend;
  onPopUser;
}) => {
  const [isResponded, setIsResponded] = useState(null);
  const [isMutualFriend, setIsMutualFriend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(Platform.OS === 'web');
  const [numOfLines, setNumOfLines] = useState(0);

  const dispatch = useAppDispatch();
  const isResponsive = useIsResponsive();
  const { networkId, staticProvider, address, wallet, decryptMessage } =
    useWeb3AuthContext();
  const currentFriendsData = useAppSelector(
    state => state.friends.currentFriends,
  );

  const handleTextLayout = event => {
    if (Platform.OS !== 'web') {
      const { lines } = event.nativeEvent;
      if (numOfLines == 0) {
        setNumOfLines(lines?.length);
      }
    }
  };

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  const textRef = useRef<Text>(null);

  const handleLayout = () => {
    textRef.current.measure((x, y, width, height) => {
      const lines = Math.floor(height / 17);
      if (lines > 2) {
        setNumOfLines(lines);
        setExpanded(false);
      }
    });
  };
  useEffect(() => {
    if (Platform.OS === 'web') {
      handleLayout();
    }
  }, []);

  const acceptRequest = async item => {
    setIsLoading(true);
    const friendPublicKey = await getUserPublicKey(item?.user?.wallet);

    //Loot8-2807: Check if the request sender is already added as friend by user (without accepting the request)
    const isAlreadyFriend =
      currentFriendsData?.length > 0 &&
      currentFriendsData?.filter(
        f =>
          f.wallet.toLocaleLowerCase() ===
          item?.user?.wallet.toLocaleLowerCase(),
      )?.length > 0
        ? true
        : false;

    // update the current friend list
    // add as friend
    if (!isAlreadyFriend) {
      //if not already friend
      await dispatch(
        addFriend({
          networkID: networkId,
          provider: staticProvider,
          address: address,
          wallet: wallet,
          followUserAddress: item?.user?.wallet,
          isMutual: true,
          newUser: item?.user,
        }),
      );
    }

    const response = await dispatch(
      deletePrivateMsgRequestToFriend({
        networkID: networkId,
        address: address,
        wallet: wallet,
        messageId: item.messageId,
        requestAccepted: true,
        publicKey: friendPublicKey,
        decryptMessage: decryptMessage,
      }),
    );

    if (response && response.payload.status && response.payload.status == 200) {
      await dispatch(
        updateFriendRequest({
          wallet: item?.user?.wallet,
          isRequestSend: true,
          isCancelled: false,
          messageId: item.messageId,
          timestamp: item.timestamp,
          type: 'update',
        }),
      );

      onPopUser(item?.user?.wallet);
      setIsMutualFriend(true);
    } else {
      showToastMessage(
        ToastCustomMessageType.INFO,
        'Unable to accept request. Please try again later!',
      );
    }
    setIsLoading(false);
  };

  const cancelRequest = async item => {
    setIsLoading(true);
    const friendPublicKey = await getUserPublicKey(item?.user?.wallet);
    const response = await dispatch(
      deletePrivateMsgRequestToFriend({
        networkID: networkId,
        address: address,
        wallet: wallet,
        messageId: item.messageId,
        requestAccepted: false,
        publicKey: friendPublicKey,
        decryptMessage: decryptMessage,
      }),
    );

    if (response && response.payload.status && response.payload.status == 200) {
      await dispatch(
        updateFriendRequest({
          wallet: item?.user?.wallet,
          isRequestSend: true,
          isCancelled: true,
          messageId: item.messageId,
          timestamp: item.timestamp,
          type: 'cancel',
        }),
      );
      setIsResponded(false);
      onPopUser(item?.user?.wallet);
      await dispatch(popPendingRequest(item?.messageId));
    } else {
      showToastMessage(
        ToastCustomMessageType.INFO,
        'Unable to cancel request. Please try again later!',
      );
    }
    setIsLoading(false);
  };

  const onFriendSelect = item => {
    onSelectedFriend(item);
  };
  return (
    <View style={componentStyles.container}>
      <View style={componentStyles.row}>
        <View>
          <ProfileAvatar
            size={50}
            source={
              item?.user?.avatarURI &&
              item?.user?.avatarURI != '' &&
              item?.user?.avatarURI != 'no-avatar' &&
              !item?.user?.avatarURI.includes('ipfs://') &&
              !item?.user?.avatarURI.includes('assets_avatars_p_') &&
              !item?.user?.avatarURI.includes('file:///')
                ? { uri: item?.user?.avatarURI }
                : null
            }
            userAddress={item?.user?.wallet}
          />
          {isMutualFriend && (
            <Image
              source={LinkVariantIcon}
              style={componentStyles.floatingIcon}
              resizeMode="contain"
            />
          )}
        </View>
        <View style={componentStyles.rightContainer}>
          <View style={componentStyles.row}>
            <Text style={componentStyles.userTitle}>
              {item?.user?.name}
              <Text style={{ color: defaultTheme.SECONDARY_TEXT_COLOR }}>
                {` ${getTimeTextFromTimestamp(item?.data?.senderTimestamp)}`}
              </Text>
            </Text>
            <View style={componentStyles.row}>
              {isLoading ? (
                <ActivityIndicator
                  size="small"
                  color={defaultTheme.PRIMARY_TEXT_COLOR}
                  style={{ marginRight: 20 }}
                />
              ) : isResponded == null ? (
                <>
                  <GradientButton
                    disabled={false}
                    width={isResponsive ? 100 : 75}
                    height={35}
                    type="secondary"
                    buttonLabel={'Delete'}
                    onPress={() => cancelRequest(item)}
                    customStyle={{ marginRight: 8 }}
                  />
                  <GradientButton
                    disabled={false}
                    width={isResponsive ? 100 : 75}
                    height={35}
                    buttonLabel={'Confirm'}
                    onPress={() => acceptRequest(item)}
                  />
                </>
              ) : isResponded ? (
                <GradientButton
                  disabled={false}
                  width={isResponsive ? 100 : 75}
                  height={35}
                  buttonLabel={'Message'}
                  onPress={() => onFriendSelect(item?.user)}
                />
              ) : null}
            </View>
          </View>

          <Pressable
            onPress={toggleExpansion}
            disabled={numOfLines > NUM_OF_LINES ? false : true}
            style={componentStyles.messageBubble}>
            <Text
              numberOfLines={
                numOfLines == 0 ? null : expanded ? numOfLines : NUM_OF_LINES
              }
              style={componentStyles.messageText}
              onTextLayout={handleTextLayout}
              ref={textRef}>
              {item?.data?.data?.content?.decryptedText}{' '}
              {numOfLines > NUM_OF_LINES && !expanded && (
                <Text
                  style={{
                    color: '#F4B540',
                    textDecorationLine: 'underline',
                  }}>
                  Read More
                </Text>
              )}
            </Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default FriendReqCard;

const componentStyles = StyleSheet.create({
  container: {
    flex: 1,
  },
  messageBubble: {
    backgroundColor: defaultTheme.CARD_BG_COLOR,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    padding: 10,
    marginTop: 5,
  },
  messageText: {
    color: defaultTheme.SECONDARY_TEXT_COLOR,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
  },
  userTitle: {
    flex: 1,
    fontSize: defaultTheme.FONT_SIZE_XXXMEDIUM,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightContainer: { flex: 1, marginLeft: 10 },
  floatingIcon: {
    width: 18,
    height: 18,
    position: 'absolute',
    right: -5,
    bottom: -2.5,
  },
});
