import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { createSelector } from 'reselect';
import { RootState } from '../store';

import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import {
  IPassportSubscribeAsyncThunk,
  IPassportUnsubscribeAsyncThunk,
} from './interfaces';
import {
  pushPassportDetails,
  removePassportDetails,
  subscribePassport,
  unsubscribePassport,
} from './PassportSlice';
import { getCollectibleDetails } from './OfferSlice';

export interface ICreatorsSliceData {
  readonly AllCreatorsDetailsList: ICollectibleDetail[]; //list of all creators details without any filter
  readonly AllCreatorsDetailsSearchList: ICollectibleDetail[]; //list of all creators details without any filter for searching purpose
}

// * Follow a Creator
// ? Subscribe to Free (Profile) Expass of a Creator
export const followCreator = createAsyncThunk(
  'creator/followCreator',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      passportAddress,
      passportSubscribePrice,
      userInfo,
      collectionType,
    }: IPassportSubscribeAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    // * Subscribe Creator Passport
    // ? Using the subscribePassport function from Passport Slice to keep both states in sync and re-use the code
    const passportTokenId = await dispatch(
      subscribePassport({
        networkID,
        provider,
        wallet,
        address,
        passportAddress,
        passportSubscribePrice,
        userInfo,
        collectionType,
      }),
    );

    if (passportTokenId.payload !== '') {
      // The state update logic
      const state = getState() as RootState;
      const entityData = state.Entity.EntityData;

      let passport = await getCollectibleDetails(
        {
          networkID,
          provider,
          collectibleAddress: passportAddress,
          address,
          wallet,
        },
        { entityData },
        {
          isCache: true,
          isBalanceRefresh: false,
          isMarketPlaceRefresh: true,
        },
      );
      if (passport != null && passport.dataURI != '') {
        dispatch(pushCreatorsDetails(passport));
        dispatch(removePassportDetails(passportAddress));
      }
      return passportTokenId;
    } else {
      return null;
    }
  },
);

// * Un-Follow a Creator
// ? UnSubscribe to the Free (Profile) Expass of a Creator
export const unfollowCreator = createAsyncThunk(
  'creator/unfollowCreator',
  async (
    {
      networkID,
      provider,
      wallet,
      address,
      passportAddress,
      passportIds,
      userInfo,
      collectionType,
    }: IPassportUnsubscribeAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;

    // * Unsubscribe Creator Passport
    // ? Using the unsubscribePassport function from Passport Slice to keep both states in sync and re-use the code
    const txReceipt = await dispatch(
      unsubscribePassport({
        networkID,
        provider,
        wallet,
        address,
        passportAddress,
        passportIds,
        userInfo,
        collectionType,
      }),
    );

    let passport = await getCollectibleDetails(
      {
        networkID,
        provider,
        collectibleAddress: passportAddress,
        address,
        wallet,
      },
      { entityData },
      {
        isCache: true,
        isBalanceRefresh: false,
        isMarketPlaceRefresh: true,
      },
    );

    // * Check for Event Logs emitted by the contract
    // * If event logs are present, then the transaction is successful
    if (txReceipt.payload.eventLogs.length > 0) {
      dispatch(removeCreatorDetails(passportAddress));
      if (passport != null && passport.dataURI != '') {
        dispatch(pushPassportDetails(passport));
      }

      return txReceipt;
    } else {
      return null;
    }
  },
);

export const searchCreator = createAsyncThunk(
  'creator/searchCreator',
  async ({ searchText }: { searchText }, { getState, dispatch }) => {
    const state = getState() as RootState;
    let allCreatorsSearchList = [
      ...state.Creators.AllCreatorsDetailsSearchList,
    ];
    let AllCreatorsDetailsList = [...state.Creators.AllCreatorsDetailsList];

    if (searchText && searchText.length > 0) {
      let searchTextLower = searchText.toLowerCase().trim();

      if (searchText.length === 1) {
        allCreatorsSearchList = AllCreatorsDetailsList.filter(
          p => p.name.toLowerCase().startsWith(searchTextLower) === true,
        );
      } else {
        allCreatorsSearchList = AllCreatorsDetailsList.filter(
          p => p.name.toLowerCase().indexOf(searchTextLower) > -1,
        );
      }
    } else {
      allCreatorsSearchList = AllCreatorsDetailsList;
    }

    return {
      allCreatorsDetailsSearchList: allCreatorsSearchList,
    };
  },
);

const initialState: ICreatorsSliceData = {
  AllCreatorsDetailsList: [],
  AllCreatorsDetailsSearchList: [],
};

const CreatorsSlice = createSlice({
  name: 'CreatorsDetails',
  initialState,
  reducers: {
    pushCreatorsDetails(state, action) {
      state.AllCreatorsDetailsList = state.AllCreatorsDetailsList.filter(x => {
        return (
          x.address?.toLowerCase() != action.payload.address?.toLowerCase()
        );
      });
      state.AllCreatorsDetailsList.push(action.payload);
      state.AllCreatorsDetailsSearchList = state.AllCreatorsDetailsList;
    },
    removeCreatorDetails(state, action) {
      state.AllCreatorsDetailsList = state.AllCreatorsDetailsList.filter(x => {
        return x.address?.toLowerCase() != action.payload?.toLowerCase();
      });
      state.AllCreatorsDetailsSearchList = state.AllCreatorsDetailsList;
    },
  },
  extraReducers: builder => {
    builder.addCase(searchCreator.fulfilled, (state, action) => {
      state.AllCreatorsDetailsSearchList =
        action.payload.allCreatorsDetailsSearchList;
    });
  },
});

export const CreatorsSliceReducer = CreatorsSlice.reducer;

const baseInfo = (state: RootState) => state.Creators;

export const { pushCreatorsDetails, removeCreatorDetails } =
  CreatorsSlice.actions;

export const getCreatorsState = createSelector(
  baseInfo,
  CreatorsSlice => CreatorsSlice,
);
