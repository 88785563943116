import React, { useEffect, useState } from 'react';
import {
  FlatList,
  Image,
  Platform,
  Pressable,
  useWindowDimensions,
  View,
  StyleSheet,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
  Button,
  Text,
  TextInput,
  DefaultTheme,
  Avatar,
} from 'react-native-paper';
import { useDispatch } from 'react-redux';
import Toast from 'react-native-root-toast';
import Addpfpicon from '../assets/svg/addPFPIconNew.png';
import ImagePickerComponent from '../components/ImagePickerComponent';
import InfoLoader from '../components/Loader/InfoLoader';
import ModalComponent from '../components/Modal';
import { ModalDisconnected } from '../components/Modal/DisconnectedModal';
import { getBase64Image } from '../helpers/ImageHelper';
import { wait } from '../helpers/ipfs';
import { useAppSelector } from '../hooks';
import { useWeb3AuthContext } from '../hooks/web3authContext';
import { IAvatarList } from '../slices/AppSlice';
import {
  CreateUserDetail,
  setUserAvatar,
  setUserName,
  setUserStatus,
} from '../slices/AppUserSlice';
import { updateNameAvatarAllMessages } from '../slices/PassportMessageSlice';
import { AppDispatch } from '../store';
import styles from '../styles';
import { defaultTheme } from '../themes/loot8';
import ProfileAvatar from '../components/ProfileAvatar';
import HOCContainer from '../components/HOCContainer';
import { EditIconSvg } from '../assets/svg/ProfileSvgs';
import GradientButton from '../components/GradientButton';

const customTheme = {
  ...DefaultTheme,
  roundness: 24,
};

function ProfileScreen({ navigation }) {
  const STATUS_MAX_LENGTH = 70;
  const NAME_MIN_LENGTH = 3;
  const NAME_MAX_LENGTH = 35;

  const getTruncatedName = name => {
    return name.length > NAME_MAX_LENGTH
      ? name.substring(0, NAME_MAX_LENGTH)
      : name;
  };

  const dispatch = useDispatch<AppDispatch>();
  const {
    networkId,
    staticProvider,
    address,
    userInfo,
    wallet,
    disconnect,
    decryptMessage,
  } = useWeb3AuthContext();
  const userData = useAppSelector(state => state.AppUser.UserData);
  const [nameText, setUserNameText] = useState(
    userData?.isExist
      ? getTruncatedName(userData?.name)
      : getTruncatedName(userInfo.name),
  );
  const userName = userData
    ? userData.name + ' #' + userData.id
    : userInfo.name;
  const [statusText, setUserStatusText] = useState(
    userData?.isExist ? userData?.status : '',
  );
  const loading = useAppSelector(state => state.App.msgLoading);
  const loadingUser = useAppSelector(state => state.AppUser.loading);
  const isNewUser = useAppSelector(state => state.AppUser.isNewUser);
  const [signupLoader, setSignupLoader] = useState(false);
  const currenLocation = useAppSelector(
    state => state.Location.currentLocation,
  );
  const avatarData = useAppSelector(state => state.App.avatarData);

  const [countInputTextLength, setCountInputTextLength] = useState(
    userData?.status ? userData?.status.length : 0,
  );
  const [isNameEmpty, setIsNameEmpty] = useState(
    nameText?.length < NAME_MIN_LENGTH,
  );
  const [showModal, setShowModal] = useState(false);
  const [avatarUri, setSelectedImage] = useState(
    userData?.isExist ? userData?.avatarURI : userInfo.profileImage,
  );
  const [avatarSelected, setAvatarSelected] = useState(false);
  const [avatarUriAction, setSelectImageAction] = useState(true);
  const [createUserFailure, setCreateUserFailure] = useState(false);
  const heightOffset = Platform.OS === 'ios' ? 120 : 70;
  const [countNameTextLength, setCountNameTextLength] = useState(
    userData?.isExist
      ? userData?.name
        ? userData?.name.length
        : 0
      : userInfo?.name
        ? getTruncatedName(userInfo?.name)?.length
        : 0,
  );
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const data: IAvatarList[] =
    avatarData && avatarData.data ? avatarData.data : [];

  const [iconName, setIconName] = useState(
    data.length > 0
      ? data[0].base64Data
      : require('../assets/loot8-default-avatar.png'),
  );
  const [isSelected, setIsSelected] = useState(0);
  const { width } = useWindowDimensions();
  const [selection, setSelection] = useState(
    Platform.OS === 'android' ? { start: 0 } : null,
  );
  const [nameTextInputFocused, setNameTextInputFocused] = useState(false);
  const [statusTextInputFocused, setStatusTextInputFocused] = useState(false);

  const onSave = async () => {
    if (userData?.isExist) {
      if (userData?.status !== statusText) {
        await dispatch(
          setUserStatus({
            networkID: networkId,
            provider: staticProvider,
            address: address,
            wallet: wallet,
            newStatus: statusText,
          }),
        );
        setStatusTextInputFocused(false);
      }
      if (userData?.name !== nameText) {
        await dispatch(
          setUserName({
            networkID: networkId,
            provider: staticProvider,
            address: address,
            wallet: wallet,
            newUserName: nameText,
          }),
        );
        setNameTextInputFocused(false);
      }
      if (userData?.avatarURI !== avatarUri) {
        await dispatch(
          setUserAvatar({
            networkID: networkId,
            provider: staticProvider,
            address: address,
            wallet: wallet,
            newAvatarUri: avatarUri,
          }),
        );
      }
      if (userData?.avatarURI !== avatarUri || userData?.name !== nameText) {
        dispatch(
          updateNameAvatarAllMessages({
            currentUserAddress: address,
            userName: nameText,
            avatarURI: avatarUri,
          }),
        );
      }
      setNameTextInputFocused(false);
      setStatusTextInputFocused(false);
      // Toast
      Toast.show('Profile updated successfully', {
        duration: Toast.durations.LONG,
        position: Toast.positions.BOTTOM,
      });
    } else {
      setSignupLoader(true);
      await dispatch(
        CreateUserDetail({
          networkID: networkId,
          provider: staticProvider,
          address,
          userInfo,
          wallet,
          userName: nameText,
          userStatus: statusText,
          userAvatarURI: avatarUri,
          userLocation: currenLocation,
          decryptMessage,
        }),
      );
      setSignupLoader(false);
    }
    setAvatarSelected(false);
  };
  const onCancel = async () => {
    setAvatarSelected(false);
    if (userData?.isExist) {
      setUserNameText(userData?.name);
      setUserStatusText(userData?.status);
    } else {
      setSignupLoader(true);
      await dispatch(
        CreateUserDetail({
          networkID: networkId,
          provider: staticProvider,
          address,
          userInfo,
          wallet,
          userName: getTruncatedName(userInfo.name),
          userStatus: '',
          userAvatarURI: userInfo?.profileImage,
          decryptMessage,
        }),
      );
      // showError();
      setSignupLoader(false);
    }
  };

  useEffect(() => {
    const showError = async () => {
      if (isNewUser && !userData?.isExist) {
        await wait(100);
        setShowModal(true);
        setCreateUserFailure(true);
      }
    };
    showError();
  }, [isNewUser]);

  const onAvatarButtonClick = () => {
    setShowModal(false);
    setTimeout(() => {
      setShowAvatarModal(true);
    }, 500);
  };

  useEffect(() => {
    setNumColumns(calcNumColumns(width));
  }, [width]);

  const minCols = 3;

  const calcNumColumns = width => {
    width = width > 600 ? 600 : width;
    const cols = width / 100;
    const colsFloor = Math.floor(cols) > minCols ? Math.floor(cols) : minCols;
    const colsMinusMargin = cols - 2 * colsFloor * 10;
    if (colsMinusMargin < colsFloor && colsFloor > minCols) {
      return colsFloor - 1;
    } else return colsFloor;
  };

  const [numColumns, setNumColumns] = useState(calcNumColumns(width));

  const setSelectedAvatar = async () => {
    let resizedImage = await getBase64Image(iconName);
    if (resizedImage) {
      setSelectedImage('data:image/jpeg;base64,' + resizedImage.base64);
      setSelectImageAction(false);
      setShowAvatarModal(false);
    }
  };

  const onCloseAvatarModal = () => {
    setShowAvatarModal(false);
    setSelectImageAction(false);
    setTimeout(() => {
      setShowModal(true);
    }, 500);
  };

  return (
    <>
      <View
        style={{ height: '100%', width: '100%', overflow: 'hidden', flex: 1 }}>
        <KeyboardAwareScrollView
          style={{}}
          resetScrollToCoords={{ x: 0, y: 0 }}
          scrollsToTop={true}
          enableOnAndroid={true}
          showsVerticalScrollIndicator={false}
          extraHeight={130}
          keyboardShouldPersistTaps="handled">
          <View style={styles.mainContainer}>
            <View
              style={[
                styles.profileCatContentContainer,
                styles.maxWidthAdjust,
              ]}>
              <View
                style={{
                  width: '100%',
                  alignItems: 'center',
                  alignContent: 'stretch',
                }}>
                {/* Profile Container  */}
                <View style={[styles.profileAvatarContainer]}>
                  <ProfileAvatar
                    style={styles.profileAvatar}
                    size={112}
                    source={
                      avatarUri == '' ||
                      avatarUri == 'no-avatar' ||
                      (avatarUri &&
                        (avatarUri.includes('ipfs://') ||
                          avatarUri.includes('assets_avatars_p_') ||
                          avatarUri.includes('file:///')))
                        ? null
                        : { uri: avatarUri }
                    }
                    tickStyle={{ right: 30, top: -1, height: 24, width: 24 }}
                    userAddress={address}
                  />
                </View>
                <View style={[styles.profileEditIconContainer]}>
                  <Button
                    onPress={() => {
                      setShowModal(true);
                      setSelectImageAction(true);
                    }}
                    icon={() =>
                      userData?.isExist ? (
                        <EditIconSvg />
                      ) : (
                        <Image
                          source={Addpfpicon}
                          style={{
                            width: userData?.isExist ? 24 : 34,
                            height: userData?.isExist ? 24 : 34,
                          }}
                          resizeMode="contain"
                        />
                      )
                    }
                    children={''}></Button>
                </View>
                <Text
                  style={{
                    fontSize: 18,
                    lineHeight: 18,
                    fontFamily: defaultTheme.FONT_FAMILY_BOLD,
                    color: '#FFFFFF',
                  }}>
                  {userName}
                </Text>

                <View
                  style={{
                    width: '100%',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    marginTop: 32,
                  }}>
                  <View style={{ width: '100%', justifyContent: 'flex-start' }}>
                    <Text style={componentStyles.label}>Name</Text>
                    <View style={{ flexDirection: 'row', columnGap: 4 }}>
                      <TextInput
                        theme={customTheme}
                        style={[componentStyles.inputContainer]}
                        contentStyle={{ padding: 0 }}
                        placeholder="Enter a Name"
                        placeholderTextColor="rgba(219, 226, 237,0.35)"
                        value={nameText}
                        autoCorrect={false}
                        mode="outlined"
                        outlineColor="rgba(219, 226, 237,0.35)"
                        activeOutlineColor="rgb(219, 226, 237)"
                        textColor="rgb(219, 226, 237)"
                        cursorColor="rgb(219, 226, 237)"
                        right={
                          !nameTextInputFocused ? (
                            <TextInput.Icon
                              icon="square-edit-outline"
                              size={20}
                              iconColor="rgb(219, 226, 237)"
                              onPress={() => {
                                setNameTextInputFocused(true);
                                setStatusTextInputFocused(false);
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                        disabled={!nameTextInputFocused}
                        onChangeText={text => {
                          if (
                            text.length === 0 ||
                            (text.trim().length > 0 &&
                              text.trim().length < NAME_MIN_LENGTH)
                          ) {
                            setUserNameText(text.trim());
                            setIsNameEmpty(true);
                            setCountNameTextLength(text.trim().length);
                          } else if (
                            text &&
                            text.length >= NAME_MIN_LENGTH &&
                            text.length <= NAME_MAX_LENGTH &&
                            text.trim().length > 0
                          ) {
                            setUserNameText(text);
                            setIsNameEmpty(false);
                            setCountNameTextLength(text.length);
                          }
                        }}
                        returnKeyType="done"
                      />
                      {nameTextInputFocused && (
                        <View
                          style={{
                            flex: 1,
                            flexDirection: 'row',
                            columnGap: 4,
                          }}>
                          <GradientButton
                            buttonLabel="Cancel"
                            customStyle={{ flex: 1 }}
                            type="secondary"
                            onPress={() => setNameTextInputFocused(false)}
                          />
                          <GradientButton
                            buttonLabel="Save"
                            customStyle={{ flex: 1 }}
                            onPress={() => onSave()}
                          />
                        </View>
                      )}
                    </View>
                  </View>
                  <View style={{ width: '100%', justifyContent: 'flex-start' }}>
                    <Text style={componentStyles.label}>Tagline</Text>
                    <View style={{ flexDirection: 'row', columnGap: 4 }}>
                      <TextInput
                        theme={customTheme}
                        style={componentStyles.inputContainer}
                        contentStyle={{ padding: 0 }}
                        placeholder="Set Tagline"
                        placeholderTextColor="rgba(219, 226, 237,0.35)"
                        value={statusText}
                        onChangeText={text => {
                          if (text && text.length <= STATUS_MAX_LENGTH) {
                            setUserStatusText(text);
                            setCountInputTextLength(text.length);
                          } else if (text.length === 0) {
                            setUserStatusText(text);
                            setCountInputTextLength(text.length);
                          }
                        }}
                        selection={selection}
                        autoCorrect={false}
                        mode="outlined"
                        outlineColor="rgba(219, 226, 237,0.35)"
                        activeOutlineColor="rgb(219, 226, 237)"
                        textColor="rgb(219, 226, 237)"
                        cursorColor="rgb(219, 226, 237)"
                        disabled={!statusTextInputFocused}
                        right={
                          !statusTextInputFocused ? (
                            <TextInput.Icon
                              icon="square-edit-outline"
                              size={20}
                              iconColor="rgb(219, 226, 237)"
                              onPress={() => {
                                setStatusTextInputFocused(true);
                                setNameTextInputFocused(false);
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                        returnKeyType="done"
                        onFocus={() => {
                          if (Platform.OS === 'android') {
                            setSelection(null);
                          }
                        }}
                        onBlur={() => {
                          if (Platform.OS === 'android') {
                            setSelection({ start: 0 });
                          }
                        }}
                      />
                      {statusTextInputFocused && (
                        <View
                          style={{
                            flex: 1,
                            flexDirection: 'row',
                            columnGap: 4,
                          }}>
                          <GradientButton
                            buttonLabel="Cancel"
                            customStyle={{ flex: 1 }}
                            type="secondary"
                            onPress={() => setStatusTextInputFocused(false)}
                          />
                          <GradientButton
                            buttonLabel="Save"
                            customStyle={{ flex: 1 }}
                            onPress={() => onSave()}
                          />
                        </View>
                      )}
                    </View>
                  </View>
                </View>
                {avatarSelected && (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      columnGap: 4,
                      width: '100%',
                      justifyContent: 'center',
                      marginTop: 24,
                    }}>
                    <GradientButton
                      customStyle={{ flex: 1 }}
                      buttonLabel="Cancel"
                      type="secondary"
                      onPress={() => onCancel()}
                    />
                    <GradientButton
                      customStyle={{ flex: 1 }}
                      buttonLabel="Save"
                      onPress={() => onSave()}
                    />
                  </View>
                )}
                {!userData?.isExist && (
                  <View
                    style={{ marginVertical: 15, justifyContent: 'center' }}>
                    <Text
                      style={styles.deleteAccountBtnText}
                      onPress={() => disconnect()}>
                      Back to Login
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>
          {showModal && avatarUriAction && !createUserFailure ? (
            <ModalComponent
              showModal={showModal}
              enableHeader={false}
              needCloseButton={true}
              modalStyle={{ bottom: 0, justifyContent: 'flex-end' }}
              modalBodyStyle={{ alignSelf: 'center' }}
              onDismiss={() => {
                setShowModal(false);
                setSelectImageAction(true);
              }}>
              <View
                style={{
                  padding: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 4,
                }}>
                <Text style={styles.imagePickerHeaderText}>
                  Change Profile Photo
                </Text>
                <ImagePickerComponent
                  onAvatarButtonClick={onAvatarButtonClick}
                  setSelectedImage={setSelectedImage}
                  setSelectImageAction={setSelectImageAction}
                />
              </View>
            </ModalComponent>
          ) : (
            <></>
          )}
        </KeyboardAwareScrollView>
      </View>

      {signupLoader ? (
        !userData?.isExist ? (
          <InfoLoader
            text1={'Please hang on!'}
            text2={'Setting up your account...'}
          />
        ) : (
          <></>
        )
      ) : (
        (loading || loadingUser) && (
          <InfoLoader text1={'Updating Profile Info'} />
        )
      )}
      {showModal && createUserFailure ? (
        <ModalComponent
          showModal={showModal}
          needCloseButton={true}
          enableHeader={false}
          onDismiss={() => {
            setCreateUserFailure(false);
            setShowModal(false);
          }}>
          <View style={{ padding: 30, alignContent: 'center' }}>
            <Text style={styles.modalTextStyle}>Something went wrong.</Text>
            <Text style={styles.modalTextStyle}>Please try again later.</Text>
          </View>
        </ModalComponent>
      ) : (
        <></>
      )}

      {showAvatarModal && (
        <ModalComponent
          showModal={showAvatarModal}
          enableHeader={false}
          needCloseButton={true}
          modalStyle={{
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          modalBodyStyle={{ marginVertical: 5 }}
          onDismiss={onCloseAvatarModal}>
          <View style={{ margin: 5 }}>
            <View>
              <Text style={styles.profileAvatarModalText}>
                Select an Avatar
              </Text>
            </View>
            <View style={[styles.profileAvatarModalContainer]}>
              <Avatar.Image
                style={[styles.profileAvatar]}
                size={160}
                source={{ uri: iconName }}
              />
            </View>
            <View
              style={{
                width: '60%',
                marginVertical: 20,
                alignSelf: 'center',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}>
              <GradientButton
                buttonLabel="Clear"
                type="secondary"
                width={100}
                onPress={() => {
                  setIconName(
                    data.length > 0
                      ? data[0].base64Data
                      : require('../assets/loot8-default-avatar.png'),
                  );
                  setIsSelected(0);
                }}
              />
              <GradientButton
                buttonLabel="Save"
                width={100}
                onPress={() => {
                  setSelectedAvatar();
                  setAvatarSelected(true);
                }}
              />
            </View>
            <View
              style={{
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 5,
              }}>
              <FlatList
                data={data}
                key={numColumns}
                showsVerticalScrollIndicator={false}
                renderItem={({ item, index }) => (
                  <Pressable
                    style={{
                      borderColor: index == isSelected ? 'none' : '#FFF',
                    }}
                    onPress={() => {
                      setIconName(item.base64Data);
                      setIsSelected(index);
                    }}>
                    <View
                      style={[
                        styles.profileAvatarContainerA,
                        {
                          borderColor:
                            index == isSelected
                              ? defaultTheme.SECONDARY_COLOR
                              : '#FFF',
                        },
                      ]}>
                      <Avatar.Image
                        style={[
                          styles.profileAvatarA,
                          {
                            backgroundColor:
                              index == isSelected
                                ? defaultTheme.SECONDARY_COLOR
                                : '#FFF',
                          },
                        ]}
                        size={59}
                        source={{ uri: item.base64Data }}
                      />
                    </View>
                  </Pressable>
                )}
                horizontal={false}
                keyboardShouldPersistTaps="handled"
                numColumns={numColumns}
                keyExtractor={(item, index) => index.toString()}
                contentContainerStyle={{}}
                style={{ maxHeight: 300 }}
              />
            </View>
          </View>
        </ModalComponent>
      )}
    </>
  );
}
const componentStyles = StyleSheet.create({
  label: {
    textAlign: 'left',
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: 'rgb(219, 226, 237)',
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    marginBottom: 5,
    marginTop: 15,
  },
  inputContainer: {
    backgroundColor: '#474053',
    width: '100%',
    height: 48,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    flex: 1,
  },
});

export default HOCContainer({
  OriginalComponent: ProfileScreen,
  title: 'Account Detail',
  withBottomTabs: true,
  isScrollEnabled: false,
});
