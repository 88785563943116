import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { useNetInfo } from '@react-native-community/netinfo';
import BarcodeReader from '../components/BarcodeReader';
import { defaultTheme } from '../themes/loot8';
import HOCContainer from '../components/HOCContainer';
import { Camera } from 'expo-camera';

const QRScreen = ({ navigation, route }) => {
  const [permission, requestCameraPermission] = Camera.useCameraPermissions();
  const [scanned, setScanned] = useState(false);
  const [cameraReady, setCameraReady] = useState(false);
  const [permissionResponse, requestBarcodePermission] =
    BarCodeScanner.usePermissions();
  const netInfo = useNetInfo();

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'web') {
        if (!permission && !permission?.granted) {
          requestCameraPermission();
        } else {
          setScanned(false);
        }
      } else {
        const { status } = await BarCodeScanner.getPermissionsAsync();
        if (status !== 'granted') {
          requestBarcodePermission();
        } else {
          setScanned(false);
        }
      }
      setCameraReady(Platform.OS === 'ios' || Platform.OS === 'android');
    })();
  }, []);

  if (!permission && Platform.OS === 'web') {
    // Camera permissions are still loading.
    return <View />;
  }

  return (
    netInfo.isConnected && (
      <View style={styles.container}>
        <BarcodeReader
          navigation={navigation}
          route={route}
          setScanned={setScanned}
          scanned={scanned}
          setCameraReady={setCameraReady}
          cameraReady={cameraReady}
          requestPermissions={
            Platform.OS === 'web'
              ? requestCameraPermission
              : requestBarcodePermission
          }
          permissionGranted={
            Platform.OS === 'web'
              ? permission?.granted
              : permissionResponse?.granted
          }
        />
      </View>
    )
  );
};

export default HOCContainer({
  OriginalComponent: QRScreen,
  withBottomTabs: false,
  isScrollEnabled: false,
  title: 'Scan QR Code',
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    paddingHorizontal: -15,
    overflow: 'hidden',
  },
});
