import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { createSelector } from 'reselect';
import {
  addresses,
  APP_CONFIGURATION_FILE,
  APP_STORAGE_COLLECTIBLE_LIST,
  APP_STORAGE_GET_COLLECTIBLEDETAILS,
  APP_STORAGE_GET_PASSPORTBALANCE,
  APP_STORAGE_PASSPORT_LIST,
  getAnynetStaticProvider,
  getNetwork,
  getStaticProvider,
  isNativeChain,
  MINTED_COLLECTION,
  NetworkId,
  ZERO_ADDRESS,
} from '../appconstants';
import { getData, removeData, storeData } from '../helpers/AppStorage';
import { getIPNSData, wait } from '../helpers/ipfs';
import { RootState } from '../store';
import { SendMetaTX } from './AppSlice';
import {
  getAllMintedCollectionForPatron,
  loadAllCatList,
  loadCatalogCollectiable,
  loadCatalogPassport,
  loadCatalogThirdPartyCollectiable,
} from './CatalogSlice';
import { isLocationAvailable, setAll } from './helpers';
import {
  IBaseAsyncThunk,
  IMessageMetaData,
  IPassportAsyncThunk,
  IPassportFactoryBaseAsyncThunk,
  IPassportOfferFactoryBaseAsyncThunk,
} from './interfaces';
import { NotificationType, pushNotification } from './NotificationSlice';

import { getCollectibleDetails } from './OfferSlice';
import { showToastMessage } from '../helpers/Gadgets';
import { LogCustomError } from '../helpers/AppLogger';
import {
  CollectionHelper__factory,
  CollectionManager__factory,
  Loot8Collection__factory,
  Loot8UniformCollection__factory,
} from '../typechain';
import {
  GetBlockNumberFromLogsData,
  GetCollectibleMintMessage,
  GetLinkedCollectibleMintMessage,
  GetPassportMintMessage,
} from '../helpers/MsgHelper';
import { ICollectibleDetail } from '../interfaces/ICollectibleDetail.interface';
import { CollectionType } from '../enums/collection.enum';
import { getAllPassport, mintPassport } from './PassportSlice';
import { getSyncedTime, isActiveTimeStamp } from '../helpers/DateHelper';
import Constants from 'expo-constants';
import { uniqWith } from 'lodash';
import { BigNumber } from 'ethers';
import { LogToLoot8Console } from '../helpers/Loot8ConsoleLogger';
import { OfferType } from '../enums/offers.enum';

// comment out whitelist digital collectible.
// https://neuralmetrics.atlassian.net/browse/LOOT8-665
//
// store whitelist digital collectible minting queue.
// let WhitelistMintingQueue = new Map();

// initilize collectible minting variable.
let collectibleMintingQueue = new Map();

export interface IDigitalCollectibleData {
  readonly loading: boolean;
  readonly isMintloading: boolean;
  readonly AllDigitalCollectibleList: ICollectibleDetail[]; // list of all digital collectible.
  readonly ActiveDigitalCollectibleList: ICollectibleDetail[]; // list of all active digital collectible.
}

export const getAllDigitalCollectibles = async (
  { networkID, provider }: { networkID; provider },
  isCache = true,
): Promise<{ source: string; chainId: number }[]> => {
  let availableCollectible = isCache
    ? await getData(APP_STORAGE_COLLECTIBLE_LIST)
    : null;
  if (availableCollectible) return availableCollectible;
  const collectionManager = CollectionManager__factory.connect(
    addresses[networkID].CollectionManager,
    provider,
  );
  availableCollectible = (
    await collectionManager.getAllCollectionsWithChainId(
      CollectionType.COLLECTION,
      true,
    )
  )
    .map(p => {
      if (p)
        return {
          source: p.source,
          chainId: BigNumber.from(p.chainId).toNumber(),
        };
    })
    .filter(p => p);
  await storeData(APP_STORAGE_COLLECTIBLE_LIST, availableCollectible);
  return availableCollectible;
};

export const checkUserBalance = async ({
  networkID,
  provider,
  collectionAddress,
  address,
}: {
  networkID;
  provider;
  collectionAddress;
  address;
}): Promise<any> => {
  const Loot8Collection = Loot8Collection__factory.connect(
    collectionAddress,
    provider,
  );
  const tokenMinted = Number(await Loot8Collection.balanceOf(address));
  if (tokenMinted) {
    return true;
  }
  return false;
};

export const loadAllDigitalCollectible = createAsyncThunk(
  'digitalCollectible/loadAllDigitalCollectible',
  async (
    {
      networkID,
      provider,
      entities,
      address,
      wallet,
      userInfo,
      userLocation,
      isCache = true,
    }: IPassportFactoryBaseAsyncThunk,
    { dispatch, getState },
  ): Promise<any> => {
    let digitalCollectibleAddressList: { source: string; chainId: number }[] =
      [];
    const state = getState() as RootState;
    const entityData = state.Entity.EntityData;
    await dispatch(clearDigitalCollectibleDetails());

    digitalCollectibleAddressList = await getAllDigitalCollectibles(
      { networkID, provider },
      isCache,
    );
    // LogToLoot8Console("digitalCollectibleAddressList",digitalCollectibleAddressList)
    //await dispatch(setPassportList(collectibleAddressLst));
    await Promise.all(
      digitalCollectibleAddressList
        .filter(
          collectibleAddress => collectibleAddress.source !== ZERO_ADDRESS,
        )
        .map(async collectibleAddress => {
          let providerToPass = provider;
          let chainId = collectibleAddress.chainId;
          if (!isNativeChain(chainId)) {
            providerToPass = getAnynetStaticProvider(chainId);
          }
          let collectible = await getCollectibleDetails(
            {
              networkID: chainId,
              provider: providerToPass,
              collectibleAddress: collectibleAddress.source,
              address,
              wallet,
            },
            { entityData },
            {
              isCache: true,
              isBalanceRefresh: false,
              isMarketPlaceRefresh: true,
            },
          );
          if (collectible != null && collectible.dataURI != '') {
            await dispatch(pushDigitalCollectibleDetails(collectible));
          }
        }),
    );
  },
);

export const mintLinkedCollectibles = createAsyncThunk(
  'digitalCollectible/mintLinkedCollectibles',
  async (
    {
      networkID,
      provider,
      passportAddress,
      address,
      wallet,
    }: IPassportAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    let data = GetLinkedCollectibleMintMessage(passportAddress, address);

    let msg: IMessageMetaData = {
      to: addresses[networkID].OrderDispatcher,
      wallet: wallet,
      data: data,
      networkID: networkID,
      provider: provider,
    };
    return await dispatch(SendMetaTX(msg));
  },
);

// export const loadAvailableDigitalCollectibleDetails = createAsyncThunk(
//   'digitalCollectible/loadAvailableDigitalCollectibleDetails',
//   async (
//     {
//       networkID,
//       provider,
//       entities,
//       address,
//       wallet,
//       userInfo,
//       userLocation,
//       isCache = true,
//       passportAddress = null,
//     }: IPassportFactoryBaseAsyncThunk,
//     { dispatch, getState },
//   ): Promise<any> => {
// let allDigitalCollectibleDetails: ICollectibleDetail[] = [];
// let mintableDigitalCollectible = [];
// LogToLoot8Console("loadAvailableDigitalCollectibleDetails userLocation", userLocation);
// await dispatch(clearActiveDigitalCollectibleDetails());
// const state = getState() as RootState;
// const entityData = state.Entity.EntityData;
// allDigitalCollectibleDetails =
//   state.DigitalCollectible.AllDigitalCollectibleList.filter(
//     item => item.offerType !== OfferType.FEATURED,
//   );
// const passportAddressList = await getAllPassport(
//   { networkID, provider },
//   true,
// );
// const allPatronPassports = await getAllMintedCollectionForPatron(
//   { networkID, provider, address, collectionType: CollectionType.PASSPORT },
//   isCache,
// );
// filter collectible which is attached with passport.
// if (passportAddress) {
// let allDigitalCollectibleDetailsTemp = allDigitalCollectibleDetails;
// allDigitalCollectibleDetails = [];
// allDigitalCollectibleDetailsTemp?.forEach(collectible => {
// const isPassportAttach = collectible?.linkCollectible?.filter(item => {
//   return passportAddress.toLowerCase().includes(item?.toLowerCase());
// });
// if (isPassportAttach?.length > 0) {
// allDigitalCollectibleDetails.push(collectible);
// }
// });
// }
// await Promise.all(
//   allDigitalCollectibleDetails?.map(async digitalCollectibleDetail => {
// try {
// let isOpenDigitalCollectible: boolean = true;
// let isPassportAttach: boolean = false;
// let isPassportMintedToPatron: boolean = false;
// if (
//   digitalCollectibleDetail?.area != null &&
//   digitalCollectibleDetail.area.length == 2 &&
//   digitalCollectibleDetail.area[1] > 0
// ) {
// check geo-fence if digital collectible is not minted.
// isOpenDigitalCollectible = false;
// if (userLocation != null) {
// if (
//   isLocationAvailable(userLocation, digitalCollectibleDetail.area)
// ) {
// isOpenDigitalCollectible = true;
// }
// }
// }
// if (isOpenDigitalCollectible && digitalCollectibleDetail.maxTokens) {
// let providerToPass = provider;
// if (!isNativeChain(digitalCollectibleDetail.chainId)) {
//   providerToPass = getAnynetStaticProvider(
//     digitalCollectibleDetail.chainId,
//   );
// }
// const digiCollectible = Loot8Collection__factory.connect(
//   digitalCollectibleDetail.address,
//   providerToPass,
// );
// const collectionCollectibleIds =
//   +(await digiCollectible.collectionCollectibleIds());
// LogToLoot8Console(
//   digitalCollectibleDetail.name,
//   collectionCollectibleIds,
// );
// if (collectionCollectibleIds > digitalCollectibleDetail.maxTokens) {
// isOpenDigitalCollectible = false;
// }
// }
// check collectible timestamp
// let isDigitialCollectibleActive = true;
// if (digitalCollectibleDetail.start && digitalCollectibleDetail.end) {
// if (
//   !isActiveTimeStamp(
//     digitalCollectibleDetail.start,
//     digitalCollectibleDetail.end,
//   )
// ) {
// isOpenDigitalCollectible = false;
// isDigitialCollectibleActive = false;
// }
// LogToLoot8Console("is collectible within timestamp?", isOpenDigitalCollectible)
// }
// add active digital collectible
// if (isDigitialCollectibleActive) {
//   await dispatch(
//     pushActiveDigitalCollectibleDetails(digitalCollectibleDetail),
//   );
// }
// check digital collectible to map with passport
// digitalCollectibleDetail.linkCollectible.map(async item => {
// if (
//   passportAddressList
//     ?.map(p => p.source?.toLowerCase())
//     .includes(item?.toLowerCase())
// ) {
// isPassportAttach = true;
// }
// });
// if (isPassportAttach) {
// check passport minted to patron.
// digitalCollectibleDetail.linkCollectible.map(async item => {
// if (
//   allPatronPassports
//     ?.map(p => p.source?.toLowerCase())
//     .includes(item?.toLowerCase())
// ) {
// isPassportMintedToPatron = true;
// }
// });
// }
// if passport and collectible is linked but passport is not minted
// then avoid to mint digital collectible
// if (isPassportAttach && !isPassportMintedToPatron) {
//   isOpenDigitalCollectible = false;
// }
// LogToLoot8Console("isPassportAttach",isPassportAttach);
// LogToLoot8Console("isPassportMintedToPatron",isPassportMintedToPatron);
// LogToLoot8Console("digitalCollectibleDetail",digitalCollectibleDetail)
// if (
//   digitalCollectibleDetail?.collectibleCount == 0 &&
//   isOpenDigitalCollectible
// ) {
// let providerToPass = provider;
// if (!isNativeChain(digitalCollectibleDetail.chainId)) {
//   providerToPass = getAnynetStaticProvider(
//     digitalCollectibleDetail.chainId,
//   );
// }
// digitalCollectibleDetail = await getCollectibleDetails(
//   {
//     networkID: digitalCollectibleDetail.chainId,
//     provider: providerToPass,
//     collectibleAddress: digitalCollectibleDetail.address,
//     address,
//     wallet,
//   },
//   { entityData },
//   {
//     isCache: true,
//     isBalanceRefresh: true,
//     isMarketPlaceRefresh: true,
//   },
// );
// if (
//   digitalCollectibleDetail?.collectibleCount == 0 &&
//   digitalCollectibleDetail?.isActive
// ) {
// mintableDigitalCollectible.push(
//   digitalCollectibleDetail?.address,
// );
// }
// }
// } catch (error) {
//   LogCustomError(
//     'loadAvailableDigitalCollectibleDetails',
//     error?.name,
//     error?.message,
//     error,
//   );
// }
// }),
// );
// if (mintableDigitalCollectible.length > 0) {
// LogToLoot8Console("mintableDigitalCollectible",mintableDigitalCollectible);
// let toBeMintedCollectible = allDigitalCollectibleDetails.filter(x =>
//   mintableDigitalCollectible.find(y => y == x?.address),
// );
// dispatch(
//   mintAvailableDigitalCollectible({
//     networkID,
//     provider,
//     address,
//     wallet,
//     toBeMintedCollectible,
//   }),
// ).then(x => {
//   dispatch(
//     loadCatalogCollectiable({
//       networkID,
//       provider,
//       address,
//       wallet,
//       userInfo,
//       isCache: false,
//     }),
//   );
// });
// }
//   },
// );

// export const mintCollectible = createAsyncThunk(
//   'digitalCollectible/mintCollectible',
//   async (
//     {
//       networkID,
//       provider,
//       passportAddress,
//       address,
//       wallet,
//     }: IPassportAsyncThunk,
//     { dispatch },
//   ): Promise<any> => {
//     let data = GetPassportMintMessage(address, passportAddress);

// if (!isNativeChain(networkID)) {
//   data = GetCollectibleMintMessage(address);
// }
// let msg: IMessageMetaData = {
//   to:
//   !isNativeChain(networkID)
//       ? passportAddress
//       : addresses[networkID].CollectionManager,
//   wallet: wallet,
//   data: data,
//   networkID: networkID,
//   provider: provider,
// };
// return await dispatch(SendMetaTX(msg));
//   },
// );

// comment out whitelist digital collectible.
// https://neuralmetrics.atlassian.net/browse/LOOT8-665
//
// call gasless transaction to mint whitelist digital collectible.
// export const mintDigitalCollectible = createAsyncThunk("digitalCollectible/mintDigitalCollectible", async ({ networkID, provider, address, wallet, collectionAddress }: any , { dispatch }): Promise<any> => {
//   // remove on new contract deployment
//   const data = GetCollectibleMintMessage(address);
//   let msg: IMessageMetaData = {
//     to: collectionAddress,
//     wallet: wallet,
//     data: data,
//     networkID: networkID,
//     provider: provider
//   }
//   LogToLoot8Console(msg);
//   return await dispatch(SendMetaTX(msg));
// });

// export const mintAvailableDigitalCollectible = createAsyncThunk(
//   'digitalCollectible/mintAvailableDigitalCollectible',
//   async (
//     { networkID, provider, address, wallet, toBeMintedCollectible }: any,
//     { dispatch, getState },
//   ): Promise<any> => {
// LogToLoot8Console('calling digital collectible mint');
// const state = getState() as RootState;
// const entityData = state.Entity.EntityData;
// for (let i = 0; i < toBeMintedCollectible.length; i++) {
// let collectibleDetail: ICollectibleDetail = toBeMintedCollectible[i];
// // clear cache
// await storeData(
//   APP_STORAGE_GET_COLLECTIBLEDETAILS(collectibleDetail?.address),
//   null,
// );
// try {
// const arbChainId = getNetwork();
// const arbProvider = getStaticProvider();
// const collectionManager = CollectionManager__factory.connect(
//   addresses[arbChainId].CollectionManager,
//   arbProvider,
// );
// let passedPreMint = true;
// if (!isNativeChain(arbChainId)) {
//   passedPreMint = await collectionManager.passedPreMintingChecks(
//     address,
//     collectibleDetail.address,
//   );
// }
// if (passedPreMint) {
// check minting queue
// let isCollectibleMintable = true;
// if (collectibleMintingQueue.has(collectibleDetail.address)) {
//   // set flag if collectible already in minting queue.
//   isCollectibleMintable = false;
// } else {
//   collectibleMintingQueue.set(collectibleDetail.address, true);
// }
// if (isCollectibleMintable) {
// let providerToPass = provider;
// if (!isNativeChain(collectibleDetail.chainId)) {
//   providerToPass = getAnynetStaticProvider(
//     collectibleDetail.chainId,
//   );
// }
// const res = await dispatch(
//   mintCollectible({
//     networkID: collectibleDetail.chainId,
//     provider: providerToPass,
//     address,
//     passportAddress: collectibleDetail.address,
//     wallet,
//   }),
// );
// await wait(1000);
// collectibleDetail = toBeMintedCollectible[i];
// let id = null;
// if (
//   res &&
//   res.payload &&
//   res.payload.payload?.eventLogs &&
//   res.payload.payload?.eventLogs[0]
// ) {
//   id = res.payload.payload?.eventLogs[0].transactionHash;
// }
// let collectible = await getCollectibleDetails(
//   {
//     networkID: collectibleDetail.chainId,
//     provider: providerToPass,
//     collectibleAddress: collectibleDetail?.address,
//     address,
//     wallet,
//   },
//   { entityData },
//   {
//     isCache: true,
//     isBalanceRefresh: true,
//     isMarketPlaceRefresh: true,
//   },
// );
// LogToLoot8Console("collectible",collectible)
// if (collectible && collectible?.collectibleCount > 0 && id) {
// push minted collection to local storage.
// try {
//   let blockNumber = GetBlockNumberFromLogsData(
//     res?.payload?.payload?.eventLogs,
//   );
//   if (blockNumber) {
//     await storeData(MINTED_COLLECTION(collectible?.address), {
//       address: collectible?.address,
//       blockNumber: blockNumber,
//       collectionType: collectible?.collectionType,
//       chainId: collectible?.chainId,
//     });
//   }
// } catch (e) {}
//push notification
//     dispatch(
//       pushNotification({
//         subject: 'Congrats! You just received a new collectible!',
//         body:
//           collectibleDetail?.name +
//           ' has been added to your portfolio',
//         //uri: friend.avatarURI,
//         timeStamp: Number.parseInt(getSyncedTime().toString()),
//         blockNumber: await provider.getBlockNumber(),
//         id,
//         notificationType: NotificationType.CollectibleMint,
//         dataObject: collectibleDetail,
//       }),
//     );
//   }
//   // update collectible minting queue to set current collectible to false.
//   // so it will be pulled to minting queue again in case of failure.
//   collectibleMintingQueue.set(collectibleDetail.address, false);
// }
// }
// } catch (error) {
//   LogCustomError(
//     'mintAvailableDigitalCollectible',
//     address,
//     'Failed on mintAvailableDigitalCollectible',
//     error,
//   );
// }
// }
//   },
// );

// comment out whitelist collectible
// https://neuralmetrics.atlassian.net/browse/LOOT8-665
//
// mint whitelist digital collectible and trigger notification once minting is done.
// export const mintWhiteListDigitalCollectible = createAsyncThunk("digitalCollectible/mintWhiteListDigitalCollectible", async ({ networkID, provider, address, wallet, toBeMintedCollectible }: any, { dispatch, getState }): Promise<any> => {
//   LogToLoot8Console("calling whitelist digital collectible mint");
//   const state = getState() as RootState;
//   const entityData = state.Entity.EntityData;
//   for (let i = 0; i < toBeMintedCollectible.length; i++) {

//     // check minting queue
//     let isCollectibleMintable = true;
//     if(WhitelistMintingQueue.has(toBeMintedCollectible[i].address)) {
//       isCollectibleMintable = false;
//     } else {
//       WhitelistMintingQueue.set(toBeMintedCollectible[i].address, true);
//     }
//     LogToLoot8Console("is collectible in queue?",toBeMintedCollectible[i].address, isCollectibleMintable)

//     // check in case token is minted
//     const tokenMinted = await checkUserBalance({ networkID: toBeMintedCollectible[i].chainId, provider: getAnynetStaticProvider(toBeMintedCollectible[i].chainId), collectionAddress: toBeMintedCollectible[i].address, address })
//     if(!tokenMinted && isCollectibleMintable) {

//       const res = await dispatch(mintDigitalCollectible({ networkID: toBeMintedCollectible[i].chainId, provider: getAnynetStaticProvider(toBeMintedCollectible[i].chainId), address, collectionAddress: toBeMintedCollectible[i].address , wallet }));
//       await wait(1200);
//       let id = null;
//       if (res && res.payload && res.payload.payload?.eventLogs && res.payload.payload?.eventLogs[0]) {
//         id = res.payload.payload?.eventLogs[0].transactionHash

//         let collectibleDetail = await getThirdPartyCollectiableDetails({ chainId: toBeMintedCollectible[i].chainId, provider, collectiableAddress: toBeMintedCollectible[i].address, address, entityAddress: null }, false);
//         if (collectibleDetail && collectibleDetail.length > 0 && collectibleDetail[0].name && collectibleDetail[0].name != '') {
//           //push notification
//           dispatch(pushNotification({
//             subject: 'Congrats! You just received a new collectible!',
//             body: collectibleDetail[0]?.name + ' has been added to your portfolio',
//             timeStamp: Number.parseInt(((new Date().getTime()) / 1000).toString()),
//             blockNumber: await provider.getBlockNumber(),
//             id: collectibleDetail[0]?.name + collectibleDetail[0]?.address,
//             notificationType: NotificationType.CollectibleMint
//           }));
//         }
//       }
//     }

//     // set false for collectible in minting queue.
//     WhitelistMintingQueue.set(toBeMintedCollectible[i].address, false);
//   }
// });

export const clearCollectibleMintingQueue = createAsyncThunk(
  'digitalCollectible/clearCollectibleMintingQueue',
  async (
    { networkID, provider }: IBaseAsyncThunk,
    { dispatch },
  ): Promise<any> => {
    if (collectibleMintingQueue) {
      collectibleMintingQueue.clear();
    }
  },
);

const initialState: IDigitalCollectibleData = {
  loading: false,
  isMintloading: false,
  AllDigitalCollectibleList: [],
  ActiveDigitalCollectibleList: [],
};

const DigitalCollectibleSlice = createSlice({
  name: 'DigitalCollectibleDetails',
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
    pushDigitalCollectibleDetails(state, action) {
      state.AllDigitalCollectibleList = state.AllDigitalCollectibleList.filter(
        x => {
          return (
            x.address?.toLowerCase() != action.payload.address?.toLowerCase()
          );
        },
      );
      state.AllDigitalCollectibleList.push(action.payload);
    },
    clearDigitalCollectibleDetails(state) {
      state.AllDigitalCollectibleList = [];
    },
    pushActiveDigitalCollectibleDetails(state, action) {
      state.ActiveDigitalCollectibleList =
        state.ActiveDigitalCollectibleList.filter(x => {
          return (
            x.address?.toLowerCase() != action.payload.address?.toLowerCase()
          );
        });
      state.ActiveDigitalCollectibleList.push(action.payload);
    },
    clearActiveDigitalCollectibleDetails(state) {
      state.ActiveDigitalCollectibleList = [];
    },
  },
  extraReducers: builder => {
    builder
      // .addCase(mintAvailableDigitalCollectible.pending, (state, action) => {
      //   state.isMintloading = true;
      // })
      // .addCase(
      //   mintAvailableDigitalCollectible.rejected,
      //   (state, { error }: any) => {
      //     state.isMintloading = false;
      //     showToastMessage();
      //     LogCustomError(
      //       'mintAvailableDigitalCollectible',
      //       error.name,
      //       error.message,
      //       error.stack,
      //     );
      //   },
      // )
      // .addCase(mintAvailableDigitalCollectible.fulfilled, (state, action) => {
      //   state.isMintloading = false;
      // })
      .addCase(loadAllDigitalCollectible.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAllDigitalCollectible.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(
        loadAllDigitalCollectible.rejected,
        (state: { loading: boolean }, { error }: any) => {
          state.loading = false;
          LogCustomError(
            'loadAllDigitalCollectible',
            error.name,
            error.message,
            ' ',
          );
        },
      );
  },
});

export const DigitalCollectibleSliceReducer = DigitalCollectibleSlice.reducer;

const baseInfo = (state: RootState) => state.DigitalCollectible;

export const {
  fetchAppSuccess,
  pushDigitalCollectibleDetails,
  clearDigitalCollectibleDetails,
  pushActiveDigitalCollectibleDetails,
  clearActiveDigitalCollectibleDetails,
} = DigitalCollectibleSlice.actions;

export const getDigitalCollectibleState = createSelector(
  baseInfo,
  DigitalCollectibleSlice => DigitalCollectibleSlice,
);
