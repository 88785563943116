import React, { useEffect, useState } from 'react';
import {
  View,
  Image,
  Platform,
  Keyboard,
  Pressable,
  TextInput,
  useWindowDimensions,
} from 'react-native';
import { Text } from 'react-native-paper';
import Icons from 'react-native-vector-icons/MaterialCommunityIcons';

import styles from '../../styles';
import ModalComponent from '../Modal';
import HOCContainer from '../HOCContainer';
import ProfileAvatar from '../ProfileAvatar';
import GradientButton from '../GradientButton';
import { defaultTheme } from '../../themes/loot8';
import { uploadUserData } from '../../helpers/ipfs';
import { showToastMessage } from '../../helpers/Gadgets';
import ImagePickerComponent from '../ImagePickerComponent';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { postMessage } from '../../slices/PassportMessageSlice';
import { TABLET_DIMENTION, ToastCustomMessageType } from '../../appconstants';

import cameraIcon from '../../assets/camera.png';
import smileyIcon from '../../assets/smiley.png';
import attachmentIcon from '../../assets/attachment.png';

const MESSAGE_MAX_LENGTH = 360;

const CreatePost = ({ route, navigation }) => {
  const dispatch = useAppDispatch();
  const { networkId, staticProvider, wallet } = useWeb3AuthContext();
  const [isLoading, setIsLoading] = useState(false);

  const userData = useAppSelector(state => state.AppUser.UserData);
  const [messageText, setMessageText] = useState('');
  const [countInputTextLength, setCountInputTextLength] = useState(
    messageText ? messageText.length : 0,
  );
  const [disablePostButton, setDisablePostButton] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const { width } = useWindowDimensions();

  const onCreatePost = async () => {
    try {
      // Check if there's text or an image to post
      const isPostContentValid =
        (messageText && messageText.trim().length > 0) ||
        (selectedImage && selectedImage !== '');

      if (!isPostContentValid) {
        // If there's no valid content, we can show a toast message or disable the button
        setDisablePostButton(false);
        return;
      }

      // Dismiss the keyboard if it is present
      if (Keyboard?.dismiss) {
        Keyboard.dismiss();
      }

      // Set loading states and disable the post button
      setDisablePostButton(true);
      setIsLoading(true);

      let attachments = {};

      // If there's an image, upload it and create the attachment object
      if (selectedImage && selectedImage !== '') {
        const uniqueName = `PassportMessage_${Date.now().toString()}.txt`;
        attachments.name = 'Passport Message Image' + Date.now().toString();
        attachments.uri = await uploadUserData(
          selectedImage,
          wallet,
          uniqueName,
        );
      }

      // Prepare the data for posting the message
      const messageData = {
        networkID: networkId,
        chainId: route.params.chainId,
        provider: staticProvider,
        address: route.params.passportAddress,
        wallet,
        text: messageText === '' ? undefined : messageText.trim(),
        attachments,
      };

      // Send the post message and await the response
      const response = await dispatch(postMessage(messageData));

      // Check the response for a successful status
      if (response?.payload?.status === 200) {
        clearData();
        setIsLoading(false);
        route.params.setScrollToMessages(true);
        redirectBack();
      } else {
        handlePostError(response);
      }
    } catch (error) {
      // Catch unexpected errors and handle them gracefully
      console.error('An error occurred while posting the message:', error);
      setDisablePostButton(false);
      setIsLoading(false);
      setShowErrorModal(true);
      route.params.setScrollToMessages(false);
      showToastMessage(
        ToastCustomMessageType.ERROR,
        'An unexpected error occurred. Please try again later.',
      );
    }
  };

  // Helper function to handle different types of post errors
  const handlePostError = response => {
    const error = response?.payload?.error;

    if (
      error?.code === 400 &&
      error.message?.includes('account status: muted')
    ) {
      clearData();
      setIsLoading(false);
      route.params.setScrollToMessages(false);
      redirectBack();
      showToastMessage(
        ToastCustomMessageType.INFO,
        'Unable to post message. Your account is muted. Please try again later!',
      );
    } else {
      setDisablePostButton(false);
      setShowErrorModal(true);
      setIsLoading(false);
      route.params.setScrollToMessages(false);
    }
  };

  const onCancel = () => {
    clearData();
    route.params.setScrollToMessages(false);
    redirectBack();
  };

  const redirectBack = () => {
    navigation.goBack();
  };

  const hasValue = () => {
    return (
      (messageText && messageText.trim().length > 0) ||
      (selectedImage && selectedImage !== '')
    );
  };

  useEffect(() => {
    clearData();
  }, []);

  const clearData = () => {
    setMessageText('');
    setSelectedImage('');
    setCountInputTextLength(0);
    setDisablePostButton(false);
  };

  return (
    <View
      style={[
        width >= TABLET_DIMENTION.width
          ? styles.createPostWebViewContainer
          : styles.createPostMobileViewContainer,
      ]}>
      <View
        style={[
          width >= TABLET_DIMENTION.width
            ? styles.createPostWebContainer
            : styles.createPostContainer,
          {
            margin: 0,
            padding: 0,
            marginTop: 30,
            borderRadius: 0,
          },
        ]}>
        <View style={styles.createPostBodyContainer}>
          <View
            style={[
              // styles.createPostProfile,
              { flexDirection: 'column' },
            ]}>
            <ProfileAvatar
              size={36}
              // style={styles.headerProfileImage}
              source={
                userData.avatarURI &&
                userData.avatarURI != '' &&
                userData.avatarURI != 'no-avatar' &&
                !userData.avatarURI.includes('ipfs://') &&
                !userData.avatarURI.includes('assets_avatars_p_') &&
                !userData.avatarURI.includes('file:///')
                  ? { uri: userData.avatarURI }
                  : require('../../assets/loot8-default-avatar.png')
              }
              userAddress={userData?.wallet}
              tickStyle={{ right: 18, top: -6 }}
            />
          </View>
          <View
            style={{
              marginLeft: 6,
              borderRadius: 10,
              ...styles.createPostMessageContainer,
              backgroundColor: defaultTheme.SECONDARY_COLOR,
            }}>
            <TextInput
              multiline
              numberOfLines={4}
              editable={!isLoading}
              style={
                (Platform.OS == 'web'
                  ? styles.createPostWebViewInputText
                  : styles.createPostMobileInputText,
                {
                  height: 86,
                  padding: 10,
                  color: '#fff',
                  paddingTop: 8,
                  borderRadius: 10,
                })
              }
              value={messageText}
              onChangeText={text => {
                if (text && text.length <= MESSAGE_MAX_LENGTH) {
                  setMessageText(text);
                  setCountInputTextLength(text.length);
                } else if (text.length === 0) {
                  setMessageText(text);
                  setCountInputTextLength(text.length);
                }
              }}
              maxLength={MESSAGE_MAX_LENGTH}
              placeholder={`What's happening?`}
              placeholderTextColor="rgba(255,255,255,0.6)"
              cursorColor={defaultTheme.CONTENT_NAME_BACKGROUND_COLOR}
            />
            <View
              style={{
                marginBottom: 2,
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}>
              <Pressable
                onPress={() => setShowAddImage(true)}
                style={{
                  width: 30,
                  height: 30,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Image source={cameraIcon} style={{ width: 20, height: 16 }} />
              </Pressable>
              <Pressable
                onPress={() => setShowAddImage(true)}
                style={{
                  width: 30,
                  height: 30,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Image
                  source={attachmentIcon}
                  style={{ width: 14, height: 16 }}
                />
              </Pressable>
              <Pressable
                disabled
                onPress={() => setShowAddImage(true)}
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 4,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Image source={smileyIcon} style={{ width: 16, height: 16 }} />
              </Pressable>
            </View>
          </View>
        </View>
        <View
          style={{
            paddingTop: 5,
            width: '100%',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
          }}>
          <View
            style={[
              {
                flex: 1,
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
              },
            ]}>
            <Text style={styles.createPostCharacterCountCaption}>
              {' '}
              {countInputTextLength.toString()} / {MESSAGE_MAX_LENGTH}{' '}
            </Text>
          </View>
        </View>
        {selectedImage && selectedImage !== '' ? (
          <View
            style={{
              width: 100,
              height: 100,
              marginTop: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Image
              source={{ uri: selectedImage }}
              style={{
                height: 100,
                width: 100,
                borderWidth: 1,
                alignItems: 'center',
                borderColor: '#dfdfdf',
                justifyContent: 'center',
              }}
              resizeMethod="scale"
              resizeMode="contain"
            />
            {!isLoading && (
              <Pressable
                onPress={() => setSelectedImage(null)}
                style={{
                  top: 5,
                  right: 5,
                  width: 25,
                  height: 25,
                  borderRadius: 30,
                  position: 'absolute',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#F0F0F0B0',
                }}>
                <Icons
                  name="window-close"
                  size={20}
                  style={{ color: '#404040' }}
                />
              </Pressable>
            )}
          </View>
        ) : (
          <></>
        )}
        <View
          style={{
            marginTop: 60,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <GradientButton
            width={'48%'}
            type="secondary"
            onPress={onCancel}
            buttonLabel={'Cancel'}
            disabled={isLoading || disablePostButton}
          />
          <GradientButton
            width={'48%'}
            loading={isLoading}
            onPress={onCreatePost}
            buttonLabel={'Post Message'}
            disabled={!hasValue() || disablePostButton}
          />
        </View>
        {showErrorModal ? (
          <ModalComponent
            showModal={showErrorModal}
            needCloseButton={true}
            enableHeader={false}
            onDismiss={() => {
              setShowErrorModal(false);
            }}>
            <View style={{ padding: 30, alignContent: 'center' }}>
              <Text style={styles.modalTextStyle}>Something went wrong.</Text>
              <Text style={styles.modalTextStyle}>Please try again!!</Text>
            </View>
          </ModalComponent>
        ) : (
          <></>
        )}
        {showAddImage && (
          <ModalComponent
            showModal={showAddImage}
            enableHeader={false}
            needCloseButton={true}
            modalStyle={{ bottom: 0, justifyContent: 'flex-end' }}
            modalBodyStyle={{ alignSelf: 'flex-start' }}
            onDismiss={() => setShowAddImage(false)}>
            <View style={{ padding: 10 }}>
              <Text style={styles.imagePickerHeaderText}>Attach Photo</Text>
              <ImagePickerComponent
                onAvatarButtonClick={() => {}}
                setSelectedImage={setSelectedImage}
                setSelectImageAction={setShowAddImage}
                showAvatar={false}
                isSquare={false}
              />
            </View>
          </ModalComponent>
        )}
      </View>
    </View>
  );
};

export default HOCContainer({
  OriginalComponent: CreatePost,
  title: 'Post a New Message',
});
