import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import {
  Pressable,
  View,
  Text,
  Image,
  Dimensions,
  StyleSheet,
  Clipboard,
  ScrollView,
} from 'react-native';
import { defaultTheme } from '../../themes/loot8';
import styles from '../../styles';
import { useDispatch } from 'react-redux';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { useAppSelector } from '../../hooks';
import { AppDispatch } from '../../store';
import Ionicons from 'react-native-vector-icons/Ionicons';
import AppLoaderComponent from '../Loader';
import { ScreenName } from '../../enums/screen.enum';
import EightIcon from '../../assets/eightIcon.png';
import usdcIcon from '../../assets/usdcLogo.png';
import { ActivityIndicator, Avatar, Button } from 'react-native-paper';
import {
  NetworkId,
  addresses,
  getAnynetStaticProvider,
  isNativeChain,
} from '../../appconstants';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  approveForBuyListing,
  buyPublicMarketPlaceCollection,
} from '../../slices/MarketPlaceSlice';
import InfoLoader from '../Loader/InfoLoader';
import { CatalogTypes } from '../../enums/catalog.enum';
import {
  loadCatalogCollectiable,
  loadCatalogPassport,
} from '../../slices/CatalogSlice';
import { CollectionType } from '../../enums/collection.enum';
import { getTruncatedName } from '../../helpers/Gadgets';
import MarketPlaceConfirmationSheet from './MarketPlaceConfirmationSheet';
import { ActionSheetRef } from 'react-native-actions-sheet';
import {
  updatePassportDetails,
  updatePassportSubscriptionStatus,
} from '../../slices/PassportSlice';
import { getCollectibleDetails } from '../../slices/OfferSlice';
import { PassportType } from '../../enums/passportCategory.enum';
import { getWalletTokenDetails } from '../../slices/helpers';
import { Loot8Token__factory } from '../../typechain';
import HOCContainer from '../HOCContainer';
import GradientButton from '../GradientButton';

const MarketPlaceBuyItems = ({ collectionItem, type, userCurrentBalance }) => {
  const loadingSelectedCollectiblePassport = useAppSelector(
    state => state.MarketPlace.loadingSelectedCollectiblePassport,
  );
  const allPassportDetailsList = useAppSelector(
    state => state.Passports.AllPassportDetailsList,
  );
  const allUsers = useAppSelector(state => state.AppUser.AllUsersData);

  const selectedCollectiblePassport = useMemo(
    () =>
      allPassportDetailsList?.find(
        x =>
          x?.address?.toLocaleLowerCase() ==
          collectionItem?.passport?.toLocaleLowerCase(),
      ),
    [allPassportDetailsList, collectionItem?.passport],
  );

  const [isCopied, setIsCopied] = useState(false);
  const [isOwnerAddressCopied, setIsOwnerAddressCopied] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState(collectionItem);

  const getSellerData = async () => {
    if (selectedCollection) {
      const ownerData = allUsers?.find(
        (user: any) =>
          user?.wallet?.toLowerCase() ==
          selectedCollection?.seller?.toLowerCase(),
      );
      if (ownerData) {
        const addSellerData = { ...selectedCollection, sellerData: ownerData };
        setSelectedCollection(addSellerData);
      } else {
        const sellerData = { ...selectedCollection, sellerData: null };
        setSelectedCollection(sellerData);
      }
    }
  };

  const copyToClipboard = (userAddr: string, isOwnerAddress = false) => {
    Clipboard.setString(userAddr);
    if (isOwnerAddress) {
      setIsOwnerAddressCopied(true);
    } else {
      setIsCopied(true);
    }
    setTimeout(() => {
      if (isOwnerAddress) {
        setIsOwnerAddressCopied(false);
      } else {
        setIsCopied(false);
      }
    }, 2000);
  };

  useEffect(() => {
    getSellerData();
  }, [allUsers]);

  return (
    <>
      {type === CollectionType.COLLECTION ? (
        <View style={styles.tokenBuyItemsContainer}>
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Collectible Name</Text>
            <View
              style={{
                flexDirection: 'row',
                flex: 0.6,
                justifyContent: 'flex-end',
              }}>
              <Text
                style={{
                  ...styles.tokenBuyItemCaption,
                  maxWidth: '75%',
                  textAlign: 'right',
                }}>
                {selectedCollection?.collectionData?.name}
              </Text>
            </View>
          </View>
          <View style={styles.tokenBuyHairline}></View>
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Collection Address</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={styles.tokenBuyItemCaption}>
                {Dimensions.get('window').width > 480
                  ? selectedCollection?.collectionData?.address
                  : selectedCollection?.collectionData?.address.substring(
                      0,
                      5,
                    ) +
                    '...' +
                    selectedCollection?.collectionData?.address.substring(
                      selectedCollection?.collectionData?.address.length - 5,
                      selectedCollection?.collectionData?.address.length,
                    )}
              </Text>
              <Icon
                style={{ marginLeft: 5 }}
                name="clipboard-multiple"
                size={14}
                color="#FFFFFF"
                onPress={() => {
                  copyToClipboard(selectedCollection?.collectionData?.address);
                }}></Icon>
              {isCopied ? (
                <View style={styles.drawerCopyTextContainer}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                      fontSize: 8,
                    }}>
                    Copied!
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>
          <View style={styles.tokenBuyHairline}></View>
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Token Id</Text>
            <Text style={styles.tokenBuyItemCaption}>
              {selectedCollection?.tokenId}
            </Text>
          </View>
          <View style={styles.tokenBuyHairline}></View>
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Price</Text>
            <View style={{ flexDirection: 'row' }}>
              {isNativeChain(selectedCollection.chainId) ? (
                <Image
                  source={EightIcon}
                  resizeMode="contain"
                  style={{
                    marginLeft: 5,
                    width: 20,
                    height: 20,
                    marginRight: 5,
                  }}
                />
              ) : (
                <Image
                  source={usdcIcon}
                  resizeMode="contain"
                  style={{
                    marginLeft: 5,
                    width: 20,
                    height: 20,
                    marginRight: 5,
                  }}
                />
              )}
              <Text style={styles.tokenBuyItemCaption}>
                {selectedCollection?.price}
              </Text>
            </View>
          </View>
          <View style={styles.tokenBuyHairline}></View>
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Owner</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Avatar.Image
                style={localStyle.collectibleOwnerProfileAvatar}
                size={20}
                source={
                  selectedCollection?.sellerData?.avatarURI == '' ||
                  selectedCollection?.sellerData?.avatarURI == 'no-avatar' ||
                  (selectedCollection?.sellerData?.avatarURI &&
                    (selectedCollection?.sellerData?.avatarURI.includes(
                      'ipfs://',
                    ) ||
                      selectedCollection?.sellerData?.avatarURI.includes(
                        'assets_avatars_p_',
                      ) ||
                      selectedCollection?.sellerData?.avatarURI.includes(
                        'file:///',
                      )))
                    ? require('../../assets/loot8-default-avatar.png')
                    : { uri: selectedCollection?.sellerData?.avatarURI }
                }
              />
              <Text style={styles.tokenBuyItemCaption}>
                {getTruncatedName(selectedCollection?.sellerData?.name)}
              </Text>
            </View>
          </View>
          <View style={styles.tokenBuyHairline}></View>
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Owner Address</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={styles.tokenBuyItemCaption}>
                {Dimensions.get('window').width > 480
                  ? selectedCollection?.sellerData?.wallet
                  : selectedCollection?.sellerData?.wallet.substring(0, 5) +
                    '...' +
                    selectedCollection?.sellerData?.wallet.substring(
                      selectedCollection?.sellerData?.wallet.length - 5,
                      selectedCollection?.sellerData?.wallet.length,
                    )}
              </Text>
              <Icon
                style={{ marginLeft: 5 }}
                name="clipboard-multiple"
                size={14}
                color="#FFFFFF"
                onPress={() => {
                  copyToClipboard(selectedCollection?.sellerData?.wallet, true);
                }}></Icon>
              {isOwnerAddressCopied ? (
                <View style={styles.drawerCopyTextContainer}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                      fontSize: 8,
                    }}>
                    Copied!
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>

          {!loadingSelectedCollectiblePassport &&
            selectedCollectiblePassport?.name &&
            selectedCollectiblePassport?.tokenId && (
              <>
                <View style={styles.tokenBuyHairline}></View>
                <View style={styles.tokenBuyItemContainer}>
                  <Text style={styles.tokenBuyItemCaption}>ExPass Name</Text>
                  <Text style={styles.tokenBuyItemCaption}>{`${getTruncatedName(
                    selectedCollectiblePassport?.name,
                  )} #${selectedCollectiblePassport?.tokenId}`}</Text>
                </View>
              </>
            )}
          {loadingSelectedCollectiblePassport && (
            <>
              <View style={styles.tokenBuyHairline}></View>
              <View style={styles.tokenBuyItemContainer}>
                <Text style={styles.tokenBuyItemCaption}>ExPass Name</Text>
                <ActivityIndicator
                  size="small"
                  color={defaultTheme.BUTTON_FAB_BACKGROUND_COLOR}
                />
              </View>
            </>
          )}
          <View style={styles.tokenBuyHairline} />
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Your Balance</Text>
            <View style={{ flexDirection: 'row' }}>
              {isNativeChain(selectedCollection?.chainId) && (
                <Image
                  source={EightIcon}
                  resizeMode="contain"
                  style={{
                    marginLeft: 5,
                    width: 20,
                    height: 20,
                    marginRight: 5,
                  }}
                />
              )}
              {userCurrentBalance ? (
                <>
                  <Text style={styles.tokenBuyItemCaption}>
                    {userCurrentBalance ? userCurrentBalance : 0}
                  </Text>
                  {!isNativeChain(selectedCollection?.chainId) && (
                    <Text style={styles.tokenBuyItemCaption}>{' USDC'}</Text>
                  )}
                </>
              ) : (
                <ActivityIndicator
                  size="small"
                  color={defaultTheme.BUTTON_FAB_BACKGROUND_COLOR}
                />
              )}
            </View>
          </View>
        </View>
      ) : (
        <View style={styles.tokenBuyItemsContainer}>
          {/* ExPass Name */}
          <View style={styles.tokenBuyItemContainer}>
            <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
              ExPass Name
            </Text>
            <View
              style={{
                flexDirection: 'row',
                flex: 0.6,
                justifyContent: 'flex-end',
              }}>
              <Text
                style={{
                  ...styles.tokenBuyItemCaption,
                  maxWidth: '75%',
                  textAlign: 'right',
                }}>
                {selectedCollection?.collectionData?.name}
              </Text>
            </View>
          </View>
          <View style={styles.tokenBuyHairline} />

          {/* Collection Address */}
          <View style={styles.tokenBuyItemContainer}>
            <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
              Collection Address
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flex: 0.6,
              }}>
              <Text
                style={{
                  ...styles.tokenBuyItemCaption,
                  maxWidth: '75%',
                  textAlign: 'right',
                }}>
                {Dimensions.get('window').width > 480
                  ? selectedCollection?.collectionData?.address
                  : selectedCollection?.collectionData?.address.substring(
                      0,
                      5,
                    ) +
                    '...' +
                    selectedCollection?.collectionData?.address.substring(
                      selectedCollection?.collectionData?.address.length - 5,
                      selectedCollection?.collectionData?.address.length,
                    )}
              </Text>
              <Icon
                style={{ marginLeft: 5 }}
                name="clipboard-multiple"
                size={14}
                color="#FFFFFF"
                onPress={() => {
                  copyToClipboard(selectedCollection?.collectionData?.address);
                }}></Icon>
              {isCopied ? (
                <View style={styles.drawerCopyTextContainer}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                      fontSize: 8,
                    }}>
                    Copied!
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </View>
          </View>
          <View style={styles.tokenBuyHairline} />

          {/* Token ID */}
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Token Id</Text>
            <Text style={{ ...styles.tokenBuyItemCaption, textAlign: 'right' }}>
              {selectedCollection?.tokenId}
            </Text>
          </View>
          <View style={styles.tokenBuyHairline} />

          {/* Price  */}
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Price</Text>
            <View style={{ flexDirection: 'row' }}>
              {isNativeChain(selectedCollection.chainId) ? (
                <Image
                  source={EightIcon}
                  resizeMode="contain"
                  style={{
                    marginLeft: 5,
                    width: 20,
                    height: 20,
                    marginRight: 5,
                  }}
                />
              ) : (
                <Image
                  source={usdcIcon}
                  resizeMode="contain"
                  style={{
                    marginLeft: 5,
                    width: 20,
                    height: 20,
                    marginRight: 5,
                  }}
                />
              )}
              <Text style={styles.tokenBuyItemCaption}>
                {collectionItem?.price?.toFixed(2)}
              </Text>
            </View>
          </View>

          <View style={styles.tokenBuyHairline} />

          {/* Owner Name */}
          <View style={styles.tokenBuyItemContainer}>
            <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.3 }}>
              Owner
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flex: 0.7,
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}>
                <Avatar.Image
                  style={localStyle.collectibleOwnerProfileAvatar}
                  size={20}
                  source={
                    selectedCollection?.sellerData?.avatarURI == '' ||
                    selectedCollection?.sellerData?.avatarURI == 'no-avatar' ||
                    (selectedCollection?.sellerData?.avatarURI &&
                      (selectedCollection?.sellerData?.avatarURI.includes(
                        'ipfs://',
                      ) ||
                        selectedCollection?.sellerData?.avatarURI.includes(
                          'assets_avatars_p_',
                        ) ||
                        selectedCollection?.sellerData?.avatarURI.includes(
                          'file:///',
                        )))
                      ? require('../../assets/loot8-default-avatar.png')
                      : { uri: selectedCollection?.sellerData?.avatarURI }
                  }
                />
                <Text
                  style={{
                    ...styles.tokenBuyItemCaption,
                    maxWidth: '75%',
                    textAlign: 'right',
                  }}>
                  {selectedCollection?.sellerData?.name}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.tokenBuyHairline} />

          {/* Owner address */}
          <View style={styles.tokenBuyItemContainer}>
            <Text style={{ ...styles.tokenBuyItemCaption, flex: 0.4 }}>
              Owner Address
            </Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flex: 0.6,
              }}>
              <Text
                style={{
                  ...styles.tokenBuyItemCaption,
                  maxWidth: '75%',
                  textAlign: 'right',
                }}>
                {Dimensions.get('window').width > 480
                  ? selectedCollection?.sellerData?.wallet
                  : selectedCollection?.sellerData?.wallet.substring(0, 5) +
                    '...' +
                    selectedCollection?.sellerData?.wallet.substring(
                      selectedCollection?.sellerData?.wallet.length - 5,
                      selectedCollection?.sellerData?.wallet.length,
                    )}
              </Text>
              <Icon
                style={{ marginLeft: 5 }}
                name="clipboard-multiple"
                size={14}
                color="#FFFFFF"
                onPress={() => {
                  copyToClipboard(selectedCollection?.sellerData?.wallet, true);
                }}></Icon>
              {isOwnerAddressCopied && (
                <View style={styles.drawerCopyTextContainer}>
                  <Text
                    style={{
                      color: '#FFFFFF',
                      fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
                      fontSize: 8,
                    }}>
                    Copied!
                  </Text>
                </View>
              )}
            </View>
          </View>

          <View style={styles.tokenBuyHairline} />

          {/* User Balance */}
          <View style={styles.tokenBuyItemContainer}>
            <Text style={styles.tokenBuyItemCaption}>Your Balance</Text>
            <View style={{ flexDirection: 'row' }}>
              {isNativeChain(selectedCollection?.chainId) && (
                <Image
                  source={EightIcon}
                  resizeMode="contain"
                  style={{
                    marginLeft: 5,
                    width: 20,
                    height: 20,
                    marginRight: 5,
                  }}
                />
              )}
              {userCurrentBalance ? (
                <>
                  <Text style={styles.tokenBuyItemCaption}>
                    {userCurrentBalance ? userCurrentBalance : 0}
                  </Text>
                  {!isNativeChain(selectedCollection?.chainId) && (
                    <Text style={styles.tokenBuyItemCaption}>{' USDC'}</Text>
                  )}
                </>
              ) : (
                <ActivityIndicator
                  size="small"
                  color={defaultTheme.BUTTON_FAB_BACKGROUND_COLOR}
                />
              )}
            </View>
          </View>
        </View>
      )}
    </>
  );
};

const MarketPlaceBuyCollection = ({ route, navigation }) => {
  const type = route?.params?.type;
  const { networkId, staticProvider, address, wallet, userInfo } =
    useWeb3AuthContext();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedCollection, setSelectedCollection] = useState(
    route?.params?.collection,
  );
  // const [loading, setLoading] = useState(false);
  // const [collectionData,setCollectionData] = useState(null);
  // const [showMessageModel,setShowMessageModel] = useState(false);
  // const windowWidth = Dimensions.get('window').width;

  const actionSheetRef = useRef<ActionSheetRef>(null);
  const [showMessageTitle, setShowMessageTitle] = useState('');
  const [showMessageSubTitle, setShowMessageSubTitle] = useState('');
  const [purchaseInProgress, setPurchaseInProgress] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);

  let selectCollectionType =
    type == CollectionType.COLLECTION ? 'Collectible' : 'ExPass';

  const CatalogCollectiableDetailsList = useAppSelector(
    state => state.Catalogs.CatalogCollectiableDetailsList,
  );
  const multiplePassportDetailsList = useAppSelector(
    state => state.Catalogs.CatalogMultiplePassportsDetailsList,
  );
  const [currentBalance, setCurrentBalance] = useState(0);
  const [insufficientBalance, setInsufficientBalance] = useState(true);
  const [fetchingBalance, setFetchingBalance] = useState(true);

  const onPurchase = async () => {
    if (!insufficientBalance) {
      setPurchaseInProgress(true);

      let chainId = selectedCollection.chainId;
      let anynetProvider = getAnynetStaticProvider(chainId);

      await dispatch(
        approveForBuyListing({
          networkID: chainId,
          provider: anynetProvider,
          wallet,
          address,
          amount: selectedCollection?.price,
        }),
      );
      let response = await dispatch(
        buyPublicMarketPlaceCollection({
          networkID: chainId,
          provider: anynetProvider,
          address: address,
          wallet: wallet,
          listingId: selectedCollection?.id,
          isCollectibleData: type == CollectionType.COLLECTION ? true : false,
        }),
      );

      if (response && response.payload) {
        setTransactionSuccess(true);
        setPurchaseInProgress(false);
        navigateToSuccessScreen();
        actionSheetRef?.current?.hide();
        if (type == CollectionType.COLLECTION) {
          await dispatch(
            loadCatalogCollectiable({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet,
              userInfo,
            }),
          );
        } else {
          let providerToPass = staticProvider;
          if (!isNativeChain(selectedCollection?.chainId)) {
            providerToPass = getAnynetStaticProvider(
              selectedCollection.chainId,
            );
          }

          const latestPassport = await getCollectibleDetails(
            {
              networkID: selectedCollection.chainId,
              provider: providerToPass,
              collectibleAddress: selectedCollection?.collection,
              address,
              wallet,
            },
            { entityData: null },
            {
              isCache: true,
              isBalanceRefresh: true,
              isMarketPlaceRefresh: false,
            },
          );
          await dispatch(
            updatePassportDetails({
              address: selectedCollection?.collection,
              collectibleIds:
                latestPassport?.collectibleIds.length > 0
                  ? latestPassport?.collectibleIds
                  : [selectedCollection?.tokenId],
              collectibleCount:
                latestPassport?.collectibleCount > 0
                  ? latestPassport?.collectibleCount
                  : 1,
              isAvailable: true,
              passportType: latestPassport.passportType,
            }),
          );

          await dispatch(
            updatePassportSubscriptionStatus({
              address: selectedCollection?.collection,
              passportType: PassportType.SUBSCRIBED,
              buyPrice: selectedCollection?.price,
              timestamp: parseInt((Date.now() / 1000).toString()),
            }),
          );

          await dispatch(
            loadCatalogPassport({
              networkID: networkId,
              provider: staticProvider,
              address,
              wallet,
              userInfo,
              isCache: false,
            }),
          );
        }
      } else {
        setTransactionSuccess(false);
        setShowMessageTitle('Transaction Failed');
        setShowMessageSubTitle('Please try after sometime');
      }
      setPurchaseInProgress(false);
      setTimeout(() => {
        if (!transactionSuccess) {
          actionSheetRef?.current?.show();
        }
      }, 500);
    } else {
      if (!transactionSuccess) {
        actionSheetRef?.current?.show();
      }
    }
  };

  const navigateBack = () => {
    actionSheetRef.current.hide();
    navigation.goBack();
  };

  const navigateToSuccessScreen = () => {
    let collectionDetail = null;
    if (type === CollectionType.COLLECTION) {
      if (CatalogCollectiableDetailsList?.length > 0) {
        collectionDetail = CatalogCollectiableDetailsList?.find(
          x =>
            x.address.toLocaleLowerCase() ==
              selectedCollection?.collection?.toLocaleLowerCase() &&
            Number(x.collectibleId) == Number(selectedCollection?.tokenId),
        );
      }
    } else if (type === CollectionType.PASSPORT) {
      if (multiplePassportDetailsList?.length > 0) {
        collectionDetail = multiplePassportDetailsList?.find(
          x =>
            x.address.toLocaleLowerCase() ==
              selectedCollection?.collection?.toLocaleLowerCase() &&
            Number(x.collectibleId) === Number(selectedCollection?.tokenId),
        );
      }
    }

    const navigationParams =
      selectCollectionType === 'Collectible'
        ? {
            catalog: collectionDetail
              ? collectionDetail
              : {
                  ...selectedCollection?.collectionData,
                  tokenId: selectedCollection?.tokenId,
                },
            availableCatalogs: null,
            type:
              type === CollectionType.COLLECTION
                ? CatalogTypes.COLLECTIBLES
                : CatalogTypes.PASSPORT,
            from: ScreenName.MARKET_PLACE_BUY_COLLECTIBLE,
          }
        : {
            passportAddress: selectedCollection.collection,
            chainId: selectedCollection?.chainId,
            from: ScreenName.MARKET_PLACE_BUY_COLLECTIBLE,
          };

    navigation.navigate('Success', {
      screen: ScreenName.SUCCESS_SCREEN,
      params: {
        message: `${
          selectCollectionType === 'Collectible' ? 'Collectible' : 'ExPass'
        } has been bought`,
        navigateTo: 'MoreTab',
        navigationParams: {
          screen:
            selectCollectionType === 'Collectible'
              ? ScreenName.CATALOG_DETAILS
              : 'PassportDetail',
          params: navigationParams,
          initial: false,
        },
        buttonLabel: `Open ${selectCollectionType}`,
      },
    });
  };

  // const navigateToCollectible = () => {
  //   // actionSheetRef?.current?.hide();
  //   let collectionDetail = null;
  //   if (type === CollectionType.COLLECTION) {
  //     if (CatalogCollectiableDetailsList?.length > 0) {
  //       collectionDetail = CatalogCollectiableDetailsList?.find(
  //         x =>
  //           x.address.toLocaleLowerCase() ==
  //             selectedCollection?.collection?.toLocaleLowerCase() &&
  //           Number(x.collectibleId) == Number(selectedCollection?.tokenId),
  //       );
  //     }
  //   } else if (type === CollectionType.PASSPORT) {
  //     if (multiplePassportDetailsList?.length > 0) {
  //       collectionDetail = multiplePassportDetailsList?.find(
  //         x =>
  //           x.address.toLocaleLowerCase() ==
  //             selectedCollection?.collection?.toLocaleLowerCase() &&
  //           Number(x.collectibleId) === Number(selectedCollection?.tokenId),
  //       );
  //     }
  //   }
  //   navigation.navigate(ScreenName.CATALOG_TAB, {
  //     screen: ScreenName.CATALOG_DETAILS,
  //     params: {
  //       catalog: collectionDetail
  //         ? collectionDetail
  //         : selectedCollection?.collectionData,
  //       availableCatalogs: null,
  //       type:
  //         type === CollectionType.COLLECTION
  //           ? CatalogTypes.COLLECTIBLES
  //           : CatalogTypes.PASSPORT,
  //       from: ScreenName.MARKET_PLACE_BUY_COLLECTIBLE,
  //     },
  //     initial: false,
  //   });
  // };

  const navigateToTokenPackages = () => {
    actionSheetRef.current.hide();
    navigation.navigate(ScreenName.WALLET_TAB, {
      screen: ScreenName.TOKEN_PACKAGES,
    });
  };

  const getUSDCTokenDetails = async (
    chainId: number,
    walletTokenAddress: string,
    userAddress: string,
  ) => {
    const provider = getAnynetStaticProvider(chainId);
    const walletBalance = Loot8Token__factory.connect(
      walletTokenAddress,
      provider,
    );
    const bal =
      Number(await walletBalance.balanceOf(userAddress)) / Math.pow(10, 6);
    return { walletBalance: bal?.toFixed(2) };
  };

  useEffect(() => {
    (async () => {
      const currentLoot8TokenBalance = isNativeChain(
        selectedCollection?.chainId,
      )
        ? await getWalletTokenDetails(
            addresses[networkId].Loot8Token,
            address,
            true,
          )
        : await getUSDCTokenDetails(
            selectedCollection?.chainId,
            addresses[selectedCollection?.chainId].USDC,
            address,
          );
      if (currentLoot8TokenBalance && currentLoot8TokenBalance.walletBalance) {
        setCurrentBalance(currentLoot8TokenBalance.walletBalance);
        const currentPrice = selectedCollection?.price;
        if (
          Number(currentLoot8TokenBalance.walletBalance) >= Number(currentPrice)
        ) {
          setInsufficientBalance(false);
        } else {
          setInsufficientBalance(true);
        }
      }
      setFetchingBalance(false);
    })();

    return () => {
      setInsufficientBalance(true);
      setCurrentBalance(0);
      setFetchingBalance(true);
    };
  }, []);

  return (
    <View
      style={{ height: '100%', width: '100%', overflow: 'hidden', flex: 1 }}>
      <View>
        <ScrollView showsVerticalScrollIndicator={false}>
          {/* container */}
          <View style={{ alignItems: 'center', marginTop: 10, flex: 1 }}>
            <Text
              style={{
                fontSize: defaultTheme.FONT_SIZE_XSMALL,
                fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
                color: defaultTheme.PRIMARY_TEXT_COLOR,
              }}>{`Buy ${selectCollectionType}`}</Text>
            <View style={styles.tokenPackagesSecondTitleCotainer}>
              <Text
                style={{
                  ...styles.tokenPackagesSecondTitle,
                  textAlign: 'center',
                  width: '85%',
                  alignSelf: 'center',
                  fontFamily: defaultTheme.FONT_FAMILY_MEDIUM_ITALIC,
                }}>
                {' '}
                Please verify the details below. Once confirmed, the transaction
                cannot be undone.{' '}
              </Text>
            </View>
            <View
              style={[
                styles.tokenBuyTokenDetails,
                { backgroundColor: defaultTheme.CARD_BG_COLOR },
              ]}>
              <MarketPlaceBuyItems
                collectionItem={selectedCollection}
                type={type}
                userCurrentBalance={currentBalance}
              />
            </View>
          </View>
        </ScrollView>
      </View>
      <View style={{ width: '100%', position: 'absolute', bottom: '0%' }}>
        <GradientButton
          buttonLabel={`Buy ${selectCollectionType}`}
          loading={purchaseInProgress}
          disabled={fetchingBalance || purchaseInProgress}
          onPress={() => {
            if (purchaseInProgress) {
              transactionSuccess ? navigateToSuccessScreen() : onPurchase();
            } else {
              onPurchase();
            }
          }}
        />
      </View>

      {/* Confirmation Sheet */}
      {!transactionSuccess && (
        <MarketPlaceConfirmationSheet
          actionSheetRef={actionSheetRef}
          showMessageTitle={showMessageTitle}
          showMessageSubTitle={showMessageSubTitle}
          buttonTitle={'Okay'}
          onPressButton={navigateBack}
          insufficientBalance={insufficientBalance}
          navigateToTokenPackages={navigateToTokenPackages}
          isNativeChain={isNativeChain(selectedCollection?.chainId)}
        />
      )}

      {purchaseInProgress && (
        <InfoLoader text1="Please sit tight while we are processing your request." />
      )}
    </View>
  );
};

export default HOCContainer({
  OriginalComponent: MarketPlaceBuyCollection,
});

const localStyle = StyleSheet.create({
  collectibleOwnerProfileAvatar: {
    zIndex: 3,
    borderWidth: 0.1,
    borderColor: defaultTheme.AVATAR_BORDER_COLOR,
    backgroundColor: '#AB339B',
    borderStyle: 'solid',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: -5,
    paddingLeft: -5,
    marginRight: 3,
    height: 20,
    width: 20,
    borderRadius: 20 / 2,
    // alignSelf:'flex-end'
  },
});
