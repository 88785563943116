import React, { useEffect, useState } from 'react';
import { View, Platform, useWindowDimensions } from 'react-native';
import { useWeb3AuthContext } from '../../hooks/web3authContext';
import { useAppSelector } from '../../hooks';
import {
  IMapMarker,
  IUniqueLocations,
} from '../../interfaces/ILocation.interface';
import {
  MAX_DATE_TIME,
  NetworkId,
  TABLET_DIMENTION,
  ZERO_ADDRESS,
  getAnynetStaticProvider,
  getAppConfiguration,
  isNativeChain,
} from '../../appconstants';
import { isCollectionExpire } from '../../helpers/DateHelper';
import { uniqWith } from 'lodash';
import { CollectionType } from '../../enums/collection.enum';
import { getLocationDistance } from '../../helpers/locationHelper';
import {
  getAllCollectionByCategory,
  getCollectibleDetails,
} from '../../slices/OfferSlice';
import { Loot8Collection__factory } from '../../typechain';
import { Marker } from 'react-native-maps';
import { OfferType, UICustomOfferType } from '../../enums/offers.enum';
import MarkerPinImage from './MarkerPinImage';
import MarkerItem from './MarkerItem';
import { wait } from '../../helpers/ipfs';
import { getCollectionConstraint } from '../../helpers/MapHelper';

let globalFeaturedOffersList = [];
let globalRegularOffersList = [];
let globalEventsList = [];
let globalPassportList = [];
let globalNonGeofencedItems = [];
let globalCouponList = [];

const getCenterOffsetForAnchor = (
  anchor: { x: number; y: number },
  markerWidth: number,
  markerHeight: number,
): { x: number; y: number } => ({
  x: markerWidth * 0.5 - markerWidth * anchor.x,
  y: markerHeight * 0.5 - markerHeight * anchor.y,
});

const MARKER_WIDTH = 42;
const MARKER_HEIGHT = 42;

/** Customizable anchor prop - Specify your desired anchor adjustements here */
const ANCHOR = { x: 0.6, y: 1.5 };
/** generate centerOffset prop based on the anchor and custom view for the pin. whenerver view changes, please check zoom-in zoom out and adjust offset if needed */
const CENTEROFFSET = () => {
  return getCenterOffsetForAnchor(ANCHOR, MARKER_WIDTH, MARKER_HEIGHT);
};

const WEB_MILES_TO_SHOW_IMAGE = 100;
const MOBILE_MILES_TO_SHOW_IMAGE = 60;

const Markers = ({
  setSelectedMarker,
  setShowParameters,
  showPinImage,
  currentRegion,
  setNonGeoFencedItems,
}) => {
  const { networkId, staticProvider, address, wallet } = useWeb3AuthContext();

  const [digitalCollectibleList, setDigitalCollectibleList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [allLocationList, setAllLocationList] = useState([]);
  const [enableCollectionImage, setEnableCollectionImage] = useState(true);

  let activePassportDetailsList = useAppSelector(
    state => state.Passports.AllPassportDetailsList,
  );
  let activeDigitalCollectibleList = useAppSelector(
    state => state.DigitalCollectible.AllDigitalCollectibleList,
  );
  const loadingPassport = useAppSelector(state => state.Passports.loading);
  const loadingCollectible = useAppSelector(
    state => state.DigitalCollectible.loading,
  );

  const { width } = useWindowDimensions();
  const [milesToShowImage, setMilesToShowImage] = useState(
    width >= TABLET_DIMENTION.width
      ? WEB_MILES_TO_SHOW_IMAGE
      : MOBILE_MILES_TO_SHOW_IMAGE,
  );

  const pinLocations = async () => {
    let estLocation: IMapMarker[] = [];

    await Promise.all(
      activePassportDetailsList
        ?.filter(x => x.isActive)
        ?.map(async (item: any, index: any) => {
          let isValidPassportToMint = true;

          let providerToPass = staticProvider;
          if (
            !isNativeChain(item.chainId)
          ) {
            providerToPass = getAnynetStaticProvider(item.chainId);
          }

          if (item.maxTokens) {
            const passportCollection = Loot8Collection__factory.connect(
              item.address,
              providerToPass,
            );
            if (
              +(await passportCollection.collectionCollectibleIds()) >
              item.maxTokens
            ) {
              isValidPassportToMint = false;
            }
          }

          // This condition is not needed now as mintWithLinkedOnly expass is visible in Avaiable Expasses list by default.
          // if mintWithLinkedOnly flag is true but collectible is not attached with that passport,
          // then avoid passport to show in the non geo-fenced drops list.
          // if(item && item?.mintWithLinkedOnly && item?.whitelistedCollections?.length === 0) {
          //     isValidPassportToMint = false;
          // }

          if (
            isValidPassportToMint &&
            item?.area != null &&
            item?.area.length == 2 &&
            item?.area[1] > 0
          ) {
            // let distance = getLocationDistance(currentLoc.latitude, currentLoc.longitude,  Number(item?.area[0][0]), Number(item?.area[0][1]));
            // if(distance < DISTANCE_IN_MILES) {
            // const key = `${Number(item?.area[0][0])}${Number(item?.area[0][1])}`;

            let isActivePassport = true;

            // check collection expiry.
            if (item?.end !== MAX_DATE_TIME && isCollectionExpire(item.end)) {
              isActivePassport = false;
            }

            // check max token limit
            if (item?.maxTokens) {
              if (item?.collectionCollectibleIds > item.maxTokens) {
                isActivePassport = false;
              }
            }

            if (isActivePassport) {
              // get constraint.
              const constraint = await getCollectionConstraint(
                address,
                activePassportDetailsList,
                activeDigitalCollectibleList,
                item,
                staticProvider,
                networkId,
              );

              let loc = {
                id: index,
                coordinate: {
                  latitude: Number(item?.area[0][0]),
                  longitude: Number(item?.area[0][1]),
                },
                title: item.name,
                description: '',
                image: item?.image,
                type: item.collectionType,
                constraintList: constraint,
                isMp4Collectible: item.isMp4Collectible,
                video: item.video,
                thumbnail: item.thumbnail,
                offerType: item.offerType,
                isCoupon: item.isCoupon,
                address: item.address,
                enableImage: false,
                thirdPartyVerifiedURL: item?.thirdPartyVerifiedURL,
              };

              estLocation = [...globalPassportList];

              estLocation = estLocation.filter(
                x => x && x.address?.toLowerCase() != loc.address.toLowerCase(),
              );
              estLocation.push(loc);

              globalPassportList = [...estLocation];
            }
          } else {
            if (isValidPassportToMint) {
              // get constraint.
              const constraint = await getCollectionConstraint(
                address,
                activePassportDetailsList,
                activeDigitalCollectibleList,
                item,
                staticProvider,
                networkId,
              );
              let loc = {
                id: index,
                coordinate: null,
                title: item.name,
                description: '',
                image: item?.image,
                type: item.collectionType,
                constraintList: constraint,
                isMp4Collectible: item.isMp4Collectible,
                video: item.video,
                thumbnail: item.thumbnail,
                offerType: item.offerType,
                isCoupon: item.isCoupon,
                address: item.address,
                enableImage: false,
                thirdPartyVerifiedURL: item?.thirdPartyVerifiedURL,
              };

              // without geo-fenced passport
              globalNonGeofencedItems = globalNonGeofencedItems?.filter(x => {
                return x.address != loc.address;
              });
              globalNonGeofencedItems.push(loc);
            }
          }
        }),
    );
  };

  const pushOffers = async (offerItems, offerType: UICustomOfferType) => {
    let offers = [];
    if (offerItems) {
      // coupon wont be display on map.
      await Promise.all(
        offerItems
          ?.filter(x => x.isActive)
          .map(async (item: any, index: any) => {
            let isValidOfferToMint = true;

            let providerToPass = staticProvider;
            if (
              !isNativeChain(item.chainId)
            ) {
              providerToPass = getAnynetStaticProvider(item.chainId);
            }

            if (item.maxTokens) {
              const collection = Loot8Collection__factory.connect(
                item.address,
                providerToPass,
              );
              if (
                +(await collection.collectionCollectibleIds()) > item.maxTokens
              ) {
                isValidOfferToMint = false;
              }
            }

            // if linkCollectible is empty or not having any attached collection,then avoid offer to show in the non geo-fenced drops list.
            if (item && item?.linkCollectible?.length === 0) {
              isValidOfferToMint = false;
            } else if (
              item &&
              item?.linkCollectible?.length === 1 &&
              !item.isCoupon &&
              (item.collectionType == CollectionType.OFFER ||
                item.collectionType == CollectionType.TICKET)
            ) {
              // if attached linkcollectible is inactive, then hide offers/events from non-geo-fenced items.
              const passport = activePassportDetailsList?.find(
                x =>
                  x?.address?.toLocaleLowerCase() ===
                  item?.linkCollectible[0]?.toLocaleLowerCase(),
              );
              if (!passport) {
                let providerToPass = staticProvider;
                if (
                  !isNativeChain(item.chainId)
                ) {
                  providerToPass = getAnynetStaticProvider(item.chainId);
                }
                let collectible = await getCollectibleDetails(
                  {
                    networkID: networkId,
                    provider: providerToPass,
                    collectibleAddress: item?.linkCollectible[0],
                    address,
                    wallet,
                  },
                  { entityData: null },
                  {},
                );
                if (!collectible?.isActive) {
                  isValidOfferToMint = false;
                }
              }
            }

            if (
              isValidOfferToMint &&
              item?.area != null &&
              item?.area.length == 2 &&
              item?.area[1] > 0
            ) {
              let isActiveOffer = true;

              // check collection expiry.
              if (item?.end !== MAX_DATE_TIME && isCollectionExpire(item.end)) {
                isActiveOffer = false;
              }

              // check max token limit
              if (item?.maxTokens) {
                if (item?.collectionCollectibleIds > item.maxTokens) {
                  isActiveOffer = false;
                }
              }

              if (isActiveOffer) {
                // get constraint.
                const constraint = await getCollectionConstraint(
                  address,
                  activePassportDetailsList,
                  activeDigitalCollectibleList,
                  item,
                  staticProvider,
                  networkId,
                );

                let loc = {
                  id: index,
                  coordinate: {
                    latitude: Number(item?.area[0][0]),
                    longitude: Number(item?.area[0][1]),
                  },
                  title: item.name,
                  description: '',
                  image: item?.image,
                  type: item.collectionType,
                  constraintList: constraint,
                  isMp4Collectible: item.isMp4Collectible,
                  video: item.video,
                  thumbnail: item.thumbnail,
                  offerType: item.offerType,
                  isCoupon: item.isCoupon,
                  address: item.address,
                  enableImage: false,
                };
                offers.push(loc);
              }
            } else {
              if (isValidOfferToMint) {
                // get constraint.
                const constraint = await getCollectionConstraint(
                  address,
                  activePassportDetailsList,
                  activeDigitalCollectibleList,
                  item,
                  staticProvider,
                  networkId,
                );

                let loc = {
                  id: index,
                  coordinate: null,
                  title: item.name,
                  description: '',
                  image: item?.image,
                  type: item.collectionType,
                  constraintList: constraint,
                  isMp4Collectible: item.isMp4Collectible,
                  video: item.video,
                  thumbnail: item.thumbnail,
                  offerType: item.offerType,
                  isCoupon: item.isCoupon,
                  address: item.address,
                  enableImage: false,
                };

                // without geo-fenced offers/events
                globalNonGeofencedItems = globalNonGeofencedItems?.filter(x => {
                  return x.address != loc.address;
                });
                globalNonGeofencedItems.push(loc);
              }
            }
          }),
      );
    }

    if (offers?.length > 0) {
      if (offerType === UICustomOfferType.FEATURED_OFFER) {
        let offerList = [...globalFeaturedOffersList];

        offers?.map(item => {
          offerList = offerList.filter(
            x => x && x.address?.toLowerCase() != item.address.toLowerCase(),
          );
          offerList.push(item);
        });

        globalFeaturedOffersList = [...offerList];
      } else if (offerType === UICustomOfferType.REGULAR) {
        let offerList = [...globalRegularOffersList];

        offers?.map(item => {
          offerList = offerList.filter(
            x => x && x.address?.toLowerCase() != item.address?.toLowerCase(),
          );
          offerList.push(item);
        });

        globalRegularOffersList = [...offerList];
      } else if (offerType === UICustomOfferType.TICKET) {
        let offerList = [...globalEventsList];

        offers?.map(item => {
          offerList = offerList.filter(
            x => x && x.address?.toLowerCase() != item.address?.toLowerCase(),
          );
          offerList.push(item);
        });

        globalEventsList = [...offerList];
      } else if (offerType === UICustomOfferType.COUPON) {
        let offerList = [...globalCouponList];

        offers?.map(item => {
          offerList = offerList.filter(
            x => x && x.address?.toLowerCase() != item.address?.toLowerCase(),
          );
          offerList.push(item);
        });

        globalCouponList = [...offerList];
      }
    }
  };

  const getAllPinnedLocations = async () => {
    if (!loadingCollectible) {
      pinDigitalCollectibleLocations();
    }
    if (!loadingPassport) {
      pinLocations();
    }
  };

  useEffect(() => {
    getAllPinnedLocations();
  }, [loadingPassport, loadingCollectible]);

  const pinDigitalCollectibleLocations = async () => {
    let digitiCollectibleLocation: IMapMarker[] = [];
    await Promise.all(
      activeDigitalCollectibleList
        ?.filter(x => x.isActive)
        .map(async (item: any, index: any) => {
          let isValidCollectibleToMint = true;

          let providerToPass = staticProvider;
          if (
            !isNativeChain(item.chainId)
          ) {
            providerToPass = getAnynetStaticProvider(item.chainId);
          }

          if (item.maxTokens) {
            const collection = Loot8Collection__factory.connect(
              item.address,
              providerToPass,
            );
            if (
              +(await collection.collectionCollectibleIds()) > item.maxTokens
            ) {
              isValidCollectibleToMint = false;
            }
          }

          if (
            isValidCollectibleToMint &&
            item?.area != null &&
            item?.area.length == 2 &&
            item?.area[1] > 0
          ) {
            //const key = `${Number(item?.area[0][0])}${Number(item?.area[0][1])}`;
            let isActiveCollectible = true;

            // check collection expiry.
            if (item?.end !== MAX_DATE_TIME && isCollectionExpire(item?.end)) {
              isActiveCollectible = false;
            }

            // check max token limit
            if (item?.maxTokens) {
              if (item?.collectionCollectibleIds > item.maxTokens) {
                isActiveCollectible = false;
              }
            }

            if (isActiveCollectible) {
              // get constraint.
              const constraint = await getCollectionConstraint(
                address,
                activePassportDetailsList,
                activeDigitalCollectibleList,
                item,
                staticProvider,
                networkId,
              );

              let loc = {
                id: index,
                coordinate: {
                  latitude: Number(item?.area[0][0]),
                  longitude: Number(item?.area[0][1]),
                },
                title: item?.name,
                description: '',
                image: item?.image,
                type: item.collectionType,
                constraintList: constraint,
                isMp4Collectible: item.isMp4Collectible,
                video: item.video,
                thumbnail: item.thumbnail,
                offerType: item.offerType,
                isCoupon: item.isCoupon,
                address: item.address,
                enableImage: false,
              };

              digitiCollectibleLocation.push(loc);
            }
          } else {
            if (isValidCollectibleToMint) {
              // get constraint.
              const constraint = await getCollectionConstraint(
                address,
                activePassportDetailsList,
                activeDigitalCollectibleList,
                item,
                staticProvider,
                networkId,
              );

              let loc = {
                id: index,
                coordinate: null,
                title: item.name,
                description: '',
                image: item?.image,
                type: item.collectionType,
                constraintList: constraint,
                isMp4Collectible: item.isMp4Collectible,
                video: item.video,
                thumbnail: item.thumbnail,
                offerType: item.offerType,
                isCoupon: item.isCoupon,
                address: item.address,
                enableImage: false,
              };

              // without geo-fenced collectible
              globalNonGeofencedItems = globalNonGeofencedItems?.filter(x => {
                return x.address != loc.address;
              });
              globalNonGeofencedItems.push(loc);
            }
          }
        }),
    );

    setDigitalCollectibleList(digitiCollectibleLocation);
  };

  // combine multiple collection(passport/collectible) into single collection.
  useEffect(() => {
    // combine multiple location.
    let locations = [
      ...globalPassportList,
      ...digitalCollectibleList,
      ...globalFeaturedOffersList,
      ...globalRegularOffersList,
      ...globalEventsList,
      ...globalCouponList,
    ];

    const uniqLocationsList = uniqWith(locations, (a, b) => {
      return (
        a.coordinate?.latitude === b.coordinate?.latitude &&
        a.coordinate?.longitude === b.coordinate?.longitude
      );
    });

    let uniqLocations: IUniqueLocations[] = [];
    uniqLocationsList?.map((loc, index) => {
      // filter location
      const allLocationList = locations?.filter(x => {
        return (
          x &&
          x.coordinate.latitude === loc.coordinate.latitude &&
          x.coordinate.longitude === loc.coordinate.longitude
        );
      });

      if (allLocationList && allLocationList.length > 0) {
        // filter passport list
        const passportList = allLocationList?.filter(x => {
          return x && x.type === CollectionType.PASSPORT;
        });

        // filter collectible list
        const collectibleList = allLocationList?.filter(x => {
          return x && x.type === CollectionType.COLLECTION;
        });

        // filter featured offer list
        const featuredOffersData = allLocationList?.filter(x => {
          return (
            x &&
            x.type === CollectionType.OFFER &&
            x.offerType === OfferType.FEATURED &&
            !x.isCoupon
          );
        });

        // filter regular offer list
        const regularOffersData = allLocationList?.filter(x => {
          return (
            x &&
            x.type === CollectionType.OFFER &&
            x.offerType === OfferType.REGULAR &&
            !x.isCoupon
          );
        });

        // filter events list
        const eventsData = allLocationList?.filter(x => {
          return x && x.type === CollectionType.TICKET;
        });

        // filter coupon list
        const couponsData = allLocationList?.filter(x => {
          return (
            x &&
            x.type === CollectionType.OFFER &&
            (x.offerType === OfferType.FEATURED ||
              x.offerType === OfferType.REGULAR) &&
            x.isCoupon
          );
        });

        let uLoc: IUniqueLocations = {
          coordinate: loc.coordinate,
          locationList: allLocationList,
          totalPin: allLocationList?.length,
          totalPassportCount: passportList?.length,
          totalCollectibleCount: collectibleList?.length,
          passportList: passportList,
          collectibleList: collectibleList,
          featuredOfferList: featuredOffersData,
          regularOfferList: regularOffersData,
          eventsList: eventsData,
          totalFeaturedCount: featuredOffersData?.length,
          totalRegularCount: regularOffersData?.length,
          totalEventCount: eventsData?.length,
          key: `${loc.coordinate.latitude}${loc.coordinate.longitude}`,
          couponList: couponsData,
          totalCouponCount: couponsData?.length,
        };
        uniqLocations.push(uLoc);
      }
    });

    // update the state of location list
    setAllLocationList(uniqLocations);

    if (currentRegion) {
      const enableImagelocations = uniqLocations?.filter(item => {
        const withinMiles = getLocationDistance(
          currentRegion?.latitude,
          currentRegion?.longitude,
          item?.coordinate?.latitude,
          item?.coordinate?.longitude,
        );
        return withinMiles <= milesToShowImage;
      });
      if (enableImagelocations?.length > 0) {
        setLocationList(enableImagelocations);
      }
    }
    if (globalNonGeofencedItems?.length > 0) {
      const locNonGeofencedItems = {
        totalPin: globalNonGeofencedItems?.length,
        locationList: globalNonGeofencedItems,
      };
      setNonGeoFencedItems(locNonGeofencedItems);
    }
  }, [
    globalPassportList,
    digitalCollectibleList,
    globalFeaturedOffersList,
    globalRegularOffersList,
    globalEventsList,
    globalCouponList,
  ]);

  useEffect(() => {
    if (showPinImage) {
      if (currentRegion) {
        const enableImagelocations = allLocationList.map(item => {
          const withinMiles = getLocationDistance(
            currentRegion?.latitude,
            currentRegion?.longitude,
            item?.coordinate?.latitude,
            item?.coordinate?.longitude,
          );
          if (withinMiles <= milesToShowImage) {
            return { ...item, enableImage: true };
          }
          return item;
        });
        if (enableImagelocations?.length > 0) {
          setLocationList(enableImagelocations);
        }
      }
    } else {
      if (allLocationList?.length != locationList?.length) {
        setLocationList(allLocationList);
      }
    }
  }, [currentRegion]);

  const getMilesToShowImageFromAppConfig = async () => {
    const appConfig = await getAppConfiguration();
    if (width >= TABLET_DIMENTION.width) {
      if (appConfig?.map?.webMilesToShowImage) {
        setMilesToShowImage(appConfig?.map?.webMilesToShowImage);
      }
    } else {
      if (appConfig?.map?.mobileMilesToShowImage) {
        setMilesToShowImage(appConfig?.map?.mobileMilesToShowImage);
      }
    }
  };

  useEffect(() => {
    getMilesToShowImageFromAppConfig();
  }, []);

  const pinOffers = async () => {
    await wait(5000);
    const appConfig = await getAppConfiguration();
    if (appConfig?.map?.offersAndEventsEnabled) {
      let allOfferList = [];
      const offersList = await getAllCollectionByCategory({
        networkID: networkId,
        provider: staticProvider,
        collectionType: CollectionType.OFFER,
      });
      if (offersList?.length > 0) {
        await Promise.all(
          offersList
            .filter(
              collectibleAddress => collectibleAddress.source !== ZERO_ADDRESS,
            )
            .map(async collectibleAddress => {
              let providerToPass = staticProvider;
              let chainId = collectibleAddress.chainId;
              if (
               !isNativeChain(chainId)
              ) {
                providerToPass = getAnynetStaticProvider(chainId);
              }
              let collectible = await getCollectibleDetails(
                {
                  networkID: chainId,
                  provider: providerToPass,
                  collectibleAddress: collectibleAddress.source,
                  address,
                  wallet,
                },
                { entityData: null },
                {},
              );
              if (collectible != null && collectible.dataURI != '') {
                allOfferList.push(collectible);
              }
            }),
        );
      }
      if (allOfferList?.length > 0) {
        let featuredOffer = allOfferList?.filter(
          x =>
            x && x.offerType == UICustomOfferType.FEATURED_OFFER && !x.isCoupon,
        );
        let regularOffer = allOfferList?.filter(
          x =>
            x && x.offerType != UICustomOfferType.FEATURED_OFFER && !x.isCoupon,
        );

        let featuredCoupon = allOfferList?.filter(
          x =>
            x && x.offerType == UICustomOfferType.FEATURED_OFFER && x.isCoupon,
        );
        let regularCoupon = allOfferList?.filter(
          x =>
            x && x.offerType != UICustomOfferType.FEATURED_OFFER && x.isCoupon,
        );
        let couponsList = [...featuredCoupon, ...regularCoupon];

        // featured offers
        await pushOffers(featuredOffer, UICustomOfferType.FEATURED_OFFER);

        // regular
        await pushOffers(regularOffer, UICustomOfferType.REGULAR);

        // coupon
        await pushOffers(couponsList, UICustomOfferType.COUPON);
      }
    }
  };

  const pinEvents = async () => {
    await wait(5000);
    const appConfig = await getAppConfiguration();
    if (appConfig?.map?.offersAndEventsEnabled) {
      let allEventsList = [];
      const eventsList = await getAllCollectionByCategory({
        networkID: networkId,
        provider: staticProvider,
        collectionType: CollectionType.TICKET,
      });
      if (eventsList?.length > 0) {
        await Promise.all(
          eventsList
            .filter(
              collectibleAddress => collectibleAddress.source !== ZERO_ADDRESS,
            )
            .map(async collectibleAddress => {
              let providerToPass = staticProvider;
              let chainId = collectibleAddress.chainId;
              if (
                !isNativeChain(chainId)
              ) {
                providerToPass = getAnynetStaticProvider(chainId);
              }
              let collectible = await getCollectibleDetails(
                {
                  networkID: chainId,
                  provider: providerToPass,
                  collectibleAddress: collectibleAddress.source,
                  address,
                  wallet,
                },
                { entityData: null },
                {},
              );
              if (collectible != null && collectible.dataURI != '') {
                allEventsList.push(collectible);
              }
            }),
        );
      }
      if (allEventsList?.length > 0) {
        // events
        await pushOffers(allEventsList, UICustomOfferType.TICKET);
      }
    }
  };

  useEffect(() => {
    // pull offers
    pinOffers();
  }, []);

  useEffect(() => {
    // pull events
    pinEvents();
  }, []);

  const enableCollectionImageBySetting = async () => {
    const appConfig = await getAppConfiguration();
    setEnableCollectionImage(appConfig?.map?.enableCollectionImage);
  };

  // enable/disable collection image
  useEffect(() => {
    enableCollectionImageBySetting();
  }, []);

  return (
    <>
      {/* for mobile */}
      {locationList?.map((marker, index) => {
        return (
          <Marker
            key={marker.key}
            coordinate={marker.coordinate}
            opacity={0.9}
            centerOffset={CENTEROFFSET()}
            onPress={() => {
              setSelectedMarker(marker);
              setShowParameters(true);
            }}
            tracksViewChanges={false}>
            {enableCollectionImage && showPinImage && marker.enableImage ? (
              <View
                style={[Platform.OS == 'web' ? { alignItems: 'center' } : {}]}>
                <MarkerItem marker={marker} />
              </View>
            ) : (
              <View
                style={[
                  !(
                    marker.enableImage &&
                    showPinImage &&
                    enableCollectionImage
                  ) &&
                    Platform.OS == 'web' && {
                      marginTop: -84,
                      alignItems: 'center',
                    },
                ]}>
                <MarkerPinImage marker={marker} showNumber={true} />
              </View>
            )}
          </Marker>
        );
      })}
    </>
  );
};

export default Markers;
