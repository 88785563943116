import { ethers } from 'ethers';
import {
  CollectionManager__factory,
  Dispatcher__factory,
  HQNFTsERC721Mint__factory,
  Loot8Collection__factory,
  Loot8Token__factory,
  Loot8Marketplace__factory,
  Loot8UniformCollection__factory,
  SubscriptionManager__factory,
  User__factory,
  USDC__factory,
  Loot8Onboarder__factory,
  Event__factory,
} from '../typechain/factories';
import { LogToLoot8Console } from './Loot8ConsoleLogger';
import {
  NetworkId,
  ZERO_ADDRESS,
  addresses,
  isNativeChain,
} from '../appconstants';
import { Loot8FiatOffRamp__factory } from '../typechain/factories/Loot8FiatOffRamp__factory';
import { Loot8ERC20__factory } from '../typechain/factories/Loot8ERC20__factory';
import { TicketRevertReason } from '../enums/tickets.enum';

export const GetERC721TransferMessage = (patron, friend, tokenId) => {
  const ERC721 = new ethers.utils.Interface(Loot8Collection__factory.abi);
  return ERC721.encodeFunctionData('transferFrom', [patron, friend, tokenId]);
};

export const getOfframpTransactionLogsData = logs => {
  let decodedEventData = null;
  let requestId = 0;
  let timestamp = 0;
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const IOffRamp = new ethers.utils.Interface(
        Loot8FiatOffRamp__factory.abi,
      );
      decodedEventData = IOffRamp.decodeEventLog(
        'OffRampRequested',
        logs[2].data,
        logs[2].topics,
      );
    }
    if (decodedEventData && decodedEventData.length > 1) {
      requestId = decodedEventData?._requestId?.toString?.() || 0;
      timestamp = parseInt(decodedEventData?._timestamp?._hex) || 0;
    }
  } catch (e) {
    LogToLoot8Console('error in decoding offramp transaction logs');
  }
  return { requestId, timestamp };
};

export const GetCreateUserMessage = (name, imageUrl, dataURI) => {
  const IUser = new ethers.utils.Interface(Dispatcher__factory.abi);
  return IUser.encodeFunctionData('registerUser', [name, imageUrl, dataURI]);
};

export const GetDeleteUserMessage = () => {
  const IUser = new ethers.utils.Interface(User__factory.abi);
  return IUser.encodeFunctionData('deregister');
};

export const GetFollowUserMessage = address => {
  const IUser = new ethers.utils.Interface(User__factory.abi);
  return IUser.encodeFunctionData('follow', [address]);
};

export const GetUnFollowUserMessage = address => {
  const IUser = new ethers.utils.Interface(User__factory.abi);
  return IUser.encodeFunctionData('unfollow', [address]);
};

export const GetLinkedCollectibleMintMessage = (collection, patron) => {
  const IPassport = new ethers.utils.Interface(Dispatcher__factory.abi);
  return IPassport.encodeFunctionData('mintLinked', [collection, patron]);
};

export const getOffRampTransactionMessage = (
  requestId,
  amount,
  platform,
  data,
) => {
  const IOffRamp = new ethers.utils.Interface(Loot8FiatOffRamp__factory.abi);
  return IOffRamp.encodeFunctionData('requestOffRamp', [
    requestId,
    amount,
    platform,
    data,
  ]);
};

export const getApprovalTransactionMessage = (spender, amount) => {
  const IOffRamp = new ethers.utils.Interface(Loot8ERC20__factory.abi);
  return IOffRamp.encodeFunctionData('approve', [spender, amount]);
};

export const GetPassportMintMessage = (patron, collection) => {
  const IPassport = new ethers.utils.Interface(CollectionManager__factory.abi);
  return IPassport.encodeFunctionData('mintCollectible', [patron, collection]);
};

export const GetCollectibleMintMessage = patron => {
  const IPassport = new ethers.utils.Interface(
    Loot8UniformCollection__factory.abi,
  );
  return IPassport.encodeFunctionData('mintNext', [patron]);
};

// export const GetOfferMintMessage = (patron) => {
//     const IOffer = new ethers.utils.Interface(Offer__factory.abi);
//     return IOffer.encodeFunctionData('addPendingOrder', [patron]);
// }

export const GetOrderReserveMessage = (
  _offer,
  _address,
  _patron,
  _cashPayment,
  _offerId,
) => {
  const IDispatcher = new ethers.utils.Interface(Dispatcher__factory.abi);
  return IDispatcher.encodeFunctionData('addReservation', [
    _offer,
    _address,
    _patron,
    _cashPayment,
    _offerId,
  ]);
};

// export const GetEventMintMessage = (patron) => {
//     const IEvent = new ethers.utils.Interface(Event__factory.abi);
//     return IEvent.encodeFunctionData('addOrder', [patron]);
// }

export const GetUserStatusMessage = status => {
  const IUser = new ethers.utils.Interface(User__factory.abi);
  return IUser.encodeFunctionData('setStatus', [status]);
};

export const GetUserNameMessage = name => {
  const IUser = new ethers.utils.Interface(User__factory.abi);
  return IUser.encodeFunctionData('changeName', [name]);
};

export const GetThirdPartyContractMintMessage = (
  func,
  types: string[],
  args: string[],
) => {
  const ICollectibles = new ethers.utils.Interface([
    'function ' + func + '(' + types.join(',') + ')',
  ]);
  return ICollectibles.encodeFunctionData(func, args);
};

export const GetUserAvatarMessage = avatarURI => {
  const IUser = new ethers.utils.Interface(User__factory.abi);
  return IUser.encodeFunctionData('setAvatarURI', [avatarURI]);
};

export const GetUserDataURIMessage = dataURI => {
  const IUser = new ethers.utils.Interface(User__factory.abi);
  return IUser.encodeFunctionData('setDataURI', [dataURI]);
};

export const GetUserCreatedLogData = logs => {
  let decodedEventData = null;
  let createdUserID = 0;
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const IUser = new ethers.utils.Interface(User__factory.abi);
      decodedEventData = IUser.decodeEventLog(
        'UserCreated',
        logs[0].data,
        logs[0].topics,
      );
    }
    if (
      decodedEventData &&
      Array.isArray(decodedEventData) &&
      decodedEventData.length > 1
    ) {
      createdUserID = parseInt(decodedEventData[1], 10);
    }
  } catch (e) {
    LogToLoot8Console('error in decoding user created event logs');
  }
  return createdUserID;
};

export const GetUserLinkAccountMessage = (extAccount, signature) => {
  const IUser = new ethers.utils.Interface(User__factory.abi);
  return IUser.encodeFunctionData('linkExternalAccount', [
    extAccount,
    signature,
  ]);
};

export const GetUserDeLinkAccountMessage = extAccount => {
  const IUser = new ethers.utils.Interface(User__factory.abi);
  return IUser.encodeFunctionData('delinkExternalAccount', [extAccount]);
};

export const GetTransferNFTCallLogsData = logs => {
  let decodedEventData = null;
  let tokenId = '';
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const ICollectibles = new ethers.utils.Interface(
        HQNFTsERC721Mint__factory.abi,
      );
      decodedEventData = ICollectibles.decodeEventLog(
        'Transfer',
        logs[0].data,
        logs[0].topics,
      );
    }
    if (
      decodedEventData &&
      Array.isArray(decodedEventData) &&
      decodedEventData.length > 1
    ) {
      //createdUserID = parseInt(decodedEventData[1], 10);
      tokenId = decodedEventData[2].toString();
      LogToLoot8Console(decodedEventData);
    }
  } catch (e) {
    LogToLoot8Console('error in decoding transfer NFT call event logs');
  }

  return tokenId;
};

export const GetOfferMintedMessage = logs => {
  let createdReservationID = 0;
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const IDispatcher = new ethers.utils.Interface(Dispatcher__factory.abi);

      logs?.map((item, index) => {
        try {
          const decodedEventDataItem = IDispatcher.parseLog(item);
          if (
            decodedEventDataItem &&
            decodedEventDataItem?.name.toLocaleLowerCase() ===
              'ReservationAdded'.toLocaleLowerCase() &&
            decodedEventDataItem?.args &&
            decodedEventDataItem?.args?._newReservationId &&
            !createdReservationID
          ) {
            createdReservationID =
              +decodedEventDataItem?.args?._newReservationId;
          }
        } catch (e) {}
      });
    }
  } catch (e) {
    LogToLoot8Console('error in decoding offer minting event logs');
  }
  return createdReservationID;
};

export const ApproveLoot8TokenForSubscription = (spender, amount) => {
  const ILoot8Token = new ethers.utils.Interface(Loot8Token__factory.abi);
  return ILoot8Token.encodeFunctionData('approve', [spender, amount]);
};

export const ApprovePassportForUnsubscribe = (spender, amount) => {
  const ILoot8UniformCollection = new ethers.utils.Interface(
    Loot8UniformCollection__factory.abi,
  );
  return ILoot8UniformCollection.encodeFunctionData('approve', [
    spender,
    amount,
  ]);
};

export const SubscribePassportMessage = passportAddress => {
  const ISubscription = new ethers.utils.Interface(
    SubscriptionManager__factory.abi,
  );
  return ISubscription.encodeFunctionData('subscribe', [passportAddress]);
};

export const UnsubscribePassportMessage = (passportAddress, passportIds) => {
  const ISubscription = new ethers.utils.Interface(
    SubscriptionManager__factory.abi,
  );
  return ISubscription.encodeFunctionData('unsubscribe', [
    passportAddress,
    passportIds,
  ]);
};

export const GetSubscribePassportLogsData = (logs, address) => {
  let decodedEventData = null;
  let tokenId = '';
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const ILoot8Collection = new ethers.utils.Interface(
        Loot8Collection__factory.abi,
      );
      logs?.map((item, index) => {
        try {
          const decodedEventDataItem = ILoot8Collection.decodeEventLog(
            'Transfer',
            item.data,
            item.topics,
          );
          if (
            decodedEventDataItem &&
            Array.isArray(decodedEventDataItem) &&
            decodedEventDataItem.length >= 3
          ) {
            if (
              decodedEventDataItem[0] === ZERO_ADDRESS &&
              decodedEventDataItem[1].toLocaleLowerCase() ===
                address.toLocaleLowerCase()
            ) {
              tokenId = Number(decodedEventDataItem[2]).toString();
            }
          }
        } catch (e) {}
      });
    }
  } catch (e) {
    LogToLoot8Console('error in decoding Subscribe Passport call event logs');
  }
  return tokenId;
};

export const GetApprovalForListMarketPlace = (address, tokenId) => {
  const ICollection = new ethers.utils.Interface(
    Loot8UniformCollection__factory.abi,
  );
  return ICollection.encodeFunctionData('approve', [address, tokenId]);
};

export const GetListCollectibleMarketPlace = (
  passport,
  collection,
  tokenId,
  paymentToken,
  price,
  signature,
  expiry,
  listingType,
) => {
  const IMarketPlace = new ethers.utils.Interface(
    Loot8Marketplace__factory.abi,
  );
  return IMarketPlace.encodeFunctionData('listCollectible', [
    passport,
    collection,
    tokenId,
    paymentToken,
    price,
    signature,
    expiry,
    listingType,
  ]);
};

export const GetDeListCollectibleMarketPlace = listingID => {
  const IMarketPlace = new ethers.utils.Interface(
    Loot8Marketplace__factory.abi,
  );
  return IMarketPlace.encodeFunctionData('delistCollectible', [listingID]);
};

export const decodeListingCollectibleMarketPlace = logs => {
  let listingId = 0;
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const IMarketPlace = new ethers.utils.Interface(
        Loot8Marketplace__factory.abi,
      );

      logs?.map((item, index) => {
        try {
          const decodedEventDataItem = IMarketPlace.parseLog(item);
          if (
            decodedEventDataItem &&
            decodedEventDataItem?.name.toLocaleLowerCase() ===
              'ItemListedForSale'.toLocaleLowerCase() &&
            decodedEventDataItem?.args &&
            decodedEventDataItem?.args?._listingId &&
            !listingId
          ) {
            listingId = +decodedEventDataItem?.args?._listingId;
          }
        } catch (e) {
          LogToLoot8Console('error in decoding listing marketplace event log');
        }
      });
    }
  } catch (e) {
    LogToLoot8Console('error in decoding listing marketplace event logs');
  }
  return listingId;
};

export const decodeUnlistingCollectibleMarketPlace = logs => {
  let listingId = 0;
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      const IMarketPlace = new ethers.utils.Interface(
        Loot8Marketplace__factory.abi,
      );

      logs?.map((item, index) => {
        try {
          const decodedEventDataItem = IMarketPlace.parseLog(item);
          if (
            decodedEventDataItem &&
            decodedEventDataItem?.name.toLocaleLowerCase() ===
              'ItemDelisted'.toLocaleLowerCase() &&
            decodedEventDataItem?.args &&
            decodedEventDataItem?.args?._listingId &&
            !listingId
          ) {
            listingId = +decodedEventDataItem?.args?._listingId;
          }
          if (
            decodedEventDataItem &&
            decodedEventDataItem?.name.toLocaleLowerCase() ===
              'ItemSold'.toLocaleLowerCase() &&
            decodedEventDataItem?.args &&
            decodedEventDataItem?.args?._tokenId &&
            !listingId
          ) {
            listingId = +decodedEventDataItem?.args?._tokenId;
          }
        } catch (e) {
          LogToLoot8Console('error in decoding listing marketplace event log');
        }
      });
    }
  } catch (e) {
    LogToLoot8Console('error in decoding listing marketplace event logs');
  }
  return listingId;
};

export const GetApprovalForBuyListing = (address, amount) => {
  const ICollection = new ethers.utils.Interface(Loot8Token__factory.abi);
  return ICollection.encodeFunctionData('approve', [address, amount]);
};

export const GetForeignChainApprovalForBuyListing = async (
  networkID,
  provider,
  wallet,
  amount,
) => {
  const ICollection = new ethers.utils.Interface(USDC__factory.abi);
  let paymentToken = isNativeChain(networkID)
    ? addresses[networkID].Loot8Token
    : addresses[networkID].USDC;

  // get the MyToken contract factory and deploy a new instance of the contract
  const token = new ethers.Contract(paymentToken, USDC__factory.abi, provider);

  // set token value and deadline
  const deadline = Math.floor(Date.now() / 1000) + 4200;

  // get the current nonce for the deployer address
  const nonces = await token.nonces(wallet.address);
  // set the domain parameters
  const domain = {
    name: await token.name(),
    version: '1',
    chainId: networkID,
    verifyingContract: token.address,
  };

  // set the Permit type parameters
  const types = {
    Permit: [
      {
        name: 'owner',
        type: 'address',
      },
      {
        name: 'spender',
        type: 'address',
      },
      {
        name: 'value',
        type: 'uint256',
      },
      {
        name: 'nonce',
        type: 'uint256',
      },
      {
        name: 'deadline',
        type: 'uint256',
      },
    ],
  };

  // set the Permit type values
  const values = {
    owner: wallet.address,
    spender: addresses[networkID].MarketPlace,
    value: amount,
    nonce: nonces,
    deadline: deadline,
  };

  // sign the Permit type data with the deployer's private key
  const signature = await wallet._signTypedData(domain, types, values);

  // split the signature into its components
  const sig = ethers.utils.splitSignature(signature);

  return ICollection.encodeFunctionData('permit', [
    wallet.address,
    addresses[networkID].MarketPlace,
    amount,
    deadline,
    sig.v,
    sig.r,
    sig.s,
  ]);
};

export const GetBuyCollectibleMarketPlace = (listingId, signature, expiry) => {
  const IMarketPlace = new ethers.utils.Interface(
    Loot8Marketplace__factory.abi,
  );
  return IMarketPlace.encodeFunctionData('buy', [listingId, signature, expiry]);
};

// export const GetAirdropSubscriptionMessage = (patron, collection) => {
//     const IPassport = new ethers.utils.Interface(SubscriptionManager__factory.abi);
//     return IPassport.encodeFunctionData('airdropSubscription', [patron, collection]);
// }

export const GetBlockNumberFromLogsData = logs => {
  let blockNumber = '';
  try {
    if (logs && Array.isArray(logs) && logs.length > 0) {
      logs
        ?.filter(x => 1 == 1)
        .map((item, index) => {
          try {
            blockNumber = item?.blockNumber;
          } catch (e) {}
        });
    }
  } catch (e) {
    LogToLoot8Console('error in getting block number from logs');
  }
  return blockNumber;
};

export const getCreatorStatus = async ({
  networkID,
  provider,
  address,
}): Promise<boolean> => {
  let isCreator = false;
  try {
    const loot8Onboarder = Loot8Onboarder__factory.connect(
      addresses[networkID].Onboarder,
      provider,
    );

    isCreator = await loot8Onboarder.userOnboarded(address);

    return isCreator;
  } catch (e) {
    LogToLoot8Console(
      'error in getting isUserOrCreator() from Loot8Onboarder contract',
      e,
    );
  }
};

export const getTicketTypedData = ({ networkId }) => {
  const domain = {
    name: 'LOOT8Ticketing',
    version: '1',
    chainId: networkId,
    verifyingContract: addresses[networkId].Loot8TicketVerification,
  };

  const types = {
    Loot8TicketRedemption: [
      { name: 'event', type: 'address' },
      { name: 'ticket', type: 'address' },
      { name: 'ticketId', type: 'uint256' },
      { name: 'owner', type: 'address' },
      { name: 'timestamp', type: 'uint256' },
      { name: 'message', type: 'string' },
    ],
  };

  return { domain, types };
};

export const GetRedeemTicketMessage = (
  _ticket,
  _ticketId,
  _owner,
  _timestamp,
  _signature,
) => {
  const IEvent = new ethers.utils.Interface(Event__factory.abi);
  return IEvent.encodeFunctionData('redeemTicket', [
    _ticket,
    _ticketId,
    _owner,
    _timestamp,
    _signature,
  ]);
};

export const GetRedeemTicketRevertReason = async ({
  staticProvider,
  event,
  data,
  address,
}) => {
  try {
    const result = await staticProvider.call({
      to: event,
      data,
      from: address,
    });

    const errorData = '0x' + result.slice(10);
    const decodedError = ethers.utils.defaultAbiCoder.decode(
      ['string'],
      errorData,
    );

    return decodedError[0] || TicketRevertReason.GENERIC;
  } catch {
    return TicketRevertReason.GENERIC;
  }
};
