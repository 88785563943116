import { StyleSheet } from 'react-native';
import { defaultTheme } from '../../themes/loot8';

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  rightContainer: {
    flex: 1,
    marginLeft: 15,
  },
  image: {
    aspectRatio: 41 / 25,
    borderRadius: defaultTheme.CONTENT_RADIUS,
    borderWidth: 1,
    borderColor: defaultTheme.SECONDARY_TEXT_COLOR,
    overflow: 'hidden',
  },
  title: {
    fontSize: defaultTheme.FONT_SIZE_XMEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XMEDIUM + 2,
    fontFamily: defaultTheme.FONT_FAMILY_SEMI_BOLD,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  description: {
    marginTop: 2.5,
    fontSize: defaultTheme.FONT_SIZE_XXMEDIUM,
    lineHeight: defaultTheme.FONT_SIZE_XXMEDIUM + 2,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  subtitle: {
    marginTop: 2.5,
    fontSize: defaultTheme.FONT_SIZE_XXXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXXSMALL + 2,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.SECONDARY_TEXT_COLOR,
  },
  creatorType: {
    marginTop: 2.5,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    lineHeight: defaultTheme.FONT_SIZE_XXSMALL + 2,
    fontFamily: defaultTheme.FONT_FAMILY_REGULAR,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
  },
  btnContainer: {
    flex: 0.48,
    borderRadius: defaultTheme.BUTTON_RADIUS,
    height: 36,
    overflow: 'hidden',
  },
  ripple: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btnText: {
    fontFamily: defaultTheme.FONT_FAMILY_MEDIUM,
    fontSize: defaultTheme.FONT_SIZE_XXSMALL,
    color: defaultTheme.PRIMARY_TEXT_COLOR,
    textTransform: 'capitalize',
  },
  disabled: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(0,0,0,0.25)',
  },
});
