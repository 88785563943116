import React, { useEffect, useState } from 'react';
import { FlatList, Text, View } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import { LinearGradient } from 'expo-linear-gradient';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { styles } from './styles';
import { defaultTheme } from '../../themes/loot8';
import useActiveDimensions from '../../hooks/useActiveDimensions';
import useIsResponsive from '../../hooks/useIsResponsive';

const AnimatedTabButtons = ({ data, changeSlidesOnPress }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeWidth = useActiveDimensions().width;
  const isResponsive = useIsResponsive();

  const tabWidth = isResponsive
    ? defaultTheme.CONTENT_CONTAINER_MAX_WIDTH / data.length
    : activeWidth / data.length - defaultTheme.HORIZONTAL_PADDING * 0.75;
  const leftProgress = useSharedValue(0);

  const leftAnimatedStyles = useAnimatedStyle(() => {
    return {
      left: leftProgress.value,
    };
  });
  const GradientBg = () => {
    return (
      <Animated.View
        style={[styles.gradientBg, leftAnimatedStyles, { width: tabWidth }]}>
        <LinearGradient
          colors={[defaultTheme.GRADIENT_COLOR1, defaultTheme.GRADIENT_COLOR2]}
          start={{ x: 0.2, y: 0.0001 }}
          end={{ x: 1, y: 0.0001 }}
          style={{ flex: 1 }}
        />
      </Animated.View>
    );
  };
  const onTabPress = index => {
    leftProgress.value = withSpring(tabWidth * index);
    changeSlidesOnPress(index);
    setActiveIndex(index);
  };
  useEffect(() => {
    onTabPress(activeIndex);
  }, [activeWidth, isResponsive]);
  return (
    <LinearGradient
      colors={[defaultTheme.GRADIENT_COLOR2, defaultTheme.GRADIENT_COLOR1]}
      start={{ x: 0.2, y: 0.0001 }}
      end={{ x: 1, y: 0.0001 }}
      style={[styles.container]}>
      <View style={styles.contentContainer}>
        <FlatList
          style={{ flex: 1, zIndex: 9 }}
          contentContainerStyle={{ flexGrow: 1 }}
          horizontal
          scrollEnabled={false}
          data={data}
          renderItem={({ item, index }) => (
            <TabItem
              title={item.title}
              bubbleCount={item?.bubbleCount}
              onPress={() => onTabPress(index)}
              tabWidth={tabWidth}
            />
          )}
          keyExtractor={item => item.id}
        />
        <GradientBg />
      </View>
    </LinearGradient>
  );
};

export default AnimatedTabButtons;

const TabItem = ({ title, bubbleCount, onPress, tabWidth }) => {
  return (
    <View style={[styles.activeBg, { width: tabWidth }]}>
      <TouchableRipple
        style={styles.itemContainer}
        rippleColor={'rgba(0,0,0,0.25)'}
        onPress={onPress}>
        <>
          <Text style={styles.itemText}>{title}</Text>
          {bubbleCount > 0 && (
            <View style={styles.counterCircle}>
              <Text style={styles.counterBubbleText}>
                {bubbleCount > 10 ? '10+' : bubbleCount}
              </Text>
            </View>
          )}
        </>
      </TouchableRipple>
    </View>
  );
};
