import React from 'react';
import { Text } from 'react-native';

import { defaultTheme } from '../../themes/loot8';

function ScreenTitle({ title }) {
  return (
    <Text
      style={{
        color: defaultTheme.PRIMARY_TEXT_COLOR,
        fontSize: defaultTheme.FONT_SIZE_XSMALL,
        fontFamily: defaultTheme.FONT_FAMILY_EXTRABOLD,
        marginTop: 0,
        marginVertical: 12,
        alignSelf: 'center',
        textAlign: 'center',
      }}>
      {title ? title : ' '}
    </Text>
  );
}

export default ScreenTitle;
